import {
  Box,
  Divider,
  IconButton,
  StepLabel,
  Stepper,
  Typography,
  styled
} from '@mui/material';
import { stepLabelClasses } from '@mui/material/StepLabel';
import PsButton from 'components/common/PsButton';
import { colorPalette } from 'theme';

export const StyledStepper = styled(Stepper)({
  marginRight: '2.5rem',
  width: '60%'
});

export const StyledDivider = styled(Divider)({
  margin: '1rem 0'
});

export const MainContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  backgroundColor: 'white',
  borderRadius: '7px',
  padding: '10px'
});

export const HeaderBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%'
});

export const TitleBox = styled(Box)({
  marginBottom: '1rem'
});

export const HeaderText = styled(Typography)({
  whiteSpace: 'nowrap',
  width: '40%',
  color: colorPalette.black,
  fontSize: '26px',
  fontWeight: '700',
  marginRight: '2rem',
  padding: '0'
});

export const TitleText = styled(Typography)({
  color: colorPalette.darkGray,
  fontSize: '18',
  fontWeight: '600',
  lineHeight: '1.4'
});

export const SubTitleText = styled(Typography)({
  color: colorPalette.darkGray,
  fontSize: '18',
  fontWeight: '500',
  lineHeight: '1.4'
});

export const StyledSaveButton = styled(PsButton)({
  borderRadius: '6px !important',
  color: colorPalette.white,
  fontSize: '18px !important',
  fontWeight: 'bold',
  height: '45px',
  outline: 'none',
  padding: '8px 0px!important',
  width: '11.875rem'
});

export const StyledFooterBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between'
});

export const StyledStepLabel = styled(StepLabel)(() => ({
  [`& .${stepLabelClasses.label}`]: {
    [`&.${stepLabelClasses.completed}`]: {
      color: colorPalette.purple,
      fontWeight: '600'
    },
    [`&.${stepLabelClasses.active}`]: {
      color: colorPalette.purple,
      fontWeight: '600'
    },
    color: colorPalette.grey,
    fontWeight: '600'
  }
}));

export const TitleMainBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  paddingRight: '.75rem'
});

export const StyledIconButton = styled(IconButton)({
  color: colorPalette.purple,
  height: '35px',
  width: '35px'
});
