import React from 'react';
import { Container, Box, Typography } from '@mui/material';
import Config from 'config/config';

export const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Welcome to MindMiner Privacy Policy page. Your privacy is critically
          important to us.
        </Typography>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          1. Information We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          We collect various types of information, including information that
          identifies or may identify you as an individual (Personal Information)
          as explained in more detail below.
        </Typography>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          2. How We Use Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          MindMiner uses the information we collect primarily to provide,
          maintain, protect, and improve our current products and to develop new
          ones. We use personal information to personalize your experience.
        </Typography>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          3. Sharing of Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We do not share your personal information with companies,
          organizations, or individuals outside of MindMiner unless one of the
          following circumstances applies: with your consent, for external
          processing, or for legal reasons.
        </Typography>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          4. Cookies and Tracking Technologies
        </Typography>
        <Typography variant="body1" paragraph>
          MindMiner uses cookies and similar tracking technologies to track the
          activity on our service and hold certain information. You can instruct
          your browser to refuse all cookies or to indicate when a cookie is
          being sent.
        </Typography>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          5. Security
        </Typography>
        <Typography variant="body1" paragraph>
          The security of your personal information is important to us, but
          remember that no method of transmission over the internet, or method
          of electronic storage is 100% secure. While we strive to use
          commercially acceptable means to protect your personal information, we
          cannot guarantee its absolute security.
        </Typography>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          6. Your Data Protection Rights
        </Typography>
        <Typography variant="body1" paragraph>
          You have the right to access, update, or delete the information we
          have on you. Whenever made possible, you can access, update, or
          request deletion of your personal information directly within your
          account settings section. If you are unable to perform these actions
          yourself, please contact us to assist you.
        </Typography>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          7. Changes to This Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page. You are
          advised to review this Privacy Policy periodically for any changes.
        </Typography>
      </Box>

      <Box>
        <Typography variant="h5" component="h2" gutterBottom>
          8. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about this Privacy Policy, please contact us
          at {Config.SUPPORT_EMAIL}.
        </Typography>
      </Box>
    </Container>
  );
};
