import { ArrowDropUp } from '@mui/icons-material';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { colorPalette } from 'theme';

export const StyledHelpButton = styled(Button)(() => ({
  backgroundColor: `${colorPalette.lightGreen}98`,
  borderBottomLeftRadius: '0',
  borderBottomRightRadius: '0',
  borderTopLeftRadius: '10px',
  borderTopRightRadius: '10px',
  height: '50px',
  maxWidth: '180px',
  padding: '10px 16px',
  position: 'fixed',
  right: '-69px',
  textTransform: 'none',
  top: '50%',
  transform: 'rotate(-90deg)',
  width: '180px',
  '&:hover': {
    backgroundColor: `${colorPalette.lightGreen}`
  }
}));

export const ArrowLeft = styled(ArrowDropUp)(() => ({
  color: colorPalette.white
}));

export const StyledTypography = styled(Typography)(() => ({
  color: colorPalette.white,
  fontSize: '13px',
  fontWeight: '700',
  margin: '0px 5px 0px 10px'
}));
