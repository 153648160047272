import React from 'react';
import { toastify } from 'pages/newContests/toastify';
import Actions from 'redux-state/actions';
import { Constants, VARIANT } from 'utilities/constants';
import { Application } from '../CardApplication';
import { Profile } from '../CardProfile';
import { TOASTIFY_DURATION } from './styledComponents';

export const handleTitleEdit = async (
  updatedTitle: string,
  dispatch,
  activeConcept: Application,
  user: Profile,
  setIsEditingTitle: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (user) {
    setIsEditingTitle((pre) => !pre);
    const data = {
      title: updatedTitle,
      isTitleEdited: true
    };
    dispatch(
      Actions.setLoginUser({
        ...user,
        activeConcept: { ...activeConcept, ...data }
      })
    );
    dispatch(Actions.updateConcept(activeConcept.id, data));
  } else {
    dispatch(Actions.openAuthModal(true));
  }
};

export const handleClickMintPatent = (
  user: Profile,
  dispatch,
  hasSolsInActiveConcept: boolean,
  setOpenPatentModal: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (!user) {
    dispatch(Actions.openAuthModal(true));
    return;
  }

  if (!hasSolsInActiveConcept) {
    toastify(
      Constants.ADD_MORE_SOLUTIONS,
      VARIANT.ERROR,
      VARIANT.BOTTOM_LEFT,
      TOASTIFY_DURATION
    );
    return;
  }
  setOpenPatentModal(true);
};

export const getAnswerFromAI = async (dispatch, activeConcept) => {
  if (activeConcept?.solutions.length !== 0) {
    dispatch(Actions.generateTitle(activeConcept.id));
    toastify(
      `Image for the concept "${activeConcept.title}" is being generated by AI.`,
      VARIANT.INFO,
      VARIANT.BOTTOM_LEFT,
      TOASTIFY_DURATION
    );
  } else {
    toastify(
      Constants.Add_ONE_SOLUTION,
      VARIANT.ERROR,
      VARIANT.BOTTOM_LEFT,
      TOASTIFY_DURATION
    );
  }
};
