import dataProvider from 'dataPrvider';

export const Api = {
  distributeReward: async () => {
    try {
      const response = dataProvider.distributeReward();
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
  getRewardPoolThreshold: async () => {
    try {
      return dataProvider.getRewardPoolThreshold();
    } catch (error) {
      throw new Error(error);
    }
  }
};
