import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colorPalette } from 'theme';

export const TitleText = styled(Typography)({
  color: colorPalette.purple,
  cursor: 'pointer',
  fontSize: '0.8125rem',
  fontWeight: 700,
  textDecoration: 'underline',
  textDecorationColor: colorPalette.purple,
  whiteSpace: 'nowrap'
});

export const MainContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  gap: '8px'
});
