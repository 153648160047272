import React, { useEffect, useMemo, useState } from 'react';
import { Box, Grid, LinearProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { GridProps } from '@mui/system';
import ProductIcon from 'assets/icons-new/product.svg';
import CommunityIcon from 'assets/icons/communities.svg';
import InventionsIcon from 'assets/icons/invention.svg';
import ProblemIcon from 'assets/icons/problems.svg';
import SolutionIcon from 'assets/icons/solutions.svg';
import { Profile } from 'authProvider';
import { CardCommunity } from 'components/CardCommunity/CardCommunity';
import CommunityTabsView from 'components/CommunityTabsView';
import InventionView from 'components/CommunityViews/InventionView';
import NewsfeedView from 'components/CommunityViews/NewsfeedView';
import ProblemView from 'components/CommunityViews/ProblemView';
import ProductView from 'components/CommunityViews/ProductView';
import SolutionView from 'components/CommunityViews/SolutionView';
import Helmet from 'components/Helmet';
import TopBar from 'components/TopBar';
import dataProvider from 'dataPrvider';
import { isObjectEmpty } from 'helpers';
import useRouter from 'hooks/useRouter';
import { SideMenu } from 'layout';
import { useDispatch } from 'react-redux';
import Actions from 'redux-state/actions';
import { GetLoader, GetTag } from 'redux-state/commons/selectors';
import { Constants } from 'utilities/constants';
import { colorPalette, useIsMediumScreen } from '../../theme';

const Community = () => {
  const tag = GetTag();
  const loader = GetLoader();
  const [owner, setOwner] = useState<Profile | undefined>(undefined);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: profileData } = await dataProvider.getOneByKey<Profile>(
          'profiles',
          { key: tag.owner as string }
        );
        setOwner(profileData);
      } catch (error) {
        console.error(error);
      }
    };

    if (tag?.owner) {
      fetchData();
    }
  }, [tag]);

  const dispatch = useDispatch();
  const router = useRouter();
  const { key } = router.query;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const paginationOptions = useMemo(
    () => ({
      page: page,
      rowsPerPage: rowsPerPage,
      setPage: setPage,
      setRowsPerPage: setRowsPerPage
    }),
    [page, rowsPerPage, setRowsPerPage, setPage]
  );

  const pagination = useMemo(
    () => ({
      page: page,
      perPage: rowsPerPage
    }),
    [page, rowsPerPage]
  );

  useEffect(() => {
    dispatch(Actions.getTag(key as string));
  }, [dispatch, key]);

  const tabs = [
    {
      title: 'Feeds',
      iconUrl: CommunityIcon,
      content: <NewsfeedView />
    },
    {
      title: 'Problems',
      iconUrl: ProblemIcon,
      content: (
        <ProblemView
          profile={owner}
          paginationOptions={paginationOptions}
          tag={tag}
        />
      )
    },
    {
      title: 'Solution',
      iconUrl: SolutionIcon,
      content: (
        <SolutionView profile={owner} paginationOptions={paginationOptions} />
      )
    },
    {
      title: 'Inventions',
      iconUrl: InventionsIcon,
      content: (
        <InventionView profile={owner} paginationOptions={paginationOptions} />
      )
    },
    {
      title: 'Products',
      iconUrl: ProductIcon,
      content: (
        <ProductView profile={owner} paginationOptions={paginationOptions} />
      )
    }
  ];

  const isMediumScreen = useIsMediumScreen();

  return (
    <>
      {!isObjectEmpty(tag) ? (
        <>
          {!isMediumScreen && (
            <SideMenu active={Constants.ALL} isHomePage={false} />
          )}
          <TopBar
            title={tag?.name}
            description={tag?.body}
            tag={tag}
            pagination={pagination}
          />
          <Helmet title="Communities" />
          <StyledContainer isMediumScreen={isMediumScreen}>
            <StyledCardContainer>
              <MainContainer isMediumScreen={isMediumScreen}>
                <CardCommunity tag={tag} />
                <CommunityTabsView tabs={tabs} initialTab="Feeds" />
              </MainContainer>
            </StyledCardContainer>
          </StyledContainer>
        </>
      ) : (
        <LinearProgress value={10} />
      )}
    </>
  );
};

interface StyledGridProps extends GridProps {
  isMediumScreen: boolean;
}

interface StyledGridProps extends GridProps {
  isMediumScreen: boolean;
}

const StyledContainer = styled(Box)<StyledGridProps>(({ isMediumScreen }) => ({
  background: colorPalette.ashWhite,
  display: 'flex',
  flexDirection: 'column',
  height: 'auto',
  padding: isMediumScreen ? null : '0px 0px 48px 80px',
  width: '100%'
}));

const StyledCardContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap'
});

const MainContainer = styled(Grid)<StyledGridProps>(({ isMediumScreen }) => ({
  margin: isMediumScreen ? '0 auto' : '0 5% 0 5%',
  width: isMediumScreen ? '95%' : '100%'
}));

export default Community;
