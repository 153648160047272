import { combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { reduxBatch } from '@manaflair/redux-batch';

import {
  persistStore,
  persistReducer,
  createMigrate,
  createTransform
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { Concept } from 'components/CardProduct';
import { Contest } from 'components/CardContest';
import { Problem } from 'components/CardProblem';
import { GetListResult } from 'dataPrvider';
import { Node } from 'interface/common';
import commonsReducer from './commons/reducer';
import contestViewReducer from './contestView/reducer';
import homeReducer from './home/reducer';
import ideamapReducer from './ideamap/reducer';
import onboardingReducer from './onboarding/reducer';
import profileReducer from './profile/reducer';
import rewardsReducer from './rewards/reducer';
import shareReducer from './share/reducer';
import mySaga from './sagas';

export interface RootState {
  ideamap: {
    aiGenerationLoader: boolean;
    circularProgressValue: number;
    circularProgressValueForProduct: { productId: string; vlaue: number };
    concept: Concept;
    contestsLoader: boolean;
    graph: Node;
    imageGenerationLoader: boolean;
    imageUploadLoader: boolean;
    isConceptCartOpen: boolean;
    isSideDrawerOpen: boolean;
    newConceptLoader: boolean;
    solutionLoader: boolean;
    selectedItem: Node;
    updateConceptLoader: boolean;
    getConceptsLoader: boolean;
  };
  contestView: {
    contest: Contest;
    contests: Array<Contest>;
    generatedCoverImage: string;
    generatedCoverImageLoading: boolean;
    generatedDescription: string;
    generatedDescriptionLoading: boolean;
    generatedImage: string;
    generatedImageLoading: boolean;
    leaderboardLoader: boolean;
    problems: Problem[];
    solutions: GetListResult;
  };
  rewards: {
    distributeRewardLoading: boolean;
  };
  share: {
    shareStatus: null;
  };
}

const sagaMiddleware = createSagaMiddleware();

const migrations = {
  0: (state: any) => ({
    ...state
  }),
  1: (state: any) => ({
    ...state,
    ideamap: {
      aiGenerationLoader: false,
      circularProgressValue: 0,
      circularProgressValueForProduct: {},
      concept: {},
      contestsLoader: false,
      graph: undefined,
      imageGenerationLoader: false,
      imageUploadLoader: false,
      isConceptCartOpen: false,
      isSideDrawerOpen: false,
      newConceptLoader: false,
      solutionLoader: false,
      selectedItem: null,
      updateConceptLoader: false,
      getConceptsLoader: false
    },
    commons: {
      toggleSubscriptionModal: false,
      toggleCreditsTopUpModal: false
    },
    home: {
      companies: [],
      loading: false,
      products: [],
      tags: [],
      tagsCount: 0,
      tagsLoader: false
    },
    userProfile: {
      agreements: [],
      authStatus: '',
      concepts: [],
      contests: [],
      deployNFTLoader: false,
      editItemLoader: false,
      eFilePatentLoading: false,
      error: false,
      errorItemId: null,
      errorMessage: '',
      getAgreementLoading: false,
      getConceptsLoading: false,
      getContestsLoading: false,
      getInventionsLoading: false,
      getProblemLoading: false,
      getProductsLoading: false,
      getNotificationsLoading: false,
      getRewardHistoryLoading: false,
      getSolutionLoading: false,
      getUserRankingLoading: false,
      inventions: [],
      notifications: [],
      nftDeployFinishLoader: false,
      nftDeployStartLoader: false,
      nftDeployWithMetamaskLoader: false,
      pinnedConcepts: [],
      pinnedContests: [],
      pinnedInventions: [],
      pinnedProblems: [],
      pinnedSolutions: [],
      problems: [],
      products: [],
      rewardHistory: [],
      solutions: [],
      statusCode: null,
      tokenURI: '',
      totalAgreements: 0,
      totalConcepts: 0,
      totalContests: 0,
      totalInventions: 0,
      totalProblems: 0,
      totalProducts: 0,
      totalReward: 0,
      totalSolutions: 0,
      txApprovalModalObj: {
        gasFee: 0,
        open: false,
        type: ''
      },
      userRank: 0,
      unreadNotificationCount: 0
    },
    onboarding: {
      getSocialAuthKeys: [],
      getSocialAuthKeysLoading: false,
      resetPasswordLoading: false,
      resetPasswordStatus: '',
      signInLoading: false,
      signUpLoading: false,
      loginUser: null
    }
  }),
  2: (state: any) => ({
    ...state,
    ideamap: {
      activeConcept: {}
    }
  })
};

const combineReducer = combineReducers({
  commons: commonsReducer,
  contestView: contestViewReducer,
  home: homeReducer,
  ideamap: ideamapReducer,
  onboarding: onboardingReducer,
  rewards: rewardsReducer,
  share: shareReducer,
  userProfile: profileReducer
});

const subsetTransform = createTransform(
  // Transform state on its way to being serialized and persisted
  (inboundState: any) => ({
    getSocialAuthKeys: inboundState.getSocialAuthKeys,
    loginUser: inboundState.loginUser,
    resetPasswordStatus: inboundState.resetPasswordStatus
  }),
  // Transform state being rehydrated
  (outboundState) => ({
    ...outboundState // Rehydrate only what was persisted
  }),
  { whitelist: ['onboarding'] } // Apply to specific reducers
);

const persistConfig = {
  key: 'root',
  blacklist: [
    'commons',
    'contestView',
    'rewards',
    'home',
    'userProfile',
    'share'
  ],
  migrate: createMigrate(migrations, { debug: false }),
  storage,
  version: 0,
  transforms: [subsetTransform]
};
const rootReducer = persistReducer(persistConfig, combineReducer);

const middleware = [
  ...getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    thunk: true
  }),
  sagaMiddleware
];

const enhancers: any = [reduxBatch];

const store: any = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  enhancers,
  middleware,
  reducer: rootReducer
});

export const persistor = persistStore(store);

export default store;

sagaMiddleware.run(mySaga);
