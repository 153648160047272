import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CachedOutlined } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { Problem } from 'components/CardProblem';
import { Product } from 'components/CardProduct';
import { Solution } from 'components/CardSolution';
import { Tag, TagInfo } from 'components/CardTag';
import {
  FromBox,
  NoTransactionText,
  ProgressWrapper,
  RefreshIcon,
  StyledArrowInwards,
  StyledArrowOutward,
  StyledLink,
  StyledMainBox,
  StyledTypography
} from 'components/MyWallet/styledComponents';
import TableView from 'components/TableView';
import { getHeaders } from 'components/TableView/getHeaders';
import Config from 'config/config';
import { ethers } from 'ethers';
import { getTransactions } from 'helpers/blockchain';
import { GetUser } from 'redux-state/selectors';
import { ASSET_TYPES, Constants } from 'utilities/constants';

export interface Transaction {
  block_timestamp: string;
  body?: string;
  from_address: string;
  hash: string;
  id?: string | number;
  ideaPoints?: number;
  isFiled?: boolean;
  isLocked?: boolean;
  nftTransactionUrl?: string;
  parentProduct?: Product;
  parentProductTitle?: string;
  problem?: Problem | Array<Problem>;
  problems?: Array<Problem>;
  solutions?: Array<Solution>;
  status?: string;
  tagsInfo?: Array<TagInfo> | Array<Tag>;
  to_address: string;
  value: string;
}

interface TransactionViewProps {
  refresh: number;
  walletType: string;
}

export const TransactionsView: React.FC<TransactionViewProps> = ({
  refresh,
  walletType
}) => {
  const user = GetUser();
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchTransactions = useCallback(() => {
    setLoading(true);
    getTransactions(user.walletAddress, walletType)
      .then((response) => {
        let transactions = response.result;
        if (walletType === ASSET_TYPES.MATIC) {
          transactions = transactions.filter((transaction) => {
            const value = ethers.utils.parseEther(transaction.value);
            return !value.isZero();
          });
        }
        setTransactions(transactions);
      })
      .catch((error) => {
        console.log({ error });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [user.walletAddress, walletType]);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions, refresh]);

  const formatString = (str) => {
    if (str) {
      const lower = str.toLowerCase();
      const start = lower.slice(0, 7);
      const end = lower.slice(-4);
      return `${start}..${end}`;
    }
  };

  const getCells = useMemo(() => {
    return (icon, item) => {
      const transactionHashKey =
        walletType === ASSET_TYPES.MATIC
          ? Constants.MATIC_HASH
          : Constants.IDEACOIN_HASH;
      const transactionHash = item[transactionHashKey];
      const baseCells = {
        hash: (
          <StyledLink
            href={`${Config.SEPOLIA_BASE_URL}/${Constants.TX}/${transactionHash}`}
            target={Constants._BLANK}
            rel={Constants.NO_OPENER_NO_REFFERRER}
          >
            {formatString(transactionHash)}
          </StyledLink>
        ),
        from: (
          <FromBox>
            <StyledLink
              href={`${Config.SEPOLIA_BASE_URL}/${Constants.ADDRESS}/${item.from_address}`}
              target={Constants._BLANK}
              rel={Constants.NO_OPENER_NO_REFFERRER}
            >
              {`${formatString(item.from_address)} `}
            </StyledLink>
            {item.from_address.toLowerCase() ===
            user.walletAddress.toLowerCase() ? (
              <StyledArrowOutward />
            ) : (
              <StyledArrowInwards />
            )}
          </FromBox>
        ),
        to: (
          <StyledLink
            href={`${Config.SEPOLIA_BASE_URL}/${Constants.ADDRESS}/${item.to_address}`}
            target={Constants._BLANK}
            rel={Constants.NO_OPENER_NO_REFFERRER}
          >
            {formatString(item.to_address)}
          </StyledLink>
        ),
        value: (
          <StyledTypography>{`${ethers.utils.formatEther(
            item.value
          )}`}</StyledTypography>
        ),
        timestamp: (
          <StyledTypography>
            {new Date(item.block_timestamp).toLocaleString()}
          </StyledTypography>
        )
      };
      return baseCells;
    };
  }, [user.walletAddress, walletType]);

  const rows = {
    component: (icon, item) => getCells(icon, item),
    items: transactions,
    pinnedItems: []
  };

  const headers: Array<string> = useMemo(() => {
    return getHeaders(Constants.ACCOUNT_TRANSACTIONS, user, true);
  }, [user]);

  const shouldShowTransactions = !loading && transactions.length > 0;
  const shouldShowNoTransactions = !loading && transactions.length === 0;

  return (
    <StyledMainBox>
      {loading && (
        <ProgressWrapper>
          <CircularProgress />
        </ProgressWrapper>
      )}
      {shouldShowNoTransactions && (
        <NoTransactionText>{Constants.NO_TRANSACTION_YET}</NoTransactionText>
      )}
      {shouldShowTransactions && (
        <>
          <RefreshIcon onClick={fetchTransactions}>
            <CachedOutlined />
          </RefreshIcon>
          <TableView
            headers={headers}
            rows={rows}
            showPaginations={false}
            hasWalletFixedCellWidth={true}
          />
        </>
      )}
    </StyledMainBox>
  );
};
