import React from 'react';
export const InventionBulbIcon = ({
  size = 30,
  color = '#C77312',
  className
}: {
  size?: number;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <clipPath id="InventionBulbIcon">
          <path
            fill="#c77312"
            d="M0 0h14.349v20.737H0z"
            data-name="Rectangle 710"
          />
        </clipPath>
      </defs>
      <g data-name="Group 408" transform="translate(-1387 -461)">
        <circle
          cx="15"
          cy="15"
          r="15"
          fill="currentColor"
          data-name="Ellipse 8"
          transform="translate(1387 461)"
        />
        <g data-name="Group 89">
          <g
            fill={color}
            clipPath="url(#InventionBulbIcon)"
            data-name="Group 67"
            transform="translate(1394.826 465.631)"
          >
            <path
              d="M7.177 0a7.174 7.174 0 0 0-4.1 13.062l.115.081v2.545a.8.8 0 0 0 .8.795h6.38a.8.8 0 0 0 .795-.8v-2.541l.115-.081A7.174 7.174 0 0 0 7.177 0m4.59 10.354a5.556 5.556 0 0 1-1.4 1.4l-.79.557v2.576H4.779v-2.571l-.79-.557a5.588 5.588 0 1 1 7.778-1.4"
              data-name="Path 271"
            />
            <path
              d="M5.05 20.741h4.254a.8.8 0 0 0 .795-.8v-.793H4.256v.794a.8.8 0 0 0 .8.795"
              data-name="Path 272"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
