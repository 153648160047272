import { Button, styled, Typography } from '@mui/material';
import { colorPalette } from 'theme';

export const StyledTypography = styled(Typography)<{ fontSize?: string }>(
  ({ fontSize = '1rem' }) => ({
    color: colorPalette.white,
    fontSize: fontSize,
    fontWeight: '600'
  })
);

export const StyledButton = styled(Button)<{
  btnColor?: string;
  width?: string;
}>(({ btnColor, width }) => ({
  backgroundColor: btnColor,
  color: btnColor,
  textTransform: 'none',
  width: width,
  '&:hover': {
    backgroundColor: btnColor
  }
}));
