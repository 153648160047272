import { Box, Button, Grid, styled, Accordion } from '@mui/material';
import { colorPalette } from 'theme';

export const MainBox = styled(Box)({
  flexWrap: 'wrap',
  justifyContent: 'center',
  marginTop: '1rem'
});

export const ViewStatsButton = styled(Button)({
  background: 'white',
  border: 'none',
  borderRadius: '0rem 0rem 0.5rem 0.5rem',
  color: colorPalette.purple,
  justifyContent: 'center',
  marginBottom: '0.625rem',
  paddingLeft: '1.5625rem',
  paddingRight: '1.25rem'
});

export const StyledBlock = styled(Box)({
  alignItems: 'center',
  background: colorPalette.white,
  borderRadius: 8,
  display: 'flex',
  flexDirection: 'row',
  gap: '0.625rem',
  justifyContent: 'space-between',
  padding: '1.3125rem 1.875rem',
  width: '100%'
});

export const GridItem = styled(Grid)({
  padding: 0
});

export const MainAccordionContainer = styled(Grid)({
  margin: '0 auto',
  width: '100%'
});

export const StyledAccordionContainer = styled(Accordion)(({ expanded }) => ({
  borderRadius: '0rem 0rem 0.9375rem 0.9375rem',
  color: colorPalette.purple,
  display: expanded ? 'block' : 'none',
  marginBottom: '-0.9375rem'
}));

export const StyledBox = styled(Box)({
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center'
});
