import React, { useContext, useCallback } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { PsTheme } from '../../theme';
import { Votes } from 'components/Votes';
import { Profile } from '../../authProvider';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root' | 'child' | 'votes';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: 'auto',
      paddingTop: 20,
      [psTheme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'stretch',
        '&.row': {
          flexDirection: 'row',
          alignItems: 'center'
        }
      }
    },
    child: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'flex-end',
      '& > *': {
        marginBottom: 10
      }
    },
    votes: {
      margin: '0 10px',
      [psTheme.breakpoints.down('xs')]: {
        justifyContent: 'flex-end'
      }
    }
  };
});

type CardActionsProps = {
  children?: React.ReactNode;
  className?: string;
  resource: string;
  resourceId: string | number;
  likes: number;
  dislikes: number;
  user?: Profile;
  row?: boolean;
};

const CardActionsView = ({
  className,
  resource,
  resourceId,
  likes,
  dislikes,
  children,
  user,
  row
}: CardActionsProps) => {
  const { classes } = useStyles();
  let rootClassName = classes.root;
  if (className) {
    rootClassName += ' ' + className;
  }
  if (row) {
    rootClassName += ' row';
  }

  if (!children) {
    return null;
  }

  return (
    <div className={rootClassName}>
      <div className={classes.child}>{children}</div>
      {resource ? (
        <Votes
          className={classes.votes}
          resource={resource}
          itemId={resourceId}
          likes={likes}
          dislikes={dislikes}
          user={user}
        />
      ) : null}
    </div>
  );
};

export const CardActions = styled(CardActionsView)({});

export default CardActions;
