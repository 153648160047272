import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from 'react';
import Select, { components, StylesConfig, ActionMeta } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { colorPalette } from 'theme';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface OptionType {
  label: string;
  value: number;
  __isNew__?: boolean;
}

const customStyles: StylesConfig<OptionType, true> = {
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: colorPalette.mintCream,
    borderRadius: '1.25rem',
    padding: '0 0.5rem'
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: colorPalette.lightGreen,
    padding: '.625rem'
  }),
  singleValue: (provided) => ({
    ...provided,
    color: colorPalette.lightGreen
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: colorPalette.grey,
    ':hover': {
      backgroundColor: 'transparent',
      cursor: 'pointer',
      color: colorPalette.grey
    }
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderColor: state.isFocused ? colorPalette.purple : colorPalette.lightGrey,
    boxShadow: '0px 0px 16px 0px #00000014',
    border: '1px solid colorPalette.lightGrey',
    padding: '0.5rem 0',
    ':hover': {
      borderColor: colorPalette.purple
    }
  })
};

const MAX_VISIBLE_OPTIONS = 5;

interface PsSearchSelectProps {
  createableValue?: any;
  formatCreateLabel?: (inputValue: string) => ReactNode;
  handleInputChange?: (value: string) => void;
  inputValue?: string;
  isLoading?: boolean;
  isMulti?: true | undefined;
  name?: string;
  onChange?: (
    newValue: OptionType[] | null,
    actionMeta: ActionMeta<OptionType>
  ) => void;
  onCreateableChange?: any;
  onCreateOption?: (inputValue: string) => void;
  optionsList: any;
  page?: number;
  placeholder?: string;
  setInputValue?: React.Dispatch<React.SetStateAction<string>>;
  setPage?: Dispatch<SetStateAction<number>>;
  value?: any;
  setSelectedValue?: React.Dispatch<React.SetStateAction<Array<OptionType>>>;
  resetOptions?: boolean;
  isProfile?: boolean;
}

const PsSearchSelect: React.FC<PsSearchSelectProps> = ({
  createableValue,
  formatCreateLabel,
  handleInputChange,
  inputValue,
  isLoading,
  isMulti,
  name,
  onChange,
  onCreateableChange,
  onCreateOption,
  optionsList,
  page,
  placeholder,
  resetOptions,
  setInputValue,
  setPage,
  setSelectedValue,
  value,
  isProfile
}) => {
  const [options, setOptions] = useState<OptionType[]>([]);

  useEffect(() => {
    if (resetOptions) {
      setOptions([]);
    }
  }, [resetOptions]);

  useEffect(() => {
    if (optionsList) {
      const transformedOptions = optionsList.map((option) => ({
        value: option.id,
        label: option.name
      }));

      setOptions((prevOptions) => {
        const uniqueOptions = new Set();

        const mergedOptions = [...prevOptions, ...transformedOptions].filter(
          (option) => {
            if (option.label && !uniqueOptions.has(option.value)) {
              uniqueOptions.add(option.value);
              return true;
            }
            return false;
          }
        );

        return mergedOptions;
      });
    }
  }, [optionsList]);

  const handleMenuScrollToBottom = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const CustomMultiValue = ({ index, getValue, ...props }: any) => {
    const maxToShow = MAX_VISIBLE_OPTIONS;
    const overflowItems = getValue().slice(maxToShow);

    const handleRemove = (event, option: OptionType) => {
      event.preventDefault();
      event.stopPropagation();
      setSelectedValue((prevSelectedValues) =>
        prevSelectedValues.filter(
          (selectedValue) => selectedValue.value !== option.value
        )
      );
    };

    if (index < maxToShow) {
      return <components.MultiValue {...props} />;
    } else if (index === maxToShow) {
      return (
        <Tooltip
          title={
            <div
              style={{
                padding: '10px'
              }}
            >
              {overflowItems.map((item: OptionType) => (
                <div key={item.value} className="tag-list">
                  <Typography className="tag-content" variant="body2">
                    {item.label}
                  </Typography>
                  <IconButton
                    onClick={(e) => handleRemove(e, item)}
                    size="small"
                    style={{
                      marginRight: '.3125rem',
                      color: colorPalette.red,
                      border: '1px solid red'
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </div>
              ))}
            </div>
          }
          classes={{ tooltip: 'tooltip-class' }}
        >
          <div
            style={{
              alignItems: 'center',
              backgroundColor: '#DBF1E5',
              borderRadius: '20px',
              color: '#35A850',
              display: 'flex',
              padding: '10px',
              position: 'relative',
              cursor: 'pointer'
            }}
          >
            <span>{`+${overflowItems.length} more`}</span>
          </div>
        </Tooltip>
      );
    } else {
      return null;
    }
  };

  const SelectDropdownWrapper = isMulti || isProfile ? Select : CreatableSelect;

  const onBlur = useCallback(() => {
    if (setInputValue) {
      setInputValue('');
    }
  }, [setInputValue]);
  return (
    <SelectDropdownWrapper
      {...(!isMulti && { formatCreateLabel })}
      {...(!isMulti && { onCreateOption })}
      {...(isMulti && { isMulti })}
      components={{ MultiValue: CustomMultiValue }}
      control={''}
      controlDisabled={''}
      controlHasValue={''}
      controlOpened={''}
      dropdownIndicator={''}
      dropdownIndicatorOpened={''}
      inputValue={inputValue}
      isLoading={isLoading}
      name={name}
      onBlur={onBlur}
      onChange={isMulti || isProfile ? onChange : onCreateableChange}
      onInputChange={handleInputChange}
      onMenuScrollToBottom={handleMenuScrollToBottom}
      option={''}
      optionFocused={''}
      options={options}
      optionSelected={''}
      placeholder={placeholder}
      styles={customStyles}
      value={isMulti || isProfile ? value : createableValue}
    />
  );
};

export default PsSearchSelect;
