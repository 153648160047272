import React, { useCallback, useContext, useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { PsButton } from 'components/common/PsButton';
import {
  Card,
  CardImage,
  CardBody,
  CardTitle,
  CardOwner,
  CardText,
  CardActions
} from 'components/card';
import { Tags } from 'components/Tag';
import { ShareBtn } from 'components/ShareBtn';
import { OwnerInfo } from 'components/CardProfile';
import { TagInfo } from 'components/CardTag';
import { PsRecord } from '../../dataPrvider';
import { AuthContext } from 'contexts/AuthContext';
import { ModalContext } from 'contexts/ModalContext';
import ImproveModal from 'modals/ImproveModal';
import { Picture, getArticle, placeholderType } from '../../helpers';
import { PsTheme } from '../../theme';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root' | 'tags' | 'actionButton';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {},
    tags: {
      '& > div': {
        marginTop: 4,
        marginBottom: 4
      }
    },
    actionButton: {
      marginRight: 10
    }
  };
});

export type Product = PsRecord & {
  key: string;
  title?: string;
  body?: string;
  owner?: string | number;
  ownerInfo?: OwnerInfo;
  files?: Array<Picture>;
  createdAt?: string;
  company: string;
  companyKey: string;
  companyName: string;
  tags?: string[];
  tagsInfo?: Array<TagInfo>;
  isPublic: boolean;
  likes: number;
  dislikes: number;
  reward?: number;
};

export type CardProductProps = {
  product: Product;
};

const CardProductView = ({ product }: CardProductProps) => {
  const {
    id,
    key,
    title,
    body,
    owner,
    ownerInfo,
    company,
    companyName,
    createdAt,
    files,
    tagsInfo = [],
    likes,
    dislikes
  } = product;
  const { user } = useContext(AuthContext);
  const { classes } = useStyles();

  const image = files && files.length ? files[0] : undefined;
  const url = `/products/${key || id}`;
  const [isImproveModalOpen, setIsImproveModalOpen] = useState<boolean>(false);
  const [invemtionTitle, setInventionTitle] = useState<string>('');
  const [inventionId, setInventionId] = useState<string>('');
  const [inventionKey, setInventionKey] = useState<string>('');

  const onImproveClick = useCallback(() => {
    setIsImproveModalOpen(true);
    setInventionTitle(title);
    setInventionId(id.toString());
    setInventionKey(key);
  }, [id, key, title]);

  const titleText = (
    <>
      {title}, {getArticle(companyName)} <span>{companyName}</span> product
    </>
  );

  return (
    <Card>
      <CardImage href={url} image={image} type={placeholderType.A} />
      <CardBody>
        <CardTitle title={titleText} href={url}>
          <Tags className={classes.tags} tagsList={tagsInfo} />
        </CardTitle>
        <CardOwner
          ownerName={ownerInfo && ownerInfo.username}
          ownerKey={ownerInfo && ownerInfo.key}
          owner={owner}
          createdAt={createdAt}
        />
        <CardText>{body}</CardText>
        <CardActions
          resource="products"
          resourceId={id}
          likes={likes}
          dislikes={dislikes}
          user={user}
        >
          <>
            <PsButton
              className={classes.actionButton}
              onClick={onImproveClick}
              coins="up to 30"
            >
              Improve
            </PsButton>
            <ShareBtn product={product} tagsList={tagsInfo} />
          </>
        </CardActions>
      </CardBody>
      {isImproveModalOpen && (
        <ImproveModal
          isImproveModalOpen={isImproveModalOpen}
          productId={inventionId}
          productKey={inventionKey}
          setIsImproveModalOpen={setIsImproveModalOpen}
          shortTitle={invemtionTitle}
          title={invemtionTitle}
        />
      )}
    </Card>
  );
};

export const CardProduct = styled(CardProductView)({});

export default CardProduct;
