import ApplicationSelect from './steps/ApplicationSelect';

import ApplicationName, {
  defaultInventionTitle
} from 'modals/AddNewApplication/steps/ApplicationName';
import ApplicationTagsStep from 'modals/AddNewApplication/steps/ApplicationTagsStep';
import FinilizeApplicationStep from 'modals/AddNewApplication/steps/FinilizeApplicationStep';

import ProblemName, {
  defaultProblemTitle
} from 'modals/AddNewProblem/steps/ProblemName';
import ProblemTagsStep from 'modals/AddNewProblem/steps/ProblemTagsStep';
import FinilizeProblemStep from 'modals/AddNewProblem/steps/FinilizeProblemStep';

import SolutionName, {
  defaultSolutionTitle
} from 'modals/AddNewSolution/steps/SolutionName';
import SolutionTagsStep from 'modals/AddNewSolution/steps/SolutionTagsStep';
import FinilizeSolutionStep from 'modals/AddNewSolution/steps/FinilizeSolutionStep';

import { isTitleValid } from '../../helpers';
import {
  ValidateFormType,
  FormStepConfig,
  AttachInventionToSolutionSteps,
  AddApplicationSteps,
  AddProblemSteps,
  AddSolutionSteps
} from 'contexts/ModalDataContext';

export { defaultProblemTitle, defaultSolutionTitle, defaultInventionTitle };

export const stepName = {
  PROBLEM_START: 'problemStart',
  PROBLEM_END: 'problemEnd',
  SOLUTION_START: 'solutionStart',
  SOLUTION_END: 'solutionEnd',
  APPLICATION_START: 'applicationStart',
  APPLICATION_END: 'applicationEnd'
};

export const ADD_SOL_TO_INV_STEPS_CONFIG: {
  [name: string]: FormStepConfig;
} = {
  [AttachInventionToSolutionSteps.ApplicationSelect]: {
    Component: ApplicationSelect,
    isFinalStep: true,
    name: stepName.APPLICATION_START,
    modalTitle: `Add Product to Invention`,
    backButtonTitle: 'Cancel',
    nextStep: AddApplicationSteps.ApplicationName,
    isNextStepBlocked: (data) => {
      return !data.selectApplication;
    },
    validate: (data): ValidateFormType => {
      if (data.selectApplication) {
        return { success: true };
      }
      return { success: false, errors: { title: 'required' } };
    }
  },
  [AddApplicationSteps.ApplicationName]: {
    Component: ApplicationName,
    modalTitle: 'Add New Invention',
    nextStep: AddApplicationSteps.ApplicationTags,
    isNextStepBlocked: (data) => {
      return !data.titleApplication || !data.mainProblem;
    },
    validate: (data): ValidateFormType => {
      if (!data.titleApplication) {
        return {
          success: false,
          errors: { titleApplication: 'Title required' }
        };
      }
      if (
        data.titleApplication &&
        !isTitleValid(data.titleApplication, defaultInventionTitle)
      ) {
        return {
          success: false,
          errors: {
            titleApplication: `Title should starts with "${defaultInventionTitle}"`
          }
        };
      }
      if (!data.mainProblem) {
        return {
          success: false,
          errors: {
            parentProblem: 'required'
          }
        };
      }
      return { success: true };
    }
  },
  [AddApplicationSteps.ApplicationTags]: {
    modalTitle: 'Add New Invention',
    nextStep: AddApplicationSteps.FinilizeApplication,
    Component: ApplicationTagsStep,
    isNextStepBlocked: (data) => {
      return false;
    },
    validate: (data): ValidateFormType => {
      return { success: true };
    }
  },
  [AddApplicationSteps.FinilizeApplication]: {
    modalTitle: 'Add New Invention',
    Component: FinilizeApplicationStep,
    name: stepName.APPLICATION_END,
    nextButtonTitle: 'Finish',
    isNextStepBlocked: (data) => {
      return false;
    },
    validate: (data): ValidateFormType => {
      return { success: true };
    }
  },
  [AddProblemSteps.ProblemName]: {
    Component: ProblemName,
    nextStep: AddProblemSteps.ProblemTags,
    name: stepName.PROBLEM_START,
    modalTitle: 'Add New Problem',
    isNextStepBlocked: (data) => {
      return !data.titleProblem;
    },
    validate: (data): ValidateFormType => {
      if (!data.titleProblem) {
        return { success: false, errors: { titleProblem: 'Title required' } };
      }
      if (
        data.titleProblem &&
        !isTitleValid(data.titleProblem, defaultProblemTitle)
      ) {
        return {
          success: false,
          errors: {
            titleProblem: `Title should starts with "${defaultProblemTitle}"`
          }
        };
      }
      return { success: true };
    }
  },
  [AddProblemSteps.ProblemTags]: {
    Component: ProblemTagsStep,
    nextStep: AddProblemSteps.FinilizeProblem,
    modalTitle: 'Add New Problem',
    isNextStepBlocked: (data) => {
      if (data.tagsProblem.length === 0) return true;

      return false;
    },
    validate: (data): ValidateFormType => {
      if (data.tagsProblem) {
        return { success: true };
      }
      return { success: false, errors: { tags: 'required' } };
    }
  },
  [AddProblemSteps.FinilizeProblem]: {
    Component: FinilizeProblemStep,
    name: stepName.PROBLEM_END,
    modalTitle: 'Add New Problem',
    isNextStepBlocked: (data) => {
      return false;
    },
    validate: (data): ValidateFormType => {
      return { success: true };
    }
  },
  [AddSolutionSteps.SolutionName]: {
    Component: SolutionName,
    nextStep: AddSolutionSteps.SolutionTags,
    name: stepName.SOLUTION_START,
    modalTitle: 'Add New Solution',
    isNextStepBlocked: (data) => {
      if (
        !data.titleSolution ||
        (!data.parentProblem && !data.parentSolution)
      ) {
        return true;
      }
      if (data.finalizeType === 'public') {
        return !data.termsAgree;
      }
      return false;
    },
    validate: (data): ValidateFormType => {
      if (!data.titleSolution) {
        return { success: false, errors: { titleSolution: 'Title required' } };
      }
      if (
        data.titleSolution &&
        !isTitleValid(data.titleSolution, defaultSolutionTitle)
      ) {
        return {
          success: false,
          errors: {
            titleSolution: `Title should starts with "${defaultSolutionTitle}"`
          }
        };
      }
      if (data.finalizeType === 'public' && !data.termsAgree) {
        return {
          success: false,
          errors: {
            termsAgree: 'required'
          }
        };
      }
      if (!data.titleSolution && !data.parentProblem && !data.parentSolution) {
        return {
          success: false,
          errors: {
            title: 'required',
            parentProblem: 'required',
            parentSolution: 'required'
          }
        };
      } else if (!data.parentProblem && !data.parentSolution) {
        return {
          success: false,
          errors: { parentProblem: 'required', parentSolution: 'required' }
        };
      }
      return { success: true };
    }
  },
  [AddSolutionSteps.SolutionTags]: {
    Component: SolutionTagsStep,
    nextStep: AddSolutionSteps.FinilizeSolution,
    modalTitle: 'Add New Solution',
    isNextStepBlocked: (data) => {
      if (data.tagsSolution.length === 0) return true;

      return false;
    },
    validate: (data): ValidateFormType => {
      if (data.tagsSolution) {
        return { success: true };
      }
      return { success: false, errors: { tags: 'required' } };
    }
  },
  [AddSolutionSteps.FinilizeSolution]: {
    Component: FinilizeSolutionStep,
    name: stepName.SOLUTION_END,
    modalTitle: 'Add New Solution',
    isNextStepBlocked: (data) => {
      return false;
    },
    validate: (data): ValidateFormType => {
      return { success: true };
    }
  }
};
