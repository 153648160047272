/* eslint-disable max-lines-per-function */
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState
} from 'react';
import { useDispatch } from 'react-redux';
import { CancelOutlined } from '@mui/icons-material';
import { Application } from 'components/CardApplication';
import { OverviewConcept } from 'components/OverviewConcept';
import SimpleIconButton from 'components/SimpleIconButton';
import { SolutionsRelationship } from 'components/SolutionsRelationship';
import config from 'config/config';
import { toggleCreditsModal } from 'helpers/common';
import { useCheckCredits } from 'hooks/useCheckCredits';
import { toastify } from 'pages/newContests/toastify';
import Actions from 'redux-state/actions';
import { GetUser } from 'redux-state/selectors';
import {
  Breakpoints,
  Constants,
  ERRORS,
  TOASTIFY_DURATION,
  VARIANT
} from 'utilities/constants';
import { BaseModal } from '../Common/BaseModal';
import {
  CloseIconWrapper,
  HeaderBox,
  HeaderText,
  MainContainer,
  NextStepText,
  StyledDivider,
  StyledFooterBox,
  StyledSaveButton
} from './styledComponents';

const API_COSTS = config.API_COSTS;

interface ContentComponentProps {
  archiveStatus?: boolean;
  beforeClose?: () => void;
  concept: Application;
  isConceptActive?: boolean;
  isFormValid?: boolean;
  onTaskComplete?: () => void;
  pagination?: { page: number; perPage: number };
  profileId: string | number;
  setConceptUpdateObj?: Dispatch<SetStateAction<unknown>>;
  setIsFormValid?: Dispatch<SetStateAction<boolean>>;
  setIsNextStepBlocked?: Dispatch<SetStateAction<boolean>>;
  setOpenNextModal?: () => void;
  setSaveCallback?: Dispatch<React.SetStateAction<(() => void) | undefined>>;
}

export const COMP_NAMES = {
  RELATIONSHIP: 'Relationship',
  OVERVIEW: 'Overview'
};

export type ContentComponentConfig = {
  Component: React.FC<ContentComponentProps>;
  nextStep?: string;
  nextStepText?: string;
  title: string;
};

export const CONTENT_COMPONENTS: {
  [name: string]: ContentComponentConfig;
} = {
  [COMP_NAMES.OVERVIEW]: {
    Component: OverviewConcept,
    nextStep: COMP_NAMES.RELATIONSHIP,
    nextStepText: Constants.UPDATE_SOLUTIONS_RELATIONSHIP,
    title: Constants.EDIT_CONCEPT
  },
  [COMP_NAMES.RELATIONSHIP]: {
    Component: SolutionsRelationship,
    nextStep: COMP_NAMES.OVERVIEW,
    nextStepText: Constants.EDIT_CONCEPT,
    title: Constants.UPDATE_SOLUTIONS_RELATIONSHIP
  }
};

interface EditConceptModalProps {
  concept: Application;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const EditConceptModal: React.FC<EditConceptModalProps> = ({
  concept,
  open,
  setOpen
}) => {
  const user = GetUser();
  const activeConcept = useMemo(
    () => user?.activeConcept || {},
    [user?.activeConcept]
  );
  const [currentStep, setCurrentStep] = useState<string | undefined>(
    COMP_NAMES.OVERVIEW || ''
  );
  const [conceptUpdateObj, setConceptUpdateObj] = useState({});
  const [isNextStepBlocked, setIsNextStepBlocked] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [childSaveCallback, setChildSaveCallback] = useState<
    (() => void) | undefined
  >();

  const dispatch = useDispatch();

  const { Component, nextStep, nextStepText, title } =
    CONTENT_COMPONENTS[currentStep || ''];
  const ContentComponent = Component;
  const hasSufficientCredits = useCheckCredits(API_COSTS.CREATE_PROBLEM);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleAction = useCallback(() => {
    if (!user) dispatch(Actions.openAuthModal(true));
    if (isNextStepBlocked) return;
    if (currentStep === COMP_NAMES.OVERVIEW) {
      dispatch(
        Actions.setLoginUser({
          ...user,
          activeConcept: { ...activeConcept, ...conceptUpdateObj }
        })
      );
      dispatch(Actions.updateConcept(concept.id, conceptUpdateObj));
    } else if (currentStep === COMP_NAMES.RELATIONSHIP) {
      if (isFormValid && childSaveCallback) {
        childSaveCallback();
      }
    }

    handleClose();
  }, [
    activeConcept,
    childSaveCallback,
    concept.id,
    conceptUpdateObj,
    currentStep,
    dispatch,
    handleClose,
    isFormValid,
    isNextStepBlocked,
    user
  ]);

  const changeView = useCallback(() => {
    if (nextStep === COMP_NAMES.RELATIONSHIP) {
      if (user.credits < API_COSTS.GET_REL_PROMPT + API_COSTS.SEARCH_ANSWER) {
        toastify(
          ERRORS.NOT_ENOUGH_CREDITS,
          VARIANT.ERROR,
          VARIANT.BOTTOM_LEFT,
          TOASTIFY_DURATION
        );
        toggleCreditsModal(user, dispatch);
        return;
      }
    }
    setCurrentStep(nextStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextStep, user.credits]);

  if (!hasSufficientCredits()) {
    setOpen(false);
    return null;
  }

  return (
    <BaseModal open={open} onClose={handleClose} maxWidth={Breakpoints.SMALL}>
      <MainContainer>
        <HeaderBox>
          <HeaderText>{title}</HeaderText>
        </HeaderBox>
        <CloseIconWrapper>
          <SimpleIconButton
            color={VARIANT.PRIMARY}
            Icon={CancelOutlined}
            onClick={handleClose}
          />
        </CloseIconWrapper>
        <StyledDivider />
        <ContentComponent
          concept={concept}
          profileId={user?.id}
          setConceptUpdateObj={setConceptUpdateObj}
          setIsNextStepBlocked={setIsNextStepBlocked}
          setIsFormValid={setIsFormValid}
          isFormValid={isFormValid}
          isConceptActive={concept.id === activeConcept?.id}
          setSaveCallback={setChildSaveCallback}
        />
        <StyledDivider />
        <StyledFooterBox>
          <NextStepText onClick={changeView}>{nextStepText}</NextStepText>
          <StyledSaveButton onClick={handleAction}>
            {Constants.UPDATE}
          </StyledSaveButton>
        </StyledFooterBox>
      </MainContainer>
    </BaseModal>
  );
};
