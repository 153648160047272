import React from 'react';
import { Typography } from '@mui/material';
import { Constants } from 'utilities/constants';
import {
  StyledIdeaCoin,
  StyledLogo,
  StyledSubTypeContainer,
  StyledTYpography,
  StyledTypeImage
} from '../../StyledComponents';

interface SubTypesProps {
  coin: string;
  imagePath: string;
  onClick: () => void;
  title: string;
}

const SubTypes: React.FC<SubTypesProps> = ({
  coin,
  imagePath,
  onClick,
  title
}) => {
  return (
    <StyledSubTypeContainer onClick={onClick}>
      <Typography variant="h6">{title}</Typography>
      <StyledTypeImage src={imagePath} alt={title} />

      <StyledTYpography variant="body2">
        <StyledLogo src="/icons/mini-logo.svg" alt="mini-logo" />
        <StyledIdeaCoin>
          + {coin} {Constants.IDEA_COINS}
        </StyledIdeaCoin>{' '}
        {Constants.EARN}
      </StyledTYpography>
    </StyledSubTypeContainer>
  );
};

export default SubTypes;
