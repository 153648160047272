import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { PsButton } from 'components/common/PsButton';
import { Application } from 'components/CardApplication';
import {
  ModalDataContext,
  StepComponentType,
  StepComponentProps
} from 'contexts/ModalDataContext';
import { DataContext, PayProduct } from 'contexts/DataContext';
import { AuthContext } from 'contexts/AuthContext';
import styles from 'modals/ModalWindow.module.scss';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

export const productPayStatus = {
  PREPARE: 'prepare',
  PENDING: 'pending',
  PAYED: 'payed'
};

export const PayDocumentsStep: StepComponentType<StepComponentProps> = ({
  onNotify
}) => {
  const { values, updateField } = useContext(ModalDataContext);
  const { dataProvider } = useContext(DataContext);
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState('');

  const products: Array<PayProduct> = values.payProducts || [];
  const app = values.application as Application;

  const onPayClick = (product: PayProduct, test?: boolean) => {
    setLoading(`${product.productId}`);
    const appId = values.application ? values.application.id : '';
    dataProvider
      .payOptionPay(appId, product.productId, { test })
      .then((data) => {
        if (data.redirect && typeof window !== 'undefined') {
          window.open(data.redirect, '_self');
        }
        if (test && onNotify) {
          onNotify('products-refresh');
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading('');
      });
  };

  const onRefreshClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (onNotify) {
      onNotify('products-refresh');
    }
  };

  return (
    <div className={styles.stepWrapper}>
      {products.length ? (
        <Typography className={styles.text}>Pay next documents:</Typography>
      ) : (
        <Typography className={styles.text}>No documents to Pay</Typography>
      )}
      <table className={styles.signTable}>
        <tbody>
          {products.map((product) => {
            const onClick = (e: React.MouseEvent) => {
              e.preventDefault();
              onPayClick(product);
            };
            const onTestClick = (e: React.MouseEvent) => {
              e.preventDefault();
              onPayClick(product, true);
            };
            const isLoading = loading === product.productId;
            return (
              <tr key={product.productId}>
                <td className={styles.signTableTitle}>
                  {product.title || '(empty)'}
                </td>
                <td className={styles.signTableBtn}>
                  {product.payStatus === productPayStatus.PAYED ? (
                    <Typography color="secondary">Paid</Typography>
                  ) : product.payStatus === productPayStatus.PENDING ? (
                    <Typography color="secondary">Pending</Typography>
                  ) : (
                    <>
                      <PsButton
                        onClick={onClick}
                        color="secondary"
                        disabled={!!loading}
                        smallest
                      >
                        <CircularProgress
                          className={`${styles.signTableBtnLoader} ${
                            isLoading ? styles.signTableBtnLoaderActive : ''
                          }`}
                          size={20}
                          thickness={4}
                        />
                        <span
                          className={`${
                            isLoading ? styles.signTableBtnTextHide : ''
                          }`}
                        >
                          Pay
                        </span>
                      </PsButton>
                      &nbsp;
                      {user && user.isAdmin ? (
                        <PsButton
                          onClick={onTestClick}
                          color="secondary"
                          disabled={!!loading}
                          smallest
                        >
                          <CircularProgress
                            className={`${styles.signTableBtnLoader} ${
                              isLoading ? styles.signTableBtnLoaderActive : ''
                            }`}
                            size={20}
                            thickness={4}
                          />
                          <span
                            className={`${
                              isLoading ? styles.signTableBtnTextHide : ''
                            }`}
                          >
                            Test
                          </span>
                        </PsButton>
                      ) : null}
                    </>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {products.length ? (
        <Typography className={styles.textSmall}>
          <a onClick={onRefreshClick}>Refresh</a>
        </Typography>
      ) : null}
    </div>
  );
};

PayDocumentsStep.propTypes = {
  onNotify: PropTypes.func
};

export default PayDocumentsStep;
