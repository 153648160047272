import React, { useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { red } from '@mui/material/colors';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  return {
    root: {
      marginBottom: '32.5px'
    },
    media: {
      height: 0,
      paddingTop: '56.25%' // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest
      })
    },
    expandOpen: {
      transform: 'rotate(180deg)'
    },
    avatar: {
      backgroundColor: red[500]
    },
    button: {
      boxSizing: 'border-box',
      background: '#FFFFFF',
      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '31px'
    },
    headerActions: {
      margin: 8,
      padding: 0,
      cursor: 'pointer'
    },
    headerCard: {
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '22px',
      color: '#000000',
      paddingLeft: 20
    }
  };
});

interface Props {
  children?: React.ReactElement[] | React.ReactElement;
  open?: boolean;
  title: string;
}

export function ExpandCard({ children, title, open = false }: Props) {
  const { classes } = useStyles();
  const [expanded, setExpanded] = React.useState<boolean>(open);

  useEffect(() => {
    setExpanded(open);
  }, [open]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={`${classes.root} ${classes.button}`}>
      <CardActions
        className={classes.headerActions}
        disableSpacing
        onClick={handleExpandClick}
      >
        {title ? (
          <Typography className={classes.headerCard}>{title}</Typography>
        ) : null}
        <IconButton
          className={`${classes.expand} ${expanded ? classes.expandOpen : ''}`}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>{children} </CardContent>
      </Collapse>
    </Card>
  );
}

export default ExpandCard;
