import React, { useContext, useState, useCallback, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { PsFormControl, ShortEvent } from 'components/common/PsFormControl';
import { PsInput } from 'components/common/PsInput';
import { PsSelect, PsOptionType } from 'components/common/PsSelect';
import { Tag } from 'components/CardTag';
import { ModalDataContext } from 'contexts/ModalDataContext';
import { DataContext } from 'contexts/DataContext';
import { AuthContext } from 'contexts/AuthContext';
import styles from 'modals/ModalWindow.module.scss';
import { PsTheme } from '../../../theme';

type Problem = {
  id: string;
  title?: string;
};

type ClassKey = 'root';

const ContestNameView = () => {
  const { user } = useContext(AuthContext);
  const { dataProvider } = useContext(DataContext);
  const { values, updateField } = useContext(ModalDataContext);
  const [title, setTitle] = useState(values.titleContest);
  const [tag, setTag] = useState(values.contestTag);
  const [tags, setTags] = useState<Array<PsOptionType>>([]);

  useEffect(() => {
    if (user) {
      if (user.isAdmin || user.isChallengeOwner) {
        dataProvider
          .getList<Tag>('tags', {
            pagination: { page: 1, perPage: 50000 },
            sort: { field: 'name', order: 'ASC' },
            filter: {}
          })
          .then(({ data = [] }) => {
            const list: Array<PsOptionType> = data.map((item: Tag) => ({
              value: `${item.id}`,
              label: item.name || '(empty)'
            }));
            setTags(list);
          })
          .catch(() => {
            setTags([]);
          });
      } else if (user.userData && user.userData.moderator) {
        const tags = user.userData.moderator.map((item) => {
          return {
            value: `${item.id}`,
            label: item.name || '(empty)'
          };
        });
        setTags(tags);
      }
    }
  }, []);

  const onNameChange = useCallback(
    (e: ShortEvent) => {
      setTitle(e.target.value);
      updateField('titleContest', e.target.value);
    },
    [setTitle, updateField]
  );

  const onTagChange = useCallback(
    (e: ShortEvent) => {
      setTag(e.target.value);
      updateField('contestTag', e.target.value);
    },
    [setTag, updateField]
  );

  return (
    <div className={styles.stepWrapper}>
      <PsFormControl
        placeholder="Type your contest name here"
        label="Enter Contest Name"
        helperText="Example: The problem of the buildup of fluid energy to prevent time travel"
      >
        <PsInput name="titleContest" value={title} onChange={onNameChange} />
      </PsFormControl>

      <PsFormControl
        placeholder="Select appropriate tag for the contest"
        label="Select tag"
        helperText="Example: Hunger"
      >
        <PsSelect
          maxMenuHeight={270}
          name="contestTag"
          onChange={onTagChange}
          options={tags}
          value={tag}
        />
      </PsFormControl>
    </div>
  );
};

export const ContestName = styled(ContestNameView)({});

export default ContestName;
