import React, { useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import {
  Card,
  CardImage,
  CardBody,
  CardTitle,
  CardOwner,
  CardText,
  CardActions
} from 'components/card';
import { ShareBtn } from 'components/ShareBtn';
import { PsButton } from 'components/common/PsButton';
import { PsLogo } from 'components/common/PsLogo';
import { OwnerInfo } from 'components/CardProfile';
import { TagInfo } from 'components/CardTag';
import { PsRecord } from 'contexts/DataContext';
import { AuthContext } from 'contexts/AuthContext';
import { Picture, placeholderType } from '../../helpers';
import { PsTheme } from '../../theme';
import useRouter from 'hooks/useRouter';
import { makeStyles } from 'tss-react/mui';

export type Challenge = PsRecord & {
  key: string;
  title?: string;
  body?: string;
  header?: string;
  owner?: string | number;
  ownerInfo?: OwnerInfo;
  internalReward: number;
  externalReward: string;
  startDate: string;
  endDate: string;
  files?: Array<Picture>;
  files2?: Array<Picture>;
  createdAt?: string;
  likes: number;
  dislikes: number;
  tags?: string[];
  tagsInfo?: Array<TagInfo>;
};

type ClassKey = 'root' | 'tags' | 'tagsButton' | 'solveBtn';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {},
    tags: {
      '& > div': {
        marginTop: 4,
        marginBottom: 4
      }
    },
    tagsButton: {
      marginTop: 10
    },
    solveBtn: {
      padding: '10px 15px',
      marginRight: 10,
      borderRadius: 10,
      lineHeight: '26px',
      minHeight: 40,
      '& svg': {
        marginLeft: 15
      }
    }
  };
});

export type CardChallengeProps = {
  challenge: Challenge;
  isDetailed?: boolean;
};

const CardChallengeView = ({ challenge }: CardChallengeProps) => {
  const {
    id,
    key,
    title,
    body,
    owner,
    ownerInfo,
    createdAt,
    internalReward,
    files,
    likes,
    dislikes
  } = challenge;
  const { classes } = useStyles();
  const { user } = useContext(AuthContext);
  const router = useRouter();
  const image = files && files.length ? files[0] : undefined;
  const url = `/challenges/${key || id}`;

  const onSolveBtnClick = (e: React.MouseEvent) => {
    e.preventDefault();
    router.push(url);
  };

  return (
    <Card>
      <CardImage href={url} image={image} type={placeholderType.P} />
      <CardBody>
        <CardTitle title={title} href={url} />
        <CardOwner
          ownerName={ownerInfo && ownerInfo.username}
          ownerKey={ownerInfo && ownerInfo.key}
          owner={owner}
          createdAt={createdAt}
        />
        <CardText>{body}</CardText>
        <CardActions
          resource="challenges"
          resourceId={id}
          likes={likes}
          dislikes={dislikes}
          user={user}
        >
          <PsButton
            className={classes.solveBtn}
            color="secondary"
            onClick={onSolveBtnClick}
          >
            Earn {internalReward} IdeaCoin Jackpot
            <PsLogo size={25} color="white" small />
          </PsButton>
          <ShareBtn challenge={challenge} />
        </CardActions>
      </CardBody>
    </Card>
  );
};

export const CardChallenge = styled(CardChallengeView)({});

export default CardChallenge;
