import { styled } from '@mui/material/styles';
import { Dialog, Typography, IconButton, Box } from '@mui/material';
import { colorPalette } from 'theme'; // Assuming you have this in a separate file or in the same file

export const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    padding: '16px',
    borderRadius: '12px',
    width: '40%',
    boxShadow: colorPalette.boxShadowDarkGrey,
    backgroundColor: colorPalette.white
  }
});

export const StyledDialogTitle = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '1.2rem',
  fontWeight: 600,
  borderBottom: `1px solid ${colorPalette.alto}`,
  color: colorPalette.deepNavy,
  padding: '16px 16px 10px 16px'
});

export const StyledDialogContent = styled(Box)({
  paddingTop: '16px',
  padding: '16px',
  fontSize: '1rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  backgroundColor: colorPalette.white
});

export const StyledTypography = styled(Typography)({
  fontSize: '0.9rem',
  fontWeight: 400,
  color: colorPalette.darkGrey
});

export const StyledIconButton = styled(IconButton)({
  padding: '8px',
  color: colorPalette.darkShadowGrey
});
