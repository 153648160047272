import React from 'react';
import { FunctionComponent } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const ProblemsIcon: FunctionComponent<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props} viewBox="0 0 28 28">
    <path
      fill="currentColor"
      d="M14 2.2a11.8 11.8 0 0 0-8.344 20.144A11.8 11.8 0 0 0 22.344 5.656 11.723 11.723 0 0 0 14 2.2M14 0A14 14 0 1 1 0 14 14 14 0 0 1 14 0Z"
      data-name="Ellipse 67 - Outline"
    />
    <text
      fill="currentColor"
      data-name="?"
      fontSize="21"
      fontWeight="500"
      transform="translate(14 22)"
    >
      <tspan x="-6.016" y="0">
        ?
      </tspan>
    </text>
  </SvgIcon>
);
