import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { colorPalette } from 'theme';

interface HexPlusOutlinedIconProps {
  height?: string;
  width?: string;
  color?: string;
}

export const HexPlusOutlinedIcon: React.FC<HexPlusOutlinedIconProps> = ({
  height = '40px',
  width = '40px',
  color = colorPalette.darkGreen
}) => {
  return (
    <SvgIcon
      sx={{ width: width, height: height }}
      viewBox="0 0 24 26"
      fill="none"
    >
      <path
        d="M0 8.79632V17.0891C0.00121442 17.9048 0.218529 18.7056 0.629826 19.41C1.04112 20.1145 1.63171 20.6973 2.34149 21.0994L9.73696 25.284C10.4349 25.6704 11.2196 25.873 12.0174 25.873C12.8152 25.873 13.5998 25.6704 14.2978 25.284L21.6933 21.0994C22.4031 20.6973 22.9937 20.1145 23.4051 19.41C23.8165 18.7056 24.0339 17.9048 24.0353 17.0891V8.79632C24.0341 7.9806 23.8168 7.17975 23.4055 6.47531C22.9942 5.77088 22.4036 5.188 21.6938 4.78604L14.2983 0.600341C13.6025 0.206815 12.8168 0 12.0174 0C11.218 0 10.4322 0.206815 9.73645 0.600341L2.34045 4.78501C1.63075 5.18727 1.0403 5.77038 0.629188 6.47499C0.218076 7.1796 0.000981028 7.98055 0 8.79632ZM3.02453 5.99171L10.42 1.80705C10.9078 1.53279 11.458 1.38872 12.0177 1.38872C12.5773 1.38872 13.1275 1.53279 13.6153 1.80705L21.0087 5.99171C21.5056 6.27245 21.9192 6.67994 22.2072 7.17264C22.4953 7.66533 22.6476 8.2256 22.6485 8.79632V17.0891C22.6474 17.6596 22.495 18.2197 22.207 18.7122C21.9189 19.2047 21.5055 19.612 21.0087 19.8926L13.6153 24.0768C13.1275 24.3511 12.5773 24.4951 12.0177 24.4951C11.458 24.4951 10.9078 24.3511 10.42 24.0768L3.02453 19.8926C2.52823 19.6116 2.11524 19.2042 1.82756 18.7117C1.53989 18.2193 1.3878 17.6594 1.38678 17.0891V8.79632C1.38767 8.22583 1.53969 7.66577 1.82737 7.17312C2.11505 6.68047 2.52812 6.27284 3.02453 5.99171Z"
        fill={color}
      />
      <path
        d="M10.5872 17.8318V8.48828H12.8089V17.8318H10.5872ZM6.91211 14.1982V12.1219H16.484V14.1982H6.91211Z"
        fill={color}
      />
    </SvgIcon>
  );
};
