import React from 'react';
import { colorPalette } from 'theme';
import { Constants } from 'utilities/constants';
import { TextContainer } from './StyledComponents';

type RelatedParentProps = {
  problemKey: string;
  problemTitle: string;
  productKey: string;
  productTitle: string;
};

export const RelatedParent: React.FC<RelatedParentProps> = ({
  problemKey,
  problemTitle,
  productKey,
  productTitle
}) => {
  return (
    <TextContainer>
      <a
        href={`/${Constants.PROBLEMS}/${problemKey}`}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          color: colorPalette.purple,
          fontWeight: 700,
          textDecoration: 'none'
        }}
      >
        {problemTitle}
      </a>{' '}
      {Constants.ASSOCIATED_WITH}{' '}
      <a
        href={`/${Constants.PRODUCTS}/${productKey}`}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          color: colorPalette.purple,
          fontWeight: 700,
          textDecoration: 'none'
        }}
      >
        {productTitle}
      </a>
    </TextContainer>
  );
};
