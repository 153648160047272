import { Edit } from '@mui/icons-material';
import { Avatar, Box, Skeleton, styled, Typography } from '@mui/material';
import { colorPalette } from 'theme';

export const TOASTIFY_DURATION = 2500;

export const MainContainerBox = styled(Box)<{ width: string }>(({ width }) => ({
  alignItems: 'center',
  backgroundColor: colorPalette.white,
  borderRadius: '.5rem',
  boxShadow: `0 .0625rem .4375rem ${colorPalette.ebb}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: '1.5rem 0rem',
  width: width
}));

export const TitleWrapper = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: '0.25rem',
  width: '100%'
});

export const ContentWrapper = styled(Box)({
  padding: '1rem .7rem 0rem .7rem',
  width: '100%'
});

export const ItemNodesWrapper = styled(Box)({
  marginBottom: '1.0625rem'
});

export const ChangeConceptWrapper = styled(Box)({
  padding: '0rem .7rem .5rem .7rem',
  width: '100%'
});

export const EditIcon = styled(Edit)({
  borderRadius: '50%',
  color: colorPalette.purple,
  height: '1.4375rem',
  width: '1.4375rem'
});

export const ConceptName = styled(Typography)({
  cursor: 'pointer',
  display: '-webkit-box',
  fontSize: '.9375rem',
  fontWeight: '600',
  lineHeight: 1.25,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: '3',
  ':hover': { textDecoration: 'underline' }
});

export const InfoText = styled(Typography)({
  color: colorPalette.lightBlack,
  fontSize: '.875rem',
  fontWeight: '600'
});

export const ChangeConceptText = styled(Typography)({
  color: colorPalette.darkGreen,
  fontSize: '.75rem',
  fontWeight: 600,
  lineHeight: 1.3,
  textAlign: 'left',
  textDecoration: 'underline'
});

export const AiBox = styled(Box)({ display: 'flex' });

export const LoaderBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  minWidth: '2.875rem',
  padding: '.5rem'
});

export const TextSkeleton = styled(Skeleton)({
  height: '1.875rem',
  width: '52%'
});

export const IconsBox = styled(Box)({
  display: 'flex',
  justifyContent: 'right',
  marginLeft: '.5rem'
});

export const NameBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  gap: '.65rem',
  justifyContent: 'left',
  width: '100%'
});

export const ConceptAvatar = styled(Avatar)({
  borderRadius: '.3125rem',
  height: '150px',
  width: '100%',
  marginBottom: '1.125rem',
  objectFit: 'cover'
});

export const TeaserText = styled(Typography)({
  '-webkit-box-orient': 'vertical',
  '-webkit-line-clamp': '2',
  display: '-webkit-box',
  fontSize: 'small',
  lineHeight: '1.25rem',
  overflow: 'hidden',
  overflowX: 'hidden',
  textOverflow: 'ellipsis',
  width: '100%'
});

export const ChangeConceptBox = styled(Box)({
  alignItems: 'center',
  alignSelf: 'flex-start',
  cursor: 'pointer',
  display: 'flex',
  gap: '.5rem',
  justifyContent: 'flex-start',
  marginLeft: '.3125rem',
  marginTop: '.625rem'
});

export const InfoBox = styled(Box)<{ marginBottom?: string }>(
  ({ marginBottom }) => ({
    alignItems: 'center',
    backgroundColor: colorPalette.softGreen,
    borderRadius: '.375rem',
    display: 'flex',
    gap: '.5625rem',
    height: '3.75rem',
    justifyContent: 'left',
    marginBottom: marginBottom,
    padding: '.75rem',
    width: '100%'
  })
);
