import React from 'react';

function Frame2() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="105"
      height="70"
      fill="none"
      viewBox="0 0 105 70"
    >
      <g clipPath="url(#clip0_4313_1613)">
        <path
          fill="url(#paint0_linear_4313_1613)"
          d="M18.556 29.288s19.185-.646 35.168 21.393c28.266 38.976 46.37 34.062 54.133 32.838-17.808 3.37-40.425 7.283-64.511-26.606C30.56 38.922 2.712 38.848 2.712 38.848l18.38-4.042-2.537-5.52v.002z"
        ></path>
        <path
          fill="url(#paint1_linear_4313_1613)"
          d="M144.724 85.545c-29.361 3.233-61.387-46.894-29.406-54.028-1.901.423-7.283 1.625-7.283 1.625-37.678 8.403-3.924 63.57 36.689 52.403z"
        ></path>
        <path
          style={{ mixBlendMode: 'multiply' }}
          fill="url(#paint2_linear_4313_1613)"
          d="M144.724 85.545c-29.361 3.233-61.387-46.894-29.406-54.028-1.901.423-7.283 1.625-7.283 1.625-37.678 8.403-3.924 63.57 36.689 52.403z"
        ></path>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_4313_1613"
          x1="45.074"
          x2="60.879"
          y1="23.852"
          y2="94.821"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D99E38"></stop>
          <stop offset="0.15" stopColor="#FFF585"></stop>
          <stop offset="0.27" stopColor="#EBBC53"></stop>
          <stop offset="0.74" stopColor="#FFF28F"></stop>
          <stop offset="0.76" stopColor="#FEEA83"></stop>
          <stop offset="0.81" stopColor="#FCDD6E"></stop>
          <stop offset="0.86" stopColor="#FBD562"></stop>
          <stop offset="0.91" stopColor="#FBD35E"></stop>
          <stop offset="0.94" stopColor="#F9D05C"></stop>
          <stop offset="0.96" stopColor="#F3C958"></stop>
          <stop offset="0.97" stopColor="#E9BD50"></stop>
          <stop offset="0.98" stopColor="#C99436"></stop>
          <stop offset="0.99" stopColor="#B47926"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_4313_1613"
          x1="108.64"
          x2="121.565"
          y1="33.618"
          y2="91.653"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D99E38"></stop>
          <stop offset="0.15" stopColor="#FFF585"></stop>
          <stop offset="0.27" stopColor="#EBBC53"></stop>
          <stop offset="0.74" stopColor="#FFF28F"></stop>
          <stop offset="0.76" stopColor="#FEEA83"></stop>
          <stop offset="0.81" stopColor="#FCDD6E"></stop>
          <stop offset="0.86" stopColor="#FBD562"></stop>
          <stop offset="0.91" stopColor="#FBD35E"></stop>
          <stop offset="0.94" stopColor="#F9D05C"></stop>
          <stop offset="0.96" stopColor="#F3C958"></stop>
          <stop offset="0.97" stopColor="#E9BD50"></stop>
          <stop offset="0.98" stopColor="#C99436"></stop>
          <stop offset="0.99" stopColor="#B47926"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_4313_1613"
          x1="146.387"
          x2="91.855"
          y1="100.162"
          y2="13.167"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B16611"></stop>
          <stop offset="0.29" stopColor="#F8F2EB"></stop>
          <stop offset="0.67" stopColor="#fff"></stop>
          <stop offset="0.68" stopColor="#FCF9F6"></stop>
          <stop offset="0.77" stopColor="#E2CCAD"></stop>
          <stop offset="0.85" stopColor="#CEA873"></stop>
          <stop offset="0.92" stopColor="#C08E49"></stop>
          <stop offset="0.97" stopColor="#B77E2F"></stop>
          <stop offset="1" stopColor="#B47926"></stop>
        </linearGradient>
        <clipPath id="clip0_4313_1613">
          <path
            fill="#fff"
            d="M0 0H80.955V184.596H0z"
            transform="matrix(.05832 -.9983 .99892 .04651 .219 81.547)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Frame2;
