import React from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { FetchError } from '../../fetchUtils';
import { PsTheme } from '../../theme';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root';

const useStyles = makeStyles()((theme) => {
  const psTheme = theme as PsTheme;
  return {
    root: {
      marginBottom: 10,
      textAlign: 'center'
    }
  };
});

type PsHttpErrorProps = {
  className?: string;
  error?: FetchError;
};

const PsHttpErrorView = ({ className, error }: PsHttpErrorProps) => {
  const { classes } = useStyles();

  let rootClassName = classes.root;
  if (className) {
    rootClassName += ' ' + className;
  }

  if (!error) {
    return null;
  }

  let text = error.message;
  if (error.status === 404) {
    text = 'Not Found';
  }

  return (
    <Typography className={rootClassName} variant="body2">
      {text}
    </Typography>
  );
};

export const PsHttpError = styled(PsHttpErrorView)({});

export default PsHttpError;
