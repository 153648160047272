import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Application } from 'components/CardApplication';
import LabeledIconButton from 'components/common/buttons/LabeledIconButton';
import Actions from 'redux-state/actions';
import { GetUser } from 'redux-state/selectors';
import { colorPalette } from 'theme';
import { Constants, VARIANT } from 'utilities/constants';
import {
  ConceptName,
  NodeWrapper,
  Tag,
  TagContainer,
  TagWrapper
} from './styledComponents';

interface ConceptNodeProps {
  concept: Application;
}

export const ConceptNode: React.FC<ConceptNodeProps> = ({ concept }) => {
  const dispatch = useDispatch();

  const user = GetUser();
  const activeConcept = user?.activeConcept || {};

  const activateConcept = useCallback(() => {
    if (!user) {
      dispatch(Actions.openAuthModal(true));
      return;
    }
    dispatch(Actions.setLoginUser({ ...user, activeConcept: concept }));
    dispatch(Actions.editProfile(user?.id, { activeConcept: concept.id }));
  }, [user, dispatch, concept]);

  const isConceptActive = concept.id === activeConcept?.id;

  return (
    <NodeWrapper>
      <ConceptName
        onClick={() => {
          window.open(
            `/${Constants.INVENTIONS}/${concept?.key}`,
            Constants._BLANK
          );
        }}
      >
        {concept?.title}
      </ConceptName>
      <TagWrapper>
        <TagContainer>
          <Tag
            role="ProblemTag"
            color={colorPalette.red}
            borderColor={colorPalette.red}
            bgColor={colorPalette.pink}
          >
            {concept?.problems?.length ?? '0'} {Constants.PROBLEM}
            {concept?.problems?.length !== 1 ? 's' : ''}
          </Tag>
          <Tag
            role="SolutionTag"
            color={colorPalette.green}
            borderColor={colorPalette.green}
            bgColor={colorPalette.mintTulip}
          >
            {concept?.solutions?.length ?? '0'} {Constants.SOLUTION}
            {concept?.solutions?.length !== 1 ? 's' : ''}
          </Tag>
        </TagContainer>
        <LabeledIconButton
          icon={null}
          variant={VARIANT.CONTAINED}
          text={isConceptActive ? Constants.ACTIVATED : Constants.ACTIVATE}
          btnColor={colorPalette.purple}
          fontSize=".75rem"
          width="5rem"
          disabled={isConceptActive}
          onClick={activateConcept}
        />
      </TagWrapper>
    </NodeWrapper>
  );
};
