import { useSelector } from 'react-redux';
import { UserProfileState } from './interface';
import { Profile } from 'components/CardProfile';

export const GetFilePatentLoader = () =>
  useSelector(
    (state: UserProfileState) => state.userProfile.eFilePatentLoading
  );
export const GetAuthStatus = () =>
  useSelector((state: UserProfileState) => state.userProfile.authStatus);
export const GetEditProfileItemLoader = () =>
  useSelector((state: UserProfileState) => state.userProfile.editItemLoader);
export const GetErrorStatus = () =>
  useSelector((state: UserProfileState) => state.userProfile.error);
export const GetErrorItemId = () =>
  useSelector((state: UserProfileState) => state.userProfile.errorItemId);
export const GetErrorMessage = () =>
  useSelector((state: UserProfileState) => state.userProfile.errorMessage);
export const GetNotifications = () =>
  useSelector((state: UserProfileState) => state.userProfile.notifications);
export const GetUnreadNotificationCount = () =>
  useSelector(
    (state: UserProfileState) => state.userProfile.unreadNotificationCount
  );
export const GetNotificationsLoader = () =>
  useSelector(
    (state: UserProfileState) => state.userProfile.getNotificationsLoading
  );
export const GetProfileStatusCode = () =>
  useSelector((state: UserProfileState) => state.userProfile.statusCode);
export const GetProfileProblemsLoader = () =>
  useSelector((state: UserProfileState) => state.userProfile.getProblemLoading);
export const GetProfileProblems = () =>
  useSelector((state: UserProfileState) => state.userProfile.problems);
export const GetProfileProblemsCount = () =>
  useSelector((state: UserProfileState) => state.userProfile.totalProblems);
export const GetProblemsCount = () =>
  useSelector((state: UserProfileState) => state.userProfile.problemsCount);
export const GetSolutionsCount = () =>
  useSelector((state: UserProfileState) => state.userProfile.solutionsCount);
export const GetConceptsCount = () =>
  useSelector((state: UserProfileState) => state.userProfile.conceptsCount);
export const GetInventionsCount = () =>
  useSelector((state: UserProfileState) => state.userProfile.inventionsCount);
export const GetContestsCount = () =>
  useSelector((state: UserProfileState) => state.userProfile.contestsCount);
export const GetAgreementsCount = () =>
  useSelector((state: UserProfileState) => state.userProfile.agreementsCount);
export const GetProfileAgreements = () =>
  useSelector((state: UserProfileState) => state.userProfile.agreements);
export const GetProfileAgreementsCount = () =>
  useSelector((state: UserProfileState) => state.userProfile.totalAgreements);
export const GetProfilePinnedProblems = () =>
  useSelector((state: UserProfileState) => state.userProfile.pinnedProblems);
export const GetProfileSolutionsLoader = () =>
  useSelector(
    (state: UserProfileState) => state.userProfile.getSolutionLoading
  );
export const GetProfileSolutions = () =>
  useSelector((state: UserProfileState) => state.userProfile.solutions);
export const GetProfileSolutionsCount = () =>
  useSelector((state: UserProfileState) => state.userProfile.totalSolutions);
export const GetProfilePinnedSolutions = () =>
  useSelector(
    (state: UserProfileState) => state.userProfile.pinnedSolutions ?? []
  );
export const GetProfileConceptsLoader = () =>
  useSelector(
    (state: UserProfileState) => state.userProfile.getConceptsLoading
  );
export const GetProfileConcepts = () =>
  useSelector((state: UserProfileState) => state.userProfile.concepts);
export const GetProfileConceptsCount = () =>
  useSelector((state: UserProfileState) => state.userProfile.totalConcepts);
export const GetProfilePinnedConcepts = () =>
  useSelector(
    (state: UserProfileState) => state.userProfile.pinnedConcepts ?? []
  );
export const GetProfileInventionsLoader = () =>
  useSelector(
    (state: UserProfileState) => state.userProfile.getInventionsLoading
  );
export const GetProfileInventions = () =>
  useSelector((state: UserProfileState) => state.userProfile.inventions);
export const GetProfileInventionsCount = () =>
  useSelector((state: UserProfileState) => state.userProfile.totalInventions);
export const GetProfilePinnedInventions = () =>
  useSelector((state: UserProfileState) => state.userProfile.pinnedInventions);
export const GetProfileProductsLoader = () =>
  useSelector(
    (state: UserProfileState) => state.userProfile.getProductsLoading
  );
export const GetProfileProducts = () =>
  useSelector((state: UserProfileState) => state.userProfile.products);
export const GetProfileProductsCount = () =>
  useSelector((state: UserProfileState) => state.userProfile.totalProducts);
export const GetProfileContestsLoader = () =>
  useSelector(
    (state: UserProfileState) => state.userProfile.getContestsLoading
  );
export const GetProfileContests = () =>
  useSelector((state: UserProfileState) => state.userProfile.contests);
export const GetProfileContestsCount = () =>
  useSelector((state: UserProfileState) => state.userProfile.totalContests);
export const GetProfilePinnedContests = () =>
  useSelector(
    (state: UserProfileState) => state.userProfile.pinnedContests ?? []
  );
export const GetRewardHistory = () =>
  useSelector((state: UserProfileState) => state.userProfile.rewardHistory);
export const GetRewardHistoryCount = () =>
  useSelector((state: UserProfileState) => state.userProfile.totalReward);
export const GetRewardHistoryLoader = () =>
  useSelector(
    (state: UserProfileState) => state.userProfile.getRewardHistoryLoading
  );

export const GetTokenURI = () =>
  useSelector((state: Profile) => state.userProfile.tokenURI);

export const GetTxApprovalModalObj = () =>
  useSelector((state: Profile) => state.userProfile.txApprovalModalObj);

export const GetNftDeployStartLoader = () =>
  useSelector(
    (state: UserProfileState) => state.userProfile.nftDeployStartLoader
  );
export const GetNftDeployWithMetamaskLoader = () =>
  useSelector(
    (state: UserProfileState) => state.userProfile.nftDeployWithMetamaskLoader
  );
export const GetNftDeployFinishLoader = () =>
  useSelector(
    (state: UserProfileState) => state.userProfile.nftDeployFinishLoader
  );

export const GetNftDeployLoader = () =>
  useSelector((state: UserProfileState) => state.userProfile.deployNFTLoader);

export const GetUserRankingLoader = () =>
  useSelector(
    (state: UserProfileState) => state.userProfile.getUserRankingLoading
  );

export const GetUserRanking = () =>
  useSelector((state: UserProfileState) => state.userProfile.userRank);

export const GetUserIdeaPoints = () =>
  useSelector((state: UserProfileState) => state.userProfile.userIdeaPoints);

export const GetUserMutualTagsLoader = () =>
  useSelector(
    (state: UserProfileState) => state.userProfile.getProfileMutualTagsLoader
  );

export const GetUserMutualTags = () =>
  useSelector((state: UserProfileState) => state.userProfile.profileMutualTags);

export const GetEditProfileLoader = () =>
  useSelector((state: UserProfileState) => state.userProfile.editProfileLoader);
