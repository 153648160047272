import React, { FC, useMemo, useState } from 'react';
import LabeledIconButton from 'components/common/buttons/LabeledIconButton';
import { colorPalette } from 'theme';
import { Constants, SUBSCRIPTION_PLANS, VARIANT } from 'utilities/constants';
import { GetUser } from 'redux-state/selectors';
import { CircularProgress } from '@mui/material';

export const SubActionButton: FC<{
  subscription: any;
  onClick?: () => void;
}> = ({ onClick, subscription }) => {
  const user = GetUser();
  const [loading, setLoading] = useState<boolean>(false);

  const isFreeUser = user?.subscriptionPlan === SUBSCRIPTION_PLANS.FREE_TIER;
  const isCurrentPlan = subscription.title === user?.subscriptionPlan;

  const buttonStyles = useMemo(
    () => ({
      backgroundColor: isCurrentPlan ? colorPalette.white : 'none',
      border: isCurrentPlan ? 'none' : `1px solid ${colorPalette.purple}`,
      boxShadow: 'none',
      color: isCurrentPlan ? colorPalette.purple : 'none',
      cursor: isCurrentPlan ? 'default' : 'pointer',
      marginTop: '.625rem',
      padding: isCurrentPlan ? '.25rem 0rem' : '.1563rem 0rem',
      '&:hover': {
        backgroundColor: colorPalette.purple,
        '& .MuiTypography-root': {
          color: colorPalette.white
        },
        '& .MuiCircularProgress-root': {
          color: colorPalette.white
        }
      },
      '&.Mui-disabled': {
        backgroundColor: colorPalette.white,
        color: colorPalette.purple,
        boxShadow: 'none',
        border: 'none'
      }
    }),
    [isCurrentPlan]
  );

  const handleClick = async () => {
    setLoading(true);
    onClick();
  };

  const buttonText = isCurrentPlan
    ? Constants.CURRENT_PLAN
    : !isFreeUser && subscription.title === SUBSCRIPTION_PLANS.FREE_TIER
      ? Constants.DOWNGRADE
      : (subscription?.actionButtonText ?? Constants.SUBSCRIBE);

  return (
    <LabeledIconButton
      buttonSx={buttonStyles}
      disabled={isCurrentPlan}
      fontSize=".5938rem"
      icon={loading ? <CircularProgress size={10} /> : null}
      onClick={handleClick}
      text={buttonText}
      typographySx={{ color: colorPalette.purple, fontWeight: 700 }}
      variant={isCurrentPlan ? VARIANT.CONTAINED : VARIANT.OUTLINED}
      width="75%"
    />
  );
};
