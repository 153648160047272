import React from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { Profile } from 'components/CardProfile';
import { AvaImage } from 'components/card';
import { PsTheme } from '../../theme';
import { Picture } from 'helpers/index';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root' | 'image';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      fontSize: 0,
      lineHeight: 0
    },
    image: {
      width: 65,
      verticalAlign: 'middle',
      marginRight: 15,
      marginBottom: 15
    }
  };
});

export type ContributorItem = Pick<
  Profile,
  'id' | 'key' | 'files' | 'email' | 'username'
>;

export type ContributorsProps = {
  className?: string;
  users: Array<ContributorItem>;
  round?: boolean;
};

const ContributorsView = ({ className, users, round }: ContributorsProps) => {
  const { classes } = useStyles();
  let rootClassname = classes.root;
  if (className) {
    rootClassname += ` ${className}`;
  }

  if (!users || !users.length) {
    return null;
  }

  return (
    <div className={rootClassname}>
      {users.map((contributor, i) => {
        let image: Picture | undefined = undefined;
        if (contributor.files && contributor.files.length) {
          image = contributor.files[0];
        }
        const name = contributor.username || contributor.email;
        const url = `/profiles/${contributor.key || contributor.id}`;
        return (
          <AvaImage
            key={`${contributor.id}-${i}`}
            className={classes.image}
            image={image}
            imageAlt={name}
            href={url}
            round={round}
            target="_blank"
          />
        );
      })}
    </div>
  );
};

export const Contributors = styled(ContributorsView)({});

export default Contributors;
