import { Box, styled, Typography } from '@mui/material';
import { colorPalette } from 'theme';

export const MainWrapper = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  gap: '.3125rem',
  justifyContent: 'left'
});

export const ValueText = styled(Typography)({
  color: colorPalette.purple,
  fontSize: '.75rem',
  fontWeight: '700'
});
