import { Avatar, Box, Grid, Typography, styled } from '@mui/material';
import { colorPalette } from 'theme';

export const MainBox = styled(Box)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    alignItems: 'center',
    background: colorPalette.white,
    display: 'flex',
    flexDirection: isMediumScreen ? 'column' : 'row',
    justifyContent: 'space-between',
    padding: '1rem'
  })
);

export const StyledEditCursor = styled(Box)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    alignItems: 'center',
    background: colorPalette.lightPurple,
    borderRadius: '1.25rem',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    height: isMediumScreen ? '1.9375rem' : '2.25rem',
    justifyContent: 'center',
    width: isMediumScreen ? '1.9375rem' : '2.25rem'
  })
);

export const StyledMainContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column'
});

export const DetailsWrapper = styled(Box)({
  display: 'flex',
  gap: '10px',
  justifyContent: 'space-between'
});

export const StyledSubContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row'
});

export const StyledBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  position: 'relative',
  width: 'fit-content'
});

export const StyledProfileAvatar = styled(Avatar)({
  borderRadius: '50%',
  height: '8.125rem',
  marginTop: '-4rem',
  width: '8.125rem'
});

export const StyledTypography = styled(Typography)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    fontFamily: 'Montserrat',
    fontSize: '1.5rem',
    fontWeight: 700,
    textAlign: 'left',
    whiteSpace: isMediumScreen ? 'wrap' : 'unset',
    width: isMediumScreen ? '20vw' : 'unset'
  })
);

export const StyledClickableText = styled(Typography)({
  color: colorPalette.purple,
  cursor: 'pointer',
  fontFamily: 'Montserrat',
  fontSize: '0.75rem',
  fontWeight: 600,
  lineHeight: '0.9144rem',
  minWidth: 0,
  padding: 0,
  textAlign: 'left',
  textDecoration: 'underline',
  textTransform: 'none'
});

export const StyledGrid = styled(Grid)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gridColumn: isMediumScreen ? 'span 1' : 'span 6',
    justifyContent: 'center',
    padding: '1rem'
  })
);

export const StyledParentGrid = styled(Grid)({
  flexWrap: 'unset',
  justifyContent: 'space-between'
});
