import React, { useCallback, useContext, useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { ModalDataContext } from 'contexts/ModalDataContext';
import { PsLogo } from 'components/common/PsLogo';
import { PsNFT } from 'components/common/PsNFT';
import styles from 'modals/ModalWindow.module.scss';
import { PsTheme } from '../../../theme';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import { makeStyles } from 'tss-react/mui';

const getContestJackpot = (coins: number): [nft: number, reward: number] => {
  if (coins <= 10) {
    return [3, 4];
  } else if (coins <= 20) {
    return [6, 7];
  } else if (coins <= 30) {
    return [9, 10];
  } else if (coins <= 40) {
    return [12, 15];
  } else if (coins <= 50) {
    return [15, 20];
  } else if (coins <= 60) {
    return [18, 25];
  } else if (coins <= 70) {
    return [21, 30];
  } else if (coins <= 80) {
    return [24, 35];
  } else if (coins <= 90) {
    return [27, 40];
  } else if (coins <= 100) {
    return [30, 45];
  }
  return [30, 45];
};

type ClassKey =
  | 'root'
  | 'descrTitle'
  | 'itemTitle'
  | 'block'
  | 'center'
  | 'left'
  | 'right';

const useStyles = makeStyles()(() => {
  const psTheme = useTheme() as PsTheme;
  return {
    root: {},
    descrTitle: {
      marginBottom: '0 !important'
    },
    itemTitle: {
      '& > svg': {
        marginRight: 20,
        marginBottom: -8
      }
    },
    block: {
      marginBottom: 25,
      fontWeight: 'normal',
      '&:last-child': {
        marginBottom: 0
      },
      '& b': {
        fontWeight: 600
      }
    },
    center: {
      textAlign: 'center'
    },
    left: {
      textAlign: 'center',
      '& svg': {
        marginBottom: -4,
        marginRight: 5
      }
    },
    right: {
      textAlign: 'center',
      '& svg': {
        marginBottom: -4,
        marginRight: 5
      }
    }
  };
});

const ContestBountyView = () => {
  const { classes } = useStyles();
  const { values, updateField } = useContext(ModalDataContext);
  const initCoins = values.contestCoins || 30;
  const [initNft, initRewards] = getContestJackpot(initCoins);
  const [coins, setCoins] = useState(initCoins);
  const [nft, setNft] = useState(initNft);
  const [reward, setReward] = useState(initRewards);

  const onCoinsChange = useCallback(
    (event: any, value: number | number[]) => {
      setCoins(value as number);
      const [initNft, initRewards] = getContestJackpot(value as number);
      setNft(initNft);
      setReward(initRewards);
    },
    [setCoins, setNft, setReward]
  );

  const onCoinsChangeCommitted = useCallback(
    (event: any, value: number | number[]) => {
      updateField('contestCoins', value);
    },
    [updateField]
  );

  return (
    <div className={styles.stepWrapper}>
      <Typography className={`${styles.textBig} ${classes.descrTitle}`}>
        Choose Contest Jackpot:
      </Typography>
      <Typography className={styles.textSmall}>
        The larger jackpot you choose, the more Invention NFTs contestants will
        need to generate (with your encouragement) to unlock rewards.
      </Typography>
      <div className={classes.block}>
        <Grid container spacing={0}>
          <Grid item xs={12} className={classes.center}>
            <b>{coins} Coins</b>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={3} className={classes.left}>
            <PsLogo size={20} color="#632DDD" small /> <b>10</b> Coins
          </Grid>
          <Grid item xs={6}>
            <Slider
              defaultValue={initCoins}
              min={10}
              max={100}
              step={10}
              onChange={onCoinsChange}
              onChangeCommitted={onCoinsChangeCommitted}
            />
          </Grid>
          <Grid item xs={3} className={classes.right}>
            <PsLogo size={20} color="#632DDD" small /> <b>100</b> Coins
          </Grid>
        </Grid>
      </div>

      <Typography className={styles.textSmall}>
        Minimum number of user generated NFTs required to unlock jackpot:
      </Typography>
      <Typography className={`${styles.textBig} ${classes.itemTitle}`}>
        <PsLogo size={35} color="#632DDD" small />
        {nft} Invention NFTs, to unlock jackpot
      </Typography>

      <Typography className={styles.textSmall}>
        You get, when the jackpot is unlocked:
      </Typography>
      <Typography className={`${styles.textBig} ${classes.itemTitle}`}>
        <PsNFT size={35} color="black" />
        {reward} IdeaCoins, your influencer reward
      </Typography>
    </div>
  );
};

export const ContestBounty = styled(ContestBountyView)({});

export default ContestBounty;
