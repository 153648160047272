import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_PRODUCTS,
  GET_COMPANIES,
  GET_TAGS,
  ADD_PRODUCT,
  ADD_TAG,
  GET_LOCATION_TAGS,
  GET_WORKPLACE_TAGS,
  GET_UNIVERSITY_TAGS,
  GET_RELATED_TAGS
} from './types';
import * as Actions from './actions';
import { Api } from './api';
import { Tag } from 'components/CardTag';

function* getProducts(action: {
  type: string;
  payload: string;
}): Generator<any, void, any> {
  try {
    const response: Array<any> = yield call(Api.getProducts, action.payload);
    yield put(Actions.getProductsSuccess(response));
  } catch (error) {
    console.log('error', error);
  }
}

function* getCompanies(action: {
  type: string;
  payload: string;
}): Generator<any, void, any> {
  try {
    const response: Array<any> = yield call(Api.getCompanies, action.payload);
    yield put(Actions.getCompaniesSuccess(response));
  } catch (error) {
    console.log('error', error);
  }
}

function* getTags(action: {
  type: string;
  name: string;
  payload: any;
  tagFilter?: string | null;
  searchFilter?: string | null;
  sort?: { field: string; order: string };
  load?: boolean;
}): Generator<any, void, any> {
  try {
    const { load = true } = action;
    if (load) {
      yield put(Actions.setGetTagsLoader(true));
    }
    const response: Array<any> = yield call(
      Api.getTags,
      action.payload,
      action.tagFilter,
      action.searchFilter,
      action.sort
    );
    yield put(Actions.getTagsSuccess(response));
    yield put(Actions.setGetTagsLoader(false));
  } catch (error) {
    console.log('error', error);
    yield put(Actions.setGetTagsLoader(false));
  }
}

function* addProduct(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const {
      data: { key }
    }: any = yield call(Api.addProduct, action.payload);
    window.location.replace(`/products/${key}`);
  } catch (error) {
    console.log('error', error);
  }
}

function* getLocationTags(action: {
  type: string;
  name: string;
  payload: any;
  tagFilter?: string | null;
  searchFilter?: string | null;
  sort?: { field: string; order: string };
  load?: boolean;
}): Generator<any, void, any> {
  try {
    const response: Array<any> = yield call(
      Api.getTags,
      action.payload,
      action.tagFilter,
      action.searchFilter,
      action.sort
    );
    yield put(Actions.getLocationTagsSuccess(response));
  } catch (error) {
    console.log('error', error);
  }
}

function* getWorkplaceTags(action: {
  type: string;
  name: string;
  payload: any;
  tagFilter?: string | null;
  searchFilter?: string | null;
  sort?: { field: string; order: string };
  load?: boolean;
}): Generator<any, void, any> {
  try {
    const response: Array<any> = yield call(
      Api.getTags,
      action.payload,
      action.tagFilter,
      action.searchFilter,
      action.sort
    );
    yield put(Actions.getWorkplaceTagsSuccess(response));
  } catch (error) {
    console.log('error', error);
  }
}

function* getUniversityTags(action: {
  type: string;
  name: string;
  payload: any;
  tagFilter?: string | null;
  searchFilter?: string | null;
  sort?: { field: string; order: string };
  load?: boolean;
}): Generator<any, void, any> {
  try {
    const response: Array<any> = yield call(
      Api.getTags,
      action.payload,
      action.tagFilter,
      action.searchFilter,
      action.sort
    );
    yield put(Actions.getUniversityTagsSuccess(response));
  } catch (error) {
    console.log('error', error);
  }
}

function* getRelatedTags(action: {
  type: string;
  name: string;
  payload: any;
  tagFilter?: string | null;
  searchFilter?: string | null;
  sort?: { field: string; order: string };
  load?: boolean;
}): Generator<any, void, any> {
  try {
    const response: Array<any> = yield call(
      Api.getTags,
      action.payload,
      action.tagFilter,
      action.searchFilter,
      action.sort
    );
    yield put(Actions.getRelatedTagsSuccess(response));
  } catch (error) {
    console.log('error', error);
  }
}

function* addTag(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data }: Tag = yield call(Api.addTag, action.payload);
    if (action.payload.isRepresentative) {
      action.payload.cb?.(data);
    } else {
      window.location.replace(`/communities/${data.key}`);
    }
  } catch (error) {
    action.payload.cb?.(null, error);
    console.log('error', error);
  }
}

function* mySaga() {
  yield takeLatest(GET_PRODUCTS, getProducts);
  yield takeLatest(GET_COMPANIES, getCompanies);
  yield takeLatest(GET_TAGS, getTags);
  yield takeLatest(ADD_PRODUCT, addProduct);
  yield takeLatest(ADD_TAG, addTag);
  yield takeLatest(GET_LOCATION_TAGS, getLocationTags);
  yield takeLatest(GET_WORKPLACE_TAGS, getWorkplaceTags);
  yield takeLatest(GET_UNIVERSITY_TAGS, getUniversityTags);
  yield takeLatest(GET_RELATED_TAGS, getRelatedTags);
}

export default mySaga;
