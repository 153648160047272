/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import { Box, IconButton, Tooltip } from '@mui/material';
import defaultImage from 'assets/images/defaultImage2.png';
import LabeledIconButton from 'components/common/buttons/LabeledIconButton';
import { ConceptItemNodes } from 'components/ConceptItemNodes';
import { HexMindMinerIcon } from 'components/icons/HexMindMinerIcon';
import { HexPlusIcon } from 'components/icons/HexPlusIcon';
import { HexPlusOutlinedIcon } from 'components/icons/HexPlusOutlinedIcon';
import pusher from 'config/pusherConfig';
import dataProvider from 'dataPrvider';
import { isObjectEmpty } from 'helpers';
import { AllConceptsModal } from 'modals/AllConceptsModal';
import { MakePatentPendingModal } from 'modals/MakePatentPending';
import { toastify } from 'pages/newContests/toastify';
import { GlassMagnifier } from 'react-image-magnifiers';
import Actions from 'redux-state/actions';
import {
  GetLoadAiGeneration,
  GetUpdateConceptLoader,
  GetUser
} from 'redux-state/selectors';
import { colorPalette } from 'theme';
import {
  CHANNELS,
  Constants,
  ERRORS,
  EVENTS,
  VARIANT
} from 'utilities/constants';
import { ConceptTitle } from './ConceptTitle';
import { EditButton } from './EditButton';
import {
  ChangeConceptBox,
  ChangeConceptText,
  ChangeConceptWrapper,
  ContentWrapper,
  IconsBox,
  InfoBox,
  InfoText,
  ItemNodesWrapper,
  MainContainerBox,
  NameBox,
  TextSkeleton,
  TitleWrapper,
  TOASTIFY_DURATION
} from './styledComponents';
import { handleClickMintPatent } from './utils';

interface ActiveConceptCardProps {
  HeaderComponent?: React.FC;
  itemNodesHeight?: string;
  width?: string;
  onPatentDownloadClick?: (
    isOpenDownloadModal: boolean,
    isOpenPatentPendingModal: boolean
  ) => void;
}

export const ActiveConceptCard: React.FC<ActiveConceptCardProps> = ({
  HeaderComponent,
  itemNodesHeight = '300px',
  width,
  onPatentDownloadClick
}) => {
  const dispatch = useDispatch();

  const [isEditingTitle, setIsEditingTitle] = useState<boolean>(false);
  const [openAllConceptsModal, setOpenAllConceptsModal] =
    useState<boolean>(false);
  const [openPatentModal, setOpenPatentModal] = useState<boolean>(false);

  const aiGenLoader = GetLoadAiGeneration();
  const updateConceptLoader = GetUpdateConceptLoader();
  const user = GetUser();
  const activeConcept = useMemo(
    () => user?.activeConcept || {},
    [user?.activeConcept]
  );
  const hasActiveConcept = !isObjectEmpty(activeConcept);
  const hasSolsInActiveConcept = activeConcept?.solutions?.length > 0;

  const InfoContainer = useMemo(() => {
    return (
      <InfoBox marginBottom={hasSolsInActiveConcept ? '1.125rem' : 'none'}>
        <HexPlusIcon width="2.1875rem" height="2.1875rem" />
        <InfoText>{Constants.ADD_SOLUTIONS_TO_BUILD_CONCEPT}</InfoText>
      </InfoBox>
    );
  }, [hasSolsInActiveConcept]);

  useEffect(() => {
    if (activeConcept?._id) {
      const channel = pusher.subscribe(
        `${CHANNELS.AI_IMAGE}-${activeConcept._id}`
      );
      const handleData = (data) => {
        if (data?.files) {
          dispatch(
            Actions.setLoginUser({
              ...user,
              activeConcept: { ...user.activeConcept, files: data.files }
            })
          );
        }
        dispatch(Actions.getConcept(user._id));

        toastify(
          `Image for the concept "${activeConcept.title}" has been updated.`,
          VARIANT.SUCCESS,
          VARIANT.BOTTOM_LEFT,
          TOASTIFY_DURATION
        );

        channel.unbind(EVENTS.DALL_E, handleData);
      };

      channel.bind(EVENTS.DALL_E, handleData);

      return () => {
        channel.unbind(EVENTS.DALL_E, handleData);
        pusher.unsubscribe(`${CHANNELS.AI_IMAGE}-${activeConcept._id}`);
      };
    }
  }, [activeConcept, dispatch, user]);

  const handleCloseAllConceptsModal = useCallback(() => {
    setOpenAllConceptsModal(false);
  }, []);

  const handleMintPatent = useCallback(
    () =>
      handleClickMintPatent(
        user,
        dispatch,
        hasSolsInActiveConcept,
        setOpenPatentModal
      ),
    [dispatch, hasSolsInActiveConcept, user]
  );

  const handleOpenAllConceptsModal = useCallback(() => {
    if (!user) {
      dispatch(Actions.openAuthModal(true));
      return;
    }
    setOpenAllConceptsModal(true);
  }, [dispatch, user]);

  const handleVideoGenerateFromAI = async () => {
    try {
      if (activeConcept?.solutions?.length < 2) {
        toastify(
          Constants.ADD_SOLUTIONS_TO_CONCEPT,
          VARIANT.INFO,
          VARIANT.BOTTOM_LEFT,
          TOASTIFY_DURATION
        );
        return;
      }

      await dataProvider.processVideoExample(
        Constants.TEXT_TO_VIDEO,
        activeConcept.id
      );
      toastify(
        `Ai video for the concept "${activeConcept.title}" will be generated in 6-8 minutes.`,
        VARIANT.SUCCESS,
        VARIANT.BOTTOM_LEFT,
        TOASTIFY_DURATION
      );
    } catch (error) {
      console.error(ERRORS.VIDEO_GENERATION_FAILED, error);
    }
  };

  const hasImage = activeConcept?.files?.[0]?.url !== undefined;

  return (
    <MainContainerBox width={width ?? '100%'}>
      {HeaderComponent && <HeaderComponent />}
      {hasActiveConcept && (
        <ContentWrapper>
          <TitleWrapper>
            <NameBox>
              {updateConceptLoader || aiGenLoader ? (
                <TextSkeleton variant={VARIANT.TEXT} />
              ) : (
                <ConceptTitle
                  isEditingTitle={isEditingTitle}
                  setIsEditingTitle={setIsEditingTitle}
                />
              )}
            </NameBox>
            <IconsBox>
              <Tooltip
                placement={VARIANT.TOP}
                color={VARIANT.PRIMARY}
                title={Constants.GENERATE_AI_VIDEO}
              >
                <IconButton onClick={handleVideoGenerateFromAI}>
                  <SmartDisplayIcon />
                </IconButton>
              </Tooltip>
              <EditButton />
            </IconsBox>
          </TitleWrapper>

          <GlassMagnifier
            className="magnifier-container"
            imageAlt={Constants.CONCEPT_IMAGE}
            imageSrc={activeConcept?.files?.[0]?.url ?? defaultImage}
            largeImageSrc={activeConcept?.files?.[0]?.url ?? defaultImage}
            magnifierBorderColor="rgba(255, 255, 255, 0.6)"
            magnifierBorderSize={2}
            magnifierSize={hasImage ? '23%' : '5px'}
            style={{
              borderRadius: '.375rem',
              marginBottom: '1.125rem'
            }}
          />

          {!hasSolsInActiveConcept ? (
            InfoContainer
          ) : (
            <>
              <ItemNodesWrapper>
                <ConceptItemNodes
                  concept={activeConcept}
                  maxHeight={itemNodesHeight}
                />
              </ItemNodesWrapper>
              <LabeledIconButton
                btnColor={colorPalette.darkGreen}
                buttonSx={{ padding: '.625rem 1.25rem' }}
                fontSize=".875rem"
                icon={<HexMindMinerIcon width="2.0625rem" height="2.0625rem" />}
                onClick={handleMintPatent}
                text={Constants.MINT_PATENT_TOKEN}
                typographySx={{ lineHeight: 1.2 }}
                variant={VARIANT.CONTAINED}
                width="100%"
              />
            </>
          )}
        </ContentWrapper>
      )}
      <ChangeConceptWrapper>
        <ChangeConceptBox onClick={handleOpenAllConceptsModal}>
          {(hasSolsInActiveConcept || !hasActiveConcept) && (
            <Box sx={{ marginLeft: '.125rem', height: '1.5625rem' }}>
              <HexPlusOutlinedIcon width="1.5625rem" height="1.5625rem" />
            </Box>
          )}
          <ChangeConceptText>
            {Constants.ADD_OR_ACTIVATE_CONCEPT}
          </ChangeConceptText>
        </ChangeConceptBox>
      </ChangeConceptWrapper>

      {openAllConceptsModal && (
        <AllConceptsModal
          onClose={handleCloseAllConceptsModal}
          open={openAllConceptsModal}
        />
      )}
      {openPatentModal && (
        <MakePatentPendingModal
          concept={activeConcept}
          open={openPatentModal}
          setOpen={setOpenPatentModal}
          onPatentDownloadClick={onPatentDownloadClick}
        />
      )}
    </MainContainerBox>
  );
};
