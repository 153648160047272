import { Box, Typography, styled } from '@mui/material';
import { colorPalette } from 'theme';

export const StyledSubContainer = styled(Box)({
  alignItems: 'center',
  border: `2px solid ${colorPalette.silverGrey}`,
  borderRadius: '10px',
  display: 'flex',
  height: '4.375rem',
  width: '100%'
});

export const StyledTextMainContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0 .75rem 0 .75rem',
  width: '100%'
});

export const StyledPatentText = styled(Typography)({
  fontSize: '18px',
  fontWeight: 500
});

export const StyledPatentTextValue = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  gap: '8px'
});

export const StyledValueContainer = styled(Typography)({
  fontWeight: 700
});

export const StyledMainContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '14px'
});

export const StyledContainer = styled(Box)({
  paddingTop: '1.625rem'
});
