import * as React from 'react';
export const HexagonalAddToIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={37}
    height={40}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M17.063 29.839v-19.67h3.688v19.67h-3.688ZM9.073 21.8v-3.548H28.74V21.8H9.072Z"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={4}
      d="M35 27.152V12.848a3.576 3.576 0 0 0-1.788-3.094L20.695 2.602a3.576 3.576 0 0 0-3.576 0L4.603 9.754a3.576 3.576 0 0 0-1.789 3.094v14.304a3.576 3.576 0 0 0 1.789 3.094l12.516 7.152a3.576 3.576 0 0 0 3.576 0l12.517-7.152A3.576 3.576 0 0 0 35 27.152Z"
    />
  </svg>
);
