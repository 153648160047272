import React, { useCallback } from 'react';
import { LinearProgress, Box } from '@mui/material';
import { Modal } from 'modals/Modal';
import SignatureModal from 'modals/SignaturePad/SignatureModal';
import Config from 'config/config';
import dataProvider from 'dataPrvider';

export type AgreementDocModalProps = {
  showDocModal: boolean;
  setShowDocModal: React.Dispatch<React.SetStateAction<boolean>>;
  signedUrl: string;
  onSave: (sign: string) => Promise<void>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  applicationId: string | undefined;
};

const AgreementDocModal = ({
  showDocModal,
  setShowDocModal,
  signedUrl,
  onSave,
  loading,
  setLoading,
  applicationId
}: AgreementDocModalProps) => {
  const onModalClose = useCallback(() => {
    dataProvider.payOptionClear(applicationId);
    setShowDocModal(false);
  }, [applicationId, setShowDocModal]);

  const doc = Config.CLIENT_DOC_URL;

  const handlePdfLoad = () => {
    setLoading(false);
  };

  return (
    <Modal open={showDocModal}>
      {loading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <Box className="doc_modal">
        {!signedUrl && (
          <SignatureModal
            signedUrl={signedUrl}
            loading={loading}
            onSave={onSave}
            onClose={onModalClose}
          />
        )}
        <object
          className="doc_width_height"
          data={signedUrl ? signedUrl : doc}
          type="application/pdf"
          onLoad={handlePdfLoad}
        >
          <div>No PDF viewer available</div>
        </object>
      </Box>
    </Modal>
  );
};

export default AgreementDocModal;
