import React from 'react';
import { styled, Theme, useTheme } from '@mui/material/styles';

import { PsTheme } from '../../theme';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      minWidth: 0,
      padding: '24px 16px',
      [psTheme.breakpoints.down('sm')]: {
        padding: '5px 10px'
      }
    }
  };
});

type CardBodyProps = {
  children?: React.ReactNode;
  className?: string;
};

const CardBodyView = ({ className, children }: CardBodyProps) => {
  const { classes } = useStyles();

  let rootClassName = classes.root;
  if (className) {
    rootClassName += ' ' + className;
  }

  return <div className={rootClassName}>{children}</div>;
};

export const CardBody = styled(CardBodyView)({});

export default CardBody;
