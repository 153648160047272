import React, { useContext, useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';

import { PsLogo } from 'components/common/PsLogo';
import { DataContext, DataProvider, PsRecord } from 'contexts/DataContext';
import { Problem, CardProblemSmall } from 'components/CardProblem';
import { Solution, CardSolutionSmall } from 'components/CardSolution';
import { Application, CardApplicationSmall } from 'components/CardApplication';
import { Pagination } from 'components/Pagination';
import { TAG_TYPES } from 'utilities/constants';
import { PsTheme } from '../../theme';

export const ItemsType = {
  APPLICATION: 'application',
  IDEA_COINS: 'idea coin',
  IDEA_NFT: 'idea nft',
  IDEA_POINTS: 'idea point',
  INVENTION: 'invention',
  NFT: 'nft',
  PROBLEM: 'problem',
  SOLUTION: 'solution'
};

const loadDataDefault = async (
  dataProvider: DataProvider,
  profileId: string | number,
  type: string,
  options: { perPage: number; page: number }
): Promise<{ data: Array<PsRecord>; total: number }> => {
  const { page, perPage } = options;
  switch (type) {
    case ItemsType.SOLUTION: {
      return dataProvider.getList<PsRecord>('solutions', {
        pagination: { page: page + 1, perPage },
        sort: { field: 'createdAt', order: 'DESC' },
        filter: {
          owner: profileId,
          $custom: { type: 'forUi' }
        }
      });
    }
    case ItemsType.PROBLEM: {
      return dataProvider.getList<PsRecord>('problems', {
        pagination: { page: page + 1, perPage },
        sort: { field: 'createdAt', order: 'DESC' },
        filter: {
          owner: profileId,
          $custom: { type: 'forUi' }
        }
      });
    }
    case ItemsType.INVENTION: {
      return dataProvider.getList<PsRecord>('applications', {
        pagination: { page: page + 1, perPage },
        sort: { field: 'createdAt', order: 'DESC' },
        filter: {
          owner: profileId,
          $custom: { type: 'forUi' }
        }
      });
    }
    case ItemsType.APPLICATION: {
      return dataProvider.getList<PsRecord>('applications', {
        pagination: { page: page + 1, perPage },
        sort: { field: 'createdAt', order: 'DESC' },
        filter: {
          owner: profileId,
          $custom: { type: TAG_TYPES.IS_FILED }
        }
      });
    }
    case ItemsType.NFT: {
      return dataProvider.getList<PsRecord>('applications', {
        pagination: { page: page + 1, perPage },
        sort: { field: 'createdAt', order: 'DESC' },
        filter: {
          owner: profileId,
          $custom: { type: 'nftOnly' }
        }
      });
    }
    default: {
      return {
        data: [],
        total: 0
      };
    }
  }
};

const renderItemDefault = (record: PsRecord, type: string): React.ReactNode => {
  switch (type) {
    case ItemsType.SOLUTION: {
      const solution = record as Solution;
      return <CardSolutionSmall key={solution.id} solution={solution} />;
    }
    case ItemsType.PROBLEM: {
      const problem = record as Problem;
      return <CardProblemSmall key={problem.id} problem={problem} />;
    }
    case ItemsType.INVENTION:
    case ItemsType.APPLICATION:
    case ItemsType.NFT: {
      const app = record as Application;
      return <CardApplicationSmall key={app.id} application={app} />;
    }
    default: {
      return <span key={record.id}>{record.id}</span>;
    }
  }
};

type ClassKey =
  | 'root'
  | 'header'
  | 'headerLeft'
  | 'headerIcon'
  | 'block'
  | 'loading';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      marginBottom: -10
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start'
    },
    headerLeft: {
      display: 'flex',
      alignItems: 'center'
    },
    headerIcon: {
      marginRight: 10
    },
    block: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginTop: 10
    },
    loading: {
      textAlign: 'center'
    }
  };
});

type ProfileRelatedItemsProps = {
  profileId: string | number;
  itemsType: string;
  perPage?: number;
  loadData?: (
    dataProvider: DataProvider,
    profileId: string | number,
    type: string,
    options: { perPage: number; page: number }
  ) => Promise<{ data: Array<PsRecord>; total: number }>;
  renderItem?: (record: PsRecord, type: string) => React.ReactNode;
};

const ProfileRelatedItemsView = ({
  profileId,
  itemsType,
  loadData = loadDataDefault,
  renderItem = renderItemDefault,
  perPage = 3
}: ProfileRelatedItemsProps) => {
  const { dataProvider } = useContext(DataContext);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [items, setItems] = useState<Array<PsRecord>>([]);
  const { classes } = useStyles();

  useEffect(() => {
    setLoading(true);
    loadData(dataProvider, profileId, itemsType, { perPage, page })
      .then(({ data, total }) => {
        setItems(data);
        setTotalPages(Math.ceil(total / perPage));
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [profileId, itemsType, perPage, page]);

  const onPageChange = (page: number) => {
    setPage(page);
  };

  return (
    <div className={classes.root}>
      <div className={classes.block}>
        {loading ? (
          <Typography className={classes.loading} variant="body2">
            Loading...
          </Typography>
        ) : null}
        {items.map((item) => {
          return renderItem(item, itemsType);
        })}
        <Pagination total={totalPages} active={page} onChange={onPageChange} />
      </div>
    </div>
  );
};

export const ProfileRelatedItems = styled(ProfileRelatedItemsView)({});

export default ProfileRelatedItems;

const MyNFTsInfoTitleView = () => {
  const { classes } = useStyles();
  const theme: PsTheme = useTheme();
  return (
    <div className={classes.header}>
      <div className={classes.headerLeft}>
        <PsLogo
          className={classes.headerIcon}
          small
          size={55}
          color={theme.palette.secondary.main}
        />
        <span>My NFTs:</span>
      </div>
    </div>
  );
};

export const MyNFTsInfoTitle = styled(MyNFTsInfoTitleView)({});
