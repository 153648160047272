/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { IoIosNotifications } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Avatar,
  Box,
  CircularProgress,
  Skeleton,
  Tooltip,
  styled
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import MuiIconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { PopoverOrigin } from '@mui/material/Popover';
import { PlusCircleIcon } from 'assets/icons';
import ideaCoinPurpleIcon from 'assets/icons/ideaCoinPurple.svg';
import ideaCoinYellowIcon from 'assets/icons/ideaCoinYellow.svg';
import triangleDownIcon from 'assets/icons/triangle-down.svg';
import walletIcon from 'assets/icons/wallet.svg';
import logo from 'assets/images/logo.svg';
import { AutoSearch } from 'components/AutoSearch';
import { ItemsType } from 'components/CardProfile';
import { CustomMenu } from 'components/CustomMenu';
import { GetActions } from 'components/CustomMenu/actions';
import IconButton from 'components/IconButton';
import { MyWallet } from 'components/MyWallet';
import { NotificationsPopup } from 'components/NotificationsPopup';
import { RewardsPoolPopup } from 'components/RewardsPoolPopup';
import { PsButton } from 'components/common/PsButton';
import pusher from 'config/pusherConfig';
import { AuthContext } from 'contexts/AuthContext';
import { AuthFormsContext } from 'contexts/AuthFormsContext';
import { DataContext } from 'contexts/DataContext';
import { ModalComponentsKeys, ModalContext } from 'contexts/ModalContext';
import { ModalName, SimpleModalContext } from 'contexts/SimpleModalContext';
import { getOwnerMaticBalance, getQuery } from 'helpers';
import { showUserIdeaBalance } from 'helpers/blockchain';
import { getToken } from 'helpers/common';
import getQueryParams from 'helpers/getQueryParams';
import useRouter from 'hooks/useRouter';
import { AuthModal } from 'modals/AuthModal';
import { CreditsTopUpModal } from 'modals/CreditsTopUpModal';
import { SubscriptionModal } from 'modals/SubscriptionModal';
import { ToastContainer } from 'react-toastify';
import Actions from 'redux-state/actions';
import { setConcept } from 'redux-state/ideamap/actions';
import { setLoginUser } from 'redux-state/onboarding/actions';
import {
  GetOpenAuthModal,
  GetOpenCredtisTopUpModal,
  GetOpenSubscriptionModal,
  GetUnreadNotificationCount,
  GetUser
} from 'redux-state/selectors';
import { colorPalette, useIsMediumScreen } from 'theme';
import {
  Constants,
  ERRORS,
  FORM_VIEW,
  QUERY_PARAMS,
  TAG_TYPES,
  VARIANT
} from 'utilities/constants';
import styles from './Header.module.scss';
import {
  EarningItem,
  IconButtonWrapper,
  IdeaButton,
  IdeaCoinsLabel,
  IdeaCoinsNumber,
  IdeaCoinsText,
  IdeaPointsLabel,
  IdeaPointsNumber,
  IdeaPointsText,
  StyledBadge,
  StyledMobileImage,
  StyledSubContainer
} from './styledComponents';

const menuAnchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'right'
};
const menuTransformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right'
};
type HeaderProps = {
  className?: string;
  onMenuToggle: (show?: boolean) => void;
};
export const Header = ({ className, onMenuToggle }: HeaderProps) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { openModal } = useContext(ModalContext);
  const { updateModalQueue, showWalletHistory } =
    useContext(SimpleModalContext);
  const user = GetUser();
  const { auth, loading, setUser } = useContext(AuthContext);
  const { tryLoginFromUrl } = useContext(AuthFormsContext);
  const {
    firstRewardCache,
    firstProblemCache,
    firstSolutionCache,
    firstInventionCache,
    showToast
  } = useContext(DataContext);
  const [openRewardsPopup, setOpenRewardsPopup] = useState(false);
  const [openNotificationsPopup, setOpenNotificationsPopup] = useState(false);
  const unreadNotiCount = GetUnreadNotificationCount();
  const [userMenuOpened, setUserMenuOpened] = useState(false);
  const [ownerMaticBalance, setOwnerMaticBalance] = useState<number>();
  const [ideaCoins, setIdeaCoins] = useState<number | null>(null);
  const [formView, setFormView] = useState<string>(FORM_VIEW.LOGIN);
  const [showWallet, setShowWallet] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openAuthModal = GetOpenAuthModal();
  const openCreditsTopUpModal = GetOpenCredtisTopUpModal();
  const openSubModal = GetOpenSubscriptionModal();

  const userMenuAnchorRef = useRef<Element | null>(null);
  const rewardsPopupAnchorRef = useRef<Element | null>(null);
  const notificationsPopupAnchorRef = useRef<Element | null>(null);

  const resetToken = getQueryParams(QUERY_PARAMS.RESET_TOKEN);
  const isMediumScreen = useIsMediumScreen();
  const isMenuOpen = Boolean(anchorEl);
  const [unreadCount, setUnreadCount] = useState<number>(unreadNotiCount);

  let search = '';
  if (typeof window !== 'undefined') {
    search = window.location.search;
  }
  useEffect(() => {
    if (user?._id ?? user?.id) {
      dispatch(Actions.getProfile(user?._id ?? user?.id));
    }
  }, []);

  useEffect(() => {
    if (unreadNotiCount) setUnreadCount(unreadNotiCount);
  }, [unreadNotiCount]);

  useEffect(() => {
    if (user) {
      const token = getToken();
      if (!token) {
        dispatch(setLoginUser(null));
      }
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (resetToken && !user) {
      setFormView(FORM_VIEW.SET_NEW_PASSWORD);
      dispatch(Actions.openAuthModal(true));
    }
  }, [resetToken, user]);

  useEffect(() => {
    if (user) {
      showUserIdeaBalance(user.walletAddress)
        .then((balance) => {
          setIdeaCoins(balance);
        })
        .catch((error) => {
          console.error(ERRORS.GET_IDEA_COINS, error.message);
        });
    }
  }, [user]);

  useEffect(() => {
    const fetchBalance = async () => {
      const balance = await getOwnerMaticBalance();
      setOwnerMaticBalance(Number(balance));
    };
    fetchBalance();
  }, []);

  useEffect(() => {
    if (loading) {
      return;
    }
    const { e, openPopup } = getQuery();
    if (e || openPopup) {
      const newUrl = location.origin + location.pathname;
      router.replace(router.pathname + newUrl);
    }
    let e2 = '';
    if (typeof window !== 'undefined') {
      e2 = window.localStorage['loginEmail'];
    }
    if ((e || e2) && typeof window !== 'undefined') {
      tryLoginFromUrl({ email: e || e2 });
    } else if (openPopup && !user && typeof window !== 'undefined') {
      window.localStorage['afterLoginPopup'] = openPopup;
      dispatch(Actions.openAuthModal(true));
    } else if (openPopup) {
      openModal(openPopup || '');
    }
  }, [router.query, loading]);

  useEffect(() => {
    if (firstRewardCache) {
      updateModalQueue({ name: ModalName.FIRST_POINT, data: firstRewardCache });
    }
  }, [firstRewardCache]);

  useEffect(() => {
    if (firstProblemCache) {
      updateModalQueue({
        name: ModalName.FIRST_PROBLEM,
        data: firstProblemCache
      });
    }
  }, [firstProblemCache]);

  useEffect(() => {
    if (firstSolutionCache) {
      updateModalQueue({
        name: ModalName.FIRST_SOLUTION,
        data: firstSolutionCache
      });
    }
  }, [firstSolutionCache]);
  useEffect(() => {
    if (firstInventionCache) {
      updateModalQueue({
        name: ModalName.FIRST_INVENTION,
        data: firstInventionCache
      });
    }
  }, [firstInventionCache]);

  useEffect(() => {
    if (!user) return;

    const handleNewNotification = () => {
      dispatch(
        Actions.getUnreadNotificationCount(
          userId,
          Constants.NOTIFICATION,
          { $ne: TAG_TYPES.TAG },
          { $eq: false },
          { $ne: true }
        )
      );
      showToast(Constants.NEW_NOTIFICATION_RECEIVED, {
        style: {
          position: 'absolute',
          bottom: 0,
          left: 77,
          backgroundColor: colorPalette.white,
          color: colorPalette.black
        },
        autoHideDuration: 5000
      });
    };

    const userId = user?._id ?? user?.id;
    const progressChannel = pusher.subscribe(Constants.NOTIFICATIONS);
    progressChannel.bind(userId, handleNewNotification);

    return () => {
      progressChannel.unbind(userId, handleNewNotification);
      pusher.unsubscribe(Constants.NOTIFICATIONS);
    };
  }, [user, dispatch, showToast]);

  const onMenuIconOpenClick = () => {
    onMenuToggle(true);
  };
  const toggleModal = useCallback(() => {
    openModal(ModalComponentsKeys.addNewEntity);
  }, [openModal]);
  const onIdeaPointsClick = useCallback(() => {
    showWalletHistory(user?._id ?? user.id, user?.ideaPoints);
  }, [showWalletHistory, user]);
  const onUserMenuClick = useCallback(() => {
    setUserMenuOpened(true);
  }, [setUserMenuOpened]);
  const onUserMenuClose = useCallback(() => {
    setUserMenuOpened(false);
  }, [setUserMenuOpened]);
  const onFullFlowClick = () => {
    openModal(ModalComponentsKeys.addNewFullFlow);
    setUserMenuOpened(false);
  };
  const onMyProfileClick = useCallback(() => {
    router.push(`/profiles/${user?.key}`);
    setUserMenuOpened(false);
  }, [router, user, setUserMenuOpened]);
  const onMyActivityClick = useCallback(() => {
    router.push(`/activity/my`);
    setUserMenuOpened(false);
  }, []);
  const onAdminPanelClick = useCallback(() => {
    if (typeof window !== 'undefined') {
      window.location.href = '/admin';
    }
  }, []);
  const onLoginClick = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(Actions.openAuthModal(true));
  }, []);
  const onLogoutClick = useCallback(() => {
    dispatch(setLoginUser(null));
    dispatch(setConcept({}));
    setUserMenuOpened(false);
    setUser(undefined);
    auth.logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const setUserMenuAnchorRef = useCallback((el: Element | null) => {
    userMenuAnchorRef.current = el;
  }, []);
  const setRewardsPopupAnchorRef = useCallback((el: Element | null) => {
    rewardsPopupAnchorRef.current = el;
  }, []);
  const setNotificationsPopupAnchorRef = useCallback((el: Element | null) => {
    notificationsPopupAnchorRef.current = el;
  }, []);
  const OnIdeaCoinsClick = useCallback(() => {
    setOpenRewardsPopup(true);
  }, []);
  const onNotificationsClick = useCallback(() => {
    setOpenNotificationsPopup(true);
  }, []);
  const handleAuthModalClose = useCallback(() => {
    dispatch(Actions.openAuthModal(false));
    if (formView == FORM_VIEW.SET_NEW_PASSWORD) {
      router.push('/feeds');
    }
    setFormView(FORM_VIEW.LOGIN);
    dispatch(Actions.updateErrorStatus(null, ''));
    dispatch(Actions.resetPasswordResponse({}));
  }, [dispatch, formView, router]);
  const handleWalletClick = useCallback(() => {
    setShowWallet(true);
  }, []);
  const getActions = useMemo(() => {
    return () => {
      return GetActions({
        type: 'LoginMenu',
        setUserMenuOpened,
        user
      });
    };
  }, [user]);
  interface ButtonProps {
    count?: number;
    iconUrl?: string;
    marginRight?: string;
    onClick: () => void;
    setRef?: (el: Element | null) => void;
    toolTip?: string;
    type?: string;
  }
  const NotificationButton = ({
    marginRight,
    onClick,
    setRef,
    toolTip
  }: ButtonProps) => {
    return (
      <Tooltip title={toolTip}>
        <Box onClick={onClick} ref={setRef} marginRight={marginRight}>
          <IdeaButton onClick={onClick} ref={setRef} marginRight={marginRight}>
            <StyledBadge badgeContent={unreadCount}>
              <IoIosNotifications color={colorPalette.purple} size={30} />
            </StyledBadge>
          </IdeaButton>
        </Box>
      </Tooltip>
    );
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="header-spacer">
      <ToastContainer />
      <AppBar className="header-wrapper" position="fixed">
        <div className="header-body">
          <a href="/" rel="noreferrer">
            <img src={logo} alt="mindminer" className={styles.logoImg} />
          </a>
          <StyledSubContainer isMediumScreen={isMediumScreen}>
            {!isMediumScreen && user && <AutoSearch />}
          </StyledSubContainer>
          <div className={styles.right}>
            {loading ? (
              <div>
                <CircularProgress
                  className={styles.loader}
                  size={28}
                  thickness={2}
                />
              </div>
            ) : (
              <>
                <div className={styles.linksWrapper}>
                  {!user ? (
                    <>
                      <div key="login" className={styles.linkWrapper}>
                        <a
                          href="#"
                          onClick={onLoginClick}
                          className={styles.link}
                        >
                          Login
                        </a>
                      </div>
                      {openAuthModal && (
                        <AuthModal
                          formView={formView}
                          onClose={handleAuthModalClose}
                          open={openAuthModal}
                          setFormView={setFormView}
                        />
                      )}
                    </>
                  ) : null}
                </div>
                {user ? (
                  <Box className={styles.userInfo}>
                    {ownerMaticBalance && ideaCoins != null ? (
                      <>
                        {isMediumScreen ? (
                          <>
                            <MuiIconButton onClick={handleMenuOpen}>
                              <MoreVertIcon />
                            </MuiIconButton>

                            <PsButton
                              onClick={toggleModal}
                              disableElevation
                              className={`${styles.addButton}`}
                              sx={{ marginRight: '0.9375rem' }}
                            >
                              <img src={PlusCircleIcon} alt="plus icon" />
                            </PsButton>
                            <Menu
                              id="wallet-menu"
                              anchorEl={anchorEl}
                              keepMounted
                              open={isMenuOpen}
                              onClose={handleMenuClose}
                            >
                              <MenuItem onClick={handleWalletClick}>
                                <StyledMobileImage>
                                  <img
                                    src={walletIcon}
                                    alt={Constants.MY_WALLET}
                                  />
                                </StyledMobileImage>
                                {Constants.MY_WALLET}
                              </MenuItem>
                              <MenuItem onClick={onIdeaPointsClick}>
                                <img
                                  src={ideaCoinPurpleIcon}
                                  alt={Constants.POINTS}
                                  style={{ marginRight: '0.625rem' }}
                                />
                                {Constants.POINTS}
                              </MenuItem>
                              <MenuItem onClick={OnIdeaCoinsClick}>
                                <img
                                  src={ideaCoinYellowIcon}
                                  alt={Constants.COINS}
                                  style={{ marginRight: '0.625rem' }}
                                />
                                {Constants.COINS}
                              </MenuItem>
                            </Menu>
                          </>
                        ) : (
                          <>
                            <EarningItem>
                              <img
                                width="1.4275rem"
                                height="1.4275rem"
                                src={ideaCoinPurpleIcon}
                                alt={Constants.POINTS}
                              />
                              <IdeaPointsText>
                                <IdeaPointsLabel>
                                  {Constants.POINTS}
                                </IdeaPointsLabel>
                                <IdeaPointsNumber>
                                  {user?.ideaPoints}
                                </IdeaPointsNumber>
                              </IdeaPointsText>
                            </EarningItem>
                            <EarningItem
                              onClick={OnIdeaCoinsClick}
                              ref={setRewardsPopupAnchorRef}
                            >
                              <img
                                src={ideaCoinYellowIcon}
                                alt={Constants.COINS}
                              />
                              <IdeaCoinsText>
                                <IdeaCoinsLabel>
                                  {Constants.COINS}
                                </IdeaCoinsLabel>
                                <IdeaCoinsNumber>
                                  {ideaCoins > 1000
                                    ? Constants.ONE_THOUSAND_PLUS
                                    : ideaCoins}
                                </IdeaCoinsNumber>
                              </IdeaCoinsText>
                            </EarningItem>
                          </>
                        )}
                        <NotificationButton
                          count={unreadNotiCount}
                          marginRight={!isMediumScreen ? '.0125rem' : '0.3rem'}
                          onClick={onNotificationsClick}
                          setRef={setNotificationsPopupAnchorRef}
                          toolTip={Constants.NOTIFICATIONS_C}
                          type={ItemsType.IDEA_COINS}
                        />
                        {openRewardsPopup && (
                          <RewardsPoolPopup
                            anchorEl={rewardsPopupAnchorRef.current}
                            handleWalletClick={handleWalletClick}
                            open={openRewardsPopup}
                            setOpen={setOpenRewardsPopup}
                          />
                        )}
                        {openNotificationsPopup && (
                          <NotificationsPopup
                            open={openNotificationsPopup}
                            setOpen={setOpenNotificationsPopup}
                            setUnreadCount={setUnreadCount}
                          />
                        )}
                      </>
                    ) : (
                      [...Array(3)].map((_, index) => (
                        <Skeleton
                          key={index}
                          variant={VARIANT.ROUNDED}
                          width={48}
                          height={48}
                          sx={{ marginRight: '20px' }}
                        />
                      ))
                    )}
                    <StyledBox>
                      <PsButton
                        onClick={toggleModal}
                        disableElevation
                        className={`${styles.addButton}`}
                      >
                        <img src={PlusCircleIcon} alt="plus icon" />
                      </PsButton>
                      <Box className={styles.profileMenuDropdown}>
                        <CustomMenu actions={getActions()} type="LoginMenu" />
                        <img
                          src={triangleDownIcon}
                          alt="dropdown icon"
                          className={styles.profileMenuDropdownIcon}
                        />
                      </Box>
                    </StyledBox>
                  </Box>
                ) : null}
                {showWallet && (
                  <MyWallet open={showWallet} setOpen={setShowWallet} />
                )}
              </>
            )}
            {openCreditsTopUpModal && (
              <CreditsTopUpModal open={openCreditsTopUpModal} />
            )}
            {openSubModal && <SubscriptionModal open={openSubModal} />}
            <Box className={styles.mobileHolder}>
              <StyledMenuItem>
                <Box
                  className={styles.profileMenuDropdown}
                  ref={setUserMenuAnchorRef}
                >
                  <PsButton
                    disableElevation
                    className={`${styles.headerButton} ${styles.userDropdownButton}`}
                    onClick={onUserMenuClick}
                  >
                    <StyledImage src={user?.files?.[0]?.url} />
                  </PsButton>
                  <img
                    src={triangleDownIcon}
                    alt="dropdown icon"
                    className={styles.profileMenuDropdownIcon}
                  />
                  <Menu
                    anchorEl={userMenuAnchorRef.current}
                    keepMounted
                    open={userMenuOpened}
                    onClose={onUserMenuClose}
                    anchorOrigin={menuAnchorOrigin}
                    transformOrigin={menuTransformOrigin}
                  >
                    <MenuItem onClick={onMyProfileClick}>My Profile</MenuItem>
                    <MenuItem onClick={onMyActivityClick}>My Activity</MenuItem>
                    <MenuItem onClick={onAdminPanelClick}>Admin Panel</MenuItem>
                    <MenuItem onClick={onLogoutClick}>Logout</MenuItem>
                  </Menu>
                </Box>
              </StyledMenuItem>
            </Box>
          </div>
        </div>
      </AppBar>
      <div className="header-spacer"></div>
    </div>
  );
};
const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
}));
const StyledImage = styled(Avatar)({
  width: '3rem',
  height: '3rem',
  borderRadius: '.625rem'
});
const StyledMenuItem = styled(MenuItem)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0.3125rem'
});
export default Header;
