import { useSelector } from 'react-redux';
import { RootState } from '..';

export const GetConceptData = () =>
  useSelector((state: RootState) => state.ideamap.concept);

export const GetGraphData = () =>
  useSelector((state: RootState) => state.ideamap.graph);

export const GetLoadAiGeneration = () =>
  useSelector((state: RootState) => state.ideamap.aiGenerationLoader);

export const GetLoadContests = () =>
  useSelector((state: RootState) => state.ideamap.contestsLoader);

export const GetLoadImageGeneration = () =>
  useSelector((state: RootState) => state.ideamap.imageGenerationLoader);

export const GetLoadImageUpload = () =>
  useSelector((state: RootState) => state.ideamap.imageUploadLoader);

export const GetLoadNewConcept = () =>
  useSelector((state: RootState) => state.ideamap.newConceptLoader);

export const GetSolutionLoader = () =>
  useSelector((state: RootState) => state.ideamap.solutionLoader);

export const GetToggleConceptCart = () =>
  useSelector((state: RootState) => state.ideamap.isConceptCartOpen);

export const GetToggleSideDrawer = () =>
  useSelector((state: RootState) => state.ideamap.isSideDrawerOpen);

export const GetCircularProgressValueForProduct = (productId) =>
  useSelector((state: RootState) => state.ideamap[productId] || 0);

export const GetCircularProgressValue = () =>
  useSelector((state: RootState) => state.ideamap.circularProgressValue);

export const GetSelectedItem = () =>
  useSelector((state: RootState) => state.ideamap.selectedItem);
export const GetUpdateConceptLoader = () =>
  useSelector((state: RootState) => state.ideamap.updateConceptLoader);

export const GetConceptsLoader = () =>
  useSelector((state: RootState) => state.ideamap.getConceptsLoader);
