/* eslint-disable */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Actions from 'redux-state/actions';
import { Box, Drawer, Typography } from '@mui/material';
import {
  GetConceptsCount,
  GetDownloadPatentLoader,
  GetEditProfileItemLoader,
  GetProfileConcepts,
  GetProfileConceptsLoader
} from 'redux-state/selectors';
import MenuIcon from '@mui/icons-material/Menu';
import { LinearProgress, Button, IconButton } from '@mui/material';
import ConceptIcon from 'assets/icons/concepts.svg';
import getQueryParams from 'helpers/getQueryParams';
import { CHANNELS, Constants } from 'utilities/constants';
import { colorPalette, useIsMediumScreen } from 'theme';
import { Application } from '../CardApplication';
import { Profile } from '../CardProfile';
import ConceptList from './ConceptList';
import { ConceptDetailView } from './ConceptDetail';
import pusher from '../../config/pusherConfig';
import DataNotFound from '../DataNotFound';
import { StyledConceptDetailBox } from './styles';

interface ConceptViewProps {
  profile: Profile;
  archiveStatus: boolean;
  handleArchiveToggle: (archive: boolean) => void;
}

const ConceptView: React.FC<ConceptViewProps> = ({
  archiveStatus,
  handleArchiveToggle,
  profile
}) => {
  const dispatch = useDispatch();

  const conceptId = getQueryParams(Constants.ID);
  const [selectedConceptId, setSelectedConceptId] = useState<
    number | string | null
  >();
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const concepts: Array<Application> = GetProfileConcepts();
  const conceptsCount: number = GetConceptsCount();
  const conceptsLoader: boolean = GetProfileConceptsLoader();
  const downloadLoader: boolean = GetDownloadPatentLoader();
  const editProfileLoader: boolean = GetEditProfileItemLoader();
  const selectedConcept = concepts?.find(
    (concept) => concept.id === selectedConceptId
  );

  const isMediumScreen = useIsMediumScreen();

  const paramsPage = Number(getQueryParams(Constants.PAGE));
  const paramsPerPage = Number(getQueryParams(Constants.PER_PAGE));

  const [page, setPage] = useState<number>(paramsPage ?? 0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(paramsPerPage || 5);

  const paginationOptions = useMemo(
    () => ({
      page: page,
      rowsPerPage: rowsPerPage,
      setPage: setPage,
      setRowsPerPage: setRowsPerPage
    }),
    [page, rowsPerPage, setRowsPerPage, setPage]
  );

  const pagination = useMemo(
    () => ({
      page: page,
      perPage: rowsPerPage
    }),
    [page, rowsPerPage]
  );

  //TODO: commenting for testing video loader
  const fetchProfileConcepts = useCallback(() => {
    if (profile) {
      // dispatch(
      //   Actions.getProfileConcepts(profile?.id ?? profile?._id, pagination, null, archiveStatus)
      // );
    }
  }, [dispatch, profile, pagination, archiveStatus]);

  useEffect(() => {
    if (profile) {
      dispatch(
        Actions.getProfileConcepts(
          profile?.id ?? profile?._id,
          pagination,
          null,
          archiveStatus
        )
      );
    }
  }, [dispatch, pagination, profile, archiveStatus]);

  useEffect(() => {
    fetchProfileConcepts();
    if (conceptId) {
      setSelectedConceptId(conceptId);
    }
  }, [fetchProfileConcepts, dispatch, conceptId]);

  useEffect(() => {
    const channel = pusher.subscribe(CHANNELS.VIDEO_GENERATION_CHANNEL);
    channel.bind(Constants.VIDEOS, function (data) {
      dispatch(
        Actions.getProfileConcepts(
          profile?.id ?? profile?._id,
          pagination,
          null,
          archiveStatus
        )
      );
    });
    return () => {
      channel.unbind(Constants.VIDEOS);
      pusher.unsubscribe(CHANNELS.VIDEO_GENERATION_CHANNEL);
    };
  }, [dispatch, profile, pagination, archiveStatus]);

  const toggleDrawer = (open: boolean) => {
    setDrawerOpen(open);
  };

  if (!conceptsLoader && concepts?.length === 0) {
    return (
      <DataNotFound
        iconUrl={ConceptIcon}
        text={Constants.CONCEPTS}
        isInventionView={true}
      />
    );
  }

  return (
    <Box
      display="flex"
      width="100%"
      sx={{ backgroundColor: colorPalette.white }}
    >
      {(downloadLoader || editProfileLoader || conceptsLoader) && (
        <LinearProgress />
      )}
      {!isMediumScreen ? (
        <>
          <Box width="35%" padding={2}>
            <ConceptList
              concepts={concepts}
              selectedConceptId={selectedConceptId}
              onSelectConcept={setSelectedConceptId}
              totalRows={conceptsCount}
              paginationOptions={paginationOptions}
              isConcept={true}
            />
          </Box>
          <StyledConceptDetailBox width={'63%'}>
            <ConceptDetailView
              selectedConceptId={selectedConceptId}
              profile={profile}
              archiveStatus={archiveStatus}
              pagination={pagination}
              fetchProfileConcepts={fetchProfileConcepts}
              isConcept={true}
            />
          </StyledConceptDetailBox>
        </>
      ) : (
        <>
          <StyledConceptDetailBox width={'100%'}>
            <Box display={'flex'} flexDirection={'row'}>
              <IconButton
                aria-label="open drawer"
                onClick={() => toggleDrawer(true)}
                sx={{ color: colorPalette.purple }}
              >
                <MenuIcon sx={{ fontSize: 30 }} />
                Concept List
              </IconButton>
            </Box>
            <ConceptDetailView
              selectedConceptId={selectedConceptId}
              profile={profile}
              archiveStatus={archiveStatus}
              pagination={pagination}
              fetchProfileConcepts={fetchProfileConcepts}
              isConcept={true}
            />
          </StyledConceptDetailBox>
          <Drawer
            anchor="left"
            open={drawerOpen}
            onClose={() => toggleDrawer(false)}
            PaperProps={{
              sx: { height: '80vh', top: '100px', position: 'fixed' }
            }}
          >
            <Box
              width="300px"
              height="100%"
              padding={2}
              role="presentation"
              sx={{ overflow: 'auto' }}
            >
              <Typography variant="h6" mb={2}>
                Select a Concept
              </Typography>
              <ConceptList
                concepts={concepts}
                selectedConceptId={selectedConceptId}
                onSelectConcept={(id) => {
                  setSelectedConceptId(id);
                  toggleDrawer(false);
                }}
                totalRows={conceptsCount}
                paginationOptions={paginationOptions}
                isConcept={true}
              />
            </Box>
          </Drawer>
        </>
      )}
    </Box>
  );
};

export { ConceptView };
