import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

interface LogoProps extends SvgIconProps {
  width?: number | string;
  height?: number | string;
}

export const FacebookColoredIcon: React.FC<LogoProps> = ({
  width = '1.8rem',
  height = '1.8rem',
  ...props
}) => {
  return (
    <SvgIcon sx={{ width: width, height: height }} viewBox="0 0 100 100">
      <g id="Layer_1" />
      <g id="Layer_2">
        <g>
          <path
            d="m50 2.5c-58.892 1.725-64.898 84.363-7.46 95h7.46 7.46c57.451-10.647 51.419-93.281-7.46-95z"
            fill="#1877f2"
          />
          <path
            d="m57.46 64.104h11.125l2.117-13.814h-13.242v-8.965c0-3.779 1.85-7.463 7.781-7.463h6.021v-11.761c-12.894-2.323-28.385-1.616-28.722 17.66v10.529h-12.123v13.814h12.123v33.396h7.46 7.46z"
            fill="#f1f1f1"
          />
        </g>
      </g>
    </SvgIcon>
  );
};
