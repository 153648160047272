import { Avatar, Box, Typography, styled } from '@mui/material';
import { colorPalette } from 'theme';

export const MainContainerBox = styled(Box)({
  alignItems: 'center',
  backgroundColor: colorPalette.white,
  borderRadius: '0.5rem',
  boxShadow: `0 0.0625rem 0.4375rem ${colorPalette.ebb}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: '1.5rem 0rem',
  paddingBottom: '1.5rem',
  width: '100%'
});

export const StyledUserAvatar = styled(Avatar)({
  height: '5.8125rem',
  marginTop: '-3rem',
  width: '5.8125rem'
});

export const StyledUserName = styled(Box)({
  fontSize: '1.125rem',
  fontWeight: 700,
  lineHeight: '1.3713rem',
  marginTop: '0.8rem',
  maxWidth: '18.125rem',
  overflowY: 'clip',
  textOverflow: 'ellipsis',
  textTransform: 'capitalize',
  whiteSpace: 'nowrap',
  cursor: 'pointer',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline'
  }
});

export const ImageBox = styled('img')({
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  borderTopLeftRadius: '0.625rem',
  borderTopRightRadius: '0.625rem',
  height: '5.5rem',
  objectFit: 'cover',
  width: '100%'
});

export const StyledInfoTypography = styled(Typography)({
  color: colorPalette.lightBlack,
  fontSize: '0.8125rem',
  fontWeight: 400,
  lineHeight: '1.125rem',
  marginTop: '0.3125rem',
  maxWidth: '18.125rem',
  overflowY: 'clip',
  textOverflow: 'ellipsis',
  textTransform: 'capitalize',
  whiteSpace: 'nowrap'
});

export const StyledIdeapointsInfo = styled(Box)({
  color: `${colorPalette.purple}`,
  cursor: 'pointer',
  fontSize: '0.8125rem',
  fontWeight: 400,
  lineHeight: '1.125rem',
  marginTop: '0.3125rem',
  maxWidth: '18.125rem',
  overflowY: 'clip',
  textOverflow: 'ellipsis',
  textTransform: 'capitalize',
  whiteSpace: 'nowrap',
  verticalAlign: 'baseline',
  '&:hover': {
    textDecoration: 'underline'
  }
});

export const CommunityNum = styled(Box)({
  color: `${colorPalette.purple}`,
  cursor: 'pointer',
  display: 'inline-block',
  fontSize: '0.9375rem',
  fontWeight: 700,
  marginTop: '0.3125rem',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline'
  },
  verticalAlign: 'baseline'
});

export const Highlight = styled(Box)({
  color: `${colorPalette.purple}`,
  display: 'inline-block',
  fontSize: '0.8125rem',
  fontWeight: 600,
  lineHeight: '1.125rem',
  verticalAlign: 'baseline'
});
