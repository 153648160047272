import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useDispatch } from 'react-redux';
import { AddCircleOutline } from '@mui/icons-material';
import { Contest } from 'components/CardContest';
import { CardContestView } from 'components/CardContestView/CardContestView';
import { Tag } from 'components/CardTag';
import { CustomScrollbar } from 'components/PerfectScrollbar';
import { BaseModal } from 'modals/Common/BaseModal';
import { ContentSection } from 'modals/Common/ContentSection';
import { HeaderSection } from 'modals/Common/HeaderSection';
import Actions from 'redux-state/actions';
import { GetContests } from 'redux-state/selectors';
import { Constants } from 'utilities/constants';
import {
  ContestsBox,
  HeaderBox,
  StyledButton,
  StyledDivider,
  TitleTypography
} from './styledComponents';

interface SeeAllContestsModalProps {
  contests?: Array<Contest>;
  open?: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  tag: Tag;
  toggleContestModal: any;
  showCreateButton?: boolean;
}

export const SeeAllContestsModal: React.FC<SeeAllContestsModalProps> = ({
  open,
  setOpen,
  tag,
  toggleContestModal,
  showCreateButton = true
}) => {
  const dispatch = useDispatch();
  const lastContestRef = useRef(null);
  const [lastContestIndex, setLastContestIndex] = useState<number>();

  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const contests = GetContests();

  const pagination = {
    page: 0,
    perPage: rowsPerPage
  };

  const CreateContestButton = useMemo(
    () => (
      <StyledButton
        onClick={toggleContestModal}
        variant="contained"
        startIcon={<AddCircleOutline />}
      >
        {Constants.CREATE_A_CONTEST}
      </StyledButton>
    ),
    [toggleContestModal]
  );

  useEffect(() => {
    if (open) {
      dispatch(Actions.getContests({ tagId: tag.id, pagination }));
    }
  }, [tag, rowsPerPage, setOpen]);

  useEffect(() => {
    if (contests) {
      setLastContestIndex(contests.length - 1);
    }
  }, [contests]);

  useEffect(() => {
    if (lastContestRef.current) {
      const lastContestObserver = new IntersectionObserver(
        (entries) => {
          const lastContest = entries[0];
          if (lastContest.isIntersecting) {
            setRowsPerPage((prevRowsPerPage) => prevRowsPerPage + 10);
          }
        },
        { threshold: 0.4 }
      );

      lastContestObserver.observe(lastContestRef.current);

      return () => {
        if (lastContestRef.current) {
          lastContestObserver.unobserve(lastContestRef.current);
        }
      };
    }
  }, [lastContestRef.current]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <BaseModal open={open} onClose={handleClose}>
      <HeaderSection>
        <HeaderBox>
          <TitleTypography>{`${tag.name} ${Constants.C_CONTESTS}`}</TitleTypography>
          {showCreateButton && CreateContestButton}
        </HeaderBox>
      </HeaderSection>
      <StyledDivider />
      <ContentSection>
        <CustomScrollbar>
          {contests.length &&
            contests.map((contest, index) => {
              const isLast = index === lastContestIndex;
              return (
                <ContestsBox
                  key={contest.id}
                  ref={isLast ? lastContestRef : null}
                >
                  <CardContestView
                    contest={contest}
                    inCardCommunity={false}
                    pagination={pagination}
                    tag={tag}
                  />
                </ContestsBox>
              );
            })}
        </CustomScrollbar>
      </ContentSection>
    </BaseModal>
  );
};
