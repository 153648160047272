import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const NumberOne = (props: SvgIconProps) => {
  return (
    <SvgIcon
      sx={{ width: '100%', maxWidth: '73px', height: 'auto' }}
      viewBox="0 0 73 73"
      {...props}
    >
      <g clipPath="url(#clip0_5573_3592)">
        <circle cx="36.5" cy="36.5" r="36.5" fill="#F64B4B" />
        <path
          d="M35.7432 50V26.96L38.2632 29.48H30.7032V24.8H41.5752V50H35.7432Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_5573_3592">
          <rect width="73" height="73" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
