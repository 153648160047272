import React, { useCallback, useContext, useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import {
  ModalDataContext,
  StepComponentType,
  StepComponentProps
} from 'contexts/ModalDataContext';
import { DataContext } from 'contexts/DataContext';
import { AuthContext } from 'contexts/AuthContext';
import { PsOptionType, PsSelect } from 'components/common/PsSelect';
import { PsSelectedList } from 'components/common/PsSelectedList';
import { PsFormControl, ShortEvent } from 'components/common/PsFormControl';
import styles from 'modals/ModalWindow.module.scss';
import { PsTheme } from '../../../theme';

type Problem = {
  id: string;
  title?: string;
};

type ClassKey = 'root';

export type ContestProblemsProps = StepComponentProps;

const ContestProblemsView: StepComponentType<ContestProblemsProps> = ({
  onNotify
}: ContestProblemsProps) => {
  const { values, updateField } = useContext(ModalDataContext);
  const { dataProvider } = useContext(DataContext);
  const { user } = useContext(AuthContext);
  const [problems, setProblems] = useState<Array<PsOptionType>>([]);
  const [selectedProblems, setSelectedProblems] = useState<string>(
    (values.contestProblems || []).join(',')
  );

  useEffect(() => {
    const first = {
      value: '--create--',
      label: `Create New Problem`,
      raw: ''
    };
    let tags: { id: string | number; name: string }[] = [];
    if (user && user.userData && user.userData.moderator) {
      tags = user.userData.moderator;
    }
    const selectedTag = tags.find((tag) => tag.id === values.contestTag);
    const selectedTagName = selectedTag ? selectedTag.name : 'no-tag-selected';
    dataProvider
      .getList<Problem>('problems', {
        pagination: { page: 1, perPage: 50000 },
        sort: { field: 'id', order: 'DESC' },
        filter: { tags: [selectedTagName] }
      })
      .then(({ data = [] }) => {
        const list: Array<PsOptionType> = data.map((item: Problem) => ({
          value: item.id,
          label: item.title || '(empty)'
        }));
        if (values.selectApplication !== 'false') {
          setProblems([first, ...list]);
        } else {
          setProblems(list);
        }
      })
      .catch(() => {
        if (values.selectApplication !== 'false') {
          setProblems([first]);
        } else {
          setProblems([]);
        }
      });
  }, []);

  const onProblemsChange = useCallback(
    (event: ShortEvent) => {
      const { value } = event.target;
      const valueList = value.split(',');
      if (
        value &&
        valueList.some((val: string) => val.startsWith('--create--'))
      ) {
        if (onNotify) {
          onNotify('create problem');
        }
        return;
      }
      setSelectedProblems(value);
      if (value) {
        updateField('contestProblems', value.split(','));
      } else {
        updateField('contestProblems', []);
      }
    },
    [setSelectedProblems]
  );

  const onProblemsInputChange = (text: string) => {
    const first = problems[0];
    const isFirstCreate = first && first.value.startsWith('--create--');

    if (text) {
      const first = {
        value: `--create--, ${text}`,
        label: `Create New Problem`,
        raw: text
      };
      if (isFirstCreate) {
        const [, ...rest] = problems;
        setProblems([first, ...rest]);
      } else {
        setProblems([first, ...problems]);
      }
    }
  };

  return (
    <div className={styles.stepWrapper}>
      <PsFormControl placeholder="Select problems here" label="Select problems">
        <PsSelect
          isMulti
          maxMenuHeight={270}
          name="contestProblems"
          onInputChange={onProblemsInputChange}
          options={problems}
          onChange={onProblemsChange}
          value={selectedProblems}
        />
      </PsFormControl>
      <PsSelectedList
        options={problems}
        value={selectedProblems}
        onChange={onProblemsChange}
      />
    </div>
  );
};

export const ContestProblems = styled(ContestProblemsView)({});

export default ContestProblems;
