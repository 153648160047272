import { Box, styled } from '@mui/material';
import { colorPalette } from 'theme';

export const ContainerBox = styled(Box)(() => ({
  alignItems: 'center',
  borderRadius: '3.125rem',
  display: 'flex',
  height: '3.125rem',
  justifyContent: 'space-between',
  margin: '.3125rem 0',
  padding: '0.25rem .25rem',
  width: '100%'
}));

export const WhiteSpace = styled(Box)(() => ({
  width: '22px'
}));

export const IconWrapper = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex'
}));

export const IconBox = styled(Box)(() => ({
  alignItems: 'center',
  borderRadius: '50%',
  display: 'flex',
  flexShrink: 0,
  height: '2.5rem',
  justifyContent: 'center',
  marginRight: '.625rem',
  width: '2.5rem'
}));

export const ScrollBox = styled(Box)<{ maxHeight: string }>(
  ({ maxHeight }) => ({
    display: 'flex',
    flexDirection: 'column',
    maxHeight: maxHeight,
    overflowX: 'hidden',
    overflowY: 'auto'
  })
);

export const ProblemContainer = styled(ContainerBox)({
  border: `.0625rem solid ${colorPalette.red}`
});

export const SolutionContainer = styled(ContainerBox)({
  border: `.0625rem solid ${colorPalette.lightGreen}`,
  cursor: 'pointer'
});

export const ProblemIcon = styled(IconBox)({
  backgroundColor: colorPalette.pink,
  color: colorPalette.bloodRed
});

export const SolutionIcon = styled(IconBox)({
  backgroundColor: colorPalette.mintTulip,
  color: colorPalette.green
});
