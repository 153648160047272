import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState
} from 'react';
import { SvgIconComponent, SwapHoriz, Telegram } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import CommunityIcon from 'assets/icons/communities.svg';
import { MindMinerLogo } from 'components/icons/MindMinerLogo';
import { BaseModal } from 'modals/Common/BaseModal';
import { TransferModal } from 'modals/TransferModal';
import { GetUser } from 'redux-state/selectors';
import { colorPalette, useIsMediumScreen } from 'theme';
import {
  ASSET_TYPES,
  Breakpoints,
  Constants,
  VARIANT
} from 'utilities/constants';
import { Balance } from './Balance';
import {
  AddressContainer,
  AddressText,
  AddressViewWrapper,
  AmountBox,
  ButtonBox,
  ButtonsWrapper,
  CopyButton,
  HeaderBox,
  IconText,
  LogoWrapper,
  MainContent,
  MainInfoBox,
  SelectWrapper,
  StyledCloseButton,
  StyledCloseIcon,
  StyledCopyIcon,
  StyledIconButton
} from './styledComponents';
import { NftView } from './tabs/NftView';
import { TransactionsView } from './tabs/TransactionsView';
import { WalletSelect } from './WalletSelect';
import { WalletTabsView } from './WalletTabsView';

interface MyWalletProps {
  open?: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
}

const ActionButton: React.FC<{
  Icon: SvgIconComponent;
  text: string;
  marginRight?: string;
  onClick?: () => void;
}> = ({ Icon, text, marginRight, onClick }) => {
  return (
    <ButtonsWrapper>
      <StyledIconButton onClick={onClick}>
        <Icon sx={{ width: '1.125rem', height: '1.125rem', marginRight }} />
      </StyledIconButton>
      <IconText>{text}</IconText>
    </ButtonsWrapper>
  );
};

export const MyWallet: React.FC<MyWalletProps> = ({ open, setOpen }) => {
  const user = GetUser();
  const isMediumScreen = useIsMediumScreen();
  const [tooltipText, setTooltipText] = useState<string>(
    Constants.COPY_TO_CLIPBOARD
  );
  const [walletType, setWalletType] = useState<string>(ASSET_TYPES.MATIC);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [triggerRefresh, setTriggerRefresh] = useState(0);
  const [balance, setBalance] = useState(0);

  const tabs = [
    {
      title: Constants.NFTS,
      iconUrl: CommunityIcon,
      content: <NftView />
    },
    {
      title: Constants.TRANSACTIONS,
      iconUrl: CommunityIcon,
      content: (
        <TransactionsView refresh={triggerRefresh} walletType={walletType} />
      )
    }
  ];

  const handleSendClick = useCallback(() => {
    setShowTransferModal(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const formatAddress = (address: string) => {
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  const handleCopyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(user?.walletAddress);
    setTooltipText(Constants.ADDRESS_COPIED);
  }, [user?.walletAddress]);

  const WalletAddressView = () => {
    return (
      <AddressContainer>
        <AddressText>{formatAddress(user.walletAddress)}</AddressText>
        <Tooltip title={tooltipText} arrow placement={VARIANT.RIGHT}>
          <CopyButton onClick={handleCopyToClipboard}>
            <StyledCopyIcon />
          </CopyButton>
        </Tooltip>
      </AddressContainer>
    );
  };

  const CloseButton = useMemo(() => {
    return (
      <>
        <StyledCloseButton onClick={handleClose}>
          <StyledCloseIcon />
        </StyledCloseButton>
      </>
    );
  }, [handleClose]);

  return (
    <BaseModal open={open} onClose={handleClose} maxWidth={Breakpoints.MEDIUM}>
      <>
        <HeaderBox>
          <LogoWrapper>
            {isMediumScreen ? (
              <WalletSelect
                value={walletType}
                setValue={setWalletType}
                options={[ASSET_TYPES.MATIC, ASSET_TYPES.IDEACOINS]}
                backgroundColor={colorPalette.lavenderGray}
              />
            ) : (
              <MindMinerLogo width={140} height={33} />
            )}
          </LogoWrapper>
          <AddressViewWrapper>
            <WalletAddressView />
          </AddressViewWrapper>
          {!isMediumScreen && CloseButton}
        </HeaderBox>
        <MainContent>
          <MainInfoBox>
            {!isMediumScreen && (
              <SelectWrapper>
                <WalletSelect
                  value={walletType}
                  setValue={setWalletType}
                  options={[ASSET_TYPES.MATIC, ASSET_TYPES.IDEACOINS]}
                />
              </SelectWrapper>
            )}
            <AmountBox>
              <Balance
                address={user.walletAddress}
                refresh={triggerRefresh}
                setWalletBalance={setBalance}
                type={walletType}
              />
            </AmountBox>
            <ButtonBox>
              <ActionButton
                Icon={Telegram}
                text={Constants.C_SEND}
                marginRight=".075rem"
                onClick={handleSendClick}
              />
              <ActionButton Icon={SwapHoriz} text={Constants.SWAP} />
            </ButtonBox>
          </MainInfoBox>
          <WalletTabsView tabs={tabs} initialTab={Constants.NFTS} />
        </MainContent>
        {showTransferModal && (
          <TransferModal
            balance={balance}
            setOpen={setShowTransferModal}
            triggerRefresh={setTriggerRefresh}
            type={walletType}
          />
        )}
      </>
    </BaseModal>
  );
};
