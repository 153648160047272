import { AcceptConditionsStep } from './steps/AcceptConditionsStep';
import { DownloadStep } from './steps/DownloadStep';

import {
  ValidateFormType,
  FormStepConfig,
  DownloadPatentSteps
} from 'contexts/ModalDataContext';

export const DOWNLOAD_PATENT_STEPS_CONFIG: {
  [name: string]: FormStepConfig;
} = {
  [DownloadPatentSteps.AcceptConditionsStep]: {
    Component: AcceptConditionsStep,
    nextStep: DownloadPatentSteps.DownloadStep,
    isNextStepBlocked: (data) => {
      return !data.termsAgree;
    },
    validate: (data): ValidateFormType => {
      if (!data.termsAgree) {
        return {
          success: false,
          errors: { termsAgree: 'Terms required' }
        };
      }
      return { success: true };
    }
  },
  [DownloadPatentSteps.DownloadStep]: {
    Component: DownloadStep,
    isFinalStep: true,
    isNextStepBlocked: (data) => {
      return false;
    },
    validate: (data): ValidateFormType => {
      return { success: true };
    }
  }
};
