import React, { useState, useContext, ReactElement } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import ModalWindow from 'modals/ModalWindow';
import { PsButton } from 'components/common/PsButton';
import { PsLogo } from 'components/common/PsLogo';
import Step from 'components/Step';
import Stepper from 'components/Stepper';
import { Problem } from 'components/CardProblem';
import { Solution } from 'components/CardSolution';
import { Application } from 'components/CardApplication';
import { AuthContext } from 'contexts/AuthContext';
import { ModalContext, ModalComponentsKeys } from 'contexts/ModalContext';
import {
  ModalDataContext,
  ImproveApplicationSteps,
  AddProblemSteps,
  AddSolutionSteps,
  StepComponentProps
} from 'contexts/ModalDataContext';
import { DataContext } from 'contexts/DataContext';
import {
  IMPROVE_APPLICATION_STEPS_CONFIG,
  defaultProblemTitle,
  defaultSolutionTitle,
  stepName
} from './config';
import { getProblemQuestion, getSolutionQuestion } from './texts';
import styles from 'modals/ModalWindow.module.scss';
import { makeStyles } from 'tss-react/mui';
import useRouter from 'hooks/useRouter';

const steps: string[] = [
  'Choose Category',
  'Define problem',
  'Create Solution'
];

const stepsProblem: string[] = ['Problem Name', 'Tag Name', 'Finalize Problem'];

const stepsSolution: string[] = [
  'Solution Name',
  'Tag Name',
  'Finalize Solution'
];

const useButtonClasses = makeStyles()(() => {
  return {
    root: {
      margin: '8px 0',
      fontWeight: 400
    }
  };
});

export const ImproveApplication: React.FC = () => {
  const { refreshUserBalance } = useContext(AuthContext);
  const {
    closeModal,
    isRedirectionEnabled,
    openModal,
    setIsRedirectionEnabled,
    setRootClassName,
    triggerActionCallback
  } = useContext(ModalContext);
  const { values, updateField, resetFields, setErrors } =
    useContext(ModalDataContext);
  const { dataProvider } = useContext(DataContext);
  const theme = useTheme();
  const router = useRouter();
  const [prevSteps, setPrevSteps] = useState<string[]>([]);
  const [activeStepNumber, setActiveStepNumber] = useState<number>(0);
  const [currentStep, setCurrentStep] = useState<string | undefined>(
    ImproveApplicationSteps.ChooseTypeStep
  );
  const [loading, setLoading] = useState(false);
  const [stepsName, setStepsName] = useState<Array<string>>(steps);
  const isProduct = values.selectApplicationType === 'product';
  const isPriorArt = values.selectApplicationType === 'prior-art';

  const buttonClasses = useButtonClasses();

  const {
    Component,
    nextStep,
    validate,
    isNextStepBlocked,
    isFinalStep,
    name,
    modalTitle,
    nextButtonTitle,
    backButtonTitle
  } = IMPROVE_APPLICATION_STEPS_CONFIG[currentStep || ''];

  const StepComponent = Component as React.FC<StepComponentProps>;

  const handleClickBack = () => {
    if (activeStepNumber === 0) {
      closeModal();
      resetFields();
      return;
    }

    if (activeStepNumber === 1) {
      setRootClassName('');
    }

    if (name === stepName.PROBLEM_START || name === stepName.SOLUTION_START) {
      setRootClassName('wide');
      setStepsName(steps);
      updateField('modalTitle', undefined);
    }

    setActiveStepNumber((prev) => Math.max(0, prev - 1));
    setCurrentStep(prevSteps[prevSteps.length - 1]);
    setPrevSteps(prevSteps.slice(0, prevSteps.length - 1));
  };

  const handleClickFinish = async () => {
    const isProduct = values.selectApplicationType === 'product';
    const isPriorArt = values.selectApplicationType === 'prior-art';
    if (typeof validate === 'function') {
      const { success, errors } = validate(values);

      if (!success) {
        setErrors({ ...errors });

        return;
      }
    }

    const problemInfo = {
      title: (values.titleProblem || '').replace(defaultProblemTitle, ''),
      parentApplication:
        !isProduct && !isPriorArt ? values.selectApplication : undefined,
      parentProduct: isProduct ? values.selectApplication : undefined,
      parentPriorArt: isPriorArt ? values.selectApplication : undefined,
      tags: values.tagsProblem,
      body: values.descriptionProblem,
      files: values.filesProblem,
      parentProblem: values.rootProblem,
      isPublic: true
    };

    setLoading(true);

    dataProvider
      .create<Problem>('problems', { data: problemInfo })
      .then(({ data }) => {
        updateField('parentProblemLabel', data.title);
        updateField('modalTitle', undefined);

        const appInfo = {
          title: `an improved ${values.selectApplicationLabel}`,
          parentApplication:
            !isProduct && !isPriorArt ? values.selectApplication : undefined,
          parentProduct: isProduct ? values.selectApplication : undefined,
          parentPriorArt: isPriorArt ? values.selectApplication : undefined,
          problems: [data.id],
          type: values.productId,
          isImproved: true
        };
        return dataProvider
          .create<Application>('applications', {
            data: appInfo
          })
          .then(() => data.key);
      })
      .then((problemKey) => {
        if (!isRedirectionEnabled) {
          triggerActionCallback();
          closeModal();
          setIsRedirectionEnabled(true);
        } else {
          router.push(`/problems/${problemKey}`);
          resetFields();
          refreshUserBalance();
        }
        closeModal();
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleNextStep = async () => {
    const isProduct = values.selectApplicationType === 'product';
    const isPriorArt = values.selectApplicationType === 'prior-art';
    if (typeof validate === 'function') {
      const { success, errors } = validate(values);

      if (!success) {
        setErrors({ ...errors });

        return;
      }
    }

    if (activeStepNumber === 0) {
      setRootClassName('wide');
    }

    if (name === stepName.CATEGORY_SELECT) {
      setLoading(true);
      dataProvider
        .improveApp(
          values.selectApplication || '',
          `${values.productId}`,
          values.selectApplicationType
        )
        .then((data) => {
          if (!data.success) {
            setErrors({ productId: data.message || 'Operation not available' });
            throw new Error(`Vote result: "${JSON.stringify(data)}"`);
          }
          updateField(
            'modalTitle',
            <>
              Add a <b>{values.productId}</b> problem:
            </>
          );
          updateField('rootProblemHide', true);
          updateField('parentProblemHide', true);
          updateField('parentSolutionHide', true);
          updateField('titleProblemLabel', "What's your problem");
          let name = 'invention';
          if (isProduct) {
            name = 'product';
          } else if (isPriorArt) {
            name = 'prior art';
          }
          updateField(
            'introProblemTags',
            <>
              Add tags to the <b>{values.productId}</b> problem with the{' '}
              <b>{values.selectApplicationLabel}</b> {name} as an opportunity
              for improvement.
            </>
          );
          updateField(
            'introProblem',
            getProblemQuestion(values.productId, values.selectApplicationLabel)
          );
          setPrevSteps([
            ImproveApplicationSteps.ChooseTypeStep,
            ImproveApplicationSteps.ChooseSubTypeStep
          ]);
          setErrors({});
          setActiveStepNumber(2);
          setCurrentStep(AddProblemSteps.ProblemName);
          setStepsName(stepsProblem);
          setRootClassName('');
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });

      return;
    }

    if (name === stepName.PROBLEM_END) {
      const problemInfo = {
        title: (values.titleProblem || '').replace(defaultProblemTitle, ''),
        parentApplication:
          !isProduct && !isPriorArt ? values.selectApplication : undefined,
        parentProduct: isProduct ? values.selectApplication : undefined,
        parentPriorArt: isPriorArt ? values.selectApplication : undefined,
        tags: values.tagsProblem,
        body: values.descriptionProblem,
        files: values.filesProblem,
        parentProblem: values.rootProblem,
        isPublic: true
      };

      setLoading(true);

      dataProvider
        .create<Problem>('problems', { data: problemInfo })
        .then(({ data }) => {
          updateField('parentProblem', data.id);
          updateField('parentProblemLabel', data.title);
          updateField(
            'introSolution',
            getSolutionQuestion(
              values.productId,
              values.selectApplicationLabel,
              data.title
            )
          );
          updateField('modalTitle', undefined);
          setPrevSteps([
            ImproveApplicationSteps.ChooseTypeStep,
            ImproveApplicationSteps.ChooseSubTypeStep
          ]);
          setErrors({});
          setActiveStepNumber(5);
          setCurrentStep(AddSolutionSteps.SolutionName);
          setStepsName(stepsSolution);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });

      return;
    }

    if (name === stepName.SOLUTION_END) {
      const solutionInfo = {
        title: (values.titleSolution || '').replace(defaultSolutionTitle, ''),
        tags: values.tagsSolution,
        body: values.descriptionSolution,
        files: values.filesSolution,
        problem: values.parentProblem,
        isPublic: values.finalizeType === 'public'
      };

      setLoading(true);

      dataProvider
        .create<Solution>('solutions', { data: solutionInfo })
        .then(({ data }) => {
          updateField('parentSolutionLabel', data.title);
          const appInfo = {
            title: `an improved ${values.selectApplicationLabel}`,
            parentApplication:
              !isProduct && !isPriorArt ? values.selectApplication : undefined,
            parentProduct: isProduct ? values.selectApplication : undefined,
            parentPriorArt: isPriorArt ? values.selectApplication : undefined,
            problem: values.parentProblem,
            selected: [data.id],
            type: values.productId,
            isImproved: true
          };
          return dataProvider
            .create<Application>('applications', {
              data: appInfo
            })
            .then(() => data.key);
        })
        .then((solutionKey) => {
          router.push(`/solutions/${solutionKey}`);
          openModal(ModalComponentsKeys.improveApplicationSuccess);
          refreshUserBalance();
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });

      return;
    }

    setPrevSteps([...prevSteps, currentStep || '']);
    setErrors({});
    setActiveStepNumber((prev) => prev + 1);
    if (typeof nextStep === 'function') {
      setCurrentStep(nextStep(values));
      return;
    }

    setCurrentStep(nextStep);
  };

  const onStepNotify = (args: any) => {
    if (args === 'terms-click') {
      //
    }
  };

  let title = values.modalTitle || modalTitle;
  if (!title) {
    let name = 'invention';
    if (isProduct) {
      name = 'product';
    } else if (isPriorArt) {
      name = 'prior art';
    }
    title = (
      <>
        Improve the <b>{values.selectApplicationLabel}</b> {name}
      </>
    );
  }

  const isNextStepDisabled: boolean =
    typeof isNextStepBlocked === 'function' && isNextStepBlocked(values);

  let stepperStep = activeStepNumber;
  if (activeStepNumber < 2) {
    stepperStep = 0;
  }
  if (activeStepNumber >= 2 && activeStepNumber < 4) {
    stepperStep = activeStepNumber - 2;
  }
  if (activeStepNumber >= 5) {
    stepperStep = activeStepNumber - 5;
  }

  let subTitle: ReactElement | undefined = undefined;
  if (activeStepNumber === 2) {
    let name = 'invention';
    if (isProduct) {
      name = 'product';
    } else if (isPriorArt) {
      name = 'prior art';
    }
    subTitle = (
      <>
        Identify a <b>{values.productId}</b> problem with the{' '}
        <b>{values.selectApplicationLabel}</b> {name} as an opportunity for
        improvement.
      </>
    );
  }

  let nextBtnText = nextButtonTitle || 'Next';
  let nextBtnCoins: string | undefined = undefined;
  let showFinishBtn = false;
  if (activeStepNumber === 4) {
    nextBtnText = 'Next: Solve This Problem';
    nextBtnCoins = '+' + values.productRaw;
    showFinishBtn = true;
  }

  return (
    <ModalWindow
      title={title}
      titleAfter={
        <span className={styles.modalTitleIcon}>
          Earn: <PsLogo size={23} color={theme.palette.primary.main} small />
        </span>
      }
      subTitle={subTitle}
    >
      <Stepper className={styles.stepper} activeStep={stepperStep}>
        {stepsName.map((step) => (
          <Step key={step}>{step}</Step>
        ))}
      </Stepper>
      <StepComponent onNotify={onStepNotify} />
      <div className={`${styles.actions} actions`}>
        <PsButton
          disabled={isNextStepDisabled || loading}
          onClick={handleNextStep}
          fullWidth
          classes={buttonClasses}
          coins={nextBtnCoins}
        >
          {nextBtnText}
        </PsButton>
        {showFinishBtn ? (
          <PsButton
            disabled={isNextStepDisabled || loading}
            onClick={handleClickFinish}
            fullWidth
            cancel
            classes={buttonClasses}
          >
            Finish
          </PsButton>
        ) : null}
        <PsButton
          onClick={handleClickBack}
          fullWidth
          cancel
          classes={buttonClasses}
        >
          {backButtonTitle || 'Back'}
        </PsButton>
      </div>
    </ModalWindow>
  );
};

export default ImproveApplication;
