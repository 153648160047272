import {
  adaptV4Theme,
  createTheme,
  Palette,
  PaletteColor,
  Theme,
  ThemeOptions,
  useTheme
} from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

interface PsPalette extends Palette {
  third: PaletteColor;
}

export interface PsTheme extends Theme {
  palette: PsPalette;
}

interface PsThemeOptions extends ThemeOptions {
  palette?: PsPalette;
}

export const useIsSmallScreen = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('sm'));
};

export const useIsMediumScreen = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('md'));
};

export const colorPalette = {
  alto: '#DDDDDD',
  amberOrange: '#FFB300',
  amour: '#ffeaeb',
  aquaGreen: '#35A8501A',
  ashWhite: '#f6f6f7',
  azalea: '#FADADA',
  backgroundPurple: '#DFD3FF',
  black: '#000000',
  bloodRed: '#ce4242',
  blueChalk: '#F0EAFF',
  blueRomance: '#DFF7D7',
  boxShadowDarkGrey: 'rgba(0,0,0, 0.32)',
  boxShadowGrey: '#0000001F',
  blushPink: '#F8DADA',
  brightGray: '#E8ECF0',
  brightPurple: '#5038ED',
  ceramic: '#FBFFF5',
  charcoal: '#171923',
  chilliPepper: '#C31A1A',
  cinnabar: '#EA3636',
  cloudSilver: '#f6f6f6',
  colonialWhite: '#FDEBD3',
  cosmicLatte: '#d5d5fa',
  danger: '#fbdada',
  darkGray: '#666666',
  darkGreen: '#008954',
  darkGrey: '#454F63',
  darkPastelPurple: '#906fde',
  darkPurple: '#5433AE',
  darkShadowGrey: '#979fa8',
  deepNavy: '#1B2559',
  deepViolet: '#642edd',
  dimBlack: '#333333',
  doveGray: '#707070',
  ebb: '#ebe1e1',
  electricViolet: '#8000ff',
  explosiveGrey: '#c4c4c4',
  fairLavender: '#F9F6FF',
  forestGreen: '#39B225',
  gold: '#FFD600',
  golden: '#DF8533',
  goldenYellow: '#ffc800',
  gray: '#808080',
  green: '#2d9f72',
  greenHaze: '#07A854',
  grey: '#7c7c7c',
  highlightGreen: '#DDFDE4',
  jungleGreen: '#2D9F72',
  lavenderGray: '#f0eef4',
  lavenderMist: '#F6F4FB',
  lavenderPurple: '#d6c7f8',
  lavenderWhisper: '#ECE4FF',
  lightBlack: '#404040',
  lightCyan: '#fbfaff',
  lightestGreen: '#e8f5e9',
  lightestPurple: '#f4eff9',
  lightGreen: '#35A850',
  lightGrey: '#ededed',
  lightGreyish: '#495057',
  lightLavender: '#F3EFFF',
  lightPeach: '#FFEBBB',
  lightPurple: '#ECE7F8',
  lightPurpleBackground: '#9543f6',
  lightShadow: '#F8F9FA',
  lightShadowGrey: '#D3D3D3',
  lightViolet: '#BB86FC',
  linkWater: '#ECE7F9',
  magicMint: '#B1F4D4',
  magnolia: '#f9f1ff',
  malachite: '#01d057',
  maroon: '#800000',
  marzipan: '#F9D991',
  mediumGray: '#aaa',
  meteor: '#C77312',
  midnightHaze: '#454F63',
  midnightPurple: '#2A116D',
  mintCream: '#DBF1E5',
  mintGreen: '#DCFAE3CC',
  mintTulip: '#c7ebd5',
  mirage: '#161C2D',
  mustard: '#C77312',
  nobel: '#b4b4b4',
  orangeBrown: '#b56a11',
  orangeYellow: '#f9d991',
  oxfordBlue: '#031f46',
  paleBlue: '#DBECFD',
  parrotGreen: '#4CAF50',
  pastelPink: '#FFCFDF',
  pastelPurple: '#9181F4',
  pink: '#ffe0e2',
  purple: '#632DDD',
  purpleBlue: '#6330E1',
  purpleHeart: '#8e22d5',
  red: '#FF0000',
  roseWhite: '#FFFAFA',
  seaGreen: '#b1f4d4',
  selago: '#E6D9FC',
  semiBlack: '#0000001A',
  shadowBlack: '#00000040',
  shamrockGreen: '#009457',
  silverGrey: '#BFBFBF',
  skin: '#FEEAD8',
  slateBlue: '#696F8C',
  snowWhite: '#f9f9f9',
  snowyMint: '#d9ffe8',
  softGreen: '#E0F9EB',
  softGrey: '#eeeeee',
  softOrange: '#DD7750',
  softRed: '#FF4848',
  spanishGray: '#979494',
  steelBlue: '#577EBF',
  sugarCane: '#f9fff5',
  titaniumWhite: '#E4E4E4',
  torchRed: '#fe2536',
  transparent: 'transparent',
  tundora: '#4a4a4a',
  VenetianRed: '#EF6767',
  veryDarkGrey: '#404040',
  veryLightPurple: '#F8F5FF',
  whisper: '#f0f0f0',
  white: '#ffffff',
  whiteGrey: '#E0E0E0',
  whiteSilver: '#ccc',
  whiteSmoke: '#f5f5f5'
};

const themeOptions: PsThemeOptions = {
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: 14,
    htmlFontSize: 16,
    body1: {
      lineHeight: 1.8
    }
  },
  breakpoints: {
    values: {
      xs: 650,
      sm: 800,
      md: 900,
      lg: 1100,
      xl: 1340
    }
  },
  palette: {
    primary: {
      main: '#632DDD'
    },
    secondary: {
      main: '#07A854'
    },
    third: {
      main: '#031F46'
    }
  },
  overrides: {
    MuiSvgIcon: {
      colorAction: {
        color: '#c4c4c4'
      }
    }
  }
} as unknown as PsThemeOptions;

export const theme = createTheme(adaptV4Theme(themeOptions));
