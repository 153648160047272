import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Actions from 'redux-state/actions';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { PsTheme } from 'theme';
import { Card, CardBody, CardImage, CardTagTitle } from 'components/card';
import { TagContestList } from 'components/CardContest';
import { OwnerInfo } from 'components/CardProfile';
import { TagTopUsers, TagUsers } from 'components/CardTag';
import { Coins } from 'components/Coins';
import { PsButton } from 'components/common/PsButton';
import { PsChip } from 'components/common/PsChip';
import { DiscordBtn } from 'components/DiscordBtn';
import { FollowBtn } from 'components/FollowBtn';
import { ShareBtn } from 'components/ShareBtn';
import { Tab, TabContent, Tabs } from 'components/Tabs';
import { AuthContext } from 'contexts/AuthContext';
import { DataContext } from 'contexts/DataContext';
import { ModalComponentsKeys, ModalContext } from 'contexts/ModalContext';
import { ModalDataContext } from 'contexts/ModalDataContext';
import { PsRecord } from 'dataPrvider';
import { Picture, placeholderType } from 'helpers';
import { makeStyles } from 'tss-react/mui';
import { Constants, TAG_TYPES } from 'utilities/constants';
import { Application } from '../CardApplication';
import { Problem } from '../CardProblem';
import { Product } from '../CardProduct';
import { Solution } from '../CardSolution';

export type Tag = PsRecord & {
  body?: string;
  color?: string;
  files?: Array<Picture>;
  followers?: Array<string | number>;
  ideaPoints?: number;
  inventions?: Array<Application>;
  inventionsCount?: number;
  key: string;
  name?: string;
  owner?: string;
  ownerInfo?: OwnerInfo;
  problems?: Array<Problem>;
  problemsCount?: number;
  products?: Array<Product>;
  productsCount?: number;
  solutions?: Array<Solution>;
  solutionsCount?: number;
  type?: string;
};

export type TagInfo = PsRecord & {
  key: string;
  name?: string;
  title?: string;
  color?: string;
  type?: string;
};

type ClassKey =
  | 'root'
  | 'top'
  | 'descriptionRow'
  | 'infoRow'
  | 'bottomRow'
  | 'btns'
  | 'bottom';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      flexDirection: 'column'
    },
    top: {
      display: 'flex',
      width: '100%',
      [psTheme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    },
    descriptionRow: {
      margin: '0 0 23px 0',
      fontSize: 17
    },
    infoRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      marginTop: 10,
      marginBottom: 15
    },
    bottomRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      marginTop: 10,
      '& p': {
        lineHeight: 1.2
      },
      '& a': {
        textDecoration: 'none',
        color: psTheme.palette.primary.main
      },
      '& a:hover': {
        textDecoration: 'underline'
      }
    },
    btns: {
      display: 'flex',
      '& > button': {
        marginRight: 10
      }
    },
    bottom: {
      padding: '10px 10px'
    }
  };
});

export type TagCardProps = {
  tag: Tag;
};

const TagCardView = ({ tag }: TagCardProps) => {
  const { dataProvider } = useContext(DataContext);
  const { user } = useContext(AuthContext);
  const { openModal } = useContext(ModalContext);
  const { setType, updateField } = useContext(ModalDataContext);
  const dispatch = useDispatch();

  const [totalRewards, setTotalRewards] = useState(0);
  const { classes } = useStyles();

  const actionType = tag?.followers?.includes(user?.id)
    ? Constants.UNFOLLOW
    : Constants.FOLLOW;

  const {
    id,
    key,
    color,
    files,
    name,
    body,
    owner,
    type,
    discordChannelInvite
  } = tag;
  const [tagTab, setTagTab] = useState(`${name} Contributors`);
  const image = files && files.length ? files[0] : undefined;
  const url = `/tags/${key || id}`;
  let canCreateContest = false;
  if (
    user &&
    user.userData &&
    user.userData.moderator &&
    user.userData.moderator.length
  ) {
    const tag = user.userData.moderator.find((i) => i.id === id);
    if (tag) {
      canCreateContest = true;
    }
  }
  let showSubscription = false;
  if (
    !owner &&
    type === 'workplace' &&
    user &&
    user.userData &&
    user.userData.subscr &&
    user.userData.subscr.type &&
    !user.userData.subscr.type.startsWith('company')
  ) {
    showSubscription = true;
  }

  useEffect(() => {
    if (!id) {
      setTotalRewards(0);
      return;
    }
    dataProvider
      .getGroupRewards(id)
      .then(({ data }) => {
        setTotalRewards(data.total);
      })
      .catch((err) => {
        console.error(err);
        setTotalRewards(0);
      });
  }, [id]);

  useEffect(() => {
    if (
      !owner &&
      id &&
      user &&
      user.subscriptionTag &&
      id === user.subscriptionTag
    ) {
      updateField('parentProblem', id);
      updateField('parentProblemLabel', name);
      openModal(ModalComponentsKeys.companySubscriptionFinalize);
    }
  }, [id, name, openModal, owner, updateField, user]);

  const onContestCreateClick = () => {
    setType(ModalComponentsKeys.addNewContest);
    updateField('parentProblem', id);
    openModal(ModalComponentsKeys.addNewContest);
  };

  const onSubscriptionClick = (e: React.MouseEvent) => {
    e.preventDefault();
    updateField('parentProblem', id);
    updateField('parentProblemLabel', name);
    updateField('parentSolutionOnly', true);
    openModal(ModalComponentsKeys.companySubscription);
  };

  const onFollowBtnClick = (tagId: string) => {
    if (user) {
      dispatch(Actions.getTagFollowers(tagId, user.id, actionType));
    }
  };

  return (
    <Card className={classes.root}>
      <div className={classes.top}>
        <CardImage href={url} image={image} type={placeholderType.T} />
        <CardBody>
          <CardTagTitle title={`${name} Mindminers`}>
            <PsChip label={name} textColor={color} size="medium" />
          </CardTagTitle>
          {body ? (
            <Typography className={classes.descriptionRow}>{body}</Typography>
          ) : null}
          <Coins count={totalRewards ? totalRewards.toFixed(2) : totalRewards}>
            Collectively Generated
          </Coins>
          {canCreateContest ? (
            <div className={classes.infoRow}>
              <div>
                <PsButton
                  onClick={onContestCreateClick}
                  color="secondary"
                  smallest
                >
                  Create New Contest
                </PsButton>
              </div>
            </div>
          ) : null}
          <div className={classes.bottomRow}>
            <div>
              <Typography>
                {showSubscription ? (
                  <a href="#" onClick={onSubscriptionClick}>
                    Are you {name} employee? Claim this group
                  </a>
                ) : null}
              </Typography>
            </div>
            <div className={classes.btns}>
              {tag.type !== TAG_TYPES.LOCATION &&
                tag.type !== TAG_TYPES.SCHOOL && (
                  <FollowBtn
                    actionType={actionType}
                    onClick={() => onFollowBtnClick(id as string)}
                    tag={tag}
                    id={id}
                    type="tag"
                  />
                )}
              <DiscordBtn invite={discordChannelInvite} />
              <ShareBtn tag={tag} />
            </div>
          </div>
        </CardBody>
      </div>
      <div className={classes.bottom}>
        <TagContestList tag={id} />
        <Tabs>
          <Tab
            label={`${name} Contributors`}
            value={tagTab}
            setValue={setTagTab}
          />
          <Tab label="Leaderboard" value={tagTab} setValue={setTagTab} />
        </Tabs>

        <TabContent label={`${name} Contributors`} value={tagTab}>
          {id && <TagUsers id={id} moderator={owner} />}
        </TabContent>
        <TabContent label="Leaderboard" value={tagTab}>
          <TagTopUsers id={id} />
        </TabContent>
      </div>
    </Card>
  );
};

export const CardTag = styled(TagCardView)({});

export default CardTag;
