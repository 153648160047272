import React, { FC } from 'react';
import { Box, styled } from '@mui/material';
import { StyledVoteButton } from 'pages/home/StyledComponents';
import { PiArrowFatDown, PiArrowFatDownDuotone } from 'react-icons/pi';
import { useIsMediumScreen } from 'theme';
import { Constants } from 'utilities/constants';

interface DislikeProps {
  disabledButtons?: boolean;
  handleActionButton: (action: string) => void;
  isDisliked?: boolean;
}

const Dislike: FC<DislikeProps> = ({
  disabledButtons,
  handleActionButton,
  isDisliked
}) => {
  const isMediumScreen = useIsMediumScreen();

  const handleClick = (event) => {
    event.stopPropagation();
    handleActionButton('dislike');
  };

  const DislikeIcon = isDisliked ? PiArrowFatDownDuotone : PiArrowFatDown;

  return (
    <StyledBox>
      <StyledVoteButton disabled={disabledButtons}>
        <DislikeIcon
          color="red"
          size={isMediumScreen ? Constants.MEDIUM_20 : Constants.MEDIUM_25}
          onClick={handleClick}
        />
      </StyledVoteButton>
    </StyledBox>
  );
};

const StyledBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center'
});

export default Dislike;
