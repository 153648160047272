import React, { useContext, useEffect, useState } from 'react';
import { styled, useTheme, lighten } from '@mui/material/styles';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

import { DataContext } from 'contexts/DataContext';
import { PsLogo } from 'components/common/PsLogo';
import { getPlaceholderImage } from '../../helpers';
import { ContestTop, ContestTops } from 'components/CardContest';
import { PsTheme } from '../../theme';

import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

type ClassKey =
  | 'root'
  | 'loading'
  | 'header'
  | 'user'
  | 'number'
  | 'photo'
  | 'userPhoto'
  | 'userPhotoWin'
  | 'info'
  | 'top'
  | 'coins'
  | 'bottom';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      padding: '0 15px'
    },
    loading: {
      lineHeight: '50px',
      textAlign: 'center'
    },
    header: {
      margin: '0 10px 16px',
      paddingTop: 5,
      fontSize: 24,
      fontWeight: 600,
      textTransform: 'uppercase'
    },
    user: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      margin: '16px 0',
      fontSize: 20,
      fontWeight: 600
    },
    number: {
      width: 48,
      flexShrink: 0,
      paddingLeft: 7
    },
    photo: {
      position: 'relative',
      display: 'block',
      width: 55,
      flexShrink: 0,
      marginRight: 43
    },
    userPhoto: {
      display: 'block',
      width: 55,
      height: 55,
      borderRadius: 10,
      objectFit: 'cover'
    },
    userPhotoWin: {
      position: 'absolute',
      top: -10,
      right: -10,
      color: '#FFD700'
    },
    info: {
      flexGrow: 1
    },
    top: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: 10,
      '& a': {
        color: 'inherit',
        textDecoration: 'none'
      },
      '& a:hover': {
        textDecoration: 'underline'
      }
    },
    coins: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontWeight: 'normal',
      '& > svg': {
        marginLeft: 12
      },
      '& > span': {
        whiteSpace: 'nowrap'
      }
    },
    bottom: {
      height: 3,
      background: lighten(psTheme.palette.primary.main, 0.8),
      borderRadius: 1,
      '& > div': {
        height: 3,
        background: psTheme.palette.primary.main,
        borderRadius: 1
      }
    }
  };
});

export type ContestTopsProps = {
  id: string | number;
  type: 'solution' | 'invention';
  winners: number;
};

const ContestTopsView = ({ id, type, winners }: ContestTopsProps) => {
  const psTheme: PsTheme = useTheme();
  const { dataProvider } = useContext(DataContext);
  const [tops, setTops] = useState<Array<ContestTop>>([]);
  const [loading, setLoading] = useState(true);
  const { classes } = useStyles();

  useEffect(() => {
    setLoading(true);
    dataProvider
      .getContestTops<ContestTops>(id)
      .then((tops) => {
        if (type === 'solution') {
          setTops(tops.solutions);
        } else {
          setTops(tops.applications);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  return (
    <div className={classes.root}>
      {loading ? (
        <Typography className={classes.loading} variant="body2">
          Loading...
        </Typography>
      ) : !tops.length ? (
        <Typography variant="body2">
          This contest has no {type}s yet. Be the first to add a {type} to
          appear on the leaderboard and earn Idea Points.
        </Typography>
      ) : (
        <Typography className={classes.header}>Top 10</Typography>
      )}

      <div>
        {tops.map((top, index) => {
          let src = getPlaceholderImage(type);
          if (top.files && top.files.length) {
            src = top.files[0].url;
          }
          const isWinner = index < winners;
          const name = top.title;

          const width = top.totalPer * 100;
          const url = `/${type}s/${top.key || top.id}`;
          return (
            <div key={top.id} className={classes.user} title={name}>
              <div className={classes.number}>{index + 1}.</div>
              <Link to={url}>
                <a className={classes.photo}>
                  <img className={classes.userPhoto} src={src} alt={name} />
                  {isWinner ? (
                    <EmojiEventsIcon className={classes.userPhotoWin} />
                  ) : null}
                </a>
              </Link>
              <div className={classes.info}>
                <div className={classes.top}>
                  <Link to={url}>
                    <a>{name}</a>
                  </Link>
                  <div className={classes.coins}>
                    <span>{top.votesSum} Votes,</span>&nbsp;
                    <span>{top.rewardsSum.toFixed(2)}</span>
                    <PsLogo
                      size={30}
                      color={psTheme.palette.primary.main}
                      small
                    />
                  </div>
                </div>
                <div className={classes.bottom}>
                  <div style={{ width: `${width}%` }}></div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const ContestTopsList = styled(ContestTopsView)({});

export default ContestTopsList;
