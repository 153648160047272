import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef
} from 'react';
import { useDispatch } from 'react-redux';
import Actions from 'redux-state/actions';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import CloseIcon from '@mui/icons-material/Close';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  debounce,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Contest } from 'components/CardContest';
import { Problem } from 'components/CardProblem';
import { Tag } from 'components/CardTag';
import PsLogo from 'components/common/PsLogo';
import EditIcon from 'components/icons/EditIcon';
import AuthContext from 'contexts/AuthContext';
import moment, { Moment } from 'moment';
import { useDropzone } from 'react-dropzone';
import { FiPlusCircle } from 'react-icons/fi';
import { FiTrash2 } from 'react-icons/fi';
import { GrPowerCycle } from 'react-icons/gr';
import { IoImageOutline } from 'react-icons/io5';
import { LuAsterisk } from 'react-icons/lu';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  GetGeneratedDescription,
  GetGeneratedDescriptionLoader,
  GetGeneratedImage,
  GetGenerateImageLoader,
  GetProblems,
  GetTag
} from 'redux-state/selectors';
import { colorPalette, theme } from 'theme';
import { Constants } from 'utilities/constants';
import { AddNewProblemModal } from './AddNewProblemModal';
import Leaf from './leaf';
import {
  ContainerBox,
  CreateButton,
  DraftButton,
  Heading,
  HeadingContainer,
  LoaderBox,
  MainDialog,
  MainDialogTitle,
  ModalTitle,
  StyledAddProblemButton,
  StyledAvatar,
  StyledBoxCreate,
  StyledContestTitle,
  StyledEarnIdeapoints,
  StyledFlexBox,
  StyledHexBox,
  StyledIconButton,
  StyledIdeaCoins,
  StyledIdeacoinsMark,
  StyledImageBox,
  StyledImageBox2,
  StyledIoCalendarOutline,
  StyledNodeBox,
  StyledProblem,
  StyledRadioGroup,
  StyledRemoveButton,
  StyledSlider,
  StyledSliderText,
  StyledTypo,
  StyledTypo2,
  StyledTypo3,
  StyledUserBox
} from './StyledComponents';
import { toastify } from './toastify';
import HexagonIcon from './HexagonIcon';
import { convertFileToBase64 } from '../../helpers';
import { NodeContentBase } from 'components/InteractiveNodeContainer/NodeContent/NodeContentBase';

const SliderValueLabelComponent = (props: any) => {
  const { children, value } = props;
  return (
    <Tooltip
      title={<Leaf text={value} className="small-leaf" />}
      placement="top"
      arrow
      classes={{ tooltip: 'popover-class' }}
    >
      {children}
    </Tooltip>
  );
};
interface ContestProblemProps {
  children?: Problem[];
}
interface ContestModalProps {
  contest?: Contest;
  contestProblem?: ContestProblemProps;
  isArchived?: boolean;
  isDuplicate?: boolean;
  onClose: any;
  open: boolean;
  pagination?: { page: number; perPage: number };
  tag?: Tag;
}

const TextFieldMarkup = (params) => (
  <TextField
    {...params}
    InputProps={{
      ...params.InputProps,
      style: { cursor: 'text' }
    }}
    placeholder="Select a date or type here"
  />
);

export const ContestModal: React.FC<ContestModalProps> = ({
  contest,
  contestProblem,
  isArchived,
  isDuplicate,
  onClose,
  open,
  pagination,
  tag
}) => {
  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);
  const contestSliderStart = 7;
  const contestSliderEnd = 21;
  const ideaCoinsSliderStart = 10;
  const ideaCoinsSliderEnd = 100;
  const generatedDescription = GetGeneratedDescription();
  const generatedDescriptionLoader = GetGeneratedDescriptionLoader();
  const generatedImage = GetGeneratedImage();
  const generatedImageLoader = GetGenerateImageLoader();
  const [previewImage, setPreviewImage] = useState<string | ArrayBuffer>(null);
  const [bountyCoins, setBountyCoins] = useState<number>(
    contest?.bountyCoins || ideaCoinsSliderStart
  );
  const [nftToUnlock, setNftToUnlock] = useState(
    calculateNftToUnlock(contest?.bountyCoins || ideaCoinsSliderStart)
  );
  const [contestTitle, setContestTitle] = useState<string>(
    contest?.title || ''
  );
  const [contestDescription, setContestDescription] = useState<string>(
    contest?.body || ''
  );
  const [startDate, setStartDate] = useState<Moment>(
    moment(contest?.startDate || moment())
  );
  const [isValidDate, setIsValidDate] = useState<boolean>(true);
  const [winType, setWinType] = useState<string>(
    contest?.wins || Constants.WINNER_TAKES_ALL
  );
  const [contestType, setContestType] = useState<string>(
    contest?.type || Constants.MOST_VOTES
  );
  const [endDate, setEndDate] = useState<number>(
    contest
      ? moment(contest.endDate).diff(moment(contest.startDate), 'days')
      : contestSliderStart
  );
  const [problems, setProblems] = useState<Problem[]>(
    contest?.children || contestProblem?.children || []
  );
  const [problemList, setProblemList] = useState<string[]>(
    contest?.children?.map((child) => child.id) ||
      contestProblem?.children?.map((child) => child.id) ||
      []
  );
  const [problemModal, setProblemModal] = useState<boolean>(false);
  const [updatedFiles, setUpdatedFiles] = useState<
    { contentType: string; title: string; url: string }[]
  >(contest?.files || []);
  const newList = GetProblems();

  const buttonOnNode = [
    {
      component: (node) => (
        <IconButton
          key="button-1"
          sx={{
            boxShadow: `0px 0px 6.2px 0px ${colorPalette.boxShadowDarkGrey}`
          }}
        >
          <EditIcon color={colorPalette.purple} />
        </IconButton>
      ),
      onClick: (node) => {
        console.log('clicked');
      }
    }
  ];

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/gif': ['.gif'],
      'image/svg+xml': ['.svg']
    }
  });

  const deleteFromList = useCallback(
    (problemId) => {
      const updatedProblemList = problemList.filter((id) => id !== problemId);
      setProblemList(updatedProblemList);
    },
    [problemList]
  );

  const handleSliderChange = useCallback(
    (event: Event, newValue: number | number[]) => {
      const newBountyCoins = newValue as number;
      setBountyCoins(newBountyCoins);
      setNftToUnlock(calculateNftToUnlock(newBountyCoins));
    },
    []
  );

  const debouncedUpdate = useRef(
    debounce(() => {
      generateAIDescription();
    }, 1500)
  ).current;

  const handleTitleChange = (event) => {
    setContestTitle(event.target.value);
    contest && Object.keys(contest).length && debouncedUpdate();
  };

  const handleDateSlider = useCallback((event: Event, newValue: number) => {
    setEndDate(newValue);
  }, []);

  const handleWinType = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setWinType(event.target.value);
    },
    []
  );

  const handleContestType = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setContestType(event.target.value);
    },
    []
  );

  const handleDatePick = useCallback((newDate: Moment | null) => {
    if (newDate) {
      setStartDate(newDate);
      setIsValidDate(!newDate.isBefore(moment(), 'day'));
    }
  }, []);

  const generateAIDescription = () => {
    if (!contestTitle) {
      toastify(Constants.CHECK_TITLE);
      return;
    }
    if (user) {
      dispatch(
        Actions.generateDescriptionFromAI({
          title: contestTitle,
          type: Constants.CONTESTS
        })
      );
    } else {
      dispatch(Actions.openAuthModal(true));
    }
  };

  useEffect(() => {
    if (generatedDescription) {
      setContestDescription(generatedDescription);
    }
  }, [generatedDescription]);

  const generateAIImage = () => {
    if (!contestTitle) {
      toastify(Constants.CHECK_TITLE);

      return;
    }
    if (!contestDescription) {
      toastify(Constants.CHECK_DESCRIPTION);
      return;
    }
    if (user) {
      dispatch(
        Actions.generateImageFromAI({
          title: contestTitle,
          description: contestDescription,
          type: Constants.CONTESTS
        })
      );
    } else {
      dispatch(Actions.openAuthModal(true));
    }
  };

  useEffect(() => {
    if (generatedImage) {
      setPreviewImage(generatedImage);
      const url = generatedImage.split('/');
      const generatedImageName = url[url.length - 1];
      setUpdatedFiles([
        {
          contentType: 'image/jpeg',
          title: generatedImageName,
          url: generatedImage
        }
      ]);
    }
  }, [generatedImage]);

  const closeModal = useCallback(() => {
    onClose();
    setProblemList([]);
    setContestTitle('');
    setContestDescription('');
    setPreviewImage('');
    dispatch(Actions.generateDescriptionFromAISuccess({}));
    dispatch(Actions.generateImageFromAISuccess({}));
  }, [dispatch, onClose]);

  const toggleNewProblemModal = useCallback(() => {
    setProblemModal(!problemModal);
  }, [problemModal]);

  const handleCreateContest = useCallback(async () => {
    if (user) {
      if (!isValidDate) {
        toastify(Constants.CHECK_DATE);
        return;
      }
      if (!contestTitle) {
        toastify(Constants.CHECK_TITLE);
        return;
      }
      if (!winType) {
        toastify(Constants.CHECK_WIN_TYPE);
        return;
      }
      if (!contestType) {
        toastify(Constants.CHECK_CONTEST_TYPE);
        return;
      }
      const newEndDate = moment(startDate).add(endDate, Constants.DAYS);
      const updatedChildren = problems.map((problem) => ({
        id: problem.id,
        ref: Constants.C_PROBLEM
      }));
      const data = {
        body: contestDescription,
        bountyCoins: bountyCoins,
        bountyNft: nftToUnlock,
        bountyReward: 0, // have to update this
        children: updatedChildren,
        endDate: newEndDate.toISOString(),
        files: updatedFiles,
        startDate: startDate.toISOString(),
        tag: contest
          ? contest?.tag
          : (tag?.id as string) || (tag?._id as string),
        title: contestTitle,
        type: contestType,
        wins: winType
      };
      {
        contest && Object.keys(contest).length && !isDuplicate
          ? dispatch(
              Actions.updateContest(
                contest.id,
                data,
                user.id,
                pagination,
                isArchived
              )
            )
          : dispatch(Actions.createContest(data));
      }
    } else {
      dispatch(Actions.openAuthModal(true));
    }
    closeModal();
  }, [
    bountyCoins,
    closeModal,
    contest,
    contestDescription,
    contestTitle,
    contestType,
    dispatch,
    endDate,
    isArchived,
    isDuplicate,
    isValidDate,
    nftToUnlock,
    pagination,
    problems,
    startDate,
    tag,
    updatedFiles,
    user,
    winType
  ]);

  const handleSaveDraft = useCallback(async () => {
    if (user) {
      const newEndDate = moment(startDate).add(endDate, Constants.DAYS);
      const updatedChildren = problems.map((problem) => ({
        id: problem.id,
        ref: Constants.C_PROBLEM
      }));
      dispatch(
        Actions.createContest({
          body: contestDescription,
          bountyCoins: bountyCoins,
          bountyNft: nftToUnlock,
          bountyReward: 0, // have to update this
          children: updatedChildren,
          endDate: newEndDate.toISOString(),
          files: updatedFiles,
          startDate: startDate.toISOString(),
          status: 'draft',
          tag: contest
            ? contest?.tag
            : (tag?.id as string) || (tag?._id as string),
          title: contestTitle,
          type: contestType,
          wins: winType
        })
      );
      closeModal();
    } else {
      dispatch(Actions.openAuthModal(true));
    }
  }, [
    bountyCoins,
    closeModal,
    contest,
    contestDescription,
    contestTitle,
    contestType,
    dispatch,
    endDate,
    nftToUnlock,
    problems,
    startDate,
    tag,
    updatedFiles,
    user,
    winType
  ]);

  useEffect(() => {
    if (newList) {
      const updatedList = newList.map((item) => ({ ...item, type: 'problem' }));
      setProblems(updatedList);
    }
  }, [newList]);

  useEffect(() => {
    if (problemList.length > 0) {
      dispatch(Actions.getProblemsFromId(problemList));
    } else {
      setProblems([]);
    }
  }, [dispatch, problemList]);

  useEffect(() => {
    if (acceptedFiles.length) {
      const reader = new FileReader();
      reader.readAsDataURL(acceptedFiles[0]);
      reader.onload = () => {
        setPreviewImage(reader.result);
      };
      Promise.all(acceptedFiles.map(convertFileToBase64)).then(([item]) => {
        const { file, base64 } = item;
        setUpdatedFiles([
          {
            contentType: file.type,
            title: file.name,
            url: base64
          }
        ]);
      });
    }
  }, [acceptedFiles]);

  return (
    <MainDialog
      aria-labelledby="customized-dialog-title"
      onClose={closeModal}
      open={open}
    >
      <ToastContainer />
      <StyledBoxCreate>
        <MainDialogTitle id="customized-dialog-title">
          <ModalTitle>
            {contest && Object.keys(contest).length && isDuplicate
              ? Constants.DUPLICATE_CONTEST
              : contest && Object.keys(contest).length && !isDuplicate
                ? Constants.EDIT_CONTEST
                : Constants.CREATE_NEW_CONTEST}
          </ModalTitle>
        </MainDialogTitle>
        <StyledIconButton aria-label="close" onClick={closeModal}>
          <CloseIcon />
        </StyledIconButton>
      </StyledBoxCreate>
      <Divider />
      <StyledContestTitle>
        <Grid
          container
          spacing={2}
          sx={{
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column'
            }
          }}
        >
          <Grid item md={6}>
            <ContainerBox>
              <Heading>
                Contest Title
                <LuAsterisk color={colorPalette.red} />
              </Heading>
              <TextField
                onChange={handleTitleChange}
                sx={{ width: '100%' }}
                value={contestTitle}
                variant="outlined"
              />
            </ContainerBox>
          </Grid>
          <Grid item md={6}>
            <ContainerBox>
              <Heading>
                Start Date
                <LuAsterisk color={colorPalette.red} />
              </Heading>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  disablePast
                  sx={{ width: '100%' }}
                  slots={{
                    openPickerIcon: StyledIoCalendarOutline,
                    textField: TextFieldMarkup
                  }}
                  slotProps={{ openPickerButton: { disableRipple: true } }}
                  value={startDate}
                  onChange={handleDatePick}
                />
              </LocalizationProvider>
            </ContainerBox>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column'
            }
          }}
        >
          <Grid item md={6}>
            <ContainerBox>
              <HeadingContainer>
                <Heading>
                  {Constants.REQUEST_BOUNTY_FROM_MINDMINER}
                  <LuAsterisk color={colorPalette.red} />
                </Heading>
                <StyledHexBox sx={{ position: 'relative' }}>
                  <HexagonIcon number={nftToUnlock} size={45} />
                  <Typography
                    sx={{ marginLeft: '0.625rem', fontSize: '0.875rem' }}
                  >
                    {Constants.NFTS_TO_UNLOCK}
                  </Typography>
                </StyledHexBox>
              </HeadingContainer>
              <StyledSlider
                defaultValue={bountyCoins}
                aria-label="Default"
                valueLabelDisplay="auto"
                min={ideaCoinsSliderStart}
                max={ideaCoinsSliderEnd}
                onChange={handleSliderChange}
                slots={{
                  valueLabel: SliderValueLabelComponent
                }}
              />
              <StyledIdeaCoins>
                <StyledIdeacoinsMark>
                  <PsLogo size={25} color={colorPalette.purple} small />
                  <StyledSliderText>{ideaCoinsSliderStart}</StyledSliderText>
                </StyledIdeacoinsMark>
                <StyledIdeacoinsMark>
                  <StyledSliderText sx={{ marginRight: '0.5rem' }}>
                    {ideaCoinsSliderEnd} Ideacoins available
                  </StyledSliderText>
                  <PsLogo size={25} color={colorPalette.purple} small />
                </StyledIdeacoinsMark>
              </StyledIdeaCoins>
            </ContainerBox>
          </Grid>
          <Grid item md={6}>
            <ContainerBox>
              <HeadingContainer>
                <Heading>{Constants.CONTEST_LENGTH}</Heading>
              </HeadingContainer>
              <StyledSlider
                defaultValue={endDate}
                aria-label="Default"
                valueLabelDisplay="auto"
                min={contestSliderStart}
                max={contestSliderEnd}
                marks={[
                  {
                    value: contestSliderStart + 0.5,
                    label: `${contestSliderStart} days`
                  },
                  {
                    value: contestSliderEnd - 0.5,
                    label: `${contestSliderEnd} days`
                  }
                ]}
                sx={{
                  '& .MuiSlider-thumb': {
                    marginLeft: '0.5rem'
                  },
                  '& .MuiSlider-mark': {
                    color: 'transparent',
                    backgroundColor: 'transparent'
                  }
                }}
                onChange={handleDateSlider}
              />
            </ContainerBox>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column'
            }
          }}
        >
          <Grid item md={6}>
            <ContainerBox>
              <Heading>
                Who Wins the Contest
                <LuAsterisk color={colorPalette.red} />
              </Heading>
              <StyledRadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={winType}
                onChange={handleWinType}
              >
                <FormControlLabel
                  value={Constants.WINNER_TAKES_ALL}
                  control={<Radio />}
                  label={Constants.WINNER_TAKES_ALL}
                />
                {/* <FormControlLabel                 commenting out - incomplete functionality for now
                  value={Constants.TOP_3_WINNERS}
                  control={<Radio />}
                  label={Constants.TOP_3_WINNERS}
                />  */}
                {/* <FormControlLabel                 commenting out - incomplete functionality for now
                  value={Constants.TOP_10_WINNERS}
                  control={<Radio />}
                  label={Constants.TOP_10_WINNERS}
                /> */}
              </StyledRadioGroup>
            </ContainerBox>
            <ContainerBox>
              <Heading>
                Contest Type
                <LuAsterisk color={colorPalette.red} />
              </Heading>
              <StyledRadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={contestType}
                onChange={handleContestType}
              >
                {/* <FormControlLabel                 commenting out - incomplete functionality for now
                  value={Constants.MOST_COINS_EARNED}
                  control={<Radio />}
                  label={Constants.MOST_COINS_EARNED}
                /> */}
                <FormControlLabel
                  value={Constants.MOST_VOTES}
                  control={<Radio />}
                  label={Constants.MOST_VOTES}
                />
                {/* <FormControlLabel                 commenting out - incomplete functionality for now
                  value={Constants.HYBRID}
                  control={<Radio />}
                  label={Constants.HYBRID}
                /> */}
              </StyledRadioGroup>
            </ContainerBox>
          </Grid>
          <Grid item md={6}>
            <ContainerBox>
              <Heading>Contest Feature Image</Heading>
              <StyledImageBox>
                <StyledAvatar
                  src={
                    (previewImage as string) || contest?.files?.[0]?.url || ''
                  }
                />
                <StyledImageBox2>
                  {generatedImageLoader ? (
                    <LoaderBox padding={'0.5rem'}>
                      <CircularProgress size="1.375rem" />
                    </LoaderBox>
                  ) : (
                    <Button onClick={generateAIImage}>
                      <GrPowerCycle size={26} />
                    </Button>
                  )}
                  <Button {...getRootProps({})} disabled={generatedImageLoader}>
                    <input name="files" {...getInputProps()} />
                    <IoImageOutline size={26} />
                  </Button>
                </StyledImageBox2>
              </StyledImageBox>
            </ContainerBox>
          </Grid>
        </Grid>
        <ContainerBox>
          <Heading>{Constants.DESCRIPTION}</Heading>
          <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
            <TextField
              multiline
              onChange={(e) => setContestDescription(e.target.value)}
              rows={3}
              sx={{ width: '100%' }}
              value={contestDescription}
              variant="outlined"
            />
            {generatedDescriptionLoader ? (
              <LoaderBox padding={'0.5rem 1.4075rem'}>
                <CircularProgress size="1.375rem" />
              </LoaderBox>
            ) : (
              <Button onClick={generateAIDescription}>
                <GrPowerCycle size={26} />
              </Button>
            )}
          </Box>
        </ContainerBox>
        <ContainerBox>
          <Heading>
            Associated Problems with this Contest
            <LuAsterisk color={colorPalette.red} />
          </Heading>
          <StyledProblem>
            {problems.map((problem: any) => (
              <StyledNodeBox key={problem.id}>
                <Box sx={{ width: '100%' }}>
                  <NodeContentBase node={problem} buttonList={buttonOnNode} />
                </Box>
                <StyledRemoveButton
                  onClick={() => {
                    deleteFromList(problem.id);
                  }}
                >
                  <FiTrash2 size={30} />
                  <Typography sx={{ marginLeft: '0.3125rem' }}>
                    Remove
                  </Typography>
                </StyledRemoveButton>
              </StyledNodeBox>
            ))}
          </StyledProblem>
        </ContainerBox>
        <StyledAddProblemButton onClick={toggleNewProblemModal}>
          <FiPlusCircle style={{ margin: '5px' }} color={colorPalette.purple} />
          Add New Problem
        </StyledAddProblemButton>
        <AddNewProblemModal
          onClose={toggleNewProblemModal}
          open={problemModal}
          problemList={problemList}
          setProblemList={setProblemList}
          tag={contest ? contest.tagsInfo[0] : tag}
        />
      </StyledContestTitle>
      <Divider />
      <StyledUserBox>
        <StyledFlexBox>
          {user && (
            <>
              <Avatar src={user.files[0]?.url} />
              <StyledEarnIdeapoints>
                <StyledTypo>{user.username}</StyledTypo>
                <StyledTypo2>
                  Earn
                  <StyledTypo3>{Constants.THOUSAND} ideapoints</StyledTypo3>
                  for winning this contest
                </StyledTypo2>
              </StyledEarnIdeapoints>
            </>
          )}
        </StyledFlexBox>
        <StyledFlexBox>
          <DraftButton
            onClick={handleSaveDraft}
            disabled={
              generatedDescriptionLoader ||
              generatedImageLoader ||
              Boolean(contest && Object.keys(contest).length)
            }
          >
            {Constants.SAVE_DRAFT}
          </DraftButton>
          <CreateButton
            disabled={
              !contestTitle ||
              !winType ||
              !contestType ||
              !problems.length ||
              generatedDescriptionLoader ||
              generatedImageLoader
            }
            onClick={handleCreateContest}
          >
            {contest && Object.keys(contest).length && !isDuplicate
              ? Constants.UPDATE_CONTEST
              : Constants.CREATE_CONTEST}
          </CreateButton>
        </StyledFlexBox>
      </StyledUserBox>
    </MainDialog>
  );
};

export const calculateNftToUnlock = (bountyCoins) => {
  const mapping = {
    10: 3,
    14: 4,
    18: 5,
    22: 6,
    26: 7,
    30: 8,
    34: 9,
    38: 10,
    42: 11,
    46: 12,
    50: 13,
    54: 14,
    58: 15,
    62: 16,
    66: 17,
    70: 18,
    74: 19,
    78: 20,
    82: 21,
    86: 22,
    90: 23,
    94: 24,
    100: 25
  };

  const keys = Object.keys(mapping)
    .map(Number)
    .sort((a, b) => a - b);
  for (let i = keys.length - 1; i >= 0; i--) {
    if (bountyCoins >= keys[i]) {
      return mapping[keys[i]];
    }
  }
  return 0;
};
