import React, { useContext, useState, useEffect } from 'react';
import { styled, useTheme, alpha } from '@mui/material/styles';

import { Radio } from 'components/Radio/Radio';
import { PsLogo } from 'components/common/PsLogo';
import {
  ModalDataContext,
  StepComponentProps,
  StepComponentType
} from 'contexts/ModalDataContext';
import { DataContext } from 'contexts/DataContext';
import { PsTheme } from '../../../theme';
import styles from 'modals/ModalWindow.module.scss';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

type ImproveOption = {
  value: string;
  text: string;
  earn?: string;
};

const strengthsLeft = [
  { value: 'Performability', text: 'Performability' },
  { value: 'Affordability', text: 'Affordability' },
  { value: 'Featureability', text: 'Featureability' },
  { value: 'Deliverability', text: 'Deliverability' }
];

const strengthsRight = [
  { value: 'Useability', text: 'Useability' },
  { value: 'Maintainability', text: 'Maintainability' },
  { value: 'Durability', text: 'Durability' },
  { value: 'Imageability', text: 'Imageability' }
];

const wastesLeft = [
  { value: 'Complexity', text: 'Complexity' },
  { value: 'Precision', text: 'Precision' },
  { value: 'Variability', text: 'Variability' },
  { value: 'Sensitivity', text: 'Sensitivity' }
];
const wastesRight = [
  { value: 'Immaturity', text: 'Immaturity' },
  { value: 'Danger', text: 'Danger' },
  { value: 'High Skill', text: 'High Skill' }
];

const RadioText = ({
  option,
  coins
}: {
  option: ImproveOption;
  coins: Record<string, number>;
}) => {
  let amount = 0;
  if (typeof coins[option.value] !== 'undefined') {
    amount = coins[option.value];
  } else {
    amount = coins.base;
  }
  return (
    <span className={styles.improveRow}>
      <span className={styles.improveCellTitle}>{option.text}</span>
      <span className={styles.improveCellInfo}>
        {amount ? (
          <span className={styles.improveCellInfoText}>
            <PsLogo size={26} color="#632DDD" small />
            &nbsp;
            <span>
              <b>{amount.toFixed(2)}</b>&nbsp;IdeaCoins
            </span>
            &nbsp; Earn
          </span>
        ) : null}
      </span>
    </span>
  );
};

const useRadioStyles = makeStyles()(() => {
  const theme = useTheme();
  return {
    root: {
      borderRadius: 8,
      margin: '8px 0',
      border: `solid 1px ${alpha(theme.palette.primary.main, 0.15)}`,
      background: 'rgb(248, 245, 255, 0.5)',
      color: theme.palette.primary.main,
      '&:hover': {
        border: `solid 1px ${alpha(theme.palette.primary.main, 0.5)}`
      }
    }
  };
});

type ClassKey = 'root' | 'grid' | 'error';

const useStyles2 = makeStyles()(() => {
  const psTheme = useTheme() as PsTheme;
  return {
    root: {},
    grid: {
      [psTheme.breakpoints.down('xs')]: {
        '& > div': {
          width: '100%'
        }
      }
    },
    error: {
      color: 'red'
    }
  };
});

const ChooseSubTypeView = () => {
  const { values, errors, setErrors, updateField } =
    useContext(ModalDataContext);
  const { dataProvider } = useContext(DataContext);
  const { classes: radioClasses } = useRadioStyles();
  const { classes } = useStyles2();
  const [leftList, setLeftList] = useState<Array<ImproveOption>>([]);
  const [rightList, setRightList] = useState<Array<ImproveOption>>([]);
  const [coins, setCoins] = useState<Record<string, number>>({});
  const [selectedEntity, setSelectedEntity] = useState<string | undefined>(
    `${values.productId}`
  );

  useEffect(() => {
    if (!values.selectApplication) {
      return;
    }
    if (values.description === 'Wastes') {
      setLeftList(wastesLeft);
      setRightList(wastesRight);
    } else {
      setLeftList(strengthsLeft);
      setRightList(strengthsRight);
    }

    dataProvider
      .getImproveApp(values.selectApplication, values.selectApplicationType)
      .then((data) => {
        setCoins(data.coins || {});
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleEntityChange = (e: React.ChangeEvent, value: string) => {
    setSelectedEntity(value);
    setErrors({});
    const amount = (coins[value] || coins.base).toFixed(2);
    updateField('productId', value);
    updateField('productRaw', amount);
    // updateField('finalizeType', selectedStrengths ? 'strengths' : 'wastes');
  };

  return (
    <div className={styles.stepWrapper}>
      <Typography variant="h6">
        {values.description === 'Wastes'
          ? ' Choose Reduce Waste'
          : 'Choose Increase Value'}
      </Typography>
      <Grid container spacing={3} className={classes.grid}>
        <Grid item sm={6}>
          <RadioGroup
            onChange={handleEntityChange}
            value={selectedEntity}
            aria-label="entity"
            name="entity"
          >
            {leftList.map((option) => {
              return (
                <FormControlLabel
                  className={styles.improveOption}
                  key={option.value}
                  classes={radioClasses}
                  value={option.value}
                  control={<Radio />}
                  label={<RadioText option={option} coins={coins} />}
                />
              );
            })}
          </RadioGroup>
        </Grid>
        <Grid item sm={6}>
          <RadioGroup
            onChange={handleEntityChange}
            value={selectedEntity}
            aria-label="entity"
            name="entity"
          >
            {rightList.map((option) => {
              return (
                <FormControlLabel
                  className={styles.improveOption}
                  key={option.value}
                  classes={radioClasses}
                  value={option.value}
                  control={<Radio />}
                  label={<RadioText option={option} coins={coins} />}
                />
              );
            })}
          </RadioGroup>
        </Grid>
      </Grid>
      {errors.productId ? (
        <Typography className={classes.error}>{errors.productId}</Typography>
      ) : null}
    </div>
  );
};

export const ChooseSubTypeStep = styled(ChooseSubTypeView)(
  {}
) as StepComponentType<StepComponentProps>;

export default ChooseSubTypeStep;
