import {
  Box,
  Divider,
  styled,
  Typography,
  StepLabel,
  Avatar,
  Radio
} from '@mui/material';
import { colorPalette } from 'theme';
import PsFormControl from 'components/common/PsFormControl';

interface StyledSubHeaderTextProps {
  type: string;
}

export const StyledHeaderText = styled(Typography)({
  fontWeight: 'bold'
});

export const StyledHeaderWrapper = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '1rem'
});

export const TextWrapper = styled(PsFormControl)({
  marginTop: 0,
  '& .css-1jqx3gz-MuiFormHelperText-root-helperText': {
    margin: '0.5rem 0'
  }
});

export const StyledSubHeaderText = styled(Typography)({
  color: colorPalette.purple,
  fontWeight: 600,
  marginBottom: '1rem'
});

export const StyledDivider = styled(Divider)({
  margin: '1.625rem 0'
});

export const ActiveStepLabel = styled(StepLabel)({
  color: 'red !important'
});

export const StyledCounterButtonWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '.25rem'
});

export const StyledBackButtonWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start',
  marginTop: '.25rem'
});

export const StyledSubContainer = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '.9375rem',
  justifyContent: 'center',
  marginTop: '.625rem'
});

export const StyledContainer = styled(Box)({
  marginLeft: '3.125rem',
  marginRight: '3.125rem'
});

export const StyledSubTypeContainer = styled(Box)({
  alignItems: 'center',
  backgroundColor: colorPalette.lightCyan,
  border: `0.0988rem solid ${colorPalette.lightLavender}`,
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  minHeight: '8.5rem',
  minWidth: '13rem',
  padding: '0.625rem'
});

export const StyledTYpography = styled(Typography)({
  alignItems: 'center',
  display: 'flex'
});

export const StyledTypeImage = styled(Avatar)({
  height: 50,
  width: 50,
  borderRadius: 0,
  '& img': {
    objectFit: 'contain'
  }
});

export const StyledLogo = styled(Avatar)({
  height: 25,
  marginRight: '0.3125rem',
  width: 25
});

export const StyledIdeaCoin = styled(Box)({
  color: colorPalette.deepViolet,
  marginRight: '0.3125rem'
});

export const StyledRadioGroupContainer = styled(Box)({
  width: '100%'
});

export const StyledHolder = styled(Box)({
  alignItems: 'center',
  backgroundColor: '#efeafc',
  borderRadius: '0.25rem',
  display: 'flex',
  justifyContent: 'center',
  margin: 'auto auto 1.8rem',
  maxWidth: '40rem',
  padding: '.25rem'
});

export const StyledRadioButton = styled(Radio)({
  display: 'none'
});

export const StyledRadioLabel = styled(Box)({
  borderRadius: 4,
  color: colorPalette.purple,
  cursor: 'pointer',
  flex: '1',
  marginRight: 8,
  padding: '0.625rem 1.25rem',
  textAlign: 'center',
  '&.selected-option': {
    backgroundColor: colorPalette.purple,
    color: colorPalette.white
  }
});

export const StyledProductTitle = styled(Box)({
  fontSize: '1rem',
  '& span': {
    color: colorPalette.purple,
    fontWeight: 700
  }
});

export const StyledWrapperSubLabel = styled(Box)({
  fontSize: '1rem',
  marginBottom: '1rem',
  '& span': {
    color: colorPalette.purple,
    fontWeight: 700
  }
});
