import React from 'react';
import { styled, useTheme, alpha, darken } from '@mui/material/styles';

import { PsLogo } from 'components/common/PsLogo';

import { PsTheme } from '../../theme';
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from 'tss-react/mui';

export const EditIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M14.1665 2.49999C14.3854 2.28112 14.6452 2.1075 14.9312 1.98905C15.2171 1.8706 15.5236 1.80963 15.8332 1.80963C16.1427 1.80963 16.4492 1.8706 16.7352 1.98905C17.0211 2.1075 17.281 2.28112 17.4998 2.49999C17.7187 2.71886 17.8923 2.97869 18.0108 3.26466C18.1292 3.55063 18.1902 3.85713 18.1902 4.16665C18.1902 4.47618 18.1292 4.78268 18.0108 5.06865C17.8923 5.35461 17.7187 5.61445 17.4998 5.83332L6.24984 17.0833L1.6665 18.3333L2.9165 13.75L14.1665 2.49999Z"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

type ClassKey =
  | 'root'
  | 'outlined'
  | 'contained'
  | 'sizeSmall'
  | 'sizeLarge'
  | 'facebook'
  | 'google'
  | 'editBtn'
  | 'hasCoins'
  | 'coins';

const useStyles = makeStyles()((theme) => {
  const psTheme = theme as PsTheme;
  return {
    root: {
      position: 'relative',
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '36px',
      borderRadius: '10px',
      textTransform: 'none',
      paddingLeft: 30,
      paddingRight: 30,
      '& .loader': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        margin: '-15px 0 0 -15px'
      },
      '&.small': {
        minHeight: 48,
        lineHeight: '30px',
        borderRadius: 10
      },
      '&.smallest': {
        paddingLeft: 25,
        paddingRight: 25,
        borderRadius: 10,
        lineHeight: '28px',
        minHeight: 40
      }
    },
    outlined: {
      borderWidth: 2,
      lineHeight: '34px',
      '&:hover': {
        borderWidth: 2
      },
      '&.Mui-disabled': {
        color: alpha(psTheme.palette.third.main, 0.3),
        borderColor: alpha(psTheme.palette.third.main, 0.3)
      }
    },
    contained: {
      '&.Mui-disabled': {
        color: 'white',
        backgroundColor: alpha(psTheme.palette.third.main, 0.3)
      }
    },
    outlinedSecondary: {
      paddingLeft: 35,
      paddingRight: 35,
      borderRadius: '30px',
      lineHeight: '44px',
      fontSize: 18
    },
    containedSecondary: {
      paddingLeft: 25,
      paddingRight: 25,
      borderRadius: '30px',
      lineHeight: '44px',
      fontSize: 18,
      minHeight: 56
    },
    sizeSmall: {
      fontSize: '18px',
      lineHeight: '40px'
    },
    sizeLarge: {
      fontSize: '22px',
      lineHeight: '32px'
    },
    facebook: {
      background: '#3B5997',
      '&:hover': {
        background: darken('#3B5997', 0.2)
      }
    },
    google: {
      background: '#DB4537',
      '&:hover': {
        background: darken('#DB4537', 0.2)
      }
    },
    editBtn: {
      display: 'inline-flex',
      alignItems: 'center',
      padding: '0 20px',
      fontSize: 18,
      minHeight: 40,
      borderRadius: 10,
      background: '#F5F3FA',
      color: psTheme.palette.primary.main,
      cursor: 'pointer',
      '& svg': {
        margin: '0 6px 0 0px',
        flexShrink: '0'
      },
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    hasCoins: {
      display: 'flex',
      justifyContent: 'space-around'
    },
    coins: {
      display: 'inline-flex',
      alignItems: 'center',
      paddingLeft: 10,
      fontSize: 16,
      fontWeight: 600,
      whiteSpace: 'nowrap'
    }
  };
});

export type PsButtonProps = ButtonProps & {
  component?: string;
  loading?: boolean;
  cancel?: boolean;
  social?: string;
  small?: boolean;
  smallest?: boolean;
  target?: string;
  coins?: string | React.ReactNode;
};

export const PsButtonView = (props: PsButtonProps) => {
  const { classes } = useStyles();
  const {
    children,
    cancel,
    className,
    color = 'primary',
    social,
    small,
    smallest,
    coins,
    loading,
    ...rest
  } = props;
  const {
    google,
    facebook,
    editBtn,
    coins: c,
    hasCoins,
    ...restClasses
  } = classes;
  let rootClassName = `${className} ${
    social ? (social === 'google' ? google : facebook) : ''
  }`;
  let coinsSize = 36;
  if (small) {
    rootClassName += ' small';
    coinsSize = 30;
  }
  if (smallest) {
    rootClassName += ' smallest';
    coinsSize = 25;
  }
  if (coins) {
    rootClassName += ' ' + classes.hasCoins;
  }
  return (
    <Button
      classes={restClasses}
      variant={cancel ? 'outlined' : 'contained'}
      color={color}
      {...rest}
      className={rootClassName}
    >
      {loading ? (
        <CircularProgress className="loader" size={30} thickness={2} />
      ) : null}
      {children}
      {coins ? (
        <span className={classes.coins}>
          <PsLogo size={coinsSize} small />
          &nbsp; {coins}
        </span>
      ) : null}
    </Button>
  );
};

export const PsButton = styled(PsButtonView)({});

export default PsButton;

const EditButtonView = ({ className, children, ...rest }: PsButtonProps) => {
  const { classes } = useStyles();
  let rootClassName = classes.editBtn;
  if (className) {
    rootClassName += ' ' + className;
  }
  return (
    <span className={rootClassName} {...rest}>
      <EditIcon />
      {children}
    </span>
  );
};

export const EditButton = styled(EditButtonView)({});
