import { Box, Modal, Typography, styled } from '@mui/material';
import { colorPalette } from 'theme';

export const StyledModal = styled(Modal)({
  maxWidth: '43.1875rem',
  margin: 'auto'
});

export const HeaderMainBox = styled(Box)({
  alignItems: 'flex-start',
  display: 'flex',
  justifyContent: 'space-between'
});

export const TitleTypography = styled(Typography)({
  fontSize: '1.75rem',
  fontWeight: '700',
  lineHeight: '1.7'
});

export const SubtitleTypography = styled(Typography)({
  color: colorPalette.purple,
  fontSize: '1rem',
  fontWeight: '600',
  marginTop: '.25rem'
});

export const EarnTypography = styled(Typography)({
  fontSize: '1.1875rem',
  whiteSpace: 'pre',
  fontWeight: '500'
});

export const EarnBox = styled(Box)({
  alignItems: 'center',
  display: 'flex'
});
