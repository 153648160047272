import React from 'react';
import { useDispatch } from 'react-redux';
import { Box, Tooltip } from '@mui/material';
import Actions from 'redux-state/actions';
import { GetUser } from 'redux-state/selectors';
import { Constants, VARIANT } from 'utilities/constants';
import {
  ConnectButton,
  SocialLinkInfo,
  SocialLinkItem,
  SocialLinkText,
  SocialSwitch,
  StyledButtonContainer,
  StyledButtonTypography,
  StyledNameEmailTypography,
  StyledPlatformTypography
} from './StyledComponents';

interface Icon {
  component: React.ReactNode;
  connected: boolean;
  email: string;
  id: string | number;
  onClick?: () => void;
  platform: string;
  username: string;
}

interface SocialIconsProps {
  icon: Icon;
  setShare: React.Dispatch<React.SetStateAction<boolean>>;
  share: boolean;
}

export const SocialIcons: React.FC<SocialIconsProps> = ({
  icon,
  setShare,
  share
}) => {
  const user = GetUser();
  const dispatch = useDispatch();

  const handleDisconnect = (id) => {
    if (user && icon.platform !== '') {
      dispatch(Actions.socialDisconnect(id));
    }
  };

  return (
    <SocialLinkItem key={icon.platform}>
      <SocialLinkInfo>
        <Box>{icon.component}</Box>
        <SocialLinkText>
          <StyledPlatformTypography>
            {icon.platform.charAt(0).toUpperCase() + icon.platform.slice(1)}
          </StyledPlatformTypography>
          {icon.username && (
            <Tooltip title={icon.username}>
              <StyledNameEmailTypography variant={VARIANT.BODY1}>
                @{icon.username}
              </StyledNameEmailTypography>
            </Tooltip>
          )}
          {icon.email && (
            <Tooltip title={icon.email}>
              <StyledNameEmailTypography
                variant={VARIANT.BODY2}
                color="textSecondary"
              >
                {icon.email}
              </StyledNameEmailTypography>
            </Tooltip>
          )}
        </SocialLinkText>
      </SocialLinkInfo>
      <StyledButtonContainer>
        {icon.connected ? (
          <ConnectButton
            isConnected={icon.connected}
            variant={VARIANT.CONTAINED}
            onClick={() => handleDisconnect(icon.id)}
          >
            <StyledButtonTypography>
              {Constants.DISCONNECT}
            </StyledButtonTypography>
          </ConnectButton>
        ) : (
          <ConnectButton
            isConnected={icon.connected}
            variant={VARIANT.CONTAINED}
            onClick={icon.onClick}
          >
            <StyledButtonTypography>{Constants.CONNECT}</StyledButtonTypography>
          </ConnectButton>
        )}
        <Tooltip title="Enable sharing and connect again">
          <SocialSwitch checked={share} onChange={() => setShare(!share)} />
        </Tooltip>
      </StyledButtonContainer>
    </SocialLinkItem>
  );
};
