import { BsPlus } from 'react-icons/bs';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { TabPanel } from '@mui/lab';
import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Drawer,
  LinearProgress,
  Link,
  styled,
  Typography,
  TypographyProps
} from '@mui/material';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { colorPalette } from 'theme';
import { Constants } from 'utilities/constants';

export const Loader = styled(Box)({
  padding: '.625rem 0rem 1.125rem 0rem'
});

export const LoadingBar = styled(LinearProgress)({
  display: 'flex',
  justifyContent: 'center',
  width: '95%'
});

export const AddChildToGrandParentBox = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  margin: '1.25rem 0 .625rem 0',
  [theme.breakpoints.up('xl')]: {
    margin: '1.25rem 0 .625rem 3.5rem'
  },
  [theme.breakpoints.up('md')]: {
    margin: '1.25rem 0 .625rem 2rem'
  }
}));

export const AddChildToGrandParentText = styled(Typography)(({ theme }) => ({
  fontSize: '1.1rem',
  fontWeight: 'bold',
  [theme.breakpoints.up('md')]: {
    fontSize: '1.375rem'
  }
}));

export const AddChildToGrandParentButton = styled(Button)(({ theme }) => ({
  backgroundColor: colorPalette.cinnabar,
  borderRadius: '1.5rem',
  color: colorPalette.white,
  fontSize: '.75rem',
  fontWeight: '700',
  padding: '.5rem .8rem',
  textTransform: 'capitalize',
  ':hover': {
    backgroundColor: colorPalette.maroon
  },
  [theme.breakpoints.up('md')]: {
    padding: '.6rem 2.8rem',
    fontSize: '1rem'
  }
}));

export const GenerateMoreButtonBox = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '2rem'
}));

export const GenerateMoreButton = styled(Box)<{
  disabled?: boolean;
  type: string;
}>(({ type, disabled, theme }) => ({
  color: colorPalette.black,
  cursor: disabled ? 'not-allowed' : 'pointer',
  fontWeight: 'bold',
  borderRadius: '3.75rem',
  padding: '.6rem 2.8rem',
  border:
    type === Constants.PROBLEMS
      ? `1px solid ${colorPalette.malachite}`
      : `1px solid ${colorPalette.torchRed}`,
  '&:hover': !disabled && {
    backgroundColor:
      type === Constants.PROBLEMS
        ? colorPalette.shamrockGreen
        : colorPalette.torchRed,
    color: colorPalette.white
  },
  [theme.breakpoints.down('md')]: {
    padding: '.5rem .8rem',
    fontSize: '1rem'
  }
}));

export const CustomTreeView = styled(SimpleTreeView)<{
  leftPad: boolean;
}>(({ leftPad, theme }) => ({
  [theme.breakpoints.up('xl')]: {
    paddingLeft: leftPad ? '2.1rem ' : 0
  },
  [theme.breakpoints.up('md')]: {
    paddingLeft: leftPad ? '1rem ' : 0
  },
  flexGrow: 1,
  paddingLeft: '0',
  position: 'relative',
  width: '100%',
  '& > li': {
    ':first-child': {
      '&:before': {
        borderLeft: '0rem'
      }
    }
  }
}));

export const StyledContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center'
});

export const StyledSubContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%'
});

export const ParentBox = styled(Box)({
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

export const StyledTypography = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    fontSize: '1.5625rem',
    marginTop: '2.5rem',
    color: theme.palette.text.secondary
  })
);

export const StackButton = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  },
  display: 'none'
}));

export const AddToConceptButton = styled(Button)({
  backgroundColor: colorPalette.lightGreen,
  color: colorPalette.white,
  borderRadius: '1.5625rem',
  padding: '.625rem .9375rem'
});

export const IconSize = 25;

export const SectionStyle = { padding: '0px .3125rem' };

export const ConceptButtonText = styled(Typography)({
  lineHeight: '1.25rem',
  fontSize: '.875rem',
  whiteSpace: 'nowrap'
});

export const TreeIconStyle = { marginRight: '.625rem' };

export const DrawerStyle = {
  width: 590,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: 590,
    boxSizing: 'border-box',
    padding: '1rem'
  }
};

export const TabListBoxStyle = { border: 0, borderColor: 'divider' };

export const TabListStyle = {
  '& .MuiTabs-indicator': {
    display: 'none'
  }
};

export const StyledTabPanel = styled(TabPanel)({
  padding: '1.5rem 0'
});

export const ConceptDrawerStyle = styled(Drawer)({
  anchor: 'left'
});

export const StyledParent = styled(Box)(({ theme }) => ({
  '.user-avatar': {
    width: '3.8rem',
    height: '3.8rem'
  },
  '.item-img': {
    width: '2.3rem',
    height: '2.3rem'
  },
  [theme.breakpoints.down('sm')]: {
    '.user-avatar': {
      width: '2.5rem',
      height: '2.5rem'
    },
    '.item-img': {
      width: '2rem',
      height: '2rem'
    }
  },
  [theme.breakpoints.up('xl')]: {
    '.user-avatar': {
      width: '4.5rem',
      height: '4.5rem'
    },
    '.item-img': {
      width: '2.8rem',
      height: '2.8rem'
    }
  },
  '.selected-node-text': {
    fontWeight: 'bold'
  }
}));

export const CircularBox = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center'
}));

export const StyledTopSection = styled(Box)<{
  leftPadding: boolean;
}>(({ leftPadding, theme }) => ({
  [theme.breakpoints.up('xl')]: {
    padding: leftPadding ? '0 2rem' : 0
  },
  width: '100%',
  padding: leftPadding ? '0 0.6rem' : 0
}));

export const StyledDrawer = styled(Drawer)<{
  open: boolean;
  leftMarg: boolean;
}>(({ open, leftMarg, theme }) => ({
  width: open ? '30%' : '0rem',
  zIndex: 9,
  marginLeft: '0rem',
  [theme.breakpoints.up('md')]: {
    marginLeft: open && leftMarg ? '0.5rem' : '0rem'
  },
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    height: '100vh',
    left: '0',
    maxWidth: '30%',
    padding: '1.5rem 1rem 1rem',
    paddingBottom: '3.75rem',
    position: 'fixed',
    top: '9rem',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      left: '5.0625rem',
      top: '5.625rem',
      maxWidth: '30%'
    }
  }
}));

export const StyleBox = styled(Box)<{ leftMarg: boolean }>(
  ({ leftMarg, theme }) => ({
    display: 'flex',
    marginTop: leftMarg ? '5rem' : 0,
    [theme.breakpoints.up('md')]: {
      marginLeft: leftMarg ? '5rem' : 0,
      marginTop: '0'
    }
  })
);

export const StyledItemsBox = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end'
});

export const StyledSeeAllConceptsBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  gap: '0.125rem',
  justifyContent: 'flex-start'
});

export const StyledSeeActiveConceptsBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  gap: '0.125rem',
  marginBottom: '-10px',
  position: 'absolute',
  right: 0,
  top: -25
});

export const StyledButtonTypography = styled(Typography)({
  color: colorPalette.purple,
  cursor: 'pointer',
  fontSize: '0.75rem',
  fontWeight: 600
});

export const StyledArrowIcon = styled(ArrowBackIosIcon)({
  color: colorPalette.purple,
  cursor: 'pointer',
  height: '0.75rem',
  width: '0.75rem'
});

export const StyledArrowForwardIcon = styled(ArrowForwardIosIcon)({
  color: colorPalette.purple,
  cursor: 'pointer',
  height: '0.75rem',
  width: '0.75rem'
});

export const StyledClose = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  right: '0.1875rem',
  top: '0.1875rem',
  zIndex: '.625rem',
  [theme.breakpoints.up('md')]: {
    display: 'none'
  }
}));

export const ActiveBox = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    marginBottom: '1rem',
    alignItems: 'center'
  }
}));

export const StyledPlusIcon = styled(BsPlus)({
  fontSize: '35px',
  fontWeight: 700,
  strokeWidth: '.0313rem'
});

export const StyledButtonContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between'
});

export const StyledButtonTextContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  marginLeft: '-1.125rem'
});

export const StyledButtonEarnPointsTextContainer = styled(Box)({
  fontWeight: 600
});

export const StyledIdeaIconTextContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  marginLeft: '3.75rem'
});

export const StyledIdeadPointsIconContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  marginRight: '.5rem'
});

export const StyledAccordion = styled(Accordion)({
  borderRadius: '0.625rem',
  boxShadow: '0rem 0.25rem 0.5rem rgba(0, 0, 0, 0.2)',
  '&:before': {
    display: 'none'
  }
});

export const StyledProgressAccordion = styled(StyledAccordion)({
  boxShadow: '0rem 0.25rem 0.5rem rgba(0, 0, 0, 0.2)',
  marginTop: '16px'
});

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  '& .MuiLinearProgress-bar': {
    backgroundColor: theme.palette.success.main
  }
}));

export const StyledAccordionContentBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%'
});

export const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  backgroundColor: '#e0e0e0',
  borderRadius: 5,
  height: 10,
  marginRight: 2,
  width: '100%',
  '& .MuiLinearProgress-bar': {
    backgroundColor: theme.palette.success.main
  }
}));

export const StyledAIChatLink = styled(Link)({
  color: 'primary',
  cursor: 'pointer',
  display: 'flex',
  fontSize: '0.875rem',
  justifyContent: 'flex-end',
  marginBottom: 8,
  textDecoration: 'underline'
});
