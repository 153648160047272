import React, { FC, ReactElement, ReactNode, CSSProperties } from 'react';
import { Box, Breakpoint, CircularProgress, Dialog } from '@mui/material';
import { CtaShareIcon } from 'components/icons/CtaIShareIcon';
import { EarnRoyaltyIcon } from 'components/icons/EarnRoyaltyIcon';
import { StyledWatermarkContainer } from './StyledComponents';

export interface CommonSectionProps {
  children?: ReactNode | JSX.Element | ReactElement;
}

interface IBaseModalProps extends CommonSectionProps {
  fullWidth?: boolean;
  isEarnRoyaltyBgImage?: boolean;
  isCtaInfoBgImage?: boolean;
  loading?: boolean;
  maxWidth?: false | Breakpoint;
  onClose?: (
    e: MouseEvent | React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => void;
  open?: boolean;
}

export const BaseModal: FC<IBaseModalProps> = ({
  children,
  fullWidth = true,
  isCtaInfoBgImage = false,
  isEarnRoyaltyBgImage = false,
  loading = false,
  maxWidth,
  onClose,
  open,
  ...others
}) => {
  const overlayStyle: CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    zIndex: 1
  };

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onClose={onClose}
      open={open}
      PaperProps={{
        style: {
          maxHeight: '90vh',
          height: 'auto',
          overflowY: 'auto'
        }
      }}
      {...others}
    >
      {isEarnRoyaltyBgImage && (
        <StyledWatermarkContainer>
          <EarnRoyaltyIcon />
        </StyledWatermarkContainer>
      )}
      {isCtaInfoBgImage && (
        <StyledWatermarkContainer>
          <CtaShareIcon />
        </StyledWatermarkContainer>
      )}
      {children}
      {loading && (
        <Box style={overlayStyle}>
          <CircularProgress size={30} thickness={2} />
        </Box>
      )}
    </Dialog>
  );
};
