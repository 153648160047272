import React, { useState, useContext, useEffect, ReactElement } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import ModalWindow from 'modals/ModalWindow';
import { PsButton } from 'components/common/PsButton';
import Step from 'components/Step';
import Stepper from 'components/Stepper';
import { Solution } from 'components/CardSolution';
import { ModalContext, ModalComponentsKeys } from 'contexts/ModalContext';
import {
  ModalDataContext,
  AddSolutionSteps,
  StepComponentProps
} from 'contexts/ModalDataContext';
import { DataContext } from 'contexts/DataContext';
import { AuthContext } from 'contexts/AuthContext';
import { ADD_SOLUTION_STEPS_CONFIG, defaultSolutionTitle } from './config';
import styles from 'modals/ModalWindow.module.scss';
import { makeStyles } from 'tss-react/mui';
import useRouter from 'hooks/useRouter';
import { useFetchGraph } from 'hooks/useFetchGraph';

const steps: string[] = ['Solution Name', 'Tag Name', 'Finalize Solution'];

const useButtonClasses = makeStyles()(() => {
  return {
    root: {
      margin: '8px 0',
      fontWeight: 400
    }
  };
});

const AddNewSolution: React.FC = () => {
  const { user } = useContext(AuthContext);
  const {
    openModal,
    triggerActionCallback,
    isRedirectionEnabled,
    setIsRedirectionEnabled
  } = useContext(ModalContext);
  const { values, setErrors, updateField } = useContext(ModalDataContext);
  const { dataProvider, refreshRelatedSolutionsList, refreshSolutionDetail } =
    useContext(DataContext);
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [subTitle, setSubTitle] = useState<React.ReactNode>(undefined);
  // const { fetchChildrenGraph } = useFetchGraph({ type, id });

  let initStep = AddSolutionSteps.SolutionName;
  let initNumber = 0;
  let initPrevSteps: string[] = [];

  if (values.activeSolution) {
    initStep = AddSolutionSteps.SolutionTags;
    initNumber = 1;
    initPrevSteps = [AddSolutionSteps.SolutionName];
  }

  const [currentStep, setCurrentStep] = useState<string | undefined>(initStep);
  const [activeStepNumber, setActiveStepNumber] = useState<number>(initNumber);
  const [prevSteps, setPrevSteps] = useState<string[]>(initPrevSteps);

  const buttonClasses = useButtonClasses();

  const {
    Component,
    nextStep,
    validate,
    isNextStepBlocked,
    isFinalStep,
    nextButtonTitle,
    modalTitle
  } = ADD_SOLUTION_STEPS_CONFIG[currentStep || ''];

  const StepComponent = Component as React.FC<StepComponentProps>;

  useEffect(() => {
    if (
      user &&
      user.userData &&
      user.userData.tags &&
      user.userData.tags.length
    ) {
      const tags = user.userData.tags.map((t) => t.id);
      updateField('tags', tags);
    }
  }, []);

  useEffect(() => {
    let elem: ReactElement | undefined = undefined;
    if (values.parentProblemLabel) {
      elem = (
        <>
          for the problem of <b>{values.parentProblemLabel}</b>
        </>
      );
    }
    if (values.parentSolutionLabel) {
      elem = (
        <>
          for the solution of <b>{values.parentSolutionLabel}</b>
        </>
      );
    }
    if (values.selectApplicationLabel) {
      elem = (
        <>
          to the invention of <b>{values.selectApplicationLabel}</b>
        </>
      );
    }
    setSubTitle(elem);
  }, [
    values.parentProblemLabel,
    values.parentSolutionLabel,
    values.selectApplicationLabel
  ]);

  const solutionFastCreate = async (): Promise<Solution | void> => {
    const solutionInfo = {
      title: (values.titleSolution || '').replace(defaultSolutionTitle, ''),
      problem: values.parentProblem,
      parentSolution: values.parentSolution,
      isPublic: values.finalizeType === 'public',
      termsAgree: true, // values.termsAgree,
      isInProgress: true
    };

    return Promise.resolve()
      .then(() => {
        if (values.activeSolution) {
          return dataProvider.update<Solution>('solutions', {
            id: values.activeSolution.id,
            data: solutionInfo
          });
        }
        return dataProvider.create<Solution>('solutions', {
          data: solutionInfo
        });
      })
      .then(({ data }) => {
        updateField('activeSolution', data);
        return data;
      });
  };

  const handleClickBack = () => {
    if (activeStepNumber === 0) {
      openModal(ModalComponentsKeys.addNewEntity);
      return;
    }
    setActiveStepNumber((prev) => Math.max(0, prev - 1));
    setCurrentStep(prevSteps[prevSteps.length - 1]);
    setPrevSteps(prevSteps.slice(0, prevSteps.length - 1));
  };

  const handleNextStep = async () => {
    if (typeof validate === 'function') {
      const { success, errors } = validate(values);

      if (!success) {
        setErrors({ ...errors });

        return;
      }
    }

    if (activeStepNumber === 0) {
      // setLoading(true);
      // solutionFastCreate()
      //   .then(() => {
      //     setPrevSteps([AddSolutionSteps.SolutionName]);
      //     setErrors({});
      //     setActiveStepNumber(1);
      //     setCurrentStep(AddSolutionSteps.SolutionTags);
      //   })
      //   .catch((err) => {
      //     console.error(err);
      //   })
      //   .finally(() => {
      //     setLoading(false);
      //   });
      // return;
    }

    if (isFinalStep) {
      const solutionInfo = {
        title: (values.titleSolution || '').replace(defaultSolutionTitle, ''),
        tags: values.tagsSolution,
        body: values.descriptionSolution,
        files: values.filesSolution,
        problem: values.parentProblem,
        parentSolution: values.parentSolution,
        isPublic: values.finalizeType === 'public',
        termsAgree: values.termsAgree,
        isInProgress: false
      };

      setLoading(true);

      Promise.resolve()
        .then(() => {
          if (values.activeSolution) {
            return dataProvider.update<Solution>('solutions', {
              id: values.activeSolution.id,
              data: solutionInfo
            });
          }
          return dataProvider.create<Solution>('solutions', {
            data: solutionInfo
          });
        })
        .then(({ data }) => {
          refreshSolutionDetail();
          refreshRelatedSolutionsList();
          if (values.selectApplication) {
            return dataProvider
              .addSolutionToProblem(
                values.parentProblem || '',
                values.selectApplication || '',
                data.id // solution id
              )
              .then(() => {
                return data.key;
              });
          }
          return data.key;
        })
        .then((solutionKey) => {
          if (!isRedirectionEnabled) {
            triggerActionCallback();
            setIsRedirectionEnabled(true);
          } else {
            router.push(`/solutions/${solutionKey}`);
          }
          openModal(ModalComponentsKeys.success);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });

      return;
    }

    setPrevSteps([...prevSteps, currentStep || '']);
    setErrors({});
    setActiveStepNumber((prev) => prev + 1);
    if (typeof nextStep === 'function') {
      setCurrentStep(nextStep(values));
      return;
    }

    setCurrentStep(nextStep);
  };

  const onStepNotify = (args: any) => {
    if (args === 'terms click') {
      if (loading) {
        return;
      }
      updateField('termsAgree', true);
      if (values.selectApplication) {
        return;
      }
      setLoading(true);
      solutionFastCreate()
        .then((solution) => {
          if (!solution) {
            throw new Error('No solution create for sign');
          }
          return dataProvider.solutionSign(solution.id);
        })
        .then((data) => {
          if (data.redirect) {
            window.open(data.redirect, '_self');
          } else {
            window.open(data.link, '_self');
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const title = values.modalTitle || modalTitle || 'Add New Solution';

  const isNextStepDisabled: boolean =
    typeof isNextStepBlocked === 'function' && isNextStepBlocked(values);

  return (
    <ModalWindow title={title} subTitle={subTitle}>
      <Stepper className={styles.stepper} activeStep={activeStepNumber}>
        {steps.map((step) => (
          <Step key={step}>{step}</Step>
        ))}
      </Stepper>
      <StepComponent onNotify={onStepNotify} />
      <div className={styles.actions}>
        <PsButton
          disabled={isNextStepDisabled || loading}
          loading={loading}
          onClick={handleNextStep}
          fullWidth
          classes={buttonClasses}
        >
          {nextButtonTitle || 'Next'}
        </PsButton>
        <PsButton
          onClick={handleClickBack}
          fullWidth
          cancel
          classes={buttonClasses}
        >
          Back
        </PsButton>
      </div>
    </ModalWindow>
  );
};

export default AddNewSolution;
