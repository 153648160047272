import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const BackgroundLandingPage = (props: SvgIconProps) => {
  return (
    <SvgIcon
      sx={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: -1
      }}
      viewBox="0 0 1600 908"
      preserveAspectRatio="xMidYMid slice"
    >
      <rect
        y="-45"
        width="1600"
        height="953"
        fill="url(#paint0_linear_5573_3636)"
      />
      <mask
        id="mask0_5573_3636"
        maskUnits="userSpaceOnUse"
        x="0"
        y="-45"
        width="1600"
        height="953"
      >
        <rect y="-45" width="1600" height="953" fill="white" />
      </mask>
      <g mask="url(#mask0_5573_3636)">
        <circle
          opacity="0.0361096"
          cx="-304.5"
          cy="-202.5"
          r="575.5"
          fill="white"
        />
        <ellipse
          opacity="0.0458287"
          cx="-314.5"
          cy="-222.5"
          rx="822.5"
          ry="822"
          fill="white"
        />
        <ellipse
          opacity="0.050665"
          cx="-345.5"
          cy="-242"
          rx="1121.5"
          ry="1121"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_5573_3636"
          x1="804.757"
          y1="-515.833"
          x2="-35.6038"
          y2="886.667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7B11F9" />
          <stop offset="0.394106" stopColor="#7922D8" />
          <stop offset="1" stopColor="#3636B2" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};
