import React, { Dispatch, SetStateAction } from 'react';
import { FormControlLabel, RadioGroup } from '@mui/material';
import { Constants } from 'utilities/constants';
import {
  StyledHolder,
  StyledRadioButton,
  StyledRadioGroupContainer,
  StyledRadioLabel
} from '../../StyledComponents';
interface TypesProps {
  selectedEntity: string;
  setSelectedEntity: Dispatch<SetStateAction<string>>;
}
const categories = [
  { value: 'reduce', text: 'Reduce' },
  { value: 'increase', text: 'Increase' }
];
const Types: React.FC<TypesProps> = ({ selectedEntity, setSelectedEntity }) => {
  return (
    <StyledHolder>
      <StyledRadioGroupContainer>
        <RadioGroup
          aria-label={Constants.ENTITY}
          name={Constants.ENTITY}
          row
          value={selectedEntity}
        >
          {categories.map((option) => (
            <StyledRadioLabel
              key={option.value}
              className={
                selectedEntity === option.value ? 'selected-option' : ''
              }
              onClick={() => setSelectedEntity(option.value)}
            >
              <FormControlLabel
                control={<StyledRadioButton />}
                label={option.text}
                value={option.value}
              />
            </StyledRadioLabel>
          ))}
        </RadioGroup>
      </StyledRadioGroupContainer>
    </StyledHolder>
  );
};
export default Types;
