import ContestName from './steps/ContestName';
import ContestDescription from './steps/ContestDescription';
import ContestTypes from './steps/ContestTypes';
import ContestBounty from './steps/ContestBounty';
import ContestProblems from './steps/ContestProblems';
import ProblemName, {
  defaultProblemTitle
} from 'modals/AddNewProblem/steps/ProblemName';
import ProblemTagsStep from 'modals/AddNewProblem/steps/ProblemTagsStep';
import FinilizeProblemStep from 'modals/AddNewProblem/steps/FinilizeProblemStep';
import {
  ValidateFormType,
  FormStepConfig,
  AddContestSteps,
  AddProblemSteps
} from 'contexts/ModalDataContext';
import { isTitleValid } from '../../helpers';

export { defaultProblemTitle };

export const stepName = {
  PROBLEM_START: 'problemStart',
  PROBLEM_END: 'problemEnd'
};

export const ADD_CONTEST_STEPS_CONFIG: {
  [name: string]: FormStepConfig;
} = {
  [AddContestSteps.ContestName]: {
    Component: ContestName as React.FC,
    nextStep: AddContestSteps.ContestDescription,
    isNextStepBlocked: (data) => {
      return !data.titleContest || !data.contestTag;
    },
    validate: (data): ValidateFormType => {
      if (data.titleContest && data.contestTag) {
        return { success: true };
      }
      return { success: false, errors: { titleContest: 'required' } };
    }
  },
  [AddContestSteps.ContestDescription]: {
    Component: ContestDescription as React.FC,
    nextStep: AddContestSteps.ContestTypes,
    isNextStepBlocked: (data) => {
      return !data.descriptionContest;
    },
    validate: (data): ValidateFormType => {
      if (data.descriptionContest) {
        return { success: true };
      }
      return { success: false, errors: { descriptionContest: 'required' } };
    }
  },
  [AddContestSteps.ContestTypes]: {
    nextStep: AddContestSteps.ContestBounty,
    Component: ContestTypes as React.FC,
    isNextStepBlocked: (data) => {
      if (!data.contestStart || !data.contestEnd) {
        return true;
      }
      const start = new Date(data.contestStart as string);
      const end = new Date(data.contestEnd as string);
      return end < start;
    },
    validate: (data): ValidateFormType => {
      if (data.contestStart && data.contestEnd) {
        return { success: true };
      }
      return { success: false, errors: { contestStart: 'required' } };
    }
  },
  [AddContestSteps.ContestBounty]: {
    nextStep: AddContestSteps.ContestProblems,
    Component: ContestBounty as React.FC,
    isNextStepBlocked: (data) => {
      return false;
    },
    validate: (data): ValidateFormType => {
      return { success: true };
    }
  },
  [AddContestSteps.ContestProblems]: {
    isFinalStep: true,
    Component: ContestProblems as React.FC,
    isNextStepBlocked: (data) => {
      if (!data.contestProblems || data.contestProblems.length === 0)
        return true;
      return false;
    },
    validate: (data): ValidateFormType => {
      if (data.contestProblems && data.contestProblems.length) {
        return { success: true };
      }
      return { success: false, errors: { contestProblems: 'required' } };
    },
    nextButtonTitle: 'Finish'
  },
  [AddProblemSteps.ProblemName]: {
    Component: ProblemName,
    nextStep: AddProblemSteps.ProblemTags,
    name: stepName.PROBLEM_START,
    modalTitle: 'Add New Problem',
    isNextStepBlocked: (data) => {
      return !data.titleProblem;
    },
    validate: (data): ValidateFormType => {
      if (!data.titleProblem) {
        return { success: false, errors: { titleProblem: 'Title required' } };
      }
      if (
        data.titleProblem &&
        !isTitleValid(data.titleProblem, defaultProblemTitle)
      ) {
        return {
          success: false,
          errors: {
            titleProblem: `Title should starts with "${defaultProblemTitle}"`
          }
        };
      }
      return { success: true };
    }
  },
  [AddProblemSteps.ProblemTags]: {
    Component: ProblemTagsStep,
    nextStep: AddProblemSteps.FinilizeProblem,
    modalTitle: 'Add New Problem',
    isNextStepBlocked: (data) => {
      if (data.tagsProblem.length === 0) return true;

      return false;
    },
    validate: (data): ValidateFormType => {
      if (data.tagsProblem) {
        return { success: true };
      }
      return { success: false, errors: { tags: 'required' } };
    }
  },
  [AddProblemSteps.FinilizeProblem]: {
    Component: FinilizeProblemStep,
    name: stepName.PROBLEM_END,
    isFinalStep: true,
    modalTitle: 'Add New Problem',
    isNextStepBlocked: (data) => {
      return false;
    },
    validate: (data): ValidateFormType => {
      return { success: true };
    }
  }
};
