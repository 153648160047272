import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  useMemo
} from 'react';
import { useDropzone } from 'react-dropzone';

import { styled, useTheme } from '@mui/material/styles';
import { Modal } from 'modals/Modal';
import { Solution, SolutionRelation } from 'components/CardSolution';
import { PsFormControl, ShortEvent } from 'components/common/PsFormControl';
import { PsInput } from 'components/common/PsInput';
import { PsButton } from 'components/common/PsButton';
import { DataContext } from 'contexts/DataContext';
import {
  Picture,
  convertFileToBase64,
  getPlaceholderImage,
  placeholderType
} from '../../helpers';
import { PsTheme } from '../../theme';
import { CheckIcon } from 'components/icons/CheckIcon';
import { BrainIcon } from 'components/icons/BrainIcon';
import { UploadIcon } from 'components/icons/UploadIcon';
import { makeStyles } from 'tss-react/mui';
import InputBase from '@mui/material/InputBase';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import { CircularProgress, Tab } from '@mui/material';
import Image from 'mui-image';

interface ISolutionTitles {
  id: string | number;
  title: string;
  teaser: string;
}

const DEFAULT_IMAGE = {
  title: 'picture',
  contentType: 'image/svg+xml',
  url: getPlaceholderImage(placeholderType.S)
};

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      textAlign: 'center'
    },
    '&.MuiSvgIcon-root': {
      fontSize: '16px'
    },
    columns: {
      // display: 'flex',
      // textAlign: 'end',
      // padding: '0 85px',

      [psTheme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    },
    left: {
      width: '100%',
      flexShrink: 0,
      [psTheme.breakpoints.down('sm')]: {
        width: '100%',
        maxWidth: 490,
        margin: '0 auto'
      }
    },
    imageHolder: {
      '& .inner': {
        position: 'relative',
        // paddingTop: '112%',
        height: '267px',
        marginBottom: '15px'
      },
      '& img': {
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'block',
        width: '100%',
        height: '100%',
        borderRadius: 10,
        objectFit: 'cover'
      }
    },
    dropzoneContainer: {
      // padding: '10px 0',
      // '& .link': {
      //   color: psTheme.palette.primary.main,
      //   cursor: 'pointer',
      // },
      // '& .link:hover': {
      //   textDecoration: 'underline',
      // },
      // '& .link svg': {
      //   marginRight: 5,
      //   marginBottom: -3,
      // },
    },
    right: {
      flexGrow: 1,
      textAlign: 'left',
      [psTheme.breakpoints.down('sm')]: {
        paddingLeft: 0
      }
    },
    buttons: {
      display: 'flex',
      justifyContent: 'center',
      padding: '40px 0 62px',
      '& > button': {
        borderRadius: '25px'
      },
      '& > button:first-child': {
        marginRight: 70,
        borderRadius: '25px',
        backgroundColor: '#D6C7F8',
        width: '183px',
        height: '50px',
        border: 'none',
        fontSize: '18px'
      },
      '& > button:nth-child(2)': {
        borderRadius: '25px',
        backgroundColor: '#632DDD',
        width: '183px',
        height: '50px',
        padding: 0,
        fontSize: '18px'
      },
      [psTheme.breakpoints.down('xs')]: {
        flexDirection: 'column-reverse',
        '& > button': {
          width: '100%'
        },
        '& > button:first-child': {
          marginRight: 0,
          marginTop: 10
        }
      }
    },
    generateButton: {
      '& > button:first-child': {
        backgroundColor: '#D6C7F8',
        color: '#632DDD',
        padding: '8px',
        fontSize: '16px',
        width: '250px'
      }
    },
    aiButton: {
      marginTop: -20,
      marginBottom: 30
    },
    checkIconStyles: {
      color: '#B1F4D4',
      fontSize: '2.5rem',
      height: '30px',
      width: '30px',
      borderRadius: '50%',
      backgroundColor: '#B1F4D4'
    },
    wrapper: {
      display: 'flex',
      alignItems: 'flex-start',
      gap: '20px',
      marginBottom: '20px'
    },
    solutionTitle: {
      margin: 0,
      fontSize: '20px',
      fontWeight: 600,
      color: '#1D1D1B'
    },
    imgTextWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginBottom: '10px',
      fontSize: '13px',
      [psTheme.breakpoints.down('sm')]: {
        width: '100%',
        maxWidth: 490,
        margin: '0 auto'
      }
    },
    link: {
      cursor: 'pointer',
      color: '#632DDD',
      fontWeight: 600,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '7px'
    },
    input: {
      position: 'relative',
      resize: 'vertical',
      height: '50px'
    },
    brainIcon: {
      position: 'absolute',
      right: -14,
      top: 0,
      transform: 'translateY(-50%)'
    },
    tabs: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      color: '#632DDD',
      borderBottom: '1px solid #C4C4C4',

      '& .MuiTabs-flexContainer': {
        justifyContent: 'space-between'
      },

      '& .MuiTab-root': {
        textTransform: 'none'
      },

      '& .Mui-selected': {
        color: '#632DDD',
        fontWeight: 600
      }
    },
    tab: {
      fontSize: '15px',
      fontWeight: 400,
      color: '#454F63'
    },
    verticalTabs: {
      height: '400px',
      overflow: 'scroll',

      '& .MuiTab-root': {
        padding: 0,
        textTransform: 'none'
      },

      '& .Mui-selected': {
        background: '#D6C7F821 0% 0% no-repeat padding-box',
        border: '1px solid #D6C7F8',
        borderRadius: '6px',
        padding: '6px 8px',
        fontWeight: 600,
        color: '#1D1D1B'
      }
    },
    verticalTab: {
      fontSize: '13px',
      fontWeight: 400,
      color: '#454F63',

      '& .MuiTab-wrapper': {
        alignItems: 'self-start',
        justifyContent: 'flex-start',
        textAlign: 'initial'
      }
    },
    relationsTitle: {
      fontSize: '15px',
      fontWeight: 600,
      color: '#1D1D1B'
    },
    titleInput: {
      '& .MuiInputBase-input:focus': {
        color: '#454F63'
      }
    }
  };
});

type ModalEditSolutionProps = {
  solution: Solution;
  open: boolean;
  onClose?: () => void;
  onUpdate?: (profile: Solution) => void;
  setNewDescFurther?: (descr: string) => void;
  setNewTitle?: (title: string) => void;
  solutionTitles: ISolutionTitles[];
};

const ModalEditSolutionView = ({
  solution,
  open,
  onClose,
  onUpdate,
  setNewDescFurther,
  setNewTitle,
  solutionTitles
}: ModalEditSolutionProps) => {
  const initTitle = solution.title || '';
  const initDescr = solution.descFurther || '';
  const initMaterials = solution.descMaterials || '';
  const initDimensions = solution.descDimensions || '';
  const initSteps = solution.descSteps || '';
  // const initRelations = solution.relations || [];
  const initRelations = useMemo(
    () => solution.relations || [],
    [solution.relations]
  );

  const initPicture =
    solution.files && solution.files.length ? solution.files[0] : DEFAULT_IMAGE;
  const { dataProvider } = useContext(DataContext);
  const [loading, setLoading] = useState(false);
  const { classes } = useStyles();

  const [picture, setPicture] = useState<Picture>(initPicture);
  const [title, setTitle] = useState<string>(initTitle);
  const [descr, setDescr] = useState<string>(initDescr);
  const [, setDescrAI] = useState<string>('');
  const [materials, setMaterials] = useState<string>(initMaterials);
  const [, setMaterialsAI] = useState<string>('');
  const [dimensions, setDimensions] = useState<string>(initDimensions);
  const [, setDimensionsAI] = useState<string>('');
  const [steps, setSteps] = useState<string>(initSteps);
  const [, setStepsAI] = useState<string>('');
  const [relations, setRelations] =
    useState<Array<SolutionRelation>>(initRelations);

  const [newRelationsOptions, setNewRelationsOptions] =
    useState<Array<SolutionRelation>>(initRelations);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(0);
  const [tabValue, setTabValue] = useState(0);
  const [isGenerateBtnShow] = useState(true);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/gif': ['.gif'],
      'image/svg+xml': ['.svg']
    }
  });

  useEffect(() => {
    // const initTitle = solution.title || '';
    // const initDescr = solution.desc || '';
    // const initMaterials = solution.materials || '';
    // const initDimensions = solution.dimensions || '';
    // const initSteps = solution.steps || '';
    // const initRelations = solution.relations || [];
    // const initPicture =
    //   solution.files && solution.files.length
    //     ? solution.files[0]
    //     : DEFAULT_IMAGE;

    const initTitle = solution.title || '';
    const initDescr = descr || solution.descr;
    // const initMaterials = solution.materials || materials;
    const initMaterials = materials || solution.materials;
    const initDimensions = dimensions || solution.dimensions;
    const initSteps = steps || solution.steps;
    const initRelations = newRelationsOptions || solution.relations;
    const initPicture =
      solution.files && solution.files.length
        ? solution.files[0]
        : DEFAULT_IMAGE;

    setPicture(initPicture);
    setTitle(initTitle);
    setDescr(initDescr);
    setMaterials(initMaterials);
    setDimensions(initDimensions);
    setSteps(initSteps);
    setRelations(initRelations);
    // setNewRelationsOptions(initRelations);
    if (initRelations.length === 0) {
      setNewRelationsOptions(initRelations);
    }
  }, [
    descr,
    dimensions,
    materials,
    newRelationsOptions,
    relations,
    solution,
    steps
  ]);

  useEffect(() => {
    if (!solution) {
      return;
    }
    let filter = {};
    if (solution.problem) {
      filter = {
        problem: solution.problem,
        $custom: { type: 'relevantSolutions', skip: solution.id }
      };
    }
    if (solution.parentSolution) {
      filter = {
        parentSolution: solution.parentSolution,
        $custom: { type: 'relevantSolutions', skip: solution.id }
      };
    }
  }, [dataProvider, solution]);

  useEffect(() => {
    if (newRelationsOptions.length === 0 || solutionTitles.length === 0) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [newRelationsOptions.length, solutionTitles.length]);

  useEffect(() => {
    if (!title) {
      return;
    }
    const problemName = solution.problemInfo ? solution.problemInfo.title : '';

    if (!initDescr || initDescr === '') {
      const descrQuery = `Describe the solution of ${title} to solve the problem of ${problemName}?`;
      dataProvider.answerSearch(descrQuery).then((res) => {
        if (res.text) {
          setDescrAI(res.text);
          if (!solution.descFurther) {
            setDescr(res.text);
          }
        }
      });
    }

    if (!initMaterials || initMaterials === '') {
      const materialsQuery = `What materials could be used to create the solution of ${title} to solve the problem of ${problemName}?`;
      dataProvider.answerSearch(materialsQuery).then((res) => {
        if (res.text) {
          setMaterialsAI(res.text);
          if (!solution.descMaterials) {
            setMaterials(res.text);
          }
        }
      });
    }

    if (!initDimensions || initDimensions === '') {
      const dimensionsQuery = `What dimensions could be used to create the solution of ${title} to solve the problem of ${problemName}?`;
      dataProvider.answerSearch(dimensionsQuery).then((res) => {
        if (res.text) {
          setDimensionsAI(res.text);
          if (!solution.descDimensions) {
            setDimensions(res.text);
          }
        }
      });
    }

    if (!initSteps || initSteps === '') {
      const stepsQuery = `Describe the steps for the solution of ${title} to solve the problem of ${problemName}?`;
      dataProvider.answerSearch(stepsQuery).then((res) => {
        if (res.text) {
          setStepsAI(res.text);
          if (!solution.descSteps) {
            setSteps(res.text);
          }
        }
      });
    }
  }, [
    dataProvider,
    initRelations.length,
    solution.descDimensions,
    solution.descFurther,
    solution.descMaterials,
    solution.descSteps,
    solution.problemInfo,
    solution.relations,
    solution.relations?.length,
    solutionTitles,
    title,
    initDescr,
    initMaterials,
    initDimensions,
    initSteps
  ]);

  // TODO: Uncomment below code if relation between solutions required
  // const generateRelations = useCallback(() => {
  //   if (
  //     solutionTitles &&
  //     solutionTitles.length !== 0 &&
  //     initRelations.length === 0
  //   ) {
  //     const promises = solutionTitles.map(async (solutionTitle) => {
  //       const textResponseAI = `In the context of the title of the concept how does ${title} relate
  //       to and interact with ${solutionTitle.title}`;
  //       const res = await dataProvider.answerSearch(textResponseAI);
  //       if (res.text) {
  //         return {
  //           id: solutionTitle.id,
  //           text: res.text
  //         };
  //       }
  //     });

  //     Promise.all(promises).then((aiResponses) => {
  //       const filteredResponses = aiResponses.filter(
  //         (response) => response !== undefined
  //       ) as SolutionRelation[];
  //       if (initRelations.length === 0) {
  //         setNewRelationsOptions(filteredResponses);
  //         setRelations(filteredResponses);
  //       }
  //     });
  //   }
  // }, [dataProvider, initRelations.length, solutionTitles, title]);

  // const handleGenerateRelations = () => {
  //   setIsGenerateBtnShow(false);
  //   generateRelations();
  // };

  useEffect(() => {
    if (!acceptedFiles.length) {
      return;
    }
    Promise.all(acceptedFiles.map(convertFileToBase64)).then(([item]) => {
      const { file, base64 } = item;
      const image = {
        contentType: file.type,
        title: file.name,
        url: base64
      };
      setPicture(image);
    });
  }, [acceptedFiles]);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (loading) {
      return;
    }

    const files: Array<Picture> = [];
    if (picture !== DEFAULT_IMAGE) {
      files.push(picture);
    }

    const solutionInfo = {
      title,
      descFurther: descr,
      descMaterials: materials,
      descDimensions: dimensions,
      descSteps: steps,
      relations: [...newRelationsOptions],
      files
    };

    dataProvider
      .update<Solution>('solutions', { id: solution.id, data: solutionInfo })
      .then(({ data }) => {
        if (onUpdate) {
          onUpdate(data);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
        onClose();
      });
    if (setNewDescFurther) {
      setNewDescFurther(descr);
    }
    if (setNewTitle) {
      setNewTitle(title);
    }
  };

  const onCancel = useCallback(() => {
    if (onClose) {
      const files: Array<Picture> = [];
      if (picture !== DEFAULT_IMAGE) {
        files.push(picture);
      }

      const solutionInfo = {
        title,
        descFurther: descr,
        descMaterials: materials,
        descDimensions: dimensions,
        descSteps: steps,
        relations: [...newRelationsOptions],
        files
      };

      dataProvider
        .update<Solution>('solutions', { id: solution.id, data: solutionInfo })
        .then(({ data }) => {
          if (onUpdate) {
            onUpdate(data);
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
      if (setNewDescFurther) {
        setNewDescFurther(descr);
      }
      if (setNewTitle) {
        setNewTitle(title);
      }
      onClose();
    }
    setPicture(initPicture);
    setTitle(initTitle);
    // setDescr(initDescr);
    setMaterials(materials);
    setDimensions(dimensions);
    setSteps(steps);
    setRelations(initRelations);

    setDescr(descr);
  }, [
    onClose,
    initPicture,
    initTitle,
    materials,
    dimensions,
    steps,
    initRelations,
    descr,
    picture,
    title,
    newRelationsOptions,
    dataProvider,
    solution.id,
    setNewDescFurther,
    setNewTitle,
    onUpdate
  ]);

  const onTitleChange = useCallback(
    (e: ShortEvent) => {
      setTitle(e.target.value);
    },
    [setTitle]
  );

  const onDescrChange = useCallback(
    (e: ShortEvent) => {
      setDescr(e.target.value);
    },
    [setDescr]
  );

  const onMaterialsChange = useCallback(
    (e: ShortEvent) => {
      setMaterials(e.target.value);
    },
    [setMaterials]
  );

  const onDimensionsChange = useCallback(
    (e: ShortEvent) => {
      setDimensions(e.target.value);
    },
    [setDimensions]
  );

  const onStepsChange = useCallback(
    (e: ShortEvent) => {
      setSteps(e.target.value);
    },
    [setSteps]
  );

  const onRelationsChange = useCallback(
    (e: ShortEvent, index: number) => {
      const newRelationValues = [...newRelationsOptions];
      newRelationValues[index].text = e.target.value;
      setNewRelationsOptions(newRelationValues);
    },
    [newRelationsOptions]
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleTabValueChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setTabValue(newValue);
  };

  return (
    <Modal
      open={open}
      onClose={onCancel}
      // title={`Edit ${title} Solution Details`}
    >
      <div className={classes.wrapper}>
        <div>
          <CheckIcon className={classes.checkIconStyles} />
        </div>

        <PsFormControl
          className={classes.titleInput}
          placeholder="Title"
          labelLight
        >
          <InputBase
            name="title"
            value={title}
            onChange={onTitleChange}
            fullWidth
            multiline={true}
            style={{
              fontSize: '20px',
              fontWeight: 600,
              color: '#1D1D1B'
            }}
          />
        </PsFormControl>
      </div>

      <div className={classes.columns}>
        <div className={classes.left}>
          <div className={classes.right}>
            <form onSubmit={onSubmit}>
              <PsFormControl
                placeholder="The solution is further described as a "
                label={
                  <Typography
                    style={{
                      fontSize: 13,
                      fontWeight: 600,
                      color: '#1D1D1B'
                    }}
                  >
                    Description
                  </Typography>
                }
                // helperText="Example: The solution is further described as a place where the dog sleeps"
                labelLight
              >
                <PsInput
                  name="descr"
                  className={classes.input}
                  fullWidth
                  multiline={true}
                  onChange={onDescrChange}
                  style={{
                    color: '#5B38D5',
                    backgroundColor: '#D6C7F821',
                    height: 'auto'
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <BrainIcon className={classes.brainIcon} />
                    </InputAdornment>
                  }
                  value={descr}
                />
              </PsFormControl>

              <div>
                <div className={classes.imgTextWrapper}>
                  <div
                    {...getRootProps({
                      className: classes.dropzoneContainer
                    })}
                  >
                    <input name="files" {...getInputProps()} />
                    <span className={classes.link}>
                      <UploadIcon />
                      Upload from your files
                    </span>
                  </div>
                </div>

                <div className={classes.imageHolder}>
                  <div className="inner">
                    <Image
                      src={picture.url}
                      alt={picture.title}
                      // layout="fill"
                    />
                  </div>
                </div>
              </div>

              <Box borderBottom="1px solid #C4C4C4" marginBottom="23px">
                <Box marginBottom="19px">
                  <Tabs
                    className={classes.tabs}
                    style={{ justifyContent: 'space-between' }}
                    value={value}
                    onChange={handleChange}
                    aria-label="tabs"
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: '#632DDD'
                      }
                    }}
                  >
                    <Tab
                      className={classes.tab}
                      label="Dimensions"
                      id="tab-0"
                      aria-controls="tabpanel-0"
                    />
                    <Tab
                      className={classes.tab}
                      label="Steps to Use"
                      id="tab-1"
                      aria-controls="tabpanel-1"
                    />
                    <Tab
                      className={classes.tab}
                      label="Materials"
                      id="tab-2"
                      aria-controls="tabpanel-2"
                    />
                  </Tabs>
                </Box>
                <Box marginBottom="19px">
                  {value === 0 && (
                    <Box>
                      <PsFormControl
                        placeholder="The solution has the dimensions of: "
                        labelLight
                      >
                        <PsInput
                          name="dimensions"
                          onChange={onDimensionsChange}
                          fullWidth
                          multiline={true}
                          style={{
                            color: '#5B38D5',
                            backgroundColor: '#D6C7F821',
                            paddingLeft: '32px',
                            height: 'auto'
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <BrainIcon className={classes.brainIcon} />
                            </InputAdornment>
                          }
                          value={dimensions}
                        />
                      </PsFormControl>
                    </Box>
                  )}
                  {value === 1 && (
                    <Box>
                      <PsFormControl
                        placeholder="The solution requires the steps of: "
                        labelLight
                      >
                        <PsInput
                          name="steps"
                          onChange={onStepsChange}
                          fullWidth
                          multiline={true}
                          style={{
                            color: '#5B38D5',
                            backgroundColor: '#D6C7F821',
                            paddingLeft: '32px',
                            height: 'auto'
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <BrainIcon className={classes.brainIcon} />
                            </InputAdornment>
                          }
                          value={steps}
                        />
                      </PsFormControl>
                    </Box>
                  )}
                  {value === 2 && (
                    <Box>
                      <PsFormControl
                        placeholder="The solution includes the following materials: "
                        labelLight
                      >
                        <PsInput
                          name="materials"
                          onChange={onMaterialsChange}
                          fullWidth
                          multiline={true}
                          style={{
                            color: '#5B38D5',
                            backgroundColor: '#D6C7F821',
                            paddingLeft: '32px',
                            height: 'auto'
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <BrainIcon className={classes.brainIcon} />
                            </InputAdornment>
                          }
                          value={materials}
                        />
                      </PsFormControl>
                    </Box>
                  )}
                </Box>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  gap: '17px',
                  justifyContent: 'center'
                }}
              >
                {isGenerateBtnShow && initRelations.length === 0 ? (
                  <div className={classes.generateButton}>
                    <PsButton
                      disabled={loading}
                      loading={loading}
                      type="button"
                      small
                      onClick={onSubmit}
                    >
                      Update
                    </PsButton>
                  </div>
                ) : (
                  <>
                    {isLoading ? (
                      <Box
                        display="flex"
                        flexDirection="column"
                        gap="15px"
                        alignItems="center"
                      >
                        <Typography>
                          AI is working on generating relations between
                          solutions
                        </Typography>
                        <CircularProgress disableShrink />
                      </Box>
                    ) : (
                      <>
                        <Box>
                          <Typography className={classes.relationsTitle}>
                            The current solution relates to others as:
                          </Typography>

                          <Tabs
                            className={classes.verticalTabs}
                            orientation="vertical"
                            variant="scrollable"
                            value={tabValue}
                            onChange={handleTabValueChange}
                            aria-label="tabs"
                            TabIndicatorProps={{
                              style: {
                                width: '100%',
                                backgroundColor: 'transparent'
                              }
                            }}
                          >
                            {solutionTitles &&
                              solutionTitles.map((item, index) => (
                                <Tab
                                  key={item.id}
                                  className={classes.verticalTab}
                                  label={item.teaser}
                                  id={`tab-${index}`}
                                  aria-controls={`tabpanel-${index}`}
                                />
                              ))}
                          </Tabs>
                        </Box>
                        <Box width="100%">
                          {newRelationsOptions &&
                            newRelationsOptions.map((relation, index) => (
                              <>
                                {tabValue === index && (
                                  <Box>
                                    <PsFormControl
                                      key={index}
                                      placeholder="Related Solutions"
                                      labelLight
                                    >
                                      <PsInput
                                        fullWidth
                                        multiline={true}
                                        name="relations"
                                        onChange={(event: ShortEvent) =>
                                          onRelationsChange(event, index)
                                        }
                                        style={{
                                          color: '#5B38D5',
                                          backgroundColor: '#D6C7F821',
                                          height: 'auto'
                                        }}
                                        endAdornment={
                                          <InputAdornment position="end">
                                            <BrainIcon
                                              className={classes.brainIcon}
                                            />
                                          </InputAdornment>
                                        }
                                        value={relation.text}
                                      />
                                    </PsFormControl>
                                  </Box>
                                )}
                              </>
                            ))}
                        </Box>
                      </>
                    )}
                  </>
                )}
              </Box>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export const ModalEditSolution = styled(ModalEditSolutionView)({});

export default ModalEditSolution;
