import React, { useContext, useEffect, useMemo } from 'react';
import {
  LinearProgress,
  SelectChangeEvent,
  Typography,
  Box
} from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useDispatch } from 'react-redux';
import { Tag } from 'components/CardTag';
import { ImageView } from 'components/ImageView';
import TableView from 'components/TableView';
import FilterBy from 'components/common/FilterBy';
import { getHeaders } from 'components/TableView/getHeaders';
import { Follow } from 'components/common/Follow';
import SimpleIconButton from 'components/SimpleIconButton';
import useRouter from 'hooks/useRouter';
import Actions from 'redux-state/actions';
import { GetTagsCount, GetTagsLoader, GetUser } from 'redux-state/selectors';
import { Constants, TAG_TYPES } from 'utilities/constants';
import {
  StyledDivider,
  StyledFollowText,
  StyledHeaderContainer,
  StyledSearchIcon,
  StyledTableWrapper,
  StyledTextField,
  TagsViewContainer,
  TagsViewHeaderContainer,
  TagsViewModalWrapper
} from './StyledComponents';

interface TagsViewProps {
  open: boolean;
  tags: Tag;
  handleClose: () => void;
  paginationOptions?: {
    page: number;
    rowsPerPage: number;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  };
  pagination: { page: number; perPage: number };
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  searchFilter: string;
  setSearchFilter: React.Dispatch<React.SetStateAction<string>>;
  filterType: string;
  setFilterType: React.Dispatch<React.SetStateAction<string>>;
}

const TagsView: React.FC<TagsViewProps> = ({
  filterType,
  handleClose,
  open,
  pagination,
  paginationOptions,
  searchFilter,
  searchValue,
  setFilterType,
  setSearchFilter,
  setSearchValue,
  tags
}) => {
  const { page, rowsPerPage, setPage, setRowsPerPage } = paginationOptions;
  const dispatch = useDispatch();
  const router = useRouter();
  const tagsCount = GetTagsCount();
  const user = GetUser();
  const { key } = router.query;
  const tagsLoader = GetTagsLoader();

  const filterItems = useMemo(
    () => [
      { label: Constants.ALL, value: Constants.ALL, icon: null },
      { label: Constants.COMPANY, value: Constants.WORKPLACE, icon: null },
      {
        label: Constants.LOCATION,
        value: Constants.LOCATION,
        icon: null
      },
      {
        label: TAG_TYPES.SCHOOL,
        value: TAG_TYPES.SCHOOL,
        icon: null
      },
      {
        label: Constants.SUBJECT_MATTER,
        value: Constants.SUBJECT_MATTER,
        icon: null
      },
      { label: Constants.C_MUTUAL, value: Constants.MUTUAL, icon: null },
      { label: Constants.FOLLOWED_TAGS, value: Constants.FOLLOWED, icon: null }
    ],
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onFollowBtnClick = (tagId: string, actionType: string) => {
    if (user) {
      dispatch(
        Actions.getTagFollowers(
          tagId,
          user.id,
          actionType,
          key,
          Constants.TAGS,
          pagination,
          filterType,
          searchFilter,
          { field: Constants.IDEA_POINTS, order: Constants.DESC }
        )
      );
    }
  };

  const getCells = useMemo(() => {
    return (icon, item, index) => {
      const actionType = item.followers?.some(
        (follower) => follower._id === user?.id
      )
        ? Constants.UNFOLLOW
        : Constants.FOLLOW;
      const baseCells = {
        count: (
          <Typography variant="body2">
            {page * rowsPerPage + index + 1}
          </Typography>
        ),
        tag: (
          <ImageView
            iconImage={icon}
            id={item.key}
            isTagView={true}
            placeholderImage={icon}
            tagImage={item.pictures ? item.pictures[0] : null}
            title={item.name}
            type={
              item.type == Constants.WORKPLACE
                ? Constants.COMMUNITIES
                : Constants.TAGS
            }
          />
        ),
        ideaPoints: <Typography>{item?.ideaPoints ?? 0}</Typography>,
        tagType: <Typography>{item.type}</Typography>,
        button: item.type !== TAG_TYPES.LOCATION &&
          item.type !== TAG_TYPES.SCHOOL && (
            <Follow
              key={item.id}
              actionType={actionType}
              id={item.id}
              onClick={() => onFollowBtnClick(item.id, actionType)}
              type={Constants.TAG}
            />
          )
      };

      return baseCells;
    };
  }, [onFollowBtnClick]);

  const handleChange = (event: SelectChangeEvent<any>) => {
    setFilterType(event.target.value);
    setPage(0);
  };

  useEffect(() => {
    setSearchFilter(searchValue);
  }, [searchValue, setSearchFilter]);

  useEffect(() => {
    if (!tags || tags.length == 0) {
      dispatch(
        Actions.getTags(
          {
            key: key,
            item: Constants.TAGS,
            pagination
          },
          filterType,
          searchFilter,
          { field: Constants.IDEA_POINTS, order: Constants.DESC }
        )
      );
    }
  }, [dispatch, filterType, searchFilter, pagination, key]);

  const filteredTags = tags?.filter((tag) =>
    tag?.name?.toLowerCase()?.includes(searchValue?.toLowerCase())
  );

  const rows = {
    component: (icon, item, type, index) => {
      return getCells(icon, item, index);
    },
    items: filteredTags,
    pinnedItems: []
  };

  useEffect(() => {
    if (tags?.length < 1) {
      setPage(0);
    }
  }, [setPage, tags]);

  const headers: Array<string> = useMemo(() => {
    return getHeaders(Constants.TAGS, user, true);
  }, [user]);
  return (
    <TagsViewModalWrapper open={open} onClose={handleClose}>
      <TagsViewContainer>
        <TagsViewHeaderContainer>
          <StyledFollowText>Follow Tags</StyledFollowText>
          <SimpleIconButton
            Icon={CancelOutlinedIcon}
            color="primary"
            onClick={handleClose}
          />
        </TagsViewHeaderContainer>

        <StyledDivider />
        <StyledHeaderContainer>
          <StyledTextField
            value={searchValue}
            placeholder="Search"
            onChange={(e) => setSearchValue(e.target.value)}
            InputProps={{
              startAdornment: (
                <SimpleIconButton Icon={StyledSearchIcon} color="primary" />
              )
            }}
          />
          <Box sx={{ width: '40%' }}>
            <FilterBy
              filterType={filterType}
              items={filterItems}
              handleChange={handleChange}
            />
          </Box>
        </StyledHeaderContainer>
        {tagsLoader && <LinearProgress />}
        <StyledTableWrapper>
          <TableView
            headers={headers}
            page={page}
            rows={rows}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            totalRows={tagsCount}
          />
        </StyledTableWrapper>
      </TagsViewContainer>
    </TagsViewModalWrapper>
  );
};
export default TagsView;
