import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { FooterSection } from './FooterSection';
import { HeaderSection } from './HeaderSection';
import { InnovationSection } from './InnovationSection';
import { PatentPendingSection } from './PatentPendingSection';
import { ReachSection } from './ReachSection';
import { RoyaltiesSection } from './RoyaltiesSection';
import { GetUser } from 'redux-state/selectors';
import { Navigate, useLocation } from 'react-router-dom';

export const LandingPage = () => {
  const loginUser = GetUser();

  const location = useLocation();

  return !loginUser ? (
    <Box>
      <HeaderSection />
      <RoyaltiesSection />
      <PatentPendingSection />
      <InnovationSection />
      <FooterSection />
      <ReachSection />
    </Box>
  ) : (
    <Navigate to="/feeds" replace state={{ from: location }} />
  );
};
