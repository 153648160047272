import React, { useContext, useState, useCallback, useEffect } from 'react';
import { PsFormControl, ShortEvent } from 'components/common/PsFormControl';
import { PsInput } from 'components/common/PsInput';
import { PsInputLabel } from 'components/common/PsInputLabel';
import { ImageField } from 'components/ImageField';
import { ModalDataContext } from 'contexts/ModalDataContext';
import styles from 'modals/ModalWindow.module.scss';

const CreateCompanyStep: React.FC = () => {
  const { values, handleInputChange, updateField } =
    useContext(ModalDataContext);
  const [title, setTitle] = useState(values.title);
  const { description } = values;

  useEffect(() => {
    updateField('parentProblemOnly', false);
    updateField('modalTitle', 'Enter Company Details');
  }, []);

  const onProblemNameChange = useCallback(
    (e: ShortEvent) => {
      handleInputChange(e);
      setTitle(e.target.value);
    },
    [setTitle, handleInputChange]
  );

  return (
    <div className={styles.stepWrapper}>
      <PsFormControl
        placeholder="Type company name here"
        label="Type Company Name:"
      >
        <PsInput name="title" value={title} onChange={onProblemNameChange} />
      </PsFormControl>

      <PsFormControl
        helperText="Example: ..."
        label={`Describe Company Name`}
        placeholder="Type Your Description here"
      >
        <PsInput
          multiline
          name="description"
          onChange={handleInputChange}
          rows={4}
          value={description}
        />
      </PsFormControl>

      <ImageField
        name="files"
        value={values.files}
        onChange={handleInputChange}
        label="Upload Company Name logo:"
      />
    </div>
  );
};

export default CreateCompanyStep;
