import React from 'react';
export const LocationIcon = ({
  size = 48,
  color = '#000',
  className
}: {
  size?: number;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color}
        d="M24 33.1q5.2-4.2 7.85-8.075Q34.5 21.15 34.5 17.8q0-2.95-1.075-5t-2.65-3.35Q29.2 8.15 27.35 7.575 25.5 7 24 7t-3.35.575q-1.85.575-3.425 1.875-1.575 1.3-2.65 3.35t-1.075 5q0 3.35 2.65 7.225Q18.8 28.9 24 33.1Zm0 3.25q-.35 0-.65-.075-.3-.075-.55-.275-6.25-4.85-9.275-9.325Q10.5 22.2 10.5 17.8q0-3.4 1.225-5.975Q12.95 9.25 14.9 7.5q1.95-1.75 4.35-2.625Q21.65 4 24 4t4.75.875q2.4.875 4.35 2.625 1.95 1.75 3.175 4.325Q37.5 14.4 37.5 17.8q0 4.4-3.025 8.875T25.2 36q-.25.2-.55.275-.3.075-.65.075ZM24 21q1.45 0 2.475-1.025Q27.5 18.95 27.5 17.5q0-1.45-1.025-2.475Q25.45 14 24 14q-1.45 0-2.475 1.025Q20.5 16.05 20.5 17.5q0 1.45 1.025 2.475Q22.55 21 24 21ZM12 44q-.65 0-1.075-.425-.425-.425-.425-1.075 0-.65.425-1.075Q11.35 41 12 41h24q.65 0 1.075.425.425.425.425 1.075 0 .65-.425 1.075Q36.65 44 36 44Zm12-26.2Z"
      />
    </svg>
  );
};

export default LocationIcon;
