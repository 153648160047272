import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

interface ContainedStarIconProps {
  height?: string;
  width?: string;
}

export const ContainedStarIcon: React.FC<ContainedStarIconProps> = ({
  height = '35px',
  width = '35px'
}) => {
  return (
    <SvgIcon
      sx={{ width: width, height: height }}
      viewBox="0 0 35 36"
      fill="none"
    >
      <path
        d="M17.5 0.5C7.83465 0.5 0 8.33465 0 18C0 27.6653 7.83465 35.5 17.5 35.5C27.1653 35.5 35 27.6653 35 18C35 8.33465 27.1653 0.5 17.5 0.5ZM25.1942 16.5745L21.9968 19.6921L22.7521 24.0928C22.7802 24.2575 22.7617 24.4267 22.6987 24.5813C22.6357 24.736 22.5307 24.87 22.3955 24.9681C22.2604 25.0663 22.1005 25.1247 21.934 25.1368C21.7674 25.1489 21.6008 25.1142 21.4529 25.0366L17.5 22.9589L13.5471 25.0366C13.3992 25.1142 13.2326 25.1489 13.066 25.1368C12.8994 25.1247 12.7396 25.0663 12.6045 24.9681C12.4693 24.87 12.3643 24.736 12.3013 24.5813C12.2383 24.4267 12.2198 24.2575 12.2479 24.0928L13.0033 19.6921L9.80576 16.5745C9.6859 16.4579 9.60108 16.3101 9.56089 16.1478C9.5207 15.9855 9.52675 15.8151 9.57836 15.6561C9.62996 15.497 9.72506 15.3556 9.85288 15.2477C9.98071 15.1399 10.1362 15.0701 10.3016 15.046L14.7202 14.4044L16.6966 10.4C16.7706 10.25 16.885 10.1238 17.027 10.0355C17.169 9.94723 17.3328 9.90045 17.5 9.90045C17.6672 9.90045 17.831 9.94723 17.973 10.0355C18.115 10.1238 18.2294 10.25 18.3034 10.4L20.2798 14.4044L24.6984 15.046C25.4324 15.1527 25.7257 16.0573 25.1942 16.5745Z"
        fill="#E0D5F8"
      />
    </SvgIcon>
  );
};
