export const CREATE_CONCEPT = 'CREATE_CONCEPT';
export const GENERATE_AI = 'GENERATE_AI';
export const GET_CHILDREN_GRAPH = 'GET_CHILDREN_GRAPH';
export const GET_CONCEPT = 'GET_CONCEPT';
export const GET_CONTESTS_BY_PROBLEM_AND_SOLUTION =
  'GET_CONTESTS_BY_PROBLEM_AND_SOLUTION';
export const GET_GRAPH = 'GET_GRAPH';
export const SET_CONCEPT_DATA = 'SET_CONCEPT_DATA';
export const SET_GRAPH_DATA = 'SET_GRAPH_DATA';
export const TOGGLE_CONCEPT_CART = 'TOGGLE_CONCEPT_CART';
export const TOGGLE_LOADER_AI_GENERATION = 'TOGGLE_LOADER_AI_GENERATION';
export const TOGGLE_LOADER_CONTESTS = 'TOGGLE_LOADER_CONTESTS';
export const TOGGLE_LOADER_IMAGE_GENERATION = 'TOGGLE_LOADER_IMAGE_GENERATION';
export const TOGGLE_LOADER_IMAGE_UPLOAD = 'TOGGLE_LOADER_IMAGE_UPLOAD';
export const TOGGLE_LOADER_NEW_CONCEPT = 'TOGGLE_LOADER_NEW_CONCEPT';
export const TOGGLE_LOADER_SOLUTION = 'TOGGLE_LOADER_SOLUTION';
export const TOGGLE_SIDE_DRAWER = 'TOGGLE_SIDE_DRAWER';
export const UPDATE_CONCEPT = 'UPDATE_CONCEPT';
export const CIRCULAR_PROGRESS_VALUE_FOR_PRODUCT =
  'CIRCULAR_PROGRESS_VALUE_FOR_PRODUCT';
export const CIRCULAR_PROGRESS_VALUE = 'CIRCULAR_PROGRESS_VALUE';
export const SET_SELECTED_ITEM = 'SET_SELECTED_ITEM';
export const UPDATE_CONCEPT_SUCCESS = 'UPDATE_CONCEPT_SUCCESS';
export const GET_CONCEPTS_SUCCESS = 'GET_CONCEPTS_SUCCESS';
