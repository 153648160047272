import React from 'react';
import { styled, useTheme, lighten } from '@mui/material/styles';

import { PsTheme } from '../../theme';
import { DiscordIcon } from 'components/icons/DiscordIcon';
import Config from 'config/config';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      marginRight: 10,
      '& > button': {
        display: 'flex',
        padding: 12,
        background: lighten(psTheme.palette.third.main, 0.9),
        border: 'none',
        borderRadius: 10,
        outline: 'none',
        cursor: 'pointer',
        '&:hover': {
          background: lighten(psTheme.palette.third.main, 0.8)
        }
      }
    }
  };
});

type DiscordBtnProps = {
  className?: string;
  invite?: string;
};

const DiscordBtnView = ({ className, invite }: DiscordBtnProps) => {
  const { classes } = useStyles();
  const handleDiscordClick = (e: React.MouseEvent) => {
    e.preventDefault();
    // If an invite code exists for the channel, redirect user there, otherwise use the invite code for the server
    window.open(
      `https://discord.gg/${
        invite || Config.NEXT_PUBLIC_DISCORD_GUILD_INVITE_CODE || ''
      }`,
      '_blank'
    );
  };

  let rootClassName = classes.root;
  if (className) {
    rootClassName += ' ' + className;
  }

  return (
    <span className={rootClassName}>
      <button onClick={handleDiscordClick}>
        <DiscordIcon />
      </button>
    </span>
  );
};

export const DiscordBtn = styled(DiscordBtnView)({});

export default DiscordBtn;
