// StyledComponents.js
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const ActionsBox = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(2)
}));

export const LoaderWrapper = styled('div')({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center'
});

export const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    backgroundColor: '#f9f9f9',
    borderRadius: '10px',
    padding: '20px'
  }
});

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  alignSelf: 'center',
  color: theme.palette.error.main
}));

export const StyledDialogContent = styled(DialogContent)({
  textAlign: 'center'
});

export const StyledDialogActions = styled(DialogActions)({
  justifyContent: 'center'
});

export const StyledButton = styled(Button)({
  marginRight: '10px'
});

export const StyledCircularProgress = styled(CircularProgress)({
  size: 30,
  thickness: 2
});
