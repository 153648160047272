import React, { useState, useCallback, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import dataProvider from 'dataPrvider';
import Actions from 'redux-state/actions';
import { Problem } from 'components/CardProblem';
import AuthContext from 'contexts/AuthContext';
import { Constants } from 'utilities/constants';
import CounterButton from 'components/common/CounterButton';
import { ShortEvent } from 'components/common/PsFormControl';
import PsInput from 'components/common/PsInput';
import { StyledCircularProgress } from 'modals/AddProblem/StyledComponents';
import { getProblemQuestion } from 'modals/ImproveApplication/texts';
import CircularProgressWithLabel from '../../CircularProgressWithLabel';
import { Node } from '../../Content';
import NodeContent from '../../NodeContent';
import {
  StyledCounterButtonWrapper,
  StyledProductTitle,
  StyledWrapperSubLabel,
  TextWrapper
} from '../../StyledComponents';
import pusher from '../../../../config/pusherConfig';

interface Step2Props {
  finalTitle?: string;
  handleNext: () => void;
  problem?: Node;
  productId: string;
  productKey: string;
  setFinalTitle?: React.Dispatch<React.SetStateAction<string>>;
  setProblem?: any;
  subType: string;
  title: string;
}

const Step2: React.FC<Step2Props> = ({
  finalTitle,
  handleNext,
  problem,
  productId,
  productKey,
  setFinalTitle,
  setProblem,
  subType,
  title
}) => {
  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);
  const [nodes, setNodes] = useState([]);
  const defaultProblemTitle = Constants.DEFAULT_PROBLEM_TITLE;
  const [progress, setProgress] = useState<number>(0);
  const [problemTitle, setProblemTitle] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const trimmedKey = productKey
    ?.substring(0, 140)
    .replace(/[^a-zA-Z0-9_=@,.;-]/g, '');

  const problemFieldLabel = (
    <StyledProductTitle>
      {getProblemQuestion(subType, title)}
    </StyledProductTitle>
  );

  const onProblemNameChange = useCallback(
    (e: ShortEvent) => {
      const { value = '' } = e.target;
      const rawVal = value.slice(defaultProblemTitle.length);
      const finalTitle = `${defaultProblemTitle}${rawVal}`;
      setFinalTitle(finalTitle);
      setProblemTitle(rawVal);
    },
    [defaultProblemTitle]
  );

  const fetchProblems = async () => {
    try {
      const params = {
        productId: productId,
        type: subType
      };
      if (user) {
        const result =
          await dataProvider.generateProblemsFromProductTypes(params);
      } else {
        dispatch(Actions.openAuthModal(true));
      }
    } catch (error) {
      console.error('Error fetching problems:', error);
    }
  };

  const handleProblemUpdate = (data) => {
    setNodes(data.allProblems);
  };

  const handleProgressUpdate = (data) => {
    setProgress(data.progress);
  };

  useEffect(() => {
    fetchProblems();
  }, [productId, subType]);

  useEffect(() => {
    const problemChannel = pusher.subscribe(
      `${Constants.IMPROVE_PROBLEM_CHANNEL}-${trimmedKey}`
    );
    const progressChannel = pusher.subscribe(
      `${Constants.PROGRESS_CHANNEL}-${trimmedKey}`
    );

    problemChannel.bind(Constants.NEW_PROBLEMS, handleProblemUpdate);
    progressChannel.bind(Constants.PROGRESS_VAL, handleProgressUpdate);

    return () => {
      dispatch(Actions.setCircularProgressValue(0));
      progressChannel.unbind(Constants.PROGRESS_VAL);
      problemChannel.unbind(Constants.NEW_PROBLEMS);
      pusher.unsubscribe(`${Constants.IMPROVE_PROBLEM_CHANNEL}-${trimmedKey}`);
      pusher.unsubscribe(`${Constants.PROGRESS_CHANNEL}-${trimmedKey}`);
    };
  }, [dispatch, trimmedKey, productId]);

  const createAndSetProblem = async (problemInfo) => {
    const response = await dataProvider.create<Problem>(Constants.PROBLEMS, {
      data: problemInfo
    });
    setProblem(response.data);
  };

  const manageNextStep = async () => {
    if (!user) return;
    setLoading(true);
    if (!problem) {
      const problemInfo = { title: problemTitle, parentProduct: productId };
      await createAndSetProblem(problemInfo);
    } else if (!problem.id) {
      await createAndSetProblem(problem);
    } else if (problem.parentProduct !== productId) {
      await dataProvider.assignProblem({
        id: productId,
        problemId: problem.id
      });
    }
    setLoading(false);
    handleNext();
  };

  return (
    <>
      <TextWrapper label={problemFieldLabel} placeholder={defaultProblemTitle}>
        <PsInput
          name={Constants.TITLE_PROBLEM}
          onChange={onProblemNameChange}
          placeHolder={defaultProblemTitle}
          value={finalTitle}
        />
      </TextWrapper>

      <StyledCounterButtonWrapper>
        <Box sx={{ position: 'relative' }}>
          <CounterButton
            handleClick={manageNextStep}
            isCreateButton={true}
            text={Constants.NEXT}
            disabled={
              loading ||
              !(problem || problemTitle.trim() !== '') ||
              (!(nodes && nodes.length > 0) &&
                finalTitle
                  .slice(Constants.DEFAULT_PROBLEM_TITLE.length)
                  .trim() === '')
            }
          />
          {loading && <StyledCircularProgress size={30} thickness={2} />}
        </Box>
      </StyledCounterButtonWrapper>

      {nodes && nodes.length === 0 ? (
        <CircularProgressWithLabel
          type={Constants.PROBLEMS}
          progress={progress}
        />
      ) : (
        <StyledWrapperSubLabel>
          {Constants.SELECT_AI_GENERATED} <b>{subType}</b>{' '}
          {Constants.PROBLEM_ASSOCIATED} <span>{title}</span>:
        </StyledWrapperSubLabel>
      )}

      {nodes &&
        nodes.map((node) => (
          <NodeContent
            key={node.id}
            node={node}
            problem={problem}
            setSelectedNode={setProblem}
            type={Constants.PROBLEM}
          />
        ))}
    </>
  );
};

export default Step2;
