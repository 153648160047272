import { useSelector } from 'react-redux';
import { Profile } from 'components/CardProfile';
import { UserProfileState } from './interface';

export const GetResetPasswordLoader = () =>
  useSelector(
    (state: UserProfileState) => state.onboarding.resetPasswordLoading
  );
export const GetResetPasswordStatus = () =>
  useSelector(
    (state: UserProfileState) => state.onboarding.resetPasswordStatus
  );

export const GetUser = () =>
  useSelector((state: Profile) => state.onboarding.loginUser);

export const GetPublicUser = () =>
  useSelector((state: Profile) => state.onboarding.publicUser);

export const GetSocialAuthKeyLoader = () =>
  useSelector(
    (state: UserProfileState) => state.onboarding.getSocialAuthKeysLoading
  );

export const GetSocialAuthKey = () =>
  useSelector((state: UserProfileState) => state.onboarding.getSocialAuthKeys);

export const GetUserLoading = () =>
  useSelector((state: UserProfileState) => state.onboarding.getUserLoading);
