import { Constants } from 'utilities/constants';
import { Profile } from '../CardProfile';

const headers = {
  Concept: ['', 'Concepts', ''],
  Contest: ['', 'Contests', ''],
  Invention: ['', 'Inventions', ''],
  Problem: ['', 'Problems', ''],
  Product: ['Item', 'Company'],
  Solution: ['', 'Solutions', ''],
  CommunityProblem: ['My Problems', 'Product', '', 'IdeaPoints'],
  CommunitySolution: ['Item', 'Problem', 'IdeaPoints'],
  CommunityInvention: ['Name', 'Problems/Solutions', 'IdeaPoints'],
  CommunityProduct: ['Item', 'Company', 'IdeaPoints'],
  tags: ['', 'Name', 'IdeaPoints', 'Tag Type', ''],
  LeaderBoard: [
    '',
    'Members',
    'IdeaPoints',
    'IdeaCoins',
    'Type',
    'Total # of solved problems'
  ],
  Agreement: ['Name', 'fileUrl', 'Created At'],
  [Constants.ACCOUNT_TRANSACTIONS]: ['Hash', 'From', 'To', 'Value', 'Timestamp']
};
export const getHeaders = (
  key: string,
  user?: Profile,
  isCurrentUser?: boolean
) => {
  const headersArray = headers[key];
  if (isCurrentUser) {
    switch (key) {
      case Constants.COMMUNITY_PROBLEM:
        return [...headersArray, Constants.ACTIONS];
      case Constants.TAGS:
      case Constants.ACCOUNT_TRANSACTIONS:
      case Constants.LEADER_BOARD:
        return [...headersArray];
      default:
        return [...headersArray, ''];
    }
  }
  return headersArray;
};
