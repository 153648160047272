import React from 'react';
import { Box, Typography, styled } from '@mui/material';
import Config from 'config/config';
import useRouter from 'hooks/useRouter';
import { Constants } from 'utilities/constants';
import { colorPalette, useIsMediumScreen } from '../../theme';

const clientUrl = Config.NEXT_PUBLIC_CLIENT_URL;

interface Props {
  boxWidth?: number;
  currentTab?: string;
  fontWeight?: string;
  fontSize?: string;
  height?: string;
  id?: string;
  isCommunityFeed?: boolean;
  textWidth?: number;
  title: string;
  textDecoration?: string;
  type?: string;
  useSameTab?: boolean;
  reRoute?: boolean;
}

export const LinkView: React.FC<Props> = ({
  boxWidth,
  currentTab,
  fontSize = '.8125rem',
  fontWeight = 'bold',
  height = 50,
  id,
  isCommunityFeed,
  textWidth = 300,
  title,
  textDecoration,
  type,
  useSameTab = false,
  reRoute = true
}) => {
  const router = useRouter();
  const isMediumScreen = useIsMediumScreen();

  const handleClick = () => {
    const basePath = `${type}/${id}`;
    const query = currentTab ? `?currentTab=${currentTab}` : '';
    const url = `${clientUrl}${basePath}${query}`;

    if (!reRoute) return;
    if (useSameTab) {
      router.push(`/${basePath}${query}`);
    } else {
      window.open(url, Constants._BLANK);
    }
  };

  return (
    <StyledBox width={boxWidth ? boxWidth : 'none'} height={height}>
      <StyledLink
        isCommunityFeed={isCommunityFeed}
        isMediumScreen={isMediumScreen}
        lineHeight={1.5}
        fontWeight={fontWeight}
        fontSize={fontSize}
        textDecoration={textDecoration}
        noWrap
        onClick={handleClick}
        variant="body2"
        maxWidth={isMediumScreen ? 250 : textWidth}
      >
        {title}
      </StyledLink>
    </StyledBox>
  );
};

const StyledLink = styled(Typography)<{
  isMediumScreen: boolean;
  isCommunityFeed: boolean;
  textDecoration?: string;
}>(({ isMediumScreen, isCommunityFeed, textDecoration }) => ({
  alignSelf: 'center',
  color: colorPalette.purple,
  cursor: 'pointer',
  overflow: 'hidden',
  textDecoration: textDecoration ?? 'none',
  textOverflow: 'ellipsis',
  width: isMediumScreen && 'auto',
  '@media (max-width: 900px)': {
    fontSize: '12px',
    paddingRight: isCommunityFeed ? '10px' : '20px'
  }
}));

const StyledBox = styled(Box)({
  display: 'flex'
});
