import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import useRouter from 'hooks/useRouter';
import TagsView from 'pages/home/TagsView';
import Actions from 'redux-state/actions';
import {
  GetFollowingTagsCount,
  GetTags,
  GetUser,
  GetUserIdeaPoints,
  GetUserRanking
} from 'redux-state/selectors';
import { Constants } from 'utilities/constants';
import {
  CommunityNum,
  Highlight,
  ImageBox,
  MainContainerBox,
  StyledIdeapointsInfo,
  StyledInfoTypography,
  StyledUserAvatar,
  StyledUserName
} from './StyledComponents';
import { NotificationsPopup } from '../NotificationsPopup';

// eslint-disable-next-line max-lines-per-function, complexity
export const ProfileFeedView = ({ trigger }: { trigger?: boolean }) => {
  const dispatch = useDispatch();
  const followedCommunities = GetFollowingTagsCount();
  const tags = GetTags();
  const user = GetUser();
  const ideaPoints = GetUserIdeaPoints();
  const userRank = GetUserRanking();
  const [filterType, setFilterType] = useState<string>(Constants.ALL);
  const [open, setOpen] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchFilter, setSearchFilter] = useState<string>(searchValue);
  const maxCommunityNumber = 500;
  const router = useRouter();
  const onIdeaPointsClick = () => setOpenNotificationsPopup(true);
  const onMyProfileClick = () => router.push(`/profiles/${user?.key}`);
  const image =
    user?.files && user?.files?.length ? user?.files[1]?.url : undefined;
  const [openNotificationsPopup, setOpenNotificationsPopup] = useState(false);

  const paginationOptions = useMemo(
    () => ({
      page: page,
      rowsPerPage: rowsPerPage,
      setPage: setPage,
      setRowsPerPage: setRowsPerPage
    }),
    [page, rowsPerPage, setRowsPerPage, setPage]
  );

  const pagination = useMemo(
    () => ({
      page: page,
      perPage: rowsPerPage
    }),
    [page, rowsPerPage]
  );

  const handleOpen = () => {
    setFilterType(Constants.FOLLOWED);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setRowsPerPage(5);
    setPage(0);
    setSearchValue('');
    setFilterType(Constants.ALL);
  };

  useEffect(() => {
    if (user && user?.key) {
      dispatch(Actions.getUserRanking(user?.key as string));
      dispatch(Actions.getFollowingTagsCount(user?.key as string, false));
    }
  }, [dispatch, user, trigger]);

  useEffect(() => {
    if (user?.id) {
      dispatch(Actions.getUserIdeaPoints(user?.id));
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (user?.id) {
      dispatch(
        Actions.getTags(
          { item: Constants.TAGS, pagination, userId: user.id },
          filterType,
          searchFilter,
          { field: Constants.IDEA_POINTS, order: Constants.DESC }
        )
      );
    }
  }, [dispatch, pagination, filterType, searchFilter, user?.id]);

  return (
    <MainContainerBox>
      <ImageBox
        src={user?.files[1]?.url ? image : Constants.DEFAULT_COVER_IMAGE}
      />
      <StyledUserAvatar src={user?.files?.[0]?.url} />
      <StyledUserName onClick={user?.username ? onMyProfileClick : undefined}>
        {user && user?.username ? `${user?.username}` : 'MindMiner User'}
      </StyledUserName>
      <CommunityNum onClick={handleOpen}>
        {Constants.FOLLOWING_TEXT}{' '}
        {(typeof followedCommunities === 'number'
          ? followedCommunities
          : followedCommunities.totalCount) < maxCommunityNumber
          ? typeof followedCommunities === 'number'
            ? followedCommunities
            : followedCommunities.totalCount
          : '500+'}{' '}
        {Constants.C_COMMUNITIES}
      </CommunityNum>
      <StyledInfoTypography>
        {Constants.MINDMINER_OVERALL_RANKING} <Highlight>{userRank}</Highlight>
      </StyledInfoTypography>
      <StyledIdeapointsInfo onClick={onIdeaPointsClick}>
        {Constants.IDEA_POINTS_TEXT} <Highlight>{ideaPoints}</Highlight>
      </StyledIdeapointsInfo>

      {open && (
        <TagsView
          open={open}
          handleClose={handleClose}
          tags={tags}
          paginationOptions={paginationOptions}
          pagination={pagination}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          searchFilter={searchFilter}
          setSearchFilter={setSearchFilter}
          filterType={filterType}
          setFilterType={setFilterType}
        />
      )}
      {openNotificationsPopup && (
        <NotificationsPopup
          open={openNotificationsPopup}
          setOpen={setOpenNotificationsPopup}
        />
      )}
    </MainContainerBox>
  );
};
