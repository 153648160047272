import React, { useContext, useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { Profile } from 'components/CardProfile';
import { Contributors } from 'components/CardTag';
import { DataContext } from 'contexts/DataContext';
import { Picture, getPlaceholderImage, placeholderType } from '../../helpers';
import { PsTheme } from '../../theme';
import youtube from 'assets/icons/youtube.png';
import linkedin from 'assets/icons/linkedin.png';
import instagram from 'assets/icons/instagram.png';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

const DEFAULT_IMAGE = {
  title: 'picture',
  contentType: 'image/svg+xml',
  url: getPlaceholderImage(placeholderType.U)
};

const UserIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.7">
        <path
          d="M15 15.75V14.25C15 13.4544 14.6839 12.6913 14.1213 12.1287C13.5587 11.5661 12.7956 11.25 12 11.25H6C5.20435 11.25 4.44129 11.5661 3.87868 12.1287C3.31607 12.6913 3 13.4544 3 14.25V15.75"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 8.25C10.6569 8.25 12 6.90685 12 5.25C12 3.59315 10.6569 2.25 9 2.25C7.34315 2.25 6 3.59315 6 5.25C6 6.90685 7.34315 8.25 9 8.25Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

type Contributor = {
  id: string | number;
  key: string;
  username?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  files?: Array<Picture>;
};

type ClassKey =
  | 'root'
  | 'loading'
  | 'moderator'
  | 'moderatorPhoto'
  | 'moderatorLabel'
  | 'moderatorName'
  | 'moderatorSocial'
  | 'users'
  | 'user'
  | 'userPhoto';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      display: 'flex',
      padding: '10px 0',
      [psTheme.breakpoints.down('xs')]: {
        flexDirection: 'column'
      }
    },
    loading: {
      lineHeight: '100px',
      textAlign: 'center'
    },
    moderator: {
      width: 230,
      marginRight: 16,
      flexShrink: 0,
      [psTheme.breakpoints.down('xs')]: {
        marginBottom: 10
      }
    },
    moderatorPhoto: {
      display: 'block',
      width: '100%',
      height: 230,
      marginBottom: 10,
      borderRadius: 15,
      objectFit: 'cover'
    },
    moderatorLabel: {
      fontSize: 13,
      marginBottom: 13
    },
    moderatorName: {
      float: 'left',
      marginBottom: 10,
      color: psTheme.palette.primary.main,
      '& svg': {
        margin: '0px 8px -4px 0px',
        color: psTheme.palette.primary.main
      }
    },
    moderatorSocial: {
      float: 'right',
      '& a': {
        marginLeft: 10
      }
    },
    users: {
      fontSize: 0,
      lineHeight: 0
    },
    user: {
      display: 'inline-block',
      marginRight: 15,
      marginBottom: 15
    },
    userPhoto: {
      display: 'block',
      width: 65,
      height: 65,
      borderRadius: 10,
      objectFit: 'cover'
    }
  };
});

export type TagUsersProps = {
  id: string | number;
  moderator?: string | number;
};

const TagUsersView = ({ id, moderator }: TagUsersProps) => {
  const { dataProvider } = useContext(DataContext);
  const [user, setUser] = useState<Profile>();
  const [contributors, setContributors] = useState<Array<Contributor>>([]);
  const [loading, setLoading] = useState(true);
  const { classes } = useStyles();

  useEffect(() => {
    if (moderator) {
      dataProvider
        .getOne<Profile>('profiles', { id: moderator })
        .then(({ data }) => {
          setUser(data);
        })
        .catch((err) => {
          console.error(err);
        });
    }

    setLoading(true);
    dataProvider
      .getTagContributors<Contributor>({ id })
      .then(({ data }) => {
        setContributors(data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  let moderatorImage = DEFAULT_IMAGE;
  let moderatorName;

  if (user) {
    if (user.files && user.files.length) {
      moderatorImage = user.files[0];
    }
    moderatorName = user.username || user.email;
  }

  return (
    <>
      {loading ? (
        <Typography className={classes.loading} variant="body2">
          Loading...
        </Typography>
      ) : null}
      <div className={classes.root}>
        {!loading && user ? (
          <div className={classes.moderator}>
            <Link to={`/profiles/${user.key || user.id}`}>
              <img
                className={classes.moderatorPhoto}
                src={moderatorImage.url}
                alt={moderatorName}
              />
            </Link>
            <div className={classes.moderatorLabel}>Moderator</div>
            <div>
              <div className={classes.moderatorName}>
                <UserIcon />
                <span>{moderatorName}</span>
              </div>
              <div className={classes.moderatorSocial}>
                {user.youtubeUrl ? (
                  <a href={user.youtubeUrl} target="_blank" rel="noreferrer">
                    <img src={youtube} alt="youtube" />
                  </a>
                ) : null}
                {user.instagramUrl ? (
                  <a href={user.instagramUrl} target="_blank" rel="noreferrer">
                    <img src={instagram} alt="instagram" />
                  </a>
                ) : null}
                {user.linkedinUrl ? (
                  <a href={user.linkedinUrl} target="_blank" rel="noreferrer">
                    <img src={linkedin} alt="linkedin" />
                  </a>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
        {!loading && contributors && contributors.length ? (
          <Contributors users={contributors} />
        ) : null}
      </div>
    </>
  );
};

export const TagUsers = styled(TagUsersView)({});

export default TagUsers;
