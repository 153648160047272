/* eslint-disable */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { IoClose } from 'react-icons/io5';
import { useDispatch } from 'react-redux';
import { CloudUpload } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  Typography
} from '@mui/material';
import { loadStripe } from '@stripe/stripe-js';
import MintPatentLogo from 'assets/icons/MintPatent.svg';
import OpenSeaLogo from 'assets/icons/OpenSea-Full-Logo.svg';
import PurpleHexagonIcon from 'assets/icons/PurpleHexagon.svg';
import NewsFeedShareIcon from 'assets/icons/share_feed.svg';
import { hasNoSolutions } from 'components/ConceptsButton/helpers';
import DataContext from 'contexts/DataContext';
import dataProvider from 'dataPrvider';
import { AnimatePresence, motion } from 'framer-motion';
import { useRemoveQueryParams } from 'helpers';
import { deployNft } from 'helpers/blockchain';
import { getBasePath } from 'helpers/common';
import getQueryParams from 'helpers/getQueryParams';
import { DownloadPatentAppModal } from 'modals/DownloadPatentApplication';
import { FinalizeConceptModal } from 'modals/FinalizeConceptModal';
import { LockConceptModal } from 'modals/LockConceptModal';
import { MakePatentPendingModal } from 'modals/MakePatentPending';
import { TxApprovalModal } from 'modals/TxApprovalModal';
import { UploadFilingReceiptModal } from 'modals/UploadFillingReceiptModal';
import {
  LoadingContainer,
  StyledIcons,
  VotingBox
} from 'pages/home/StyledComponents';
import Actions from 'redux-state/actions';
import { Api } from 'redux-state/profile/api';
import {
  GetDownloadPatentLoader,
  GetErrorMessage,
  GetNftDeployStartLoader,
  GetProfileConcepts,
  GetProfileInventions,
  GetToggleConceptCart,
  GetTokenURI,
  GetTxApprovalModalObj,
  GetUser
} from 'redux-state/selectors';
import { colorPalette, useIsMediumScreen } from 'theme';
import {
  ASSET_TYPES,
  CHANNELS,
  Constants,
  ERRORS,
  QUOTATION_STATUS,
  STAKING_STATUS,
  VARIANT
} from 'utilities/constants';
import pusher from '../../config/pusherConfig';
import { ActiveConceptCard } from '../ActiveConceptCard';
import { TagInfo } from '../CardTag';
import { Application } from '../CardApplication';
import { ChatBotDrawer } from '../ChatBotDrawer';
import { ChatHelpButton } from '../common/buttons/ChatBotHelpButton';
import { CustomButton } from '../common/buttons/InventionLargeButton';
import { useChecklist } from '../common/CheckListData';
import { ConceptCheckList } from '../common/ConceptCheckList';
import Vote from '../common/Vote';
import { IconContainer } from '../EntityDrawerNew/EntityDrawerNew';
import GalleryView, { ImageCarouselProps } from '../GalleryView';
import { LogoMindminer } from '../icons/LogoMindminer';
import RequestQuotationModal from '../RequestQuotationModal';
import StakerModal from '../StakerModal';
import StakingSetupModal from '../StakingSetupModal';
import { ChipList } from '../Tag';
import { EntityIcon } from '../TreeGraph/EntityIcon';
import {
  ProblemContainer,
  ProblemIcon,
  ProbSolContainer,
  PurpleText,
  SolutionBar,
  SolutionContainer,
  SolutionIcon,
  StyledAccordion,
  StyledBadge,
  StyledBoxTypography,
  StyledButton,
  StyledClose,
  StyledDetailBox,
  StyledDrawer,
  StyledGrid,
  StyledIdeaPointsCount,
  StyledIdeaPointsText,
  StyledImageBox,
  StyledImagesPlusMoreBox,
  StyledLinearProgress,
  StyledMintButton,
  StyledNumber,
  StyledParentContainer,
  StyledPatentText,
  StyledPoweredByBox,
  StyledProgressAccordion,
  StyledShareImage,
  StyledStakedText,
  StyledStakingInternalBox,
  StyledStakingMainBox,
  StyledStakingNumericTextBox,
  StyledStakingSimpleTextBox,
  StyledTagsDetailsText,
  StyledTextBox,
  StyledTitle,
  StyledTitleForGallery,
  StyledVideoMainBox,
  TeaserTypo
} from './styles';
import { toastify } from 'pages/newContests/toastify';

export interface ProgressStatus {
  descriptionAndTitleGeneratedFromAI: boolean;
  enoughNumberOfSolutionRelationship: boolean;
  enoughNumberOfSolutions: boolean;
  nameAndAdressOfInventorSaved: boolean;
  solutionRelationshipsDuplicate: boolean;
  solutionsDuplicate: boolean;
}
interface StakerPaymentResponse {
  sessionId: string;
  pubKey: string;
}

const ConceptDetailView = ({
  profile,
  archiveStatus,
  pagination,
  fetchProfileConcepts,
  isConcept,
  selectedConceptId,
  publicViewInvetion = null,
  showShareOption = true,
  showMoreVertIcon = true
}) => {
  const isMediumScreen = useIsMediumScreen();
  const dispatch = useDispatch();
  const isConceptDrawerOpen = GetToggleConceptCart();
  const user = GetUser();
  const removeQueryParams = useRemoveQueryParams();
  const patentFileFlow = getQueryParams(Constants.PATENT_FILE_FLOW);
  const { setAlertContent } = useContext(DataContext);
  const downloadLoader: boolean = GetDownloadPatentLoader();
  const deployNFTLoader = GetNftDeployStartLoader();
  const txApprovalModalObj = GetTxApprovalModalObj();
  const tokenURI = GetTokenURI();
  const { showToast } = useContext(DataContext);
  const stripeKey = (user && user.userData?.STRIPE_PUB_KEY) || '';
  const errorMsg = GetErrorMessage();

  const concepts: Array<Application> = GetProfileConcepts();
  const inventions = GetProfileInventions();
  const selectedConcept = selectedConceptId
    ? isConcept
      ? concepts?.find((concept) => concept.id === selectedConceptId)
      : inventions.find((invention) => invention.id === selectedConceptId)
    : publicViewInvetion;

  const [checkListData, setCheckListData] = useState<ProgressStatus>(null);
  const [openChat, setOpenChat] = useState<boolean>(false);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [openDownloadPatentAppModal, setOpenDownloadPatentAppModal] =
    useState<boolean>(false);
  const [openPatentPendingModal, setOpenPatentPendingModal] =
    useState<boolean>(false);
  const [openLockConceptModal, setOpenLockConceptModal] =
    useState<boolean>(false);
  const [openFilingReceiptModal, setOpenFilingReceiptModal] =
    useState<boolean>(false);
  const [isFinalizeModalOpen, setIsFinalizeModalOpen] =
    useState<boolean>(false);
  const [isRequestQoutationModalOpen, setIsRequestQuotationModalOpen] =
    useState<boolean>(false);
  const [inventionStaker, setInventionStaker] = useState<any>();
  const [isStakingSetupModalOpen, setIsSatkingSetupModalOpen] =
    useState<boolean>(false);
  const [isStakerModalOpen, setIsStakerModalOpen] = useState<boolean>(false);
  const [deployingId, setDeployingId] = useState<string | null>(null);
  const [openGallery, setOpenGallery] = useState<boolean>(false);
  const [images, setImages] = useState<Array<ImageCarouselProps>>([]);
  const [numberOfUnitStaked, setNumberOfUnitStaked] = useState<Number>();
  const showTxApprovalModal =
    txApprovalModalObj?.open && txApprovalModalObj?.type == ASSET_TYPES.NFT;

  useEffect(() => {
    if (errorMsg) {
      toastify(errorMsg, VARIANT.ERROR, VARIANT.BOTTOM_LEFT, 2000);
      dispatch(Actions.toggleError('', Constants.APPLICATIONS));
    }
  }, [dispatch, errorMsg]);

  useEffect(() => {
    const channel = pusher.subscribe(CHANNELS.VIDEO_GENERATION_CHANNEL);
    channel.bind(Constants.VIDEOS, function (data) {
      fetchProfileConcepts();
    });
    return () => {
      channel.unbind(Constants.VIDEOS);
      pusher.unsubscribe(CHANNELS.VIDEO_GENERATION_CHANNEL);
    };
  }, [dispatch, profile, pagination, archiveStatus, inventionStaker]);

  const isDisabled = useCallback(
    (item) => {
      return (
        selectedConcept?.envelopeId ||
        selectedConcept?.isFiled ||
        downloadLoader
      );
    },
    [downloadLoader]
  );

  const handleSetOpenChat = useCallback(() => {
    setOpenChat(true);
    dispatch(Actions.toggleConceptCart(true));
  }, []);

  const getInventionStaker = async () => {
    try {
      if (selectedConcept) {
        const data = await Api.getInventionStakerByApplicationId(
          selectedConcept.id
        );
        setInventionStaker(data);
      }
    } catch (error) {
      console.error(ERRORS.ERROR_FETCHING_PROGRESS_DATA, error);
    }
  };

  useEffect(() => {
    const fetchProgress = async () => {
      try {
        if (selectedConcept) {
          const response = await dataProvider.checkImprovedProgress(
            selectedConcept?.id
          );
          setCheckListData(response ?? null);
        }
      } catch (error) {
        console.error(ERRORS.ERROR_FETCHING_PROGRESS_DATA, error);
      }
    };

    fetchProgress();
    getInventionStaker();
  }, [selectedConcept?.id]);

  useEffect(() => {
    if (inventionStaker) {
      const totalUnits = inventionStaker.stakers.reduce((total, staker) => {
        return total + staker.numberOfStake;
      }, 0);
      setNumberOfUnitStaked(totalUnits);
    }
  }, [inventionStaker]);

  const setOpenDownloadAndPatentPendingModal = (
    isOpenDownloadModal,
    isOpenPatentPendingModal
  ) => {
    setOpenDownloadPatentAppModal(isOpenDownloadModal);
    setOpenPatentPendingModal(isOpenPatentPendingModal);
  };

  const onPatentDownloadClick = () => {
    if (!selectedConcept) {
      return;
    }
    if (hasNoSolutions(selectedConcept)) {
      setAlertContent(
        <>
          You need to add at least one solution to the invention before minting
          NFT
        </>
      );
      return;
    }
    setOpenDownloadPatentAppModal(true);
    setOpenChat(false);
    dispatch(Actions.toggleConceptCart(false));
  };

  const beforeLockConceptModalClose = useCallback(() => {
    removeQueryParams([Constants.PATENT_FILE_FLOW]);
  }, []);

  const openModalAfterLockConcept = useCallback(() => {
    if (patentFileFlow && patentFileFlow == Constants.VIA_SELF) {
      setOpenDownloadPatentAppModal(true);
    } else {
      setOpenPatentPendingModal(true);
    }
  }, [patentFileFlow]);

  const makePatentApplication = useCallback(() => {
    if (hasNoSolutions(selectedConcept)) {
      setAlertContent(<>{Constants.YOU_NEED_TO_ADD_ATLEAST_ONE_SOLUTION}</>);
      return;
    }

    if (selectedConcept?.isLocked) {
      if (selectedConcept?.isDownloaded) {
        setOpenDownloadAndPatentPendingModal(true, false);
      } else {
        setOpenPatentPendingModal(true);
      }
    } else {
      setOpenLockConceptModal(true);
    }
    fetchProfileConcepts();
  }, [setAlertContent, selectedConcept]);

  const onTaskCompletion = useCallback(() => {
    dispatch(
      Actions.getProfileConcepts(
        profile?.id ?? profile?._id,
        pagination,
        null,
        archiveStatus
      )
    );
  }, [dispatch, profile, pagination, archiveStatus]);

  const handleImprove = () => {};

  const handleDrawer = useCallback(() => {
    dispatch(Actions.toggleConceptCart(!isConceptDrawerOpen));
  }, [dispatch, isConceptDrawerOpen]);

  const handleVideoGenerateFromAI = async () => {
    try {
      await dataProvider.processVideoExample(
        Constants.TEXT_TO_VIDEO,
        selectedConcept.id
      );
      dispatch(
        Actions.getProfileConcepts(
          profile?.id ?? profile?._id,
          pagination,
          null,
          archiveStatus
        )
      );
    } catch (error) {
      console.error(ERRORS.VIDEO_GENERATION_FAILED, error);
    }
  };

  const openTxApprovalModal = useCallback(
    (gasFeeEstimate, type) => {
      dispatch(
        Actions.openTxApprovalModal({
          txApprovalModalObj: {
            gasFee: gasFeeEstimate,
            open: true,
            type
          }
        })
      );
    },
    [dispatch]
  );

  const deployNFT = useCallback(
    (application: { id: string }) => {
      if (user) {
        setDeployingId(application.id);
        dispatch(
          Actions.nftDeployStart({
            id: application.id,
            onDeployStartSuccess: openTxApprovalModal,
            privateKey: user.privateKey
          })
        );
      }
    },
    [dispatch, openTxApprovalModal, user]
  );

  const onTxConfirm = useCallback(async () => {
    try {
      if (user) {
        if (!selectedConcept) return;
        dispatch(Actions.toggleDeployNftLoader({ deployNFTLoader: true }));
        const [transactionHash, tokenId] = await deployNft(
          user.privateKey,
          tokenURI
        );
        dispatch(
          Actions.nftDeployFinish({
            id: selectedConcept.id,
            isArchived: archiveStatus,
            pagination,
            privateKey: user.privateKey,
            tokenId,
            transactionHash,
            userId: user.id,
            walletAddress: user.walletAddress
          })
        );
      }
    } catch (error) {
      dispatch(Actions.toggleDeployNftLoader({ deployNFTLoader: false }));
      showToast(ERRORS.DEPLOY_NFT, { variant: VARIANT.ERROR });
    }
  }, [
    archiveStatus,
    deployingId,
    dispatch,
    pagination,
    showToast,
    tokenURI,
    user
  ]);

  const isDeployingNFT = useMemo(() => {
    return (item) => {
      return deployNFTLoader && deployingId === item.id;
    };
  }, [deployNFTLoader, deployingId]);

  const UploadFilingReceipt = useCallback((item) => {
    setOpenFilingReceiptModal(true);
  }, []);

  const handleNFTButton = (selectedConcept) => {
    if (selectedConcept.nftTransactionUrl !== undefined) {
      window.open(selectedConcept.nftTransactionUrl, '_blank');
    } else {
      deployNFT(selectedConcept);
    }
  };

  const handleSatkingSetupOpenModal = () => {
    setIsSatkingSetupModalOpen(true);
  };

  const handleSatkingSetupCloseModal = () => {
    setIsSatkingSetupModalOpen(false);
    getInventionStaker();
  };

  const handleSatkerOpenModal = () => {
    if (selectedConcept.stakingInProgress) {
      setIsStakerModalOpen(true);
    } else {
      handleRequestQuotationOpenModal();
    }
  };

  const handleSatkerCloseModal = () => {
    setIsStakerModalOpen(false);
  };

  const handleOpenGallery = () => {
    const galleryImages = selectedConcept.solutions
      .map((solution) => ({
        url: solution.files[0]?.url,
        title: solution.title,
        key: solution.key
      }))
      .filter((image) => image.url);

    setImages(galleryImages);
    setOpenGallery(true);
  };

  const handleCloseGallery = () => {
    setOpenGallery(false);
  };

  const handleRequestQuotationOpenModal = () =>
    setIsRequestQuotationModalOpen(true);
  const handleRequestQuotationCloseModal = () =>
    setIsRequestQuotationModalOpen(false);

  const handlePayment = async (payload) => {
    const baseUrl = window.location.origin;
    const redirectURL = `${baseUrl}/${Constants.PROFILES}/${profile?.key}?${Constants.CURRENT_TAB}=${Constants.INVENTIONS}`;

    const items = [
      { name: Constants.INVENTION_STAKING, price: payload.amount, quantity: 1 }
    ];
    try {
      const response = await dataProvider.stakerPayment<StakerPaymentResponse>(
        selectedConcept._id,
        payload.amount,
        payload.numberOfStake,
        redirectURL,
        items
      );
      if (response.sessionId) {
        return loadStripe(stripeKey).then(async (stripe) => {
          if (stripe) {
            await stripe.redirectToCheckout({
              sessionId: response.sessionId
            });
          }
        });
      }
    } catch (err) {
      console.error(Constants.PAYMENT_FAILED, err);
    }
  };

  const handleStakeInventionFormSubmit = async (payload) => {
    payload.applicationId = selectedConcept.id;
    await handlePayment(payload);
    handleSatkerCloseModal();
    getInventionStaker();
  };

  const handleCrowdFundButton = async () => {
    const isStakingInProgress = selectedConcept.stakingInProgress;
    const isQuotationReceived =
      selectedConcept.quotationStatus === QUOTATION_STATUS.RECEIVED;
    const isStakerFulfilled =
      inventionStaker?.status === STAKING_STATUS.FULFILLED;

    if (!isStakingInProgress && !isQuotationReceived) {
      handleRequestQuotationOpenModal();
    } else if (
      !isStakingInProgress &&
      isQuotationReceived &&
      !isStakerFulfilled
    ) {
      handleSatkingSetupOpenModal();
    } else if (
      isStakingInProgress ||
      (isQuotationReceived && isStakerFulfilled)
    ) {
      handleRequestQuotationOpenModal();
    }
  };

  const stakingProgressBar = Math.round(
    (inventionStaker?.amountStaked / inventionStaker?.stakingThreshold) * 100
  );

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {};

  const { fulfilled, warnings } = useChecklist(checkListData);

  const totalChecks = fulfilled.length + warnings.length;
  const passedChecks = fulfilled.length;
  const progressPercentage = Math.round((passedChecks / totalChecks) * 100);

  if (!selectedConcept) {
    return (
      <Typography
        sx={{
          fontSize: isMediumScreen ? '0.7963rem' : '1.125rem',
          fontWeight: 600,
          fontFamily: 'Montserrat, sans-serif'
        }}
      >
        {isConcept
          ? Constants.SELECT_A_CONCEPT_TO_VIEW_DETAILS
          : Constants.SELECT_A_INVENTION_TO_VIEW_DETAILS}
      </Typography>
    );
  }

  const VideoSection = () => {
    if (isConcept) {
      return (
        <Box height={220}>
          {selectedConcept.videoUrl ? (
            <>
              <StyledVideoMainBox>
                <video src={selectedConcept.videoUrl} controls />
              </StyledVideoMainBox>
            </>
          ) : (
            <>
              <StyledVideoMainBox>
                {selectedConcept.videoIsProcessing ? (
                  <LoadingContainer>
                    <CircularProgress />
                    <Typography variant={VARIANT.BODY1}>
                      {Constants.BE_PATIENT_VIDEO_GENERATION_IN_PROGRESS}
                    </Typography>
                  </LoadingContainer>
                ) : (
                  <Button
                    variant={VARIANT.CONTAINED}
                    color="primary"
                    onClick={handleVideoGenerateFromAI}
                  >
                    Generate Video
                  </Button>
                )}
              </StyledVideoMainBox>
            </>
          )}
        </Box>
      );
    } else {
      return (
        <Box>
          {selectedConcept.videoUrl ? (
            <StyledVideoMainBox>
              <video
                src={selectedConcept.videoUrl}
                controls
                width="100%"
                height="300px"
              />
            </StyledVideoMainBox>
          ) : (
            <StyledVideoMainBox>
              <Typography variant="body1">
                {Constants.NO_VIDEO_AVAILABLE}
              </Typography>
            </StyledVideoMainBox>
          )}
        </Box>
      );
    }
  };

  const SolutionImages = ({ selectedConcept }) => {
    return (
      <StyledImageBox onClick={handleOpenGallery}>
        {selectedConcept.solutions.map((solution, solutionIndex) =>
          solution.files[0] ? (
            <img
              key={solutionIndex}
              src={solution.files[0].url}
              alt={`${Constants.SOLUTION_IMAGE_TEXT} ${solutionIndex + 1}`}
            />
          ) : null
        )}
      </StyledImageBox>
    );
  };

  const Nodes = ({ selectedConcept }) => {
    const dispatch = useDispatch();
    const user = GetUser();

    const handleSolutionDelete = useCallback(
      (solutionId) => {
        const selected = selectedConcept.solutions
          .filter((solution) => solution.id !== solutionId)
          .map(({ id }) => id);

        const problems = selectedConcept.problems
          .filter((problem) =>
            problem?.children?.some((child) => selected?.includes(child.id))
          )
          .map(({ id }) => id);
        if (user) {
          dispatch(Actions.togLoadSolution(true));
          dispatch(
            Actions.updateConcept(selectedConcept.id, { selected, problems })
          );
        } else {
          dispatch(Actions.openAuthModal(true));
        }
      },
      [
        dispatch,
        selectedConcept?.id,
        selectedConcept?.problems,
        selectedConcept?.solutions,
        user
      ]
    );

    return (
      <Accordion
        sx={{
          borderRadius: '10px',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)'
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6">Problems & Solutions</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <SolutionBar>
            {selectedConcept?.problems?.map((problem) => {
              const filteredSolutions = selectedConcept?.solutions?.filter(
                (solution) =>
                  problem?.children?.some((child) => child.id === solution.id)
              );

              return filteredSolutions?.length ? (
                <React.Fragment key={problem.id}>
                  <ProblemContainer
                    onClick={() => {
                      window.open(
                        `/${Constants.PROBLEMS}/${problem.key}`,
                        '_blank'
                      );
                    }}
                  >
                    <ProbSolContainer>
                      <ProblemIcon>
                        <EntityIcon type={'problem'} />
                      </ProblemIcon>
                      <TeaserTypo>
                        {problem?.teaser ? problem.teaser : problem.title}
                      </TeaserTypo>
                    </ProbSolContainer>
                  </ProblemContainer>

                  {filteredSolutions.map((solution) => (
                    <AnimatePresence key={solution.id}>
                      <motion.div
                        initial={{ x: '100vw', opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: '-100vw', opacity: 0 }}
                        transition={{ duration: 0.5, ease: 'easeInOut' }}
                        key={solution.key}
                      >
                        <SolutionContainer
                          onClick={() => {
                            window.open(`/solutions/${solution.key}`, '_blank');
                          }}
                        >
                          <ProbSolContainer>
                            <SolutionIcon>
                              <EntityIcon type={'solution'} />
                            </SolutionIcon>
                            <TeaserTypo>
                              {solution?.teaser
                                ? solution.teaser
                                : solution.title}
                            </TeaserTypo>
                          </ProbSolContainer>
                          <IconButton
                            aria-label="delete"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleSolutionDelete(solution?.id);
                            }}
                          >
                            <IoClose size={22} color={colorPalette.black} />
                          </IconButton>
                        </SolutionContainer>
                      </motion.div>
                    </AnimatePresence>
                  ))}
                </React.Fragment>
              ) : null;
            })}
          </SolutionBar>
        </AccordionDetails>
      </Accordion>
    );
  };

  const getPatentStatusMessage = () => {
    if (selectedConcept?.envelopeId)
      return Constants.AWAITING_MINDMINER_PATENT_FILING;
    if (selectedConcept?.isDownloaded)
      return Constants.DOWNLOAD_PATENT_APPLICATION;
    if (selectedConcept?.generatingPatentApplication)
      return Constants.DOWNLOADING_PATENT;
    return Constants.MAKE_PATENT_APPLICATION;
  };

  const getStakingStatusMessage = () => {
    const isOwner = user?.id === selectedConcept?.owner;
    const isStakingInProgress = selectedConcept.stakingInProgress;
    const isQuotationReceived =
      selectedConcept.quotationStatus === QUOTATION_STATUS.RECEIVED;
    const isQuotationSent =
      selectedConcept.quotationStatus === QUOTATION_STATUS.SENT;
    const isQuotationNotSent =
      selectedConcept.quotationStatus === QUOTATION_STATUS.NOT_SENT;
    const isStakingFulfilled =
      inventionStaker?.status === STAKING_STATUS.FULFILLED;
    const isStakingInProgressStatus =
      inventionStaker?.status === STAKING_STATUS.IN_PROGRESS;

    if (isOwner) {
      if (!isStakingInProgress && isQuotationNotSent) {
        return Constants.START_STAKING_AND_CROWDFUNDING;
      }

      if (!isStakingInProgress && isQuotationReceived && !isStakingFulfilled) {
        return Constants.START_STAKING_AND_CROWDFUNDING;
      }

      if (!isStakingInProgress && isQuotationSent) {
        return Constants.AWAITING_MANUFACTURER_QUOTE;
      }

      if (
        isStakingInProgress &&
        isQuotationReceived &&
        isStakingInProgressStatus
      ) {
        return Constants.SHARE_TO_PRE_SELL_AND_CROWDFUNDING;
      }

      if (!isStakingInProgress && isStakingFulfilled) {
        return Constants.STAKING_COMPLETE;
      }
    }

    if (!isOwner) {
      if (isStakingFulfilled) {
        return Constants.STAKING_COMPLETE;
      }

      return Constants.STAKE_AND_PREPURCHASE;
    }

    return null;
  };

  return (
    <StyledDetailBox>
      <StyledDrawer
        anchor="left"
        disableScrollLock
        onClose={handleDrawer}
        open={isConceptDrawerOpen}
        leftMarg={true}
        ref={scrollRef}
        variant="persistent"
      >
        <StyledClose>
          <IconContainer onClick={handleDrawer}>
            <IoClose size={10} color={colorPalette.purple} />
          </IconContainer>
        </StyledClose>
        <ActiveConceptCard
          itemNodesHeight="50vh"
          onPatentDownloadClick={onPatentDownloadClick}
        />
      </StyledDrawer>
      <ChatHelpButton
        buttonText={Constants.AI_CHAT}
        onClick={handleSetOpenChat}
      />

      <ChatBotDrawer
        graphData={
          user?.activeConcept && user?.activeConcept?.problems?.length >= 1
            ? user?.activeConcept?.problems?.[0]?.parentProduct
            : selectedConcept?.problems?.[0]?.parentProduct
        }
        graphType={Constants.COMPANY_PRODUCTS}
        open={openChat}
        setOpen={setOpenChat}
      />
      <StyledParentContainer>
        <StyledTitle>{selectedConcept.title}</StyledTitle>
        <StyledIcons>
          <Box>
            <VotingBox>
              {!isConcept && (
                <Vote
                  item={selectedConcept}
                  itemType={Constants.APPLICATION}
                  pagination={pagination}
                  dislikeCount={selectedConcept.dislikes}
                  likeCount={selectedConcept.likes}
                />
              )}
            </VotingBox>
          </Box>
          <Box sx={{ position: 'relative' }}>
            {!isConcept && showShareOption && (
              <StyledBadge>
                <StyledButton
                  isMediumScreen={isMediumScreen}
                  onClick={handlePopoverOpen}
                >
                  <StyledShareImage
                    isMediumScreen={!isMediumScreen}
                    src={NewsFeedShareIcon}
                  />
                </StyledButton>
              </StyledBadge>
            )}
          </Box>
          {selectedConcept.videoUrl && selectedConcept.videoIsProcessing && (
            <Typography
              sx={{
                color: colorPalette.purple,
                fontWeight: '400',
                fontSize: '0.75rem'
              }}
            >
              Your video generation is in progress...
            </Typography>
          )}
        </StyledIcons>
      </StyledParentContainer>

      {!isConcept && (
        <>
          {(() => {
            const awaitingText = Constants.AWAITING_PATENT_TEXT;
            const patentPendingText = Constants.PATENT_PENDING_TEXT;

            const formattedDate = new Date(
              selectedConcept?.createdAt
            ).toLocaleDateString(Constants.DATE_TEXT, {
              year: Constants.NUMERIC_TEXT,
              month: Constants.LONG_TEXT,
              day: Constants.NUMERIC_TEXT
            });
            return (
              <>
                <StyledTextBox>
                  <StyledBoxTypography>
                    <PurpleText>
                      {Constants.INITIALLY_CREATED_TEXT}{' '}
                      {selectedConcept?.ownerInfo?.username},{' '}
                    </PurpleText>

                    {selectedConcept?.nftTransactionUrl ? (
                      <StyledPatentText>{patentPendingText}</StyledPatentText>
                    ) : (
                      <StyledPatentText>{awaitingText}</StyledPatentText>
                    )}
                  </StyledBoxTypography>
                </StyledTextBox>
              </>
            );
          })()}
        </>
      )}
      <VideoSection />
      <SolutionImages selectedConcept={selectedConcept} />
      {selectedConcept?.isFiled && (
        <>
          <Box sx={{ paddingTop: 2 }}>
            <CustomButton
              goldenButton={true}
              centerText={Constants.EARN_JACKPOT}
              endText="$--"
              startIcon={
                <LogoMindminer
                  fill={colorPalette.white}
                  width={45}
                  height={45}
                />
              }
              startText={getStakingStatusMessage()}
              onClick={
                user?.id === selectedConcept?.owner
                  ? handleCrowdFundButton
                  : handleSatkerOpenModal
              }
              sx={{ mb: 1 }}
            />
          </Box>
          {inventionStaker && selectedConcept.stakingInProgress && (
            <>
              <Box display={'flex'} flexDirection={'row'}>
                <Box display={'flex'} alignItems="center" width="95%">
                  <StyledLinearProgress
                    colorGreen={false}
                    variant="determinate"
                    value={stakingProgressBar >= 100 ? 100 : stakingProgressBar}
                  />
                </Box>
                <Box display="flex" alignItems="center">
                  {selectedConcept && checkListData ? (
                    <StyledNumber variant={VARIANT.SUBTITLE1}>
                      {stakingProgressBar >= 100 ? 100 : stakingProgressBar}%
                    </StyledNumber>
                  ) : (
                    <CircularProgress size={24} />
                  )}
                </Box>
              </Box>
              <StyledStakedText mb={2}>
                {Constants.USD_SYMBOL}
                {inventionStaker?.amountStaked ?? 0}
                {Constants.OF}
                {Constants.USD_SYMBOL}
                {inventionStaker?.stakingThreshold ?? 0}
                {Constants.STAKE_TEXT}
              </StyledStakedText>
              <StyledStakingMainBox>
                <LogoMindminer fill={colorPalette.amberOrange} />
                <StyledStakingInternalBox>
                  <StyledStakingSimpleTextBox>
                    {Constants.IDEA_COINS_STACKING_JACKPOT}
                  </StyledStakingSimpleTextBox>
                  <StyledStakingNumericTextBox>
                    {inventionStaker?.jackpotPricePerUnit} {Constants.COINS}
                    <StyledStakingNumericTextBox className={VARIANT.NORMAL}>
                      {Constants.SLASH_UNIT}
                    </StyledStakingNumericTextBox>
                  </StyledStakingNumericTextBox>
                </StyledStakingInternalBox>
              </StyledStakingMainBox>
            </>
          )}
        </>
      )}
      <StyledAccordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <StyledTagsDetailsText>
            {Constants.TAGS_AND_DETAILS_TEXT}
          </StyledTagsDetailsText>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <StyledGrid container spacing={2}>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                  <LogoMindminer
                    fill={colorPalette.purple}
                    width={20}
                    height={20}
                  />
                  <StyledIdeaPointsCount>
                    {selectedConcept.ideaPoints}
                  </StyledIdeaPointsCount>
                  <StyledIdeaPointsText>
                    Idea Points Generated
                  </StyledIdeaPointsText>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <ChipList
                  list={selectedConcept?.tagsInfo}
                  max={10}
                  getName={(tag) => {
                    const name = (tag as TagInfo).name || '';
                    return name;
                  }}
                  getUrl={(tagUrl) => {
                    const basePath = getBasePath(tagUrl);
                    return `/${basePath}/${(tagUrl as TagInfo).key || (tagUrl as TagInfo).id}`;
                  }}
                />
              </Grid>
            </StyledGrid>

            <Typography variant="body2" color="textSecondary" mt={2}>
              {selectedConcept.description}
            </Typography>
          </Box>
        </AccordionDetails>
      </StyledAccordion>

      <Box mt={2}>
        <Nodes selectedConcept={selectedConcept} />
      </Box>

      {!selectedConcept.isFiled && (
        <StyledProgressAccordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              '& .MuiLinearProgress-bar': {
                backgroundColor: colorPalette.green
              }
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <Box display="flex" alignItems="center" width="50%">
                <StyledLinearProgress
                  colorGreen={true}
                  variant="determinate"
                  value={progressPercentage >= 100 ? 100 : progressPercentage}
                />
              </Box>
              <Box display="flex" alignItems="center">
                {selectedConcept && checkListData ? (
                  <Typography variant="subtitle1" fontWeight="bold">
                    {progressPercentage >= 100 ? 100 : progressPercentage}%
                    Improved
                  </Typography>
                ) : (
                  <CircularProgress size={24} />
                )}
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {!selectedConcept.isDownloaded && !selectedConcept?.envelopeId && (
              <Box display="flex" justifyContent="flex-end" mb={2}>
                <Link
                  onClick={handleSetOpenChat}
                  color="primary"
                  underline="hover"
                  fontSize="0.875rem"
                  sx={{ cursor: 'pointer' }}
                >
                  {Constants.IMPROVE_YOUR_CONCEPT_WITH_THE_HELP_OF_AI_CHAT}{' '}
                  &rarr;
                </Link>
              </Box>
            )}
            {selectedConcept && checkListData ? (
              <ConceptCheckList fulfilled={fulfilled} warnings={warnings} />
            ) : (
              <LinearProgress />
            )}
          </AccordionDetails>
        </StyledProgressAccordion>
      )}
      {selectedConcept.isFiled ? (
        <>
          <CustomButton
            goldenButton={false}
            startIcon={
              <img src={PurpleHexagonIcon} alt={Constants.HEXAGON_ICON} />
            }
            startText={
              isDeployingNFT(selectedConcept)
                ? Constants.DEPLOYING
                : selectedConcept.nftTransactionUrl
                  ? Constants.LIST_YOUR_NFT
                  : Constants.DEPLOY_NFT
            }
            centerText={''}
            endText="$--"
            onClick={() => handleNFTButton(selectedConcept)}
            sx={{ mt: 1 }}
          />
          <StyledPoweredByBox>
            <Typography mr={1}>{Constants.POWERED_BY}</Typography>
            <img src={OpenSeaLogo}></img>
          </StyledPoweredByBox>
        </>
      ) : (
        <>
          <StyledMintButton
            variant="contained"
            disabled={
              selectedConcept?.generatingPatentApplication ||
              selectedConcept?.envelopeId
            }
            color="success"
            fullWidth
            onClick={
              selectedConcept?.isDownloaded
                ? makePatentApplication
                : () => setIsFinalizeModalOpen(true)
            }
          >
            <img src={MintPatentLogo} alt={Constants.MINT_PATENT_LOGO} />
            <Typography variant="h6" marginLeft={1}>
              {getPatentStatusMessage()}
            </Typography>
          </StyledMintButton>
          {selectedConcept.isDownloaded && !selectedConcept.envelopeId && (
            <StyledMintButton
              variant="contained"
              disabled={isDisabled(selectedConcept)}
              color="success"
              fullWidth
              onClick={() => UploadFilingReceipt(selectedConcept)}
            >
              <CloudUpload />
              <Typography variant="h6" marginLeft={1}>
                {Constants.E_FILE_PATENT}
              </Typography>
            </StyledMintButton>
          )}
        </>
      )}
      {selectedConcept && (
        <LockConceptModal
          archiveStatus={archiveStatus}
          concept={selectedConcept}
          open={openLockConceptModal}
          pagination={pagination}
          profileId={profile?.id ?? profile?._id}
          setOpen={setOpenLockConceptModal}
          setOpenNextModal={openModalAfterLockConcept}
          beforeClose={beforeLockConceptModalClose}
          onTaskComplete={onTaskCompletion}
        />
      )}
      <MakePatentPendingModal
        concept={selectedConcept}
        onPatentDownloadClick={setOpenDownloadAndPatentPendingModal}
        open={openPatentPendingModal}
        pagination={pagination}
        setOpen={setOpenPatentPendingModal}
      />
      <DownloadPatentAppModal
        application={selectedConcept}
        onFileViaMindMiner={setOpenDownloadAndPatentPendingModal}
        open={openDownloadPatentAppModal}
        pagination={pagination}
        profile={profile}
        setOpen={setOpenDownloadPatentAppModal}
      />
      <UploadFilingReceiptModal
        concept={selectedConcept}
        open={openFilingReceiptModal}
        setOpen={setOpenFilingReceiptModal}
        pagination={pagination}
        profileId={profile?.id ?? profile?._id}
      />
      <FinalizeConceptModal
        approveImprovements={handleImprove}
        approveMintToken={makePatentApplication}
        open={isFinalizeModalOpen}
        setOpen={setIsFinalizeModalOpen}
      />
      {showTxApprovalModal && (
        <TxApprovalModal
          gasFee={txApprovalModalObj.gasFee}
          onConfirm={onTxConfirm}
          open={txApprovalModalObj.open}
          type={txApprovalModalObj.type}
        />
      )}
      <StakingSetupModal
        isOpen={isStakingSetupModalOpen}
        onClose={handleSatkingSetupCloseModal}
        inventionId={selectedConcept.id}
        fetchProfileConcepts={fetchProfileConcepts}
      />
      <RequestQuotationModal
        isOpen={isRequestQoutationModalOpen}
        onClose={handleRequestQuotationCloseModal}
        quotationStatus={selectedConcept.quotationStatus}
        stakingStatus={selectedConcept.stakingInProgress}
        inventionStakingStatus={inventionStaker?.status}
        conceptId={selectedConcept.id}
        owner={selectedConcept.owner.toString()}
        fetchProfileConcepts={fetchProfileConcepts}
      />
      <StakerModal
        isOpen={isStakerModalOpen}
        onClose={handleSatkerCloseModal}
        onSubmit={handleStakeInventionFormSubmit}
        pricePerProduct={inventionStaker?.minimumStakeAmount}
        numberOfUnitsStaked={numberOfUnitStaked}
        maximumNumberOfUnitsAllowed={
          inventionStaker?.totalProductsToBeManufactured
        }
      />
      {images.length != 0 && (
        <Dialog
          open={openGallery}
          onClose={handleCloseGallery}
          fullWidth
          maxWidth="sm"
        >
          <Box sx={{ padding: 3 }}>
            <StyledTitleForGallery>
              {Constants.SOLUTIONS_IMAGES}
            </StyledTitleForGallery>
            <GalleryView images={images} />
          </Box>
        </Dialog>
      )}
    </StyledDetailBox>
  );
};

export { ConceptDetailView };
