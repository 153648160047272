export class FetchError extends Error {
  status: number | undefined;

  toJson() {
    return {
      message: this.message,
      status: this.status
    };
  }
}

export class FetchUtils {
  static fetchJson = async (
    url: RequestInfo,
    options?: RequestInit
  ): Promise<any> => {
    const response = await fetch(url, options).catch((error) => {
      console.log(error);
    });
    if (response && response.status >= 200 && response.status < 300) {
      const json = await response.json();
      if (json.error && json.message) {
        const error = new FetchError(json.message);
        error.status = response.status;
        throw error;
      }
      return json;
    } else if (response) {
      let message;
      try {
        message = await response.text();
      } catch (err) {
        /* empty */
      }
      const error = new FetchError(message || response.statusText);
      error.status = response.status;
      throw error;
    }
  };
}

export default FetchUtils;
