import { Avatar, Box, Button, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PsLogo from 'components/common/PsLogo';
import { colorPalette } from 'theme';

export const StyledButtonContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center'
});

export const StyledAvatar = styled(Avatar)({
  height: '26px',
  width: '26px'
});

export const StyledLink = styled(Link)({
  color: colorPalette.greenHaze,
  textDecorationColor: colorPalette.greenHaze
});

export const StyledButton = styled(Button)({
  backgroundColor: colorPalette.greenHaze,
  width: '216px',
  height: '28px'
});

export const StyledImage = styled(Avatar)({
  height: '3.7rem',
  width: '3.7rem'
});

export const StyledContestCardTitle = styled(Typography)({
  display: '-webkit-box',
  fontSize: '.75rem',
  fontWeight: 700,
  lineHeight: '1.2',
  marginLeft: '.9375rem',
  marginTop: '.125rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: '2'
});

export const StyledContestCardLinkTypography = styled(Typography)({
  fontSize: 13,
  fontWeight: 500,
  marginTop: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-end',
  '@media (max-width:1400px)': {
    fontSize: 10
  }
});

export const StyledContestCardDateTypography = styled(Typography)({
  fontSize: 9
});

export const StyledEarnTypography = styled(Typography)({
  fontSize: 10,
  fontWeight: 700
});

export const StyledLogo = styled(PsLogo)({
  marginLeft: 12
});

export const MainContainer = styled(Box)({
  background: '#fff',
  border: '1px solid #4CAF50',
  borderRadius: 3,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '160px',
  overflow: 'hidden',
  padding: '10px 10px'
});

export const MainContent = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start ',
  alignItems: 'center'
});

export const ImageContainer = styled(Box)({
  display: 'flex',
  height: '4rem',
  width: '4rem'
});

export const InfoContainerMobile = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '.1975rem',
  width: '100%'
});

export const DetailsContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center'
});

export const StyledCardButton = styled(Button)({
  color: colorPalette.greenHaze,
  fontSize: '.625rem',
  lineHeight: '0.5',
  padding: '.3125rem',
  textDecorationColor: colorPalette.greenHaze,
  whiteSpace: 'nowrap',
  ':hover': { textDecoration: 'underline' }
});

export const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center'
});

export const ReactionButtons = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px 18px',
  borderRadius: '30px',
  minHeight: '40px',
  minWidth: '100px',
  transform: 'scale(0.45)',
  backgroundColor: '#f0eef4'
});

export const StyledLikes = styled(Typography)({
  margin: '0px 10px 0px 10px',
  fontSize: '16px',
  fontWeight: '500',
  fontFamily: 'montserrat',
  color: 'black'
});

export const DescriptionText = styled(Typography)({
  display: '-webkit-box',
  fontSize: '.625rem',
  lineHeight: '1.1',
  marginBottom: '.625rem',
  marginTop: '.625rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: '2'
});
