import { useContext, useState } from 'react';
import DataContext from 'contexts/DataContext';

import type { Application } from 'components/CardApplication';
import type { Concept } from 'components/CardProduct';
import AuthContext from 'contexts/AuthContext';

type SolutionShort = {
  id: string;
  key: string;
  title: string;
  teaser: string;
};

interface Options {
  solutionId: string;
  problemId: string | null | undefined;
  productId?: string | null | undefined;
}

export const useAddSolutionInConcept = ({
  solutionId,
  problemId,
  productId
}: Options) => {
  const [loading, setLoading] = useState(false);

  const {
    selectedConceptId,
    concepts,
    dataProvider,
    getUserConcepts,
    setSelectedConceptId,
    setOpenedGraphCard
  } = useContext(DataContext);

  const { user } = useContext(AuthContext);

  const selectedConcept = concepts.find(
    (concept) => concept.id === selectedConceptId
  );

  const currentUser = user?.id;

  const solutionInConcept = selectedConcept?.selected?.includes(solutionId);

  const handleAddInConcept = () => {
    if (!productId || solutionInConcept || !solutionId || loading) {
      return;
    }

    setLoading(true);

    if (selectedConceptId && selectedConcept) {
      let currentSolutions: Array<any> = [];
      if (selectedConcept.solutions) {
        currentSolutions = [...selectedConcept.solutions];
      }
      const newSelectedSolutions = [...currentSolutions, { id: solutionId }];

      dataProvider
        .update<Application>('applications', {
          id: selectedConceptId,
          data: {
            selected: newSelectedSolutions.map(({ id }) => id)
          }
        })
        .then((res) => {
          getUserConcepts(currentUser)?.then(() => {
            setSelectedConceptId(res.data.id);
          });
        })
        .finally(() => setLoading(false));

      return;
    }

    if (!concepts.length) {
      const appInfo = {
        title: `Concept ${concepts.length + 1}`,
        parentProduct: productId,
        problem: problemId,
        selected: [solutionId],
        type: productId
      };
      dataProvider
        .create<Concept>('applications', {
          data: appInfo
        })
        .then((res) => {
          getUserConcepts(currentUser)?.then(() => {
            setSelectedConceptId(res.data.id);
          });
        })
        .finally(() => {
          setLoading(false);
        });

      return;
    }

    const concept = concepts[0];
    let currentSolutions: Array<SolutionShort> = [];
    if (concept.solutions) {
      currentSolutions = [...concept.solutions];
    }

    dataProvider
      .update<Application>('applications', {
        id: concept.id,
        data: {
          selected: [...currentSolutions, { id: solutionId }].map(
            ({ id }) => id
          )
        }
      })
      .then(() => {
        getUserConcepts(currentUser)?.then(() => {
          setSelectedConceptId(concept.id);
          setOpenedGraphCard(solutionId);
        });
      })
      .finally(() => setLoading(false));
  };

  return { loading, addInConcept: handleAddInConcept, solutionInConcept };
};
