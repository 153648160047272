import React, { FC, useMemo } from 'react';
import { NodeContentBaseProps } from 'interface/common';
import { getBasePath } from 'helpers/common';
import { Constants } from 'utilities/constants';
import { ChipList } from 'components/Tag';
import { TagInfo } from 'components/CardTag';
import IdentifierIcon from 'components/IdentifierIcon';
import {
  ButtonContainer,
  ImageContainer,
  StackBox,
  StyledAvatar,
  StyledBox,
  StyledInnerBox,
  StyledTitle,
  TextContainer,
  TagContainer
} from './StyledComponents';

export const NodeContentBase: FC<NodeContentBaseProps> = ({
  buttonList,
  children,
  image,
  isCurrentConceptSolution,
  messageId,
  node,
  onNodeClick,
  selectedNode,
  showAvatar = true,
  tags
}) => {
  const { ownerImage, shortTitle, title, type, id } = node;

  const nodeText = useMemo(
    () =>
      type === Constants.PRODUCT
        ? title
        : selectedNode === id
          ? title
          : (shortTitle ?? title),
    [id, shortTitle, title, type, selectedNode]
  );

  const imgUrl = node?.files?.length > 0 ? node.files[0]?.url : '';

  return (
    <StyledBox
      datatype={type}
      onClick={onNodeClick}
      onDragStart={(e) => e.preventDefault()}
      type={type}
      isCurrentConceptSolution={isCurrentConceptSolution}
    >
      <IdentifierIcon
        type={type}
        image={ownerImage}
        profileKey={node?.owner?.key}
      />
      <StyledInnerBox>
        <TextContainer>
          {showAvatar && (
            <ImageContainer>
              <StyledAvatar className="item-img" src={image} alt="icon" />
            </ImageContainer>
          )}

          <TagContainer marginLeft={!showAvatar ? '1.25rem' : 'none'}>
            <StyledTitle className="selected-node-text" type={type}>
              {nodeText}
            </StyledTitle>

            {tags?.length ? (
              <ChipList
                list={tags}
                max={3}
                getName={(tag) => tag.name}
                getUrl={(item) => {
                  const basePath = getBasePath(item);
                  return `/${basePath}/${
                    (item as TagInfo).key || (item as TagInfo).id
                  }`;
                }}
              />
            ) : null}
          </TagContainer>
        </TextContainer>

        <ButtonContainer>
          {buttonList &&
            buttonList.map((button, index) => {
              const CustomButton = button.component;
              const key = `button-${index}`;
              return (
                <StackBox
                  sx={{ marginRight: '0.625rem' }}
                  key={key}
                  onClick={(event) => {
                    event.stopPropagation();
                    button.onClick(event, node, messageId);
                  }}
                >
                  <CustomButton node={node} />
                </StackBox>
              );
            })}
          {children}
        </ButtonContainer>
      </StyledInnerBox>
    </StyledBox>
  );
};
