import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useContext
} from 'react';
import { AuthContext, formTypes } from 'contexts/AuthContext';

export type AuthFormsContextType = {
  email: string;
  setEmail: (val: string) => void;
  tempUser: any;
  setTempUser: (val: any) => void;
  loading: boolean;
  setLoading: (val: boolean) => void;
  loadingFacebook: boolean;
  setLoadingFacebook: (val: boolean) => void;
  loadingGoogle: boolean;
  setLoadingGoogle: (val: boolean) => void;
  loadingPortis: boolean;
  setLoadingPortis: (val: boolean) => void;
  signUpFormError: string;
  setSignUpFormError: (val: string) => void;
  loginFormError: string;
  setLoginFormError: (val: string) => void;
  forgotFormError: string;
  setForgotFormError: (val: string) => void;
  newFormError: string;
  setNewFormError: (val: string) => void;
  tryLoginFromUrl: (data: { email: string }) => void;
};

const defaultContext = {
  email: '',
  setEmail: (val: string) => ({}),
  tempUser: undefined,
  setTempUser: (val: any) => ({}),
  loading: false,
  setLoading: (val: boolean) => ({}),
  loadingFacebook: false,
  setLoadingFacebook: (val: boolean) => ({}),
  loadingGoogle: false,
  setLoadingGoogle: (val: boolean) => ({}),
  loadingPortis: false,
  setLoadingPortis: (val: boolean) => ({}),
  signUpFormError: '',
  setSignUpFormError: (val: string) => ({}),
  loginFormError: '',
  setLoginFormError: (val: string) => ({}),
  forgotFormError: '',
  setForgotFormError: (val: string) => ({}),
  newFormError: '',
  setNewFormError: (val: string) => ({}),
  tryLoginFromUrl: (data: { email: string }) => ({})
};

export const AuthFormsContext =
  createContext<AuthFormsContextType>(defaultContext);

type Props = {
  children: React.ReactElement;
};

export const AuthFormsContextProvider = ({ children }: Props) => {
  const { auth, user, authModalOpened, setAuthModalOpened } =
    useContext(AuthContext);

  const [tempUser, setTempUser] = useState();

  const [email, setEmail] = useState('');

  const [loading, setLoading] = useState(false);
  const [loadingFacebook, setLoadingFacebook] = useState(false);
  const [loadingGoogle, setLoadingGoogle] = useState(false);
  const [loadingPortis, setLoadingPortis] = useState(false);

  const [signUpFormError, setSignUpFormError] = useState('');
  const [loginFormError, setLoginFormError] = useState('');
  const [forgotFormError, setForgotFormError] = useState('');
  const [newFormError, setNewFormError] = useState('');

  useEffect(() => {
    if (!authModalOpened) {
      setEmail('');
      setTempUser(undefined);
      setLoading(false);
      setLoadingFacebook(false);
      setLoadingGoogle(false);
      setLoadingPortis(false);
      setSignUpFormError('');
      setLoginFormError('');
      setForgotFormError('');
      setNewFormError('');
    }
  }, [authModalOpened]);

  const tryLoginFromUrl = useCallback(
    (data: any = {}) => {
      const { email } = data;
      Promise.resolve()
        .then(() => {
          return auth.getPermissions();
        })
        .catch(() => {
          //
        })
        .then((authUser) => {
          if (authUser) {
            window.localStorage['loginEmail'] = email;
            auth.profile = undefined;
            return auth.logout();
          }
        })
        .then(() => {
          window.localStorage['loginEmail'] = '';
          return auth.login({ email });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [auth, setEmail, setTempUser, setAuthModalOpened]
  );

  return (
    <AuthFormsContext.Provider
      value={{
        email,
        setEmail,
        tempUser,
        setTempUser,
        loading,
        setLoading,
        loadingFacebook,
        setLoadingFacebook,
        loadingGoogle,
        setLoadingGoogle,
        loadingPortis,
        setLoadingPortis,
        signUpFormError,
        setSignUpFormError,
        loginFormError,
        setLoginFormError,
        forgotFormError,
        setForgotFormError,
        newFormError,
        setNewFormError,
        tryLoginFromUrl
      }}
    >
      {children}
    </AuthFormsContext.Provider>
  );
};

export const AuthFormsContextConsumer = AuthFormsContext.Consumer;

export default AuthFormsContext;
