import React from 'react';
import { Container, Content, FeatureText } from './styledComponents';
import { CheckCircle } from '@mui/icons-material';
import { VARIANT } from 'utilities/constants';
import { generateUniqueId } from 'helpers/common';

export const SubscriptionFeatures = ({ features }) => {
  return (
    <Container>
      {features.map((feature) => (
        <Content key={generateUniqueId()}>
          <CheckCircle
            color={VARIANT.PRIMARY}
            sx={{ width: '.8125rem', height: '.8125rem' }}
          />
          <FeatureText>{feature}</FeatureText>
        </Content>
      ))}
    </Container>
  );
};
