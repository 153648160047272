import React, { useEffect, useMemo, useState } from 'react';
import dataProvider from 'dataPrvider';
import { Constants, NUMBERS } from 'utilities/constants';
import StakingSetupDialog from './StakingSetupDialog';

const StakingSetupModal = ({
  isOpen,
  onClose,
  inventionId,
  fetchProfileConcepts
}) => {
  const quotationAmountPerUnit = 5000;
  const basicThreshold = useMemo(() => {
    const baseThreshold =
      quotationAmountPerUnit * NUMBERS.ONE_POINT_FIVE + quotationAmountPerUnit;
    const calculatedThreshold = baseThreshold * NUMBERS.ONE_POINT_ONE;
    return calculatedThreshold.toFixed(2);
  }, [quotationAmountPerUnit]);

  const initialFormData = useMemo(
    () => ({
      numberOfProducts: '1',
      stakingThreshold: basicThreshold
    }),
    [basicThreshold]
  );

  const [formData, setFormData] = useState(initialFormData);
  const [isThresholdValid, setThresholdValid] = useState(true);

  useEffect(() => {
    if (isOpen) {
      setFormData(initialFormData);
      setThresholdValid(true);
    }
  }, [isOpen, initialFormData]);

  const calculateTotalThreshold = (numProducts) =>
    (parseFloat(basicThreshold) * numProducts).toFixed(2);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (parseFloat(value) !== parseInt(value, 10)) return;

    setFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData, [name]: value };
      if (name === Constants.NUMBER_OF_PRODUCTS) {
        const numProducts = parseInt(value, 10) || 0;
        const updatedThreshold = calculateTotalThreshold(numProducts);
        updatedFormData.stakingThreshold = updatedThreshold;
      }
      return updatedFormData;
    });
  };

  const createPayload = () => ({
    applicationId: inventionId,
    totalProductsToBeManufactured: parseInt(formData.numberOfProducts, 10),
    stakingThreshold: parseFloat(formData.stakingThreshold),
    minimumStakeAmount: basicThreshold,
    status: 'InProgress',
    jackpotPricePerUnit:
      parseFloat(formData.stakingThreshold) / NUMBERS.FIVE_HUNDRES
  });

  const handleCreateStaking = async () => {
    const payload = createPayload();
    await dataProvider.create(Constants.INVENTIONSTAKER, { data: payload });
    fetchProfileConcepts();
    onClose();
  };

  return (
    <StakingSetupDialog
      isOpen={isOpen}
      onClose={onClose}
      formData={formData}
      isThresholdValid={isThresholdValid}
      handleInputChange={handleInputChange}
      handleCreateStaking={handleCreateStaking}
      basicThreshold={basicThreshold}
    />
  );
};

export default StakingSetupModal;
