import React, { useState, useEffect, useContext, useCallback } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { Solution } from 'components/CardSolution';
import { Application } from 'components/CardApplication';
import { CardSolutionSmall } from 'components/CardSolution';
import { DataContext } from 'contexts/DataContext';
import { Pagination } from 'components/Pagination';
import { PsTheme } from '../../theme';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'block' | 'header' | 'loading' | 'noResult';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    block: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginTop: 10
    },
    header: {
      fontSize: 32,
      fontWeight: 'bold',
      lineHeight: '39px',
      marginTop: 24,
      color: psTheme.palette.third.main,
      [psTheme.breakpoints.down('sm')]: {
        fontSize: 25,
        lineHeight: '30px'
      },
      '& b': {
        fontWeight: 'bold',
        color: psTheme.palette.primary.main
      }
    },
    loading: {
      lineHeight: '30px',
      textAlign: 'center'
    },
    noResult: {
      lineHeight: '30px',
      textAlign: 'center'
    }
  };
});

type RelatedSolutionsProps = {
  title?: string | React.ReactNode;
  noResultContent?: React.ReactNode;
  problem?: string | number;
  challenge?: string | number;
  tagName?: string;
  owner?: string | number;
  selectedForApplication?: string | number;
  suggestedForApplication?: string | number;
  product?: string | number;
  improvedSolution?: string | number;
};

const RelatedSolutionsView = ({
  noResultContent,
  title,
  problem,
  challenge,
  tagName,
  owner,
  selectedForApplication,
  suggestedForApplication,
  product,
  improvedSolution
}: RelatedSolutionsProps) => {
  const { classes } = useStyles();
  const {
    dataProvider,
    relatedSolutionsListCache,
    refreshRelatedSolutionsList
  } = useContext(DataContext);
  const [page, setPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [solutions, setSolutions] = useState<Array<Solution>>([]);
  const [applications, setApplications] = useState<Array<Application>>([]);
  const [loading, setLoading] = useState(true);
  const perPage = 5;

  useEffect(() => {
    if (
      !problem &&
      !challenge &&
      !tagName &&
      !owner &&
      !selectedForApplication &&
      !suggestedForApplication &&
      !product &&
      !improvedSolution
    ) {
      setSolutions([]);
      setLoading(false);
      return;
    }
    let filter = {};
    if (problem) {
      filter = {
        problem,
        $custom: { type: 'relatedProblems' }
      };
    } else if (challenge) {
      filter = {
        $custom: { type: 'selectedForChallenge', challenge }
      };
    } else if (tagName) {
      filter = {
        tags: [tagName],
        $custom: { type: 'forUi' }
      };
    } else if (owner) {
      filter = {
        owner,
        $custom: { type: 'forUi' }
      };
    } else if (selectedForApplication) {
      filter = {
        owner,
        $custom: {
          type: 'selectedForApplication',
          application: selectedForApplication
        }
      };
    } else if (suggestedForApplication) {
      filter = {
        owner,
        $custom: {
          type: 'suggestedForApplication',
          application: suggestedForApplication
        }
      };
    } else if (product) {
      filter = {
        $custom: {
          type: 'selectedForProduct',
          product
        }
      };
    } else if (improvedSolution) {
      filter = { improvedSolution, $custom: true };
    }

    setLoading(true);
    dataProvider
      .getList<Solution>('solutions', {
        pagination: { page: page + 1, perPage },
        sort: { field: 'votes', order: 'DESC' },
        filter
      })
      .then(({ data, total }) => {
        setSolutions(data);
        setTotalPages(Math.ceil(total / perPage));
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    page,
    problem,
    tagName,
    owner,
    product,
    selectedForApplication,
    suggestedForApplication,
    relatedSolutionsListCache
  ]);

  useEffect(() => {
    if (!product) {
      setApplications([]);
      return;
    }
    dataProvider
      .getList<Application>('applications', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'votes', order: 'DESC' },
        filter: {
          $custom: {
            type: 'selectedForProduct',
            product
          }
        }
      })
      .then(({ data, total }) => {
        setApplications(data);
      })
      .catch((err) => {
        console.error(err);
        setApplications([]);
      });
  }, [product, relatedSolutionsListCache]);

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const onTagRemove = useCallback(() => {
    refreshRelatedSolutionsList();
  }, [refreshRelatedSolutionsList]);

  return (
    <div className={classes.block}>
      {title ? (
        <Typography className={classes.header} variant="h2" component="h2">
          {title}
        </Typography>
      ) : null}
      {loading ? (
        <Typography className={classes.loading} variant="body2">
          Loading...
        </Typography>
      ) : null}
      {!loading && !solutions.length ? (
        <Typography className={classes.noResult}>
          {noResultContent
            ? noResultContent
            : 'This item does not yet have any solutions'}
        </Typography>
      ) : null}
      {solutions.map((solution) => {
        return (
          <CardSolutionSmall
            key={solution.id}
            solution={solution}
            onTagRemove={onTagRemove}
            filterTagName={tagName}
            productId={product}
            applications={product ? applications : undefined}
          />
        );
      })}
      <Pagination total={totalPages} active={page} onChange={onPageChange} />
    </div>
  );
};

export const RelatedSolutions = styled(RelatedSolutionsView)({});

export default RelatedSolutions;
