import React from 'react';

export const getProblemQuestion = (
  type?: string | number,
  inventionName?: string
) => {
  switch (type) {
    case 'Performability': {
      return (
        <>
          What problem of the <b>{inventionName}</b> prevents the desired
          utility for its users?
        </>
      );
    }
    case 'Affordability': {
      return (
        <>
          What problem of the <b>{inventionName}</b> provides more value than
          the consumer wants for the price?
        </>
      );
    }
    case 'Featureability': {
      return (
        <>
          What problem of the <b>{inventionName}</b> has led to a missing or
          unnecessary feature?
        </>
      );
    }
    case 'Deliverability': {
      return (
        <>
          What problem of the <b>{inventionName}</b> prevents its delivery
          precisely when it is needed?
        </>
      );
    }
    case 'Useability': {
      return (
        <>
          What problem of the <b>{inventionName}</b> creates an unnecessary need
          for learning, or makes it not easy to use?
        </>
      );
    }
    case 'Maintainability': {
      return (
        <>
          What problem of the <b>{inventionName}</b> has led to the requirement
          of excessive skill or expense to maintain it?
        </>
      );
    }
    case 'Durability': {
      return (
        <>
          What problem of the <b>{inventionName}</b> has caused it to be too
          fragile?
        </>
      );
    }
    case 'Imageability': {
      return (
        <>
          What problem of the <b>{inventionName}</b> creates a negative
          perception of how it looks or works?
        </>
      );
    }

    case 'Complexity': {
      return (
        <>
          What problem of the <b>{inventionName}</b> prevents it from being
          simple to use, work or understand?
        </>
      );
    }
    case 'Precision': {
      return (
        <>
          What problem of the <b>{inventionName}</b> prevents it from being
          adaptable in its uses or functionality?
        </>
      );
    }
    case 'Variability': {
      return (
        <>
          What problem of the <b>{inventionName}</b> leads to regular changes in
          its uses or functioning?
        </>
      );
    }
    case 'Sensitivity': {
      return (
        <>
          What problem of the <b>{inventionName}</b> prevents it from being
          robust?
        </>
      );
    }
    case 'Immaturity': {
      return (
        <>
          What problem of the <b>{inventionName}</b> leads to a lack of
          confidence in it, or a feeling that it is proven?
        </>
      );
    }
    case 'Danger': {
      return (
        <>
          What problem of the <b>{inventionName}</b> prevents it from being
          safe?
        </>
      );
    }
    case 'High Skill': {
      return (
        <>
          What problem of the <b>{inventionName}</b> causes it to require much
          skill or knowledge to work?
        </>
      );
    }
    default: {
      return <>No supported type</>;
    }
  }
};

export const getSolutionQuestion = (
  type?: string | number,
  inventionName?: string,
  problemName?: string
) => {
  switch (type) {
    case 'Performability': {
      return (
        <>
          How can you make the <b>{inventionName}</b> have improved performance
          by solving the problem of <b>{problemName}</b>?
        </>
      );
    }
    case 'Affordability': {
      return (
        <>
          How can you make the <b>{inventionName}</b> more affordable by solving
          the problem of <b>{problemName}</b>?
        </>
      );
    }
    case 'Featureability': {
      return (
        <>
          How can you make the <b>{inventionName}</b> have better features by
          solving the problem of <b>{problemName}</b>?
        </>
      );
    }
    case 'Deliverability': {
      return (
        <>
          How can you make the <b>{inventionName}</b> more deliverable by
          solving the problem of <b>{problemName}</b>?
        </>
      );
    }
    case 'Useability': {
      return (
        <>
          How can you make the <b>{inventionName}</b> as easy as possible to use
          by solving the problem of <b>{problemName}</b>?
        </>
      );
    }
    case 'Maintainability': {
      return (
        <>
          How can you make the <b>{inventionName}</b> easier to maintain by
          solving the problem of <b>{problemName}</b>?
        </>
      );
    }
    case 'Durability': {
      return (
        <>
          How can you make the <b>{inventionName}</b> more durable by solving
          the problem of <b>{problemName}</b>?
        </>
      );
    }
    case 'Imageability': {
      return (
        <>
          How can you make the <b>{inventionName}</b> have an improved image by
          solving the problem of <b>{problemName}</b>?
        </>
      );
    }

    case 'Complexity': {
      return (
        <>
          How can you make the <b>{inventionName}</b> simpler by solving the
          problem of <b>{problemName}</b>?
        </>
      );
    }
    case 'Precision': {
      return (
        <>
          How can you make the <b>{inventionName}</b> more adaptable by solving
          the problem of <b>{problemName}</b>?
        </>
      );
    }
    case 'Variability': {
      return (
        <>
          How can you make the <b>{inventionName}</b> more consistent by solving
          the problem of <b>{problemName}</b>?
        </>
      );
    }
    case 'Sensitivity': {
      return (
        <>
          How can you make the <b>{inventionName}</b> more robust by solving the
          problem of <b>{problemName}</b>?
        </>
      );
    }
    case 'Immaturity': {
      return (
        <>
          How can you make the <b>{inventionName}</b> more proven by solving the
          problem of <b>{problemName}</b>?
        </>
      );
    }
    case 'Danger': {
      return (
        <>
          How can you make the <b>{inventionName}</b> safer by solving the
          problem of <b>{problemName}</b>?
        </>
      );
    }
    case 'High Skill': {
      return (
        <>
          How can you make the <b>{inventionName}</b> require less skill by
          solving the problem of <b>{problemName}</b>?
        </>
      );
    }
    default: {
      return <>No supported type</>;
    }
  }
};
