import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback
} from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { intervalToDuration, formatDuration } from 'date-fns';
import { PsTheme } from '../../theme';
import { DataContext } from 'contexts/DataContext';
import {
  Contest,
  ContestTops,
  ContestTop,
  ContestTopUser
} from 'components/CardContest';
import { Contributors } from 'components/CardTag';
import { PsButton } from 'components/common/PsButton';
import { PsLogo } from 'components/common/PsLogo';
import {
  getPlaceholderImage,
  placeholderType,
  getFileTitle
} from '../../helpers';
import useRouter from 'hooks/useRouter';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { makeStyles } from 'tss-react/mui';

type ClassKey =
  | 'root'
  | 'header'
  | 'content'
  | 'title'
  | 'time'
  | 'solveBtn'
  | 'row'
  | 'cell'
  | 'photo'
  | 'userPhoto'
  | 'cellTitle'
  | 'cellText'
  | 'users';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      marginTop: 13,
      padding: '13px 20px',
      borderRadius: 10,
      backgroundColor: '#F5F3FA',
      '&:first-child': {
        marginTop: 0
      }
    },
    header: {
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'space-between',
      [psTheme.breakpoints.down('xs')]: {
        flexDirection: 'column'
      }
    },
    title: {
      marginBottom: 10,
      fontSize: 24,
      fontWeight: 700,
      color: 'inherit',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    time: {
      marginBottom: 10,
      fontWeight: 600,
      color: psTheme.palette.primary.main
    },
    solveBtn: {
      fontSize: 23,
      margin: '-15px 0 0 -25px',
      '& .btn-text': {
        fontSize: 16,
        lineHeight: '20px'
      }
    },
    content: {
      marginBottom: 10
    },
    row: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      minHeight: 100,
      [psTheme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start'
      }
    },
    cell: {
      width: '33%',
      paddingLeft: 25,
      '&:first-child': {
        paddingTop: 20
      },
      [psTheme.breakpoints.down('sm')]: {
        width: '100%',
        paddingLeft: 20,
        paddingTop: 10,
        paddingBottom: 10,
        '&:first-child': {
          paddingTop: 0,
          paddingLeft: 0
        }
      }
    },
    photo: {
      display: 'block',
      width: 55,
      flexShrink: 0,
      marginRight: 43
    },
    userPhoto: {
      display: 'block',
      width: 55,
      height: 55,
      borderRadius: 10,
      objectFit: 'cover'
    },
    cellTitle: {
      marginBottom: 10,
      fontSize: 20,
      fontWeight: 700,
      '& a': {
        color: 'inherit',
        textDecoration: 'none'
      },
      '& a:hover': {
        textDecoration: 'underline'
      }
    },
    cellText: {
      fontSize: 20,
      lineHeight: '24px'
    },
    users: {
      marginBottom: -15,
      paddingTop: 5
    }
  };
});

type DurationProps = {
  startDate: string;
  endDate: string;
  empty?: boolean;
};

export const Duration = ({ startDate, endDate, empty }: DurationProps) => {
  const { refreshContestsList } = useContext(DataContext);
  const [status, setStatus] = useState('');
  const [duration, setDuration] = useState('');
  const [timerNum, setTimerNum] = useState(0);
  const timer = useRef<number | null>(null);
  const { classes } = useStyles();

  useEffect(() => {
    const now = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);
    let newStatus = '';
    if (now < start) {
      newStatus = 'pending';
    } else if (now < end) {
      newStatus = 'active';
    } else {
      newStatus = 'passed';
    }
    if (
      (status === 'pending' && newStatus === 'active') ||
      (status === 'active' && newStatus === 'passed')
    ) {
      refreshContestsList();
    }
    setStatus(newStatus);

    if (newStatus !== 'passed') {
      const dur = intervalToDuration({ start: now, end });
      const durStr = formatDuration(dur, {
        format: [
          'years',
          'months',
          'weeks',
          'days',
          'hours',
          'minutes',
          'seconds'
        ],
        delimiter: ' : '
      });
      setDuration(durStr + ' Remaining');
    } else {
      clearInterval(timer.current as number);
      timer.current = null;
    }
  }, [timerNum]);

  useEffect(() => {
    timer.current = setInterval(() => {
      setTimerNum((cur) => cur + 1);
    }, 1000) as unknown as number;
    return () => {
      clearInterval(timer.current as number);
      timer.current = null;
    };
  }, []);

  if (!status || status === 'passed' || empty) {
    return null;
  }

  return <div className={classes.time}>{duration}</div>;
};

export type CardContestSmallProps = {
  className?: string;
  contest: Contest;
  type?: string;
};

const CardContestSmallView = ({
  className,
  contest,
  type
}: CardContestSmallProps) => {
  const { id, key, title, startDate, endDate, bountyCoins } = contest;
  const { dataProvider } = useContext(DataContext);
  const router = useRouter();
  const { classes } = useStyles();
  const [topSolution, setTopSolution] = useState<ContestTop | undefined>(
    undefined
  );
  const [topApplication, setTopApplication] = useState<ContestTop | undefined>(
    undefined
  );
  const [topUsers, setTopUsers] = useState<Array<ContestTopUser>>([]);

  useEffect(() => {
    Promise.all([
      dataProvider.getContestTops<ContestTops>(id),
      dataProvider.getContestTopUsers<Array<ContestTopUser>>(id)
    ])
      .then(([tops, users]) => {
        if (tops && tops.solutions && tops.solutions[0]) {
          setTopSolution(tops.solutions[0]);
        }
        if (tops && tops.applications && tops.applications[0]) {
          setTopApplication(tops.applications[0]);
        }
        if (users && users.length) {
          setTopUsers(users);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const onSolveClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      router.push(`/contests/${key || id}`);
    },
    [router, id, key]
  );

  let rootClassName = classes.root;
  if (className) {
    rootClassName += ' ' + className;
  }

  let solutionImgUrl = getPlaceholderImage(placeholderType.S);
  let solutionImgAlt = 'solution image';
  let solutionOwnerName = '';
  let applicationImgUrl = getPlaceholderImage(placeholderType.A);
  let applicationImgAlt = 'invention image';
  let applicationOwnerName = '';

  if (topSolution) {
    solutionOwnerName = topSolution.ownerData
      ? topSolution.ownerData.username || topSolution.ownerData.email
      : '';
    if (topSolution.files && topSolution.files[0]) {
      solutionImgUrl = topSolution.files[0].url;
      solutionImgAlt = getFileTitle(topSolution.files[0].title);
    }
  }

  if (topApplication) {
    applicationOwnerName = topApplication.ownerData
      ? topApplication.ownerData.username || topApplication.ownerData.email
      : '';
    if (topApplication.files && topApplication.files[0]) {
      applicationImgUrl = topApplication.files[0].url;
      applicationImgAlt = getFileTitle(topApplication.files[0].title);
    }
  }

  return (
    <div className={rootClassName}>
      <div className={classes.header}>
        <Link to={`/contests/${key || id}`}>
          <a className={classes.title}>{title}</a>
        </Link>
        <Duration startDate={startDate} endDate={endDate} />
      </div>
      <div className={classes.row}>
        {type !== 'active' ? (
          <div className={classes.cell}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <PsLogo size={55} color="#632DDD" small />
              </Grid>
              <Grid item xs={9} className={classes.cellText}>
                +{bountyCoins} Jackpot <br />
                IdeaCoins
              </Grid>
            </Grid>
          </div>
        ) : (
          <div className={classes.cell}>
            <PsButton
              onClick={onSolveClick}
              className={classes.solveBtn}
              color="secondary"
              coins={
                <span className="btn-text">
                  +{bountyCoins} IdeaCoins <br />
                  Jackpot
                </span>
              }
              smallest
            >
              SOLVE
            </PsButton>
          </div>
        )}
        {topApplication ? (
          <div className={classes.cell}>
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.cellTitle}>
                <Link
                  to={`/applications/${
                    topApplication.key || topApplication.id
                  }`}
                >
                  Leading Invention
                </Link>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Link
                  to={`/applications/${
                    topApplication.key || topApplication.id
                  }`}
                >
                  <a className={classes.photo}>
                    <img
                      className={classes.userPhoto}
                      src={applicationImgUrl}
                      alt={applicationImgAlt}
                    />
                  </a>
                </Link>
              </Grid>
              <Grid item xs={9} className={classes.cellText}>
                {topApplication.title} <br /> By {applicationOwnerName}
              </Grid>
            </Grid>
          </div>
        ) : null}
        {topSolution ? (
          <div className={classes.cell}>
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.cellTitle}>
                <Link to={`/solutions/${topSolution.key || topSolution.id}`}>
                  Leading Solution
                </Link>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Link to={`/solutions/${topSolution.key || topSolution.id}`}>
                  <a className={classes.photo}>
                    <img
                      className={classes.userPhoto}
                      src={solutionImgUrl}
                      alt={solutionImgAlt}
                    />
                  </a>
                </Link>
              </Grid>
              <Grid item xs={9} className={classes.cellText}>
                {topSolution.title} <br /> By {solutionOwnerName}
              </Grid>
            </Grid>
          </div>
        ) : null}
      </div>
      <Contributors className={classes.users} users={topUsers} />
    </div>
  );
};

export const CardContestSmall = styled(CardContestSmallView)({});

export default CardContestSmall;
