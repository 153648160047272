import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useDispatch } from 'react-redux';
import { Inventory } from '@mui/icons-material';
import { Box, IconButton, LinearProgress } from '@mui/material';
import SolutionIcon from 'assets/icons/solutions.svg';
import userIcon from 'assets/images/user_67x65.jpeg';
import ArchiveToggleButton from 'components/ArchiveToggleButton';
import AuthContext from 'contexts/AuthContext';
import { ModalComponentsKeys } from 'contexts/ModalContext';
import { Node } from 'interface/common';
import AddToConceptModal from 'modals/AddToConceptModal';
import { AddToTypo } from 'components/common/StyledComponents';
import Actions from 'redux-state/actions';
import {
  GetConceptData,
  GetProfilePinnedSolutions,
  GetProfileSolutions,
  GetProfileSolutionsCount,
  GetProfileSolutionsLoader,
  GetUser
} from 'redux-state/selectors';
import { Constants } from 'utilities/constants';
import { Problem } from '../CardProblem';
import { Profile } from '../CardProfile';
import { Solution } from '../CardSolution';
import { TreeIconWithPlus } from '../ConceptsButton/TreeIconWithPlus';
import { CustomMenu } from '../CustomMenu';
import { GetActions } from '../CustomMenu/actions';
import DataNotFound from '../DataNotFound';
import { ImageView } from '../ImageView';
import { ListItemView } from '../ListItemView';
import TableView from '../TableView';
import { getHeaders } from '../TableView/getHeaders';
import { ToolTip } from '../ToolTip';
import { ArrowIconButton } from '../common/ArrowIcon';
import { AddToBut, ButtonContainer, StyledBox, StyledMainBox } from './styles';

interface ItemListProps {
  profile: Profile;
  archiveStatus: boolean;
  handleArchiveToggle: (archive: boolean) => void;
}

interface ListItemViewProps {
  expandedId: string;
  id: string;
  problems: Array<Problem>;
  setExpandedId: React.Dispatch<React.SetStateAction<boolean>>;
}

const SolutionsView: React.FC<ItemListProps> = ({
  profile,
  archiveStatus,
  handleArchiveToggle
}) => {
  const user = GetUser();
  const dispatch = useDispatch();
  const conceptData = GetConceptData();
  const [expandedId, setExpandedId] = useState();
  const [expandedIcon, setExpandedIcon] = useState<boolean>(true);
  const [isAddToConceptModalOpen, setisAddToConceptModalOpen] =
    useState<boolean>(false);
  const [selectedNode, setSelectedNode] = useState<Node | null>(null);
  const activeConcept = user?.activeConcept || {};

  const isCurrentConceptSolution = useMemo(
    () => (id) => {
      const activeConceptData = conceptData?.data?.find(
        (concept) => concept?.id === activeConcept?.id
      );
      if (!activeConceptData) return false;
      return activeConceptData?.selected?.includes(id);
    },
    [activeConcept?.id, conceptData?.data]
  );

  const handleDrawer = useCallback(() => {
    dispatch(Actions.toggleConceptCart(true));
  }, [dispatch]);

  const toggleExpandText = (id, expandedIcon) => {
    setExpandedId((prevId) => (prevId !== id ? id : null));
    setExpandedIcon(!expandedIcon);
  };

  const handleClickButton = (item) => {
    setSelectedNode(item);
    setisAddToConceptModalOpen(!isAddToConceptModalOpen);
  };

  const closeAddToConceptModal = useCallback(() => {
    setisAddToConceptModalOpen(false);
  }, []);

  const solutions = GetProfileSolutions();
  const pinnedSolutions = GetProfilePinnedSolutions();
  const solutionLoader = GetProfileSolutionsLoader();
  const solutionsCount = GetProfileSolutionsCount();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const pagination = useMemo(
    () => ({
      page: page,
      perPage: rowsPerPage
    }),
    [page, rowsPerPage]
  );

  const isCurrentUser = !!(user && (user.id === profile.id || user.isAdmin));
  const hasEmptyData = !!(solutionsCount === 0 && pinnedSolutions.length === 0);

  useEffect(() => {
    if (profile) {
      dispatch(
        Actions.getProfileSolutions(profile.id, pagination, null, archiveStatus)
      );
    }
  }, [dispatch, pagination, profile, archiveStatus]);

  useEffect(() => {
    if (solutions.length < 1 || archiveStatus) {
      setPage(0);
    }
  }, [solutions, archiveStatus]);

  const Item = useCallback(
    ({ problems, id, expandedId, setExpandedId }: ListItemViewProps) => {
      return (
        <ListItemView
          expandedId={expandedId}
          id={id}
          problems={problems}
          setExpandedId={setExpandedId}
          type={Constants.PROBLEMS}
          useSameTab={true}
        />
      );
    },
    []
  );

  const headers: Array<string> = useMemo(() => {
    return getHeaders('Solution', user, isCurrentUser);
  }, [isCurrentUser, user]);

  const getActions = useMemo(() => {
    return (item: Solution) => {
      return GetActions({
        item,
        profile,
        type: 'solutions',
        pagination
      });
    };
  }, [pagination, profile]);

  const handleUnarchiveToggle = useCallback(
    (item) => {
      if (user) {
        dispatch(
          Actions.setArchiveItem(
            item._id,
            'solutions',
            false,
            profile.id,
            false,
            pagination
          )
        );
      } else {
        dispatch(Actions.openAuthModal(true));
      }
    },
    [user, dispatch, profile, pagination]
  );

  const getCustomItem = (item) => {
    return Array.isArray(item.problem) ? item.problem : [item.problem];
  };

  const getCells = useMemo(() => {
    return (icon, item) => {
      const baseCells = {
        icon: (
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => toggleExpandText(item._id, expandedIcon)}
          >
            <ArrowIconButton
              expandedId={expandedId}
              id={item._id}
            ></ArrowIconButton>
          </IconButton>
        ),
        item: (
          <ImageView
            iconImage={icon}
            id={item.key}
            placeholderImage={item.files[0]?.url ?? userIcon}
            title={item.teaser}
            type={Constants.SOLUTIONS}
            useSameTab={true}
          />
        ),
        button: user && (
          <AddToBut
            onClick={() => handleClickButton(item)}
            variant="contained"
            disabled={isCurrentConceptSolution(item._id)}
            isCurrentConceptSolution={isCurrentConceptSolution(item._id)}
          >
            <TreeIconWithPlus height={22} sx={{ marginRight: '0.625rem' }} />
            <AddToTypo>{Constants.ADD_TO_CONCEPT}</AddToTypo>
          </AddToBut>
        )
      };
      if (isCurrentUser) {
        baseCells['actions'] = item.isArchived ? (
          <Box onClick={() => handleUnarchiveToggle(item)}>
            <ToolTip
              color="primary"
              Icon={<Inventory color="primary" />}
              title="Unarchive"
            />
          </Box>
        ) : (
          <CustomMenu
            actions={getActions(item)}
            item={item}
            itemType={'solutions'}
            title="Solution"
            pagination={pagination}
          />
        );
      }

      return baseCells;
    };
  }, [
    expandedId,
    Item,
    isCurrentUser,
    expandedIcon,
    getActions,
    pagination,
    handleUnarchiveToggle
  ]);

  const rows = {
    component: (icon, item) => getCells(icon, item),
    items: solutions,
    pinnedItems: pinnedSolutions
  };

  return (
    <StyledMainBox>
      <ButtonContainer>
        <ArchiveToggleButton
          archiveStatus={archiveStatus}
          handleArchiveToggle={handleArchiveToggle}
        />
      </ButtonContainer>
      <StyledBox>
        {solutionLoader && <LinearProgress value={solutionsCount} />}
        {!solutionLoader && hasEmptyData && (
          <DataNotFound
            iconUrl={SolutionIcon}
            modal={ModalComponentsKeys.addNewSolution}
            text={Constants.SOLUTION}
          />
        )}
        {!hasEmptyData && (
          <TableView
            expandedId={expandedId}
            headers={headers}
            page={page}
            rows={rows}
            rowsPerPage={rowsPerPage}
            setExpandedId={setExpandedId}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            totalRows={solutionsCount - pinnedSolutions.length}
            type={'solutions'}
          />
        )}
        {isAddToConceptModalOpen && (
          <AddToConceptModal
            handleDrawer={handleDrawer}
            isFeed={false}
            node={selectedNode}
            onClose={closeAddToConceptModal}
            open={isAddToConceptModalOpen}
          />
        )}
      </StyledBox>
    </StyledMainBox>
  );
};

export { SolutionsView };
