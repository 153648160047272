import React, { useState, useCallback, useContext, useEffect } from 'react';

import { Modal } from 'modals/Modal';
import { Challenge } from 'components/CardChallenge';
import { PsFormControl, ShortEvent } from 'components/common/PsFormControl';
import { PsInput } from 'components/common/PsInput';
import { PsSelect, PsOptionType } from 'components/common/PsSelect';
import { PsSelectedList } from 'components/common/PsSelectedList';
import { PsButton, EditIcon } from 'components/common/PsButton';
import { useDropzone } from 'react-dropzone';
import { DataContext } from 'contexts/DataContext';
import { AuthContext } from 'contexts/AuthContext';
import {
  Picture,
  convertFileToBase64,
  getPlaceholderImage,
  getFileTitle,
  placeholderType,
  updateFileName
} from '../../helpers';
import { PsTheme } from '../../theme';
import { Tag } from '../CardTag';
import { Contest } from '../CardContest';
import { Profile } from '../CardProfile';
import PsInputLabel from '../common/PsInputLabel';
import { PsLogo } from '../common/PsLogo';
import { makeStyles } from 'tss-react/mui';
import useRouter from 'hooks/useRouter';
import { styled, useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import Slider from '@mui/material/Slider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const DEFAULT_IMAGE = {
  title: 'picture',
  contentType: 'image/svg+xml',
  url: getPlaceholderImage(placeholderType.A)
};

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {},
    columns: {
      display: 'flex',
      [psTheme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    },
    left: {
      width: '44%',
      flexShrink: 0,
      [psTheme.breakpoints.down('sm')]: {
        width: '100%',
        maxWidth: 700,
        margin: '0 auto'
      }
    },
    imageHolder: {
      '& .inner': {
        position: 'relative',
        paddingTop: '60%'
      },
      '& img': {
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'block',
        width: '100%',
        height: '100%',
        borderRadius: 10,
        objectFit: 'cover'
      }
    },
    dropzoneContainer: {
      padding: '10px 0',
      '& .link': {
        color: psTheme.palette.primary.main,
        cursor: 'pointer'
      },
      '& .link:hover': {
        textDecoration: 'underline'
      },
      '& .link svg': {
        marginRight: 5,
        marginBottom: -3
      }
    },
    pictureTitle: {
      paddingTop: 10,
      '& label': {
        fontSize: 16
      }
    },
    right: {
      paddingLeft: 25,
      flexGrow: 1,
      '& .MuiGrid-container > div': {
        marginTop: -20
      },
      [psTheme.breakpoints.down('sm')]: {
        paddingLeft: 0
      },
      [psTheme.breakpoints.down('xs')]: {
        paddingTop: 20,
        '& .MuiGrid-spacing-xs-2': {
          margin: '0 -8px'
        }
      }
    },
    labels: {
      marginBottom: 15
    },
    dateInput: {
      width: '100%',
      '& input': {
        padding: '13px 14px'
      },
      '& fieldset': {
        borderRadius: 8
      }
    },
    descrTitle: {
      marginBottom: '0 !important'
    },
    itemTitle: {
      '& > svg': {
        marginRight: 20,
        marginBottom: -8
      }
    },
    block: {
      marginTop: 25,
      marginBottom: 25,
      fontWeight: 'normal',
      '&:last-child': {
        marginBottom: 0
      },
      '& b': {
        fontWeight: 600
      }
    },
    center: {
      textAlign: 'center'
    },
    coinsLeft: {
      textAlign: 'center',
      '& svg': {
        marginBottom: -4,
        marginRight: 5
      }
    },
    coinsRight: {
      textAlign: 'center',
      '& svg': {
        marginBottom: -4,
        marginRight: 5
      }
    },
    buttons: {
      display: 'flex',
      '& > button': {
        width: '50%',
        marginBottom: 10
      },
      '& > span': {
        width: '50%'
      },
      '& > button:first-child': {
        marginRight: 70
      },
      [psTheme.breakpoints.down('xs')]: {
        flexDirection: 'column-reverse',
        '& > button': {
          width: '100%'
        },
        '& > button:first-child': {
          marginRight: 0
        }
      }
    }
  };
});

type ModalEditChallengeProps = {
  app: Challenge;
  open: boolean;
  onClose?: () => void;
  onUpdate?: (profile: Challenge) => void;
};

const ModalEditChallengeView = ({
  app,
  open,
  onClose,
  onUpdate
}: ModalEditChallengeProps) => {
  const { user } = useContext(AuthContext);
  const router = useRouter();
  const { classes } = useStyles();

  const initTitle = app.title || '';
  const initHeader = app.header || '';
  const initOwner = app.owner || '';
  const initTags = app.tags || [];
  const initContests = app.contests || [];
  const initStartDate = app.startDate ? new Date(app.startDate) : null;
  const initEndDate = app.endDate ? new Date(app.endDate) : null;
  const initInternalReward = app.internalReward || 30;
  const initExternalReward = app.externalReward || '';
  const initExternalPicture =
    app.files2 && app.files2.length ? app.files2[0] : DEFAULT_IMAGE;
  const initExternalPictureTitle =
    app.files2 && app.files2.length ? app.files2[0].title : '';
  const initPicture =
    app.files && app.files.length ? app.files[0] : DEFAULT_IMAGE;
  const initPictureTitle =
    app.files && app.files.length ? app.files[0].title : '';

  const { dataProvider } = useContext(DataContext);
  const [loading, setLoading] = useState(false);

  const [title, setTitle] = useState<string>(initTitle);
  const [header, setHeader] = useState<string>(initHeader);
  const [startDate, setStartDate] = useState<Date | null>(initStartDate);
  const [endDate, setEndDate] = useState<Date | null>(initEndDate);
  const [internalReward, setInternalReward] =
    useState<number>(initInternalReward);
  const [externalReward, setExternalReward] =
    useState<string>(initExternalReward);
  const [externalPicture, setExternalPicture] =
    useState<Picture>(initExternalPicture);
  const [externalPictureTitle, setExternalPictureTitle] = useState(
    initExternalPictureTitle
  );
  const [picture, setPicture] = useState<Picture>(initPicture);
  const [pictureTitle, setPictureTitle] = useState(initPictureTitle);

  const [tags, setTags] = useState<Array<PsOptionType>>([]);
  const [tagsValue, setTagsValue] = useState<string>(
    initTags ? initTags.join(',') : ''
  );

  const [owners, setOwners] = useState<Array<PsOptionType>>([]);
  const [ownerValue, setOwnerValue] = useState<string | number>(initOwner);

  const [contests, setContests] = useState<Array<PsOptionType>>([]);
  const [contestsValue, setContestsValue] = useState<string>(
    initContests ? initContests.join(',') : ''
  );

  const pictureDropzone = useDropzone({
    multiple: false,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/gif': ['.gif'],
      'image/svg+xml': ['.svg']
    }
  });
  const externalPictureDropzone = useDropzone({
    multiple: false,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/gif': ['.gif'],
      'image/svg+xml': ['.svg']
    }
  });

  useEffect(() => {
    const initTitle = app.title || '';
    const initHeader = app.header || '';
    const initOwner = app.owner || '';
    const initTags = app.tags || [];
    const initContests = app.contests || [];
    const initStartDate = app.startDate ? new Date(app.startDate) : null;
    const initEndDate = app.endDate ? new Date(app.endDate) : null;
    const initInternalReward = app.internalReward || 30;
    const initExternalReward = app.externalReward || '';
    const initExternalPicture =
      app.files2 && app.files2.length ? app.files2[0] : DEFAULT_IMAGE;
    const initExternalPictureTitle =
      app.files2 && app.files2.length ? app.files2[0].title : '';
    const initPicture =
      app.files && app.files.length ? app.files[0] : DEFAULT_IMAGE;
    const initPictureTitle =
      app.files && app.files.length ? app.files[0].title : '';

    setTitle(initTitle);
    setHeader(initHeader);
    setOwnerValue(initOwner);
    setTagsValue(initTags ? initTags.join(',') : '');
    setContestsValue(initContests ? initContests.join(',') : '');
    setStartDate(initStartDate || null);
    setEndDate(initEndDate || null);
    setInternalReward(initInternalReward);
    setExternalReward(initExternalReward);
    setExternalPicture(initExternalPicture);
    setExternalPictureTitle(initExternalPictureTitle);
    setPicture(initPicture);
    setPictureTitle(initPictureTitle);

    dataProvider
      .getList<Tag>('tags', {
        pagination: { page: 1, perPage: 50000 },
        sort: { field: 'name', order: 'ASC' },
        filter: { $custom: { type: 'forChallenge' } }
      })
      .then(({ data = [] }) => {
        const list: Array<PsOptionType> = data.map((item: Tag) => ({
          value: `${item.id}`,
          label: item.name || '(empty)'
        }));
        setTags(list);
      })
      .catch(() => {
        setTags([]);
      });

    dataProvider
      .getList<Contest>('contests', {
        pagination: { page: 1, perPage: 50000 },
        sort: { field: 'name', order: 'ASC' },
        filter: {}
      })
      .then(({ data = [] }) => {
        const list: Array<PsOptionType> = data.map((item: Contest) => ({
          value: `${item.id}`,
          label: item.title || '(empty)'
        }));
        setContests(list);
      })
      .catch(() => {
        setContests([]);
      });

    dataProvider
      .getList<Profile>('profiles', {
        pagination: { page: 1, perPage: 50000 },
        sort: { field: 'username', order: 'ASC' },
        filter: {}
      })
      .then(({ data = [] }) => {
        const list: Array<PsOptionType> = data.map((item: Profile) => ({
          value: `${item.id}`,
          label: item.username || '(empty)'
        }));
        setOwners(list);
      })
      .catch(() => {
        setOwners([]);
      });
  }, [app, dataProvider]);

  useEffect(() => {
    if (!externalPictureDropzone.acceptedFiles.length) {
      return;
    }
    Promise.all(
      externalPictureDropzone.acceptedFiles.map(convertFileToBase64)
    ).then(([item]) => {
      const { file, base64 } = item;
      const image = {
        contentType: file.type,
        title: file.name,
        url: base64
      };
      setExternalPicture(image);
      setExternalPictureTitle(getFileTitle(file.name));
    });
  }, [externalPictureDropzone.acceptedFiles]);

  useEffect(() => {
    if (!pictureDropzone.acceptedFiles.length) {
      return;
    }
    Promise.all(pictureDropzone.acceptedFiles.map(convertFileToBase64)).then(
      ([item]) => {
        const { file, base64 } = item;
        const image = {
          contentType: file.type,
          title: file.name,
          url: base64
        };
        setPicture(image);
        setPictureTitle(getFileTitle(file.name));
      }
    );
  }, [pictureDropzone.acceptedFiles]);

  const onDelete = () => {
    setLoading(true);

    dataProvider
      .delete<Challenge>('challenges', { id: app.id })
      .then(() => {
        router.push('/challenges');
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const onCancel = () => {
    if (onClose) {
      onClose();
    }
    setTitle(initTitle);
    setHeader(initHeader);
    setOwnerValue(initOwner);
    setTagsValue(initTags ? initTags.join(',') : '');
    setContestsValue(initContests ? initContests.join(',') : '');
    setStartDate(initStartDate || null);
    setEndDate(initEndDate || null);
    setInternalReward(initInternalReward);
    setExternalReward(initExternalReward);
    setExternalPicture(initExternalPicture);
    setExternalPictureTitle(initExternalPictureTitle);
    setPicture(initPicture);
    setPictureTitle(initPictureTitle);
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (loading) {
      return;
    }

    const files: Array<Picture> = [];
    if (picture !== DEFAULT_IMAGE) {
      files.push(picture);
    }
    const files2: Array<Picture> = [];
    if (externalPicture !== DEFAULT_IMAGE) {
      files2.push(externalPicture);
    }

    const challengeInfo = {
      title,
      header,
      files,
      files2,
      owner: ownerValue || '',
      tags: tagsValue.split(','),
      contests: contestsValue.split(','),
      startDate: startDate ? startDate.toISOString() : '',
      endDate: endDate ? endDate.toISOString() : '',
      internalReward: internalReward || 30,
      externalReward: externalReward || ''
    };

    setLoading(true);

    dataProvider
      .update<Challenge>('challenges', { id: app.id, data: challengeInfo })
      .then(({ data }) => {
        if (onUpdate) {
          onUpdate(data);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onTitleChange = useCallback(
    (e: ShortEvent) => {
      setTitle(e.target.value);
    },
    [setTitle]
  );

  const onHeaderChange = useCallback(
    (e: ShortEvent) => {
      setHeader(e.target.value);
    },
    [setHeader]
  );

  const onTagsChange = useCallback(
    (event: ShortEvent) => {
      const { value } = event.target;
      setTagsValue(value);
    },
    [setTagsValue]
  );

  const onContestsChange = useCallback(
    (event: ShortEvent) => {
      const { value } = event.target;
      setContestsValue(value);
    },
    [setContestsValue]
  );

  const onOwnerChange = useCallback(
    (event: ShortEvent) => {
      const { value } = event.target;
      setOwnerValue(value);
    },
    [setOwnerValue]
  );

  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
  };

  const onInternalRewardChange = (event: Event, value: number | number[]) => {
    setInternalReward(value as number);
  };

  const onExternalRewardChange = useCallback(
    (e: ShortEvent) => {
      setExternalReward(e.target.value);
    },
    [setExternalReward]
  );

  const onExternalPictureTitleChange = (e: ShortEvent) => {
    const newTitle = e.target.value;
    setExternalPictureTitle(newTitle);
    if (externalPicture) {
      const newFileName = updateFileName(externalPicture.title, newTitle);
      setPicture({
        ...externalPicture,
        title: newFileName
      });
    }
  };

  const onPictureTitleChange = (e: ShortEvent) => {
    const newTitle = e.target.value;
    setPictureTitle(newTitle);
    if (picture) {
      const newFileName = updateFileName(picture.title, newTitle);
      setPicture({
        ...picture,
        title: newFileName
      });
    }
  };

  return (
    <Modal open={open} onClose={onCancel} title="Edit challenge information">
      <form onSubmit={onSubmit}>
        <div className={classes.columns}>
          <div className={classes.left}>
            <PsFormControl
              placeholder="Type your challenge header here"
              label="Enter Challenge Header"
            >
              <PsInput
                name="titleProblem"
                onChange={onHeaderChange}
                value={header}
              />
            </PsFormControl>

            <PsFormControl
              placeholder="Type your challenge name here"
              label="Enter Challenge Name"
            >
              <PsInput name="title" value={title} onChange={onTitleChange} />
            </PsFormControl>

            <PsFormControl
              placeholder="Select appropriate groups for the contest"
              label="Select groups"
            >
              <PsSelect
                name="tags"
                isMulti
                maxMenuHeight={270}
                onChange={onTagsChange}
                options={tags}
                value={tagsValue}
              />
            </PsFormControl>
            <PsSelectedList
              options={tags}
              value={tagsValue}
              onChange={onTagsChange}
            />

            <PsFormControl
              placeholder="Select appropriate contests"
              label="Select contests"
            >
              <PsSelect
                name="tagsProblem"
                isMulti
                maxMenuHeight={270}
                onChange={onContestsChange}
                options={contests}
                value={contestsValue}
              />
            </PsFormControl>
            <PsSelectedList
              options={contests}
              value={contestsValue}
              onChange={onContestsChange}
            />

            <div className={classes.imageHolder}>
              <div className="inner">
                <img src={picture.url} alt={getFileTitle(picture.title)} />
              </div>
            </div>
            <div
              {...pictureDropzone.getRootProps({
                className: classes.dropzoneContainer
              })}
            >
              <input name="files" {...pictureDropzone.getInputProps()} />
              <span className="link">
                <EditIcon />
                Edit Challenge image
              </span>
            </div>

            <PsFormControl
              className={classes.pictureTitle}
              label="Challenge image title"
              labelLight
            >
              <PsInput
                name="pictureTitle"
                onChange={onPictureTitleChange}
                value={pictureTitle}
              />
            </PsFormControl>

            {user && user.isAdmin ? (
              <PsFormControl
                placeholder="Select appropriate challenge owner"
                label="Select challenge owner"
              >
                <PsSelect
                  name="tags"
                  maxMenuHeight={270}
                  onChange={onOwnerChange}
                  options={owners}
                  value={`${ownerValue}`}
                />
              </PsFormControl>
            ) : null}
          </div>

          <div className={classes.right}>
            <PsInputLabel
              className={classes.labels}
              label="Whats Challenge Length"
            />
            <Grid container spacing={4}>
              <Grid item xs={6} sm={5}>
                <DatePicker
                  className={classes.dateInput}
                  // disableToolbar
                  // variant="inline"
                  format="MMM dd, yyyy"
                  // margin="normal"
                  // id="date-start"
                  // placeholder="Enter Start Date"
                  value={startDate}
                  onChange={handleStartDateChange}
                  // autoOk={true}
                  // disablePast={true}
                  // inputVariant="outlined"
                  // helperText="Start Date"
                />
              </Grid>
              <Hidden xsDown={true}>
                <Grid item sm={2}></Grid>
              </Hidden>
              <Grid item xs={6} sm={5}>
                <DatePicker
                  className={classes.dateInput}
                  // disableToolbar
                  // variant="inline"
                  format="MMM dd, yyyy"
                  // margin="normal"
                  // id="date-end"
                  // placeholder="Enter End Date"
                  value={endDate}
                  onChange={handleEndDateChange}
                  // autoOk={true}
                  // disablePast={true}
                  // inputVariant="outlined"
                  // helperText="End Date"
                />
              </Grid>
            </Grid>

            <PsInputLabel
              className={classes.labels}
              label="Choose Challenge Jackpot"
            />
            <div className={classes.block}>
              <Grid container spacing={4}>
                <Grid item xs={12} className={classes.center}>
                  <b>{internalReward} Coins</b>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={3} className={classes.coinsLeft}>
                  <PsLogo size={20} color="#632DDD" small /> <b>10</b> Coins
                </Grid>
                <Grid item xs={6}>
                  <Slider
                    defaultValue={initInternalReward}
                    min={10}
                    max={100}
                    step={10}
                    onChange={onInternalRewardChange}
                  />
                </Grid>
                <Grid item xs={3} className={classes.coinsRight}>
                  <PsLogo size={20} color="#632DDD" small /> <b>100</b> Coins
                </Grid>
              </Grid>
            </div>

            <PsFormControl
              placeholder="Type reward title here"
              label="External reward title"
              helperText={`Example: Samsung 65" TV.`}
            >
              <PsInput
                name="description"
                value={externalReward}
                onChange={onExternalRewardChange}
              />
            </PsFormControl>

            <div className={classes.imageHolder}>
              <div className="inner">
                <img
                  src={externalPicture.url}
                  alt={getFileTitle(externalPicture.title)}
                />
              </div>
            </div>
            <div
              {...externalPictureDropzone.getRootProps({
                className: classes.dropzoneContainer
              })}
            >
              <input
                name="files"
                {...externalPictureDropzone.getInputProps()}
              />
              <span className="link">
                <EditIcon />
                Edit External reward image
              </span>
            </div>

            <PsFormControl
              className={classes.pictureTitle}
              label="External reward image title"
              labelLight
            >
              <PsInput
                name="pictureTitle"
                value={externalPictureTitle}
                onChange={onExternalPictureTitleChange}
              />
            </PsFormControl>

            <div className={classes.buttons}>
              <PsButton
                disabled={loading}
                color="secondary"
                onClick={onCancel}
                cancel
                small
              >
                Cancel
              </PsButton>
              <PsButton
                disabled={loading}
                color="secondary"
                type="submit"
                small
              >
                Save changes
              </PsButton>
            </div>

            <div className={classes.buttons}>
              <PsButton
                disabled={loading}
                color="secondary"
                onClick={onDelete}
                cancel
                small
              >
                Delete
              </PsButton>
              <span></span>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export const ModalEditChallenge = styled(ModalEditChallengeView)({});

export default ModalEditChallenge;
