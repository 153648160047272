import React from 'react';
import { Box } from '@mui/material';
import { Constants } from 'utilities/constants';
import { Node } from 'interface/common';
import {
  FooterImageContainer,
  StyledHeading,
  StyledOwnerImageBottom
} from './StyledComponents';

interface FooterProps {
  item?: Node;
}

const Footer: React.FC<FooterProps> = ({ item }) => {
  const solutionCreators =
    item?.children
      ?.map((child) => child.ownerImage)
      .filter((value, index, self) => self.indexOf(value) === index) || [];

  return (
    <Box>
      {solutionCreators.length > 0 && (
        <>
          <StyledHeading>
            {item?.type === Constants.PROBLEM
              ? Constants.SOLUTION_CREATORS
              : item?.type === Constants.APPLICATION
                ? Constants.SOLUTION_CONTRIBUTOR
                : item?.type === Constants.SOLUTION
                  ? Constants.CONTRIBUTORS
                  : item?.type === Constants.PRODUCT
                    ? Constants.CREATORS
                    : ''}
          </StyledHeading>
          <FooterImageContainer>
            {solutionCreators.map((ownerImage) => (
              <StyledOwnerImageBottom key={ownerImage} src={ownerImage} />
            ))}
          </FooterImageContainer>
        </>
      )}
    </Box>
  );
};
export default Footer;
