import React from 'react';
import { Constants } from 'utilities/constants';
import { RenderActionButton } from 'components/common/RenderActionButton';

interface FooterProps {
  onAction: () => void;
}
export const Footer: React.FC<FooterProps> = ({ onAction }) => {
  return <RenderActionButton onClick={onAction} itemType={Constants.CREATE} />;
};
