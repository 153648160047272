import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useDispatch } from 'react-redux';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { CustomTextField } from 'components/common/inputs/CustomTextField';
import { validateEmail } from 'helpers/validateEmail';
import useRouter from 'hooks/useRouter';
import Actions from 'redux-state/actions';
import { GetErrorMessage, GetProfileStatusCode } from 'redux-state/selectors';
import { colorPalette } from 'theme';
import {
  Constants,
  ERRORS,
  FORM_VIEW,
  INPUT_TYPES,
  VARIANT
} from 'utilities/constants';
import { SocialIconsView } from './SocialIconsView';
import {
  ActionButton,
  ButtonTypography,
  ForgetPassText,
  InfoTypography,
  LinkWrapper,
  MainBox,
  TitleTypography
} from './styledComponents';

interface LoginViewProps {
  setFormView: Dispatch<SetStateAction<string>>;
}

type FieldValidityType = {
  email: boolean;
  password: boolean;
};

export const LoginView: React.FC<LoginViewProps> = ({ setFormView }) => {
  const dispatch = useDispatch();
  const router = useRouter();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fieldsValidity, setFieldsValidity] = useState<FieldValidityType>({
    email: true,
    password: true
  });

  const statusCode = GetProfileStatusCode();
  const errorMessage = GetErrorMessage();

  const validateInputs = useCallback(() => {
    dispatch(Actions.updateProfileErrorStatus(null, ''));
    const passwordIsEmpty = password === '';
    const emailIsValid = validateEmail(email);

    setFieldsValidity({
      password: !passwordIsEmpty,
      email: emailIsValid
    });
    return !passwordIsEmpty && emailIsValid;
  }, [dispatch, email, password]);

  const onEmailChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setEmail(value);
    },
    [setEmail]
  );

  const onPasswordChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setPassword(value);
    },
    [setPassword]
  );

  const getEmailHelperText = useCallback(() => {
    if (!fieldsValidity.email) {
      dispatch(Actions.updateErrorStatus(null, ''));
      return ERRORS.INVALID_EMAIL;
    } else if (statusCode == 409) {
      return errorMessage;
    }
    return '';
  }, [dispatch, errorMessage, fieldsValidity, statusCode]);

  const getErrorText = useCallback(() => {
    if (statusCode == 401) {
      return errorMessage;
    } else if (statusCode != 200 && errorMessage) {
      console.log('hehe', errorMessage);
      return ERRORS.UNEXPECTED_ERROR;
    }
    return '';
  }, [errorMessage, statusCode]);

  const onLoginClick = useCallback(() => {
    if (validateInputs()) {
      dispatch(Actions.logIn({ email, password }));
    }
  }, [dispatch, email, password, router, validateInputs]);

  const onForgetPasswordClick = useCallback(() => {
    setFormView(FORM_VIEW.RESET_PASSWORD);
  }, [setFormView]);

  const onSignUpClick = useCallback(() => {
    setFormView(FORM_VIEW.SIGN_UP);
  }, [setFormView]);

  const { emailHelperText, errorText } = useMemo(() => {
    const emailHelperText = getEmailHelperText();
    const errorText = getErrorText();
    return { emailHelperText, errorText };
  }, [getEmailHelperText, getErrorText]);

  return (
    <MainBox>
      <TitleTypography>{Constants.LOGIN}</TitleTypography>
      <CustomTextField
        borderColorOnHover={colorPalette.purple}
        error={!fieldsValidity.email || !!errorText}
        fillColor={colorPalette.lightLavender}
        fullWidth
        helperText={emailHelperText}
        Icon={PersonOutlineOutlinedIcon}
        onChange={onEmailChange}
        placeholder={Constants.USERNAME_EMAIL}
        type={INPUT_TYPES.EMAIL}
        value={email}
      />
      <CustomTextField
        borderColorOnHover={colorPalette.purple}
        error={!fieldsValidity.password || !!errorText}
        fillColor={colorPalette.lightLavender}
        fullWidth
        helperText={
          !fieldsValidity.password ? ERRORS.EMPTY_PASSWORD : errorText
        }
        Icon={LockOutlinedIcon}
        onChange={onPasswordChange}
        placeholder={Constants.C_PASSWORD}
        type={INPUT_TYPES.PASSWORD}
        value={password}
      />
      <ForgetPassText onClick={onForgetPasswordClick}>
        {Constants.FORGOT_PASSWORD}
      </ForgetPassText>
      <ActionButton
        isSignUp={true}
        onClick={onLoginClick}
        variant={VARIANT.CONTAINED}
      >
        <ButtonTypography>{Constants.LOGIN}</ButtonTypography>
      </ActionButton>
      <InfoTypography>{Constants.LOGIN_SOCIAL}</InfoTypography>
      <SocialIconsView />
      <InfoTypography>
        {Constants.DONT_HAVE_ACOUNT}{' '}
        <LinkWrapper onClick={onSignUpClick}>{Constants.SIGN_UP}</LinkWrapper>
      </InfoTypography>
    </MainBox>
  );
};
