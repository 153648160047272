import React, { useContext, useState, useCallback, useEffect } from 'react';
import { PsFormControl, ShortEvent } from 'components/common/PsFormControl';
import { PsGroup, PsOptionType, PsSelect } from 'components/common/PsSelect';
import { PsSelectedList } from 'components/common/PsSelectedList';
import { Tag } from 'components/CardTag';
import { AuthContext } from 'contexts/AuthContext';
import { ModalDataContext } from 'contexts/ModalDataContext';
import { DataContext } from 'contexts/DataContext';
import styles from 'modals/ModalWindow.module.scss';

const TagsStep: React.FC = () => {
  const { user } = useContext(AuthContext);
  const { values, updateField } = useContext(ModalDataContext);
  const { dataProvider } = useContext(DataContext);
  const [tags, setTags] = useState<Array<PsOptionType>>([]);

  const initTags = values.tags || (user ? user.tags || [] : []);
  const initLocation =
    values.parentProblem || (user ? user.location || '' : '');
  const initSchool = values.parentSolution || (user ? user.school || '' : '');
  const initWorkplace =
    values.mainProblem || (user ? user.workplace || '' : '');

  const [tagsValue, setTagsValue] = useState<string>(initTags.join(','));
  const [location, setLocation] = useState<string>(initLocation);
  const [school, setSchool] = useState<string>(initSchool);
  const [workplace, setWorkplace] = useState<string>(initWorkplace);

  useEffect(() => {
    dataProvider
      .getList<Tag>('tags', {
        pagination: { page: 1, perPage: 50000 },
        sort: { field: 'name', order: 'ASC' },
        filter: { $custom: { type: 'simple' } }
      })
      .then(({ data }) => {
        const tags: Array<PsOptionType> = data.map((tag) => ({
          label: `${tag.name}`,
          value: `${tag.id}`
        }));
        setTags(tags);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [dataProvider]);

  useEffect(() => {
    const initTags = values.tags || (user ? user.tags || [] : []);
    const initLocation =
      values.parentProblem || (user ? user.location || '' : '');
    const initSchool = values.parentSolution || (user ? user.school || '' : '');
    const initWorkplace =
      values.mainProblem || (user ? user.workplace || '' : '');

    setTagsValue(initTags.join(','));
    updateField('tags', initTags);
    setLocation(initLocation);
    updateField('parentProblem', initLocation);
    setSchool(initSchool);
    updateField('parentSolution', initSchool);
    setWorkplace(initWorkplace);
    updateField('mainProblem', initWorkplace);
  }, [
    updateField,
    user,
    values.mainProblem,
    values.parentProblem,
    values.parentSolution,
    values.tags
  ]);

  const locationDataLoader = (
    query: string
  ): Promise<readonly (PsOptionType | PsGroup<PsOptionType>)[]> => {
    return dataProvider.getLocationList(query);
  };

  const universityDataLoader = (
    query: string
  ): Promise<readonly (PsOptionType | PsGroup<PsOptionType>)[]> => {
    return dataProvider.getUniversityList(query);
  };

  const workplaceDataLoader = (
    query: string
  ): Promise<readonly (PsOptionType | PsGroup<PsOptionType>)[]> => {
    return dataProvider.getWorkplaceList(query);
  };

  const onTagsChange = useCallback(
    (event: ShortEvent) => {
      const { value } = event.target;
      setTagsValue(value);
      updateField('tags', value.split(','));
    },
    [setTagsValue, updateField]
  );

  const onLocationChange = useCallback(
    (e: ShortEvent) => {
      const { value, rawValue } = e.target;
      setLocation(value);
      updateField('parentProblem', value);
      updateField('parentProblemLabel', rawValue || '');
    },
    [setLocation, updateField]
  );

  const onSchoolChange = useCallback(
    (e: ShortEvent) => {
      const { value, rawValue } = e.target;
      setSchool(value);
      updateField('parentSolution', value);
      updateField('parentSolutionLabel', rawValue || '');
    },
    [setSchool, updateField]
  );

  const onwWorkplaceChange = useCallback(
    (e: ShortEvent) => {
      const { value, rawValue } = e.target;
      setWorkplace(value);
      updateField('mainProblem', value);
      updateField('mainProblemLabel', rawValue || '');
    },
    [setWorkplace, updateField]
  );

  return (
    <div className={styles.stepWrapper}>
      {/*<Typography className={styles.text}>Tag Yourself:</Typography>*/}

      <PsFormControl
        placeholder="Choose one or more tags"
        label="Interests: Add tags to see idea opportunities that may interest you in your feed"
        labelLight
      >
        <PsSelect
          name="tags"
          onChange={onTagsChange}
          options={tags}
          maxMenuHeight={270}
          isMulti
          value={tagsValue}
        />
      </PsFormControl>
      <PsSelectedList
        options={tags}
        value={tagsValue}
        onChange={onTagsChange}
      />

      <PsFormControl
        label="My location: Add tags to see idea opportunities from where you live in your feed"
        placeholder="type to search..."
        labelLight
      >
        <PsSelect
          name="location"
          onChange={onLocationChange}
          dataLoader={locationDataLoader}
          maxMenuHeight={270}
          value={location}
        />
      </PsFormControl>

      <PsFormControl
        label="My school: Add tags to see idea opportunities from your school in your feed"
        placeholder="type to search..."
        labelLight
      >
        <PsSelect
          name="school"
          onChange={onSchoolChange}
          dataLoader={universityDataLoader}
          maxMenuHeight={270}
          value={school}
        />
      </PsFormControl>

      <PsFormControl
        label="My Workplace: Add tags to see idea opportunities from where you work in your feed"
        placeholder="type to search..."
        labelLight
      >
        <PsSelect
          name="workplace"
          dataLoader={workplaceDataLoader}
          maxMenuHeight={270}
          onChange={onwWorkplaceChange}
          placeholder="type to search"
          value={workplace}
        />
      </PsFormControl>
    </div>
  );
};

export default TagsStep;
