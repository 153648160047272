import React from 'react';
export const UploadIcon = ({
  color = '',
  className
}: {
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="11.166"
      height="13.559"
      viewBox="0 0 11.166 13.559"
    >
      <path
        id="Icon_material-file-upload"
        data-name="Icon material-file-upload"
        d="M10.69,14.868h4.785V10.083h3.19L13.083,4.5,7.5,10.083h3.19Zm-3.19,1.6H18.666v1.6H7.5Z"
        transform="translate(-7.5 -4.5)"
        fill="#632ddd"
      />
    </svg>
  );
};
