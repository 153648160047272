import React, { useState, useEffect } from 'react';
import { Box, Typography, styled } from '@mui/material';
import moment from 'moment';
import { colorPalette } from 'theme';
import { Constants } from 'utilities/constants';

interface CountdownMonthsProps {
  endTime: string;
  setTimeDescription: (description: string) => void;
  startTime: string;
}

export const CountdownMonths: React.FC<CountdownMonthsProps> = ({
  endTime,
  setTimeDescription,
  startTime
}) => {
  const [currentTime, setCurrentTime] = useState(moment());
  const [targetTime, setTargetTime] = useState(endTime);

  useEffect(() => {
    const updateStatus = () => {
      const now = moment();
      if (now.isBefore(moment(startTime))) {
        //before
        setTimeDescription(Constants.CONTEST_STARTING_IN);
        setTargetTime(startTime);
      } else if (now.isBefore(moment(endTime))) {
        //during
        setTimeDescription(Constants.CONTEST_REMAINING_TIME);
        setTargetTime(endTime);
      } else {
        //after
        setTimeDescription(Constants.CONTEST_IS_OVER);
        setTargetTime(endTime);
      }
    };

    updateStatus();
    const interval = setInterval(() => {
      setCurrentTime(moment());
      updateStatus();
    }, 1000);

    return () => clearInterval(interval);
  }, [startTime, endTime, setTimeDescription]);

  const deadline = moment(targetTime);
  let timeBetween = moment.duration(deadline.diff(currentTime));

  if (timeBetween.asMilliseconds() < 0) {
    timeBetween = moment.duration(0);
  }

  const totalDays = timeBetween.asDays();
  const weeks = Math.floor(totalDays / 7)
    .toString()
    .padStart(2, '0');
  const days = Math.floor(totalDays % 7)
    .toString()
    .padStart(2, '0');
  const hours = Math.floor(timeBetween.asHours() % 24)
    .toString()
    .padStart(2, '0');
  const minutes = Math.floor(timeBetween.asMinutes() % 60)
    .toString()
    .padStart(2, '0');
  const seconds = Math.floor(timeBetween.asSeconds() % 60)
    .toString()
    .padStart(2, '0');

  return (
    <StyledBox>
      <BoxLight>
        <CentralizeBox>
          <StyledTypography>{weeks}</StyledTypography>
          <StyledTypographyLabel>WEEKS</StyledTypographyLabel>
        </CentralizeBox>
      </BoxLight>
      <BoxLight>
        <CentralizeBox>
          <StyledTypography>{days}</StyledTypography>
          <StyledTypographyLabel>DAYS</StyledTypographyLabel>
        </CentralizeBox>
      </BoxLight>
      <BoxDark>
        <CentralizeBox>
          <StyledTypography2>{hours}</StyledTypography2>
          <StyledTypographyLabel2>HOURS</StyledTypographyLabel2>
        </CentralizeBox>
        <CentralizeBox2>
          <StyledTypography2>:</StyledTypography2>
        </CentralizeBox2>
        <CentralizeBox>
          <StyledTypography2>{minutes}</StyledTypography2>
          <StyledTypographyLabel2>MINUTES</StyledTypographyLabel2>
        </CentralizeBox>
        <CentralizeBox2>
          <StyledTypography2>:</StyledTypography2>
        </CentralizeBox2>
        <CentralizeBox>
          <StyledTypography2>{seconds}</StyledTypography2>
          <StyledTypographyLabel2>SECONDS</StyledTypographyLabel2>
        </CentralizeBox>
      </BoxDark>
    </StyledBox>
  );
};

const BoxLight = styled(Box)({
  backgroundColor: colorPalette.lightPurple,
  display: 'flex',
  alignItems: 'center',
  borderTopLeftRadius: '0.3125rem',
  borderTopRightRadius: '0.3125rem',
  marginRight: '0.3125rem',
  width: '3rem',
  padding: '0.3125rem 0'
});

const BoxDark = styled(Box)({
  backgroundColor: colorPalette.darkPastelPurple,
  display: 'flex',
  alignItems: 'center',
  borderTopLeftRadius: '0.3125rem',
  borderTopRightRadius: '0.3125rem',
  padding: '0.3125rem 0.625rem'
});

const CentralizeBox = styled(Box)({
  textAlign: 'center',
  width: '100%',
  height: '100%'
});

const CentralizeBox2 = styled(Box)({
  textAlign: 'center',
  width: '100%',
  height: '100%'
});

const StyledBox = styled(Box)({
  display: 'flex'
});

const StyledTypography = styled(Typography)({
  color: colorPalette.purple,
  fontSize: '1.6rem',
  fontWeight: 600,
  lineHeight: 'normal'
});

const StyledTypography2 = styled(Typography)({
  color: colorPalette.white,
  fontSize: '1.6rem',
  fontWeight: 600,
  lineHeight: 'normal'
});

const StyledTypographyLabel = styled(Typography)({
  color: colorPalette.purple,
  fontSize: '0.6rem',
  margin: 0,
  whiteSpace: 'pre',
  fontWeight: 600
});

const StyledTypographyLabel2 = styled(Typography)({
  color: colorPalette.white,
  fontSize: '0.6rem',
  margin: 0,
  whiteSpace: 'pre',
  fontWeight: 600
});
