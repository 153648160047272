/* eslint-disable max-lines-per-function */
import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { ButtonWithCounter } from 'components/common/ButtonWithCounter';
import { HexDoneIcon } from 'components/icons/HexDoneIcon';
import { HexPlusIcon } from 'components/icons/HexPlusIcon';
import IdeaMapDetailsIcon from 'components/icons/IdeaMapDetailsIcon';
import { DetailsModal } from 'modals/DetailsModal';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { Constants, MESSAGE_TYPES } from 'utilities/constants';
import { Message } from '../interfaces';
import { CustomTreeView, MessageText } from '../styledComponents';
import RenderTreeItems from 'components/RenderTreeItems';

export const IconSize = 25;

interface AiGeneratedItemsProps {
  expandedNode?: {
    key: string;
    msgId: string;
  };
  message: Message;
}

export const AiGeneratedItems: React.FC<AiGeneratedItemsProps> = ({
  expandedNode,
  message
}) => {
  const [openDetailsModal, setOpenDetailsModal] = useState<boolean>(false);
  const [selectedProblem, setSelectedProblem] = useState();
  const [expansionIds, setExpansionIds] = useState<string[]>([]);

  useEffect(() => {
    if (expandedNode) {
      setExpansionIds((pre) => [...pre, expandedNode.key]);
    }
  }, [expandedNode]);

  const ExpandIcon = useCallback(() => {
    return <AiOutlinePlus size={IconSize} />;
  }, []);

  const CollapseIcon = useCallback(() => {
    return <AiOutlineMinus size={IconSize} />;
  }, []);

  const AddButton = useCallback(({ node }) => {
    return node.isClicked ? <HexDoneIcon /> : <HexPlusIcon />;
  }, []);

  const SolveButton = useCallback(
    ({ node }) => (
      <ButtonWithCounter
        bigCounter
        coins={node.isAiGenerated ? '' : Constants.PLUS_ONE}
        disabled={node.isClicked}
      >
        {Constants.SOLVE}
      </ButtonWithCounter>
    ),
    []
  );

  const onDetailsClick = useCallback((event, problem) => {
    event.stopPropagation();
    setSelectedProblem(problem);
    setOpenDetailsModal(true);
  }, []);

  const handleExpandedItemsChange = (
    event: React.SyntheticEvent,
    itemIds: string[]
  ) => {
    setExpansionIds(itemIds);
  };

  return (
    <>
      <MessageText mb=".75rem">{message.title}</MessageText>
      <Box mb=".625rem">
        <CustomTreeView
          defaultExpandedItems={expansionIds}
          expandedItems={expansionIds}
          leftPad={false}
          onExpandedItemsChange={handleExpandedItemsChange}
          slots={{
            collapseIcon: CollapseIcon,
            expandIcon: ExpandIcon
          }}
        >
          <RenderTreeItems
            buttonList={
              message.type !== MESSAGE_TYPES.PRODUCT
                ? {
                    parent: [
                      {
                        component: SolveButton,
                        onClick: message.onProblemClick
                      },
                      {
                        component: IdeaMapDetailsIcon,
                        onClick: onDetailsClick
                      }
                    ],
                    child: {
                      parent: [
                        {
                          component: AddButton,
                          onClick: message.onSolutionClick
                        }
                      ]
                    }
                  }
                : {
                    parent: []
                  }
            }
            ideaMapType={Constants.PROBLEM}
            isChatBot={true}
            messageId={message.id}
            nodes={message.data}
          />
        </CustomTreeView>
        {openDetailsModal && (
          <DetailsModal
            data={selectedProblem}
            open={openDetailsModal}
            setOpen={setOpenDetailsModal}
          />
        )}
      </Box>
    </>
  );
};
