import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext
} from 'react';
import { Box, Typography } from '@mui/material';
import { PersonOutlinedIcon } from 'components/icons/PersonOutlinedIcon';
import DummyImage from 'assets/images/user_67x65.jpeg';
import { Contest } from 'components/CardContest/CardContest';
import { CardContestViewMobile } from 'components/CardContestView/CardContestViewMobile';
import { Tag } from 'components/CardTag';
import { LinkView } from 'components/LinkView';
import Vote from 'components/common/Vote';
import AuthContext from 'contexts/AuthContext';
import { Constants } from 'utilities/constants';
import { useIsMediumScreen } from '../../../theme';
import {
  ContentBox,
  ContestCardStyled,
  InfoContainer,
  StyledBox,
  StyledButton,
  StyledButtonContainer,
  StyledCardButton,
  StyledContestCardDateTypography,
  StyledContestCardDescriptionTypography,
  StyledContestCardLinkTypography,
  StyledContestCardTitle,
  StyledDescriptionContainer,
  StyledEarnTypography,
  StyledImage,
  StyledLogo,
  StyledMainContainer,
  TextContainer,
  VotingBox
} from './styles';

interface CardContestViewProps {
  contest: Contest;
  inCardCommunity?: boolean;
  pagination?: { page: number; perPage: number };
  setOpenSeeAllContestsModal?: Dispatch<SetStateAction<boolean>>;
  tag: Tag;
  toggleContestModal?: () => void;
}

export const CardContestView = ({
  contest,
  inCardCommunity = true,
  pagination,
  setOpenSeeAllContestsModal,
  tag,
  toggleContestModal
}: CardContestViewProps) => {
  const isMediumScreen = useIsMediumScreen();
  const { user } = useContext(AuthContext);
  const { createdAt, ownerInfo, title } = contest;

  const onSeeAllContestsClick = useCallback(() => {
    setOpenSeeAllContestsModal(true);
  }, []);

  //TODO: For now we are using static values. We will update them to dynamic after implementation of contest module.
  return (
    <>
      {isMediumScreen ? (
        <CardContestViewMobile
          contest={contest}
          inCardCommunity={inCardCommunity}
          onSeeAllContestsClick={onSeeAllContestsClick}
          tag={tag}
          toggleContestModal={toggleContestModal}
        />
      ) : (
        <ContestCardStyled>
          <StyledMainContainer>
            <StyledImage
              alt={title}
              src={contest.files[0]?.url}
              variant="rounded"
            />
            <ContentBox>
              <TextContainer>
                <InfoContainer>
                  <StyledContestCardTitle>{title}</StyledContestCardTitle>
                </InfoContainer>
                <StyledContestCardDateTypography
                  variant="subtitle1"
                  color="text.secondary"
                  gutterBottom
                >
                  <Box display="flex" alignItems="center">
                    {`Added ${createdAt.split('T')[0]}  |  Created by `}
                    <PersonOutlinedIcon />
                    &nbsp;
                    <LinkView
                      fontSize=".6563rem"
                      fontWeight="400"
                      height="none"
                      id={ownerInfo?.key}
                      title={ownerInfo ? ownerInfo.username : ''}
                      textDecoration="underline"
                      type="profiles"
                    />
                  </Box>
                </StyledContestCardDateTypography>
                <StyledDescriptionContainer>
                  <StyledContestCardDescriptionTypography
                    variant="subtitle1"
                    gutterBottom
                  >
                    {contest?.body}
                  </StyledContestCardDescriptionTypography>
                  {inCardCommunity && (
                    <VotingBox>
                      <Vote
                        item={contest}
                        itemType={Constants.CONTEST}
                        pagination={pagination}
                        scale="0.65"
                        tagId={tag.id}
                        voteStatus={contest.voteStatus}
                      />
                    </VotingBox>
                  )}
                </StyledDescriptionContainer>
              </TextContainer>
            </ContentBox>
          </StyledMainContainer>
          <StyledButtonContainer>
            {inCardCommunity && (
              <StyledContestCardLinkTypography>
                <StyledCardButton onClick={onSeeAllContestsClick}>
                  {Constants.SEE_ALL_CONTESTS}
                </StyledCardButton>
                {tag?.owner && user?.id === tag?.owner && (
                  <>
                    <Typography color="text.secondary">|</Typography>
                    <StyledCardButton onClick={toggleContestModal}>
                      {Constants.CREATE_A_CONTEST}
                    </StyledCardButton>
                  </>
                )}
              </StyledContestCardLinkTypography>
            )}
            {!inCardCommunity && (
              <StyledBox>
                <Vote
                  item={contest}
                  itemType={Constants.CONTEST}
                  pagination={pagination}
                  scale="0.65"
                  tagId={tag.id}
                  voteStatus={contest.voteStatus}
                />
              </StyledBox>
            )}
            <StyledButton variant="contained" color="primary" size="small">
              <StyledEarnTypography>
                Earn 30 Idea Coins Jackpot
              </StyledEarnTypography>
              <StyledLogo size={20} small />
            </StyledButton>
          </StyledButtonContainer>
        </ContestCardStyled>
      )}
    </>
  );
};
