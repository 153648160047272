import { styled } from '@mui/material';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import { colorPalette } from 'theme';

export const CircularSkeleton = {
  fontSize: '4.3125rem',
  marginTop: '-1.25rem',
  marginLeft: '2.625rem'
};

export const CustomTreeItem = styled(TreeItem)(({ theme }) => ({
  '.MuiTreeItem-iconContainer': {
    width: '100px !important',
    '& > svg': {
      background: colorPalette.white,
      border: '.125rem solid rgba(0, 0, 0, 0.2)',
      height: '1.75rem',
      position: 'absolute',
      top: '1.8125rem',
      width: '1.75rem',
      zIndex: '3',
      left: '-0.5rem',
      [theme.breakpoints.down('sm')]: {
        width: '1.2rem',
        height: '1.2rem',
        top: '1.3rem',
        left: '-0.3rem'
      }
    }
  },
  [`& .${treeItemClasses.content}`]: {
    backgroundColor: 'transparent',
    gap: 0,
    margin: theme.spacing(0.2, 0),
    marginBottom: '.625rem',
    padding: theme.spacing(0.625, 1),
    paddingRight: '0rem',

    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent !important'
    }
  },
  [`& .${treeItemClasses.iconContainer}`]: {
    marginLeft: '-4.4375rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '-5rem'
    },
    '& .close': {
      opacity: 0.3
    }
  },
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: 50,
    paddingLeft: 13,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 2,
      paddingLeft: 5
    }
  },
  '.Mui-selected': {
    backgroundColor: 'transparent !important',
    '.item-img': {
      width: '2.8rem',
      height: '2.8rem'
    },
    '.selected-node-text': {
      fontWeight: 'bold'
    }
  },
  '.MuiTreeItem-label &:hover': {
    background: 'transparent'
  },
  background: 'transparent',
  listStyleType: 'none',
  margin: '.625rem 0 .625rem .625rem',
  position: 'relative',
  ':last-child': {
    '&:after': {
      display: 'none'
    }
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    top: '-1.5625rem',
    left: '.3125rem',
    borderLeft: `1px solid ${colorPalette.explosiveGrey}`,
    borderBottom: `1px solid ${colorPalette.explosiveGrey}`,
    width: '4.75rem',
    height: '4.25rem',
    [theme.breakpoints.down('sm')]: {
      width: '3rem',
      top: '-2.3rem'
    }
  },
  '&:after': {
    position: 'absolute',
    content: '""',
    top: '2.6875rem',
    left: '.3125rem',
    borderLeft: `.0625rem solid ${colorPalette.explosiveGrey}`,
    borderTop: `.0625rem solid ${colorPalette.explosiveGrey}`,
    width: '3.125rem',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      top: '1.92rem'
    }
  }
}));

export const NodeStyle = {
  display: 'flex',
  alignItems: 'center'
};

export const TextSkeleton = {
  fontSize: '4.3125rem',
  width: '95%',
  borderRadius: '3.5625rem',
  marginLeft: '-4.25rem',
  marginTop: '-1.5625rem'
};
