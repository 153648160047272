export const enum Breakpoints {
  SMALL = 'sm',
  MEDIUM = 'md',
  LARGE = 'lg',
  EXTRA_LARGE = 'xl',
  EXTRA_SMALL = 'xs'
}

export const enum TAG_TYPES {
  AREA = 'area',
  BASE_TAGS = 'baseTags',
  COUNT_LIMIT = '99+',
  IS_FILED = 'filedOnly',
  LOCATION = 'location',
  SCHOOL = 'school',
  TAG = 'tag',
  TAG_FEED = 'tagFeed',
  UNIVERSITY = 'university',
  WORKPLACE = 'workplace'
}

export const enum INPUT_TYPES {
  EMAIL = 'email',
  PASSWORD = 'password',
  TEXT = 'text'
}

export const enum MESSAGE_TYPES {
  ACTION = 'action',
  AI_PROBLEMS = 'aiProblems',
  PRODUCT = 'product',
  TEXT = 'text',
  EDIT_SOLUTION = 'editSolution',
  SOLUTION_RELATIONSHIP = 'solutionRelationship',
  ITEM_DETAILS = 'itemDetails'
}

export const enum MESSAGE_ROLES {
  BOT = 'bot',
  USER = 'user'
}

export const enum FORM_VIEW {
  SIGN_UP = 'SignUpView',
  LOGIN = 'LoginView',
  SET_NEW_PASSWORD = 'SetNewPasswordView',
  RESET_PASSWORD = 'ResetPasswordView',
  CONFIRM_PASSWORD = 'Confirm New Password'
}

export const enum SUBSCRIPTION_PLANS {
  FREE_TIER = 'Free Tier',
  ONE_TIME_CREDITS_500 = 'Purchase 500 Credits',
  QUARTERLY_SUBSCRIPTION = '3 Month Subscription',
  YEARLY_SUBSCRIPTION = '12 Month Subscription'
}

export const enum VARIANT {
  BODY1 = 'body1',
  BODY2 = 'body2',
  BOTTOM = 'bottom',
  BOLD = 'bold',
  CAPITALIZE = 'capitalize',
  CONTAINED = 'contained',
  CAROUSEL_CONTAINER = 'carousel-container',
  DEFAULT = 'default',
  DETERMINATE = 'determinate',
  END = 'end',
  ERROR = 'error',
  FULLWIDTH = 'fullWidth',
  CAROUSEL_ITEM_PADDING_40_PX = 'carousel-item-padding-40-px',
  NUMBER = 'number',
  NORMAL = 'normal',
  OUTLINED = 'outlined',
  PRIMARY = 'primary',
  RIGHT = 'right',
  ROUNDED = 'rounded',
  SECONDARY = 'secondary',
  SMALL = 'small',
  SUCCESS = 'success',
  SMOOTH = 'smooth',
  SUBTITLE1 = 'subtitle1',
  TEXT_SECONDARY = 'text.secondary',
  TEXT = 'text',
  TOP_CENTER = 'top-center',
  TOP = 'top',
  TOP_RIGHT = 'top-right',
  BOTTOM_LEFT = 'bottom-left',
  PERSISTENT = 'persistent',
  INFO = 'info',
  H6 = 'h6',
  VERTICAL = 'vertical',
  CENTER = 'center',
  EASE_IN_OUT = 'easeInOut',
  START = 'start',
  WARNING = 'warning'
}

export const enum CHANNELS {
  AI_IMAGE = 'ai-image',
  DISTRIBUTE_REWARD = 'distribute-reward-channel',
  VIDEO_GENERATION_CHANNEL = 'video-generation-channel'
}

export const enum DOM_EVENTS {
  BLUR = 'blur'
}

export const enum EVENTS {
  LOADING = 'loading',
  IMAGE_FROM_DESCR = 'IMAGE_FROM_DESCR',
  DALL_E = 'dallE'
}

export const enum ERRORS {
  COMPANY_ALREADY_EXISTS = 'Company with same name already exists.',
  CONNECT_FACEBOOK_ACCOUNT = 'Connect your facebook account and enable sharing first',
  CONVERSION_IDEACOIN_TO_MATIC_FAILED = 'Conversion from IdeaCoin to Matic failed',
  CONVERTING_IDEA_TO_MATIC = 'Error converting Idea to Matic',
  CURRENCY_CONVERSION_FAILED = 'Currency conversion failed',
  DEPLOY_NFT = 'An error occurred while deploying NFT.',
  DEPLOY_WITH_METAMASK = 'Error in deployWithMetamask API call:',
  EMPTY_PASSWORD = 'Password can not be empty',
  EMPTY_USERNAME = 'Username can not be empty',
  ENTER_VALID_CREDITS = 'Please enter a valid credit amount',
  ERROR_DURING_STAKER_PAYMENT_REQUEST = 'Error during staker payment request:',
  ERROR_FETCHING_IDEACOIN_BALANCE = 'Error fetching IdeaCoin balance for address',
  ERROR_FETCHING_PROGRESS_DATA = 'Error fetching progress data:',
  ERROR_NFT_COUNT = 'Error fetching NFT count:',
  ESTIMATE_GAS = 'Error estimating gas:',
  EVENT_DATA = 'Event data is undefined.',
  FAILED_TO_CONVERT_MATIC = 'Failed to convert MATIC due to',
  FAILED_TO_FETCH_CRYPTO_PRICES = 'Failed to fetch cryptocurrency prices',
  FAILED_TO_INITIATE_PAYMENT_SESSION = 'Failed to initiate payment session',
  FETCH_BALANCE = 'Error fetching balance',
  FETCH_IMG_URL = 'Error fetching image URL:',
  FETCH_MATIC_TO_USD_RATE = 'Failed to fetch the MATIC to USD conversion rate.',
  FETCH_METADATA = 'Failed to fetch metadata',
  FETCH_PRODUCT_ERROR = 'Error fetching parent product:',
  FETCH_TOKEN_ID_AND_URIS = 'Error fetching token IDs and URIs:',
  FETCHING_CURRENCY_DATA = 'Error fetching currency data: ',
  FETCHING_LIVE_PRICES = 'Error fetching live prices:',
  FETCHING_THRESHOLD = 'Error while fetching threshold.',
  GAS_FEE = 'Error calculating gas fee:',
  GET_IDEA_COINS = 'Get Idea Coins Error',
  IDEA_COINS_TO_MATIC = 'Error converting Idea to Matic: ',
  INSUFFICIENT_BALANCE = 'Insufficient balance. Please ensure you have enough funds.',
  INVALID_ADDRESS = 'Invalid Ethereum address',
  INVALID_AMOUNT = 'Amount must be a positive number',
  INVALID_BALANCE_TYPE = 'Invalid type parameter',
  INVALID_EMAIL = 'Invalid email',
  MISMATCH_PASSWORD = 'Password does not match',
  NFT_DEPLOY_FINISH = 'Error in nftDeployFinish API call:',
  NO_OWNER = 'No owner',
  NOT_ENOUGH_CREDITS = 'Your account has insufficient credits. Please purchase additional credits to continue',
  PROBLEM_ERROR = 'Problem Error',
  PRODUCT_ERROR = 'Product Error',
  SOCIAL_AUTH_KEYS = 'No social auth keys found',
  TRANSFER_TOKEN = 'Error occurred while transferring tokens:',
  UNAUTHORIZED_PLEASE_LOGIN = '401 Unauthorized. Please login again',
  UNEXPECTED_ERROR = 'An unexpected error occurred',
  USER_IDEAPOINTS = 'No user idea points found',
  USER_NOT_FOUND = 'User not found',
  USER_RANKING = 'No user rank found',
  VIDEO_GENERATION_FAILED = 'Video generation failed:',
  WORD_LIMIT_EXCEEDED = 'Word limit exceeded. Please enter 50 words or less.'
}

export const enum PATH_NAMES {
  CONTESTS = '/contests',
  INVENTIONS = '/inventions',
  LINKED_ACCOUNTS = '/linkedaccounts',
  PROBLEMS = '/problems',
  PRODUCTS = '/products',
  PROFILES = '/profiles',
  REWARDS = '/rewards',
  SOCIAL_AUTH_KEYS = '/socialauthkeys',
  SOLUTIONS = '/solutions',
  USER_IDEAPOINTS = '/profiles/userIdeaPoints',
  USER_RANKING = '/profiles/userRanking'
}

export const enum END_POINTS {
  CALCULATE_MULTIPLIER_IDEA_POINTS = '/calculateMultiplierIdeaPoints',
  CREATE_EMPLOYEE = '/createEmployee',
  CREATE_PURCHASE_CREDITS_SESSION = '/createPurchaseCreditsSession',
  DISTRIBUTE_MATIC_REWARD = '/distributeMaticReward',
  FORGET_PASSWORD = '/forgetPassword',
  GET_REWARD_POOL_THRESHOLD = '/getRewardsPoolThreshold',
  GET_USER_OWNED_COMMUNITY = '/getUserOwnedCommunity',
  REQUEST_PAY = 'requestPay',
  RESET_PASSWORD = '/resetPassword',
  SIGN_IN = '/signin',
  SIGN_UP = '/signup'
}

export const enum RESOURCE {
  APPLICATIONS = 'applications',
  AUTH = 'auth',
  PROFILES = 'profiles',
  SUBSCRIPTIONS = 'subscriptions',
  TAGS = 'tags'
}

export const enum REACTION_TYPES {
  DISLIKE = 'dislike',
  LIKE = 'like'
}

export const enum URLS {
  FACEBOOK = 'https://www.facebook.com',
  LINKEDIN = 'https://www.linkedin.com',
  REDDIT = 'https://www.reddit.com',
  TWITTER = 'https://twitter.com',
  WHATSAPP_SHARE = 'https://api.whatsapp.com'
}

export const enum QUERY_PARAMS {
  RESET_TOKEN = 'resetToken'
}

export const enum Constants {
  _BLANK = '_blank',
  ACCEPT_AND_CONTINUE = 'Accept and Continue',
  ACCEPT_CONDITIONS = 'Accept Conditions',
  ACCOUNT_TRANSACTIONS = 'AccountTransactions',
  ACTION = 'action',
  ACTIONS = 'Actions',
  ACTIVATE = 'Activate',
  ACTIVATED = 'Activated',
  ACTIVE = 'active',
  ACTIVE_CONCEPT = 'Active Concept',
  ADD = 'Add',
  ADD_COMPANY = 'Add Company',
  ADD_EMPLOYEE = 'Add Employee',
  ADD_EXISTING_PROBLEM = 'Add Existing Problem',
  ADD_ITEM = 'ADD ITEM',
  ADD_MORE_SOLUTIONS = 'You need to add at least one solution to the invention before minting NFT',
  ADD_MORE_SOLUTIONS_TO_CONCEPT = 'Please add more solutions to active concept.',
  ADD_NEW_COMPANY = 'Add New Company',
  ADD_NEW_CONCEPT = 'Add New Concept',
  ADD_NEW_INVENTION = 'Add Invention/NFTs',
  ADD_NEW_PROBLEM = 'Add New Problem',
  ADD_NEW_PRODUCT = 'Add New Product',
  ADD_NEW_RELATED_PROBLEM = 'Add New Related Problem',
  ADD_NEW_SOLUTION = 'Add New Solution',
  Add_ONE_SOLUTION = 'Add at least one solution',
  ADD_OR_ACTIVATE_CONCEPT = 'Add new concept or activate another concept',
  ADD_PRODUCT = 'Add Product',
  ADD_SOLUTION = 'Add Solution',
  ADD_SOLUTIONS_TO_BUILD_CONCEPT = 'Add solutions to build a concept',
  ADD_SOLUTIONS_TO_CONCEPT = `Please add solutions to concept first.`,
  ADD_STAKER = 'addStaker',
  ADD_TO_ACTIVE_CONCEPT = 'addToActiveConcept',
  ADD_TO_ACTIVE_CONCEPT_TITLE = 'Add to Active Concept',
  ADD_TO_CONCEPT = 'Add to Concept',
  ADDED_TO_CONCEPT = 'Added To Concept',
  ADDRESS = 'address',
  ADDRESS_COPIED = 'Address copied!',
  AGREEMENT = 'Agreement',
  AGREEMENTS = 'Agreements',
  AI_CHAT = 'AI Chat',
  AI_GENERATED = 'AI Generated',
  AI_IMAGE_PLACEHOLDER_TEXT = 'AI is generating the image...',
  AI_PROBLEMS_MSG_TEXT = 'Click Solve to generate solutions',
  ALL = 'all',
  ALL_COMMUNITIES = 'All Communities',
  ALL_CONCEPTS = 'All Concepts',
  AMOUNT = 'Amount',
  AMPLIFY_IDEAS = 'amplify your ideas and multiply your earnings.',
  APPLICATION = 'application',
  APPLICATIONS = 'applications',
  APPROVE = 'Approve',
  APPROVED = 'Approved',
  ASC = 'ASC',
  ASSIGN_RIGHTS = ' I assign my rights in this solution to MindMiner in accordance with these',
  ASSOCIATED_WITH = 'associated with',
  AUTH_SUCCESS = 'auth-success',
  AUTH_TOKEN_LIFE_SPAN = '2592000',
  AUTO_CALCULATED_THRESHOLD = 'Auto-calculated threshold',
  AWAITING_MANUFACTURER_QUOTE = 'Awaiting Manufacturer Quote',
  AWAITING_MINDMINER_PATENT_FILING = 'Awaiting MindMiner Patent Filing',
  AWAITING_PATENT_TEXT = 'Awaiting Patent Filing',
  AWAITING_YOUR_PATENT_FILING = 'Awaiting Your Patent Filing',
  BACK = 'Back',
  BACK_TO_LOGIN = 'Back to login',
  BASED_ON_QUOTE = 'Based on the quote, ',
  BE_PATIENT_VIDEO_GENERATION_IN_PROGRESS = 'Be patient, your video generation will generate in 6-8 minutes...',
  BECOME_COMMUNITY_LEADER = ' Become a Community Leader',
  BEST_VALUE = 'Best Value',
  BITCOIN = 'Bitcoin',
  BITCOIN_SYMBOL = 'BTC',
  Body = 'Body',
  BUTTON_STYLES = 'button-styles',
  C_ALL = 'All',
  C_APPLICATION = 'Application',
  C_COMMUNITIES = 'Communities',
  C_COMMUNITY = 'Community',
  C_COMPANIES = 'Companies',
  C_CONCEPTS = 'Concepts',
  C_CONTEST = 'Contest',
  C_CONTESTS = 'Contests',
  C_CREATE = 'Create',
  C_EDIT = 'Edit',
  C_EMAIL = 'Email',
  C_INVENTION = 'Invention',
  C_INVENTIONS = 'Inventions',
  C_LIKES = 'Likes',
  C_MUTUAL = 'Mutual',
  C_NAME = 'Name',
  C_NONE = 'None',
  C_PASSWORD = 'Password',
  C_PROBLEM = 'Problem',
  C_PROBLEMS = 'Problems',
  C_PRODUCT = 'Product',
  C_PRODUCTS = 'Products',
  C_PUBLIC = 'Public',
  C_SEND = 'Send',
  C_SOLUTION = 'Solution',
  C_SOLUTIONS = 'Solutions',
  C_SORT_BY = 'Sort by',
  C_TAG = 'Tag',
  C_USERNAME = 'Username',
  CALCULATE_MULTIPLIER_IDEA_POINTS = 'calculateMultiplierIdeaPoints',
  CALC_AMOUNT = 'Calculated Amount',
  CANCEL = 'Cancel',
  CANNOT_GENERATE_TITLE = "Can't generate title from AI as this title is already edited by User",
  CAPITALIZE = 'capitalize',
  CHAIN = 'chain',
  CHECK_CONTEST_TYPE = 'Please select a contest type.',
  CHECK_DATE = 'Contest can not be created for a past date.',
  CHECK_DESCRIPTION = 'Please generate a description for the contest first!',
  CHECK_FIELD = 'Checked',
  CHECK_TITLE = 'Please enter a title for the contest!',
  CHECK_WIN_TYPE = 'Please select how a winner is decided.',
  CHECKED_IMOROVED_PROGRESS = 'checkImprovedProgress',
  CHOOSE_CATEGORY = 'Choose Category',
  CLAIM = 'Claim',
  CLICK_HERE = 'Click here',
  COIN_ICON = 'coins icon',
  COINS = 'Idea Coins',
  COLUMN = 'column',
  COMMUNITIES = 'communities',
  COMMUNITY = 'community',
  COMMUNITY_FOLLOW_CTA = 'You can become part of a community’s leaderboard by solving a problem in the community or following the community.  By following the community, problems, solutions and inventions created within the community become part of your feed.',
  COMMUNITY_IMAGE = 'Community Image',
  COMMUNITY_INVENTION = 'CommunityInvention',
  COMMUNITY_LEADER = 'Community Leader',
  COMMUNITY_MEMBERS = 'Community Members',
  COMMUNITY_PROBLEM = 'CommunityProblem',
  COMMUNITY_PRODUCT = 'CommunityProduct',
  COMMUNITY_SOLUTION = 'CommunitySolution',
  COMPANY = 'company',
  COMPANY_FIELD_LABEL = 'Is this company associated with the product?',
  COMPANY_FIELD_PLACEHOLDER = 'Which company?',
  COMPANY_PRODUCT = 'company-product',
  COMPANY_PRODUCTS = 'company-products',
  COMPANY_TAGS = 'Company Tags',
  COMPLETE = 'Complete',
  CONCEPT = 'concept',
  CONCEPT_CREATION_END = ' to view the concept.',
  CONCEPT_CREATION_START = ' will be created. ',
  CONCEPT_DESCRIPTION = 'Concept Description',
  CONCEPT_FEATURE_IMAGE = 'Concept Feature Image',
  CONCEPT_IMAGE = 'Concept Image',
  CONCEPT_NAME = 'Concept Name',
  CONCEPT_PROBLEM = 'conceptProblem',
  CONCEPT_SOLUTION = 'conceptSolution',
  CONCEPT_STATEMENT = 'contributed to a concept.',
  CONCEPTS = 'concepts',
  CONFIRM = 'Confirm',
  CONFIRM_AND_PATENT = 'Confirm & Patent',
  CONFIRM_CONCEPT = 'Confirm Concept',
  CONFIRM_NEW_PASSWORD = 'Confirm New Password',
  CONFIRM_PASSWORD = 'Confirm Password',
  CONFIRMATION_DIALOG_TITLE = 'confirmation-dialog-title',
  CONNECT = 'Connect',
  CONNECT_SOCIAL_ACCOUNTS = 'Connect with your social links to multiply your rewards.',
  CONNECTED = 'CONNECTED',
  CONTEST = 'contest',
  CONTEST_IS_OVER = 'The Contest Is Over!',
  CONTEST_LENGTH = 'Contest Length',
  CONTEST_MODAL_JACKPOT_INFO = 'The Jackpot winner will receive IdeaCoins, with the equivalent amount in BTC released based on the BTC pool threshold during the next royalty distribution.',
  CONTEST_REMAINING_TIME = 'Contest Remaining Time:',
  CONTEST_STARTING_IN = 'Contest Starting In: ',
  CONTEST_WIN = 'You can earn a jackpot of royalty producing IdeaCoins when you create solutions or inventions that receive the most upvotes in a contest.  You can join a contest simply by solving a problem included in the contest.',
  CONTEST_WINNER = 'contest-winner',
  CONTEST_WINNER_INFO_ONE = 'Each contest winner earns a reward of IdeaCoins. Each IdeaCoin produces royalties delivered in Bitcoin to the same wallet holding the Bitcoin. The royalties are distributed once the MindMiner Royalty Pool reaches the threshold amount, which periodically increases, to trigger distribution of Bitcoin royalties to IdeaCoin holders.',
  CONTEST_WINNER_INFO_TWO = 'You can also earn IdeaCoins when the solutions that you create on MindMiner are used within your own or someone else’s newly minted patent pending token.',
  CONTEST_WINNER_STATEMENT = 'You won a contest',
  CONTESTS = 'contests',
  CONTRIBUTORS = 'Contributors:',
  COPY = 'Copy',
  COPY_TO_CLIPBOARD = 'Copy to clipboard',
  COVER = 'cover',
  COVER_IMAGE = 'Cover Image',
  CREATE = 'create',
  CREATE_A_CONTEST = 'Create a Contest',
  CREATE_CHECKOUT_SEESION = 'create-checkout-session',
  CREATE_CONTEST = 'Create Contest',
  CREATE_CONTEST_DRAFT = 'The contest has been created as a draft. ',
  CREATE_CONTEST_S = 'create contest',
  CREATE_CONTEST_TOAST_END = ' to review in Profile.',
  CREATE_CONTEST_TOAST_START = 'has been created as a draft. ',
  CREATE_NEW_CONTEST = 'Create New Contest',
  CREATE_NEW_PROBLEM = 'Create New Problem',
  CREATE_OR_ACTIVATE_CONCEPT = 'Please create or activate a concept first.',
  CREATE_PATENT = 'Create a Patent Application & NFT for this Solution',
  CREATE_PROBLEM_SOLUTION = 'Create problem or solution',
  CREATE_SOLUTION = 'Create Solution',
  CREATED_AT = 'createdAt',
  CREATED_PROBLEM = 'created problem',
  CREATED_PROBLEM_STATEMENT = 'You created a problem',
  CREATED_SOLUTION = 'created solution',
  CREATED_SOLUTION_STATEMENT = 'You created a solution',
  CREATORS = 'Creators:',
  CROWDFUND_AND_MANUFACTURE = 'Crowdfund & Manufacture',
  CURRENT_PLAN = 'Current Plan',
  CURRENT_TAB = 'currentTab',
  DATE = 'Date',
  DATE_TEXT = 'en-US',
  DAYS = 'days',
  DEACTIVATE_CONCEPT = 'Deactivate Concept',
  DEFAULT_CREATE_ITEM_POINTS = '1',
  DEFAULT_SHARE_ITEM_POINTS = '25',
  DEFAULT_COVER_IMAGE = 'https://thingscareerrelated.com/wp-content/uploads/2021/10/default-background-image.png?w=862',
  DEFAULT_PROBLEM_TITLE = 'The problem of:',
  DEFAULT_SOLUTION_TITLE = 'The solution of: ',
  DEFINE_PROBLEM = 'Define Problem',
  DELETE = 'Delete',
  DENSE = 'dense',
  DEPLOY_NFT = 'Deploy NFT',
  DEPLOYED = 'Deployed',
  DEPLOYING = 'Deploying...',
  DESC = 'DESC',
  DESCRIBE_PROBLEM = 'Describe your problem',
  DESCRIBE_PRODUCT = 'Describe your product',
  DESCRIBE_SOLUTION = 'Describe your solution',
  DESCRIPTION = 'Description',
  DESTINATION_ADDRESS = 'Destination Address',
  DISCONNECT = 'DISCONNECT',
  DISCORD = 'discord',
  DISLIKE = 'dislike',
  DONT_HAVE_ACOUNT = 'Dont have an account?',
  DONT_SHOW_MODAL = `Don't show this modal again`,
  DOWNGRADE = 'Downgrade',
  DOWNLOAD = 'Download',
  DOWNLOAD_PATENT_APPLICATION = 'Download Patent Application',
  DOWNLOADING_PATENT = 'Downloading Patent',
  DRAFT = 'draft',
  DUMMY_COVER_IMAGE_URL = 'https://assets.gqindia.com/photos/5cdc75d38e62990b13f38389/16:9/w_2560%2Cc_limit/starbucks-cup-hed-2013.jpg',
  DUMMY_IMAGE = 'https://fastly.picsum.photos/id/39/200/300.jpg?hmac=CcUiRU6-82MldMqtiF9shpKCbwzwkILEWuRi90JsADs',
  DUPLICATE_CONTEST = 'Duplicate Contest',
  E_FILE_PATENT = 'E-file Patent',
  EARN = 'Earn',
  EARN_COINS = 'Earn IdeaCoins from this Solution',
  EARN_IDEACOINS = 'Earn IdeaCoins and create Idea #NFTs',
  EARN_IDEAPOINTS = 'Earn 1 IdeaPoints',
  EARN_JACKPOT = 'Earn Jackpot',
  EARN_MORE = 'earn more',
  EARN_500_PTS = 'Earn 500 IdeaPoints',
  EARN_250_PTS = 'Earn 250 IdeaPoints',
  EARN_ROYALTIES = 'How do you earn royalties?',
  EARN_ROYALTIES_TEXT = ' You can earn royalties when someone includes one of youC_r public solutions into their own patent pending invention.  You can also earn royalties by winning a contest jackpot.  You can withdraw your earned royalties from your MindMiner wallet.',
  EARNED = 'earned',
  EDIT_CONCEPT = 'Edit Concept',
  EDIT_CONTEST = 'Edit Contest',
  EDIT_COVER_PHOTO = 'Edit Cover Photo',
  EDIT_PROFILE_PHOTO = 'Edit Profile Photo',
  EDIT_TITLE = 'Edit Title',
  END = 'end',
  END_CONTEST_CLICK_HERE = ' click here',
  END_CONTEST_COMMON = 'The contest has ended. If you want to',
  END_CONTEST_DUPLICATE = 'duplicate',
  END_CONTEST_EDIT_IT = 'edit it',
  END_CONTEST_MIDDLE = ' participate in a separate contests, ',
  END_CONTEST_OR = ' or ',
  END_CONTEST_START = ' extend it further, you can ',
  ENTER = 'Enter',
  ENTER_CREDITS_COUNT = 'Enter credits count',
  ENTER_NUMBER_OF_PRODUCTS = 'Enter number of products',
  ENTER_PRICE_$ = 'Enter price ($)',
  ENTER_PRODUCT_TITLE = 'Enter Product Title',
  ENTERT_DETAIL_OF_STAKING = 'Enter the details for your staking.',
  ENTITY = 'entity',
  ERC20 = 'erc20',
  ERROR = 'Error',
  ERROR_FACEBOOK = 'Error sharing to Facebook',
  ERROR_GENERATE_TITLE = 'Drag at least one solution',
  ERROR_GET_ANSWER_FROM_AI = "Can't generate title from AI as this title is already edited by User",
  ERROR_MINT_NFT = 'You need to add at least one solution to the invention before minting NFT',
  ERROR_PROCESSING_GRAPH_DATA = 'Error processing graph data',
  ERROR_REDDIT_LOGIN = 'Error during reddit login:',
  ESTIMATED_GAS_FEE = 'Estimated gas fee',
  ETHER = 'ether',
  ETHEREUM = 'Ethereum',
  ETHEREUM_SYMBOL = 'ETH',
  EXIT_CONCEPTS = 'Exit Concepts',
  EXPIRED_TOKEN_ERROR = 'Your token is expired. Please log in again.',
  FACEBOOK = 'facebook',
  FEED_PRODUCT_IMPROVE = 'To improve ',
  FEEDS = 'feeds',
  FILE_VIA_MINDMINER = 'Mint Patent Pending Token to File Via MindMiner',
  FILING_DATE = 'Filing Date',
  FILTER = 'Filter',
  FILTER_BY = 'Filter by',
  FILTER_TYPE = 'filterType',
  FINALIZE_CONCEPT_TEXT = 'Finalize Concept Before Minting Token',
  FIRST_NAME = 'First Name',
  FIRST_NAME_REQUIRED = 'First name is Required',
  FOLLOW = 'follow',
  FOLLOWED = 'followed',
  FOLLOWED_TAGS = 'followed tags',
  FOLLOWING_COMMUNITIES = 'Following 500+ Communities',
  FOLLOWING_TEXT = 'Following',
  FOR_UI = 'forUi',
  FORGOT_PASSWORD = 'Forgot Password',
  FROM = 'From',
  FULL_STOP = '.',
  GENERATE_AI_IMAGE = 'Generate image from AI',
  GENERATE_AI_VIDEO = 'Generate an Ai Video for the concept',
  GENERATE_MORE = 'Generate More',
  GENERATE_PROBLEMS_FIRST = 'Please generate problems first.',
  GENERATE_TITLE_AND_IMG = 'Generate title and image from AI',
  GENRATE_TITLE_DESCRIPTION_FROM_AI = 'Generate Title and Description from AI',
  GENRATE_TITLE_FROM_AI = 'Generate Title from AI',
  GET_FOLLOWING_TAGS = 'getFollowingTags',
  GET_MANUFACTURER_QUOTE = 'Get Manufacturer Quote',
  GET_MUTUAL_TAGS = 'getMutualTags',
  GET_TAGS_BY_USER_ID = 'getTagsByUserId',
  GOOGLE = 'google',
  GOT_IT = 'Got it!',
  H3 = 'h3',
  HAVE_ACCOUNT_ALREADY = 'Already have a account?',
  HEXAGON_ICON = 'Hexagon Icon',
  HIDE_PROBLEMS = 'Hide Problems',
  HIDE_STATS = 'Hide Stats',
  HOME = 'Home',
  HOW_TO_EARN = 'How to Earn',
  HUNDRED_PERCENT = '100%',
  HYBRID = 'Hybrid',
  ID = 'id',
  IDEA_COINS = 'Ideacoins',
  IDEA_COINS_STACKING_JACKPOT = 'IdeaCoin Staking Jackpot:',
  IDEA_NFTS = 'Idea NFTs',
  IDEA_POINTS = 'ideaPoints',
  C_IDEA_POINTS = 'IdeaPoints',
  IDEA_POINTS_EARNED = 'Total Idea Points Earned',
  IDEA_POINTS_TEXT = 'Total IdeaPoints Earned:',
  IDEACOIN_HASH = 'transaction_hash',
  IDS = 'ids',
  ILLUSTRATION_TEXT = 'Need illustration help?',
  IMG = 'img',
  IMPROVE = 'Improve',
  IMPROVE_BUTTON_TEXT = 'Improve Concept Further',
  IMPROVE_CONCEPT_AI_TEXT = 'Improve your concept with the help of AI chat',
  IMPROVE_INVENTION = 'Improve Invention',
  IMPROVE_PROBLEM_CHANNEL = 'improve-problem-channel',
  IMPROVE_PRODUCT = 'Improve Product',
  IMPROVE_SOLUTION = 'You can make solutions better by identifying problems with them and solving those new problems.  Earn points and royalties from your better solutions.',
  IMPROVE_SOLUTION_CHANNEL = 'improve-solution-channel',
  IMPROVE_YOUR_CONCEPT_TEXT = 'We recommend that you improve your concept before generating a patent pending token. A patent pending token is locked once created. You cannot improve your concept without creating another patent pending token once you mint the token.',
  IMPROVE_YOUR_CONCEPT_WITH_THE_HELP_OF_AI_CHAT = 'Improve your concept with the help of AI chat',
  IMPROVED = 'Improved',
  IMPROVED_PROBLEM = 'improved problem',
  IMPROVED_SOLUTION = 'improved solution',
  IMPROVED_STATEMENT = "contributed to an application's improvement.",
  INFO = 'info',
  INITIALLY_CREATED_TEXT = 'Initially Created By',
  INPUT_ADORNMENT_POSITION_START = 'start',
  INPUT_PROBLEM_FIELD_LABEL = `What's your problem?`,
  INPUT_SOLUTION_FIELD_LABEL = `What's your solution?`,
  INSTAGRAM = 'instagram',
  INTERESTS = 'Interests',
  Invention = 'invention',
  INVENTION_CONTEST = 'This invention is not part of any contest.',
  INVENTION_IMPROVE = ' You can make your own or someone else’s concept better by identifying problems with it and solving them.  Your concept becomes an invention when you lock it in with a patent filing.',
  INVENTION_SELL_PURCHASE = 'You can own or sell your ideas as inventions when you mint a patent pending token for your concepts.  Combine your patent pending inventions with others to maximize the value of your collective intellectual property.',
  INVENTION_STAKE = 'You can crowdfund your invention by allowing others to stake your patent pending tokens.  You can earn royalties when the inventions that you stake meet their crowdfunding targets.',
  INVENTION_STAKING = 'Invention Staking',
  INVENTIONS = 'inventions',
  INVENTIONSTAKER = 'inventionStaker',
  INVITE = 'invite',
  INVITE_STATEMENT = ', and they joined MindMiner!',
  IS_COMMUNITY = 'isCommunity',
  JOIN_CONTEST = 'Join contest to earn jackpot',
  KEEP_PRIVATE = 'Keep Private',
  KEY = 'Key',
  LARGE_33 = '33',
  LAST_NAME = 'Last Name',
  LAST_NAME_REQUIRED = 'Last name is Required',
  LEADER_BOARD = 'LeaderBoard',
  LIKE = 'like',
  LIKES = 'likes',
  LINKED_ACCOUNTS = 'Linked Accounts',
  LINKEDIN = 'linkedin',
  LIST_YOUR_NFT = 'List Your NFT',
  LOADING = 'Loading...',
  LOCAL = 'local',
  LOCAL_MEMBERS = 'Local Members',
  LOCATION = 'location',
  LOCATIONS = 'Locations',
  LOCK_CONCEPT_MODAL = 'lockConceptModal',
  LOGIN = 'Login',
  LOGIN_SOCIAL = 'Login using social networks',
  LOGIN_SUCCESS = 'Login Successful',
  LONG_TEXT = 'long',
  MAKE_PATENT_APPLICATION = 'Make Patent Application',
  MAKE_PUBLIC = 'Make Public',
  MARK_ALL_AS_READ = 'mark all as read',
  MARK_AS_READ = 'Mark as read',
  MARK_AS_UNREAD = 'Mark as unread',
  MARKED_AS_PATENT_FILED = 'Marked As Patent Filed',
  MATIC = 'Matic',
  MATIC_HASH = 'hash',
  MATIC_SHARE = 'Matic Share',
  MEDIUM_15 = '15',
  MEDIUM_16 = '16',
  MEDIUM_20 = '20',
  MEDIUM_22 = '22',
  MEDIUM_25 = '25',
  MEDIUM_28 = '28',
  MESSAGE = 'message',
  MINDMINER = 'mindminer',
  MINDMINER_OVERALL_RANKING = 'Mindminer Overall Ranking:',
  MINDMINER_RANKING_TEXT = 'Mindminer OverAll Ranking:',
  MINDMINER_TEAM = 'Mindminer Team',
  MINT_NFT = 'Mint NFT',
  MINT_PATENT_BUTTON_TEXT = 'Mint Patent Pending Token Anyway',
  MINT_PATENT_LOGO = 'MintPatent Logo',
  MINT_PATENT_TOKEN = 'Mint Patent Pending Token',
  MODAL_PROBLEM = '?showModal=problems',
  MONTH = 'month',
  MORE = 'More',
  MOST_COINS_EARNED = 'Most Coins Earned',
  MOST_VOTES = 'Most Votes',
  MUST_BE_EIGHT_CHARACTERS = 'Must be at least 8 characters',
  MUTUAL = 'mutual',
  MUTUAL_COMMUNITIES = 'Mutual Communities',
  MY_ACTIVE_CONCEPT = 'My Active Concept',
  MY_AGREEMENTS = 'My Agreements',
  MY_COMMUNITY = 'My Community',
  MY_CONCEPTS = 'My Concepts',
  MY_CONTESTS = 'My Contests',
  MY_INVENTIONS = 'My Inventions',
  MY_PROBLEMS = 'My Problems',
  MY_SOLUTIONS = 'My Solutions',
  MY_WALLET = 'My Wallet',
  NA = 'N/A',
  NAME = 'name',
  NEW_NOTIFICATION_RECEIVED = 'New Notification Received!',
  NEW_PROBLEMS = 'new-problems',
  NEW_SOLUTION = 'new-solution',
  NEW_TITLE = 'New title',
  NEXT = 'Next',
  NEXT_FILING_DUE_DATE = 'Next Filing Due Date',
  NEXT_ROYALTY_DISTRIBUTION_THRESHOLD = 'Next Royalty Distribution Threshold',
  NFTS = 'NFTs',
  NFTS_TO_UNLOCK = 'NFTs to Unlock Rewards',
  NO_ACTIVE_CONCEPT = 'No Active Concept',
  NO_ACTIVE_CONCEPT_STATEMENT = 'Currently, there are no active concepts.',
  NO_CONCEPTS_FOUND = 'No Concepts Found',
  NO_NFT_MINTED = 'No NFTs Minted',
  NO_NOTIFICATIONS = 'No notifications',
  NO_OPENER_NO_REFFERRER = 'noopener noreferrer',
  NO_TRANSACTION_YET = 'No Transactions Yet',
  NO_VIDEO_AVAILABLE = 'No video available',
  NONE = 'none',
  NOT_ABLE_TO_READ_TEXT = 'Not able to read text',
  NOTIFICATION = 'notification',
  NOTIFICATIONS = 'notifications',
  NOTIFICATION_C = 'Notification',
  NOTIFICATIONS_C = 'Notifications',
  NotLockedFiled = '!locked/filed',
  NUMBER_OF_PRODUCTS = 'numberOfProducts',
  NUMBER_OF_PRODUCTS_WITH_SPACE = 'Number of Products',
  NUMERIC_TEXT = 'numeric',
  OF = ' of ',
  OF_THE = ' of the',
  ON_MINDMINER = 'on MindMiner',
  ONE_THOUSAND_PLUS = '1000+',
  ONE_TIME_CREDITS_PURCHASE = 'One-time credits purchase',
  ONE_TIME_PURCHASE = 'One time purchase',
  OPTIONS = 'Options',
  PAGE = 'page',
  PAGE_NOT_FOUND = 'Page Not Found',
  PAGINATION = 'pagination',
  PASSED = 'passed',
  PATENT = 'patent',
  PATENT_APPLICATION_TERMS_CONDITION = 'I agree that the patent application that you are about to download is for informational purposes only. It does not constitute and is not a substitute for legal advice. You should consult with a patent attorney before making any patent filings or related steps.I agree to indemnify and hold harmless MindMiner from any of uses of any patent application template that I download from MindMiner.',
  PATENT_FILE_FLOW = 'patentFileFlow',
  PATENT_NUMBER = 'Patent Number',
  PATENT_PENDING = 'patent-pending',
  PATENT_PENDING_INVENTIONS = 'Patent Pending Inventions',
  PATENT_PENDING_STATEMENT = 'You filed a patent pending token',
  PATENT_PENDING_TEXT = 'Patent Pending Ready to Sell',
  PATENT_PENDING_TOKEN = 'Patent Pending Token',
  PATENT_SUBMITTED = 'Patent Submitted',
  PATENT_VALUE = '$10,000.00',
  PAY_OPTIONS = 'payOptions',
  PAYMENT_FAILED = 'Payment failed:',
  PENDING_TRANSFER = 'Transfer is pending...',
  PER_PAGE = 'perPage',
  PERCENTAGE_IMPROVED = '% Improved',
  PLACEHOLDER = 'placeholder',
  PLACEHOLDER_IMAGE = 'https://placehold.co/50x50?text=Photo',
  PLEASE_ADD_PATENT_NUMBER = 'Please add patent number',
  PLUS_FIVE_HUNDRED = '+500',
  PLUS_ONE = '+1',
  PLUS_ONE_THOUSAND = '+1000',
  PLUS_THIRTY = '+30',
  PLUS_TWENTY_FIVE = '+25',
  PLUS_TWO = '+2',
  PLUS_ZERO = '+0',
  POINTS = 'Idea Points',
  POST = 'POST',
  POWERED_BY = 'Powered by',
  PREVENT_FROM = 'prevents it from being',
  PREVIEW_PDF = 'Preview PDF',
  PRICE_PER_PRODUCT = 'Price per product: ',
  PRIOR_ART = 'prior-art',
  PRIOR_ARTS = 'prior-arts',
  PRIVATE = 'private',
  PROBLEM = 'problem',
  PROBLEM_ASSOCIATED = 'problem associated with the',
  PROBLEM_CONTEST = 'This problem is not part of any contests.',
  PROBLEM_DETAILS = 'Problem Details',
  PROBLEM_DRAWER_STRING = 'Problems associated with this Solution',
  PROBLEM_HELPER_TEXT = 'Example: The problem of the buildup of fluid energy to prevent time travel',
  PROBLEM_IDEAPOINTS_TEXT = ' generated by MindMiner users from solutions to this problem.',
  PROBLEM_SOLUTION_TEXT = 'Problems & Solutions',
  PROBLEM_SOLVE = 'You can earn points and royalties by creating solutions for problems.  Combine solutions together into concepts that you can patent and sell.',
  PROBLEM_TAGS_LABEL = 'Choose Tags for this problem',
  PROBLEMS = 'problems',
  PRODUCT = 'product',
  PRODUCT_CONTEST = 'This product is not part of any contest.',
  PRODUCT_FIELD_LABEL = 'Select Product',
  PRODUCT_HEADING = 'Product',
  PRODUCT_HELPER_TEXT = 'Example: the product is described as a channeling mechanism for flux',
  PRODUCT_IMPROVE = 'You can make a product better by identifying problems with it and adding your solutions.  You can patent, sell and earn royalties from improved versions of existing products.',
  PRODUCT_TAGS_LABEL = 'Choose Tags for this product',
  PRODUCT_TITLE = 'Product Title',
  PRODUCTS = 'products',
  PROFILE = 'profile',
  PROFILES = 'profiles',
  PROGRESS_CHANNEL = 'progress-channel',
  PROGRESS_VAL = 'progress-value',
  PUBLIC = 'public',
  PURCHASE = 'Purchase',
  PURCHASE_PATENT_TOKEN = 'Purchase Patent Pending Token',
  PURCHASED_PATENT = 'purchased-patent',
  PURCHASED_PATENT_STATEMENT = 'You purchased a patent application',
  RANKING = '1,412',
  RECENTLY_ADDED = 'Recent',
  REDDIT = 'reddit',
  REDUCE = 'reduce',
  REFEREE_ADDED_TO_CONCEPT_STATEMENT = 'added to a concept',
  REFEREE_CREATED_PROBLEM_STATEMENT = 'created a problem',
  REFEREE_CREATED_SOLUTION_STATEMENT = 'created a solution',
  REFEREE_CREATED_STATEMENT = 'Your referee',
  REFERRAL_CODE = 'referralCode',
  REFERRED_CONCEPT_ADDITION = 'referred concept addition',
  REFERRED_PROBLEM = 'referred problem',
  REFERRED_SOLUTION = 'referred solution',
  REJECT = 'Reject',
  REJECTED = 'Rejected',
  RELATED_PROBLEM = 'Related Problem',
  RELATED_PROBLEM_PRODUCT = 'Related Problem & Product',
  RELATED_PROBLEMS = 'Related Problems',
  RELATED_PRODUCT = 'Related Product',
  REMOVE = 'remove',
  REMOVE_OWNER = 'Remove yourself as owner of this community',
  REPRESENTATIVE = 'representative',
  REQUEST_BOUNTY_FROM_MINDMINER = 'Request Bounty from MindMiner',
  RESET = 'Reset',
  RESET_PASSWORD = 'Reset Password',
  RESET_PASSWORD_SUCCESS = 'Password reset successful. Please Login.',
  RESET_YOUR_PASSWORD = 'Reset your Password',
  RETRIEVED_TOKEN_ERROR = 'Error retrieving new token. Please log in again.',
  REWARD = 'reward',
  REWARD_HISTORY = 'rewardhistory',
  REWARD_POOL = 'Reward Pool',
  REWARD_POOL_INFO = 'You can claim the equivalent matic value to your IdeaCoins once the reward pool threshold is reached.',
  REWARDS = 'rewards',
  row = 'row',
  S_SUBMIT = 'submit',
  SAVE = 'Save',
  SAVE_AND_CLOSE = 'Save And Close',
  SAVE_AND_CONTINUE = 'Save And Continue',
  SAVE_CHANGES = 'Save Changes',
  SAVE_DRAFT = 'Save Draft',
  SAVED = 'Saved',
  SCHOOLS = 'Schools',
  SEARCH_BAR_PLACEHOLDER = 'Search for a product or company',
  SEARCH_PATENT_PLACEHOLDER = 'Search Patent Pending Concept',
  SEE_ACTIVE_CONCEPTS = 'See active concepts',
  SEE_ALL_COMMUNITIES = 'See All Communities',
  SEE_ALL_CONCEPTS = 'See all concepts',
  SEE_ALL_CONTESTS = 'See All Contests',
  SEE_ALL_TAGS = 'See All Tags',
  SEE_LEADER_BOARD = 'See Leader board',
  SELECT = 'Select',
  SELECT_A_CONCEPT_TO_VIEW_DETAILS = 'Select a concept to view details',
  SELECT_A_INVENTION_TO_VIEW_DETAILS = 'Select a invention to view details',
  SELECT_AI_GENERATED = 'Or select an AI generated',
  SELECT_COMPANY_LABEL = 'Select Company',
  SELECT_CONCEPT = 'selectConcept',
  SELECT_CONCEPT_TITLE = 'Select Concept',
  SELECT_ONE_OF_YOUR_CONCEPT_TO_PATENT_BUILD_AND_SELL = 'Select one of your concepts to patent, build, and sell',
  SELECT_OR_CREATE_CONCEPT = 'Please select or create a concept',
  SELECT_PARENT_PROBLEM = 'Select parent problem',
  SELECT_TWO_SOLUTIONS = 'Plese select 2 solutions.',
  SELECTED_SOLUTION = 'Selected Solution',
  SEND_RESET_LINK = 'Send Reset Link',
  SET_NEW_PASSWORD = 'Set New Password',
  SHARE = 'share',
  SHARE_CTA = 'You can earn IdeaPoints simply by sharing problems, solutions and inventions to your social networks.  Earn more by automatically sharing to all of your networks.  You can earn royalties when someone creates a solution or invention in response to an item that you shared.',
  SHARE_MORE_EARN_MORE = 'Share more, earn more',
  SHARE_STATEMENT = 'You shared a',
  SHARE_TO_PRE_SELL_AND_CROWDFUNDING = 'Share to Pre-Sell and Crowdfunding',
  SHORT_TITLE = 'Short Title',
  SHOW_MODAL = 'showModal',
  SHOW_PROBLEM_MODAL = 'showproblemModal',
  SHOW_PROBLEMS = 'Show Problems',
  SHOW_PRODUCT_MODAL = 'showproductModal',
  SHOW_SOLUTION_MODAL = 'showsolutionModal',
  SHOW_STATS = 'Show Stats',
  SIGN_UP = 'Sign up',
  SIGN_UP_SOCIAL = 'Sign up using social networks',
  SIGN_UP_SUCCESS = 'SignUp Successful, Please Login.',
  SIMPLE_WORK = 'simple to use, work or understand?',
  SLASH_UNIT = '/Unit',
  SMALL_10 = '10',
  SMALL_12 = '12',
  SNAPCHAT = 'snapchat',
  SOLUTION = 'solution',
  SOLUTION_ADD_TO_CONCEPT = 'Your concepts are unique combinations of solutions that you create and discover.  You can patent and sell your concepts.',
  SOLUTION_ADDED = 'Solution Added',
  SOLUTION_ADDED_TO = 'Solution added to',
  SOLUTION_ASSOCIATED = 'Solution associated with the',
  SOLUTION_CONTRIBUTOR = 'Solution Contributors for this Invention:',
  SOLUTION_CREATORS = 'Solution Creators:',
  SOLUTION_DRAWER_STRING = 'Solutions to this problem',
  SOLUTION_HELPER_TEXT = 'Example: the solution is described as a channeling mechanism for flux',
  SOLUTION_HELPER_TITLE = 'Example: The solution of a flux capacitor',
  SOLUTION_IMAGE_TEXT = 'Solution Image',
  SOLUTION_PATENT_PENDING = 'solution-patent-pending',
  SOLUTION_PATENT_PENDING_STATEMENT = 'was part of a patent pending token.',
  SOLUTION_PURCHASED_PATENT = 'solution-purchased-patent',
  SOLUTION_PURCHASED_PATENT_STATEMENT = 'was part of a purchased patent.',
  SOLUTION_TAGS_LABEL = 'Choose Tags for this solution',
  SOLUTIONS = 'solutions',
  SOLUTIONS_BY_IDS = 'solutionsByIds',
  SOLUTIONS_IMAGES = 'Solutions Images',
  SOLUTIONS_RELATIONSHIP = `Solutions Relationship`,
  SOLVE = 'Solve',
  SOLVE_FOR = 'Solve for the ',
  SOLVE_PROBLEM = 'Please solve a Problem to participate in this Contest.',
  SOLVED_PROBLEMS = 'Solved Problems',
  SORT_BY = 'Sort By',
  STAKE_AND_PREPURCHASE = 'Stake And Pre-Purchase',
  STAKE_INVENTION = 'Stake Invention (Pre Purchase)',
  STAKE_TEXT = ' to meet staking threshold for product and royality distribution to stakers',
  STAKE_THIS_INVENTION = 'Stake This Invention',
  STAKE_THIS_PROJECT = 'Stake This Project',
  STAKE_VALUE = '$75.00',
  STAKING_COMPLETE = 'Staking Complete',
  START_STAKING_AND_CROWDFUNDING = 'Start Staking And Crowdfunding',
  STRING = 'string',
  SUBJECT_MATTER = 'subject matter',
  SUBMIT = 'Submit',
  SUBSCRIBE = 'Subscribe',
  SUBSCRIBE_FOR_MONTHLY = 'Subscribe for monthly credits or buy in bulk to create, solve, and',
  SUBSCRIPTION = 'Subscription',
  MY_PROFILE = 'My Profile',
  MY_ACTIVITY = 'My Activity',
  CREDITS_TOP_UP = 'Credits Top-Up',
  ADMIN_PANEL = 'Admin Panel',
  LOGOUT = 'Logout',
  SUBSCRIPTION_AND_CREDIT_PURCHASE = 'Subscription and Credit Purchase Offerings',
  SWAP = 'Swap',
  TAB_ID_KEY = 'mindminerTabId',
  TAB_KEY_PREFIX = 'tabHistory_',
  TAG = 'tag',
  TAG_IMAGE = 'Tag Image',
  TAG_USER = 'tagUser',
  TAGS = 'tags',
  TAGS_AND_DETAILS_TEXT = 'Tags & Details',
  TAGS_SOLUTION = 'tagsSolution',
  TEASER = 'Teaser',
  TERMS_CONDITIONS = 'terms and conditions',
  TESTIMONIAL_ONE_CONTENT = "MindMiner’s AI system has transformed my approach to innovation. By combining solutions from various products, it generates truly novel ideas. The patent-pending digital tokens are a game-changer - I'm building a valuable portfolio of intellectual property with each invention.",
  TESTIMONIAL_ONE_NAME = 'Isaac Olson',
  TESTIMONIAL_THREE_CONTENT = 'This AI invention system has truly impressed me. Its ability to cross-pollinate ideas from different fields has led to groundbreaking solutions. The patent-pending digital tokens provide a clear innovation trail, invaluable for securing grants and collaborations.',
  TESTIMONIAL_TWO_CONTENT = "As a startup founder, MindMiner is my secret weapon. It's like having a tireless R&D team working 24/7. Quickly generating and owning patent-pending ideas has accelerated our product development cycle, while the digital tokens add an exciting new asset to our portfolio.",
  TEXT_TO_VIDEO = 'textToVideo',
  THOUSAND = '1000',
  THRESHOLD = 'Threshold',
  THRESHOLD_CAN_NOT_BE_LESS_THAN = 'Threshold cannot be less than',
  THRESHOLD_REACHED = 'Royalites will be distributed to ideaCoin holders soon.',
  THUMBNAIL = 'Thumbnail',
  TIKTOK = 'tiktok',
  TIME_FORMAT = 'MMMM D, YYYY',
  TITLE = 'Title',
  TITLE_PROBLEM = 'titleProblem',
  TITLE_PRODUCT = 'titleProduct',
  TITLE_SOLUTION = 'titleSolution',
  TO = 'To',
  TO_GENERATE_VIDEO_PATENT_THIS_CONCEPT_FIRST = 'To generate video, patent this concept first.',
  TOGGLE_ON = 'Turn on each switch to enable one-click sharing with all of your social networks to',
  TOKEN = 'token',
  TOP_10_WINNERS = 'Top 10 Winners',
  TOP_3_WINNERS = 'Top 3 Winners',
  TOP_INTEREST_COMMUNTIES = 'Top Interests Communities',
  TOP_RANKED = 'Top Ranked',
  TOP_RIGHT = 'top-right',
  TOP_UP_CREDITS = 'Top Up Credits',
  TOTAL = 'Total',
  TOTAL_AMOUNT_IN_$ = 'Total amount in $',
  TOTAL_EARNINGS = 'Total Earnings',
  TOTAL_IDEACOINS = 'Total IdeaCoins',
  TOTAL_MATICS = 'Total Matics',
  TOTAL_STAKING_THRESHOLD = 'totalStakingThreshold',
  TOTAL_STAKING_THRESHOLD_WITH_SPACE = 'Total Staking Threshold',
  TRANSACTIONS = 'Transactions',
  TRANSFER_COMPLETE = 'Transfer complete!',
  TRANSFERS = 'transfers',
  TRUE = 'true',
  TWITTER = 'twitter',
  TX = 'tx',
  TX_CONFIRMATION_MSG = 'Transaction sent, waiting for confirmations...',
  TYPE = 'type',
  TYPE_TITLE = 'Type a title to create a new',
  UNFOLLOW = 'unfollow',
  UNIVERSITY = 'university',
  UNLIMIT = 'unlimit',
  UPDATE = 'Update',
  UPDATE_CONTEST = 'Update Contest',
  UPDATE_SOLUTIONS_RELATIONSHIP = 'Update Solutions Relationship',
  UPGRADE = 'Upgrade',
  UPLOAD_OR_GENERATE_AI_IMAGE = 'Upload or Generate AI image',
  UPLOAD_PDF_FILING_RECEIPT = 'Upload PDF filing receipt',
  USD = 'USD',
  USD_SYMBOL = '$',
  USER_AGREEMENT = 'UserAgreement',
  USER_AGREEMENTS = 'userAgreements',
  USER_INTERFACE = 'problem of its user interface being too cluttered?',
  USER_TAGS = 'usertags',
  USERNAME = 'username',
  USERNAME_EMAIL = 'Username/Email',
  VIA_MINDMINER = 'viaMindMiner',
  VIA_SELF = 'viaSelf',
  VIDEOS = 'videos',
  VIEW_DEPLOYED_NFT = 'View Deployed NFT',
  VIEW_NFT = 'viewNFT',
  VIEW_SELL_NFT = 'View/Sell NFT',
  VIEW_SOCIAL_LINK = 'View Social links',
  VIEW_TRANSACTION = 'View transaction',
  VISUALIZE_INNOVATIVE_IDEAS = 'visualize innovative ideas.',
  VOTE_CTA = 'You can earn multiples of your IdeaPoints by upvoting valuable problems, solutions and inventions and downvoting undesirable ones.  Items that rank within their communities will impact your points earning multiplier.',
  VOTES = 'votes',
  WALLET_SETTINGS = 'Wallet Settings',
  WARNING = 'Warning',
  WHICH_PROBLEM = 'Which problem',
  WINNER_TAKES_ALL = 'Winner Takes All',
  WORKPLACE = 'workplace',
  YES = 'Yes',
  YOU_ARE_NOT_PART_OF_THIS_COMMUNITY = 'You are not part of this community',
  YOU_HAVE_ALREADY_STARTED_STAKING = 'You have already started the staking process.',
  YOU_INVITED = 'You invited ',
  YOU_NEED_TO_ADD_ATLEAST_ONE_SOLUTION = 'You need to add at least one solution to the invention before minting NFT',
  YOUR_BALANCE = 'Your Balance',
  YOUR_COMMUNITY = 'Your community',
  Your_IdeaCoins = 'Your IdeaCoins',
  Your_IdeaPoints = 'Your IdeaPoints',
  YOUR_NEXT_ROYALTY_DISTRIBUTION = 'Your Next Royalty Distribution',
  YOUR_PROBLEM = 'Your problem',
  YOUR_SOLUTION = 'What is your solution to the above problem?',
  YOUR_SOLUTION_IDEAPOINTS = 'Your solution',
  YOURSELF_AS_OWNER = 'yourself as the owner of',
  YOUTUBE = 'youtube'
}

export const CURRENCIES = ['USD', 'BTC', 'ETH', 'USDT'];

export const CURRENCIES_SYMBOL_TO_NAME = {
  USD: 'USD',
  BTC: 'Bitcoin',
  ETH: 'Ethereum',
  USDT: 'Tether'
};

export const CHECKS_LIST = {
  FIVE_SOLUTIONS: '5 Solutions',
  THREE_RELATIONSHIP_BETWEEN_SOLUTIONS: '3 Relationships between solutions',
  DESCRIPTION_AND_TITLE_OF_CONCEPT_EDITED_FROM_AI_CONFIMED_BY_USER:
    'Description & Title of Concept edited from AI/confirmed by user',
  ONE_NEWLY_GENERATED_SOLUTION_CREATED_BY_CONCEPT_CREATOR:
    '1 Newly Generated Solution created by concept creator',
  ONE_NEW_RELATIONSHIP_NOT_INHERITED_FROM_PARENT_PRODUCT_OR_INVENTION:
    '1 New Relationship not inherited from parent product/invention',
  NAME_AND_ADDRESS_OF_INVENTOR_PERMANENTLY_SAVED_IN_USER_PROFILE:
    'Name and Address of Inventor permanently saved in user profile'
};

export const enum VOTE_CRITERIA {
  GENERAL = 'general',
  IDEAMAP = 'ideaMap'
}

export const enum QUOTATION_STATUS {
  NOT_SENT = 'NotSent',
  SENT = 'Sent',
  RECEIVED = 'Received'
}

export const enum STAKING_STATUS {
  FULFILLED = 'Fulfilled',
  IN_PROGRESS = 'InProgress'
}

export const enum HEADINGS {
  H3 = 'h3'
}

export const enum NUMBERS {
  FIVE_HUNDRES_STRING = '500',
  FIVE_HUNDRES = 500,
  ONE_POINT_ONE = 1.1,
  ONE_POINT_FIVE = 1.5
}

export enum TRANSACTION_TYPE {
  APPROVAL = 'APPROVAL',
  SEND = 'SEND'
}

export enum ASSET_TYPES {
  IDEACOINS = 'IdeaCoins',
  MATIC = 'MATIC',
  NFT = 'NFT'
}

export enum CATEGORY_URL_MAP {
  product = '/products/',
  company = '/communities/'
}

export const enum LABELS {
  ARE_YOU_A_REPRESENTATIVE_OF_THE_COMPANY = 'Are you a representative of the company'
}

export const TOASTIFY_DURATION = 2000;

export const INITIAL_PAYMENT_SUCCESS_CONTENT = ` MindMiner's AI will generate a patent application for your concept.
You will temporarily allocate the rights to the concept to MindMiner so that MindMiner has the authorization to file your patent application.
MindMiner will file the patent application and pay the associated fees, and then MindMiner will assign the rights to the filed patent application to anyone who owns the token (initially, you).
Once the patent application is filed, MindMiner will allocate the rights to the token that you mint.
MindMiner remains under the contractual obligation to exclusively allocate the invention rights in your concept to a patent pending concept that you will own, or return the invention rights to you upon your request.
After the patent pending token is created for your concept, it will be placed in your wallet on MindMiner.
You can then sell your patent pending concept via the token, or transfer the patent pending token to any other wallet as you wish.`;

export const REQUEST_QUOTATION_MODAL_TEXT = `To start staking for this invention, you first need to request a manufacturer quote
to calculate the total amount required for this project.`;

export const ALREDAY_REQUESTED_QUOTATION_MODAL_TEXT = `You have already requested a quotation. You will receive a notification once the
manufacturer responds. Thank you.`;

export const REQUEST_QUOTATION_ALERT_TEXT =
  'Thanks for requesting. We will let you know once we receive the quote from the manufacturer.';

export const COMPLETE_STAKING_TEXT =
  'Your staking is complete, kindly contact MindMiner for further process. Thanks';
