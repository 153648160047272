import React from 'react';
export const EditIcon = ({
  size = 24,
  color = '#677890',
  className
}: {
  size?: number;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 23V18.4812L13.7225 5.80818L18.2589 10.327L5.53643 23H1ZM3.04651 20.9614H4.5814L15.3256 10.259L13.7907 8.73011L3.04651 19.4325V20.9614ZM19.7597 8.83204L15.2233 4.31324L18.0884 1.45926C18.3385 1.1648 18.6227 1.01191 18.9411 1.00059C19.2594 0.98926 19.5778 1.14215 19.8961 1.45926L22.5566 4.10938C22.8522 4.40384 23 4.71529 23 5.04372C23 5.37216 22.8749 5.6836 22.6248 5.97806L19.7597 8.83204Z"
        fill={color}
      />
    </svg>
  );
};

export default EditIcon;
