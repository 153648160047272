import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FunctionComponent } from 'react';

export const ContestsIcon: FunctionComponent<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props} viewBox="0 0 23.755 24.391">
    <path
      fill="currentColor"
      d="M23.071.777A2.508 2.508 0 0 0 21.261 0H2.494A2.492 2.492 0 0 0 0 2.62a11.665 11.665 0 0 0 1.317 4.93 10.393 10.393 0 0 0 2.517 3.089c.161.134.325.263.489.386a7.39 7.39 0 0 0 6.563 6.286v1.495c-.129.023-.245.075-.371.106s-.228.062-.34.1a5 5 0 0 0-.683.275c-.113.056-.222.115-.328.178s-.215.1-.309.168a3.559 3.559 0 0 0-1.595 2.9v1.863h9.24v-1.868a3.562 3.562 0 0 0-1.6-2.9 5.062 5.062 0 0 0-2.031-.825v-1.492a7.4 7.4 0 0 0 6.563-6.286c.165-.124.329-.253.488-.386a10.38 10.38 0 0 0 2.517-3.089 11.636 11.636 0 0 0 1.314-4.93 2.5 2.5 0 0 0-.68-1.843m-1.3 1.74a9.04 9.04 0 0 1-2.262 5.775V1.983h1.752a.509.509 0 0 1 .51.534m-4.245-.534v8a5.411 5.411 0 0 1-5.415 5.383h-.468a5.411 5.411 0 0 1-5.415-5.383v-8ZM9.248 22.408c.083-.878 1.065-1.6 2.332-1.694h.585c1.276.1 2.259.817 2.342 1.7Zm-5-20.425v6.309a9.042 9.042 0 0 1-2.264-5.775.5.5 0 0 1 .138-.375.51.51 0 0 1 .372-.159Z"
      data-name="Path 276"
    />
  </SvgIcon>
);
