import React from 'react';
import styles from './ModalWindow.module.scss';
import Typography from '@mui/material/Typography';

interface styleModalProps {
  margin: string;
  maxWidth: string;
}

export interface ModalWindowProps {
  children: React.ReactNode;
  className?: string;
  style?: styleModalProps;
  subTitle?: React.ReactNode;
  title: React.ReactNode;
  titleAfter?: React.ReactNode;
  titleCenter?: boolean;
}

export const ModalWindow: React.FC<ModalWindowProps> = ({
  children,
  style = {},
  subTitle,
  title,
  titleAfter,
  titleCenter = false
}) => {
  const { maxWidth, margin } = style;
  return (
    <div className={styles.modal} style={{ maxWidth, margin }}>
      <div className={styles.modalWrapper}>
        <div className={styles.modalBox}>
          <div className={styles.modalTitleHolder}>
            <Typography
              className={`${styles.modalTitle} ${
                titleCenter ? styles.modalTitleCenter : ''
              }`}
              component="h2"
              variant="h4"
            >
              {title}
            </Typography>
            {titleAfter ? titleAfter : null}
          </div>
          {subTitle ? (
            <Typography className={styles.modalSubTitle}>{subTitle}</Typography>
          ) : null}
          {children}
        </div>
      </div>
    </div>
  );
};

export default ModalWindow;
