/* eslint-disable import/named */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

interface ProfileStatsImageProps {
  height?: number | string;
  width?: number | string;
}

const ProfileStatsImage: React.FC<ProfileStatsImageProps> = ({
  height = 50,
  width = 50,
  ...props
}) => {
  return (
    <SvgIcon
      {...props}
      sx={{ width: width, height: height }}
      viewBox="0 0 50 50"
    >
      <g clipPath="url(#clip0_5818_3931)">
        <path
          d="M8.96484 0.71582H44.974V14.1146H8.96484V0.71582Z"
          fill="#632DDD"
        />
        <path
          d="M12.3164 3.22754H20.6906V11.6018H12.3164V3.22754Z"
          fill="white"
          fillOpacity="0.7"
        />
        <path
          d="M22.3652 3.22754H40.7885V4.90238H22.3652V3.22754ZM22.3652 6.57723H40.7885V8.25208H22.3652V6.57723ZM22.3652 9.92692H40.7885V11.6018H22.3652V9.92692Z"
          fill="white"
          fillOpacity="0.7"
        />
        <path
          d="M13.9922 19.1396H50.0014V32.5384H13.9922V19.1396Z"
          fill="#632DDD"
          fillOpacity="0.2"
        />
        <path
          d="M17.3398 21.6514H25.7141V30.0256H17.3398V21.6514ZM27.3889 21.6514H45.8122V23.3262H27.3889V21.6514ZM27.3889 25.0011H45.8122V26.6759H27.3889V25.0011ZM27.3889 28.3507H45.8122V30.0256H27.3889V28.3507Z"
          fill="white"
          fillOpacity="0.6"
        />
        <path
          d="M13.9922 35.8877H50.0014V49.2865H13.9922V35.8877Z"
          fill="#632DDD"
          fillOpacity="0.2"
        />
        <path
          d="M17.3398 38.3994H25.7141V46.7736H17.3398V38.3994ZM27.3889 38.3994H45.8122V40.0743H27.3889V38.3994ZM27.3889 41.7491H45.8122V43.424H27.3889V41.7491ZM27.3889 45.0988H45.8122V46.7736H27.3889V45.0988Z"
          fill="white"
          fillOpacity="0.6"
        />
        <path
          d="M5.02454 7.17028L3.10433 5.25007L1.18412 7.17028L0 5.98616L3.10433 2.88184L6.20865 5.98616L5.02454 7.17028Z"
          fill="#632DDD"
        />
        <path
          d="M2.26562 4.06543H3.94047V10.7648H2.26562V4.06543Z"
          fill="#632DDD"
        />
        <path
          d="M3.94222 29.5355L0.837891 26.4312L2.02201 25.2471L3.94222 27.1673L5.86243 25.2471L7.04654 26.4312L3.94222 29.5355Z"
          fill="#632DDD"
        />
        <path
          d="M3.10479 21.6514H4.77964V28.3507H3.10479V21.6514ZM3.94222 46.2833L0.837891 43.179L2.02201 41.9949L3.94222 43.9151L5.86243 41.9949L7.04654 43.179L3.94222 46.2833Z"
          fill="#632DDD"
        />
        <path
          d="M3.10547 38.3994H4.78031V45.0988H3.10547V38.3994Z"
          fill="#632DDD"
        />
        <path
          d="M8.96484 21.6514H10.6397V23.3262H8.96484V21.6514ZM8.96484 25.0011H10.6397V26.6759H8.96484V25.0011ZM8.96484 28.3507H10.6397V30.0256H8.96484V28.3507ZM8.96484 38.3998H10.6397V40.0747H8.96484V38.3998ZM8.96484 41.7495H10.6397V43.4244H8.96484V41.7495ZM8.96484 45.0992H10.6397V46.774H8.96484V45.0992Z"
          fill="#632DDD"
          fillOpacity="0.2"
        />
      </g>
      <defs>
        <clipPath id="clip0_5818_3931">
          <rect
            width="50"
            height="50"
            fill="white"
            transform="translate(0 0.000976562)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default ProfileStatsImage;
