import { useEffect, useState } from 'react';
import { CHECKS_LIST } from 'utilities/constants';

interface CheckListData {
  enoughNumberOfSolutions?: boolean;
  enoughNumberOfSolutionRelationship?: boolean;
  descriptionAndTitleGeneratedFromAI?: boolean;
  solutionsDuplicate?: boolean;
  solutionRelationshipsDuplicate?: boolean;
  nameAndAdressOfInventorSaved?: boolean;
}

export function useChecklist(checkListData: CheckListData) {
  const [fulfilled, setFulfilled] = useState<string[]>([]);
  const [warnings, setWarnings] = useState<string[]>([]);

  useEffect(() => {
    const checks = [
      {
        condition: checkListData?.enoughNumberOfSolutions,
        label: CHECKS_LIST.FIVE_SOLUTIONS
      },
      {
        condition: checkListData?.enoughNumberOfSolutionRelationship,
        label: CHECKS_LIST.THREE_RELATIONSHIP_BETWEEN_SOLUTIONS
      },
      {
        condition: checkListData?.descriptionAndTitleGeneratedFromAI,
        label:
          CHECKS_LIST.DESCRIPTION_AND_TITLE_OF_CONCEPT_EDITED_FROM_AI_CONFIMED_BY_USER
      },
      {
        condition: !checkListData?.solutionsDuplicate,
        label:
          CHECKS_LIST.ONE_NEWLY_GENERATED_SOLUTION_CREATED_BY_CONCEPT_CREATOR
      },
      {
        condition: !checkListData?.solutionRelationshipsDuplicate,
        label:
          CHECKS_LIST.ONE_NEW_RELATIONSHIP_NOT_INHERITED_FROM_PARENT_PRODUCT_OR_INVENTION
      },
      {
        condition: checkListData?.nameAndAdressOfInventorSaved,
        label:
          CHECKS_LIST.NAME_AND_ADDRESS_OF_INVENTOR_PERMANENTLY_SAVED_IN_USER_PROFILE
      }
    ];

    const newFulfilled = checks
      .filter((check) => check.condition)
      .map((check) => check.label);
    const newWarnings = checks
      .filter((check) => !check.condition)
      .map((check) => check.label);

    setFulfilled(newFulfilled);
    setWarnings(newWarnings);
  }, [checkListData]);

  return { fulfilled, warnings };
}
