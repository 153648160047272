import React from 'react';
import { SvgIconProps, SvgIcon } from '@mui/material';
import { FunctionComponent } from 'react';

export const TreeIconChecked: FunctionComponent<SvgIconProps> = ({
  ...props
}) => (
  <SvgIcon {...props} viewBox="0 0 23.512 19.276" fontSize="small">
    <g data-name="Component 39 – 1">
      <path
        fill="#008954"
        d="M9.786 7.244V.381A.387.387 0 0 0 9.405 0H5.592a.387.387 0 0 0-.381.381v6.863a.387.387 0 0 0 .381.381h3.813a.387.387 0 0 0 .381-.381Zm13.725-3.05V.381A.387.387 0 0 0 23.13 0h-3.813a.387.387 0 0 0-.381.381v3.813a.387.387 0 0 0 .381.381h3.813a.387.387 0 0 0 .381-.381Zm0 6.863V7.244a.387.387 0 0 0-.381-.381h-3.813a.387.387 0 0 0-.381.381v3.813a.387.387 0 0 0 .381.381h3.813a.387.387 0 0 0 .381-.382Zm0 6.863v-3.814a.387.387 0 0 0-.381-.381h-3.813a.387.387 0 0 0-.381.381v3.813a.387.387 0 0 0 .381.381h3.813a.387.387 0 0 0 .381-.381Zm-5.338-16.2a.184.184 0 0 0-.191-.191h-.571A7.267 7.267 0 0 1 14.647.81 8.736 8.736 0 0 0 11.311 0h-.572a.184.184 0 0 0-.191.191v1.143a.184.184 0 0 0 .191.191h.572a7.267 7.267 0 0 1 2.764.715 8.736 8.736 0 0 0 3.336.81h.572a.184.184 0 0 0 .191-.191Zm0 6.863a.184.184 0 0 0-.191-.191h-.571c-.747 0-1.636-1.255-2.431-2.351-1.048-1.466-2.128-2.991-3.669-2.991h-.572a.184.184 0 0 0-.191.191v1.143a.184.184 0 0 0 .191.191h.572c.747 0 1.636 1.255 2.431 2.351 1.048 1.461 2.129 2.987 3.67 2.987h.572a.184.184 0 0 0 .191-.191Zm0 6.863a.184.184 0 0 0-.191-.191h-.571c-.667 0-2.017-2.7-2.748-4.146-1.287-2.558-2.4-4.8-3.908-4.988a.188.188 0 0 0-.207.191v1.16a.206.206 0 0 0 .127.191c.7.27 1.938 2.764 2.621 4.13 1.334 2.685 2.494 4.988 4.114 4.988h.572a.184.184 0 0 0 .191-.191v-1.149Z"
        data-name="Path 423"
      />
      <path
        fill="none"
        stroke="#008954"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.8"
        d="m9.15 12.961-5.415 5.415-2.462-2.461"
        data-name="Icon feather-check"
      />
    </g>
  </SvgIcon>
);
