import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { VARIANT } from 'utilities/constants';
import { colorPalette } from 'theme';

interface HexDoneIconProps {
  height?: string;
  width?: string;
  variant?: VARIANT.CONTAINED | VARIANT.OUTLINED;
  strokeColor?: string;
  fillColor?: string;
}

export const HexDoneIcon: React.FC<HexDoneIconProps> = ({
  height = '50px',
  width = '50px',
  variant = VARIANT.CONTAINED,
  strokeColor = colorPalette.darkGreen,
  fillColor = colorPalette.highlightGreen
}) => {
  return (
    <SvgIcon sx={{ width: width, height: height }} viewBox="0 0 88 90">
      {variant === VARIANT.CONTAINED && (
        <path
          d="M0.46875 44.028C0.46875 19.8274 20.0882 0.208984 44.2887 0.208984C68.4893 0.208984 88.1087 19.8274 88.1087 44.028C88.1087 68.2285 68.4893 87.847 44.2887 87.847C20.0882 87.847 0.46875 68.2285 0.46875 44.028Z"
          fill={fillColor}
        />
      )}
      <path
        d="M60.998 35.3034L56.6513 30.999L38.0787 49.3833L30.3448 41.7319L25.998 46.0363L38.0787 57.999L60.998 35.3034Z"
        fill={strokeColor}
      />
      <path
        d="M71.998 56.3432V31.7121C71.9969 30.6323 71.7119 29.5717 71.1715 28.6368C70.6311 27.7019 69.8543 26.9256 68.9192 26.3856L47.367 14.0701C46.4309 13.5296 45.369 13.2451 44.2881 13.2451C43.2072 13.2451 42.1453 13.5296 41.2092 14.0701L19.657 26.3856C18.7218 26.9256 17.9451 27.7019 17.4047 28.6368C16.8643 29.5717 16.5792 30.6323 16.5781 31.7121V56.3432C16.5792 57.423 16.8643 58.4836 17.4047 59.4185C17.9451 60.3534 18.7218 61.1297 19.657 61.6697L41.2092 73.9852C42.1453 74.5256 43.2072 74.8102 44.2881 74.8102C45.369 74.8102 46.4309 74.5256 47.367 73.9852L68.9192 61.6697C69.8543 61.1297 70.6311 60.3534 71.1715 59.4185C71.7119 58.4836 71.9969 57.423 71.998 56.3432Z"
        stroke={strokeColor}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
};
