import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import AuthContext, { formTypes } from 'contexts/AuthContext';
import DataContext from 'contexts/DataContext';
import { Solution } from 'components/CardSolution';
import { Application } from 'components/CardApplication';
import dataProvider from 'dataPrvider';
import ConfirmationModal from 'modals/ConfirmationModal/ConfirmationModal';
import ModalSmall from 'modals/ModalSmall';
import { EditSolutionModal } from 'modals/EditSolutionModal';
import Actions from 'redux-state/actions';
import { colorPalette } from 'theme';
import { Constants } from 'utilities/constants';
import {
  DelIconText,
  EditIconText,
  MainBox,
  NodeItemMainBox,
  NodeItemWrapper,
  StyledIconButton
} from './styles';
import { StepComponentProps } from '../../types';
import { NodeContentBase } from 'components/InteractiveNodeContainer/NodeContent/NodeContentBase';

export const ReviewSolutions: React.FC<StepComponentProps> = ({
  concept,
  profileId,
  pagination,
  handleClose,
  onTaskComplete
}) => {
  const { user } = useContext(AuthContext);
  const [selectedSolution, setSelectedSolution] = useState<Solution>();
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showConfModal, setShowConfModal] = useState<boolean>(false);
  const [selectedSolutionIds, setSelectedSolutionIds] = useState<string[]>([]);

  const dispatch = useDispatch();

  const { setAlertContent } = useContext(DataContext);

  const solutions = useMemo(
    () =>
      concept.solutions.map((item) => ({ ...item, type: Constants.SOLUTION })),
    [concept.solutions]
  );

  const onEditClick = useCallback((event, solution) => {
    setSelectedSolution(solution);
    setShowEditModal(true);
  }, []);

  const onDelClick = useCallback((event, solution) => {
    setSelectedSolution(solution);
    setShowConfModal(true);
  }, []);

  const handleCloseEditModal = useCallback(() => {
    setShowEditModal(false);
  }, []);

  const handleCloseConfModal = useCallback(() => {
    setShowConfModal(false);
  }, []);

  const getConceptWithUnlinkedSolution = () => {
    const filteredSolutions = solutions
      .filter((solution) => {
        return solution.id && solution.id !== selectedSolution.id;
      })
      .map((solution) => {
        return solution.id;
      });
    return { selected: filteredSolutions };
  };

  const handleUnlinkSolution = () => {
    if (user) {
      dispatch(
        Actions.editProfileItem({
          type: Constants.APPLICATIONS,
          id: concept.id,
          profileId,
          pagination,
          data: getConceptWithUnlinkedSolution()
        })
      );
    } else {
      dispatch(Actions.openAuthModal(true));
    }
  };

  interface IconProps {
    node: any;
  }

  const DeleteIcon = useCallback(
    ({ node }: IconProps) => {
      return (
        <StyledIconButton>
          <Delete
            sx={{
              color: colorPalette.red,
              margin: '0 0rem .125rem 0',
              height: '20px',
              width: '20px'
            }}
          />
          <DelIconText>Delete</DelIconText>
        </StyledIconButton>
      );
    },
    [onDelClick]
  );

  const EditIcon = useCallback(
    ({ node }: IconProps) => {
      return (
        <StyledIconButton>
          <Edit
            sx={{
              color: colorPalette.purple,
              margin: '0 0rem .125rem 0',
              height: '20px',
              width: '20px'
            }}
          />
          <EditIconText sx={{ color: colorPalette.purple }}>Edit</EditIconText>
        </StyledIconButton>
      );
    },
    [onEditClick]
  );

  const conceptSolutions = useMemo(() => {
    return concept.problems.flatMap((problem) =>
      Array.isArray(problem.children)
        ? problem.children
            .filter(
              (child) =>
                child.id &&
                child.solutionTeaser &&
                !concept.selected.includes(child.id)
            )
            .map((child) => ({
              id: child.id,
              teaser: child.solutionTeaser
            }))
        : []
    );
  }, [concept.problems, concept.selected]);

  const handleSolutionSelectChange = (event) => {
    setSelectedSolutionIds(event.target.value);
  };

  const handleSubmit = () => {
    // Filter and combine selected solution IDs in one go
    const combinedSelectedSolutionIds = Array.from(
      new Set([
        ...concept.selected,
        ...conceptSolutions
          .filter((solution) => selectedSolutionIds.includes(solution.id))
          .map((solution) => solution.id)
      ])
    );

    dataProvider
      .update<Application>(Constants.APPLICATIONS, {
        id: concept.id,
        data: { selected: combinedSelectedSolutionIds }
      })
      .then(() => {
        onTaskComplete();
        setSelectedSolutionIds([]);
      })
      .catch((error) => {
        console.error('Update failed:', error);
      });
  };

  const solutionsNodes = useMemo(
    () =>
      solutions.map((solution, index) => (
        <NodeItemWrapper key={`solution-${index}`}>
          <Typography
            sx={{
              color: colorPalette.darkGray,
              fontSize: '18',
              fontWeight: '500',
              marginRight: '.625rem'
            }}
          >
            {`${index + 1}.`}
          </Typography>
          <NodeContentBase
            node={solution}
            key={index}
            buttonList={[
              {
                component: EditIcon,
                onClick: onEditClick
              },
              {
                component: DeleteIcon,
                onClick: onDelClick
              }
            ]}
          />
        </NodeItemWrapper>
      )),
    [DeleteIcon, EditIcon, solutions]
  );

  useEffect(() => {
    if (solutions && solutions.length < 1) {
      handleClose();
      setAlertContent(
        <>
          You need to add at least one solution to the invention before minting
          NFT
        </>
      );
      return;
    }
  }, [solutions]);

  return (
    <MainBox>
      <NodeItemMainBox>{solutionsNodes}</NodeItemMainBox>
      <Typography variant="h6" sx={{ marginTop: '16px' }}>
        Select Solutions
      </Typography>
      <FormControl fullWidth sx={{ marginTop: '16px' }}>
        <Select
          labelId="select-solutions-label"
          multiple
          value={selectedSolutionIds}
          onChange={handleSolutionSelectChange}
          renderValue={(selected) =>
            selected
              .map((id) => {
                const solution = conceptSolutions.find((s) => s.id === id);
                return solution ? solution.teaser : '';
              })
              .join(', ')
          }
        >
          {conceptSolutions.map((solution) => (
            <MenuItem key={solution.id} value={solution.id}>
              <Checkbox checked={selectedSolutionIds.includes(solution.id)} />
              <ListItemText primary={solution.teaser} />
            </MenuItem>
          ))}
        </Select>
        <Button
          color="primary"
          onClick={handleSubmit}
          variant="contained"
          sx={{ marginTop: '16px' }}
          disabled={selectedSolutionIds.length === 0}
        >
          {Constants.ADD_SOLUTION}
        </Button>
      </FormControl>
      {selectedSolution && (
        <EditSolutionModal
          solution={selectedSolution}
          open={showEditModal}
          onClose={handleCloseEditModal}
          profileId={profileId}
          pagination={pagination}
        />
      )}
      <ModalSmall isOpen={showConfModal} onClose={handleCloseConfModal}>
        <ConfirmationModal
          action={'unlink'}
          item={selectedSolution}
          onClose={handleCloseConfModal}
          onDelete={handleUnlinkSolution}
          title={Constants.SOLUTIONS}
        />
      </ModalSmall>
    </MainBox>
  );
};
