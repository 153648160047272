import React from 'react';
import { Box, Grid } from '@mui/material';
import { colorPalette } from 'theme';
import { Constants } from 'utilities/constants';
import { TagInfo } from 'components/CardTag';
import { getBasePath } from 'helpers/common';
import { Node } from 'interface/common';
import { ChipList } from 'components/Tag';
import { LogoMindminer } from 'components/icons/LogoMindminer';
import { InventionPatentContent } from './InventionPatentContent';
import Footer from '../Footer';
import {
  ContentContainer,
  HeadingVotePanelContainer,
  IdeaIconContainer,
  StyledHeading,
  StyledTextContentWrapper,
  StyledTextWrapper,
  StyledTypography,
  TextContainer
} from '../StyledComponents';

interface ContentProps {
  displayedTags?: Array<TagInfo>;
  item: Node;
  showAllTags?: boolean;
  votingPanel?: React.ReactNode;
  onShowAllTagsClick?: () => void;
}

export const InventionContent: React.FC<ContentProps> = ({
  displayedTags,
  item,
  onShowAllTagsClick,
  showAllTags,
  votingPanel
}) => {
  const { title, ideaPoints, type } = item;

  return (
    <ContentContainer>
      <Grid md={12} sm={12}>
        <HeadingVotePanelContainer>
          <StyledTypography type={type}>
            {Constants.C_INVENTION}:
          </StyledTypography>
          <Grid>{votingPanel}</Grid>
        </HeadingVotePanelContainer>
        <StyledTextWrapper>{title}</StyledTextWrapper>

        <ChipList
          list={displayedTags}
          max={5}
          showAll={showAllTags}
          onShowAllTagsClick={onShowAllTagsClick}
          getName={(inventionTag) => {
            const name = (inventionTag as TagInfo).name || '';
            return name;
          }}
          getUrl={(inventionTag) => {
            const basePath = getBasePath(inventionTag);
            return `/${basePath}/${
              (inventionTag as TagInfo).key || (inventionTag as TagInfo).id
            }`;
          }}
        />
        <InventionPatentContent />

        <StyledHeading>{Constants.C_CONTESTS}:</StyledHeading>
        <StyledTextContentWrapper>
          {Constants.INVENTION_CONTEST}
        </StyledTextContentWrapper>

        <StyledHeading>{Constants.TOTAL_EARNINGS}:</StyledHeading>
        <TextContainer>
          <IdeaIconContainer>
            <LogoMindminer fill={colorPalette.purple} width={36} height={36} />
            <Box>
              <StyledTextContentWrapper>
                <span style={{ color: colorPalette.purple, fontWeight: 700 }}>
                  {ideaPoints} {Constants.IDEA_POINTS}
                </span>
                {Constants.PROBLEM_IDEAPOINTS_TEXT}
              </StyledTextContentWrapper>
            </Box>
          </IdeaIconContainer>
        </TextContainer>
        <Footer item={item} />
      </Grid>
    </ContentContainer>
  );
};
