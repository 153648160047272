/* eslint-disable */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Actions from 'redux-state/actions';
import { Box, Drawer, Typography } from '@mui/material';
import {
  GetDownloadPatentLoader,
  GetEditProfileItemLoader,
  GetProfileInventions,
  GetProfileInventionsCount,
  GetProfileInventionsLoader,
  GetProfilePinnedInventions
} from 'redux-state/selectors';
import MenuIcon from '@mui/icons-material/Menu';
import { LinearProgress, Button, IconButton } from '@mui/material';
import InventionIcon from 'assets/icons/invention.svg';
import getQueryParams from 'helpers/getQueryParams';
import { CHANNELS, Constants } from 'utilities/constants';
import { colorPalette, useIsMediumScreen } from 'theme';
import { Profile } from '../CardProfile';
import pusher from '../../config/pusherConfig';
import ConceptList from '../ConceptView/ConceptList';
import { StyledConceptDetailBox } from '../ConceptView/styles';
import { ConceptDetailView } from '../ConceptView/ConceptDetail';
import DataNotFound from '../DataNotFound';

interface InventionViewProps {
  profile: Profile;
  archiveStatus: boolean;
  handleArchiveToggle: (archive: boolean) => void;
}

const InventionView: React.FC<InventionViewProps> = ({
  archiveStatus,
  handleArchiveToggle,
  profile
}) => {
  const dispatch = useDispatch();

  const [selectedInventionId, setSelectedInventionId] = useState<
    number | string | null
  >();
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const inventions = GetProfileInventions();
  const inventionsLoader = GetProfileInventionsLoader();
  const pinnedInventions = GetProfilePinnedInventions();
  const inventionsCount = GetProfileInventionsCount();
  const downloadLoader: boolean = GetDownloadPatentLoader();
  const editProfileLoader: boolean = GetEditProfileItemLoader();
  const selectedInvention = inventions.find(
    (invention) => invention.id === selectedInventionId
  );

  const isMediumScreen = useIsMediumScreen();

  const paramsPage = Number(getQueryParams(Constants.PAGE));
  const paramsPerPage = Number(getQueryParams(Constants.PER_PAGE));

  const [page, setPage] = useState<number>(paramsPage ?? 0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(paramsPerPage || 5);

  const paginationOptions = useMemo(
    () => ({
      page: page,
      rowsPerPage: rowsPerPage,
      setPage: setPage,
      setRowsPerPage: setRowsPerPage
    }),
    [page, rowsPerPage, setRowsPerPage, setPage]
  );

  const pagination = useMemo(
    () => ({
      page: page,
      perPage: rowsPerPage
    }),
    [page, rowsPerPage]
  );

  const fetchProfileConcepts = useCallback(() => {
    if (profile) {
      dispatch(
        Actions.getProfileInventions(
          profile.id,
          pagination,
          null,
          archiveStatus,
          true
        )
      );
    }
  }, [dispatch, profile, pagination, archiveStatus]);

  useEffect(() => {
    fetchProfileConcepts();
  }, [fetchProfileConcepts, dispatch]);

  useEffect(() => {
    const channel = pusher.subscribe(CHANNELS.VIDEO_GENERATION_CHANNEL);
    channel.bind(Constants.VIDEOS, function (data) {
      dispatch(
        Actions.getProfileConcepts(profile.id, pagination, null, archiveStatus)
      );
    });
    return () => {
      channel.unbind(Constants.VIDEOS);
      pusher.unsubscribe(CHANNELS.VIDEO_GENERATION_CHANNEL);
    };
  }, [dispatch, profile.id, pagination, archiveStatus]);

  const toggleDrawer = (open: boolean) => {
    setDrawerOpen(open);
  };

  if (!inventionsLoader && inventions.length === 0) {
    return (
      <DataNotFound
        iconUrl={InventionIcon}
        text={Constants.INVENTIONS}
        isInventionView={true}
      />
    );
  }

  return (
    <Box
      display="flex"
      width="100%"
      sx={{ backgroundColor: colorPalette.white }}
    >
      {(downloadLoader || editProfileLoader || inventionsLoader) && (
        <LinearProgress />
      )}
      {!isMediumScreen ? (
        <>
          <Box width="35%" padding={2}>
            <ConceptList
              concepts={inventions}
              selectedConceptId={selectedInventionId}
              onSelectConcept={setSelectedInventionId}
              totalRows={inventionsCount}
              paginationOptions={paginationOptions}
              isConcept={false}
            />
          </Box>
          <StyledConceptDetailBox width={'63%'}>
            <ConceptDetailView
              selectedConceptId={selectedInventionId}
              profile={profile}
              archiveStatus={archiveStatus}
              pagination={pagination}
              fetchProfileConcepts={fetchProfileConcepts}
              isConcept={false}
            />
          </StyledConceptDetailBox>
        </>
      ) : (
        <>
          <StyledConceptDetailBox width={'100%'}>
            <Box display={'flex'} flexDirection={'row'}>
              <IconButton
                aria-label="open drawer"
                onClick={() => toggleDrawer(true)}
                sx={{ color: colorPalette.purple }}
              >
                <MenuIcon sx={{ fontSize: 30 }} />
                Invention List
              </IconButton>
            </Box>
            <ConceptDetailView
              selectedConceptId={selectedInventionId}
              profile={profile}
              archiveStatus={archiveStatus}
              pagination={pagination}
              fetchProfileConcepts={fetchProfileConcepts}
              isConcept={false}
            />
          </StyledConceptDetailBox>
          <Drawer
            anchor="left"
            open={drawerOpen}
            onClose={() => toggleDrawer(false)}
            PaperProps={{
              sx: { height: '80vh', top: '100px', position: 'fixed' }
            }}
          >
            <Box
              width="300px"
              height="100%"
              padding={2}
              role="presentation"
              sx={{ overflow: 'auto' }}
            >
              <Typography variant="h6" mb={2}>
                Select a Concept
              </Typography>
              <ConceptList
                concepts={inventions}
                selectedConceptId={selectedInventionId}
                onSelectConcept={(id) => {
                  setSelectedInventionId(id);
                  toggleDrawer(false);
                }}
                totalRows={inventionsCount}
                paginationOptions={paginationOptions}
                isConcept={false}
              />
            </Box>
          </Drawer>
        </>
      )}
    </Box>
  );
};

export { InventionView };
