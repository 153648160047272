import { useCallback, useMemo } from 'react';
import Actions from 'redux-state/actions';
import { Constants, REACTION_TYPES } from 'utilities/constants';
import { formatNumber, handleReaction } from 'helpers';
import Dislike from 'components/common/Dislike';
import Like from 'components/common/Like';
import { ReactionButtons, StyledLikes } from './StyledComponents';

export const useVoting = ({
  dispatch,
  dislikes,
  graphData,
  id,
  isLiked,
  isParentNode,
  like,
  likes,
  node,
  setLike,
  type,
  user,
  voteItem
}) => {
  const handleActionButton = useCallback(
    (reactionType: string) => {
      if (!user) {
        return dispatch(Actions.openAuthModal(true));
      }

      if (graphData) {
        if (reactionType === Constants.LIKE) {
          voteItem(isLiked ? 0 : 1, node);
          handleReaction(graphData, id, REACTION_TYPES.LIKE);
          setLike(!isLiked);
        } else {
          voteItem(-1, node);
          handleReaction(graphData, id, REACTION_TYPES.DISLIKE);
        }

        dispatch(Actions.setGraph(graphData));
      }
    },
    [dispatch, graphData, id, isLiked, node, setLike, user, voteItem]
  );

  const VotingSection = useMemo(
    () => (
      <ReactionButtons type={type || 'default'}>
        <Like
          disabledButtons={isParentNode}
          handleActionButton={handleActionButton}
          isLiked={isLiked}
        />
        <StyledLikes>{formatNumber(likes)}</StyledLikes>
        <Dislike
          disabledButtons={isParentNode}
          handleActionButton={handleActionButton}
        />
        <StyledLikes>{formatNumber(dislikes)}</StyledLikes>
      </ReactionButtons>
    ),
    [dislikes, handleActionButton, isParentNode, isLiked, likes, type]
  );

  return { handleActionButton, VotingSection };
};
