/* eslint-disable */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Actions from 'redux-state/actions';
import { Badge, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Popover, {
  PopoverOrigin,
  PopoverPosition,
  PopoverReference
} from '@mui/material/Popover';
import { styled, useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import NewsFeedShareIcon from 'assets/icons/share_feed.svg';
import { Application } from 'components/CardApplication';
import { Challenge } from 'components/CardChallenge';
import { Contest } from 'components/CardContest';
import { PriorArt } from 'components/CardPriorArt';
import { Problem } from 'components/CardProblem';
import { Product } from 'components/CardProduct';
import { Profile } from 'components/CardProfile';
import { Solution } from 'components/CardSolution';
import { Tag, TagInfo } from 'components/CardTag';
import { GreenCounter } from 'components/common/GreenCounter';
import { PsButton } from 'components/common/PsButton';
import { PsFormControl, ShortEvent } from 'components/common/PsFormControl';
import { PsInput } from 'components/common/PsInput';
import { PsLogo } from 'components/common/PsLogo';
import Config from 'config/config';
import { AuthContext } from 'contexts/AuthContext';
import { DataContext } from 'contexts/DataContext';
import {
  ShareButtonText,
  StyledButtonWrapper,
  StyledShareImage
} from 'pages/home/StyledComponents';
import {
  GetCalculateMultiplierIdeaPoints,
  GetSocialAuthKey,
  GetUser
} from 'redux-state/selectors';
import { colorPalette, PsTheme, useIsMediumScreen } from 'theme';
import { makeStyles } from 'tss-react/mui';
import { Constants, ERRORS, URLS } from 'utilities/constants';
import { RequireAtLeastOne } from 'helpers';
import 'react-toastify/dist/ReactToastify.css';

type Entity = {
  problem: Problem;
  solution: Solution;
  application: Application;
  tag: Tag;
  profile: Profile;
  contest: Contest;
  product: Product;
  priorArt: PriorArt;
  challenge: Challenge;
};

export type OneOfEntity = RequireAtLeastOne<
  Entity,
  | 'problem'
  | 'solution'
  | 'application'
  | 'product'
  | 'priorArt'
  | 'tag'
  | 'profile'
  | 'contest'
  | 'challenge'
  | any
>;

const getResourceType = (entity: OneOfEntity): string => {
  if (entity.problem) {
    return 'problems';
  } else if (entity.solution) {
    return 'solutions';
  } else if (entity.application) {
    return 'applications';
  } else if (entity.product) {
    return 'products';
  } else if (entity.priorArt) {
    return 'prior-arts';
  } else if (entity.tag) {
    return 'tags';
  } else if (entity.contest) {
    return 'contests';
  } else if (entity.challenge) {
    return 'challenges';
  } else {
    return 'profiles';
  }
};

const getResourceId = (entity: OneOfEntity, key = 'id'): string | number => {
  if (entity.problem) {
    return entity.problem[key];
  } else if (entity.solution) {
    return entity.solution[key];
  } else if (entity.application) {
    return entity.application[key];
  } else if (entity.product) {
    return entity.product[key];
  } else if (entity.priorArt) {
    return entity.priorArt[key];
  } else if (entity.tag) {
    return entity.tag[key];
  } else if (entity.contest) {
    return entity.contest[key];
  } else if (entity.challenge) {
    return entity.challenge[key];
  } else if (entity.profile) {
    return entity.profile[key];
  }
  return '';
};

const getResourceName = (entity: OneOfEntity): string | undefined => {
  if (entity.problem) {
    return entity.problem.teaser || entity.problem.title;
  } else if (entity.solution) {
    return entity.solution.teaser || entity.solution.title;
  } else if (entity.application) {
    return entity.application.teaser || entity.application.title;
  } else if (entity.product) {
    return entity.product.title;
  } else if (entity.priorArt) {
    return entity.priorArt.title;
  } else if (entity.tag) {
    return entity.tag.name;
  } else if (entity.contest) {
    return entity.contest.title;
  } else if (entity.challenge) {
    return entity.challenge.title;
  } else if (entity.profile) {
    return entity.profile.username;
  }
  return '';
};

const getShareText = (
  entity: OneOfEntity,
  tagsList?: Array<TagInfo>
): string => {
  let text = '';
  let tagsStr = '';
  if (tagsList && tagsList.length) {
    const tagNames = tagsList.filter((t) => t.name).map((t) => `#${t.name}`);
    tagsStr = ` in the ${tagNames.join(', ')} group`;
  }
  if (entity?.item?.itemType === Constants.PROBLEM) {
    text = `${Constants.EARN_IDEACOINS} by solving the problem of ${entity.item.title}${tagsStr} ${Constants.ON_MINDMINER}.`;
  } else if (entity?.item?.itemType === Constants.SOLUTION) {
    text = `${Constants.EARN_IDEACOINS} by improving the solution of ${entity.item.title}${tagsStr} ${Constants.ON_MINDMINER}.`;
  } else if (entity?.item?.itemType === Constants.APPLICATION) {
    text = `${Constants.EARN_IDEACOINS} by improving the invention of ${entity.item.itemId.title}${tagsStr} ${Constants.ON_MINDMINER}.`;
  } else if (entity?.item?.itemType === Constants.PRODUCT) {
    text = `${Constants.EARN_IDEACOINS} by improving the product of ${entity.item.itemId.title}${tagsStr} ${Constants.ON_MINDMINER}.`;
  } else if (entity?.priorArt) {
    text = `${Constants.EARN_IDEACOINS} by improving the prior art of ${entity.priorArt.title}${tagsStr} ${Constants.ON_MINDMINER}.`;
  } else if (entity?.tag) {
    text = `${Constants.EARN_IDEACOINS} with ${entity.tag.name} MindMiners.`;
  } else if (entity?.profile) {
    text = `${Constants.EARN_IDEACOINS} with ${entity.profile.username} ${Constants.ON_MINDMINER}.`;
  } else if (entity?.challenge) {
    text = `${Constants.EARN_IDEACOINS} with ${entity.challenge.title} challenge ${Constants.ON_MINDMINER}.`;
  } else if (entity?.item?.itemType === Constants.CONTEST) {
    const coins = entity?.item?.itemId?.bountyCoins;
    const name = entity?.item?.itemId?.title;
    const tag = entity?.item?.tags?.[0]?.name;
    text = `Earn the ${coins} IdeaCoin jackpot by solving the ${name} problems in the ${tag} group ${Constants.ON_MINDMINER}"`;
  }
  return text;
};

const rewardType = {
  SHARE_FB: 'share-fb',
  SHARE_LN: 'share-ln',
  SHARE_TW: 'share-tw',
  SHARE_IN: 'share-in',
  SHARE_WA: 'share-wa',
  SHARE_RB: 'share-rb',
  SHARE_TT: 'share-tt',
  SHARE_DC: 'share-dc'
};

const ShareIcon = () => {
  const isMediumScreen = useIsMediumScreen();
  return (
    <svg
      width={isMediumScreen ? '15px' : '20px'}
      height={isMediumScreen ? '15px' : '20px'}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M18 8C19.6569 8 21 6.65685 21 5C21 3.34315 19.6569 2 18 2C16.3431 2 15 3.34315 15 5C15 6.65685 16.3431 8 18 8Z"
        stroke="currentColor"
        strokeWidth="1.76842"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 15C7.65685 15 9 13.6569 9 12C9 10.3431 7.65685 9 6 9C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15Z"
        stroke="currentColor"
        strokeWidth="1.76842"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 22C19.6569 22 21 20.6569 21 19C21 17.3431 19.6569 16 18 16C16.3431 16 15 17.3431 15 19C15 20.6569 16.3431 22 18 22Z"
        stroke="currentColor"
        strokeWidth="1.76842"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.59058 13.51L15.4206 17.49"
        stroke="currentColor"
        strokeWidth="1.76842"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4106 6.51001L8.59058 10.49"
        stroke="currentColor"
        strokeWidth="1.76842"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

type ClassKey =
  | 'holder'
  | 'button'
  | 'buttonIcon'
  | 'buttonLogo'
  | 'coins'
  | 'popover'
  | 'root'
  | 'paper'
  | 'title'
  | 'social'
  | 'input'
  | 'submit'
  | 'row'
  | 'newShareButton';

const useStyles = makeStyles()((theme) => {
  const psTheme = theme as PsTheme;
  return {
    holder: {},
    button: {
      padding: '8px',
      color: psTheme.palette.primary.main
    },
    buttonIcon: {},
    buttonLogo: {
      position: 'absolute',
      top: -6,
      right: -9
    },
    coins: {
      position: 'absolute',
      top: -8,
      left: '100%',
      paddingLeft: 12,
      color: psTheme.palette.primary.main
    },
    popover: {
      // pointerEvents: 'none',
    },
    root: {},
    paper: {
      width: 380,
      margin: '0.625rem 0',
      padding: '1.5rem 1.375rem 0.9375rem',
      borderRadius: 10,
      border: '1px solid rgba(121, 114, 140, 0.11)',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'
    },
    title: {
      fontSize: 18,
      marginBottom: 20,
      '& b': {
        fontWeight: 600
      }
    },
    social: {
      padding: '1.0625rem 0',
      '& > a': {
        marginRight: 20,
        marginBottom: 20
      },
      '& > a:last-child': {
        marginRight: 0
      }
    },
    input: {
      marginBottom: 15,
      '& label': {
        fontSize: 14,
        color: psTheme.palette.third.main
      }
    },
    submit: {
      paddingLeft: 15,
      paddingRight: 15,
      borderRadius: 10,
      lineHeight: '28px',
      minHeight: 40,
      '& svg': {
        marginLeft: 15
      }
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 10,
      '& > span': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
      },
      '& b': {
        fontWeight: 600
      },
      '& .coin': {
        marginLeft: 5
      }
    },
    newShareButton: {
      display: 'inline-flex',
      alignItems: 'center',
      color: '#677890',
      marginLeft: 50,
      '& > button': {
        background: 'none',
        border: 'none',
        outline: 'none',
        margin: '0',
        padding: '0',
        cursor: 'pointer',
        appearance: 'none'
      },
      '& > span': {
        paddingLeft: 10,
        fontSize: 18,
        lineHeight: '20px'
      }
    }
  };
});

type ShareBtnProps = OneOfEntity & {
  className?: string;
  isMainFeed?: boolean;
  item?: Problem | Solution | Product | Application;
  itemId?: string;
  itemType?: string;
  passedAnchor?: any;
  tagsList?: Array<TagInfo>;
  title?: string;
  entity?: OneOfEntity;
};

const ShareBtnView = ({
  className,
  isMainFeed,
  item,
  itemId,
  itemType,
  passedAnchor,
  tagsList,
  title,
  ...entity
}: ShareBtnProps) => {
  const { classes } = useStyles();
  const { setAuthModalOpened } = useContext(AuthContext);
  const { showToast } = useContext(DataContext);
  const isMediumScreen = useIsMediumScreen();
  const { dataProvider, sharePopupInfo, showSharePopup } =
    useContext(DataContext);
  const psTheme: PsTheme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [email, setEmail] = useState('');
  const [emailSuccess, setEmailSuccess] = useState('');
  const [emailError, setEmailError] = useState('');
  const [emailLoading, setEmailLoading] = useState(false);
  const open = Boolean(anchorEl);
  const [sharePopupCenter, setSharePopupCenter] = useState<
    PopoverPosition | undefined
  >();
  const dispatch = useDispatch();
  const user = GetUser();
  const socialAuthKeys: any = GetSocialAuthKey();
  const resourceType = getResourceType(entity);
  const resourceId = getResourceId(entity);
  const resourceKey = getResourceId(entity, 'key');
  const resourceName = getResourceName(entity) || '(empty)';
  const shareText = getShareText(entity, tagsList);
  const multiplierIdeapoints = GetCalculateMultiplierIdeaPoints();
  const ideaPoints =
    item?.itemId?.likes === 0 && item?.itemId?.dislikes >= 3
      ? 0
      : multiplierIdeapoints === 0
        ? Constants.DEFAULT_SHARE_ITEM_POINTS
        : multiplierIdeapoints;

  let shareUrl = '';
  if (typeof window !== 'undefined') {
    shareUrl = `${window.location.origin}/${
      itemType
        ? itemType == Constants.APPLICATION
          ? Constants.INVENTIONS
          : `${itemType}s`
        : resourceType
    }/${itemId ?? resourceKey}?${Constants.REFERRAL_CODE}=${
      user?.id
    }&showModal=${
      itemType
        ? itemType == Constants.APPLICATION
          ? Constants.INVENTIONS
          : `${itemType}s`
        : resourceType
    }`;
  }

  const shareTextE = encodeURIComponent(shareText + ' ');
  const shareUrlE = encodeURIComponent(shareUrl);
  const twitterShareUrl = `${URLS.TWITTER}/intent/tweet?text=${shareTextE}&url=${shareUrlE}`;
  const watsappShareUrl = `${URLS.WHATSAPP_SHARE}/send?text=${Config.NEXT_PUBLIC_API_URL}/share/facebookShare?shareUrl=${shareUrl}`;
  const redditShareUrl = `${URLS.REDDIT}/submit?url=${shareUrlE}&title=${shareTextE}`;
  const facebookShareUrl = `${URLS.FACEBOOK}/dialog/share?app_id=${Config.NEXT_PUBLIC_FB_APP_ID}&display=popup&href=${Config.NEXT_PUBLIC_API_URL}/share/facebookShare?shareUrl=${shareUrl}`;

  useEffect(() => {
    if (sharePopupInfo && typeof window !== 'undefined') {
      const left = window.innerWidth / 2;
      const top = window.innerHeight / 2;
      setSharePopupCenter({ top, left });
    } else {
      setSharePopupCenter(undefined);
    }
  }, [sharePopupInfo]);

  const onEmailChange = useCallback(
    (e: ShortEvent) => {
      setEmail(e.target.value);
      setEmailError('');
      setEmailSuccess('');
    },
    [setEmail]
  );

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    console.log('open plz');
    event.preventDefault();
    event.stopPropagation();
    if (!user) {
      dispatch(Actions.openAuthModal(true));
      return;
    }
    setAnchorEl(passedAnchor ?? event.currentTarget);
    dispatch(
      Actions.calculateMultiplierIdeaPoints({
        defaultIdeaPoints: Constants.DEFAULT_SHARE_ITEM_POINTS,
        likes: item?.itemId?.likes || 0,
        dislikes: item?.itemId?.dislikes || 0
      })
    );
  };

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
    showSharePopup(undefined);
  }, [setAnchorEl]);

  const onFacebookShareClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(Actions.getSocialAuthKeys(user?.id));

    const facebookShare = socialAuthKeys.find(
      (key) => key.type == Constants.FACEBOOK && key.isShared == true
    );

    if (facebookShare) {
      try {
        if (typeof window !== 'undefined') {
          const left = window.innerWidth / 2 - 350;
          const top = window.innerHeight / 2 - 350;
          window.open(
            facebookShareUrl,
            '_blank',
            `width=700,height=700,left=${left},top=${top}`
          );
        }
        if (user) {
          dataProvider.trackSharing(
            itemId ?? resourceKey,
            itemType ? `${itemType}s` : resourceType,
            rewardType.SHARE_FB,
            itemType,
            itemId
          );
        }
      } catch (error) {
        console.error(Constants.ERROR_FACEBOOK, error);
      }
    } else {
      showToast(ERRORS.CONNECT_FACEBOOK_ACCOUNT, {
        style: {
          backgroundColor: colorPalette.white,
          bottom: 0,
          color: colorPalette.black,
          left: 77,
          position: 'absolute'
        },
        autoHideDuration: 5000
      });
    }
  };

  const onWatsappShareClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      if (typeof window !== 'undefined') {
        const left = window.innerWidth / 2 - 350;
        const top = window.innerHeight / 2 - 350;
        window.open(
          watsappShareUrl,
          '_blank',
          `width=700,height=700,left=${left},top=${top}`
        );
      }
      if (user) {
        dataProvider
          .trackSharing(
            itemId ?? resourceKey,
            itemType ? `${itemType}s` : resourceType,
            rewardType.SHARE_WA,
            itemType,
            itemId
          )
          .catch((err: Error) => {
            console.error(err);
          });
      }
    },
    [
      user,
      watsappShareUrl,
      dataProvider,
      itemId,
      resourceKey,
      itemType,
      resourceType
    ]
  );

  const onShareClick = useCallback(
    (platform: string) => {
      dispatch(Actions.shareToSocialMedia({ platform, shareUrl }));
      if (user) {
        let shareRewardType = '';
        switch (platform) {
          case Constants.LINKEDIN:
            shareRewardType = rewardType.SHARE_LN;
            break;
          case Constants.TWITTER:
            shareRewardType = rewardType.SHARE_TW;
            break;
          case Constants.REDDIT:
            shareRewardType = rewardType.SHARE_RB;
            break;
          case Constants.DISCORD:
            shareRewardType = rewardType.SHARE_DC;
            break;
          case Constants.INSTAGRAM:
            shareRewardType = rewardType.SHARE_IN;
            break;
          default:
            shareRewardType = '';
        }
        platform;
        dataProvider
          .trackSharing(
            itemId ?? resourceKey,
            itemType ? `${itemType}s` : resourceType,
            shareRewardType,
            itemType,
            itemId
          )
          .catch((err: Error) => {
            console.error(err);
          });
      }
      handlePopoverClose();
    },
    [
      dataProvider,
      dispatch,
      handlePopoverClose,
      itemId,
      itemType,
      resourceKey,
      resourceType,
      shareUrl,
      user
    ]
  );

  const onInviteSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (emailLoading || !email) {
      return;
    }
    setEmailError('');
    setEmailSuccess('');
    setEmailLoading(true);
    dataProvider
      .inviteUser<Profile>(email, resourceType, resourceId)
      .then((data) => {
        if (data && data.invited) {
          setEmailSuccess(`${email} invited.`);
          setEmail('');
        } else {
          setEmailError(`${email} already exist.`);
        }
      })
      .catch((err) => {
        setEmailError(err.message);
      })
      .finally(() => {
        setEmailLoading(false);
      });
  };

  let rootClassName = classes.button;
  if (className) {
    rootClassName += ' ' + className;
  }
  if (open) {
    rootClassName += ' open';
  }

  let openState = open;
  let anchorReference: PopoverReference = 'anchorEl';
  let anchorElement: HTMLElement | null = anchorEl;
  let anchorOrigin: PopoverOrigin = {
    vertical: 'bottom',
    horizontal: 'right'
  };
  let transformOrigin: PopoverOrigin = {
    vertical: 'top',
    horizontal: 'right'
  };
  if (sharePopupInfo) {
    openState = true;
    anchorReference = 'anchorPosition';
    anchorElement = null;
    anchorOrigin = {
      vertical: 'center',
      horizontal: 'center'
    };
    transformOrigin = {
      vertical: 'center',
      horizontal: 'center'
    };
  }

  return (
    <>
      {isMainFeed ? (
        <Box>
          <Badge
            sx={{
              '.MuiBadge-badge': {
                top: '8px'
              }
            }}
            badgeContent={
              <GreenCounter
                bgColor={colorPalette.white}
                coinColor={colorPalette.purple}
                coins={Constants.PLUS_TWENTY_FIVE}
                height={Constants.MEDIUM_22}
                textColor={colorPalette.purple}
                width={Constants.MEDIUM_22}
              />
            }
          >
            <StyledButtonWrapper
              isMediumScreen={isMediumScreen}
              onClick={handlePopoverOpen}
            >
              <StyledShareImage
                isMediumScreen={isMediumScreen}
                src={NewsFeedShareIcon}
              />
              {!isMediumScreen && <ShareButtonText>Share</ShareButtonText>}
            </StyledButtonWrapper>
          </Badge>
        </Box>
      ) : (
        <IconButton className={rootClassName} onClick={handlePopoverOpen}>
          <ShareIcon />
        </IconButton>
      )}
      <Popover
        id="share-info"
        className={classes.popover}
        open={openState}
        anchorReference={anchorReference}
        anchorEl={anchorEl}
        onClick={(e) => {
          e.stopPropagation();
        }}
        anchorPosition={sharePopupCenter}
        onClose={handlePopoverClose}
        classes={{ paper: classes.paper }}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <div className={classes.title}>
          <Typography>
            Invite someone to {itemType === 'problem' ? 'solve' : 'improve'}{' '}
            <b>{title}</b>
          </Typography>
        </div>
        <div className={classes.row}>
          <span>
            <span>
              Share to{' '}
              <b>
                earn {ideaPoints}
                IdeaPoints
              </b>
              :
            </span>
            <PsLogo
              className="coin"
              size={23}
              color={psTheme.palette.primary.main}
              small
            />
          </span>
        </div>

        <div className={classes.social}>
          <a href="#" onClick={onFacebookShareClick}>
            <img
              src="/icons/share-facebook.svg"
              alt="facebook"
              width="36"
              height="36"
            />
          </a>
          <a
            href={watsappShareUrl}
            onClick={onWatsappShareClick}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/icons/share-watsup.svg"
              alt="watsup"
              width="36"
              height="36"
            />
          </a>
          {socialAuthKeys &&
            socialAuthKeys.map((socials) => {
              if (socials.isShared === true) {
                switch (socials.type) {
                  case Constants.LINKEDIN:
                    return (
                      <a
                        href="#"
                        onClick={() => {
                          onShareClick(Constants.LINKEDIN);
                        }}
                      >
                        <img
                          src="/icons/share-linkedin.svg"
                          alt="linkedin"
                          width="36"
                          height="36"
                        />
                      </a>
                    );
                  case Constants.TWITTER:
                    return (
                      <a
                        href="#"
                        onClick={() => {
                          onShareClick(Constants.TWITTER);
                        }}
                        rel="noreferrer"
                      >
                        <img
                          src="/icons/share-twitter.svg"
                          alt="twitter"
                          width="36"
                          height="36"
                          style={{ borderRadius: '5px' }}
                        />
                      </a>
                    );
                  case Constants.REDDIT:
                    return null;
                  // <a
                  //   href="#"
                  //   onClick={() => {
                  //     onShareClick(Constants.REDDIT);
                  //   }}
                  //   rel="noreferrer"
                  // >
                  //   <img
                  //     src="/icons/share-reddit.svg"
                  //     alt="reddit"
                  //     width="36"
                  //     height="36"
                  //   />
                  // </a>
                  case Constants.DISCORD:
                    return (
                      <a
                        href="#"
                        onClick={() => {
                          onShareClick(Constants.DISCORD);
                        }}
                        rel="noreferrer"
                      >
                        <img
                          src="/icons/share-discord.svg"
                          alt="discord"
                          width="36"
                          height="36"
                          style={{
                            backgroundColor: '#5865F2',
                            borderRadius: '8px',
                            padding: '5px'
                          }}
                        />
                      </a>
                    );
                  case Constants.INSTAGRAM:
                    return (
                      <a
                        href="#"
                        onClick={() => {
                          onShareClick(Constants.INSTAGRAM);
                        }}
                        rel="noreferrer"
                      >
                        <img
                          src="/icons/share-instagram.svg"
                          alt="instagram"
                          width="36"
                          height="36"
                        />
                      </a>
                    );
                  default:
                    return null;
                }
              }
              return null;
            })}
        </div>

        <form action="#" onSubmit={onInviteSubmit}>
          <PsFormControl
            className={classes.input}
            label="Add someone's e-mail address below:"
            error={!!emailError}
            helperText={emailError || emailSuccess}
            labelLight
          >
            <PsInput name="email" value={email} onChange={onEmailChange} />
          </PsFormControl>

          <div>
            <Box sx={{ marginBottom: '1rem' }}>
              <span>
                Invite to <b>earn IdeaCoins</b>:
              </span>
              <PsLogo
                className="coin"
                size={23}
                color={psTheme.palette.primary.main}
                small
              />
            </Box>
            <PsButton
              className={classes.submit}
              color="secondary"
              type="submit"
              disabled={emailLoading}
            >
              Send E-mail Invite
              <PsLogo size={23} small />
            </PsButton>
          </div>
        </form>
      </Popover>
    </>
  );
};

export const ShareBtnSimple = styled(ShareBtnView)({});
