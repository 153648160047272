import React, { Dispatch, SetStateAction } from 'react';
import LabeledIconButton from 'components/common/buttons/LabeledIconButton';
import { ProblemContent } from 'components/common/DeatilsDrawer/Problem/ProblemContent';
import { SolutionContent } from 'components/common/DeatilsDrawer/Solution/SolutionContent';
import { generateUniqueId } from 'helpers/common';
import { Node } from 'interface/common';
import { colorPalette } from 'theme';
import {
  Constants,
  MESSAGE_ROLES,
  MESSAGE_TYPES,
  VARIANT
} from 'utilities/constants';
import { Message } from '../interfaces';
import { ActionButtonWrapper } from '../styledComponents';

const typeToContentMap = {
  [Constants.PROBLEM]: ProblemContent,
  [Constants.SOLUTION]: SolutionContent
};

const typeToActionMap = {
  [Constants.PROBLEM]: Constants.SOLVE,
  [Constants.SOLUTION]: Constants.IMPROVE
};

interface ItemDetailsProps {
  item: Node;
  setMessages?: Dispatch<SetStateAction<Message[]>>;
}

export const ItemDetails: React.FC<ItemDetailsProps> = ({
  item,
  setMessages
}) => {
  const handleImproveClick = () => {
    const newMessages = {
      id: generateUniqueId(),
      data: item,
      type: MESSAGE_TYPES.EDIT_SOLUTION,
      title: `${Constants.IMPROVE} ${Constants.C_SOLUTION}`,
      role: MESSAGE_ROLES.BOT
    };
    setMessages((prevMessages) => [...prevMessages, newMessages]);
  };

  const handleAction = () => {
    switch (item.type) {
      case Constants.SOLUTION:
        handleImproveClick();
        break;
      default:
        break;
    }
  };

  const ContentRenderer = typeToContentMap[item?.type];
  return (
    <>
      <ContentRenderer displayedTags={item?.tags} item={item} />
      {item.type === Constants.SOLUTION && (
        <ActionButtonWrapper marginTop=".625rem">
          <LabeledIconButton
            icon={null}
            variant={VARIANT.CONTAINED}
            text={typeToActionMap[item?.type]}
            btnColor={colorPalette.purple}
            buttonSx={{ padding: '.3125rem', marginTop: '.625rem' }}
            typographySx={{
              fontSize: '.75rem'
            }}
            width="10.625rem"
            onClick={handleAction}
          />
        </ActionButtonWrapper>
      )}
    </>
  );
};
