import React, { useState, useEffect, useContext, useCallback } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { Product, CardProductSmall } from 'components/CardProduct';
import { DataContext } from 'contexts/DataContext';
import { Pagination } from 'components/Pagination';
import { PsTheme } from '../../theme';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'block' | 'header' | 'loading' | 'noResult';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    block: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginTop: 10
    },
    header: {
      fontSize: 32,
      fontWeight: 'bold',
      lineHeight: '39px',
      marginTop: 24,
      color: psTheme.palette.third.main,
      [psTheme.breakpoints.down('sm')]: {
        fontSize: 25,
        lineHeight: '30px'
      }
    },
    loading: {
      lineHeight: '30px',
      textAlign: 'center'
    },
    noResult: {
      lineHeight: '30px',
      textAlign: 'center'
    }
  };
});

type RelatedProductsProps = {
  title?: string;
  noResultContent?: React.ReactNode;
  tag?: string | number;
  problem?: string | number;
  solution?: string | number;
  application?: string | number;
};

const RelatedProductsView = ({
  noResultContent,
  title,
  tag,
  problem,
  solution,
  application
}: RelatedProductsProps) => {
  const { classes } = useStyles();
  const { dataProvider, relatedProductsListCache } = useContext(DataContext);
  const [page, setPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [products, setProducts] = useState<Array<Product>>([]);
  const [loading, setLoading] = useState(true);
  const perPage = 5;

  useEffect(() => {
    if (!tag && !problem && !solution && !application) {
      setProducts([]);
      setLoading(false);
      return;
    }
    let filter = {};
    if (tag) {
      filter = { $custom: { type: 'forTag', tag } };
    } else if (problem) {
      filter = { $custom: { type: 'selectedForProblem', problem } };
    } else if (solution) {
      filter = { $custom: { type: 'selectedForSolution', solution } };
    } else if (application) {
      filter = { $custom: { type: 'selectedForApplication', application } };
    }

    setLoading(true);
    dataProvider
      .getList<Product>('company-products', {
        pagination: { page: page + 1, perPage },
        sort: { field: 'votes', order: 'DESC' },
        filter
      })
      .then(({ data, total }) => {
        setProducts(data);
        setTotalPages(Math.ceil(total / perPage));
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [page, tag, relatedProductsListCache]);

  const onPageChange = (page: number) => {
    setPage(page);
  };

  return (
    <div className={classes.block}>
      {title ? (
        <Typography className={classes.header} variant="h2" component="h2">
          {title}
        </Typography>
      ) : null}
      {loading ? (
        <Typography className={classes.loading} variant="body2">
          Loading...
        </Typography>
      ) : null}
      {!loading && !products.length ? (
        <Typography className={classes.noResult}>
          {noResultContent
            ? noResultContent
            : 'This item does not yet have any products'}
        </Typography>
      ) : null}
      {products.map((product) => {
        return <CardProductSmall key={product.id} product={product} />;
      })}
      <Pagination total={totalPages} active={page} onChange={onPageChange} />
    </div>
  );
};

export const RelatedProducts = styled(RelatedProductsView)({});

export default RelatedProducts;
