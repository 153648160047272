import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { Add } from '@mui/icons-material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Box,
  Drawer,
  Grid,
  IconButton,
  LinearProgress,
  styled
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ProductImage from 'assets/icons-new/product.svg';
import ContestIcon from 'assets/icons/contests.svg';
import InventionsIcon from 'assets/icons/invention.svg';
import OpenDrawerIcon from 'assets/icons/open_drawer.svg';
import ProblemIcon from 'assets/icons/problems.svg';
import SolutionIcon from 'assets/icons/solutions.svg';
import { AdvanceSearch } from 'components/AdvanceSearch';
import { AutoSearch } from 'components/AutoSearch';
import { ActiveConceptCard } from 'components/ActiveConceptCard';
import LabeledIconButton from 'components/common/buttons/LabeledIconButton';
import { CommunitiesListView } from 'components/CommunitiesListView';
import FeedView from 'components/FeedView';
import { FeedSortFilter } from 'components/FeedSortFilter';
import Helmet from 'components/Helmet/index';
import { HomeTopBar } from 'components/HomeTopBar';
import { CreateProblemSolution } from 'components/HomeTopBar/CreateProblemSolution';
import PipeDivider from 'components/PipeDivider';
import { ProfileFeedView } from 'components/ProfileFeedView';
import useRouter from 'hooks/useRouter';
import { useRemoveQueryParams } from 'helpers';
import { SideMenu } from 'layout';
import ProductModal from 'modals/AddProduct';
import { AllConceptsView } from 'modals/AllConceptsModal/AllConceptsView';
import Actions from 'redux-state/actions';
import {
  GetActivityLoader,
  GetConceptData,
  GetFeed,
  GetHomeLoader,
  GetUser
} from 'redux-state/selectors';
import { PsTheme, colorPalette, useIsMediumScreen } from 'theme';
import { Constants, VARIANT } from 'utilities/constants';
import { ActiveConceptHeader } from './ActiveConceptHeader';
import HomeButtonsView from './HomeButtonsView';
import {
  AutoSuggestWrapper,
  ButtonWrapper,
  SearchWrapper,
  StyledStickyBox
} from './StyledComponents';
import TagsList from './TagsList';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      margin: 'auto',
      maxWidth: '100vw',
      padding: '0 25px',
      width: '100%'
    },
    feedWrapper: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      margin: 'auto',
      maxWidth: '1555px',
      padding: '0 25px',
      width: 'fit-content'
    },
    mainContentWrapper: {
      display: 'flex',
      justifyContent: 'center'
    },
    button: {
      display: 'inline-block',
      position: 'relative',
      marginRight: 20,
      padding: '12px 12px 8px',
      borderRadius: 10
    },
    buttonLogo: {
      position: 'absolute',
      top: 1,
      right: -9
    },
    coins: {
      position: 'absolute',
      top: -5,
      left: '100%',
      paddingLeft: 12,
      color: psTheme.palette.primary.main
    },
    priceSlide: {
      height: 'auto',
      '&.screen > .layer.front': {
        position: 'relative',
        height: 'auto',
        padding: '40px 1em'
      }
    },
    searchRow: {
      position: 'relative',
      margin: '48px 0',
      display: 'flex',
      alignItems: 'center',
      zIndex: 100
    },
    pricingTable: {
      marginBottom: 30,
      '& .title': {
        textAlign: 'center',
        marginBottom: 20,
        fontSize: 30,
        fontWeight: 600,
        [psTheme.breakpoints.down('xs')]: {
          marginBottom: 10,
          fontSize: 25,
          lineHeight: '1.1'
        }
      },
      '& .border': {
        position: 'relative',
        border: 'solid 1px rgba(3, 31, 70, 0.5)',
        '&:after': {
          content: '""',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: '50%',
          display: 'block',
          borderLeft: 'solid 1px rgba(3, 31, 70, 0.5)'
        },
        [psTheme.breakpoints.down('sm')]: {
          '&:after': {
            display: 'none'
          }
        }
      },
      '& div.border-3': {
        '&:after': {
          left: '33.3%'
        },
        '&:before': {
          content: '""',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: '66.6%',
          display: 'block',
          borderLeft: 'solid 1px rgba(3, 31, 70, 0.5)'
        },
        [psTheme.breakpoints.down('sm')]: {
          '&:after': {
            display: 'none'
          },
          '&:before': {
            display: 'none'
          }
        }
      },
      '& .border-line': {
        display: 'none'
      },
      '& div.border-4': {
        '&:after': {
          left: '25%'
        },
        '&:before': {
          content: '""',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: '50%',
          display: 'block',
          borderLeft: 'solid 1px rgba(3, 31, 70, 0.5)'
        },
        '& .border-line': {
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: '75%',
          display: 'block',
          borderLeft: 'solid 1px rgba(3, 31, 70, 0.5)'
        },
        [psTheme.breakpoints.down('sm')]: {
          '&:after': {
            display: 'none'
          },
          '&:before': {
            display: 'none'
          },
          '& .border-line': {
            display: 'none'
          }
        }
      },
      '& .row': {
        display: 'flex',
        [psTheme.breakpoints.down('sm')]: {
          flexDirection: 'column',
          '&.row-b': {
            display: 'none'
          }
        }
      },
      '& .cell': {
        width: '50%',
        marginBottom: 0,
        padding: '10px 15px',
        flexGrow: 1,
        flexShrink: 0,
        textAlign: 'center',
        '& > div:first-child': {
          fontSize: 25,
          lineHeight: '1.1',
          marginBottom: 10,
          color: '#632DDD'
        },
        '& .mobile': {
          display: 'none',
          marginTop: 10
        },
        '& .desk': {
          display: 'inline-flex'
        },
        [psTheme.breakpoints.down('sm')]: {
          width: '100%',
          borderTop: 'solid 1px rgba(3, 31, 70, 0.5)',
          '&:first-child': {
            borderTop: 'none'
          },
          '& .mobile': {
            display: 'inline-flex'
          },
          '& .desk': {
            display: 'none'
          }
        },
        [psTheme.breakpoints.down('xs')]: {
          fontSize: 17,
          '& > div:first-child': {
            fontSize: 20
          }
        }
      },
      '& .cell-3': {
        width: '33.3%',
        [psTheme.breakpoints.down('sm')]: {
          width: '100%'
        }
      },
      '& .cell-4': {
        width: '25%',
        [psTheme.breakpoints.down('sm')]: {
          width: '100%'
        }
      }
    },
    navBar: {
      '& ul': {
        top: '30px'
      }
    },
    addButton: {
      width: '7.5rem',
      height: '1.875rem',
      margin: '3px'
    },
    suggestionContainer: {
      flexDirection: 'row',
      width: '100%',
      maxWidth: '1420px',
      margin: '0 auto',
      padding: '0 10px',
      justifyContent: 'center',
      background: 'red'
    },
    buttons: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row-reverse',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: '10px'
    }
  };
});

const HomeView = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const loader = GetHomeLoader();
  const feed = GetFeed();
  const feedLoader = GetActivityLoader();

  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [feedFilters, setFeedFilters] = useState<string[]>([Constants.ALL]);
  const { filterType } = router.query;
  const [sortType, setSortType] = useState<string>(Constants.CREATED_AT);
  const [accordionExpanded, setAccordionExpanded] = useState<boolean>(false);
  const isMediumScreen = useIsMediumScreen();
  const [showPrdCreationModal, setShowPrdCreationModal] =
    useState<boolean>(false);
  const [showCompanyCreationModal, setShowCompanyCreationModal] =
    useState<boolean>(false);
  const [trigger, setTrigger] = useState<boolean>(false);

  const user = GetUser();
  const conceptData = GetConceptData();
  const activeConcept = user?.activeConcept || {};
  const filteredData = useMemo(
    () => conceptData?.data?.filter((item) => !item?.isPaid && !item?.isFiled),
    [conceptData]
  );

  const filterItems = useMemo(
    () => [
      {
        label: Constants.C_ALL,
        value: Constants.ALL,
        icon: null,
        isTitle: true
      },
      {
        label: Constants.C_PROBLEM,
        value: Constants.PROBLEM,
        icon: ProblemIcon
      },
      {
        label: Constants.C_PRODUCT,
        value: Constants.PRODUCT,
        icon: ProductImage
      },
      {
        label: Constants.C_SOLUTION,
        value: Constants.SOLUTION,
        icon: SolutionIcon
      },
      {
        label: Constants.C_INVENTION,
        value: Constants.Invention,
        icon: InventionsIcon
      },
      {
        label: Constants.C_CONTEST,
        value: Constants.CONTEST,
        icon: ContestIcon
      }
    ],
    []
  );

  const sortItems = useMemo(
    () => [
      {
        label: Constants.RECENTLY_ADDED,
        value: Constants.CREATED_AT,
        icon: null
      },
      {
        label: Constants.C_LIKES,
        value: Constants.LIKES,
        icon: null
      },
      {
        label: Constants.TOP_RANKED,
        value: Constants.IDEA_POINTS,
        icon: null
      }
    ],
    []
  );

  const pagination = useMemo(
    () => ({
      page: 0,
      perPage: rowsPerPage
    }),
    [rowsPerPage]
  );

  const currentActiveConcept = filteredData?.find(
    (concept) => concept?.id === activeConcept?.id
  );

  useEffect(() => {
    dispatch(Actions.getFeed(pagination, null, null, feedFilters, sortType));
  }, [dispatch, pagination, feedFilters, sortType]);

  const { classes } = useStyles();

  const handleAccordionChange = () => {
    setAccordionExpanded(!accordionExpanded);
  };

  const handleAdvanceSearchChange = (filters: Array<string>, sort: string) => {
    setRowsPerPage(10);
    setFeedFilters(filters);
    setSortType(sort);
  };

  const modalToggler = {
    [Constants.PRODUCT]: setShowPrdCreationModal
  };

  const openModal = (modal) => {
    user ? modalToggler[modal](true) : dispatch(Actions.openAuthModal(true));
  };

  return (
    <StyledHomeWrapper isMediumScreen={isMediumScreen}>
      <Helmet title="Home" />
      <header>
        <title>MindMiner - Home</title>
      </header>
      {loader && <LinearProgress />}
      <div className={isMediumScreen ? classes.feedWrapper : classes.wrapper}>
        <SideMenu
          active={(filterType as string) ?? Constants.ALL}
          setFilterType={setFeedFilters}
          isHomePage={true}
        />

        <Box>
          {isMediumScreen && (
            <>
              <StyledSubContainer isMediumScreen={isMediumScreen}>
                <AutoSuggestWrapper>
                  <AutoSearch />
                </AutoSuggestWrapper>
                <PipeDivider size="long" />
                <StyledIconButton onClick={handleAccordionChange}>
                  <img src={OpenDrawerIcon} />
                </StyledIconButton>
              </StyledSubContainer>
            </>
          )}

          {isMediumScreen ? (
            <StyledFeedMainBox>
              <StyledDrawerContainer
                anchor="right"
                open={accordionExpanded}
                onClose={handleAccordionChange}
              >
                <StyledSubDrawerContainer>
                  <StyledChevronRightIconContainer>
                    <ChevronRightIcon onClick={handleAccordionChange} />
                  </StyledChevronRightIconContainer>
                </StyledSubDrawerContainer>
                <StyledConditionalBox isMediumScreen={isMediumScreen}>
                  <StyledCartDrawer isMediumScreen={isMediumScreen}>
                    <HomeButtonsView />
                    <AllConceptsView />
                  </StyledCartDrawer>
                </StyledConditionalBox>
              </StyledDrawerContainer>
              {feedLoader && (
                <LinearProgress sx={{ width: '99%' }} value={10} />
              )}
              <FeedView
                feed={feed}
                filters={feedFilters}
                pagination={pagination}
                setRowsPerPage={setRowsPerPage}
                sortType={sortType}
              />
            </StyledFeedMainBox>
          ) : (
            <>
              <StyledFeedMainBox>
                <Grid container>
                  <Grid
                    item
                    md={3}
                    lg={2.5}
                    sx={{ display: { xs: 'none', md: 'block', lg: 'block' } }}
                  >
                    <StyledStickyBox
                      isUserLoggedIn={user}
                      sx={{
                        width: 'calc(21vw - 2.35rem)'
                      }}
                    >
                      <ProfileFeedView trigger={trigger} />
                      {user && (
                        <ActiveConceptCard
                          HeaderComponent={ActiveConceptHeader}
                        />
                      )}
                    </StyledStickyBox>
                  </Grid>
                  <Grid
                    item
                    md={9}
                    lg={6.5}
                    sx={{
                      padding: '0px 15px'
                    }}
                  >
                    <CreateProblemSolution />
                    <FeedSortFilter
                      feedFilters={feedFilters}
                      filterItems={filterItems}
                      handleChange={handleAdvanceSearchChange}
                      initFilters={feedFilters}
                      setFeedFilters={setFeedFilters}
                      sortItems={sortItems}
                    />
                    <FeedView
                      feed={feed}
                      filters={feedFilters}
                      pagination={pagination}
                      setRowsPerPage={setRowsPerPage}
                      sortType={sortType}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    sx={{ display: { xs: 'none', md: 'none', lg: 'block' } }}
                  >
                    <StyledStickyBox isUserLoggedIn={user}>
                      <CommunitiesListView
                        isFeed={true}
                        setTrigger={setTrigger}
                      />
                    </StyledStickyBox>
                  </Grid>
                </Grid>
              </StyledFeedMainBox>
            </>
          )}
        </Box>
      </div>
      {showPrdCreationModal && (
        <ProductModal
          open={showPrdCreationModal}
          initialValue={''}
          onClose={() => setShowPrdCreationModal(false)}
        />
      )}
    </StyledHomeWrapper>
  );
};

const Home = styled(HomeView)({});

const StyledHomeWrapper = styled(Box)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: isMediumScreen ? '0rem' : '5rem'
  })
);

const StyledConditionalBox = styled(Box)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%'
  })
);

const StyledFeedMainBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  boxSizing: 'border-box',
  flexGrow: 1,
  paddingTop: '.875rem',
  '@media (max-width:900px)': {
    flexDirection: 'column'
  }
}));

const StyledCartDrawer = styled(Box)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    boxSizing: 'border-box',
    height: '100%',
    overflowY: 'auto',
    padding: '4px 18px 4px 4px',
    position: 'relative',
    width: '100%'
  })
);

const StyledSubContainer = styled(Box)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    display: 'flex',
    flexDirection: isMediumScreen ? 'row' : 'column',
    position: 'relative',
    alignItems: isMediumScreen && 'center',
    marginTop: '5rem',
    '& .react-autosuggest__container': {
      flex: 1
    }
  })
);

const StyledIconButton = styled(IconButton)({
  backgroundColor: 'white',
  borderRadius: '6px',
  marginLeft: '6px',
  padding: '8px',
  border: `1px solid ${colorPalette.nobel}`,
  minHeight: '52px',
  minWidth: '52px'
});

const StyledDrawerContainer = styled(Drawer)({
  boxShadow: 'none',
  display: 'block',
  '& .MuiDrawer-paper': {
    flexDirection: 'row',
    height: '100vh',
    width: '100%'
  }
});

const StyledSubDrawerContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  width: 'auto'
});

const StyledChevronRightIconContainer = styled(Box)({
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  height: '100%',
  justifyContent: 'center'
});
export default Home;
