import React, { useState, useRef, useEffect } from 'react';
import { styled, Theme, useTheme } from '@mui/material/styles';

import { Picture, getPlaceholderImage, getFileTitle } from '../../helpers';
import { PsTheme } from '../../theme';

import { ZoomModal } from 'components/ImageField';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root' | 'inner' | 'placeholder' | 'img';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      display: 'block',
      textDecoration: 'none',
      width: '47%',
      padding: 11,
      [psTheme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    inner: {
      display: 'block',
      position: 'relative',
      paddingTop: '49%',
      '&.zoom': {
        cursor: 'zoom-in'
      },
      [psTheme.breakpoints.down('sm')]: {
        paddingTop: '40%'
      },
      [psTheme.breakpoints.down('xs')]: {
        paddingTop: '55%'
      }
    },
    placeholder: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: 15,
      objectFit: 'cover',
      opacity: 1,
      transition: 'opacity 0.3s ease',
      '&.loaded': {
        opacity: 0
      }
    },
    img: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: 15,
      objectFit: 'cover',
      opacity: 0,
      transition: 'opacity 0.3s ease',
      '&.loaded': {
        opacity: 1
      }
    }
  };
});

type CardImageProps = {
  children?: React.ReactNode;
  className?: string;
  href?: string;
  hrefTarget?: string;
  image?: Picture;
  imageAlt?: string;
  type?: string;
};

const CardImageView = ({
  className,
  href,
  hrefTarget,
  image,
  imageAlt,
  type,
  children
}: CardImageProps) => {
  const [loaded, setLoaded] = useState(false);
  const [zoomOpen, setZoomOpen] = useState(false);
  const [pictureType, setPictureType] = useState('');
  const imgRef = useRef<HTMLImageElement>(null);
  const { classes } = useStyles();

  useEffect(() => {
    setLoaded(false);
  }, [image, image ? image.url : '']);

  useEffect(() => {
    if (imgRef.current) {
      if (imgRef.current.complete) {
        if (imgRef.current.naturalHeight) {
          setLoaded(true);
        }
      } else {
        imgRef.current.addEventListener('load', () => {
          setLoaded(true);
        });
      }
    }
  }, [imgRef.current, image, image ? image.url : '']);

  const onZoomClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (!imgRef || !imgRef.current) {
      return;
    }
    const { naturalHeight, naturalWidth } = imgRef.current;
    const winSize = window.innerHeight / window.innerWidth;
    const pictSize = naturalHeight / naturalWidth;

    setPictureType(winSize > pictSize ? 'h' : 'v');
    setZoomOpen(true);
  };

  const onZoomClose = () => {
    setZoomOpen(false);

    setTimeout(() => {
      setPictureType('');
    }, 500);
  };

  let rootClassName = classes.root;
  if (className) {
    rootClassName += ' ' + className;
  }
  const def = getPlaceholderImage(type);

  const Placeholder = (
    <img
      className={`${classes.placeholder} ${loaded ? 'loaded' : ''}`}
      src={def}
      alt={type}
    />
  );

  const Image = image ? (
    <img
      ref={imgRef}
      className={`${classes.img} ${loaded ? 'loaded' : ''}`}
      src={image.url}
      alt={imageAlt || getFileTitle(image.title)}
    />
  ) : null;

  if (href) {
    return (
      <Link to={href}>
        <a className={rootClassName} target={hrefTarget}>
          <span className={classes.inner}>
            {Placeholder}
            {Image}
            {children}
          </span>
        </a>
      </Link>
    );
  }

  return (
    <>
      <div className={rootClassName} onClick={onZoomClick}>
        <div className={`${classes.inner} ${!href && loaded ? 'zoom' : ''}`}>
          {Placeholder}
          {Image}
          {children}
        </div>
      </div>
      <ZoomModal
        open={zoomOpen}
        onClose={onZoomClose}
        picture={image}
        pictureType={pictureType}
      />
    </>
  );
};

export const CardImage = styled(CardImageView)({});

export default CardImage;
