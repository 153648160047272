import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  ModalDataContext,
  StepComponentType,
  StepComponentProps
} from 'contexts/ModalDataContext';
import { DataContext } from 'contexts/DataContext';
import { PsFormControl, ShortEvent } from 'components/common/PsFormControl';
import { PsSelect, PsOptionType } from 'components/common/PsSelect';
import { PsSelectedList } from 'components/common/PsSelectedList';
import styles from 'modals/ModalWindow.module.scss';
import Typography from '@mui/material/Typography';
import SolutionModal from 'modals/AddSolution';

const FinilizeApplicationStep: StepComponentType<StepComponentProps> = ({
  onNotify
}: StepComponentProps) => {
  const { values, updateField } = useContext(ModalDataContext);
  const { dataProvider } = useContext(DataContext);
  const [list, setList] = useState<Array<PsOptionType>>([]);
  const [selectedValue, setSelectedValue] = useState<string>(
    values.selected.join(',') || ''
  );
  const [isSolutionModalOpen, setIsSolutionModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (!values.mainProblem) {
      return;
    }

    dataProvider.getProblemTree({ id: values.mainProblem }).then((list) => {
      const first = {
        value: '--create--',
        label: `Create New Solution`,
        raw: ''
      };
      const optionsList: Array<PsOptionType> = list
        .map((item) => {
          return {
            value: `${item.id}`,
            label: item.title || '(empty)',
            type: item.type
          };
        })
        .filter((item) => item.type === 'solution');
      setList([first, ...optionsList]);
    });
  }, []);

  const onListChange = useCallback(
    (event: ShortEvent) => {
      const { value } = event.target;
      const valueList = value.split(',');
      if (
        value &&
        valueList.some((val: string) => val.startsWith('--create--'))
      ) {
        setIsSolutionModalOpen(true);
        return;
      }

      setSelectedValue(value);
      if (value) {
        updateField('selected', valueList);
      } else {
        updateField('selected', []);
      }
    },
    [setSelectedValue, updateField]
  );

  const onSelectedInputChange = (text: string) => {
    const first = list[0];
    const isFirstCreate = first && first.value.startsWith('--create--');

    if (text) {
      const first = {
        value: `--create--, ${text}`,
        label: `Create New Solution`,
        raw: text
      };
      if (isFirstCreate) {
        const [, ...rest] = list;
        setList([first, ...rest]);
      } else {
        setList([first, ...list]);
      }
    }
  };

  const handleCloseSolutionModal = () => {
    setIsSolutionModalOpen(false);
  };

  return (
    <div className={styles.stepWrapper}>
      {values.mainProblemLabel ? (
        <Typography className={styles.text}>
          Choose solutions as components to your invention of the{' '}
          <b>{values.mainProblemLabel}</b>
        </Typography>
      ) : null}
      <PsFormControl
        placeholder="Choose solution"
        label={`Solutions to the problem of a ${values.mainProblemLabel}`}
      >
        <PsSelect
          isMulti
          maxMenuHeight={270}
          onInputChange={onSelectedInputChange}
          name="selected"
          options={list}
          onChange={onListChange}
          value={selectedValue}
        />
      </PsFormControl>
      <PsSelectedList
        options={list}
        value={selectedValue}
        onChange={onListChange}
      />
      {isSolutionModalOpen && (
        <SolutionModal
          open={isSolutionModalOpen}
          onClose={handleCloseSolutionModal}
        />
      )}
    </div>
  );
};

export default FinilizeApplicationStep;
