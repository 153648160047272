import React, { useState, useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import ModalWindow from 'modals/ModalWindow';
import { PsButton } from 'components/common/PsButton';
import { PsLogo } from 'components/common/PsLogo';
import Step from 'components/Step';
import Stepper from 'components/Stepper';
import { ModalContext } from 'contexts/ModalContext';
import {
  ModalDataContext,
  DownloadPatentSteps,
  StepComponentProps
} from 'contexts/ModalDataContext';
import { DataContext } from 'contexts/DataContext';
import { AuthContext } from 'contexts/AuthContext';
import { DOWNLOAD_PATENT_STEPS_CONFIG } from './config';
import styles from 'modals/ModalWindow.module.scss';
import { makeStyles } from 'tss-react/mui';

const steps: string[] = ['Accept Conditions', 'Download'];

const useButtonClasses = makeStyles()(() => {
  return {
    root: {
      margin: '8px 0',
      fontWeight: 400
    }
  };
});

export const DownloadPatent: React.FC = () => {
  const { user, refreshUserBalance } = useContext(AuthContext);
  const { closeModal } = useContext(ModalContext);
  const { values, updateField, resetFields, setErrors } =
    useContext(ModalDataContext);
  const { dataProvider } = useContext(DataContext);
  const theme = useTheme();
  const [prevSteps, setPrevSteps] = useState<string[]>([]);
  const [activeStepNumber, setActiveStepNumber] = useState<number>(0);
  const [currentStep, setCurrentStep] = useState<string | undefined>(
    DownloadPatentSteps.AcceptConditionsStep
  );
  const [loading, setLoading] = useState(false);

  const buttonClasses = useButtonClasses();

  const {
    Component,
    nextStep,
    validate,
    isNextStepBlocked,
    isFinalStep,
    nextButtonTitle,
    backButtonTitle
  } = DOWNLOAD_PATENT_STEPS_CONFIG[currentStep || ''];

  const StepComponent = Component as React.FC<StepComponentProps>;

  const handleClickBack = () => {
    if (activeStepNumber === 0) {
      closeModal();
      resetFields();
      return;
    }

    setActiveStepNumber((prev) => Math.max(0, prev - 1));
    setCurrentStep(prevSteps[prevSteps.length - 1]);
    setPrevSteps(prevSteps.slice(0, prevSteps.length - 1));
  };

  const handleNextStep = async () => {
    if (typeof validate === 'function') {
      const { success, errors } = validate(values);

      if (!success) {
        setErrors({ ...errors });

        return;
      }
    }

    if (isFinalStep) {
      setLoading(true);
      const appId = values.application.id;
      const title = `${user.firstName}_${values.application.title.slice(
        0,
        10
      )}_${appId}.pdf`;
      dataProvider
        .downloadPatentApp(appId, title)
        .then(() => {
          if (user) {
            user.downloadTemplates = user.downloadTemplates || [];
            user.downloadTemplates.push(appId);
          }
          setTimeout(() => {
            closeModal();
            resetFields();
            refreshUserBalance();
          }, 1000);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });

      return;
    }

    setPrevSteps([...prevSteps, currentStep || '']);
    setErrors({});
    setActiveStepNumber((prev) => prev + 1);
    if (typeof nextStep === 'function') {
      setCurrentStep(nextStep(values));
      return;
    }

    setCurrentStep(nextStep);
  };

  const onStepNotify = (args: any) => {
    if (args === 'terms-click') {
      //
    }
  };

  const title = values.modalTitle || 'Download patent application template for';
  const subTitle = <b>{values.application ? values.application.title : ''}</b>;

  const isNextStepDisabled: boolean =
    typeof isNextStepBlocked === 'function' && isNextStepBlocked(values);

  return (
    <ModalWindow
      title={title}
      titleAfter={
        <span className={styles.modalTitleIcon}>
          Earn: <PsLogo size={23} color={theme.palette.primary.main} small />
        </span>
      }
      subTitle={subTitle}
    >
      <Stepper className={styles.stepper} activeStep={activeStepNumber}>
        {steps.map((step) => (
          <Step key={step}>{step}</Step>
        ))}
      </Stepper>
      <StepComponent onNotify={onStepNotify} />
      <div className={`${styles.actions} actions`}>
        <PsButton
          disabled={isNextStepDisabled || loading}
          onClick={handleNextStep}
          fullWidth
          classes={buttonClasses}
          loading={loading}
        >
          {nextButtonTitle || 'Accept and Continue'}
        </PsButton>
        <PsButton
          onClick={handleClickBack}
          fullWidth
          cancel
          classes={buttonClasses}
        >
          {backButtonTitle || 'Back'}
        </PsButton>
      </div>
    </ModalWindow>
  );
};

export default DownloadPatent;
