import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Config from 'config/config';
import { useDispatch } from 'react-redux';
import Actions from 'redux-state/actions';
import {
  GetRewardHistory,
  GetRewardHistoryLoader
} from 'redux-state/profile/selectors';

import { CoinsSmall } from 'components/Coins';
import { Card, CardBody, CardRow } from 'components/card-small';
import AuthContext from 'contexts/AuthContext';
import { makeStyles } from 'tss-react/mui';
import { Constants, TAG_TYPES } from 'utilities/constants';
import { rewardResource, rewardType } from '../../helpers';
import { PsTheme } from '../../theme';
import { StyledTitle } from './StyledComponents';
import { GetUser } from 'redux-state/selectors';

const clientUrl = Config.NEXT_PUBLIC_CLIENT_URL;

export const getRewardType = (resource: string, type: string): string => {
  if (type.includes(rewardType.APP_PAY)) {
    if (resource === rewardResource.SOLUTION) {
      return `You submitted a new solution`;
    }
    return `You purchased an Idea NFT`;
  } else if (type.includes(rewardType.APP_IMPROVE)) {
    return `You improved an application`;
  } else if (type.includes(rewardType.SOLUTION_IMPROVE)) {
    return `You improved a solution`;
  } else if (type.includes(rewardType.SOLUTION_CREATE)) {
    return `You created a solution`;
  } else if (type.includes(rewardType.NFT_DEPLOY)) {
    return `You deployed a NFT`;
  } else if (type.includes(rewardType.NFT_DEPLOY_MM)) {
    return 'Someone deployed a NFT';
  } else if (type.includes(rewardType.CONTEST_WIN)) {
    return `You won a contest`;
  } else if (type.includes(rewardType.CHALLENGE_WIN)) {
    return `You won a challenge`;
  } else if (type.includes(rewardType.CONTEST_OWNER_WIN)) {
    return `You won contest`;
  } else if (type.includes(rewardType.SOLUTION_VOTE)) {
    return `You voted for a solution`;
  }
  // share
  let ent = '';
  switch (resource) {
    case rewardResource.PROBLEM: {
      ent = 'a problem';
      break;
    }
    case rewardResource.SOLUTION: {
      ent = 'a solution';
      break;
    }
    case rewardResource.APPLICATION: {
      ent = 'an invention';
      break;
    }
    case rewardResource.CONTEST: {
      ent = 'a contest';
      break;
    }
    case rewardResource.TAG: {
      ent = 'a tag';
      break;
    }
    case rewardResource.PROFILE: {
      ent = 'a profile';
      break;
    }
    default: {
      ent = '';
      break;
    }
  }
  return `You shared ${ent}`;
};

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    block: {
      display: 'flex',
      flexDirection: 'column',
      height: '30rem',
      marginTop: 10,
      overflowY: 'auto',
      width: '100%'
    },
    loading: {
      textAlign: 'center'
    },
    noResult: {
      textAlign: 'center'
    },
    header: {
      fontSize: 32,
      fontWeight: 'bold',
      lineHeight: '39px',
      marginTop: 24,
      color: psTheme.palette.third.main
    }
  };
});

const itemTypeMap = {
  [Constants.PROBLEM]: Constants.PROBLEMS,
  [Constants.PRODUCT]: Constants.PRODUCTS,
  [Constants.SOLUTION]: Constants.SOLUTIONS,
  [Constants.APPLICATION]: Constants.INVENTIONS,
  [Constants.PROFILE]: Constants.PROFILES,
  [Constants.CONTEST]: Constants.CONTESTS
};
const RelatedProfileApplicationsView = () => {
  const user = GetUser();
  const dispatch = useDispatch();
  const lastRewardRef = useRef(null);
  const [lastRewardIndex, setLastRewardIndex] = useState<number>();
  const [rowsPerPage, setRowsPerPage] = useState<number>(8);
  const { classes } = useStyles();
  const rewards = GetRewardHistory();
  const loader = GetRewardHistoryLoader();

  const pagination = useMemo(
    () => ({
      page: 0,
      perPage: rowsPerPage
    }),
    [rowsPerPage]
  );

  useEffect(() => {
    if (rewards) {
      setLastRewardIndex(rewards?.length - 1);
    }
  }, [rewards]);

  useEffect(() => {
    if (user) {
      dispatch(
        Actions.getRewardHistory(
          pagination,
          user.id,
          { $ne: TAG_TYPES.TAG },
          { $ne: 0 }
        )
      );
    }
  }, [dispatch, pagination, user]);

  useEffect(() => {
    if (lastRewardRef.current) {
      const lastRewardObserver = new IntersectionObserver(
        (entries) => {
          const lastReward = entries[0];
          if (lastReward.isIntersecting) {
            setRowsPerPage((prevRowsPerPage) => prevRowsPerPage + 5);
          }
        },
        { threshold: 0.5 }
      );

      lastRewardObserver.observe(lastRewardRef.current);

      return () => {
        if (lastRewardRef.current) {
          lastRewardObserver.unobserve(lastRewardRef.current);
        }
      };
    }
  }, [lastRewardRef.current]);

  const getTitle = useMemo(() => {
    return (reward) => {
      if (!reward) return;
      const { actionType, itemType } = reward;
      switch (actionType) {
        case Constants.CREATED_PROBLEM:
          return `${Constants.CREATED_PROBLEM_STATEMENT} ${reward.itemId.title}.`;
        case Constants.CREATED_SOLUTION:
          return `${Constants.CREATED_SOLUTION_STATEMENT} ${reward.itemId.title}.`;
        case Constants.IMPROVED_PROBLEM:
          return `${Constants.YOUR_PROBLEM} ${reward.itemId.title} ${Constants.IMPROVED_STATEMENT}`;
        case Constants.IMPROVED_SOLUTION:
          return `${Constants.YOUR_SOLUTION_IDEAPOINTS} ${reward.itemId.title} ${Constants.IMPROVED_STATEMENT}`;
        case Constants.CONCEPT_SOLUTION:
          return `${Constants.YOUR_SOLUTION_IDEAPOINTS} ${reward.itemId.title} ${Constants.CONCEPT_STATEMENT}`;
        case Constants.CONCEPT_PROBLEM:
          return `${Constants.YOUR_PROBLEM} ${reward.itemId.title} ${Constants.CONCEPT_STATEMENT}`;
        case Constants.INVITE:
          return `${Constants.YOU_INVITED} ${reward.itemId.email}${Constants.INVITE_STATEMENT}`;
        case Constants.SHARE:
          return `${Constants.SHARE_STATEMENT} ${itemType} ${reward.itemId.title}.`;
        case Constants.CONTEST_WINNER:
          return `${Constants.CONTEST_WINNER_STATEMENT} ${reward.itemId.title}.`;
        case Constants.SOLUTION_PATENT_PENDING:
          return `${Constants.YOUR_SOLUTION_IDEAPOINTS} ${reward.itemId.title} ${Constants.SOLUTION_PATENT_PENDING_STATEMENT}`;
        case Constants.SOLUTION_PURCHASED_PATENT:
          return `${Constants.YOUR_SOLUTION_IDEAPOINTS} ${reward.itemId.title} ${Constants.SOLUTION_PURCHASED_PATENT_STATEMENT}`;
        case Constants.PATENT_PENDING:
          return `${Constants.PATENT_PENDING_STATEMENT} ${reward.itemId.title}.`;
        case Constants.PURCHASED_PATENT:
          return `${Constants.PURCHASED_PATENT_STATEMENT} ${reward.itemId.title}.`;
        case Constants.REFERRED_PROBLEM:
          return `${Constants.REFEREE_CREATED_STATEMENT} ${reward?.itemId?.owner?.username} ${Constants.REFEREE_CREATED_PROBLEM_STATEMENT} ${reward.itemId.title}.`;
        case Constants.REFERRED_SOLUTION:
          return `${Constants.REFEREE_CREATED_STATEMENT} ${reward?.itemId?.owner?.username} ${Constants.REFEREE_CREATED_SOLUTION_STATEMENT} ${reward.itemId.title}.`;
        case Constants.REFERRED_CONCEPT_ADDITION:
          return `${Constants.REFEREE_CREATED_STATEMENT} ${reward?.itemId?.owner?.username} ${Constants.REFEREE_ADDED_TO_CONCEPT_STATEMENT} ${reward.itemId.title}.`;
        default:
          return '';
      }
    };
  }, []);

  const handleTitleClick = (reward) => {
    window.open(
      `${clientUrl}${itemTypeMap[reward.itemType]}/${reward.itemId.key}`,
      '_blank'
    );
  };

  return (
    <div className={classes.block}>
      {!user && (
        <Typography className={classes.noResult}>
          Please login first.
        </Typography>
      )}
      {user && !loader && !rewards?.length && (
        <Typography className={classes.noResult}>
          no rewards earned yet.
        </Typography>
      )}
      <Box sx={{ overflowY: 'scroll' }}>
        {rewards?.map((reward, index) => {
          const isLast = index === lastRewardIndex;
          return (
            <div ref={isLast ? lastRewardRef : null} key={reward?.id}>
              <Card>
                <CardBody sx={{ paddingLeft: 5 }}>
                  <StyledTitle onClick={() => handleTitleClick(reward)}>
                    {getTitle(reward)}
                  </StyledTitle>
                  <CardRow>
                    <CoinsSmall
                      icon={user?.files[0]?.url}
                      count={reward?.ideaPoints}
                      beforeText={`${user.username} ${Constants.EARNED}`}
                      afterText=""
                    />
                  </CardRow>
                </CardBody>
              </Card>
            </div>
          );
        })}
      </Box>
    </div>
  );
};

export const RelatedRewards = styled(RelatedProfileApplicationsView)({});

export default RelatedRewards;
