import React, { FC, useCallback, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { EditConceptModal } from 'modals/EditConceptModal';
import { GetUser } from 'redux-state/selectors';
import { Constants, VARIANT } from 'utilities/constants';
import { EditIcon } from './styledComponents';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface EditButtonProps {}

export const EditButton: FC<EditButtonProps> = () => {
  const user = GetUser();
  const [openEditConceptModal, setOpenEditConceptModal] =
    useState<boolean>(false);

  const handleClick = useCallback(() => {
    setOpenEditConceptModal(true);
  }, []);

  return (
    <>
      <Tooltip
        placement={VARIANT.TOP}
        color={VARIANT.PRIMARY}
        title={Constants.EDIT_TITLE}
      >
        <IconButton onClick={handleClick}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      {openEditConceptModal && (
        <EditConceptModal
          open={openEditConceptModal}
          setOpen={setOpenEditConceptModal}
          concept={user?.activeConcept}
        />
      )}
    </>
  );
};
