import React, {
  createContext,
  useState,
  useCallback,
  useContext,
  useEffect,
  useRef
} from 'react';
import { AuthContext } from 'contexts/AuthContext';
import { ModalDataContext } from 'contexts/ModalDataContext';
import { IdeaPointsInfo, IdeaPointsInfoTitle } from 'components/CardProfile';
import { StyledComponent } from '@emotion/styled';
import { Theme } from '@mui/material/styles';
import { Css, Cx } from 'tss-react';
import { Constants } from 'utilities/constants';
import { useStyles } from '../utilities/Styles';

type QueueItemType = {
  name: string;
  data?: any;
};

const getModalQueue = (): QueueItemType[] => {
  if (typeof window !== 'undefined') {
    const listStr = window.localStorage['modalsQueue'] || '[]';
    return JSON.parse(listStr);
  }
  return [];
};

const saveModalQueue = (list: QueueItemType[]): void => {
  if (typeof window !== 'undefined') {
    const listStr = JSON.stringify(list);
    window.localStorage['modalsQueue'] = listStr;
  }
};

export const ModalName = {
  FIRST_POINT: 'firstPoint',
  FIRST_PROBLEM: 'firstProblem',
  FIRST_SOLUTION: 'firstSolution',
  FIRST_INVENTION: 'firstInvention'
};

type ComponentOrNone = React.ReactNode | undefined;

export type SimpleModalContextType = {
  show: boolean;
  title?: ComponentOrNone;
  content?: ComponentOrNone;
  classes?: Record<any, any>;
  openModal: (
    component?: ComponentOrNone,
    title?: ComponentOrNone,
    classes?: Record<any, any>
  ) => void;
  closeModal: () => void;
  updateModalQueue: (data: QueueItemType) => void;
  checkModalQueue: () => void;
  showModal: (title: any, content: any, styles: any) => void;
  showWalletHistory: (
    profileId: string | number,
    claimed?: number,
    unrealized?: number
  ) => void;
};

const defaultContext = {
  show: false,
  title: undefined,
  content: undefined,
  classes: undefined,
  openModal: () => ({}),
  closeModal: () => ({}),
  updateModalQueue: (data: QueueItemType) => ({}),
  checkModalQueue: () => ({}),
  showModal: (title: any, content: any, styles: any) => ({}),
  showWalletHistory: (
    profileId: string | number,
    claimed?: number,
    unrealized?: number
  ) => ({})
};

export const SimpleModalContext =
  createContext<SimpleModalContextType>(defaultContext);

type Props = {
  children: React.ReactElement;
};

export const SimpleModalContextProvider = ({ children }: Props) => {
  const isUserBalanceInitialUpdate = useRef(true);
  const { userBalance } = useContext(AuthContext);
  const { updateField } = useContext(ModalDataContext);

  const queueInit = getModalQueue();
  const [queue, setQueue] = useState<QueueItemType[]>(queueInit);
  const [nextOpen, setNextOpen] = useState<QueueItemType | undefined>();

  const [show, setShow] = useState<boolean>(false);
  const [title, setTitle] = useState<React.ReactNode | undefined>();
  const [content, setContent] = useState<React.ReactNode | undefined>();
  const [classes, setClasses] = useState<Record<any, any> | undefined>();

  const ideaCoinsInfoClasses = useStyles();

  type modalStyleProps = {
    classes: Record<never, string>;
    theme: Theme;
    css: Css;
    cx: Cx;
  };

  useEffect(() => {
    if (isUserBalanceInitialUpdate.current) {
      isUserBalanceInitialUpdate.current = false;
      return;
    }
    checkModalQueue();
  }, [userBalance]);

  const openModal = useCallback(
    (
      component: React.ReactNode | undefined,
      title: React.ReactNode | undefined
    ) => {
      setContent(component);
      setTitle(title);
      setShow(true);
    },
    [setContent, setTitle, setShow]
  );

  const showModal = useCallback(
    (
      title: string,
      content: StyledComponent<any, any, any>,
      styles: modalStyleProps
    ) => {
      setTitle(title);
      setContent(content);
      setClasses(styles);
      setShow(true);
    },
    [setShow, setContent, setTitle, setClasses]
  );

  const showWalletHistory = useCallback(
    (profileId: string | number, claimed?: number) => {
      updateField(Constants.PAY_OPTIONS, [claimed]);

      const title = <IdeaPointsInfoTitle />;
      const content = <IdeaPointsInfo profileId={profileId} />;

      setTitle(title);
      setContent(content);
      setClasses(ideaCoinsInfoClasses.modal);
      setShow(true);
    },
    [
      updateField,
      setShow,
      setContent,
      setTitle,
      setClasses,
      ideaCoinsInfoClasses.modal
    ]
  );

  const updateModalQueue = useCallback(
    (item: QueueItemType) => {
      const newQueue = [...queue, item];
      setQueue(newQueue);
      saveModalQueue(newQueue);
    },
    [setQueue, queue]
  );

  const checkModalQueue = useCallback(() => {
    const queueClone = queue.slice(0);
    const next = queueClone.shift();
    if (next) {
      setNextOpen(next);
      const newQueue = [...queueClone];
      setQueue(newQueue);
      saveModalQueue(newQueue);
    }
  }, [setNextOpen, setQueue, queue]);

  const closeModal = useCallback(() => {
    setShow(false);
    setTimeout(() => {
      setContent(undefined);
      setTitle(undefined);
    }, 500);

    setTimeout(() => {
      checkModalQueue();
    }, 1000);
  }, [setShow, setContent, setTitle, checkModalQueue]);

  return (
    <SimpleModalContext.Provider
      value={{
        show,
        title,
        content,
        classes,
        openModal,
        closeModal,
        updateModalQueue,
        checkModalQueue,
        showModal,
        showWalletHistory
      }}
    >
      {children}
    </SimpleModalContext.Provider>
  );
};

export const SimpleModalContextConsumer = SimpleModalContext.Consumer;

export default SimpleModalContext;
