import React, { useCallback, useContext, useState } from 'react';
import { useTheme, alpha } from '@mui/material/styles';
import { PsButton } from 'components/common/PsButton';
import Radio from 'components/Radio';
import { AuthContext } from 'contexts/AuthContext';
import { ModalContext, ModalComponentsKeys } from 'contexts/ModalContext';
import { ModalDataContext } from 'contexts/ModalDataContext';
import ProblemModal from 'modals/AddProblem';
import ProductModal from 'modals/AddProduct';
import SolutionModal from 'modals/AddSolution';
import styles from 'modals/ModalWindow.module.scss';
import ModalWindow from 'modals/ModalWindow';
import { makeStyles } from 'tss-react/mui';
import { Constants } from 'utilities/constants';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  return {
    root: {
      borderRadius: 8,
      margin: '8px 0',
      border: `solid 1px ${alpha(theme.palette.primary.main, 0.15)}`,
      background: 'rgb(248, 245, 255, 0.5)',
      color: theme.palette.primary.main,
      '&:hover': {
        border: `solid 1px ${alpha(theme.palette.primary.main, 0.5)}`
      }
    }
  };
});

const useButtonStyles = makeStyles()(() => {
  return {
    root: {
      margin: '8px 0',
      fontWeight: 400
    }
  };
});

const AddNewEntity = () => {
  const { user } = useContext(AuthContext);
  const { setType, updateField } = useContext(ModalDataContext);
  const [selectedEntity, setSelectedEntity] = useState<string | null>(null);
  const { classes } = useStyles();
  const buttonClasses = useButtonStyles();

  const { openModal, toggleModal } = useContext(ModalContext);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isSolModalOpen, setIsSolModalOpen] = useState<boolean>(false);
  const [isProdModalOpen, setIsProdModalOpen] = useState<boolean>(false);

  const options = [
    { label: 'Problem', value: ModalComponentsKeys.addNewProblem },
    { label: 'Solution', value: ModalComponentsKeys.addNewSolution },
    { label: Constants.C_PRODUCT, value: ModalComponentsKeys.addNewInvention }
  ];
  if (user && (user.isTagModerator || user.isChallengeOwner)) {
    options.push({
      label: 'Contest',
      value: ModalComponentsKeys.addNewContest
    });
  }
  if (user && user.isAdmin) {
    options.push({
      label: 'Challenge',
      value: ModalComponentsKeys.addNewChallenge
    });
  }
  const handleEntityChange = (e: React.ChangeEvent, value: string) => {
    setSelectedEntity(value);
    setType(value);
    updateField('selectApplication', undefined);
  };

  const openNewModal = useCallback(() => {
    if (selectedEntity) {
      if (selectedEntity == ModalComponentsKeys.addNewProblem) {
        setIsModalOpen(true);
      } else if (selectedEntity == ModalComponentsKeys.addNewSolution) {
        setIsSolModalOpen(true);
      } else {
        setIsProdModalOpen(true);
      }
    }
  }, [selectedEntity, openModal]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    toggleModal();
  };

  const handleSolCloseModal = useCallback(() => {
    setIsSolModalOpen(false);
    toggleModal();
  }, [toggleModal]);

  const handleProdCloseModal = useCallback(() => {
    setIsProdModalOpen(false);
    toggleModal();
  }, [toggleModal]);

  return (
    <>
      <ModalWindow title="Add new">
        <RadioGroup
          onChange={handleEntityChange}
          value={selectedEntity}
          aria-label="entity"
          name="entity"
        >
          {options.map(({ label, value }) => {
            return (
              <FormControlLabel
                key={value}
                classes={classes}
                value={value}
                control={<Radio />}
                label={label}
              />
            );
          })}
        </RadioGroup>
        <div className={styles.actions}>
          <PsButton
            onClick={openNewModal}
            disabled={!selectedEntity}
            fullWidth
            classes={buttonClasses}
          >
            Start
          </PsButton>
          <PsButton
            onClick={toggleModal}
            fullWidth
            cancel
            classes={buttonClasses}
          >
            Cancel
          </PsButton>
        </div>
      </ModalWindow>
      {isModalOpen && (
        <ProblemModal open={isModalOpen} onClose={() => handleCloseModal()} />
      )}
      {isSolModalOpen && (
        <SolutionModal
          open={isSolModalOpen}
          onClose={() => handleSolCloseModal()}
        />
      )}
      {isProdModalOpen && (
        <ProductModal
          open={isProdModalOpen}
          initialValue={''}
          onClose={() => handleProdCloseModal()}
        />
      )}
    </>
  );
};

export default AddNewEntity;
