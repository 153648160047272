import React, { useCallback, useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { Tags } from 'components/Tag';
import { CoinsSmall } from 'components/Coins';
import { PsButton } from 'components/common/PsButton';
import { Application } from 'components/CardApplication';
import { Solution } from 'components/CardSolution';
import {
  Card,
  CardImage,
  CardBody,
  CardTitle,
  CardOwner,
  CardText,
  CardActions,
  CardVote,
  CardRow,
  CardCell
} from 'components/card-small';
import { ModalComponentsKeys, ModalContext } from 'contexts/ModalContext';
import { ModalDataContext } from 'contexts/ModalDataContext';
import { AuthContext } from 'contexts/AuthContext';
import { placeholderType } from '../../helpers';
import { TagRemove } from 'components/Tag';
import { ShareBtn } from 'components/ShareBtn';
import { PsTheme } from '../../theme';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root' | 'tags';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {},
    tags: {
      '& > div': {
        marginTop: 4,
        marginBottom: 4
      }
    }
  };
});

export type CardSolutionSmallProps = {
  solution: Solution;
  onTagRemove?: () => void;
  filterTagName?: string;
  productId?: string | number;
  applications?: Application[] | undefined;
};

const CardSolutionSmallView = ({
  solution,
  onTagRemove,
  filterTagName,
  productId = '',
  applications
}: CardSolutionSmallProps) => {
  const {
    id,
    key,
    title,
    teaser,
    body,
    problem,
    owner,
    ownerInfo,
    createdAt,
    tags = [],
    tagsInfo = [],
    files,
    likes,
    dislikes,
    reward,
    isPublic = false
  } = solution;
  const { user } = useContext(AuthContext);
  const { openModal, openProblemCreate, openInventionAttachToSolution } =
    useContext(ModalContext);
  const { setType, updateField } = useContext(ModalDataContext);
  const { classes } = useStyles();

  const image = files && files.length ? files[0] : undefined;
  const url = `/solutions/${key || id}`;

  const onAddToInventionClick = useCallback(() => {
    if (applications?.length) {
      openInventionAttachToSolution({
        solutionId: id,
        solutionProblemId: problem,
        solutionTitle: teaser || title,
        inventions: applications
      });
    } else {
      openProblemCreate({ productId });
    }
  }, [applications, openModal]);

  const onBrainstormClick = useCallback(() => {
    setType(ModalComponentsKeys.addNewSolution);
    updateField('parentSolution', id);
    updateField('parentSolutionLabel', teaser || title);
    openModal(ModalComponentsKeys.brainstormSolution);
  }, [updateField, openModal, setType, id, teaser, title]);

  return (
    <Card>
      <CardImage image={image} href={url} type={placeholderType.S} />
      {onTagRemove ? (
        <TagRemove
          type="solutions"
          itemId={id}
          tagKey={filterTagName}
          onRemove={onTagRemove}
        />
      ) : null}
      <CardBody>
        <CardTitle title={teaser || title} href={url}>
          <Tags className={classes.tags} tagsList={tagsInfo} />
        </CardTitle>
        <CardOwner
          owner={owner}
          ownerKey={ownerInfo && ownerInfo.key}
          ownerName={ownerInfo && ownerInfo.username}
          createdAt={createdAt}
        />
        <CardText>{body}</CardText>
        <CardRow>
          <CoinsSmall count={reward} />
          <CardCell>
            <CardActions>
              <PsButton
                onClick={onBrainstormClick}
                color="secondary"
                smallest
                coins="up to 30"
              >
                Improve
              </PsButton>
              {applications ? (
                <PsButton
                  onClick={onAddToInventionClick}
                  color="secondary"
                  smallest
                >
                  Incorporate to the Concept
                </PsButton>
              ) : null}
              <ShareBtn solution={solution} tagsList={tagsInfo} />
            </CardActions>
          </CardCell>
        </CardRow>
      </CardBody>
      <CardVote
        resource="solutions"
        itemId={id}
        likes={likes}
        dislikes={dislikes}
        user={user}
      />
    </Card>
  );
};

export const CardSolutionSmall = styled(CardSolutionSmallView)({});

export default CardSolutionSmall;
