import {
  Button,
  IconButton,
  Link,
  TextField,
  Typography,
  styled
} from '@mui/material';
import { colorPalette } from 'theme';

export const TitleText = styled(Typography)({
  fontSize: '1.75rem',
  fontWeight: '700',
  lineHeight: '1'
});

export const StatusText = styled(Typography)({
  fontSize: '.875rem',
  fontWeight: '400',
  lineHeight: '1',
  marginTop: '.5rem'
});

export const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1)
}));

export const StyledTextField = styled(TextField)({
  marginBottom: '1rem',
  width: '100%'
});

export const SendButton = styled(Button)({
  backgroundColor: `${colorPalette.purple}`,
  boxShadow: 'none',
  color: colorPalette.white,
  fontSize: '1.125rem',
  fontWeight: '600',
  height: '3.25rem',
  textTransform: 'none',
  width: '100%',
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: `${colorPalette.purple}95`
  }
});

export const StyledParagraph = styled(Typography)({
  fontSize: '.875rem',
  margin: 0
});

export const StyledLink = styled(Link)({
  fontSize: '.875rem',
  margin: '0rem',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline'
  }
});
