import React, { ReactElement, useCallback, useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { Tags } from 'components/Tag';
import { PsButton } from 'components/common/PsButton';
import { Problem } from 'components/CardProblem';
import {
  Card,
  CardImage,
  CardBody,
  CardTitle,
  CardOwner,
  CardText,
  CardActions,
  CardVote,
  CardRow,
  CardCell
} from 'components/card-small';
import { placeholderType } from '../../helpers';
import { ModalContext } from 'contexts/ModalContext';
import { AuthContext } from 'contexts/AuthContext';
import { TagRemove } from 'components/Tag';
import { ShareBtn } from 'components/ShareBtn';
import { PsTheme } from '../../theme';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root' | 'tags';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {},
    tags: {
      '& > div': {
        marginTop: 4,
        marginBottom: 4
      }
    }
  };
});

export type CardProblemSmallProps = {
  problem: Problem;
  onTagRemove?: () => void;
  filterTagName?: string;
};

const CardProblemSmallView = ({
  problem,
  onTagRemove,
  filterTagName
}: CardProblemSmallProps) => {
  const {
    id,
    key,
    title,
    teaser,
    body,
    owner,
    ownerInfo,
    tags = [],
    tagsInfo = [],
    createdAt,
    files,
    likes,
    dislikes,
    parentApplicationKey,
    parentApplicationTitle,
    parentApplicationProblemKey,
    parentApplicationProblemName,
    parentProductKey,
    parentProductTitle,
    parentPriorArtKey,
    parentPriorArtTitle,
    isPublic = false
  } = problem;
  const { user } = useContext(AuthContext);
  const { openSolutionCreate } = useContext(ModalContext);
  const { classes } = useStyles();

  const image = files && files.length ? files[0] : undefined;
  const url = `/problems/${key || id}`;

  const onSolveClick = useCallback(() => {
    openSolutionCreate({ problemId: id });
  }, [openSolutionCreate, id]);

  let subTitle: ReactElement | undefined = undefined;
  if (parentApplicationTitle && parentApplicationProblemName) {
    subTitle = (
      <>
        a{' '}
        <Link to={`/problems/${parentApplicationProblemKey}`}>
          <a>{parentApplicationProblemName}</a>
        </Link>{' '}
        problem of the{' '}
        <Link to={`/inventions/${parentApplicationKey}`}>
          <a>{parentApplicationTitle}</a>
        </Link>{' '}
        invention
      </>
    );
  }
  if (parentProductTitle) {
    subTitle = (
      <>
        problem of the{' '}
        <Link to={`/products/${parentProductKey}`}>
          <a>{parentProductTitle}</a>
        </Link>{' '}
        product
      </>
    );
  } else if (parentPriorArtTitle) {
    subTitle = (
      <>
        problem of the{' '}
        <Link to={`/prior-arts/${parentPriorArtKey}`}>
          <a>{parentPriorArtTitle}</a>
        </Link>{' '}
        prior art
      </>
    );
  }

  return (
    <Card>
      <CardImage image={image} href={url} type={placeholderType.P} />
      {onTagRemove ? (
        <TagRemove
          type="problems"
          itemId={id}
          tagKey={filterTagName}
          onRemove={onTagRemove}
        />
      ) : null}
      <CardBody>
        <CardTitle title={teaser || title} subTitle={subTitle} href={url}>
          <Tags className={classes.tags} tagsList={tagsInfo} />
        </CardTitle>
        <CardOwner
          owner={owner}
          ownerKey={ownerInfo && ownerInfo.key}
          ownerName={ownerInfo && ownerInfo.username}
          createdAt={createdAt}
        />
        <CardText>{body}</CardText>
        <CardRow>
          <div></div>
          <CardCell>
            <CardActions>
              <PsButton
                onClick={onSolveClick}
                color="secondary"
                coins="+1"
                smallest
              >
                Solve
              </PsButton>
              <ShareBtn problem={problem} tagsList={tagsInfo} />
            </CardActions>
          </CardCell>
        </CardRow>
      </CardBody>
      <CardVote
        resource="problems"
        itemId={id}
        likes={likes}
        dislikes={dislikes}
        user={user}
      />
    </Card>
  );
};

export const CardProblemSmall = styled(CardProblemSmallView)({});

export default CardProblemSmall;
