import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { CancelOutlined } from '@mui/icons-material';
import SimpleIconButton from 'components/SimpleIconButton';
import { BaseModal } from 'modals/Common/BaseModal';
import Actions from 'redux-state/actions';
import { colorPalette } from 'theme';
import {
  Breakpoints,
  Constants,
  SUBSCRIPTION_PLANS
} from 'utilities/constants';
import {
  StyledHeaderBox,
  StyledHeaderTypography,
  StyledSubHeaderTypography,
  SubCardWrapper,
  SubsWrapper,
  WhiteSpace
} from './styledComponents';
import { SubscriptionCard } from './SubscriptionCard';

const SUBSCRIPTION_OPTIONS = [
  {
    bestValue: false,
    features: [
      'Explore & Find Challenges',
      'Win Big in Contests',
      'Submit Ideas & Earn Rewards',
      'Earn More from Collaborations'
    ],
    id: 'free-tier',
    price: 0,
    shortDescription: null,
    title: SUBSCRIPTION_PLANS.FREE_TIER
  },
  {
    bestValue: false,
    features: [
      'Ideal for short projects',
      'Create ideas & visuals',
      'AI tools for innovation',
      'Perfect for campaigns'
    ],
    id: 'quarterly-subscription',
    price: 50,
    shortDescription: 'Receive 350 credits per month',
    title: SUBSCRIPTION_PLANS.QUARTERLY_SUBSCRIPTION
  },
  {
    bestValue: true,
    features: [
      'Affordable, high credits',
      'For ongoing idea creation',
      'AI insights & solutions',
      'Great for regular projects'
    ],
    id: 'yearly-subscription',
    price: 40,
    shortDescription: 'Receive 400 credits per month',
    title: SUBSCRIPTION_PLANS.YEARLY_SUBSCRIPTION
  }
];

interface SubscriptionModalProps {
  open: boolean;
}
export const SubscriptionModal: FC<SubscriptionModalProps> = ({ open }) => {
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    dispatch(Actions.toggleSubscriptionModal(false));
  }, [dispatch]);

  return (
    <BaseModal open={open} maxWidth={Breakpoints.SMALL} onClose={handleClose}>
      <SimpleIconButton
        iconStyle={{
          color: colorPalette.white,
          position: 'absolute',
          right: '.4375rem',
          top: '.4375rem'
        }}
        Icon={CancelOutlined}
        onClick={handleClose}
      />
      <StyledHeaderBox>
        <StyledHeaderTypography>
          {Constants.SUBSCRIPTION_AND_CREDIT_PURCHASE}
        </StyledHeaderTypography>
        <StyledSubHeaderTypography>
          {Constants.SUBSCRIBE_FOR_MONTHLY}
        </StyledSubHeaderTypography>
        <StyledSubHeaderTypography>
          {Constants.VISUALIZE_INNOVATIVE_IDEAS}
        </StyledSubHeaderTypography>
        <WhiteSpace />
      </StyledHeaderBox>
      <SubsWrapper>
        {SUBSCRIPTION_OPTIONS.map((subscription) => (
          <SubCardWrapper key={subscription.id}>
            <SubscriptionCard subscription={subscription} />
          </SubCardWrapper>
        ))}
      </SubsWrapper>
    </BaseModal>
  );
};
