import SolutionName, { defaultSolutionTitle } from './steps/SolutionName';
import SolutionTagsStep from './steps/SolutionTagsStep';
import FinilizeSolutionStep from './steps/FinilizeSolutionStep';
import {
  ValidateFormType,
  FormStepConfig,
  AddSolutionSteps
} from 'contexts/ModalDataContext';
import { isTitleValid } from '../../helpers';

export { defaultSolutionTitle };

export const ADD_SOLUTION_STEPS_CONFIG: {
  [name: string]: FormStepConfig;
} = {
  [AddSolutionSteps.SolutionName]: {
    Component: SolutionName,
    nextStep: AddSolutionSteps.SolutionTags,
    isNextStepBlocked: (data) => {
      if (
        !data.titleSolution ||
        (!data.parentProblem && !data.parentSolution)
      ) {
        return true;
      }
      if (data.finalizeType === 'public') {
        return !data.termsAgree;
      }
      return false;
    },
    validate: (data): ValidateFormType => {
      if (!data.titleSolution) {
        return { success: false, errors: { titleSolution: 'Title required' } };
      }
      if (
        data.titleSolution &&
        !isTitleValid(data.titleSolution, defaultSolutionTitle)
      ) {
        return {
          success: false,
          errors: {
            titleSolution: `Title should starts with "${defaultSolutionTitle}"`
          }
        };
      }
      if (data.finalizeType === 'public' && !data.termsAgree) {
        return {
          success: false,
          errors: {
            termsAgree: 'required'
          }
        };
      }
      if (!data.titleSolution && !data.parentProblem && !data.parentSolution) {
        return {
          success: false,
          errors: {
            title: 'required',
            parentProblem: 'required',
            parentSolution: 'required'
          }
        };
      } else if (!data.parentProblem && !data.parentSolution) {
        return {
          success: false,
          errors: { parentProblem: 'required', parentSolution: 'required' }
        };
      }
      return { success: true };
    }
  },
  [AddSolutionSteps.SolutionTags]: {
    nextStep: AddSolutionSteps.FinilizeSolution,
    Component: SolutionTagsStep,
    isNextStepBlocked: (data) => {
      if (data.tagsSolution.length === 0) return true;

      return false;
    },
    validate: (data): ValidateFormType => {
      if (data.tagsSolution) {
        return { success: true };
      }
      return { success: false, errors: { tags: 'required' } };
    }
  },
  [AddSolutionSteps.FinilizeSolution]: {
    isFinalStep: true,
    Component: FinilizeSolutionStep,
    // nextStep: (data) => {
    //   return data.createdSuccess ? AddOrderSteps.Created : AddOrderSteps.Failed;
    // },
    nextButtonTitle: 'Finish'
  }
};
