/* eslint-disable */
import React from 'react';
import { useDispatch } from 'react-redux';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import {
  Box,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  TablePagination,
  Typography
} from '@mui/material';
import { styled } from '@mui/system';
import ProblemIcon from 'assets/icons/problems.svg';
import HexagonIcon from 'assets/icons/PurpleHexagon.svg';
import SolutionIcon from 'assets/icons/solutions.svg';
import Actions from 'redux-state/actions';
import { GetProfileConceptsLoader, GetUser } from 'redux-state/selectors';
import { colorPalette } from 'theme';
import { Constants } from 'utilities/constants';
import { LogoMindminer } from '../icons/LogoMindminer';
import {
  StyledBoxHeadingList,
  StyledIconsRow,
  StyledIconsRowItem
} from './styles';

// Styled components for the layout
const StyledListItem = styled(ListItem)(({ theme, selected }) => ({
  border: selected
    ? `2px solid ${theme.palette.primary.main}`
    : `1px solid ${colorPalette.whiteGrey}`,
  borderRadius: '8px',
  marginBottom: '8px',
  cursor: 'pointer'
}));

const ConceptList = ({
  concepts,
  selectedConceptId,
  onSelectConcept,
  totalRows,
  paginationOptions,
  isConcept = true
}) => {
  const { page, rowsPerPage, setPage, setRowsPerPage } = paginationOptions;
  const conceptLoader = GetProfileConceptsLoader();
  const user = GetUser();
  const dispatch = useDispatch();

  const getRowsPerPage = (totalRows: number): Array<number> => {
    return totalRows > 10 ? [5, 10, 15] : totalRows > 5 ? [5, 10] : [];
  };

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleConceptClick = (id) => {
    onSelectConcept(id);
    dispatch(Actions.editProfile(user?.id, { activeConcept: id }));
  };

  return (
    <Box padding={2}>
      {isConcept && (
        <StyledBoxHeadingList>
          <Box>
            <img src={HexagonIcon} alt="HexagonIcon" />
          </Box>
          <Typography
            sx={{
              fontSize: '0.98rem',
              fontWeight: 'bold',
              color: colorPalette.purple,
              fontFamily: 'Montserrat, sans-serif',
              marginLeft: '5px'
            }}
          >
            {Constants.SELECT_ONE_OF_YOUR_CONCEPT_TO_PATENT_BUILD_AND_SELL}
          </Typography>
        </StyledBoxHeadingList>
      )}
      {!conceptLoader ? (
        <List>
          {concepts?.map((concept) => (
            <StyledListItem
              key={concept.id}
              selected={concept.id === selectedConceptId}
              onClick={() => handleConceptClick(concept.id)}
            >
              <Box display="flex" flexDirection="column" width="100%">
                <Typography
                  variant="body1"
                  color={
                    concept.id === selectedConceptId
                      ? colorPalette.purple
                      : colorPalette.black
                  }
                >
                  {concept.title}
                </Typography>
                <StyledIconsRow>
                  <StyledIconsRowItem>
                    <StyledIcon alt="question mark" src={ProblemIcon} />
                    <Typography sx={{ mt: '2px', ml: '2px' }}>
                      {concept.problems?.length}
                    </Typography>
                  </StyledIconsRowItem>
                  <StyledIconsRowItem>
                    <StyledIcon alt="solution icon" src={SolutionIcon} />
                    <Typography sx={{ mt: '2px', ml: '2px' }}>
                      {concept.selected?.length}
                    </Typography>
                  </StyledIconsRowItem>
                  <StyledIconsRowItem>
                    <LogoMindminer
                      fill={colorPalette.purple}
                      width={20}
                      height={20}
                    />
                    <Typography sx={{ mt: '2px', ml: '2px' }}>
                      {concept.ideaPoints}
                    </Typography>
                  </StyledIconsRowItem>
                </StyledIconsRow>
              </Box>
              <IconButton edge="end">
                <MoreVertIcon />
              </IconButton>
            </StyledListItem>
          ))}
        </List>
      ) : (
        <LinearProgress />
      )}
      {totalRows > 0 && (
        <TablePagination
          component="div"
          width={50}
          count={totalRows}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[]}
        />
      )}
    </Box>
  );
};

const StyledIcon = styled('img')({
  height: '20px',
  width: '20px'
});

export default ConceptList;
