import React from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { PsTheme } from '../../theme';
import { colors } from './consts';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root' | 'item' | 'link';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      listStyle: 'none',
      margin: 0,
      padding: '13px 0 20px 5px',
      whiteSpace: 'nowrap'
    },
    item: {
      padding: '6px 0 7px',
      textAlign: 'center'
    },
    link: {
      position: 'relative',
      display: ' inline-block',
      width: 45,
      height: 45,
      borderRadius: '50%',
      background: 'red',
      '&.active': {
        border: 'solid 4px #5a5a5a'
      }
    }
  };
});

export type DrawColorToolsProps = {
  className?: string;
  active: string;
  onSelect: (color: string) => void;
};

const DrawColorToolsView = ({
  className,
  active,
  onSelect
}: DrawColorToolsProps) => {
  const { classes } = useStyles();
  let rootClassName = classes.root;
  if (className) {
    rootClassName += ' ' + className;
  }
  return (
    <ul className={rootClassName}>
      {colors.map((color) => {
        const isActive = color === active ? 'active' : '';

        const onClick = (e: React.MouseEvent) => {
          e.preventDefault();
          onSelect(color);
        };
        return (
          <li className={classes.item} key={color}>
            <a
              href={`#color-${color}`}
              onClick={onClick}
              className={`${classes.link} ${isActive}`}
              style={{ background: color }}
            >
              <span className="icon" />
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export const DrawColorTools = styled(DrawColorToolsView)({});

export default DrawColorTools;
