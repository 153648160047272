import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import { Picture } from 'helpers';
import { TagInfo } from 'components/CardTag';
import { Constants, ERRORS } from 'utilities/constants';
import { DescriptionField } from 'components/common/DescriptionField';
import { ImageContainer } from 'components/common/ImageContainer';
import ProblemField from 'components/common/ProblemField';
import PsInput from 'components/common/PsInput';
import { ShortEvent } from 'components/common/PsFormControl';
import { TagsField } from 'components/common/TagsField';
import VisibilityField from 'components/common/VisibilityField';
import {
  StyledDescriptionExampleText,
  StyledFieldLabel,
  TextWrapper
} from './StyledComponents';
import { OptionType } from '../Common/interface';

interface ContentProps {
  companyId?: string;
  description?: string;
  isIdeaMapPopUp?: boolean;
  onChange?: (e: ShortEvent) => void;
  parentNodeId?: string;
  productTags?: Array<TagInfo>;
  picture?: Picture;
  selectedCompany?: OptionType | null;
  selectedProblem?: OptionType | null;
  setTitle?: React.Dispatch<React.SetStateAction<string>>;
  setSelectedProblem?: React.Dispatch<React.SetStateAction<any | null>>;
  setDescription?: React.Dispatch<React.SetStateAction<string>>;
  setPicture?: React.Dispatch<React.SetStateAction<Picture>>;
  setSelectedCompany?: React.Dispatch<React.SetStateAction<OptionType | null>>;
  setSelectedTags?: React.Dispatch<
    React.SetStateAction<Array<OptionType | null>>
  >;
  startLoading?: () => void;
  stopLoading?: () => void;
  selectedTags?: Array<OptionType | null>;
  setTermsAgree?: React.Dispatch<React.SetStateAction<boolean>>;
  setVisibility?: React.Dispatch<React.SetStateAction<string>>;
  title: string;
  termsAgree?: boolean;
  visibility?: string;
  selectedParentProblem?: OptionType;
  setSelectedParentProblem?: React.Dispatch<React.SetStateAction<OptionType>>;
  preSelectedSolution?: string;
}

const descriptionExampleText = (
  <StyledDescriptionExampleText>
    {Constants.SOLUTION_HELPER_TEXT}
  </StyledDescriptionExampleText>
);

export const Content: React.FC<ContentProps> = ({
  description,
  isIdeaMapPopUp,
  picture,
  productTags,
  selectedProblem,
  selectedTags,
  setDescription,
  setPicture,
  setSelectedProblem,
  setSelectedTags,
  setTermsAgree,
  setTitle,
  setVisibility,
  startLoading,
  stopLoading,
  termsAgree,
  title,
  visibility,
  selectedParentProblem,
  setSelectedParentProblem,
  preSelectedSolution
}) => {
  const solutionFieldLabel = useMemo(
    () => (
      <StyledFieldLabel>
        {isIdeaMapPopUp
          ? Constants.INPUT_SOLUTION_FIELD_LABEL
          : Constants.YOUR_SOLUTION}
      </StyledFieldLabel>
    ),
    [isIdeaMapPopUp]
  );

  const defaultSolutionTitle = Constants.DEFAULT_SOLUTION_TITLE;
  const [finalTitle, setFinalTitle] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (!finalTitle) {
      setFinalTitle(defaultSolutionTitle);
    }
  }, [defaultSolutionTitle, finalTitle]);

  const onProblemNameChange = useCallback(
    (e: ShortEvent) => {
      const { value = '' } = e.target;
      const wordCount = value.trim().split(/\s+/).length;
      const isWordLimitExceeded = wordCount > 50;
      setErrorMessage(isWordLimitExceeded ? ERRORS.WORD_LIMIT_EXCEEDED : '');

      if (!isWordLimitExceeded) {
        const rawVal = value.slice(defaultSolutionTitle.length);
        const finalTitle = `${defaultSolutionTitle}${rawVal}`;
        setTitle(rawVal);
        setFinalTitle(finalTitle);
      }
    },
    [defaultSolutionTitle]
  );

  return (
    <Box>
      {!isIdeaMapPopUp && (
        <ProblemField
          selectedProblem={selectedParentProblem || selectedProblem}
          setSelectedProblem={setSelectedParentProblem || setSelectedProblem}
        />
      )}
      <TextWrapper
        helperText={errorMessage || Constants.SOLUTION_HELPER_TITLE}
        label={solutionFieldLabel}
        placeholder={Constants.DEFAULT_SOLUTION_TITLE}
        required
      >
        <PsInput
          name={Constants.TITLE_SOLUTION}
          onChange={onProblemNameChange}
          placeHolder={Constants.DEFAULT_SOLUTION_TITLE}
          value={preSelectedSolution || finalTitle}
          error={!!errorMessage}
        />
      </TextWrapper>

      <TagsField
        label={Constants.SOLUTION_TAGS_LABEL}
        placeholder={Constants.SELECT}
        productTags={productTags}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        startLoading={startLoading}
        stopLoading={stopLoading}
        title={preSelectedSolution || title}
      />

      <Grid
        columnSpacing={{ xs: 2 }}
        container
        direction={isXs ? Constants.COLUMN : Constants.row}
        rowSpacing={1}
      >
        <Grid item xs={12} sm={12} md={6}>
          <DescriptionField
            description={description}
            exampleText={descriptionExampleText}
            label={Constants.DESCRIBE_SOLUTION}
            setDescription={setDescription}
            title={preSelectedSolution || title}
            parentNode={selectedParentProblem || selectedProblem}
            type={Constants.SOLUTIONS}
          />
          <VisibilityField
            setTermsAgree={setTermsAgree}
            setVisibility={setVisibility}
            termsAgree={termsAgree}
            title={preSelectedSolution || title}
            visibility={visibility}
            startLoading={startLoading}
            stopLoading={stopLoading}
          />
        </Grid>
        <Grid item md={6} xs={12} sm={12}>
          <ImageContainer
            description={description}
            parentNode={selectedParentProblem || selectedProblem}
            picture={picture}
            setPicture={setPicture}
            supportText={true}
            title={preSelectedSolution || title}
            type={Constants.SOLUTIONS}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
