import { styled } from '@mui/material/styles';
import {
  Avatar,
  Box,
  Button,
  Typography,
  TypographyProps
} from '@mui/material';
import AvatarGroup from '@mui/material/AvatarGroup';
import { colorPalette } from '../../theme';

export const StyledBlock = styled(Box)({
  background: colorPalette.white,
  border: `1px solid ${colorPalette.lightShadowGrey}`,
  borderRadius: '7px',
  display: 'flex',
  flexDirection: 'column',
  padding: '10px 10px'
});

export const StyledStatsContent = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between'
});

export const StyledTypography = styled(Typography)({
  color: colorPalette.purple,
  fontSize: '12px',
  fontWeight: '600'
});

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: colorPalette.lavenderPurple,
  border: '2px',
  borderColor: colorPalette.white,
  color: 'inherit',
  height: theme.spacing(5),
  width: theme.spacing(5),
  cursor: 'pointer'
}));

export const AddEmployeeIcon = styled(Avatar)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: colorPalette.purple,
  border: '2px',
  borderColor: colorPalette.white,
  color: 'inherit',
  display: 'flex',
  height: theme.spacing(5),
  justifyContent: 'center',
  width: theme.spacing(5)
}));

export const StyledAvatarGroup = styled(AvatarGroup)({
  color: colorPalette.purple
});

export const OverflowAvater = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  backgroundColor: colorPalette.lavenderPurple,
  borderRadius: '50%',
  fontSize: '20px',
  height: '100%',
  justifyContent: 'center',
  width: '100%'
});

export const TitleContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: '6px'
});

interface StyledTypographyProps extends TypographyProps {
  isMediumScreen?: boolean;
}

export const TitleText = styled(Typography)<StyledTypographyProps>(
  ({ isMediumScreen }) => ({
    fontSize: isMediumScreen ? '12px' : '14px',
    fontWeight: 600
  })
);

export const StyledButton = styled(Button)({
  color: colorPalette.purple,
  cursor: 'pointer',
  fontSize: 13,
  fontWeight: 500,
  textDecoration: 'underline',
  textTransform: 'capitalize',
  '&:hover': {
    backgroundColor: colorPalette.transparent
  }
});

export const AvatarContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
});
