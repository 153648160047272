import React, { useState, useContext, useCallback, useEffect } from 'react';
import { styled, useTheme, lighten } from '@mui/material/styles';

import { TagInfo } from 'components/CardTag';
import { Profile } from 'components/CardProfile';
import { AuthContext } from 'contexts/AuthContext';
import { DataContext } from 'contexts/DataContext';
import { PsLogo } from 'components/common/PsLogo';
import { PsButton } from 'components/common/PsButton';
import { PsInput } from 'components/common/PsInput';
import { PsFormControl, ShortEvent } from 'components/common/PsFormControl';
import { PsTheme } from '../../theme';
import Popover, {
  PopoverOrigin,
  PopoverPosition,
  PopoverReference
} from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { OneOfEntity } from './OneOfEntity';
import Config from 'config/config';
import { makeStyles } from 'tss-react/mui';

const getResourceType = (entity: OneOfEntity): string => {
  if (entity.problem) {
    return 'problems';
  } else if (entity.solution) {
    return 'solutions';
  } else if (entity.application) {
    return 'applications';
  } else if (entity.product) {
    return 'products';
  } else if (entity.priorArt) {
    return 'prior-arts';
  } else if (entity.tag) {
    return 'tags';
  } else if (entity.contest) {
    return 'contests';
  } else if (entity.challenge) {
    return 'challenges';
  } else {
    return 'profiles';
  }
};

const getResourceId = (entity: OneOfEntity, key = 'id'): string | number => {
  if (entity.problem) {
    return entity.problem[key];
  } else if (entity.solution) {
    return entity.solution[key];
  } else if (entity.application) {
    return entity.application[key];
  } else if (entity.product) {
    return entity.product[key];
  } else if (entity.priorArt) {
    return entity.priorArt[key];
  } else if (entity.tag) {
    return entity.tag[key];
  } else if (entity.contest) {
    return entity.contest[key];
  } else if (entity.challenge) {
    return entity.challenge[key];
  } else if (entity.profile) {
    return entity.profile[key];
  }
  return '';
};

const getResourceName = (entity: OneOfEntity): string | undefined => {
  if (entity.problem) {
    return entity.problem.teaser || entity.problem.title;
  } else if (entity.solution) {
    return entity.solution.teaser || entity.solution.title;
  } else if (entity.application) {
    return entity.application.teaser || entity.application.title;
  } else if (entity.product) {
    return entity.product.title;
  } else if (entity.priorArt) {
    return entity.priorArt.title;
  } else if (entity.tag) {
    return entity.tag.name;
  } else if (entity.contest) {
    return entity.contest.title;
  } else if (entity.challenge) {
    return entity.challenge.title;
  } else if (entity.profile) {
    return entity.profile.username;
  }
  return '';
};

const getShareText = (
  entity: OneOfEntity,
  tagsList?: Array<TagInfo>
): string => {
  let text = '';
  let tagsStr = '';
  if (tagsList && tagsList.length) {
    const tagNames = tagsList.filter((t) => t.name).map((t) => `#${t.name}`);
    tagsStr = ` in the ${tagNames.join(', ')} group`;
  }
  if (entity.problem) {
    text = `Earn IdeaCoins and create Idea #NFTs by solving the problem of ${entity.problem.title}${tagsStr} on MindMiner.`;
  } else if (entity.solution) {
    text = `Earn IdeaCoins and create Idea #NFTs by improving the solution of ${entity.solution.title}${tagsStr} on MindMiner.`;
  } else if (entity.application) {
    text = `Earn IdeaCoins and create Idea #NFTs by improving the invention of ${entity.application.title}${tagsStr} on MindMiner.`;
  } else if (entity.product) {
    text = `Earn IdeaCoins and create Idea #NFTs by improving the product of ${entity.product.title}${tagsStr} on MindMiner.`;
  } else if (entity.priorArt) {
    text = `Earn IdeaCoins and create Idea #NFTs by improving the prior art of ${entity.priorArt.title}${tagsStr} on MindMiner.`;
  } else if (entity.tag) {
    text = `Earn IdeaCoins and create Idea #NFTs with ${entity.tag.name} MindMiners.`;
  } else if (entity.profile) {
    text = `Earn IdeaCoins and create Idea #NFTs with ${entity.profile.username} on MindMiner.`;
  } else if (entity.challenge) {
    text = `Earn IdeaCoins and create Idea #NFTs with ${entity.challenge.title} challenge on MindMiner.`;
  } else if (entity.contest) {
    const coins = entity.contest.bountyCoins;
    const name = entity.contest.title;
    const tag = entity.contest.tagName;
    text = `Earn the ${coins} IdeaCoin jackpot by solving the ${name} problems in the ${tag} group on MindMiner"`;
  }
  return text;
};

const rewardType = {
  SHARE_FB: 'share-fb',
  SHARE_LN: 'share-ln',
  SHARE_TW: 'share-tw',
  SHARE_IN: 'share-in',
  SHARE_WA: 'share-wa',
  SHARE_RB: 'share-rb',
  SHARE_TT: 'share-tt'
};

const ShareIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M18 8C19.6569 8 21 6.65685 21 5C21 3.34315 19.6569 2 18 2C16.3431 2 15 3.34315 15 5C15 6.65685 16.3431 8 18 8Z"
        stroke="currentColor"
        strokeWidth="1.76842"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 15C7.65685 15 9 13.6569 9 12C9 10.3431 7.65685 9 6 9C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15Z"
        stroke="currentColor"
        strokeWidth="1.76842"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 22C19.6569 22 21 20.6569 21 19C21 17.3431 19.6569 16 18 16C16.3431 16 15 17.3431 15 19C15 20.6569 16.3431 22 18 22Z"
        stroke="currentColor"
        strokeWidth="1.76842"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.59058 13.51L15.4206 17.49"
        stroke="currentColor"
        strokeWidth="1.76842"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4106 6.51001L8.59058 10.49"
        stroke="currentColor"
        strokeWidth="1.76842"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

type ClassKey =
  | 'holder'
  | 'button'
  | 'buttonIcon'
  | 'buttonLogo'
  | 'coins'
  | 'popover'
  | 'root'
  | 'paper'
  | 'title'
  | 'social'
  | 'input'
  | 'submit'
  | 'row'
  | 'newShareButton';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    holder: {},
    button: {
      display: 'inline-block',
      position: 'relative',
      marginRight: 20,
      padding: '12px 12px 8px',
      borderRadius: 10,
      color: lighten(psTheme.palette.third.main, 0.4),
      background: lighten(psTheme.palette.third.main, 0.9),
      cursor: 'pointer',
      '&:hover': {
        color: psTheme.palette.primary.main
      },
      '&.open': {
        color: psTheme.palette.primary.main
      }
    },
    buttonIcon: {},
    buttonLogo: {
      position: 'absolute',
      top: -6,
      right: -9
    },
    coins: {
      position: 'absolute',
      top: -8,
      left: '100%',
      paddingLeft: 12,
      color: psTheme.palette.primary.main
    },
    popover: {
      // pointerEvents: 'none',
    },
    root: {},
    paper: {
      width: 380,
      margin: '10px 0',
      padding: '24px 22px 15px',
      borderRadius: 10,
      border: '1px solid rgba(121, 114, 140, 0.11)',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'
    },
    title: {
      fontSize: 18,
      marginBottom: 20,
      '& b': {
        fontWeight: 600
      }
    },
    social: {
      padding: '17px 0 38px',
      '& > a': {
        marginRight: 20
      },
      '& > a:last-child': {
        marginRight: 0
      }
    },
    input: {
      marginBottom: 15,
      '& label': {
        fontSize: 14,
        color: psTheme.palette.third.main
      }
    },
    submit: {
      paddingLeft: 15,
      paddingRight: 15,
      borderRadius: 10,
      lineHeight: '28px',
      minHeight: 40,
      '& svg': {
        marginLeft: 15
      }
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 10,
      '& > span': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
      },
      '& b': {
        fontWeight: 600
      },
      '& .coin': {
        marginLeft: 5
      }
    },
    newShareButton: {
      display: 'inline-flex',
      alignItems: 'center',
      color: '#677890',
      marginLeft: 50,
      '& > button': {
        background: 'none',
        border: 'none',
        outline: 'none',
        margin: '0',
        padding: '0',
        cursor: 'pointer',
        appearance: 'none'
      },
      '& > span': {
        paddingLeft: 10,
        fontSize: 18,
        lineHeight: '20px'
      }
    }
  };
});

type ShareBtnProps = OneOfEntity & {
  className?: string;
  tagsList?: Array<TagInfo>;
};

const ShareBtnView = ({ className, tagsList, ...entity }: ShareBtnProps) => {
  const { classes } = useStyles();
  const { user } = useContext(AuthContext);
  const { dataProvider, sharePopupInfo, showSharePopup } =
    useContext(DataContext);
  const psTheme: PsTheme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [email, setEmail] = useState('');
  const [emailSuccess, setEmailSuccess] = useState('');
  const [emailError, setEmailError] = useState('');
  const [emailLoading, setEmailLoading] = useState(false);
  const open = Boolean(anchorEl);
  const [sharePopupCenter, setSharePopupCenter] = useState<
    PopoverPosition | undefined
  >();

  const resourceType = getResourceType(entity);
  const resourceId = getResourceId(entity);
  const resourceKey = getResourceId(entity, 'key');
  const resourceName = getResourceName(entity) || '(empty)';
  const shareText = getShareText(entity, tagsList);
  let shareUrl = '';
  if (typeof window !== 'undefined') {
    shareUrl = `${window.location.origin}/${resourceType}/${resourceKey}`;
  }

  const shareTextE = encodeURIComponent(shareText + ' ');
  const shareUrlE = encodeURIComponent(shareUrl);
  const twitterShareUrl = `https://twitter.com/intent/tweet?text=${shareTextE}&url=${shareUrlE}`;
  const watsappShareUrl = `whatsapp://send?text=${shareUrlE}${shareUrlE}`;
  const redditShareUrl = `https://www.reddit.com/submit?url=${shareUrlE}&title=${shareTextE}`;
  const linkedIdShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${shareUrlE}`;
  const facebookShareUrl = `https://www.facebook.com/dialog/share?app_id=${Config.NEXT_PUBLIC_FB_APP_ID}&display=popup&href=${shareUrlE}&quote=${shareTextE}`;

  useEffect(() => {
    if (sharePopupInfo && typeof window !== 'undefined') {
      const left = window.innerWidth / 2;
      const top = window.innerHeight / 2;
      setSharePopupCenter({ top, left });
    } else {
      setSharePopupCenter(undefined);
    }
  }, [sharePopupInfo]);

  const onEmailChange = useCallback(
    (e: ShortEvent) => {
      setEmail(e.target.value);
      setEmailError('');
      setEmailSuccess('');
    },
    [setEmail]
  );

  const handlePopoverOpen = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
    showSharePopup(undefined);
  }, [setAnchorEl]);

  const onFacebookShareClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      if (typeof window !== 'undefined') {
        const left = window.innerWidth / 2 - 350;
        const top = window.innerHeight / 2 - 350;
        window.open(
          facebookShareUrl,
          '_blank',
          `width=700,height=700,left=${left},top=${top}`
        );
      }

      if (user) {
        dataProvider
          .trackSharing(resourceId, resourceType, rewardType.SHARE_FB)
          .catch((err: Error) => {
            console.error(err);
          });
      }
    },
    [[user, dataProvider]]
  );

  const onLinkedinShareClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      if (typeof window !== 'undefined') {
        const left = window.innerWidth / 2 - 350;
        const top = window.innerHeight / 2 - 350;
        window.open(
          linkedIdShareUrl,
          '_blank',
          `width=700,height=700,left=${left},top=${top}`
        );
      }

      if (user) {
        dataProvider
          .trackSharing(resourceId, resourceType, rewardType.SHARE_LN)
          .catch((err: Error) => {
            console.error(err);
          });
      }
    },
    [[user, dataProvider]]
  );

  const onTwitterShareClick = useCallback(
    (e: React.MouseEvent) => {
      if (user) {
        dataProvider
          .trackSharing(resourceId, resourceType, rewardType.SHARE_TW)
          .catch((err: Error) => {
            console.error(err);
          });
      }
    },
    [user, dataProvider]
  );

  const onWatsappShareClick = useCallback(
    (e: React.MouseEvent) => {
      if (user) {
        dataProvider
          .trackSharing(resourceId, resourceType, rewardType.SHARE_WA)
          .catch((err: Error) => {
            console.error(err);
          });
      }
    },
    [user, dataProvider]
  );

  const onRedditShareClick = useCallback(
    (e: React.MouseEvent) => {
      if (user) {
        dataProvider
          .trackSharing(resourceId, resourceType, rewardType.SHARE_RB)
          .catch((err: Error) => {
            console.error(err);
          });
      }
    },
    [user, dataProvider]
  );

  const onInviteSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (emailLoading || !email) {
      return;
    }
    setEmailError('');
    setEmailSuccess('');
    setEmailLoading(true);
    dataProvider
      .inviteUser<Profile>(email, resourceType, resourceId)
      .then((data) => {
        if (data && data.invited) {
          setEmailSuccess(`${email} invited.`);
          setEmail('');
        } else {
          setEmailError(`${email} already exist.`);
        }
      })
      .catch((err) => {
        setEmailError(err.message);
      })
      .finally(() => {
        setEmailLoading(false);
      });
  };

  let rootClassName = classes.button;
  if (className) {
    rootClassName += ' ' + className;
  }
  if (open) {
    rootClassName += ' open';
  }

  let openState = open;
  let anchorReference: PopoverReference = 'anchorEl';
  let anchorElement: HTMLElement | null = anchorEl;
  let anchorOrigin: PopoverOrigin = {
    vertical: 'bottom',
    horizontal: 'right'
  };
  let transformOrigin: PopoverOrigin = {
    vertical: 'top',
    horizontal: 'right'
  };
  if (sharePopupInfo) {
    openState = true;
    anchorReference = 'anchorPosition';
    anchorElement = null;
    anchorOrigin = {
      vertical: 'center',
      horizontal: 'center'
    };
    transformOrigin = {
      vertical: 'center',
      horizontal: 'center'
    };
  }

  return (
    <>
      <span className={rootClassName} onClick={handlePopoverOpen}>
        <ShareIcon />
        <PsLogo
          className={classes.buttonLogo}
          color={psTheme.palette.primary.main}
          size={23}
          small
        />
        <Typography className={classes.coins} component="span">
          +1
        </Typography>
      </span>
      <Popover
        id="share-info"
        className={classes.popover}
        open={openState}
        anchorReference={anchorReference}
        anchorEl={anchorEl}
        anchorPosition={sharePopupCenter}
        onClose={handlePopoverClose}
        classes={{ paper: classes.paper }}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <div className={classes.title}>
          Invite someone to solve <b>{resourceName}</b> problems
        </div>
        <div className={classes.row}>
          <span>
            <span>
              Share to <b>earn IdeaPoints</b>:
            </span>
            <PsLogo
              className="coin"
              size={23}
              color={psTheme.palette.primary.main}
              small
            />
          </span>
        </div>

        <div className={classes.social}>
          <a href="#" onClick={onFacebookShareClick}>
            <img
              src="/icons/share-facebook.svg"
              alt="facebook"
              width="36"
              height="36"
            />
          </a>
          <a href="#" onClick={onLinkedinShareClick}>
            <img
              src="/icons/share-linkedin.svg"
              alt="linkedin"
              width="36"
              height="36"
            />
          </a>
          <a
            href={twitterShareUrl}
            onClick={onTwitterShareClick}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/icons/share-twitter.svg"
              alt="twitter"
              width="36"
              height="36"
            />
          </a>
          <a
            href={watsappShareUrl}
            onClick={onWatsappShareClick}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/icons/share-watsup.svg"
              alt="watsup"
              width="36"
              height="36"
            />
          </a>
          <a
            href={redditShareUrl}
            onClick={onRedditShareClick}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/icons/share-reddit.svg"
              alt="reddit"
              width="36"
              height="36"
            />
          </a>
        </div>

        <form action="#" onSubmit={onInviteSubmit}>
          <PsFormControl
            className={classes.input}
            name="email"
            label="Add someone's e-mail address below:"
            error={!!emailError}
            helperText={emailError || emailSuccess}
            labelLight
          >
            <PsInput value={email} onChange={onEmailChange} />
          </PsFormControl>

          <div className={classes.row}>
            <span>
              <span>
                Invite to <b>earn IdeaCoins</b>:
              </span>
              <PsLogo
                className="coin"
                size={23}
                color={psTheme.palette.primary.main}
                small
              />
            </span>
            <br />
            <PsButton
              className={classes.submit}
              color="secondary"
              type="submit"
              disabled={emailLoading}
            >
              Send E-mail Invite
              <PsLogo size={23} small />
            </PsButton>
          </div>
        </form>
      </Popover>
    </>
  );
};

export const ShareBtn = styled(ShareBtnView)({});

export default ShareBtn;
