export const tools = {
  PEN: 'pen',
  COLOR: 'color',
  RAZER: 'razer',
  BACK: 'back',
  CLEAR: 'clear'
};

export const colors = [
  '#ff9693',
  '#ffb06e',
  '#ffea97',
  '#dca0e2',
  '#ffa9d3',
  '#bde8ae',
  '#97dcff',
  '#f3f3f3',
  '#ff5f48',
  '#ff8126',
  '#ffdb34',
  '#ae61b6',
  '#ed5ea5',
  '#89cc6d',
  '#57bff0',
  '#a9a9a9',
  '#5a5a5a'
];

export const sizes = [49, 25, 7];
