import React, { createContext, useState, useCallback } from 'react';
import { Solution } from 'components/CardSolution';
import { ShortEvent } from 'components/common/PsFormControl';

export type ModalDataValuesType = {
  title: string;
  titleProblem: string;
  titleProblemLabel: React.ReactNode;
  titleSolution: string;
  titleApplication: string;
  titleContest: string;
  titleChallenge: string;
  parentProblem?: string;
  parentProblemLabel?: string;
  parentProblemOnly?: boolean;
  parentProblemHide?: boolean;
  parentSolution?: string;
  parentSolutionLabel?: string;
  parentSolutionOnly?: boolean;
  parentSolutionHide?: boolean;
  mainProblem?: string;
  mainProblemLabel?: string;
  rootProblem?: string;
  rootProblemLabel?: string;
  rootProblemHide?: boolean;
  selectApplication?: string;
  selectApplicationLabel?: string;
  selectApplicationType?: string;
  company?: string;
  companyLabel?: string;
  companyRaw?: string;
  product?: string;
  productLabel?: string;
  productRaw?: string;
  contestCoins?: number;
  contestProblems?: string[];
  contestTag?: string;
  contestWins?: string;
  contestStart?: string;
  contestEnd?: string;
  challengeHeader?: string;
  challengeTags: string[];
  challengeContests: string[];
  challengeOwner?: string;
  challengeInternalReward?: number;
  challengeExternalReward?: string;
  challengeStart?: string;
  challengeEnd?: string;
  tags: string[];
  tagsProblem: string[];
  tagsSolution: string[];
  tagsApplication: string[];
  selected: string[];
  description: string;
  descriptionProblem: string;
  descriptionSolution: string;
  descriptionApplication: string;
  descriptionContest: string;
  files: Array<{
    contentType: string;
    title: string;
    url: string;
  }>;
  filesProblem: Array<{
    contentType: string;
    title: string;
    url: string;
  }>;
  filesSolution: Array<{
    contentType: string;
    title: string;
    url: string;
  }>;
  filesContest: Array<{
    contentType: string;
    title: string;
    url: string;
  }>;
  filesChallenge: Array<{
    contentType: string;
    title: string;
    url: string;
  }>;
  files2Challenge: Array<{
    contentType: string;
    title: string;
    url: string;
  }>;
  files3Challenge: Array<{
    contentType: string;
    title: string;
    url: string;
  }>;
  application?: any;
  pagination?: { page: number; perPage: number };
  isCommunity?: boolean;
  problems?: any;
  solutions?: any;
  applications?: any;
  productId?: string | number;
  finalizeType?: string;
  termsAgree?: boolean;
  payOptions?: Array<any>;
  payProducts?: Array<any>;
  modalTitle?: React.ReactNode;
  brainMethod?: string;
  brainObject?: string;
  activeSolution?: Solution;
  introProblem?: React.ReactNode;
  introProblemTags?: React.ReactNode;
  introSolution?: React.ReactNode;
  imageBase64: string;
  tempNumber?: undefined;
};

type ErrorsType =
  | 'title'
  | 'titleProblem'
  | 'titleSolution'
  | 'titleContest'
  | 'titleApplication'
  | 'titleChallenge'
  | 'description'
  | 'descriptionContest'
  | 'tags'
  | 'tagsProblem'
  | 'parentProblem'
  | 'parentSolution'
  | 'contestStart'
  | 'contestProblems'
  | 'challengeTags'
  | 'challengeContests'
  | 'challengeStart'
  | 'productId'
  | 'termsAgree'
  | 'brainMethod'
  | 'brainObject'
  | 'tempNumber';

export type ModalDataErrorsType = {
  [key in ErrorsType]?: string;
};

export type ValidateFormType = {
  success: boolean;
  errors?: ModalDataErrorsType;
};

export const AddProblemSteps = {
  ProblemName: 'ProblemName',
  ProblemTags: 'ProblemTags',
  FinilizeProblem: 'FinilizeProblem'
};

export const AddSolutionSteps = {
  SolutionName: 'SolutionName',
  SolutionTags: 'SolutionTags',
  FinilizeSolution: 'FinilizeSolution'
};

export const AddApplicationSteps = {
  ApplicationName: 'ApplicationName',
  ApplicationTags: 'ApplicationTags',
  FinilizeApplication: 'FinilizeApplication'
};

export const AddFullFlowSteps = {
  ProblemName: 'ProblemName',
  ProblemTags: 'ProblemTags',
  FinilizeProblem: 'FinilizeProblem',
  SolutionName: 'SolutionName',
  SolutionTags: 'SolutionTags',
  FinilizeSolution: 'FinilizeSolution',
  SelectType: 'SelectType',
  ApplicationName: 'ApplicationName',
  ApplicationTags: 'ApplicationTags',
  FinilizeApplication: 'FinilizeApplication'
};

export const AddContestSteps = {
  ContestName: 'ContestName',
  ContestDescription: 'ContestDescription',
  ContestTypes: 'ContestTypes',
  ContestBounty: 'ContestBounty',
  ContestProblems: 'ContestProblems'
};

export const AddChallengeSteps = {
  ChallengeName: 'ChallengeName',
  ChallengeDate: 'ChallengeDate',
  ChallengeInternalReward: 'ChallengeInternalReward',
  ChallengeExternalReward: 'ChallengeExternalReward'
};

export const AddSolutionToInventionSteps = {
  SolutionSelect: 'SolutionSelect'
};

export const AttachInventionToSolutionSteps = {
  ApplicationSelect: 'ApplicationSelect'
};

export const BuildPatentApplicationSteps = {
  CreditsStep: 'CreditsStep',
  AddressStep: 'AddressStep',
  ChooseTypeStep: 'ChooseTypeStep',
  ChoosePaymentStep: 'ChoosePaymentStep',
  SignDocumentsStep: 'SignDocumentsStep',
  PayDocumentsStep: 'PayDocumentsStep'
};

export const ImproveApplicationSteps = {
  ChooseTypeStep: 'ChooseTypeStep',
  ChooseSubTypeStep: 'ChooseSubTypeStep'
};

export const BrainstormSolutionSteps = {
  ChooseTypeStep: 'ChooseTypeStep',
  TypeDescriptionStep: 'TypeDescriptionStep'
};

export const CompanySubscriptionSteps = {
  ChooseCompanyStep: 'ChooseCompanyStep',
  CreateCompanyStep: 'CreateCompanyStep',
  SelectPlanStep: 'SelectPlanStep'
};

export const CompanySubscriptionFinalizeSteps = {
  ConfirmInformationStep: 'ConfirmInformationStep',
  InviteUsersStep: 'InviteUsersStep'
};

export const RegisterPopupSteps = {
  NameAndEmailStep: 'NameAndEmailStep',
  TagsStep: 'TagsStep'
};

export const DownloadPatentSteps = {
  AcceptConditionsStep: 'AcceptConditionsStep',
  DownloadStep: 'DownloadStep'
};

export type StepComponentProps = {
  onNotify?: (args: any) => void;
};

export type StepComponentType<T extends StepComponentProps> =
  | React.FC<T>
  | React.Component<T>;

export type FormStepConfig = {
  Component: StepComponentType<StepComponentProps>;
  nextStep?: ((data: ModalDataValuesType) => string) | string;
  isNextStepBlocked?: ((data: ModalDataValuesType) => boolean) | boolean;
  validate?: (data: ModalDataValuesType) => ValidateFormType;
  onAsyncSubmit?: (
    data: ModalDataValuesType
  ) => Promise<ModalDataValuesType | any>;
  name?: string;
  isFinalStep?: boolean;
  modalTitle?: string;
  nextButtonTitle?: string;
  backButtonTitle?: string;
};

export type ModalDataContextType = {
  type: string;
  setType: (type: string) => void;
  values: ModalDataValuesType;
  errors: ModalDataErrorsType;
  setErrors: (error: ModalDataErrorsType) => void;
  updateField: (name: keyof ModalDataValuesType, value: any) => void;
  resetFields: () => void;
  handleInputChange: (e: ShortEvent) => void;
  setActionCallback: (callback: () => void) => void; // Add method to set callback
  triggerActionCallback: () => void; // Add method to trigger callback
  isRedirectionEnabled: boolean;
};

const defaultContext = {
  type: '',
  isRedirectionEnabled: true,
  setType: (type: string) => '',
  values: {
    title: '',
    titleProblem: '',
    titleProblemLabel: undefined,
    titleSolution: '',
    titleApplication: '',
    titleContest: '',
    titleChallenge: '',
    tags: [],
    tagsProblem: [],
    tagsSolution: [],
    tagsApplication: [],
    selected: [],
    description: '',
    descriptionProblem: '',
    descriptionSolution: '',
    descriptionApplication: '',
    descriptionContest: '',
    challengeTags: [],
    challengeContests: [],
    files: [],
    filesProblem: [],
    filesSolution: [],
    filesContest: [],
    filesChallenge: [],
    files2Challenge: [],
    files3Challenge: [],
    imageBase64: ''
  },
  errors: {},
  setErrors: (error: ModalDataErrorsType) => ({}),
  updateField: () => {
    console.log('updateField');
  },
  resetFields: () => {
    console.log('resetFields');
  },
  handleInputChange: () => {
    console.log('handleInputChange');
  },
  setActionCallback: () => {
    console.log('Action Call Back');
  },
  triggerActionCallback: () => {
    console.log('trigger call back');
  }
};

export const ModalDataContext =
  createContext<ModalDataContextType>(defaultContext);

type Props = {
  children: React.ReactElement;
};

export const ModalDataContextProvider = ({ children }: Props) => {
  const [type, setType] = useState<string>('');
  const [errors, setErrors] = useState<ModalDataErrorsType>({});
  const [data, setData] = useState<ModalDataValuesType>({
    title: '',
    titleProblem: '',
    titleSolution: '',
    titleApplication: '',
    titleContest: '',
    titleChallenge: '',
    tags: [],
    tagsProblem: [],
    tagsSolution: [],
    tagsApplication: [],
    selected: [],
    description: '',
    descriptionProblem: '',
    descriptionSolution: '',
    descriptionApplication: '',
    descriptionContest: '',
    challengeTags: [],
    challengeContests: [],
    files: [],
    filesProblem: [],
    filesSolution: [],
    filesContest: [],
    filesChallenge: [],
    files2Challenge: [],
    files3Challenge: [],
    titleProblemLabel: undefined,
    imageBase64: ''
  });

  // const [currentStep, setCurrentStep] = useState<AddProblemSteps>(
  //   AddProblemSteps.ProblemName,
  // );

  const [actionCallback, setActionCallback] = useState<(() => void) | null>(
    null
  ); // Add callback state

  const resetActionCallback = () => {
    setActionCallback(null);
  };

  const updateField = useCallback(
    (
      name: keyof ModalDataValuesType,
      value: string | boolean | string[]
    ): void => {
      setErrors((errors) => ({ ...errors, [name]: null }));
      setData((data) => ({
        ...data,
        [name]: value
      }));
    },
    []
  );

  const resetFields = useCallback(() => {
    setErrors({});
    setData({
      title: '',
      titleProblem: '',
      titleProblemLabel: undefined,
      titleSolution: '',
      titleApplication: '',
      titleContest: '',
      titleChallenge: '',
      tags: [],
      tagsProblem: [],
      tagsSolution: [],
      tagsApplication: [],
      selected: [],
      description: '',
      descriptionProblem: '',
      descriptionSolution: '',
      descriptionApplication: '',
      descriptionContest: '',
      files: [],
      filesProblem: [],
      filesSolution: [],
      filesContest: [],
      filesChallenge: [],
      files2Challenge: [],
      files3Challenge: [],
      parentProblem: undefined,
      parentProblemLabel: undefined,
      parentProblemOnly: false,
      parentProblemHide: false,
      parentSolution: undefined,
      parentSolutionLabel: undefined,
      parentSolutionOnly: false,
      parentSolutionHide: false,
      mainProblem: undefined,
      mainProblemLabel: undefined,
      rootProblem: undefined,
      rootProblemLabel: undefined,
      rootProblemHide: undefined,
      selectApplication: undefined,
      selectApplicationLabel: undefined,
      company: undefined,
      companyLabel: undefined,
      companyRaw: undefined,
      challengeTags: [],
      challengeContests: [],
      product: undefined,
      productLabel: undefined,
      productRaw: undefined,
      productId: undefined,
      payOptions: undefined,
      payProducts: undefined,
      finalizeType: undefined,
      termsAgree: undefined,
      application: undefined,
      problems: undefined,
      solutions: undefined,
      applications: undefined,
      modalTitle: undefined,
      brainMethod: undefined,
      brainObject: undefined,
      activeSolution: undefined,
      introProblem: undefined,
      introProblemTags: undefined,
      imageBase64: '',
      tempNumber: undefined
    });
  }, []);

  const handleInputChange = useCallback(
    (e: ShortEvent) => {
      const { name = '', value, label, rawValue } = e.target;

      updateField(name as keyof ModalDataValuesType, value);
      if (label) {
        const labelName = `${name}Label`;
        updateField(labelName as keyof ModalDataValuesType, label);
      }
      if (rawValue) {
        const labelName = `${name}Raw`;
        updateField(labelName as keyof ModalDataValuesType, label);
      }
    },
    [updateField]
  );

  const triggerActionCallback = useCallback(() => {
    if (actionCallback) {
      actionCallback();
      resetActionCallback();
    }
  }, [actionCallback]);

  return (
    <ModalDataContext.Provider
      value={{
        type,
        setType,
        updateField,
        resetFields,
        handleInputChange,
        errors,
        setErrors,
        values: data,
        setActionCallback: (callback: () => void) =>
          setActionCallback(() => callback),
        triggerActionCallback,
        isRedirectionEnabled: null
      }}
    >
      {children}
    </ModalDataContext.Provider>
  );
};

export const ModalDataContextConsumer = ModalDataContext.Consumer;

export default ModalDataContext;
