import React, { useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import {
  Card,
  CardImage,
  CardBody,
  CardTitle,
  CardOwner,
  CardText,
  CardActions
} from 'components/card';
import { PsButton } from 'components/common/PsButton';
import { PsLogo } from 'components/common/PsLogo';
import { OwnerInfo } from 'components/CardProfile';
import { TagInfo } from 'components/CardTag';
import { Tags } from 'components/Tag';
import { ShareBtn } from 'components/ShareBtn';
import { PsRecord } from 'contexts/DataContext';
import { AuthContext } from 'contexts/AuthContext';
import { Picture, placeholderType } from '../../helpers';
import { PsTheme } from '../../theme';
import useRouter from 'hooks/useRouter';
import { makeStyles } from 'tss-react/mui';

export type ContestWinner = {
  id: string | number;
  key: string;
  place: number;
  amount: number;
  owner: string | number;
};

export type ContestTop = {
  id: string | number;
  key: string;
  total: number;
  totalPer: number;
  votesSum: number;
  rewardsSum: number;
  title?: string;
  files?: Array<Picture>;
  owner: string | number;
  ownerData: {
    key: string;
    email: string;
    firstName?: string;
    lastName?: string;
    username?: string;
  };
};

export type ContestTopUser = {
  id: string | number;
  key: string;
  firstName?: string;
  lastName?: string;
  username?: string;
  email: string;
  files?: Array<Picture>;
  total: number;
  totalPer: number;
  votesSum: number;
  rewardsSum: number;
};

export type ContestTops = {
  applications: Array<ContestTop>;
  solutions: Array<ContestTop>;
};

export type Contest = PsRecord & {
  key: string;
  title?: string;
  body?: string;
  owner?: string | number;
  ownerInfo?: OwnerInfo;
  tag: string | number;
  tagInfo: TagInfo;
  problems?: Array<string | number>;
  type: string;
  wins: string;
  bountyCoins: number;
  bountyNft: number;
  bountyReward: number;
  startDate: string;
  endDate: string;
  files?: Array<Picture>;
  createdAt?: string;
  likes: number;
  dislikes: number;
  applications?: Array<ContestWinner>;
  solutions?: Array<ContestWinner>;
};

type ClassKey = 'root' | 'tags' | 'tagsButton' | 'solveBtn';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {},
    tags: {
      '& > div': {
        marginTop: 4,
        marginBottom: 4
      }
    },
    tagsButton: {
      marginTop: 10
    },
    solveBtn: {
      padding: '10px 15px',
      marginRight: 10,
      borderRadius: 10,
      lineHeight: '26px',
      minHeight: 40,
      '& svg': {
        marginLeft: 15
      }
    }
  };
});

export type CardContestProps = {
  contest: Contest;
  isDetailed?: boolean;
};

const CardContestView = ({ contest, isDetailed = false }: CardContestProps) => {
  const {
    id,
    key,
    title,
    body,
    owner,
    ownerInfo,
    createdAt,
    files,
    tag,
    bountyCoins,
    problems = [],
    likes,
    dislikes
  } = contest;
  const { user } = useContext(AuthContext);
  const router = useRouter();
  const { classes } = useStyles();
  const image = files && files.length ? files[0] : undefined;
  const url = `/contests/${key || id}`;

  const onSolveBtnClick = (e: React.MouseEvent) => {
    e.preventDefault();
    router.push(url);
  };

  return (
    <Card>
      <CardImage href={url} image={image} type={placeholderType.P} />
      <CardBody>
        <CardTitle title={title} href={isDetailed ? undefined : url}>
          <Tags className={classes.tags} tags={[tag]} />
        </CardTitle>
        <CardOwner
          ownerName={ownerInfo && ownerInfo.username}
          ownerKey={ownerInfo && ownerInfo.key}
          owner={owner}
          createdAt={createdAt}
        />
        <CardText>{body}</CardText>
        <CardActions
          resource="contests"
          resourceId={id}
          likes={likes}
          dislikes={dislikes}
          user={user}
        >
          <PsButton
            className={classes.solveBtn}
            color="secondary"
            onClick={onSolveBtnClick}
          >
            Earn {bountyCoins} IdeaCoin Jackpot
            <PsLogo size={25} color="white" small />
          </PsButton>
          <ShareBtn contest={contest} />
        </CardActions>
      </CardBody>
    </Card>
  );
};

export const CardContest = styled(CardContestView)({});

export default CardContest;
