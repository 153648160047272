import { ArrowForwardIosOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  TextField,
  Typography,
  styled
} from '@mui/material';
import { colorPalette } from 'theme';
import { Breakpoints } from 'utilities/constants';

export const LeftSection = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '50%',
  height: '100%',
  [theme.breakpoints.down(Breakpoints.SMALL)]: {
    width: '100%',
    padding: '1rem'
  }
}));
export const TitleTypography = styled(Typography)({
  fontSize: 24,
  fontWeight: 700,
  marginBottom: '0.5rem',
  textAlign: 'left'
});

export const ResetStatusText = styled(Typography)<{ error?: boolean }>(
  ({ error }) => ({
    color: error ? colorPalette.softRed : colorPalette.greenHaze,
    fontSize: 11,
    fontWeight: 400
  })
);

export const ButtonTypography = styled(Typography)({
  font: 'Poppins',
  fontSize: 12,
  fontWeight: 700,
  textTransform: 'none'
});

export const InfoTypography = styled(Typography)({
  color: colorPalette.dimBlack,
  fontSize: 10,
  fontWeight: 400,
  marginTop: '1rem',
  textAlign: 'center'
});

export const ForgetPassText = styled(Typography)({
  color: colorPalette.dimBlack,
  cursor: 'pointer',
  fontSize: 12,
  fontWeight: 400,
  textAlign: 'right',
  width: '100%'
});

export const RightSection = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  display: 'flex',
  justifyContent: 'center',
  height: '100%',
  width: '30%',
  [theme.breakpoints.down(Breakpoints.SMALL)]: {
    width: '100%',
    padding: '1rem'
  }
}));

export const MainBox = styled(Box)<{ isSignUp?: boolean }>(
  ({ isSignUp = false, theme }) => ({
    backgroundColor: colorPalette.white,
    borderRadius: '2.5rem',
    boxShadow: '0rem 0.25rem 0.9375rem rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    padding: isSignUp ? '1.5rem' : '2rem',
    width: '31rem',
    [theme.breakpoints.down(Breakpoints.SMALL)]: {
      width: '100%',
      padding: '1rem',
      borderRadius: '1rem'
    }
  })
);

export const StyledParentContainer = styled(Box)<{ isMobile: boolean }>(
  ({ isMobile }) => ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    height: '100%',
    maxHeight: '90vh',
    overflowY: 'auto',
    justifyContent: 'space-between',
    padding: isMobile ? '0.5rem' : '2rem'
  })
);

export const StyledLogo = styled(Box)(() => ({
  marginBottom: '1rem',
  textAlign: 'center',
  '& img': {
    width: '100%',
    height: 'auto'
  }
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: '1rem',
  width: '100%',
  '& input': {
    padding: '0.75rem'
  },
  [theme.breakpoints.down(Breakpoints.SMALL)]: {
    marginBottom: '1rem'
  }
}));

export const ActionButton = styled(Button)<{ isSignUp?: boolean }>(
  ({ isSignUp = false, theme }) => ({
    background: `linear-gradient(90deg, ${colorPalette.pastelPurple} 0%, ${colorPalette.brightPurple} 100%)`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '1rem',
    color: colorPalette.white,
    height: isSignUp ? '3rem' : '3.25rem',
    margin: isSignUp ? '0.2rem auto 0 auto' : '1rem auto 0 auto',
    padding: '0.5rem',
    width: isSignUp ? '10rem' : '11rem',
    transition:
      'transform 0.3s ease, background 0.3s ease, box-shadow 0.3s ease',
    [theme.breakpoints.down(Breakpoints.SMALL)]: {
      width: '80%',
      height: '2.5rem'
    },
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)'
    },
    '&:disabled': {
      background: colorPalette.grey,
      color: colorPalette.lightGrey,
      cursor: 'not-allowed',
      boxShadow: 'none',
      transform: 'none'
    }
  })
);

export const SocialIcons = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  marginTop: '0.5rem',
  width: '100%',
  [theme.breakpoints.down(Breakpoints.SMALL)]: {
    marginTop: '1rem',
    gap: '0.5rem'
  }
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: 'white',
  border: `0.0625rem solid ${colorPalette.nobel}`,
  borderRadius: '0.375rem',
  height: '2.375rem',
  margin: '0.25rem',
  padding: '0.25rem',
  width: '2.375rem',
  [theme.breakpoints.up(Breakpoints.SMALL)]: {
    height: '2.375rem',
    width: '2.375rem',
    margin: '0.375rem'
  }
}));

export const LinkWrapper = styled(Box)({
  color: colorPalette.dimBlack,
  cursor: 'pointer',
  display: 'inline-block',
  fontSize: 10,
  fontWeight: 700,
  verticalAlign: 'baseline'
});

export const CloseIconWrapper = styled(Box)({
  position: 'absolute',
  right: 10,
  top: 10
});

export const SocialIconsContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  marginTop: '0.2rem',
  width: '100%',
  [theme.breakpoints.down(Breakpoints.SMALL)]: {
    marginTop: '1rem',
    gap: '0.5rem'
  }
}));

export const StyledResetTypography = styled(Typography)({
  font: 'Poppins',
  fontSize: '.8125rem',
  fontWeight: 700,
  textTransform: 'none'
});

export const StyledResetCheckTypography = styled(Typography)({
  fontSize: 12,
  fontWeight: 400,
  marginBottom: '1rem',
  textAlign: 'left'
});

export const StyledCircularProgress = styled(CircularProgress)({
  color: colorPalette.white
});

export const ArrowForwardIcon = styled(ArrowForwardIosOutlined)({
  fontSize: '.875rem',
  marginLeft: '.125rem'
});
