import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useDispatch } from 'react-redux';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Actions from 'redux-state/actions';
import { CustomTextField } from 'components/common/inputs/CustomTextField';
import { validateEmail } from 'helpers/validateEmail';
import {
  GetErrorMessage,
  GetResetPasswordLoader,
  GetProfileStatusCode,
  GetResetPasswordStatus
} from 'redux-state/selectors';
import { colorPalette } from 'theme';
import {
  Constants,
  ERRORS,
  FORM_VIEW,
  INPUT_TYPES,
  VARIANT
} from 'utilities/constants';
import {
  ActionButton,
  InfoTypography,
  LinkWrapper,
  MainBox,
  ResetStatusText,
  StyledCircularProgress,
  StyledResetTypography,
  TitleTypography
} from './styledComponents';

interface ResetPasswordViewProps {
  setFormView: Dispatch<SetStateAction<string>>;
}

type FieldValidityType = {
  email: boolean;
};

export const ResetPasswordView: React.FC<ResetPasswordViewProps> = ({
  setFormView
}) => {
  const dispatch = useDispatch();

  const statusCode = GetProfileStatusCode();
  const errorMessage = GetErrorMessage();
  const forgetPasswordLoader = GetResetPasswordLoader();
  const resetPasswordStatus = GetResetPasswordStatus();

  const [email, setEmail] = useState('');
  const [fieldsValidity, setFieldsValidity] = useState<FieldValidityType>({
    email: true
  });

  const onEmailChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setEmail(value);
    },
    [setEmail]
  );

  const getHelperText = useCallback(() => {
    if (!fieldsValidity.email) {
      dispatch(Actions.updateErrorStatus(null, ''));
      return ERRORS.INVALID_EMAIL;
    } else if (statusCode == 401) {
      return ERRORS.USER_NOT_FOUND;
    } else if (statusCode != 200 && errorMessage) {
      return ERRORS.UNEXPECTED_ERROR;
    }
    return '';
  }, [dispatch, errorMessage, fieldsValidity, statusCode]);

  const { emailHelperText } = useMemo(() => {
    const emailHelperText = getHelperText();
    return { emailHelperText };
  }, [getHelperText]);

  const onLoginClick = useCallback(() => {
    setFormView(FORM_VIEW.LOGIN);
  }, [setFormView]);

  //TODO: Implement Validation Functionality
  const validateInputs = useCallback(() => {
    dispatch(Actions.updateProfileErrorStatus(null, ''));
    const emailIsValid = validateEmail(email);

    setFieldsValidity({
      email: emailIsValid
    });
    return emailIsValid;
  }, [dispatch, email]);

  const onSendResetLink = useCallback(() => {
    if (validateInputs()) {
      dispatch(Actions.forgetPassword({ email }));
    }
  }, [dispatch, email, validateInputs]);

  return (
    <MainBox>
      <TitleTypography gutterBottom>
        {Constants.RESET_YOUR_PASSWORD}
      </TitleTypography>
      <CustomTextField
        Icon={PersonOutlineOutlinedIcon}
        placeholder={Constants.USERNAME_EMAIL}
        type={INPUT_TYPES.EMAIL}
        fullWidth
        fillColor={colorPalette.lightLavender}
        borderColorOnHover={colorPalette.purple}
        error={!fieldsValidity.email || !!errorMessage}
        helperText={emailHelperText}
        value={email}
        onChange={onEmailChange}
      />
      <ResetStatusText>{resetPasswordStatus} </ResetStatusText>
      <ActionButton
        onClick={onSendResetLink}
        variant={VARIANT.CONTAINED}
        disabled={!!resetPasswordStatus}
      >
        {forgetPasswordLoader ? (
          <StyledCircularProgress size={23} />
        ) : (
          <StyledResetTypography>
            {Constants.SEND_RESET_LINK}
          </StyledResetTypography>
        )}
      </ActionButton>
      <InfoTypography variant={VARIANT.BODY2}>
        {Constants.BACK_TO_LOGIN}{' '}
        <LinkWrapper onClick={onLoginClick}>{Constants.LOGIN}</LinkWrapper>
      </InfoTypography>
    </MainBox>
  );
};
