import React from 'react';
import { styled, Theme, useTheme } from '@mui/material/styles';

import { PsTheme } from '../../theme';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root' | 'tag' | 'rest';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      marginBottom: 9
    },
    tag: {
      fontSize: 20,
      fontWeight: 600,
      marginRight: 10
    },
    rest: {
      maxWidth: '100%',
      '& > h2': {
        fontWeight: 'bold',
        fontSize: 24,
        margin: '0 0 7px 0',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    }
  };
});

type CardTagTitleProps = {
  title?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
};

const CardTagTitleView = ({
  className,
  title,
  children
}: CardTagTitleProps) => {
  const { classes } = useStyles();

  let rootClassName = classes.root;
  if (className) {
    rootClassName += ' ' + className;
  }

  return (
    <div className={rootClassName}>
      {children ? <div className={classes.tag}>{children}</div> : null}
      <div className={classes.rest}>
        <h2>{title}</h2>
      </div>
    </div>
  );
};

export const CardTagTitle = styled(CardTagTitleView)({});

export default CardTagTitle;
