import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const DottedLine = (props: SvgIconProps) => {
  return (
    <SvgIcon
      sx={{
        width: '100%',
        maxWidth: '1350px',
        height: 3
      }}
      viewBox="0 0 1000 3"
      {...props}
    >
      <path
        d="M1.99568 1.5H691.004"
        stroke="#D5D7DD"
        strokeWidth="10"
        strokeLinecap="square"
        strokeDasharray="0 13"
      />
    </SvgIcon>
  );
};
