import React, { useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { Tags } from 'components/Tag';
import { Application } from 'components/CardApplication';
import {
  Card,
  CardBody,
  CardImage,
  CardOwner,
  CardRow,
  CardCell,
  CardText,
  CardTitle,
  CardVote,
  CardActions
} from 'components/card-small';
import { TagRemove } from 'components/Tag';
import { ShareBtn } from 'components/ShareBtn';
import { AuthContext } from 'contexts/AuthContext';
import { CoinsSmall } from 'components/Coins';
import { VisibilityStatus } from './VisibilityStatus';
import { finalizeType as finalizeTypes, placeholderType } from '../../helpers';
import { PsTheme } from '../../theme';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root' | 'tags';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {},
    tags: {
      '& > div': {
        marginTop: 4,
        marginBottom: 4
      }
    }
  };
});

export type CardApplicationSmallProps = {
  application: Application;
  vote?: boolean;
  onTagRemove?: () => void;
  filterTagName?: string;
};

const CardApplicationSmallView = ({
  application,
  onTagRemove,
  filterTagName,
  vote = true
}: CardApplicationSmallProps) => {
  const {
    id,
    key,
    title,
    teaser,
    body,
    owner,
    ownerInfo,
    tags = [],
    tagsInfo = [],
    createdAt,
    files,
    likes,
    dislikes,
    reward,
    finalizeType,
    isFiled,
    isPublic = false
  } = application;
  const { user } = useContext(AuthContext);
  const { classes } = useStyles();
  const isOpenSource = finalizeType === finalizeTypes.OPEN;
  const image = files && files.length ? files[0] : undefined;
  const url = `/inventions/${key || id}`;

  return (
    <Card>
      <CardImage image={image} href={url} type={placeholderType.A} />
      {onTagRemove ? (
        <TagRemove
          type="applications"
          itemId={id}
          tagKey={filterTagName}
          onRemove={onTagRemove}
        />
      ) : null}
      <CardBody>
        <CardTitle
          title={teaser || title}
          href={url}
          beforeTitle={<VisibilityStatus application={application} />}
        >
          <Tags className={classes.tags} tagsList={tagsInfo} />
        </CardTitle>
        <CardOwner
          owner={owner}
          ownerKey={ownerInfo && ownerInfo.key}
          ownerName={ownerInfo && ownerInfo.username}
          createdAt={createdAt}
        />
        <CardText>{body}</CardText>
        <CardRow>
          <CoinsSmall count={reward} />
          <CardCell>
            <CardActions>
              <ShareBtn application={application} tagsList={tagsInfo} />
            </CardActions>
          </CardCell>
        </CardRow>
      </CardBody>
      {vote ? (
        <CardVote
          resource="applications"
          itemId={id}
          likes={likes}
          dislikes={dislikes}
          user={user}
        />
      ) : (
        false
      )}
    </Card>
  );
};

export const CardApplicationSmall = styled(CardApplicationSmallView)({});

export default CardApplicationSmall;
