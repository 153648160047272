import React from 'react';
import { Box, IconButton, styled } from '@mui/material';
import { colorPalette, useIsMediumScreen } from 'theme';

export const SocialIconsContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  marginTop: '0.5rem',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    marginTop: useIsMediumScreen ? null : '1rem',
    gap: '0.5rem'
  }
}));

export const StyledIcon = styled(IconButton)(({ theme }) => ({
  backgroundColor: 'white',
  border: `0.0625rem solid ${colorPalette.grey}50`,
  borderRadius: '.4375rem',
  height: useIsMediumScreen ? '2.5rem' : '4rem',
  margin: '.3125rem',
  padding: '0.25rem',
  width: useIsMediumScreen ? '2.5rem' : '4rem',
  [theme.breakpoints.up('sm')]: {
    height: '4rem',
    width: '4rem',
    margin: '.3125rem'
  }
}));
