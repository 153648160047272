import React, { useState, useEffect, useContext, useCallback } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

import { PsLogo } from 'components/common/PsLogo';
import { Contest } from 'components/CardContest';
import { Challenge } from 'components/CardChallenge';
import { ContestIcon } from 'components/icons/ContestIcon';
import { DataContext } from 'contexts/DataContext';
import { PsTheme } from '../../theme';
import { makeStyles } from 'tss-react/mui';

type ClassKey =
  | 'contestAndChallengeContainer'
  | 'contentWrapper'
  | 'gridContainer'
  | 'contestWrapper'
  | 'contestContainer'
  | 'contest'
  | 'jackpot'
  | 'loading'
  | 'noResult';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    contestAndChallengeContainer: {
      maxWidth: '637px',
      width: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    contentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
      '& .title': {
        display: 'flex',
        justifyContent: 'space-between',
        '& p': {
          fontWeight: 600,
          fontSize: '14px',
          lineHeight: '17px',
          color: '#000000'
        }
      }
    },
    gridContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '26px',
      [psTheme.breakpoints.down('xs')]: {
        gridTemplateColumns: '1fr'
      }
    },
    contestWrapper: {
      display: 'flex',
      gap: '28px',
      flexDirection: 'column'
    },
    contestContainer: {
      display: 'flex',
      gap: '25px'
    },
    contest: {
      padding: '0 0 0 8px',
      display: 'flex',
      gap: '26px',
      maxWidth: '235px',
      width: '100%',
      backgroundColor: '#DBF1E4',
      borderRadius: '12px',
      alignItems: 'center',
      textDecoration: 'none',
      '& p': {
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '17px',
        color: '#07A854',
        width: '100%',
        textAlign: 'center'
      },
      '& div:nth-child(1)': {
        minWidth: '20px',
        minHeight: '20px'
      },
      [psTheme.breakpoints.down('xs')]: {
        maxWidth: '100%'
      }
    },
    jackpot: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& p': {
        fontWeight: 400,
        marginRight: 10
      }
    },
    loading: {
      lineHeight: '30px',
      textAlign: 'center'
    },
    noResult: {
      lineHeight: '30px',
      textAlign: 'center'
    }
  };
});

type RelatedJackpotsProps = {
  problem?: string | number;
  solution?: string | number;
  application?: string | number;
  product?: string | number;
};

const RelatedJackpotsView = ({
  problem,
  solution,
  application,
  product
}: RelatedJackpotsProps) => {
  const { dataProvider, relatedContestsListCache } = useContext(DataContext);
  const [contests, setContests] = useState<Array<Contest>>([]);
  const [challenges, setChallenges] = useState<Array<Challenge>>([]);
  const [loading, setLoading] = useState(true);
  const { classes } = useStyles();

  useEffect(() => {
    if (!problem && !solution && !application && !product) {
      setContests([]);
      setChallenges([]);
      setLoading(false);
      return;
    }
    let resource = '';
    let itemId = '';
    if (problem) {
      resource = 'problems';
      itemId = problem as string;
    }
    if (solution) {
      resource = 'solutions';
      itemId = solution as string;
    }
    if (application) {
      resource = 'applications';
      itemId = application as string;
    }
    if (product) {
      resource = 'company-products';
      itemId = product as string;
    }

    setLoading(true);
    dataProvider
      .getJackpots<{ contests: Contest[]; challenges: Challenge[] }>(
        resource,
        itemId
      )
      .then(({ contests = [], challenges = [] }) => {
        setContests(contests);
        setChallenges(challenges);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [problem]);

  return (
    <>
      {loading ? (
        <Typography className={classes.loading} variant="body2">
          Loading...
        </Typography>
      ) : null}
      {!loading && !contests.length && !challenges.length ? (
        <Typography className={classes.noResult}>
          This item is not currently part of any contests or challenges
        </Typography>
      ) : null}
      <div className={classes.contestAndChallengeContainer}>
        <div className={classes.gridContainer}>
          {contests.length ? (
            <div className={classes.contentWrapper}>
              <div className="title">
                <Typography>contest</Typography>
                <Typography>jackpot</Typography>
              </div>
              <div className={classes.contestWrapper}>
                {contests.map((contest) => {
                  return (
                    <div key={contest.id} className={classes.contestContainer}>
                      <Link to={`/contests/${contest.key}`}>
                        <a className={classes.contest} target="_blank">
                          <ContestIcon size={20}></ContestIcon>
                          <Typography>{contest.title}</Typography>
                        </a>
                      </Link>
                      <div className={classes.jackpot}>
                        <Typography>{contest.bountyCoins}</Typography>
                        <PsLogo size={18} color="#632DDD" small></PsLogo>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
          {contests.length ? (
            <div className={classes.contentWrapper}>
              <div className="title">
                <Typography>challenge</Typography>
                <Typography>jackpot</Typography>
              </div>
              <div className={classes.contestWrapper}>
                {challenges.map((challenge) => {
                  return (
                    <div
                      key={challenge.id}
                      className={classes.contestContainer}
                    >
                      <Link to={`/challenges/${challenge.key}`}>
                        <a className={classes.contest} target="_blank">
                          <ContestIcon size={20}></ContestIcon>
                          <Typography>{challenge.title}</Typography>
                        </a>
                      </Link>
                      <div className={classes.jackpot}>
                        <Typography>{challenge.internalReward}</Typography>
                        <PsLogo size={18} color="#632DDD" small></PsLogo>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export const RelatedJackpots = styled(RelatedJackpotsView)({});

export default RelatedJackpots;
