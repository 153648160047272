import React, { useContext, useState, useEffect } from 'react';
import { PsFormControl, ShortEvent } from 'components/common/PsFormControl';
import PsInputLabel from 'components/common/PsInputLabel';
import { PsInput } from 'components/common/PsInput';
import { PsButton } from 'components/common/PsButton';
import { ModalDataContext } from 'contexts/ModalDataContext';
import { DataContext } from 'contexts/DataContext';
import { AuthContext } from 'contexts/AuthContext';
import styles from 'modals/ModalWindow.module.scss';
import Typography from '@mui/material/Typography';

const ChooseCompanyStep: React.FC = () => {
  const { user } = useContext(AuthContext);
  const { values, updateField } = useContext(ModalDataContext);
  const { dataProvider } = useContext(DataContext);
  const [users, setUsers] = useState<Array<string>>(values.payOptions || []);
  const [name, setName] = useState('');

  useEffect(() => {
    const modalTitle = (
      <>
        Claim the company page for <b>{values.parentProblemLabel}</b>
      </>
    );
    updateField('modalTitle', modalTitle);
  }, [values.parentProblemLabel]);

  const onNameChange = (e: ShortEvent) => {
    setName(e.target.value);
  };

  const onInviteSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const newUsers = [...users, name];
    setUsers(newUsers);
    setName('');
    updateField('payOptions', newUsers);
  };

  return (
    <div className={styles.stepWrapper}>
      <PsInputLabel
        label={`Invite Other ${values.parentProblemLabel} Employees:`}
      />
      <form className={styles.formColumns} action="#" onSubmit={onInviteSubmit}>
        <div>
          <PsFormControl
            placeholder="Type email here"
            //label={`Invite Other ${values.parentProblemLabel} Employees:`}
          >
            <PsInput name="title" value={name} onChange={onNameChange} />
          </PsFormControl>
        </div>
        <div>
          <PsButton type="submit">Invite</PsButton>
        </div>
      </form>
      <PsInputLabel label={`Invited:`} />
      <Typography className={styles.textSmall}>
        {users.map((u) => (
          <>
            <span>{u}</span>
            <br />
          </>
        ))}
      </Typography>
    </div>
  );
};

export default ChooseCompanyStep;
