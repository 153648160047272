import { Box, Dialog, styled } from '@mui/material';
import { colorPalette } from 'theme';

export const StyledModalWrapper = styled(Box)({
  background: colorPalette.white,
  borderRadius: 1,
  boxShadow: '0rem .25rem .5rem rgba(0, 0, 0, 0.1)',
  maxWidth: 1200
});

export const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    maxWidth: '72rem',
    width: '100%'
  }
});

export const StyledHeaderWrapper = styled(Box)({
  padding: '1.5625rem 1.5625rem 1rem 1.5625rem'
});

export const StyledActionWrapper = styled(Box)({
  padding: '1rem 1.5625rem 1.5625rem  1.5625rem'
});

export const ContentSectionWrapper = styled(Box)({
  borderBottom: `1px solid ${colorPalette.lightGrey}`,
  borderTop: `1px solid ${colorPalette.lightGrey}`,
  maxHeight: '70vh',
  overflowY: 'auto',
  padding: '1.5625rem'
});

export const StyledWatermarkContainer = styled(Box)({
  bottom: 0,
  position: 'absolute',
  right: 0
});
