import React, { FunctionComponent } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { PsTheme } from '../../theme';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      display: 'flex',
      width: '100%',
      alignItems: 'flex-start',
      marginBottom: '16px'
    },
    title: {
      margin: 0,
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: 600,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '& a': {
        color: 'inherit',
        textDecoration: 'none'
      },
      '& a:hover': {
        color: 'inherit',
        textDecoration: 'underline'
      },
      '& b': {
        color: psTheme.palette.primary.main
      },
      '& span': {
        color: psTheme.palette.primary.main
      }
    },
    rest: {
      marginLeft: 'auto'
    }
  };
});

type CardTitleProps = {
  children?: any;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  beforeTitle?: React.ReactNode;
  href?: string;
  hrefTarget?: string;
  className?: string;
};

const CardTitleView: FunctionComponent<CardTitleProps> = ({
  className = '',
  beforeTitle,
  title,
  href,
  hrefTarget,
  children
}) => {
  const { classes } = useStyles();
  return (
    <div className={classnames(classes.root, { [className]: className })}>
      {beforeTitle}
      {title ? (
        <h2 className={classes.title}>
          {href ? (
            <Link to={href || ''}>
              <a target={hrefTarget}>{title}</a>
            </Link>
          ) : (
            title
          )}
        </h2>
      ) : null}
      <div className={classes.rest}>{children}</div>
    </div>
  );
};

export const CardTitle = styled(CardTitleView)({});

export default CardTitle;
