import { Box, styled, TextField, Typography } from '@mui/material';
import PsButton from 'components/common/PsButton';
import { colorPalette } from 'theme';

export const StyledHeaderBox = styled(Box)({
  alignItems: 'center',
  background: colorPalette.purple,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '.625rem'
});

export const StyledCrossIconBox = styled(Box)({
  background: colorPalette.purple,
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%'
});

export const WhiteSpace = styled(Box)({
  height: '5rem'
});

export const ContentWrapper = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginTop: '-5.3125rem',
  padding: '1.25rem',
  width: '100%'
});

export const NameFieldBox = styled(Box)({
  width: '100%'
});

export const StyledBodyTypography = styled(Typography)({
  color: `${colorPalette.dimBlack} !important`,
  fontSize: '15px !important',
  fontWeight: '500 !important',
  paddingBottom: '.1875rem'
});

export const StyledBodyFieldText = styled(TextField)({
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    boxShadow: `0px 0px 5px 0px ${colorPalette.black}15`
  },
  '& input:-webkit-autofill': {
    // WebkitBoxShadow: `0 0 0 1000px ${colorPalette.black} inset`,
    WebkitColor: colorPalette.black,
    WebkitTextFillColor: colorPalette.white
  },
  '& .MuiFormHelperText-root': {
    fontSize: '.625rem',
    color: colorPalette.nobel
  },
  width: '100%'
});

export const ActionButton = styled(PsButton)({
  borderRadius: '6px !important',
  color: colorPalette.white,
  fontSize: '1.125rem !important',
  fontWeight: 'bold',
  height: '2.8125rem',
  marginTop: '.625rem',
  outline: 'none',
  padding: '.5rem 0px!important',
  width: '11.875rem'
});

export const StyledHeaderTypography = styled(Typography)({
  color: colorPalette.white,
  fontSize: '1.625rem',
  fontWeight: 700,
  marginTop: '1.1rem'
});

export const StyledSubHeaderTypography = styled(Typography)({
  color: colorPalette.white,
  fontSize: '0.78rem',
  fontWeight: 400,
  lineHeight: 1.2
});

export const SubCardWrapper = styled(Box)({
  marginBottom: '.8125rem',
  marginTop: '18px',
  width: '78%'
});

export const ContentBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginTop: '-4.6rem',
  paddingBottom: '1.25rem'
});

export const PurchaseBox = styled(Box)({
  alignItems: 'center',
  backgroundColor: colorPalette.white,
  borderRadius: '8px',
  boxShadow: `0rem 0rem .4375rem .0688rem ${colorPalette.black}10`,
  display: 'flex',
  flexDirection: 'row',
  gap: '.9375rem',
  justifyContent: 'flex-start',
  padding: '1.25rem',
  width: '78%'
});

export const TextFieldsBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '.625rem',
  width: '60%'
});

export const CalcAmountBox = styled(Box)({
  alignItems: 'flex-start',
  backgroundColor: colorPalette.white,
  borderRadius: '8px',
  boxShadow: `0rem 0rem .4375rem .0688rem ${colorPalette.black}10`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  overflow: 'hidden',
  padding: '1rem',
  width: '40%',
  wordWrap: 'break-word'
});

export const CalcAmountTitle = styled(Typography)({
  fontSize: '1rem',
  fontWeight: '500',
  lineHeight: 1.4
});

export const CalcAmountText = styled(Typography)({
  fontSize: '1.875rem',
  fontWeight: '800',
  lineHeight: 1.2
});
