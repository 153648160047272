import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';

const useStyles = makeStyles()(() => {
  return {
    root: {
      '&:hover': {
        backgroundColor: 'transparent'
      }
    },
    icon: {
      borderRadius: 4,
      position: 'relative',
      width: 24,
      height: 24,
      border: '1px solid #B2BAC6',
      background: 'rgba(246, 244, 251, 0.4)',

      'input:disabled ~ &': {
        boxShadow: 'none'
      }
    },
    checkedIcon: {
      backgroundColor: 'transparent',
      '&:before': {
        position: 'absolute',
        top: -1,
        left: -1,
        display: 'block',
        width: 24,
        height: 24,
        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' width='24' height='24'%3E%3Crect x='0.5' y='0.5' width='23' height='23' rx='3.5' fill='transparent' fill-opacity='0.4' stroke='%2307A854' /%3E%3Cg transform='translate(5 6)'%3E%3Cpath d='M13 1.5L4.75 9.75L1 6' stroke='%2307A854' stroke-width='1.4' stroke-line-cap='round' stroke-line-join='round' /%3E%3C/g%3E%3C/svg%3E")`,
        content: '""'
      }
    }
  };
});

// Inspired by blueprintjs
function StyledCheckbox(props: CheckboxProps) {
  const { classes } = useStyles();

  return (
    <Checkbox
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={
        <span className={`${classes.icon} ${classes.checkedIcon}`} />
      }
      icon={<span className={classes.icon} />}
      inputProps={{ 'aria-label': 'decorative checkbox' }}
      {...props}
    />
  );
}

export default StyledCheckbox;
