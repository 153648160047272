import React, { useContext, useMemo, useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { PsTheme } from '../../theme';
import { DrawSizeTools } from './DrawSizeTools';
import { DrawColorTools } from './DrawColorTools';
import { tools } from './consts';
import { makeStyles } from 'tss-react/mui';

type ClassKey =
  | 'root'
  | 'nav'
  | 'navRow'
  | 'navCell'
  | 'contentHolder'
  | 'content'
  | 'contentScroll'
  | 'contentItem';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      position: 'relative',
      width: 90,
      height: 455,
      zIndex: 10
    },
    nav: {
      position: 'relative',
      display: 'table',
      tableLayout: 'fixed',
      width: '100%',
      height: '100%',
      background: '#f3f3f3',
      border: 'solid 1px #ccc',
      borderWidth: '0 1px 1px 0'
    },
    navRow: {
      position: 'relative',
      display: 'table-row',
      height: 91
    },
    navCell: {
      display: 'table-cell',
      verticalAlign: 'middle',
      width: '100%',
      textAlign: 'center',
      color: '#a9a9a9',
      background: 'rgba(255,255,255,0)',
      textDecoration: 'none',
      '&:active': {
        color: '#5a5a5a'
      },
      '&.active': {
        background: '#fff',
        color: '#5a5a5a'
      },
      '& .icon_bg--pen': {
        float: 'left',
        position: 'relative',
        display: 'block',
        width: 0,
        '& > ins': {
          position: 'absolute',
          top: 19,
          left: 39,
          display: 'block',
          width: 10,
          height: 25,
          zIndex: 3,
          transform: 'rotate(29deg)'
        }
      },
      '& .icon_bg--color': {
        display: 'inline-block',
        width: 45,
        height: 45,
        textIndent: -100,
        overflow: 'hidden',
        border: 'solid 4px #a9a9a9',
        borderRadius: '50%'
      },
      '&.active .icon_bg--color': {
        border: 'solid 4px #5a5a5a'
      },
      '& .icon': {
        position: 'relative',
        fontFamily: '"HemaPasen-Icons" !important',
        fontStyle: 'normal !important',
        fontWeight: 'normal !important',
        fontVariant: 'normal !important',
        // textTransform: 'none !important',
        speak: 'none',
        lineHeight: 1,
        fontSize: 64
        //-webkit-font-smoothing: antialiased;
      }
    },
    contentHolder: {
      position: 'absolute',
      top: 0,
      left: '100%',
      width: '100%',
      height: '100%',
      border: 'solid 1px #ccc',
      boxSizing: 'content-box',
      borderWidth: '0 1px 1px 0'
    },
    content: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: '#fff',
      zIndex: 1,
      '&.active': {
        zIndex: 5
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'block',
        width: '100%',
        height: 30,
        zIndex: 10,
        backgroundSize: '100%',
        backgroundImage:
          'linear-gradient(to bottom, #ffffff 10%,rgba(255,255,255,0))'
      },
      '&:after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        display: 'block',
        width: '100%',
        height: 30,
        zIndex: 10,
        backgroundSize: '100%',
        backgroundImage:
          'linear-gradient(to top, #ffffff 10%,rgba(255,255,255,0))'
      }
    },
    contentScroll: {
      height: '100%',
      width: '100%',
      overflow: 'hidden',
      overflowY: 'auto'
    },
    contentItem: {}
  };
});

export type DrawToolsProps = {
  classes?: any;
  tool: string;
  size: number;
  color: string;
  onToolSelect: (tool: string) => void;
  onSizeSelect: (size: number) => void;
  onColorSelect: (color: string) => void;
  onBackClick: () => void;
  onClearClick: () => void;
};

const DrawToolsView = ({
  tool,
  size,
  color,
  onSizeSelect,
  onColorSelect,
  onToolSelect,
  onBackClick,
  onClearClick
}: DrawToolsProps) => {
  const onToolClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const tool = e.currentTarget.dataset.tool as string;
    onToolSelect(tool);
  };

  const _onBackClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    onBackClick();
  };

  const _onClearClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    onClearClick();
  };
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.nav}>
        <div className={classes.navRow}>
          <a
            href=""
            className={`${classes.navCell} ${
              tool === tools.PEN ? 'active' : ''
            }`}
            onClick={onToolClick}
            data-tool={tools.PEN}
          >
            <span className="icon_bg icon_bg--pen">
              <ins style={{ background: color }} />
            </span>
            <span className="icon">g</span>
          </a>
        </div>
        <div className={classes.navRow}>
          <a
            href=""
            className={`${classes.navCell} ${
              tool === tools.COLOR ? 'active' : ''
            }`}
            onClick={onToolClick}
            data-tool={tools.COLOR}
          >
            <span
              className="icon_bg icon_bg--color"
              style={{ background: color }}
            />
          </a>
        </div>
        <div className={classes.navRow}>
          <a
            href=""
            className={`${classes.navCell} ${
              tool === tools.RAZER ? 'active' : ''
            }`}
            onClick={onToolClick}
            data-tool={tools.RAZER}
          >
            <span className="icon">h</span>
          </a>
        </div>
        <div className={classes.navRow}>
          <a
            href=""
            className={classes.navCell}
            onClick={_onBackClick}
            data-tool={tools.BACK}
          >
            <span className="icon">p</span>
          </a>
        </div>
        <div className={classes.navRow}>
          <a
            href=""
            className={classes.navCell}
            onClick={_onClearClick}
            data-tool={tools.CLEAR}
          >
            <span className="icon">t</span>
          </a>
        </div>
      </div>
      <div className={classes.contentHolder}>
        <div
          className={`${classes.content} ${
            tool === tools.PEN || tool === tools.RAZER ? 'active' : ''
          }`}
        >
          <div className={classes.contentScroll}>
            <DrawSizeTools active={size} onSelect={onSizeSelect} />
          </div>
        </div>
        <div
          className={`${classes.content} ${
            tool === tools.COLOR ? 'active' : ''
          }`}
        >
          <div className={classes.contentScroll}>
            <DrawColorTools active={color} onSelect={onColorSelect} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const DrawTools = styled(DrawToolsView)({});

export default DrawTools;
