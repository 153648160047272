import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const ShareGreenImage = (props: SvgIconProps) => {
  return (
    <SvgIcon sx={{ width: 42, height: 43 }} viewBox="0 0 42 43">
      <path
        d="M0 28.612H41M32.6327 4.34668H38.4898C39.8763 4.34668 41 5.47043 41 6.85685V30.2854C41 31.6719 39.8763 32.7956 38.4898 32.7956H2.51017C1.12375 32.7957 0 31.6719 0 30.2855V6.85693C0 5.47043 1.12375 4.34676 2.51017 4.34676H8.36733M31.7959 41.9998H9.20408V41.9828C9.20408 39.6816 11.0696 37.8161 13.3708 37.8161H27.6293C29.9304 37.8161 31.7959 39.6816 31.7959 41.9828V41.9998Z"
        stroke="#68D585"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.34766 28.6122V7.69383H8.36807M32.6334 7.69383H37.6538V28.6122M15.062 37.8163L17.5722 32.7959M23.4293 32.7959L25.9395 37.8163M8.36807 28.6122V3.51017C8.36807 2.12383 9.49191 1 10.8782 1H30.1232C31.5095 1 32.6333 2.12383 32.6333 3.51017V28.6122"
        stroke="#68D585"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0604 23.5919H25.9379M26.3264 12.296L20.4991 18.5715L14.6719 12.296L20.4991 6.02051L26.3264 12.296Z"
        stroke="#49695C"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
