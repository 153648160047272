import React, { useContext, useCallback } from 'react';
import { styled } from '@mui/material/styles';

import { SimpleModalContext } from 'contexts/SimpleModalContext';
import { Modal } from 'modals/Modal';

type ClassKey = 'root';

export const SimpleModalView = () => {
  const { show, title, content, closeModal } = useContext(SimpleModalContext);

  const onModalClose = useCallback(() => {
    closeModal();
  }, [closeModal]);

  return (
    <Modal open={show} onClose={onModalClose} title={title}>
      {content}
    </Modal>
  );
};

export const SimpleModal = styled(SimpleModalView)({});

export default SimpleModal;
