import {
  DELETE_PROFILE_ITEM,
  E_FILE_PATENT_SUCCESS,
  E_FILE_PATENT,
  EDIT_PROFILE_ITEM,
  EDIT_PROFILE_SUCCESS,
  EDIT_PROFILE,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS,
  GET_PROFILE_AGREEMENTS_COUNT_SUCCESS,
  GET_PROFILE_AGREEMENTS_COUNT,
  GET_PROFILE_AGREEMENTS_SUCCESS,
  GET_PROFILE_AGREEMENTS,
  GET_PROFILE_CONCEPTS_COUNT_SUCCESS,
  GET_PROFILE_CONCEPTS_COUNT,
  GET_PROFILE_CONCEPTS_SUCCESS,
  GET_PROFILE_CONCEPTS,
  GET_PROFILE_CONTESTS_COUNT_SUCCESS,
  GET_PROFILE_CONTESTS_COUNT,
  GET_PROFILE_CONTESTS_SUCCESS,
  GET_PROFILE_CONTESTS,
  GET_PROFILE_INVENTIONS_COUNT_SUCCESS,
  GET_PROFILE_INVENTIONS_COUNT,
  GET_PROFILE_INVENTIONS_SUCCESS,
  GET_PROFILE_INVENTIONS,
  GET_PROFILE_MUTUAL_TAGS_SUCCESS,
  GET_PROFILE_MUTUAL_TAGS,
  GET_PROFILE_PROBLEMS_COUNT_SUCCESS,
  GET_PROFILE_PROBLEMS_COUNT,
  GET_PROFILE_PROBLEMS_SUCCESS,
  GET_PROFILE_PROBLEMS,
  GET_PROFILE_PRODUCTS_SUCCESS,
  GET_PROFILE_PRODUCTS,
  GET_PROFILE_SOLUTIONS_COUNT_SUCCESS,
  GET_PROFILE_SOLUTIONS_COUNT,
  GET_PROFILE_SOLUTIONS_SUCCESS,
  GET_PROFILE_SOLUTIONS,
  GET_REWARD_HISTORY_SUCCESS,
  GET_REWARD_HISTORY,
  GET_UNREAD_NOTIFICATION_COUNT_SUCCESS,
  GET_USER_IDEAPOINTS_SUCCESS,
  GET_USER_IDEAPOINTS,
  GET_USER_RANKING_SUCCESS,
  GET_USER_RANKING,
  NFT_DEPLOY_FINISH_SUCCESS,
  NFT_DEPLOY_FINISH,
  NFT_DEPLOY_START_SUCCESS,
  NFT_DEPLOY_START,
  NFT_DEPLOY_WITH_METAMASK_SUCCESS,
  NFT_DEPLOY_WITH_METAMASK,
  OPEN_TX_APPROVAL_MODAL,
  SET_TOKEN_URI,
  TOGGLE_DEPLOY_NFT_LOADER,
  TOGGLE_ERROR,
  UPDATE_ERROR_STATUS
} from './types';
import { ProfilePayload } from './interface';

const initTxApprovalModalObj = {
  gasFee: 0,
  open: false,
  type: ''
};

const INITIAL_STATE = {
  agreements: [],
  agreementsCount: 0,
  authStatus: '',
  concepts: [],
  conceptsCount: 0,
  contests: [],
  contestsCount: 0,
  deployNFTLoader: false,
  editItemLoader: false,
  editProfileLoader: false,
  eFilePatentLoading: false,
  error: false,
  errorItemId: null,
  errorMessage: '',
  getAgreementLoading: false,
  getConceptsLoading: false,
  getContestsLoading: false,
  getInventionsLoading: false,
  getNotificationsLoading: false,
  getProblemLoading: false,
  getProductsLoading: false,
  getProfileMutualTagsLoader: false,
  getRewardHistoryLoading: false,
  getSolutionLoading: false,
  getUserRankingLoading: false,
  inventions: [],
  inventionsCount: 0,
  nftDeployFinishLoader: false,
  nftDeployStartLoader: false,
  nftDeployWithMetamaskLoader: false,
  pinnedConcepts: [],
  pinnedContests: [],
  pinnedInventions: [],
  pinnedProblems: [],
  pinnedSolutions: [],
  problems: [],
  problemsCount: 0,
  products: [],
  profileMutualTags: [],
  rewardHistory: [],
  solutions: [],
  solutionsCount: 0,
  statusCode: null,
  tokenURI: '',
  totalAgreements: 0,
  totalConcepts: 0,
  totalContests: 0,
  totalInventions: 0,
  totalProblems: 0,
  totalProducts: 0,
  totalReward: 0,
  totalSolutions: 0,
  txApprovalModalObj: initTxApprovalModalObj,
  unreadNotificationCount: 0,
  userIdeaPoints: 0,
  userRank: 0
};

const reducer = (
  state = INITIAL_STATE,
  action: { type: string; payload: ProfilePayload }
) => {
  switch (action.type) {
    case GET_PROFILE_PROBLEMS:
      return { ...state, getProblemLoading: true };
    case GET_PROFILE_AGREEMENTS:
      return { ...state, getAgreementLoading: true };
    case EDIT_PROFILE_ITEM:
      return { ...state, editItemLoader: true };
    case E_FILE_PATENT:
      return { ...state, eFilePatentLoading: true };
    case E_FILE_PATENT_SUCCESS:
      return { ...state, eFilePatentLoading: false };
    case GET_PROFILE_PROBLEMS_SUCCESS:
      return {
        ...state,
        problems: action.payload.items,
        pinnedProblems: action.payload.updatePinItems
          ? action.payload.pinnedItems
          : state.pinnedProblems,
        totalProblems: action.payload.count,
        getProblemLoading: false
      };
    case GET_PROFILE_PROBLEMS_COUNT:
      return {
        ...state,
        getProblemLoading: true
      };
    case GET_PROFILE_PROBLEMS_COUNT_SUCCESS:
      return {
        ...state,
        problemsCount: action.payload.count,
        getProblemLoading: false
      };
    case GET_PROFILE_SOLUTIONS_COUNT:
      return {
        ...state,
        getSolutionLoading: true
      };
    case EDIT_PROFILE:
      return {
        ...state,
        editProfileLoader: true
      };
    case EDIT_PROFILE_SUCCESS:
      return {
        ...state,
        editProfileLoader: false
      };
    case GET_PROFILE_SOLUTIONS_COUNT_SUCCESS:
      return {
        ...state,
        solutionsCount: action.payload.count,
        getSolutionLoading: false
      };
    case GET_PROFILE_CONCEPTS_COUNT:
      return {
        ...state,
        getConceptsLoading: true
      };
    case GET_PROFILE_CONCEPTS_COUNT_SUCCESS:
      return {
        ...state,
        conceptsCount: action.payload.count,
        getConceptsLoading: false
      };
    case GET_PROFILE_INVENTIONS_COUNT:
      return {
        ...state,
        getInventionsLoading: true
      };
    case GET_PROFILE_INVENTIONS_COUNT_SUCCESS:
      return {
        ...state,
        inventionsCount: action.payload.count,
        getInventionsLoading: false
      };
    case GET_PROFILE_CONTESTS_COUNT:
      return {
        ...state,
        getContestsLoading: true
      };
    case GET_PROFILE_CONTESTS_COUNT_SUCCESS:
      return {
        ...state,
        contestsCount: action.payload.count,
        getContestsLoading: false
      };
    case GET_PROFILE_AGREEMENTS_COUNT:
      return {
        ...state,
        getAgreementLoading: true
      };
    case GET_PROFILE_AGREEMENTS_COUNT_SUCCESS:
      return {
        ...state,
        agreementsCount: action.payload.count,
        getAgreementLoading: false
      };
    case GET_PROFILE_AGREEMENTS_SUCCESS:
      return {
        ...state,
        agreements: action.payload.items,
        totalAgreements: action.payload.count,
        getAgreementLoading: false
      };
    case GET_PROFILE_SOLUTIONS:
      return { ...state, getSolutionLoading: true };
    case GET_PROFILE_SOLUTIONS_SUCCESS:
      return {
        ...state,
        pinnedSolutions: action.payload.updatePinItems
          ? action.payload.pinnedItems
          : state.pinnedSolutions,
        solutions: action.payload.items,
        totalSolutions: action.payload.count,
        getSolutionLoading: false
      };
    case GET_PROFILE_CONCEPTS:
      return { ...state, getConceptsLoading: true };
    case GET_PROFILE_CONCEPTS_SUCCESS:
      return {
        ...state,
        concepts: action.payload.items,
        pinnedConcepts: action.payload.updatePinItems
          ? action.payload.pinnedItems
          : state.pinnedConcepts,
        totalConcepts: action.payload.count,
        editItemLoader: false,
        getConceptsLoading: false
      };
    case GET_PROFILE_INVENTIONS:
      return { ...state, getInventionsLoading: true };
    case GET_PROFILE_INVENTIONS_SUCCESS:
      return {
        ...state,
        inventions: action.payload.items,
        pinnedInventions: action.payload.updatePinItems
          ? action.payload.pinnedItems
          : state.pinnedInventions,
        totalInventions: action.payload.count,
        getInventionsLoading: false
      };
    case GET_PROFILE_PRODUCTS:
      return { ...state, getProductsLoading: true };
    case GET_PROFILE_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload.items,
        totalProducts: action.payload.count + action.payload.pinnedItems.length,
        getProductsLoading: false
      };
    case GET_PROFILE_CONTESTS:
      return { ...state, getContestsLoading: true };
    case GET_PROFILE_CONTESTS_SUCCESS:
      return {
        ...state,
        contests: action.payload.items,
        pinnedContests: action.payload.updatePinItems
          ? action.payload.pinnedItems
          : state.pinnedContests,
        totalContests: action.payload.count,
        getContestsLoading: false
      };
    case TOGGLE_DEPLOY_NFT_LOADER:
      return {
        ...state,
        deployNFTLoader: action.payload.deployNFTLoader
      };
    case SET_TOKEN_URI:
      return { ...state, tokenURI: action.payload.tokenURI };
    case OPEN_TX_APPROVAL_MODAL:
      return {
        ...state,
        txApprovalModalObj: {
          gasFee: action.payload.txApprovalModalObj.gasFee,
          open: action.payload.txApprovalModalObj.open,
          type: action.payload.txApprovalModalObj.type
        }
      };
    case TOGGLE_ERROR:
      // eslint-disable-next-line no-case-declarations
      const errorStates = {
        ...state,
        error: !state.error,
        errorItemId: action.payload.errorItemId,
        errorMessage: action.payload.errorMessage
      };
      switch (action.payload.type) {
        case 'problems':
          return {
            ...errorStates,
            getProblemLoading: false
          };
        case 'solutions':
          return {
            ...errorStates,
            getSolutionLoading: false
          };
        case 'contests':
          return {
            ...errorStates,
            getContestsLoading: false
          };
        case 'applications':
          if (action.payload.isFiled) {
            return {
              ...errorStates,
              getInventionsLoading: false
            };
          } else {
            return {
              ...errorStates,
              getConceptsLoading: false
            };
          }
        default:
          return {
            ...state
          };
      }
    case GET_NOTIFICATIONS:
      return { ...state, getNotificationsLoading: true };
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        getNotificationsLoading: false,
        notifications: action.payload.data
      };
    case GET_UNREAD_NOTIFICATION_COUNT_SUCCESS:
      return {
        ...state,
        unreadNotificationCount: action.payload.count
      };
    case GET_REWARD_HISTORY:
      return { ...state, getRewardHistoryLoading: true };
    case GET_REWARD_HISTORY_SUCCESS:
      return {
        ...state,
        getRewardHistoryLoading: false,
        rewardHistory: action.payload.data,
        totalReward: action.payload.total
      };
    case GET_USER_RANKING:
      return { ...state, getUserRankingLoading: true };
    case GET_USER_RANKING_SUCCESS:
      return {
        ...state,
        getUserRankingLoading: false,
        userRank: action.payload.rank
      };
    case GET_USER_IDEAPOINTS:
      return { ...state };
    case GET_USER_IDEAPOINTS_SUCCESS:
      return {
        ...state,
        userIdeaPoints: action.payload.ideaPoints
      };
    case NFT_DEPLOY_START:
      return { ...state, nftDeployStartLoader: true };
    case NFT_DEPLOY_START_SUCCESS:
      return {
        ...state,
        nftDeployStartLoader: false
      };
    case NFT_DEPLOY_WITH_METAMASK:
      return { ...state, nftDeployWithMetamaskLoader: true };
    case NFT_DEPLOY_WITH_METAMASK_SUCCESS:
      return {
        ...state,
        nftDeployWithMetamaskLoader: false
      };
    case NFT_DEPLOY_FINISH:
      return {
        ...state,
        nftDeployFinishLoader: true
      };
    case NFT_DEPLOY_FINISH_SUCCESS:
      return {
        ...state,
        nftDeployFinishLoader: false,
        deployNFTLoader: false,
        tokenURI: '',
        txApprovalModalObj: {
          gasFee: 0,
          open: false,
          type: ''
        }
      };
    case GET_PROFILE_MUTUAL_TAGS:
      return { ...state, getProfileMutualTagsLoader: true };
    case GET_PROFILE_MUTUAL_TAGS_SUCCESS:
      return {
        ...state,
        getProfileMutualTagsLoader: false,
        profileMutualTags: action.payload.data
      };
    case DELETE_PROFILE_ITEM:
      switch (action.payload.type) {
        case 'problems':
          return { ...state, getProblemLoading: true };
        case 'solutions':
          return { ...state, getSolutionLoading: true };
        case 'contests':
          return { ...state, getContestsLoading: true };
        case 'applications':
          if (action.payload.isFiled) {
            return { ...state, getInventionsLoading: true };
          } else {
            return { ...state, getConceptsLoading: true };
          }
        default:
          return state;
      }
    case UPDATE_ERROR_STATUS:
      return {
        ...state,
        statusCode: action.payload.statusCode,
        errorMessage: action.payload.errorMessage
      };
    default:
      return state;
  }
};

export default reducer;
