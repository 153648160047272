import React, { useState, useContext, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import ModalWindow from 'modals/ModalWindow';
import { PsButton } from 'components/common/PsButton';
import { PsInput } from 'components/common/PsInput';
import { ShortEvent, PsFormControl } from 'components/common/PsFormControl';
import { ModalContext } from 'contexts/ModalContext';
import { ModalDataContext } from 'contexts/ModalDataContext';
import { DataContext } from 'contexts/DataContext';
import { WalletContext } from 'contexts/WalletContext';
import styles from 'modals/ModalWindow.module.scss';
import { makeStyles } from 'tss-react/mui';

const addressRegExp = /^0x[a-fA-F0-9]{40}$/;

const useButtonClasses = makeStyles()(() => {
  return {
    root: {
      margin: '8px 0',
      fontWeight: 400
    }
  };
});

export const SellInvention: React.FC = () => {
  const { closeModal } = useContext(ModalContext);
  const { values, resetFields } = useContext(ModalDataContext);
  const { dataProvider, refreshApplicationDetail } = useContext(DataContext);
  const { walletProvider, checkMagicConnect } = useContext(WalletContext);
  const [transferAddress, setTransferAddress] = useState('');
  const [transferAddressError, setTransferAddressError] = useState('');
  const [loading, setLoading] = useState(true);
  const buttonClasses = useButtonClasses();

  useEffect(() => {
    if (!values.selectApplicationType) {
      setTransferAddress('');
      setLoading(false);
      return;
    }
    dataProvider
      .getOne<{ walletAddress: string }>('profiles', {
        id: values.selectApplicationType
      })
      .then(({ data }) => {
        setTransferAddress(data.walletAddress || '');
      })
      .catch((err) => {
        setTransferAddress('');
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [values.selectApplicationType]);

  const handleClickBack = () => {
    closeModal();
    resetFields();
  };

  const handleNextStep = async () => {
    setTransferAddressError('');
    if (!transferAddress) {
      setTransferAddressError('Transfer Address is missed');
      return;
    }
    if (!addressRegExp.test(transferAddress)) {
      setTransferAddressError('Transfer Address is incorrect');
      return;
    }
    const id = values.selectApplication;
    if (!id) {
      setTransferAddressError('No Invention Id found');
      return;
    }

    setLoading(true);

    checkMagicConnect()
      .then(() => {
        return dataProvider.nftDeployStart(id);
      })
      .then((info) => {
        return walletProvider.deployNft(
          info.contractAddress,
          info.tokenURI,
          transferAddress
        );
      })
      .then(([transactionHash, tokenId]) => {
        return dataProvider.nftDeployFinish(id, {
          transactionHash,
          tokenId
        });
      })
      .then(() => {
        refreshApplicationDetail();
        closeModal();
        resetFields();
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onTransferAddressChange = (e: ShortEvent) => {
    setTransferAddress(e.target.value);
    setTransferAddressError('');
  };

  return (
    <ModalWindow title="Deploy NFT">
      <div className={styles.stepWrapper}>
        <PsFormControl
          placeholder="0x...."
          label="Transfer Address"
          helperText={
            transferAddressError
              ? transferAddressError
              : 'Address transfer NFT to'
          }
          error={!!transferAddressError}
        >
          <PsInput
            name="transferAddress"
            onChange={onTransferAddressChange}
            value={transferAddress}
          />
        </PsFormControl>
      </div>
      <div className={styles.actions}>
        <PsButton
          disabled={loading}
          onClick={handleNextStep}
          fullWidth
          classes={buttonClasses}
          loading={loading}
        >
          Deploy
        </PsButton>
        <PsButton
          onClick={handleClickBack}
          fullWidth
          cancel
          classes={buttonClasses}
          disabled={loading}
        >
          Cancel
        </PsButton>
      </div>
    </ModalWindow>
  );
};

export default SellInvention;
