import { FunctionComponent } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const InventionsIcon: FunctionComponent<SvgIconProps> = ({
  ...props
}) => (
  <SvgIcon {...props} viewBox="0 0 20.753 29.991">
    <defs>
      <clipPath id="InventionsIcon">
        <path
          fill="currentColor"
          d="M0 0h20.753v29.991H0z"
          data-name="Rectangle 710"
        />
      </clipPath>
    </defs>
    <g fill="currentColor" clipPath="url(#InventionsIcon)" data-name="Group 67">
      <path
        d="M10.38 0a10.375 10.375 0 0 0-5.933 18.891l.167.117v3.68a1.158 1.158 0 0 0 1.151 1.15h9.227a1.158 1.158 0 0 0 1.15-1.151v-3.68l.167-.117A10.375 10.375 0 0 0 10.38 0m6.638 14.975a8.035 8.035 0 0 1-2.031 2.031l-1.142.806v3.726H6.912v-3.726l-1.143-.806a8.082 8.082 0 1 1 11.249-2.031"
        data-name="Path 271"
      />
      <path
        d="M7.304 29.992h6.153a1.156 1.156 0 0 0 1.15-1.151v-1.147H6.155v1.149a1.156 1.156 0 0 0 1.151 1.15"
        data-name="Path 272"
      />
    </g>
  </SvgIcon>
);
