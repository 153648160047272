import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

interface HexOutlinedIconProps {
  height?: string;
  width?: string;
}

export const HexOutlinedIcon: React.FC<HexOutlinedIconProps> = ({
  height = '40px',
  width = '40px'
}) => {
  return (
    <SvgIcon
      sx={{ width: width, height: height }}
      viewBox="0 0 29 29"
      fill="none"
    >
      <path
        d="M25.375 19.3341V9.66744C25.3746 9.24365 25.2627 8.82742 25.0506 8.46052C24.8385 8.09361 24.5337 7.78892 24.1667 7.57703L15.7083 2.74369C15.341 2.53159 14.9242 2.41992 14.5 2.41992C14.0758 2.41992 13.659 2.53159 13.2917 2.74369L4.83333 7.57703C4.46632 7.78892 4.16148 8.09361 3.94939 8.46052C3.73731 8.82742 3.62543 9.24365 3.625 9.66744V19.3341C3.62543 19.7579 3.73731 20.1741 3.94939 20.541C4.16148 20.9079 4.46632 21.2126 4.83333 21.4245L13.2917 26.2579C13.659 26.47 14.0758 26.5816 14.5 26.5816C14.9242 26.5816 15.341 26.47 15.7083 26.2579L24.1667 21.4245C24.5337 21.2126 24.8385 20.9079 25.0506 20.541C25.2627 20.1741 25.3746 19.7579 25.375 19.3341Z"
        stroke="#1E1E1E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
};
