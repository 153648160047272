import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useDispatch } from 'react-redux';
import { ContentCopy, RotateLeft, Save } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { Solution } from 'components/CardSolution';
import { LinkView } from 'components/LinkView';
import PsFormControl, { ShortEvent } from 'components/common/PsFormControl';
import PsSelect, { PsOptionType } from 'components/common/PsSelect';
import AuthContext from 'contexts/AuthContext';
import { getValueObj } from 'helpers/common';
import Actions from 'redux-state/actions';
import {
  GetAiAnswer,
  GetLoader,
  GetRelationPrompt,
  GetRelationPromptLoader
} from 'redux-state/commons/selectors';
import {
  GetConceptData,
  GetEditProfileItemLoader,
  GetUser
} from 'redux-state/selectors';
import { Constants } from 'utilities/constants';
import {
  ButtonsBox,
  DropDownBox,
  DropDownsMainBox,
  LinkViewWrapper,
  RelationIconBox,
  RelationsBox,
  StyledBodyFieldText,
  StyledBodyTypography,
  StyledCompareArrows,
  StyledIconButton,
  StyledTypography
} from './styles';
import { Application } from '../CardApplication';
import { updateConceptSolutionsRelationship } from '../ChatUI/helpers';

const teaserMap = {};

interface SolutionsRelationshipProps {
  concept?: Application;
  isConceptActive?: boolean;
  isFormValid?: boolean;
  pagination?: { page: number; perPage: number };
  profileId?: string | number;
  setIsFormValid?: Dispatch<SetStateAction<boolean>>;
  setIsNextStepBlocked?: Dispatch<SetStateAction<boolean>>;
  setSaveCallback?: Dispatch<React.SetStateAction<(() => void) | undefined>>;
}

export const SolutionsRelationship: React.FC<SolutionsRelationshipProps> = ({
  concept,
  isConceptActive = false,
  isFormValid,
  pagination,
  profileId,
  setIsFormValid,
  setIsNextStepBlocked,
  setSaveCallback
}) => {
  const initSolutionObj = {
    value: '',
    label: '',
    raw: ''
  };
  const initRelation = 'Please select solutions from above drop-downs.';
  const user = GetUser();
  const [generatedRelations, setGeneratedRelations] = useState({});
  const prompt = GetRelationPrompt();
  const promptLoader = GetRelationPromptLoader();
  const activeConcept = user?.activeConcept || {};
  const conceptData = GetConceptData();
  const selectedConcept = useMemo(() => {
    return (
      activeConcept &&
      Object?.keys(activeConcept)?.length &&
      conceptData?.data?.find((concept) => concept?.id === activeConcept?.id)
    );
  }, [activeConcept, conceptData?.data]);
  const [solutions, setSolutions] = useState<Array<PsOptionType>>([]);
  const [firstSolution, setFirstSolution] =
    useState<PsOptionType>(initSolutionObj);
  const [secondSolution, setSecondSolution] =
    useState<PsOptionType>(initSolutionObj);
  const [solutionsRelation, setSolutionsRelation] =
    useState<string>(initRelation);

  const editSolutionLoader = GetEditProfileItemLoader();

  const loader = GetLoader();
  const aiAnswer = GetAiAnswer();

  const dispatch = useDispatch();

  const solutionDocuments = concept.solutions;
  const key = [firstSolution.value, secondSolution.value].sort().join('');

  const areSolutionsSelected =
    firstSolution.value != '' && secondSolution.value != '';

  useEffect(() => {
    const list: Array<PsOptionType> = solutionDocuments.map(
      (solution: Solution) => {
        teaserMap[`${solution.id}`] = solution.teaser;
        return {
          value: `${solution.id}`,
          label: solution.title || '',
          raw: solution.key || ''
        };
      }
    );
    setSolutions(list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [concept]);

  useEffect(() => {
    if (areSolutionsSelected) {
      if (!Object.prototype.hasOwnProperty.call(generatedRelations, key)) {
        const remainingSolutions = selectedConcept?.solutions?.filter(
          (solution) =>
            solution?.id !== firstSolution?.value &&
            solution?.id !== secondSolution?.value
        );
        const problemSolvedBySolution = selectedConcept?.problems?.filter(
          (problem) =>
            problem?.children?.some(
              (child) =>
                child?.id === firstSolution?.value ||
                child?.id === secondSolution?.value
            )
        );
        dispatch(
          Actions.getRelationPrompt({
            conceptTitle: selectedConcept?.title,
            productTitle: selectedConcept?.problems[0]?.parentProduct?.title,
            firstSolutionTitle: firstSolution?.label,
            secondSolutionTitle: secondSolution?.label,
            remainingSolutions,
            problemSolvedBySolution
          })
        );
      } else {
        setSolutionsRelation(generatedRelations[key].relation);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areSolutionsSelected, firstSolution, secondSolution]);

  useEffect(() => {
    if (prompt) {
      dispatch(Actions.aiAnswerSearch({ prompt }));
      dispatch(Actions.getRelationPromptSuccess(''));
    }
  }, [prompt]);

  useEffect(() => {
    if (aiAnswer) {
      setSolutionsRelation(aiAnswer);
      setGeneratedRelations((prevRelations) => ({
        ...prevRelations,
        [key]: { relation: aiAnswer, isSaved: false }
      }));
      dispatch(Actions.aiAnswerSearchSuccess({ text: '' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aiAnswer]);

  useEffect(() => {
    if (loader) {
      setIsNextStepBlocked(loader);
    } else {
      if (isRelationGenrated() && !isRelationSaved()) {
        setIsFormValid(true);
        setIsNextStepBlocked(isRelationGenrated() && isRelationSaved());
      }
    }
  }, [generatedRelations, solutionsRelation, loader]);

  const onFirstSolutionChange = useCallback((event: ShortEvent) => {
    const solution = {
      value: event.target.value,
      label: event.target.label,
      raw: event.target.rawValue
    };
    setFirstSolution(solution);
  }, []);

  const onSecondSolutionChange = useCallback((event: ShortEvent) => {
    const solution = {
      value: event.target.value,
      label: event.target.label,
      raw: event.target.rawValue
    };
    setSecondSolution(solution);
  }, []);

  const Solutions1 = useMemo(
    () => solutions.filter((option) => option.value !== secondSolution.value),
    [solutions, secondSolution.value]
  );
  const Solutions2 = useMemo(
    () => solutions.filter((option) => option.value !== firstSolution.value),
    [solutions, firstSolution.value]
  );

  const handleReset = useCallback(() => {
    setFirstSolution(initSolutionObj);
    setSecondSolution(initSolutionObj);
    setSolutionsRelation(initRelation);
    setGeneratedRelations({});
  }, []);

  const handleCopyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(solutionsRelation);
  }, [solutionsRelation]);

  const handleSave = useCallback(() => {
    const conceptUpdateObj = updateConceptSolutionsRelationship(concept, {
      solution1: firstSolution.label,
      solution2: secondSolution.label,
      relationship: solutionsRelation
    });
    if (user) {
      if (isConceptActive) {
        dispatch(
          Actions.setLoginUser({
            ...user,
            activeConcept: { ...activeConcept, ...conceptUpdateObj }
          })
        );
        dispatch(Actions.updateConcept(concept.id, conceptUpdateObj));
      } else {
        dispatch(
          Actions.editProfileItem({
            type: Constants.APPLICATIONS,
            id: concept.id,
            profileId: profileId,
            pagination,
            data: conceptUpdateObj
          })
        );
      }
      setGeneratedRelations((prevRelations) => ({
        ...prevRelations,
        [key]: { ...prevRelations[key], isSaved: true }
      }));
    } else {
      dispatch(Actions.openAuthModal(true));
    }
  }, [firstSolution, secondSolution, solutionsRelation, user]);

  useEffect(() => {
    if (isFormValid) {
      setSaveCallback(() => handleSave);
    }
  }, [setSaveCallback, handleSave, isFormValid]);

  const isDisabled = (): boolean =>
    loader || promptLoader || solutionsRelation == initRelation;

  const isRelationGenrated = (): boolean =>
    Object.prototype.hasOwnProperty.call(generatedRelations, key);

  const isRelationSaved = (): boolean => generatedRelations[key].isSaved;

  const handleSolutionsRelationChange = (event) => {
    setSolutionsRelation(event.target.value);
  };

  return (
    <>
      <DropDownsMainBox>
        <DropDownBox>
          <PsFormControl
            placeholder="Choose one solution"
            label="Select Solution"
          >
            <PsSelect
              name={Constants.SOLUTIONS}
              options={Solutions1}
              value={firstSolution.value}
              onChange={onFirstSolutionChange}
              maxMenuHeight={270}
              valueObj={getValueObj(Solutions1, firstSolution.value)}
            />
          </PsFormControl>
        </DropDownBox>
        <RelationIconBox>
          <StyledCompareArrows />
          <StyledTypography>Related to</StyledTypography>
        </RelationIconBox>

        <DropDownBox>
          <PsFormControl
            placeholder="Choose one solution"
            label="Select Solution"
          >
            <PsSelect
              name={Constants.SOLUTIONS}
              options={Solutions2}
              value={secondSolution.value}
              onChange={onSecondSolutionChange}
              maxMenuHeight={270}
              valueObj={getValueObj(Solutions2, secondSolution.value)}
            />
          </PsFormControl>
        </DropDownBox>
      </DropDownsMainBox>

      <RelationsBox>
        {areSolutionsSelected && (
          <StyledBodyTypography lineHeight={1.5}>
            <LinkViewWrapper>
              <LinkView
                fontWeight="600"
                id={firstSolution.raw}
                title={firstSolution.label}
                type={Constants.SOLUTIONS}
                textWidth={250}
              />
            </LinkViewWrapper>{' '}
            relates to{' '}
            <LinkViewWrapper>
              <LinkView
                fontWeight="600"
                id={secondSolution.raw}
                title={secondSolution.label}
                type={Constants.SOLUTIONS}
                textWidth={250}
              />
            </LinkViewWrapper>{' '}
            in the context of <b>{concept.title} </b>
            by:
          </StyledBodyTypography>
        )}
        <StyledBodyFieldText
          onChange={handleSolutionsRelationChange}
          rows={4}
          multiline
          value={
            loader || promptLoader
              ? 'AI is generating relationship......'
              : solutionsRelation
          }
        />
        <ButtonsBox>
          <StyledIconButton
            disabled={isDisabled()}
            onClick={handleReset}
            size="small"
          >
            <Tooltip title={Constants.RESET} placement="bottom">
              <RotateLeft />
            </Tooltip>
          </StyledIconButton>
          <StyledIconButton
            disabled={
              editSolutionLoader ||
              isDisabled() ||
              (isRelationGenrated() && isRelationSaved())
            }
            size="small"
          >
            <Tooltip
              onClick={handleSave}
              title={Constants.SAVE}
              placement="bottom"
            >
              <Save />
            </Tooltip>
          </StyledIconButton>
          <StyledIconButton
            onClick={handleCopyToClipboard}
            disabled={isDisabled()}
            size="small"
          >
            <Tooltip title={Constants.COPY} placement="bottom">
              <ContentCopy />
            </Tooltip>
          </StyledIconButton>
        </ButtonsBox>
      </RelationsBox>
    </>
  );
};
