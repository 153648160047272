import {
  Box,
  Button,
  Card,
  Grid,
  styled,
  Popover,
  Typography
} from '@mui/material';
import { colorPalette } from 'theme';

export const StyledPopover = styled(Popover)({
  ' & .MuiPopover-paper': {
    borderRadius: '6px'
  }
});

export const PopoverContent = styled(Box)({
  padding: '.9375rem',
  width: '32vw',
  paddingBottom: '.9375rem',
  '@media (max-width:900px)': {
    width: '90vw'
  }
});

export const StyledNotificationCard = styled(Card)({
  border: 'none',
  borderRadius: 0,
  boxShadow: 'none',
  margin: 0,
  marginTop: '20px'
});

export const NoNotificationsTypography = styled(Typography)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center'
});

export const MainContainerGrid = styled(Grid)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between'
});

export const FirstItemGrid = styled(Grid)({
  alignItems: 'center',
  display: 'flex',
  paddingLeft: '10px'
});

export const SecondItemGrid = styled(Grid)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center'
});

export const ThirdItemGrid = styled(Grid)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-end'
});

export const NotificationsButton = styled(Button)({
  borderRadius: '4px',
  color: 'white',
  fontSize: '12px',
  fontWeight: 600,
  margin: '10px 10px 0px 0px',
  textTransform: 'capitalize',
  whiteSpace: 'nowrap',
  ':hover': {
    boxShadow: `0rem 0.0625rem 0.7rem 0rem ${colorPalette.boxShadowDarkGrey}`
  }
});

export const IdeaCoinsButton = styled(Button)({
  backgroundColor: colorPalette.veryLightPurple,
  borderRadius: '4px',
  color: colorPalette.black,
  fontSize: '12px',
  fontWeight: 500,
  margin: '10px 10px 0px 0px',
  pointerEvents: 'none',
  textTransform: 'capitalize',
  variant: 'contained',
  whiteSpace: 'nowrap',
  ':hover': {
    cursor: 'unset'
  }
});
