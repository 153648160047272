import React, { useRef, useEffect } from 'react';
import { Box } from '@mui/material';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

interface ICustomScrollbar {
  children: React.ReactNode;
  className?: string;
}

export const CustomScrollbar = ({ className, children }: ICustomScrollbar) => {
  const scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef.current) {
      const ps = new PerfectScrollbar(scrollRef.current, {
        wheelSpeed: 2,
        wheelPropagation: true,
        minScrollbarLength: 20
      });

      return () => {
        ps.destroy();
      };
    }
  }, []);

  return (
    <Box className={`custom-scrollbar ${className}`} ref={scrollRef}>
      {children}
    </Box>
  );
};
