import React, { useState, useEffect, useContext, useCallback } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { PsButton } from 'components/common/PsButton';
import { PsLogo } from 'components/common/PsLogo';
import { PsNFT } from 'components/common/PsNFT';
import { AvaImage } from 'components/card/AvaImage';
import { Pagination } from 'components/Pagination';
import { Application } from 'components/CardApplication';
import { DataContext } from 'contexts/DataContext';
import { getDateStr } from '../../helpers';
import { PsTheme } from '../../theme';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

type NftInfo = Application;

type ClassKey =
  | 'root'
  | 'flexContainer'
  | 'ideaContainer'
  | 'ideaContent'
  | 'logoContainer'
  | 'user'
  | 'userImage'
  | 'tagButton'
  | 'pagination'
  | 'noResult'
  | 'loading';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {},
    flexContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '56px',
      maxWidth: '509px'
    },
    ideaContainer: {
      display: 'flex',
      gap: '64px'
    },
    logoContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      '& svg:last-child': {
        marginLeft: -3
      }
    },
    ideaContent: {
      display: 'flex',
      flexDirection: 'column',
      '& .title a': {
        textDecoration: 'none',
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '22px',
        color: '#632DDD'
      },
      '& .timeAndUserContainer': {
        display: 'flex',
        alignItems: 'center',
        gap: '15px',
        '& p': {
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '15px',
          color: '#66788E'
        },
        '& a': {
          color: '#632DDD'
        },
        [psTheme.breakpoints.down('xs')]: {
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '0px',
          margin: '0 0 10px '
        }
      },
      '& .tags': {
        display: 'flex',
        gap: '8px',
        [psTheme.breakpoints.down('xs')]: {
          flexDirection: 'column'
        }
      }
    },
    user: {
      display: 'flex',
      alignItems: 'center',
      gap: '3',
      '& a': {
        marginLeft: 5,
        textDecoration: 'none'
      }
    },
    userImage: {
      width: 40,
      height: 40,
      '& img': {
        borderRadius: 5
      }
    },
    tagButton: {
      background: '#D9F1E5',
      borderRadius: '200px',
      padding: '5px 10px',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '16px',
      color: '#07A854',
      minHeight: 0,
      whiteSpace: 'pre'
    },
    pagination: {
      paddingTop: 15
    },
    loading: {
      lineHeight: '30px',
      textAlign: 'center'
    },
    noResult: {
      lineHeight: '30px',
      textAlign: 'center'
    }
  };
});

type RelatedNftsProps = {
  className?: string;
  noResultContent?: React.ReactNode;
  solutionId?: string | number;
  productId?: string | number;
};

const RelatedNftsView = ({
  className,
  noResultContent,
  solutionId,
  productId
}: RelatedNftsProps) => {
  const { dataProvider } = useContext(DataContext);

  const [list, setList] = useState<Array<NftInfo>>([]);
  const [page, setPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const { classes } = useStyles();
  const perPage = 5;

  useEffect(() => {
    if (!solutionId && !productId) {
      setList([]);
      setTotalPages(0);
      setLoading(false);
      return;
    }

    let resource = '';
    let itemId: string | number = '';
    if (solutionId) {
      resource = 'solutions';
      itemId = solutionId;
    }
    if (productId) {
      resource = 'company-products';
      itemId = productId;
    }

    dataProvider
      .getRelatedNfts<NftInfo>(resource, itemId, {
        pagination: { page: page + 1, perPage }
      })
      .then(({ data, total }) => {
        setList(data);
        setTotalPages(Math.ceil(total / perPage));
      })
      .catch((err) => {
        setList([]);
        setTotalPages(0);
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [solutionId, productId, page]);

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const onTagClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { dataset = {} } = e.currentTarget as HTMLButtonElement;
    if (dataset.name) {
      window.open(`/tags/${dataset.name}`, '_blank');
    }
  };

  return (
    <div className={`${classes.root} ${className}`}>
      {loading ? (
        <Typography className={classes.loading}>Loading...</Typography>
      ) : null}
      {!loading && !list.length ? (
        <Typography className={classes.noResult}>
          {noResultContent ? noResultContent : 'No results'}
        </Typography>
      ) : null}
      <div className={classes.flexContainer}>
        {list.map((item) => {
          const url = item.nftTransactionUrl || '';
          const ownerUrl = `/profiles/${item.ownerKey}`;
          return (
            <div key={item.id} className={classes.ideaContainer}>
              <div className={classes.logoContainer}>
                <PsLogo color="black" size={30} small></PsLogo>
                <PsNFT color="black" size={30} />
              </div>

              <div className={classes.ideaContent}>
                <div className="title">
                  <Link to={url}>
                    <a target="_blank">{item.title}</a>
                  </Link>
                </div>
                <div className="timeAndUserContainer">
                  <Typography>
                    Added {getDateStr(item.createdAt)} | Created by{' '}
                  </Typography>
                  <div className={classes.user}>
                    <AvaImage
                      className={classes.userImage}
                      image={item.ownerInfo?.picture}
                      imageAlt={item.ownerInfo?.username}
                    />
                    <Link to={ownerUrl}>
                      <a target="_blank">
                        <Typography>{item.ownerInfo?.username}</Typography>
                      </a>
                    </Link>
                  </div>
                </div>
                <div className="tags">
                  {(item.tagsInfo || []).map((tagInfo) => {
                    return (
                      <PsButton
                        key={tagInfo.key}
                        className={classes.tagButton}
                        color="secondary"
                        data-name={tagInfo.key}
                        onClick={onTagClick}
                      >
                        {tagInfo.name}
                      </PsButton>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Pagination
        className={classes.pagination}
        total={totalPages}
        active={page}
        onChange={onPageChange}
      />
    </div>
  );
};

export const RelatedNfts = styled(RelatedNftsView)({});

export default RelatedNfts;
