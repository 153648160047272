import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState
} from 'react';
import { useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress } from '@mui/material';
import Config from 'config/config';
import IdeaCoinsABI from 'contract/IdeaCoins.json';
import { Wallet, ethers } from 'ethers';
import {
  CHAINS_CONFIG,
  estimateGasForApproval,
  estimateGasForMaticTransfer,
  estimateGasForTransferFrom,
  sepolia
} from 'helpers/blockchain';
import { TxApprovalModal } from 'modals/TxApprovalModal';
import Actions from 'redux-state/actions';
import { GetTxApprovalModalObj, GetUser } from 'redux-state/selectors';
import {
  ASSET_TYPES,
  Constants,
  ERRORS,
  TRANSACTION_TYPE,
  VARIANT
} from 'utilities/constants';
import { BaseModal } from '../Common/BaseModal';
import { ContentSection } from '../Common/ContentSection';
import { HeaderSection } from '../Common/HeaderSection';
import {
  CloseButton,
  SendButton,
  StatusText,
  StyledLink,
  StyledParagraph,
  StyledTextField,
  TitleText
} from './styledComponents';

const provider = new ethers.providers.JsonRpcProvider(sepolia.rpcUrl);
const contract = new ethers.Contract(
  Config.IDEACOIN_CONTRACT_ADDRESS,
  IdeaCoinsABI,
  provider
);

interface TransferModalProps {
  balance?: number;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  triggerRefresh?: Dispatch<SetStateAction<number>>;
  type: string;
}

const NETWORK_STATUS = {
  PENDING: 'pending',
  COMPLETE: 'complete',
  ERROR: 'error'
};

export const TransferModal: React.FC<TransferModalProps> = ({
  balance,
  setOpen,
  triggerRefresh,
  type
}) => {
  const dispatch = useDispatch();

  const user = GetUser();
  const txApprovalModalObj = GetTxApprovalModalObj();

  const [destAddress, setDestAddress] = useState('');
  const [amount, setAmount] = useState<number | null>(null);
  const [transactionType, setTransactionType] = useState<string>(
    type == ASSET_TYPES.IDEACOINS
      ? TRANSACTION_TYPE.APPROVAL
      : TRANSACTION_TYPE.SEND
  );

  const [fieldsValidity, setFieldsValidity] = useState({
    destAddress: {
      isValid: true,
      error: ''
    },
    amount: {
      isValid: true,
      error: ''
    }
  });
  const [networkResponse, setNetworkResponse] = useState<{
    status: string;
    message: string | React.ReactElement;
  }>({
    status: null,
    message: ''
  });

  const isLoading = useMemo(
    () => networkResponse.status === NETWORK_STATUS.PENDING,
    [networkResponse.status]
  );

  const clearFields = useCallback(() => {
    setDestAddress('');
    setAmount(0);
    if (type == ASSET_TYPES.IDEACOINS) {
      setTransactionType(TRANSACTION_TYPE.APPROVAL);
    }
  }, [type]);

  const handledestAddressChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => setDestAddress(event.target.value);

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const newAmount = parseFloat(value);
    setAmount(newAmount);
  };

  const validateInputs = useCallback(() => {
    let destAddressError = '',
      amountError = '';
    const isAddressValid = (() => {
      if (!ethers.utils.isAddress(destAddress)) {
        destAddressError = ERRORS.INVALID_ADDRESS;
        return false;
      }
      return true;
    })();
    const isAmountValid = (() => {
      if (isNaN(amount) || amount <= 0) {
        amountError = ERRORS.INVALID_AMOUNT;
        return false;
      }
      if (amount > balance) {
        amountError = ERRORS.INSUFFICIENT_BALANCE;
        return false;
      }
      return true;
    })();
    setFieldsValidity({
      destAddress: {
        isValid: isAddressValid,
        error: destAddressError
      },
      amount: {
        isValid: isAmountValid,
        error: amountError
      }
    });
    return isAddressValid && isAmountValid;
  }, [amount, balance, destAddress]);

  const sendToken = useCallback(
    async (amount: number, to: string, privateKey: string) => {
      const chain = CHAINS_CONFIG[sepolia.chainId];
      const provider = new ethers.providers.JsonRpcProvider(chain.rpcUrl);
      const wallet: Wallet = new ethers.Wallet(privateKey, provider);
      const tx = {
        to,
        value: ethers.utils.parseEther(amount.toString())
      };
      const transactionResponse = await wallet.sendTransaction(tx);
      return transactionResponse;
    },
    []
  );

  const calculateGasFee = useCallback(
    async (type) => {
      try {
        if (type == ASSET_TYPES.IDEACOINS) {
          if (transactionType == TRANSACTION_TYPE.APPROVAL) {
            const gas = await estimateGasForApproval(
              user.walletAddress,
              ethers.utils.parseUnits(amount.toString(), 18)
            );
            return gas;
          }
          if (transactionType == TRANSACTION_TYPE.SEND) {
            const gas = await estimateGasForTransferFrom(
              user.walletAddress,
              destAddress,
              ethers.utils.parseUnits(amount.toString(), 18)
            );
            return gas;
          }
        }
        if (type == ASSET_TYPES.MATIC) {
          const gas = await estimateGasForMaticTransfer(
            user.walletAddress,
            amount
          );
          return gas;
        }
      } catch (error) {
        console.log(error);
      }
    },
    [amount, destAddress, transactionType, user.walletAddress]
  );

  const onSendClick = useCallback(async () => {
    if (validateInputs()) {
      setNetworkResponse({
        status: NETWORK_STATUS.PENDING,
        message: ''
      });
      const gasFee = await calculateGasFee(type);
      if (gasFee) {
        dispatch(
          Actions.openTxApprovalModal({
            txApprovalModalObj: {
              type,
              open: true,
              gasFee
            }
          })
        );
      }
    }
  }, [calculateGasFee, dispatch, type, validateInputs]);

  const makeTransaction = () => {
    dispatch(Actions.setTokenURI({ tokenURI: '' }));
    dispatch(
      Actions.openTxApprovalModal({
        txApprovalModalObj: {
          type: '',
          open: false,
          gasFee: 0
        }
      })
    );
    if (validateInputs()) {
      transferFunctions[type]();
    }
  };

  const handleTransactionResponse = useCallback(
    (receipt) => {
      clearFields();
      if (receipt && receipt.status === 1) {
        setNetworkResponse({
          status: NETWORK_STATUS.COMPLETE,
          message: (
            <StyledParagraph>
              {Constants.TRANSFER_COMPLETE}{' '}
              <StyledLink
                href={`${sepolia.blockExplorerUrl}/${Constants.TX}/${receipt.transactionHash}`}
                target={Constants._BLANK}
                rel={Constants.NO_OPENER_NO_REFFERRER}
              >
                {Constants.VIEW_TRANSACTION}
              </StyledLink>
            </StyledParagraph>
          )
        });
        triggerRefresh((prev) => prev + 1);
      } else {
        setNetworkResponse({
          status: NETWORK_STATUS.ERROR,
          message: JSON.stringify(receipt)
        });
      }
    },
    [clearFields, triggerRefresh]
  );

  const transferMatic = useCallback(async () => {
    try {
      const transactionResponse = await sendToken(
        amount,
        destAddress,
        user.privateKey
      );
      setNetworkResponse({
        status: NETWORK_STATUS.PENDING,
        message: Constants.TX_CONFIRMATION_MSG
      });
      const confirmedTransaction = await transactionResponse.wait(2);
      handleTransactionResponse(confirmedTransaction);
    } catch (error) {
      console.error({ error });
      setNetworkResponse({
        status: NETWORK_STATUS.ERROR,
        message: error.reason || JSON.stringify(error.message)
      });
    }
  }, [
    amount,
    destAddress,
    handleTransactionResponse,
    sendToken,
    user.privateKey
  ]);

  const transferIdeaCoin = useCallback(async () => {
    try {
      const signer = new ethers.Wallet(user.privateKey, provider);
      const contractWithSigner = contract.connect(signer);
      const amountToApprove = ethers.utils.parseUnits(amount.toString(), 18);

      let transactionResponse;
      if (transactionType == TRANSACTION_TYPE.APPROVAL) {
        transactionResponse = await contractWithSigner.approve(
          user.walletAddress,
          amountToApprove
        );
        await transactionResponse.wait();
        setTransactionType(TRANSACTION_TYPE.SEND);
        onSendClick();
      } else if (transactionType == TRANSACTION_TYPE.SEND) {
        transactionResponse = await contractWithSigner.transferFrom(
          user.walletAddress,
          destAddress,
          amountToApprove
        );
        const transferReceipt = await transactionResponse.wait(2);
        handleTransactionResponse(transferReceipt);
      }
    } catch (error) {
      setNetworkResponse({
        status: NETWORK_STATUS.ERROR,
        message: error.reason || JSON.stringify(error)
      });
    }
  }, [
    amount,
    destAddress,
    handleTransactionResponse,
    onSendClick,
    transactionType,
    user.privateKey,
    user.walletAddress
  ]);

  const transferFunctions = useMemo(() => {
    return {
      [ASSET_TYPES.MATIC]: transferMatic,
      [ASSET_TYPES.IDEACOINS]: transferIdeaCoin
    };
  }, [transferIdeaCoin, transferMatic]);

  const handleClose = useCallback(() => {
    if (isLoading) return;
    setOpen(false);
  }, [isLoading, setOpen]);

  const txReject = useCallback(() => {
    setNetworkResponse({
      status: null,
      message: ''
    });
    clearFields();
  }, [clearFields]);

  const getStatusMessage = (status, message) => {
    const statusMap = {
      [NETWORK_STATUS.PENDING]: '',
      [NETWORK_STATUS.COMPLETE]: message,
      [NETWORK_STATUS.ERROR]: `${Constants.ERROR}: ${message}`
    };
    return statusMap[status] || '';
  };

  const message = getStatusMessage(
    networkResponse.status,
    networkResponse.message
  );

  const showTxApprovalModal =
    txApprovalModalObj?.open &&
    (txApprovalModalObj?.type == ASSET_TYPES.IDEACOINS || ASSET_TYPES.NFT);

  return (
    <BaseModal open={true} onClose={handleClose} fullWidth={false}>
      <HeaderSection>
        <CloseButton onClick={handleClose}>
          <CloseIcon />
        </CloseButton>
        <TitleText>{`${Constants.C_SEND} ${type}`}</TitleText>
      </HeaderSection>
      <ContentSection>
        <StyledTextField
          label={Constants.DESTINATION_ADDRESS}
          variant={VARIANT.OUTLINED}
          value={destAddress}
          onChange={handledestAddressChange}
          error={!fieldsValidity.destAddress.isValid}
          helperText={fieldsValidity.destAddress.error}
          disabled={networkResponse.status === NETWORK_STATUS.PENDING}
        />
        <StyledTextField
          label={Constants.AMOUNT}
          variant={VARIANT.OUTLINED}
          value={amount}
          onChange={handleAmountChange}
          InputProps={{ inputProps: { min: 0 } }}
          type={VARIANT.NUMBER}
          error={!fieldsValidity.amount.isValid}
          helperText={fieldsValidity.amount.error}
          disabled={networkResponse.status === NETWORK_STATUS.PENDING}
        />
        <SendButton
          variant={VARIANT.CONTAINED}
          onClick={onSendClick}
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={28} /> : Constants.C_SEND}
        </SendButton>
        {networkResponse.status && (
          <StatusText
            color={
              networkResponse.status === NETWORK_STATUS.ERROR
                ? VARIANT.ERROR
                : undefined
            }
          >
            {message}
          </StatusText>
        )}
      </ContentSection>
      {showTxApprovalModal && (
        <TxApprovalModal
          amount={amount}
          destAddress={destAddress}
          from={user.walletAddress}
          gasFee={txApprovalModalObj.gasFee}
          onConfirm={makeTransaction}
          onReject={txReject}
          open={txApprovalModalObj.open}
          transactionType={transactionType}
          type={txApprovalModalObj.type}
        />
      )}
    </BaseModal>
  );
};
