import React, { FunctionComponent } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { getDateStr } from '../../helpers';
import { PsTheme } from '../../theme';
import classnames from 'classnames';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      display: 'flex',
      fontSize: 16,
      lineHeight: '16px',
      letterSpacing: '0.01em',
      color: 'rgba(3,31,70,.7)',
      fontFamily: psTheme.typography.fontFamily,
      paddingRight: 18,
      marginTop: 'auto',
      paddingTop: '16px',
      [psTheme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start'
      }
    }
  };
});

type CardOwnerProps = {
  className?: string;
  createdAt?: string;
};

const CardTimestampView: FunctionComponent<CardOwnerProps> = ({
  className = '',
  createdAt
}) => {
  const dateString = getDateStr(createdAt);
  const { classes } = useStyles();

  return (
    <div className={classnames(classes.root, { [className]: className })}>
      Added {dateString}
    </div>
  );
};

export const CardTimestamp = styled(CardTimestampView)({});
