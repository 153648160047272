import { ChooseTypeStep } from './steps/ChooseTypeStep';
import { ChooseSubTypeStep } from './steps/ChooseSubTypeStep';

import ProblemName, {
  defaultProblemTitle
} from 'modals/AddNewProblem/steps/ProblemName';
import ProblemTagsStep from 'modals/AddNewProblem/steps/ProblemTagsStep';
import FinilizeProblemStep from 'modals/AddNewProblem/steps/FinilizeProblemStep';

import SolutionName, {
  defaultSolutionTitle
} from 'modals/AddNewSolution/steps/SolutionName';
import SolutionTagsStep from 'modals/AddNewSolution/steps/SolutionTagsStep';
import FinilizeSolutionStep from 'modals/AddNewSolution/steps/FinilizeSolutionStep';

export { defaultProblemTitle, defaultSolutionTitle };

import {
  ValidateFormType,
  FormStepConfig,
  ImproveApplicationSteps,
  AddProblemSteps,
  AddSolutionSteps
} from 'contexts/ModalDataContext';
import { isTitleValid } from '../../helpers';

export const stepName = {
  CATEGORY_SELECT: 'categorySelect',
  PROBLEM_START: 'problemStart',
  PROBLEM_END: 'problemEnd',
  SOLUTION_START: 'solutionStart',
  SOLUTION_END: 'solutionEnd'
};

export const IMPROVE_APPLICATION_STEPS_CONFIG: {
  [name: string]: FormStepConfig;
} = {
  [ImproveApplicationSteps.ChooseTypeStep]: {
    Component: ChooseTypeStep,
    nextStep: ImproveApplicationSteps.ChooseSubTypeStep,
    isNextStepBlocked: (data) => {
      return !data.description || !data.selectApplication;
    },
    validate: (data): ValidateFormType => {
      if (!data.description || !data.selectApplication) {
        return {
          success: false,
          errors: {
            description: 'required'
          }
        };
      }
      return { success: true };
    },
    backButtonTitle: 'Cancel'
  },
  [ImproveApplicationSteps.ChooseSubTypeStep]: {
    Component: ChooseSubTypeStep,
    name: stepName.CATEGORY_SELECT,
    nextStep: AddProblemSteps.ProblemName,
    isNextStepBlocked: (data) => {
      return !data.productId || !data.selectApplication;
    },
    validate: (data): ValidateFormType => {
      if (!data.productId || !data.selectApplication) {
        return {
          success: false,
          errors: {
            productId: 'required'
          }
        };
      }
      return { success: true };
    }
  },
  [AddProblemSteps.ProblemName]: {
    Component: ProblemName,
    nextStep: AddProblemSteps.ProblemTags,
    name: stepName.PROBLEM_START,
    modalTitle: 'Add New Problem',
    isNextStepBlocked: (data) => {
      return !data.titleProblem;
    },
    validate: (data): ValidateFormType => {
      if (!data.titleProblem) {
        return { success: false, errors: { titleProblem: 'Title required' } };
      }
      if (
        data.titleProblem &&
        !isTitleValid(data.titleProblem, defaultProblemTitle)
      ) {
        return {
          success: false,
          errors: {
            titleProblem: `Title should starts with "${defaultProblemTitle}"`
          }
        };
      }
      return { success: true };
    }
  },
  [AddProblemSteps.ProblemTags]: {
    Component: ProblemTagsStep,
    nextStep: AddProblemSteps.FinilizeProblem,
    modalTitle: 'Add New Problem',
    isNextStepBlocked: (data) => {
      if (data.tagsProblem.length === 0) return true;

      return false;
    },
    validate: (data): ValidateFormType => {
      if (data.tagsProblem) {
        return { success: true };
      }
      return { success: false, errors: { tags: 'required' } };
    }
  },
  [AddProblemSteps.FinilizeProblem]: {
    Component: FinilizeProblemStep,
    name: stepName.PROBLEM_END,
    nextStep: AddSolutionSteps.SolutionName,
    modalTitle: 'Add New Problem',
    isNextStepBlocked: (data) => {
      return false;
    },
    validate: (data): ValidateFormType => {
      return { success: true };
    }
  },
  [AddSolutionSteps.SolutionName]: {
    Component: SolutionName,
    nextStep: AddSolutionSteps.SolutionTags,
    name: stepName.SOLUTION_START,
    modalTitle: 'Add New Solution',
    isNextStepBlocked: (data) => {
      if (
        !data.titleSolution ||
        (!data.parentProblem && !data.parentSolution)
      ) {
        return true;
      }
      if (data.finalizeType === 'public') {
        return !data.termsAgree;
      }
      return false;
    },
    validate: (data): ValidateFormType => {
      if (!data.titleSolution) {
        return { success: false, errors: { titleSolution: 'Title required' } };
      }
      if (
        data.titleSolution &&
        !isTitleValid(data.titleSolution, defaultSolutionTitle)
      ) {
        return {
          success: false,
          errors: {
            titleSolution: `Title should starts with "${defaultSolutionTitle}"`
          }
        };
      }
      if (data.finalizeType === 'public' && !data.termsAgree) {
        return {
          success: false,
          errors: {
            termsAgree: 'required'
          }
        };
      }
      if (!data.titleSolution && !data.parentProblem && !data.parentSolution) {
        return {
          success: false,
          errors: {
            title: 'required',
            parentProblem: 'required',
            parentSolution: 'required'
          }
        };
      } else if (!data.parentProblem && !data.parentSolution) {
        return {
          success: false,
          errors: { parentProblem: 'required', parentSolution: 'required' }
        };
      }
      return { success: true };
    }
  },
  [AddSolutionSteps.SolutionTags]: {
    Component: SolutionTagsStep,
    nextStep: AddSolutionSteps.FinilizeSolution,
    modalTitle: 'Add New Solution',
    isNextStepBlocked: (data) => {
      if (data.tagsSolution.length === 0) return true;

      return false;
    },
    validate: (data): ValidateFormType => {
      if (data.tagsSolution) {
        return { success: true };
      }
      return { success: false, errors: { tags: 'required' } };
    }
  },
  [AddSolutionSteps.FinilizeSolution]: {
    Component: FinilizeSolutionStep,
    name: stepName.SOLUTION_END,
    isFinalStep: true,
    modalTitle: 'Add New Solution',
    isNextStepBlocked: (data) => {
      return false;
    },
    validate: (data): ValidateFormType => {
      return { success: true };
    }
  }
};
