import React from 'react';
import { styled, useTheme } from '@mui/material/styles';

import coinImage from 'assets/images/coin.svg';
import { makeStyles } from 'tss-react/mui';
import { PsTheme } from 'theme';
import { Constants } from 'utilities/constants';
import { StyledAvatar } from './styledComponents';

type ClassKey = 'root' | 'image' | 'count' | 'name';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 15,
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 18,
      lineHeight: '22px',
      letterSpacing: '-0.02em',
      fontFeatureSettings: '"tnum" on, "lnum" on',
      color: '#031f46',
      opacity: '0.9'
    },
    image: {
      marginRight: 6
    },
    count: {
      fontWeight: 800,
      fontSize: 18,
      lineHeight: '22px',
      color: psTheme.palette.secondary.main,
      marginRight: 6
    },
    name: {
      color: psTheme.palette.secondary.main,
      marginRight: 6
    }
  };
});

type CoinsSmallProps = React.ComponentPropsWithoutRef<'div'> & {
  afterText?: string;
  beforeText?: string;
  children?: React.ReactNode;
  className?: string;
  count?: number;
  icon?: string;
};

const CoinsSmallView = ({
  afterText = 'Earned',
  beforeText,
  children,
  className,
  count,
  icon,
  ...rest
}: CoinsSmallProps) => {
  const { classes } = useStyles();
  let rootClassName = classes.root;
  if (className) {
    rootClassName += ' ' + className;
  }

  return (
    <div className={rootClassName} {...rest}>
      {icon ? (
        <StyledAvatar src={icon} alt={Constants.PROFILE} />
      ) : (
        <img
          className={classes.image}
          src={coinImage}
          alt={Constants.COIN_ICON}
        />
      )}
      {beforeText ? <span className={classes.name}>{beforeText}</span> : null}
      <span className={classes.count}>{count}</span>
      <span className={classes.name}>IdeaPoints</span>
      {afterText}
    </div>
  );
};

export const CoinsSmall = styled(CoinsSmallView)({});

export default CoinsSmall;
