import React from 'react';
export const ShareIcon = ({
  size = 24,
  color = '#677890'
}: {
  size?: number;
  color?: string;
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 8C19.6569 8 21 6.65685 21 5C21 3.34315 19.6569 2 18 2C16.3431 2 15 3.34315 15 5C15 6.65685 16.3431 8 18 8Z"
        stroke={color}
        strokeWidth="1.76842"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 15C7.65685 15 9 13.6569 9 12C9 10.3431 7.65685 9 6 9C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15Z"
        stroke={color}
        strokeWidth="1.76842"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 22C19.6569 22 21 20.6569 21 19C21 17.3431 19.6569 16 18 16C16.3431 16 15 17.3431 15 19C15 20.6569 16.3431 22 18 22Z"
        stroke={color}
        strokeWidth="1.76842"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.59082 13.5098L15.4208 17.4898"
        stroke={color}
        strokeWidth="1.76842"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4108 6.50977L8.59082 10.4898"
        stroke={color}
        strokeWidth="1.76842"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ShareIcon;
