import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useDispatch } from 'react-redux';
import { DataContext } from 'contexts/DataContext';
import dataProvider from 'dataPrvider';
import Actions from 'redux-state/actions';
import { Constants } from 'utilities/constants';
import {
  MarketInfo,
  MarketItem,
  MarketItemDetailed,
  NFT_NETWORK,
  WALLET_NETWORK,
  WalletError,
  WalletProvider,
  WalletType,
  chain,
  chainIds,
  chainNames,
  walletProvider
} from '../walletProvider';
import AuthContext from './AuthContext';

export {
  NFT_NETWORK,
  WALLET_NETWORK,
  WalletError,
  WalletType,
  chain,
  chainIds,
  chainNames
};

export type { MarketInfo, MarketItem, MarketItemDetailed };

export type WalletContextType = {
  walletProvider: WalletProvider;
  marketInfo?: MarketInfo;
  setMarketInfo: (val: MarketInfo) => void;
  magicLoading: boolean;
  magicConnected: boolean;
  magicAddress: string;
  magicBalance: number;
  checkMagicConnect: () => Promise<boolean>;
};

const defaultContext = {
  walletProvider,
  marketInfo: undefined,
  setMarketInfo: () => ({}),
  magicLoading: false,
  magicConnected: false,
  magicAddress: '',
  magicBalance: 0,
  checkMagicConnect: () => Promise.resolve(true)
};

export const WalletContext = createContext<WalletContextType>(defaultContext);

type Props = {
  children: React.ReactElement;
  walletProvider: WalletProvider;
};

export const WalletContextProvider = ({ walletProvider, children }: Props) => {
  const { setAlertContent } = useContext(DataContext);
  const { user } = useContext(AuthContext);
  const dispatch = useDispatch();

  const [marketInfo, setMarketInfo] = useState<MarketInfo | undefined>(
    undefined
  );

  const [magicLoading, setMagicLoading] = useState(true);
  const [magicConnected, setMagicConnected] = useState(false);
  const [magicAddress, setMagicAddress] = useState('');
  const [magicBalance, setMagicBalance] = useState(0);

  useEffect(() => {
    walletProvider.magicOn((connected) => {
      setMagicConnected(connected);

      if (connected) {
        walletProvider
          .magicGetInfo()
          .then((info) => {
            setMagicAddress(info?.address || '');
          })
          .finally(() => {
            setMagicLoading(false);
          });
      } else {
        setMagicLoading(false);
      }
    });

    return () => {
      walletProvider.magicOff();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setMagicConnected, setMagicLoading, setMagicAddress]);

  const checkMagicConnect = useCallback(async () => {
    if (magicConnected) {
      return true;
    }
    try {
      await walletProvider.magicConnect();
      return true;
    } catch (err) {
      const errorText = <>Can not connect to the wallet</>;
      setAlertContent(errorText);
      throw err;
    }
  }, [walletProvider, magicConnected, setAlertContent]);

  useEffect(() => {
    const updateUser = async () => {
      await dataProvider.update(Constants.PROFILES, {
        id: user.id,
        data: { walletAddress: magicAddress }
      });
      dispatch(Actions.getProfile(user.id));
    };
    if (user && !user.walletAddress && magicAddress) {
      updateUser();
    }
  }, [user, magicAddress]);

  useEffect(() => {
    if (user) {
      dispatch(Actions.setLoginUser(user));
    }
  }, [user]);

  return (
    <WalletContext.Provider
      value={{
        walletProvider,
        marketInfo,
        setMarketInfo,
        magicLoading,
        magicConnected,
        magicAddress,
        magicBalance,
        checkMagicConnect
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export const WalletContextConsumer = WalletContext.Consumer;

export default WalletContext;
