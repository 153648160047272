import React, { useContext } from 'react';
import { PsFormControl } from 'components/common/PsFormControl';
import { PsInput } from 'components/common/PsInput';
import { ImageField } from 'components/ImageField';
import { ModalDataContext } from 'contexts/ModalDataContext';
import { defaultSolutionTitle } from '../config';
import styles from 'modals/ModalWindow.module.scss';

const FinilizeSolutionStep: React.FC = () => {
  const { values, handleInputChange } = useContext(ModalDataContext);

  const { descriptionSolution, titleSolution } = values;
  const finalTitleSolution = titleSolution.slice(4);
  let aiText = '';
  if (titleSolution) {
    aiText = titleSolution.slice(defaultSolutionTitle.length);
  }

  return (
    <div className={styles.stepWrapper}>
      <PsFormControl
        placeholder="The solution is described as..."
        labelInfo={true}
        label={
          <>
            Describe Your <b>{finalTitleSolution}</b>
          </>
        }
        helperText={
          <>
            Example: the solution is described as{' '}
            <b>a channeling mechanism for flux</b>
          </>
        }
      >
        <PsInput
          multiline
          name="descriptionSolution"
          onChange={handleInputChange}
          rows={4}
          value={descriptionSolution}
        />
      </PsFormControl>

      <ImageField
        name="filesSolution"
        value={values.filesSolution}
        onChange={handleInputChange}
        aiText={aiText}
        supportDraw
      />
    </div>
  );
};

export default FinilizeSolutionStep;
