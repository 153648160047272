import React, { useEffect, useState } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { showUserIdeaBalance } from 'helpers/blockchain';
import { ERRORS } from 'utilities/constants';

interface IdeaCoinsProps {
  walletAddress: string;
}

const IdeaCoins: React.FC<IdeaCoinsProps> = ({ walletAddress }) => {
  const [balance, setBalance] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    if (walletAddress) {
      showUserIdeaBalance(walletAddress)
        .then((balance) => {
          setBalance(balance);
        })
        .catch((error) => {
          console.error(ERRORS.GET_IDEA_COINS, error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setBalance(0);
      setLoading(false);
    }
  }, []);

  return loading ? (
    <CircularProgress size="1.375rem" />
  ) : (
    <Typography>{balance}</Typography>
  );
};

export default IdeaCoins;
