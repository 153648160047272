import React, { useContext, useEffect, useState, useRef } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import Autosuggest from 'react-autosuggest';
import { PsButton } from 'components/common/PsButton';
import { PsOptionType, PsSelect } from 'components/common/PsSelect';
import { PsSelectedList } from 'components/common/PsSelectedList';
import { ShortEvent } from 'components/common/PsFormControl';
import { Tag } from 'components/CardTag';
import { SearchIcon } from 'assets/icons';
import { DataContext } from 'contexts/DataContext';
import { getQuery } from '../../helpers';
import { PsTheme } from '../../theme';
import Typography from '@mui/material/Typography';
import useRouter from 'hooks/useRouter';
import { makeStyles } from 'tss-react/mui';
import { LinearProgress } from '@mui/material';

type ClassKey = 'root' | 'searchRow' | 'searchButton' | 'tags' | 'tagsList';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      marginTop: 10,
      fontFamily: psTheme.typography.fontFamily,
      fontSize: 15
    },
    searchRow: {
      position: 'relative',
      margin: '48px 0',
      display: 'flex',
      alignItems: 'center',
      zIndex: 100
    },
    searchButton: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      height: 54,
      width: 210,
      fontSize: 16,
      lineHeight: '20px',
      letterSpacing: '0.01em'
    },
    tags: {
      width: 250,
      marginTop: '0 !important',
      '& > div > div': {
        borderRadius: '10px 0 0 10px'
      }
    },
    tagsList: {
      '&.has': {
        marginTop: '-40px'
      }
    },
    padding: {
      padding: 5
    }
  };
});

const renderSuggestion = (suggestion: { type: string; title: string }) => {
  let caption = suggestion.type;
  if (caption === 'applications') {
    caption = 'inventions';
  }
  if (caption === 'campaigns') {
    caption = 'crowdfunding';
  }
  if (caption === 'quotes') {
    caption = 'requests for quotes';
  }
  return (
    <div>
      <Typography variant="caption">{caption}</Typography>
      <Typography variant="subtitle1">{suggestion.title}</Typography>
    </div>
  );
};

export const tags: string[] = [
  'Health',
  'Pets',
  'Personal Safety',
  'Care',
  'Federal Politics',
  'City Politics',
  'Local Politics'
];

let fetchTimer: NodeJS.Timeout;

type SearchRowProps = {
  className?: string;
};

const SearchRowView = () => {
  const { classes } = useStyles();
  const { dataProvider, updateFilterTags } = useContext(DataContext);
  const router = useRouter();
  const tagsStr = (router.query.tags as string) || '';
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState<Array<any>>([]);
  const [tags, setTags] = useState<Array<PsOptionType>>([]);
  const [tagsValue, setTagsValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [tagsNames, setTagsNames] = useState(tagsStr);

  const autoRef = useRef() as React.RefObject<Autosuggest<any, any>>;

  useEffect(() => {
    dataProvider
      .getList<Tag>('tags', {
        pagination: { page: 1, perPage: 50000 },
        sort: { field: 'name', order: 'ASC' },
        filter: {}
      })
      .then(({ data = [] }) => {
        let tagsNamesList: Array<string> | null = null;
        let tagsValueList: Array<string> | null = null;
        if (tagsNames && !tagsValue) {
          tagsNamesList = tagsNames.split(',');
          tagsValueList = [];
        }
        const list: Array<PsOptionType> = data.map((item) => {
          const value = item.key;
          const raw = item.name || '(empty)';
          const label = item.name || '(empty)';
          if (
            tagsValueList &&
            tagsNamesList &&
            tagsNamesList.indexOf(label) !== -1
          ) {
            tagsValueList.push(value);
          }
          return { value, label, raw };
        });
        setTags(list);
        if (tagsValueList && tagsNamesList) {
          setTagsValue(tagsValueList.join(','));
          updateFilterTags(tagsNamesList, tagsValueList);
        }
      })
      .catch(() => {
        setTags([]);
      });
  }, []);

  const loadSearchResults = async (query: string, tagsStr: string) => {
    if (!query) {
      setLoading(false);
      return;
    }
    const res = await dataProvider.globalSearch(query, tagsStr);

    if (Array.isArray(res) && res.length) {
      setSuggestions(res);
    }

    setLoading(false);
  };

  const onTagClick = (key: string | number, label: string) => {
    router.push(`/tags/${key}`);
  };

  const onTagsChange = (event: ShortEvent) => {
    const tagsStr = event.target.rawValue;
    const tagsValueStr = event.target.value;
    setTagsNames(tagsStr);
    setTagsValue(tagsValueStr);
    updateFilterTags(
      tagsStr ? tagsStr.split(',') : [],
      tagsValueStr ? tagsValueStr.split(',') : []
    );
    const { tags, ...newQuery } = getQuery();
    if (tagsStr) {
      newQuery.tags = tagsStr;
    }
    router.replace(
      `${router.pathname}?${new URLSearchParams(newQuery).toString()}`
    );
    loadSearchResults(value, event.target.rawValue).then(() => {
      if (autoRef && autoRef.current && autoRef.current.input) {
        autoRef.current.input.focus();
      }
    });
  };

  const onChange = (event: any, data: any) => {
    const { newValue } = data;
    setValue(newValue);
  };

  const onSuggestionsFetchRequested = (data: any) => {
    setLoading(true);
    const { value } = data;
    if (fetchTimer) {
      clearTimeout(fetchTimer);
    }
    fetchTimer = setTimeout(() => {
      loadSearchResults(value, tagsNames);
    }, 1000);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onSuggestionSelected = (event: any, data: any) => {
    const { suggestion } = data;
    const { type, key } = suggestion;
    const finalType = type === 'applications' ? 'inventions' : type;
    router.push(`/${finalType}/${key}`);
    setValue('');
  };

  const inputProps = {
    placeholder: 'Search',
    value,
    onChange: onChange
  };

  const getSuggestionValue = () => {
    return value;
  };

  return (
    <div className="search_form">
      {loading && <LinearProgress value={suggestions.length} />}
      <div className={classes.searchRow}>
        <PsSelect
          className={classes.tags}
          options={tags}
          maxMenuHeight={270}
          value={tagsValue}
          onChange={onTagsChange}
          placeholder="Select tags"
          isSearch
          isMulti
        />
        <Autosuggest
          ref={autoRef}
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          onSuggestionSelected={onSuggestionSelected}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          highlightFirstSuggestion
        />
      </div>
      <div>
        <PsSelectedList
          className={classes.tagsList}
          options={tags}
          value={tagsValue}
          onChange={onTagsChange}
          onCLick={onTagClick}
        />
      </div>
    </div>
  );
};

export const SearchRow = styled(SearchRowView)({});

export default SearchRow;
