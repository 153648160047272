import { useDispatch } from 'react-redux';
import { toggleCreditsModal } from 'helpers/common';
import { toastify } from 'pages/newContests/toastify';
import { GetUser } from 'redux-state/selectors';
import { ERRORS, TOASTIFY_DURATION, VARIANT } from 'utilities/constants';

export const useCheckCredits = (requiredCredits) => {
  const user = GetUser();
  const dispatch = useDispatch();

  const hasSufficientCredits = () => {
    if (user.credits < requiredCredits) {
      toggleCreditsModal(user, dispatch);
      toastify(
        ERRORS.NOT_ENOUGH_CREDITS,
        VARIANT.ERROR,
        VARIANT.BOTTOM_LEFT,
        TOASTIFY_DURATION
      );
      return false;
    }
    return true;
  };

  return hasSufficientCredits;
};
