import React, { useCallback, useState } from 'react';
import { styled, useTheme, lighten } from '@mui/material/styles';

import { colorPalette, PsTheme } from '../../theme';
import InputLabel from '@mui/material/InputLabel';
import { makeStyles } from 'tss-react/mui';
import { Box, Typography } from '@mui/material';

export const InfoIcon = ({ ...props }) => {
  return (
    <svg {...props} fill="none" width="20" height="20">
      <path
        d="M10.0001 18.3334C14.6025 18.3334 18.3334 14.6024 18.3334 10C18.3334 5.39765 14.6025 1.66669 10.0001 1.66669C5.39771 1.66669 1.66675 5.39765 1.66675 10C1.66675 14.6024 5.39771 18.3334 10.0001 18.3334Z"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 13.3333V10"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 6.66669H10.0083"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

type ClassKey =
  | 'labelWrapper'
  | 'label'
  | 'labelLight'
  | 'iconWrapper'
  | 'icon';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    labelWrapper: {
      marginTop: '-5px',
      marginBottom: '15px'
    },
    label: {
      display: 'inline',
      fontSize: 20,
      fontWeight: 600,
      lineHeight: 1.2,
      color: psTheme.palette.third.main,
      '& b': {
        fontWeight: 600,
        color: psTheme.palette.primary.main
      },
      [psTheme.breakpoints.down('xs')]: {
        fontSize: 17,
        lineHeight: 1
      }
    },
    labelLight: {
      fontWeight: 'normal',
      color: lighten(psTheme.palette.third.main, 0.3)
    },
    iconWrapper: {
      display: 'inline-block',
      margin: '0px 5px 3px',
      verticalAlign: 'bottom',
      color: psTheme.palette.primary.main,
      cursor: 'pointer'
    },
    icon: {
      display: 'block',
      color: psTheme.palette.primary.main
    }
  };
});

export type PsInputLabelProps = {
  className?: string;
  id?: string;
  name?: string;
  label?: React.ReactNode;
  labelInfo?: string | boolean;
  labelLight?: boolean;
  onLabelInfoClick?: () => void;
  required?: boolean;
};

export const PsInputLabelView = ({
  className,
  id,
  name,
  label,
  labelInfo,
  labelLight,
  onLabelInfoClick,
  required
}: PsInputLabelProps) => {
  const { classes } = useStyles();
  const [labelTitle] = useState(
    typeof labelInfo === 'string' ? labelInfo : undefined
  );

  let labelClassName = classes.label;
  if (labelLight) {
    labelClassName += ' ' + classes.labelLight;
  }

  const onClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      if (onLabelInfoClick) {
        onLabelInfoClick();
      }
    },
    [onLabelInfoClick]
  );

  return (
    <div className={`${classes.labelWrapper} ${className ? className : ''}`}>
      <InputLabel
        className={labelClassName}
        htmlFor={name || id}
        disableAnimation
      >
        <Typography
          lineHeight={1.5}
          overflow="hidden"
          variant="body2"
          whiteSpace="normal"
        >
          <Box display={'inline'}>
            {label}
            {required && (
              <StyledRequiredBox display={'inline'}> *</StyledRequiredBox>
            )}{' '}
          </Box>
        </Typography>
      </InputLabel>
      {labelInfo ? (
        <StyledIconWrapper onClick={onClick} title={labelTitle}>
          <StyledInfoIcon />
        </StyledIconWrapper>
      ) : null}
    </div>
  );
};

const StyledInfoIcon = styled(InfoIcon)({
  display: 'block',
  color: '#632DDD'
});

const StyledIconWrapper = styled(Typography)({
  display: 'inline-block',
  margin: '0px 5px 3px',
  verticalAlign: 'bottom',
  color: '#632DDD',
  cursor: 'pointer'
});

const StyledRequiredBox = styled(Box)({
  color: colorPalette.red,
  fontSize: '1.2rem',
  position: 'relative',
  top: '.375rem'
});

export const PsInputLabel = styled(PsInputLabelView)({});

export default PsInputLabel;
