import React from 'react';
import { Box, Typography } from '@mui/material';
import { VARIANT } from 'utilities/constants';
import {
  BorderLinearProgress,
  MainBox,
  ProgressBarBox
} from './styledComponents';

export const LinearProgressWithPercentage: React.FC<{
  currentPercentage: number;
}> = ({ currentPercentage }) => {
  return (
    <>
      <MainBox>
        <ProgressBarBox>
          <BorderLinearProgress
            variant={VARIANT.DETERMINATE}
            value={currentPercentage}
          />
        </ProgressBarBox>
        <Box>
          <Typography variant={VARIANT.BODY2} color={VARIANT.TEXT_SECONDARY}>
            {`${Math.round(currentPercentage)}%`}
          </Typography>
        </Box>
      </MainBox>
    </>
  );
};
