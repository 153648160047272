import { Box, styled, Typography } from '@mui/material';
import { colorPalette } from 'theme';

export const Container = styled(Box)({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  gap: '.5313rem',
  height: '3.125rem',
  justifyContent: 'flex-start'
});

export const Content = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  gap: '.25rem',
  justifyContent: 'flex-start'
});

export const MainBox = styled(Box)({
  alignItems: 'center',
  backgroundColor: colorPalette.white,
  borderRadius: '8px',
  boxShadow: `0rem 0rem .4375rem .0688rem ${colorPalette.black}10`,
  display: 'flex',
  flexDirection: 'row',
  height: '6.875rem',
  justifyContent: 'flex-start'
});

export const PriceBox = styled(Box)({
  alignItems: 'center',
  backgroundColor: `${colorPalette.purple}17`,
  borderRadius: '8px 0px 0px 8px',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  width: '30%'
});

export const PlanTitle = styled(Box)({
  alignItems: 'flex-start',
  display: 'flex',
  gap: '.625rem',
  justifyContent: 'flex-start',
  marginBottom: '.9875rem'
});

export const BestValueBox = styled(Box)({
  alignItems: 'center',
  backgroundColor: colorPalette.purple,
  borderRadius: '.1375rem',
  display: 'flex',
  justifyContent: 'center',
  padding: '.1938rem .375rem'
});

export const BestValueText = styled(Box)({
  color: colorPalette.white,
  fontSize: '.5313rem',
  fontWeight: 600
});

export const DetailsBox = styled(Box)({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '.9375rem 1.5625rem',
  width: '70%'
});

export const FeatureText = styled(Typography)({
  fontSize: '.5938rem',
  fontWeight: 600,
  width: '11.25rem'
});

export const MainPrice = styled(Typography)({
  color: colorPalette.charcoal,
  fontSize: '.6875rem',
  fontWeight: 500,
  lineHeight: 1
});

export const MainPriceWrapper = styled(Typography)({
  display: 'inline-block',
  fontSize: '1.75rem',
  fontWeight: 800,
  lineHeight: 1
});

export const CreditsText = styled(Typography)({
  color: colorPalette.charcoal,
  fontSize: '.5313rem',
  fontWeight: 500,
  lineHeight: 1,
  marginTop: '.375rem'
});

export const MainDurationText = styled(Typography)({
  fontSize: '1.0625rem',
  fontWeight: 700,
  lineHeight: 1
});
