import * as React from 'react';
export const VoteIconRectangle = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={243}
    height={162}
    fill="none"
    {...props}
  >
    <g filter="url(#a)">
      <rect width={209} height={127} x={17} y={17.398} fill="#fff" rx={8} />
    </g>
    <rect
      width={150}
      height={59.734}
      x={47}
      y={51.398}
      fill="#EBE2FF"
      opacity={0.4}
      rx={29.867}
    />
    <path
      fill="#000"
      d="M101.285 86.496v-1.088l4.56-4.4c.406-.384.704-.72.896-1.008.203-.299.336-.57.4-.816.075-.256.112-.501.112-.736 0-.576-.202-1.03-.608-1.36-.405-.33-.997-.496-1.776-.496-.597 0-1.136.101-1.616.304-.48.192-.896.496-1.248.912l-1.088-.944c.427-.544.998-.96 1.712-1.248a6.204 6.204 0 0 1 2.384-.448c.779 0 1.456.128 2.032.384.576.245 1.019.603 1.328 1.072.32.47.48 1.024.48 1.664 0 .363-.048.72-.144 1.072-.096.352-.277.725-.544 1.12-.266.395-.65.837-1.152 1.328l-4.064 3.92-.384-.624h6.768v1.392h-8.048Zm12.788.128a7.339 7.339 0 0 1-2.304-.368c-.736-.245-1.339-.576-1.808-.992l.736-1.264c.373.352.859.64 1.456.864a5.42 5.42 0 0 0 1.92.336c.832 0 1.477-.176 1.936-.528.469-.363.704-.848.704-1.456 0-.587-.224-1.056-.672-1.408-.437-.363-1.136-.544-2.096-.544h-.896v-1.12l3.328-4.096.224.64h-6.128v-1.392h7.392v1.088l-3.312 4.08-.832-.496h.528c1.355 0 2.368.304 3.04.912.683.608 1.024 1.381 1.024 2.32 0 .63-.155 1.205-.464 1.728-.309.523-.779.939-1.408 1.248-.619.299-1.408.448-2.368.448Zm5.572-2.88v-1.12l5.728-7.328h1.728l-5.68 7.328-.816-.256h8.944v1.376h-9.904Zm6.224 2.752v-2.752l.048-1.376v-2.432h1.504v6.56h-1.552Z"
    />
    <path
      fill="#fff"
      stroke="#35A850"
      strokeWidth={1.5}
      d="M64.598 80.765h-.003a.208.208 0 0 1-.085-.018.255.255 0 0 1-.085-.062.361.361 0 0 1 .006-.477l.007-.007 12.256-12.98.004-.004a.255.255 0 0 1 .084-.06l-.303-.687.303.686a.209.209 0 0 1 .082-.018.231.231 0 0 1 .162.08l.005.004L89.287 80.2l.007.007a.32.32 0 0 1 .068.108.373.373 0 0 1 .025.14c-.004.215-.16.311-.26.31h-5.348c-.869 0-1.5.732-1.5 1.544v12.134c0 .215-.155.316-.257.316h-10.35c-.1 0-.255-.1-.255-.316V82.31c0-.812-.633-1.545-1.502-1.545h-5.317Z"
    />
    <path
      fill="#fff"
      stroke="#FF4848"
      strokeWidth={1.5}
      d="M180.06 80.784h.003c.028 0 .057.006.085.018a.247.247 0 0 1 .084.062.368.368 0 0 1-.005.483l-.007.007-12.257 13.016-.004.005a.255.255 0 0 1-.083.06.208.208 0 0 1-.082.019.231.231 0 0 1-.162-.08h.001l-.005-.004-12.256-13.016-.007-.007a.334.334 0 0 1-.069-.109.375.375 0 0 1-.024-.142c.003-.219.161-.314.26-.312H160.879c.871 0 1.501-.736 1.501-1.547V67.069c0-.22.157-.319.256-.319h10.349c.1 0 .257.1.257.319v12.168c0 .811.63 1.547 1.501 1.547h5.317Zm-25.538.298Z"
    />
    <defs>
      <filter
        id="a"
        width={243}
        height={161}
        x={0}
        y={0.398}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={8.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5175_3327"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_5175_3327"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
