import React, { useState, useCallback, useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import ModalWindow from 'modals/ModalWindow';
import { PsButton } from 'components/common/PsButton';
import { PsLogo } from 'components/common/PsLogo';
import { Problem } from 'components/CardProblem';
import { Solution } from 'components/CardSolution';
import { Application } from 'components/CardApplication';
import Step from 'components/Step';
import Stepper from 'components/Stepper';
import { ModalContext, ModalComponentsKeys } from 'contexts/ModalContext';
import {
  ModalDataContext,
  AddApplicationSteps,
  AddProblemSteps,
  AddSolutionSteps,
  StepComponentProps
} from 'contexts/ModalDataContext';
import { DataContext } from 'contexts/DataContext';
import { removeDuplicates } from '../../helpers';
import {
  ADD_APPLICATION_STEPS_CONFIG,
  stepName,
  defaultProblemTitle,
  defaultSolutionTitle,
  defaultInventionTitle
} from './config';
import styles from 'modals/ModalWindow.module.scss';
import { makeStyles } from 'tss-react/mui';
import useRouter from 'hooks/useRouter';

const steps: string[] = [
  'Invention name',
  'Tag Invention',
  'Finalize Invention'
];

const stepsProblem: string[] = ['Problem Name', 'Tag Name', 'Finalize Problem'];

const stepsSolution: string[] = [
  'Solution Name',
  'Tag Name',
  'Finalize Solution'
];

const useButtonClasses = makeStyles()(() => {
  return {
    root: {
      margin: '8px 0',
      fontWeight: 400
    }
  };
});

const AddNewApplication: React.FC = () => {
  const { openModal } = useContext(ModalContext);
  const { values, setErrors, updateField } = useContext(ModalDataContext);
  const { dataProvider } = useContext(DataContext);
  const theme = useTheme();
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [prevSteps, setPrevSteps] = useState<string[]>([]);
  const [activeStepNumber, setActiveStepNumber] = useState<number>(0);
  const [currentStep, setCurrentStep] = useState<string | undefined>(
    AddApplicationSteps.ApplicationName
  );
  const [stepsName, setStepsName] = useState<Array<string>>(steps);

  const buttonClasses = useButtonClasses();

  const {
    Component,
    nextStep,
    validate,
    isNextStepBlocked,
    isFinalStep,
    nextButtonTitle,
    modalTitle,
    name
  } = ADD_APPLICATION_STEPS_CONFIG[currentStep || ''];

  const StepComponent = Component as React.FC<StepComponentProps>;

  const handleClickBack = useCallback(() => {
    if (name === stepName.PROBLEM_START) {
      setPrevSteps([]);
      setErrors({});
      setActiveStepNumber(0);
      setCurrentStep(AddApplicationSteps.ApplicationName);
      setStepsName(steps);
      return;
    }

    if (name === stepName.SOLUTION_START) {
      setPrevSteps([
        AddApplicationSteps.ApplicationName,
        AddApplicationSteps.ApplicationTags
      ]);
      setErrors({});
      setActiveStepNumber(2);
      setCurrentStep(AddApplicationSteps.FinilizeApplication);
      setStepsName(steps);
      return;
    }

    if (activeStepNumber === 0) {
      openModal(ModalComponentsKeys.addNewEntity);
      return;
    }
    setActiveStepNumber((prev) => Math.max(0, prev - 1));
    setCurrentStep(prevSteps[prevSteps.length - 1]);
    setPrevSteps(prevSteps.slice(0, prevSteps.length - 1));
  }, [prevSteps]);

  const handleNextStep = async () => {
    if (typeof validate === 'function') {
      const { success, errors } = validate(values);

      if (!success) {
        setErrors({ ...errors });

        return;
      }
    }

    if (name === stepName.PROBLEM_END) {
      const problemInfo = {
        title: (values.titleProblem || '').replace(defaultProblemTitle, ''),
        tags: removeDuplicates(values.tagsProblem),
        body: values.descriptionProblem,
        files: values.filesProblem,
        parentProblem: values.rootProblem,
        isPublic: true
      };

      setLoading(true);

      dataProvider
        .create<Problem>('problems', { data: problemInfo })
        .then(({ data }) => {
          router.push(`/problems/${data.key || data.id}`);
          updateField('mainProblem', data.id);
          updateField('mainProblemLabel', data.title);
          setPrevSteps([]);
          setErrors({});
          setActiveStepNumber(0);
          setCurrentStep(AddApplicationSteps.ApplicationName);
          setStepsName(steps);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });

      return;
    }

    if (name === stepName.SOLUTION_END) {
      const solutionInfo = {
        title: (values.titleSolution || '').replace(defaultSolutionTitle, ''),
        tags: removeDuplicates(values.tagsSolution),
        body: values.descriptionSolution,
        files: values.filesSolution,
        problem: values.parentProblem,
        //parentSolution: values.parentSolution,
        isPublic: values.finalizeType === 'public'
      };

      setLoading(true);

      dataProvider
        .create<Solution>('solutions', { data: solutionInfo })
        .then(({ data }) => {
          router.push(`/solutions/${data.key || data.id}`);
          const newSelected = [...values.selected, data.id];
          updateField('selected', newSelected);
          setPrevSteps([
            AddApplicationSteps.ApplicationName,
            AddApplicationSteps.ApplicationTags
          ]);
          setErrors({});
          setActiveStepNumber(2);
          setCurrentStep(AddApplicationSteps.FinilizeApplication);
          setStepsName(steps);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });

      return;
    }

    if (isFinalStep) {
      const applicationInfo = {
        title: (values.titleApplication || '').replace(
          defaultInventionTitle,
          ''
        ),
        body: values.descriptionApplication,
        problem: values.mainProblem,
        selected: [values.mainProblem, ...values.selected],
        tags: values.tagsApplication,
        parentProduct: values.selectApplication,
        isPublic: false
      };

      setLoading(true);

      dataProvider
        .create<Application>('applications', { data: applicationInfo })
        .then(({ data }) => {
          router.push(`/inventions/${data.key || data.id}`);
          openModal(ModalComponentsKeys.success);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });

      return;
    }

    setPrevSteps([...prevSteps, currentStep || '']);
    setErrors({});
    setActiveStepNumber((prev) => prev + 1);
    if (typeof nextStep === 'function') {
      setCurrentStep(nextStep(values));
      return;
    }

    setCurrentStep(nextStep);
  };

  const onStepNotify = (args: any) => {
    if (args === 'create problem') {
      setPrevSteps([...prevSteps, currentStep || '']);
      setErrors({});
      setActiveStepNumber(3);
      setCurrentStep(AddProblemSteps.ProblemName);
      setStepsName(stepsProblem);
    }
    if (args === 'create solution') {
      updateField('parentProblem', values.mainProblem);
      updateField('parentProblemLabel', values.mainProblemLabel);
      updateField('parentProblemHide', true);
      updateField('parentSolutionHide', true);
      setPrevSteps([...prevSteps, currentStep || '']);
      setErrors({});
      setActiveStepNumber(6);
      setCurrentStep(AddSolutionSteps.SolutionName);
      setStepsName(stepsSolution);
    }
  };

  const isNextStepDisabled: boolean =
    typeof isNextStepBlocked === 'function' && isNextStepBlocked(values);

  const title = values.modalTitle || modalTitle || 'Add New Invention';

  let stepperStep = activeStepNumber;
  if (activeStepNumber >= 3 && activeStepNumber < 6) {
    stepperStep = activeStepNumber - 3;
  }
  if (activeStepNumber >= 6) {
    stepperStep = activeStepNumber - 6;
  }

  return (
    <ModalWindow
      title={title}
      titleAfter={
        <span className={styles.modalTitleIcon}>
          Earn: <PsLogo size={23} color={theme.palette.primary.main} small />
        </span>
      }
    >
      <Stepper className={styles.stepper} activeStep={stepperStep}>
        {stepsName.map((step) => (
          <Step key={step}>{step}</Step>
        ))}
      </Stepper>
      <StepComponent onNotify={onStepNotify} />
      <div className={styles.actions}>
        <PsButton
          disabled={isNextStepDisabled || loading}
          loading={loading}
          onClick={handleNextStep}
          fullWidth
          classes={buttonClasses}
        >
          {nextButtonTitle || 'Next'}
        </PsButton>
        <PsButton
          onClick={handleClickBack}
          fullWidth
          cancel
          classes={buttonClasses}
        >
          Back
        </PsButton>
      </div>
    </ModalWindow>
  );
};

export default AddNewApplication;
