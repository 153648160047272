import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { Tag } from 'components/CardTag';
import CounterButton from 'components/common/CounterButton';
import config from 'config/config';
import AuthContext from 'contexts/AuthContext';
import dataProvider from 'dataPrvider';
import { Picture } from 'helpers';
import { useCheckCredits } from 'hooks/useCheckCredits';
import Actions from 'redux-state/actions';
import {
  GetGeneratedDescriptionLoader,
  GetGenerateImageLoader
} from 'redux-state/selectors';
import { Constants } from 'utilities/constants';
import { ActionSection } from '../Common/ActionSection';
import { BaseModal } from '../Common/BaseModal';
import { ContentSection } from '../Common/ContentSection';
import { HeaderSection } from '../Common/HeaderSection';
import { OptionType } from '../Common/interface';
import { Content } from './Content';
import { Header } from './Header';
import {
  StyledCircularProgress,
  StyledCounterButtonMainWrapper
} from './StyledComponents';

const API_COSTS = config.API_COSTS;

interface ProductModalProps {
  onClose?: () => void;
  open: boolean;
  initialValue?: string;
}

const ProductModal: React.FC<ProductModalProps> = ({
  onClose,
  open,
  initialValue
}) => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState<string>(initialValue);
  const { user } = useContext(AuthContext);
  const [selectedTags, setSelectedTags] = useState<Array<OptionType | null>>(
    []
  );
  const [selectedCompany, setSelectedCompany] = useState<OptionType | null>(
    null
  );

  const [description, setDescription] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [picture, setPicture] = useState<Picture | undefined>(undefined);
  const [loadingCount, setLoadingCount] = useState<number>(0);
  const startLoading = useCallback(() => {
    setLoadingCount((count) => count + 1);
  }, []);
  const stopLoading = useCallback(
    () => setLoadingCount((count) => count - 1),
    []
  );
  const generatedDescriptionLoader = GetGeneratedDescriptionLoader();
  const generateImageLoader = GetGenerateImageLoader();
  const formLoading = loadingCount > 0 || generatedDescriptionLoader;
  const hasSufficientCredits = useCheckCredits(API_COSTS.CREATE_PRODUCT);

  if (!hasSufficientCredits()) {
    onClose();
    return null;
  }

  const handleCreateClick = useCallback(() => {
    setLoading(true);
    if (user) {
      const createCompanyName = selectedCompany.value || '';
      const createCompany = !!selectedCompany.__isNew__;

      Promise.resolve()
        .then(() => {
          if (createCompany) {
            return dataProvider
              .create<Tag>(Constants.TAGS, {
                data: { name: createCompanyName, type: Constants.WORKPLACE }
              })
              .then(({ data }) => data.id);
          }
          return createCompanyName;
        })
        .then((companyId) => {
          if (companyId) {
            const formattedTags = selectedTags.map((tag) => tag.value);
            const payload = {
              body: description,
              company: companyId,
              files: picture ? [picture] : [],
              tags: formattedTags,
              title
            };
            dispatch(Actions.addProduct(payload));
          }
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    } else {
      dispatch(Actions.openAuthModal(true));
    }
  }, [
    description,
    dispatch,
    picture,
    selectedCompany,
    selectedTags,
    title,
    user
  ]);

  const disableCreateButton = useCallback(() => {
    return (
      !selectedTags || !description || !selectedCompany || generateImageLoader
    );
  }, [description, generateImageLoader, selectedCompany, selectedTags]);

  const isCreateButtonDisabled = useMemo(() => {
    return disableCreateButton() || loading;
  }, [disableCreateButton, loading]);

  const handleClose = useCallback(() => {
    onClose();
    dispatch(Actions.generateDescriptionFromAISuccess({}));
    dispatch(Actions.generateImageFromAISuccess({}));
  }, [dispatch, onClose]);
  return (
    <BaseModal open={open} loading={formLoading} maxWidth={'lg'}>
      <HeaderSection>
        <Header
          label={Constants.ADD_NEW_PRODUCT}
          onClose={handleClose}
          showCrossIcon={true}
        />
      </HeaderSection>
      <ContentSection>
        <Content
          description={description}
          picture={picture}
          selectedCompany={selectedCompany}
          selectedTags={selectedTags}
          setDescription={setDescription}
          setPicture={setPicture}
          setSelectedCompany={setSelectedCompany}
          setSelectedTags={setSelectedTags}
          setTitle={setTitle}
          startLoading={startLoading}
          stopLoading={stopLoading}
          title={title}
        />
      </ContentSection>
      <ActionSection>
        <StyledCounterButtonMainWrapper>
          <Box sx={{ position: 'relative' }}>
            <CounterButton
              disabled={isCreateButtonDisabled}
              handleClick={handleCreateClick}
              isCreateButton
              text={Constants.C_CREATE}
            />
            {loading && <StyledCircularProgress size={30} thickness={2} />}
          </Box>
        </StyledCounterButtonMainWrapper>
      </ActionSection>
    </BaseModal>
  );
};

export default ProductModal;
