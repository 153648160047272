import { Dispatch, FC, ReactElement, SetStateAction, useCallback } from 'react';
import { Box, Skeleton } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { animated, useSpring } from '@react-spring/web';
import { AnimatePresence, motion } from 'framer-motion';
import InteractiveNodeContainer from 'components/InteractiveNodeContainer';
import { Node, VotingCallBackArgs } from 'interface/common';
import { Constants } from 'utilities/constants';
import { TransitionProps } from '@mui/material/transitions';
import {
  CircularSkeleton,
  CustomTreeItem,
  NodeStyle,
  TextSkeleton
} from './StyledComponents';

const getItemId = (id: string, index: number): string => `${id}-${index}`;

interface RenderTreeItemsProps {
  buttonList?: {
    parent?: any;
    child?: any;
  };
  expansionIds?: string[];
  fetchChildrenGraph?: (id?: string, type?: string) => void;
  handleDrawer?: () => void;
  ideaMapType?: string;
  isChatBot?: boolean;
  messageId?: string;
  nodes: Node[];
  problemAndSolutionCreationCallBack?: () => void;
  selectedNode?: string;
  setExpansionIds?: Dispatch<SetStateAction<string[]>>;
  setIsSolutionModalOpen?: (value: boolean) => void;
  setProblem?: (problem: Node) => void;
}

const RenderTreeItems: FC<RenderTreeItemsProps> = ({
  buttonList,
  expansionIds = [],
  fetchChildrenGraph,
  handleDrawer,
  ideaMapType,
  isChatBot = false,
  messageId,
  nodes,
  problemAndSolutionCreationCallBack,
  selectedNode,
  setExpansionIds,
  setIsSolutionModalOpen,
  setProblem
}): ReactElement => {
  const manipulateIds = useCallback(
    (id: string) => {
      if (!setExpansionIds) return;
      setExpansionIds((prevIds) =>
        prevIds.includes(id)
          ? prevIds.filter((item) => item !== id)
          : [...prevIds, id]
      );
    },
    [setExpansionIds]
  );

  const handleNodeClick = useCallback(
    (node: Node) => {
      if (
        node.type === Constants.Invention ||
        (node.children?.length &&
          node.children.some((item) => 'hasDataLoaded' in item))
      ) {
        fetchChildrenGraph?.(
          node.id,
          node.type === Constants.Invention ? Constants.APPLICATION : node.type
        );
      }
      const id = node.id ?? node.key;
      manipulateIds(id);
    },
    [fetchChildrenGraph, manipulateIds]
  );

  const processNodeChildren = useCallback(
    (children: any[], parentId: string): Node[] => {
      return children.map((child) => {
        if (typeof child === 'string' && child !== Constants.SOLUTION) {
          return {
            id: child,
            itemId: child,
            hasDataLoaded: false,
            treeParent: parentId
          };
        }
        return child;
      });
    },
    []
  );

  const TransitionComponent: FC<TransitionProps> = useCallback((props) => {
    const style = useSpring({
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px, ${props.in ? 0 : 20}px, 0)`
    });

    return (
      <animated.div style={style}>
        <Collapse {...props} />
      </animated.div>
    );
  }, []);

  return (
    <>
      {nodes?.map((node, index) => {
        const processedNode: Node = {
          ...node,
          children: node?.children
            ? processNodeChildren(node.children, node.id ?? '')
            : []
        };

        const shouldRenderChildren =
          (processedNode.children.length > 0 &&
            processedNode.type !== Constants.SOLUTION &&
            ((isChatBot && processedNode.type === Constants.PROBLEM) ||
              (!isChatBot && processedNode.type !== Constants.PRODUCT))) ||
          (ideaMapType === Constants.APPLICATIONS &&
            processedNode.type === Constants.SOLUTION);

        return (
          <CustomTreeItem
            key={processedNode.id || getItemId(processedNode.key, index)}
            itemId={
              processedNode.id
                ? `${processedNode.id}${processedNode.treeParent ?? ''}`
                : getItemId(processedNode.key, index)
            }
            data-type={processedNode.type}
            onClick={() => handleNodeClick(processedNode)}
            slots={{ groupTransition: TransitionComponent }}
            label={
              processedNode.hasDataLoaded === false ? (
                <Box sx={NodeStyle}>
                  <Skeleton
                    variant="circular"
                    width={70}
                    height={70}
                    sx={CircularSkeleton}
                  />
                  <Skeleton variant="text" sx={TextSkeleton} />
                </Box>
              ) : (
                <AnimatePresence
                  key={`${processedNode.id}-${processedNode.parentType}`}
                >
                  <motion.li
                    animate={{ opacity: 1 }}
                    layout="position"
                    onDragStart={(e) => e.preventDefault()}
                  >
                    <InteractiveNodeContainer
                      className="node-class"
                      fetchChildrenGraph={fetchChildrenGraph}
                      handleDrawer={handleDrawer}
                      ideaMapType={ideaMapType}
                      node={processedNode}
                      selectedNode={selectedNode}
                      setIsSolutionModalOpen={setIsSolutionModalOpen}
                      setProblem={setProblem}
                      problemAndSolutionCreationCallBack={
                        problemAndSolutionCreationCallBack
                      }
                      buttonList={buttonList?.parent}
                      messageId={messageId}
                    />
                  </motion.li>
                </AnimatePresence>
              )
            }
          >
            {shouldRenderChildren ? (
              <RenderTreeItems
                buttonList={buttonList?.child}
                expansionIds={expansionIds}
                fetchChildrenGraph={fetchChildrenGraph}
                handleDrawer={handleDrawer}
                ideaMapType={ideaMapType}
                isChatBot={isChatBot}
                messageId={messageId}
                nodes={processedNode.children}
                problemAndSolutionCreationCallBack={
                  problemAndSolutionCreationCallBack
                }
                selectedNode={selectedNode}
                setExpansionIds={setExpansionIds}
                setIsSolutionModalOpen={setIsSolutionModalOpen}
                setProblem={setProblem}
              />
            ) : null}
          </CustomTreeItem>
        );
      })}
    </>
  );
};

export default RenderTreeItems;
