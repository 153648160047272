import {
  call,
  CallEffect,
  put,
  PutEffect,
  takeLatest
} from 'redux-saga/effects';
import { Application } from 'components/CardApplication';
import { Contest } from 'components/CardContest';
import { Problem } from 'components/CardProblem';
import { Concept, Product } from 'components/CardProduct';
import { Profile } from 'components/CardProfile';
import { Solution } from 'components/CardSolution';
import { UserAgreement } from 'components/common/Types';
import { initDeployNFT } from 'helpers/blockchain';
import { getConcept } from 'redux-state/ideamap/actions';
import { Constants, ERRORS, TAG_TYPES } from 'utilities/constants';
import * as Actions from './actions';
import { Api } from './api';
import {
  ActionsItemPayload,
  eFilePatentPayload,
  ProfileIdPayload,
  RewardHistory
} from './interface';
import {
  DELETE_PROFILE_ITEM,
  E_FILE_PATENT,
  EDIT_PROFILE_ITEM,
  EDIT_PROFILE,
  GET_NOTIFICATIONS,
  GET_PROFILE_AGREEMENTS_COUNT,
  GET_PROFILE_AGREEMENTS,
  GET_PROFILE_CONCEPTS_COUNT,
  GET_PROFILE_CONCEPTS,
  GET_PROFILE_CONTESTS_COUNT,
  GET_PROFILE_CONTESTS,
  GET_PROFILE_INVENTIONS_COUNT,
  GET_PROFILE_INVENTIONS,
  GET_PROFILE_MUTUAL_TAGS,
  GET_PROFILE_PROBLEMS_COUNT,
  GET_PROFILE_PROBLEMS,
  GET_PROFILE_PRODUCTS,
  GET_PROFILE_SOLUTIONS_COUNT,
  GET_PROFILE_SOLUTIONS,
  GET_REWARD_HISTORY,
  GET_UNREAD_NOTIFICATION_COUNT,
  GET_USER_IDEAPOINTS,
  GET_USER_RANKING,
  MARK_ALL_READ,
  MARK_MULTIPLE_READ,
  NFT_DEPLOY_FINISH,
  NFT_DEPLOY_START,
  SET_ARCHIVE_ITEM,
  SET_PIN_ITEM,
  TOGGLE_READ_STATUS
} from './types';
import * as onboardingActions from 'redux-state/onboarding/actions';

function* eFilePatent(action: {
  type: string;
  payload: eFilePatentPayload;
}): Generator<CallEffect | PutEffect, void, any> {
  try {
    const { profileId, pagination } = action.payload;
    const response = yield call(Api.eFilePatent, action.payload);
    if (response) {
      yield put(Actions.eFilePatentSuccess());
      yield put(Actions.getProfileConcepts(profileId, pagination));
    }
  } catch (error) {
    console.log('error', error);
  }
}

function* getProfileProblems(action: {
  type: string;
  payload: ActionsItemPayload;
}): Generator<CallEffect | PutEffect, void, Array<Problem>> {
  try {
    const { profileId, pagination, getPinItems, isArchived } = action.payload;
    const response: Array<Problem> = yield call(
      Api.getProfileItems,
      'problems',
      'forUi',
      'createdAt',
      profileId,
      pagination,
      getPinItems,
      isArchived
    );
    yield put(Actions.getProfileProblemsSuccess(response));
  } catch (error) {
    console.log('error', error);
  }
}

function* getProfileProblemsCount(action: {
  type: string;
  payload: ActionsItemPayload;
}): Generator<CallEffect | PutEffect, void, number> {
  try {
    const { profileId } = action.payload;
    const response = yield call(
      Api.getProfileItemsCount,
      'problems',
      profileId
    );
    yield put(Actions.getProfileProblemsCountSuccess(response));
  } catch (error) {
    console.log('error', error);
  }
}

function* getProfileSolutionsCount(action: {
  type: string;
  payload: ActionsItemPayload;
}): Generator<CallEffect | PutEffect, void, number> {
  try {
    const { profileId } = action.payload;
    const response = yield call(
      Api.getProfileItemsCount,
      'solutions',
      profileId
    );
    yield put(Actions.getProfileSolutionsCountSuccess(response));
  } catch (error) {
    console.log('error', error);
  }
}

function* getProfileConceptsCount(action: {
  type: string;
  payload: ActionsItemPayload;
}): Generator<CallEffect | PutEffect, void, number> {
  try {
    const { profileId, isFiled = false } = action.payload;
    const response = yield call(
      Api.getProfileItemsCount,
      'applications',
      profileId,
      isFiled
    );
    yield put(Actions.getProfileConceptsCountSuccess(response));
  } catch (error) {
    console.log('error', error);
  }
}

function* getProfileInventionsCount(action: {
  type: string;
  payload: ActionsItemPayload;
}): Generator<CallEffect | PutEffect, void, number> {
  try {
    const { profileId, isFiled = true } = action.payload;
    const response = yield call(
      Api.getProfileItemsCount,
      'applications',
      profileId,
      isFiled
    );
    yield put(Actions.getProfileInventionsCountSuccess(response));
  } catch (error) {
    console.log('error', error);
  }
}

function* getProfileContestsCount(action: {
  type: string;
  payload: ActionsItemPayload;
}): Generator<CallEffect | PutEffect, void, number> {
  try {
    const { profileId } = action.payload;
    const response = yield call(
      Api.getProfileItemsCount,
      'contests',
      profileId
    );
    yield put(Actions.getProfileContestsCountSuccess(response));
  } catch (error) {
    console.log('error', error);
  }
}

function* getProfileAgreementsCount(action: {
  type: string;
  payload: ActionsItemPayload;
}): Generator<CallEffect | PutEffect, void, number> {
  try {
    const { profileId } = action.payload;
    const response = yield call(
      Api.getProfileItemsCount,
      'userAgreements',
      profileId
    );
    yield put(Actions.getProfileAgreementsCountSuccess(response));
  } catch (error) {
    console.log('error', error);
  }
}

function* getProfileSolutions(action: {
  type: string;
  payload: ActionsItemPayload;
}): Generator<CallEffect | PutEffect, void, Array<Solution>> {
  try {
    const { profileId, pagination, getPinItems, isArchived } = action.payload;
    const response: Array<Solution> = yield call(
      Api.getProfileItems,
      'solutions',
      'forUi',
      'createdAt',
      profileId,
      pagination,
      getPinItems,
      isArchived
    );
    yield put(Actions.getProfileSolutionsSuccess(response));
  } catch (error) {
    console.log('error', error);
  }
}

function* getProfileConcepts(action: {
  type: string;
  payload: ActionsItemPayload;
}): Generator<CallEffect | PutEffect, void, Array<Concept>> {
  try {
    const { profileId, pagination, getPinItems, isArchived } = action.payload;
    const response: Array<Concept> = yield call(
      Api.getProfileItems,
      'applications',
      'isNotfilled',
      'createdAt',
      profileId,
      pagination,
      getPinItems,
      isArchived,
      false
    );
    yield put(Actions.getProfileConceptsSuccess(response));
  } catch (error) {
    console.log('error', error);
  }
}

function* getProfileInventions(action: {
  type: string;
  payload: ActionsItemPayload;
}): Generator<CallEffect | PutEffect, void, Array<Application>> {
  try {
    const { profileId, pagination, getPinItems, isArchived, isFiled } =
      action.payload;
    const response: Array<Application> = yield call(
      Api.getProfileItems,
      'applications',
      TAG_TYPES.IS_FILED,
      'createdAt',
      profileId,
      pagination,
      getPinItems,
      isArchived,
      isFiled
    );
    yield put(Actions.getProfileInventionsSuccess(response));
  } catch (error) {
    console.log('error', error);
  }
}

function* getProfileProducts(action: {
  type: string;
  payload: ProfileIdPayload;
}): Generator<CallEffect | PutEffect, void, Array<Product>> {
  try {
    const { profileId, pagination } = action.payload;

    const response: Array<Product> = yield call(
      Api.getProfileItems,
      'company-products',
      'Product',
      'votes',
      profileId,
      pagination
    );
    yield put(Actions.getProfileProductsSuccess(response));
  } catch (error) {
    console.log('error', error);
  }
}

function* getProfileContests(action: {
  type: string;
  payload: ActionsItemPayload;
}): Generator<CallEffect | PutEffect, void, Array<Contest>> {
  const { profileId, pagination, getPinItems, isArchived } = action.payload;

  try {
    const response: Array<Contest> = yield call(
      Api.getProfileItems,
      'contests',
      'Contest',
      'createdAt',
      profileId,
      pagination,
      getPinItems,
      isArchived
    );

    yield put(Actions.getProfileContestsSuccess(response));
  } catch (error) {
    console.log('error', error);
  }
}

function* getProfieAgreements(action: {
  type: string;
  payload: ActionsItemPayload;
}): Generator<CallEffect | PutEffect, void, Array<UserAgreement>> {
  try {
    const { profileId, pagination } = action.payload;
    const response: Array<UserAgreement> = yield call(
      Api.getProfileItems,
      Constants.USER_AGREEMENTS,
      Constants.USER_AGREEMENT,
      Constants.CREATED_AT,
      profileId,
      pagination
    );
    yield put(Actions.getProfileAgreementsSuccess(response));
  } catch (err) {
    console.log('error', err);
  }
}

function* getNotifications(action: {
  type: string;
  payload: {
    pagination: { page: number; perPage: number };
    userId?: string | number | null;
    itemType?: { $ne: string | null };
    self?: { $ne: boolean };
  };
}): Generator<CallEffect | PutEffect, void, Notification> {
  try {
    const response: Notification = yield call(
      Api.getNotifications,
      action.payload
    );
    yield put(Actions.getNotificationsSuccess(response));
  } catch (error) {
    console.log('error', error);
  }
}

function* getUnreadNotificationCount(action: {
  type: string;
  payload: {
    userId: string;
    category: string;
    itemType: { $ne: string };
    read?: { $eq: boolean };
    self?: { $ne: boolean };
  };
}): Generator<CallEffect | PutEffect, void, Notification> {
  try {
    const response: any = yield call(
      Api.getUnreadNotificationCount,
      action.payload
    );
    yield put(Actions.getUnreadNotificationCountSuccess(response));
  } catch (error) {
    console.log('error', error);
  }
}

function* toggleReadStatus(action: {
  type: string;
  payload;
}): Generator<CallEffect | PutEffect, void, Notification> {
  try {
    yield call(Api.toggleReadStatus, action.payload);
  } catch (error) {
    console.log('error', error);
  }
}

function* markAllAsRead(action: {
  type: string;
  payload;
}): Generator<CallEffect | PutEffect, void, Notification> {
  try {
    yield call(Api.markAllAsRead, action.payload);
  } catch (error) {
    console.log('error', error);
  }
}

function* markMultipleAsRead(action: {
  type: string;
  payload;
}): Generator<CallEffect | PutEffect, void, Notification> {
  try {
    yield call(Api.markMultipleAsRead, action.payload);
  } catch (error) {
    console.log('error', error);
  }
}

function* getRewardHistory(action: {
  type: string;
  payload: {
    pagination: { page: number; perPage: number };
    userId?: string | number | null;
    itemType?: { $ne: string | null };
    ideaPoints?: { $ne: number | null };
  };
}): Generator<CallEffect | PutEffect, void, RewardHistory> {
  try {
    const response: RewardHistory = yield call(
      Api.getRewardHistory,
      action.payload
    );
    yield put(Actions.getRewardHistorySuccess(response));
  } catch (error) {
    console.log('error', error);
  }
}

function* nftDeployStart(action: {
  type: string;
  payload: {
    id: string | number;
    privateKey: string;
    onDeployStartSuccess: (
      gasFeeEstimate: number,
      type: string,
      tokenURI: string
    ) => void;
  };
}) {
  try {
    const { contractAddress, tokenURI } = yield call(
      Api.nftDeployStart,
      action.payload.id
    );
    if (tokenURI && contractAddress) {
      yield put(Actions.setTokenURI({ tokenURI }));
      yield call(
        initDeployNFT,
        tokenURI,
        action.payload.onDeployStartSuccess,
        action.payload.privateKey
      );
      yield put(Actions.nftDeployStartSuccess({ contractAddress, tokenURI }));
    } else {
      yield put(Actions.nftDeployStartSuccess({}));
      yield put(
        Actions.toggleError(ERRORS.UNEXPECTED_ERROR, Constants.APPLICATIONS)
      );
    }
  } catch (error) {
    const message =
      error?.status == 500 && error.message
        ? ERRORS.UNEXPECTED_ERROR
        : error.message;
    yield put(Actions.nftDeployStartSuccess({}));
    yield put(Actions.toggleError(message, Constants.APPLICATIONS));
  }
}

function* nftDeployFinish(action: {
  type: string;
  payload: {
    id: string | number;
    isArchived: boolean;
    pagination: { page: number; perPage: number };
    privateKey: string;
    tokenId: string;
    transactionHash: string;
    userId: string | number;
    walletAddress: string;
  };
}) {
  try {
    const { id, isArchived, pagination, tokenId, transactionHash, userId } =
      action.payload;
    const response = yield call(Api.nftDeployFinish, id, {
      transactionHash,
      tokenId
    });
    if (response) {
      const nftDepolySuccess = yield put(
        Actions.nftDeployFinishSuccess(response)
      );
      if (nftDepolySuccess) {
        yield call(
          dispatchProfileAction,
          Constants.APPLICATIONS,
          userId,
          true,
          pagination,
          true,
          isArchived
        );
      }
    } else {
      yield put(Actions.nftDeployFinishSuccess([]));
      yield put(
        Actions.toggleError(ERRORS.UNEXPECTED_ERROR, Constants.APPLICATIONS)
      );
    }
  } catch (error) {
    const message =
      error?.status == 500 ? ERRORS.UNEXPECTED_ERROR : error.message;
    yield put(Actions.nftDeployFinishSuccess([]));
    yield put(Actions.toggleError(message, Constants.APPLICATIONS));
    console.log(error.message);
  }
}

function* setPinItem(action: {
  type: string;
  payload: ActionsItemPayload;
}): Generator<CallEffect, void, boolean> {
  try {
    const { id, type, isPinned, profileId, isFiled, pagination } =
      action.payload;

    const response: boolean = yield call(
      Api.setPinItem,
      id,
      type,
      isPinned,
      isFiled
    );
    if (response) {
      yield call(
        dispatchProfileAction,
        type,
        profileId,
        isFiled,
        pagination,
        true
      );
    }
  } catch (error) {
    console.log('error', error);
  }
}

interface DeleteProfileItemPayload {
  id: string;
  type: 'problems' | 'solutions' | 'contests' | 'applications';
  profileId: string;
  isFiled: boolean;
  pagination: {
    page: number;
    perPage: number;
  };
}

interface MutualTagsPayload {
  userRank: boolean;
  key: string;
  pagination: {
    page: number;
    perPage: number;
  };
  filter: string;
}

function* deleteProfileItem(action: {
  type: string;
  payload: DeleteProfileItemPayload;
}): Generator<CallEffect | PutEffect, void, boolean> {
  const { id, type, profileId, isFiled, pagination } = action.payload;
  try {
    const response: boolean = yield call(Api.deleteProfileItem, type, id);
    if (response) {
      yield call(
        dispatchProfileAction,
        type,
        profileId,
        isFiled,
        pagination,
        true
      );
    }
  } catch (error) {
    const errorMessage = error.message;
    yield put(Actions.toggleError(errorMessage, type, isFiled, id));
  }
}

function* getProfileMutualTags(action: {
  type: string;
  payload: MutualTagsPayload;
}): Generator<CallEffect | PutEffect, void, boolean> {
  const { userRank, key, pagination, filter } = action.payload;
  try {
    const response = yield call(
      Api.getProfileMutualTags,
      userRank,
      key,
      pagination,
      filter
    );
    if (response) {
      yield put(Actions.getProfileMutualTagsSuccess(response));
    }
  } catch (error) {
    const errorMessage = error.message;
    console.log('Error: ', errorMessage);
    yield put(Actions.getProfileMutualTagsSuccess([]));
  }
}

function* setArchiveItem(action: {
  type: string;
  payload: ActionsItemPayload;
}): Generator<CallEffect, void, boolean> {
  try {
    const { id, type, isArchived, profileId, isFiled, pagination } =
      action.payload;

    // To fetch pin items also
    const getPinItems = isArchived;

    const response: boolean = yield call(
      Api.setArchiveItem,
      id,
      type,
      isArchived
    );
    if (response) {
      yield call(
        dispatchProfileAction,
        type,
        profileId,
        isFiled,
        pagination,
        getPinItems,
        !isArchived
      );
    }
  } catch (error) {
    console.log('error', error);
  }
}

function* editProfileItem(action) {
  try {
    const result = yield call(Api.editProfileItem, action.payload);
    if (result) {
      const {
        profileId,
        type,
        parentType = null,
        pagination,
        getConcepts = false
      } = action.payload;

      yield call(
        dispatchProfileAction,
        parentType ? parentType : type,
        profileId,
        null,
        pagination,
        true,
        false,
        getConcepts
      );
    }
  } catch (error) {
    console.log('error', error);
  }
}

function* editProfile(action) {
  try {
    const result = yield call(Api.editProfile, action.payload);
    if (result) {
      yield put(onboardingActions.getProfile(result?.id));
      yield put(Actions.editProfileSuccess());
    }
  } catch (error) {
    console.log('error', error);
  }
}

function* dispatchProfileAction(
  type,
  profileId,
  isFiled = false,
  pagination = null,
  getPinItems = false,
  isArchived = false,
  getConcepts = false
) {
  switch (type) {
    case 'applications':
      yield put(
        isFiled
          ? Actions.getProfileInventions(
              profileId,
              pagination,
              getPinItems,
              isArchived,
              isFiled
            )
          : Actions.getProfileConcepts(
              profileId,
              pagination,
              getPinItems,
              isArchived
            )
      );
      break;
    case 'problems':
      yield put(
        Actions.getProfileProblems(
          profileId,
          pagination,
          getPinItems,
          isArchived
        )
      );
      break;
    case 'solutions':
      yield put(
        Actions.getProfileSolutions(
          profileId,
          pagination,
          getPinItems,
          isArchived
        )
      );
      break;
    case 'contests':
      yield put(
        Actions.getProfileContests(
          profileId,
          pagination,
          getPinItems,
          isArchived
        )
      );
      break;
    default:
      break;
  }
  if (getConcepts) {
    yield put(onboardingActions.getProfile(profileId));
    yield put(getConcept(profileId));
  }
}

function* getUserRanking(
  action
): Generator<CallEffect | PutEffect, void, Profile> {
  try {
    const data = yield call(Api.getUserRanking, action.payload.userKey);
    if (data) {
      yield put(Actions.getUserRankingSuccess(data));
    }
  } catch (error) {
    console.log(ERRORS.USER_RANKING, error);
    yield put(Actions.getUserRankingSuccess(0));
  }
}

function* getUserIdeaPoints(
  action
): Generator<CallEffect | PutEffect, void, Profile> {
  try {
    const data = yield call(Api.getUserIdeaPoints, action.payload.userId);
    if (data) {
      yield put(Actions.getUserIdeaPointsSuccess(data));
    }
  } catch (error) {
    console.log(ERRORS.USER_IDEAPOINTS, error);
    yield put(Actions.getUserRankingSuccess(0));
  }
}

function* mySaga() {
  yield takeLatest(DELETE_PROFILE_ITEM, deleteProfileItem);
  yield takeLatest(E_FILE_PATENT, eFilePatent);
  yield takeLatest(EDIT_PROFILE_ITEM, editProfileItem);
  yield takeLatest(EDIT_PROFILE, editProfile);
  yield takeLatest(GET_NOTIFICATIONS, getNotifications);
  yield takeLatest(GET_PROFILE_AGREEMENTS_COUNT, getProfileAgreementsCount);
  yield takeLatest(GET_PROFILE_AGREEMENTS, getProfieAgreements);
  yield takeLatest(GET_PROFILE_CONCEPTS_COUNT, getProfileConceptsCount);
  yield takeLatest(GET_PROFILE_CONCEPTS, getProfileConcepts);
  yield takeLatest(GET_PROFILE_CONTESTS_COUNT, getProfileContestsCount);
  yield takeLatest(GET_PROFILE_CONTESTS, getProfileContests);
  yield takeLatest(GET_PROFILE_INVENTIONS_COUNT, getProfileInventionsCount);
  yield takeLatest(GET_PROFILE_INVENTIONS, getProfileInventions);
  yield takeLatest(GET_PROFILE_MUTUAL_TAGS, getProfileMutualTags);
  yield takeLatest(GET_PROFILE_PROBLEMS_COUNT, getProfileProblemsCount);
  yield takeLatest(GET_PROFILE_PROBLEMS, getProfileProblems);
  yield takeLatest(GET_PROFILE_PRODUCTS, getProfileProducts);
  yield takeLatest(GET_PROFILE_SOLUTIONS_COUNT, getProfileSolutionsCount);
  yield takeLatest(GET_PROFILE_SOLUTIONS, getProfileSolutions);
  yield takeLatest(GET_REWARD_HISTORY, getRewardHistory);
  yield takeLatest(GET_UNREAD_NOTIFICATION_COUNT, getUnreadNotificationCount);
  yield takeLatest(GET_USER_IDEAPOINTS, getUserIdeaPoints);
  yield takeLatest(GET_USER_RANKING, getUserRanking);
  yield takeLatest(MARK_ALL_READ, markAllAsRead);
  yield takeLatest(MARK_MULTIPLE_READ, markMultipleAsRead);
  yield takeLatest(NFT_DEPLOY_FINISH, nftDeployFinish);
  yield takeLatest(NFT_DEPLOY_START, nftDeployStart);
  yield takeLatest(SET_ARCHIVE_ITEM, setArchiveItem);
  yield takeLatest(SET_PIN_ITEM, setPinItem);
  yield takeLatest(TOGGLE_READ_STATUS, toggleReadStatus);
}

export default mySaga;
