import React from 'react';
import { StyledIcon } from './StyledComponents';

interface SocialIconButtonProps {
  Icon: React.FC;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const SocialIconButton: React.FC<SocialIconButtonProps> = ({
  Icon,
  onClick
}) => {
  return (
    <StyledIcon onClick={onClick}>
      <Icon />
    </StyledIcon>
  );
};
