import { Box, Divider, Typography, styled } from '@mui/material';
import PsButton from 'components/common/PsButton';
import { colorPalette } from 'theme';

export const CloseIconWrapper = styled(Box)(() => ({
  position: 'absolute',
  top: '15px',
  right: '20px'
}));

export const StyledDivider = styled(Divider)({
  margin: '1rem 0'
});

export const MainContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  backgroundColor: 'white',
  borderRadius: '7px',
  padding: '20px 30px'
});

export const HeaderBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%'
});

export const HeaderText = styled(Typography)({
  whiteSpace: 'nowrap',
  width: '40%',
  color: colorPalette.black,
  fontSize: '26px',
  fontWeight: '700',
  marginRight: '2rem',
  padding: '0'
});

export const NextStepText = styled(Typography)({
  fontSize: '14px',
  fontWeight: '600',
  textDecoration: 'underline',
  color: colorPalette.purple,
  cursor: 'pointer'
});

export const StyledSaveButton = styled(PsButton)({
  borderRadius: '6px !important',
  color: colorPalette.white,
  fontSize: '18px !important',
  fontWeight: 'bold',
  height: '45px',
  outline: 'none',
  padding: '8px 0px!important',
  width: '11.875rem'
});

export const StyledFooterBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between'
});
