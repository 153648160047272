import React, { useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { PsFormControl } from 'components/common/PsFormControl';
import { PsInput } from 'components/common/PsInput';
import { ModalDataContext } from 'contexts/ModalDataContext';
import { ImageField } from 'components/ImageField';
import styles from 'modals/ModalWindow.module.scss';
import { PsTheme } from '../../../theme';

type ClassKey = 'root';

const ContestDescriptionView = () => {
  const { values, handleInputChange } = useContext(ModalDataContext);
  const { descriptionContest } = values;

  return (
    <div className={styles.stepWrapper}>
      <PsFormControl
        placeholder="Type Your Description here"
        label="Describe Your Contest"
        labelInfo={true}
        helperText="Example: This Contest  is dedicated to tackling  problems and creating solutions and inventions related to hunger."
      >
        <PsInput
          multiline
          onChange={handleInputChange}
          rows={4}
          value={descriptionContest}
          name="descriptionContest"
        />
      </PsFormControl>

      <ImageField
        name="filesContest"
        value={values.filesContest}
        onChange={handleInputChange}
      />
    </div>
  );
};

export const ContestDescription = styled(ContestDescriptionView)({});

export default ContestDescription;
