import React, { useContext, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { AuthContext } from 'contexts/AuthContext';
import { ModalComponentsKeys, ModalContext } from 'contexts/ModalContext';
import { ModalDataContext } from 'contexts/ModalDataContext';
import { PsButton } from 'components/common/PsButton';
import { PsFormControl, ShortEvent } from 'components/common/PsFormControl';
import { PsInput } from 'components/common/PsInput';
import { CardImage } from 'components/card/CardImage';
import { ProblemIcon } from 'components/icons/ProblemIcon';
import { SolutionIcon } from 'components/icons/SolutionIcon';
import { InventionIcon } from 'components/icons/InventionIcon';
import { ContestIcon } from 'components/icons/ContestIcon';
import { defaultProblemTitle } from 'modals/AddNewProblem/config';
import { defaultSolutionTitle } from 'modals/AddNewSolution/config';
import { defaultInventionTitle } from 'modals/AddNewApplication/config';
import { PlusCircleIcon } from 'assets/icons';
import { Picture, placeholderType } from '../../helpers';
import { PsTheme } from '../../theme';
import { makeStyles } from 'tss-react/mui';

const useCardImageStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      width: '80px',
      padding: '0',
      [psTheme.breakpoints.down('sm')]: {
        width: '80px'
      }
    },
    inner: {
      paddingTop: '100%',
      [psTheme.breakpoints.down('sm')]: {
        paddingTop: '100%'
      },
      [psTheme.breakpoints.down('xs')]: {
        paddingTop: '100%'
      }
    },
    placeholder: {
      borderRadius: 15
    },
    img: {
      borderRadius: 15
    }
  };
});

type ClassKey =
  | 'quickActions'
  | 'quickActionsHeader'
  | 'quickActionsAva'
  | 'quickActionsInput'
  | 'quickActionsButton'
  | 'quickActionsFooter';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    quickActions: {
      background: '#ffffff',
      border: '1px solid rgba(121, 114, 140, 0.11)',
      boxSizing: 'border-box',
      boxShadow:
        '0px 4px 8px rgba(10, 0, 32, 0.02), 0px 4px 84px rgba(7, 0, 21, 0.04)',
      borderRadius: 15,
      padding: '30px 30px 13px',
      marginBottom: 40
    },
    quickActionsHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 20,
      paddingBottom: 25,
      borderBottom: 'solid 1px #E8ECF0'
    },
    quickActionsAva: {
      flexShrink: 0,
      marginRight: 20
    },
    quickActionsInput: {
      marginBottom: 0,
      marginRight: 20,
      '& > div': {
        marginTop: 0
      }
    },
    quickActionsButton: {
      flexShrink: 0,
      width: 56,
      height: 56,
      minWidth: 56,
      padding: 0,
      backgroundColor: psTheme.palette.secondary.main,
      '&:hover': {
        backgroundColor: psTheme.palette.secondary.main
      }
    },
    quickActionsFooter: {
      color: '#000',
      '& ul': {
        listStyle: 'none',
        margin: 0,
        padding: 0
      },
      '& li': {
        display: 'inline-block',
        marginRight: 30,
        marginBottom: 10
      },
      '& li > span': {
        cursor: 'pointer',
        width: '100%',
        display: 'inline-flex',
        alignItems: 'center',
        textDecoration: 'none',
        padding: '8px 11px',
        borderRadius: '7px',
        color: 'inherit',
        transition: 'color 0.3s ease'
      },
      '& li > span:hover': {
        color: psTheme.palette.primary.main
      },
      '& li > span.active': {
        background: '#F6F4FB'
      },
      '& li > span > svg': {
        marginRight: 10
      }
    }
  };
});

type QuickActionsProps = {
  classes?: any;
  className?: string;
};

const actionsType = {
  PROBLEM: 'problem',
  SOLUTION: 'solution',
  INVENTION: 'invention',
  CONTEST: 'contest'
};

const QuickActionsView = ({ classes, className }: QuickActionsProps) => {
  const { user } = useContext(AuthContext);
  const { openModal } = useContext(ModalContext);
  const { setType, updateField } = useContext(ModalDataContext);
  const [title, setTitle] = useState('');
  const [activeType, setActiveType] = useState(actionsType.PROBLEM);
  const quickActionCardImageStyles = useCardImageStyles();

  let userImage: Picture | undefined = undefined;
  let userUrl = '';
  if (user) {
    userImage = user.files ? user.files[0] : undefined;
    userUrl = `/profiles/${user.key}`;
  }

  let showContests = false;
  if (
    user &&
    user.userData &&
    user.userData.moderator &&
    user.userData.moderator.length
  ) {
    showContests = true;
  }

  const selectActiveType = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    const type = e.currentTarget.dataset.type || '';
    setActiveType(type);
  };

  const onTitleChange = (e: ShortEvent) => {
    const { value = '' } = e.target;
    setTitle(value);
  };

  const onFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    switch (activeType) {
      case actionsType.PROBLEM: {
        updateField('titleProblem', `${defaultProblemTitle}${title}`);
        setType(ModalComponentsKeys.addNewProblem);
        openModal(ModalComponentsKeys.addNewProblem);
        break;
      }
      case actionsType.SOLUTION: {
        updateField('titleSolution', `${defaultSolutionTitle}${title}`);
        setType(ModalComponentsKeys.addNewSolution);
        openModal(ModalComponentsKeys.addNewSolution);
        break;
      }
      case actionsType.INVENTION: {
        updateField('titleApplication', `${defaultInventionTitle}${title}`);
        setType(ModalComponentsKeys.addNewInvention);
        openModal(ModalComponentsKeys.addNewInvention);
        break;
      }
      case actionsType.CONTEST: {
        updateField('title', title);
        setType(ModalComponentsKeys.addNewContest);
        openModal(ModalComponentsKeys.addNewContest);
        break;
      }
    }
  };

  let rootClassName = classes.quickActions;
  if (className) {
    rootClassName += ' ' + className;
  }

  return (
    <div className={rootClassName}>
      <form className={classes.quickActionsHeader} onSubmit={onFormSubmit}>
        <CardImage
          className={classes.quickActionsAva}
          // classes={quickActionCardImageStyles}
          href={userUrl}
          image={userImage}
          type={placeholderType.U}
        />
        <PsFormControl
          className={classes.quickActionsInput}
          placeholder="What is your idea?"
        >
          <PsInput value={title} onChange={onTitleChange} />
        </PsFormControl>
        <PsButton
          // onClick={toggleModal}
          disableElevation
          className={classes.quickActionsButton}
          type="submit"
        >
          <img src={PlusCircleIcon} alt="plus icon" />
        </PsButton>
      </form>
      <div className={classes.quickActionsFooter}>
        <ul>
          <li>
            <span
              className={activeType === actionsType.PROBLEM ? 'active' : ''}
              onClick={selectActiveType}
              data-type={actionsType.PROBLEM}
            >
              <ProblemIcon size={20} />
              <span>Problem</span>
            </span>
          </li>
          <li>
            <span
              className={activeType === actionsType.SOLUTION ? 'active' : ''}
              onClick={selectActiveType}
              data-type={actionsType.SOLUTION}
            >
              <SolutionIcon size={20} />
              <span>Solution</span>
            </span>
          </li>
          <li>
            <span
              className={activeType === actionsType.INVENTION ? 'active' : ''}
              onClick={selectActiveType}
              data-type={actionsType.INVENTION}
            >
              <InventionIcon size={20} />
              <span>Invention</span>
            </span>
          </li>
          {showContests ? (
            <li>
              <span
                className={activeType === actionsType.CONTEST ? 'active' : ''}
                onClick={selectActiveType}
                data-type={actionsType.CONTEST}
              >
                <ContestIcon size={20} />
                <span>Contest</span>
              </span>
            </li>
          ) : null}
        </ul>
      </div>
    </div>
  );
};

export const QuickActions = styled(QuickActionsView)({});

export default QuickActions;
