import React, { memo, useCallback } from 'react';
import { IconButton } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { IoClose } from 'react-icons/io5';
import { colorPalette } from 'theme';
import { Constants, PATH_NAMES, VARIANT } from 'utilities/constants';
import {
  IconWrapper,
  SolutionContainer,
  SolutionIcon
} from './styledComponents';
import { useDispatch } from 'react-redux';
import { GetUser } from 'redux-state/selectors';
import Actions from 'redux-state/actions';
import { EntityIcon } from '../TreeGraph/EntityIcon';
import { TeaserText } from '../ActiveConceptCard/styledComponents';
import { Application } from '../CardApplication';
import { Solution } from '../CardSolution';

interface SolutionBoxProps {
  concept: Application;
  solution: Solution;
}

export const SolutionBox = memo(
  ({ concept, solution }: SolutionBoxProps) => {
    const dispatch = useDispatch();
    const user = GetUser();

    const handleSolutionDelete = useCallback(
      (solutionId) => {
        const selected = concept.solutions
          .filter((sol) => sol.id !== solutionId)
          .map(({ id }) => id);

        const problems = concept.problems
          .filter((problem) =>
            problem?.children?.some((child) => selected?.includes(child.id))
          )
          .map(({ id }) => id);

        if (user) {
          dispatch(Actions.togLoadSolution(true));
          dispatch(
            Actions.updateConcept(concept.id, { selected, problems }, false)
          );
        } else {
          dispatch(Actions.openAuthModal(true));
        }
      },
      [dispatch, concept, user]
    );

    return (
      <AnimatePresence>
        <motion.div
          initial={{ x: '100vw', opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: '-100vw', opacity: 0 }}
          transition={{ duration: 0.5, ease: VARIANT.EASE_IN_OUT }}
          key={solution.key}
        >
          <SolutionContainer
            onClick={() =>
              window.open(
                `${PATH_NAMES.SOLUTIONS}/${solution.key}`,
                Constants._BLANK
              )
            }
          >
            <IconWrapper>
              <SolutionIcon>
                <EntityIcon type={Constants.SOLUTION} />
              </SolutionIcon>
              <TeaserText>{solution.teaser || solution.title}</TeaserText>
            </IconWrapper>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleSolutionDelete(solution.id);
              }}
            >
              <IoClose size={22} color={colorPalette.black} />
            </IconButton>
          </SolutionContainer>
        </motion.div>
      </AnimatePresence>
    );
  },
  (prevProps, nextProps) => prevProps.solution === nextProps.solution
);

// Set display name for debugging
SolutionBox.displayName = 'SolutionBox';
