import React, { FC } from 'react';
import { StatusBox, StatusTypography } from './styledComponents';

interface StatusBoxProps {
  backgroundColor: string;
  color: string;
  status: string;
}

export const StatusView: FC<StatusBoxProps> = ({
  backgroundColor,
  color,
  status
}) => {
  return (
    <StatusBox backgroundColor={backgroundColor}>
      <StatusTypography color={color}>{status}</StatusTypography>
    </StatusBox>
  );
};
