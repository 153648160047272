import React, { useCallback, useContext, useEffect, useState } from 'react';
import { alpha, styled, useTheme } from '@mui/material/styles';

import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import TextModal from 'components/common/TextModal';
import { Radio } from 'components/Radio/Radio';
import { AuthContext } from 'contexts/AuthContext';
import { SubscriptionOption } from 'contexts/DataContext';
import { ModalDataContext } from 'contexts/ModalDataContext';
import dataProvider from 'dataPrvider';
import { getIPAddress } from 'helpers/getIPAddress';
import styles from 'modals/ModalWindow.module.scss';
import { PsTheme } from 'theme';
import { makeStyles } from 'tss-react/mui';

const useCheckboxStyles = makeStyles()(() => {
  const psTheme = useTheme() as PsTheme;
  return {
    root: {
      '& a': {
        color: psTheme.palette.primary.main
      }
    }
  };
});

const PsCheckboxView = (props: CheckboxProps) => {
  const { classes } = useCheckboxStyles();
  const newProps = { ...props, classes };
  return <Checkbox color="default" {...newProps} />;
};
const PsCheckbox = styled(PsCheckboxView)({});

type ClassKey = 'root' | 'title' | 'text';

const useRadioTextStyles = makeStyles()(() => {
  const psTheme = useTheme() as PsTheme;
  return {
    root: {},
    title: {
      fontWeight: 600
    },
    text: {
      '& b': {
        fontWeight: 600,
        color: psTheme.palette.primary.main
      }
    }
  };
});

type RadioTextProps = {
  option: SubscriptionOption;
  name: string | undefined;
};

const RadioText = styled(({ option, name = '' }: RadioTextProps) => {
  const { classes } = useRadioTextStyles();

  let title = `${option.price}/month Basic Subscription`;
  let text = (
    <>
      Control company branding, respond to users, priority purchase of NFTs
      related to inventions tagging <b>{name}</b>
    </>
  );
  if (option.type === 'companyTop') {
    title = `${option.price}/month Premium Subscription`;
    text = (
      <>
        All basic features, plus ask users for solutions over prioritized
        problems, allow up to 5 company users to create unlimited patent
        applications, and control visibility over items tagging <b>{name}</b>
      </>
    );
  }
  return (
    <div className={classes.root}>
      <div className={classes.title}>{title}</div>
      <div className={classes.text}>{text}</div>
    </div>
  );
})();

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  return {
    root: {
      borderRadius: 8,
      margin: '8px 0',
      border: `solid 1px ${alpha(theme.palette.primary.main, 0.15)}`,
      background: 'rgb(248, 245, 255, 0.5)',
      color: theme.palette.primary.main,
      '&:hover': {
        border: `solid 1px ${alpha(theme.palette.primary.main, 0.5)}`
      }
    }
  };
});

const SelectPlanStep: React.FC = () => {
  const { user } = useContext(AuthContext);
  const { values, updateField } = useContext(ModalDataContext);
  const { classes } = useStyles();
  const [termsAgree, setTermsAgree] = useState(values.termsAgree);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>('');

  let options: Array<SubscriptionOption> = [];
  if (user && user.userData && user.userData.prices) {
    options = user.userData.prices
      .filter((op) => op.type.startsWith('company'))
      .sort((a, b) => {
        return a.type === 'companyTop' ? 1 : -1;
      });
  }

  const [selectedEntity, setSelectedEntity] = useState<string | undefined>(
    `${values.finalizeType}`
  );

  const handleEntityChange = (e: React.ChangeEvent, value: string) => {
    setSelectedEntity(value);
    const selected = options.find((item) => item.type === value);
    updateField('finalizeType', selected ? selected.type : '');
  };

  const onTermsChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setTermsAgree(event.target.checked);
      updateField('termsAgree', event.target.checked);
    },
    [setTermsAgree, updateField]
  );

  useEffect(() => {
    const modalTitle = (
      <>
        <b>{values.parentProblemLabel}</b> MindMiner Subscription
      </>
    );
    updateField('modalTitle', modalTitle);
  }, [values.parentProblemLabel]);

  const onTermsClick = useCallback(async (e: React.MouseEvent) => {
    try {
      e.preventDefault();
      const currentDate = new Date().toISOString().split('T')[0];
      const ipAddress = await getIPAddress();
      const data = { title: values.parentProblemLabel, ipAddress, currentDate };
      const response = await dataProvider.getTermsAndConditionsDoc(data);
      setModalText(response?.data || '');
      setShowModal(true);
    } catch (error) {
      console.error('Error generating terms and conditions:', error);
    }
  }, []);

  return (
    <div className={styles.stepWrapper}>
      <RadioGroup
        onChange={handleEntityChange}
        value={selectedEntity}
        aria-label="entity"
        name="entity"
      >
        {options.map((option) => {
          return (
            <FormControlLabel
              className={styles.payOption}
              key={option.type}
              classes={classes}
              value={option.type}
              control={<Radio />}
              label={
                <RadioText option={option} name={values.parentProblemLabel} />
              }
            />
          );
        })}
      </RadioGroup>

      <FormControlLabel
        className={styles.terms}
        control={
          <PsCheckbox
            checked={termsAgree}
            onChange={onTermsChange}
            name="checkedG"
          />
        }
        label={
          <>
            I agree to the{' '}
            <a href="#" onClick={onTermsClick}>
              terms and conditions
            </a>{' '}
            and that I am an authorized representative of{' '}
            <b>{values.parentProblemLabel}</b>. I understand that this payment
            is nonrefundable.
          </>
        }
      />
      {showModal && (
        <TextModal
          open={showModal}
          onClose={() => setShowModal(false)}
          text={modalText}
        />
      )}
    </div>
  );
};

export default SelectPlanStep;
