import React, { useCallback } from 'react';
import { CloudUpload } from '@mui/icons-material';
import { useDropzone } from 'react-dropzone';
import { colorPalette } from 'theme';
import { MainBox, StyledSpan, StyledTypography } from './styledComponents';

interface DropzoneProps {
  fileTypes: Array<string>;
  maxFiles: number;
  setFiles: React.Dispatch<React.SetStateAction<any>>;
}

export const Dropzone: React.FC<DropzoneProps> = ({
  fileTypes,
  maxFiles,
  setFiles
}) => {
  const onDrop = useCallback((acceptedFiles) => {
    const pdfFiles = acceptedFiles.filter((file) =>
      fileTypes.includes(file.type)
    );
    if (maxFiles == 1) {
      setFiles(() => {
        const file = [...pdfFiles];
        return file[0];
      });
    } else {
      setFiles((prevFiles) => {
        const combinedFiles = [...pdfFiles, ...prevFiles];
        return combinedFiles.slice(0, maxFiles);
      });
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles
  });

  return (
    <>
      <MainBox {...getRootProps()}>
        <input {...getInputProps()} />
        <CloudUpload
          sx={{
            color: colorPalette.purple,
            width: '3.25rem',
            height: '3.25rem'
          }}
        />
        <StyledTypography>
          {!isDragActive ? (
            <>
              <StyledSpan component="span">Browse</StyledSpan> or Drag file here
            </>
          ) : (
            'Drop files here'
          )}
        </StyledTypography>
      </MainBox>
    </>
  );
};
