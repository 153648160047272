import React from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from '@mui/material/Select';
import { colorPalette } from 'theme';
import { VARIANT } from 'utilities/constants';
import { StyledSelect } from './styledComponents';

interface WalletSelectProps {
  value: string;
  setValue: (value: string) => void;
  options: Array<string>;
  backgroundColor?: string;
}

export const WalletSelect: React.FC<WalletSelectProps> = ({
  value,
  setValue,
  options,
  backgroundColor
}) => {
  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value as string);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth variant={VARIANT.OUTLINED}>
        <StyledSelect
          value={value}
          onChange={handleChange}
          bgColor={backgroundColor}
          MenuProps={{
            PaperProps: {
              sx: {
                mt: 0.2,
                backgroundColor: colorPalette.white,
                borderRadius: '.4375rem',
                boxShadow: '0rem .125rem .375rem rgba(0, 0, 0, 0.12)',
                '& .MuiMenuItem-root': {
                  padding: '.3125rem .9375rem',
                  '&:hover': {
                    backgroundColor: colorPalette.ashWhite
                  },
                  '&:not(:last-child)': {
                    borderBottom: `.0625rem solid ${colorPalette.ashWhite}`
                  }
                }
              }
            }
          }}
        >
          {options.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </Box>
  );
};
