import React, { useState, useContext, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { DataSource } from 'components/TreeGraph/types';
import { PsOptionType, PsSelect } from 'components/common/PsSelect';
import { PsFormControl, ShortEvent } from 'components/common/PsFormControl';
import { Problem, CardProblemDetail } from 'components/CardProblem';
import { Solution, CardSolutionDetail } from 'components/CardSolution';
import { Application, CardApplicationDetail } from 'components/CardApplication';
import { DataContext } from 'contexts/DataContext';
import { GraphChart } from './GraphChart';

import { PsTheme } from '../../theme';
import { makeStyles } from 'tss-react/mui';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import { GetOneResult } from '../../dataPrvider';

interface ISolutionTitles {
  id: string | number;
  title: string;
  teaser: string;
}

const hasSomeTag = (itemTags: string[], allTags: string[]): boolean => {
  let res = false;
  (itemTags || []).forEach((itemTag) => {
    const has = allTags.some((allTag) => allTag === itemTag);
    if (has) {
      res = true;
    }
  });
  return res;
};

type ClassKey =
  | 'root'
  | 'title'
  | 'buttons'
  | 'drawer'
  | 'drawerLoading'
  | 'drawerHeader'
  | 'drawerHeaderCancel'
  | 'drawerHeaderOpen'
  | 'drawerContent'
  | 'drawerContentType';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      padding: '0 15px 20px'
    },
    title: {
      fontSize: '30px',
      fontWeight: 600,
      marginBottom: 10,
      textAlign: 'center'
    },
    buttons: {
      width: 1,
      '& [aria-label="Next"]': {
        width: 48,
        marginLeft: -53
      },
      '& [aria-label="Previous"]': {
        width: 48,
        marginLeft: 5
      }
    },
    drawer: {},
    drawerLoading: {
      padding: 20,
      textAlign: 'center'
    },
    drawerHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    drawerHeaderCancel: {},
    drawerHeaderOpen: {
      padding: '0 20px',
      '& a': {
        color: psTheme.palette.primary.main,
        textDecoration: 'none'
      },
      '& a:hover': {
        textDecoration: 'underline'
      }
    },
    drawerContent: {
      padding: 10
    },
    drawerContentType: {
      marginBottom: 10,
      padding: '4px 10px',
      color: 'white',
      fontWeight: 'bold',
      textAlign: 'center',
      textTransform: 'capitalize',
      '&.problem': {
        background: '#632DDD'
      },
      '&.solution': {
        background: '#FBCA67'
      },
      '&.invention': {
        background: '#07A854'
      }
    }
  };
});

export type NodeType = {
  title: string;
  type: string; // profile, problem, solution
  id: string;
  key?: string;
  image?: string;
  tags: string[];
  big?: boolean;
};

export type LinkType = {
  source: string;
  target: string;
};

export type GraphData = {
  nodes: Array<NodeType>;
  links: Array<LinkType>;
  nodesTree: DataSource;
};

const getNodeUrl = (node: NodeType): string => {
  // nft url
  let url = node.title;
  if (node.type === 'problem') {
    url = `/problems/${node.key}`;
  } else if (node.type === 'solution') {
    url = `/solutions/${node.key}`;
  } else if (node.type === 'invention') {
    url = `/inventions/${node.key}`;
  }
  return url;
};

type GraphDepsProps = {
  className?: string;
  nodes: Array<NodeType>;
  links: Array<LinkType>;
};

const GraphDepsView = ({
  className,
  nodes: initNodes,
  links: initLinks
}: GraphDepsProps) => {
  const { classes } = useStyles();
  const { dataProvider } = useContext(DataContext);

  const [nodes, setNodes] = useState<Array<NodeType>>(initNodes);
  const [links, setLinks] = useState<Array<LinkType>>(initLinks);

  const [tags, setTags] = useState<Array<PsOptionType>>([]);
  const [tagsValue, setTagsValue] = useState<string>('');

  const [loading, setLoading] = useState(false);
  const [type, setType] = useState<string>('');
  const [item, setItem] = useState<
    Problem | Solution | Application | undefined
  >();

  const [isOpened, setIsOpened] = useState(false);

  // const drawerClasses = useDrawerStyles();

  useEffect(() => {
    const tagsSet = new Set();
    initNodes.forEach((node) => {
      if (!node.tags || !node.tags.length) {
        const noTag = '(no tags)';
        node.tags = [noTag];
        tagsSet.add(noTag);
      } else {
        node.tags.forEach((tag) => {
          tagsSet.add(tag);
        });
      }
    });

    // @ts-ignore
    const ttt = [...tagsSet];
    ttt.sort();
    // setTags(ttt.map((tag) => ({ label: tag, value: tag })));
    setTagsValue(ttt.join('[$]'));
  }, [initNodes, initLinks]);

  useEffect(() => {
    const selectedTags = tagsValue.split('[$]');

    const newNodes = initNodes
      .map((node) => {
        const hasTag = hasSomeTag(node.tags, selectedTags);
        return hasTag ? node : undefined;
      })
      .filter((el) => el) as Array<NodeType>;

    const newLinks = initLinks
      .map((link) => {
        const hasTarget = newNodes.some((node) => node.id === link.target);
        const hasSource = newNodes.some((node) => node.id === link.source);
        return hasTarget && hasSource ? link : undefined;
      })
      .filter((el) => el) as Array<LinkType>;

    setNodes(newNodes);
    setLinks(newLinks);
  }, [tagsValue]);

  const onNodeClick = async (node: NodeType) => {
    if (node.big) {
      return;
    }

    if (
      node.type !== 'problem' &&
      node.type !== 'solution' &&
      node.type !== 'invention'
    ) {
      const url = getNodeUrl(node);
      window.open(url, '_blank');
      return;
    }

    setItem(undefined);
    setType('');
    setLoading(true);
    setIsOpened(true);

    let response:
      | GetOneResult<Problem>
      | GetOneResult<Solution>
      | GetOneResult<Application>
      | undefined = undefined;

    switch (node.type) {
      case 'problem': {
        response = await dataProvider.getOne<Problem>('problems', {
          id: node.id
        });
        break;
      }
      case 'solution': {
        response = await dataProvider.getOne<Solution>('solutions', {
          id: node.id
        });
        break;
      }
      case 'invention': {
        response = await dataProvider.getOne<Application>('applications', {
          id: node.id
        });
        break;
      }
    }

    if (response) {
      setItem(response.data);
    }
    setType(node.type);
    setLoading(false);
  };

  const onTagsChange = (event: ShortEvent) => {
    const { value } = event.target;
    setTagsValue(value);
  };

  const onDrawerCloseClick = () => {
    setIsOpened(false);
  };

  let rootClassname = classes.root;
  if (className) {
    rootClassname += ' ' + className;
  }

  const [solutionTitles, setSolutionTitles] = useState<ISolutionTitles[]>([]);

  useEffect(() => {
    const solutionsIds = item?.solutions;
    const titles: ISolutionTitles[] = [];

    const getSolutions = async (id: any) => {
      const { data } = await dataProvider.getOne<Solution>('solutions', {
        id
      });
      if (data.title && data.teaser) {
        titles.push({ id: data.id, title: data.title, teaser: data.teaser });
      }
      setSolutionTitles(titles);
    };

    if (solutionsIds) {
      solutionsIds.map((item: any) => getSolutions(item));
    }
  }, [dataProvider, item?.solutions]);

  return (
    <div className={rootClassname}>
      <PsFormControl placeholder="Tags?" label="">
        <PsSelect
          isMulti
          maxMenuHeight={270}
          onChange={onTagsChange}
          options={tags}
          separator="[$]"
          value={tagsValue}
        />
      </PsFormControl>

      <GraphChart
        nodesData={nodes}
        linksData={links}
        onNodeClick={onNodeClick}
      />

      <Drawer
        anchor="right"
        open={isOpened}
        onClose={onDrawerCloseClick}
        // classes={drawerClasses}
      >
        {loading ? (
          <Typography className={classes.drawerLoading}>Loading...</Typography>
        ) : null}

        {item ? (
          <>
            <div className={classes.drawerHeader}>
              <div className={classes.drawerHeaderCancel}>
                <IconButton onClick={onDrawerCloseClick}>
                  <CloseIcon fontSize="large" />
                </IconButton>
              </div>
              <div className={classes.drawerHeaderOpen}>
                <Typography>
                  <Link to={`${type}s/${item.key}`}>
                    <a target="_blank">Open in a new window</a>
                  </Link>
                </Typography>
              </div>
            </div>
            <div className={classes.drawerContent}>
              <Typography className={`${classes.drawerContentType} ${type}`}>
                {type}
              </Typography>
              {type === 'problem' ? (
                <CardProblemDetail problem={item as Problem} />
              ) : null}
              {type === 'solution' ? (
                <CardSolutionDetail
                  solution={item as Solution}
                  solutionTitles={solutionTitles}
                />
              ) : null}
              {type === 'invention' ? (
                <CardApplicationDetail application={item as Application} />
              ) : null}
            </div>
          </>
        ) : null}
      </Drawer>
    </div>
  );
};

export const GraphDeps = styled(GraphDepsView)({});

export default GraphDeps;
