import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Link } from 'react-router-dom';
import { History } from '@mui/icons-material';
import {
  Avatar,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  styled
} from '@mui/material';
import ContestIcon from 'assets/icons/contests.svg';
import InventionsIcon from 'assets/icons/invention.svg';
import ProblemIcon from 'assets/icons/problems.svg';
import ProductIcon from 'assets/icons-new/product.svg';
import SolutionIcon from 'assets/icons/solutions.svg';
import { CustomScrollbar } from 'components/PerfectScrollbar';
import { StyledTabIcon } from 'components/TabsView/styles';
import HomeIcon from 'components/icons/HomeIcon';
import AuthContext from 'contexts/AuthContext';
import { getTabHistory } from 'helpers/tabHistory';
import useRouter from 'hooks/useRouter';
import { Constants, PATH_NAMES } from 'utilities/constants';

/* eslint-disable max-lines-per-function */
const NavigationHistory = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [urlList, setUrlList] = useState<string[]>([]);
  const { pathname } = useRouter();
  const { user } = useContext(AuthContext);

  const icons = useMemo(
    () => ({
      [Constants.PROFILES]: <StyledProfile src={user?.files[0]?.url || ''} />,
      [Constants.SOLUTIONS]: (
        <StyledTabIcon src={SolutionIcon} isMediumScreen={false} />
      ),
      [Constants.PROBLEMS]: (
        <StyledTabIcon src={ProblemIcon} isMediumScreen={false} />
      ),
      [Constants.INVENTIONS]: (
        <StyledTabIcon src={InventionsIcon} isMediumScreen={false} />
      ),
      [Constants.CONTESTS]: (
        <StyledTabIcon src={ContestIcon} isMediumScreen={false} />
      ),
      [Constants.PRODUCTS]: (
        <StyledTabIcon src={ProductIcon} isMediumScreen={false} />
      ),
      [Constants.HOME]: <HomeIcon />
    }),
    [user?.files]
  );

  useEffect(() => {
    const urls = getTabHistory();
    setUrlList(urls?.reverse() || []);
  }, []);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const getIconType = (url: string) => {
    const urlMappings: Record<string, string> = {
      [PATH_NAMES.PROFILES]: Constants.PROFILES,
      [PATH_NAMES.SOLUTIONS]: Constants.SOLUTIONS,
      [PATH_NAMES.PROBLEMS]: Constants.PROBLEMS,
      [PATH_NAMES.INVENTIONS]: Constants.INVENTIONS,
      [PATH_NAMES.CONTESTS]: Constants.CONTESTS,
      [PATH_NAMES.PRODUCTS]: Constants.PRODUCTS
    };

    for (const [key, value] of Object.entries(urlMappings)) {
      if (url.includes(key)) {
        return value;
      }
    }
    return Constants.HOME;
  };

  const getNameFromUrl = (url: string) =>
    toTitleCase(url.substring(url?.lastIndexOf('/') + 1)) || Constants.HOME;

  const toTitleCase = (str: string) =>
    str
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');

  const truncateName = (name: string, maxLength = 20): string =>
    name.length > maxLength ? `${name.substring(0, maxLength)}...` : name;

  return (
    <>
      <IconButton onClick={handleClick} className="HistoryBtn">
        <History />
      </IconButton>
      <CustomScrollbar>
        <StyledMenu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {urlList.map((url) => (
            <MenuItem
              disabled={pathname === url}
              key={url}
              component={Link}
              to={url}
            >
              <ListItemIcon sx={{ alignItems: 'center' }}>
                {icons[getIconType(url)]}
              </ListItemIcon>
              <ListItemText primary={truncateName(getNameFromUrl(url))} />
            </MenuItem>
          ))}
        </StyledMenu>
      </CustomScrollbar>
    </>
  );
};

const StyledProfile = styled(Avatar)({
  borderRadius: '100%',
  position: 'relative',
  marginRight: '0.675rem',
  width: '1.875rem',
  height: '1.875rem'
});

const StyledMenu = styled(Menu)({
  maxHeight: '25rem'
});

export default NavigationHistory;
/* eslint-disable max-lines-per-function */
