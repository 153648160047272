import React, { FC } from 'react';
import { Box } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { DragSourceMonitor, useDrag } from 'react-dnd';
import { useDispatch } from 'react-redux';
import Actions from 'redux-state/actions';
import { colorPalette } from 'theme';
import { Constants } from 'utilities/constants';
import { GetSolutionLoader, GetUser } from 'redux-state/selectors';
import { Node, NodeItemProps } from 'interface/common';
import NodeContent from './NodeContent';
import { styled } from '@mui/material/styles';

export const NodeItem: FC<NodeItemProps> = React.memo(
  ({
    activeConcept,
    addNewConcept,
    buttonList,
    conceptData,
    graphData,
    ideaMapType,
    image,
    isConceptDrawerOpen = false,
    isCurrentConceptSolution = false,
    isParentNode = false,
    messageId,
    node,
    openAddToConceptModal,
    selectedNode,
    setDrawerItem,
    setDrawerItemType,
    setIsDetailDrawer,
    setIsSolutionModalOpen,
    setProblem,
    showAvatar = true,
    VotingSection
  }) => {
    const renderNodeContent = () => (
      <NodeContent
        addNewConcept={addNewConcept}
        buttonList={buttonList}
        graphData={graphData}
        ideaMapType={ideaMapType}
        image={image}
        isConceptDrawerOpen={isConceptDrawerOpen}
        isCurrentConceptSolution={isCurrentConceptSolution}
        isParentNode={isParentNode}
        messageId={messageId}
        node={node}
        openAddToConceptModal={openAddToConceptModal}
        selectedNode={selectedNode}
        setDrawerItem={setDrawerItem}
        setDrawerItemType={setDrawerItemType}
        setIsDetailDrawer={setIsDetailDrawer}
        setIsSolutionModalOpen={setIsSolutionModalOpen}
        setProblem={setProblem}
        showAvatar={showAvatar}
        VotingSection={VotingSection}
      />
    );

    return <>{renderNodeContent()}</>;
  }
);
