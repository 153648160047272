import {
  Box,
  BoxProps,
  Button,
  Grid,
  Typography,
  TypographyProps
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { colorPalette } from '../../theme';

interface StyledBoxProps extends BoxProps {
  isMediumScreen: boolean;
}

interface StyledTypographyProps extends TypographyProps {
  isMediumScreen: boolean;
}

export const MainAccordionContainer = styled(Grid)({
  margin: '0 auto'
});

export const AccordionContainer = styled(Box)({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  height: '233px',
  justifyContent: 'space-between',
  marginLeft: '1%'
});

export const StyledTagActionsBox = styled(Box)({
  bottom: '60px',
  margin: '0px',
  position: 'absolute',
  right: '4px'
});

export const ViewMoreButtonContent = styled(Box)({
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center'
});

export const ViewMoreButtonContainer = styled(Box)({
  backgroundColor: colorPalette.ashWhite,
  display: 'flex',
  height: '24px',
  justifyContent: 'center',
  marginTop: '2px',
  paddingBottom: '5px'
});

export const MainBox = styled(Box)<StyledBoxProps>(({ isMediumScreen }) => ({
  alignItems: 'center',
  boxShadow: '0px 0px 11px rgba(0,0,0,0.2)',
  display: 'flex',
  flexDirection: 'row',
  height: isMediumScreen ? '70px' : '145px',
  justifyContent: 'space-between',
  overflow: 'hidden',
  padding: isMediumScreen ? null : '30px 0px 20px 80px',
  position: 'relative',
  width: 'auto'
}));

export const StyledBlock = styled(Box)({
  background: 'rgba(233, 232, 226, 0.9)',
  borderRadius: 40,
  padding: '7px 12px',
  width: '240px'
});

export const EarningsBlock = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '96%'
});

export const StyledBlockTitle = styled(Typography)({
  color: colorPalette.black,
  fontSize: 18,
  fontWeight: 600
});

export const EarningsContainer = styled(Box)({
  bottom: '25px',
  color: colorPalette.white,
  margin: '0px',
  position: 'absolute',
  right: '16px'
});

export const MainContent = styled(Box)<StyledBoxProps>(
  ({ isMediumScreen }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '61%',
    marginLeft: isMediumScreen ? '1%' : '5%'
  })
);

export const StyledContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: '10px'
});

export const InfoContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center'
});

export const IconsBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  marginRight: '7px'
});

export const StyledEditCursor = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  background: colorPalette.lightPurple,
  borderRadius: '20px',
  cursor: 'pointer',
  height: '35px',
  width: '35px',
  '@media (max-width:900px)': {
    height: '30px',
    width: '30px'
  }
});

export const StyledShareCursor = styled(Box)({
  alignSelf: 'center',
  background: colorPalette.lightPurple,
  borderRadius: '20px',
  cursor: 'pointer'
});

export const BlueDiagonal = styled(Box)({
  background: colorPalette.purple,
  border: '3px',
  borderRadius: '3px',
  height: '10px',
  left: '-138px',
  overflow: 'hidden',
  position: 'absolute',
  top: '32px',
  transform: 'rotate(67.5deg)',
  width: '290px'
});

export const WhiteDiagonal = styled(Box)({
  background: colorPalette.white,
  border: '3px',
  borderRadius: '3px',
  height: '4px',
  left: '-116px',
  overflow: 'hidden',
  position: 'absolute',
  top: '32px',
  transform: 'rotate(67.5deg)',
  width: '290px'
});

export const ImageContainer = styled(Box)({
  boxShadow: '80px 0px 11px rgba(0,0,0,0.2)',
  display: 'flex',
  maxWidth: '33%',
  position: 'relative'
});

export const ClippedBox = styled(Box)<StyledBoxProps>(({ isMediumScreen }) => ({
  clipPath: isMediumScreen
    ? 'polygon(10% 0, 100% 0, 100% 100%, 0 100%)'
    : 'polygon(0 0, 100% 0, 100% 100%, 10% 100%)'
}));

export const StyledEarningContent = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between'
});

export const CoverImage = styled('img')({
  height: '165px',
  objectFit: 'cover',
  overflow: 'hidden',
  width: '700px'
});
export const DividerContainer = styled(Box)({
  marginLeft: '25px',
  marginRight: '25px'
});

export const DescriptionText = styled(Typography)<StyledTypographyProps>(
  ({ isMediumScreen }) => ({
    color: colorPalette.darkGray,
    display: '-webkit-box',
    fontSize: '14px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: isMediumScreen ? '3' : '2'
  })
);

export const ViewMoreButton = styled(Button)({
  background: colorPalette.white,
  border: 'none',
  borderRadius: '0px 0px 7px 7px',
  color: colorPalette.purple,
  justifyContent: 'center',
  paddingLeft: '25px',
  paddingRight: '20px',
  '&:hover': {
    background: 'white'
  }
});
