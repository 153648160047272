import React, { createContext, useCallback, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Application } from 'components/CardApplication';
import { AuthContext } from 'contexts/AuthContext';
import { ModalDataContext } from 'contexts/ModalDataContext';
import Actions from 'redux-state/actions';
import { Product } from '../components/CardProduct';

export const ModalComponentsKeys = {
  addNewEntity: 'addNewEntity',
  addNewProblem: 'addNewProblem',
  addNewSolution: 'addNewSolution',
  addNewProduct: 'addNewProduct',
  addNewInvention: 'addNewInvention',
  addNewFullFlow: 'addNewFullFlow',
  addNewContest: 'addNewContest',
  addNewChallenge: 'addNewChallenge',
  success: 'success',
  buildPatentApplication: 'buildPatentApplication',
  buildPatentApplicationSuccess: 'buildPatentApplicationSuccess',
  buildPatentApplicationSuccessOpen: 'buildPatentApplicationSuccessOpen',
  improveApplication: 'improveApplication',
  improveApplicationSuccess: 'improveApplicationSuccess',
  brainstormSolution: 'brainstormSolution',
  brainstormSolutionSuccess: 'brainstormSolutionSuccess',
  companySubscription: 'companySubscription',
  companySubscriptionFinalize: 'companySubscriptionFinalize',
  payOutRequest: 'payOutRequest',
  payOutRequestSuccess: 'payOutRequestSuccess',
  walletSettings: 'walletSettings',
  manageTags: 'manageTags',
  addSolutionToInvention: 'addSolutionToInvention',
  attachInventionToSolution: 'attachInventionToSolution',
  registerPopup: 'registerPopup',
  sellInvention: 'sellInvention',
  sellNft: 'sellNft',
  downloadPatent: 'downloadPatent'
};

interface OpenProblemCreateOptions {
  productId: string | number;
  productDetails?: Product;
  callback?: () => void; // Add callback here
}

export type ModalContextType = {
  isOpen: boolean;
  openModal: (key: string) => void;
  closeModal: () => void;
  toggleModal: () => void;

  isProfileOpen: boolean;
  openProfileModal: () => void;
  closeProfileModal: () => void;

  openProblemCreate: (options: OpenProblemCreateOptions) => void;
  openSolutionCreate: (options: {
    problemId?: string | number;
    parentProduct?: string | number;
    problemType?: string | number;
    fetchChildrenGraph?: (id: string, type: string) => void;
    callback?: () => void; // Add callback here
  }) => void;
  openSolutionImprove: (options: {
    solutionId: string | number;
    solutionTitle?: string;
  }) => void;
  openSolutionAddToInvention: (options: {
    application: Application;
    onlyAdd?: boolean;
  }) => void;
  openInventionCreate: (options?: {
    problemId?: string | number;
    problemTitle?: string;
    parentProduct?: string | number;
  }) => void;
  openInventionImprove: (options: {
    inventionId: string | number;
    inventionTitle?: string;
    type?: 'product' | 'prior-art' | undefined;
    fetchChildrenGraph?: (id: string, type: string) => void;
    callback?: () => void; // Add callback here
  }) => void;
  openInventionAttachToSolution: (options: {
    solutionId: string | number;
    solutionProblemId?: string | number;
    solutionTitle?: string;
    inventions?: Application[];
  }) => void;
  openInventionBuildPatent: (options: { application: Application }) => void;
  componentKey: string;
  rootClassName: string;
  setRootClassName: (val: string) => void;
  triggerActionCallback: () => void; // Add this line;
  isRedirectionEnabled: boolean;
  setIsRedirectionEnabled: (enabled: boolean) => void;
};

const defaultContext: ModalContextType = {
  isOpen: false,
  openModal: (key: string) => ({}),
  closeModal: () => ({}),
  toggleModal: () => ({}),
  isProfileOpen: false,
  openProfileModal: () => ({}),
  closeProfileModal: () => ({}),
  openProblemCreate: (options: {
    productId: string | number;
    callback?: () => void;
  }) => ({}),
  openSolutionCreate: (options: {
    problemId?: string | number;
    parentProduct?: string | number;
    problemType?: string | number;
    fetchChildrenGraph?: (id: string, type: string) => void;
    callback?: () => void; // Add callback here
  }) => ({}),
  openSolutionImprove: (options: {
    solutionId: string | number;
    solutionTitle?: string;
  }) => ({}),
  openSolutionAddToInvention: (options: {
    application: Application;
    onlyAdd?: boolean;
  }) => ({}),
  openInventionCreate: (options?: {
    problemId?: string | number;
    problemTitle?: string;
    parentProduct?: string | number;
  }) => ({}),
  openInventionImprove: (options: {
    inventionId: string | number;
    inventionTitle?: string;
    type?: 'product' | 'prior-art' | undefined;
    callback?: () => void; // Add callback here
  }) => ({}),
  openInventionAttachToSolution: (options: {
    solutionId: string | number;
    solutionProblemId?: string | number;
    solutionTitle?: string;
    inventions?: Application[];
  }) => ({}),
  openInventionBuildPatent: (options: { application: Application }) => ({}),
  componentKey: '',
  rootClassName: '',
  setRootClassName: (val: string) => ({}),
  triggerActionCallback: () => {
    console.log('trigger call back');
  },
  isRedirectionEnabled: true,
  setIsRedirectionEnabled(enabled: boolean) {
    console.log('setting reditection');
  }
};

export const ModalContext = createContext<ModalContextType>(defaultContext);

type Props = {
  children: React.ReactElement;
};

export const ModalContextProvider = ({ children }: Props) => {
  const { user } = useContext(AuthContext);
  const dispatch = useDispatch();
  const { setType, updateField } = useContext(ModalDataContext);
  const [isOpen, setIsOpen] = useState(defaultContext.isOpen);
  const [componentKey, setComponentKey] = useState<string>('');
  const [rootClassName, setRootClassName] = useState('');
  const [isRedirectionEnabled, setIsRedirectionEnabled] =
    useState<boolean>(true);
  const [actionCallback, setActionCallback] = useState<(() => void) | null>(
    null
  ); // Add callback state

  const [isProfileOpen, setIsProfileOpen] = useState(false);

  const openModal = useCallback(
    (key: string) => {
      if (
        !user &&
        key !== ModalComponentsKeys.registerPopup &&
        key !== ModalComponentsKeys.success
      ) {
        dispatch(Actions.openAuthModal(true));
        return;
      }
      setIsOpen(true);
      setComponentKey(key);
    },
    [setComponentKey, setIsOpen, user]
  );

  const openProfileModal = useCallback(() => {
    setIsProfileOpen(true);
  }, [setIsProfileOpen]);

  const closeProfileModal = useCallback(() => {
    setIsProfileOpen(false);
  }, [setIsProfileOpen]);

  const closeModal = useCallback(() => {
    setIsOpen(false);
    setRootClassName('');
  }, [isOpen, setIsOpen]);

  const toggleModal = useCallback(() => {
    setIsOpen(!isOpen);
    setRootClassName('');
  }, [isOpen, setIsOpen]);

  const openProblemCreate = useCallback(
    ({ productId, productDetails, callback }: OpenProblemCreateOptions) => {
      setType(ModalComponentsKeys.addNewProblem);
      if (productId) {
        updateField('rootProblemHide', true);
        updateField('parentProblemHide', true);
        updateField('parentProblemHide', true);
        updateField('introProblemTags', ' ');
        updateField('titleProblemLabel', "What's your problem");
        updateField('selectApplication', productId);
        updateField('product', productId);
        updateField('company', productDetails?.company);
      }
      if (callback) {
        setIsRedirectionEnabled(false);
        setActionCallback(() => callback); // Store the callback
      }
      openModal(ModalComponentsKeys.addNewProblem);
    },
    [updateField, openModal, setType]
  );

  const openSolutionCreate = useCallback(
    ({
      problemId,
      parentProduct,
      callback
    }: {
      problemId?: string | number;
      parentProduct?: string | number;
      callback?: () => void; // Add callback parameter
    }) => {
      setType(ModalComponentsKeys.addNewSolution);
      if (parentProduct) {
        updateField('productId', parentProduct);
        updateField('parentSolutionHide', true);
      } else if (problemId) {
        updateField('parentProblem', problemId);
        updateField('parentProblemHide', true);
        updateField('parentSolutionHide', true);
      }
      if (callback) {
        setIsRedirectionEnabled(false);
        setActionCallback(() => callback); // Store the callback
      }
      openModal(ModalComponentsKeys.addNewSolution);
    },
    [updateField, openModal, setType]
  );

  const triggerActionCallback = useCallback(() => {
    if (actionCallback) {
      actionCallback(); // Call the stored callback
    }
  }, [actionCallback]);

  const openSolutionImprove = useCallback(
    ({
      solutionId,
      solutionTitle
    }: {
      solutionId: string | number;
      solutionTitle?: string;
    }) => {
      setType(ModalComponentsKeys.addNewSolution);
      updateField('parentSolution', solutionId);
      updateField('parentSolutionLabel', solutionTitle);
      openModal(ModalComponentsKeys.brainstormSolution);
    },
    [updateField, openModal, setType]
  );

  const openSolutionAddToInvention = useCallback(
    ({
      application,
      onlyAdd
    }: {
      application: Application;
      onlyAdd?: boolean;
    }) => {
      setType(ModalComponentsKeys.addSolutionToInvention);
      updateField('application', application);
      updateField('rootProblemHide', onlyAdd);
      openModal(ModalComponentsKeys.addSolutionToInvention);
    },
    [updateField, openModal, setType]
  );

  const openInventionCreate = useCallback(
    (options?: {
      problemId?: string | number;
      problemTitle?: string;
      parentProduct?: string | number;
    }) => {
      const { problemId, problemTitle, parentProduct } = options || {};
      setType(ModalComponentsKeys.addNewInvention);
      updateField('mainProblem', problemId);
      updateField('mainProblemLabel', problemTitle);
      updateField('selectApplication', parentProduct);
      openModal(ModalComponentsKeys.addNewInvention);
    },
    [updateField, openModal, setType]
  );

  const openInventionImprove = useCallback(
    ({
      callback,
      inventionId,
      inventionTitle,
      type
    }: {
      inventionId: string | number;
      inventionTitle?: string;
      type?: 'product' | 'prior-art' | undefined;
      callback: () => void;
    }) => {
      updateField('selectApplication', inventionId);
      updateField('selectApplicationLabel', inventionTitle);
      updateField('selectApplicationType', type);
      openModal(ModalComponentsKeys.improveApplication);

      if (callback) {
        setIsRedirectionEnabled(false);
        setActionCallback(() => callback); // Store the callback
      }
    },
    [updateField, openModal]
  );

  const openInventionAttachToSolution = useCallback(
    ({
      solutionId,
      solutionProblemId,
      solutionTitle,
      inventions
    }: {
      solutionId: string | number;
      solutionProblemId?: string | number;
      solutionTitle?: string;
      inventions?: Application[];
    }) => {
      setType(ModalComponentsKeys.attachInventionToSolution);
      updateField('application', solutionId);
      updateField('mainProblem', solutionProblemId);
      updateField('productLabel', solutionTitle);
      updateField('applications', inventions);
      openModal(ModalComponentsKeys.attachInventionToSolution);
    },
    [updateField, openModal, setType]
  );

  const openInventionBuildPatent = useCallback(
    ({ application }: { application: Application }) => {
      const isExlusivity =
        application.isShared &&
        !application.isExclusivityPaid &&
        !application.isExclusivityExpired;
      if (isExlusivity) {
        updateField('parentProblemOnly', true);
      }
      updateField('application', application);
      openModal(ModalComponentsKeys.buildPatentApplication);
    },
    [updateField, openModal]
  );

  return (
    <ModalContext.Provider
      value={{
        isOpen,
        openModal,
        closeModal,
        toggleModal,

        isProfileOpen,
        openProfileModal,
        closeProfileModal,

        openProblemCreate,
        openSolutionCreate,
        openSolutionImprove,
        openSolutionAddToInvention,
        openInventionCreate,
        openInventionImprove,
        openInventionAttachToSolution,
        openInventionBuildPatent,

        componentKey,
        rootClassName,
        setRootClassName,
        isRedirectionEnabled,
        triggerActionCallback, // Provide the callback trigger method in the context
        setIsRedirectionEnabled
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const ModalContextConsumer = ModalContext.Consumer;

export default ModalContext;
