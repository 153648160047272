import React, { useContext, useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import Actions from 'redux-state/actions';

import { RelatedRewards } from 'components/RelatedRewards';
import { TabContent } from 'components/Tabs';
import { PsLogo } from 'components/common/PsLogo';
import AuthContext from 'contexts/AuthContext';
import { ModalDataContext } from 'contexts/ModalDataContext';
import { GetPublicUser, GetUser } from 'redux-state/selectors';
import { makeStyles } from 'tss-react/mui';
import { PsTheme } from '../../theme';

export const useIdeaPointsInfoModalClasses = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    modalRoot: {
      paddingLeft: 15,
      paddingRight: 15,
      [psTheme.breakpoints.down('xs')]: {
        paddingTop: 50
      }
    },
    modalPaper: {
      width: '100%',
      maxWidth: 1150
    },
    modalHeader: {
      fontSize: 36,
      [psTheme.breakpoints.down('sm')]: {
        fontSize: 30
      },
      [psTheme.breakpoints.down('xs')]: {
        fontSize: 25
      }
    }
  };
});

type ClassKey = 'root' | 'header' | 'headerLeft' | 'headerIcon';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      marginBottom: -10
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start'
    },
    headerLeft: {
      display: 'flex',
      alignItems: 'center'
    },
    headerIcon: {
      marginRight: 10
    }
  };
});

type IdeaPointsInfoProps = {
  profileId: string | number;
};

const IdeaPointsInfoView = ({ profileId }: IdeaPointsInfoProps) => {
  const { values } = useContext(ModalDataContext);
  const [activeTab, setActiveTab] = useState('Claimed');
  const { classes } = useStyles();

  const stats = values.payOptions || [];

  return (
    <div className={classes.root}>
      <TabContent label="Claimed" value={activeTab}>
        <RelatedRewards />
      </TabContent>
    </div>
  );
};

export const IdeaPointsInfo = styled(IdeaPointsInfoView)({});

export default IdeaPointsInfo;

const IdeaPointsInfoTitleView = () => {
  const dispatch = useDispatch();
  const theme: PsTheme = useTheme();
  const { classes } = useStyles();
  const loginUser = GetUser();
  useEffect(() => {
    if (loginUser) {
      dispatch(Actions.getProfile(loginUser._id ?? loginUser.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.header}>
      <div className={classes.headerLeft}>
        <PsLogo
          className={classes.headerIcon}
          small
          size={55}
          color={theme.palette.primary.main}
        />
        <span>IdeaPoints: {loginUser && loginUser.ideaPoints}</span>
      </div>
    </div>
  );
};

export const IdeaPointsInfoTitle = styled(IdeaPointsInfoTitleView)({});
