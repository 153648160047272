import React, { useContext, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { ModalDataContext } from 'contexts/ModalDataContext';
import { PsFormControl, ShortEvent } from 'components/common/PsFormControl';
import PsInputLabel from 'components/common/PsInputLabel';
import styles from 'modals/ModalWindow.module.scss';
import { PsTheme } from '../../../theme';
import { makeStyles } from 'tss-react/mui';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio, { radioClasses } from '@mui/material/Radio';
import Grid from '@mui/material/Grid';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Hidden from '@mui/material/Hidden';

type ClassKey = 'root' | 'input';

const useStyles = makeStyles()(() => {
  const psTheme = useTheme() as PsTheme;
  return {
    root: {},
    input: {
      width: '100%',
      '& input': {
        padding: '13px 14px'
      },
      '& fieldset': {
        borderRadius: 8
      }
    }
  };
});

const useRadioStyles = makeStyles()(() => {
  const theme = useTheme();
  return {
    root: {
      width: '33%',
      margin: '10px 0 0',
      paddingRight: 10,
      '&:last-child': {
        paddingRight: 0
      },
      '& > span.Mui-checked': {
        color: theme.palette.primary.main
      },
      '& > span:last-child': {
        lineHeight: '17px'
      }
    }
  };
});

const ContestTypesView = () => {
  const { classes } = useStyles();
  const { values, updateField } = useContext(ModalDataContext);
  const [wins, setWins] = useState(values.contestWins || '1');
  const initStartDate = values.contestStart
    ? new Date(values.contestStart)
    : null;
  const initEndDate = values.contestEnd ? new Date(values.contestEnd) : null;
  const [startDate, setStartDate] = useState<Date | null>(initStartDate);
  const [endDate, setEndDate] = useState<Date | null>(initEndDate);

  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date);
    const dateStr = date ? date.toISOString() : '';
    updateField('contestStart', dateStr);
  };

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
    const dateStr = date ? date.toISOString() : '';
    updateField('contestEnd', dateStr);
  };

  const { classes: radioStyles } = useRadioStyles();

  const handleWinsChange = (e: ShortEvent) => {
    setWins(e.target.value);
    updateField('contestWins', e.target.value);
  };

  return (
    <div className={styles.stepWrapper}>
      <PsFormControl label="Who Wins the Contest?" labelInfo={true}>
        <RadioGroup
          name="contestWins"
          value={wins}
          onChange={handleWinsChange}
          row
        >
          {[
            { label: 'Winner takes all', value: '1' },
            { label: 'Top 3 winners', value: '3' },
            { label: 'Top 10 winners', value: '10' }
          ].map(({ label, value }) => {
            return (
              <FormControlLabel
                key={value}
                classes={radioClasses}
                value={value}
                control={<Radio />}
                label={label}
              />
            );
          })}
        </RadioGroup>
      </PsFormControl>

      <PsInputLabel label="Whats Contest Length" />
      <Grid container spacing={2}>
        <Grid item xs={6} sm={5}>
          <DatePicker
            className={classes.input}
            // disableToolbar
            // variant="inline"
            format="MMM DD, YYYY"
            // margin="normal"
            // id="date-start"
            // placeholder="Enter Start Date"
            value={startDate}
            onChange={handleStartDateChange}
            // autoOk={true}
            disablePast={true}
            // inputVariant="outlined"
            // helperText="Start Date"
          />
        </Grid>
        <Hidden xsDown={true}>
          <Grid item sm={2}></Grid>
        </Hidden>
        <Grid item xs={6} sm={5}>
          <DatePicker
            className={classes.input}
            // disableToolbar
            // variant="inline"
            format="MMM DD, YYYY"
            // margin="normal"
            // id="date-end"
            // placeholder="Enter End Date"
            value={endDate}
            onChange={handleEndDateChange}
            // autoOk={true}
            disablePast={true}
            // inputVariant="outlined"
            // helperText="End Date"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export const ContestTypes = styled(ContestTypesView)({});

export default ContestTypes;
