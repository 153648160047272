import React, { useState, useEffect, useContext, useCallback } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { Problem, CardProblemSmall } from 'components/CardProblem';
import { DataContext } from 'contexts/DataContext';
import { Pagination } from 'components/Pagination';
import { PsTheme } from '../../theme';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'block' | 'header' | 'loading' | 'noResult';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    block: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginTop: 10
    },
    header: {
      fontSize: 32,
      fontWeight: 'bold',
      lineHeight: '39px',
      marginTop: 24,
      color: psTheme.palette.third.main,
      [psTheme.breakpoints.down('sm')]: {
        fontSize: 25,
        lineHeight: '30px'
      }
    },
    loading: {
      lineHeight: '30px',
      textAlign: 'center'
    },
    noResult: {
      lineHeight: '30px',
      textAlign: 'center'
    }
  };
});

type RelatedProblemsProps = {
  title?: string;
  noResultContent?: React.ReactNode;
  problem?: string | number;
  solution?: string | number;
  challenge?: string | number;
  tagName?: string;
  owner?: string | number;
  selectedForApplication?: string | number;
  contest?: string | number;
  product?: string | number;
};

const RelatedProblemsView = ({
  noResultContent,
  title,
  problem,
  solution,
  challenge,
  tagName,
  owner,
  selectedForApplication,
  contest,
  product
}: RelatedProblemsProps) => {
  const { classes } = useStyles();
  const { dataProvider, relatedProblemsListCache, refreshRelatedProblemsList } =
    useContext(DataContext);
  const [page, setPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [problems, setProblems] = useState<Array<Problem>>([]);
  const [loading, setLoading] = useState(true);
  const perPage = 5;

  useEffect(() => {
    if (
      !problem &&
      !solution &&
      !challenge &&
      !tagName &&
      !owner &&
      !selectedForApplication &&
      !contest &&
      !product
    ) {
      setProblems([]);
      setLoading(false);
      return;
    }
    let filter = {};
    if (problem) {
      filter = { id: problem };
    } else if (solution) {
      filter = {
        solutionInvolved: solution,
        $custom: { type: 'solutionInvolved' }
      };
    } else if (challenge) {
      filter = {
        $custom: { type: 'selectedForChallenge', challenge }
      };
    } else if (tagName) {
      filter = {
        tags: [tagName],
        $custom: { type: 'forUi' }
      };
    } else if (owner) {
      filter = {
        owner,
        $custom: { type: 'forUi' }
      };
    } else if (selectedForApplication) {
      filter = {
        $custom: {
          type: 'selectedForApplication',
          application: selectedForApplication
        }
      };
    } else if (contest) {
      filter = {
        $custom: {
          type: 'selectedForContest',
          contest
        }
      };
    } else if (product) {
      filter = {
        $custom: {
          type: 'selectedForProduct',
          product
        }
      };
    }

    setLoading(true);
    dataProvider
      .getList<Problem>('problems', {
        pagination: { page: page + 1, perPage },
        sort: { field: 'votes', order: 'DESC' },
        filter
      })
      .then(({ data, total }) => {
        setProblems(data);
        setTotalPages(Math.ceil(total / perPage));
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    page,
    problem,
    solution,
    tagName,
    owner,
    selectedForApplication,
    contest,
    product,
    relatedProblemsListCache
  ]);

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const onTagRemove = useCallback(() => {
    refreshRelatedProblemsList();
  }, [refreshRelatedProblemsList]);

  return (
    <div className={classes.block}>
      {title ? (
        <Typography className={classes.header} variant="h2" component="h2">
          {title}
        </Typography>
      ) : null}
      {loading ? (
        <Typography className={classes.loading} variant="body2">
          Loading...
        </Typography>
      ) : null}
      {!loading && !problems.length ? (
        <Typography className={classes.noResult}>
          {noResultContent
            ? noResultContent
            : 'This item does not yet have any problems'}
        </Typography>
      ) : null}
      {problems.map((problem) => {
        return (
          <CardProblemSmall
            key={problem.id}
            problem={problem}
            onTagRemove={onTagRemove}
            filterTagName={tagName}
          />
        );
      })}
      <Pagination total={totalPages} active={page} onChange={onPageChange} />
    </div>
  );
};

export const RelatedProblems = styled(RelatedProblemsView)({});

export default RelatedProblems;
