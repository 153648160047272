import { Box, Typography, IconButton, styled } from '@mui/material';
import { colorPalette } from 'theme';

export const StyledIconButton = styled(IconButton)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

export const MainBox = styled(Box)({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column'
});

export const NodeItemMainBox = styled(Box)({
  maxHeight: '300px',
  overflow: 'auto',
  width: '100%'
});

export const NodeItemWrapper = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '1rem',
  width: '100%'
});

export const DelIconText = styled(Typography)({
  color: colorPalette.red,
  fontSize: '12px'
});

export const EditIconText = styled(Typography)({
  color: colorPalette.purple,
  fontSize: '12px'
});
