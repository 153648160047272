import React, { useCallback, useContext } from 'react';
import { styled } from '@mui/material/styles';

import { PsButton } from 'components/common/PsButton';
import {
  Card,
  CardImage,
  CardBody,
  CardTitle,
  CardOwner,
  CardText,
  CardActions
} from 'components/card';
import { Tags } from 'components/Tag';
import { ShareBtn } from 'components/ShareBtn';
import { OwnerInfo } from 'components/CardProfile';
import { TagInfo } from 'components/CardTag';
import { SolutionRelation } from 'components/CardSolution';
import { PsRecord } from 'contexts/DataContext';
import { AuthContext } from 'contexts/AuthContext';
import { ModalComponentsKeys, ModalContext } from 'contexts/ModalContext';
import { ModalDataContext } from 'contexts/ModalDataContext';
import { Picture, placeholderType } from '../../helpers';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

export type Solution = PsRecord & {
  key: string;
  title?: string;
  teaser?: string;
  body?: string;
  problem?: string | number;
  problemInfo?: {
    key: string;
    title: string;
  };
  parentSolution?: string | number;
  improvedSolution?: string | number;
  improvedSolutionInfo?: {
    key: string;
    title: string;
    teaser: string;
  };
  improvement?: {
    method: string;
    object: string;
    sight: string;
    text: string;
  };
  boost?: Array<{
    method: string;
    object: string;
    sight: string;
    text: string;
  }>;
  owner?: string | number;
  ownerInfo?: OwnerInfo;
  files?: Array<Picture>;
  createdAt?: string;
  tags?: string[];
  tagsInfo?: Array<TagInfo>;
  descFurther?: string;
  descMaterials?: string;
  descDimensions?: string;
  descSteps?: string;
  relations?: Array<SolutionRelation>;
  likes: number;
  dislikes: number;
  reward?: number;
  isPublic: boolean;
  envelopeId?: string;
  termsAgree?: boolean;
  isInProgress?: boolean;
};

const useStyles = makeStyles()(() => {
  return {
    root: {},
    tags: {
      '& > div': {
        marginTop: 4,
        marginBottom: 4
      }
    },
    actionButton: {
      marginRight: 10
    }
  };
});

interface ISolutionTitles {
  id: string | number;
  title: string;
}

export type SolutionCardProps = {
  solution: Solution;
  solutionTitles: ISolutionTitles[] | undefined;
};

const CardSolutionView = ({
  solution
}: // solutionTitles,
SolutionCardProps) => {
  const {
    id,
    key,
    title,
    teaser,
    body,
    owner,
    ownerInfo,
    improvedSolutionInfo,
    createdAt,
    files,
    tagsInfo = [],
    likes,
    dislikes
  } = solution;

  const { user } = useContext(AuthContext);
  const { openModal } = useContext(ModalContext);
  const { setType, updateField } = useContext(ModalDataContext);
  const { classes } = useStyles();

  const image = files && files.length ? files[0] : undefined;
  const url = `/solutions/${key || id}`;

  const onBrainstormClick = useCallback(() => {
    setType(ModalComponentsKeys.addNewSolution);
    updateField('parentSolution', id);
    updateField('parentSolutionLabel', teaser || title);
    openModal(ModalComponentsKeys.brainstormSolution);
  }, [updateField, openModal, setType, id, teaser, title]);

  return (
    <Card>
      <CardImage href={url} image={image} type={placeholderType.S} />
      <CardBody>
        <CardTitle title={teaser || title} href={url}>
          <Tags className={classes.tags} tagsList={tagsInfo} />
        </CardTitle>
        <CardOwner
          owner={owner}
          ownerKey={ownerInfo && ownerInfo.key}
          ownerName={ownerInfo && ownerInfo.username}
          createdAt={createdAt}
        />
        <CardText>
          {body}
          {improvedSolutionInfo ? (
            <>
              <br />
              This solution improved the solution of the{' '}
              <Link to={`/solutions/${improvedSolutionInfo.key}`}>
                {improvedSolutionInfo.teaser || improvedSolutionInfo.title}
              </Link>
              .
            </>
          ) : null}
        </CardText>
        <CardActions
          resource="solutions"
          resourceId={id}
          likes={likes}
          dislikes={dislikes}
          user={user}
        >
          <PsButton
            className={classes.actionButton}
            onClick={onBrainstormClick}
            coins="+1"
          >
            Improve
          </PsButton>
          <ShareBtn solution={solution} tagsList={tagsInfo} />
          {/* <PsButton className={classes.actionButton} onClick={onImproveClick}>
            Create child solution
          </PsButton> */}
        </CardActions>
      </CardBody>
    </Card>
  );
};

export const CardSolution = styled(CardSolutionView)({});

export default CardSolution;
