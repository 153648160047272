import React, { useEffect, useState } from 'react';
import type { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import Actions from 'redux-state/actions';
import useRouter from 'hooks/useRouter';
import { useFetchGraph } from 'hooks/useFetchGraph';
import { Constants } from 'utilities/constants';
import NewIdeaMap from '../newIdeaMap';
import InventionPublicView from '../InventionPublicView';

const InventionDetails: FunctionComponent = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { key } = router.query;
  const [isDataCleared, setIsDataCleared] = useState<boolean>(false);

  // TODO: shift to component unmount
  useEffect(() => {
    if (key) {
      dispatch(Actions.setGraph({})); // Clear the graph data
      setIsDataCleared(true); // Mark data as cleared
    }
  }, [dispatch, key]);

  const { graphData, fetchChildrenGraph } = useFetchGraph({
    type: Constants.APPLICATIONS,
    id: key as string,
    mapType: Constants.APPLICATION
  });

  return (
    isDataCleared && (
      <InventionPublicView graphData={graphData} inventionKey={key as string} />
    )
  );
};
export default InventionDetails;
