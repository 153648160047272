import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useDispatch } from 'react-redux';
import AddToConceptModal from 'modals/AddToConceptModal';
import Actions from 'redux-state/actions';
import AuthContext from 'contexts/AuthContext';
import dataProvider from 'dataPrvider';
import getQueryParams from 'helpers/getQueryParams';
import { DataSource } from 'components/TreeGraph/types';
import { DetailsDrawer } from 'components/common/DeatilsDrawer';
import {
  GetConceptData,
  GetGraphData,
  GetToggleConceptCart
} from 'redux-state/ideamap/selectors';
import { GetAiTitle, GetUser } from 'redux-state/selectors';
import { Constants, VOTE_CRITERIA } from 'utilities/constants';
import { InteractiveNodeContainerProps, Node } from 'interface/common';
import ImproveModal from 'modals/ImproveModal';
import { NodeItem } from './NodeItem';
import { useVoting } from 'hooks/useVoting';

const InteractiveNodeContainer: FC<InteractiveNodeContainerProps> = ({
  addNewConcept,
  buttonList,
  handleDrawer,
  ideaMapType,
  isParentNode = false,
  messageId,
  node,
  selectedNode,
  setIsSolutionModalOpen,
  setProblem
}) => {
  const { dislikes, id, isLiked, likes, shortTitle, title, type, key } = node;
  const referralCode = getQueryParams('referralCode');

  const [drawerItem, setDrawerItem] = useState<Node | null>(null);
  const [image, setImage] = useState<string>(node?.image);
  const [drawerItemType, setDrawerItemType] = useState<string>('');
  const [isAddToConceptModalOpen, setIsAddToConceptModalOpen] =
    useState<boolean>(false);
  const [isDetailDrawer, setIsDetailDrawer] = useState<boolean>(false);
  const [isImproveModalOpen, setIsImproveModalOpen] = useState<boolean>(false);
  const [like, setLike] = useState<boolean | undefined>(node?.isLiked);

  const user = GetUser();
  const activeConcept = user?.activeConcept || {};
  const conceptData = GetConceptData();
  const graphData = GetGraphData();
  const isConceptDrawerOpen = GetToggleConceptCart();
  const dispatch = useDispatch();
  const { refreshUserBalance } = useContext(AuthContext);
  const aiTitle = GetAiTitle();

  const isCurrentConceptSolution = useMemo(() => {
    const activeConceptData = conceptData?.data?.find(
      (concept) => concept?.id === activeConcept?.id
    );
    if (!activeConceptData) return false;
    return activeConceptData?.selected?.includes(id);
  }, [activeConcept?.id, conceptData?.data, id]);
  const openAddToConceptModal = useCallback(() => {
    if (user) {
      if (isConceptDrawerOpen) {
        const existingProbIds = activeConcept.problems.map(({ id }) => id);
        const newProblems = existingProbIds.includes(node.problem)
          ? existingProbIds
          : [...existingProbIds, node.problem];

        const data = {
          selected: [node.id, ...activeConcept.selected],
          problems: newProblems
        };
        dispatch(Actions.updateConcept(activeConcept?.id, data));
        dispatch(Actions.getAiTitle({ conceptId: activeConcept?.id }));
      } else {
        setIsAddToConceptModalOpen(true);
      }
    } else {
      dispatch(Actions.openAuthModal(true));
    }
  }, [user, isConceptDrawerOpen, dispatch]);

  useEffect(() => {
    if (aiTitle) {
      const data = {
        title: aiTitle
      };
      dispatch(Actions.updateConcept(activeConcept?.id, data));
      dispatch(Actions.getAiTitleSuccess({ title: '' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aiTitle]);

  const closeAddToConceptModal = useCallback(() => {
    setIsAddToConceptModalOpen(false);
  }, []);

  const voteItem = useCallback(
    (
      voteType: number,
      node: Pick<DataSource, Constants.ID | Constants.TYPE>
    ) => {
      if (user) {
        dispatch(
          Actions.voteItem(
            `${node.type}s`,
            id,
            voteType,
            VOTE_CRITERIA.IDEAMAP,
            {}
          )
        );
      } else {
        dispatch(Actions.openAuthModal(true));
      }
    },
    [id, refreshUserBalance, user, dispatch]
  );

  const { VotingSection } = useVoting({
    user,
    graphData,
    id,
    isLiked,
    setLike,
    voteItem,
    dispatch,
    isParentNode,
    likes,
    dislikes,
    type,
    like,
    node
  });

  return (
    <>
      <NodeItem
        activeConcept={activeConcept}
        addNewConcept={addNewConcept}
        buttonList={buttonList}
        conceptData={conceptData}
        graphData={graphData}
        ideaMapType={ideaMapType}
        image={image}
        isConceptDrawerOpen={isConceptDrawerOpen}
        isCurrentConceptSolution={isCurrentConceptSolution}
        isParentNode={isParentNode}
        messageId={messageId}
        node={node}
        openAddToConceptModal={openAddToConceptModal}
        selectedNode={selectedNode}
        setDrawerItem={setDrawerItem}
        setDrawerItemType={setDrawerItemType}
        setIsDetailDrawer={setIsDetailDrawer}
        setIsSolutionModalOpen={setIsSolutionModalOpen}
        setProblem={setProblem}
        VotingSection={VotingSection}
      />

      <DetailsDrawer
        drawerItemType={drawerItemType}
        image={image}
        isCurrentConceptSolution={isCurrentConceptSolution}
        item={drawerItem}
        onClose={() => setIsDetailDrawer(false)}
        open={isDetailDrawer}
        setImage={setImage}
        setIsAddToConceptModalOpen={setIsAddToConceptModalOpen}
        votingPanel={VotingSection}
      />
      {isAddToConceptModalOpen && (
        <AddToConceptModal
          handleDrawer={handleDrawer}
          node={node}
          onClose={closeAddToConceptModal}
          open={isAddToConceptModalOpen}
          isFeed={false}
          referralCode={referralCode ?? null}
        />
      )}
      {isImproveModalOpen && (
        <ImproveModal
          isImproveModalOpen={isImproveModalOpen}
          setIsImproveModalOpen={setIsImproveModalOpen}
          title={title}
          shortTitle={shortTitle}
          productId={id}
          productKey={key}
        />
      )}
    </>
  );
};

export default React.memo(InteractiveNodeContainer);
