import React from 'react';
import { FunctionComponent } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { PsTheme } from '../../theme';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from 'tss-react/mui';

const FavouriteIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.6"
    height="19.095"
    viewBox="0 0 21.6 19.095"
  >
    <path
      id="Icon_feather-heart"
      data-name="Icon feather-heart"
      d="M20.411,6.009a5.157,5.157,0,0,0-7.295,0L12.123,7l-.994-.994A5.158,5.158,0,1,0,3.834,13.3l.994.994,7.295,7.295L19.418,14.3l.994-.994a5.157,5.157,0,0,0,0-7.295Z"
      transform="translate(-1.323 -3.497)"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    button: {
      padding: '8px',
      color: psTheme.palette.primary.main
    }
  };
});

const FavouriteBtnView: FunctionComponent = () => {
  const { classes } = useStyles();
  return (
    <IconButton className={classes.button}>
      <FavouriteIcon />
    </IconButton>
  );
};

export const FavouriteBtn = styled(FavouriteBtnView)({});
