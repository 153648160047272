import {
  Box,
  CircularProgress,
  Divider,
  TextField,
  Typography,
  styled
} from '@mui/material';
import { Loop } from '@mui/icons-material';
import { colorPalette } from 'theme';
import PsFormControl from 'components/common/PsFormControl';

interface StyledSubHeaderTextProps {
  type: string;
}

export const StyledModalWrapper = styled(Box)({
  background: colorPalette.white,
  borderRadius: 1,
  boxShadow: '0rem .25rem .5rem rgba(0, 0, 0, 0.1)',
  maxWidth: 1200,
  padding: '1.5625rem',
  width: '50rem'
});

export const StyledHeaderText = styled(Typography)({
  fontWeight: 'bold'
});

export const StyledHeaderWrapper = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between'
});

export const StyledSubHeaderText = styled(Typography)<StyledSubHeaderTextProps>(
  ({ type }) => ({
    color: type === 'problem' ? colorPalette.purple : colorPalette.VenetianRed,
    fontWeight: 600,
    marginBottom: '1.75rem',
    position: 'relative'
  })
);

export const StyledDivider = styled(Divider)({
  margin: '1rem 0 '
});

export const StyledFieldPlaceholder = styled(Typography)({
  fontWeight: 500
});

export const StyledTagFieldLabel = styled(Typography)({
  fontWeight: 500
});

export const StyledFieldLabel = styled(Typography)({
  fontWeight: 600,
  marginBottom: '-0.3125rem'
});

export const DetailsBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%'
});

export const DetailsMainBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: '1.5rem',
  marginTop: '1.5rem'
});

export const DescriptionBox = styled(Box)({
  width: '50%'
});

export const StyledBodyTypography = styled(Typography)({
  color: `${colorPalette.dimBlack} !important`,
  fontSize: '15px !important',
  fontWeight: '500 !important',
  paddingBottom: '0.1875rem'
});

export const StyledBodyFieldText = styled(TextField)({
  borderRadius: '8px',
  width: '100%'
});

export const ImageMainBox = styled(Box)({
  marginLeft: '2rem',
  width: '50%'
});

export const ImageBox = styled(Box)({
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  width: '100%'
});

export const FeatureImage = styled('img')({
  borderRadius: '6px',
  height: '10.6875rem',
  objectFit: 'cover',
  overflow: 'hidden',
  width: '100%'
});

export const StyledLoopIcon = styled(Loop)<{ loading: boolean }>(
  ({ loading }) => ({
    color: colorPalette.purple,
    animation: loading ? 'rotate 1s linear infinite' : 'none',
    '@keyframes rotate': {
      from: {
        transform: 'rotate(360deg)'
      },
      to: {
        transform: 'rotate(0deg)'
      }
    }
  })
);

export const StyledImageSupportText = styled(Typography)({
  color: colorPalette.gray,
  fontSize: '12px',
  fontWeight: '500'
});

export const StyledDescriptionExampleText = styled(Typography)({
  color: colorPalette.grey,
  fontSize: '12px',
  fontWeight: '500',
  margin: '0.2rem 0 0'
});

export const TextWrapper = styled(PsFormControl)({
  '& p': {
    display: 'inline-block'
  },
  '& .css-1jqx3gz-MuiFormHelperText-root-helperText': {
    margin: '0.5rem 0'
  }
});

export const StyledCounterButtonWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '.25rem'
});

export const StyledCounterButtonMainWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between'
});

export const StyledCircularProgress = styled(CircularProgress)({
  left: '50%',
  marginLeft: '-0.75rem',
  marginTop: '-0.75rem',
  position: 'absolute',
  top: '50%'
});

export const StyledMultiplierIdeaPts = styled(Typography)({
  color: colorPalette.purple,
  fontSize: '1.5rem',
  fontWeight: 500
});
