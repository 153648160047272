import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, LinearProgress, Link, Typography, styled } from '@mui/material';
import ProblemIcon from 'assets/icons/problems.svg';
import { Tag } from 'components/CardTag';
import { PsButton } from 'components/common/PsButton';
import AuthContext from 'contexts/AuthContext';
import { ModalComponentsKeys, ModalContext } from 'contexts/ModalContext';
import { ModalDataContext } from 'contexts/ModalDataContext';
import useRouter from 'hooks/useRouter';
import ProblemModal from 'modals/AddProblem';
import { ContestModal } from 'pages/newContests/ContestModal';
import Actions from 'redux-state/actions';
import {
  GetLoader,
  GetRelatedProblems,
  GetRelatedProblemsCount
} from 'redux-state/commons/selectors';
import { Constants } from 'utilities/constants';
import { useIsMediumScreen } from '../../theme';
import { Problem } from '../CardProblem';
import { Profile } from '../CardProfile';
import { CustomMenu } from '../CustomMenu';
import { GetActions } from '../CustomMenu/actions';
import DataNotFound from '../DataNotFound';
import { ImageView } from '../ImageView';
import { LinkView } from '../LinkView';
import TableView from '../TableView';
import { getHeaders } from '../TableView/getHeaders';

const StyledPsButton = styled(PsButton)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    backgroundColor: 'red',
    borderRadius: '25px !important',
    marginTop: '24px',
    width: isMediumScreen ? '170px' : '220px',
    height: isMediumScreen ? '40px' : 'none'
  })
);

const ButtonContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  padding: '0 15px'
});

const StyledMainBox = styled(Box)({
  backgroundColor: 'white',
  height: 'auto',
  width: '100%'
});

const StyledBox = styled(Box)({
  backgroundColor: 'white',
  height: 'auto',
  padding: '15px',
  width: '100%'
});

interface ItemListProps {
  profile?: Profile;
  paginationOptions?: {
    page: number;
    rowsPerPage: number;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  };
  tag: Tag;
}

const ProblemView: React.FC<ItemListProps> = ({
  profile,
  paginationOptions,
  tag
}) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { key } = router.query;

  const isMediumScreen = useIsMediumScreen();

  const problems: Array<Problem> = GetRelatedProblems();
  const problemsCount: number = GetRelatedProblemsCount() || 0;
  const problemLoader = GetLoader();

  const { user } = useContext(AuthContext);
  const { setType, updateField } = useContext(ModalDataContext);
  const { openModal } = useContext(ModalContext);
  const [isProblemModalOpen, setIsProblemModalOpen] = useState<boolean>(false);
  const [communityTagHeader, setCommunityTagHeader] = useState<string>('');
  const [openContestModal, setOpenContestModal] = useState<boolean>(false);
  const [contestProblem, setContestProblem] = useState<Problem>();

  const handleCloseModal = () => {
    setIsProblemModalOpen(false);
  };

  const { page, rowsPerPage, setPage, setRowsPerPage } = paginationOptions;
  const pagination = useMemo(
    () => ({
      page: page,
      perPage: rowsPerPage
    }),
    [page, rowsPerPage]
  );

  const isCurrentUser = !!(user && (user.id === profile?.id || user?.isAdmin));
  const hasEmptyData = problems.length == 0;

  useEffect(() => {
    dispatch(
      Actions.getRelatedItems({
        resource: 'tags',
        key: key as string,
        item: Constants.PROBLEMS,
        pagination
      })
    );
  }, [dispatch, key, pagination]);

  useEffect(() => {
    if (problems?.length < 1) {
      setPage(0);
    }
  }, [problems, setPage]);

  const headers: Array<string> = useMemo(() => {
    return getHeaders(Constants.COMMUNITY_PROBLEM, user, isCurrentUser);
  }, [isCurrentUser, user]);

  const getActions = useMemo(() => {
    return (item: Problem) => {
      return GetActions({
        item,
        profile,
        type: 'Community',
        pagination
      });
    };
  }, [pagination, profile]);
  const handleStartContest = (item) => {
    setOpenContestModal(true);
    setContestProblem(item);
  };

  const getCells = useMemo(() => {
    return (icon, item) => {
      const baseCells = {
        item: (
          <ImageView
            iconImage={icon}
            placeholderImage={item?.files[0]?.url || icon}
            title={item.title}
            id={item.key}
            type="problems"
            useSameTab
          />
        ),
        product: (
          <LinkView
            id={item?.parentProduct?.id}
            title={item?.parentProduct?.title}
            type="products"
          />
        ),
        link: (
          <Link href="#" onClick={() => handleStartContest(item)}>
            <Typography sx={{ fontSize: isMediumScreen ? '10px' : '14px' }}>
              Start Contest
            </Typography>
          </Link>
        ),
        ideaPoints: <Typography>{item.ideaPoints ?? 0}</Typography>
      };
      if (isCurrentUser) {
        baseCells['actions'] = (
          <CustomMenu
            showPin={false}
            actions={getActions(item)}
            item={item}
            itemType={'problems'}
            pagination={pagination}
            title="problem"
            type="Community"
            tagKey={key as string}
          />
        );
      }

      return baseCells;
    };
  }, [isMediumScreen, isCurrentUser, getActions, pagination, key]);

  const rows = {
    component: (icon, item) => getCells(icon, item),
    items: problems,
    pinnedItems: []
  };

  const tagLabel = `Add New ${tag ? tag.name : ''} Problem`;
  const onProblemAdd = () => {
    if (!tag) {
      return;
    }
    setIsProblemModalOpen(true);
    setCommunityTagHeader(tagLabel);
  };
  const contest = useMemo(
    () => ({
      children: [contestProblem]
    }),
    [contestProblem]
  );
  return (
    <StyledMainBox>
      {user ? (
        <ButtonContainer>
          <StyledPsButton
            isMediumScreen={isMediumScreen}
            onClick={onProblemAdd}
            color="secondary"
            smallest
          >
            <Typography
              sx={{
                fontSize: isMediumScreen ? '12px' : '14px',
                fontWeight: '550'
              }}
            >
              Add New Problem
            </Typography>
          </StyledPsButton>
        </ButtonContainer>
      ) : null}
      <StyledBox>
        {problemLoader && <LinearProgress value={10} />}
        {!problemLoader && problemsCount === 0 && (
          <DataNotFound
            iconUrl={ProblemIcon}
            modal={ModalComponentsKeys.addNewProblem}
            text={Constants.PROBLEM}
          />
        )}
        {!hasEmptyData && (
          <TableView
            headers={headers}
            page={page}
            rows={rows}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            totalRows={problemsCount}
            type={'problems'}
          />
        )}
      </StyledBox>
      {isProblemModalOpen && (
        <ProblemModal
          communityTagHeader={communityTagHeader}
          isCommunityTagsPopup={true}
          onClose={handleCloseModal}
          open={isProblemModalOpen}
        />
      )}
      {openContestModal && (
        <ContestModal
          contestProblem={contest}
          onClose={() => setOpenContestModal(false)}
          open={openContestModal}
          tag={tag}
        />
      )}
    </StyledMainBox>
  );
};

export default ProblemView;
