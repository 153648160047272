import { makeStyles } from 'tss-react/mui';
import { PsTheme } from '../theme';
import { useTheme } from '@mui/material/styles';

const modal = makeStyles()((theme) => {
  const psTheme = theme as PsTheme;

  return {
    modalHeader: {
      fontSize: 36,
      [psTheme.breakpoints.down('sm')]: {
        fontSize: 30
      },
      [psTheme.breakpoints.down('xs')]: {
        fontSize: 25
      }
    },
    modalPaper: {
      maxWidth: 600,
      height: 'fit-content',
      textAlign: 'center',
      [psTheme.breakpoints.down('xs')]: {
        padding: '0'
      },
      width: '100%'
    },
    modalRoot: {
      paddingLeft: 15,
      paddingRight: 15,
      [psTheme.breakpoints.down('xs')]: {
        paddingTop: 50
      }
    }
  };
});

const select = makeStyles()(() => {
  return {
    itemsSelect: {
      marginLeft: 8,
      height: 32,
      padding: 0,
      overflow: 'hidden'
    },
    itemsSelectWrapper: {
      alignItems: 'center',
      color: '#000000',
      display: 'flex',
      fontFeatureSettings: '"pnum" on, "onum" on',
      fontSize: 14,
      justifyContent: 'flex-end',
      lineHeight: '20px',
      marginBottom: 22,
      marginLeft: 15,
      textAlign: 'center',
      '& > span': {
        flexShrink: 0
      }
    }
  };
});

export const useStyles = () => {
  return {
    modal: modal(),
    select: select()
  };
};
