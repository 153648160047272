import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

interface LogoProps extends SvgIconProps {
  width?: number | string;
  height?: number | string;
}

export const XIcon: React.FC<LogoProps> = ({
  width = '1.8rem',
  height = '1.8rem',
  ...props
}) => {
  return (
    <SvgIcon sx={{ width: width, height: height }} viewBox="0 0 1227 1227">
      <g>
        <path
          d="m613.5 0c-338.815 0-613.5 274.685-613.5 613.5s274.685 613.5 613.5 613.5 613.5-274.685 613.5-613.5-274.685-613.5-613.5-613.5z"
          fill="#000"
        />
        <path
          d="m680.617 557.98 262.632-305.288h-62.235l-228.044 265.078-182.137-265.078h-210.074l275.427 400.844-275.427 320.142h62.239l240.82-279.931 192.35 279.931h210.074l-285.641-415.698zm-335.194-258.435h95.595l440.024 629.411h-95.595z"
          fill="#fff"
        />
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </SvgIcon>
  );
};
