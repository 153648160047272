import React from 'react';
import { Box, styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Application } from 'components/CardApplication';
import { Contest } from 'components/CardContest';
import { Problem } from 'components/CardProblem';
import { Solution } from 'components/CardSolution';
import { Tag } from 'components/CardTag';
import PsButton from 'components/common/PsButton';
import { ImageView } from 'components/ImageView/index';
import { colorPalette } from 'theme';

const StyledModalContainer = styled(Box)({
  backgroundColor: colorPalette.white,
  borderRadius: '7px',
  display: 'flex',
  justifyContent: 'center',
  padding: '35px',
  width: '90%'
});

const StyledContentContainer = styled(Box)({
  alignItems: 'center',
  backgroundColor: colorPalette.white,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
});

const StyledTypography = styled(Typography)({
  color: colorPalette.oxfordBlue,
  fontSize: '28px',
  fontWeight: '600',
  lineHeight: '34px',
  textAlign: 'center',
  width: '100%'
});

const StyledBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-around',
  width: '100%'
});

interface deleteModalProps {
  action: string;
  icon?: string;
  item: Problem | Solution | Application | Contest | Tag;
  onClose: () => void;
  onDelete: () => void;
  title: string;
}

const ConfirmationModal: React.FC<deleteModalProps> = ({
  action,
  icon,
  item,
  onClose,
  onDelete,
  title
}) => {
  const handleAction = () => {
    onDelete();
    onClose();
  };

  return (
    <StyledModalContainer>
      <StyledContentContainer>
        <StyledTypography>{`Are you sure you want to ${action} ${
          title ?? ''
        }?`}</StyledTypography>
        <ImageView
          iconImage={icon}
          title={item.title}
          imgClassName="placeholder_img"
          mainItemClassName="delete_modal_image_view"
          titleClassName="img_title"
          iconClassName="img_icon"
        />
        <StyledBox>
          <PsButton
            className="remove_modal_button"
            type="submit"
            variant="outlined"
            onClick={onClose}
          >
            Cancel
          </PsButton>
          <PsButton
            className="remove_modal_button"
            type="submit"
            color="error"
            onClick={handleAction}
          >
            {action.charAt(0).toUpperCase() + action.slice(1)}
          </PsButton>
        </StyledBox>
      </StyledContentContainer>
    </StyledModalContainer>
  );
};

export default ConfirmationModal;
