import React from 'react';
import { colorPalette } from 'theme';

export const HexagonIcon = ({
  size = 35,
  color = colorPalette.purple,
  textColor = colorPalette.white,
  left = 0,
  number = 0
}: {
  size?: number;
  color?: string;
  textColor?: string;
  left?: number;
  number?: number;
}) => {
  const integerNumber = Math.round(number);
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        left: `calc(${Math.max(0, Math.min(left, 96))}% - ${size / 2}px)`,
        top: 0,
        width: size,
        height: size,
        zIndex: 999
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size + 5}
        viewBox="0 0 26 27"
      >
        <path
          fill={color}
          d="M.15 9.535v8.96A5.03 5.03 0 00.825 21a4.96 4.96 0 001.828 1.825l7.902 4.521a4.989 4.989 0 004.873 0l7.901-4.52A4.96 4.96 0 0025.158 21c.439-.76.671-1.626.672-2.507V9.535a5.03 5.03 0 00-.672-2.507 4.958 4.958 0 00-1.83-1.825l-7.9-4.522a4.908 4.908 0 00-4.875 0L2.651 5.202A4.96 4.96 0 00.823 7.028 5.03 5.03 0 00.15 9.535zm3.232-3.03l7.902-4.52a3.453 3.453 0 013.413 0l7.9 4.52c.53.304.973.744 1.28 1.276.308.533.47 1.138.472 1.754v8.96a3.517 3.517 0 01-.472 1.752c-.308.532-.75.973-1.28 1.276l-7.9 4.52a3.453 3.453 0 01-3.413 0l-7.902-4.52a3.468 3.468 0 01-1.279-1.276 3.517 3.517 0 01-.47-1.753V9.535a3.518 3.518 0 01.47-1.753 3.468 3.468 0 011.28-1.276z"
          vectorEffect="non-scaling-stroke"
        ></path>
        <path
          fill={colorPalette.white}
          d="M12.882 26.916c-.48 0-.952-.125-1.371-.363l-8.877-5.078a2.784 2.784 0 01-1.025-1.023 2.824 2.824 0 01-.378-1.405V8.984c0-.495.13-.98.377-1.407.247-.427.6-.78 1.026-1.024l8.878-5.08a2.774 2.774 0 012.742 0l8.877 5.08c.425.243.779.596 1.025 1.023.246.426.377.911.378 1.405v10.063a2.824 2.824 0 01-.378 1.405c-.247.426-.6.78-1.025 1.023l-8.877 5.081a2.784 2.784 0 01-1.372.363z"
          vectorEffect="non-scaling-stroke"
        ></path>
        <path
          fill={color}
          d="M12.882 25.051a2.38 2.38 0 01-1.173-.31l-7.594-4.345a2.382 2.382 0 01-.877-.875 2.417 2.417 0 01-.323-1.202V9.711c0-.423.112-.839.323-1.204a2.38 2.38 0 01.877-.876l7.595-4.346a2.374 2.374 0 012.345 0l7.595 4.346c.364.208.666.51.877.875.21.365.322.78.323 1.202v8.609a2.416 2.416 0 01-.324 1.202c-.21.365-.512.667-.876.875l-7.594 4.347a2.38 2.38 0 01-1.174.31z"
          vectorEffect="non-scaling-stroke"
        ></path>
      </svg>
      <div
        style={{
          position: 'absolute',
          color: textColor,
          fontWeight: 'bold',
          fontSize: size / 2.5,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%'
        }}
      >
        {integerNumber}
      </div>
    </div>
  );
};

export default HexagonIcon;
