import React from 'react';
import { Helmet as Title } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

interface HelmetProps {
  title: string;
}

const Helmet = ({ title }: HelmetProps) => {
  const { key } = useParams();

  return (
    <Title>
      <title>{`MindMiner - ${title}${key ? `:${key}` : ''}`}</title>
    </Title>
  );
};

export default Helmet;
