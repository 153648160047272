/* eslint-disable */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { DiscordIcon } from 'components/icons/DiscordRoundIcon';
import { FacebookColoredIcon } from 'components/icons/FacebookColoredIcon';
import { GoogleColoredIcon } from 'components/icons/GoogleColoredIcon';
import { InstagramIcon } from 'components/icons/InstagramIcon';
import { LinkedinIcon } from 'components/icons/LinkedinIcon';
import { SnapchatIcon } from 'components/icons/SnapchatIcon';
import { TiktokIcon } from 'components/icons/TiktokIcon';
import { XIcon } from 'components/icons/XIcon';
import { YoutubeIcon } from 'components/icons/YoutubeIcon';
import useRouter from 'hooks/useRouter';
import Actions from 'redux-state/actions';
import { GetSocialAuthKey, GetUser } from 'redux-state/selectors';
import { PATH_NAMES, Constants } from 'utilities/constants';
import {
  StyledIconsBox,
  StyledMainBox,
  StyledTypography
} from './StyledComponents';

export const SocialLinks = () => {
  const user = GetUser();
  const router = useRouter();
  const dispatch = useDispatch();
  const socialAuthKeys = GetSocialAuthKey();

  const iconMap = {
    discord: <DiscordIcon width={20} height={20} />,
    facebook: <FacebookColoredIcon width={20} height={20} />,
    google: <GoogleColoredIcon width={20} height={20} />,
    instagram: <InstagramIcon width={20} height={20} />,
    linkedin: <LinkedinIcon width={20} height={20} />,
    snapchat: <SnapchatIcon width={20} height={20} />,
    tiktok: <TiktokIcon width={20} height={20} />,
    twitter: <XIcon width={20} height={20} />,
    youtube: <YoutubeIcon width={20} height={20} />
  };

  useEffect(() => {
    if (user) {
      dispatch(Actions.getSocialAuthKeys(user?.id));
    }
  }, [dispatch, user?.id]);

  return (
    <StyledMainBox>
      <StyledTypography
        onClick={() => router.push(PATH_NAMES.LINKED_ACCOUNTS)}
        mr={1.5}
      >
        {Constants.VIEW_SOCIAL_LINK}
      </StyledTypography>
      {socialAuthKeys &&
        socialAuthKeys.map((authKey, index) => {
          const IconComponent = iconMap[authKey.type];
          return (
            IconComponent && (
              <StyledIconsBox key={authKey.type} index={index}>
                {IconComponent}
              </StyledIconsBox>
            )
          );
        })}
    </StyledMainBox>
  );
};
