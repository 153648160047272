import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Node } from 'interface/common';
import SolutionModal from 'modals/AddSolution';
import ImproveModal from 'modals/ImproveModal';
import Actions from 'redux-state/actions';
import { GetUser } from 'redux-state/selectors';
import { Constants } from 'utilities/constants';
import { DrawerHeader } from './DrawerHeader';
import { HeaderContent } from './HeaderContent';
import { StyledDrawer } from './StyledComponents';

interface DetailsDrawerProps {
  drawerItemType?: string;
  image: string;
  isCurrentConceptSolution?: boolean;
  item: Node;
  onClose?: () => void;
  open: boolean;
  setImage: React.Dispatch<React.SetStateAction<string>>;
  setIsAddToConceptModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  votingPanel?: React.ReactNode;
}

export const DetailsDrawer: React.FC<DetailsDrawerProps> = ({
  drawerItemType,
  image,
  isCurrentConceptSolution = false,
  item,
  onClose,
  open,
  setImage,
  setIsAddToConceptModalOpen,
  votingPanel
}) => {
  const dispatch = useDispatch();
  const user = GetUser();
  const [isSolutionModalOpen, setIsSolutionModalOpen] =
    useState<boolean>(false);
  const [isProductModalOpen, setIsProductModalOpen] = useState<boolean>(false);
  const [showAllTags, setShowAllTags] = useState<boolean>(false);

  const onShowAllTagsClick = () => {
    setShowAllTags(!showAllTags);
  };

  const handleClick = useCallback(() => {
    if (
      item?.type === Constants.APPLICATION ||
      drawerItemType === Constants.APPLICATION
    ) {
      onClose();
      dispatch(Actions.toggleConceptCart(true));
    } else if (drawerItemType === Constants.PROBLEM) {
      setIsSolutionModalOpen(true);
    } else if (drawerItemType === Constants.PRODUCT) {
      setIsProductModalOpen(true);
    }
  }, [dispatch, drawerItemType, item?.type, onClose]);

  const openAddToConceptModal = useCallback(() => {
    if (user) {
      setIsAddToConceptModalOpen(true);
      onClose();
    } else {
      dispatch(Actions.openAuthModal(true));
    }
  }, [dispatch, onClose, setIsAddToConceptModalOpen, user]);

  return (
    <>
      <StyledDrawer
        anchor="right"
        onClick={(e) => {
          e.stopPropagation();
        }}
        open={open}
        onClose={onClose}
      >
        <DrawerHeader
          handleClick={handleClick}
          isCurrentConceptSolution={isCurrentConceptSolution}
          item={item}
          image={image}
          openAddToConceptModal={openAddToConceptModal}
        />
        <HeaderContent
          drawerItemType={drawerItemType}
          handleClick={handleClick}
          isCurrentConceptSolution={isCurrentConceptSolution}
          item={item}
          onShowAllTagsClick={onShowAllTagsClick}
          openAddToConceptModal={openAddToConceptModal}
          setImage={setImage}
          showAllTags={showAllTags}
          votingPanel={votingPanel}
        />
      </StyledDrawer>
      {isSolutionModalOpen && (
        <SolutionModal
          onClose={() => setIsSolutionModalOpen(false)}
          open={isSolutionModalOpen}
        />
      )}
      {isProductModalOpen && (
        <ImproveModal
          isImproveModalOpen={isProductModalOpen}
          productId={item.id}
          productKey={item.key}
          setIsImproveModalOpen={setIsProductModalOpen}
          shortTitle={item.title}
          title={item.title}
        />
      )}
    </>
  );
};
