import React, { useContext, useState } from 'react';
import { FaCheck } from 'react-icons/fa6';
import { MdQuestionMark } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import AuthContext from 'contexts/AuthContext';
import ModalContext, { ModalComponentsKeys } from 'contexts/ModalContext';
import ProblemModal from 'modals/AddProblem';
import SolutionModal from 'modals/AddSolution';
import Actions from 'redux-state/actions';
import { colorPalette } from 'theme';
import { Constants } from 'utilities/constants';
import {
  AttachedIcon,
  StyledBox,
  StyledButton,
  StyledTypography
} from './StyledComponents';

const HomeButtonsView = () => {
  const { user } = useContext(AuthContext);
  const dispatch = useDispatch();
  const { openModal } = useContext(ModalContext);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isSolModalOpen, setIsSolModalOpen] = useState<boolean>(false);

  const handleOpenModal = (key) => {
    if (user) {
      if (key === ModalComponentsKeys.addNewProblem) {
        setIsModalOpen(true);
      } else if (key === ModalComponentsKeys.addNewSolution) {
        setIsSolModalOpen(true);
      } else {
        openModal(key);
      }
    } else {
      dispatch(Actions.openAuthModal(true));
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const renderButton = (
    key,
    param,
    IconComponent,
    iconSize,
    iconColor,
    text
  ) => (
    <StyledButton
      variant="contained"
      onClick={() => handleOpenModal(key)}
      param={param}
    >
      <AttachedIcon type={param}>
        <IconComponent size={iconSize} color={iconColor} />
      </AttachedIcon>
      <StyledTypography>{text}</StyledTypography>
    </StyledButton>
  );

  return (
    <StyledBox>
      {renderButton(
        ModalComponentsKeys.addNewProblem,
        Constants.PROBLEM,
        MdQuestionMark,
        '25',
        colorPalette.red,
        Constants.ADD_NEW_PROBLEM
      )}
      {renderButton(
        ModalComponentsKeys.addNewSolution,
        Constants.SOLUTION,
        FaCheck,
        '25',
        colorPalette.green,
        Constants.ADD_NEW_SOLUTION
      )}

      {isModalOpen && (
        <ProblemModal open={isModalOpen} onClose={handleCloseModal} />
      )}
      {isSolModalOpen && (
        <SolutionModal
          open={isSolModalOpen}
          onClose={() => {
            setIsSolModalOpen(false);
          }}
        />
      )}
    </StyledBox>
  );
};

export default HomeButtonsView;
