import styled from '@emotion/styled';
import { Box, Button, Typography } from '@mui/material';

export const StyledHeaderWrapper = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between'
});

export const StyledHeaderText = styled(Typography)({
  fontSize: '28px',
  fontWeight: '700'
});

export const StyledFooterButton = styled(Button)({
  borderRadius: '6px',
  fontWeight: 600,
  height: '3.3125rem',
  margin: '.625rem 0 0 .625rem',
  textTransform: 'none',
  width: '8.125rem'
});

export const FooterContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end'
});

export const StyledRoyaltyText = styled(Typography)({
  fontSize: '22px',
  fontWeight: 500,
  margin: '19px 1.875rem .9375rem 1.875rem'
});
