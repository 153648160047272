import React, { useCallback, useContext, useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import {
  Card,
  CardTitle,
  CardImage,
  CardBody,
  CardHeader,
  CardOwner,
  CardTimestamp
} from 'components/new-card';
import { CardText } from 'components/card';
import { Problem, NextStepsProblem } from 'components/CardProblem';
import { Tags } from 'components/Tag';
import { ShareBtnSimple } from 'components/ShareBtn';
import { AuthContext } from 'contexts/AuthContext';
import { DataContext } from 'contexts/DataContext';
import { ModalComponentsKeys, ModalContext } from 'contexts/ModalContext';
import { ModalDataContext } from 'contexts/ModalDataContext';
import { Tab, TabContent, Tabs } from 'components/Tabs/Tabs';
import { ExpandCard } from 'components/ExpandCard';
import { RelatedJackpots } from 'components/RelatedJackpots';
import { RelatedSolutions } from 'components/RelatedSolutions';
import { RelatedApplications } from 'components/RelatedApplications';
import { RelatedProducts } from 'components/RelatedProducts';
import { RelatedPriorArts } from 'components/RelatedPriorArts';
import { GraphData, GraphDeps } from 'components/GraphDeps';
import { placeholderType } from '../../helpers';
import VotesNew from 'components/Votes/VotesNew';
import { FavouriteBtn } from 'components/FavouriteBtn';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { ButtonWithCounter } from 'components/common/ButtonWithCounter';
import { makeStyles } from 'tss-react/mui';
import { PsTheme } from '../../theme';
import { Constants } from 'utilities/constants';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {},
    tags: {
      '& > div': {
        marginTop: 4,
        marginBottom: 4
      }
    },
    actions: {
      display: 'flex',
      alignItems: 'center',
      padding: '16px 0',
      fontSize: '15px',
      color: '#454F63;'
    },
    coinsCount: {
      fontWeght: 600,
      fontSize: '18px',
      color: '#1D1D1B'
    },
    actionButton: {
      marginRight: 10
    },
    tagsButton: {
      marginTop: 10
    },
    related: {
      padding: '30px 0 0 0'
    },
    ownerVotes: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '16px'
    },
    votes: {
      marginLeft: 'auto',
      marginTop: 0,
      paddingTop: 0
    },
    card: {
      marginBottom: '32px'
    },
    questionMarkIcon: {
      color: '#c31a1a91',
      fontSize: '2.5rem'
    },
    iconSpace: {
      marginRight: '16px'
    }
  };
});

export type CardProblemDetailProps = {
  problem: Problem;
};

const CardProblemDetailView = ({ problem }: CardProblemDetailProps) => {
  const {
    id,
    title,
    teaser,
    body,
    owner,
    ownerInfo,
    createdAt,
    files,
    tags = [],
    tagsInfo = [],
    likes,
    dislikes
  } = problem;
  const { user } = useContext(AuthContext);
  const { dataProvider } = useContext(DataContext);
  const { openModal, openSolutionCreate } = useContext(ModalContext);
  const { updateField } = useContext(ModalDataContext);
  const { classes } = useStyles();

  const [relatedTab, setRelatedTab] = useState('Part of these Solutions');
  const [graph, setGraph] = useState<GraphData | undefined>();

  const image = files && files.length ? files[0] : undefined;

  useEffect(() => {
    if (!problem) {
      setGraph(undefined);
      return;
    }
    dataProvider
      .getGraph<GraphData>(Constants.PROBLEMS, problem.id, Constants.PROBLEM)
      .then((data) => {
        setGraph(data);
      })
      .catch((err) => {
        console.error(err);
        setGraph(undefined);
      });
  }, [problem]);

  const onSolveClick = useCallback(() => {
    openSolutionCreate({ problemId: id });
  }, [openSolutionCreate, id]);

  const onManageTagsClick = useCallback(() => {
    updateField('title', 'problems');
    updateField('productId', id);
    updateField('tags', tags);
    openModal(ModalComponentsKeys.manageTags);
  }, [updateField, openModal, id, tags]);

  const showEditTags = (user?.userData?.moderator || []).length;

  return (
    <>
      <Card className={classes.card}>
        <CardHeader>
          <CardTitle
            beforeTitle={
              <div className={classes.iconSpace}>
                <HelpOutlineIcon
                  className={classes.questionMarkIcon}
                ></HelpOutlineIcon>
              </div>
            }
            title={<>{title || teaser}</>}
          >
            <FavouriteBtn />
            <ShareBtnSimple problem={problem} />
          </CardTitle>
          <CardImage image={image} type={placeholderType.P} />
        </CardHeader>
        <CardBody>
          <div className={classes.ownerVotes}>
            <CardOwner
              ownerName={ownerInfo && ownerInfo.username}
              ownerKey={ownerInfo && ownerInfo.key}
              owner={owner}
              createdAt={createdAt}
              ownerPicture={ownerInfo?.picture?.url}
            />
            <VotesNew
              className={classes.votes}
              resource="problems"
              itemId={id}
              likes={likes}
              dislikes={dislikes}
              user={user}
            />
          </div>
          <Tags
            className={classes.tags}
            tagsList={tagsInfo}
            edit={showEditTags ? 'before' : ''}
            onEditClick={onManageTagsClick}
          />
          <div className={classes.actions}>
            <ButtonWithCounter
              className={classes.actionButton}
              onClick={onSolveClick}
              coins={30}
            >
              Solve
            </ButtonWithCounter>
            <span>
              Up to <span className={classes.coinsCount}>30</span> IdeaPoints
            </span>
          </div>
          <CardText>{body}</CardText>
        </CardBody>
      </Card>
      <ExpandCard title="next steps">
        <NextStepsProblem problem={problem} />
      </ExpandCard>
      <ExpandCard title="contests & challenges">
        <RelatedJackpots problem={problem.id} />
      </ExpandCard>
      <ExpandCard title="related items">
        <div className={classes.related}>
          <Tabs>
            <Tab
              label="Part of these Solutions"
              value={relatedTab}
              setValue={setRelatedTab}
            />
            <Tab
              label="Part of these Inventions/NFTs"
              value={relatedTab}
              setValue={setRelatedTab}
            />
            <Tab
              label="Part of these Products"
              value={relatedTab}
              setValue={setRelatedTab}
            />
          </Tabs>
          <TabContent label="Part of these Solutions" value={relatedTab}>
            <RelatedSolutions problem={problem?.id} />
          </TabContent>
          <TabContent label="Part of these Inventions/NFTs" value={relatedTab}>
            <RelatedApplications problem={problem?.id} />
          </TabContent>
          <TabContent label="Part of these Products" value={relatedTab}>
            <RelatedProducts problem={problem?.id} />
          </TabContent>
        </div>
      </ExpandCard>
      <ExpandCard title="prior art search">
        <RelatedPriorArts problem={problem?.id} />
      </ExpandCard>
      {/*<ExpandCard title="more details"></ExpandCard>*/}
      {/*{graph ? (*/}
      {/*  <ExpandCard title="problem map" open>*/}
      {/*    <GraphDeps nodes={graph.nodes} links={graph.links} />*/}
      {/*  </ExpandCard>*/}
      {/*) : null}*/}
    </>
  );
};

export const CardProblemDetail = styled(CardProblemDetailView)({});

export default CardProblemDetail;
