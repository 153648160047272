import React from 'react';
import { IconButton, InputAdornment } from '@mui/material';
import { colorPalette } from 'theme';
import { Constants, INPUT_TYPES, VARIANT } from 'utilities/constants';
import {
  FieldWrapper,
  StyledTextField,
  VisibilityIcon,
  VisibilityOffIcon
} from './styledComponents';

interface CustomTextFieldProps {
  borderColorOnHover: string;
  error: boolean;
  fillColor: string;
  fullWidth?: boolean;
  helperText: string;
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  onChange?: any;
  onKeyDown?: any;
  placeholder?: string;
  type?: string;
  value?: string;
}

export const CustomTextField: React.FC<CustomTextFieldProps> = ({
  borderColorOnHover,
  error,
  fillColor,
  fullWidth = true,
  helperText,
  Icon,
  onChange,
  onKeyDown,
  placeholder,
  type,
  value
}) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const togglePasswordVisibility = () =>
    setShowPassword((prevShowPassword) => !prevShowPassword);

  const inputType =
    type === INPUT_TYPES.PASSWORD
      ? showPassword
        ? INPUT_TYPES.TEXT
        : INPUT_TYPES.PASSWORD
      : type;

  return (
    <FieldWrapper>
      <StyledTextField
        borderColorOnHover={borderColorOnHover}
        fillColor={fillColor}
        fullWidth={fullWidth}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        InputProps={{
          startAdornment: Icon ? (
            <InputAdornment position={Constants.INPUT_ADORNMENT_POSITION_START}>
              <Icon />
            </InputAdornment>
          ) : null,
          endAdornment: type === INPUT_TYPES.PASSWORD && (
            <InputAdornment position={VARIANT.END}>
              <IconButton onClick={togglePasswordVisibility}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
          style: {
            backgroundColor: fillColor,
            borderRadius: '1rem',
            color: colorPalette.black,
            fontSize: 12,
            fontWeight: 400,
            height: '3.25rem',
            marginBottom: '0.2rem',
            width: '100%'
          }
        }}
        placeholder={placeholder}
        type={inputType}
        variant={VARIANT.OUTLINED}
        error={error}
        helperText={helperText}
      />
    </FieldWrapper>
  );
};
