import React, { useCallback, useContext, useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { PsButton } from 'components/common/PsButton';
import {
  Card,
  CardImage,
  CardBody,
  CardTitle,
  CardOwner,
  CardText,
  CardActions
} from 'components/card';
import { Tags } from 'components/Tag';
import { ShareBtn } from 'components/ShareBtn';
import { OwnerInfo } from 'components/CardProfile';
import { TagInfo } from 'components/CardTag';
import { PsRecord } from '../../dataPrvider';
import { AuthContext } from 'contexts/AuthContext';
import { ModalContext } from 'contexts/ModalContext';
import { Picture, getArticle, placeholderType } from '../../helpers';
import { PsTheme } from '../../theme';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root' | 'tags' | 'actionButton';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {},
    tags: {
      '& > div': {
        marginTop: 4,
        marginBottom: 4
      }
    },
    actionButton: {
      marginRight: 10
    }
  };
});

export type PriorArt = PsRecord & {
  key: string;
  title?: string;
  body?: string;
  url: string;
  alias: string;
  files?: Array<Picture>;
  createdAt?: string;
  relatedType: string;
  relatedId: string;
  tags?: string[];
  tagsInfo?: Array<TagInfo>;
  isPublic: boolean;
  likes: number;
  dislikes: number;
  reward?: number;
};

export type CardPriorArtProps = {
  priorArt: PriorArt;
};

const CardPriorArtView = ({ priorArt }: CardPriorArtProps) => {
  const {
    id,
    key,
    title,
    body,
    createdAt,
    files,
    tagsInfo = [],
    likes,
    dislikes
  } = priorArt;
  const { user } = useContext(AuthContext);
  const { openInventionImprove } = useContext(ModalContext);
  const { classes } = useStyles();

  const image = files && files.length ? files[0] : undefined;
  const url = `/prior-arts/${key || id}`;

  const onImproveClick = useCallback(() => {
    openInventionImprove({
      inventionId: id,
      inventionTitle: title,
      type: 'prior-art'
    });
  }, [openInventionImprove, id, title]);

  return (
    <Card>
      <CardImage href={url} image={image} type={placeholderType.A} />
      <CardBody>
        <CardTitle title={title} href={url}>
          <Tags className={classes.tags} tagsList={tagsInfo} />
        </CardTitle>
        <CardText>{body}</CardText>
        <CardActions
          resource="prior-arts"
          resourceId={id}
          likes={likes}
          dislikes={dislikes}
          user={user}
        >
          <>
            <PsButton
              className={classes.actionButton}
              onClick={onImproveClick}
              coins="up to 30"
            >
              Improve
            </PsButton>
            <ShareBtn priorArt={priorArt} tagsList={tagsInfo} />
          </>
        </CardActions>
      </CardBody>
    </Card>
  );
};

export const CardPriorArt = styled(CardPriorArtView)({});

export default CardPriorArt;
