import React, { FC, useEffect, useMemo, useState } from 'react';
import { ExpandMore } from '@mui/icons-material';
import {
  AccordionDetails,
  CircularProgress,
  LinearProgress,
  Typography
} from '@mui/material';
import { useChecklist } from 'components/common/CheckListData';
import { ConceptCheckList } from 'components/common/ConceptCheckList';
import { ProgressStatus } from 'components/ConceptView/ConceptDetail';
import dataProvider from 'dataPrvider';
import { GetUser } from 'redux-state/selectors';
import { Constants, ERRORS, VARIANT } from 'utilities/constants';
import {
  AIChatLink,
  ChatLinkWrapper,
  ProgressWrapper,
  StyledAccordionContentBox,
  StyledAccordionSummary,
  StyledLinearProgress,
  StyledProgressAccordion
} from './styledComponents';

export const ConceptProgressAccordion: FC = () => {
  const user = GetUser();
  const activeConcept = useMemo(() => user?.activeConcept || {}, [user]);

  const [checkListData, setCheckListData] = useState<ProgressStatus>(null);
  const { fulfilled, warnings } = useChecklist(checkListData);

  const totalChecks = fulfilled.length + warnings.length;
  const passedChecks = fulfilled.length;
  const progressPercentage = Math.round((passedChecks / totalChecks) * 100);

  useEffect(() => {
    const fetchProgress = async () => {
      try {
        if (activeConcept) {
          const response = await dataProvider.checkImprovedProgress(
            activeConcept?._id ?? activeConcept?.id
          );
          setCheckListData(response ?? null);
        }
      } catch (error) {
        console.error(ERRORS.ERROR_FETCHING_PROGRESS_DATA, error);
      }
    };

    fetchProgress();
  }, [activeConcept]);

  return (
    <StyledProgressAccordion>
      <StyledAccordionSummary expandIcon={<ExpandMore />}>
        <StyledAccordionContentBox>
          <ProgressWrapper>
            <StyledLinearProgress
              variant={VARIANT.DETERMINATE}
              value={progressPercentage >= 100 ? 100 : progressPercentage}
            />
          </ProgressWrapper>
          {activeConcept && checkListData ? (
            <Typography variant={VARIANT.SUBTITLE1} fontWeight={VARIANT.BOLD}>
              {progressPercentage >= 100 ? 100 : progressPercentage}
              {Constants.PERCENTAGE_IMPROVED}
            </Typography>
          ) : (
            <CircularProgress size={24} />
          )}
        </StyledAccordionContentBox>
      </StyledAccordionSummary>
      <AccordionDetails>
        <ChatLinkWrapper mb={2}>
          <AIChatLink href="#">{Constants.IMPROVE_CONCEPT_AI_TEXT}</AIChatLink>
        </ChatLinkWrapper>
        {activeConcept && checkListData ? (
          <ConceptCheckList fulfilled={fulfilled} warnings={warnings} />
        ) : (
          <LinearProgress />
        )}
      </AccordionDetails>
    </StyledProgressAccordion>
  );
};
