import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Actions from 'redux-state/actions';
import {
  GetAgreementsCount,
  GetConceptsCount,
  GetContestsCount,
  GetInventionsCount,
  GetProblemsCount,
  GetSolutionsCount
} from 'redux-state/selectors';
import { colorPalette, useIsMediumScreen } from 'theme';
import {
  StyledBox,
  StyledTab,
  StyledTabContent,
  StyledTabIcon,
  StyledTabLabel,
  StyledTabs
} from './styles';
import useRouter from 'hooks/useRouter';
import { Constants } from 'utilities/constants';

interface Tab {
  content: React.ReactNode;
  title: string;
  key: string;
}

interface TabViewProps {
  activeTab?: string;
  archiveStatus: boolean;
  handleArchiveToggle: (toggle: boolean) => void;
  setArchiveStatus: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  tabs: Tab[];
  viewOwnProfile?: boolean;
}

const TabsView: React.FC<TabViewProps> = ({
  tabs,
  activeTab = Constants.PROBLEMS,
  setActiveTab,
  setArchiveStatus,
  viewOwnProfile
}) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { id } = router.query;

  const problemsCount = GetProblemsCount();
  const solutionsCount = GetSolutionsCount();
  const inventionsCount = GetInventionsCount();
  const contestsCount = GetContestsCount();
  const conceptsCount = GetConceptsCount();
  const agreementsCount = GetAgreementsCount();

  const isMediumScreen = useIsMediumScreen();

  const actionMapper: { [key: string]: number } = {
    [Constants.PROBLEMS]: problemsCount,
    [Constants.SOLUTIONS]: solutionsCount,
    [Constants.INVENTIONS]: inventionsCount,
    [Constants.CONTESTS]: contestsCount,
    ...(viewOwnProfile && {
      [Constants.CONCEPTS]: conceptsCount,
      [Constants.AGREEMENTS]: agreementsCount
    })
  };

  useEffect(() => {
    if (id) {
      dispatch(Actions.getProfileProblemsCount(id as string));
      dispatch(Actions.getProfileSolutionsCount(id as string));
      dispatch(Actions.getProfileInventionsCount(id as string, true));
      dispatch(Actions.getProfileContestsCount(id as string));
      if (viewOwnProfile) {
        dispatch(Actions.getProfileConceptsCount(id as string, false));
        dispatch(Actions.getProfileAgreementsCount(id as string));
      }
    }
  }, [id, dispatch, viewOwnProfile]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
    setArchiveStatus(false);
  };

  return (
    <StyledBox isMediumScreen={isMediumScreen}>
      <StyledTabs
        aria-label="secondary tabs example"
        indicatorColor="primary"
        isMediumScreen={isMediumScreen}
        onChange={handleTabChange}
        orientation="horizontal"
        scrollButtons="auto"
        TabIndicatorProps={{
          style: {
            backgroundColor: colorPalette.darkPurple
          }
        }}
        textColor="secondary"
        value={activeTab}
        variant="scrollable"
      >
        {tabs.map((tab) => (
          <StyledTab
            key={tab.key}
            label={
              <StyledTabContent>
                <StyledTabIcon isMediumScreen={isMediumScreen} tabKey={tab.key}>
                  {actionMapper[tab.key]}
                </StyledTabIcon>
                <StyledTabLabel
                  active={activeTab === tab.key}
                  isMediumScreen={isMediumScreen}
                >
                  {tab.title}
                </StyledTabLabel>
              </StyledTabContent>
            }
            value={tab.key}
          />
        ))}
      </StyledTabs>
      {tabs.find((tab) => tab.key === activeTab)?.content}
    </StyledBox>
  );
};

export default TabsView;
