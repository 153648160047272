import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useDispatch } from 'react-redux';
import { EmailOutlined, PersonOutlineRounded } from '@mui/icons-material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { CustomTextField } from 'components/common/inputs/CustomTextField';
import { validateEmail } from 'helpers/validateEmail';
import Actions from 'redux-state/actions';
import { GetErrorMessage, GetProfileStatusCode } from 'redux-state/selectors';
import { colorPalette } from 'theme';
import {
  Constants,
  ERRORS,
  FORM_VIEW,
  INPUT_TYPES,
  VARIANT
} from 'utilities/constants';
import { SocialIconsView } from './SocialIconsView';
import {
  ActionButton,
  ButtonTypography,
  InfoTypography,
  LinkWrapper,
  MainBox,
  TitleTypography
} from './styledComponents';

interface SignUpViewProps {
  setFormView: Dispatch<SetStateAction<string>>;
}

type FieldValidityType = {
  email: boolean;
  password: boolean;
  confirmPassword: boolean;
  userName: boolean;
};

export const SignUpView: React.FC<SignUpViewProps> = ({ setFormView }) => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [fieldsValidity, setFieldsValidity] = useState<FieldValidityType>({
    email: true,
    password: true,
    confirmPassword: true,
    userName: true
  });

  const statusCode = GetProfileStatusCode();
  const errorMessage = GetErrorMessage();

  const validateInputs = useCallback(() => {
    dispatch(Actions.updateProfileErrorStatus(null, ''));
    const passwordIsEmpty = password === '';
    const userNameIsEmpty = username === '';
    const confirmPasswordIsEmpty = confirmPassword === '';
    const passwordMatch = password === confirmPassword;
    const emailIsValid = validateEmail(email);

    setFieldsValidity({
      confirmPassword: !confirmPasswordIsEmpty && passwordMatch,
      email: emailIsValid,
      password: !passwordIsEmpty,
      userName: !userNameIsEmpty
    });
    return (
      !passwordIsEmpty &&
      !confirmPasswordIsEmpty &&
      emailIsValid &&
      passwordMatch &&
      !userNameIsEmpty
    );
  }, [confirmPassword, dispatch, email, password, username]);

  const onEmailChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setEmail(value);
    },
    [setEmail]
  );

  const onUserNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setUsername(value);
    },
    [setUsername]
  );

  const onConfirmPasswordChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setConfirmPassword(value);
    },
    [setConfirmPassword]
  );

  const onPasswordChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setPassword(value);
    },
    [setPassword]
  );

  const getEmailHelperText = useCallback(() => {
    if (!fieldsValidity.email) {
      dispatch(Actions.updateErrorStatus(null, ''));
      return ERRORS.INVALID_EMAIL;
    } else if (statusCode == 409) {
      return errorMessage;
    }
    return '';
  }, [dispatch, errorMessage, fieldsValidity, statusCode]);

  const getErrorText = useCallback(() => {
    if (statusCode == 401) {
      return errorMessage;
    } else if (statusCode != 200 && statusCode != 409 && errorMessage) {
      return ERRORS.UNEXPECTED_ERROR;
    }
    return '';
  }, [errorMessage, statusCode]);

  const onLoginClick = useCallback(() => {
    setFormView(FORM_VIEW.LOGIN);
  }, [setFormView]);

  const { emailHelperText, errorText } = useMemo(() => {
    const emailHelperText = getEmailHelperText();
    const errorText = getErrorText();
    return { emailHelperText, errorText };
  }, [getEmailHelperText, getErrorText]);

  const onSignUpClick = useCallback(() => {
    if (validateInputs()) {
      const payload = { username, email, password };
      dispatch(Actions.signUp(payload));
    }
  }, [dispatch, email, password, username, validateInputs]);

  return (
    <MainBox isSignUp={true}>
      <TitleTypography>{Constants.SIGN_UP}</TitleTypography>
      <CustomTextField
        borderColorOnHover={colorPalette.purple}
        error={!fieldsValidity.userName}
        fillColor={colorPalette.lightLavender}
        fullWidth
        helperText={!fieldsValidity.userName ? ERRORS.EMPTY_USERNAME : ''}
        Icon={PersonOutlineRounded}
        onChange={onUserNameChange}
        placeholder={Constants.C_USERNAME}
        type={INPUT_TYPES.TEXT}
        value={username}
      />
      <CustomTextField
        borderColorOnHover={colorPalette.purple}
        error={!fieldsValidity.email}
        fillColor={colorPalette.lightLavender}
        fullWidth
        helperText={emailHelperText}
        Icon={EmailOutlined}
        onChange={onEmailChange}
        placeholder={Constants.C_EMAIL}
        type={INPUT_TYPES.EMAIL}
        value={email}
      />
      <CustomTextField
        borderColorOnHover={colorPalette.purple}
        error={!fieldsValidity.password}
        fillColor={colorPalette.lightLavender}
        fullWidth
        helperText={!fieldsValidity.password ? ERRORS.EMPTY_PASSWORD : ''}
        Icon={LockOutlinedIcon}
        onChange={onPasswordChange}
        placeholder={Constants.C_PASSWORD}
        type={INPUT_TYPES.PASSWORD}
        value={password}
      />
      <CustomTextField
        borderColorOnHover={colorPalette.purple}
        error={!fieldsValidity.confirmPassword}
        fillColor={colorPalette.lightLavender}
        fullWidth
        helperText={
          !fieldsValidity.confirmPassword ? ERRORS.MISMATCH_PASSWORD : errorText
        }
        Icon={LockOutlinedIcon}
        onChange={onConfirmPasswordChange}
        placeholder={Constants.CONFIRM_PASSWORD}
        type={INPUT_TYPES.PASSWORD}
        value={confirmPassword}
      />
      <ActionButton
        isSignUp={true}
        onClick={onSignUpClick}
        variant={VARIANT.CONTAINED}
      >
        <ButtonTypography>{Constants.SIGN_UP}</ButtonTypography>
      </ActionButton>
      <InfoTypography>{Constants.SIGN_UP_SOCIAL}</InfoTypography>
      <SocialIconsView />
      <InfoTypography>
        {Constants.HAVE_ACCOUNT_ALREADY}{' '}
        <LinkWrapper onClick={onLoginClick}>{Constants.LOGIN}</LinkWrapper>
      </InfoTypography>
    </MainBox>
  );
};
