import React, { useState, useEffect, useContext, useCallback } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { DataContext } from 'contexts/DataContext';
import { Contest } from 'components/CardContest';
import { Pagination } from 'components/Pagination';
import { CardContestSmall } from './CardContestSmall';
import { PsTheme } from '../../theme';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'block' | 'header' | 'loading' | 'noResult';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    block: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginTop: 10
    },
    header: {
      fontSize: 32,
      fontWeight: 'bold',
      lineHeight: '39px',
      marginTop: 24,
      color: psTheme.palette.third.main,
      [psTheme.breakpoints.down('sm')]: {
        fontSize: 25,
        lineHeight: '30px'
      }
    },
    loading: {
      lineHeight: '30px',
      textAlign: 'center'
    },
    noResult: {
      lineHeight: '30px',
      textAlign: 'center'
    }
  };
});

type RelatedContestsProps = {
  title?: string;
  noResultContent?: React.ReactNode;
  challenge?: string | number;
};

const RelatedContestsView = ({
  title,
  noResultContent,
  challenge
}: RelatedContestsProps) => {
  const { dataProvider, relatedContestsListCache } = useContext(DataContext);
  const [page, setPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [contests, setContests] = useState<Array<Contest>>([]);
  const [loading, setLoading] = useState(true);
  const { classes } = useStyles();
  const perPage = 5;

  useEffect(() => {
    if (!challenge) {
      setContests([]);
      setLoading(false);
      return;
    }
    let filter = {};
    if (challenge) {
      filter = {
        $custom: { type: 'selectedForChallenge', challenge }
      };
    }

    setLoading(true);
    dataProvider
      .getList<Contest>('contests', {
        pagination: { page: page + 1, perPage },
        sort: { field: 'votes', order: 'DESC' },
        filter
      })
      .then(({ data, total }) => {
        setContests(data);
        setTotalPages(Math.ceil(total / perPage));
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [page, challenge, relatedContestsListCache]);

  const onPageChange = (page: number) => {
    setPage(page);
  };

  return (
    <div className={classes.block}>
      {title ? (
        <Typography className={classes.header} variant="h2" component="h2">
          {title}
        </Typography>
      ) : null}
      {loading ? (
        <Typography className={classes.loading} variant="body2">
          Loading...
        </Typography>
      ) : null}
      {!loading && !contests.length ? (
        <Typography className={classes.noResult}>
          {noResultContent
            ? noResultContent
            : 'This item does not yet have any contests'}
        </Typography>
      ) : null}
      {contests.map((contest) => {
        return <CardContestSmall key={contest.id} contest={contest} />;
      })}
      <Pagination total={totalPages} active={page} onChange={onPageChange} />
    </div>
  );
};

export const RelatedContests = styled(RelatedContestsView)({});

export default RelatedContests;
