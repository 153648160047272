import { Box, Button, Typography, styled } from '@mui/material';
import { colorPalette } from 'theme';

export const StyledHeaderText = styled(Typography)({
  fontSize: '28px',
  fontWeight: '700'
});

export const StyledHeaderWrapper = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between'
});

export const StyledFooterText = styled(Button)({
  color: `${colorPalette.purple}`,
  fontSize: '18px',
  fontWeight: '700',
  textTransform: 'none',
  '&:hover': {
    textDecoration: 'none',
    backgroundColor: 'white'
  }
});

export const StyledFooterButton = styled(Button)({
  borderRadius: '6px',
  fontWeight: 600,
  height: '3.3125rem',
  textTransform: 'none',
  width: '8.125rem'
});

export const FooterContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between'
});
