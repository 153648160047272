import React from 'react';
import { FormControlLabel } from '@mui/material';
import { Constants } from 'utilities/constants';
import { OptionType } from 'modals/Common/interface';
import { ProductField } from 'components/common/ProductField';
import PsInput from 'components/common/PsInput';
import ProblemField from 'components/common/ProblemField';
import { StyledRadio, StyledRadioGroup } from '../styledComponents';

interface ContentProps {
  companyId?: string;
  problemData?: string | null;
  selectedParentProblem?: OptionType;
  selectedProduct?: OptionType;
  setProblemData?: React.Dispatch<React.SetStateAction<string>>;
  setSelectedParentProblem?: React.Dispatch<React.SetStateAction<OptionType>>;
  setSelectedProduct?: React.Dispatch<React.SetStateAction<OptionType>>;
  setSolutionData?: React.Dispatch<React.SetStateAction<string>>;
  setSort?: React.Dispatch<React.SetStateAction<string>>;
  solutionData?: string;
  sort?: string;
}

const sortItems = [
  { value: Constants.PROBLEM, label: Constants.C_PROBLEM },
  { value: Constants.SOLUTION, label: Constants.C_SOLUTION }
];

export const Content: React.FC<ContentProps> = ({
  companyId,
  problemData,
  selectedParentProblem,
  selectedProduct,
  setProblemData,
  setSelectedParentProblem,
  setSelectedProduct,
  setSolutionData,
  setSort,
  solutionData,
  sort
}) => {
  const handleRadioChange = (event) => {
    setSort(event.target.value);
  };

  const handleProblemInputChange = (event) => {
    setProblemData(event.target.value);
  };

  const handleSolutionInputChange = (event) => {
    setSolutionData(event.target.value);
  };

  return (
    <>
      <StyledRadioGroup value={sort} onChange={handleRadioChange}>
        {sortItems.map((item) => (
          <FormControlLabel
            key={item.value}
            value={item.value}
            control={<StyledRadio />}
            label={item.label}
          />
        ))}
      </StyledRadioGroup>
      {sort === Constants.PROBLEM && (
        <>
          <ProductField
            companyId={companyId}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            boldLabel={true}
          />
          <PsInput
            name={Constants.TITLE_PROBLEM}
            placeHolder={Constants.DEFAULT_PROBLEM_TITLE}
            rows={6}
            multiline
            onChange={handleProblemInputChange}
            value={problemData}
          />
        </>
      )}
      {sort === Constants.SOLUTION && (
        <>
          <ProblemField
            selectedProblem={selectedParentProblem}
            setSelectedProblem={setSelectedParentProblem}
          />
          <PsInput
            multiline
            name={Constants.TITLE_SOLUTION}
            onChange={handleSolutionInputChange}
            placeHolder={Constants.DEFAULT_SOLUTION_TITLE}
            rows={6}
            value={solutionData}
          />
        </>
      )}
    </>
  );
};
