import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Card, IconButton, Modal, TextField } from '@mui/material';
import { Application } from 'components/CardApplication/CardApplication';
import PsButton from 'components/common/PsButton';
import AuthContext from 'contexts/AuthContext';
import DataContext from 'contexts/DataContext';
import WalletContext from 'contexts/WalletContext';
import dataProvider from 'dataPrvider';
import Actions from 'redux-state/actions';
import walletProvider from 'walletProvider';
import { ModalWindow } from '../ModalWindow';

const ModalWindowProps = { maxWidth: '650px', margin: '0 auto' };
const DeployButtonProps = { width: '100%', marginBottom: '16px' };
const CancelButtonProps = { width: '100%' };
const ControllerStyleProps = { paddingBottom: '20px', width: '100%' };
const CardStyleProps = { boxShadow: 'none', paddingTop: '20px' };

type DeployNftProps = {
  application: Application;
  onClose: () => void;
  onDeploy?: () => void;
  open: boolean;
};
type PickApplicationKeys = Pick<Application, 'id' | 'title' | 'owner'>;

export const DeployNft: React.FC<DeployNftProps> = ({
  onClose,
  onDeploy,
  open,
  application
}) => {
  const { magicAddress } = useContext(WalletContext);
  const { setAlertContent } = useContext(DataContext);
  const { user } = useContext(AuthContext);
  const dispatch = useDispatch();
  const { handleSubmit, control, formState, setValue } = useForm();
  const [loading, setLoading] = useState(false);
  const [record, setRecord] = useState<PickApplicationKeys | undefined>(
    application
  );

  const hasNoSolutions = useCallback((record: Application) => {
    const list = record.selected || [];
    const withoutProblem = list.filter((item: string) => {
      return item != record.problem;
    });
    return withoutProblem.length <= 0;
  }, []);

  const onModalDeploy = useCallback(() => {
    setRecord(undefined);
    onDeploy?.();
  }, [onDeploy]);

  const onSubmit = useCallback(
    (values: { address: string }) => {
      if (!record) {
        setAlertContent(<>Record Not Found</>);
        return;
      }

      const { address } = values;
      const { id } = record;

      if (!address) {
        setAlertContent(<>Address Not Valid</>);
        return;
      }

      if (hasNoSolutions(record as unknown as Application)) {
        setAlertContent(
          <>
            You need to add at least one solution to the invention before
            minting NFT
          </>
        );
        return;
      }
      if (user) {
        setLoading(true);
        dataProvider
          .nftDeployStart(id)
          .then(({ contractAddress, tokenURI }) => {
            return walletProvider.deployWithMetamask(
              contractAddress,
              tokenURI,
              address
            );
          })
          .then(([transactionHash, tokenId]) => {
            return dataProvider.nftDeployFinish(id, {
              transactionHash,
              tokenId
            });
          })
          .then(() => {
            onModalDeploy();
          })
          .catch((error) => {
            setAlertContent(<>{error.message}</>);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        dispatch(Actions.openAuthModal(true));
      }
    },
    [record, hasNoSolutions, user, setAlertContent, onModalDeploy]
  );

  const handleClose = useCallback(() => onClose(), [onClose]);

  const { disableSubmitBtn } = useMemo(() => {
    const disableSubmitBtn =
      loading ||
      !formState.isValid ||
      !record ||
      hasNoSolutions(record as unknown as Application);
    return { disableSubmitBtn };
  }, [formState.isValid, hasNoSolutions, loading, record]);

  const isWalletAddress = useCallback((address) => {
    return /^0x[a-fA-F0-9]{40}$/.test(address);
  }, []);

  const handleClear = useCallback(() => {
    setValue('address', '');
    setRecord(undefined);
  }, [setValue]);

  const MemoizedForm = useMemo(() => {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Controller
            control={control}
            defaultValue={magicAddress}
            name="address"
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={handleClear}>
                      <CancelIcon />
                    </IconButton>
                  )
                }}
                label="Transfer address"
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
                sx={ControllerStyleProps}
              />
            )}
            rules={{
              required: 'Transfer address is required',
              validate: isWalletAddress
            }}
          />
        </Box>
        <Box>
          <PsButton
            disabled={disableSubmitBtn}
            loading={loading}
            style={DeployButtonProps}
            type="submit"
            variant="contained"
          >
            Deploy
          </PsButton>
          <PsButton
            disabled={loading}
            onClick={handleClose}
            style={CancelButtonProps}
            type="button"
            variant="outlined"
          >
            Cancel
          </PsButton>
        </Box>
      </form>
    );
  }, [
    control,
    disableSubmitBtn,
    handleClose,
    handleSubmit,
    isWalletAddress,
    loading,
    magicAddress,
    onSubmit
  ]);

  return (
    <Modal open={open}>
      <ModalWindow title="Deploy NFT" titleCenter style={ModalWindowProps}>
        <Box>
          <Card style={CardStyleProps}>{MemoizedForm}</Card>
        </Box>
      </ModalWindow>
    </Modal>
  );
};
