import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { RadioProps, Radio as MuiRadio } from '@mui/material';

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      marginLeft: 8,
      color: theme.palette.text.primary,
      '& + span': {
        padding: '17px 17px 17px 5px',
        color: theme.palette.text.primary,
        fontSize: 16
      },
      '&.Mui-checked': {
        color: theme.palette.primary.main
      },
      '&.Mui-checked + span': {
        color: theme.palette.primary.main
      }
    }
  };
});

// Inspired by blueprintjs
export function Radio(props: RadioProps) {
  const { classes } = useStyles();

  return <MuiRadio classes={classes} {...props} />;
}

export default Radio;
