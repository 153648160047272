import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  styled
} from '@mui/material';
import { colorPalette } from 'theme';
import { Constants } from 'utilities/constants';
import { StyledIoIosInformationCircle } from './StyledComponents';

interface DescriptionModalProps {
  onClose: () => void;
  open: boolean;
}

export const DescriptionModal: React.FC<DescriptionModalProps> = ({
  onClose,
  open
}) => {
  return (
    <StyledDialog
      aria-labelledby="customized-dialog-title"
      onClose={onClose}
      open={open}
    >
      <DialogContent sx={{ textAlign: 'center' }}>
        <StyledBoxUpper>
          <StyledIoIosInformationCircle
            size={100}
            color={colorPalette.purple}
          />
        </StyledBoxUpper>
        <StyledTitle>Jackpot Reward</StyledTitle>
        <StyledTypography sx={{ marginBottom: '0.625rem' }}>
          {Constants.CONTEST_WINNER_INFO_ONE}
        </StyledTypography>
        <StyledTypography>{Constants.CONTEST_WINNER_INFO_TWO}</StyledTypography>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'center'
        }}
      >
        <Button onClick={onClose} variant="contained" color="primary">
          Ok
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export const StyledDialog = styled(Dialog)({
  '& .MuiDialogContent-root': {
    padding: '2rem'
  },
  '& .MuiDialogActions-root': {
    padding: '1rem'
  },
  '& .MuiPaper-root': {
    maxWidth: '50rem',
    borderRadius: '0.25rem'
  }
});

export const StyledBoxUpper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '1rem'
});

export const StyledTitle = styled(Box)({
  fontWeight: 600,
  fontSize: '1.5rem',
  marginBottom: '0.625rem'
});

export const StyledTypography = styled(Typography)({
  fontWeight: 500,
  fontSize: '1rem',
  color: colorPalette.veryDarkGrey
});
