import React from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { PsTheme } from '../../theme';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root' | 'addBtn' | 'list';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      userSelect: 'none'
    },
    addBtn: {
      appearance: 'none',
      display: 'flex',
      alignItems: 'center',
      margin: '0 0 5px 0',
      padding: '3px 3px',
      background: 'none',
      border: 'none',
      borderRadius: 6,
      fontSize: 16,
      cursor: 'pointer',
      '& svg': {
        marginRight: 5
      },
      '&:hover': {
        color: psTheme.palette.primary.main
      }
    },
    list: {}
  };
});

type DrawerModalListProps = {
  children?: React.ReactNode;
  editable?: boolean;
  onAdd?: (e: React.MouseEvent) => void;
};

const DrawerModalListView = ({
  children,
  editable,
  onAdd
}: DrawerModalListProps) => {
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      {editable ? (
        <button title="Add Solution" className={classes.addBtn} onClick={onAdd}>
          <AddCircleOutlineIcon /> Add Solution
        </button>
      ) : null}
      <div className={`drawer-list ${classes.list}`}>{children}</div>
    </div>
  );
};

export const DrawerModalList = styled(DrawerModalListView)({});

export default DrawerModalList;
