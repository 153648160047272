import { Box, styled, Typography } from '@mui/material';
import { colorPalette } from 'theme';

export const StyledMainBox = styled(Box)({
  display: 'flex',
  height: '100%',
  justifyContent: 'end',
  width: '100%'
});

export const StyledIconsBox = styled(Box)<{ index: number }>(({ index }) => ({
  marginLeft: index === 0 ? 0 : '-0.375rem',
  zIndex: index
}));

export const StyledTypography = styled(Typography)({
  color: colorPalette.purple,
  cursor: 'pointer',
  fontSize: '0.8125rem',
  fontWeight: 700,
  textDecoration: 'underline',
  textDecorationColor: colorPalette.purple,
  whiteSpace: 'nowrap'
});
