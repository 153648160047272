import React from 'react';
import { IconButton, SxProps, Theme } from '@mui/material';

export type SimpleIconButtonProps = {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  color?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
  Icon: any; //We have multiple type of icons here
  iconStyle?: SxProps<Theme>;
};
const SimpleIconButton = ({
  Icon,
  color,
  onClick,
  iconStyle
}: SimpleIconButtonProps) => {
  return (
    <IconButton sx={iconStyle} color={color} onClick={onClick}>
      <Icon />
    </IconButton>
  );
};

export default SimpleIconButton;
