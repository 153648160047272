import React, { FunctionComponent } from 'react';
import { styled, useTheme, lighten, alpha } from '@mui/material/styles';
import Chip, { ChipProps } from '@mui/material/Chip';
import { makeStyles } from 'tss-react/mui';
import { PsTheme, useIsMediumScreen } from 'theme';
import { Constants } from 'utilities/constants';

const DeleteIcon = (props: any) => {
  return (
    <svg
      focusable="false"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      {...props}
    >
      <path
        d="M11 3L3 11"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 3L11 11"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      backgroundColor: lighten('#632DDD' || '', 0.85),
      borderRadius: '4px',
      color: '#632DDD',
      padding: '4px 8px',
      marginRight: '10px',
      marginBottom: '1px'
    },
    label: {
      padding: 0,
      fontWeight: 600
    },
    deleteIcon: {
      flexShrink: 0,
      cursor: 'pointer',
      width: 14,
      height: 14,
      margin: '0px 10px 0 -8px',
      padding: 3,
      borderRadius: 10,
      color: alpha(psTheme.palette.third.main, 0.4),
      '&:hover': {
        color: alpha(psTheme.palette.third.main, 1)
      }
    }
  };
});

type Props = {
  textColor?: string;
};

type PsChipProps = ChipProps & Props;

export const PsChipView: FunctionComponent<PsChipProps> = (props) => {
  const { classes } = useStyles();
  const isMediumScreen = useIsMediumScreen();
  const { ...rest } = props;
  const deleteIcon = rest.onDelete ? <DeleteIcon /> : undefined;

  return (
    <Chip
      deleteIcon={deleteIcon}
      classes={classes}
      {...rest}
      style={{
        fontWeight: 'bold',
        fontSize: isMediumScreen && Constants.SMALL_12
      }}
    />
  );
};

export const PsChip = styled(PsChipView)({});

export default PsChip;
