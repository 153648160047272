import {
  Box,
  Divider,
  IconButton,
  styled,
  TextField,
  Typography
} from '@mui/material';
import PsButton from 'components/common/PsButton';
import { colorPalette } from 'theme';

export const StyledUploadButtonContainer = styled(Box)({
  alignSelf: 'center',
  display: 'grid',
  margin: 'auto'
});

export const StyledUploadButton = styled(IconButton)({
  backgroundColor: colorPalette.white,
  borderRadius: '.375rem',
  justifyContent: 'center',
  marginLeft: '.375rem',
  minHeight: '3.25rem',
  minWidth: '3.25rem',
  padding: '.5rem'
});

export const StyledImageText = styled(Typography)({
  color: colorPalette.spanishGray,
  fontSize: '1rem',
  fontWeight: 500
});

export const HeaderBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '.425rem',
  '@media (max-width:900px)': {
    flexDirection: 'column',
    alignItems: 'flex-start'
  }
});

export const ImageMainBox = styled(Box)({
  gap: '20px',
  display: 'flex',
  '@media (max-width:900px)': {
    flexDirection: 'column',
    alignItems: 'flex-start'
  }
});

export const ImageBox = styled(Box)({
  width: '100%'
});

export const StyledDivider = styled(Divider)({
  marginTop: '1rem'
});

export const StyledMainBox = styled(Box)({
  backgroundColor: colorPalette.white,
  display: 'flex',
  flexDirection: 'column'
});

export const StyledHeaderTypography = styled(Typography)({
  color: `${colorPalette.dimBlack} !important`,
  fontSize: '23px !important',
  fontWeight: '600!important'
});

export const StyledBodyTypography = styled(Typography)({
  color: `${colorPalette.dimBlack} !important`,
  fontSize: '14px !important',
  fontWeight: '700!important',
  marginTop: '10px',
  paddingBottom: '3px'
});

export const StyledNameTypography = styled(Typography)({
  color: `${colorPalette.dimBlack} !important`,
  fontSize: '14px !important',
  fontWeight: '700!important',
  marginTop: '1.25rem',
  paddingBottom: '3px'
});

export const StyledBodyFieldText = styled(TextField)({
  borderRadius: '8px'
});

export const StyledNameFieldText = styled(TextField)({
  borderRadius: '8px'
});

export const StyledFooterBox = styled(Box)({
  backgroundColor: colorPalette.white,
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '1.25rem',
  marginTop: '1.25rem'
});

export const StyledCancelButton = styled(PsButton)({
  backgroundColor: `${colorPalette.lavenderPurple} !important`,
  borderRadius: '30px !important',
  color: `${colorPalette.purple} !important`,
  fontSize: '14px !important',
  fontWeight: 'bold',
  height: '45px',
  marginRight: '8px',
  outline: 'none',
  padding: '8px 0px !important',
  width: '140px'
});

export const StyledSaveButton = styled(PsButton)({
  borderRadius: '30px !important',
  color: colorPalette.white,
  fontSize: '14px !important',
  fontWeight: 'bold',
  height: '45px',
  marginRight: '8px',
  outline: 'none',
  padding: '8px 0px !important',
  width: '140px'
});

export const StyledImageTextTypography = styled(Typography)({
  color: `${colorPalette.dimBlack} !important`,
  fontSize: '14px !important',
  fontWeight: '700 !important',
  marginBottom: '-1.7rem',
  width: '55%'
});
