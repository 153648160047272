import {
  Box,
  Divider,
  IconButton,
  LinearProgress,
  Popover,
  Typography,
  linearProgressClasses
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { colorPalette } from 'theme';

export const ContentBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
});

export const ProgressBox = styled(Box)({
  marginTop: '1.125rem',
  width: '100%'
});

export const LoadingBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  height: '10vh',
  justifyContent: 'center',
  width: '100%'
});

export const InfoContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row'
});

export const ContentWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '.9375rem'
});

export const HeadingMainBox = styled(Box)({
  alignItems: 'center',
  backgroundColor: colorPalette.white,
  borderRadius: '.5rem',
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '.3125rem',
  padding: '.625rem'
});

export const TextWrapper = styled(Box)({
  display: 'inline-block',
  fontWeight: '600',
  verticalAlign: 'baseline'
});

export const SymbolWrapper = styled(Box)({
  display: 'inline-block',
  fontWeight: '500',
  verticalAlign: 'baseline'
});

export const TitleText = styled(Typography)({
  fontSize: '1rem'
});

export const ThresholdText = styled(Typography)({
  color: colorPalette.darkGray,
  fontSize: '.875rem',
  fontWeight: '500',
  marginBottom: '.625rem',
  textAlign: 'left'
});

export const HeadingText = styled(Typography)({
  color: colorPalette.dimBlack,
  fontSize: '1.125rem',
  fontWeight: '600',
  lineHeight: 1
});

export const SubHeadingBox = styled(Box)({
  marginBottom: '.5rem',
  marginTop: '.1875rem'
});

export const BalanceTitleWrapper = styled(Box)({
  alignItems: 'center',
  display: 'flex'
});

export const StyledIconButton = styled(IconButton)({
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  marginRight: '4px'
});

export const SubHeading = styled(Typography)({
  color: colorPalette.dimBlack,
  fontSize: '1.0125rem',
  fontWeight: '600',
  lineHeight: 1
});

export const MainHeading = styled(Typography)({
  alignSelf: 'center',
  color: colorPalette.purple,
  fontSize: '1.25rem',
  fontWeight: '700',
  lineHeight: 1
});

export const BodyText = styled(Typography)({
  color: colorPalette.darkGray,
  fontSize: '.8125rem',
  fontWeight: '500',
  lineHeight: 1,
  marginBottom: '.5rem',
  marginTop: '1.25rem',
  padding: '.3125rem',
  textAlign: 'center'
});

export const ValueText = styled(Typography)({
  color: colorPalette.dimBlack,
  fontSize: '1.125rem',
  fontWeight: '600',
  marginLeft: '.625rem'
});

export const AmountText = styled(Typography)({
  fontSize: '1rem',
  fontWeight: '600'
});

export const PopoverContent = styled(Box)({
  padding: '.9375rem',
  width: '27vw',
  '@media (max-width:900px)': {
    width: '90vw'
  }
});

export const EquivalentAmountBox = styled(Box)<{ color?: string }>(
  ({ color }) => ({
    alignItems: 'center',
    color: color ?? colorPalette.darkGray,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  })
);

export const HeadingBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: '.5rem'
});

export const RewardsHeadingBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
});

export const LogoWrapper = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center'
});

export const StyledPopover = styled(Popover)({
  ' & .MuiPopover-paper': {
    borderRadius: '1.5625rem'
  }
});

export const StyledDivider = styled(Divider)({
  margin: '.625rem 0rem .625rem 0rem',
  backgroundColor: colorPalette.darkGray
});

export const FooterDivider = styled(Divider)({
  margin: '.3125rem 0rem .3125rem 0rem'
});

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor:
      theme.palette.mode === 'light'
        ? colorPalette.darkPastelPurple
        : colorPalette.darkPastelPurple,
    borderRadius: 5
  }
}));
