import {
  Avatar,
  Box,
  Button,
  Checkbox,
  ListItemIcon,
  Radio,
  RadioGroup,
  styled,
  Typography
} from '@mui/material';
import { colorPalette } from 'theme';
import { Constants } from 'utilities/constants';

export const StyledMainContainer = styled(Box)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    alignItems: isMediumScreen ? 'left' : 'center',
    background: `${colorPalette.white}`,
    borderRadius: '4px',
    boxSizing: 'border-box',
    display: 'flex',
    flex: 1,
    flexDirection: isMediumScreen ? 'column' : 'row',
    justifyContent: 'space-between',
    marginTop: '.625rem',
    padding: '.75rem 0'
  })
);

export const StyledListItemIcon = styled(ListItemIcon)({
  width: '1.25rem',
  '& img': {
    height: '1.25rem',
    width: '1.25rem'
  }
});

export const StyledTypography = styled(Typography)<{
  isTitle?: boolean;
  isMediumScreen?: boolean;
}>(({ isTitle, isMediumScreen }) => ({
  fontSize: isMediumScreen && Constants.SMALL_12,
  fontWeight: isTitle ? '600' : 'none',
  textTransform: 'capitalize'
}));

export const StyledSelectedItemContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%'
});

export const ContentWrapper = styled(Box)({
  padding: '.75rem .375rem'
});

export const StyledButton = styled(Button)({
  display: 'flex',
  width: '100%'
});
export const ButtonLabel = styled(Typography)({
  color: colorPalette.dimBlack,
  fontSize: '1rem',
  fontWeight: '600'
});

export const ButtonLabelWrapper = styled(Box)({
  alignItems: 'center',
  display: 'flex'
});

export const FilterItemsBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'left'
});

export const LabelWrapper = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row'
});

export const StyledCheckbox = styled(Checkbox)({
  marginRight: '.4375rem',
  '& .MuiSvgIcon-root': {
    height: '1rem',
    width: '1rem'
  }
});

export const StyledAvatar = styled(Avatar)({
  height: '1.1875rem',
  marginRight: '.75rem',
  width: '1.1875rem'
});

export const MainBox = styled(Box)<{ width: string }>(({ width }) => ({
  backgroundColor: colorPalette.white,
  borderRadius: '.5rem',
  boxShadow: `0 .0625rem .4375rem ${colorPalette.ebb}`,
  width: width
}));

export const StyledImage = styled('img')({
  height: '1.25rem',
  marginRight: '.5rem',
  width: '1.25rem'
});

export const StyledRadio = styled(Radio)({
  '& .MuiSvgIcon-root': {
    fontSize: '1rem'
  }
});

export const StyledRadioGroup = styled(RadioGroup)({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '.5625rem'
});
