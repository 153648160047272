import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import SimpleIconButton from 'components/SimpleIconButton';
import { StyledHeaderText, StyledHeaderWrapper } from './StyledComponents';

interface HeaderProps {
  label?: string | JSX.Element;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  showCrossIcon?: boolean;
}

export const Header: React.FC<HeaderProps> = ({
  label,
  onClose,
  showCrossIcon
}) => {
  const HeaderContent = useMemo(() => {
    return (
      <Box>
        <StyledHeaderWrapper>
          {label && <StyledHeaderText variant="h5">{label}</StyledHeaderText>}
          {showCrossIcon && onClose && (
            <SimpleIconButton
              Icon={CancelOutlinedIcon}
              color="primary"
              onClick={onClose}
            />
          )}
        </StyledHeaderWrapper>
      </Box>
    );
  }, []);
  return HeaderContent;
};
