import React, { FunctionComponent, MouseEvent } from 'react';
import { styled, useTheme, darken } from '@mui/material/styles';

import classnames from 'classnames';
import { TreeIconWithPlus } from 'components/ConceptsButton/TreeIconWithPlus';
import { TreeIconChecked } from 'components/ConceptsButton/TreeIconChecked';
import { makeStyles } from 'tss-react/mui';
import Button, { ButtonProps } from '@mui/material/Button';
import Box from '@mui/material/Box';

const useStyles = makeStyles()(() => {
  return {
    root: {
      borderRadius: '25px',
      padding: 0,
      paddingRight: '8px',
      background: '#008954',
      flexShrink: 0,
      display: 'inline-flex',
      justifyContent: 'flex-start',
      '&:hover': {
        background: darken('#008954', 0.3)
      },
      '& svg': {
        height: 17
      }
    },
    iconWrapper: {
      background: '#008954'
    },
    '@keyframes blink': {
      '50%': {
        opacity: 0.7
      },
      '100%': {
        transform: 'scale(1.7, 1.7)',
        opacity: 0
      }
    },
    blink: {
      display: 'block',
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: '#B6ECAB',
      opacity: '0.7',
      borderRadius: '50%',
      animation: '$blink 1.5s ease-in-out infinite'
    },
    buttonText: {
      color: '#FFFFFF',
      fontWeight: 600,
      fontSize: '18px',
      textTransform: 'none',
      flex: 1,
      textAlign: 'center',
      padding: '0 8px'
    },
    fullWidth: {
      width: '100%'
    },
    small: {
      borderRadius: '16px',
      height: '32px',
      paddingRight: '8px',
      backgroundColor: '#D6C7F8',
      '&:hover': {
        background: darken('#D6C7F8', 0.1)
      },

      '& svg': {
        height: 17
      },

      '& $buttonText': {
        fontSize: '15px',
        color: '#632DDD',
        padding: '0 8px'
      }
    },
    added: {
      backgroundColor: '#B1F4D4',
      '&:hover': {
        background: '#B1F4D4'
      },

      '& $buttonText': {
        color: '#008954'
      },

      '& $blink': {
        display: 'none'
      },

      '& $iconWrapper': {
        background: '#B1F4D4'
      }
    }
  };
});

interface Props extends ButtonProps {
  className?: string;
  onClick?: (arg: MouseEvent) => void;
  small?: boolean;
  fullWidth?: boolean;
  loading?: boolean;
  added?: boolean;
}

export const AddToConceptButton: FunctionComponent<Props> = ({
  onClick,
  className = '',
  small = false,
  fullWidth = false,
  disabled = false,
  loading = true,
  added = false
}) => {
  const { classes } = useStyles();
  return (
    <Button
      variant="contained"
      onClick={onClick}
      color="secondary"
      disabled={disabled}
      className={classnames(classes.root, {
        [className]: className,
        [classes.small]: small,
        [classes.fullWidth]: fullWidth,
        [classes.added]: added
      })}
      disableRipple={added}
      disableElevation
    >
      <Box display="inline-flex" position="relative">
        <span className={classes.blink} />
        <Box
          zIndex={1}
          width={50}
          height={50}
          borderRadius="50%"
          border="3px solid #fff"
          display="flex"
          alignItems="center"
          justifyContent="center"
          className={classes.iconWrapper}
        >
          {added ? <TreeIconChecked /> : <TreeIconWithPlus height={17} />}
        </Box>
      </Box>

      <span className={classes.buttonText}>
        {added ? 'Added to Concept' : 'Add to a Concept'}
      </span>
    </Button>
  );
};
