import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { Solution } from 'components/CardSolution';
import CounterButton from 'components/common/CounterButton';
import config from 'config/config';
import AuthContext from 'contexts/AuthContext';
import dataProvider from 'dataPrvider';
import { Picture, useRemoveQueryParams } from 'helpers';
import { getIPAddress } from 'helpers/getIPAddress';
import { useCheckCredits } from 'hooks/useCheckCredits';
import useRouter from 'hooks/useRouter';
import Actions from 'redux-state/actions';
import {
  GetCalculateMultiplierIdeaPoints,
  GetGeneratedDescriptionLoader,
  GetGenerateImageLoader,
  GetProblem
} from 'redux-state/selectors';
import { Constants } from 'utilities/constants';
import { Node } from '../AddProblem';
import { ActionSection } from '../Common/ActionSection';
import { BaseModal } from '../Common/BaseModal';
import { ContentSection } from '../Common/ContentSection';
import { HeaderSection } from '../Common/HeaderSection';
import { OptionType } from '../Common/interface';
import { Content } from './Content';
import { Header } from './Header';
import {
  StyledCircularProgress,
  StyledCounterButtonMainWrapper,
  StyledMultiplierIdeaPts
} from './StyledComponents';

const API_COSTS = config.API_COSTS;
interface SolutionModalProps {
  isIdeaMapPopUp?: boolean;
  onClose?: () => void;
  open: boolean;
  parentNode?: Node;
  problemSolutionCreationCallBack?: () => void;
  referralCode?: string | string[];
  selectedParentProblem?: OptionType;
  setSelectedParentProblem?: React.Dispatch<React.SetStateAction<OptionType>>;
  preSelectedSolution?: string;
}
interface ProductType {
  title: string;
  tags: string[];
  id: string;
  value: string;
}
interface SolutionResponse {
  data: {
    key: string;
  };
}

const SolutionModal: React.FC<SolutionModalProps> = ({
  isIdeaMapPopUp,
  onClose,
  open,
  parentNode,
  problemSolutionCreationCallBack,
  referralCode,
  selectedParentProblem,
  setSelectedParentProblem,
  preSelectedSolution
}) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const removeQueryParams = useRemoveQueryParams();
  const [title, setTitle] = useState<string>('');
  const parentNodeTitle = parentNode?.title;
  const parentNodeId = parentNode?.id;
  const { user } = useContext(AuthContext);
  const [selectedTags, setSelectedTags] = useState<Array<OptionType | null>>(
    []
  );
  const [selectedCompany, setSelectedCompany] = useState<OptionType | null>(
    null
  );
  const [selectedProblem, setSelectedProblem] = useState<OptionType | null>({
    label: parentNodeTitle,
    value: parentNodeId
  });

  const [description, setDescription] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [picture, setPicture] = useState<Picture | undefined>(undefined);
  const [loadingCount, setLoadingCount] = useState<number>(0);
  const [visibility, setVisibility] = useState<string>('');
  const [termsAgree, setTermsAgree] = useState<boolean>(false);
  const companyId = parentNode?.company;
  const startLoading = () => setLoadingCount((count) => count + 1);
  const stopLoading = () => setLoadingCount((count) => count - 1);
  const generatedDescriptionLoader = GetGeneratedDescriptionLoader();
  const generateImageLoader = GetGenerateImageLoader();
  const problem = GetProblem();
  const multiplierIdeaPoints = GetCalculateMultiplierIdeaPoints();

  const hasSufficientCredits = useCheckCredits(API_COSTS.CREATE_SOLUTION);

  if (!hasSufficientCredits()) {
    onClose();
    return null;
  }

  const ideaPoints =
    problem?.likes === 0 && problem?.dislikes >= 3
      ? 0
      : multiplierIdeaPoints === 0
        ? Constants.DEFAULT_CREATE_ITEM_POINTS
        : multiplierIdeaPoints;

  const formLoading = loadingCount > 0;
  useEffect(() => {
    if (selectedProblem) {
      dispatch(Actions.getProblem(selectedProblem?.value as string));
    }
  }, [dispatch, selectedProblem]);

  useEffect(() => {
    if (problem) {
      dispatch(
        Actions.calculateMultiplierIdeaPoints({
          defaultIdeaPoints: Constants.DEFAULT_CREATE_ITEM_POINTS,
          likes: problem?.likes || 0,
          dislikes: problem?.dislikes || 0
        })
      );
    }
  }, [dispatch, problem]);

  const handleCreateClick = async () => {
    if (user) {
      const formattedTags = selectedTags.map((tag) => tag.value);
      const currentDate = new Date().toISOString().split('T')[0];
      setLoading(true);
      const solutionInfo = {
        title: (title || preSelectedSolution || '').replace(
          Constants.DEFAULT_SOLUTION_TITLE,
          ''
        ),
        tags: formattedTags,
        body: description,
        files: picture ? [picture] : [],
        problem:
          parentNodeId || selectedProblem.value || selectedParentProblem?.value,
        isPublic: visibility === Constants.PUBLIC,
        termsAgree: visibility === Constants.PUBLIC ? termsAgree : false,
        isInProgress: false,
        ipAddress: visibility === Constants.PUBLIC ? await getIPAddress() : '',
        currentDate,
        ...(referralCode && { referralCode })
      };

      try {
        const response = (await dataProvider.create<Solution>(
          Constants.SOLUTIONS,
          {
            data: solutionInfo
          }
        )) as SolutionResponse;
        handleClose();
        if (referralCode) {
          removeQueryParams([
            Constants.REFERRAL_CODE,
            Constants.SHOW_PROBLEM_MODAL
          ]);
        }
        if (problemSolutionCreationCallBack) {
          problemSolutionCreationCallBack();
        } else {
          router.push(`/solutions/${response?.data?.key}`);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    } else {
      dispatch(Actions.openAuthModal(true));
    }
  };

  const disableCreateButton = () => {
    if (selectedParentProblem && preSelectedSolution) {
      return false;
    }
    return (
      !selectedProblem ||
      !selectedTags ||
      !description ||
      generateImageLoader ||
      (visibility === Constants.PUBLIC && !termsAgree) ||
      !visibility
    );
  };

  const handleClose = () => {
    onClose();
    dispatch(Actions.generateDescriptionFromAISuccess({}));
    dispatch(Actions.generateImageFromAISuccess({}));
    dispatch(Actions.getProblemSuccess({}));
  };
  return (
    <BaseModal
      open={open}
      loading={formLoading || generatedDescriptionLoader}
      maxWidth={'lg'}
    >
      <HeaderSection>
        <Header
          isIdeaMapPopUp={isIdeaMapPopUp}
          label={Constants.ADD_NEW_SOLUTION}
          modalType={Constants.SOLUTION}
          onClose={handleClose}
          parentNodeTitle={parentNodeTitle}
          showCrossIcon={true}
        />
      </HeaderSection>
      <ContentSection>
        <Content
          companyId={companyId}
          description={description}
          isIdeaMapPopUp={isIdeaMapPopUp}
          parentNodeId={parentNodeId}
          picture={picture}
          productTags={parentNode?.tags}
          selectedCompany={selectedCompany}
          selectedProblem={selectedProblem}
          selectedTags={selectedTags}
          setDescription={setDescription}
          setPicture={setPicture}
          setSelectedCompany={setSelectedCompany}
          setSelectedProblem={setSelectedProblem}
          setSelectedTags={setSelectedTags}
          setTermsAgree={setTermsAgree}
          setTitle={setTitle}
          setVisibility={setVisibility}
          startLoading={startLoading}
          stopLoading={stopLoading}
          termsAgree={termsAgree}
          title={title}
          visibility={visibility}
          selectedParentProblem={selectedParentProblem}
          setSelectedParentProblem={setSelectedParentProblem}
          preSelectedSolution={preSelectedSolution}
        />
      </ContentSection>
      <ActionSection>
        <StyledCounterButtonMainWrapper>
          <StyledMultiplierIdeaPts>
            {Constants.EARN} {ideaPoints} {Constants.C_IDEA_POINTS}
          </StyledMultiplierIdeaPts>
          <Box sx={{ position: 'relative' }}>
            <CounterButton
              handleClick={handleCreateClick}
              isCreateButton={true}
              text={Constants.C_CREATE}
              disabled={disableCreateButton() || loading}
            />
            {loading && <StyledCircularProgress size={30} thickness={2} />}
          </Box>
        </StyledCounterButtonMainWrapper>
      </ActionSection>
    </BaseModal>
  );
};

export default SolutionModal;
