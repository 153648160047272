import React, { useCallback, useContext, useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { ModalDataContext } from 'contexts/ModalDataContext';
import { DataContext } from 'contexts/DataContext';
import { Problem } from 'components/CardProblem';
import { Tag } from 'components/CardTag';
import { PsSelect, PsOptionType } from 'components/common/PsSelect';
import { PsSelectedList } from 'components/common/PsSelectedList';
import { PsFormControl, ShortEvent } from 'components/common/PsFormControl';
import { removeDuplicates } from '../../../helpers';
import styles from 'modals/ModalWindow.module.scss';
import { getValueObj } from 'helpers/common';
import { TAG_TYPES } from 'utilities/constants';

const ApplicationTagsStep: React.FC = () => {
  const { values, updateField } = useContext(ModalDataContext);
  const { dataProvider } = useContext(DataContext);
  const [tags, setTags] = useState<Array<PsOptionType>>([]);
  const [tagsValue, setTagsValue] = useState<string>('');
  const [selectedTags, setSelectedTags] = useState<Array<string>>([]);

  useEffect(() => {
    if (!values.mainProblem) {
      return;
    }

    dataProvider
      .getOne<Problem>('problems', { id: values.mainProblem })
      .then(({ data }) => {
        let tags = data.tags || [];
        tags = removeDuplicates(tags);
        setTagsValue(tags.join(','));
      });

    dataProvider
      .getList<Tag>('tags', {
        pagination: { page: 1, perPage: 50000 },
        sort: { field: 'name', order: 'ASC' },
        filter: { $custom: { type: TAG_TYPES.BASE_TAGS } }
      })
      .then(({ data = [] }) => {
        const list: Array<PsOptionType> = data.map((item: Tag) => ({
          value: `${item.id}`,
          label: item.name || '(empty)'
        }));
        setTags(list);
      })
      .catch(() => {
        setTags([]);
      });
  }, []);

  useEffect(() => {
    let newTagsValue = (tagsValue || '').split(',');
    newTagsValue = newTagsValue.filter((item) => item);
    setTagsValue(newTagsValue.join(','));
    setSelectedTags(newTagsValue);
  }, [tags]);

  const onTagsChange = useCallback(
    (event: ShortEvent) => {
      const { value } = event.target;
      const newSelectedTags: Array<string> = value ? value.split(',') : [];
      setSelectedTags(newSelectedTags);
      setTagsValue(value);
      if (value) {
        updateField('tagsApplication', value.split(','));
      } else {
        updateField('tagsApplication', []);
      }
    },
    [setTagsValue]
  );

  return (
    <div className={styles.stepWrapper}>
      {values.mainProblemLabel ? (
        <Typography className={styles.text}>
          Create your invention for the problem of a{' '}
          <b>{values.mainProblemLabel}</b>
        </Typography>
      ) : null}
      <PsFormControl
        placeholder="Tags"
        label={`Tag your invention for the ${values.titleApplication}`}
        disabled
      >
        <PsSelect
          isMulti
          maxMenuHeight={270}
          name="tagsApplication"
          onChange={onTagsChange}
          options={tags}
          value={tagsValue}
          valueObj={getValueObj(tags, selectedTags)}
        />
      </PsFormControl>
      <PsSelectedList
        options={tags}
        value={tagsValue}
        onChange={onTagsChange}
      />
    </div>
  );
};

export default ApplicationTagsStep;
