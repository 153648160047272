import dataProvider, { PsRecord } from 'dataPrvider';
import { Activity } from 'components/CardFeed';
import { Solution } from 'components/CardSolution';
import { Tag } from 'components/CardTag';
import { Product } from 'components/CardProduct';
import { Problem } from 'components/CardProblem';
import { Constants, END_POINTS, RESOURCE } from 'utilities/constants';
import { UserTag } from './interface';

export const Api = {
  aiAnswerSearch: async (payload) => {
    try {
      const { prompt } = payload;
      const response = dataProvider.answerSearch(prompt);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
  getFollowingTagsCount: async (payload: {
    userKey: string | number;
    onlyCount: boolean;
  }) => {
    try {
      const response = await dataProvider.getFollowingTagsCount(
        payload.userKey,
        payload.onlyCount
      );
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
  createEmployeeProfile: async (data) => {
    try {
      const response = await dataProvider.create(
        `${RESOURCE.PROFILES}${END_POINTS.CREATE_EMPLOYEE}`,
        { data }
      );
      return response;
    } catch (error) {
      throw new Error(error?.message);
    }
  },
  downloadPatentApp: async (payload) => {
    try {
      const { appId, title } = payload;
      return await dataProvider.downloadPatentApp(appId, title);
    } catch (error) {
      console.log('Error:', error);
      throw new Error(error);
    }
  },
  generateAiTitle: async ({ conceptId }) => {
    try {
      const data = await dataProvider.getImageAndTitle(
        conceptId as number,
        false
      );
      return data;
    } catch (e) {
      console.log('Error in genrating AI Title:', e);
      throw new Error(e);
    }
  },
  generateAiImage: async (payload) => {
    try {
      const { query } = payload;
      const data = await dataProvider.imageSearch(query);
      return data;
    } catch (e) {
      console.log('Error in genrating AI Image:', e);
      throw new Error(e);
    }
  },
  editTag: async (payload) => {
    try {
      const { id, type, data } = payload;
      const response = await dataProvider.update(type, { id, data });
      return response;
    } catch (e) {
      return false;
    }
  },
  getTagFollowers: async (
    tagId: string | number,
    followers?: string | number,
    actionType?: string
  ) => {
    try {
      const data = await dataProvider.followTag(Constants.TAGS, {
        tagId,
        followers,
        actionType
      });
      return data.data;
    } catch (e) {
      return [];
    }
  },
  getTag: async (payload: {
    key: string;
    pagination?: {
      page: number;
      perPage: number;
    };
  }) => {
    try {
      const { key, pagination = { page: 0, perPage: 5 } } = payload;
      const data = await dataProvider.getOneByKey<Tag>('tags', {
        key: key as string,
        pagination,
        filter: { $custom: { category: 'tag' } }
      });
      return data.data;
    } catch (e) {
      return [];
    }
  },
  getFeed: async (payload: {
    pagination?: { page: number; perPage: number };
    key?: string | null;
    filters?: Array<string> | null;
    sortType?: string | null;
  }) => {
    try {
      const {
        pagination = { page: 0, perPage: 5 },
        key,
        filters,
        sortType
      } = payload;
      const data = await dataProvider.getList<Activity>(Constants.FEEDS, {
        pagination,
        sort: { field: sortType ?? Constants.CREATED_AT, order: -1 },
        filter: {
          $custom: {
            category: Constants.PUBLIC,
            actionType: Constants.CREATE,
            key: key,
            filters
          }
        }
      });
      const { data: activities, totalActivities } = data;
      return { data: activities, totalActivities };
    } catch (e) {
      return { data: [], totalActivities: 0 };
    }
  },

  getRelatedCompanies: async (payload: {
    pagination?: { page: number; perPage: number };
    name?: string;
  }) => {
    try {
      const { pagination, name } = payload;
      const data = await dataProvider.getList<Tag>('tags', {
        pagination,
        sort: { field: 'name', order: 'ASC' },
        filter: { type: 'workplace', $custom: { name: name } }
      });

      return data;
    } catch (e) {
      return { data: [] };
    }
  },

  getEmployees: async (payload: { tagId: string | number }) => {
    try {
      const { tagId } = payload;
      const data = await dataProvider.getList<PsRecord>('profiles', {
        filter: { employer: tagId }
      });

      return data;
    } catch (e) {
      return [];
    }
  },
  getRelationPrompt: async (payload: {
    conceptTitle: string;
    productTitle: string;
    firstSolutionTitle: string;
    secondSolutionTitle: string;
    remainingSolutions: Array<Solution>;
    problemSolvedBySolution: Array<Problem>;
  }) => {
    try {
      const data = await dataProvider.getRelationPrompt(payload);
      return data;
    } catch (error) {
      console.log(error.message);
      throw error;
    }
  },
  getRelatedItems: async (payload: {
    resource: string;
    key: string;
    item: string;
    pagination?: {
      page: number;
      perPage: number;
    };
  }) => {
    try {
      const { resource, key, item, pagination } = payload;
      const data = await dataProvider.getRelatedItems(resource, {
        key,
        item,
        pagination,
        filter: { $custom: { category: 'tag' } }
      });
      return data;
    } catch (e) {
      return [];
    }
  },
  getOwnedCommunity: async (payload: any) => {
    try {
      const data = await dataProvider.getOwnedCommunity(payload.userId);
      return data;
    } catch (e) {
      return [];
    }
  },
  getTagUsers: async (payload: {
    tagId: string | number;
    filterType?: string | null;
    pagination?: { page: number; perPage: number };
  }) => {
    try {
      const { tagId, filterType, pagination } = payload;
      const data = await dataProvider.getList<UserTag>(Constants.USER_TAGS, {
        filter: {
          $custom: {
            category: Constants.TAG_USER,
            employer: tagId,
            filterType: filterType
          }
        },
        pagination,
        sort: null
      });
      return data;
    } catch (e) {
      console.log('Error getting user tags:', e);
      return [];
    }
  },
  unlinkTag: async (type: string, itemId: string | number, tagKey: string) => {
    try {
      const response = await dataProvider.removeTag(type, itemId, tagKey);
      if (response) {
        return true;
      }
    } catch (e) {
      return false;
    }
  },
  voteItem: async (
    itemType: string,
    itemId: string | number,
    voteType: number,
    voteCriteria: string
  ) => {
    try {
      const response = await dataProvider.voteItem(itemType, itemId, voteType, {
        filter: { $custom: { category: voteCriteria } },
        itemType,
        pagination: null,
        sort: { field: 'createdAt', order: 'DESC' }
      });
      return response;
    } catch (e) {
      return false;
    }
  },
  getProduct: async (id: string) => {
    try {
      const response = await dataProvider.getOne<Product>(
        Constants.COMPANY_PRODUCTS,
        {
          id
        }
      );
      return response.data;
    } catch (e) {
      console.log(e);
    }
  },
  getProblem: async (id: string) => {
    try {
      const response = await dataProvider.getOne<Problem>(Constants.PROBLEMS, {
        id
      });
      return response.data;
    } catch (e) {
      console.log(e);
    }
  },
  calculateMultiplierIdeaPoints: async (
    defaultIdeaPoints: string,
    likes: number,
    dislikes: number
  ) => {
    try {
      const response = await dataProvider.calculateMultiplierIdeaPoints({
        defaultIdeaPoints,
        likes,
        dislikes
      });
      return response;
    } catch (e) {}
  }
};
