import React, { useEffect, useState } from 'react';
import type { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import pusher from 'config/pusherConfig';
import Actions from 'redux-state/actions';
import useRouter from 'hooks/useRouter';
import { useFetchGraph } from 'hooks/useFetchGraph';
import { CHANNELS, Constants, EVENTS } from 'utilities/constants';
import NewIdeaMap from '../newIdeaMap';

const ProblemDetails: FunctionComponent = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { key } = router.query;
  const [isDataCleared, setIsDataCleared] = useState<boolean>(false);
  const { graphData, fetchChildrenGraph } = useFetchGraph({
    type: Constants.PROBLEMS,
    id: key as string,
    mapType: Constants.PROBLEM
  });

  // TODO: shift to component unmount
  useEffect(() => {
    if (key) {
      dispatch(Actions.setGraph({})); // Clear the graph data
      setIsDataCleared(true); // Mark data as cleared
    }
  }, [dispatch, key]);

  useEffect(() => {
    const trimmedKey = String(key)
      .substring(0, 140)
      .replace(/[^a-zA-Z0-9_=@,.;-]/g, '');
    if (trimmedKey) {
      const channel = pusher.subscribe(`${CHANNELS.AI_IMAGE}-${trimmedKey}`);
      const handleData = (data) => {
        if (graphData) {
          const updatedChildren = graphData.children.map((child) => {
            if (child.id === data.id) {
              return {
                ...child,
                files: data.files
              };
            }
            return child;
          });
          dispatch(
            Actions.setGraph({ ...graphData, children: updatedChildren })
          );
        }
      };
      channel.bind(EVENTS.IMAGE_FROM_DESCR, handleData);

      return () => {
        channel.unbind(EVENTS.IMAGE_FROM_DESCR, handleData);
        pusher.unsubscribe(`${CHANNELS.AI_IMAGE}-${trimmedKey}`);
      };
    }
  }, [dispatch, graphData, key]);

  return (
    isDataCleared && (
      <NewIdeaMap
        fetchChildrenGraph={fetchChildrenGraph}
        graphData={graphData}
        id={key as string}
        type={Constants.PROBLEMS}
      />
    )
  );
};

export default ProblemDetails;
