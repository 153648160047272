import React, { FC, useCallback, useMemo } from 'react';
import { TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { GetUser } from 'redux-state/selectors';
import { Constants, DOM_EVENTS, VARIANT } from 'utilities/constants';
import { ConceptName } from './styledComponents';
import { handleTitleEdit } from './utils';

interface ConceptTitleProps {
  isEditingTitle: boolean;
  setIsEditingTitle: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ConceptTitle: FC<ConceptTitleProps> = ({
  isEditingTitle,
  setIsEditingTitle
}) => {
  const dispatch = useDispatch();
  const user = GetUser();
  const activeConcept = useMemo(() => user?.activeConcept || {}, [user]);

  const handleEditEvent = useCallback(
    (event) => {
      if (event.key === Constants.ENTER || event.type === DOM_EVENTS.BLUR) {
        handleTitleEdit(
          event.target.value,
          dispatch,
          activeConcept,
          user,
          setIsEditingTitle
        );
      }
    },
    [dispatch, activeConcept, user, setIsEditingTitle]
  );

  return isEditingTitle ? (
    <TextField
      label={Constants.NEW_TITLE}
      defaultValue={activeConcept?.title || activeConcept?.name}
      size={VARIANT.SMALL}
      onKeyDown={handleEditEvent}
      onBlur={handleEditEvent}
      sx={{ width: '70%' }}
    />
  ) : (
    <ConceptName
      onClick={() => {
        window.open(
          `/${Constants.INVENTIONS}/${activeConcept?.key}`,
          Constants._BLANK
        );
      }}
    >
      {activeConcept?.title}
    </ConceptName>
  );
};
