import React from 'react';
import { styled, useTheme, darken, lighten } from '@mui/material/styles';
import { PsTheme } from '../../theme';
import { ButtonProps } from '@mui/material/Button';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      border: 'none',
      outline: 'none',
      margin: '0',
      padding: '12px',
      cursor: 'pointer',
      appearance: 'none',
      borderRadius: 10,
      lineHeight: 0,
      color: lighten(psTheme.palette.third.main, 0.4),
      background: lighten(psTheme.palette.third.main, 0.9)
    }
  };
});

type PsIconButtonProps = ButtonProps & {
  className?: string;
  children?: React.ReactNode;
};

const PsIconButtonView = ({
  className,
  children,
  ...props
}: PsIconButtonProps) => {
  const { classes } = useStyles();

  let rootClassName = classes.root;
  if (className) {
    rootClassName += ' ' + className;
  }

  return (
    <button className={rootClassName} {...props}>
      {children}
    </button>
  );
};

export const PsIconButton = styled(PsIconButtonView)({});

export default PsIconButton;
