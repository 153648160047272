import React, { useCallback, useContext, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { ProblemIcon } from 'components/icons/ProblemIcon';
import { SolutionIcon } from 'components/icons/SolutionIcon';
import { InventionIcon } from 'components/icons/InventionIcon';
import { ContestIcon } from 'components/icons/ContestIcon';
import { Small1Icon } from 'components/icons/Small1Icon';
import { CardImage, CardOwnerNew } from 'components/card';
import { Tags } from 'components/Tag';
import { OwnerInfo } from 'components/CardProfile';
import { TagInfo } from 'components/CardTag';
import { ShareBtnNew } from 'components/ShareBtn';
import { LikeDislike } from 'components/CardFeed/LikeDislike';
import { ModalComponentsKeys, ModalContext } from 'contexts/ModalContext';
import { ModalDataContext } from 'contexts/ModalDataContext';
import { AuthContext } from 'contexts/AuthContext';
import { PsRecord } from '../../dataPrvider';
import {
  Picture,
  getPlaceholderType,
  activityItem,
  activityAction
} from '../../helpers';
import { PsTheme } from '../../theme';

import { PsButton } from '../common/PsButton';
import { makeStyles } from 'tss-react/mui';
import useRouter from 'hooks/useRouter';
import { Link } from 'react-router-dom';

const getItemUrl = (itemType: string, id: string | number) => {
  let finalPath = itemType;
  if (itemType === activityItem.APPLICATION) {
    finalPath = 'inventions';
  }
  return `/${finalPath}/${id}`;
};

const useCardImageStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      width: '100%',
      padding: '20px 30px 30px',
      [psTheme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    inner: {
      paddingTop: '60%',
      [psTheme.breakpoints.down('sm')]: {
        paddingTop: '60%'
      },
      [psTheme.breakpoints.down('xs')]: {
        paddingTop: '60%'
      }
    },
    placeholder: {
      borderRadius: 10
    },
    img: {
      borderRadius: 10
    }
  };
});

type ClassKey =
  | 'box'
  | 'top'
  | 'topLeft'
  | 'topRight'
  | 'header'
  | 'headerIcon'
  | 'headerTitle'
  | 'headerButtons'
  | 'tags'
  | 'bottom'
  | 'bottomLeft'
  | 'bottomRight'
  | 'voteButton'
  | 'moreLink';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    box: {
      background: '#ffffff',
      border: '1px solid rgba(121, 114, 140, 0.11)',
      boxSizing: 'border-box',
      boxShadow:
        '0px 4px 8px rgba(10, 0, 32, 0.02), 0px 4px 84px rgba(7, 0, 21, 0.04)',
      borderRadius: 15,
      width: '100%',
      minHeight: 250,
      paddingBottom: 30,
      marginBottom: 20,

      '& .wrapper': {
        paddingLeft: 30,
        paddingRight: 30
      }
    },
    top: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '30px 0 0'
    },
    topLeft: {},
    topRight: {},
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 5
    },
    headerIcon: {
      flexGrow: 0,
      flexShrink: 0,
      margin: '3px 20px 0 0'
    },
    headerTitle: {
      flexGrow: 1,
      fontSize: 24,
      lineHeight: '34px',
      fontWeight: 'bold',
      '& a': {
        textDecoration: 'none',
        color: 'inherit'
      },
      '& a:hover': {
        textDecoration: 'underline'
      }
    },
    headerButtons: {
      flexGrow: 0,
      marginLeft: 15
    },
    tags: {
      marginTop: 10
    },
    bottom: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '30px 0 0'
    },
    bottomLeft: {},
    bottomRight: {},
    voteButton: {
      display: 'inline-flex',
      alignItems: 'center',
      color: '#677890',
      marginLeft: 50,
      '& > button': {
        background: 'none',
        border: 'none',
        outline: 'none',
        margin: '0',
        padding: '0',
        cursor: 'pointer',
        appearance: 'none'
      },
      '& > span': {
        paddingLeft: 10,
        fontSize: 18,
        lineHeight: '20px'
      }
    },
    moreLink: {
      fontSize: 20,
      color: psTheme.palette.primary.main,
      fontWeight: 600,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  };
});

export type Activity = PsRecord & {
  itemId: string;
  itemKey: string;
  itemTitle: string;
  itemType: string;
  actionType: string;
  owner: string | number;
  ownerInfo?: OwnerInfo;
  tags?: Array<string>;
  tagsInfo?: Array<TagInfo>;
  files?: Array<Picture>;
  followers: Array<string | number>;
  createdAt: string;
  likes: number;
  dislikes: number;
  tagName?: string;
  bountyCoins?: number;
};

export type CardFeedProps = {
  activity: Activity;
  seen?: boolean;
  onSeenSelect?: (id: string | number) => void;
};

const CardFeedView = ({ activity, seen, onSeenSelect }: CardFeedProps) => {
  const { openModal, openSolutionCreate, openInventionImprove } =
    useContext(ModalContext);
  const { setType, updateField } = useContext(ModalDataContext);
  const { user } = useContext(AuthContext);
  const router = useRouter();
  const imageStyles = useCardImageStyles();
  const {
    id,
    itemId,
    itemKey,
    itemTitle,
    tagsInfo,
    itemType,
    actionType,
    owner,
    ownerInfo,
    files,
    createdAt,
    likes,
    dislikes
  } = activity;

  const image = files && files.length ? files[0] : undefined;
  const url = getItemUrl(itemType, itemKey || itemId);
  const { classes } = useStyles();

  useEffect(() => {
    if (!seen && onSeenSelect && typeof window !== 'undefined') {
      onSeenSelect(id);
    }
  }, [id, seen, onSeenSelect, typeof window]);

  const onProblemSolveBtnClick = useCallback(() => {
    openSolutionCreate({ problemId: itemId });
  }, [openSolutionCreate, itemId]);

  const onBrainstormClick = useCallback(() => {
    setType(ModalComponentsKeys.addNewSolution);
    updateField('parentSolution', itemId);
    updateField('parentSolutionLabel', itemTitle);
    openModal(ModalComponentsKeys.brainstormSolution);
  }, [updateField, openModal, setType, itemId, itemTitle]);

  const onImproveClick = useCallback(() => {
    openInventionImprove({ inventionId: itemId, inventionTitle: itemTitle });
  }, [openInventionImprove, itemId, itemTitle]);

  const onSolveBtnClick = (e: React.MouseEvent) => {
    e.preventDefault();
    router.push(url);
  };

  return (
    <div className={classes.box}>
      <div className={`${classes.top} wrapper`}>
        <div className={classes.topLeft}>
          <div className={classes.header}>
            {itemType === activityItem.PROBLEM ? (
              <ProblemIcon className={classes.headerIcon} />
            ) : null}
            {itemType === activityItem.SOLUTION ? (
              <SolutionIcon className={classes.headerIcon} />
            ) : null}
            {itemType === activityItem.APPLICATION ? (
              <InventionIcon className={classes.headerIcon} />
            ) : null}
            {itemType === activityItem.CONTEST ? (
              <ContestIcon className={classes.headerIcon} />
            ) : null}
            <div className={classes.headerTitle}>
              <Link to={url}>
                <a target="_blank">{itemTitle}</a>
              </Link>
            </div>
          </div>
          <CardOwnerNew
            ownerName={ownerInfo && ownerInfo.username}
            ownerKey={ownerInfo && ownerInfo.key}
            owner={owner}
            createdAt={createdAt}
          >
            <Small1Icon />
          </CardOwnerNew>
          <Tags className={classes.tags} tagsList={tagsInfo} />
        </div>
        <div className={classes.topRight}>{/*Btn*/}</div>
      </div>
      <CardImage
        href={url}
        hrefTarget="_blank"
        image={image}
        type={getPlaceholderType(itemType)}
      />
      <div className={`wrapper`}>
        {itemType === activityItem.PROBLEM ? (
          <PsButton onClick={onProblemSolveBtnClick} coins="+1">
            Solve
          </PsButton>
        ) : null}
        {itemType === activityItem.SOLUTION ? (
          <PsButton onClick={onBrainstormClick} coins="Earn 1 IdeaPoint">
            Improve
          </PsButton>
        ) : null}
        {itemType === activityItem.APPLICATION ? (
          <PsButton onClick={onImproveClick} coins="Earn up to 30 IdeaPoints">
            Improve
          </PsButton>
        ) : null}
        {itemType === activityItem.CONTEST ? (
          <PsButton onClick={onSolveBtnClick} coins="50 IdeaPoint Jackpot">
            Solve
          </PsButton>
        ) : null}
      </div>
      <div className={`${classes.bottom} wrapper`}>
        <div className={classes.bottomLeft}>
          <Link to={url}>
            <a className={classes.moreLink}>Show more</a>
          </Link>
        </div>
        <div className={classes.bottomRight}>
          <LikeDislike
            resource={itemType}
            itemId={itemId}
            likes={likes}
            dislikes={dislikes}
            user={user}
          />
          <ShareBtnNew type={itemType} shareInfo={activity} />
        </div>
      </div>
    </div>
  );
};

export const CardFeed = styled(CardFeedView)({});

export default CardFeed;
