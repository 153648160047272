import React from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { PsTheme } from '../../theme';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'content';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    content: {
      overflow: 'auto'
    }
  };
});
type DrawerModalContentProps = {
  children?: React.ReactNode;
};

const DrawerModalContentView = ({ children }: DrawerModalContentProps) => {
  const { classes } = useStyles();
  return <div className={classes.content}>{children}</div>;
};

export const DrawerModalContent = styled(DrawerModalContentView)({});

export default DrawerModalContent;
