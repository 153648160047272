import React, { useCallback, useContext, useEffect } from 'react';
import ModalWindow from 'modals/ModalWindow';
import { PsButton } from 'components/common/PsButton';
import { PsLogo } from 'components/common/PsLogo';
import { PsNFT } from 'components/common/PsNFT';
import { Application } from 'components/CardApplication';
import { DataContext } from 'contexts/DataContext';
import { ModalContext } from 'contexts/ModalContext';
import { ModalDataContext } from 'contexts/ModalDataContext';
import { finalizeType } from '../../helpers';
import styles from 'modals/SuccessModal/SuccessModal.module.scss';
import Typography from '@mui/material/Typography';

export const BuildPatentApplicationSuccessOpen: React.FC = () => {
  const { dataProvider, refreshApplicationDetail, refreshProfileStats } =
    useContext(DataContext);
  const { closeModal } = useContext(ModalContext);
  const { values, resetFields } = useContext(ModalDataContext);

  const application: Application = values.application;

  useEffect(() => {
    dataProvider
      .payOptionFinish(application.id)
      .then(() => {
        refreshApplicationDetail();
        refreshProfileStats();
      })
      .catch((err: any) => {
        console.error(err);
      });
    return;
  }, []);

  const handleClick = useCallback(() => {
    closeModal();
    resetFields();
  }, [closeModal, resetFields]);

  return (
    <ModalWindow title="Congratulations!" titleCenter>
      <div className={styles.wrapper}>
        <div className={styles.iconRow}>
          <PsLogo size={90} color="#632DDD" small />
          <Typography className={styles.iconHolderPrimary} component="div">
            <span>+1.00</span>
            <br />
            IdeaCoins
          </Typography>
        </div>
        <br />
        <br />
        <Typography className={styles.text}>
          You minted an open source Idea NFT for your <b>{application.title}</b>
          .
        </Typography>
        <Typography className={styles.textSmall}>
          The NFT will be available on your invention profile page when ready
        </Typography>
        <PsButton onClick={handleClick} fullWidth>
          Close
        </PsButton>
      </div>
    </ModalWindow>
  );
};

export default BuildPatentApplicationSuccessOpen;
