import React from 'react';
import { Box } from '@mui/material';
import { LogoMindminer } from 'components/icons/LogoMindminer';
import { colorPalette } from 'theme';
import { Constants } from 'utilities/constants';
import {
  IdeaIconContainer,
  StyledHeading,
  StyledSubContentWrapper,
  StyledTextContentWrapper,
  TextContainer
} from '../StyledComponents';

interface ContentProps {
  body: string;
  ideaPoints: number;
}

export const Content: React.FC<ContentProps> = ({ body, ideaPoints }) => {
  return (
    <>
      <StyledHeading>{Constants.C_CONTESTS}:</StyledHeading>
      <StyledSubContentWrapper>
        {Constants.PROBLEM_CONTEST}
      </StyledSubContentWrapper>
      <StyledHeading>{Constants.DESCRIPTION}:</StyledHeading>
      <StyledSubContentWrapper>{body}</StyledSubContentWrapper>
      <StyledHeading>{Constants.TOTAL_EARNINGS}:</StyledHeading>
      <TextContainer>
        <IdeaIconContainer>
          <LogoMindminer fill={colorPalette.purple} width={36} height={36} />
          <Box>
            <StyledTextContentWrapper>
              <span style={{ color: colorPalette.purple, fontWeight: 700 }}>
                {ideaPoints} {Constants.IDEA_POINTS}
              </span>
              {Constants.PROBLEM_IDEAPOINTS_TEXT}
            </StyledTextContentWrapper>
          </Box>
        </IdeaIconContainer>
      </TextContainer>
    </>
  );
};
