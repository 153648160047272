import { Constants } from 'utilities/constants';

export const generateTabId = () => Date.now().toString();

export const getCurrentTabId = () => {
  let currentTabId = sessionStorage.getItem(Constants.TAB_ID_KEY);

  if (!currentTabId) {
    currentTabId = generateTabId();
    sessionStorage.setItem(Constants.TAB_ID_KEY, currentTabId);
  }

  return currentTabId;
};

export const updateTabHistory = (url) => {
  const currentTabId = getCurrentTabId();
  const tabHistoryKey = `${Constants.TAB_KEY_PREFIX}${currentTabId}`;
  const tabHistory = JSON.parse(sessionStorage.getItem(tabHistoryKey)) || [];
  if (!tabHistory.length) {
    sessionStorage.setItem(tabHistoryKey, JSON.stringify([url]));
  }

  if (tabHistory.at(-1) !== url) {
    tabHistory.push(url);
    sessionStorage.setItem(tabHistoryKey, JSON.stringify(tabHistory));
  }
};

export const getTabHistory = () => {
  const currentTabId = getCurrentTabId();
  const tabHistoryKey = `${Constants.TAB_KEY_PREFIX}${currentTabId}`;
  return JSON.parse(sessionStorage.getItem(tabHistoryKey)) || [];
};
