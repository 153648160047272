import ChooseCompanyStep from './steps/ChooseCompanyStep';
import CreateCompanyStep from './steps/CreateCompanyStep';
import SelectPlanStep from './steps/SelectPlanStep';

import {
  ValidateFormType,
  FormStepConfig,
  CompanySubscriptionSteps
} from 'contexts/ModalDataContext';

export const SUBSCRIPTION_STEPS_CONFIG: {
  [name: string]: FormStepConfig;
} = {
  [CompanySubscriptionSteps.ChooseCompanyStep]: {
    name: CompanySubscriptionSteps.ChooseCompanyStep,
    Component: ChooseCompanyStep,
    nextStep: (data) => {
      if (data.parentProblem) {
        return CompanySubscriptionSteps.SelectPlanStep;
      }
      return CompanySubscriptionSteps.CreateCompanyStep;
    },
    isNextStepBlocked: (data) => {
      return false;
    },
    validate: (data): ValidateFormType => {
      return { success: true };
    },
    modalTitle: 'Select Company to Subscribe'
  },
  [CompanySubscriptionSteps.CreateCompanyStep]: {
    name: CompanySubscriptionSteps.CreateCompanyStep,
    Component: CreateCompanyStep,
    nextStep: CompanySubscriptionSteps.SelectPlanStep,
    isNextStepBlocked: (data) => {
      return !data.title;
    },
    validate: (data): ValidateFormType => {
      if (data.title) {
        return { success: true };
      }
      return { success: false, errors: { title: 'required' } };
    },
    modalTitle: 'Enter Company Details'
  },
  [CompanySubscriptionSteps.SelectPlanStep]: {
    name: CompanySubscriptionSteps.SelectPlanStep,
    Component: SelectPlanStep,
    isFinalStep: true,
    isNextStepBlocked: (data) => {
      return !(data.finalizeType && data.termsAgree);
    },
    validate: (data): ValidateFormType => {
      if (data.finalizeType && data.termsAgree) {
        return { success: true };
      }
      return { success: false, errors: { title: 'required' } };
    }
  }
};
