import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef
} from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

import { SearchRow, NavBar, ContentBar } from '../../layout';
import useRouter from '../../hooks/useRouter';
import { getQuery } from '../../helpers';
import { PsTheme } from '../../theme';

import { AuthContext } from '../../contexts/AuthContext';
import { DataContext } from '../../contexts/DataContext';

import { CardFeed, Activity } from '../../components/CardFeed';
import { QuickActions } from '../../components/QuickActions';
import { PsButton } from '../../components/common/PsButton';
import { makeStyles } from 'tss-react/mui';

const PAGE_DEFAULT = 1;
const PER_PAGE_DEFAULT = 10;
const SORT_BY_DEFAULT = 'createdAt';

type ClassKey =
  | 'container'
  | 'wrapper'
  | 'loading'
  | 'noResult'
  | 'listWrapper'
  | 'header'
  | 'itemsSelectWrapper'
  | 'itemsSelect'
  | 'loadMoreWrapper';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxWidth: 1255,
      margin: '0 auto',
      padding: '0 10px'
    },
    wrapper: {
      display: 'flex'
    },
    loading: {
      marginBottom: 10,
      textAlign: 'center'
    },
    noResult: {
      maxWidth: 350,
      margin: '0 auto 10px',
      textAlign: 'center',
      color: psTheme.palette.primary.main,
      '& a': {
        color: 'inherit',
        textDecoration: 'underline'
      },
      '& a:hover': {
        textDecoration: 'none'
      }
    },
    listWrapper: {
      padding: '0 0 48px'
    },
    header: {
      display: 'flex',
      justifyContent: 'flex-end',
      flexWrap: 'wrap'
    },
    itemsSelectWrapper: {
      justifyContent: 'flex-end',
      display: 'flex',
      alignItems: 'center',
      marginBottom: 22,
      marginLeft: 15,
      fontSize: 14,
      lineHeight: '20px',
      textAlign: 'center',
      fontFeatureSettings: '"pnum" on, "onum" on',
      color: '#000000',
      '& > span': {
        flexShrink: 0
      }
    },
    itemsSelect: {
      marginLeft: 8,
      height: 32,
      padding: 0,
      overflow: 'hidden'
    },
    loadMoreWrapper: {
      textAlign: 'center'
    }
  };
});

const ActivityListView = () => {
  const state = useRef({ resetPages: true, initRender: true });
  const { user } = useContext(AuthContext);
  const { dataProvider, filterTags, feedCache, unseen, updateUnseen } =
    useContext(DataContext);
  const { classes } = useStyles();

  const router = useRouter();

  const query = getQuery();
  const initPage = parseInt(query.p, 10) || PAGE_DEFAULT;
  const initPerPage = parseInt(query.pp, 10) || PER_PAGE_DEFAULT;
  const initSortBy = query.s || SORT_BY_DEFAULT;
  const filterTagsStr = query.tags;

  const [dataChunks, setDataChunks] = useState<Array<Array<Activity>>>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(initPage);
  const [perPage] = useState<number>(initPerPage);
  const [sortBy] = useState<string>(initSortBy);
  const [totalItems, setTotalItems] = useState(0);

  let showLoadMore = false;
  if (totalItems && page * perPage < totalItems) {
    showLoadMore = true;
  }

  useEffect(() => {
    const fetchData = async () => {
      const filter: { $custom: any; tags?: any } = {
        $custom: { type: 'forUi' }
      };
      if (query.tags) {
        filter.tags = query.tags.split(',');
      }
      const data = await dataProvider.getList<Activity>('activities', {
        pagination: {
          page: query.p || PAGE_DEFAULT,
          perPage: query.pp || PER_PAGE_DEFAULT
        },
        sort: { field: query.s || SORT_BY_DEFAULT, order: 'DESC' },
        filter
      });

      setDataChunks(data as unknown as Activity[][]);
      setTotalItems(data.data.length);

      return data;
    };

    fetchData()
      .catch((error: Error) => {
        console.error(error);
      })
      .finally(() => {
        state.current.resetPages = true;
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (state.current.initRender) {
      state.current.initRender = false;
      return;
    }

    setLoading(true);
    const filter: { $custom: any; tags?: any } = {
      $custom: { type: 'forUi' }
    };
    if (filterTags && filterTags.length) {
      filter.tags = filterTags;
    }
    dataProvider
      .getList<Activity>('activities', {
        pagination: { page, perPage },
        sort: { field: sortBy, order: 'DESC' },
        filter
      })
      .then(({ data, total }: any) => {
        if (state.current.resetPages) {
          setDataChunks([data]);
        } else {
          setDataChunks([...dataChunks, data]);
        }
        setTotalItems(total);
        state.current.resetPages = true;
      })
      .catch((err: any) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [page, perPage, sortBy, filterTagsStr, feedCache, user]);

  const onSeenSelect = (id: string | number) => {
    const isUnseen = unseen.some((el) => el.activityId === id);
    updateUnseen(id, true);
  };

  const onLoadMoreClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      state.current.resetPages = false;
      state.current.initRender = false;
      setPage(page + 1);
    },
    [page, setPage]
  );

  let hasResult = false;
  if (
    dataChunks &&
    dataChunks.length &&
    dataChunks[0] &&
    dataChunks[0].length
  ) {
    hasResult = true;
  }

  return (
    <div className={classes.container}>
      <header>
        <title>MindMiner - Activities</title>
      </header>

      <SearchRow />

      <div className={classes.wrapper}>
        <NavBar />

        <ContentBar className={classes.listWrapper}>
          {user ? <QuickActions></QuickActions> : null}
          {dataChunks.map((chunk) => {
            return chunk.map((item) => {
              const isUnseen = unseen.some((el) => el.activityId === item.id);
              return (
                <CardFeed
                  key={item.id}
                  activity={item}
                  seen={!isUnseen}
                  onSeenSelect={onSeenSelect}
                />
              );
            });
          })}
          {!loading && !hasResult && user ? (
            <Typography className={classes.noResult} variant="h6">
              Add interests to your{' '}
              <Link to={`/profiles/${user.key}`}>profile</Link> and join{' '}
              <Link to="/contests">contests</Link> to see more idea creation
              opportunities.
            </Typography>
          ) : null}
          {loading ? (
            <Typography className={classes.loading} variant="body2">
              Loading...
            </Typography>
          ) : null}
          {showLoadMore ? (
            <div className={classes.loadMoreWrapper}>
              <Link to={`${router.pathname}?p=${page + 1}`}>
                <PsButton onClick={onLoadMoreClick} component="a">
                  Load More
                </PsButton>
              </Link>
            </div>
          ) : null}
        </ContentBar>
      </div>
    </div>
  );
};

export const ActivityList = styled(ActivityListView)({});

export default ActivityList;
