import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, CircularProgress, Tooltip } from '@mui/material';
import { LinearProgressWithPercentage } from 'components/common/LinearProgressWithPercentage';
import { CoinIcon } from 'components/icons/CoinIcon';
import { PolygonMaticIcon } from 'components/icons/PolygonMaticIcon';
import { WalletIconNew } from 'components/icons/WalletIconNew';
import walletIcon from 'assets/icons/wallet.svg';
import {
  getOwnerMaticBalance,
  showUserIdeaBalance,
  userCurrencyData
} from 'helpers/blockchain';
import Actions from 'redux-state/actions';
import { GetRewardPoolThreshold, GetUser } from 'redux-state/selectors';
import { colorPalette } from 'theme';
import {
  CURRENCIES_SYMBOL_TO_NAME,
  Constants,
  ERRORS
} from 'utilities/constants';
import walletProvider from 'walletProvider';
import {
  AmountText,
  BalanceTitleWrapper,
  BodyText,
  ContentWrapper,
  EquivalentAmountBox,
  FooterDivider,
  HeadingBox,
  HeadingMainBox,
  HeadingText,
  LoadingBox,
  LogoWrapper,
  MainHeading,
  PopoverContent,
  ProgressBox,
  RewardsHeadingBox,
  StyledDivider,
  StyledIconButton,
  StyledPopover,
  SubHeading,
  SubHeadingBox,
  SymbolWrapper,
  TextWrapper,
  ThresholdText,
  TitleText,
  ValueText
} from './styledComponents';
import { IconButtonWrapper } from 'layout/Header/styledComponents';
import IconButton from 'components/IconButton';

interface RewardsPoolPopupProps {
  anchorEl?: Element | null;
  handleWalletClick?: () => void;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface CurrencyData {
  maticAmount: number;
  conversions: {
    currency: string;
    amount: number;
  }[];
}

export const RewardsPoolPopup = ({
  anchorEl,
  handleWalletClick,
  open,
  setOpen
}: RewardsPoolPopupProps) => {
  const [userData, setUserData] = useState<CurrencyData | undefined>();
  const [ownerBalance, setOwnerBalance] = useState<number>();
  const [percentageMet, setPercentageMet] = useState<number | undefined>();
  const [coinsLeft, setCoinsLeft] = useState<number | undefined>();
  const [ideaCoins, setIdeaCoins] = useState<number | undefined>();
  const user = GetUser();
  const rewardPoolThreshold = GetRewardPoolThreshold();
  const dispatch = useDispatch();

  const calculateThresholdStats = useCallback(
    (threshold: number, currentBalance: number) => {
      let percentageMet = (currentBalance / threshold) * 100;
      percentageMet = percentageMet > 100 ? 100 : percentageMet;
      const coinsLeft = threshold - currentBalance;

      return {
        percentageMet: Math.floor(percentageMet),
        coinsLeft: coinsLeft > 0 ? coinsLeft : 0
      };
    },
    []
  );

  useEffect(() => {
    dispatch(Actions.getRewardPoolThreshold());
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      showUserIdeaBalance(user.walletAddress)
        .then((balance) => {
          setIdeaCoins(balance);
        })
        .catch((error) => {
          console.error(ERRORS.GET_IDEA_COINS, error.message);
        });
    }
  }, [user]);

  useEffect(() => {
    getOwnerMaticBalance()
      .then((data) => {
        setOwnerBalance(Number(data));
      })
      .catch((error) => {
        console.error(error.message);
      });
  }, []);

  useEffect(() => {
    if (ownerBalance && rewardPoolThreshold) {
      const { percentageMet, coinsLeft } = calculateThresholdStats(
        rewardPoolThreshold,
        ownerBalance
      );
      setPercentageMet(percentageMet);
      setCoinsLeft(coinsLeft);
    }
  }, [calculateThresholdStats, ownerBalance, rewardPoolThreshold]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleMagicWalletShow = useCallback(async () => {
    await walletProvider.magicShowWallet();
  }, [dispatch]);

  useEffect(() => {
    const fetchCurrencyData = () => {
      if (rewardPoolThreshold && user.walletAddress) {
        userCurrencyData(user.walletAddress, rewardPoolThreshold)
          .then((data) => {
            setUserData(data);
          })
          .catch((error) => {
            console.error(error.message);
          });
      }
    };

    fetchCurrencyData();
  }, [rewardPoolThreshold, user]);

  const EquivalentAmount = useCallback(({ currency, amount }) => {
    return (
      <EquivalentAmountBox>
        <TitleText>
          <TextWrapper>
            {`${CURRENCIES_SYMBOL_TO_NAME[currency]}`}&nbsp;
          </TextWrapper>
          {`/ ${Constants.MATIC}`}
        </TitleText>
        <AmountText>
          {amount}
          <SymbolWrapper>&nbsp;{`${currency}`}</SymbolWrapper>
        </AmountText>
      </EquivalentAmountBox>
    );
  }, []);

  const TotalAmount = useCallback(({ amount, Icon }) => {
    return (
      <LogoWrapper>
        <Icon />
        <ValueText>{amount}</ValueText>
      </LogoWrapper>
    );
  }, []);

  const RewardsSection = useMemo(() => {
    return (
      <ContentWrapper>
        <StyledDivider />
        <HeadingMainBox>
          <MainHeading>{Constants.REWARD_POOL}</MainHeading>
        </HeadingMainBox>
        <RewardsHeadingBox>
          <HeadingText>{Constants.TOTAL_MATICS}</HeadingText>
          <TotalAmount amount={ownerBalance ?? 0} Icon={PolygonMaticIcon} />
        </RewardsHeadingBox>
        <EquivalentAmountBox color={colorPalette.dimBlack}>
          <SubHeading>
            <TextWrapper>
              {Constants.NEXT_ROYALTY_DISTRIBUTION_THRESHOLD}
            </TextWrapper>
          </SubHeading>
          <AmountText>{rewardPoolThreshold}</AmountText>
        </EquivalentAmountBox>
        <ProgressBox>
          <LinearProgressWithPercentage currentPercentage={percentageMet} />
          <ThresholdText>
            {coinsLeft ? (
              <>
                <TextWrapper>{coinsLeft}&nbsp;</TextWrapper> more matics left to
                hit next threshold{' '}
              </>
            ) : (
              Constants.THRESHOLD_REACHED
            )}
          </ThresholdText>
        </ProgressBox>
      </ContentWrapper>
    );
  }, [ownerBalance, percentageMet, rewardPoolThreshold, TotalAmount]);

  const BalanceSection = useMemo(() => {
    return (
      <ContentWrapper>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <IconButtonWrapper>
            <IconButton
              color={colorPalette.purple}
              hasBorder={true}
              icon={walletIcon}
              onChange={handleWalletClick}
              title={Constants.MY_WALLET}
            />
          </IconButtonWrapper>
        </Box>
        <StyledDivider />
        <HeadingMainBox>
          <BalanceTitleWrapper>
            <Tooltip title={Constants.WALLET_SETTINGS}>
              <StyledIconButton onClick={handleMagicWalletShow}>
                <WalletIconNew />
              </StyledIconButton>
            </Tooltip>
            <MainHeading>{Constants.YOUR_BALANCE}</MainHeading>
          </BalanceTitleWrapper>
        </HeadingMainBox>
        <HeadingBox>
          <HeadingText>{Constants.TOTAL_IDEACOINS}</HeadingText>
          <TotalAmount amount={ideaCoins ?? '0'} Icon={CoinIcon} />
        </HeadingBox>
        <SubHeadingBox>
          <SubHeading>{Constants.YOUR_NEXT_ROYALTY_DISTRIBUTION}</SubHeading>
        </SubHeadingBox>
        <EquivalentAmountBox color={colorPalette.lightGreen}>
          <TitleText>
            <TextWrapper>{Constants.MATIC_SHARE}</TextWrapper>
          </TitleText>
          <AmountText>{userData?.maticAmount ?? '0'}</AmountText>
        </EquivalentAmountBox>
        {userData?.conversions.map((data, index) => (
          <EquivalentAmount
            currency={data.currency}
            amount={data.amount ?? '0'}
            key={index}
          />
        ))}
      </ContentWrapper>
    );
  }, [
    coinsLeft,
    EquivalentAmount,
    handleMagicWalletShow,
    ideaCoins,
    percentageMet,
    TotalAmount,
    userData
  ]);

  const isDataFetched = !!(
    userData &&
    ownerBalance != undefined &&
    coinsLeft != undefined &&
    ideaCoins != undefined
  );

  return (
    <StyledPopover
      open={open}
      onClose={handleClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      <PopoverContent>
        {!isDataFetched ? (
          <LoadingBox>
            <CircularProgress />
          </LoadingBox>
        ) : (
          <>
            {BalanceSection}
            {RewardsSection}
            <FooterDivider />
            <BodyText>{Constants.REWARD_POOL_INFO}</BodyText>
          </>
        )}
      </PopoverContent>
    </StyledPopover>
  );
};
