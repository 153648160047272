import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import logo from 'assets/images/logo.svg';
import { LoginSignUpImage } from 'components/icons/LoginSignupImage';
import { ResetPasswordImage } from 'components/icons/ResetPasswordImage';
import { SetNewPasswordImage } from 'components/icons/SetNewPasswordImage';
import SimpleIconButton from 'components/SimpleIconButton';
import { BaseModal } from 'modals/Common/BaseModal';
import Actions from 'redux-state/actions';
import { useIsMediumScreen } from 'theme';
import { Breakpoints, VARIANT } from 'utilities/constants';
import { LoginView } from './Login';
import { ResetPasswordView } from './ResetPassword';
import { SetNewPasswordView } from './SetNewPassword';
import { SignUpView } from './Signup';
import {
  CloseIconWrapper,
  LeftSection,
  RightSection,
  StyledLogo,
  StyledParentContainer
} from './styledComponents';

interface AuthModalProps {
  formView: string;
  onClose: () => void;
  open: boolean;
  setFormView: Dispatch<SetStateAction<string>>;
}

export const AuthModal: React.FC<AuthModalProps> = ({
  formView,
  open,
  onClose,
  setFormView
}) => {
  const dispatch = useDispatch();
  const isMediumScreen = useIsMediumScreen();

  const GetFormView = () => {
    dispatch(Actions.updateErrorStatus(null, ''));
    dispatch(Actions.resetPasswordResponse({}));
    return React.createElement(
      { ResetPasswordView, SetNewPasswordView, SignUpView, LoginView }[
        formView
      ],
      { setFormView }
    );
  };

  const getImage = useMemo(
    () =>
      React.createElement(
        {
          LoginView: LoginSignUpImage,
          ResetPasswordView: ResetPasswordImage,
          SetNewPasswordView: SetNewPasswordImage,
          SignUpView: LoginSignUpImage
        }[formView]
      ),
    [formView]
  );

  const renderCloseIcon = useCallback((onClose) => {
    return (
      <CloseIconWrapper>
        <SimpleIconButton
          Icon={CancelOutlinedIcon}
          color={VARIANT.PRIMARY}
          onClick={onClose}
        />
      </CloseIconWrapper>
    );
  }, []);

  return (
    <BaseModal open={open} maxWidth={Breakpoints.EXTRA_LARGE} onClose={onClose}>
      {!isMediumScreen && renderCloseIcon(onClose)}
      <StyledParentContainer isMobile={isMediumScreen}>
        <LeftSection>
          <StyledLogo>
            <img src={logo} />
          </StyledLogo>
          <GetFormView />
        </LeftSection>
        {!isMediumScreen && <RightSection>{getImage}</RightSection>}
      </StyledParentContainer>
    </BaseModal>
  );
};
