import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Divider } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Solution } from 'components/CardSolution';
import { ShortEvent } from 'components/common/PsFormControl';
import AuthContext from 'contexts/AuthContext';
import { BaseModal } from 'modals/Common/BaseModal';
import Actions from 'redux-state/actions';
import {
  MainContainer,
  NameFieldBox,
  NameTextField,
  StyledBodyFieldText,
  StyledBodyTypography,
  StyledFooterBox,
  StyledSaveButton,
  TitleText,
  TextFieldBox,
  DescrMainBox
} from './styles';
import { Breakpoints, Constants } from 'utilities/constants';

interface EditSolutionModalProps {
  onClose: () => void;
  open: boolean;
  pagination: { page: number; perPage: number };
  profileId: string | number;
  solution: Solution;
}

export const EditSolutionModal: React.FC<EditSolutionModalProps> = ({
  onClose,
  open,
  pagination,
  profileId,
  solution
}: EditSolutionModalProps) => {
  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);

  const [title, setTitle] = useState<string>('');
  const [teaser, setTeaser] = useState<string>('');
  const [descr, setDescr] = useState<string>('');
  const [materials, setMaterials] = useState<string>('');
  const [dimensions, setDimensions] = useState<string>('');
  const [steps, setSteps] = useState<string>('');

  useEffect(() => {
    setTitle(solution?.title || '');
    setDescr(solution?.body || '');
    setMaterials(solution?.descMaterials || '');
    setDimensions(solution?.descDimensions || '');
    setSteps(solution?.descSteps || '');
    setTeaser(solution?.teaser || '');
  }, [solution]);

  const onUpdateClick = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      if (user) {
        const solutionInfo = {
          title,
          teaser,
          body: descr,
          descMaterials: materials,
          descDimensions: dimensions,
          descSteps: steps
        };
        dispatch(
          Actions.editProfileItem({
            type: Constants.SOLUTIONS,
            id: solution.id,
            profileId: profileId,
            parentType: Constants.APPLICATIONS,
            pagination,
            data: solutionInfo,
            getConcepts: true
          })
        );
      } else {
        dispatch(Actions.openAuthModal(true));
      }
      onClose();
    },
    [
      user,
      onClose,
      title,
      descr,
      teaser,
      materials,
      dimensions,
      steps,
      solution.id,
      profileId,
      pagination
    ]
  );

  const onTitleChange = useCallback(
    (e: ShortEvent) => {
      setTitle(e.target.value);
    },
    [setTitle]
  );

  const onTeaserChange = useCallback(
    (e: ShortEvent) => {
      setTeaser(e.target.value);
    },
    [setTeaser]
  );

  const onDescrChange = useCallback(
    (e: ShortEvent) => {
      setDescr(e.target.value);
    },
    [setDescr]
  );

  const onMaterialsChange = useCallback(
    (e: ShortEvent) => {
      setMaterials(e.target.value);
    },
    [setMaterials]
  );

  const onDimensionsChange = useCallback(
    (e: ShortEvent) => {
      setDimensions(e.target.value);
    },
    [setDimensions]
  );

  const onStepsChange = useCallback(
    (e: ShortEvent) => {
      setSteps(e.target.value);
    },
    [setSteps]
  );
  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <BaseModal open={open} onClose={handleClose} maxWidth={Breakpoints.LARGE}>
      <MainContainer>
        <TitleText>Edit Solution</TitleText>
        <Divider sx={{ margin: '1rem 0' }} />
        <NameFieldBox>
          <StyledBodyTypography>Name</StyledBodyTypography>
          <NameTextField value={title} onChange={onTitleChange} />
        </NameFieldBox>
        <NameFieldBox>
          <StyledBodyTypography>Teaser</StyledBodyTypography>
          <NameTextField value={teaser} onChange={onTeaserChange} />
        </NameFieldBox>
        <DescrMainBox>
          <TextFieldBox>
            <StyledBodyTypography>Materials</StyledBodyTypography>
            <StyledBodyFieldText
              value={materials}
              multiline
              rows={5}
              onChange={onMaterialsChange}
            />
          </TextFieldBox>
          <TextFieldBox>
            <StyledBodyTypography>Dimensions</StyledBodyTypography>
            <StyledBodyFieldText
              value={dimensions}
              multiline
              rows={5}
              onChange={onDimensionsChange}
            />
          </TextFieldBox>
        </DescrMainBox>
        <NameFieldBox>
          <StyledBodyTypography>Steps of Use</StyledBodyTypography>
          <StyledBodyFieldText
            multiline
            onChange={onStepsChange}
            rows={4}
            value={steps}
          />
        </NameFieldBox>
        <NameFieldBox>
          <StyledBodyTypography>Description</StyledBodyTypography>
          <StyledBodyFieldText
            multiline
            onChange={onDescrChange}
            rows={4}
            value={descr}
          />
        </NameFieldBox>
        <Divider sx={{ margin: '1rem 0' }} />
        <StyledFooterBox>
          <StyledSaveButton>Back</StyledSaveButton>
          <StyledSaveButton onClick={onUpdateClick}>
            Update Solution
          </StyledSaveButton>
        </StyledFooterBox>
      </MainContainer>
    </BaseModal>
  );
};
