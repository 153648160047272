import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

interface ReusableCarouselProps {
  items: React.ReactNode[];
  arrows?: boolean;
  responsive: {
    [key: string]: {
      breakpoint: { max: number; min: number };
      items: number;
    };
  };
  containerClass?: string;
  itemClass?: string;
  infinite?: boolean;
}

const CarouselComponent: React.FC<ReusableCarouselProps> = ({
  items,
  arrows = false,
  responsive,
  containerClass,
  itemClass,
  infinite = false
}) => {
  return (
    <Carousel
      responsive={responsive}
      arrows={arrows}
      infinite={infinite}
      draggable
      swipeable
      containerClass={containerClass}
      itemClass={itemClass}
    >
      {items}
    </Carousel>
  );
};

export default CarouselComponent;
