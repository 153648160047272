import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import { PsTheme } from '../../theme';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';

type ClassKey = 'root' | 'paper' | 'content';

const styles = makeStyles()((theme) => {
  const psTheme = theme as PsTheme;
  return {
    root: {},
    paper: {
      '& .MuiDialog-paper': {
        maxWidth: 500
      }
    },
    content: {
      '& a': {
        color: psTheme.palette.primary.main,
        textDecoration: 'none'
      },
      '& a:hover': {
        textDecoration: 'underline'
      }
    }
  };
});

export type WarningDialogProps = {
  children: React.ReactNode;
  classes?: any;
  open: boolean;
  onSubmit: (agree: boolean) => void;
  yes?: string;
  no?: string;
};

const WarningDialogView = ({
  children,
  open,
  onSubmit,
  yes = 'Yes',
  no = 'No'
}: WarningDialogProps) => {
  const { classes } = styles();
  const handleClose = () => {
    onSubmit(false);
  };

  const handleAgree = () => {
    onSubmit(true);
  };

  return (
    <Dialog
      className={classes.paper}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText className={classes.content}>
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {yes ? (
          <Button
            onClick={handleAgree}
            color="secondary"
            variant="contained"
            autoFocus
          >
            {yes}
          </Button>
        ) : null}
        {no ? (
          <Button onClick={handleClose} color="primary" variant="contained">
            {no}
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};

export const WarningDialog = styled(WarningDialogView)({});

export default WarningDialog;
