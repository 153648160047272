import React, { useCallback } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  StyledDialog,
  StyledDialogTitle,
  StyledDialogContent,
  StyledTypography,
  StyledIconButton
} from './styledComponents';
import { Constants } from 'utilities/constants';

// Constants object for storing all the strings

interface DetailsModalProps {
  open: boolean;
  setOpen: any;
  data: {
    isAiGenerated: boolean;
    isPublic: boolean;
    key: string;
    shortTitle: string;
    teaser: string;
    title: string;
  };
}

export const DetailsModal: React.FC<DetailsModalProps> = ({
  open,
  setOpen,
  data
}) => {
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <StyledDialog open={open} onClose={handleClose}>
      <StyledDialogTitle>
        {Constants.PROBLEM_DETAILS}
        <StyledIconButton onClick={handleClose}>
          <CloseIcon />
        </StyledIconButton>
      </StyledDialogTitle>
      <StyledDialogContent>
        <StyledTypography>
          <strong>{`${Constants.TITLE}:`}</strong> {data.title}
        </StyledTypography>
        <StyledTypography>
          <strong>{`${Constants.SHORT_TITLE}:`}</strong> {data.shortTitle}
        </StyledTypography>
        <StyledTypography>
          <strong>{`${Constants.TEASER}:`}</strong> {data.teaser}
        </StyledTypography>
        <StyledTypography>
          <strong>{`${Constants.AI_GENERATED}:`}</strong>{' '}
          {data.isAiGenerated ? 'Yes' : 'No'}
        </StyledTypography>
        <StyledTypography>
          <strong>{`${Constants.C_PUBLIC}:`}</strong>{' '}
          {data.isPublic ? 'Yes' : 'No'}
        </StyledTypography>
      </StyledDialogContent>
    </StyledDialog>
  );
};
