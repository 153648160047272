import React, { useCallback, useContext, useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Box, Divider, Typography } from '@mui/material';
import { TagInfo } from 'components/CardTag';
import Vote from 'components/common/Vote';
import Config from 'config/config';
import AuthContext from 'contexts/AuthContext';
import DataContext from 'contexts/DataContext';
import ModalContext from 'contexts/ModalContext';
import { getBasePath } from 'helpers/common';
import formatDate from 'helpers/formatDate';
import useRouter from 'hooks/useRouter';
import SolutionModal from 'modals/AddSolution';
import ImproveModal from 'modals/ImproveModal';
import {
  FooterContainer,
  StyledAddedText,
  StyledButtonsContainer,
  StyledCalendarIcon,
  StyledChipList,
  StyledContainer,
  StyledCreateButton,
  StyledCreateButtonTypography,
  StyledCreatedText,
  StyledDateContainer,
  StyledEmptyFeedText,
  StyledIcons,
  StyledInfoContainer,
  StyledLinearProgress,
  StyledMainBox,
  StyledMainContainer,
  StyledMainImage,
  StyledParentContainer,
  StyledProfileDescription,
  StyledSubContainer,
  StyledSubFooterContainer,
  StyledTagsContent,
  StyledTextContainer,
  StyledTitle,
  StyledTitleText,
  StyledTreeIconWithPlus,
  StyledVoteInfoContainer,
  VotingBox
} from 'pages/home/StyledComponents';
import { Node } from 'interface/common';
import AddToConceptModal from 'modals/AddToConceptModal';
import IdentifierIcon from 'components/IdentifierIcon';
import { useDispatch } from 'react-redux';
import Actions from 'redux-state/actions';
import {
  GetActivityCount,
  GetActivityLoader,
  GetTagActivityCount,
  GetTagActivityLoader
} from 'redux-state/commons/selectors';
import {
  GetConceptData,
  GetShareStatus,
  GetToggleConceptCart,
  GetUser
} from 'redux-state/selectors';
import { colorPalette, useIsMediumScreen } from 'theme';
import { Constants } from 'utilities/constants';
import CalendarIcon from '../../assets/icons/calendar_icon.svg';
import { Application } from '../CardApplication';
import { Problem } from '../CardProblem';
import { Product } from '../CardProduct';
import { Solution } from '../CardSolution';
import { TreeIcon } from '../ConceptsButton/TreeIcon';
import { GreenCounter } from '../common/GreenCounter';
import { LinkView } from '../LinkView';
import { ShareBtnSimple } from '../ShareBtn';

const clientUrl = Config.NEXT_PUBLIC_CLIENT_URL;

interface ItemListProps {
  communityTagId?: string;
  editItem?: (solution: Solution) => void;
  feed?: Problem | Solution | Product | Application;
  filters?: string[];
  iconUrl?: string;
  isCommunityFeed?: boolean | false;
  itemsCount?: number;
  itemsData?: Array<any>;
  itemsLoader?: boolean;
  pagination?: { page: number; perPage: number };
  placeHolderImage?: string;
  setRowsPerPage?: React.Dispatch<React.SetStateAction<number>>;
  showIcons?: boolean;
  sortType?: string;
  text?: string;
}
const ListView: React.FC<ItemListProps> = ({
  communityTagId,
  feed,
  filters,
  isCommunityFeed,
  pagination,
  placeHolderImage,
  setRowsPerPage,
  sortType
}) => {
  const user = GetUser();
  const { showToast } = useContext(DataContext);
  const isMediumScreen = useIsMediumScreen();
  const [isAddToConceptModalOpen, setIsAddToConceptModalOpen] =
    useState<boolean>(false);
  const [hasMoreItems, setHasMoreItems] = useState<boolean>(true);
  const router = useRouter();
  const [isReadMore, setIsReadMore] = useState<boolean>(false);
  const [isTitleReadMore, setIsTitleReadMore] = useState<boolean>(false);
  const [showAllTags, setShowAllTags] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedNode, setSelectedNode] = useState<Node | null>(null);
  const conceptData = GetConceptData();
  const activeConcept = user?.activeConcept || {};
  const shareStatus = GetShareStatus();

  const isCurrentConceptSolution = useCallback(
    (id) => {
      const activeConceptData = conceptData?.data?.find(
        (concept) => concept.id === activeConcept?.id
      );
      if (!activeConceptData) return false;
      return activeConceptData?.selected?.includes(id);
    },
    [activeConcept?.id, conceptData?.data]
  );

  const itemsCount = isCommunityFeed
    ? GetTagActivityCount()
    : GetActivityCount();
  const feedLoader = isCommunityFeed
    ? GetTagActivityLoader()
    : GetActivityLoader();

  const useModalContext = () => {
    return useContext(ModalContext);
  };

  const { openInventionImprove, openSolutionCreate } = useModalContext();
  const dispatch = useDispatch();
  const isConceptDrawerOpen = GetToggleConceptCart();
  const [isSolModalOpen, setIsSolModalOpen] = useState<boolean>(false);
  const [isImproveModalOpen, setIsImproveModalOpen] = useState<boolean>(false);
  const [invemtionTitle, setInventionTitle] = useState<string>('');
  const [inventionId, setInventionId] = useState<string>('');
  const [inventionKey, setInventionKey] = useState<string>('');
  const [modalType, setModalType] = useState<string>('');

  const openAddToConceptModal = useCallback(
    (item) => {
      if (user) {
        setSelectedNode(item);
        setIsAddToConceptModalOpen(true);
      } else {
        dispatch(Actions.openAuthModal(true));
      }
    },
    [user]
  );

  const closeAddToConceptModal = useCallback(() => {
    setIsAddToConceptModalOpen(false);
  }, []);

  const handleDrawer = useCallback(() => {
    dispatch(Actions.toggleConceptCart(true));
  }, [dispatch]);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const toggleIsTitleReadMore = () => {
    setIsTitleReadMore(!isTitleReadMore);
  };

  const fetchFeedData = useCallback(() => {
    if (itemsCount > 0 && itemsCount <= pagination.perPage) {
      setHasMoreItems(false);
    }
  }, [
    dispatch,
    pagination,
    communityTagId,
    itemsCount,
    isCommunityFeed,
    pagination.perPage
  ]);

  useEffect(() => {
    fetchFeedData();
  }, [pagination.perPage, fetchFeedData]);

  const onShowAllTagsClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowAllTags(!showAllTags);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        setRowsPerPage((rowsPerPage) => rowsPerPage + 10);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };

  const handleModalClose = useCallback(() => {
    setModalOpen(false);
    setSelectedNode(null);
  }, []);

  const createConceptToastify = useCallback(
    (title) => {
      return (
        <Typography>
          {title}
          {Constants.CONCEPT_CREATION_START}
          <a
            style={{ cursor: 'pointer' }}
            href={`/profiles/${user?.key}?currentTab=${Constants.CONCEPTS}`}
          >
            {Constants.CLICK_HERE}
          </a>
          {Constants.CONCEPT_CREATION_END}
        </Typography>
      );
    },
    [user?.key]
  );

  const productToastify = useCallback((title, key) => {
    return (
      <Typography>
        {Constants.FEED_PRODUCT_IMPROVE}
        {title}
        <a style={{ cursor: 'pointer' }} href={`/products/${key}`}>
          {` ${Constants.CLICK_HERE}`}
        </a>
      </Typography>
    );
  }, []);

  const handleClickButton = useCallback(
    (item) => {
      if (user) {
        const { itemId, itemType } = item;
        const {
          key,
          _id,
          image,
          isLiked,
          likes,
          ownerImage,
          title,
          type,
          problem,
          contests,
          teaser,
          tags
        } = itemId;
        if (item.itemType === Constants.PROBLEM) {
          const completeNode: Node = {
            ...item.itemId,
            key: key || '',
            id: _id || '',
            image: image || '',
            isLiked: isLiked || false,
            likes: likes || 0,
            ownerImage: ownerImage || '',
            title: title || '',
            type: type || '',
            problem: problem || '',
            contests: contests || [],
            teaser: teaser || '',
            tags: item.tags || []
          };
          setSelectedNode(completeNode);
          setIsSolModalOpen(true);
        } else if (
          itemType === Constants.APPLICATION ||
          itemType === Constants.Invention
        ) {
          const title = item?.itemId?.title
            ? `Improved ${item?.itemId?.title}`
            : `Concept ${conceptData?.data?.length + 1}`;
          dispatch(
            Actions.createConcept({
              title: title,
              selected: item?.itemId?.selected || [],
              problems: item?.itemId?.problems || []
            })
          );
          showToast(createConceptToastify(title) as unknown as string, {
            style: {
              backgroundColor: colorPalette.white,
              bottom: 0,
              color: colorPalette.black,
              left: 77,
              position: 'absolute'
            },
            autoHideDuration: 5000
          });
        } else if (itemType === Constants.PRODUCT) {
          showToast(productToastify(title, key) as unknown as string, {
            style: {
              backgroundColor: colorPalette.white,
              bottom: 0,
              color: colorPalette.black,
              left: 77,
              position: 'absolute'
            },
            autoHideDuration: 5000
          });
          setInventionTitle(teaser || title);
          setInventionId(itemId._id);
          setInventionKey(key);
          setModalType(Constants.PRODUCT);
        } else if (itemType === Constants.SOLUTION) {
          const completeNode: Node = {
            ...item.itemId,
            key: key || '',
            id: _id || '',
            image: image || '',
            isLiked: isLiked || false,
            likes: likes || 0,
            ownerImage: ownerImage || '',
            title: title || '',
            type: type || '',
            problem: problem || '',
            contests: contests || [],
            teaser: teaser || '',
            tags: tags || []
          };
          setSelectedNode(completeNode);
          setModalOpen(true);
          <AddToConceptModal
            handleDrawer={handleModalClose}
            isFeed={true}
            node={selectedNode}
            onClose={handleModalClose}
            open={modalOpen}
          />;
        } else if (itemType === Constants.CONTEST) {
          router.push(
            `/${Constants.CONTESTS}/${item.itemId.key}?participate=true`
          );
        }
      } else {
        dispatch(Actions.openAuthModal(true));
      }
    },
    [
      conceptData?.data?.length,
      createConceptToastify,
      dispatch,
      handleModalClose,
      modalOpen,
      router,
      selectedNode,
      showToast,
      user
    ]
  );

  const handleTitleClick = (item) => {
    const getType = () => {
      switch (item.itemType) {
        case Constants.PROBLEM:
          return Constants.PROBLEMS;
        case Constants.SOLUTION:
          return Constants.SOLUTIONS;
        case Constants.APPLICATION:
          return Constants.INVENTIONS;
        case Constants.Invention:
          return Constants.INVENTIONS;
        case Constants.PRODUCT:
          return Constants.PRODUCTS;
        case Constants.CONTEST:
          return Constants.CONTESTS;
        default:
          return '';
      }
    };
    router.push(`/${getType()}/${item.itemId.key}`);
  };

  const getTitle = (item, isTitleReadMore, toggleIsTitleReadMore) => {
    const teaser = item?.itemId?.teaser;
    const shortTitle = item?.itemId?.shortTitle;
    const title = item?.itemId?.title;
    const key = item?.itemId?.key;
    const displayText = teaser || shortTitle || title || key;

    if (!displayText) return null;

    const shouldSlice = displayText.length > 50;
    const displayedTitle = isTitleReadMore
      ? displayText
      : displayText.slice(0, 50);

    return (
      <Box>
        {displayedTitle}
        {shouldSlice && (
          <StyledTitle onClick={toggleIsTitleReadMore}>
            {isTitleReadMore && ` .(${displayText.length - 50})`}
          </StyledTitle>
        )}
      </Box>
    );
  };

  const createButton = (handleClick, icon, text, disabled = false) => (
    <StyledCreateButton
      isCommunityFeed={isCommunityFeed}
      isMediumScreen={isMediumScreen}
      onClick={(e) => {
        e.stopPropagation();
        handleClick();
      }}
      variant="contained"
      disabled={disabled}
    >
      {icon}
      <StyledCreateButtonTypography isMediumScreen={isMediumScreen}>
        {text}
      </StyledCreateButtonTypography>
    </StyledCreateButton>
  );

  const typeButtonMap = (item) => {
    const handleClick = () => handleClickButton(item);
    switch (item.itemType) {
      case Constants.PROBLEM:
        return createButton(
          handleClick,
          <GreenCounter
            bgColor={colorPalette.greenHaze}
            coins={Constants.PLUS_ONE}
            coinColor={colorPalette.white}
            height={isMediumScreen ? Constants.MEDIUM_28 : Constants.LARGE_33}
            textColor={colorPalette.white}
            width={isMediumScreen ? Constants.MEDIUM_28 : Constants.LARGE_33}
          />,
          'Solve'
        );
      case Constants.SOLUTION: {
        const isSolutionAdded = isCurrentConceptSolution(item.itemId._id);
        return createButton(
          () => openAddToConceptModal(item.itemId),
          isSolutionAdded ? (
            <TreeIcon height={22} />
          ) : (
            <StyledTreeIconWithPlus isMediumScreen={isMediumScreen} />
          ),
          isSolutionAdded ? Constants.SOLUTION_ADDED : Constants.ADD_TO_CONCEPT,
          isSolutionAdded
        );
      }
      case Constants.CONTEST:
        return createButton(
          handleClick,
          <GreenCounter
            coins={Constants.PLUS_ONE_THOUSAND}
            coinColor={colorPalette.white}
            bgColor={colorPalette.greenHaze}
            textColor={colorPalette.white}
            width={isMediumScreen ? Constants.MEDIUM_28 : Constants.LARGE_33}
            height={isMediumScreen ? Constants.MEDIUM_28 : Constants.LARGE_33}
          />,
          'Join Contest'
        );
      case Constants.APPLICATION:
        return createButton(
          handleClick,
          <GreenCounter
            coins={Constants.PLUS_TWO}
            coinColor={colorPalette.white}
            bgColor={colorPalette.greenHaze}
            textColor={colorPalette.white}
            width={isMediumScreen ? Constants.MEDIUM_28 : Constants.LARGE_33}
            height={isMediumScreen ? Constants.MEDIUM_28 : Constants.LARGE_33}
          />,
          'Improve'
        );
      case Constants.Invention:
        return createButton(
          handleClick,
          <GreenCounter
            coins={Constants.PLUS_TWO}
            coinColor={colorPalette.white}
            bgColor={colorPalette.greenHaze}
            textColor={colorPalette.white}
            width={isMediumScreen ? Constants.MEDIUM_28 : Constants.LARGE_33}
            height={isMediumScreen ? Constants.MEDIUM_28 : Constants.LARGE_33}
          />,
          'Improve'
        );
      case Constants.PRODUCT:
        return createButton(
          handleClick,
          <GreenCounter
            coins={Constants.PLUS_TWO}
            coinColor={colorPalette.white}
            bgColor={colorPalette.greenHaze}
            textColor={colorPalette.white}
            width={isMediumScreen ? Constants.MEDIUM_28 : Constants.LARGE_33}
            height={isMediumScreen ? Constants.MEDIUM_28 : Constants.LARGE_33}
          />,
          'Improve'
        );
      default:
        return null;
    }
  };

  const getVoteStatus = useCallback((item) => {
    return item.liked ? '1' : item.disliked ? '-1' : null;
  }, []);

  useEffect(() => {
    if (shareStatus && shareStatus != '') {
      const result = shareStatus.split(':')[0];
      showToast(result + '.', {
        style: {
          position: 'absolute',
          bottom: 0,
          left: 77,
          backgroundColor: colorPalette.white,
          color: colorPalette.black
        },
        autoHideDuration: 5000
      });
      dispatch(Actions.shareToSocialMediaSuccess(''));
    }
  }, [dispatch, shareStatus, showToast]);

  useEffect(() => {
    if (user) {
      dispatch(Actions.getSocialAuthKeys(user?.id));
    }
  }, [dispatch, user]);

  return (
    <StyledMainContainer
      isCommunityFeed={isCommunityFeed}
      isEmptyFeed={!feed?.length}
      isMediumScreen={isMediumScreen}
    >
      <StyledSubContainer
        isMediumScreen={isMediumScreen}
        isCommunityFeed={isCommunityFeed}
        isEmptyFeed={!feed?.length}
      >
        {feed?.length > 0 ? (
          feed?.map((item) => (
            <StyledMainBox
              isMediumScreen={isMediumScreen}
              isCommunityFeed={isCommunityFeed}
              key={item.itemId?.id}
              type={item?.itemType}
              onClick={() => handleTitleClick(item)}
            >
              <StyledContainer>
                <StyledTitleText isMediumScreen={isMediumScreen}>
                  {getTitle(item, isTitleReadMore, toggleIsTitleReadMore)}
                </StyledTitleText>
                <ShareBtnSimple
                  item={item ?? user}
                  isMainFeed={true}
                  itemType={item?.itemType}
                  title={item?.itemId?.teaser || item?.itemId?.shortTitle}
                  itemId={item.itemId?._id}
                />
              </StyledContainer>
              <Box>
                <StyledMainImage
                  src={item?.itemId?.files?.[0]?.url || placeHolderImage}
                />
              </Box>
              <StyledTagsContent>
                <StyledChipList
                  list={item.tags}
                  max={10}
                  showAll={showAllTags}
                  onShowAllTagsClick={onShowAllTagsClick}
                  getName={(item) => {
                    const name = (item as TagInfo).name || '';
                    return name;
                  }}
                  getUrl={(item) => {
                    const basePath = getBasePath(item);
                    return `/${basePath}/${
                      (item as TagInfo).key || (item as TagInfo).id
                    }`;
                  }}
                />
              </StyledTagsContent>
              <StyledProfileDescription isMediumScreen={isMediumScreen}>
                {item.itemId?.body && (
                  <Box>
                    {isReadMore
                      ? item.itemId.body
                      : item.itemId.body.slice(0, 260)}
                    {item.itemId?.body?.length > 260 && (
                      <StyledTitle
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleReadMore();
                        }}
                        style={{
                          display: 'inline',
                          cursor: 'pointer',
                          fontSize: isMediumScreen && Constants.SMALL_12
                        }}
                      >
                        {isReadMore ? ' Less...' : ' More...'}
                      </StyledTitle>
                    )}
                  </Box>
                )}
              </StyledProfileDescription>
              {feed.length > 1 && <Divider />}
              <FooterContainer isCommunityFeed={isCommunityFeed}>
                <StyledParentContainer isMediumScreen={isMediumScreen}>
                  <StyledVoteInfoContainer isMediumScreen={isMediumScreen}>
                    <StyledIcons>
                      <Box>
                        <VotingBox>
                          <Vote
                            communityTagId={communityTagId}
                            filters={filters}
                            isCommunityFeed={isCommunityFeed}
                            item={item.itemId}
                            itemType={item.itemType}
                            pagination={pagination}
                            sortType={sortType}
                            voteStatus={getVoteStatus(item)}
                          />
                        </VotingBox>
                      </Box>
                    </StyledIcons>
                    <StyledInfoContainer isCommunityFeed={isCommunityFeed}>
                      <IdentifierIcon
                        type={item.itemType}
                        image={item?.owner?.files[0]?.url}
                        profileKey={item?.owner?.key}
                        isNewsFeed={true}
                      />
                      <StyledTextContainer
                        isMediumScreen={isMediumScreen}
                        isCommunityFeed={isCommunityFeed}
                      >
                        <StyledCreatedText isMediumScreen={isMediumScreen}>
                          Created By:
                        </StyledCreatedText>
                        <LinkView
                          isCommunityFeed={isCommunityFeed}
                          height="0"
                          id={item.owner?.key}
                          title={item.owner?.username}
                          type="profiles"
                        />
                      </StyledTextContainer>
                    </StyledInfoContainer>
                  </StyledVoteInfoContainer>
                  <StyledSubFooterContainer>
                    <StyledDateContainer isMediumScreen={isMediumScreen}>
                      <StyledContainer>
                        <StyledCalendarIcon src={CalendarIcon} alt="calendar" />
                      </StyledContainer>
                      <StyledAddedText isMediumScreen={isMediumScreen}>
                        {formatDate(item.itemId?.createdAt)}
                      </StyledAddedText>
                    </StyledDateContainer>
                    {item.itemType && (
                      <StyledButtonsContainer
                        isMediumScreen={isMediumScreen}
                        isCommunityFeed={isCommunityFeed}
                      >
                        {typeButtonMap(item)}
                      </StyledButtonsContainer>
                    )}
                  </StyledSubFooterContainer>
                </StyledParentContainer>
              </FooterContainer>
            </StyledMainBox>
          ))
        ) : (
          <StyledEmptyFeedText>
            {!feedLoader && 'No Feeds Found.'}
          </StyledEmptyFeedText>
        )}
        {feedLoader && itemsCount > 10 && hasMoreItems && (
          <StyledLinearProgress value={10} />
        )}
      </StyledSubContainer>
      {isAddToConceptModalOpen && (
        <AddToConceptModal
          handleDrawer={handleDrawer}
          isFeed={true}
          node={selectedNode}
          onClose={closeAddToConceptModal}
          open={isAddToConceptModalOpen}
        />
      )}
      {isSolModalOpen && (
        <SolutionModal
          isIdeaMapPopUp
          open={isSolModalOpen}
          onClose={() => setIsSolModalOpen(false)}
          parentNode={selectedNode}
        />
      )}
      {isImproveModalOpen && (
        <ImproveModal
          isImproveModalOpen={isImproveModalOpen}
          modalType={modalType}
          productId={inventionId}
          productKey={inventionKey}
          setIsImproveModalOpen={setIsImproveModalOpen}
          shortTitle={invemtionTitle}
          title={invemtionTitle}
        />
      )}
    </StyledMainContainer>
  );
};

export default ListView;
