import React from 'react';
import { Box } from '@mui/material';
import { EntityIcon } from 'components/TreeGraph/EntityIcon';
import { Node } from 'interface/common';
import { GetGenerateImageLoader } from 'redux-state/selectors';
import { useIsMediumScreen } from 'theme';
import { Constants } from 'utilities/constants';
import {
  AttachedIcon,
  OwnerImageContainer,
  StyledBgImageContainer,
  StyledBgImageContent,
  StyledCircularProgress,
  StyledHeaderMainContainer,
  StyledOwnerImage,
  TypeAvatar
} from './StyledComponents';
import { RenderActionButton } from '../RenderActionButton';
import { RenderAddToConceptBtn } from '../RenderAddToConceptBtn';

interface DrawerHeaderProps {
  handleClick?: () => void;
  image: string;
  isCurrentConceptSolution?: boolean;
  item?: Node;
  openAddToConceptModal?: () => void;
}

export const DrawerHeader: React.FC<DrawerHeaderProps> = ({
  handleClick,
  image,
  isCurrentConceptSolution = false,
  item,
  openAddToConceptModal
}) => {
  const isMediumScreen = useIsMediumScreen();
  const aiImageLoader = GetGenerateImageLoader();
  const { type, ownerImage } = item;

  return (
    <Box sx={{ height: '20.625rem' }}>
      <StyledHeaderMainContainer>
        <StyledBgImageContainer>
          <TypeAvatar loader={aiImageLoader} src={image} />
          {aiImageLoader && <StyledCircularProgress size={50} />}
        </StyledBgImageContainer>
        <StyledBgImageContent>
          <AttachedIcon type={type}>
            <EntityIcon type={type} isDetailDrawer />
          </AttachedIcon>
          {!isMediumScreen && type !== Constants.SOLUTION && (
            <Box>
              <RenderActionButton itemType={type} onClick={handleClick} />
            </Box>
          )}
          {!isMediumScreen && type === Constants.SOLUTION && (
            <Box>
              <RenderAddToConceptBtn
                disabled={isCurrentConceptSolution}
                itemType={type}
                onClick={openAddToConceptModal}
              />
            </Box>
          )}
        </StyledBgImageContent>
      </StyledHeaderMainContainer>
      <OwnerImageContainer>
        <StyledOwnerImage src={ownerImage} />
      </OwnerImageContainer>
    </Box>
  );
};
