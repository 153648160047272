import React, { useMemo } from 'react';
import Slider from 'react-slick';
import { Box } from '@mui/material';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import {
  StyledSliderCard,
  StyledSliderCardContent,
  StyledSliderContentBox,
  StyledSliderContentTypography,
  StyledSliderInfoAvatar,
  StyledSliderInfoBox,
  StyledSliderInfoName
} from './StyledComponents';

interface Item {
  avatar: string;
  name: string;
  text: string;
}

interface CarouselSliderProps {
  items: Item[];
}

export const CarouselSlider: React.FC<CarouselSliderProps> = ({ items }) => {
  const settings = useMemo(
    () => ({
      dots: true,
      infinite: true,
      speed: 800,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      arrows: false,
      fade: true
    }),
    []
  );

  return (
    <Slider {...settings}>
      {items.map((item, index) => (
        <Box key={index}>
          <StyledSliderCard>
            <StyledSliderCardContent>
              <StyledSliderInfoBox>
                <StyledSliderInfoAvatar src={item.avatar} />
                <StyledSliderInfoName>{item.name}</StyledSliderInfoName>
              </StyledSliderInfoBox>

              <StyledSliderContentBox>
                <StyledSliderContentTypography variant="body1">
                  {item.text}
                </StyledSliderContentTypography>
              </StyledSliderContentBox>
            </StyledSliderCardContent>
          </StyledSliderCard>
        </Box>
      ))}
    </Slider>
  );
};
