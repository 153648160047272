import React, { useContext, useState } from 'react';
import { styled, useTheme, alpha } from '@mui/material/styles';

import { PsFormControl, ShortEvent } from 'components/common/PsFormControl';
import Radio from 'components/Radio';
import { ModalComponentsKeys } from 'contexts/ModalContext';
import { ModalDataContext } from 'contexts/ModalDataContext';
import styles from 'modals/ModalWindow.module.scss';
import { makeStyles } from 'tss-react/mui';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  return {
    root: {
      borderRadius: 8,
      margin: '8px 0',
      border: `solid 1px ${alpha(theme.palette.primary.main, 0.15)}`,
      background: 'rgb(248, 245, 255, 0.5)',
      color: theme.palette.primary.main,
      '&:hover': {
        border: `solid 1px ${alpha(theme.palette.primary.main, 0.5)}`
      }
    }
  };
});

const options = [
  //{ label: 'Problem', value: ModalComponentsKeys.addNewProblem },
  { label: 'Solution', value: ModalComponentsKeys.addNewSolution },
  { label: 'Invention', value: ModalComponentsKeys.addNewInvention }
];

const SelectType: React.FC = () => {
  const { values, updateField } = useContext(ModalDataContext);
  const [selectedEntity, setSelectedEntity] = useState<string | undefined>();
  const { classes } = useStyles();

  const handleEntityChange = (e: ShortEvent) => {
    setSelectedEntity(e.target.value);
    updateField('brainObject', e.target.value);
  };

  return (
    <div className={styles.stepWrapper}>
      <PsFormControl name="brainObject" label="What to create next?">
        <RadioGroup
          aria-label="entity"
          name="entity"
          onChange={handleEntityChange}
          value={selectedEntity}
        >
          {options.map(({ label, value }) => {
            return (
              <FormControlLabel
                key={value}
                classes={classes}
                value={value}
                control={<Radio />}
                label={label}
              />
            );
          })}
        </RadioGroup>
      </PsFormControl>
    </div>
  );
};

export default SelectType;
