import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useDispatch } from 'react-redux';
import { Delete } from '@mui/icons-material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { CircularProgress, Grid, Tooltip } from '@mui/material';
import { Application } from 'components/CardApplication';
import { Dropzone } from 'components/common/Dropzone';
import Config from 'config/config';
import AuthContext from 'contexts/AuthContext';
import { extractReceiptUsingOCR, readTextFromPdf } from 'helpers';
import { ActionSection } from 'modals/Common/ActionSection';
import { BaseModal } from 'modals/Common/BaseModal';
import { ContentSection } from 'modals/Common/ContentSection';
import { HeaderSection } from 'modals/Common/HeaderSection';
import Actions from 'redux-state/actions';
import { GetFilePatentLoader } from 'redux-state/selectors';
import { colorPalette } from 'theme';
import { Constants } from 'utilities/constants';
import {
  ActionMainBox,
  BoundingBox,
  HelpTypography,
  MainBox,
  PdfBox,
  PdfIconBackground,
  PdfIconBox,
  PdfSubTitleTypography,
  PdfTitleBox,
  PdfTitleTypography,
  StyledDivider,
  StyledIconButton,
  StyledSubmitButton,
  SubTitleTypography,
  TitleTypography,
  ValueTitle,
  ValueTypography
} from './StyledComponents';

interface UploadFilingReceiptModalProps {
  open?: boolean;
  concept?: Application;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  profileId?: string | number;
  pagination?: { page: number; perPage: number };
}

type PdfReaderTypes = {
  receiptNumber: string;
  filingDate: string;
};

export const UploadFilingReceiptModal: React.FC<
  UploadFilingReceiptModalProps
> = ({ concept, open, profileId, pagination, setOpen }) => {
  const { user } = useContext(AuthContext);
  const [date, setDate] = useState<Date>();
  const [nextFilingDate, setNextFilingDate] = useState<Date>();
  const [file, setFile] = useState<File>();
  const [loading, setLoading] = useState(false);
  const [patentNumber, setPatentNumber] = useState<string>('');

  const patentFileLoader = GetFilePatentLoader();

  const dispatch = useDispatch();

  const resetFields = useCallback(() => {
    setDate(undefined);
    setPatentNumber('');
  }, []);

  const handleDelete = useCallback(() => {
    setFile(undefined);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    setFile(undefined);
    resetFields();
  }, [setOpen]);

  const onHelpClick = useCallback(() => {
    window.open(Config.NEXT_CALENDLY_LINK, '_blank');
  }, [setOpen]);

  const formatDate = useCallback((date) => {
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  }, []);

  const extractUsingOCR = (file: File) => {
    extractReceiptUsingOCR(file)
      .then((responseText: PdfReaderTypes | undefined) => {
        if (!responseText) return;
        const { receiptNumber = '', filingDate = '' } = responseText;
        setPatentNumber(receiptNumber);
        setDate(filingDate as unknown as Date);
      })
      .catch((err) => {
        console.log('extractUsingOCR ~ error:', err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFileChange = (file: File) => {
    resetFields();
    setLoading(true);
    readTextFromPdf(file)
      .then((responseText: PdfReaderTypes | undefined) => {
        if (!responseText) return;
        const { receiptNumber = '', filingDate = '' } = responseText;
        setPatentNumber(receiptNumber);
        setDate(filingDate as unknown as Date);
        setLoading(false);
      })
      .catch((err) => {
        if (err === Constants.NOT_ABLE_TO_READ_TEXT) {
          extractUsingOCR(file);
        } else {
          setLoading(false);
          console.log('Error in Reading text from PDF');
          console.log(err?.message ?? 'Internal error', 'error');
        }
      });
  };

  const onSubmit = async () => {
    const validations = [
      { condition: !patentNumber, message: Constants.PLEASE_ADD_PATENT_NUMBER }
    ];
    if (user) {
      for (const validation of validations) {
        if (validation.condition) {
          window.alert(validation.message);
          return;
        }
      }
      dispatch(
        Actions.eFilePatent({
          id: concept.id,
          number: patentNumber,
          date,
          pagination,
          profileId
        })
      );
    } else {
      dispatch(Actions.openAuthModal(true));
    }
  };

  useEffect(() => {
    if (!patentFileLoader) {
      handleClose();
    }
  }, [patentFileLoader, handleClose]);

  useEffect(() => {
    if (file) {
      handleFileChange(file);
    }
  }, [file]);

  useEffect(() => {
    if (date) {
      const dateParts = date.toString().split('/');
      const nextFilingDate = new Date(
        Number(dateParts[2]),
        Number(dateParts[0]) - 1,
        Number(dateParts[1])
      );
      nextFilingDate.setFullYear(nextFilingDate.getFullYear() + 1);
      setNextFilingDate(nextFilingDate);
    }
  }, [date]);

  const title = Constants.UPLOAD_PDF_FILING_RECEIPT;
  const isPatentApproved = !!(patentNumber && date);

  return (
    <BaseModal open={open} onClose={handleClose}>
      <HeaderSection>
        <TitleTypography>{title}</TitleTypography>
        <SubTitleTypography>{concept?.title}</SubTitleTypography>

        <StyledDivider />
      </HeaderSection>
      <ContentSection>
        <Dropzone
          setFiles={setFile}
          maxFiles={1}
          fileTypes={['application/pdf']}
        />
        {file && (
          <MainBox>
            <BoundingBox>
              <PdfBox>
                <PdfIconBox sx={{ marginRight: '.625rem' }}>
                  <PdfIconBackground>
                    <PictureAsPdfIcon
                      sx={{ color: colorPalette.darkShadowGrey }}
                    />
                  </PdfIconBackground>
                </PdfIconBox>
                <PdfTitleBox>
                  <PdfTitleTypography>{file.name}</PdfTitleTypography>
                  {!loading && (
                    <PdfSubTitleTypography isPatentApproved={isPatentApproved}>
                      {isPatentApproved
                        ? Constants.APPROVED
                        : Constants.REJECTED}
                    </PdfSubTitleTypography>
                  )}
                </PdfTitleBox>
              </PdfBox>
              {loading ? (
                <CircularProgress size="1.375rem" />
              ) : (
                <StyledIconButton size="small" disabled={patentFileLoader}>
                  <Tooltip
                    onClick={handleDelete}
                    title={Constants.DELETE}
                    placement="bottom"
                  >
                    <Delete sx={{ color: colorPalette.purple }} />
                  </Tooltip>
                </StyledIconButton>
              )}
            </BoundingBox>
          </MainBox>
        )}
        <Grid container mt={3}>
          <Grid item sm={12} md={6}>
            <ValueTitle>{`${Constants.PATENT_NUMBER}:`}</ValueTitle>
            <ValueTypography>
              {patentNumber ? patentNumber : '--'}
            </ValueTypography>
          </Grid>
          <Grid item sm={12} md={6}>
            <ValueTitle>{`${Constants.FILING_DATE}:`}</ValueTitle>
            <ValueTypography>{date ? date.toString() : '--'}</ValueTypography>
          </Grid>
          <Grid item sm={12} md={6}>
            <ValueTitle>{`${Constants.NEXT_FILING_DUE_DATE}:`}</ValueTitle>
            <ValueTypography>
              {nextFilingDate ? formatDate(nextFilingDate) : '--'}
            </ValueTypography>
          </Grid>
        </Grid>
        <StyledDivider />
      </ContentSection>
      <ActionSection>
        <ActionMainBox>
          <HelpTypography onClick={onHelpClick}>
            Get help from a patent attorney
          </HelpTypography>
          <StyledSubmitButton
            onClick={onSubmit}
            disabled={patentFileLoader || !isPatentApproved}
          >
            {patentFileLoader ? (
              <CircularProgress size="1.375rem" />
            ) : (
              Constants.SUBMIT
            )}
          </StyledSubmitButton>
        </ActionMainBox>
      </ActionSection>
    </BaseModal>
  );
};
