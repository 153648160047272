import * as React from 'react';
export const ContestWinRectangle = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={243}
    height={162}
    fill="none"
    {...props}
  >
    <g filter="url(#a)">
      <rect width={209} height={127} x={17} y={17.398} fill="#fff" rx={8} />
    </g>
    <rect
      width={87.46}
      height={87.46}
      x={74.678}
      y={37.035}
      fill="#F8F5FF"
      stroke="#E0D3FF"
      rx={5.5}
    />
    <path
      fill="#6BDDDD"
      d="m96.9 96.867 2.925-2.652c-3.666-5.286-5.118-11.951-3.69-18.393l.257-1.16 1.532.499a.472.472 0 0 0 .493-.129.446.446 0 0 0 .084-.493l-2.03-4.776a.465.465 0 0 0-.287-.266.46.46 0 0 0-.393.044l-4.45 2.672a.453.453 0 0 0-.225.386.457.457 0 0 0 .21.4.502.502 0 0 0 .115.055l1.213.394-.237.972c-1.922 7.888-.144 16.068 4.483 22.447Z"
    />
    <path
      fill="#A4C9FF"
      d="M133.517 99.572c-5.643 4.295-13.043 5.938-20.054 4.125l-1.15-.298.551-1.513a.479.479 0 0 0 .018-.265.481.481 0 0 0-.129-.233.446.446 0 0 0-.49-.1l-4.844 1.861a.47.47 0 0 0-.275.278.457.457 0 0 0 .03.394l2.515 4.542a.452.452 0 0 0 .377.237.452.452 0 0 0 .408-.196.487.487 0 0 0 .059-.113l.436-1.199.963.271c8.263 2.321 16.997.524 23.694-4.479l-2.109-3.312Z"
    />
    <path
      fill="#F9A7A7"
      d="m142.197 86.033-.257 1.159-1.531-.498a.473.473 0 0 0-.494.129.446.446 0 0 0-.083.492l2.03 4.777a.462.462 0 0 0 .286.265.46.46 0 0 0 .393-.044l4.451-2.671a.452.452 0 0 0 .225-.386.453.453 0 0 0-.211-.4.472.472 0 0 0-.115-.055l-1.213-.395.237-.971c1.955-8.024.084-16.35-4.721-22.776l-2.712 2.943c3.685 5.292 5.147 11.973 3.715 18.43Z"
    />
    <path
      fill="#4671C6"
      d="M102.6 64.225c5.402-5.393 13.199-7.97 20.827-6.556l1.168.217-.445 1.547a.472.472 0 0 0 .146.49.447.447 0 0 0 .496.066l4.703-2.196a.468.468 0 0 0 .254-.295.461.461 0 0 0-.057-.392l-2.826-4.355a.445.445 0 0 0-.393-.21.458.458 0 0 0-.444.34l-.351 1.227-.98-.203c-9.017-1.867-18.244 1.036-24.671 7.363l2.573 2.957Z"
    />
    <path
      fill="#FFEA92"
      d="M123.859 94.963h-10.9a.288.288 0 0 1-.287-.287 2.582 2.582 0 0 1 2.581-2.582h6.311a2.582 2.582 0 0 1 2.582 2.582.287.287 0 0 1-.287.287ZM120.296 92.094v-5.956a9.213 9.213 0 0 1-3.776 0v5.956h3.776Z"
    />
    <path
      fill="#3762CC"
      d="M123.859 95.455h-10.9a.78.78 0 0 1-.779-.779 3.077 3.077 0 0 1 3.073-3.073h6.311a3.077 3.077 0 0 1 3.073 3.073.779.779 0 0 1-.778.78Zm-10.685-.984h10.469a2.091 2.091 0 0 0-2.079-1.884h-6.31a2.092 2.092 0 0 0-2.08 1.884Z"
    />
    <path
      fill="#3762CC"
      d="M120.296 92.586h-3.777a.494.494 0 0 1-.492-.492v-5.956a.499.499 0 0 1 .182-.382.494.494 0 0 1 .411-.1 8.776 8.776 0 0 0 3.575 0 .492.492 0 0 1 .593.482v5.956c0 .272-.22.492-.492.492Zm-3.284-.984h2.792v-4.877a9.739 9.739 0 0 1-2.792 0v4.877Z"
    />
    <path
      fill="#FFEA92"
      d="M118.408 86.334a9.179 9.179 0 0 1-9.179-9.18v-9.682a.931.931 0 0 1 .93-.93h16.497a.93.93 0 0 1 .931.93v9.683a9.18 9.18 0 0 1-9.179 9.179Z"
    />
    <path
      fill="#FFEA92"
      d="M109.229 77.155v-8.312h-2.594c-.757 0-1.371.588-1.371 1.313v1.754c0 .214.054.424.158.613l2.713 4.921c.227.412.664.674 1.147.696a9.265 9.265 0 0 1-.053-.985Zm-.002-1.598-2.078-3.77V70.65h2.078v4.908Zm20.952-6.714h-2.592v1.806h2.078v1.139l-2.078 3.768v1.599c0 .329-.018.658-.053.985a1.376 1.376 0 0 0 1.145-.696l2.712-4.92c.104-.188.159-.4.159-.614v-1.754c0-.725-.614-1.313-1.371-1.313Z"
    />
    <path
      fill="#3762CC"
      d="M118.409 86.826c-5.332 0-9.671-4.339-9.671-9.671v-9.683a1.425 1.425 0 0 1 1.422-1.422h16.498a1.422 1.422 0 0 1 1.422 1.422v9.683c.001 5.332-4.338 9.67-9.671 9.67Zm-8.249-19.793a.439.439 0 0 0-.438.438v9.683c0 4.79 3.897 8.687 8.687 8.687 4.79 0 8.687-3.896 8.687-8.686v-9.683a.438.438 0 0 0-.438-.438H110.16Z"
    />
    <path
      fill="#3762CC"
      d="M109.282 78.632h-.022a1.877 1.877 0 0 1-1.556-.95l-2.713-4.922a1.781 1.781 0 0 1-.22-.85v-1.754c0-.996.836-1.805 1.864-1.805h2.594a.49.49 0 0 1 .492.492v8.311c0 .306.017.62.051.933a.502.502 0 0 1-.124.382.488.488 0 0 1-.366.163Zm-2.647-9.297c-.485 0-.879.368-.879.82v1.754c0 .13.033.26.097.376l2.713 4.921a.798.798 0 0 0 .175.217 8.037 8.037 0 0 1-.004-.269v-1.467l-2.019-3.662a.493.493 0 0 1-.061-.238v-1.139a.493.493 0 0 1 .492-.492h1.588v-.821h-2.102Zm1.006 2.326 1.094 1.984V71.14h-1.094v.52Zm19.893 6.97a.493.493 0 0 1-.49-.544c.034-.314.051-.627.051-.933v-1.599c0-.083.021-.164.061-.237l2.016-3.657v-.52h-1.585a.492.492 0 0 1-.492-.493v-1.805a.493.493 0 0 1 .492-.492h2.591c1.028 0 1.864.81 1.864 1.805v1.753c0 .296-.076.59-.22.85l-2.712 4.922a1.88 1.88 0 0 1-1.576.95Zm.545-2.949v1.472c0 .089-.001.178-.004.267a.826.826 0 0 0 .173-.215l2.712-4.921a.78.78 0 0 0 .098-.376v-1.754c0-.452-.395-.82-.88-.82h-2.099v.82h1.585a.49.49 0 0 1 .492.493v1.14a.492.492 0 0 1-.061.237l-2.016 3.657Z"
    />
    <path
      fill="#4671C6"
      d="M98.188 69.853a9.307 9.307 0 1 0 0-18.615 9.307 9.307 0 0 0 0 18.615Z"
    />
    <path
      fill="#A4C9FF"
      d="M101.817 65.936h-7.256v-1.479a3.132 3.132 0 0 1 3.131-3.132h.993a3.132 3.132 0 0 1 3.132 3.132v1.479Z"
    />
    <path
      fill="#3762CC"
      d="M101.816 66.428h-7.255a.492.492 0 0 1-.493-.492v-1.48a3.628 3.628 0 0 1 3.624-3.623h.993a3.628 3.628 0 0 1 3.624 3.624v1.479a.49.49 0 0 1-.493.492Zm-6.763-.984h6.271v-.987a2.642 2.642 0 0 0-2.64-2.64h-.992a2.643 2.643 0 0 0-2.64 2.64v.987Z"
    />
    <path
      fill="#F9CFCF"
      d="M98.189 61.325a3.629 3.629 0 1 0 0-7.257 3.629 3.629 0 0 0 0 7.257Z"
    />
    <path
      fill="#3762CC"
      d="M98.188 61.818a4.125 4.125 0 0 1-4.12-4.12 4.125 4.125 0 0 1 4.12-4.12 4.125 4.125 0 0 1 4.12 4.12 4.125 4.125 0 0 1-4.12 4.12Zm0-7.257a3.14 3.14 0 0 0-3.135 3.136 3.14 3.14 0 0 0 3.135 3.136 3.14 3.14 0 0 0 3.136-3.136 3.14 3.14 0 0 0-3.136-3.136Z"
    />
    <path
      fill="#3762CC"
      d="M98.188 70.344c-5.404 0-9.8-4.395-9.8-9.799 0-5.403 4.396-9.799 9.8-9.799 5.403 0 9.799 4.396 9.799 9.8 0 5.402-4.396 9.798-9.8 9.798Zm0-18.614c-4.86 0-8.815 3.955-8.815 8.815 0 4.861 3.954 8.815 8.815 8.815 4.861 0 8.815-3.954 8.815-8.815 0-4.86-3.954-8.815-8.815-8.815Z"
    />
    <path
      fill="#A4C9FF"
      d="M138.628 110.291a9.307 9.307 0 0 0 0-18.614 9.307 9.307 0 1 0 0 18.614Z"
    />
    <path
      fill="#E0EBFC"
      d="M142.254 106.375h-7.256v-1.479a3.132 3.132 0 0 1 3.132-3.132h.992a3.132 3.132 0 0 1 3.132 3.132v1.479Z"
    />
    <path
      fill="#3762CC"
      d="M142.254 106.866h-7.256a.492.492 0 0 1-.492-.492v-1.479a3.628 3.628 0 0 1 3.624-3.624h.992a3.628 3.628 0 0 1 3.624 3.624v1.479a.492.492 0 0 1-.492.492Zm-6.764-.984h6.272v-.987a2.643 2.643 0 0 0-2.64-2.639h-.993a2.642 2.642 0 0 0-2.639 2.639v.987Z"
    />
    <path
      fill="#F9CFCF"
      d="M138.626 101.764a3.628 3.628 0 1 0 0-7.255 3.628 3.628 0 0 0 0 7.255Z"
    />
    <path
      fill="#3762CC"
      d="M138.626 102.256a4.124 4.124 0 0 1-4.12-4.12 4.125 4.125 0 0 1 4.12-4.12 4.125 4.125 0 0 1 4.12 4.12 4.124 4.124 0 0 1-4.12 4.12Zm0-7.256a3.14 3.14 0 0 0-3.136 3.136 3.14 3.14 0 0 0 3.136 3.136 3.14 3.14 0 0 0 3.136-3.136A3.14 3.14 0 0 0 138.626 95Z"
    />
    <path
      fill="#3762CC"
      d="M138.627 110.783c-5.403 0-9.799-4.396-9.799-9.799s4.396-9.8 9.799-9.8c5.404 0 9.8 4.397 9.8 9.8 0 5.403-4.396 9.799-9.8 9.799Zm0-18.614c-4.86 0-8.815 3.954-8.815 8.815 0 4.86 3.955 8.815 8.815 8.815 4.861 0 8.815-3.955 8.815-8.815 0-4.86-3.954-8.815-8.815-8.815Z"
    />
    <path
      fill="#F9A7A7"
      d="M138.628 69.853a9.307 9.307 0 0 0 0-18.615 9.307 9.307 0 1 0 0 18.615Z"
    />
    <path
      fill="#6BDDDD"
      d="M134.998 65.936h7.256v-1.479a3.132 3.132 0 0 0-3.132-3.132h-.992a3.132 3.132 0 0 0-3.132 3.132v1.479Z"
    />
    <path
      fill="#3762CC"
      d="M142.254 66.428h-7.256a.49.49 0 0 1-.492-.492v-1.48a3.628 3.628 0 0 1 3.624-3.623h.992a3.628 3.628 0 0 1 3.624 3.624v1.479a.493.493 0 0 1-.492.492Zm-6.764-.984h6.272v-.987a2.643 2.643 0 0 0-2.64-2.64h-.993a2.643 2.643 0 0 0-2.639 2.64v.987Z"
    />
    <path
      fill="#F9CFCF"
      d="M138.626 61.325a3.628 3.628 0 1 0 0-7.256 3.628 3.628 0 0 0 0 7.256Z"
    />
    <path
      fill="#3762CC"
      d="M138.626 61.818a4.125 4.125 0 0 1-4.12-4.12 4.125 4.125 0 0 1 4.12-4.12 4.125 4.125 0 0 1 4.12 4.12 4.125 4.125 0 0 1-4.12 4.12Zm0-7.257a3.14 3.14 0 0 0-3.136 3.136 3.14 3.14 0 0 0 3.136 3.136 3.14 3.14 0 0 0 3.136-3.136 3.14 3.14 0 0 0-3.136-3.136Z"
    />
    <path
      fill="#3762CC"
      d="M138.627 70.344c-5.403 0-9.799-4.395-9.799-9.799 0-5.403 4.396-9.799 9.799-9.799 5.404 0 9.8 4.396 9.8 9.8 0 5.402-4.396 9.798-9.8 9.798Zm0-18.614c-4.86 0-8.815 3.955-8.815 8.815 0 4.861 3.955 8.815 8.815 8.815 4.861 0 8.815-3.954 8.815-8.815 0-4.86-3.954-8.815-8.815-8.815Z"
    />
    <path
      fill="#6BDDDD"
      d="M98.188 110.291a9.307 9.307 0 1 0 0-18.614 9.307 9.307 0 0 0 0 18.614Z"
    />
    <path
      fill="#F9A7A7"
      d="M94.56 106.375h7.257v-1.479a3.132 3.132 0 0 0-3.132-3.132h-.993a3.132 3.132 0 0 0-3.131 3.132v1.479Z"
    />
    <path
      fill="#3762CC"
      d="M101.816 106.866h-7.255a.493.493 0 0 1-.493-.492v-1.479a3.628 3.628 0 0 1 3.624-3.624h.993a3.629 3.629 0 0 1 3.624 3.624v1.479a.492.492 0 0 1-.493.492Zm-6.763-.984h6.271v-.987a2.642 2.642 0 0 0-2.64-2.639h-.992a2.643 2.643 0 0 0-2.64 2.639v.987Z"
    />
    <path
      fill="#F9CFCF"
      d="M98.189 101.764a3.628 3.628 0 1 0 0-7.257 3.628 3.628 0 0 0 0 7.257Z"
    />
    <path
      fill="#3762CC"
      d="M98.188 102.256a4.125 4.125 0 0 1-4.12-4.12 4.125 4.125 0 0 1 4.12-4.12 4.125 4.125 0 0 1 4.12 4.12 4.124 4.124 0 0 1-4.12 4.12Zm0-7.256a3.14 3.14 0 0 0-3.135 3.136 3.14 3.14 0 0 0 3.135 3.136 3.14 3.14 0 0 0 3.136-3.136A3.14 3.14 0 0 0 98.188 95Z"
    />
    <path
      fill="#3762CC"
      d="M98.188 110.783c-5.404 0-9.8-4.396-9.8-9.799s4.396-9.8 9.8-9.8c5.403 0 9.799 4.397 9.799 9.8 0 5.403-4.396 9.799-9.8 9.799Zm0-18.614c-4.86 0-8.815 3.954-8.815 8.815 0 4.861 3.954 8.815 8.815 8.815 4.861 0 8.815-3.954 8.815-8.815 0-4.86-3.954-8.815-8.815-8.815Zm22.851-11.042c-.245 0-.487-.06-.704-.175l-1.683-.885a.523.523 0 0 0-.49 0l-1.682.885a1.504 1.504 0 0 1-1.591-.115 1.498 1.498 0 0 1-.601-1.478l.321-1.873a.537.537 0 0 0-.151-.466l-1.362-1.327a1.503 1.503 0 0 1-.382-1.549c.179-.55.647-.945 1.22-1.028l1.881-.274a.534.534 0 0 0 .396-.287l.841-1.705c.257-.52.776-.842 1.355-.842a1.5 1.5 0 0 1 1.355.842l.842 1.705a.518.518 0 0 0 .396.288l1.881.273a1.502 1.502 0 0 1 1.22 1.028 1.503 1.503 0 0 1-.382 1.549l-1.362 1.327a.534.534 0 0 0-.151.466l.321 1.874a1.498 1.498 0 0 1-.601 1.478c-.257.187-.568.288-.887.289Zm-2.632-2.105c.242 0 .483.058.703.174l1.683.885c.181.095.388.08.554-.04a.511.511 0 0 0 .21-.515l-.322-1.874a1.508 1.508 0 0 1 .435-1.337l1.361-1.327a.513.513 0 0 0 .133-.54.516.516 0 0 0-.425-.358l-1.881-.273a1.507 1.507 0 0 1-1.138-.827l-.841-1.704a.51.51 0 0 0-.472-.294.517.517 0 0 0-.472.294l-.841 1.704a1.519 1.519 0 0 1-1.138.827l-1.881.273a.515.515 0 0 0-.425.358.518.518 0 0 0 .133.54l1.361 1.327a1.507 1.507 0 0 1 .434 1.337l-.321 1.874a.516.516 0 0 0 .21.515c.165.12.373.135.554.04l1.683-.885a1.51 1.51 0 0 1 .703-.174Z"
    />
    <defs>
      <filter
        id="a"
        width={243}
        height={161}
        x={0}
        y={0.398}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={8.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5175_3268"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_5175_3268"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
