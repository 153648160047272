import React, { useCallback, useContext, useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { PsFormControl, ShortEvent } from 'components/common/PsFormControl';
import { PsSelect, PsOptionType } from 'components/common/PsSelect';
import { PsInputLabel } from 'components/common/PsInputLabel';
import { PsInput } from 'components/common/PsInput';
import { PsButton } from 'components/common/PsButton';
import { Solution } from 'components/CardSolution';
import { DataContext } from 'contexts/DataContext';
import { PsTheme } from '../../theme';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { makeStyles } from 'tss-react/mui';

export type SolutionRelation = {
  _id?: string | number;
  id: string | number;
  text: string;
  title?: string;
};

type ClassKey =
  | 'root'
  | 'list'
  | 'listItem'
  | 'selectHolder'
  | 'inputHolder'
  | 'buttonRow';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      paddingTop: 10
    },
    list: {
      margin: '10px 0',
      padding: '0 0 0 30px'
    },
    listItem: {
      lineHeight: '24px',
      paddingBottom: 5,
      '& > button': {
        marginTop: -5,
        marginLeft: 10
      },
      '& a': {
        color: psTheme.palette.primary.main,
        textDecoration: 'none'
      },
      '& a:hover': {
        textDecoration: 'underline'
      }
    },
    selectHolder: {
      marginBottom: 0
    },
    inputHolder: {
      marginBottom: 0,
      paddingRight: 10,
      '& > div': {
        marginTop: 0
      }
    },
    buttonRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      paddingTop: 10
    }
  };
});

export type RelationsFormProps = {
  solution: Solution;
  title: string;
  relations: Array<SolutionRelation>;
  onChange?: (newList: Array<SolutionRelation>) => void;
  options?: string;
};

const RelationsFormView = ({
  solution,
  title,
  relations: initRelations = [],
  onChange
}: RelationsFormProps) => {
  const { dataProvider } = useContext(DataContext);
  const [relations, setRelations] =
    useState<Array<SolutionRelation>>(initRelations);
  const [relationsOptions, setRelationsOptions] = useState<Array<PsOptionType>>(
    []
  );
  const [currentId, setCurrentId] = useState<string>('');
  const [currentText, setCurrentText] = useState<string>('');
  const { classes } = useStyles();

  useEffect(() => {
    if (!solution) {
      return;
    }
    let filter = {};
    if (solution.problem) {
      filter = {
        problem: solution.problem,
        $custom: { type: 'relevantSolutions', skip: solution.id }
      };
    }
    if (solution.parentSolution) {
      filter = {
        parentSolution: solution.parentSolution,
        $custom: { type: 'relevantSolutions', skip: solution.id }
      };
    }
    dataProvider
      .getList<Solution>('solutions', {
        pagination: { page: 1, perPage: 50000 },
        sort: { field: 'name', order: 'ASC' },
        filter
      })
      .then(({ data = [] }) => {
        const list: Array<PsOptionType> = data.map((item) => ({
          value: `${item.id}`,
          raw: `${item.key}`,
          label: item.title || '(empty)'
        }));
        setRelationsOptions(list);
      })
      .catch(() => {
        setRelationsOptions([]);
      });
  }, [solution]);

  const onItemAdd = (e: React.FormEvent) => {
    e.preventDefault();
    if (!currentId || !currentText) {
      return;
    }
    const newItem: SolutionRelation = {
      id: currentId,
      text: currentText
    };
    const newList = [...relations, newItem];
    setRelations(newList);
    if (onChange) {
      onChange(newList);
    }
    setCurrentId('');
    setCurrentText('');
  };

  const onItemRemove = (index: number) => {
    const newList = [...relations];
    newList.splice(index, 1);
    setRelations(newList);
    if (onChange) {
      onChange(newList);
    }
  };

  const onIdChange = (event: ShortEvent) => {
    const { value } = event.target;
    setCurrentId(value);
  };

  const onTextChange = (e: ShortEvent) => {
    setCurrentText(e.target.value);
  };

  return (
    <div className={classes.root}>
      <PsInputLabel
        label={
          <Typography
            style={{ fontSize: 13, fontWeight: 600, color: '#1D1D1B' }}
          >
            Related Solutions
          </Typography>
        }
        labelLight
      />

      <ul className={classes.list}>
        {relations.map((relation, index) => {
          const onRemoveClick = (e: React.MouseEvent) => {
            e.preventDefault();
            onItemRemove(index);
          };

          const option = relationsOptions.find(
            (opt) => opt.value === relation.id
          );
          if (!option) {
            return null;
          }

          return (
            <li
              className={classes.listItem}
              key={`${relation.id}+${relation.text}`}
            >
              <Link to={`/solutions/${option.raw}`}>
                <a target="_blank">{option.label}</a>
              </Link>{' '}
              - {relation.text}
              <IconButton size="small" title="Remove" onClick={onRemoveClick}>
                <DeleteOutlineIcon color="primary" fontSize="small" />
              </IconButton>
            </li>
          );
        })}
      </ul>

      <form onSubmit={onItemAdd}>
        <PsFormControl
          className={classes.selectHolder}
          placeholder="Select other solutions related to this"
          labelLight
        >
          <PsSelect
            name="tags"
            maxMenuHeight={270}
            onChange={onIdChange}
            options={relationsOptions}
            value={currentId}
          />
        </PsFormControl>
        <div className={classes.buttonRow}>
          <PsFormControl
            className={classes.inputHolder}
            placeholder={`is related to ${title} solution by`}
            labelLight
          >
            <PsInput name="text" onChange={onTextChange} value={currentText} />
          </PsFormControl>
          <PsButton type="submit" disabled={!currentId || !currentText} small>
            Add
          </PsButton>
        </div>
      </form>
    </div>
  );
};

export const RelationsForm = styled(RelationsFormView)({});

export default RelationsForm;
