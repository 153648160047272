import React, { useContext, useEffect, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import { Outlet } from 'react-router-dom';

import { TermsPopup } from 'components/auth/TermsPopup';
import { WarningDialog } from 'components/common/WarningDialog';
import { DrawModal } from 'components/draw/DrawModal';

import { AuthContext } from 'contexts/AuthContext';
import { DataContext } from 'contexts/DataContext';
import { ModalContext } from 'contexts/ModalContext';

import { RootModal } from 'modals/RootModal';
import { SimpleModal } from 'modals/SimpleModal';

import useRouter from 'hooks/useRouter';

import { updateTabHistory } from 'helpers/tabHistory';
import { Footer } from 'layout/Footer';
import { Header } from 'layout/Header';
import { PsListMenu } from 'layout/PsListMenu';

export type LayoutProps = {
  children: React.ReactElement | React.ReactElement[];
};

export const Layout = ({ children }: LayoutProps) => {
  const { alertContent, setAlertContent } = useContext(DataContext);
  const { auth, user } = useContext(AuthContext);
  const { openModal } = useContext(ModalContext);
  const [showAlert, setShowAlert] = useState(!!alertContent);
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();
  const ModalProps = { keepMounted: true };

  useEffect(() => {
    auth.on('afterLoginPopup', (popupName: string) => {
      if (popupName && user) {
        openModal(popupName);
      }
    });

    auth.on('profile', (profile) => {
      if (profile && user && typeof window !== 'undefined') {
        const beforeLoginPath = window.localStorage['beforeLoginPath'];
        if (beforeLoginPath) {
          const path = beforeLoginPath === '/' ? '/activity' : beforeLoginPath;
          window.localStorage['beforeLoginPath'] = '';
          router.push(path);
        }
      }
    });
  }, []);

  useEffect(() => {
    const handleRouteChange = (url) => {
      updateTabHistory(url);
    };
    handleRouteChange(router.pathname);
  }, [router.pathname]);

  useEffect(() => {
    if (alertContent) {
      setShowAlert(true);
    }
  }, [alertContent]);

  const toggleDrawer = (open: boolean) => () => {
    setIsOpen(open);
  };

  const onMenuToggle = (state?: boolean) => {
    if (state !== undefined) {
      setIsOpen(state);
    } else {
      setIsOpen(!isOpen);
    }
  };

  const onAlertClick = () => {
    setShowAlert(false);
    setTimeout(() => {
      setAlertContent(null);
    }, 500);
  };

  return (
    <div id="root">
      <div className="layout-top">
        <Drawer
          anchor="right"
          open={isOpen}
          onClose={toggleDrawer(false)}
          ModalProps={ModalProps}
        >
          <PsListMenu onMenuToggle={onMenuToggle} />
        </Drawer>
        <div className="layout-container">
          <Header onMenuToggle={onMenuToggle}></Header>
          <div className="layout-content">{children}</div>
        </div>
      </div>

      <TermsPopup />
      <RootModal />
      <SimpleModal />
      <DrawModal />

      <WarningDialog
        open={showAlert}
        onSubmit={onAlertClick}
        yes="Cancel"
        no=""
      >
        {alertContent}
      </WarningDialog>
    </div>
  );
};

export default function LayoutRoute() {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
}
