import React, { useCallback, useContext, useState } from 'react';
import { styled, useTheme, lighten } from '@mui/material/styles';

import { ModalComponentsKeys, ModalContext } from 'contexts/ModalContext';
import { ModalDataContext } from 'contexts/ModalDataContext';
import { DataContext } from 'contexts/DataContext';
import { AuthContext } from 'contexts/AuthContext';
import { Solution } from 'components/CardSolution';
import { PsButton, EditButton } from 'components/common/PsButton';
import { Tags } from 'components/Tag';
import { PsTheme } from '../../theme';
import { makeStyles } from 'tss-react/mui';

type ClassKey =
  | 'root'
  | 'headerRow'
  | 'titleHolder'
  | 'title'
  | 'detailTitle'
  | 'detailText';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      background: '#ffffff',
      border: '1px solid rgba(121, 114, 140, 0.11)',
      boxSizing: 'border-box',
      boxShadow:
        '0px 4px 8px rgba(10, 0, 32, 0.02), 0px 4px 84px rgba(7, 0, 21, 0.04)',
      borderRadius: 10,
      overflow: 'hidden',
      width: '100%',
      marginBottom: 23,
      padding: '24px 23px 10px'
    },
    headerRow: {
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'space-between',
      marginBottom: 15
    },
    titleHolder: {
      paddingTop: 6
    },
    title: {
      fontWeight: 'bold',
      fontSize: 24,
      margin: '0 0 7px 0',
      '& b': {
        fontWeight: 'bold',
        color: psTheme.palette.primary.main
      }
    },
    detailTitle: {
      fontSize: 14,
      marginTop: 23,
      marginBottom: 10,
      color: lighten(psTheme.palette.third.main, 0.3),
      textTransform: 'uppercase'
    },
    detailText: {
      fontWeight: 600,
      lineHeight: '1.9',
      marginBottom: 15,
      '& b': {
        fontWeight: 600,
        color: psTheme.palette.primary.main
      }
    }
  };
});

export type CardSolutionDetailsProps = {
  solution: Solution;
};

const CardSolutionDetailsView = ({ solution }: CardSolutionDetailsProps) => {
  const {
    id,
    title,
    teaser,
    owner,
    descMaterials,
    descDimensions,
    descSteps,
    relations
  } = solution;
  const { user } = useContext(AuthContext);
  const { openModal } = useContext(ModalContext);
  const { setType, updateField } = useContext(ModalDataContext);
  const { setSolutionDetailEditOpened } = useContext(DataContext);
  const { classes } = useStyles();

  const showEdit = user ? user.id === owner || user.isAdmin : false;

  const onEditOpen = useCallback(() => {
    setSolutionDetailEditOpened(true);
  }, [setSolutionDetailEditOpened]);

  const onImproveClick = useCallback(() => {
    setType(ModalComponentsKeys.addNewSolution);
    updateField('parentSolution', id);
    openModal(ModalComponentsKeys.addNewSolution);
  }, [updateField, openModal, setType, id]);

  return (
    <div className={classes.root}>
      <div className={classes.headerRow}>
        <div className={classes.titleHolder}>
          <h2 className={classes.title}>
            <b>{teaser || title}</b> details:
          </h2>
        </div>
        {showEdit ? (
          <EditButton onClick={onEditOpen}>Edit Solution Details</EditButton>
        ) : null}
      </div>
      {descMaterials ? (
        <>
          <div className={classes.detailTitle}>Materials:</div>
          <div className={classes.detailText}>
            The <b>{teaser || title}</b> Includes {descMaterials}.
          </div>
        </>
      ) : null}

      {descDimensions ? (
        <>
          <div className={classes.detailTitle}>Dimensions:</div>
          <div className={classes.detailText}>
            The <b>{teaser || title}</b> measures {descDimensions}.
          </div>
        </>
      ) : null}

      {descSteps ? (
        <>
          <div className={classes.detailTitle}>Steps:</div>
          <div className={classes.detailText}>
            The <b>{teaser || title}</b> is used by:
            {descSteps.split('\n').map((step, i) => (
              <span key={i}>
                <br />
                {step}
              </span>
            ))}
            .
          </div>
        </>
      ) : null}

      {relations && relations.length ? (
        <>
          <div className={classes.detailTitle}>Related solutions:</div>
          <Tags relations={relations || []} />
        </>
      ) : null}

      <div className={classes.detailTitle}>
        <PsButton color="secondary" onClick={onImproveClick} smallest>
          Create Child Solution
        </PsButton>
      </div>
    </div>
  );
};

export const CardSolutionDetails = styled(CardSolutionDetailsView)({});

export default CardSolutionDetails;
