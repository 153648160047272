import React, { useContext, useCallback } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import ModalSmall from 'modals/ModalSmall';
import { AuthContext } from 'contexts/AuthContext';
import { ModalContext, ModalComponentsKeys } from 'contexts/ModalContext';
import { ModalDataContext } from 'contexts/ModalDataContext';
import AddNewEntity from './AddNewEntity';
import AddNewProblem from './AddNewProblem';
import AddNewSolution from './AddNewSolution';
import AddNewApplication from './AddNewApplication';
import AddNewFullFlow from './AddNewFullFlow';
import AddNewContest from './AddNewContest';
import AddNewChallenge from './AddNewChallenge';
import {
  BuildPatentApplication,
  BuildPatentApplicationSuccess,
  BuildPatentApplicationSuccessOpen
} from './BuildPatentApplication';
import {
  ImproveApplication,
  ImproveApplicationSuccess
} from './ImproveApplication';
import {
  BrainstormSolution,
  BrainstormSolutionSuccess
} from './BrainstormSolution';
import { CompanySubscription } from './CompanySubscription';
import { CompanySubscriptionFinalize } from './CompanySubscriptionFinalize';
import { PayOutRequest, PayOutRequestSuccess } from './PayOutRequest';
import { SellInvention, SellNft } from './SellInvention';
import { WalletSettings } from './WalletSettings';
import { ManageTags } from './ManageTags';
import { AddSolutionToInvention } from './AddSolutionToInvention';
import { AttachInventionToSolution } from './AttachInventionToSolution';
import { DownloadPatent } from './DownloadPatent';
import { RegisterPopup } from 'components/auth/RegisterPopup';
import SuccessModal from './SuccessModal';
import { PsTheme } from '../theme';
import { getQuery } from '../helpers';
import useRouter from 'hooks/useRouter';
import { makeStyles } from 'tss-react/mui';

export const modalComponents: Record<string, React.FC> = {
  addNewEntity: AddNewEntity,
  addNewProblem: AddNewProblem,
  addNewSolution: AddNewSolution,
  addNewInvention: AddNewApplication,
  addNewFullFlow: AddNewFullFlow,
  addNewContest: AddNewContest,
  addNewChallenge: AddNewChallenge,
  success: SuccessModal,
  buildPatentApplication: BuildPatentApplication,
  buildPatentApplicationSuccess: BuildPatentApplicationSuccess,
  buildPatentApplicationSuccessOpen: BuildPatentApplicationSuccessOpen,
  improveApplication: ImproveApplication,
  improveApplicationSuccess: ImproveApplicationSuccess,
  brainstormSolution: BrainstormSolution,
  brainstormSolutionSuccess: BrainstormSolutionSuccess,
  companySubscription: CompanySubscription,
  companySubscriptionFinalize: CompanySubscriptionFinalize,
  payOutRequest: PayOutRequest,
  payOutRequestSuccess: PayOutRequestSuccess,
  walletSettings: WalletSettings,
  manageTags: ManageTags,
  addSolutionToInvention: AddSolutionToInvention,
  attachInventionToSolution: AttachInventionToSolution,
  registerPopup: RegisterPopup,
  sellInvention: SellInvention,
  sellNft: SellNft,
  downloadPatent: DownloadPatent
};

type ClassKey = 'root';

export const RootModalView = () => {
  const { user } = useContext(AuthContext);
  const { isOpen, toggleModal, componentKey, rootClassName } =
    useContext(ModalContext);
  const { resetFields } = useContext(ModalDataContext);

  const router = useRouter();
  const Component = modalComponents[componentKey] as React.ElementType;

  const onClose = useCallback(() => {
    if (
      (user && componentKey === ModalComponentsKeys.registerPopup) ||
      componentKey === ModalComponentsKeys.sellInvention ||
      componentKey === ModalComponentsKeys.sellNft
    ) {
      return;
    }
    resetFields();
    toggleModal();

    const { openPopup, ...newQuery } = getQuery();
    if (openPopup) {
      router.replace(router.pathname + newQuery);
    }
  }, [resetFields, toggleModal, router]);

  return (
    <ModalSmall isOpen={isOpen} onClose={onClose} className={rootClassName}>
      {Component && <Component />}
    </ModalSmall>
  );
};

export const RootModal = styled(RootModalView)({});

export default RootModal;
