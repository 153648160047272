import React from 'react';
import { BotIcon } from 'components/icons/BotIcon';
import {
  ArrowLeft,
  StyledHelpButton,
  StyledTypography
} from './styledComponents';

type HelpButtonProps = {
  buttonText: string;
  onClick: () => void;
};

export const ChatHelpButton: React.FC<HelpButtonProps> = ({
  buttonText,
  onClick
}) => {
  return (
    <StyledHelpButton onClick={onClick}>
      <BotIcon />
      <StyledTypography>{buttonText}</StyledTypography>
      <ArrowLeft />
    </StyledHelpButton>
  );
};
