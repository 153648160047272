import React, { useState, useContext, useCallback, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import ModalWindow from 'modals/ModalWindow';
import { PsButton } from 'components/common/PsButton';
import Stepper from 'components/Stepper';
import Step from 'components/Step';
import { ModalComponentsKeys, ModalContext } from 'contexts/ModalContext';
import {
  AttachInventionToSolutionSteps,
  AddApplicationSteps,
  AddProblemSteps,
  AddSolutionSteps,
  ModalDataContext,
  StepComponentProps
} from 'contexts/ModalDataContext';
import { DataContext } from 'contexts/DataContext';
import { AuthContext } from 'contexts/AuthContext';
import {
  ADD_SOL_TO_INV_STEPS_CONFIG,
  stepName,
  defaultProblemTitle,
  defaultSolutionTitle,
  defaultInventionTitle
} from './config';
import styles from 'modals/ModalWindow.module.scss';
import { Problem } from 'components/CardProblem';
import { Solution } from 'components/CardSolution';
import { Application } from 'components/CardApplication';
import { makeStyles } from 'tss-react/mui';
import useRouter from 'hooks/useRouter';

const stepsApplication: string[] = [
  'Invention name',
  'Tag Invention',
  'Finalize Invention'
];

const stepsProblem: string[] = ['Problem Name', 'Tag Name', 'Finalize Problem'];

const stepsSolution: string[] = [
  'Solution Name',
  'Tag Name',
  'Finalize Solution'
];

const useButtonClasses = makeStyles()(() => {
  return {
    root: {
      margin: '8px 0',
      fontWeight: 400
    }
  };
});

export const AttachInventionToSolution: React.FC = () => {
  const { user } = useContext(AuthContext);
  const { openModal, closeModal } = useContext(ModalContext);
  const { values, setType, updateField, resetFields, setErrors } =
    useContext(ModalDataContext);
  const { dataProvider, refreshSolutionDetail } = useContext(DataContext);
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [prevSteps, setPrevSteps] = useState<string[]>([]);
  const [activeStepNumber, setActiveStepNumber] = useState<number>(0);
  const [currentStep, setCurrentStep] = useState<string | undefined>(
    AttachInventionToSolutionSteps.ApplicationSelect
  );
  const [stepsName, setStepsName] = useState<Array<string>>(stepsApplication);

  const buttonClasses = useButtonClasses();

  const {
    Component,
    nextStep,
    validate,
    isNextStepBlocked,
    isFinalStep,
    nextButtonTitle,
    backButtonTitle,
    modalTitle,
    name
  } = ADD_SOL_TO_INV_STEPS_CONFIG[currentStep || ''];

  const StepComponent = Component as React.FC<StepComponentProps>;

  useEffect(() => {
    if (values.mainProblem) {
      dataProvider
        .getOne<Problem>('problems', { id: values.mainProblem })
        .then(({ data }) => {
          updateField('mainProblemLabel', data.title);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [values.mainProblem]);

  const handleClickBack = useCallback(() => {
    if (activeStepNumber === 0) {
      closeModal();
      resetFields;
      return;
    }

    if (name === stepName.APPLICATION_START) {
      setPrevSteps([]);
      setErrors({});
      setActiveStepNumber(0);
      setCurrentStep(AttachInventionToSolutionSteps.ApplicationSelect);
      setStepsName(stepsApplication);
      return;
    }

    if (name === stepName.PROBLEM_START) {
      setPrevSteps([AttachInventionToSolutionSteps.ApplicationSelect]);
      setErrors({});
      setActiveStepNumber(1);
      setCurrentStep(AddApplicationSteps.ApplicationName);
      setStepsName(stepsApplication);
      return;
    }

    if (name === stepName.SOLUTION_START) {
      setPrevSteps([
        AddApplicationSteps.ApplicationName,
        AddApplicationSteps.ApplicationTags
      ]);
      setErrors({});
      setActiveStepNumber(3);
      setCurrentStep(AddApplicationSteps.FinilizeApplication);
      setStepsName(stepsApplication);
      return;
    }

    setActiveStepNumber((prev) => Math.max(0, prev - 1));
    setCurrentStep(prevSteps[prevSteps.length - 1]);
    setPrevSteps(prevSteps.slice(0, prevSteps.length - 1));
  }, [prevSteps]);

  const handleNextStep = async () => {
    if (typeof validate === 'function') {
      const { success, errors } = validate(values);

      if (!success) {
        setErrors({ ...errors });

        return;
      }
    }

    if (name === stepName.PROBLEM_END) {
      const problemInfo = {
        title: (values.titleProblem || '').replace(defaultProblemTitle, ''),
        tags: values.tagsProblem,
        body: values.descriptionProblem,
        files: values.filesProblem,
        parentProblem: values.rootProblem,
        isPublic: true
      };

      setLoading(true);

      dataProvider
        .create<Problem>('problems', { data: problemInfo })
        .then(({ data }) => {
          router.push(`/problems/${data.key || data.id}`);
          updateField('mainProblem', data.id);
          updateField('mainProblemLabel', data.title);
          setPrevSteps([]);
          setErrors({});
          setActiveStepNumber(1);
          setCurrentStep(AddApplicationSteps.ApplicationName);
          setStepsName(stepsApplication);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });

      return;
    }

    if (name === stepName.SOLUTION_END) {
      const solutionInfo = {
        title: (values.titleSolution || '').replace(defaultSolutionTitle, ''),
        tags: values.tagsSolution,
        body: values.descriptionSolution,
        files: values.filesSolution,
        problem: values.parentProblem,
        //parentSolution: values.parentSolution,
        isPublic: values.finalizeType === 'public'
      };

      setLoading(true);

      dataProvider
        .create<Solution>('solutions', { data: solutionInfo })
        .then(({ data }) => {
          router.push(`/solutions/${data.key || data.id}`);
          const newSelected = [...values.selected, data.id];
          updateField('selected', newSelected);
          setPrevSteps([
            AddApplicationSteps.ApplicationName,
            AddApplicationSteps.ApplicationTags
          ]);
          setErrors({});
          setActiveStepNumber(3);
          setCurrentStep(AddApplicationSteps.FinilizeApplication);
          setStepsName(stepsApplication);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });

      return;
    }

    if (name === stepName.APPLICATION_END) {
      const applicationInfo = {
        title: (values.titleApplication || '').replace(
          defaultInventionTitle,
          ''
        ),
        body: values.descriptionApplication,
        problem: values.mainProblem,
        selected: [values.mainProblem, ...values.selected],
        isPublic: false
      };

      setLoading(true);

      dataProvider
        .create<Application>('applications', { data: applicationInfo })
        .then(({ data }) => {
          router.push(`/inventions/${data.key || data.id}`);
          setType(ModalComponentsKeys.addNewInvention);
          openModal(ModalComponentsKeys.success);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });

      return;
    }

    if (isFinalStep) {
      setLoading(true);

      dataProvider
        .addSolutionToProblem(
          values.mainProblem || '',
          values.selectApplication || '',
          values.application || '' // solution id
        )
        .then((data) => {
          router.push(`/inventions/${values.selectApplication}`);
          refreshSolutionDetail();
          openModal(ModalComponentsKeys.success);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });

      return;
    }

    setPrevSteps([...prevSteps, currentStep || '']);
    setErrors({});
    setActiveStepNumber((prev) => prev + 1);
    if (typeof nextStep === 'function') {
      setCurrentStep(nextStep(values));
      return;
    }

    setCurrentStep(nextStep);
  };

  const onStepNotify = (args: any) => {
    if (args === 'create app') {
      updateField('selected', [values.application]);
      setPrevSteps([...prevSteps, currentStep || '']);
      setErrors({});
      setActiveStepNumber(1);
      setCurrentStep(AddApplicationSteps.ApplicationName);
      setStepsName(stepsApplication);
    }
    if (args === 'create problem') {
      setPrevSteps([...prevSteps, currentStep || '']);
      setErrors({});
      setActiveStepNumber(4);
      setCurrentStep(AddProblemSteps.ProblemName);
      setStepsName(stepsProblem);
    }
    if (args === 'create solution') {
      updateField('parentProblem', values.mainProblem);
      updateField('parentProblemLabel', values.mainProblemLabel);
      updateField('parentProblemHide', true);
      updateField('parentSolutionHide', true);
      setPrevSteps([...prevSteps, currentStep || '']);
      setErrors({});
      setActiveStepNumber(7);
      setCurrentStep(AddSolutionSteps.SolutionName);
      setStepsName(stepsSolution);
    }
  };

  const isNextStepDisabled: boolean =
    typeof isNextStepBlocked === 'function' && isNextStepBlocked(values);

  const title = values.modalTitle || modalTitle || 'Add to Invention';

  let stepperShow = false;
  if (activeStepNumber >= 1) {
    stepperShow = true;
  }

  let stepperStep = activeStepNumber;
  if (activeStepNumber >= 1 && activeStepNumber < 4) {
    stepperStep = activeStepNumber - 1;
  }
  if (activeStepNumber >= 4 && activeStepNumber < 7) {
    stepperStep = activeStepNumber - 4;
  }
  if (activeStepNumber >= 7) {
    stepperStep = activeStepNumber - 7;
  }

  return (
    <ModalWindow title={title}>
      {stepperShow ? (
        <Stepper className={styles.stepper} activeStep={stepperStep}>
          {stepsName.map((step) => (
            <Step key={step}>{step}</Step>
          ))}
        </Stepper>
      ) : null}
      <StepComponent onNotify={onStepNotify} />
      <div className={styles.actions}>
        <PsButton
          disabled={isNextStepDisabled || loading}
          onClick={handleNextStep}
          fullWidth
          classes={buttonClasses}
        >
          {nextButtonTitle || 'Next'}
        </PsButton>
        <PsButton
          disabled={loading}
          onClick={handleClickBack}
          fullWidth
          cancel
          classes={buttonClasses}
        >
          {backButtonTitle || 'Back'}
        </PsButton>
      </div>
    </ModalWindow>
  );
};

export default AttachInventionToSolution;
