import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
import { Step1, Step2, Step3 } from './steps';
import { Contest } from 'components/CardContest';
import { Constants } from 'utilities/constants';

interface ContentProps {
  activeStep?: number;
  closeModal?: () => void;
  productId?: string;
  productKey?: string;
  setActiveStep?: React.Dispatch<React.SetStateAction<number>>;
  setSubtype?: React.Dispatch<React.SetStateAction<string>>;
  setType?: React.Dispatch<React.SetStateAction<string>>;
  subType?: string;
  title?: string;
  type?: string;
}

export type Node = {
  id?: string | number;
  isPublic?: boolean;
  key?: string;
  parentProduct?: string;
  shortTitle?: string;
  tags?: string[] | string;
  teaser?: string;
  title?: string;
  ownerImage?: string;
  image?: string;
  isLiked?: boolean;
  likes?: number;
  _id?: string;
  type?: string;
  problem?: string;
  contests?: Array<Contest>;
};

export const Content: React.FC<ContentProps> = ({
  activeStep,
  closeModal,
  productId,
  productKey,
  setActiveStep,
  setSubtype,
  setType,
  subType,
  title,
  type
}) => {
  const [problem, setProblem] = useState<Node | null>(null);
  const defaultProblemTitle = Constants.DEFAULT_PROBLEM_TITLE;
  const [finalTitle, setFinalTitle] = useState<string>(defaultProblemTitle);
  const problemTitle = finalTitle.split(':')[1].trim();
  const selectedProblemTitle = problem?.shortTitle;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box>
          {activeStep === 0 && (
            <Step1
              handleNext={handleNext}
              setSubtype={setSubtype}
              setType={setType}
              type={type}
            />
          )}
          {activeStep === 1 && (
            <Step2
              handleNext={handleNext}
              problem={problem}
              productId={productId}
              productKey={productKey}
              setProblem={setProblem}
              subType={subType}
              title={title}
              finalTitle={finalTitle}
              setFinalTitle={setFinalTitle}
            />
          )}
          {activeStep === 2 && (
            <Step3
              closeModal={closeModal}
              problem={problem}
              productId={productId}
              productKey={productKey}
              subType={subType}
              title={title}
              problemTitle={problemTitle}
              selectedProblemTitle={selectedProblemTitle}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};
