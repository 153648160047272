import React, { useCallback, useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { Tags } from 'components/Tag';
import { Contest } from 'components/CardContest';
import {
  Card,
  CardImage,
  CardBody,
  CardTitle,
  CardOwner,
  CardText,
  CardActions,
  CardVote,
  CardRow,
  CardCell
} from 'components/card-small';
import { placeholderType } from '../../helpers';
import { AuthContext } from 'contexts/AuthContext';
import { ShareBtn } from 'components/ShareBtn';
import { PsTheme } from '../../theme';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root' | 'tags';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {},
    tags: {
      '& > div': {
        marginTop: 4,
        marginBottom: 4
      }
    }
  };
});

export type CardContestSmallProps = {
  contest: Contest;
};

const CardContestSmallView = ({ contest }: CardContestSmallProps) => {
  const {
    id,
    key,
    title,
    body,
    owner,
    ownerInfo,
    tags = [],
    tagsInfo = [],
    createdAt,
    files,
    likes,
    dislikes
  } = contest;
  const { user } = useContext(AuthContext);
  const { classes } = useStyles();
  const image = files && files.length ? files[0] : undefined;
  const url = `/contests/${key || id}`;

  return (
    <Card>
      <CardImage image={image} href={url} type={placeholderType.T} />
      <CardBody>
        <CardTitle title={title} href={url}>
          <Tags className={classes.tags} tagsList={tagsInfo} />
        </CardTitle>
        <CardOwner
          owner={owner}
          ownerKey={ownerInfo && ownerInfo.key}
          ownerName={ownerInfo && ownerInfo.username}
          createdAt={createdAt}
        />
        <CardText>{body}</CardText>
        <CardRow>
          <div></div>
          <CardCell>
            <CardActions>
              <ShareBtn contest={contest} tagsList={tagsInfo} />
            </CardActions>
          </CardCell>
        </CardRow>
      </CardBody>
      <CardVote
        resource="contests"
        itemId={id}
        likes={likes}
        dislikes={dislikes}
        user={user}
      />
    </Card>
  );
};

export const CardContestSmall = styled(CardContestSmallView)({});

export default CardContestSmall;
