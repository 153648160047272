import { Application } from 'components/CardApplication';
import { Problem } from 'components/CardProblem';
import { Product } from 'components/CardProduct';
import dataProvider from 'dataPrvider';
import { Constants } from 'utilities/constants';

export const Api = {
  createConcept: async ({ data }) => {
    try {
      const newdata = await dataProvider.create<Application>('applications', {
        data: data
      });
      return newdata;
    } catch (e) {
      return [];
    }
  },
  generateAI: async ({ conceptId }) => {
    try {
      const data = await dataProvider.getImageAndTitle(conceptId as number);
      return data;
    } catch (e) {
      return [];
    }
  },
  getConcept: async ({ userId }) => {
    try {
      const data = await dataProvider.getConceptsByUserId(userId, {
        sort: { field: 'updatedAt', order: 'DESC' },
        filter: {
          owner: userId,
          $custom: {
            type: Constants.NotLockedFiled
          }
        }
      });
      return data;
    } catch (e) {
      return [];
    }
  },
  getConceptsByProblemOrSolution: async ({ id, type }) => {
    try {
      const data = await dataProvider.getContestsByProblemOrSolution(
        id as string,
        type as string
      );
      return data;
    } catch (e) {
      return [];
    }
  },
  getGraph: async ({ id, type, mapType }) => {
    try {
      const data = await dataProvider.getGraph<Product | Problem>(
        type,
        id,
        mapType
      );
      return data;
    } catch (e) {
      return [];
    }
  },
  updateConcept: async ({ conceptId, data }) => {
    try {
      const updatedData = await dataProvider.update<Application>(
        'applications',
        {
          id: conceptId,
          data: data
        }
      );
      return updatedData;
    } catch (e) {
      return [];
    }
  }
};
