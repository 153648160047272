import React, { useCallback } from 'react';
import Config from 'config/config';
import { Constants } from 'utilities/constants';
import {
  CardNameText,
  CardTokenText,
  StyledCard,
  StyledCardContent,
  StyledCardMedia
} from './styledComponents';

export const NftCard: React.FC<{
  name: string;
  image: string;
  tokenId: string;
}> = ({ name, image, tokenId }) => {
  const onCardClick = useCallback(() => {
    window.open(
      `${Config.NFT_BASE_URL}/${Config.NEXT_PUBLIC_NFT_NETWORK}/${Config.NFT_CONTRACT_ADDRESS}/${tokenId}`,
      Constants._BLANK
    );
  }, [tokenId]);

  return (
    <StyledCard onClick={onCardClick}>
      <StyledCardMedia image={image} />
      <StyledCardContent>
        <CardNameText>{name}</CardNameText>
        <CardTokenText>{tokenId}</CardTokenText>
      </StyledCardContent>
    </StyledCard>
  );
};
