/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  Typography
} from '@mui/material';
import { Constants, VARIANT } from 'utilities/constants';

const StakerModal = ({
  isOpen,
  onClose,
  onSubmit,
  pricePerProduct,
  numberOfUnitsStaked,
  maximumNumberOfUnitsAllowed
}) => {
  const initialFormData = useMemo(
    () => ({
      numberOfProducts: '',
      amount: ''
    }),
    []
  );

  const [formData, setFormData] = useState(initialFormData);
  const remainingUnits = maximumNumberOfUnitsAllowed - numberOfUnitsStaked;

  useEffect(() => {
    if (isOpen) {
      setFormData(initialFormData);
    }
  }, [isOpen, initialFormData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (value === '') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
        amount: ''
      }));
      return;
    }

    const numericValue = parseFloat(value);

    if (numericValue !== parseInt(value, 10)) {
      return;
    }

    if (
      name === Constants.NUMBER_OF_PRODUCTS &&
      numericValue > remainingUnits
    ) {
      return;
    }

    setFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData, [name]: value };
      if (name === Constants.NUMBER_OF_PRODUCTS) {
        updatedFormData.amount = (
          parseFloat(value || 0) * pricePerProduct
        ).toFixed(2);
      }
      return updatedFormData;
    });
  };

  const handleFormSubmit = () => {
    const payload = {
      numberOfStake: parseInt(formData.numberOfProducts, 10),
      amount: parseFloat(formData.amount)
    };
    onSubmit(payload);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{Constants.STAKE_THIS_INVENTION}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {Constants.ENTERT_DETAIL_OF_STAKING}
          <Typography>
            {Constants.PRICE_PER_PRODUCT}
            {Constants.USD_SYMBOL}
            {pricePerProduct}
          </Typography>
          <Typography>
            {numberOfUnitsStaked} out of {maximumNumberOfUnitsAllowed} units are
            staked. Remaining Units:{' '}
            {maximumNumberOfUnitsAllowed - numberOfUnitsStaked}
          </Typography>
        </DialogContentText>
        <TextField
          margin={Constants.DENSE}
          label={Constants.NUMBER_OF_PRODUCTS_WITH_SPACE}
          name={Constants.NUMBER_OF_PRODUCTS}
          type={VARIANT.NUMBER}
          fullWidth
          variant={VARIANT.OUTLINED}
          value={formData.numberOfProducts}
          onChange={handleInputChange}
          placeholder={Constants.ENTER_NUMBER_OF_PRODUCTS}
          helperText={`You have staked ${numberOfUnitsStaked} out of ${maximumNumberOfUnitsAllowed} units. Remaining: ${remainingUnits}`}
          error={
            parseInt(formData.numberOfProducts || '0', 10) > remainingUnits
          }
          inputProps={{
            min: 1,
            step: 1
          }}
        />
        <TextField
          margin={Constants.DENSE}
          label={Constants.TOTAL_AMOUNT_IN_$}
          name={Constants.AMOUNT}
          type={VARIANT.NUMBER}
          fullWidth
          variant={VARIANT.OUTLINED}
          value={formData.amount}
          placeholder={Constants.AUTO_CALCULATED_THRESHOLD}
          InputProps={{
            readOnly: true
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleFormSubmit}
          color={VARIANT.PRIMARY}
          variant={VARIANT.CONTAINED}
          disabled={
            parseInt(formData.numberOfProducts || '0', 10) > remainingUnits ||
            parseInt(formData.numberOfProducts || '0', 10) <= 0 ||
            formData.numberOfProducts === ''
          }
        >
          Submit
        </Button>
        <Button onClick={onClose} color={VARIANT.SECONDARY}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StakerModal;
