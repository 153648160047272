import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Box, CircularProgress, IconButton, Tab } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { IoClose } from 'react-icons/io5';
import { useDispatch } from 'react-redux';
import ProblemModal from 'modals/AddProblem';
import SolutionModal from 'modals/AddSolution';
import { ShareInfoModal } from 'modals/ShareInfoModal';
import { ChatHelpButton } from 'components/common/buttons/ChatBotHelpButton';
import { ChatBotDrawer } from 'components/ChatBotDrawer';
import { IconContainer } from 'components/EntityDrawerNew/EntityDrawerNew';
import { ActiveConceptCard } from 'components/ActiveConceptCard';
import { ConceptProgressAccordion } from 'components/ConceptProgressAccordion';
import PatentPendingConcept from 'components/PatentPendingConcept';
import RenderTreeItems from 'components/RenderTreeItems';
import TopSection from 'components/TopSection';
import ParentNode from './ParentNode';
import Actions from 'redux-state/actions';
import {
  GetCircularProgressValue,
  GetToggleConceptCart
} from 'redux-state/ideamap/selectors';
import { Constants } from 'utilities/constants';
import { GetUser } from 'redux-state/selectors';
import { isObjectEmpty, useRemoveQueryParams } from 'helpers';
import useRouter from 'hooks/useRouter';
import dataProvider from 'dataPrvider';
import pusher from '../../config/pusherConfig';
import { SideMenu } from 'layout';
import { Node } from 'interface/common';
import { theme, colorPalette } from 'theme';
import { ActiveConcept } from 'components/common/StyledComponents';
import {
  ActiveBox,
  CircularBox,
  CustomTreeView,
  GenerateMoreButton,
  GenerateMoreButtonBox,
  IconSize,
  StyleBox,
  StyledClose,
  StyledContainer,
  StyledDrawer,
  StyledSubContainer,
  StyledTabPanel,
  StyledTopSection,
  TabListBoxStyle,
  TabListStyle
} from './StyledComponents';

interface NewIdeaMapProps {
  fetchChildrenGraph?: any;
  graphData?: any;
  id?: string;
  renderParent?: boolean;
  topSection?: boolean;
  type?: string;
}

const ExpandIcon = () => <AiOutlinePlus size={IconSize} />;
const CollapseIcon = () => <AiOutlineMinus size={IconSize} />;

const NewIdeaMap: FC<NewIdeaMapProps> = ({
  fetchChildrenGraph,
  graphData,
  id,
  renderParent = true,
  topSection = true,
  type
}) => {
  const router = useRouter();
  const { referralCode, showModal } = router.query;

  const [expansionIds, setExpansionIds] = useState<string[]>(
    graphData?.id ? [graphData.id] : []
  );
  const [generateMoreLoader, setGenerateMoreLoader] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isSolutionModalOpen, setIsSolutionModalOpen] =
    useState<boolean>(false);
  const [openChat, setOpenChat] = useState<boolean>(false);
  const [openShareInfo, setOpenShareInfo] = useState<boolean>(false);
  const [problem, setProblem] = useState<Node | null>(null);
  const [selectedNode, setSelectedNode] = useState<string | undefined>(
    undefined
  );
  const [tabValue, setTabValue] = useState<string>(Constants.ACTIVE);
  const [terminated, setTerminated] = useState<boolean>(false);

  const dispatch = useDispatch();
  const scrollRef = useRef<HTMLDivElement>(null);

  const isConceptDrawerOpen = GetToggleConceptCart();
  const progress = GetCircularProgressValue();
  const user = GetUser();
  const activeConcept = user?.activeConcept || {};
  const aiGeneratedChildrenCount = useMemo(() => {
    return (
      graphData?.children?.filter((child) => child.isAiGenerated).length || 0
    );
  }, [graphData]);

  const trimmedKey = useMemo(() => {
    return graphData?.key
      ?.substring(0, 140)
      .replace(/[^a-zA-Z0-9_=@,.;-]/g, '');
  }, [graphData?.key]);

  useEffect(() => {
    if (progress && progress >= 96) {
      dispatch(Actions.getGraph(graphData.id, type, type));
      setGenerateMoreLoader(false);
    }
  }, [progress]);

  useEffect(() => {
    if (!graphData?.id) return;

    const problemChannel = pusher.subscribe(`problem-channel-${trimmedKey}`);
    const progressChannel = pusher.subscribe(`progress-channel-${trimmedKey}`);
    const errorChannel = pusher.subscribe(`error-channel-${trimmedKey}`);

    problemChannel.bind('new-problems', (data: any) => {
      setGenerateMoreLoader(false);
      if (data?.success) {
        dispatch(Actions.getGraph(graphData.id, type, type));
      }
    });

    errorChannel.bind('errors', () => {
      alert(
        `Error while creating problems and solutions for ${graphData?.key}`
      );
      setTerminated(true);
      setGenerateMoreLoader(false);
      progressChannel.unbind('progress-value');
      pusher.unsubscribe(`progress-channel-${trimmedKey}`);
      dispatch(Actions.getGraph(graphData.id, type, type));
    });

    progressChannel.bind('progress-value', (data: any) => {
      dispatch(Actions.setCircularProgressValue(data.progress));
    });

    return () => {
      dispatch(Actions.setCircularProgressValue(0));
      setTerminated(false);
      problemChannel.unbind('new-problems');
      pusher.unsubscribe(`problem-channel-${trimmedKey}`);
      progressChannel.unbind('progress-value');
      pusher.unsubscribe(`progress-channel-${trimmedKey}`);
      errorChannel.unbind('errors');
      pusher.unsubscribe(`error-channel-${trimmedKey}`);
    };
  }, [dispatch, graphData, trimmedKey, type]);

  useEffect(() => {
    if (!user) return;
    dispatch(Actions.getConcept(user.id));
  }, [dispatch, user?.id]);

  useEffect(() => {
    const unloadHandler = () => {
      if (graphData && Object.keys(graphData).length > 0) {
        dispatch(Actions.setGraph({}));
      }
    };

    window.addEventListener('beforeunload', unloadHandler);

    return () => {
      window.removeEventListener('beforeunload', unloadHandler);
    };
  }, [dispatch, graphData]);

  useEffect(() => {
    if (
      !user?.isOpenCtaModal &&
      typeof showModal === 'string' &&
      [
        Constants.PROBLEMS,
        Constants.CONTESTS,
        Constants.SOLUTIONS,
        Constants.PRODUCTS,
        Constants.INVENTIONS
      ].includes(showModal as Constants)
    ) {
      setOpenShareInfo(true);
    }
  }, [showModal, user]);

  const problemAndSolutionCreationCallBack = useCallback(() => {
    setExpansionIds([]);
    dispatch(Actions.setGraph({}));
    dispatch(Actions.getGraph(graphData?.id, type, type));
  }, [dispatch, graphData?.id, type]);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleSetOpenChat = useCallback(() => {
    setOpenChat(true);
    dispatch(Actions.toggleConceptCart(true));
  }, [dispatch]);

  const openCreateProblemModal = useCallback(() => {
    if (user) {
      if (type === Constants.COMPANY_PRODUCTS) {
        setIsModalOpen(true);
      } else if (type === Constants.PROBLEMS) {
        setIsSolutionModalOpen(true);
        setProblem(graphData);
      }
    } else {
      dispatch(Actions.openAuthModal(true));
    }
  }, [user, type, graphData, dispatch]);

  const removeQueryParams = useRemoveQueryParams();

  const handleCloseShareInfoModal = useCallback(() => {
    setOpenShareInfo(false);
    if (showModal && typeof showModal === 'string') {
      removeQueryParams([Constants.SHOW_MODAL]);
    }
  }, [removeQueryParams, showModal]);

  const handleDrawer = useCallback(() => {
    dispatch(Actions.toggleConceptCart(!isConceptDrawerOpen));
  }, [dispatch, isConceptDrawerOpen]);

  const handleTabChange = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      setTabValue(newValue);
    },
    []
  );

  const onNodeFocus = useCallback(
    (event: React.SyntheticEvent<Element, Event>, itemId: string) => {
      setSelectedNode(itemId);
    },
    []
  );

  const scroller = useCallback(() => {
    if (scrollRef.current) {
      const contentElement =
        scrollRef.current.querySelector('.MuiDrawer-paper');
      if (contentElement) {
        contentElement.scrollTop = 0;
      }
    }
  }, []);

  const addNewConcept = useCallback(
    (
      selected: any[] = [],
      problems: any[] = [],
      title: string = '',
      parentProductId?: string
    ) => {
      if (user) {
        const appInfo = {
          title: title !== '' ? `Improved ${title}` : '',
          selected,
          problems,
          parentProduct: parentProductId
        };
        dispatch(Actions.createConcept(appInfo));
      } else {
        dispatch(Actions.openAuthModal(true));
      }
    },
    [user, dispatch]
  );

  const handleGenerateMoreButton = useCallback(async () => {
    if (generateMoreLoader || graphData.isBeingGenerated) return;
    if (user) {
      if (generateMoreLoader) return;
      setGenerateMoreLoader(true);
      try {
        dataProvider
          .generateMore(type, {
            id: graphData?.id,
            title: graphData?.title,
            key: graphData?.key,
            saveResult: true
          })
          .catch((error) => {
            console.error(error);
            setGenerateMoreLoader(false);
          });
        dispatch(Actions.getGraph(graphData?.id, type, type));
      } catch (error) {
        console.error('Error generating more:', error);
        setGenerateMoreLoader(false);
      }
    } else {
      dispatch(Actions.openAuthModal(true));
    }
  }, [user, generateMoreLoader, type, graphData, dispatch]);

  const itemTypeModelMap: Record<string, string> = useMemo(
    () => ({
      [Constants.PROBLEMS]: Constants.PROBLEM,
      [Constants.SOLUTIONS]: Constants.SOLUTION,
      [Constants.APPLICATIONS]: Constants.APPLICATION,
      [Constants.CONTESTS]: Constants.CONTEST,
      [Constants.COMPANY_PRODUCTS]: Constants.PRODUCT
    }),
    []
  );

  const hasActiveConcept = !isObjectEmpty(activeConcept);

  return graphData && Object.keys(graphData).length > 0 ? (
    <>
      {topSection && (
        <SideMenu active={itemTypeModelMap[type]} isHomePage={false} />
      )}
      <StyleBox leftMarg={topSection}>
        <StyledDrawer
          anchor="left"
          disableScrollLock
          onClose={handleDrawer}
          open={isConceptDrawerOpen}
          leftMarg={topSection}
          ref={scrollRef}
          variant="persistent"
        >
          <StyledClose>
            <IconContainer onClick={handleDrawer}>
              <IoClose size={10} color={colorPalette.purple} />
            </IconContainer>
          </StyledClose>
          {hasActiveConcept && <ConceptProgressAccordion />}
          <ActiveConceptCard itemNodesHeight="50vh" />
        </StyledDrawer>

        <StyledTopSection leftPadding={renderParent}>
          {topSection ? (
            <>
              <TopSection
                title={graphData.title}
                type={
                  graphData.type === Constants.APPLICATION
                    ? Constants.Invention
                    : graphData.type
                }
              />
              <ActiveBox>
                {!hasActiveConcept ? (
                  <>
                    <ActiveConcept>
                      {`${Constants.ACTIVE_CONCEPT}: ${activeConcept.title}`}
                    </ActiveConcept>
                  </>
                ) : (
                  <ActiveConcept>No Active Concept</ActiveConcept>
                )}
              </ActiveBox>
            </>
          ) : (
            <Box
              sx={{
                [theme.breakpoints.up('md')]: {
                  marginTop: '20px'
                }
              }}
            />
          )}

          <StyledContainer>
            <StyledSubContainer>
              {renderParent && (
                <ParentNode
                  graphData={graphData}
                  addNewConcept={addNewConcept}
                  handleDrawer={handleDrawer}
                  problemAndSolutionCreationCallBack={
                    problemAndSolutionCreationCallBack
                  }
                  progress={progress}
                  type={type}
                  openCreateProblemModal={openCreateProblemModal}
                  terminated={terminated}
                  setProblem={setProblem}
                  setIsSolutionModalOpen={setIsSolutionModalOpen}
                  setExpansionIds={setExpansionIds}
                />
              )}
              <CustomTreeView
                leftPad={renderParent}
                aria-label="customized tree"
                defaultExpandedItems={expansionIds}
                onItemFocus={onNodeFocus}
                slots={{
                  collapseIcon: CollapseIcon,
                  expandIcon: ExpandIcon
                }}
              >
                <RenderTreeItems
                  expansionIds={expansionIds}
                  fetchChildrenGraph={fetchChildrenGraph}
                  handleDrawer={handleDrawer}
                  nodes={graphData.children}
                  ideaMapType={type}
                  problemAndSolutionCreationCallBack={
                    problemAndSolutionCreationCallBack
                  }
                  selectedNode={selectedNode}
                  setExpansionIds={setExpansionIds}
                  setIsSolutionModalOpen={setIsSolutionModalOpen}
                  setProblem={setProblem}
                />
              </CustomTreeView>
            </StyledSubContainer>
          </StyledContainer>

          {type !== Constants.APPLICATIONS && (
            <GenerateMoreButtonBox>
              {aiGeneratedChildrenCount < 120 && (
                <GenerateMoreButton
                  onClick={handleGenerateMoreButton}
                  type={type}
                  disabled={generateMoreLoader || graphData.isBeingGenerated}
                >
                  {generateMoreLoader ? (
                    <CircularProgress
                      sx={{
                        color:
                          type === Constants.PROBLEMS
                            ? colorPalette.lightGreen
                            : colorPalette.torchRed
                      }}
                      size={24}
                    />
                  ) : (
                    Constants.GENERATE_MORE
                  )}
                </GenerateMoreButton>
              )}
            </GenerateMoreButtonBox>
          )}
        </StyledTopSection>

        {!openChat && type === Constants.COMPANY_PRODUCTS && (
          <ChatHelpButton
            buttonText={Constants.AI_CHAT}
            onClick={handleSetOpenChat}
          />
        )}
        {type === Constants.COMPANY_PRODUCTS && (
          <ChatBotDrawer
            graphData={graphData}
            graphType={type}
            open={openChat}
            setOpen={setOpenChat}
          />
        )}
      </StyleBox>

      {isModalOpen && (
        <ProblemModal
          problemSolutionCreationCallBack={problemAndSolutionCreationCallBack}
          isIdeaMapPopUp
          onClose={handleCloseModal}
          open={isModalOpen}
          parentNode={graphData}
          referralCode={referralCode ?? null}
        />
      )}
      {isSolutionModalOpen && (
        <SolutionModal
          isIdeaMapPopUp
          onClose={() => setIsSolutionModalOpen(false)}
          open={isSolutionModalOpen}
          parentNode={problem}
          problemSolutionCreationCallBack={problemAndSolutionCreationCallBack}
          referralCode={referralCode ?? null}
        />
      )}
      {openShareInfo && (
        <ShareInfoModal
          open={openShareInfo}
          onClose={handleCloseShareInfoModal}
        />
      )}
    </>
  ) : (
    <CircularBox>
      <CircularProgress />
    </CircularBox>
  );
};

export default NewIdeaMap;
