import React, { useCallback, useContext } from 'react';
import ModalWindow from 'modals/ModalWindow';
import { PsButton } from 'components/common/PsButton';
import { PsLogo } from 'components/common/PsLogo';
import { PsNFT } from 'components/common/PsNFT';
import { Application } from 'components/CardApplication';
import { ModalContext } from 'contexts/ModalContext';
import { ModalDataContext } from 'contexts/ModalDataContext';
import { finalizeType } from '../../helpers';
import styles from 'modals/SuccessModal/SuccessModal.module.scss';
import Typography from '@mui/material/Typography';

export const BuildPatentApplicationSuccess: React.FC = () => {
  const { closeModal } = useContext(ModalContext);
  const { values, resetFields } = useContext(ModalDataContext);
  const handleClick = useCallback(() => {
    closeModal();
    resetFields();
  }, [closeModal, resetFields]);

  const application: Application = values.application;

  return (
    <ModalWindow title="Congratulations!" titleCenter>
      <div className={styles.wrapper}>
        <div className={styles.iconHolder}>
          <PsLogo size={90} color="black" small />
          <PsNFT size={90} color="black" />
        </div>
        <br />
        <br />
        <Typography className={styles.text}>
          You minted an Idea NFT for your <b>{application.title}</b>.
        </Typography>
        {values.finalizeType === finalizeType.OPEN ? (
          <Typography className={styles.textSmall}>
            As an open source invention, your NFT provides proof of the date you
            created this invention, but no patent application will be filed
          </Typography>
        ) : (
          <Typography className={styles.textSmall}>
            Your Idea NFT will be available as soon as MindMiner files the
            patent application. MindMiner will send you a notification when the
            Idea NFT is ready and when the underlying invention becomes patent
            pending.
          </Typography>
        )}
        <Typography className={styles.textSmall}>
          You can also see the status of your Idea NFT on the invention page.
        </Typography>
        <PsButton onClick={handleClick} fullWidth>
          Close
        </PsButton>
      </div>
    </ModalWindow>
  );
};

export default BuildPatentApplicationSuccess;
