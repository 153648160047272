import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { useWeb3React } from '@web3-react/core';
import { PsButton } from 'components/common/PsButton';
import { PsFormControl, ShortEvent } from 'components/common/PsFormControl';
import { PsInput } from 'components/common/PsInput';
import { WALLET_NETWORK, chain } from 'contexts/WalletContext';
import { PsTheme } from '../../theme';
import { makeStyles } from 'tss-react/mui';

const addressRegExp = /^0x[a-fA-F0-9]{40}$/;

type ClassKey = 'root' | 'input' | 'info' | 'buttons' | 'links';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      //
    },
    input: {
      marginBottom: 5
    },
    info: {
      fontSize: 15,
      lineHeight: '1.1',
      marginBottom: 15,
      color: 'rgb(99 45 221)'
    },
    buttons: {
      paddingTop: 10,
      textAlign: 'center',
      '& button': {
        marginTop: 10,
        width: '100%',
        maxWidth: '400px'
      },
      '& button:first-child': {
        marginTop: 0
      },
      '& img': {
        width: '24px',
        height: '24px'
      }
    },
    links: {
      paddingTop: 10,
      textAlign: 'center',
      '& a': {
        color: 'rgb(99 45 221)',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline'
        }
      }
    }
  };
});

type WalletFieldProps = {
  className?: string;
  address?: string;
  onAddress?: (address: string) => void;
};

const WalletFieldView = ({
  className,
  address: initAddress,
  onAddress
}: WalletFieldProps) => {
  const { classes } = useStyles();
  const [connected, setConnected] = useState(false);
  const [address, setAddress] = useState<string>(initAddress || '');
  const [addressError, setAddressError] = useState<string>('');

  useEffect(() => {
    setAddressError('');
    setAddress(initAddress || '');
    if (initAddress) {
      setConnected(true);
    }
  }, [initAddress]);

  const onAddressChange = (e: ShortEvent) => {
    setAddress(e.target.value);
    setAddressError('');
  };

  const onSaveClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (!address || !addressRegExp.test(address)) {
      setAddressError('Address incorrect');
      return;
    }
    setConnected(true);
    if (onAddress) {
      onAddress(address);
    }
  };

  const onDisconnectClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setConnected(false);
    setAddress('');
    if (onAddress) {
      onAddress('');
    }
  };

  let rootClassName = classes.root;
  if (className) {
    rootClassName += ' ' + className;
  }

  return (
    <div className={rootClassName}>
      <PsFormControl
        className={classes.input}
        label={
          connected ? 'Your wallet address:' : 'Enter your wallet address:'
        }
        helperText={addressError}
        error={!!addressError}
        disabled={connected}
        labelLight
      >
        <PsInput
          name="walletAddress"
          value={address}
          onChange={onAddressChange}
        />
      </PsFormControl>
      {!connected && WALLET_NETWORK === chain.SEPOLIA ? (
        <div className={classes.info}>
          MindMiner is in beta! Please set your wallet to the Sepolia testnet
          chain
        </div>
      ) : null}
      {connected ? (
        <div className={classes.links}>
          <a href="#" onClick={onDisconnectClick}>
            Disconnect Wallet
          </a>
        </div>
      ) : (
        <div className={classes.buttons}>
          <PsButton onClick={onSaveClick} disabled={!address}>
            Save
          </PsButton>
        </div>
      )}
    </div>
  );
};

export const WalletField = styled(WalletFieldView)({});

export default WalletField;
