import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import AuthContext from 'contexts/AuthContext';
import { ReactionButtons, StyledLikes } from 'pages/home/StyledComponents';
import Actions from 'redux-state/actions';
import { useIsMediumScreen } from 'theme';
import { formatNumber } from 'helpers';
import { Constants, TAG_TYPES, VOTE_CRITERIA } from 'utilities/constants';
import Dislike from './Dislike';
import Like from './Like';
import { Application } from '../CardApplication';
import { Problem } from '../CardProblem';
import { Product } from '../CardProduct';
import { Solution } from '../CardSolution';
import { Contest } from '../CardContest';

interface VoteProps {
  communityTagId?: string;
  disabledButtons?: boolean;
  dislikeCount?: number;
  filters?: string[];
  isCommunityFeed?: boolean;
  item?: Problem | Solution | Application | Product | Contest | any; // adding until leaderboard module is complete
  itemType?: string;
  likeCount?: number;
  pagination?: { page: number; perPage: number };
  scale?: string;
  sortType?: string;
  tagId?: string | number;
  voteStatus?: string;
}

const Vote: React.FC<VoteProps> = ({
  communityTagId,
  disabledButtons = false,
  dislikeCount,
  filters,
  isCommunityFeed,
  item,
  itemType,
  likeCount,
  pagination,
  scale,
  sortType,
  tagId,
  voteStatus
}) => {
  const { user } = useContext(AuthContext);
  const isMediumScreen = useIsMediumScreen();
  const dispatch = useDispatch();
  const [voteStatusState, setVoteStatusState] = useState<string | null>();
  const [initialLikeCount, setInitialLikeCount] = useState<number>();

  useEffect(() => {
    setInitialLikeCount(likeCount || item?.likes || item?.likeCount || 0);
    setVoteStatusState(voteStatus);
  }, [item, likeCount, voteStatus]);

  let typeValue = '';
  if (itemType === Constants.PROBLEM) {
    typeValue = Constants.PROBLEMS;
  } else if (itemType === Constants.SOLUTION) {
    typeValue = Constants.SOLUTIONS;
  } else if (itemType === Constants.PRODUCT) {
    typeValue = Constants.PRODUCTS;
  } else if (itemType === Constants.APPLICATION) {
    typeValue = Constants.APPLICATIONS;
  } else if (itemType === Constants.CONTEST) {
    typeValue = Constants.CONTESTS;
  }

  const handleLikeDislikeAction = (item, voteType) => {
    if (user) {
      const payload = {
        pagination,
        communityTagId: isCommunityFeed ? communityTagId : null,
        tagType: isCommunityFeed ? TAG_TYPES.TAG_FEED : null,
        tagId,
        filters,
        sortType
      };

      if (voteType === 1) {
        setVoteStatusState(voteStatusState === '1' ? null : '1');
        setInitialLikeCount((prevCount) => {
          if (voteStatusState === '1') {
            return prevCount - 1;
          } else if (voteStatusState === '-1') {
            return prevCount + 1;
          } else {
            return prevCount + 1;
          }
        });
      } else if (voteType === -1) {
        setVoteStatusState(voteStatusState === '-1' ? null : '-1');
        setInitialLikeCount((prevCount) => {
          if (voteStatusState === '-1') {
            return prevCount;
          } else if (voteStatusState === '1') {
            return Math.max(0, prevCount - 1);
          } else {
            return Math.max(0, prevCount);
          }
        });
      }

      dispatch(
        Actions.voteItem(
          typeValue,
          item?._id ?? item.id,
          voteType,
          VOTE_CRITERIA.GENERAL,
          payload
        )
      );
    } else if (!user) {
      dispatch(Actions.openAuthModal(true));
    }
  };

  const getLikes = useCallback(() => {
    return formatNumber(initialLikeCount);
  }, [initialLikeCount]);

  const getDislikes = useCallback(() => {
    return formatNumber(dislikeCount || 0);
  }, [dislikeCount]);

  return (
    <ReactionButtons
      isMediumScreen={isMediumScreen}
      scale={scale}
      type={itemType}
    >
      <Like
        isLiked={voteStatusState == '1'}
        handleActionButton={() => handleLikeDislikeAction(item, 1)}
        disabledButtons={disabledButtons}
      />
      <StyledLikes isMediumScreen={isMediumScreen}>{getLikes()}</StyledLikes>
      <Dislike
        isDisliked={voteStatusState == '-1'}
        handleActionButton={() => handleLikeDislikeAction(item, -1)}
        disabledButtons={disabledButtons}
      />
      {dislikeCount && dislikeCount > 0 ? (
        <StyledLikes isMediumScreen={isMediumScreen}>
          {getDislikes()}
        </StyledLikes>
      ) : null}
    </ReactionButtons>
  );
};

export default Vote;
