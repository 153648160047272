import React from 'react';
import { colorPalette } from 'theme';

export const GreenCounter = ({
  coins,
  width = '51',
  height = '50',
  textColor = colorPalette.purple,
  coinColor = colorPalette.purple,
  bgColor = colorPalette.white
}: {
  coins: number | string;
  width?: string;
  height?: string;
  textColor?: string;
  coinColor?: string;
  bgColor?: string;
}) => (
  <div
    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 50 50"
    >
      <defs>
        <clipPath id="Counter">
          <path
            data-name="Rectangle 901"
            fill="currentColor"
            d="M0 0h40.21v40.196H0z"
          />
        </clipPath>
      </defs>
      <g data-name="Ellipse 53" fill={bgColor} stroke={bgColor}>
        <circle cx="25" cy="25" r="25" stroke="none" />
        <circle cx="25" cy="25" r="24.5" fill="none" />
      </g>
      <g data-name="Group 374">
        <text
          data-name="1"
          transform="translate(25 30)"
          fill={textColor}
          fontSize="14"
          fontFamily="Montserrat"
          fontWeight="700"
          textAnchor="middle"
        >
          <tspan x="0" y="0">
            {coins}
          </tspan>
        </text>
        <g data-name="Group 314">
          <g data-name="Group 310" fill={coinColor}>
            <path d="M16.5968 7.68412C17.8027 7.68412 18.9593 8.04095 19.9313 8.65616C21.7031 8.04095 23.598 7.69642 25.579 7.69642C27.56 7.69642 29.3072 8.01634 31.0175 8.58234C32.0388 7.88099 33.2692 7.47495 34.5735 7.47495H41.9192C42.2391 7.47495 42.559 7.42573 42.8666 7.3519C42.5836 7.0689 42.276 6.81051 41.9807 6.53982C41.6239 6.2199 41.267 5.91229 40.8979 5.62929C31.805 -1.54413 18.9593 -1.458 9.9771 5.85077C9.63257 6.13377 9.30036 6.44138 8.96814 6.74899C8.68514 7.00738 8.38984 7.25347 8.11914 7.52416C8.48827 7.6349 8.86971 7.69642 9.25114 7.70873H16.5968V7.68412Z" />
            <path d="M41.1072 17.3432H49.0927C48.9943 17.0233 48.8713 16.728 48.7605 16.4204C48.6498 16.1128 48.5514 15.8052 48.4283 15.4976C47.7516 13.8857 46.9026 12.3477 45.9059 10.9204C45.7337 10.662 45.5368 10.4282 45.3522 10.1821C45.1677 9.93602 44.9954 9.67763 44.7985 9.44385C43.9003 9.8745 42.9283 10.096 41.9316 10.1083H34.5859C34.5244 10.1083 34.4629 10.1083 34.4014 10.1206C37.2806 11.8309 39.6184 14.341 41.1195 17.3432H41.1072Z" />
            <path d="M50.1035 21.8959C50.0666 21.5883 50.0174 21.2807 49.9681 20.9731C49.9189 20.6655 49.882 20.3579 49.8328 20.0503H42.2041C42.6717 21.6252 42.9424 23.2986 42.9424 25.0212C42.9424 26.7439 42.684 28.368 42.2287 29.9184H49.8574C49.9189 29.6231 49.9435 29.3032 49.9927 28.9955C50.042 28.6879 50.0912 28.3803 50.1281 28.0727C50.2634 27.0638 50.325 26.0425 50.3373 25.0212C50.3373 23.9754 50.2511 22.9295 50.1158 21.8959H50.1035Z" />
            <path d="M41.1434 32.6377C39.6668 35.6523 37.3536 38.1623 34.499 39.8849C34.5236 39.8849 34.5482 39.8849 34.5728 39.8849H41.9308C42.9398 39.8849 43.9364 40.1187 44.8347 40.5617C45.0315 40.3156 45.2038 40.0695 45.3884 39.8234C45.5729 39.5773 45.7698 39.3313 45.9421 39.0729C46.9387 37.6333 47.7754 36.0952 48.4521 34.4833C48.5752 34.1757 48.6736 33.8681 48.7844 33.5605C48.8951 33.2529 49.0181 32.9576 49.1166 32.6377H41.1434Z" />
            <path d="M34.5739 42.5176C33.3065 42.5176 32.1007 42.1362 31.0794 41.4595C29.3445 42.0378 27.4989 42.37 25.5794 42.37C23.6599 42.37 21.8635 42.0501 20.1532 41.4841C19.1319 42.1854 17.9015 42.5915 16.5972 42.5915H9.25154C8.93162 42.5915 8.62401 42.6284 8.31641 42.7022C8.59941 42.9852 8.90701 43.2436 9.21462 43.5143C9.57145 43.8342 9.92827 44.1418 10.3097 44.4371C13.0536 46.6027 16.2281 48.153 19.6241 49.002C20.5715 49.2358 21.519 49.4204 22.491 49.5434C23.5246 49.6788 24.5581 49.7526 25.5917 49.7649H25.604C26.6499 49.7649 27.6834 49.6911 28.717 49.5434C33.2081 48.9651 37.4531 47.1687 40.9967 44.351C41.3536 44.0557 41.7104 43.7481 42.0549 43.4528C42.3502 43.1944 42.6455 42.936 42.9285 42.653C42.5963 42.5669 42.2641 42.5176 41.9196 42.5176H34.5862H34.5739Z" />
            <path d="M10.067 32.7115H2.09375C2.19218 33.0314 2.31523 33.3267 2.42597 33.6343C2.53671 33.942 2.63514 34.2619 2.75818 34.5572C3.43492 36.169 4.28392 37.6948 5.29288 39.1221C5.47744 39.3805 5.66201 39.6143 5.84657 39.8603C6.03114 40.1064 6.2034 40.3648 6.40027 40.6109C7.29849 40.1803 8.27053 39.9588 9.26718 39.9588H16.6006C16.6006 39.9588 16.7113 39.9465 16.7728 39.9342C13.8936 38.2239 11.5558 35.7138 10.0547 32.6992L10.067 32.7115Z" />
            <path d="M8.24256 25.0337C8.24256 23.3849 8.48865 21.7976 8.9193 20.2842H1.31522C1.2537 20.5795 1.21679 20.8994 1.17987 21.207C1.14296 21.5146 1.06913 21.8222 1.04453 22.1421C0.909178 23.0896 0.847656 24.0616 0.847656 25.0214C0.847656 26.141 0.946091 27.2607 1.10605 28.3681L1.21679 29.0695C1.266 29.3648 1.30292 29.6847 1.35213 29.9923H8.98082C8.51326 28.4173 8.25487 26.7563 8.25487 25.0214L8.24256 25.0337Z" />
            <path d="M16.4502 10.3173H9.26444C8.23088 10.3173 7.19731 10.0589 6.27449 9.59131C6.08992 9.82509 5.91766 10.0712 5.7454 10.305C5.57314 10.5387 5.36397 10.7848 5.19171 11.0432C4.19505 12.4951 3.34606 14.0455 2.66932 15.6697C2.54627 15.965 2.43553 16.2849 2.3248 16.5925C2.21406 16.9001 2.10332 17.2569 2.00488 17.5645H9.96579C11.3931 14.5746 13.6571 12.0522 16.4625 10.3173H16.4502Z" />
          </g>
        </g>
      </g>
    </svg>
  </div>
);
