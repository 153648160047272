import React, { useContext, useState, useEffect } from 'react';
import { styled, useTheme, alpha } from '@mui/material/styles';
import { Radio } from 'components/Radio/Radio';
import {
  ModalDataContext,
  StepComponentProps,
  StepComponentType
} from 'contexts/ModalDataContext';
import { PsTheme } from '../../../theme';
import styles from 'modals/ModalWindow.module.scss';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

type ImproveOption = {
  value: string;
  text: string;
};

const categories = [
  { value: 'Wastes', text: 'Reduce Waste' },
  { value: 'Strengths', text: 'Increase Value' }
];

const RadioText = ({ option }: { option: ImproveOption }) => {
  return (
    <span className={styles.improveRow}>
      <span className={styles.improveCellTitle}>{option.text}</span>
    </span>
  );
};

const useRadioStyles = makeStyles()(() => {
  const theme = useTheme();
  return {
    root: {
      borderRadius: 8,
      margin: '8px 0',
      border: `solid 1px ${alpha(theme.palette.primary.main, 0.15)}`,
      background: 'rgb(248, 245, 255, 0.5)',
      color: theme.palette.primary.main,
      '&:hover': {
        border: `solid 1px ${alpha(theme.palette.primary.main, 0.5)}`
      }
    }
  };
});

type ClassKey = 'root' | 'error';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {},
    error: {
      color: 'red'
    }
  };
});

const ChooseTypeView = () => {
  const { values, errors, setErrors, updateField } =
    useContext(ModalDataContext);
  const { classes } = useStyles();
  const { classes: radioClasses } = useRadioStyles();
  const [selectedEntity, setSelectedEntity] = useState<string | undefined>(
    `${values.description}`
  );

  const handleEntityChange = (e: React.ChangeEvent, value: string) => {
    setSelectedEntity(value);
    setErrors({});
    updateField('description', value);
  };

  return (
    <div className={styles.stepWrapper}>
      <Typography variant="h6">Choose category</Typography>
      <RadioGroup
        onChange={handleEntityChange}
        value={selectedEntity}
        aria-label="entity"
        name="entity"
      >
        {categories.map((option) => {
          return (
            <FormControlLabel
              className={styles.improveOption}
              key={option.value}
              classes={radioClasses}
              value={option.value}
              control={<Radio />}
              label={<RadioText option={option} />}
            />
          );
        })}
      </RadioGroup>

      {errors.description ? (
        <Typography className={classes.error}>{errors.description}</Typography>
      ) : null}
    </div>
  );
};

export const ChooseTypeStep = styled(ChooseTypeView)(
  {}
) as StepComponentType<StepComponentProps>;

export default ChooseTypeStep;
