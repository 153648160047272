import React from 'react';
import { Box, Grid, Stepper, Step } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import SimpleIconButton from 'components/SimpleIconButton';
import {
  ActiveStepLabel,
  StyledHeaderText,
  StyledHeaderWrapper,
  StyledSubHeaderText
} from './StyledComponents';
import { Constants } from 'utilities/constants';

interface HeaderProps {
  activeStep?: number;
  label?: string | JSX.Element;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  parentNodeTitle?: string;
  showCrossIcon?: boolean;
  subHeading?: string | JSX.Element;
}

const steps = [
  Constants.CHOOSE_CATEGORY,
  Constants.DEFINE_PROBLEM,
  Constants.CREATE_SOLUTION
];

export const Header: React.FC<HeaderProps> = ({
  activeStep,
  label,
  onClose,
  parentNodeTitle,
  showCrossIcon
}) => {
  return (
    <Box>
      <StyledHeaderWrapper>
        {label && <StyledHeaderText variant="h5">{label}</StyledHeaderText>}
        {showCrossIcon && onClose && (
          <SimpleIconButton
            Icon={CancelOutlinedIcon}
            color="primary"
            onClick={onClose}
          />
        )}
      </StyledHeaderWrapper>

      <Grid container>
        <Grid item xs={6}>
          <StyledSubHeaderText variant="h6">
            {parentNodeTitle}
          </StyledSubHeaderText>
        </Grid>

        <Grid item xs={6}>
          <Box>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label, index) => (
                <Step key={index}>
                  <ActiveStepLabel>{label}</ActiveStepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
