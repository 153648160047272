import React from 'react';
export const FeedIcon = ({
  size = 24,
  color = '#5C30D5',
  className
}: {
  size?: number;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
      data-testid="ViewStreamIcon"
    >
      <path
        d="M3 17v-2c0-1.1.9-2 2-2h14c1.1 0 2 .9 2 2v2c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2zM3 7v2c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2z"
        fill={color}
      ></path>
    </svg>
  );
};

export default FeedIcon;
