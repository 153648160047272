import React, { useState, useContext } from 'react';
import { ModalDataContext } from 'contexts/ModalDataContext';
import { DataContext, PayProduct } from 'contexts/DataContext';
import { PsButton } from 'components/common/PsButton';
import { finalizeType } from '../../../helpers';
import styles from 'modals/ModalWindow.module.scss';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import AgreementDocModal from 'modals/AgreementDocument/AgreementDocModal';
import { Application } from 'components/CardApplication';
import Config from 'config/config';

export const productSignStatus = {
  PREPARE: 'prepare',
  SIGNED: 'completed'
};

const IP_ADDRESS_PROVIDER_URL: string =
  Config.NEXT_IP_ADDRESS_PROVIDER_URL || '';

export const SignDocumentsStep: React.FC = () => {
  const { values, updateField } = useContext(ModalDataContext);
  const { dataProvider } = useContext(DataContext);
  const [loading, setLoading] = useState(false);
  const [showDocModal, setShowDocModal] = useState<boolean>(false);
  const [productId, setProductId] = useState<any>('');
  const [signedUrl, setSignedUrl] = useState<any>('');

  const products: Array<PayProduct> = values.payProducts || [];

  const onSignClick = (product: PayProduct) => {
    setShowDocModal(true);
    setProductId(product.productId);
  };

  const getIpAddress = () => {
    return fetch(IP_ADDRESS_PROVIDER_URL)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        return data.ip;
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
        return ''; // Return an empty string in case of error
      });
  };

  const onSave = async (sign: string) => {
    const ipAddress = await getIpAddress();
    if (sign && productId && ipAddress) {
      const appId = values.application ? values.application.id : '';
      setLoading(true);
      dataProvider
        .payOptionSign(appId, sign, ipAddress)
        .then((data) => {
          dataProvider
            .getOne<Application>('applications', { id: appId })
            .then(({ data1 = {} }: any) => {
              updateField('payProducts', data1.products || []);
              setSignedUrl(data.link);
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    }
  };

  return (
    <div className={styles.stepWrapper}>
      {values.finalizeType === finalizeType.OPEN ? (
        <div>Are you sure?</div>
      ) : (
        <>
          <table className={styles.signTable}>
            <tbody>
              {products.map((product) => {
                const onClick = (e: React.MouseEvent) => {
                  e.preventDefault();
                  onSignClick(product);
                };
                return (
                  <tr key={product.productId}>
                    <td className={styles.signTableTitle}>
                      {product.title || '(empty)'}
                    </td>
                    <td className={styles.signTableBtn}>
                      {product.status === productSignStatus.SIGNED ||
                      signedUrl ? (
                        <Typography color="secondary">Signed</Typography>
                      ) : (
                        <>
                          <Typography className={styles.text}>
                            Link Invention to NFT
                          </Typography>
                          <PsButton
                            onClick={onClick}
                            color="secondary"
                            disabled={!!loading}
                            smallest
                          >
                            <CircularProgress
                              className={`${styles.signTableBtnLoader} ${
                                loading ? styles.signTableBtnLoaderActive : ''
                              }`}
                              size={20}
                              thickness={4}
                            />
                            <span
                              className={`${
                                loading ? styles.signTableBtnTextHide : ''
                              }`}
                            >
                              {values.brainMethod === 'coinbase'
                                ? 'Sign'
                                : 'Sign & Pay'}
                            </span>
                          </PsButton>
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      )}
      <AgreementDocModal
        applicationId={values?.application?.id}
        loading={loading}
        onSave={onSave}
        setLoading={setLoading}
        setShowDocModal={setShowDocModal}
        showDocModal={showDocModal}
        signedUrl={signedUrl}
      />
    </div>
  );
};

export default SignDocumentsStep;
