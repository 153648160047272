import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

interface LogoProps extends SvgIconProps {
  width?: number | string;
  height?: number | string;
}

export const LinkedinIcon: React.FC<LogoProps> = ({
  width = '1.8rem',
  height = '1.8rem',
  ...props
}) => {
  return (
    <SvgIcon sx={{ width: width, height: height }} viewBox="0 0 176 176">
      <g id="Layer_2" data-name="Layer 2">
        <g id="_07.linkedin" data-name="07.linkedin">
          <circle id="background" cx="88" cy="88" fill="#0077b5" r="88" />
          <g id="icon" fill="#fff">
            <path d="m63.4 48a15 15 0 1 1 -15-15 15 15 0 0 1 15 15z" />
            <path d="m60 73v66.27a3.71 3.71 0 0 1 -3.71 3.73h-15.81a3.71 3.71 0 0 1 -3.72-3.72v-66.28a3.72 3.72 0 0 1 3.72-3.72h15.81a3.72 3.72 0 0 1 3.71 3.72z" />
            <path d="m142.64 107.5v32.08a3.41 3.41 0 0 1 -3.42 3.42h-17a3.41 3.41 0 0 1 -3.42-3.42v-31.09c0-4.64 1.36-20.32-12.13-20.32-10.45 0-12.58 10.73-13 15.55v35.86a3.42 3.42 0 0 1 -3.37 3.42h-16.42a3.41 3.41 0 0 1 -3.41-3.42v-66.87a3.41 3.41 0 0 1 3.41-3.42h16.42a3.42 3.42 0 0 1 3.42 3.42v5.78c3.88-5.82 9.63-10.31 21.9-10.31 27.18 0 27.02 25.38 27.02 39.32z" />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
