import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { CloudUpload, InsertPhoto } from '@mui/icons-material';
import { Box, CircularProgress, IconButton } from '@mui/material';
import { ShortEvent } from 'components/common/PsFormControl';
import { StyledIconButten } from 'components/common/StyledComponents';
import { AiTitleIcon } from 'components/icons/AiTitleIcon';
import { ToolTip } from 'components/ToolTip';
import { convertFileToBase64, Picture } from 'helpers';
import Actions from 'redux-state/actions';
import {
  GetAiTitle,
  GetAiTitleLoader,
  GetGeneratedDescription,
  GetGeneratedImage,
  GetGenerateImageLoader
} from 'redux-state/selectors';
import { toastify } from 'pages/newContests/toastify';
import { colorPalette } from 'theme';
import { Constants, VARIANT } from 'utilities/constants';
import { Application } from '../CardApplication';
import {
  AiIconBox,
  DescriptionBox,
  DetailsBox,
  DetailsMainBox,
  FeatureImage,
  ImageBox,
  ImageIconsBox,
  ImageMainBox,
  NameFieldBox,
  NameFieldMainBox,
  StyledBodyFieldText,
  StyledBodyTypography,
  StyledIconButton,
  StyledImageText,
  StyledLoopIcon,
  StyledUploadButtonContainer
} from './styles';
interface ConceptUpdateObj {
  title: string;
  teaser: string;
  files?: Array<Picture>;
}

interface OverviewConceptProps {
  archiveStatus?: boolean;
  concept?: Application;
  pagination?: { page: number; perPage: number };
  profileId?: string | number;
  setConceptUpdateObj?: Dispatch<SetStateAction<unknown>>;
}

export const OverviewConcept: React.FC<OverviewConceptProps> = ({
  archiveStatus,
  concept,
  pagination,
  profileId,
  setConceptUpdateObj
}) => {
  const aiTitle = GetAiTitle();
  const aiTitleLoader = GetAiTitleLoader();
  const generatedImage = GetGeneratedImage();
  const aiImageLoader = GetGenerateImageLoader();
  const aiDescription = GetGeneratedDescription();

  const [picture, setPicture] = useState<Picture | undefined>(
    generatedImage == null || generatedImage == undefined
      ? concept?.files[0]
        ? concept?.files[0]
        : null
      : {
          url: generatedImage,
          contentType: 'image/jpeg',
          title: 'generated ai image'
        }
  );
  const [title, setTitle] = useState<string>(aiTitle ? aiTitle : concept.title);
  const [descr, setDescr] = useState<string>(
    aiDescription ? aiDescription : concept.teaser
  );

  const dispatch = useDispatch();

  const { acceptedFiles, open } = useDropzone({
    multiple: false,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/gif': ['.gif'],
      'image/svg+xml': ['.svg']
    }
  });

  useEffect(() => {
    const newObj: ConceptUpdateObj = {
      title: title,
      teaser: descr
    };

    if (picture) {
      newObj.files = [picture];
    }

    setConceptUpdateObj(newObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, descr, picture]);

  useEffect(() => {
    if (!acceptedFiles.length) {
      return;
    }
    Promise.all(acceptedFiles.map(convertFileToBase64)).then(([item]) => {
      const { file, base64 } = item;
      const picture = {
        contentType: file.type,
        title: file.name,
        url: base64
      };
      setPicture(picture);
    });
  }, [acceptedFiles]);

  useEffect(() => {
    if (generatedImage !== null && generatedImage != undefined) {
      setPicture({
        url: generatedImage,
        contentType: 'image/jepg',
        title: 'generated'
      });
    }
  }, [generatedImage]);

  useEffect(() => {
    if (aiTitle) {
      setTitle(aiTitle);
      dispatch(
        Actions.getProfileConcepts(profileId, pagination, null, archiveStatus)
      );
      dispatch(Actions.getAiTitleSuccess({ title: '' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aiTitle]);

  useEffect(() => {
    if (aiDescription) {
      setDescr(aiDescription);
    }
  }, [aiDescription]);

  const handleTitleGenerateByAI = async () => {
    if (concept) {
      if (concept?.solutions?.length < 2) {
        toastify(
          Constants.ADD_SOLUTIONS_TO_CONCEPT,
          VARIANT.INFO,
          VARIANT.BOTTOM_LEFT,
          2000
        );
        return;
      }
      dispatch(Actions.getAiTitle({ conceptId: concept.id }));
      dispatch(
        Actions.generateDescriptionFromAI({
          title: title,
          type: Constants.CONCEPTS,
          additionalInfo: {
            problems: concept.problems,
            solutions: concept.solutions
          }
        })
      );
    } else {
      alert(Constants.ERROR_GET_ANSWER_FROM_AI);
    }
  };

  const onContainerClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      if (!picture) {
        open();
      }
    },
    [open, picture]
  );

  const onInsertPhotoClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      open();
    },
    [open]
  );

  const onGenerateClick = () => {
    if (concept?.solutions?.length < 2) {
      toastify(
        Constants.ADD_SOLUTIONS_TO_CONCEPT,
        VARIANT.INFO,
        VARIANT.BOTTOM_LEFT,
        2000
      );
      return;
    }
    dispatch(
      Actions.generateImageFromAI({
        id: concept.id,
        title,
        description: descr,
        type: Constants.CONCEPTS
      })
    );
  };

  const onTitleChange = useCallback((e: ShortEvent) => {
    setTitle(e.target.value);
  }, []);

  const onDescrChange = useCallback((e: ShortEvent) => {
    setDescr(e.target.value);
  }, []);

  return (
    <Box>
      <NameFieldMainBox>
        <NameFieldBox>
          <StyledBodyTypography>{Constants.CONCEPT_NAME}</StyledBodyTypography>
          <StyledBodyFieldText value={title} onChange={onTitleChange} />
        </NameFieldBox>
        <AiIconBox>
          {aiTitleLoader ? (
            <CircularProgress size="22px" />
          ) : (
            <ToolTip
              placement="top"
              color="primary"
              title={Constants.GENRATE_TITLE_DESCRIPTION_FROM_AI}
              Icon={
                <IconButton onClick={handleTitleGenerateByAI}>
                  <AiTitleIcon size={30} />
                </IconButton>
              }
            />
          )}
        </AiIconBox>
      </NameFieldMainBox>
      <DetailsMainBox>
        <DetailsBox>
          <DescriptionBox>
            <StyledBodyTypography>
              {Constants.CONCEPT_DESCRIPTION}
            </StyledBodyTypography>
            <StyledBodyFieldText
              rows={6}
              multiline
              value={descr}
              onChange={onDescrChange}
            />
          </DescriptionBox>
          <ImageMainBox>
            <StyledBodyTypography>
              {Constants.CONCEPT_FEATURE_IMAGE}
            </StyledBodyTypography>
            <ImageBox>
              {picture?.url ? (
                <FeatureImage
                  src={picture.url}
                  alt={Constants.CONCEPT_FEATURE_IMAGE}
                />
              ) : (
                <StyledUploadButtonContainer onClick={onContainerClick}>
                  <StyledIconButten>
                    <CloudUpload
                      sx={{ color: colorPalette.spanishGray, fontSize: '50px' }}
                    />
                  </StyledIconButten>
                  <StyledImageText>
                    {Constants.UPLOAD_OR_GENERATE_AI_IMAGE}
                  </StyledImageText>
                </StyledUploadButtonContainer>
              )}
            </ImageBox>
          </ImageMainBox>
        </DetailsBox>
        <ImageIconsBox>
          <StyledIconButton onClick={onGenerateClick}>
            <StyledLoopIcon loading={aiImageLoader} />
          </StyledIconButton>
          <StyledIconButton onClick={onInsertPhotoClick}>
            <InsertPhoto sx={{ color: colorPalette.purple }} />
          </StyledIconButton>
        </ImageIconsBox>
      </DetailsMainBox>
    </Box>
  );
};
