import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { styled, useTheme } from '@mui/material/styles';
import { PsButton } from 'components/common/PsButton';
import Actions from 'redux-state/actions';
import { GetUser } from 'redux-state/selectors';
import { PsTheme } from 'theme';
import { makeStyles } from 'tss-react/mui';
import { Constants } from 'utilities/constants';
import { Tag } from '../CardTag';

type ClassKey = 'root';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {}
  };
});

type FollowBtnProps = {
  actionType?: string;
  className?: string;
  id: string | number;
  onClick?: () => void;
  tag?: Tag;
  type: 'profile' | 'tag';
};

const FollowBtnView = ({
  className,
  type,
  id,
  tag,
  onClick,
  actionType
}: FollowBtnProps) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [subscribed, setSubscribed] = useState<boolean>(false);
  const user = GetUser();

  useEffect(() => {
    setSubscribed(actionType === Constants.UNFOLLOW);
  }, [actionType]);

  const onBtnClick = (e: React.MouseEvent) => {
    if (user) {
      e.preventDefault();
      const newSubscribedStatus = !subscribed;
      setSubscribed(newSubscribedStatus);
      onClick();
    } else {
      dispatch(Actions.openAuthModal(true));
    }
  };

  let rootClassName = classes.root;
  if (className) {
    rootClassName += ' ' + className;
  }

  if (!user || (type === 'profile' && id === user.id)) {
    return null;
  }

  return (
    <PsButton className={rootClassName} onClick={onBtnClick}>
      {subscribed ? 'Unfollow' : 'Follow'}
    </PsButton>
  );
};

export const FollowBtn = styled(FollowBtnView)({});

export default FollowBtn;
