import { styled } from '@mui/system';
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  TextField,
  Button,
  Typography
} from '@mui/material';

export const StyledDialog = styled(Dialog)({
  '& .MuiDialogTitle-root': {
    fontWeight: 'bold',
    fontSize: '1.5rem'
  },
  '& .MuiDialogContent-root': {
    paddingBottom: '20px'
  }
});

export const StyledDialogTitle = styled(DialogTitle)({
  fontWeight: 'bold',
  fontSize: '1.5rem'
});

export const StyledDialogContentText = styled(DialogContentText)({
  marginBottom: '16px'
});

export const StyledTextField = styled(TextField)({
  marginBottom: '16px'
});

export const StyledButton = styled(Button)({
  marginTop: '16px',
  backgroundColor: '#3f51b5',
  color: 'white',
  '&:hover': {
    backgroundColor: '#303f9f'
  }
});

export const StyledTypography = styled(Typography)({
  marginBottom: '16px'
});
