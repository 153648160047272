import { Problem } from 'components/CardProblem';
import { GetListResult } from 'dataPrvider';
import { Contest } from 'components/CardContest';
import { GetContestPayload } from './interface';
import {
  GENERATE_AI_COVER_IMAGE_FAIL,
  GENERATE_AI_COVER_IMAGE_SUCCESS,
  GENERATE_AI_COVER_IMAGE,
  GENERATE_AI_DESCRIPTION_FAIL,
  GENERATE_AI_DESCRIPTION_SUCCESS,
  GENERATE_AI_DESCRIPTION,
  GENERATE_AI_IMAGE_FAIL,
  GENERATE_AI_IMAGE_SUCCESS,
  GENERATE_AI_IMAGE,
  GET_CONTESTS_SUCCESS,
  GET_CONTESTS,
  SET_CONTEST_DATA,
  SET_PROBLEMS,
  SET_SOLUTIONS_WITH_OWNER_INFO,
  TOGGLE_LOADER_LEADERBOARD
} from './types';

const INITIAL_STATE = {
  contest: {},
  contests: [],
  contestsLoading: false,
  generatedCoverImage: null,
  generatedCoverImageLoading: false,
  generatedDescription: null,
  generatedDescriptionLoading: false,
  generatedImage: null,
  generatedImageLoading: false,
  leaderboardLoader: false,
  problems: [],
  solutions: null
};

type Action =
  | { type: typeof SET_CONTEST_DATA; payload: { data: Contest } }
  | { type: typeof SET_PROBLEMS; payload: { data: Problem[] } }
  | {
      type: typeof SET_SOLUTIONS_WITH_OWNER_INFO;
      payload: { data: GetListResult };
    }
  | { type: typeof TOGGLE_LOADER_LEADERBOARD; payload: boolean }
  | { type: typeof GET_CONTESTS; payload: GetContestPayload }
  | { type: typeof GET_CONTESTS_SUCCESS; payload: any }
  | { type: typeof GENERATE_AI_DESCRIPTION; payload: any }
  | { type: typeof GENERATE_AI_DESCRIPTION_SUCCESS; payload: any }
  | { type: typeof GENERATE_AI_DESCRIPTION_FAIL; payload: any }
  | { type: typeof GENERATE_AI_IMAGE; payload: any }
  | { type: typeof GENERATE_AI_IMAGE_SUCCESS; payload: any }
  | { type: typeof GENERATE_AI_IMAGE_FAIL; payload: any }
  | { type: typeof GENERATE_AI_COVER_IMAGE; payload: any }
  | { type: typeof GENERATE_AI_COVER_IMAGE_SUCCESS; payload: any }
  | { type: typeof GENERATE_AI_COVER_IMAGE_FAIL; payload: any };

const reducer = (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case GET_CONTESTS:
      return { ...state, contestsLoading: true };
    case GET_CONTESTS_SUCCESS:
      return { ...state, contests: action.payload, contestsLoading: false };
    case SET_CONTEST_DATA:
      return { ...state, contest: action.payload.data };
    case SET_PROBLEMS:
      return { ...state, problems: action.payload.data };
    case SET_SOLUTIONS_WITH_OWNER_INFO:
      return { ...state, solutions: action.payload.data };
    case GENERATE_AI_DESCRIPTION:
      return { ...state, generatedDescriptionLoading: true };
    case GENERATE_AI_DESCRIPTION_SUCCESS:
      return {
        ...state,
        generatedDescription: action.payload.text,
        generatedDescriptionLoading: false
      };
    case GENERATE_AI_DESCRIPTION_FAIL:
      return { ...state, generatedDescriptionLoading: false };
    case GENERATE_AI_IMAGE:
      return { ...state, generatedImageLoading: true };
    case GENERATE_AI_IMAGE_SUCCESS:
      return {
        ...state,
        generatedImage: action.payload.image,
        generatedImageLoading: false
      };
    case GENERATE_AI_IMAGE_FAIL:
      return { ...state, generatedImageLoading: false };
    case GENERATE_AI_COVER_IMAGE:
      return { ...state, generatedCoverImageLoading: true };
    case GENERATE_AI_COVER_IMAGE_SUCCESS:
      return {
        ...state,
        generatedCoverImage: action.payload.image,
        generatedCoverImageLoading: false
      };
    case GENERATE_AI_COVER_IMAGE_FAIL:
      return { ...state, generatedCoverImageLoading: false };
    case TOGGLE_LOADER_LEADERBOARD:
      return { ...state, leaderboardLoader: action.payload };
    default:
      return state;
  }
};

export default reducer;
