import React from 'react';
import { useDispatch } from 'react-redux';
import { AiImageIcon } from 'components/icons/AiImageIcon';
import { Node } from 'interface/common';
import Actions from 'redux-state/actions';
import { GetGenerateImageLoader } from 'redux-state/selectors';
import { Constants } from 'utilities/constants';
import { StyledGrid, StyledIconButton } from '../StyledComponents';

interface ContentProps {
  body: string;
  item: Node;
  parentProblemTitle: string;
  title: string;
  votingPanel: React.ReactNode;
}

export const GenerationButton: React.FC<ContentProps> = ({
  body,
  item,
  parentProblemTitle,
  title,
  votingPanel
}) => {
  const aiImageLoader = GetGenerateImageLoader();
  const dispatch = useDispatch();

  const onGenerateClick = () => {
    dispatch(
      Actions.generateImageFromAI({
        id: item?.id,
        title: title,
        description: body,
        type: Constants.SOLUTIONS,
        additionalInfo: {
          parentProblem: parentProblemTitle
        }
      })
    );
  };

  return (
    <StyledGrid>
      <StyledIconButton disabled={aiImageLoader} onClick={onGenerateClick}>
        <AiImageIcon />
      </StyledIconButton>
      {votingPanel}
    </StyledGrid>
  );
};
