import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, TextField, styled } from '@mui/material';
import { colorPalette } from 'theme';

export const StyledTextField = styled(TextField)<{
  fillColor: string;
  borderColorOnHover: string;
}>(({ fillColor, borderColorOnHover }) => ({
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  '& input:-webkit-autofill': {
    WebkitBoxShadow: `0 0 0 1000px ${fillColor} inset`,
    WebkitColor: borderColorOnHover,
    WebkitTextFillColor: colorPalette.black
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: borderColorOnHover,
    border: `1px solid ${borderColorOnHover}`,
    boxShadow: `0px 0px 10px 0px ${borderColorOnHover}25`
  }
}));

export const FieldWrapper = styled(Box)({
  marginBottom: '0.5rem'
});

export const VisibilityIcon = styled(Visibility)({
  fontSize: '1.25rem'
});

export const VisibilityOffIcon = styled(VisibilityOff)({
  fontSize: '1.25rem'
});
