import { Search as SearchIcon } from '@mui/icons-material';
import { Box, styled, Typography } from '@mui/material';
import { colorPalette } from 'theme';

export const SuggestionWrapper = styled(Box)({
  borderBottom: '1px solid lightgray',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'left',
  padding: '.625rem 1.25rem .625rem .625rem'
});

export const AutoSuggestWrapper = styled(Box)<{ isMediumScreen?: boolean }>(
  ({ isMediumScreen }) => ({
    width: '100%',
    '& .react-autosuggest__suggestions-container--open': {
      height: isMediumScreen ? '50vh' : '60vh',
      overflow: 'auto'
    }
  })
);

export const TitleBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between'
});

export const ButtonsWrapper = styled(Box)<{ hasTags?: boolean }>(
  ({ hasTags }) => ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: hasTags ? 'space-between' : 'flex-end',
    marginTop: '.5rem',
    rowGap: '.5rem'
  })
);

export const SuggestionContent = styled(Box)<{ hasLabel?: boolean }>(
  ({ hasLabel }) => ({
    cursor: !hasLabel ? 'pointer' : 'default',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '.625rem',
    width: '100%'
  })
);

export const StyledSearchIconBox = styled(Box)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    alignItems: 'center',
    background: colorPalette.greenHaze,
    borderRadius: '6.4px',
    color: 'white',
    display: 'flex',
    height: isMediumScreen ? '2.5rem' : '1.875rem',
    justifyContent: 'center',
    margin: 0,
    position: 'absolute',
    right: isMediumScreen ? '.7rem' : '.625rem',
    top: isMediumScreen ? '.375rem' : '5px',
    width: isMediumScreen ? '2.5rem' : '1.875rem'
  })
);

export const SuggestionTitle = styled(Typography)({
  display: '-webkit-box',
  lineHeight: 1.25,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: '2'
});

export const StyledSearchIcon = styled(SearchIcon)({
  height: '1.375rem',
  width: '1.375rem',
  '@media (max-width: 56.25rem)': {
    height: '1.625rem',
    width: '1.625rem'
  }
});
