import { Box, CircularProgress, Typography, styled } from '@mui/material';
import { colorPalette } from 'theme';

export const StyledHeaderText = styled(Typography)({
  fontWeight: 'bold'
});

export const StyledHeaderWrapper = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between'
});

export const StyledDescriptionExampleText = styled(Typography)({
  color: colorPalette.grey,
  fontSize: '12px',
  fontWeight: '500',
  margin: '0.2rem 0 0'
});

export const StyledCounterButtonMainWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end'
});

export const StyledCircularProgress = styled(CircularProgress)({
  left: '50%',
  marginLeft: '-0.75rem',
  marginTop: '-0.75rem',
  position: 'absolute',
  top: '50%'
});
