import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  styled,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import AvatarGroup from '@mui/material/AvatarGroup';
import { colorPalette } from 'theme';

export const Highlight = styled(Box)({
  color: `${colorPalette.purple}`,
  cursor: 'pointer',
  display: 'inline-block',
  fontSize: '0.625rem',
  fontWeight: 700,
  textDecoration: 'underline',
  verticalAlign: 'baseline'
});

export const StyledTagsContainer = styled(Container)(
  ({ isFeed }: { isFeed?: boolean }) => ({
    backgroundColor: colorPalette.white,
    borderRadius: '0.5rem',
    boxShadow: `0 0.0625rem 0.4375rem ${colorPalette.ebb}`,
    height: 'fit-content',
    marginTop: '1.5rem',
    padding: '0.75rem',
    width: isFeed ? 'calc(25vw - 45px)' : '100%'
  })
);

export const StyledTagsButtonContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column'
});

export const StyledTagButton = styled(Button)({
  fontWeight: 600,
  textTransform: 'capitalize'
});

export const StyledTabs = styled(Tabs)(() => ({
  width: '100%'
}));

export const StyledTabLabel = styled(Typography)(
  ({ isFeed }: { isFeed?: boolean }) => ({
    fontSize: isFeed ? '0.6875rem' : '0.8125rem',
    fontWeight: 600,
    lineHeight: '1.2',
    whiteSpace: 'nowrap'
  })
);

export const StyledTab = styled(Tab)(({ isFeed }: { isFeed?: boolean }) => ({
  alignItems: 'center',
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  minHeight: '2.5rem !important',
  minWidth: isFeed ? '25%' : 'auto',
  paddingLeft: '2.1875rem',
  paddingRight: '2.1875rem',
  position: 'relative',
  textTransform: 'none',
  '& .tab-label': {
    color: colorPalette.grey,
    fontSize: '1rem',
    marginLeft: '0.5rem'
  },
  '&.Mui-selected': {
    '& .tab-label': {
      color: colorPalette.purple
    }
  }
}));

export const StyledText = styled(Typography)(
  ({ isFeed }: { isFeed?: boolean }) => ({
    cursor: 'pointer',
    fontSize: isFeed ? '1rem' : '0.875rem',
    fontWeight: 600,
    lineHeight: '1.2',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    width: '70%',
    ':hover': { textDecoration: 'underline' }
  })
);

export const StyledIcon = styled(Avatar)({
  height: '0.625rem',
  width: '1.125rem'
});

export const CommunityAvatar = styled(Avatar)(() => ({
  height: '2.875rem',
  marginTop: '0.25rem',
  width: '2.875rem'
}));

export const StyledTagsSubContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between'
});

export const StyledTagsViewDivider = styled(Divider)({
  marginBottom: '0.5rem',
  marginTop: '0.5rem'
});

export const StyledStatsContent = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between'
});

export const StyledAvatarGroup = styled(AvatarGroup)({
  color: colorPalette.purple
});

export const OverflowAvater = styled(Avatar)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: colorPalette.purple,
  border: '0.125rem',
  color: 'white',
  cursor: 'pointer',
  display: 'flex',
  fontSize: '0.625rem',
  fontWeight: 'bold',
  height: theme.spacing(3),
  justifyContent: 'center',
  paddingLeft: '0.2rem',
  width: theme.spacing(3)
}));

export const StyledTypography = styled(Typography)({
  color: colorPalette.purple,
  fontSize: '0.75rem',
  fontWeight: '600'
});

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: colorPalette.lavenderPurple,
  border: '0.125rem',
  borderColor: colorPalette.white,
  color: 'inherit',
  cursor: 'pointer',
  height: theme.spacing(3),
  width: theme.spacing(3)
}));

export const StyledBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  gap: '0.625rem',
  marginTop: '0.25rem'
});

export const StyledHeaderTypography = styled(Typography)({
  color: colorPalette.purple,
  fontSize: '1.125rem',
  fontWeight: '700'
});

export const LoginTextTypography = styled(Typography)({
  alignItems: 'center',
  display: 'flex',
  fontColor: colorPalette.darkShadowGrey,
  fontSize: '0.75rem',
  justifyContent: 'center'
});
