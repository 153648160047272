/* eslint-disable complexity */
/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, ListItem, Skeleton, Tooltip, Typography } from '@mui/material';
import SeeMoreIcon from 'assets/icons/see_more.svg';
import { Follow } from 'components/common/Follow';
import Config from 'config/config';
import AuthContext, { formTypes } from 'contexts/AuthContext';
import { formatNumber, getOrdinal } from 'helpers';
import { getBasePath } from 'helpers/common';
import TagsView from 'pages/home/TagsView';
import Actions from 'redux-state/actions';
import {
  GetFollowingTagsCount,
  GetOwnedCommunity,
  GetTags,
  GetUser,
  GetUserMutualTags
} from 'redux-state/selectors';
import { colorPalette } from 'theme';
import { Constants, TAG_TYPES, VARIANT } from 'utilities/constants';
import {
  CommunityAvatar,
  Highlight,
  OverflowAvater,
  StyledAvatar,
  StyledAvatarGroup,
  StyledBox,
  StyledHeaderTypography,
  StyledIcon,
  StyledTab,
  StyledTabLabel,
  StyledTabs,
  StyledTagButton,
  StyledTagsButtonContainer,
  StyledTagsContainer,
  StyledTagsSubContainer,
  StyledTagsViewDivider,
  StyledText
} from './styledComponents';

const clientUrl = Config.NEXT_PUBLIC_CLIENT_URL;

export const CommunitiesListView = ({
  isFeed = false,
  setTrigger
}: {
  isFeed?: boolean;
  setTrigger?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const dispatch = useDispatch();
  const { setAuthModalOpened } = useContext(AuthContext);
  const followedCommunities = GetFollowingTagsCount();
  const mutualTags = GetUserMutualTags();
  const tags = GetTags();
  const user = GetUser();
  const userTags = GetOwnedCommunity();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [filterType, setFilterType] = useState<string>(Constants.ALL);
  const [open, setOpen] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchFilter, setSearchFilter] = useState<string>(searchValue);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setRowsPerPage(5);
    setPage(0);
    setSearchValue('');
    setFilterType(Constants.ALL);
  };

  const paginationOptions = useMemo(
    () => ({
      page: page,
      rowsPerPage: rowsPerPage,
      setPage: setPage,
      setRowsPerPage: setRowsPerPage
    }),
    [page, rowsPerPage, setRowsPerPage, setPage]
  );
  const { id } = useParams();

  const pagination = useMemo(
    () => ({
      page: page,
      perPage: rowsPerPage
    }),
    [page, rowsPerPage]
  );

  useEffect(() => {
    if ((user?._id || user?.id) && isFeed) {
      dispatch(Actions.getOwnedCommunity({ userId: user?._id ?? user?.id }));
    }
  }, [dispatch, isFeed, user]);

  useEffect(() => {
    dispatch(
      Actions.getTags(
        {
          item: Constants.TAGS,
          pagination
        },
        filterType,
        searchFilter,
        { field: Constants.IDEA_POINTS, order: Constants.DESC }
      )
    );
  }, [dispatch, pagination, filterType, searchFilter]);

  const fetchTags = useCallback(async () => {
    if (filterType === Constants.MUTUAL) {
      dispatch(Actions.getProfileMutualTags(false, id, { page, rowsPerPage }));
    } else if (isFeed && activeTabIndex === 0) {
      dispatch(Actions.getFollowingTagsCount(user?.key as string, false));
    } else if (activeTabIndex === 0) {
      dispatch(Actions.getProfileMutualTags(true));
    } else if (isFeed) {
      let filter: string;
      switch (activeTabIndex) {
        case 0:
          filter = undefined;
          break;
        case 1:
          filter = TAG_TYPES.LOCATION;
          break;
        case 2:
          filter = TAG_TYPES.SCHOOL;
          break;
        case 3:
          filter = TAG_TYPES.WORKPLACE;
          break;
        default:
          filter = undefined;
      }
      dispatch(Actions.getProfileMutualTags(true, id, null, filter));
    } else {
      dispatch(Actions.getProfileMutualTags(true, id, null));
    }
  }, [
    activeTabIndex,
    dispatch,
    filterType,
    id,
    isFeed,
    page,
    rowsPerPage,
    user?.key
  ]);

  useEffect(() => {
    fetchTags();
  }, [fetchTags, activeTabIndex, id, filterType]);

  const onFollowBtnClick = (tagId: string, actionType: string) => {
    if (setTrigger) {
      setTrigger((state: boolean) => !state);
    }
    if (user) {
      dispatch(
        Actions.getTagFollowers(
          tagId,
          user.id,
          actionType,
          null,
          Constants.TAGS,
          pagination,
          filterType,
          searchFilter,
          { field: Constants.IDEA_POINTS, order: Constants.DESC },
          id as string
        )
      );
    } else {
      setAuthModalOpened(formTypes.SIGN_UP);
    }
  };

  const handleTagClick = (item) => {
    const basePath = getBasePath(item);
    window.open(`${clientUrl}${basePath}/${item.key}`, Constants._BLANK);
  };

  const onAvatarClick = (followersKey) => {
    window.open(
      `${clientUrl}${Constants.PROFILES}/${followersKey}`,
      Constants._BLANK
    );
  };

  // eslint-disable-next-line no-shadow
  const AllCommunites = ({ tags }: { tags?: any }) => {
    const sortedTags = tags
      ? tags
          .map((tag) => ({ tag }))
          .sort((a, b) => (b.tag?.ideaPoints || 0) - (a.tag?.ideaPoints || 0))
      : isFeed
        ? [...mutualTags].sort(
            (a, b) => (b.tag?.ideaPoints || 0) - (a.tag?.ideaPoints || 0)
          )
        : mutualTags;

    return (
      isFeed && userTags
        ? [{ tag: userTags }, ...sortedTags.slice(0, 5)]
        : sortedTags.slice(0, 5)
    ).map((data, index) => {
      const actionType = data?.tag?.followers?.some(
        (follower) => follower?._id === user?.id
      )
        ? Constants.UNFOLLOW
        : Constants.FOLLOW;

      const totalFollowers = data?.tag?.followers?.length || 0;
      const extraFollowers = totalFollowers > 1 ? totalFollowers - 4 : 0;
      const totalIdeapoints = formatNumber(data?.tag?.ideaPoints || 0);

      return (
        <React.Fragment key={data?.tag?.id}>
          <StyledTagsSubContainer>
            <ListItem>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  gap: '0.625rem',
                  width: '100%'
                }}
              >
                <CommunityAvatar src={data?.tag?.files[0]?.url} />
                <Box width="100%">
                  <StyledText
                    isFeed={isFeed}
                    onClick={() => handleTagClick(data?.tag)}
                  >
                    {data?.tag?.type === 'location'
                      ? data?.tag?.name.split(',')[0]
                      : data?.tag?.name}
                    {isFeed && (
                      <span
                        style={{
                          color: colorPalette.slateBlue,
                          fontSize: '0.9rem',
                          position: 'absolute',
                          right: 0,
                          top: 10
                        }}
                      >
                        {userTags && userTags?._id
                          ? data?.tag?._id === userTags?._id
                            ? index !== 0
                              ? getOrdinal(index)
                              : ''
                            : getOrdinal(index)
                          : getOrdinal(index + 1)}
                      </span>
                    )}
                  </StyledText>
                  <Typography
                    sx={{
                      color: colorPalette.slateBlue,
                      fontSize: isFeed ? '0.8rem' : '0.6875rem',
                      fontWeight: '500',
                      lineHeight: '2.1'
                    }}
                  >
                    {isFeed ? (
                      <>
                        {totalIdeapoints} Ideapoints, {totalFollowers} followers
                      </>
                    ) : actionType === Constants.UNFOLLOW ? (
                      <>
                        You are ranked <Highlight>{data?.userRank}</Highlight>{' '}
                        in this community.
                      </>
                    ) : (
                      Constants.YOU_ARE_NOT_PART_OF_THIS_COMMUNITY
                    )}
                  </Typography>
                  <StyledBox>
                    {data?.tag?.type !== TAG_TYPES.LOCATION &&
                      data?.tag?.type !== TAG_TYPES.SCHOOL && (
                        <Follow
                          id={data?.tag?.id}
                          actionType={actionType}
                          type={Constants.TAG}
                          onClick={() =>
                            onFollowBtnClick(data?.tag?._id, actionType)
                          }
                          version={VARIANT.OUTLINED}
                          disabled={
                            isFeed &&
                            userTags &&
                            userTags?._id === data?.tag?._id
                          }
                        />
                      )}
                    {isFeed && userTags && userTags?._id === data?.tag?._id ? (
                      ''
                    ) : (
                      <StyledAvatarGroup max={10}>
                        {data?.tag?.followers.slice(0, 4).map((follower) => (
                          <Tooltip
                            key={follower._id}
                            title={follower.key}
                            placement="bottom"
                          >
                            <StyledAvatar
                              key={follower._id}
                              src={follower?.files[0]?.url}
                              alt={follower?.name}
                              onClick={() => onAvatarClick(follower.key)}
                            />
                          </Tooltip>
                        ))}
                        {extraFollowers > 0 && (
                          <OverflowAvater>+{extraFollowers}</OverflowAvater>
                        )}
                      </StyledAvatarGroup>
                    )}
                  </StyledBox>
                </Box>
              </Box>
            </ListItem>
          </StyledTagsSubContainer>
          <StyledTagsViewDivider />
        </React.Fragment>
      );
    });
  };

  const tabs = isFeed
    ? [
        {
          title: Constants.INTERESTS,
          content: <AllCommunites tags={followedCommunities?.result ?? null} />
        },
        {
          title: Constants.LOCATIONS,
          content: <AllCommunites />
        },
        {
          title: Constants.SCHOOLS,
          content: <AllCommunites />
        },
        {
          title: Constants.C_COMPANIES,
          content: <AllCommunites />
        }
      ]
    : user?.key === id
      ? [
          {
            title: Constants.ALL_COMMUNITIES,
            content: <AllCommunites />
          }
        ]
      : [
          {
            title: Constants.ALL_COMMUNITIES,
            content: <AllCommunites />
          },
          {
            title: Constants.MUTUAL_COMMUNITIES,
            content: <AllCommunites />
          }
        ];

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTabIndex(newValue);
  };

  return (
    <StyledTagsContainer isFeed={isFeed}>
      {isFeed && (
        <StyledHeaderTypography>
          {Constants.TOP_INTEREST_COMMUNTIES}
        </StyledHeaderTypography>
      )}
      <StyledTabs
        orientation="horizontal"
        aria-label="secondary tabs example"
        indicatorColor="primary"
        onChange={handleTabChange}
        scrollButtons={true}
        TabIndicatorProps={{
          style: {
            backgroundColor: colorPalette.purple
          }
        }}
        value={activeTabIndex}
        variant="fullWidth"
      >
        {tabs?.map((tab) => (
          <StyledTab
            key="index"
            isFeed={isFeed}
            label={<StyledTabLabel isFeed={isFeed}>{tab.title}</StyledTabLabel>}
          />
        ))}
      </StyledTabs>
      <Box sx={{ marginTop: '0.875rem' }}>
        {user ? (
          tabs[activeTabIndex].content
        ) : (
          <Skeleton variant="rounded" sx={{ width: '100%', height: '35rem' }} />
        )}
      </Box>
      <StyledTagsButtonContainer>
        <StyledTagButton
          onClick={handleOpen}
          endIcon={<StyledIcon src={SeeMoreIcon} alt="seeMore" />}
        >
          {Constants.SEE_ALL_COMMUNITIES}
        </StyledTagButton>
      </StyledTagsButtonContainer>
      {open && (
        <TagsView
          open={open}
          handleClose={handleClose}
          tags={filterType === Constants.MUTUAL ? mutualTags : tags}
          paginationOptions={paginationOptions}
          pagination={pagination}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          searchFilter={searchFilter}
          setSearchFilter={setSearchFilter}
          filterType={filterType}
          setFilterType={setFilterType}
        />
      )}
    </StyledTagsContainer>
  );
};
