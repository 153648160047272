import React from 'react';
import { styled, Theme, useTheme } from '@mui/material/styles';

import { PsTheme } from '../../theme';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      marginTop: 'auto',
      marginRight: 5,
      [psTheme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'flex-start'
      }
    }
  };
});

type CardRowProps = {
  children?: React.ReactNode;
  className?: string;
};

const CardRowView = ({ className, children }: CardRowProps) => {
  const { classes } = useStyles();
  let rootClassName = classes.root;
  if (className) {
    rootClassName += ' ' + className;
  }

  return <div className={rootClassName}>{children}</div>;
};

export const CardRow = styled(CardRowView)({});

export default CardRow;
