import React from 'react';
import { Container, Grid } from '@mui/material';
import logo from 'assets/images/logo.svg';
import { FacebookColoredIcon } from 'components/icons/FacebookColoredIcon';
import { InstagramIcon } from 'components/icons/InstagramIcon';
import { LinkedinIcon } from 'components/icons/LinkedinIcon';
import { XIcon } from 'components/icons/XIcon';
import {
  StyledReachCopyrightBox,
  StyledReachCopyrightTypography,
  StyledReachIconBox,
  StyledReachIconButton,
  StyledReachLink,
  StyledReachLogoBox,
  StyledReachMainBox,
  StyledReachSubTypography,
  StyledReachTypography
} from './StyledComponents';

export const ReachSection = () => {
  return (
    <StyledReachMainBox>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <StyledReachLogoBox>
              <img src={logo} />
            </StyledReachLogoBox>
            <StyledReachTypography>
              Your ideas are the currency.
            </StyledReachTypography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <StyledReachSubTypography>Company</StyledReachSubTypography>
                <StyledReachLink href="#" color="#161C2D" marginTop="1.5625rem">
                  About us
                </StyledReachLink>
                <StyledReachLink href="#" color="#ff004c" marginTop="1.25rem">
                  Contact us
                </StyledReachLink>
                <StyledReachLink href="#" color="#161C2D" marginTop="1.25rem">
                  Press
                </StyledReachLink>
              </Grid>
              <Grid item xs={4}>
                <StyledReachSubTypography>Product</StyledReachSubTypography>
                <StyledReachLink href="#" marginTop="1.5625rem" color="#161C2D">
                  Features
                </StyledReachLink>
                <StyledReachLink href="#" marginTop="1.25rem" color="#161C2D">
                  Pricing
                </StyledReachLink>
                <StyledReachLink href="#" marginTop="1.25rem" color="#161C2D">
                  News
                </StyledReachLink>
                <StyledReachLink href="#" marginTop="1.25rem" color="#161C2D">
                  Support
                </StyledReachLink>
              </Grid>
              <Grid item xs={4}>
                <StyledReachSubTypography>Legal</StyledReachSubTypography>
                <StyledReachLink
                  href="/privacy-policy"
                  marginTop="1.5625rem"
                  color="#161C2D"
                >
                  Privacy Policy
                </StyledReachLink>
                <StyledReachLink
                  href="/terms"
                  marginTop="1.25rem"
                  color="#161C2D"
                >
                  Terms & Conditions
                </StyledReachLink>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <StyledReachCopyrightBox>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <StyledReachCopyrightTypography>
                © 2024 Copyright. All Rights Reserved. MindMiner, Inc.
              </StyledReachCopyrightTypography>
            </Grid>
            <Grid item>
              <StyledReachIconBox>
                <StyledReachIconButton>
                  <XIcon />
                </StyledReachIconButton>
                <StyledReachIconButton>
                  <FacebookColoredIcon />
                </StyledReachIconButton>
                <StyledReachIconButton>
                  <InstagramIcon />
                </StyledReachIconButton>
                <StyledReachIconButton>
                  <LinkedinIcon />
                </StyledReachIconButton>
              </StyledReachIconBox>
            </Grid>
          </Grid>
        </StyledReachCopyrightBox>
      </Container>
    </StyledReachMainBox>
  );
};
