import React, { useContext, useMemo, useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { PsTheme } from '../../theme';
import { sizes } from './consts';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root' | 'item' | 'link';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      listStyle: 'none',
      margin: 0,
      padding: '20px 0 20px 5px',
      whiteSpace: 'nowrap'
    },
    item: {
      padding: '10px 0',
      textAlign: 'center'
    },
    link: {
      position: 'relative',
      display: ' inline-block',
      width: 67,
      height: 36,
      textIndent: '100px',
      overflow: 'hidden',
      borderRadius: 17,
      '&.active .icon': {
        background: '#5a5a5a'
      },
      '& .icon': {
        position: 'absolute',
        top: '50%',
        left: 0,
        display: 'block',
        width: '99%',
        height: '100%',
        background: '#a9a9a9',
        borderRadius: 17,
        transform: 'translateY(-50%)'
      },
      '&.size-25': {
        height: 22,
        '& .icon': {
          height: 22,
          borderRadius: 11
        }
      },
      '&.size-7': {
        height: 22,
        '& .icon': {
          height: 5,
          borderRadius: 3
        }
      }
    }
  };
});

export type DrawSizeToolsProps = {
  className?: string;
  active: number;
  onSelect: (size: number) => void;
};

const DrawSizeToolsView = ({
  className,
  active,
  onSelect
}: DrawSizeToolsProps) => {
  const { classes } = useStyles();
  let rootClassName = classes.root;
  if (className) {
    rootClassName += ' ' + className;
  }
  return (
    <ul className={rootClassName}>
      {sizes.map((size: number) => {
        const isActive = size === active ? 'active' : '';

        const onClick = (e: React.MouseEvent) => {
          e.preventDefault();
          onSelect(size);
        };
        return (
          <li className={classes.item} key={`size-${size}`}>
            <a
              href={`#size-${size}`}
              onClick={onClick}
              className={`${classes.link} size-${size} ${isActive}`}
            >
              <span className="icon" />
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export const DrawSizeTools = styled(DrawSizeToolsView)({});

export default DrawSizeTools;
