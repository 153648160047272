import React from 'react';
import { ethers } from 'ethers';
import { createRoot } from 'react-dom/client';
import { DndProvider } from 'react-dnd';
import { RouteObject } from 'react-router-dom';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { HelmetProvider } from 'react-helmet-async';
// import { Web3ReactProvider } from '@web3-react/core';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { AuthContextProvider } from './contexts/AuthContext';
import { DataContextProvider } from './contexts/DataContext';
import { ModalContextProvider } from './contexts/ModalContext';
import { WalletContextProvider } from './contexts/WalletContext';
import { AuthFormsContextProvider } from './contexts/AuthFormsContext';
import { ModalDataContextProvider } from './contexts/ModalDataContext';
import { SimpleModalContextProvider } from './contexts/SimpleModalContext';

import auth from './authProvider';
import Layout from './layout/Layout';
import { theme } from './theme';
import dataPrvider from './dataPrvider';
import walletProvider from './walletProvider';

// Route structure
import { AppRoutes } from './routes';

// State-management
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from 'redux-state/index';

import './index.css';
import './components/TreeGraph/ChartNode.css';
import './components/TreeGraph/ChartContainer.css';

// AppRoutes will conform, but TypeScript isn't recognizing that since the RouteObject is of a sum type.
const router = createBrowserRouter(AppRoutes as unknown as RouteObject[]);

const getLibrary = (provider: any) => {
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = 8000; // frequency provider is polling
  return library;
};

export default () => {
  // React.useEffect(() => {
  //   // Remove the server-side injected CSS.
  //   const jssStyles = document.querySelector('#jss-server-side');
  //   if (jssStyles && jssStyles.parentElement) {
  //     jssStyles.parentElement.removeChild(jssStyles);
  //   }
  // }, []);

  const rootElement = document.getElementById('root');
  const root = createRoot(rootElement!);

  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={<div>Loading...</div>}>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <SnackbarProvider maxSnack={5} autoHideDuration={7000}>
                {/* <Web3ReactProvider getLibrary={getLibrary}> */}
                <AuthContextProvider auth={auth}>
                  <DataContextProvider dataProvider={dataPrvider}>
                    <WalletContextProvider walletProvider={walletProvider}>
                      <ModalDataContextProvider>
                        <ModalContextProvider>
                          <AuthFormsContextProvider>
                            <SimpleModalContextProvider>
                              <DndProvider backend={HTML5Backend}>
                                <HelmetProvider>
                                  <RouterProvider router={router} />
                                </HelmetProvider>
                              </DndProvider>
                            </SimpleModalContextProvider>
                          </AuthFormsContextProvider>
                        </ModalContextProvider>
                      </ModalDataContextProvider>
                    </WalletContextProvider>
                  </DataContextProvider>
                </AuthContextProvider>
                {/* </Web3ReactProvider> */}
              </SnackbarProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  );
};
