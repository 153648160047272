import { styled, Box, Typography } from '@mui/material';
import { colorPalette } from 'theme';

export const InfoBox = styled(Box)(({ theme }) => ({
  alignItems: 'start',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
  height: 'auto',
  maxWidth: '27.313rem',
  opacity: 1,
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    maxWidth: '100%'
  }
}));

export const StyledMainBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'nowrap',
  gap: '1rem'
});

export const StatsParentText = styled(Typography)(({ theme }) => ({
  alignItems: 'center',
  color: 'initial',
  display: 'flex',
  fontSize: '1.3rem',
  fontWeight: 500,
  gap: '0.25rem',
  lineHeight: '1.524rem',
  [theme.breakpoints.down('md')]: {
    fontSize: '1.1rem',
    lineHeight: '1.375rem'
  }
}));

export const StatsChildText = styled(Box)({
  alignItems: 'center',
  color: 'initial',
  cursor: 'pointer',
  display: 'flex',
  fontSize: '0.9rem',
  fontWeight: 500,
  lineHeight: '1.067rem',
  textDecoration: 'underline'
});

export const StatsParentNumber = styled(Typography)(({ theme }) => ({
  color: 'initial',
  fontSize: '1.3rem',
  fontWeight: 700,
  lineHeight: '1.524rem',
  [theme.breakpoints.down('md')]: {
    fontSize: '1.1rem',
    lineHeight: '1.375rem'
  }
}));

export const StatsChildNumber = styled(Typography)({
  color: colorPalette.purple,
  fontSize: '0.9rem',
  fontWeight: 700,
  lineHeight: '1.067rem',
  marginLeft: '0.0313rem'
});
