import {
  Avatar,
  AvatarProps,
  Box,
  BoxProps,
  Typography,
  TypographyProps
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { colorPalette } from '../../theme';

interface StyledBoxProps extends BoxProps {
  isMediumScreen: boolean;
}

interface DescriptionTextSmallProps extends TypographyProps {
  isMediumScreen: boolean;
}

interface TitleTextProps extends TypographyProps {
  isMediumScreen: boolean;
}

export const ContentBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  maxWidth: '300px'
});

export const InfoContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row'
});

export const FollowContainer = styled(Box)<StyledBoxProps>(
  ({ isMediumScreen }) => ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    marginTop: isMediumScreen ? '0px' : '6px'
  })
);

interface RoundedAvatarProps extends AvatarProps {
  isMediumScreen: boolean;
}

export const RoundedAvatar = styled(Avatar)<RoundedAvatarProps>(
  ({ isMediumScreen }) => ({
    height: isMediumScreen ? '40px' : '55px',
    width: isMediumScreen ? '40px' : '55px'
  })
);

export const TitleText = styled(Typography)<TitleTextProps>(
  ({ isMediumScreen }) => ({
    fontSize: isMediumScreen ? '16px' : '20px',
    width: isMediumScreen ? '200px' : 'none',
    fontWeight: 'bold',
    lineHeight: '1',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  })
);

export const DescriptionTextSmall = styled(
  Typography
)<DescriptionTextSmallProps>(({ isMediumScreen }) => ({
  color: colorPalette.darkGray,
  fontSize: '12px',
  lineHeight: '0',
  margin: isMediumScreen ? '12px 0px 2px 1px' : '0px 0px 0px 8px'
}));
