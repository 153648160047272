import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const BackgroundFooterLandingPage = (props: SvgIconProps) => {
  return (
    <SvgIcon
      sx={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: -1
      }}
      viewBox="0 0 1600 1626"
      preserveAspectRatio="xMidYMid slice"
    >
      <rect
        x="-0.09375"
        y="0.745605"
        width="1600"
        height="1626"
        fill="url(#paint0_linear_5573_3402)"
      />
      <mask
        id="mask0_5573_3402"
        maskUnits="userSpaceOnUse"
        x="-1"
        y="0"
        width="1600"
        height="1626"
      >
        <rect
          x="-0.09375"
          y="0.745605"
          width="1600"
          height="1625.25"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_5573_3402)"></g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-0.0935616 9.22884C-0.0935616 9.22884 249.758 32.7713 378.734 28.7582C507.711 24.7452 539.832 23.6094 608.676 28.7582C677.52 33.907 1006.19 73.1784 1159.11 68.4169C1312.04 63.6554 1562 35.5874 1620.57 59.1386V0H-1L-0.0935616 9.22884Z"
        fill="#FDFDFF"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5573_3402"
          x1="799.906"
          y1="-811.882"
          x2="-825.149"
          y2="787.922"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#473159" />
          <stop offset="1" stopColor="#27162D" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};
