import React from 'react';

import styles from './Step.module.scss';

export interface StepProps {
  index?: number;
  active?: boolean;
  completed?: boolean;
  last?: boolean;
  children: React.ReactNode;
}

const Step: React.FC<StepProps> = ({
  index = 0,
  active,
  children,
  completed,
  last
}) => {
  let wrapperClassName = styles.wrapper;
  if (active) {
    wrapperClassName += ' ' + styles.active;
  }
  if (completed) {
    wrapperClassName += ' ' + styles.completed;
  }
  return (
    <div className={wrapperClassName}>
      <div className={styles.stepNumber}>step {index + 1}</div>
      <div className={styles.stepTitle}>{children}</div>
    </div>
  );
};

export default Step;
