import React from 'react';
import { styled, Theme, useTheme } from '@mui/material/styles';

import { PsTheme } from '../../theme';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      fontFamily: 'Sinkin Sans',
      fontSize: '16px',
      lineHeight: '180%',
      letterSpacing: '0.01em',
      flex: 1,
      '& > a': {
        color: psTheme.palette.primary.main,
        textDecoration: 'underline',
        '&:hover': {
          textDecoration: 'none'
        }
      }
    }
  };
});

type CardTextProps = {
  children?: React.ReactNode;
  className?: string;
};

const CardTextView = ({ className, children }: CardTextProps) => {
  const { classes } = useStyles();
  let rootClassName = classes.root;
  if (className) {
    rootClassName += ' ' + className;
  }

  if (!children) {
    return null;
  }

  return <Typography className={rootClassName}>{children}</Typography>;
};

export const CardText = styled(CardTextView)({});

export default CardText;
