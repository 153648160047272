import React, { useCallback, useMemo } from 'react';
import { Box, Button, styled, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { GetToggleConceptCart } from 'redux-state/ideamap/selectors';
import Actions from 'redux-state/actions';
import { Constants } from 'utilities/constants';
import NavigationHistory from './NavigationHistory';
import {
  ActiveConcept,
  StyledBoxFlex
} from 'components/common/StyledComponents';
import {
  BreadCrumbComponent,
  BreadCrumbIcon,
  useBreadCrumb
} from 'components/breadcrumb';
import TreeIcon from 'components/icons/TreeIcon';
import { colorPalette, useIsMediumScreen } from 'theme';
import { GetUser } from 'redux-state/selectors';

type ContestType =
  | Constants.MOST_VOTES
  | Constants.MOST_COINS_EARNED
  | Constants.HYBRID;

export interface TopSectionProps {
  title: string;
  type: ContestType;
}

const TopSection = ({ title, type }: TopSectionProps) => {
  const user = GetUser();
  const dispatch = useDispatch();
  const activeConcept = user?.activeConcept || {};
  const isConceptDrawerOpen = GetToggleConceptCart();
  const isMediumScreen = useIsMediumScreen();

  const contestTypes = useMemo(
    () => [Constants.MOST_VOTES, Constants.MOST_COINS_EARNED, Constants.HYBRID],
    []
  );

  const getUrlForType = useCallback(
    (contestType: Constants) => {
      const filterType = contestTypes.includes(contestType)
        ? Constants.CONTEST
        : contestType === Constants.Invention
          ? Constants.APPLICATION
          : contestType;
      return `${window.location.origin}/feeds?filterType=${filterType}`;
    },
    [contestTypes]
  );

  const { BREADCRUMB_OPTIONS } = useMemo(() => {
    return {
      BREADCRUMB_OPTIONS: [
        { icon: BreadCrumbIcon.MIND_MINER_ICON },
        {
          icon: BreadCrumbIcon.MIND_MINER_ICON,
          label: contestTypes.includes(type) ? Constants.CONTESTS : `${type}s`,
          url: getUrlForType(type)
        },
        { icon: BreadCrumbIcon.MIND_MINER_ICON, label: title }
      ]
    };
  }, [contestTypes, title, type, getUrlForType]);

  const breadCrumbOptions = useBreadCrumb({
    breadCrumbOptions: BREADCRUMB_OPTIONS
  });

  const handleDrawerOpen = useCallback(() => {
    dispatch(Actions.toggleConceptCart(!isConceptDrawerOpen));
  }, [dispatch, isConceptDrawerOpen]);

  return (
    <Container>
      <ButtonWrapper>
        <ConceptButton disableRipple onClick={handleDrawerOpen}>
          <StyledInnerBox>
            <TreeIcon size={15} color={colorPalette.white} />
            <StyledTypography>
              {isConceptDrawerOpen
                ? Constants.EXIT_CONCEPTS
                : Constants.C_CONCEPTS}
            </StyledTypography>
          </StyledInnerBox>
        </ConceptButton>

        {!isConceptDrawerOpen && (
          <ActiveBox>
            {activeConcept && Object.keys(activeConcept).length ? (
              <ActiveConcept
                isPointer
                onClick={isConceptDrawerOpen ? undefined : handleDrawerOpen}
              >
                {Constants.ACTIVE_CONCEPT}: {activeConcept?.title}
              </ActiveConcept>
            ) : (
              <ActiveConcept>{Constants.NO_ACTIVE_CONCEPT}</ActiveConcept>
            )}
          </ActiveBox>
        )}
      </ButtonWrapper>
      <StyledBoxFlex>
        <NavigationHistory />
        {!isMediumScreen && (
          <BreadCrumbComponent breadCrumbOptions={breadCrumbOptions} />
        )}
      </StyledBoxFlex>
    </Container>
  );
};

const ActiveBox = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    alignItems: 'center'
  }
}));

const ButtonWrapper = styled(Box)({
  alignItems: 'center',
  display: 'flex'
});

const Container = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  minHeight: '3.75rem',
  margin: '1rem 0',
  [theme.breakpoints.up('md')]: {
    minHeight: '6.5625rem',
    margin: '0 0 0.75rem'
  }
}));

const ConceptButton = styled(Button)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: colorPalette.mintTulip,
  display: 'flex',
  justifyContent: 'center',
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  [theme.breakpoints.up('md')]: {
    fontSize: '0.9rem'
  }
}));

const StyledInnerBox = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: colorPalette.lightGreen,
  borderRadius: '0.3125rem',
  display: 'flex',
  justifyContent: 'center',
  padding: '0.7rem',
  [theme.breakpoints.up('md')]: {
    padding: '0.625rem 1.5625rem 0.625rem 0.9375rem'
  }
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: 'white',
  fontSize: '12px',
  fontWeight: 'bold',
  marginLeft: '0.75rem',
  whiteSpace: 'nowrap',
  [theme.breakpoints.up('md')]: {
    marginLeft: '1rem'
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.8rem'
  }
}));

export default TopSection;
