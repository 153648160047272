import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Constants } from 'utilities/constants';
import { Content } from './Content';
import { Header } from './Header';
import { StyledBackButtonWrapper } from './StyledComponents';
import { ActionSection } from '../Common/ActionSection';
import { BaseModal } from '../Common/BaseModal';
import { ContentSection } from '../Common/ContentSection';
import { HeaderSection } from '../Common/HeaderSection';
interface ImproveModalProps {
  isImproveModalOpen: boolean;
  modalType?: string;
  productId?: string;
  productKey?: string;
  setIsImproveModalOpen: (open: boolean) => void;
  shortTitle?: string;
  title?: string;
}

const capitalizeTitle = (title: string) => {
  return title
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const ImproveModal: React.FC<ImproveModalProps> = ({
  isImproveModalOpen,
  modalType,
  productId,
  productKey,
  setIsImproveModalOpen,
  title
}) => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [type, setType] = useState<string>('reduce');
  const [subType, setSubtype] = useState<string>('');
  const [modalTitle, setModalTitle] = useState('Improve Product');

  useEffect(() => {
    if (activeStep == 0) {
      if (modalType === Constants.Invention) {
        setModalTitle(Constants.IMPROVE_INVENTION);
      } else {
        setModalTitle(Constants.IMPROVE_PRODUCT);
      }
    } else if (activeStep == 1) {
      setModalTitle(capitalizeTitle(type) + ' ' + subType + Constants.OF_THE);
    } else if (activeStep == 2) {
      setModalTitle(Constants.SOLVE_FOR + subType + Constants.OF_THE);
    }
  }, [subType, activeStep]);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const closeModal = () => {
    setIsImproveModalOpen(false);
  };
  return (
    <BaseModal open={isImproveModalOpen} maxWidth={'lg'}>
      <HeaderSection>
        <Header
          activeStep={activeStep}
          label={modalTitle}
          onClose={closeModal}
          parentNodeTitle={title}
          showCrossIcon={true}
        />
      </HeaderSection>

      <ContentSection>
        <Content
          activeStep={activeStep}
          closeModal={closeModal}
          productId={productId}
          productKey={productKey}
          setActiveStep={setActiveStep}
          setSubtype={setSubtype}
          setType={setType}
          subType={subType}
          title={title}
          type={type}
        />
      </ContentSection>

      <ActionSection>
        <StyledBackButtonWrapper>
          {activeStep != 0 && (
            <Button onClick={handleBack}>
              <ArrowBackIcon />
              {Constants.BACK}
            </Button>
          )}
        </StyledBackButtonWrapper>
      </ActionSection>
    </BaseModal>
  );
};

export default ImproveModal;
