import React, { useContext, useState, useCallback, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { Problem } from 'components/CardProblem';
import { Solution } from 'components/CardSolution';
import { PsFormControl, ShortEvent } from 'components/common/PsFormControl';
import { PsInput } from 'components/common/PsInput';
import { PsSelect, PsOptionType } from 'components/common/PsSelect';
import {
  ModalDataContext,
  StepComponentType,
  StepComponentProps
} from 'contexts/ModalDataContext';
import { DataContext } from 'contexts/DataContext';
import { Radio } from 'components/Radio';
import { PsTheme } from '../../../theme';
import styles from 'modals/ModalWindow.module.scss';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

export const defaultSolutionTitle = 'The solution of ';

const titleHelperText = 'Example: The solution of a flux capacitor';

const checkboxStyles = makeStyles()(() => {
  const psTheme = useTheme() as PsTheme;
  return {
    root: {
      '& a': {
        color: psTheme.palette.primary.main
      }
    }
  };
});

const PsCheckboxView = (props: any) => {
  const { classes } = checkboxStyles();
  return <Checkbox color="default" {...props} />;
};
export const PsCheckbox = styled(PsCheckboxView)({});

const useStyles = makeStyles()(() => {
  return {
    root: {
      margin: '0 0 5px -15px',
      '& > span:last-child': {
        padding: 0,
        color: 'inherit',
        fontWeight: 'normal',
        lineHeight: '25px'
      },
      '& > span:last-child > b': {
        fontWeight: 600
      },
      '& > span:last-child > span': {
        fontSize: 13
      },
      '&:last-child': {
        marginBottom: 20
      }
    }
  };
});

// eslint-disable-next-line react/prop-types
const SolutionName: StepComponentType<StepComponentProps> = ({ onNotify }) => {
  const { values, errors, handleInputChange, updateField } =
    useContext(ModalDataContext);
  const { dataProvider } = useContext(DataContext);
  const [title, setTitle] = useState(values.titleSolution);
  const [parentProblem, setParentProblem] = useState(values.parentProblem);
  const [parentSolution, setParentSolution] = useState(values.parentSolution);
  const [problems, setProblems] = useState<Array<PsOptionType>>([]);
  const [solutions, setSolutions] = useState<Array<PsOptionType>>([]);
  const [helperText, setHelperText] =
    useState<React.ReactNode>(titleHelperText);
  const [helperTextAi, setHelperTextAi] = useState('');
  const [type, setType] = useState<string>(values.finalizeType || 'private');
  const [termsAgree, setTermsAgree] = useState(values.termsAgree || false);
  const { classes } = useStyles();

  let isTermsDisabled = false;
  if (!values.titleSolution) {
    isTermsDisabled = true;
  }

  let showTypeHints = false;
  if (
    values.modalTitle &&
    typeof values.modalTitle === 'string' &&
    values.modalTitle.includes('Suggest')
  ) {
    showTypeHints = true;
  }

  useEffect(() => {
    if (!values.titleSolution) {
      updateField('titleSolution', defaultSolutionTitle);
      setTitle(defaultSolutionTitle);
    }
    dataProvider
      .getList<Problem>('problems', {
        pagination: { page: 1, perPage: 50000 },
        sort: { field: 'title', order: 'ASC' },
        filter: {
          $custom: { type: 'unlimit', product: values.productId }
        }
      })
      .then(({ data = [] }) => {
        const list: Array<PsOptionType> = data.map((item: Problem) => ({
          value: `${item.id}`,
          label: item.title || '(empty)'
        }));
        setProblems(list);
      })
      .catch(() => {
        setProblems([]);
      });

    dataProvider
      .getList<Solution>('solutions', {
        pagination: { page: 1, perPage: 50000 },
        sort: { field: 'title', order: 'ASC' },
        filter: { $custom: { type: 'unlimit' } }
      })
      .then(({ data = [] }) => {
        const list: Array<PsOptionType> = data.map((item: Solution) => ({
          value: `${item.id}`,
          label: item.title || '(empty)'
        }));
        setSolutions(list);
      })
      .catch(() => {
        setSolutions([]);
      });
  }, []);

  useEffect(() => {
    let problemLabel = '';
    if (parentProblem && problems && problems.length) {
      const active = problems.find((item) => {
        return item.value === parentProblem;
      });
      problemLabel = active ? active.label : '';
    }
    updateField('parentProblemLabel', problemLabel);
    let solutionLabel = '';
    if (parentSolution && solutions && solutions.length) {
      const active = solutions.find((item) => {
        return item.value === parentSolution;
      });
      solutionLabel = active ? active.label : '';
    }
    updateField('parentSolutionLabel', solutionLabel);
  }, [parentProblem, problems, parentSolution, solutions]);

  useEffect(() => {
    let helperText: React.ReactNode = titleHelperText;
    if (errors && errors.titleSolution) {
      helperText = errors.titleSolution;
    } else if (helperTextAi) {
      helperText = (
        <span
          className="ai-generated"
          dangerouslySetInnerHTML={{
            __html:
              '<b>MindMiner AI-generated example:</b> ' +
              helperTextAi.replace(/\n/g, '<br />')
          }}
        ></span>
      );
    }
    setHelperText(helperText);
  }, [helperTextAi, errors && errors.titleSolution]);

  useEffect(() => {
    if (!values.parentProblemLabel) {
      setHelperTextAi('');
      return;
    }
    setHelperTextAi('');
    const question = `What is a solution to the problem of ${values.parentProblemLabel}`;
    dataProvider
      .answerSearch(question)
      .then(({ text }) => {
        setHelperTextAi(text);
      })
      .catch((err) => {
        console.error(err);
        setHelperTextAi('');
      });
  }, [values.parentProblemLabel]);

  const onProblemNameChange = useCallback(
    (e: ShortEvent) => {
      const { value = '' } = e.target;
      const rawVal = value.slice(defaultSolutionTitle.length);
      const finalTitle = `${defaultSolutionTitle}${rawVal}`;
      setTitle(finalTitle);
      updateField('titleSolution', finalTitle);
    },
    [setTitle, updateField]
  );

  const onParentProblemChange = useCallback(
    (e: ShortEvent) => {
      handleInputChange(e);
      setParentProblem(e.target.value);
      updateField('parentProblem', e.target.value);
      updateField('tagsSolution', []);
    },
    [setParentProblem, handleInputChange, updateField]
  );

  const onParentSolutionChange = useCallback(
    (e: ShortEvent) => {
      handleInputChange(e);
      setParentSolution(e.target.value);
      updateField('parentSolution', e.target.value);
      updateField('tagsSolution', []);
    },
    [setParentSolution, handleInputChange, updateField]
  );

  const handleTypeChange = (e: React.ChangeEvent, value: string) => {
    setType(value);
    updateField('finalizeType', value);
  };

  const onTermsChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setTermsAgree(event.target.checked);
      updateField('termsAgree', event.target.checked);
    },
    [setTermsAgree, updateField]
  );

  const onTermsClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (onNotify && !isTermsDisabled) {
      onNotify('terms click');
    }
  };

  return (
    <div className={styles.stepWrapper}>
      {values.introSolution ? (
        <Typography className={styles.text}>{values.introSolution}</Typography>
      ) : null}

      <PsFormControl
        placeholder="The solution of a"
        label="Whats your solution?"
        labelInfo={true}
        helperText={helperText}
        error={!!errors.titleSolution}
      >
        <PsInput
          name="titleSolution"
          value={title}
          onChange={onProblemNameChange}
        />
      </PsFormControl>

      <PsFormControl
        placeholder={
          values.parentSolutionHide
            ? 'Chose parent problem'
            : 'Chose parent problem if needed'
        }
        label="Parent problem"
        className={values.parentProblemHide ? styles.hideFields : ''}
      >
        <PsSelect
          maxMenuHeight={270}
          name="parentProblem"
          onChange={onParentProblemChange}
          options={problems}
          value={parentProblem}
        />
      </PsFormControl>

      {!values.parentSolutionHide && (
        <PsFormControl
          name="parentSolution"
          placeholder={
            values.parentProblemHide
              ? 'Chose parent solution'
              : 'Chose parent solution if needed'
          }
          label="Parent solution"
          className={values.parentSolutionHide ? styles.hideFields : ''}
        >
          <PsSelect
            maxMenuHeight={270}
            onChange={onParentSolutionChange}
            options={solutions}
            value={parentSolution}
          />
        </PsFormControl>
      )}

      <RadioGroup
        onChange={handleTypeChange}
        value={type}
        aria-label="entity"
        name="entity"
      >
        {[
          {
            label: (
              <>
                <b>Make Public</b> Earn IdeaCoins from this Solution
                <br />
                {showTypeHints ? (
                  <span>Invention owner will be notified</span>
                ) : null}
              </>
            ),
            value: 'public'
          },
          {
            label: (
              <>
                <b>Keep Private</b> Create a Patent Application & NFT for this
                Solution
                <br />
                {showTypeHints ? (
                  <span>
                    Offer to sell to owner or others only after you purchase
                    invention NFT
                  </span>
                ) : null}
              </>
            ),
            value: 'private'
          }
        ].map(({ label, value }) => {
          return (
            <FormControlLabel
              key={value}
              classes={classes}
              value={value}
              control={<Radio />}
              label={label}
            />
          );
        })}
      </RadioGroup>

      {type === 'public' ? (
        <FormControlLabel
          className={`${styles.terms} ${
            isTermsDisabled ? styles.termsDisabled : ''
          }`}
          control={
            <PsCheckbox
              checked={values.termsAgree}
              onChange={onTermsChange}
              name="checkedG"
              disabled={isTermsDisabled}
            />
          }
          label={
            <>
              I assign my rights in this solution to MindMiner in accordance
              with these <a onClick={onTermsClick}>terms and conditions</a>
            </>
          }
        />
      ) : null}
    </div>
  );
};

export default SolutionName;
