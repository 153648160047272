import Config from 'config/config';
import { PsOptionType } from 'dataPrvider';
import Actions from 'redux-state/actions';
import Cookies from 'universal-cookie';
import { Constants, ERRORS, TAG_TYPES } from 'utilities/constants';

const BASE = 36;
const START_INDEX = 2;
const END_INDEX = 9;

const getValueObj = (
  items: Array<PsOptionType>,
  value: Array<string> | string
) => {
  const isStringType = typeof value === 'string';
  return items.filter((item) =>
    isStringType ? value === item.value : value.includes(item.value)
  );
};

export const generateUniqueId = () => {
  return `${Date.now()}-${Math.random().toString(BASE).substr(START_INDEX, END_INDEX)}`;
};

const getBasePath = (item) => {
  switch (item.type) {
    case TAG_TYPES.TAG:
    case TAG_TYPES.LOCATION:
    case TAG_TYPES.UNIVERSITY:
    case TAG_TYPES.SCHOOL:
    case TAG_TYPES.AREA:
      return Constants.TAGS;
    case TAG_TYPES.WORKPLACE:
      return Constants.COMMUNITIES;
    default:
      return Constants.TAGS;
  }
};

const getRelationPrompt = (
  conceptName,
  productName,
  firstSolutionLabel,
  secondSolutionLabel,
  remainingSolutions,
  problemSolvedBySolution
) => {
  return `
      You are tasked with describing the relationship between two solutions in a new invention that combines multiple solutions from different contexts. Your description should take into account the new concept as a whole and any previous product it builds upon. Consider how each solution addresses specific problems and how they work together in the unified concept.
      The new unified concept you will be analyzing is: ${conceptName}

      This new concept builds upon the following previous product: ${productName}

      You need to describe the relationship between these two specific solutions:
      1. ${firstSolutionLabel}
      2. ${secondSolutionLabel}

      While focusing on these two solutions, keep in mind that they are part of a larger set of solutions in the new concept. The other solutions in the concept are:
      ${remainingSolutions.map((sol) => sol?.teaser)?.join(', ')}

      Each solution in the new concept addresses specific problems. Here are the problems solved by each solution:
      The problem solved by the ${firstSolutionLabel} is ${problemSolvedBySolution[0]?.title}.
      The problem solved by the ${secondSolutionLabel} is ${problemSolvedBySolution[1]?.title}.

      In no more than 75 words, describe the relationship between the first and second solutions, considering:
      1. How they work together in the context of the new unified concept
      2. How they relate to or improve upon the previous product
      3. How they address their respective problems
      4. How their interaction might be influenced by or influence other solutions in the concept

      Provide your response in a clear, concise manner, focusing on the direct relationship between the two specified solutions while acknowledging the broader context of the invention.`;
};

export const handleTagClick = (item) => {
  const basePath = getBasePath(item);
  window.open(
    `${Config.NEXT_PUBLIC_CLIENT_URL}${basePath}/${item.key}`,
    '_blank'
  );
};

const simpleEncode = (data, key) => {
  const xorEncrypt = (input, key) => {
    let output = '';
    for (let i = 0; i < input?.length; i++) {
      output += String.fromCharCode(
        input?.charCodeAt(i) ^ key?.charCodeAt(i % key?.length)
      );
    }
    return output;
  };

  const encryptedData = xorEncrypt(data, key);
  return btoa(encryptedData);
};

const getToken = () => {
  return new Cookies().get(Constants.TOKEN);
};

const removeToken = () => {
  new Cookies().remove(Constants.TOKEN, { path: '/' });
};

const setToken = (token: string) => {
  new Cookies().set(Constants.TOKEN, token, {
    secure: true,
    path: '/',
    sameSite: 'none',
    maxAge: parseInt(Constants.AUTH_TOKEN_LIFE_SPAN.toString()) // 7 days
  });
};

const openAuthWindow = (url: string) => {
  return new Promise((resolve, reject) => {
    const authWindow = window.open(url, '_blank', 'width=800,height=800');

    const messageListener = (event: MessageEvent) => {
      if (event.origin !== Config.PUBLIC_API_URL) {
        return;
      }
      if (!event.data) {
        reject(new Error(ERRORS.EVENT_DATA));
        authWindow?.close();
        window.removeEventListener(Constants.MESSAGE, messageListener);
        return;
      }
      if (event.data.type === Constants.AUTH_SUCCESS) {
        resolve(event.data.user);
        authWindow?.close();
        window.removeEventListener(Constants.MESSAGE, messageListener);
      } else {
        reject(new Error(event.data.error));
        authWindow?.close();
        window.removeEventListener(Constants.MESSAGE, messageListener);
      }
    };

    window.addEventListener(Constants.MESSAGE, messageListener);
  });
};

export const toggleCreditsModal = (user, dispatch) => {
  if (user?.subscriptionPlan) {
    dispatch(Actions.toggleCreditsTopUpModal(true));
  } else {
    dispatch(Actions.toggleSubscriptionModal(true));
  }
};

export {
  getBasePath,
  getRelationPrompt,
  getToken,
  getValueObj,
  openAuthWindow,
  removeToken,
  setToken,
  simpleEncode
};
