import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  MenuItem,
  Radio,
  Select,
  Typography
} from '@mui/material';
import ProblemModal from 'modals/AddProblem';
import Actions from 'redux-state/actions';
import { GetLoader, GetRelatedProblems } from 'redux-state/commons/selectors';
import { Tag } from 'components/CardTag';
import { Constants } from 'utilities/constants';
import {
  MainDialog,
  MainDialogTitle,
  ModalTitle,
  StyledAddNewProb,
  StyledAddProb,
  StyledIconButton,
  StyledModalBox,
  StyledRadioGroup
} from './StyledComponents';

interface AddNewProblemModalProps {
  onClose: () => void;
  open: boolean;
  problemList: string[];
  setProblemList: React.Dispatch<React.SetStateAction<string[]>>;
  tag: Tag;
}

export const AddNewProblemModal: React.FC<AddNewProblemModalProps> = ({
  onClose,
  open,
  problemList,
  setProblemList,
  tag
}) => {
  const dispatch = useDispatch();
  const [selectedProblem, setSelectedProblem] = useState<string>('');
  const [problemType, setProblemType] = useState<string>(
    Constants.ADD_EXISTING_PROBLEM
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const loader = GetLoader();
  const allProblems = GetRelatedProblems();
  const filteredProblems =
    allProblems && Object.keys(allProblems).length
      ? allProblems.filter((problem) => {
          if (problemList.includes(problem.id as string)) {
            return false;
          }
          if (!problem.isAiGenerated) {
            return true;
          }
          const filteredList =
            problem.children.length &&
            problem.children.some((child) => !child.isAiGenerated);
          return filteredList;
        })
      : [];

  const handleSelectChange = useCallback((event) => {
    setSelectedProblem(event.target.value);
  }, []);

  const onModalClose = useCallback(() => {
    setProblemType(Constants.ADD_EXISTING_PROBLEM);
    onClose();
  }, [onClose, setProblemType]);

  const handleAddProblem = useCallback(() => {
    if (selectedProblem) {
      setProblemList([...problemList, selectedProblem]);
    }
    onModalClose();
  }, [onModalClose, problemList, selectedProblem, setProblemList]);

  const handleNewProblemCreation = useCallback(
    (id) => {
      setProblemList([...problemList, id]);
      onModalClose();
    },
    [onModalClose, problemList, setProblemList]
  );

  const handleProblemType = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newType = e.target.value;
      setProblemType(newType);
      if (newType === Constants.CREATE_NEW_PROBLEM) {
        setIsModalOpen(true);
      }
    },
    []
  );

  useEffect(() => {
    if (open) {
      dispatch(
        Actions.getRelatedItems({
          resource: Constants.TAGS,
          key: tag?.key as string,
          item: Constants.PROBLEMS,
          pagination: { page: 0, perPage: 0 }
        })
      );
    }
  }, [dispatch, tag, open]);

  const selectRef = useRef(null);
  const [selectWidth, setSelectWidth] = useState(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (selectRef.current) {
        setSelectWidth(selectRef.current.offsetWidth);
      }
    });

    if (selectRef.current) {
      resizeObserver.observe(selectRef.current);
    }

    return () => {
      if (selectRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        resizeObserver.unobserve(selectRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectRef.current]);

  return (
    <MainDialog
      aria-labelledby="customized-dialog-title"
      onClose={onModalClose}
      open={open}
    >
      <StyledAddNewProb>
        <MainDialogTitle id="customized-dialog-title">
          <ModalTitle>Add New Problem</ModalTitle>
        </MainDialogTitle>
        <StyledIconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </StyledIconButton>
      </StyledAddNewProb>
      <Divider />
      <StyledModalBox>
        <Box>
          <StyledRadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            onChange={handleProblemType}
            value={problemType}
          >
            <FormControlLabel
              value={Constants.CREATE_NEW_PROBLEM}
              control={<Radio />}
              label={Constants.CREATE_NEW_PROBLEM}
            />
            <FormControlLabel
              value={Constants.ADD_EXISTING_PROBLEM}
              control={<Radio />}
              label={Constants.ADD_EXISTING_PROBLEM}
            />
          </StyledRadioGroup>
        </Box>
        {problemType === Constants.ADD_EXISTING_PROBLEM ? (
          <Box ref={selectRef}>
            <Select
              onChange={handleSelectChange}
              sx={{
                width: '100%',
                marginTop: '25px'
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    width: '100%',
                    maxWidth: selectWidth ? `${selectWidth}px` : '59rem',
                    minWidth: 'auto !important'
                  }
                }
              }}
            >
              {loader ? (
                <Box sx={{ textAlign: 'center', margin: '10px 0px' }}>
                  <CircularProgress />
                </Box>
              ) : filteredProblems.length ? (
                filteredProblems.map((problem) => (
                  <MenuItem
                    key={problem.id}
                    value={problem.id}
                    sx={{
                      width: '100%',
                      whiteSpace: 'normal',
                      wordWrap: 'break-word',
                      lineHeight: '1.2'
                    }}
                  >
                    {problem.title}
                  </MenuItem>
                ))
              ) : (
                <MenuItem
                  sx={{
                    width: '100%',
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                    lineHeight: '1.2'
                  }}
                >
                  <Typography>No problems exist for this tag.</Typography>
                </MenuItem>
              )}
            </Select>
          </Box>
        ) : (
          <ProblemModal
            open={isModalOpen}
            onClose={handleCloseModal}
            problemSolutionCreationCallBack={handleNewProblemCreation}
          />
        )}
      </StyledModalBox>
      <Divider />
      <StyledAddProb>
        <Button onClick={handleAddProblem}>Add Problem</Button>
      </StyledAddProb>
    </MainDialog>
  );
};
