import { Box, styled, Typography } from '@mui/material';
import { colorPalette } from 'theme';
import { Constants } from 'utilities/constants';

const getBackgroundColor = (type: string) => {
  switch (type) {
    case Constants.PRODUCT:
      return colorPalette.magnolia;

    case Constants.PROBLEM:
      return colorPalette.amour;

    case Constants.SOLUTION:
      return colorPalette.snowyMint;

    case Constants.APPLICATION:
    case Constants.Invention:
      return colorPalette.marzipan;

    default:
      return colorPalette.white;
  }
};

export const ReactionButtons = styled(Box)<{ type: string }>(
  ({ type, theme }) => ({
    [theme.breakpoints.up('md')]: {
      padding: '.625rem 1.125rem',
      borderRadius: '1.875rem',
      minHeight: '2.8125rem',
      minWidth: '113px'
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '4.625rem',
      padding: '.3rem',
      '& svg': {
        width: '15px',
        height: '15px'
      }
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '.3rem .5rem',
    borderRadius: '1.875rem',
    minHeight: '1rem',
    minWidth: '5.625rem',
    backgroundColor: getBackgroundColor(type)
  })
);

export const StyledLikes = styled(Typography)(({ theme }) => ({
  color: colorPalette.black,
  fontFamily: 'montserrat',
  fontSize: '1rem',
  fontWeight: '500',
  margin: '0px .625rem 0px .625rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.8rem',
    margin: '0px .2rem 0px'
  }
}));
