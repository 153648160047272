import { Box, Button, Divider, styled, Typography } from '@mui/material';
import { colorPalette } from 'theme';

export const ContainerGrid = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  margin: '10px 0px',
  width: '100%'
});

export const MainBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-around'
});

export const StyledInnerBox = styled(Box)({
  background: 'white',
  position: 'relative'
});

export const StyledButton = styled(Button)({
  '&:hover': {
    backgroundColor: 'transparent'
  }
});

export const StyledButtonBox = styled(Box)({
  alignItems: 'center',
  borderRadius: '43px',
  boxShadow: '0 0.0625rem 0.4375rem #ebe1e1',
  display: 'flex',
  justifyContent: 'center',
  padding: '2px 15px'
});

export const StyledTypography = styled(Typography)({
  color: colorPalette.grey,
  fontSize: '12px',
  fontWeight: 500,
  textTransform: 'none',
  width: 'max-content'
});

export const DropDownBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '.9375rem'
});

export const StyledDivider = styled(Divider)({
  borderColor: colorPalette.spanishGray,
  marginBottom: '20px',
  marginTop: '-20px',
  width: '100%'
});
