import React from 'react';
import { Node } from 'interface/common';
import { useIsMediumScreen } from 'theme';
import { Constants, TAG_TYPES } from 'utilities/constants';
import { Header } from './DrawerMobileView/Header';
import { InventionContent } from './Invention/InventionContent';
import { ProblemContent } from './Problem/ProblemContent';
import { ProductContent } from './Product/ProductContent';
import { SolutionContent } from './Solution/SolutionContent';

interface HeaderContentProps {
  drawerItemType: string;
  handleClick: () => void;
  isCurrentConceptSolution: boolean;
  item: Node;
  onShowAllTagsClick: () => void;
  openAddToConceptModal: () => void;
  setImage: React.Dispatch<React.SetStateAction<string>>;
  showAllTags: boolean;
  votingPanel?: React.ReactNode;
}

const typeToContentMap = {
  [Constants.PROBLEM]: ProblemContent,
  [Constants.APPLICATION]: InventionContent,
  [Constants.SOLUTION]: SolutionContent,
  [Constants.PRODUCT]: ProductContent
};

export const HeaderContent: React.FC<HeaderContentProps> = ({
  drawerItemType,
  handleClick,
  isCurrentConceptSolution = false,
  item,
  onShowAllTagsClick,
  openAddToConceptModal,
  setImage,
  showAllTags,
  votingPanel
}) => {
  const isMediumScreen = useIsMediumScreen();
  const displayedTags = item?.tags?.filter(
    (tag) => tag.type !== TAG_TYPES.WORKPLACE
  );
  const ContentRenderer = typeToContentMap[item?.type || drawerItemType];

  return (
    <>
      {isMediumScreen && (
        <Header
          drawerItemType={drawerItemType}
          handleClick={handleClick}
          isCurrentConceptSolution={isCurrentConceptSolution}
          openAddToConceptModal={openAddToConceptModal}
        />
      )}
      {ContentRenderer && (
        <ContentRenderer
          displayedTags={displayedTags}
          item={item}
          setImage={setImage}
          onShowAllTagsClick={onShowAllTagsClick}
          showAllTags={showAllTags}
          votingPanel={votingPanel}
        />
      )}
    </>
  );
};
