import React, { useContext, useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { Solution } from 'components/CardSolution';
import { Tag, TagInfo } from 'components/CardTag';
import { EditButton } from 'components/common/PsButton';
import { PsChip } from 'components/common/PsChipNew';
import { DataContext } from 'contexts/DataContext';
import { getBasePath } from 'helpers/common';
import useRouter from 'hooks/useRouter';
import { makeStyles } from 'tss-react/mui';
import { removeDuplicates } from '../../helpers';
import { PsTheme } from '../../theme';
import { LocationIcon } from '../icons/LocationIcon';
import { SchoolIcon } from '../icons/SchoolIcon';
import { WorkplaceIcon } from '../icons/WorkplaceIcon';

type ClassKey = 'root' | 'editBtn';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      '& > div': {
        maxWidth: '100%'
      },
      '& .tag-icon': {
        marginLeft: 7
      }
    },
    editBtn: {
      display: 'inline-flex',
      alignItems: 'center',
      minHeight: 22,
      margin: 4,
      padding: 2,
      '& svg': {
        width: 12,
        height: 12,
        margin: '0 5px'
      }
    }
  };
});

type TagsProps = {
  className?: string;
  tags?: Array<string | number>;
  tagsList?: Array<TagInfo>;
  relations?: Array<{ id: string | number }>;
  edit?: string;
  onEditClick?: () => void;
  withIcons?: boolean;
};

const TagsView = ({
  className,
  tags,
  tagsList: initTagsList,
  relations,
  edit,
  onEditClick,
  withIcons
}: TagsProps) => {
  const { classes } = useStyles();

  const { dataProvider } = useContext(DataContext);
  const router = useRouter();
  const [tagsList, setTagsList] = useState<Array<Tag>>([]);
  const [solutionsList, setSolutionsList] = useState<Array<Solution>>([]);

  const getUrl = (item) => {
    const basePath = getBasePath(item);
    return `/${basePath}/${(item as TagInfo).key || (item as TagInfo).id}`;
  };

  useEffect(() => {
    if (!tags && !initTagsList && !relations) {
      setTagsList([]);
      setSolutionsList([]);
      return;
    }
    if (initTagsList) {
      setTagsList(initTagsList);
    } else if (tags) {
      const finalTags = removeDuplicates(tags);
      dataProvider
        .getMany<Tag>('tags', { ids: finalTags })
        .then(({ data }) => {
          const res: Array<Tag> = [];
          finalTags.forEach((t) => {
            const el = data.find((e) => e.id === t);
            if (el) {
              res.push(el);
            }
          });
          setTagsList(res);
        })
        .catch((err) => {
          console.error(err);
        });
    } else if (relations) {
      const list = relations.map((item) => item.id);
      const uniqueList = new Set([...list]);
      dataProvider
        .getMany<Solution>('solutions', { ids: Array.from(uniqueList) })
        .then(({ data }) => {
          setSolutionsList(data);
        })
        .catch((err) => {
          setSolutionsList([]);
          console.error(err);
        });
    }
  }, [tags, initTagsList, relations]);

  if (!tagsList.length && !solutionsList.length) {
    return null;
  }

  let rootClassName = classes.root;
  if (className) {
    rootClassName += ' ' + className;
  }

  if (relations) {
    return (
      <div className={rootClassName}>
        {solutionsList.map((solution, i) => {
          const onClick = () => {
            router.push(`/solutions/${solution.key || solution.id}`);
          };
          return (
            <PsChip
              key={`${solution.id}.${i}`}
              label={solution.title}
              onClick={onClick}
              size="small"
            />
          );
        })}
      </div>
    );
  }

  return (
    <div className={rootClassName}>
      {edit === 'before' ? (
        <EditButton
          title="Edit tags"
          className={classes.editBtn}
          onClick={onEditClick}
        />
      ) : null}
      {tagsList.map((tag) => {
        const onClick = () => {
          router.push(getUrl(tag));
        };

        let icon;
        if (withIcons) {
          const defaultColor = '#07A854';
          let finalColor = tag.color;
          if (!finalColor || !finalColor.startsWith('#')) {
            finalColor = defaultColor;
          }
          if (tag.type === 'school') {
            icon = (
              <SchoolIcon className="tag-icon" color={finalColor} size={10} />
            );
          } else if (tag.type === 'workplace') {
            icon = (
              <WorkplaceIcon
                className="tag-icon"
                color={finalColor}
                size={10}
              />
            );
          } else {
            icon = (
              <LocationIcon className="tag-icon" color={finalColor} size={10} />
            );
          }
        }

        return (
          // <PsChip label="Health" textColor="#DA8100" size="small" />
          <PsChip
            key={tag.id}
            label={tag.name}
            title={tag.name}
            icon={icon}
            textColor={tag.color}
            onClick={onClick}
            size="small"
          />
        );
      })}
      {edit === 'after' ? (
        <EditButton
          title="Edit tags"
          className={classes.editBtn}
          onClick={onEditClick}
        />
      ) : null}
    </div>
  );
};

export const Tags = styled(TagsView)({});

export default Tags;
