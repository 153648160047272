import React, { useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { PsFormControl } from 'components/common/PsFormControl';
import { PsInput } from 'components/common/PsInput';
import { ImageField } from 'components/ImageField';
import { ModalDataContext } from 'contexts/ModalDataContext';
import styles from 'modals/ModalWindow.module.scss';
import { PsTheme } from '../../../theme';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root';

const useStyles = makeStyles()(() => {
  const psTheme = useTheme() as PsTheme;
  return {
    root: {}
  };
});

const ContestDescriptionView = () => {
  const { classes } = useStyles();
  const { values, handleInputChange } = useContext(ModalDataContext);

  const { challengeExternalReward } = values;
  return (
    <div className={styles.stepWrapper}>
      <Typography className={styles.textSmall}>
        By adding an external reward or sponsorship, you can incentivize more
        and better ideas.
      </Typography>

      <PsFormControl
        placeholder="Type reward title here"
        label="External reward title"
        helperText={`Example: Samsung 65" TV.`}
      >
        <PsInput
          name="challengeExternalReward"
          onChange={handleInputChange}
          value={challengeExternalReward}
        />
      </PsFormControl>

      <ImageField
        name="files2Challenge"
        value={values.files2Challenge}
        onChange={handleInputChange}
        supportDraw
      />

      <Typography className={styles.textSmall}>
        I promise to provide this reward to the winner of the challenge
      </Typography>
    </div>
  );
};

export const ChallengeInternalReward = styled(ContestDescriptionView)({});

export default ChallengeInternalReward;
