import { Box, Typography, styled } from '@mui/material';
import { colorPalette } from 'theme';

export const MainBox = styled(Box)({
  backgroundColor: colorPalette.snowWhite,
  border: `2px dashed ${colorPalette.whiteSilver}`,
  cursor: 'pointer',
  padding: 2,
  textAlign: 'center',
  '&:hover': {
    borderColor: colorPalette.mediumGray,
    backgroundColor: colorPalette.whisper
  }
});

export const StyledTypography = styled(Typography)({
  fontSize: '1.125rem',
  fontWeight: '500',
  mb: 1,
  whiteSpace: 'pre'
});

export const StyledSpan = styled(Box)({
  color: colorPalette.purple,
  fontWeight: '500',
  textDecoration: 'underline'
});
