import React from 'react';
import { IconButton, Tooltip } from '@mui/material';

type PlacementType =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';

export type ToolTipProps = {
  color: string;
  Icon?: React.ReactNode;
  title: string;
  placement?: PlacementType;
};
export const ToolTip = ({ color, title, Icon, placement }: ToolTipProps) => {
  return (
    <Tooltip color={color} title={title} placement={placement}>
      {Icon && <IconButton>{Icon}</IconButton>}
    </Tooltip>
  );
};
