import React from 'react';
import { Box } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Constants } from 'utilities/constants';
import SimpleIconButton from 'components/SimpleIconButton';
import { StyledHeaderText, StyledHeaderWrapper } from './StyledComponents';

interface HeaderProps {
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
}

export const Header: React.FC<HeaderProps> = ({ onClose }) => {
  return (
    <Box>
      <StyledHeaderWrapper>
        <StyledHeaderText variant="h5">{Constants.C_CREATE}</StyledHeaderText>

        <SimpleIconButton
          Icon={CancelOutlinedIcon}
          color="primary"
          onClick={onClose}
        />
      </StyledHeaderWrapper>
    </Box>
  );
};
