import React from 'react';

export const AiTitleIcon = ({ size = 20 }: { size?: number }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#632DDD"
        fillRule="evenodd"
        d="M15.99 6.734a2.457 2.457 0 00-2.457-2.457H6.384a2.457 2.457 0 00-2.457 2.457v7.149a2.457 2.457 0 002.457 2.457h7.149a2.458 2.458 0 002.457-2.457V6.734zm-4.244 1.787v3.575a.67.67 0 101.34 0V8.52a.67.67 0 10-1.34 0zm-.894 3.575V9.415a1.564 1.564 0 00-1.564-1.564h-.893A1.564 1.564 0 006.83 9.415v2.68a.67.67 0 101.34 0v-.67h1.34v.67a.67.67 0 101.341 0zm-2.68-2.01h1.34v-.671a.223.223 0 00-.224-.223h-.893a.223.223 0 00-.224.223v.67z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#632DDD"
        fillRule="evenodd"
        d="M15.32 10.979h3.573a.67.67 0 100-1.34H15.32a.67.67 0 100 1.34zm-4.692-6.032V1.373a.67.67 0 10-1.34 0v3.574a.67.67 0 001.34 0zM9.288 15.67v3.574a.67.67 0 001.34 0V15.67a.67.67 0 10-1.34 0zM4.595 9.638H1.022a.67.67 0 000 1.34h3.574a.67.67 0 100-1.34zm10.723-1.34h2.234a.67.67 0 100-1.34h-2.234a.67.67 0 100 1.34zm-7.372-3.35V2.712a.67.67 0 00-1.34 0v2.234a.67.67 0 001.34 0zM6.607 15.67v2.234a.67.67 0 101.34 0V15.67a.67.67 0 10-1.34 0zm-2.01-8.712H2.361a.67.67 0 000 1.34h2.234a.67.67 0 000-1.34zm10.722 6.702h2.234a.67.67 0 100-1.34h-2.234a.67.67 0 100 1.34zm-2.01-8.713V2.713a.67.67 0 10-1.34 0v2.234a.67.67 0 001.34 0zm-1.34 10.723v2.234a.67.67 0 001.34 0V15.67a.67.67 0 10-1.34 0zm-7.373-3.35H2.362a.67.67 0 100 1.34h2.234a.67.67 0 100-1.34z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};
