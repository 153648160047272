import React from 'react';
import { Avatar, Box, BoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TAG_TYPES, VARIANT } from 'utilities/constants';
import { colorPalette, useIsMediumScreen } from '../../theme';
import { ItemsType } from '../CardProfile';

type StatsViewProps = {
  count: number;
  iconUrl: string;
  onWithdraw?: () => void;
  type: string;
  variant?: string;
  withdraw?: boolean;
};
export const EarningView = ({
  count,
  iconUrl,
  onWithdraw,
  type,
  variant,
  withdraw
}: StatsViewProps) => {
  const isMediumScreen = useIsMediumScreen();

  let fontSize, height, width;
  if (variant && variant == VARIANT.SMALL) {
    fontSize = '.589rem';
    height = '38px';
    width = '38px';
  }

  const isCountGreaterThanThreshold = count > 99;

  return (
    <StatsMainBox onClick={onWithdraw}>
      <StyledTabIcon
        alt={'tab.title'}
        height={height}
        src={iconUrl}
        width={width}
      />
      <CountBox isMediumScreen={isMediumScreen} type={type} fontSize={fontSize}>
        {isCountGreaterThanThreshold
          ? TAG_TYPES.COUNT_LIMIT
          : Math.round(count)}
      </CountBox>
    </StatsMainBox>
  );
};

interface StyledBoxProps extends BoxProps {
  isMediumScreen: boolean;
  fontSize: string;
  type: string;
}

const StatsMainBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  position: 'relative',
  pointer: 'cursor'
});

const CountBox = styled(Box)<StyledBoxProps>(({ type, fontSize }) => {
  let positioningStyles = {};

  switch (type) {
    case ItemsType.IDEA_NFT:
      positioningStyles = {
        top: '11px',
        color: colorPalette.white
      };
      break;
    default:
      positioningStyles = {
        color: colorPalette.black
      };
      break;
  }

  return {
    fontSize: fontSize ?? '12px',
    fontWeight: 'bold',
    position: 'absolute',
    ...positioningStyles
  };
});

const StyledTabIcon = styled(Avatar)<{ width?: string; height?: string }>(
  ({ height, width }) => ({
    color: 'inherit',
    height: height ?? '48px',
    width: width ?? '48px'
  })
);
