import React, { useState, useContext, useCallback } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { TagInfo } from 'components/CardTag';
import { Profile } from 'components/CardProfile';
import { AuthContext } from 'contexts/AuthContext';
import { DataContext } from 'contexts/DataContext';
import { PsLogo } from 'components/common/PsLogo';
import { PsButton } from 'components/common/PsButton';
import { PsInput } from 'components/common/PsInput';
import { PsFormControl, ShortEvent } from 'components/common/PsFormControl';
import { ShareIcon } from 'components/icons/ShareIcon';
import { activityItem } from '../../helpers';
import { PsTheme } from '../../theme';
import Popover from '@mui/material/Popover';
import Config from 'config/config';
import { makeStyles } from 'tss-react/mui';

type ShareInfo = {
  // activity
  itemId: string;
  itemKey: string;
  itemTitle: string;
  // contest
  bountyCoins?: number;
  tagName?: string;
};

const getShareText = (
  type: string,
  shareInfo: ShareInfo,
  tagsList?: Array<TagInfo>
): string => {
  let text = '';
  let tagsStr = '';
  if (tagsList && tagsList.length) {
    const tagNames = tagsList.filter((t) => t.name).map((t) => `#${t.name}`);
    tagsStr = ` in the ${tagNames.join(', ')} group`;
  }
  if (type === activityItem.PROBLEM) {
    text = `Earn IdeaCoins and create Idea #NFTs by solving the problem of ${shareInfo.itemTitle}${tagsStr} on MindMiner.`;
  } else if (type === activityItem.SOLUTION) {
    text = `Earn IdeaCoins and create Idea #NFTs by improving the solution of ${shareInfo.itemTitle}${tagsStr} on MindMiner.`;
  } else if (type === activityItem.APPLICATION) {
    text = `Earn IdeaCoins and create Idea #NFTs by improving the invention of ${shareInfo.itemTitle}${tagsStr} on MindMiner.`;
  } else if (type === activityItem.PRODUCT) {
    text = `Earn IdeaCoins and create Idea #NFTs by improving the product of ${shareInfo.itemTitle}${tagsStr} on MindMiner.`;
  } else if (type === activityItem.TAG) {
    text = `Earn IdeaCoins and create Idea #NFTs with ${shareInfo.itemTitle} MindMiners.`;
  } else if (type === activityItem.PROFILE) {
    text = `Earn IdeaCoins and create Idea #NFTs with ${shareInfo.itemTitle} on MindMiner.`;
  } else if (type === activityItem.CONTEST) {
    const coins = shareInfo.bountyCoins;
    const name = shareInfo.itemTitle;
    const tag = shareInfo.tagName;
    text = `Earn the ${coins} IdeaCoin jackpot by solving the ${name} problems in the ${tag} group on MindMiner"`;
  }
  return text;
};

const rewardType = {
  SHARE_FB: 'share-fb',
  SHARE_LN: 'share-ln',
  SHARE_TW: 'share-tw',
  SHARE_IN: 'share-in',
  SHARE_WA: 'share-wa',
  SHARE_RB: 'share-rb',
  SHARE_TT: 'share-tt'
};

type ClassKey =
  | 'newShareButton'
  | 'popover'
  | 'root'
  | 'paper'
  | 'title'
  | 'social'
  | 'input'
  | 'submit'
  | 'row';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    newShareButton: {
      display: 'inline-flex',
      alignItems: 'center',
      color: '#677890',
      marginLeft: 50,
      '& > button': {
        background: 'none',
        border: 'none',
        outline: 'none',
        margin: '0',
        padding: '0',
        cursor: 'pointer',
        appearance: 'none'
      },
      '& > span': {
        paddingLeft: 10,
        fontSize: 18,
        lineHeight: '20px'
      }
    },
    popover: {
      // pointerEvents: 'none',
    },
    root: {},
    paper: {
      width: 380,
      margin: '10px 0',
      padding: '24px 22px 15px',
      borderRadius: 10,
      border: '1px solid rgba(121, 114, 140, 0.11)',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'
    },
    title: {
      fontSize: 18,
      marginBottom: 20,
      '& b': {
        fontWeight: 600
      }
    },
    social: {
      padding: '1.0625rem 0',
      '& > a': {
        marginRight: 20
      },
      '& > a:last-child': {
        marginRight: 0
      }
    },
    input: {
      marginBottom: 15,
      '& label': {
        fontSize: 14,
        color: psTheme.palette.third.main
      }
    },
    submit: {
      paddingLeft: 15,
      paddingRight: 15,
      borderRadius: 10,
      lineHeight: '28px',
      minHeight: 40,
      '& svg': {
        marginLeft: 15
      }
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 10,
      '& > span': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
      },
      '& b': {
        fontWeight: 600
      },
      '& .coin': {
        marginLeft: 5
      }
    }
  };
});

type ShareBtnNewProps = {
  shareInfo: ShareInfo;
  type: string;
  className?: string;
  tagsList?: Array<TagInfo>;
};

const ShareBtnNewView = ({
  className,
  type,
  shareInfo,
  tagsList
}: ShareBtnNewProps) => {
  const { classes } = useStyles();
  const { user } = useContext(AuthContext);
  const { dataProvider } = useContext(DataContext);
  const psTheme: PsTheme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [email, setEmail] = useState('');
  const [emailSuccess, setEmailSuccess] = useState('');
  const [emailError, setEmailError] = useState('');
  const [emailLoading, setEmailLoading] = useState(false);
  const open = Boolean(anchorEl);
  const resourceType = type;
  const resourceId = shareInfo.itemId;
  const resourceKey = shareInfo.itemKey;
  const resourceName = shareInfo.itemTitle || '(empty)';
  const shareText = getShareText(type, shareInfo, tagsList);
  let shareUrl = '';
  if (typeof window !== 'undefined') {
    shareUrl = `${window.location.origin}/${resourceType}/${resourceKey}`;
  }

  const shareTextE = encodeURIComponent(shareText + ' ');
  const shareUrlE = encodeURIComponent(shareUrl);
  const twitterShareUrl = `https://twitter.com/intent/tweet?text=${shareTextE}&url=${shareUrlE}`;
  const watsappShareUrl = `whatsapp://send?text=${shareUrlE}${shareUrlE}`;
  const redditShareUrl = `https://www.reddit.com/submit?url=${shareUrlE}&title=${shareTextE}`;
  const linkedIdShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${shareUrlE}`;
  const facebookShareUrl = `https://www.facebook.com/dialog/share?app_id=${Config.NEXT_PUBLIC_FB_APP_ID}&display=popup&href=${shareUrlE}&quote=${shareTextE}`;

  const onEmailChange = useCallback(
    (e: ShortEvent) => {
      setEmail(e.target.value);
      setEmailError('');
      setEmailSuccess('');
    },
    [setEmail]
  );

  const handlePopoverOpen = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const onFacebookShareClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      if (typeof window !== 'undefined') {
        const left = window.innerWidth / 2 - 350;
        const top = window.innerHeight / 2 - 350;
        window.open(
          facebookShareUrl,
          '_blank',
          `width=700,height=700,left=${left},top=${top}`
        );
      }

      if (user) {
        dataProvider
          .trackSharing(resourceId, resourceType, rewardType.SHARE_FB)
          .catch((err: Error) => {
            console.error(err);
          });
      }
    },
    [[user, dataProvider]]
  );

  const onLinkedinShareClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      if (typeof window !== 'undefined') {
        const left = window.innerWidth / 2 - 350;
        const top = window.innerHeight / 2 - 350;
        window.open(
          linkedIdShareUrl,
          '_blank',
          `width=700,height=700,left=${left},top=${top}`
        );
      }

      if (user) {
        dataProvider
          .trackSharing(resourceId, resourceType, rewardType.SHARE_LN)
          .catch((err: Error) => {
            console.error(err);
          });
      }
    },
    [[user, dataProvider]]
  );

  const onTwitterShareClick = useCallback(
    (e: React.MouseEvent) => {
      if (user) {
        dataProvider
          .trackSharing(resourceId, resourceType, rewardType.SHARE_TW)
          .catch((err: Error) => {
            console.error(err);
          });
      }
    },
    [user, dataProvider]
  );

  const onWatsappShareClick = useCallback(
    (e: React.MouseEvent) => {
      if (user) {
        dataProvider
          .trackSharing(resourceId, resourceType, rewardType.SHARE_WA)
          .catch((err: Error) => {
            console.error(err);
          });
      }
    },
    [user, dataProvider]
  );

  const onRedditShareClick = useCallback(
    (e: React.MouseEvent) => {
      if (user) {
        dataProvider
          .trackSharing(resourceId, resourceType, rewardType.SHARE_RB)
          .catch((err: Error) => {
            console.error(err);
          });
      }
    },
    [user, dataProvider]
  );

  const onInviteSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (emailLoading || !email) {
      return;
    }
    setEmailError('');
    setEmailSuccess('');
    setEmailLoading(true);
    dataProvider
      .inviteUser<Profile>(email, resourceType, resourceId)
      .then((data) => {
        if (data && data.invited) {
          setEmailSuccess(`${email} invited.`);
          setEmail('');
        } else {
          setEmailError(`${email} already exist.`);
        }
      })
      .catch((err) => {
        setEmailError(err.message);
      })
      .finally(() => {
        setEmailLoading(false);
      });
  };

  return (
    <>
      <span className={classes.newShareButton}>
        <button onClick={handlePopoverOpen}>
          <ShareIcon />
        </button>
        <span>+1</span>
      </span>
      <Popover
        id="share-info"
        className={classes.popover}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        classes={{ paper: classes.paper }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <div className={classes.title}>
          Invite someone to solve <b>{resourceName}</b> problems
        </div>
        <div className={classes.row}>
          <span>
            <span>
              Share to <b>earn IdeaPoints</b>:
            </span>
            <PsLogo
              className="coin"
              size={23}
              color={psTheme.palette.primary.main}
              small
            />
          </span>
        </div>

        <div className={classes.social}>
          <a href="#" onClick={onFacebookShareClick}>
            <img
              src="/icons/share-facebook.svg"
              alt="facebook"
              width="36"
              height="36"
            />
          </a>
          <a href="#" onClick={onLinkedinShareClick}>
            <img
              src="/icons/share-linkedin.svg"
              alt="linkedin"
              width="36"
              height="36"
            />
          </a>
          {/*<a href="#">*/}
          {/*  <img src={shareInstagram} alt="instagram" />*/}
          {/*</a>*/}
          <a
            href={twitterShareUrl}
            onClick={onTwitterShareClick}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/icons/share-twitter.svg"
              alt="twitter"
              width="36"
              height="36"
            />
          </a>
          <a
            href={watsappShareUrl}
            onClick={onWatsappShareClick}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/icons/share-watsup.svg"
              alt="watsup"
              width="36"
              height="36"
            />
          </a>
          <a
            href={redditShareUrl}
            onClick={onRedditShareClick}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/icons/share-reddit.svg"
              alt="reddit"
              width="36"
              height="36"
            />
          </a>
        </div>

        <form action="#" onSubmit={onInviteSubmit}>
          <PsFormControl
            className={classes.input}
            label="Add someone's e-mail address below:"
            error={!!emailError}
            helperText={emailError || emailSuccess}
            labelLight
          >
            <PsInput name="email" onChange={onEmailChange} value={email} />
          </PsFormControl>

          <div className={classes.row}>
            <span>
              <span>
                Invite to <b>earn IdeaCoins</b>:
              </span>
              <PsLogo
                className="coin"
                size={23}
                color={psTheme.palette.primary.main}
                small
              />
            </span>
            <br />
            <PsButton
              className={classes.submit}
              color="secondary"
              type="submit"
              disabled={emailLoading}
            >
              Send E-mail Invite
              <PsLogo size={23} small />
            </PsButton>
          </div>
        </form>
      </Popover>
    </>
  );
};

export const ShareBtnNew = styled(ShareBtnNewView)({});

export default ShareBtnNew;
