import type { JSX } from 'react';
import { Avatar, Box, styled } from '@mui/material';
import { FaCheck } from 'react-icons/fa6';
import { MdLightbulb, MdQuestionMark } from 'react-icons/md';
import { PiCubeBold } from 'react-icons/pi';
import { colorPalette, useIsMediumScreen } from 'theme';
import { Constants } from 'utilities/constants';

interface IconConfig {
  icon: JSX.Element;
  borderColor: string;
  backgroundColor: string;
}

// eslint-disable-next-line complexity
const getIconConfig = (type: string, isMediumScreen: boolean): IconConfig => {
  switch (type) {
    case Constants.PROBLEM:
      return {
        icon: (
          <MdQuestionMark
            size={isMediumScreen ? Constants.SMALL_10 : Constants.MEDIUM_16}
            color={colorPalette.red}
          />
        ),
        borderColor: colorPalette.red,
        backgroundColor: colorPalette.pink
      };
    case Constants.SOLUTION:
      return {
        icon: (
          <FaCheck
            size={isMediumScreen ? Constants.SMALL_10 : Constants.MEDIUM_15}
            color={colorPalette.green}
          />
        ),
        borderColor: colorPalette.green,
        backgroundColor: colorPalette.mintTulip
      };
    case Constants.APPLICATION:
      return {
        icon: (
          <MdLightbulb
            size={isMediumScreen ? Constants.SMALL_10 : Constants.MEDIUM_16}
            color={colorPalette.meteor}
          />
        ),
        borderColor: colorPalette.meteor,
        backgroundColor: colorPalette.marzipan
      };
    case Constants.Invention:
      return {
        icon: (
          <MdLightbulb
            size={isMediumScreen ? Constants.SMALL_10 : Constants.MEDIUM_16}
            color={colorPalette.meteor}
          />
        ),
        borderColor: colorPalette.meteor,
        backgroundColor: colorPalette.marzipan
      };
    case Constants.PRODUCT:
      return {
        icon: (
          <PiCubeBold
            size={isMediumScreen ? Constants.SMALL_10 : Constants.MEDIUM_16}
            color={colorPalette.purple}
          />
        ),
        borderColor: colorPalette.purple,
        backgroundColor: colorPalette.lightPurple
      };
    default:
      return {
        icon: (
          <FaCheck
            size={isMediumScreen ? Constants.SMALL_10 : Constants.MEDIUM_15}
            color={colorPalette.green}
          />
        ),
        borderColor: colorPalette.green,
        backgroundColor: colorPalette.lightGreen
      };
  }
};

const IdentifierIcon = ({
  type,
  image,
  profileKey,
  isNewsFeed = false
}: {
  type: string;
  image: string;
  profileKey?: string;
  isNewsFeed?: boolean;
}) => {
  const isMediumScreen = useIsMediumScreen();
  const { icon, borderColor, backgroundColor } = getIconConfig(
    type,
    isMediumScreen
  );

  return (
    <div>
      <a
        href={profileKey && `/profiles/${profileKey}`}
        target="_blank"
        rel="noreferrer"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <CircleContainer>
          <AttachedIcon
            isMediumScreen={isMediumScreen}
            backgroundColor={backgroundColor}
            borderColor={borderColor}
          >
            {icon}
          </AttachedIcon>
          <Circle
            borderColor={borderColor}
            isMediumScreen={isMediumScreen}
            isNewsFeed={isNewsFeed}
          >
            <StyledAvatar src={image} />
          </Circle>
        </CircleContainer>
      </a>
    </div>
  );
};

const CircleContainer = styled(Box)(({ theme }) => ({
  borderRadius: '50%',
  position: 'relative',
  marginLeft: '-1px',
  marginRight: '0.625rem',
  [theme.breakpoints.up('md')]: {
    marginRight: '0'
  }
}));

const Circle = styled(Box)<{
  isMediumScreen: boolean;
  borderColor: string;
  isNewsFeed?: boolean;
}>(({ theme, isMediumScreen, borderColor, isNewsFeed }) => ({
  alignItems: 'center',
  backgroundColor: 'white',
  border: `1px solid ${borderColor}`,
  borderRadius: '50%',
  display: 'flex',
  height: isMediumScreen ? '42px' : '3.8rem',
  justifyContent: 'center',
  width: isMediumScreen ? '42px' : '3.8rem',
  [theme.breakpoints.up('xl')]: {
    height: isNewsFeed ? '3.75rem' : '4.5rem',
    width: isNewsFeed ? '3.75rem' : '4.5rem'
  }
}));

const AttachedIcon = styled(Box)<{
  isMediumScreen: boolean;
  backgroundColor: string;
  borderColor: string;
}>(({ isMediumScreen, backgroundColor, borderColor }) => ({
  alignItems: 'center',
  backgroundColor,
  border: `1px solid ${borderColor}`,
  borderRadius: '50%',
  display: 'flex',
  height: isMediumScreen ? '18px' : '24px',
  justifyContent: 'center',
  position: 'absolute',
  right: isMediumScreen ? '-6px' : '-2px',
  top: isMediumScreen ? '4px' : '6px',
  width: isMediumScreen ? '18px' : '24px',
  zIndex: 3
}));

const StyledAvatar = styled(Avatar)({
  height: '100%',
  width: '100%',
  zIndex: 1
});

export default IdentifierIcon;
