import {
  ADD_TAG,
  ADD_PRODUCT,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS,
  GET_TAGS_SUCCESS,
  GET_TAGS,
  GET_TAGS_LOADER,
  GET_LOCATION_TAGS_SUCCESS,
  GET_WORKPLACE_TAGS_SUCCESS,
  GET_LOCATION_TAGS,
  GET_WORKPLACE_TAGS,
  GET_UNIVERSITY_TAGS,
  GET_UNIVERSITY_TAGS_SUCCESS,
  GET_RELATED_TAGS,
  GET_RELATED_TAGS_SUCCESS
} from './types';

export const getProducts = (query: string) => ({
  type: GET_PRODUCTS,
  payload: query
});

export const getProductsSuccess = (payload: Array<any>) => ({
  type: GET_PRODUCTS_SUCCESS,
  payload
});

export const getCompanies = (query: string) => ({
  type: GET_COMPANIES,
  payload: query
});

export const getCompaniesSuccess = (payload: Array<any>) => ({
  type: GET_COMPANIES_SUCCESS,
  payload
});

export const getTags = (
  payload: any,
  searchFilter?: string | null,
  tagFilter?: string | null,
  sort?: { field: string; order: string },
  load?: boolean
) => ({
  payload,
  searchFilter,
  tagFilter,
  type: GET_TAGS,
  sort,
  load
});

export const getTagsSuccess = (payload: Array<any>) => ({
  type: GET_TAGS_SUCCESS,
  payload
});

export const getLocationTags = (
  payload: any,
  searchFilter?: string | null,
  tagFilter?: string | null,
  sort?: { field: string; order: string },
  load?: boolean
) => ({
  payload,
  searchFilter,
  tagFilter,
  type: GET_LOCATION_TAGS,
  sort,
  load
});

export const getLocationTagsSuccess = (payload: Array<any>) => ({
  type: GET_LOCATION_TAGS_SUCCESS,
  payload
});

export const getWorkplaceTags = (
  payload: any,
  searchFilter?: string | null,
  tagFilter?: string | null,
  sort?: { field: string; order: string },
  load?: boolean
) => ({
  payload,
  searchFilter,
  tagFilter,
  type: GET_WORKPLACE_TAGS,
  sort,
  load
});

export const getWorkplaceTagsSuccess = (payload: Array<any>) => ({
  type: GET_WORKPLACE_TAGS_SUCCESS,
  payload
});

export const getUniversityTags = (
  payload: any,
  searchFilter?: string | null,
  tagFilter?: string | null,
  sort?: { field: string; order: string },
  load?: boolean
) => ({
  payload,
  searchFilter,
  tagFilter,
  type: GET_UNIVERSITY_TAGS,
  sort,
  load
});

export const getUniversityTagsSuccess = (payload: Array<any>) => ({
  type: GET_UNIVERSITY_TAGS_SUCCESS,
  payload
});

export const getRelatedTags = (
  payload: any,
  searchFilter?: string | null,
  tagFilter?: string | null,
  sort?: { field: string; order: string },
  load?: boolean
) => ({
  payload,
  searchFilter,
  tagFilter,
  type: GET_RELATED_TAGS,
  sort,
  load
});

export const getRelatedTagsSuccess = (payload: Array<any>) => ({
  type: GET_RELATED_TAGS_SUCCESS,
  payload
});

export const addProduct = (payload: any) => ({
  type: ADD_PRODUCT,
  payload
});

export const addCompany = (payload: any) => ({
  type: ADD_TAG,
  payload
});

export const setGetTagsLoader = (payload: boolean) => ({
  type: GET_TAGS_LOADER,
  payload
});
