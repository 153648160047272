import React, { useState, useContext, useCallback, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import ModalWindow from 'modals/ModalWindow';
import { PsButton } from 'components/common/PsButton';
import { PsInputLabel } from 'components/common/PsInputLabel';
import { PsLogo } from 'components/common/PsLogo';
import { WalletField } from 'components/WalletField';
import { ModalContext, ModalComponentsKeys } from 'contexts/ModalContext';
import { ModalDataContext } from 'contexts/ModalDataContext';
import { DataContext, PsRecord } from 'contexts/DataContext';
import { AuthContext } from 'contexts/AuthContext';
import styles from 'modals/ModalWindow.module.scss';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';

const useClasses = makeStyles()(() => {
  return {
    headerTable: {
      display: 'table',
      '& .tr': {
        display: 'table-row'
      },
      '& .td': {
        display: 'table-cell',
        fontSize: 13
      },
      '& .td.middle': {
        width: '99%',
        textAlign: 'center'
      }
    },
    error: {
      color: 'red',
      padding: '0 15px'
    },
    wallet: {
      marginBottom: 10
    }
  };
});

const useButtonClasses = makeStyles()(() => {
  return {
    root: {
      margin: '8px 0',
      fontWeight: 400
    }
  };
});

export const PayOutRequest: React.FC = () => {
  const { userBalance, refreshUserBalance } = useContext(AuthContext);
  const { openModal, closeModal } = useContext(ModalContext);
  const { values, updateField, resetFields } = useContext(ModalDataContext);
  const { dataProvider, refreshProfileStats } = useContext(DataContext);
  const [address, setAddress] = useState(`${values.productId}` || '');
  const maxAmount = Number.parseFloat(userBalance.toFixed(2));
  const minAmount = Math.min(1, maxAmount);
  const [amount, setAmount] = useState(maxAmount / 2);
  const [amountError, setAmountError] = useState('');
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const { classes } = useClasses();
  const buttonClasses = useButtonClasses();

  useEffect(() => {
    refreshUserBalance();
  }, []);

  const handleClickBack = () => {
    closeModal();
    resetFields();
  };

  const handleNextStep = async () => {
    if (!amount || isNaN(amount) || amount <= 0 || amount > userBalance) {
      setAmountError('Amount incorrect');
      return;
    }

    setLoading(true);

    dataProvider
      .create<PsRecord>('payouts', {
        data: { address, amount }
      })
      .then(({ data }) => {
        if (data.error) {
          setAmountError(data.error);
          throw data.error;
        }
        updateField('title', `${amount}`);
        refreshUserBalance();
        refreshProfileStats();
        openModal(ModalComponentsKeys.payOutRequestSuccess);
      })
      .catch((err) => {
        setAmountError(err.message);
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onAddressChange = (newAddress: string) => {
    setAddress(newAddress);
    setAmountError('');
  };

  const onAmountChange = useCallback(
    (event: any, value: number | number[]) => {
      setAmount(value as number);
      setAmountError('');
    },
    [setAmount, setAmountError]
  );

  const onAmountChangeCommitted = useCallback(
    (event: any, value: number | number[]) => {
      updateField('payOptions', [value]);
    },
    [updateField]
  );

  return (
    <ModalWindow
      title="Pay Out Request"
      titleAfter={
        <span className={styles.modalTitleIcon}>
          Available: {userBalance.toFixed(2)}{' '}
          <PsLogo size={23} color={theme.palette.primary.main} small />
        </span>
      }
    >
      <div className={styles.stepWrapper}>
        <WalletField
          className={classes.wallet}
          address={address}
          onAddress={onAddressChange}
        ></WalletField>

        <PsInputLabel label="Amount" />
        <Typography className={classes.headerTable} component="div">
          <div className="tr">
            <div className="td">{minAmount}</div>
            <div className="td middle">
              <b>{amount}</b>
            </div>
            <div className="td">{maxAmount}</div>
          </div>
        </Typography>
        <Slider
          defaultValue={amount}
          min={minAmount}
          max={maxAmount}
          step={0.1}
          onChange={onAmountChange}
          onChangeCommitted={onAmountChangeCommitted}
        />
        {amountError ? (
          <Typography className={classes.error} variant="caption">
            {amountError}
          </Typography>
        ) : null}
      </div>
      <div className={styles.actions}>
        <PsButton
          disabled={loading}
          onClick={handleNextStep}
          fullWidth
          classes={buttonClasses}
          loading={loading}
        >
          Send
        </PsButton>
        <PsButton
          onClick={handleClickBack}
          fullWidth
          cancel
          classes={buttonClasses}
          disabled={loading}
        >
          Cancel
        </PsButton>
      </div>
    </ModalWindow>
  );
};

export default PayOutRequest;
