import React from 'react';
export const ProductIcon = ({
  size = 24,
  color = '#07A854',
  className
}: {
  size?: number;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 9.79965L8.66339 14.7597L10.9141 11.9358L1.79174 7.11914L0 9.79965Z"
        fill={color}
      />
      <path
        d="M13.0615 11.9256L15.3206 14.76L23.9998 9.79085L22.1837 6.92383L13.0615 11.9256Z"
        fill={color}
      />
      <path
        d="M3.10645 6.22396L11.9858 10.9123L20.8778 6.03678L12.0051 1.8457L3.10645 6.22396Z"
        fill={color}
      />
      <path
        d="M2.26172 17.3876L11.2892 22.1542V13.7196L9.38593 16.1077C9.16993 16.3787 8.78865 16.4517 8.48783 16.2795L2.26172 12.7148V17.3876Z"
        fill={color}
      />
      <path
        d="M12.6943 13.7197V22.1324L21.7218 17.1757V12.7148L15.4957 16.2795C15.195 16.4516 14.8136 16.3787 14.5977 16.1077L12.6943 13.7197Z"
        fill={color}
      />
    </svg>
  );
};

export default ProductIcon;
