import React, { useState, useCallback, useContext, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import ModalWindow from 'modals/ModalWindow';
import { PsButton } from 'components/common/PsButton';
import Step from 'components/Step';
import Stepper from 'components/Stepper';
import { ModalContext, ModalComponentsKeys } from 'contexts/ModalContext';
import {
  ModalDataContext,
  CompanySubscriptionFinalizeSteps
} from 'contexts/ModalDataContext';
import { DataContext } from 'contexts/DataContext';
import { SUBSCRIPTION_STEPS_CONFIG } from './config';
import styles from 'modals/ModalWindow.module.scss';
import { makeStyles } from 'tss-react/mui';

const steps: string[] = ['Select Company', 'Choose Type', 'Invite Colleagues'];

const useButtonClasses = makeStyles()(() => {
  return {
    root: {
      margin: '8px 0',
      fontWeight: 400
    }
  };
});

export const CompanySubscriptionFinalize: React.FC = () => {
  const { openModal, closeModal } = useContext(ModalContext);
  const { values, setErrors, setType } = useContext(ModalDataContext);
  const { dataProvider } = useContext(DataContext);
  const [title, setTitle] = useState<React.ReactNode>('');
  const [prevSteps, setPrevSteps] = useState<string[]>([]);
  const [activeStepNumber, setActiveStepNumber] = useState<number>(0);
  const [currentStep, setCurrentStep] = useState<string | undefined>(
    CompanySubscriptionFinalizeSteps.ConfirmInformationStep
  );

  const buttonClasses = useButtonClasses();

  const {
    Component,
    nextStep,
    validate,
    isNextStepBlocked,
    isFinalStep,
    nextButtonTitle,
    modalTitle
  } = SUBSCRIPTION_STEPS_CONFIG[currentStep || ''];

  const StepComponent = Component as React.FC;

  useEffect(() => {
    const modalTitle = (
      <>
        Claim the company page for <b>{values.parentProblemLabel}</b>
      </>
    );
    setTitle(modalTitle);
  }, [values.parentProblemLabel]);

  const handleClickBack = useCallback(() => {
    if (activeStepNumber === 0) {
      closeModal();
      return;
    }
    setActiveStepNumber((prev) => Math.max(0, prev - 1));
    setCurrentStep(prevSteps[prevSteps.length - 1]);
    setPrevSteps(prevSteps.slice(0, prevSteps.length - 1));
  }, [prevSteps]);

  const handleNextStep = async () => {
    if (typeof validate === 'function') {
      const { success, errors } = validate(values);

      if (!success) {
        setErrors({ ...errors });

        return;
      }
    }

    if (isFinalStep) {
      const subscribeData = {
        title: values.title,
        url: values.description,
        termsAgree: values.termsAgree || false,
        invites: values.payOptions as Array<string>
      };
      dataProvider
        .updateTagSubscription(values.parentProblem || '', subscribeData)
        .then((res) => {
          setType(ModalComponentsKeys.companySubscription);
          openModal(ModalComponentsKeys.success);
        })
        .catch((err) => {
          console.error(err);
        });

      return;
    }

    setPrevSteps([...prevSteps, currentStep || '']);
    setErrors({});
    setActiveStepNumber((prev) => prev + 1);
    if (typeof nextStep === 'function') {
      setCurrentStep(nextStep(values));
      return;
    }

    setCurrentStep(nextStep);
  };

  const isNextStepDisabled: boolean =
    typeof isNextStepBlocked === 'function' && isNextStepBlocked(values);

  return (
    <ModalWindow title={title}>
      <Stepper className={styles.stepper} activeStep={2}>
        {steps.map((step) => (
          <Step key={step}>{step}</Step>
        ))}
      </Stepper>
      <StepComponent />
      <div className={styles.actions}>
        <PsButton
          disabled={isNextStepDisabled}
          onClick={handleNextStep}
          fullWidth
          classes={buttonClasses}
        >
          {nextButtonTitle || 'Next'}
        </PsButton>
        <PsButton
          onClick={handleClickBack}
          fullWidth
          cancel
          classes={buttonClasses}
        >
          Back
        </PsButton>
      </div>
    </ModalWindow>
  );
};

export default CompanySubscriptionFinalize;
