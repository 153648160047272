import * as React from 'react';

interface InventionIconProps {
  width?: number | string;
  height?: number | string;
}

export const InventionIconWithContainer: React.FC<InventionIconProps> = ({
  width = '86',
  height = '86',
  ...props
}) => (
  <div style={{ transform: 'scale(1.0)', transformOrigin: 'center' }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      {...props}
    >
      <path
        fill="#F9D991"
        stroke="#C77312"
        d="M84.894 43.418c0 23.196-18.803 42-42 42-23.195 0-42-18.804-42-42s18.805-42 42-42c23.197 0 42 18.804 42 42Z"
      />
      <mask
        id="a"
        width={32}
        height={45}
        x={27}
        y={21}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance'
        }}
      >
        <path fill="#fff" d="M58.353 21.08H27.44v44.673h30.912V21.08Z" />
      </mask>
      <g fill="#C77312" mask="url(#a)">
        <path d="M42.903 21.08a15.455 15.455 0 0 0-8.832 28.139l.248.175v5.482a1.723 1.723 0 0 0 1.723 1.713h13.744a1.723 1.723 0 0 0 1.713-1.724v-5.474l.247-.174a15.454 15.454 0 0 0-8.843-28.137Zm9.888 22.305a11.97 11.97 0 0 1-3.016 3.016l-1.702 1.2v5.55H37.737v-5.539l-1.702-1.2a12.038 12.038 0 1 1 16.756-3.016M38.322 65.76h9.164a1.723 1.723 0 0 0 1.713-1.723V62.33H36.61v1.71a1.724 1.724 0 0 0 1.724 1.713" />
      </g>
    </svg>
  </div>
);
