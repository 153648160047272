import { SHARE_TO_SOCIAL_MEDIA, SHARE_TO_SOCIAL_MEDIA_SUCCESS } from './types';

export const shareToSocialMedia = (payload) => ({
  type: SHARE_TO_SOCIAL_MEDIA,
  payload
});

export const shareToSocialMediaSuccess = (payload) => ({
  type: SHARE_TO_SOCIAL_MEDIA_SUCCESS,
  payload
});
