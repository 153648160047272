import { Contest } from 'components/CardContest';
import { Problem } from 'components/CardProblem';
import { Constants } from 'utilities/constants';
import dataProvider from '../../dataPrvider';
import { GetContestPayload } from './interface';

export const Api = {
  createContest: async ({ data }) => {
    try {
      const response = await dataProvider.create<Contest>('contests', {
        data: data
      });
      return response;
    } catch (e) {
      console.log('ERROR', e);
      return [];
    }
  },
  getContests: async (payload: GetContestPayload) => {
    try {
      const { tagId, pagination = { page: 0, perPage: 5 } } = payload;
      return dataProvider.getList<Array<Contest>>('contests', {
        pagination,
        filter: {
          $custom: { category: 'tag' },
          tag: tagId
        }
      });
    } catch (e) {
      console.error('ERROR', e);
      return [];
    }
  },
  getProblemsFromId: async (payload) => {
    try {
      const response = dataProvider.getMany<Problem>('problems', {
        ids: payload.data
      });
      return response;
    } catch (e) {
      console.log('ERROR', e);
      return [];
    }
  },
  updateContest: async ({ id, data }) => {
    try {
      const response = await dataProvider.update<Contest>(Constants.CONTESTS, {
        id: id,
        data: data
      });
      return response;
    } catch (e) {
      console.log('ERROR', e);
      return [];
    }
  },
  getSolutionsWithOwnerInfo: async ({ data }) => {
    try {
      const solutions = await dataProvider.getSolutions(data.payload);
      return solutions;
    } catch (e) {
      return [];
    }
  },
  generateDescriptionFromAI: async (payload) => {
    try {
      const response = await dataProvider.generateDescriptionFromAI(
        payload.payload
      );
      return response;
    } catch (err) {
      console.error('error', err);
    }
  },
  generateImageFromAI: async ({ payload }) => {
    try {
      const response = await dataProvider.generateImageFromAI(payload.payload);
      return response;
    } catch (err) {
      console.error('error', err);
    }
  },
  generateCoverImageFromAI: async ({ payload }) => {
    try {
      const response = await dataProvider.generateImageFromAI(payload.payload);
      return response;
    } catch (err) {
      console.error('error', err);
    }
  }
};
