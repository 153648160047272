import { CreditsStep } from './steps/CreditsStep';
import { AddressStep } from './steps/AddressStep';
import { ChooseTypeStep } from './steps/ChooseTypeStep';
import { ChoosePaymentStep } from './steps/ChoosePaymentStep';
import {
  SignDocumentsStep,
  productSignStatus
} from './steps/SignDocumentsStep';
import { PayDocumentsStep, productPayStatus } from './steps/PayDocumentsStep';
import {
  ValidateFormType,
  FormStepConfig,
  BuildPatentApplicationSteps
} from 'contexts/ModalDataContext';
import { PayProduct } from 'contexts/DataContext';
import { finalizeType } from '../../helpers';

export { productSignStatus, productPayStatus };

export const stepName = {
  CHECK_CREDIT: 'CheckCredit',
  ADDRESS_INFO: 'addressInfo',
  SELECT_TYPE: 'selectType',
  SELECT_PAYMENT: 'selectPayment',
  SIGN: 'sign',
  PAY: 'pay'
};

export const BUILD_PATENT_APPLICATION_STEPS_CONFIG: {
  [name: string]: FormStepConfig;
} = {
  [BuildPatentApplicationSteps.CreditsStep]: {
    Component: CreditsStep,
    nextStep: BuildPatentApplicationSteps.AddressStep,
    name: stepName.CHECK_CREDIT,
    nextButtonTitle: 'Apply 1 MindMiner Credit',
    isNextStepBlocked: (data) => {
      return !data.tempNumber;
    },
    validate: (data): ValidateFormType => {
      if (!data.tempNumber) {
        return {
          success: false,
          errors: {
            tempNumber: 'required'
          }
        };
      }
      return { success: true };
    }
  },
  [BuildPatentApplicationSteps.AddressStep]: {
    Component: AddressStep,
    nextStep: BuildPatentApplicationSteps.ChooseTypeStep,
    name: stepName.ADDRESS_INFO,
    nextButtonTitle: 'Next',
    isNextStepBlocked: (data) => {
      return (
        !data.description ||
        !data.descriptionProblem ||
        !data.descriptionSolution ||
        !data.descriptionApplication ||
        !data.termsAgree
      );
    },
    validate: (data): ValidateFormType => {
      if (
        !data.description ||
        !data.descriptionProblem ||
        !data.descriptionSolution ||
        !data.descriptionApplication
      ) {
        return {
          success: false,
          errors: {
            description: 'required'
          }
        };
      }
      if (!data.termsAgree) {
        return {
          success: false,
          errors: {
            termsAgree: 'required'
          }
        };
      }
      return { success: true };
    }
  },
  [BuildPatentApplicationSteps.ChooseTypeStep]: {
    Component: ChooseTypeStep,
    nextStep: BuildPatentApplicationSteps.ChoosePaymentStep,
    name: stepName.SELECT_TYPE,
    nextButtonTitle: 'Apply 1 MindMiner Credit',
    isNextStepBlocked: (data) => {
      return !data.productId;
    },
    validate: (data): ValidateFormType => {
      if (!data.productId) {
        return {
          success: false,
          errors: {
            productId: 'required'
          }
        };
      }
      return { success: true };
    }
  },
  [BuildPatentApplicationSteps.ChoosePaymentStep]: {
    nextStep: BuildPatentApplicationSteps.SignDocumentsStep,
    Component: ChoosePaymentStep,
    name: stepName.SELECT_PAYMENT,
    isNextStepBlocked: (data) => {
      return !data.brainMethod;
    },
    validate: (data): ValidateFormType => {
      return { success: true };
    }
  },
  [BuildPatentApplicationSteps.SignDocumentsStep]: {
    nextStep: BuildPatentApplicationSteps.PayDocumentsStep,
    Component: SignDocumentsStep,
    name: stepName.SIGN,
    isNextStepBlocked: (data) => {
      if (data.title === finalizeType.OPEN) {
        return false;
      } else {
        let isSigned = true;
        const products: Array<PayProduct> = data.payProducts || [];
        products.forEach((product) => {
          if (product.status !== productSignStatus.SIGNED) {
            isSigned = false;
          }
        });
        return !isSigned;
      }
    },
    validate: (data): ValidateFormType => {
      return { success: true };
    }
  },
  [BuildPatentApplicationSteps.PayDocumentsStep]: {
    Component: PayDocumentsStep,
    name: stepName.PAY,
    isNextStepBlocked: (data) => {
      let isPayed = true;
      const products: Array<PayProduct> = data.payProducts || [];
      products.forEach((product) => {
        if (product.payStatus !== productPayStatus.PAYED) {
          isPayed = false;
        }
      });
      return !isPayed;
    },
    validate: (data): ValidateFormType => {
      return { success: true };
    }
  }
};
