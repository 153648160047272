import { Box, Divider, IconButton, Typography, styled } from '@mui/material';
import PsButton from 'components/common/PsButton';
import { colorPalette } from 'theme';

export const HeaderMainBox = styled(Box)({
  alignItems: 'flex-start',
  display: 'flex',
  justifyContent: 'space-between'
});

export const MainBox = styled(Box)({
  border: '1px solid',
  borderRadius: '.375rem',
  color: `${colorPalette.lightShadowGrey}`,
  marginTop: '1rem'
});

export const BoundingBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0px .5rem'
});

export const PdfBox = styled(Box)({
  display: 'flex',
  alignItems: 'center'
});

export const PdfIconBox = styled(Box)({
  marginRight: '.625rem'
});

export const PdfTitleBox = styled(Box)({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column'
});

export const ActionMainBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between'
});

export const PdfTitleTypography = styled(Typography)({
  color: colorPalette.lightBlack,
  fontSize: '1rem',
  fontWeight: '500',
  lineHeight: '0.9',
  marginTop: '.3125rem'
});

export const PdfSubTitleTypography = styled(Typography)<{
  isPatentApproved: boolean;
}>(({ isPatentApproved }) => ({
  color: isPatentApproved ? colorPalette.greenHaze : colorPalette.chilliPepper,
  fontSize: '.75rem',
  fontStyle: 'italic',
  fontWeight: '400',
  lineHeight: '1.5'
}));

export const PdfIconBackground = styled(Box)({
  alignItems: 'center',
  backgroundColor: colorPalette.softGrey,
  borderRadius: '.375rem',
  display: 'inline-flex',
  height: 40,
  justifyContent: 'center',
  margin: '.3125rem 0px',
  width: 40
});

export const TitleTypography = styled(Typography)({
  fontSize: '1.75rem',
  fontWeight: '700',
  lineHeight: '1.7'
});

export const SubTitleTypography = styled(Typography)({
  color: colorPalette.purple,
  fontSize: '1.25rem',
  fontWeight: '700',
  lineHeight: '1.7'
});

export const ValueTitle = styled(Typography)({
  color: colorPalette.lightBlack,
  fontSize: '1.125rem',
  fontWeight: '600'
});

export const ValueTypography = styled(Typography)({
  color: colorPalette.lightBlack,
  fontSize: '1.125rem',
  fontWeight: '400',
  lineHeight: '1',
  marginBottom: '1rem'
});

export const HelpTypography = styled(Typography)({
  color: colorPalette.purple,
  cursor: 'pointer',
  fontSize: '.875rem',
  fontWeight: '500',
  textDecoration: 'underline'
});

export const StyledSubmitButton = styled(PsButton)({
  borderRadius: '.375rem !important',
  color: colorPalette.white,
  fontSize: '1.125rem !important',
  fontWeight: 'bold',
  height: '2.8125rem',
  outline: 'none',
  padding: '.5rem 0rem!important',
  width: '11.875rem'
});

export const StyledDivider = styled(Divider)({
  margin: '1rem 0'
});

export const StyledIconButton = styled(IconButton)({
  color: colorPalette.purple
});
