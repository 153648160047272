import { BigNumber, ethers } from 'ethers';
import Config from 'config/config';
import IdeaCoinABI from 'contract/IdeaCoins.json';
import NftAbi from 'contract/NFT.json';
import {
  ASSET_TYPES,
  Constants,
  CURRENCIES,
  ERRORS
} from 'utilities/constants';
import { CustomError } from './CustomError';

const provider = new ethers.providers.JsonRpcProvider(Config.INFURA_URL);
const wallet = new ethers.Wallet(Config.PRIVATE_KEY, provider);

const ideaCoinContract = new ethers.Contract(
  Config.IDEACOIN_CONTRACT_ADDRESS,
  IdeaCoinABI,
  wallet
);

interface Rates {
  [key: string]: string;
}

export type Chain = {
  chainId: string;
  name: string;
  blockExplorerUrl: string;
  rpcUrl: string;
};

export const sepolia: Chain = {
  chainId: Config.RPC_TEST_CHAIN_ID,
  name: Config.SEPOLIA_TESTNET_NAME,
  blockExplorerUrl: Config.SEPOLIA_BASE_URL,
  rpcUrl: Config.INFURA_URL
};

export const mainnet: Chain = {
  chainId: Config.RPC_CHAIN_ID,
  name: Config.POLYGON_MAINNET_NAME,
  blockExplorerUrl: Config.POLYGON_EXPLORER_URL,
  rpcUrl: Config.MAINNET_INFURA_URL
};

export const CHAINS_CONFIG = {
  [sepolia.chainId]: sepolia,
  [mainnet.chainId]: mainnet
};

const nftContract = new ethers.Contract(
  Config.NFT_CONTRACT_ADDRESS,
  NftAbi,
  wallet
);

export const getOwnedNfts = async (address: string) => {
  const nfts = await nftContract.balanceOf(address);
  return nfts.toNumber();
};

export const calculateGasFee = async (gasEstimate) => {
  try {
    const gasPrice = await provider.getGasPrice();
    const gasFee = gasEstimate.mul(gasPrice);
    return ethers.utils.formatUnits(gasFee, Constants.ETHER);
  } catch (error) {
    console.log(ERRORS.GAS_FEE, error);
  }
};

export const estimateGasForNft = async (tokenURI: string) => {
  try {
    const gasEstimate = await nftContract.estimateGas.mintNFT(tokenURI);
    const gasFeeEther = await calculateGasFee(gasEstimate);
    return gasFeeEther;
  } catch (error) {
    console.error(ERRORS.ESTIMATE_GAS, error);
    throw error;
  }
};

export const estimateGasForApproval = async (
  walletAddress: string,
  amount: BigNumber
) => {
  try {
    const gasEstimate = await ideaCoinContract.estimateGas.approve(
      walletAddress,
      amount
    );
    const gasFeeEther = await calculateGasFee(gasEstimate);
    return gasFeeEther;
  } catch (error) {
    console.error(ERRORS.ESTIMATE_GAS, error);
    throw error;
  }
};

export const estimateGasForTransferFrom = async (
  walletAddress: string,
  destinationAddress: string,
  amount: BigNumber
) => {
  try {
    const gasEstimate = await ideaCoinContract.estimateGas.transferFrom(
      walletAddress,
      destinationAddress,
      amount
    );
    const gasFeeEther = await calculateGasFee(gasEstimate);
    return gasFeeEther;
  } catch (error) {
    console.log(ERRORS.ESTIMATE_GAS, error.message, error);
    throw error;
  }
};

export const estimateGasForMaticTransfer = async (
  to: string,
  amount: number
): Promise<string> => {
  try {
    const gasEstimate = await provider.estimateGas({
      to,
      value: ethers.utils.parseEther(amount.toString())
    });
    const gasFeeEther = await calculateGasFee(gasEstimate);
    return gasFeeEther;
  } catch (error) {
    console.error(ERRORS.ESTIMATE_GAS, error);
    throw error;
  }
};

export const deployNft = async (privateKey, tokenURI) => {
  const signer = new ethers.Wallet(privateKey, provider);
  const MyNFT = new ethers.Contract(
    Config.NFT_CONTRACT_ADDRESS,
    NftAbi,
    signer
  );

  const txn = await MyNFT.mintNFT(tokenURI);
  const res = await txn.wait();
  const event = res.events[0];

  const value = event.args[2];
  const tokenId = value.toNumber();

  return [res.transactionHash, tokenId];
};

export const initDeployNFT = async (
  tokenURI,
  openTxApprovalModal,
  privateKey
) => {
  const signer = new ethers.Wallet(privateKey, provider);
  const userBalance = await getBalanceByType(ASSET_TYPES.MATIC, signer.address);
  const userBalanceNumber = parseFloat(String(userBalance));

  if (userBalanceNumber === 0) {
    throw new CustomError(ERRORS.INSUFFICIENT_BALANCE, 403);
  }

  const gasFeeEstimate = await estimateGasForNft(tokenURI);
  if (userBalanceNumber < parseFloat(gasFeeEstimate)) {
    throw new CustomError(ERRORS.INSUFFICIENT_BALANCE, 403);
  }

  openTxApprovalModal(gasFeeEstimate, ASSET_TYPES.NFT);
};

export const getTransactions = async (address: string, type: string) => {
  let url = '';
  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      'X-API-Key': Config.MORALIS_API_KEY
    }
  };
  if (type === ASSET_TYPES.MATIC) {
    url = `${Config.MORALIS_API_URL}/${address}?${Constants.CHAIN}=${Config.NEXT_PUBLIC_WALLET_NETWORK}`;
  } else if (type === ASSET_TYPES.IDEACOINS) {
    url = `${Config.MORALIS_API_URL}/${address}/${Constants.ERC20}/${Constants.TRANSFERS}?${Constants.CHAIN}=${Config.NEXT_PUBLIC_WALLET_NETWORK}&type=both`;
  }
  const response = await fetch(url, options);
  if (!response.ok) {
    throw new Error(`${Constants.ERROR}: ${response.status}`);
  }
  return response.json();
};

export const getBalanceByType = async (type: string, address: string) => {
  try {
    if (type === ASSET_TYPES.MATIC) {
      const ownerMaticBalance = await provider.getBalance(address);
      return ethers.utils.formatEther(ownerMaticBalance);
    } else if (type === ASSET_TYPES.IDEACOINS) {
      const ideaCoinBalance = await getIdeaCoinBalance(address);
      return truncateToFourDecimalPlaces(ideaCoinBalance);
    } else {
      throw new Error(ERRORS.INVALID_BALANCE_TYPE);
    }
  } catch (error) {
    console.error(`${ERRORS.FETCH_BALANCE} ${type}:`, error);
  }
};

const fetchMaticToUsdRate = async (): Promise<number | undefined> => {
  const url = Config.COINBASE_API_URL;
  try {
    const response = await fetch(`${url}?currency=${Constants.USD}`);
    const data = await response.json();
    const rate = data.data.rates[ASSET_TYPES.MATIC];

    if (rate) {
      return parseFloat(rate);
    } else {
      return undefined;
    }
  } catch (error) {
    console.error(ERRORS.FETCHING_LIVE_PRICES, error.message);
    return undefined;
  }
};

export const convertMaticToUsd = async (
  maticAmount: number
): Promise<number | undefined> => {
  try {
    const maticToUsdRate = await fetchMaticToUsdRate();
    if (maticToUsdRate !== undefined) {
      return maticAmount / maticToUsdRate;
    } else {
      console.error(ERRORS.FETCH_MATIC_TO_USD_RATE);
      return undefined;
    }
  } catch (error) {
    console.error(error.message);
    return undefined;
  }
};

export const getOwnerMaticBalance = async () => {
  const ownerMaticBalance = await provider.getBalance(wallet.address);
  return ethers.utils.formatEther(ownerMaticBalance);
};

export const showUserIdeaBalance = async (address: string): Promise<number> => {
  try {
    const ideaCoinBalance = await getIdeaCoinBalance(address);
    const formattedBalance = truncateToFourDecimalPlaces(ideaCoinBalance);
    return formattedBalance;
  } catch (error) {
    console.error(ERRORS.GET_IDEA_COINS, error);
  }
};

const truncateToFourDecimalPlaces = (value: number): number => {
  const [integerPart, decimalPart] = value.toString().split('.');
  const truncatedDecimal = decimalPart ? decimalPart.slice(0, 4) : '0000';
  const truncatedValue = `${integerPart}.${truncatedDecimal}`;
  return parseFloat(truncatedValue);
};

export const fetchNftMetadata = async (
  metadataUrl: string
): Promise<{ image: string | null; name: string | null }> => {
  try {
    const response = await fetch(metadataUrl);
    if (!response.ok) {
      throw new Error(ERRORS.FETCH_METADATA);
    }
    const metadata = await response.json();
    const imageUrl = metadata.image ? metadata.image[0] : null;
    const name = metadata.name ? metadata.name : null;
    return { image: imageUrl, name };
  } catch (error) {
    return { image: null, name: null };
  }
};

const fetchLivePrices = async (): Promise<{ [key: string]: number }> => {
  const url = Config.COINBASE_API_URL;

  try {
    const response = await fetch(`${url}?currency=${Config.CURRENCY}`);
    const data = await response.json();
    const rates: Rates = data.data.rates;

    const results: { [key: string]: number } = {};
    CURRENCIES.forEach((currency) => {
      if (rates[currency]) {
        results[currency] = parseFloat(rates[currency]);
      }
    });

    return results;
  } catch (error) {
    console.error(ERRORS.FETCHING_LIVE_PRICES, error.message);
  }
};

const formatBalance = (balance: ethers.BigNumber): number => {
  return parseFloat(ethers.utils.formatUnits(balance, 18));
};

export const convertIdeaCoinsToMatic = async (
  ideaBalance: number,
  rewardPoolThreshold: number
): Promise<number> => {
  try {
    const totalRewardsDistributed = formatBalance(
      await ideaCoinContract.totalRewardsDistributed()
    );
    return parseFloat(
      ((ideaBalance * rewardPoolThreshold) / totalRewardsDistributed).toFixed(
        12
      )
    );
  } catch (error) {
    console.error(ERRORS.IDEA_COINS_TO_MATIC, error);
  }
};

const convertMaticToCurrency = async (maticAmount: number) => {
  try {
    const prices = await fetchLivePrices();
    const conversions = [];

    for (const key in prices) {
      conversions.push({
        currency: key,
        amount: maticAmount * prices[key]
      });
    }

    return conversions;
  } catch (error) {
    console.error(ERRORS.FAILED_TO_CONVERT_MATIC, error);
  }
};

export const getIdeaCoinBalance = async (address: string): Promise<number> => {
  try {
    const balance = await ideaCoinContract.balanceOf(address);
    return formatBalance(balance);
  } catch (error) {
    console.error(ERRORS.ERROR_FETCHING_IDEACOIN_BALANCE, address, error);
  }
};

const fetchCurrencyData = async (
  address: string,
  rewardPoolThreshold: number
) => {
  const defaultConversions = CURRENCIES.map((currency) => {
    return {
      currency,
      amount: 0
    };
  });

  try {
    let maticAmount: number;
    if (address === wallet.address) {
      const ownerMaticBalance = await provider.getBalance(wallet.address);
      maticAmount = parseFloat(ethers.utils.formatEther(ownerMaticBalance));
    } else {
      const ideaBalance = await getIdeaCoinBalance(address);
      maticAmount = await convertIdeaCoinsToMatic(
        ideaBalance,
        rewardPoolThreshold
      );
    }

    const conversions =
      maticAmount > 0
        ? await convertMaticToCurrency(maticAmount)
        : defaultConversions;

    return { maticAmount, conversions };
  } catch (error) {
    console.error(ERRORS.FETCHING_CURRENCY_DATA, error);
    return {
      maticAmount: 0,
      conversions: defaultConversions
    };
  }
};

export const userCurrencyData = async (walletAddress, rewardPoolThreshold) => {
  return fetchCurrencyData(walletAddress, rewardPoolThreshold);
};

export const ownerCurrencyData = async (rewardPoolThreshold) => {
  return fetchCurrencyData(wallet.address, rewardPoolThreshold);
};
