import React from 'react';
import { Container, Grid } from '@mui/material';
import { LandingPageRoyaltiesImage } from 'components/icons/LandingPageRoyaltiesImage';
import { useIsMediumScreen } from 'theme';
import {
  StyledRoyaltiesMainBox,
  StyledWinRoyaltiesContentTypography,
  StyledWinRoyaltiesHeaderTypography,
  StyledWinRoyaltiesSubContentTypography,
  StyledWinRoyaltiesSubHeaderTypography
} from './StyledComponents';

export const RoyaltiesSection = () => {
  const isMediumScreen = useIsMediumScreen();

  return (
    <StyledRoyaltiesMainBox>
      <Container>
        <Grid container spacing={isMediumScreen ? 6 : 12} alignItems="center">
          {!isMediumScreen && (
            <Grid
              item
              xs={12}
              md={6}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <LandingPageRoyaltiesImage
                sx={{ maxWidth: '100%', height: 'auto' }}
              />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <StyledWinRoyaltiesHeaderTypography variant="h4" gutterBottom>
              Win Royalties
            </StyledWinRoyaltiesHeaderTypography>
            <StyledWinRoyaltiesSubHeaderTypography variant="body1" gutterBottom>
              Top leaderboards and win problem contests by honing your ideas
              with the power of AI. Create and own new product concepts.
            </StyledWinRoyaltiesSubHeaderTypography>
            <StyledWinRoyaltiesContentTypography gutterBottom>
              Amplify Insights
            </StyledWinRoyaltiesContentTypography>
            <StyledWinRoyaltiesSubContentTypography
              variant="body2"
              gutterBottom
            >
              AI-powered innovation to help you expand idea sparks into real
              innovation.
            </StyledWinRoyaltiesSubContentTypography>

            <StyledWinRoyaltiesContentTypography gutterBottom>
              Sell Improvements
            </StyledWinRoyaltiesContentTypography>
            <StyledWinRoyaltiesSubContentTypography variant="body2">
              Automate patent-pending rights to sell your product concepts as
              digital assets.
            </StyledWinRoyaltiesSubContentTypography>
          </Grid>
        </Grid>
      </Container>
    </StyledRoyaltiesMainBox>
  );
};
