import React, { useContext, useState, useEffect, useCallback } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import {
  ModalDataContext,
  StepComponentProps,
  StepComponentType
} from 'contexts/ModalDataContext';
import { AuthContext } from 'contexts/AuthContext';
import { PsFormControl, ShortEvent } from 'components/common/PsFormControl';
import { PsInput } from 'components/common/PsInput';
import styles from 'modals/ModalWindow.module.scss';
import { PsTheme } from '../../../theme';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import { makeStyles } from 'tss-react/mui';

const useCheckboxStyles = makeStyles()(() => {
  const psTheme = useTheme() as PsTheme;
  return {
    root: {
      '& a': {
        color: psTheme.palette.primary.main
      }
    }
  };
});

const PsCheckboxView = (props: CheckboxProps) => {
  const { classes } = useCheckboxStyles();
  return <Checkbox color="default" {...props} classes={classes} />;
};
const PsCheckbox = styled(PsCheckboxView)({});

type ClassKey = 'text' | 'textSmall';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    text: {
      fontSize: 34,
      lineHeight: '44px',
      fontWeight: 'bold',
      textAlign: 'center'
    },
    textSmall: {
      lineHeight: '24px',
      textAlign: 'center',
      paddingTop: '25px',
      '& > a': {
        display: 'inline-block',
        marginTop: 10
      }
    }
  };
});

export type AddressStepProps = {
  onNotify?: (args: any) => void;
};

const AddressStepView = ({ onNotify }: AddressStepProps) => {
  const { classes } = useStyles();
  const { setTermsModalOpened } = useContext(AuthContext);
  const { values, updateField } = useContext(ModalDataContext);
  const [inventorAddress, setInventorAddress] = useState(
    values.description || ''
  );
  const [inventorAddressCity, setInventorAddressCity] = useState(
    values.descriptionProblem || ''
  );
  const [inventorAddressState, setInventorAddressState] = useState(
    values.descriptionSolution || ''
  );
  const [inventorAddressZip, setInventorAddressZip] = useState(
    values.descriptionApplication || ''
  );
  const [termsAgree, setTermsAgree] = useState(values.termsAgree || false);

  const onInventorAddressChange = (event: ShortEvent) => {
    setInventorAddress(event.target.value);
    updateField('description', event.target.value);
  };

  const onInventorAddressCityChange = (event: ShortEvent) => {
    setInventorAddressCity(event.target.value);
    updateField('descriptionProblem', event.target.value);
  };

  const onInventorAddressStateChange = (event: ShortEvent) => {
    setInventorAddressState(event.target.value);
    updateField('descriptionSolution', event.target.value);
  };

  const onInventorAddressZipChange = (event: ShortEvent) => {
    setInventorAddressZip(event.target.value);
    updateField('descriptionApplication', event.target.value);
  };

  const onTermsChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setTermsAgree(event.target.checked);
      updateField('termsAgree', event.target.checked);
    },
    [setTermsAgree, updateField]
  );

  const onTermsClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setTermsModalOpened('registration');
    setTermsAgree(true);
    updateField('termsAgree', true);
  };

  return (
    <div className={styles.stepWrapper}>
      <Typography className={styles.text}>Assignment info:</Typography>
      <Typography className={styles.textSmall}>
        Please enter your address below to validly assign ownership of the NFTs,
        inventions, solutions and patents that you create. MindMiner does not
        share this info and will never use it for marketing purposes.
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={7}>
          <PsFormControl
            placeholder="Type inventor street address"
            label="Address"
            labelLight
          >
            <PsInput
              onChange={onInventorAddressChange}
              value={inventorAddress}
              name="inventorAddress"
            />
          </PsFormControl>
        </Grid>

        <Grid item xs={12} sm={5}>
          <PsFormControl
            placeholder="Type inventor city"
            label="City"
            labelLight
          >
            <PsInput
              onChange={onInventorAddressCityChange}
              value={inventorAddressCity}
              name="inventorAddressCity"
            />
          </PsFormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={7}>
          <PsFormControl
            placeholder="Type inventor state"
            label="State"
            labelLight
          >
            <PsInput
              name="inventorAddressState"
              onChange={onInventorAddressStateChange}
              value={inventorAddressState}
            />
          </PsFormControl>
        </Grid>

        <Grid item xs={12} sm={5}>
          <PsFormControl placeholder="Type inventor zip" label="Zip" labelLight>
            <PsInput
              onChange={onInventorAddressZipChange}
              value={inventorAddressZip}
              name="inventorAddressZip"
            />
          </PsFormControl>
        </Grid>
      </Grid>

      <FormControlLabel
        className={styles.termsRegister}
        control={
          <PsCheckbox
            checked={termsAgree}
            onChange={onTermsChange}
            name="checkedG"
          />
        }
        label={
          <>
            I agree to the <a onClick={onTermsClick}>terms and conditions</a> of
            use
          </>
        }
      />
    </div>
  );
};

export const AddressStep = styled(AddressStepView)(
  {}
) as StepComponentType<StepComponentProps>;

export default AddressStep;
