import React from 'react';
import { SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { colorPalette } from 'theme';
import { VARIANT } from 'utilities/constants';
import { StyledButton, StyledTypography } from './styledComponents';

interface LabeledIconButtonProps {
  btnColor?: string;
  buttonSx?: SxProps<Theme>;
  disabled?: boolean;
  icon: React.ReactNode;
  onClick?: () => void;
  text: string;
  typographySx?: SxProps<Theme>;
  variant: VARIANT.CONTAINED | VARIANT.OUTLINED;
  width?: string;
  fontSize?: string;
}

export const LabeledIconButton: React.FC<LabeledIconButtonProps> = ({
  btnColor = colorPalette.purple,
  buttonSx,
  icon,
  onClick,
  text,
  typographySx,
  fontSize,
  variant = VARIANT.CONTAINED,
  width = '190px',
  ...props
}) => {
  return (
    <StyledButton
      btnColor={
        variant !== VARIANT.OUTLINED ? btnColor : colorPalette.transparent
      }
      onClick={onClick}
      startIcon={icon}
      sx={buttonSx}
      variant={variant}
      width={width}
      {...props}
    >
      <StyledTypography sx={typographySx} fontSize={fontSize}>
        {text}
      </StyledTypography>
    </StyledButton>
  );
};

export default LabeledIconButton;
