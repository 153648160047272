import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FunctionComponent } from 'react';

export const HomeIcon: FunctionComponent<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props} viewBox="0 0 25.551 25.551">
    <path
      fill="currentColor"
      d="M25.551 4.254A4.258 4.258 0 0 0 21.3 0H4.253A4.258 4.258 0 0 0 0 4.254V21.3a4.258 4.258 0 0 0 4.253 4.253H21.3a4.258 4.258 0 0 0 4.251-4.253Zm-23.422 0a2.126 2.126 0 0 1 2.124-2.125H21.3a2.126 2.126 0 0 1 2.124 2.125v3.11H2.129ZM23.422 21.3a2.126 2.126 0 0 1-2.122 2.122H4.253A2.126 2.126 0 0 1 2.129 21.3V9.5h21.293Z"
      data-name="Path 566"
    />
    <path
      fill="currentColor"
      d="M6.183 20.314h6.593a1.065 1.065 0 0 0 0-2.129H6.183a1.065 1.065 0 0 0 0 2.129Z"
      data-name="Path 567"
    />
    <path
      fill="currentColor"
      d="M6.183 14.73h13.185a1.065 1.065 0 0 0 0-2.129H6.183a1.065 1.065 0 1 0 0 2.129Z"
      data-name="Path 568"
    />
    <path
      fill="currentColor"
      d="M4.427 3.991a1.065 1.065 0 0 0 1.5 1.512 1.056 1.056 0 0 0 0-1.512 1.072 1.072 0 0 0-1.5 0Z"
      data-name="Path 569"
    />
    <path
      fill="currentColor"
      d="M9.931 3.991a1.065 1.065 0 1 0 0 1.512 1.059 1.059 0 0 0 0-1.512Z"
      data-name="Path 570"
    />
    <path
      fill="currentColor"
      d="M12.436 3.99a1.065 1.065 0 1 0 1.5 1.512 1.057 1.057 0 0 0 0-1.512 1.1 1.1 0 0 0-1.5 0Z"
      data-name="Path 571"
    />
  </SvgIcon>
);
