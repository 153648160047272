import React from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { PsTheme } from '../../theme';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {},
    logoText: {
      [psTheme.breakpoints.down('sm')]: {
        display: 'none'
      }
    }
  };
});

type PsNFTProps = {
  className?: string;
  color?: string;
  size?: number;
};

const PsNFTView = ({
  className,
  color = 'black',
  size = 100,
  ...props
}: PsNFTProps) => {
  const { classes } = useStyles();

  const width = size;
  const height = size;
  const viewBox = `300 125 250 550`;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      {...props}
    >
      <g fill={color}>
        <path
          d="M193,401.4c0-31,0-62.1,0-93.1c0-27.2,11.7-47.8,35.2-61.4c54.1-31.4,108.3-62.7,162.5-93.8c23.3-13.4,47-13.6,70.3-0.2
		c54.6,31.3,109.1,62.7,163.5,94.4c23,13.4,34.6,33.9,34.6,60.6c0.1,62.8,0.1,125.6,0,188.4c-0.1,25.8-11.1,46.1-33.3,59.2
		c-55.5,32.5-111.2,64.7-167.2,96.5c-21.4,12.2-44.1,11.9-65.6-0.3c-55.8-31.7-111.3-63.8-166.7-96.3c-22-12.9-33.1-33.1-33.3-58.7
		C192.8,464.9,193,433.2,193,401.4z M643.6,401.7c0-30.9-0.2-61.7,0.1-92.6c0.1-22.5-9.6-39-29.1-50c-12.2-6.9-24.3-14-36.4-21
		c-41.3-23.8-82.6-47.6-123.8-71.5c-18.2-10.6-36.7-11.3-55-0.8c-54.6,31.3-109,62.8-163.5,94.4c-18.1,10.5-27.3,26.2-27.3,47.2
		c0,62.6,0,125.2,0,187.9c0,21.7,9.6,37.6,28.2,48.4c53.4,30.8,106.9,61.6,160.2,92.6c19.6,11.4,38.8,11.4,58.4,0.1
		c53.2-30.9,106.4-61.6,159.7-92.3c19.2-11.1,28.9-27.4,28.7-49.7C643.4,463.5,643.6,432.6,643.6,401.7z"
        />
        <path
          d="M260.5,356.6c0,1.9,0,3.8,0,5.8c0,36.9,0,73.8,0,110.7c0,2.3-0.1,4.7-0.6,6.8c-1.1,4-4.1,5.6-8.1,5.2
		c-3.9-0.4-6-2.8-6.6-6.5c-0.2-1.2-0.1-2.5-0.1-3.7c0-53,0-106.1,0.1-159.1c0-2.8,0.6-6.2,2.3-8.3c3.7-4.5,10.3-2.5,12.9,3.8
		c5.6,13.6,11,27.3,16.5,40.9c18.8,46.8,37.7,93.6,56.5,140.5c0.6,1.6,1.4,3.1,3.3,4.5c0-2,0-4,0-6c0-77.4,0-154.7,0-232.1
		c0-1.6-0.1-3.2,0.1-4.8c0.5-4.3,3-6.9,7.2-7.2c4.3-0.3,7,2.2,8,6.4c0.4,1.5,0.3,3.2,0.3,4.8c0,93,0,185.9-0.1,278.9
		c0,3-0.8,6.6-2.6,8.8c-3.5,4.4-10.2,2.5-12.7-3.4c-4.1-9.8-8-19.7-11.9-29.6c-20.4-50.8-40.9-101.5-61.3-152.3
		c-0.6-1.4-1.2-2.8-1.9-4.2C261.4,356.4,261,356.5,260.5,356.6z"
        />
        <path
          d="M415.5,216.8c0,3.5,0,5.5,0,7.6c0,54.5,0,108.9,0,163.4c0,7,0,7,6.9,7c22.9,0,45.8,0,68.6,0c1.8,0,3.6-0.1,5.3,0.2
		c3.7,0.7,6,3,6.2,6.9c0.2,4.1-1.6,6.9-5.6,7.8c-2,0.5-4.2,0.4-6.3,0.4c-22.9,0-45.8,0.1-68.6-0.1c-5.1-0.1-6.6,1.3-6.6,6.5
		c0.2,55.5,0.1,111,0.1,166.6c0,2,0,3.9-0.1,5.9c-0.3,4.7-2.9,7.2-7.6,7.1c-4.7,0-7.1-2.7-7.4-7.3c-0.1-1.4-0.1-2.8-0.1-4.3
		c0-58,0-116,0-174c0-67.6,0-135.1,0-202.7c0-10.8,4.8-13.8,14.3-8.8c28.9,15.4,57.8,30.8,86.8,46.2c0.8,0.4,1.6,0.8,2.3,1.3
		c4.7,2.8,6.1,6.5,4.1,10.6c-2.1,4.3-6.4,5.6-11.3,3c-17.4-9.2-34.7-18.5-52-27.8C435.3,227.3,426.1,222.4,415.5,216.8z"
        />
        <path
          d="M569.8,299.5c0,3.1,0,5.3,0,7.5c0,69.3,0,138.7,0,208c0,1.1,0,2.1,0,3.2c-0.2,5.6-2.6,8.4-7.3,8.5
		c-4.9,0.1-7.8-2.8-7.8-8.6c-0.1-29.1,0-58.2,0-87.2c0-44.5-0.1-89,0.1-133.5c0-4.6-1.5-7-5.6-8.9c-7.4-3.4-14.6-7.3-21.8-11
		c-5.3-2.7-7.2-6.8-5.2-11c1.9-4.2,6.6-5.4,11.8-2.8c23.2,11.7,46.5,23.5,69.6,35.3c5.2,2.7,7.1,7,5.1,11.2c-2,4.1-6.6,5.1-11.9,2.5
		c-7.1-3.5-14.2-7.2-21.3-10.8C573.9,301.2,572.3,300.6,569.8,299.5z"
        />
      </g>
    </svg>
  );
};

export const PsNFT = styled(PsNFTView)({});

export default PsNFT;
