import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Tooltip, Typography } from '@mui/material';
import { PersonAddAlt1 } from '@mui/icons-material';
import LeaderBoardView from 'components/LeaderBoardView/index';
import AuthContext from 'contexts/AuthContext';
import Config from 'config/config';
import { value } from 'helpers/constants';
import AddEmployeeModal from 'modals/AddEmployeeModal';
import { useDispatch } from 'react-redux';
import Actions from 'redux-state/actions';
import { GetEmployees, GetEmployeesCount } from 'redux-state/commons/selectors';
import { colorPalette, useIsMediumScreen } from 'theme';
import { Constants } from 'utilities/constants';
import {
  AddEmployeeIcon,
  AvatarContainer,
  OverflowAvater,
  StyledAvatar,
  StyledAvatarGroup,
  StyledBlock,
  StyledButton,
  StyledStatsContent,
  StyledTypography,
  TitleContainer,
  TitleText
} from './styles';
import { Tag } from '../CardTag';

interface MembersViewProps {
  tag: Tag;
}

const MembersView = ({ tag }: MembersViewProps) => {
  const clientUrl = Config.NEXT_PUBLIC_CLIENT_URL;
  const isMediumScreen = useIsMediumScreen();
  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);

  const [maxEmpRender, setMaxEmpRender] = useState<number>(
    isMediumScreen ? 6 : 8
  );
  const [surplus, setSurplus] = useState<number>(0);
  const [showAddEmpModal, setShowAddEmpModal] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const employees = GetEmployees();
  const employeesCount = GetEmployeesCount();

  const onModalClose = useCallback(() => {
    setShowAddEmpModal(false);
  }, [setShowAddEmpModal]);

  const onAddEmpClick = useCallback(() => {
    setShowAddEmpModal(true);
  }, [setShowAddEmpModal]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (tag?.id) {
      dispatch(Actions.getEmployees({ tagId: tag?.id }));
    }
  }, [dispatch, tag]);

  useEffect(() => {
    isMediumScreen ? setMaxEmpRender(() => 6) : setMaxEmpRender(() => 8);
  }, [isMediumScreen]);

  useEffect(() => {
    setSurplus(() => Math.max(employeesCount - maxEmpRender, 0));
  }, [employeesCount, isMediumScreen, maxEmpRender]);

  const onEmployeeClick = (empKey) => {
    window.open(`${clientUrl}profiles/${empKey}`, '_blank');
  };

  return (
    <StyledBlock>
      <TitleContainer>
        <TitleText isMediumScreen={isMediumScreen}>
          {Constants.COMMUNITY_MEMBERS}
        </TitleText>
        <StyledButton onClick={handleOpen}>
          {Constants.SEE_LEADER_BOARD}
        </StyledButton>
      </TitleContainer>
      <LeaderBoardView handleClose={handleClose} open={open} tag={tag} />
      <AvatarContainer>
        <StyledStatsContent>
          <StyledAvatarGroup
            renderSurplus={() => (
              <OverflowAvater>
                <StyledTypography>+{surplus}</StyledTypography>
              </OverflowAvater>
            )}
            max={maxEmpRender}
          >
            {employees.length > 0 ? (
              employees.map((employee, index) => (
                <Tooltip key={index} title={employee.key} placement="bottom">
                  <StyledAvatar
                    src={employee.files[0]?.url}
                    key={index}
                    onClick={() => onEmployeeClick(employee.key)}
                  />
                </Tooltip>
              ))
            ) : (
              <Typography
                sx={{ color: colorPalette.dimBlack, marginBottom: '27px' }}
              >
                This Community has no members.
              </Typography>
            )}
          </StyledAvatarGroup>
        </StyledStatsContent>
        {tag?.owner && user?.id === tag?.owner && (
          <AddEmployeeIcon key={'1'} onClick={onAddEmpClick}>
            <PersonAddAlt1
              sx={{ color: colorPalette.white, cursor: 'pointer' }}
            />
          </AddEmployeeIcon>
        )}
      </AvatarContainer>
      <AddEmployeeModal
        isOpen={showAddEmpModal}
        onClose={onModalClose}
        tag={tag}
      />
    </StyledBlock>
  );
};

export default MembersView;
