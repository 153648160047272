import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  ReactElement
} from 'react';
import { Typography, Box } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { ModalComponentsKeys, ModalContext } from 'contexts/ModalContext';
import { ModalDataContext } from 'contexts/ModalDataContext';
import { DataContext } from 'contexts/DataContext';
import { AuthContext } from 'contexts/AuthContext';
import { Solution } from 'components/CardSolution';
import {
  Application,
  ActionButtons,
  ModalEditApplication,
  NextStepsApplication,
  CardApplicationSolutions
} from 'components/CardApplication';
import {
  Card,
  CardImage,
  CardBody,
  CardTitle,
  CardOwner,
  CardHeader,
  CardTimestamp
} from 'components/new-card';
import {
  DrawerModal,
  DrawerModalContent,
  DrawerModalFooter,
  DrawerModalList,
  DrawerModalItem
} from 'components/DrawerModal';
import { PsButton, EditButton } from 'components/common/PsButton';
import { PsLogo } from 'components/common/PsLogo';
import { PsNFT } from 'components/common/PsNFT';
import { Tags } from 'components/Tag';
import { Coins } from 'components/Coins';
import { ShareBtnSimple } from 'components/ShareBtn';
import { ExpandCard } from 'components/ExpandCard';
import { Tab, TabContent, Tabs } from 'components/Tabs';
import { RelatedJackpots } from 'components/RelatedJackpots';
import { RelatedSolutions } from 'components/RelatedSolutions';
import { RelatedProblems } from 'components/RelatedProblems';
import { RelatedProducts } from 'components/RelatedProducts';
import { RelatedPriorArts } from 'components/RelatedPriorArts';
import { GraphData, GraphDeps } from 'components/GraphDeps';
import { VisibilityStatus } from './VisibilityStatus';
import {
  getDateStr,
  placeholderType,
  finalizeType as finalizeTypes
} from '../../helpers';
import { PsTheme } from '../../theme';
import VotesNew from '../Votes/VotesNew';
import { CardText } from '../card';
import { ButtonWithCounter } from '../common/ButtonWithCounter';
import { InventionBulbIcon } from 'components/InventionBulbIcon';
import { makeStyles } from 'tss-react/mui';
import { Link } from 'react-router-dom';
import { DeployNftButton } from '../DeployNftButton/DeployNftButton';
import { MakePatentPendingModal } from '../../modals/MakePatentPending';
import { Constants } from 'utilities/constants';

const hasNoSolutions = (app: Application) => {
  const list = app.selected || [];
  const withoutProblem = list.filter((item) => {
    return item != app.problem;
  });
  return withoutProblem.length <= 0;
};

const useCardImageStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      width: '40%',
      [psTheme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    inner: {
      paddingTop: '60%',
      [psTheme.breakpoints.down('sm')]: {
        paddingTop: '40%'
      },
      [psTheme.breakpoints.down('xs')]: {
        paddingTop: '55%'
      }
    }
  };
});

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      flexDirection: 'column'
    },
    top: {
      display: 'flex',
      width: '100%',
      [psTheme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    },
    bottom: {
      padding: '10px 0'
    },
    related: {
      padding: '30px 0 0 0'
    },
    tags: {
      '& > div': {
        marginTop: 4,
        marginBottom: 4
      }
    },
    buttons: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row-reverse',
      justifyContent: 'flex-start',
      alignItems: 'center'
    },
    row: {
      display: 'flex',
      flexDirection: 'row'
    },
    cell: {
      display: 'flex',
      flexDirection: 'column',
      '&:first-child': {
        flexGrow: 1
      },
      '&.likes': {
        [psTheme.breakpoints.down('sm')]: {
          display: 'none'
        }
      }
    },
    contributors: {},
    contributorsTitle: {
      marginBottom: 14,
      fontSize: 18,
      fontWeight: 600
    },
    users: {
      fontSize: 0,
      lineHeight: 0
    },
    user: {
      display: 'inline-block',
      marginRight: 15,
      marginBottom: 15
    },
    userPhoto: {
      display: 'block',
      width: 45,
      height: 45,
      borderRadius: 10,
      objectFit: 'cover'
    },
    actionButtons: {
      display: 'flex',
      flexDirection: 'column'
    },
    actionButton: {
      fontSize: 20,
      minWidth: '100%',
      borderRadius: '15px',
      marginRight: 27,
      '& .MuiButton-label': {
        textAlign: 'center'
      },
      [psTheme.breakpoints.down('xs')]: {
        marginBottom: 10,
        marginRight: 0
      }
    },
    actionTextWrapper: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center'
    },
    actionText: {
      paddingTop: '16px',
      maxWidth: 400,
      lineHeight: '1.2',
      '& b': {
        fontWeight: 'normal',
        color: psTheme.palette.primary.main
      },
      '& a.big': {
        fontSize: 18,
        fontWeight: 600,
        color: psTheme.palette.primary.main,
        textDecoration: 'underline',
        cursor: 'pointer'
      },
      '& a.big:hover': {
        textDecoration: 'none'
      }
    },
    actionLinks: {
      display: 'flex',
      justifyContent: 'space-around',
      padding: '25px 10px 0',
      '& span': {
        textAlign: 'center',
        marginBottom: 20
      },
      [psTheme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'center',
        padding: '15px 10px 0'
      }
    },
    actionLink: {
      fontSize: 14,
      fontWeight: 600,
      color: psTheme.palette.primary.main,
      cursor: 'pointer',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    tagsButton: {
      margin: '10px 10px 0 0',
      whiteSpace: 'nowrap'
    },
    descriptionRow: {
      margin: '10px 0 22px',
      fontSize: 18,
      lineHeight: 1.6,
      '& b': {
        fontWeight: 600,
        color: psTheme.palette.primary.main
      }
    },
    votesBlock: {
      marginTop: -7,
      padding: 0,
      background: 'none !important',
      border: 'none !important'
    },
    infoRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      marginBottom: -5,
      paddingTop: 9,
      '& .left': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& button': {
          marginBottom: 10
        }
      },
      '& .right': {
        paddingLeft: 10
      },
      '& .coins': {
        marginRight: 30,
        marginTop: 10,
        marginBottom: 10
      },
      [psTheme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'stretch',
        '& .left': {
          flexWrap: 'wrap'
        },
        '& .right': {
          display: 'none'
        }
      }
    },
    iconNft: {
      marginLeft: -10,
      marginRight: 10
    },
    iconNftCoins: {
      display: 'inline-flex',
      marginLeft: -10,
      marginRight: 10,
      '& svg:first-child': {
        marginRight: -5
      }
    },
    nftCoinsLogo: {
      marginRight: 10,
      '& > svg:first-child': {
        marginRight: -5
      }
    },
    downloadPatent: {
      padding: '20px 10px 10px',
      '& p': {
        fontWeight: 'bold',
        lineHeight: '22px'
      },
      '& button': {
        marginTop: 15
      }
    },
    votes: {
      marginLeft: 'auto',
      marginTop: 0,
      paddingTop: 0
    },
    card: {
      marginBottom: '32px'
    },
    ownerVotes: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '16px'
    },
    itemTypeIcon: {
      position: 'absolute',
      width: 25,
      height: 25,
      border: '2px solid #FBDADA',
      margin: '0px 0px 0px -10px',
      '& > img': {
        margin: 0
      },
      backgroundColor: '#FBDADA',
      zIndex: 1
    },
    lightBulbIcon: {
      color: '#F9D991',
      fontSize: '2.5rem'
    },
    iconSpace: {
      marginRight: '16px',
      color: '#F9D991',
      // background: '#F9D991',
      // height: '30px',
      // width: '30px',
      // borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      // '& svg': {
      //   height: '20px',
      // },
      fontSize: '2.5rem'
    }
  };
});

export type CardApplicationDetailProps = {
  application: Application;
};

export const CardApplicationDetailView = ({
  application: initApplication
}: CardApplicationDetailProps) => {
  const { openModal, openInventionImprove, openSolutionAddToInvention } =
    useContext(ModalContext);
  const { updateField, setType } = useContext(ModalDataContext);
  const { dataProvider, setAlertContent } = useContext(DataContext);
  const { user, userCredits, loading } = useContext(AuthContext);

  const [application, setApplication] = useState<Application>(initApplication);
  const { classes } = useStyles();
  const {
    id,
    key,
    title,
    teaser,
    body,
    files,
    tags = [],
    tagsInfo = [],
    createdAt,
    problem,
    owner,
    ownerInfo,
    products,
    finalizeType,
    paymentType,
    inPayment,
    isFinalized,
    fillingPatentReceipt,
    fillingPatentNumber,
    fillingTime,
    isShared,
    isPaid,
    isExclusivityPaid,
    isExclusivityExpired,
    likes,
    dislikes,
    isFiled,
    parentApplicationKey,
    parentApplicationTitle,
    parentApplicationOwnerKey,
    parentApplicationOwnerName,
    parentProductKey,
    parentProductTitle,
    parentPriorArtKey,
    parentPriorArtTitle,
    nftTransactionUrl,
    termsAgree,
    reward = 0,
    isPublic: isPublicInit = false
  } = application;

  const [editOpened, setEditOpened] = useState(false);
  const [showMakePatentModal, setShowMakePatentModal] = useState(false);
  const [nftUrl, setNftUrl] = useState(nftTransactionUrl || '');
  const [isPublic, setIsPublic] = useState(isPublicInit);
  const [graph, setGraph] = useState<GraphData | undefined>();
  const [solutions, setSolutions] = useState<Solution[]>([]);
  const [solutionsCache, setSolutionsCache] = useState(0);
  const [relatedTab, setRelatedTab] = useState('Problems');

  const [drawerEditable, setDrawerEditable] = useState(false);

  const canDownloadPatent = useMemo(() => {
    let res = false;
    if (
      user &&
      application &&
      (user.isAdmin || user.id === application.owner)
    ) {
      if (user.isAdmin) {
        res = true;
      } else {
        const totalTemplates = (user.downloadTemplates || []).length;
        const hasTemplate = (user.downloadTemplates || []).some(
          (id) => id === application.id
        );
        const canHaveTemplate = totalTemplates === 0 || userCredits > 1;
        if (hasTemplate || canHaveTemplate) {
          res = true;
        }
      }
    }
    return res;
  }, [user, application]);

  const canEdit = user && (user.id === owner || user.isAdmin);
  const canNftDeploy = user && user.isAdmin;

  const canDrawerEdit = useMemo(() => {
    return drawerEditable && canEdit;
  }, [drawerEditable, canEdit]);

  const showNft = isShared ? isFiled && isExclusivityPaid : isFiled;
  const showShortUspto = fillingPatentNumber && !fillingPatentReceipt;

  const showEditTags = (user?.userData?.moderator || []).length;
  const image = files && files.length ? files[0] : undefined;
  const isOpenSourceSigned =
    products && products[0] && products[0].status === 'completed';

  const cardImageClasses = useCardImageStyles();

  useEffect(() => {
    if (loading) {
      return;
    }

    if (inPayment && finalizeType === finalizeTypes.OPEN) {
      if (isOpenSourceSigned) {
        // show success & finalize
        updateField('application', application);
        openModal(ModalComponentsKeys.buildPatentApplicationSuccessOpen);
      }
    } else if (inPayment) {
      updateField('application', application);
      updateField('payProducts', products);
      updateField('brainMethod', paymentType);
      updateField(
        'parentProblemOnly',
        isShared && !isExclusivityPaid && !isExclusivityExpired
      );
      openModal(ModalComponentsKeys.buildPatentApplication);
    }

    if (termsAgree === false) {
      updateField('application', application);
      openModal(ModalComponentsKeys.buildPatentApplicationSuccess);
      dataProvider.update<Application>('applications', {
        id,
        data: { termsAgree: true }
      });
    }
  }, [loading]);

  useEffect(() => {
    setApplication(initApplication);
    setIsPublic(initApplication.isPublic);
    setNftUrl(initApplication.nftTransactionUrl || '');
  }, [initApplication]);

  useEffect(() => {
    setIsPublic(isPublicInit);
  }, [isPublicInit]);

  useEffect(() => {
    if (!application) {
      setSolutions([]);
      return;
    }
    dataProvider
      .getList<Solution>('solutions', {
        pagination: { page: 1, perPage: 500 },
        sort: { field: 'votes', order: 'DESC' },
        filter: {
          $custom: {
            type: 'selectedForApplication',
            application: application.id
          }
        }
      })
      .then(({ data, total }) => {
        setSolutions(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [application, solutionsCache]);

  useEffect(() => {
    if (!application) {
      setGraph(undefined);
      return;
    }
    dataProvider
      .getGraph<GraphData>(
        Constants.APPLICATIONS,
        application.id,
        Constants.APPLICATION
      )
      .then((data) => {
        setGraph(data);
      })
      .catch((err) => {
        console.error(err);
        setGraph(undefined);
      });
  }, [application]);

  const onSolutionsRefresh = () => {
    setSolutionsCache(solutionsCache + 1);
  };

  const onPatentDownloadClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (!application) {
      return;
    }
    if (hasNoSolutions(application)) {
      setAlertContent(
        <>
          You need to add at least one solution to the invention before minting
          NFT
        </>
      );
      return;
    }
    updateField('application', application);
    openModal(ModalComponentsKeys.downloadPatent);
  };

  const onSolutionSuggest = (e: React.MouseEvent) => {
    e.preventDefault();
    if (!application) {
      return;
    }
    setType(ModalComponentsKeys.addNewSolution);
    updateField('parentProblem', application.problem);
    updateField('parentProblemHide', true);
    updateField('parentSolutionHide', true);
    updateField('selectApplicationLabel', application.title);
    updateField('modalTitle', 'Suggest New Solution');
    openModal(ModalComponentsKeys.addNewSolution);
  };

  const onSolutionAdd = (e: React.MouseEvent) => {
    e.preventDefault();
    if (!application) {
      return;
    }
    openSolutionAddToInvention({ application, onlyAdd: true });
  };

  const onSolutionRemove = (solution: Solution) => {
    const selected = application.selected.filter((sel) => sel !== solution.id);
    const appInfo = {
      selected
    };

    return dataProvider
      .update<Application>('applications', {
        id: application.id,
        data: appInfo
      })
      .then(({ data }) => {
        onEditUpdate(data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onEditUpdate = useCallback(
    (newApp: Application) => {
      setApplication(newApp);
      setEditOpened(false);
    },
    [setApplication]
  );

  const onEditOpen = useCallback(() => {
    setEditOpened(true);
  }, [setEditOpened]);

  const onEditClose = useCallback(() => {
    setEditOpened(false);
  }, [setEditOpened]);

  const onImproveClick = useCallback(() => {
    openInventionImprove({ inventionId: id, inventionTitle: teaser || title });
  }, [openInventionImprove, id, teaser, title]);

  const onManageTagsClick = useCallback(() => {
    updateField('title', 'applications');
    updateField('productId', id);
    updateField('tags', tags);
    openModal(ModalComponentsKeys.manageTags);
  }, [updateField, openModal, id, tags]);

  const onPatentClick = useCallback(() => {
    if (hasNoSolutions(application)) {
      setAlertContent(
        <>
          You need to add at least one solution to the invention before minting
          NFT
        </>
      );
      return;
    }
    setShowMakePatentModal(true);
  }, [setAlertContent, application]);

  const onDeployNftClick = () => {
    updateField('selectApplication', id);
    updateField('selectApplicationLabel', teaser || title);
    updateField('selectApplicationType', owner);
    openModal(ModalComponentsKeys.sellInvention);
  };

  let subTitle: ReactElement | undefined = undefined;
  if (parentApplicationTitle && parentApplicationOwnerName) {
    subTitle = (
      <>
        an improvement of the{' '}
        <b>
          <Link to={`/inventions/${parentApplicationKey}`}>
            {parentApplicationTitle}
          </Link>
        </b>{' '}
        invention
        <br />
        By{' '}
        <b>
          <Link to={`/profiles/${parentApplicationOwnerKey}`}>
            {parentApplicationOwnerName}
          </Link>
        </b>
      </>
    );
  }

  if (parentProductTitle) {
    subTitle = (
      <>
        an improvement of the{' '}
        <b>
          <Link to={`/products/${parentProductKey}`}>{parentProductTitle}</Link>
        </b>{' '}
        product
      </>
    );
  } else if (parentPriorArtTitle) {
    subTitle = (
      <>
        an improvement of the{' '}
        <b>
          <Link to={`/prior-arts/${parentPriorArtKey}`}>
            {parentPriorArtTitle}
          </Link>
        </b>{' '}
        prior art
      </>
    );
  }

  const DeployNftBtnMarkup = useMemo(
    () =>
      !nftUrl &&
      !inPayment &&
      isPaid &&
      isFiled && (
        <Box>
          <DeployNftButton title="Deploy NFT" application={application} />
        </Box>
      ),
    [application, inPayment, isFiled, isPaid, nftUrl]
  );

  return (
    <div>
      <Card className={classes.card}>
        <CardHeader>
          <CardTitle
            beforeTitle={
              <div className={classes.iconSpace}>
                <InventionBulbIcon />
              </div>
            }
            title={
              <>
                The invention of <b>{title || teaser}</b>
              </>
            }
            subTitle={subTitle}
          >
            <ShareBtnSimple application={application} tagsList={tagsInfo} />
          </CardTitle>
          <VisibilityStatus application={application} />
          <CardImage image={image} type={placeholderType.P} />
        </CardHeader>
        <CardBody>
          <div className={classes.ownerVotes}>
            <CardOwner
              ownerName={ownerInfo && ownerInfo.username}
              ownerKey={ownerInfo && ownerInfo.key}
              owner={owner}
              createdAt={createdAt}
              ownerPicture={ownerInfo?.picture?.url}
            />
            <VotesNew
              className={classes.votes}
              resource="applications"
              itemId={id}
              likes={likes}
              dislikes={dislikes}
              user={user}
            />
          </div>
          {showShortUspto ? (
            <div>
              USPTO Patent Application Filing Receipt Number{' '}
              {fillingPatentNumber}
              <br />
              Patent Application Filing Date: {getDateStr(fillingTime)}
            </div>
          ) : null}
          <div>
            <Coins className="coins" count={reward}>
              Earned
            </Coins>
            <Tags
              className={classes.tags}
              tags={tags}
              tagsList={tagsInfo}
              edit={showEditTags ? 'before' : ''}
              onEditClick={onManageTagsClick}
            />
          </div>
          <CardText>{body}</CardText>
          <div className={classes.bottom}>
            {body ? (
              <p className={classes.descriptionRow}>
                The invention of <b>{teaser || title}</b> is generally described
                as {body}
              </p>
            ) : null}
            <div className={classes.actionButtons}>
              <ActionButtons
                {...application}
                nftUrl={nftUrl}
                isPublic={isPublic}
                onPatentClick={onPatentClick}
                onDeployNftClick={onDeployNftClick}
              />
              <div className={classes.actionTextWrapper}>
                {(isFinalized && !isFiled) ||
                (showNft && !canNftDeploy) ||
                nftUrl ? (
                  <div className={classes.nftCoinsLogo}>
                    <PsLogo size={60} color="black" small />
                    <PsNFT size={60} color="black" />
                  </div>
                ) : null}
                {!isFinalized ? (
                  <Typography className={classes.actionText} component="div">
                    When you mint an invention NFT, MindMiner will create and
                    file a patent application
                  </Typography>
                ) : null}
                {isFinalized && !isFiled ? (
                  <Typography className={classes.actionText} component="div">
                    Invention NFT for <br />
                    <b>{teaser || title}</b> <br />
                    in progress (awaiting patent filing)
                  </Typography>
                ) : null}
                {showNft && !canNftDeploy && !nftUrl ? (
                  <Typography className={classes.actionText} component="div">
                    Invention NFT for <br />
                    <b>{teaser || title}</b> <br />
                    in progress (awaiting NFT deployment)
                  </Typography>
                ) : null}
                {nftUrl ? (
                  <Typography className={classes.actionText} component="div">
                    <a
                      className="big"
                      href={nftUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      View NFT on OpenSea
                    </a>
                  </Typography>
                ) : null}
              </div>
            </div>
            <div className={classes.actionLinks}>
              {fillingPatentReceipt && (canEdit || canNftDeploy) ? (
                <span>
                  <a
                    className={classes.actionLink}
                    href={fillingPatentReceipt}
                    target="_blank"
                    rel="noreferrer"
                  >
                    USPTO Filing Receipt
                  </a>
                </span>
              ) : null}
            </div>
            {/*<PsButton*/}
            {/*  onClick={onImproveClick}*/}
            {/*  color="secondary"*/}
            {/*  coins="up to 30"*/}
            {/*  smallest*/}
            {/*>*/}
            {/*  Improve*/}
            {/*</PsButton>*/}
            <div>
              <ButtonWithCounter onClick={onImproveClick} coins={1}>
                Improve
              </ButtonWithCounter>
            </div>
            {DeployNftBtnMarkup}
            <div className={classes.buttons}>
              {canEdit ? (
                <EditButton className={classes.tagsButton} onClick={onEditOpen}>
                  Edit Invention
                </EditButton>
              ) : null}
            </div>
          </div>
          <CardTimestamp createdAt={createdAt} />
        </CardBody>
      </Card>

      <ExpandCard title="next steps">
        <NextStepsApplication application={application} />
      </ExpandCard>
      <ExpandCard title="contests & challenges">
        <RelatedJackpots application={application.id} />
      </ExpandCard>
      <ExpandCard title="solution components">
        <>
          <CardApplicationSolutions
            application={application}
            solutions={solutions}
            onSolutionsRefresh={onSolutionsRefresh}
          />

          {canDownloadPatent ? (
            <div className={classes.downloadPatent}>
              <Typography>
                Add more solution components and solution details to enhance the
                value of the patent applications and Idea NFTs that you create
                from your ideas
              </Typography>
              <PsButton
                color="primary"
                smallest
                onClick={onPatentDownloadClick}
              >
                Download Patent Application
              </PsButton>
            </div>
          ) : null}
        </>
      </ExpandCard>
      <ExpandCard title="related items">
        <div className={classes.related}>
          <Tabs>
            <Tab
              title="Solves these problems"
              label="Problems"
              value={relatedTab}
              setValue={setRelatedTab}
              small
            />
            <Tab
              title="Suggested Solutions"
              label="Suggested"
              value={relatedTab}
              setValue={setRelatedTab}
              small
            />
            <Tab
              title="Related Products"
              label="Products"
              value={relatedTab}
              setValue={setRelatedTab}
              small
            />
          </Tabs>
          <TabContent label="Solutions" value={relatedTab}>
            <PsButton color="secondary" smallest onClick={onSolutionAdd}>
              Add a Solution
            </PsButton>
            <RelatedSolutions selectedForApplication={application.id} />
          </TabContent>
          <TabContent label="Problems" value={relatedTab}>
            <RelatedProblems selectedForApplication={application.id} />
          </TabContent>
          <TabContent label="Suggested" value={relatedTab}>
            <PsButton color="secondary" smallest onClick={onSolutionSuggest}>
              Suggest a Solution
            </PsButton>
            <RelatedSolutions suggestedForApplication={application.id} />
          </TabContent>
          <TabContent label="Products" value={relatedTab}>
            <RelatedProducts application={application.id} />
          </TabContent>
        </div>
      </ExpandCard>
      <ExpandCard title="prior art search">
        <RelatedPriorArts application={application?.id} />
      </ExpandCard>
      <ExpandCard title="idea NFTs"></ExpandCard>
      <ExpandCard title="more details"></ExpandCard>
      {graph ? (
        <ExpandCard title="invention map" open>
          <GraphDeps nodes={graph.nodes} links={graph.links} />
        </ExpandCard>
      ) : null}
      <ModalEditApplication
        app={application}
        open={editOpened}
        onUpdate={onEditUpdate}
        onClose={onEditClose}
      />
      {!solutions.length ? (
        <DrawerModal
          title={teaser || title}
          count={solutions.length}
          canEdit={canEdit}
          editable={canDrawerEdit}
          onEditable={setDrawerEditable}
        >
          <DrawerModalContent>
            <DrawerModalList editable={canDrawerEdit} onAdd={onSolutionAdd}>
              {solutions.map((solution) => {
                const onSolutionRemoveClick = async () => {
                  await onSolutionRemove(solution);
                };
                return (
                  <DrawerModalItem
                    key={solution.id}
                    title={solution.teaser || solution.title}
                    url={`/solutions/${solution.key || solution.id}`}
                    editable={canDrawerEdit}
                    onRemove={onSolutionRemoveClick}
                  />
                );
              })}
            </DrawerModalList>
          </DrawerModalContent>
          <DrawerModalFooter>
            <ActionButtons
              {...application}
              nftUrl={nftUrl}
              isPublic={isPublic}
              onPatentClick={onPatentClick}
              onDeployNftClick={onDeployNftClick}
            />
          </DrawerModalFooter>
        </DrawerModal>
      ) : null}
      {showMakePatentModal && (
        <MakePatentPendingModal
          concept={application}
          open={showMakePatentModal}
          setOpen={setShowMakePatentModal}
        />
      )}
    </div>
  );
};

export const CardApplicationDetail = styled(CardApplicationDetailView)({});

export default CardApplicationDetail;
