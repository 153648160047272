import React, { useContext, useEffect, useState } from 'react';
import { Tag } from 'components/CardTag';
import { ImageContainer } from 'components/common/ImageContainer';
import AuthContext from 'contexts/AuthContext';
import { Picture } from 'helpers';
import { BaseModal } from 'modals/Common/BaseModal';
import { ContentSection } from 'modals/Common/ContentSection';
import { HeaderSection } from 'modals/Common/HeaderSection';
import { useDispatch } from 'react-redux';
import Actions from 'redux-state/actions';
import { editTag } from 'redux-state/commons/actions';
import {
  GetGeneratedDescription,
  GetGeneratedDescriptionLoader
} from 'redux-state/selectors';
import { Constants } from 'utilities/constants';
import { ActionSection } from '../Common/ActionSection';
import {
  HeaderBox,
  ImageBox,
  ImageMainBox,
  StyledBodyFieldText,
  StyledBodyTypography,
  StyledCancelButton,
  StyledDivider,
  StyledFooterBox,
  StyledHeaderTypography,
  StyledImageTextTypography,
  StyledMainBox,
  StyledNameFieldText,
  StyledNameTypography,
  StyledSaveButton
} from './StyledComponents';

interface EditTagProps {
  tag?: Tag;
  type?: string;
  onClose?: () => void;
  isOpen?: boolean;
  pagination?: {
    page: number;
    perPage: number;
  };
}

const EditTagModal: React.FC<EditTagProps> = ({
  onClose,
  tag,
  isOpen,
  pagination
}) => {
  const dispatch = useDispatch();
  const aiDescription = GetGeneratedDescription();
  const generatedDescriptionLoader = GetGeneratedDescriptionLoader();
  const { user } = useContext(AuthContext);
  const [tagImage, setTagImage] = useState<Picture | undefined>(tag?.files[0]);
  const [coverImage, setCoverImage] = useState<Picture | undefined>(
    tag?.files[1]
  );
  const [name, setName] = useState<string>(tag?.name);
  const [descr, setDescr] = useState<string>(
    aiDescription ? aiDescription : tag.body
  );

  useEffect(() => {
    if (aiDescription) {
      setDescr(aiDescription);
    }
  }, [aiDescription]);

  useEffect(() => {
    dispatch(
      Actions.generateDescriptionFromAI({
        title: name,
        type: Constants.COMMUNITY
      })
    );
  }, [name]);

  const handleDescriptionChange = (event) => {
    setDescr(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleSaveChanges = () => {
    const payload = {
      id: tag.id,
      type: Constants.TAGS,
      pagination,
      data: {
        name,
        descr,
        files: [tagImage, coverImage].filter(Boolean)
      }
    };
    if (user) {
      dispatch(editTag(payload));
      dispatch(Actions.generateDescriptionFromAISuccess({}));
    } else {
      dispatch(Actions.openAuthModal(true));
    }
    onClose();
  };

  return (
    <BaseModal
      loading={generatedDescriptionLoader}
      maxWidth={'md'}
      onClose={onClose}
      open={isOpen}
    >
      <HeaderSection>
        <HeaderBox>
          <StyledHeaderTypography>{`${Constants.C_EDIT} ${tag?.name} ${Constants.C_TAG}`}</StyledHeaderTypography>
        </HeaderBox>
      </HeaderSection>
      <StyledDivider />
      <ContentSection>
        <StyledMainBox>
          <ImageMainBox>
            <ImageBox>
              <StyledImageTextTypography>
                {Constants.TAG_IMAGE}
              </StyledImageTextTypography>
              <ImageContainer
                description={descr}
                isEditTag={true}
                picture={tagImage}
                setPicture={setTagImage}
                supportText={false}
                title={name}
                type={Constants.COMMUNITY}
              />
            </ImageBox>
            <ImageBox>
              <StyledImageTextTypography>
                {Constants.COVER_IMAGE}
              </StyledImageTextTypography>
              <ImageContainer
                description={descr}
                isCoverImage={true}
                isEditTag={true}
                picture={coverImage}
                setPicture={setCoverImage}
                supportText={false}
                title={name}
                type={Constants.COMMUNITY}
              />
            </ImageBox>
          </ImageMainBox>
          <StyledNameTypography>{Constants.C_NAME}</StyledNameTypography>
          <StyledNameFieldText
            rows={1}
            multiline
            value={name}
            onChange={handleNameChange}
          />
          <StyledBodyTypography>{Constants.Body}</StyledBodyTypography>
          <StyledBodyFieldText
            multiline
            onChange={handleDescriptionChange}
            rows={4}
            value={descr}
          />
        </StyledMainBox>
      </ContentSection>
      <ActionSection>
        <StyledFooterBox>
          <StyledCancelButton onClick={onClose}>
            {Constants.CANCEL}
          </StyledCancelButton>
          <StyledSaveButton onClick={handleSaveChanges}>
            {Constants.SAVE_CHANGES}
          </StyledSaveButton>
        </StyledFooterBox>
      </ActionSection>
    </BaseModal>
  );
};

export default EditTagModal;
