import { Application } from 'components/CardApplication';
import { Contest } from 'components/CardContest';
import { Problem } from 'components/CardProblem';
import { Concept, Product } from 'components/CardProduct';
import { Solution } from 'components/CardSolution';
import { UserAgreement } from 'components/common/Types';
import { Identifier } from 'dataPrvider';
import { eFilePatentPayload } from './interface';
import {
  DELETE_PROFILE_ITEM,
  E_FILE_PATENT_SUCCESS,
  E_FILE_PATENT,
  EDIT_PROFILE_ITEM,
  EDIT_PROFILE_SUCCESS,
  EDIT_PROFILE,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS,
  GET_PROFILE_AGREEMENTS_COUNT_SUCCESS,
  GET_PROFILE_AGREEMENTS_COUNT,
  GET_PROFILE_AGREEMENTS_SUCCESS,
  GET_PROFILE_AGREEMENTS,
  GET_PROFILE_CONCEPTS_COUNT_SUCCESS,
  GET_PROFILE_CONCEPTS_COUNT,
  GET_PROFILE_CONCEPTS_SUCCESS,
  GET_PROFILE_CONCEPTS,
  GET_PROFILE_CONTESTS_COUNT_SUCCESS,
  GET_PROFILE_CONTESTS_COUNT,
  GET_PROFILE_CONTESTS_SUCCESS,
  GET_PROFILE_CONTESTS,
  GET_PROFILE_INVENTIONS_COUNT_SUCCESS,
  GET_PROFILE_INVENTIONS_COUNT,
  GET_PROFILE_INVENTIONS_SUCCESS,
  GET_PROFILE_INVENTIONS,
  GET_PROFILE_MUTUAL_TAGS_SUCCESS,
  GET_PROFILE_MUTUAL_TAGS,
  GET_PROFILE_PROBLEMS_COUNT_SUCCESS,
  GET_PROFILE_PROBLEMS_COUNT,
  GET_PROFILE_PROBLEMS_SUCCESS,
  GET_PROFILE_PROBLEMS,
  GET_PROFILE_PRODUCTS_SUCCESS,
  GET_PROFILE_PRODUCTS,
  GET_PROFILE_SOLUTIONS_COUNT_SUCCESS,
  GET_PROFILE_SOLUTIONS_COUNT,
  GET_PROFILE_SOLUTIONS_SUCCESS,
  GET_PROFILE_SOLUTIONS,
  GET_REWARD_HISTORY_SUCCESS,
  GET_REWARD_HISTORY,
  GET_UNREAD_NOTIFICATION_COUNT_SUCCESS,
  GET_UNREAD_NOTIFICATION_COUNT,
  GET_USER_IDEAPOINTS_SUCCESS,
  GET_USER_IDEAPOINTS,
  GET_USER_RANKING_SUCCESS,
  GET_USER_RANKING,
  MARK_ALL_READ,
  MARK_MULTIPLE_READ,
  NFT_DEPLOY_FINISH_SUCCESS,
  NFT_DEPLOY_FINISH,
  NFT_DEPLOY_START_SUCCESS,
  NFT_DEPLOY_START,
  NFT_DEPLOY_WITH_METAMASK_SUCCESS,
  NFT_DEPLOY_WITH_METAMASK,
  OPEN_TX_APPROVAL_MODAL,
  SET_ARCHIVE_ITEM,
  SET_PIN_ITEM,
  SET_TOKEN_URI,
  TOGGLE_DEPLOY_NFT_LOADER,
  TOGGLE_ERROR,
  TOGGLE_READ_STATUS,
  UPDATE_ERROR_STATUS
} from './types';

export const editProfileItem = (payload: {
  data: any;
  id: string | number;
  pagination?: { page: number; perPage: number };
  parentType?: string;
  profileId?: string | number;
  type: string;
  getConcepts?: boolean;
}) => ({
  type: EDIT_PROFILE_ITEM,
  payload
});

export const editProfile = (id: string | number, data) => ({
  type: EDIT_PROFILE,
  payload: { id, data }
});

export const editProfileSuccess = () => ({
  type: EDIT_PROFILE_SUCCESS
});

export const eFilePatent = (payload: eFilePatentPayload) => ({
  type: E_FILE_PATENT,
  payload
});

export const eFilePatentSuccess = () => ({
  type: E_FILE_PATENT_SUCCESS
});

export const getProfileProblems = (
  profileId: Identifier,
  pagination = null,
  getPinItems = null,
  isArchived = false
) => ({
  type: GET_PROFILE_PROBLEMS,
  payload: { profileId, pagination, getPinItems, isArchived }
});

export const getProfileProblemsCount = (profileId: string | number) => ({
  type: GET_PROFILE_PROBLEMS_COUNT,
  payload: { profileId }
});

export const getProfileProblemsCountSuccess = (count: number) => ({
  type: GET_PROFILE_PROBLEMS_COUNT_SUCCESS,
  payload: { count }
});

export const getProfileSolutionsCount = (profileId: string | number) => ({
  type: GET_PROFILE_SOLUTIONS_COUNT,
  payload: { profileId }
});

export const getProfileSolutionsCountSuccess = (count: number) => ({
  type: GET_PROFILE_SOLUTIONS_COUNT_SUCCESS,
  payload: { count }
});

export const getProfileConceptsCount = (
  profileId: string | number,
  isFiled: boolean
) => ({
  type: GET_PROFILE_CONCEPTS_COUNT,
  payload: { profileId, isFiled }
});

export const getProfileConceptsCountSuccess = (count: number) => ({
  type: GET_PROFILE_CONCEPTS_COUNT_SUCCESS,
  payload: { count }
});

export const getProfileInventionsCount = (
  profileId: string | number,
  isFiled: boolean
) => ({
  type: GET_PROFILE_INVENTIONS_COUNT,
  payload: { profileId, isFiled }
});

export const getProfileInventionsCountSuccess = (count: number) => ({
  type: GET_PROFILE_INVENTIONS_COUNT_SUCCESS,
  payload: { count }
});

export const getProfileContestsCount = (profileId: string | number) => ({
  type: GET_PROFILE_CONTESTS_COUNT,
  payload: { profileId }
});

export const getProfileContestsCountSuccess = (count: number) => ({
  type: GET_PROFILE_CONTESTS_COUNT_SUCCESS,
  payload: { count }
});

export const getProfileAgreementsCount = (profileId: string | number) => ({
  type: GET_PROFILE_AGREEMENTS_COUNT,
  payload: { profileId }
});

export const getProfileAgreementsCountSuccess = (count: number) => ({
  type: GET_PROFILE_AGREEMENTS_COUNT_SUCCESS,
  payload: { count }
});

export const getProfileProblemsSuccess = (payload: Array<Problem>) => ({
  type: GET_PROFILE_PROBLEMS_SUCCESS,
  payload
});

export const getProfileAgreements = (
  profileId: Identifier,
  pagination = null
) => ({
  type: GET_PROFILE_AGREEMENTS,
  payload: { profileId, pagination }
});

export const getProfileAgreementsSuccess = (payload: Array<UserAgreement>) => ({
  type: GET_PROFILE_AGREEMENTS_SUCCESS,
  payload
});

export const getProfileSolutions = (
  profileId: Identifier,
  pagination = null,
  getPinItems = null,
  isArchived = false
) => ({
  type: GET_PROFILE_SOLUTIONS,
  payload: { profileId, pagination, getPinItems, isArchived }
});

export const getProfileSolutionsSuccess = (payload: Array<Solution>) => ({
  type: GET_PROFILE_SOLUTIONS_SUCCESS,
  payload
});

export const getProfileConcepts = (
  profileId: Identifier,
  pagination = null,
  getPinItems = null,
  isArchived = false
) => ({
  type: GET_PROFILE_CONCEPTS,
  payload: { profileId, pagination, getPinItems, isArchived }
});

export const getProfileConceptsSuccess = (payload: Array<Concept>) => ({
  type: GET_PROFILE_CONCEPTS_SUCCESS,
  payload
});

export const getProfileInventions = (
  profileId: Identifier,
  pagination = null,
  getPinItems = null,
  isArchived = false,
  isFiled = false
) => ({
  type: GET_PROFILE_INVENTIONS,
  payload: { profileId, pagination, getPinItems, isArchived, isFiled }
});

export const getProfileInventionsSuccess = (payload: Array<Application>) => ({
  type: GET_PROFILE_INVENTIONS_SUCCESS,
  payload
});

export const getProfileProducts = (
  profileId?: Identifier,
  pagination = null
) => ({
  type: GET_PROFILE_PRODUCTS,
  payload: { profileId, pagination }
});

export const getProfileProductsSuccess = (payload: Array<Product>) => ({
  type: GET_PROFILE_PRODUCTS_SUCCESS,
  payload
});

export const getProfileContests = (
  profileId: Identifier,
  pagination = null,
  getPinItems = null,
  isArchived = false
) => ({
  type: GET_PROFILE_CONTESTS,
  payload: { profileId, pagination, getPinItems, isArchived }
});

export const getProfileContestsSuccess = (payload: Array<Contest>) => ({
  type: GET_PROFILE_CONTESTS_SUCCESS,
  payload
});

export const getNotifications = (
  pagination?: { page: number; perPage: number },
  userId?: string | number | null,
  itemType?: { $ne: string | null },
  self?: { $ne: boolean }
) => ({
  type: GET_NOTIFICATIONS,
  payload: { pagination, userId, itemType, self }
});

export const getNotificationsSuccess = (payload) => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  payload
});

export const getUnreadNotificationCount = (
  userId: string,
  category: string,
  itemType: { $ne: string },
  read?: { $eq: boolean },
  self?: { $ne: boolean }
) => ({
  type: GET_UNREAD_NOTIFICATION_COUNT,
  payload: { userId, category, itemType, read, self }
});

export const getUnreadNotificationCountSuccess = (payload) => ({
  type: GET_UNREAD_NOTIFICATION_COUNT_SUCCESS,
  payload
});

export const toggleReadStatus = (payload) => ({
  type: TOGGLE_READ_STATUS,
  payload
});

export const markMultipleAsRead = (payload) => ({
  type: MARK_MULTIPLE_READ,
  payload
});

export const markAllAsRead = (payload) => ({
  type: MARK_ALL_READ,
  payload
});

export const getRewardHistory = (
  pagination?: { page: number; perPage: number },
  userId?: string | number | null,
  itemType?: { $ne: string | null },
  ideaPoints?: { $ne: number | null }
) => ({
  type: GET_REWARD_HISTORY,
  payload: { pagination, userId, itemType, ideaPoints }
});

export const getRewardHistorySuccess = (payload) => ({
  type: GET_REWARD_HISTORY_SUCCESS,
  payload
});

export const setPinItem = (
  id: string,
  type: string,
  isPinned = false,
  profileId: Identifier,
  isFiled = null,
  pagination = null
) => ({
  type: SET_PIN_ITEM,
  payload: { id, type, isPinned, profileId, isFiled, pagination }
});

export const toggleError = (
  errorMessage: string,
  type = null,
  isFiled = null,
  errorItemId = null
) => ({
  type: TOGGLE_ERROR,
  payload: { errorMessage, type, isFiled, errorItemId }
});

export const setTokenURI = (payload: { tokenURI: string }) => ({
  type: SET_TOKEN_URI,
  payload
});

export const deleteProfileItem = (
  id: string,
  type: string,
  profileId: Identifier,
  isFiled: boolean | null = null,
  pagination: {
    page: number;
    perPage: number;
  }
) => ({
  type: DELETE_PROFILE_ITEM,
  payload: { id, type, profileId, isFiled, pagination }
});

export const setArchiveItem = (
  id: string,
  type: string,
  isArchived = false,
  profileId: Identifier,
  isFiled: boolean | null = null,
  pagination = null
) => ({
  type: SET_ARCHIVE_ITEM,
  payload: { id, type, isArchived, profileId, isFiled, pagination }
});

export const nftDeployStart = (payload: {
  id: string | number;
  onDeployStartSuccess: (
    gasFeeEstimate: number,
    type: string,
    tokenURI: string
  ) => void;
  privateKey: string;
}) => ({
  type: NFT_DEPLOY_START,
  payload
});

export const nftDeployStartSuccess = (payload) => ({
  type: NFT_DEPLOY_START_SUCCESS,
  payload
});

export const nftDeployWithMetamask = (
  contractAddress: string,
  tokenURI: string,
  walletAddress: string
) => ({
  type: NFT_DEPLOY_WITH_METAMASK,
  contractAddress,
  tokenURI,
  walletAddress
});

export const nftDeployWithMetamaskSuccess = (payload) => ({
  type: NFT_DEPLOY_WITH_METAMASK_SUCCESS,
  payload
});

export const nftDeployFinish = (payload) => ({
  type: NFT_DEPLOY_FINISH,
  payload
});

export const nftDeployFinishSuccess = (payload) => ({
  type: NFT_DEPLOY_FINISH_SUCCESS,
  payload
});

export const updateProfileErrorStatus = (
  statusCode: number,
  errorMessage: string
) => ({
  type: UPDATE_ERROR_STATUS,
  payload: { statusCode, errorMessage }
});

export const openTxApprovalModal = (payload) => ({
  type: OPEN_TX_APPROVAL_MODAL,
  payload
});

export const getUserRanking = (userKey: string) => ({
  type: GET_USER_RANKING,
  payload: { userKey }
});

export const getUserRankingSuccess = (rank) => ({
  type: GET_USER_RANKING_SUCCESS,
  payload: { rank }
});

export const getUserIdeaPoints = (userId: string | number) => ({
  type: GET_USER_IDEAPOINTS,
  payload: { userId }
});

export const getUserIdeaPointsSuccess = (ideaPoints) => ({
  type: GET_USER_IDEAPOINTS_SUCCESS,
  payload: { ideaPoints }
});

export const toggleDeployNftLoader = (payload) => ({
  type: TOGGLE_DEPLOY_NFT_LOADER,
  payload
});

export const getProfileMutualTags = (
  userRank?: boolean,
  key?: string,
  pagination = null,
  filter?: string
) => ({
  type: GET_PROFILE_MUTUAL_TAGS,
  payload: { userRank, key, pagination, filter }
});

export const getProfileMutualTagsSuccess = (data) => ({
  type: GET_PROFILE_MUTUAL_TAGS_SUCCESS,
  payload: { data: data }
});
