import React from 'react';
import { CircularProgress } from '@mui/material';
import {
  StyledCircularProgressBar,
  StyledCircularProgressBarText,
  StyledCircularProgressMainContainer,
  StyledCircularProgressSubContainer,
  StyledCircularProgressText
} from './NodeContent/StyledComponents';

type CircularProgressWithLabelProps = { type?: string; progress?: number };
const CircularProgressWithLabel: React.FC<CircularProgressWithLabelProps> = ({
  type,
  progress
}) => {
  return (
    <StyledCircularProgressMainContainer>
      <StyledCircularProgressSubContainer>
        <StyledCircularProgressText>
          {progress === 88
            ? 'AI at work: Almost Done ...'
            : `AI at work: Generating ${type}`}
        </StyledCircularProgressText>
      </StyledCircularProgressSubContainer>
      <CircularProgress
        sx={{ alignSelf: 'center' }}
        variant="determinate"
        value={progress}
        size={100}
      />
      <StyledCircularProgressBar>
        <StyledCircularProgressBarText
          variant="caption"
          color="text.secondary"
        >{`${Math.round(progress)}%`}</StyledCircularProgressBarText>
      </StyledCircularProgressBar>
    </StyledCircularProgressMainContainer>
  );
};

export default CircularProgressWithLabel;
