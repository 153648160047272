import React, { useContext, useCallback, useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { PsButton } from 'components/common/PsButton';
import { Picture } from '../../helpers';
import { PsTheme } from '../../theme';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import { makeStyles } from 'tss-react/mui';

const CloseIcon = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none">
      <path
        d="M16.5076 14.1656L27.5645 3.10871C27.8478 2.80464 28.0021 2.40248 27.9948 1.98693C27.9874 1.57139 27.8191 1.17491 27.5252 0.881031C27.2313 0.58715 26.8349 0.418811 26.4193 0.411479C26.0038 0.404148 25.6016 0.558395 25.2975 0.841726L14.2406 11.8986L3.18375 0.836379C2.87969 0.553049 2.47752 0.398802 2.06197 0.406134C1.64643 0.413465 1.24995 0.581804 0.956072 0.875684C0.662192 1.16956 0.493853 1.56604 0.486521 1.98159C0.479189 2.39713 0.633437 2.7993 0.916767 3.10336L11.9737 14.1656L0.916767 25.2225C0.759176 25.3693 0.632776 25.5464 0.545108 25.7432C0.45744 25.9399 0.4103 26.1523 0.4065 26.3677C0.4027 26.5831 0.442318 26.797 0.522991 26.9967C0.603663 27.1965 0.723738 27.3779 0.876051 27.5302C1.02836 27.6825 1.20979 27.8026 1.40952 27.8833C1.60925 27.9639 1.82318 28.0035 2.03855 27.9997C2.25391 27.9959 2.46631 27.9488 2.66307 27.8611C2.85982 27.7735 3.03691 27.6471 3.18375 27.4895L14.2406 16.4326L25.2975 27.4895C25.6016 27.7728 26.0038 27.9271 26.4193 27.9197C26.8349 27.9124 27.2313 27.7441 27.5252 27.4502C27.8191 27.1563 27.9874 26.7598 27.9948 26.3443C28.0021 25.9287 27.8478 25.5266 27.5645 25.2225L16.5076 14.1656Z"
        fill="currentColor"
      />
    </svg>
  );
};

type ClassKey =
  | 'modalRoot'
  | 'modalPaper'
  | 'modalClose'
  | 'modalContent'
  | 'modalButtons';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    modalRoot: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    modalPaper: {
      position: 'relative',
      backgroundColor: psTheme.palette.background.paper,
      borderRadius: 10,
      outline: 'none',
      'z-index': 1
    },
    modalClose: {
      position: 'absolute',
      top: 25,
      right: 20,
      padding: '10px 10px 6px',
      background: '#FFFFFF',
      boxShadow: '0px 4px 24px rgba(16, 95, 53, 0.08)',
      borderRadius: 10,
      cursor: 'pointer',
      color: 'back',
      '&:hover': {
        color: psTheme.palette.primary.main
      },
      'z-index': 2
    },
    modalContent: {
      '& img': {
        display: 'block',
        width: '50px',
        height: '50px',
        objectFit: 'contain',
        borderRadius: '9px 9px 0 0'
      },
      '& img.v': {
        width: 'auto',
        height: '80vh'
      },
      '& img.h': {
        width: '80vw',
        height: 'auto'
      }
    },
    modalButtons: {
      padding: '10px 15px',
      textAlign: 'center',
      '& > button': {
        margin: '5px 5px 0'
      }
    }
  };
});

type GenerateAIModalProps = {
  open?: boolean;
  onClose?: () => void;
  picture?: Picture;
  onPicture?: (picture?: Picture) => void;
  onGenerate?: () => void;
  loading?: boolean;
};

const GenerateAIModalView = ({
  open = false,
  onClose,
  picture,
  onPicture,
  onGenerate,
  loading
}: GenerateAIModalProps) => {
  const { classes } = useStyles();
  const [pictureType, setPictureType] = useState('');

  useEffect(() => {
    const winSize = window.innerHeight / window.innerWidth;
    setPictureType(winSize > 1 ? 'h' : 'v');
  }, [picture, open]);

  const onSaveClick = () => {
    if (onPicture) {
      onPicture(picture);
    }
    if (onClose) {
      onClose();
    }
  };

  const onGenerateClick = () => {
    if (onGenerate) {
      onGenerate();
    }
  };

  return (
    <Modal
      className={classes.modalRoot}
      open={open}
      onClose={onClose}
      keepMounted={false}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 100
      }}
    >
      <Fade in={open} timeout={100}>
        <div className={classes.modalPaper}>
          <span className={classes.modalClose} onClick={onClose}>
            <CloseIcon />
          </span>
          <div className={classes.modalContent}>
            {open && picture ? (
              <>
                <img
                  className={pictureType}
                  src={picture?.url}
                  alt="generated image"
                />
                <div className={classes.modalButtons}>
                  <Typography>Add AI - generated - image?</Typography>
                  <PsButton onClick={onSaveClick} small>
                    Add
                  </PsButton>
                  <PsButton
                    onClick={onGenerateClick}
                    disabled={loading}
                    loading={loading}
                    small
                  >
                    Regenerate
                  </PsButton>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export const GenerateAIModal = styled(GenerateAIModalView)({});

export default GenerateAIModal;
