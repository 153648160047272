import React, { useCallback, useContext, useState } from 'react';
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  Box,
  LinearProgress,
  Tooltip,
  Typography
} from '@mui/material';
import EditButtonIcon from 'assets/icons/editIcon.svg';
import PlaceHolderImage from 'assets/placeholder/problem.svg';
import EarningsView from 'components/EarningsView';
import InfoView from 'components/InfoView';
import OwnerView from 'components/OwnerView';
import AuthContext from 'contexts/AuthContext';
import EditTagModal from 'modals/EditTagModal';
import { GetEditTagLoading } from 'redux-state/selectors';
import { Constants } from 'utilities/constants';
import { Profile } from '../CardProfile';
import { Tag } from '../CardTag';
import PipeDivider from '../PipeDivider';
import { ShareBtnSimple } from '../ShareBtn';
import { colorPalette, useIsMediumScreen } from '../../theme';
import {
  AccordionContainer,
  BlueDiagonal,
  ClippedBox,
  CoverImage,
  DescriptionText,
  DividerContainer,
  EarningsBlock,
  EarningsContainer,
  IconsBox,
  ImageContainer,
  InfoContainer,
  MainAccordionContainer,
  MainBox,
  MainContent,
  StyledBlock,
  StyledBlockTitle,
  StyledContainer,
  StyledEarningContent,
  StyledEditCursor,
  StyledShareCursor,
  StyledTagActionsBox,
  ViewMoreButton,
  ViewMoreButtonContainer,
  ViewMoreButtonContent,
  WhiteDiagonal
} from './styles';

interface TagActionsProps {
  onEditClick: () => void;
  tag: Tag;
  user: Profile;
  isMediumScreen: boolean;
}
const TagActions = ({
  onEditClick,
  tag,
  user,
  isMediumScreen
}: TagActionsProps) => {
  return (
    <Box>
      <IconsBox>
        {tag?.owner && user?.id === tag?.owner && (
          <StyledEditCursor
            marginRight={isMediumScreen ? 0.2 : 1}
            marginLeft={2}
            onClick={onEditClick}
          >
            <img
              width={isMediumScreen ? 15 : 18}
              height={isMediumScreen ? 15 : 18}
              src={EditButtonIcon}
              alt="EditIcon"
            />
          </StyledEditCursor>
        )}
        <StyledShareCursor marginLeft={isMediumScreen ? 0.8 : 1.5}>
          <ShareBtnSimple tag={tag} />
        </StyledShareCursor>
      </IconsBox>
    </Box>
  );
};

interface TopBarProps {
  title?: string;
  tag?: Tag;
  description?: string;
  pagination?: {
    page: number;
    perPage: number;
  };
}

const TopBar: React.FC<TopBarProps> = ({
  title,
  description,
  tag,
  pagination
}) => {
  const ideaPoints = tag.ideaPoints ?? 0;
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [accordionExpanded, setAccordionExpanded] = useState<boolean>(false);
  const { user } = useContext(AuthContext);
  const isMediumScreen = useIsMediumScreen();
  const editTagLoading = GetEditTagLoading();

  const onEditOpen = useCallback(() => {
    setShowEditModal(true);
  }, [setShowEditModal]);

  const onEditClose = useCallback(() => {
    setShowEditModal(false);
  }, [setShowEditModal]);

  const handleAccordionChange = () => {
    setAccordionExpanded(!accordionExpanded);
  };

  const handleButtonClick = () => {
    setAccordionExpanded(!accordionExpanded);
  };

  const Description = () => {
    return (
      <DescriptionText isMediumScreen={isMediumScreen}>
        {description}
      </DescriptionText>
    );
  };

  const ClipedCoverImage = () => {
    return (
      <ClippedBox isMediumScreen={isMediumScreen}>
        <CoverImage
          //TODO: This image is static for now. We will update it to dynamic after the implementation of AWS s3.
          src={tag.files[1]?.url ?? PlaceHolderImage}
          alt={Constants.COVER_IMAGE}
        />
      </ClippedBox>
    );
  };

  const Divider = () => {
    return (
      <DividerContainer>
        <PipeDivider size={'long'} />
      </DividerContainer>
    );
  };

  return (
    <>
      {!isMediumScreen ? (
        <>
          {editTagLoading && <LinearProgress />}
          <MainBox isMediumScreen={isMediumScreen}>
            <MainContent isMediumScreen={isMediumScreen}>
              <StyledContainer>
                <InfoContainer>
                  <InfoView
                    tag={tag}
                    title={title}
                    tagId={tag?.id}
                    image={tag?.files[0]?.url}
                    isOwnCommunity={tag?.ownerInfo?.key === user?.key}
                  />
                  <Divider />
                  <OwnerView
                    leader={tag?.ownerInfo}
                    subtitle={Constants.COMMUNITY_LEADER}
                    icon={tag.ownerInfo.picture?.url}
                  />
                </InfoContainer>
                <TagActions
                  isMediumScreen={isMediumScreen}
                  onEditClick={onEditOpen}
                  tag={tag}
                  user={user}
                />
              </StyledContainer>
              <Box>
                <Tooltip
                  title={description}
                  arrow
                  followCursor
                  enterTouchDelay={700}
                >
                  <Description />
                </Tooltip>
              </Box>
            </MainContent>
            <ImageContainer>
              <ClipedCoverImage />
              <BlueDiagonal />
              <WhiteDiagonal />
              <EarningsContainer>
                <StyledBlock>
                  <StyledEarningContent>
                    <EarningsView stats={null} ideaPoints={ideaPoints} />
                  </StyledEarningContent>
                </StyledBlock>
              </EarningsContainer>
            </ImageContainer>
          </MainBox>
        </>
      ) : (
        <>
          <MainBox isMediumScreen={isMediumScreen}>
            <MainContent isMediumScreen={isMediumScreen}>
              <StyledContainer>
                <InfoContainer>
                  <InfoView
                    tag={tag}
                    title={title}
                    tagId={tag?.id}
                    image={tag?.files[0]?.url}
                  />
                </InfoContainer>
              </StyledContainer>
            </MainContent>
            <ImageContainer>
              <ClipedCoverImage />
              <StyledTagActionsBox>
                <TagActions
                  isMediumScreen={isMediumScreen}
                  onEditClick={onEditOpen}
                  tag={tag}
                  user={user}
                />
              </StyledTagActionsBox>
            </ImageContainer>
          </MainBox>
          <MainAccordionContainer>
            <Accordion
              expanded={accordionExpanded}
              onChange={handleAccordionChange}
              sx={{
                color: colorPalette.purple,
                borderRadius: '0px 0px 15px 15px',
                display: accordionExpanded ? 'block' : 'none',
                marginBottom: '-15px'
              }}
            >
              <AccordionDetails>
                <AccordionContainer>
                  <OwnerView
                    leader={tag?.ownerInfo}
                    subtitle={Constants.COMMUNITY_LEADER}
                    icon={tag.ownerInfo.picture?.url}
                  />
                  <Box sx={{ width: '100%' }}>
                    <StyledBlockTitle>Earnings</StyledBlockTitle>
                    <EarningsBlock>
                      <EarningsView stats={null} ideaPoints={ideaPoints} />
                    </EarningsBlock>
                  </Box>
                  <Box>
                    <Description />
                  </Box>
                </AccordionContainer>
              </AccordionDetails>
            </Accordion>
          </MainAccordionContainer>
        </>
      )}
      {isMediumScreen && (
        <ViewMoreButtonContainer>
          <ViewMoreButton onClick={handleButtonClick}>
            <ViewMoreButtonContent>
              <Typography variant="button" fontSize={'12px'} fontWeight={'600'}>
                {accordionExpanded ? 'Less Details' : 'View Details'}
              </Typography>
              {accordionExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ViewMoreButtonContent>
          </ViewMoreButton>
        </ViewMoreButtonContainer>
      )}
      {showEditModal && (
        <EditTagModal
          isOpen={showEditModal}
          onClose={onEditClose}
          tag={tag}
          pagination={pagination}
        />
      )}
    </>
  );
};

export default TopBar;
