import React, { useState, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { Contest } from 'components/CardContest';
import { Product } from 'components/CardProduct';
import { Tag, TagInfo } from 'components/CardTag';
import CounterButton from 'components/common/CounterButton';
import config from 'config/config';
import { ModalDataContext } from 'contexts/ModalDataContext';
import dataProvider from 'dataPrvider';
import { Picture, useRemoveQueryParams } from 'helpers';
import { useCheckCredits } from 'hooks/useCheckCredits';
import useRouter from 'hooks/useRouter';
import Actions from 'redux-state/actions';
import {
  GetCalculateMultiplierIdeaPoints,
  GetGeneratedDescriptionLoader,
  GetGenerateImageLoader,
  GetProduct,
  GetUser
} from 'redux-state/selectors';
import { Constants } from 'utilities/constants';
import { ActionSection } from '../Common/ActionSection';
import { BaseModal } from '../Common/BaseModal';
import { ContentSection } from '../Common/ContentSection';
import { HeaderSection } from '../Common/HeaderSection';
import { OptionType } from '../Common/interface';
import { Content } from './Content';
import { Header } from './Header';
import {
  StyledCircularProgress,
  StyledCounterButtonMainWrapper,
  StyledMultiplierIdeaPts
} from './StyledComponents';

const API_COSTS = config.API_COSTS;

interface ProblemModalProps {
  communityTagHeader?: string;
  isCommunityTagsPopup?: boolean;
  isIdeaMapPopUp?: boolean;
  onClose?: () => void;
  open: boolean;
  parentNode?: Node;
  problemSolutionCreationCallBack?: (id?) => void;
  referralCode?: string | string[];
  preSelectedProduct?: OptionType;
  preSelectedProblem?: string;
  preSelectedCompany?: OptionType;
}
interface ProblemResponse {
  data: {
    key: string;
    id: string;
  };
}

export type Node = {
  _id: string;
  children?: Node[];
  company?: string;
  contests: Array<Contest>;
  hasDataLoaded?: boolean;
  id: string;
  image: string;
  isBeingGenerated?: boolean;
  isLiked: boolean;
  key: string;
  likes: number;
  ownerImage: string;
  problem: string;
  shortTitle?: string;
  tags: Array<TagInfo>;
  teaser: string;
  title: string;
  type: string;
};

const ProblemModal: React.FC<ProblemModalProps> = ({
  communityTagHeader,
  isCommunityTagsPopup,
  isIdeaMapPopUp,
  onClose,
  open,
  parentNode,
  problemSolutionCreationCallBack,
  referralCode,
  preSelectedProduct,
  preSelectedProblem,
  preSelectedCompany
}) => {
  const { resetFields } = useContext(ModalDataContext);
  const dispatch = useDispatch();
  const router = useRouter();
  const user = GetUser();
  const removeQueryParams = useRemoveQueryParams();
  const [problemTitle, setProblemTitle] = useState<string>('');
  const parentNodeTitle = parentNode?.title;
  const parentNodeId = parentNode?.id;
  const companyId = parentNode?.company;
  const [selectedTags, setSelectedTags] = useState<Array<OptionType | null>>(
    []
  );
  const [selectedCompany, setSelectedCompany] = useState<OptionType | null>(
    null
  );
  const [selectedProduct, setSelectedProduct] = useState<OptionType | null>({
    label: parentNodeTitle,
    value: parentNodeId
  });

  const [description, setDescription] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [picture, setPicture] = useState<Picture | undefined>(undefined);
  const [loadingCount, setLoadingCount] = useState(0);
  const startLoading = () => setLoadingCount((count) => count + 1);
  const stopLoading = () => setLoadingCount((count) => count - 1);
  const generatedDescriptionLoader = GetGeneratedDescriptionLoader();
  const generateImageLoader = GetGenerateImageLoader();
  const product = GetProduct();
  const multiplierIdeaPoints = GetCalculateMultiplierIdeaPoints();
  const ideaPoints =
    product?.likes === 0 && product?.dislikes >= 3
      ? 0
      : multiplierIdeaPoints === 0
        ? Constants.DEFAULT_CREATE_ITEM_POINTS
        : multiplierIdeaPoints;

  const hasSufficientCredits = useCheckCredits(API_COSTS.CREATE_PROBLEM);

  if (!hasSufficientCredits()) {
    onClose();
    return null;
  }

  useEffect(() => {
    if (selectedProduct) {
      dispatch(Actions.getProduct(selectedProduct?.value as string));
    }
  }, [dispatch, selectedProduct]);

  useEffect(() => {
    if (product) {
      dispatch(
        Actions.calculateMultiplierIdeaPoints({
          defaultIdeaPoints: Constants.DEFAULT_CREATE_ITEM_POINTS,
          likes: product?.likes || 0,
          dislikes: product?.dislikes || 0
        })
      );
    }
  }, [dispatch, product]);

  const formLoading = loadingCount > 0;
  const handleCreateClick = () => {
    const formattedTags = selectedTags.map((tag) => tag.value);
    setLoading(true);

    const createCompanyName = selectedCompany.value || '';
    const createCompany = !!selectedCompany.__isNew__;

    const createProductName = selectedProduct.value || '';
    const createProduct = selectedProduct.__isNew__ || false;

    const problemInfo = {
      title: (preSelectedProblem || problemTitle || '').replace(
        Constants.DEFAULT_PROBLEM_TITLE,
        ''
      ),
      tags: formattedTags,
      body: description,
      isPublic: true,
      parentProduct: preSelectedProduct?.value || selectedProduct.value,
      files: picture ? [picture] : [],
      ...(referralCode && { referralCode })
    };

    if (user) {
      Promise.resolve()
        .then(() => {
          if (createCompany) {
            return dataProvider
              .create<Tag>(Constants.TAGS, {
                data: { name: createCompanyName, type: Constants.WORKPLACE }
              })
              .then(({ data }) => data.id);
          }
          return createCompanyName;
        })
        .then((companyId) => {
          if (companyId) {
            problemInfo.tags.push(companyId);
          }

          if (createProduct) {
            return dataProvider
              .create<Product>(Constants.COMPANY_PRODUCTS, {
                data: {
                  title: createProductName,
                  company: companyId,
                  owner: user ? user.id : undefined
                }
              })
              .then(({ data }) => data.id);
          }
          return createProductName;
        })
        .then((productId) => {
          if (productId) {
            problemInfo.parentProduct = productId;
          }

          problemInfo.tags = problemInfo.tags.filter((e) => e);

          return dataProvider.create(Constants.PROBLEMS, {
            data: problemInfo
          });
        })
        .then((response) => {
          handleClose();
          const data = response as ProblemResponse;
          if (referralCode) {
            removeQueryParams([Constants.REFERRAL_CODE, Constants.SHOW_MODAL]);
          }
          if (problemSolutionCreationCallBack) {
            problemSolutionCreationCallBack(data?.data?.id);
          } else {
            router.push(`/${Constants.PROBLEMS}/${data?.data?.key}`);
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      dispatch(Actions.openAuthModal(true));
    }
  };

  const handleClose = () => {
    resetFields();
    onClose();
    dispatch(Actions.generateDescriptionFromAISuccess({}));
    dispatch(Actions.generateImageFromAISuccess({}));
  };

  const disableCreateButton = () => {
    if (preSelectedProduct && preSelectedProblem && preSelectedCompany) {
      return false;
    }
    return (
      !problemTitle ||
      problemTitle.trim() === '' ||
      !selectedCompany ||
      !selectedCompany.value ||
      generateImageLoader
    );
  };

  const headerLabel = isCommunityTagsPopup
    ? communityTagHeader
    : Constants.ADD_NEW_PROBLEM;
  return (
    <BaseModal
      open={open}
      loading={formLoading || generatedDescriptionLoader}
      maxWidth={'lg'}
    >
      <HeaderSection>
        <Header
          label={headerLabel}
          modalType={Constants.PROBLEM}
          onClose={handleClose}
          parentNodeTitle={parentNodeTitle}
          problemSolutionCreationCallBack={problemSolutionCreationCallBack}
          showCrossIcon={true}
        />
      </HeaderSection>
      <ContentSection>
        <Content
          companyId={companyId}
          companyName={parentNode?.tags?.[0]?.name}
          parentNodeId={parentNodeId}
          problemTitle={problemTitle}
          setSelectedTags={setSelectedTags}
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
          description={description}
          setDescription={setDescription}
          picture={picture}
          setPicture={setPicture}
          setProblemTitle={setProblemTitle}
          startLoading={startLoading}
          stopLoading={stopLoading}
          isIdeaMapPopUp={isIdeaMapPopUp}
          productTags={parentNode?.tags}
          selectedTags={selectedTags}
          preSelectedProduct={preSelectedProduct}
          preSelectedProblem={preSelectedProblem}
          preSelectedCompany={preSelectedCompany}
        />
      </ContentSection>
      <ActionSection>
        <StyledCounterButtonMainWrapper>
          <StyledMultiplierIdeaPts>
            {Constants.EARN} {ideaPoints} {Constants.C_IDEA_POINTS}
          </StyledMultiplierIdeaPts>
          <Box sx={{ position: 'relative' }}>
            <CounterButton
              handleClick={handleCreateClick}
              isCreateButton={true}
              text={Constants.C_CREATE}
              disabled={disableCreateButton() || loading}
            />
            {loading && <StyledCircularProgress size={30} thickness={2} />}
          </Box>
        </StyledCounterButtonMainWrapper>
      </ActionSection>
    </BaseModal>
  );
};

export default ProblemModal;
