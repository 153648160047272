import NameAndEmailStep from './steps/NameAndEmailStep';
import TagsStep from './steps/TagsStep';

import {
  ValidateFormType,
  FormStepConfig,
  RegisterPopupSteps
} from 'contexts/ModalDataContext';

const emailRegExp = /^[\w-+.]+@([\w-]+\.)+[\w-]{2,4}$/i;

export const REGISTER_STEPS_CONFIG: {
  [name: string]: FormStepConfig;
} = {
  [RegisterPopupSteps.NameAndEmailStep]: {
    Component: NameAndEmailStep,
    nextStep: RegisterPopupSteps.TagsStep,
    backButtonTitle: 'Close',
    isNextStepBlocked: (data) => {
      if (data.finalizeType) {
        return true;
      }
      return !data.titleContest || !data.titleChallenge || !data.titleProblem;
    },
    validate: (data): ValidateFormType => {
      if (!data.titleContest) {
        return {
          success: false,
          errors: { titleContest: 'First Name is required' }
        };
      }
      if (!data.titleChallenge) {
        return {
          success: false,
          errors: { titleChallenge: 'LastName is required' }
        };
      }
      if (!data.titleProblem) {
        return {
          success: false,
          errors: { titleProblem: 'Email is required' }
        };
      }
      if (!emailRegExp.test(data.titleProblem)) {
        return {
          success: false,
          errors: { titleProblem: 'Email is incorrect' }
        };
      }
      return { success: true };
    }
  },
  [RegisterPopupSteps.TagsStep]: {
    Component: TagsStep,
    isFinalStep: true,
    nextButtonTitle: 'Finish',
    isNextStepBlocked: (data) => {
      return false;
    },
    validate: (data): ValidateFormType => {
      return { success: true };
    }
  }
};
