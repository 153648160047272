import { Box, Typography, styled } from '@mui/material';
import { colorPalette } from 'theme';

export const IconTextContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  color: colorPalette.purple,
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    flexWrap: 'nowrap'
  }
}));

export const IconContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  gap: '0.563rem',
  margin: '0.938rem 0.75rem',

  ':hover': {
    cursor: 'pointer',
    textDecoration: 'underline'
  }
});

export const StyledProfileContent = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap'
  }
}));

export const IconText = styled(Typography)({
  fontSize: '0.75rem',
  fontWeight: 600
});
