import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef
} from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { AuthContext } from 'contexts/AuthContext';
import { DataContext } from 'contexts/DataContext';
import { PsButton } from 'components/common/PsButton';
import Helmet from 'components/Helmet/index';
import { CardContest, Contest } from 'components/CardContest';
import { SearchFilter, SearchFilterTypes } from '../components/SearchFilter';
import { SearchRow, NavBar, ContentBar } from '../../layout';
import { getQuery } from '../../helpers';
import { PsTheme } from '../../theme';
import { dataProvider } from '../../dataPrvider';
import useRouter from '../../hooks/useRouter';
import { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

const PAGE_DEFAULT = 1;
const PER_PAGE_DEFAULT = 10;
const SORT_BY_DEFAULT = 'votes';
const FILTER_BY_DEFAULT = 'all-active';

type ClassKey =
  | 'container'
  | 'wrapper'
  | 'loading'
  | 'noResult'
  | 'listWrapper'
  | 'header'
  | 'itemsSelectWrapper'
  | 'itemsSelect'
  | 'loadMoreWrapper';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxWidth: 1255,
      margin: '0 auto',
      padding: '0 10px'
    },
    wrapper: {
      display: 'flex'
    },
    loading: {
      marginBottom: 10,
      textAlign: 'center'
    },
    noResult: {
      marginBottom: 10,
      textAlign: 'center'
    },
    listWrapper: {
      padding: '48px 0',
      paddingTop: 6
    },
    header: {
      display: 'flex',
      justifyContent: 'flex-end',
      flexWrap: 'wrap'
    },
    itemsSelect: {
      marginLeft: 8,
      height: 32,
      padding: 0,
      overflow: 'hidden'
    },
    loadMoreWrapper: {
      textAlign: 'center'
    }
  };
});

const ContestsListView = () => {
  const { classes } = useStyles();
  const router = useRouter();
  const state = useRef({ resetPages: true, initRender: true });
  const { user } = useContext(AuthContext);
  const { dataProvider, contestsListCache, filterTags } =
    useContext(DataContext);

  const query = getQuery();
  const initPage = parseInt(query.p, 10) || PAGE_DEFAULT;
  const initPerPage = parseInt(query.pp, 10) || PER_PAGE_DEFAULT;
  const initSortBy = query.s || SORT_BY_DEFAULT;
  const initFilterBy = query.f || FILTER_BY_DEFAULT;
  const filterTagsStr = query.tags;

  const [page, setPage] = useState(initPage);
  const [sortBy, setSortBy] = useState<string>(initSortBy);
  const [loading, setLoading] = useState(true);
  const [perPage, setPerPage] = useState<number>(initPerPage);
  const [filterBy, setFilterBy] = useState<string>(initFilterBy);
  const [totalItems, setTotalItems] = useState(0);
  const [dataChunks, setDataChunks] = useState<Array<Contest>>();

  let showLoadMore = false;
  if (totalItems && page * perPage < totalItems) {
    showLoadMore = true;
  }

  useEffect(() => {
    if (state.current.initRender) {
      state.current.initRender = false;
      return;
    }

    setLoading(true);

    const fetchData = async () => {
      const filter: { $custom: any; tags?: string[]; type?: string } = {
        $custom: { type: 'forUi' },
        type: FILTER_BY_DEFAULT
      };
      if (query.tags) {
        filter.tags = query.tags.split(',');
      }
      if (query.f) {
        filter.type = query.f;
      }
      const data = await dataProvider.getList<Contest>('contests', {
        pagination: {
          page: query.p || PAGE_DEFAULT,
          perPage: query.pp || PER_PAGE_DEFAULT
        },
        sort: { field: query.s || SORT_BY_DEFAULT, order: 'DESC' },
        filter
      });

      return data.data;
    };

    fetchData()
      .then((data: Contest[]) => {
        setDataChunks(data);
        setTotalItems(data.length);
      })
      .catch((error: Error) => {
        console.error(error);
      })
      .finally(() => {
        state.current.resetPages = true;
        setLoading(false);
      });
  }, [page, perPage, sortBy, filterBy, filterTagsStr, contestsListCache, user]);

  const onPerPageChange = useCallback(
    (e: SelectChangeEvent<{ value: unknown }>) => {
      state.current.resetPages = true;
      state.current.initRender = false;
      if (e.target.value) {
        const numValue = parseInt('' + e.target.value);
        setPerPage(numValue);
        setPage(1);
        const { pp, ...newQuery } = getQuery();
        if (numValue && numValue !== PER_PAGE_DEFAULT) {
          newQuery.pp = `${numValue}`;
        }
        router.replace(
          `${router.pathname}?${new URLSearchParams(newQuery).toString()}`
        );
        setLoading(true);
      }
    },
    [setPerPage, setPage]
  );

  const onSortByChange = useCallback(
    (e: SelectChangeEvent<{ value: unknown }>) => {
      state.current.resetPages = true;
      state.current.initRender = false;
      if (e.target.value) {
        const strVal = `${e.target.value}`;
        setSortBy(strVal);
        setPage(1);
        const { s, ...newQuery } = getQuery();
        if (strVal && strVal !== SORT_BY_DEFAULT) {
          newQuery.s = strVal;
        }
        router.replace(
          `${router.pathname}?${new URLSearchParams(newQuery).toString()}`
        );
        setLoading(true);
      }
    },
    [setSortBy, setPage]
  );

  const onFilterByChange = useCallback(
    (e: SelectChangeEvent<{ value: unknown }>) => {
      state.current.resetPages = true;
      state.current.initRender = false;
      if (e.target.value) {
        const strVal = `${e.target.value}`;
        setFilterBy(strVal);
        setPage(1);
        const { f, ...newQuery } = getQuery();
        if (strVal && strVal !== FILTER_BY_DEFAULT) {
          newQuery.f = strVal;
        }
        router.replace(
          `${router.pathname}?${new URLSearchParams(newQuery).toString()}`
        );
        setLoading(true);
      }
    },
    [setFilterBy, setPage]
  );

  const onLoadMoreClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      state.current.resetPages = false;
      state.current.initRender = false;
      setPage(page + 1);
    },
    [page, setPage]
  );

  let hasResult = false;
  if (
    dataChunks &&
    dataChunks.length &&
    dataChunks[0] &&
    dataChunks[0].length
  ) {
    hasResult = true;
  }

  return (
    <div className={classes.container}>
      <Helmet title="Contests" />
      <header>
        <title>MindMiner - Contests</title>
      </header>

      <SearchRow />

      <div className={classes.wrapper}>
        <NavBar />

        <ContentBar className={classes.listWrapper}>
          <div className={classes.header}>
            <SearchFilter
              onChange={onFilterByChange}
              type={SearchFilterTypes.ACTIVE_AND_EXPIRED}
              value={filterBy}
              title="Filter by:"
            />
            <SearchFilter
              onChange={onSortByChange}
              type={SearchFilterTypes.RATING}
              value={sortBy}
              title="Sort by:"
            />
            <SearchFilter
              onChange={onPerPageChange}
              type={SearchFilterTypes.PER_PAGE_ITEMS}
              value={perPage}
              title="Cards on page:"
            />
          </div>
          {dataChunks
            ? dataChunks.map((item) => (
                <CardContest key={item.id} contest={item} />
              ))
            : null}
          {!loading && !hasResult ? (
            <Typography className={classes.noResult} variant="body2">
              No contests yet
            </Typography>
          ) : null}
          {loading ? (
            <Typography className={classes.loading} variant="body2">
              Loading...
            </Typography>
          ) : null}
          {showLoadMore ? (
            <div className={classes.loadMoreWrapper}>
              <Link to={`${router.pathname}?p=${page + 1}`}>
                <PsButton onClick={onLoadMoreClick} component="a">
                  Load More
                </PsButton>
              </Link>
            </div>
          ) : null}
        </ContentBar>
      </div>
    </div>
  );
};

export const ContestsList = styled(ContestsListView)({});

export default ContestsList;
