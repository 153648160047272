import React from 'react';
import { FunctionComponent } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { PsTheme } from '../../theme';
import classnames from 'classnames';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      minWidth: 0,
      padding: '8px'
    }
  };
});

type CardBodyProps = {
  children?: any;
  className?: string;
};

const CardBodyView: FunctionComponent<CardBodyProps> = ({
  className = ' ',
  children
}) => {
  const { classes } = useStyles();
  return (
    <div className={classnames(classes.root, { [className]: className })}>
      {children}
    </div>
  );
};

export const CardBody = styled(CardBodyView)({});

export default CardBody;
