import React from 'react';
import { FunctionComponent } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const SolutionsIcon: FunctionComponent<SvgIconProps> = ({
  ...props
}) => (
  <SvgIcon {...props} viewBox="0 0 28 28">
    <g
      fill="#fff"
      fillOpacity="0"
      stroke="currentColor"
      strokeWidth="2.2"
      data-name="Ellipse 68"
    >
      <circle cx="14" cy="14" r="14" stroke="none" />
      <circle cx="14" cy="14" r="12.9" fill="none" />
    </g>
    <path
      fill="currentColor"
      d="M11.704 18.924a1.1 1.1 0 0 1-.778-.322L7.45 15.126a1.1 1.1 0 0 1 1.556-1.556l2.7 2.7 6.869-6.869a1.1 1.1 0 0 1 1.553 1.554l-7.647 7.647a1.1 1.1 0 0 1-.777.322Z"
      data-name="Icon feather-check"
    />
  </SvgIcon>
);
