import React, { useContext, useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Divider,
  Grid,
  LinearProgress,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { FaCheck } from 'react-icons/fa6';
import { ButtonWithCounter } from 'components/common/ButtonWithCounter';
import { ReadMore } from 'components/ReadMore';
import { Tags } from 'components/Tag';
import AuthContext from 'contexts/AuthContext';
import ModalContext from 'contexts/ModalContext';
import SolutionModal from 'modals/AddSolution';
import ImproveModal from 'modals/ImproveModal';
import { Node } from 'interface/common';
import { AddToButDetail } from 'components/common/StyledComponents';
import { useDispatch } from 'react-redux';
import { MdQuestionMark } from 'react-icons/md';
import Actions from 'redux-state/actions';
import { GetLoadContests } from 'redux-state/selectors';
import { colorPalette } from 'theme';
import { Constants, TAG_TYPES } from 'utilities/constants';
import { TreeIconWithPlus } from '../ConceptsButton/TreeIconWithPlus';
import ContestsAndChallengesView from './ContestAndChallenges';
import TreeIcon from '../icons/TreeIcon';
import { CustomScrollbar } from '../PerfectScrollbar';
import { EntityIcon } from '../TreeGraph/EntityIcon';

interface CardDetailProps {
  item: Node;
  votingPanel: React.ReactNode;
  openAddToConceptModal: () => void;
  isCurrentConceptSolution: boolean;
}

export const CardDetail = ({
  item,
  votingPanel,
  openAddToConceptModal,
  isCurrentConceptSolution
}: CardDetailProps) => {
  const { user } = useContext(AuthContext);
  const { openSolutionImprove } = useContext(ModalContext);
  const dispatch = useDispatch();
  const loader = GetLoadContests();
  const [isSolModalOpen, setIsSolModalOpen] = useState<boolean>(false);
  const [isImproveModalOpen, setIsImproveModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!item?.contests) {
      dispatch(
        Actions.getContestsByProblemOrSolution(item?.id, `${item?.type}s`)
      );
    }
  }, [item, dispatch]);

  const typeTextMapper = (type) => {
    return type == Constants.PROBLEM
      ? Constants.PROBLEM_DRAWER_STRING
      : Constants.SOLUTION_DRAWER_STRING;
  };

  const handleClickButton = () => {
    if (user) {
      if (item.type === 'solution') {
        openSolutionImprove({
          solutionId: item.id,
          solutionTitle: item.teaser || item.title
        });
        return;
      }
      if (item.type === 'product') {
        setIsImproveModalOpen(true);
      }
      if (item.type === 'problem') {
        setIsSolModalOpen(true);
        return;
      }
    } else {
      dispatch(Actions.openAuthModal(true));
    }
  };

  return (
    <Container>
      <GridOne container>
        <GridTwo item md={4}>
          <AttachedIcon type={item.type}>
            <EntityIcon type={item.type} />
          </AttachedIcon>
          <StyledAvatar src={item?.ownerImage} />
        </GridTwo>
        <GridThree item md={8}>
          <Title>{item.title}</Title>
        </GridThree>
      </GridOne>
      <GridFour container>
        <Grid item>
          {item?.type == 'solution' && (
            <>
              <ButtonWithCounter coins={Constants.PLUS_ONE} bigCounter>
                <CoinedButton onClick={handleClickButton}>Improve</CoinedButton>
              </ButtonWithCounter>
              <AddToButDetail
                onClick={openAddToConceptModal}
                variant="contained"
                disabled={isCurrentConceptSolution}
                isCurrentConceptSolution={isCurrentConceptSolution}
              >
                {isCurrentConceptSolution ? (
                  <TreeIcon size={22} />
                ) : (
                  <TreeIconWithPlus height={22} sx={{ marginRight: '5px' }} />
                )}
              </AddToButDetail>
            </>
          )}
          {item.type == 'problem' && (
            <ButtonWithCounter coins={Constants.PLUS_ONE} bigCounter>
              <CoinedButton onClick={handleClickButton}>Solve</CoinedButton>
            </ButtonWithCounter>
          )}
          {item?.type == 'product' && (
            <ButtonWithCounter coins={Constants.PLUS_TWO} bigCounter>
              <CoinedButton onClick={handleClickButton}>Improve</CoinedButton>
            </ButtonWithCounter>
          )}
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item>{votingPanel}</Grid>
            <Grid item>
              <OwnerAvatar src={item?.image} />
            </Grid>
          </Grid>
        </Grid>
      </GridFour>
      <DividerLine />
      <GridFive container>
        <Grid item md={6}>
          <SecondPart variant="h6">Contests:</SecondPart>
          {loader ? (
            <LinearProgress />
          ) : (
            <ContestBlock>
              {item.contests?.length > 0 ? (
                <ContestsAndChallengesView contests={item.contests} />
              ) : (
                <Typography>
                  {item.type === 'problem'
                    ? 'This problem is not part of any contest'
                    : item.type === 'solution'
                      ? 'This solution has not been entered in any contest'
                      : item.type === 'product'
                        ? 'This product is not part of any contest'
                        : 'There are no Contests yet'}
                </Typography>
              )}
            </ContestBlock>
          )}
          <SecondPart variant="h6">{Constants.DESCRIPTION}:</SecondPart>
          <ReadMore body={item.title} length={200} sameLine />
        </Grid>
        <GridSix item md={6}>
          <SecondPart variant="h6">Tags:</SecondPart>
          {item?.tags?.length ? (
            <CustomScrollbar>
              <TagBox>
                <Tags
                  tagsList={item.tags.filter(
                    (tag) => tag.type !== TAG_TYPES.WORKPLACE
                  )}
                />
              </TagBox>
            </CustomScrollbar>
          ) : (
            <Box
              sx={{
                display: 'inline',
                fontWeight: 'bold',
                textDecoration: 'underline',
                marginTop: '12%'
              }}
            >
              <Typography>This {item?.type} has no tags</Typography>
            </Box>
          )}
          <SecondPart variant="h6">{Constants.COMPANY_TAGS}:</SecondPart>
          {item?.tags?.length ? (
            <CustomScrollbar>
              <TagBox>
                <Tags
                  tagsList={item.tags.filter(
                    (tag) => tag.type == TAG_TYPES.WORKPLACE
                  )}
                />
              </TagBox>
            </CustomScrollbar>
          ) : (
            <Box
              sx={{
                display: 'inline',
                fontWeight: 'bold',
                textDecoration: 'underline',
                marginTop: '12%'
              }}
            >
              <Typography>This {item?.type} has no company tags</Typography>
            </Box>
          )}
        </GridSix>
      </GridFive>
      <DividerLine />
      {item?.children?.length > 0 && item.children[0].title ? (
        <Footer>
          <InnerFooter>
            <FooterTitle>{typeTextMapper(item?.children[0]?.type)}</FooterTitle>
          </InnerFooter>
          {item?.children?.slice(0, 3).map((child, i) => (
            <FooterNode
              borderColor={
                child.type === Constants.PROBLEM
                  ? colorPalette.red
                  : colorPalette.green
              }
              key={i}
            >
              <NodeIcon
                backGroundColor={
                  child.type === Constants.PROBLEM
                    ? colorPalette.pink
                    : colorPalette.mintTulip
                }
                color={
                  child.type === Constants.PROBLEM
                    ? colorPalette.torchRed
                    : colorPalette.malachite
                }
              >
                {child.type === Constants.PROBLEM ? (
                  <MdQuestionMark size={30} color={colorPalette.red} />
                ) : (
                  <FaCheck size={30} color={colorPalette.darkGreen} />
                )}
              </NodeIcon>
              <NodeText>
                <NodeTextTypo>{child.title}</NodeTextTypo>
              </NodeText>
              <NodeNumber
                backgroundColor={
                  child.type === Constants.PROBLEM
                    ? colorPalette.pastelPink
                    : colorPalette.magicMint
                }
              >
                <NodeNumberTypo
                  color={
                    child.type === Constants.PROBLEM
                      ? colorPalette.bloodRed
                      : colorPalette.darkGreen
                  }
                >
                  +12
                </NodeNumberTypo>
              </NodeNumber>
            </FooterNode>
          ))}
        </Footer>
      ) : (
        ''
      )}
      {isSolModalOpen && (
        <SolutionModal
          onClose={() => setIsSolModalOpen(false)}
          open={isSolModalOpen}
          parentNode={item}
        />
      )}
      {isImproveModalOpen && (
        <ImproveModal
          isImproveModalOpen={isImproveModalOpen}
          productId={item.id}
          productKey={item.key}
          setIsImproveModalOpen={setIsImproveModalOpen}
          shortTitle={item.title}
          title={item.title}
        />
      )}
    </Container>
  );
};

const Container = styled(Box)({
  background: colorPalette.white,
  padding: '8px 18px',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  width: '100%'
});

const GridOne = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    margin: '2.5rem 0px 1.875rem'
  },
  margin: '0.9375rem 0rem'
}));

const GridTwo = styled(Grid)({ position: 'relative' });

const GridThree = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    padding: '1rem 0 0 0.5rem'
  },
  padding: '1rem 0 0 0'
}));

const GridFour = styled(Grid)({
  justifyContent: 'space-between'
});

const GridFive = styled(Grid)({ marginTop: '5px' });

const GridSix = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    paddingLeft: '0.625rem'
  },
  paddingLeft: '0'
}));

const GridSeven = styled(Grid)({ marginTop: '25px' });

const AttachedIcon = styled(Box)<{ type: string }>(({ type }) => ({
  alignItems: 'center',
  backgroundColor:
    type == 'product'
      ? colorPalette.cosmicLatte
      : type == 'problem'
        ? colorPalette.danger
        : type == 'solution'
          ? colorPalette.magicMint
          : colorPalette.white,
  border:
    type == 'problem'
      ? `1px solid ${colorPalette.chilliPepper}`
      : type === 'solution'
        ? `1px solid ${colorPalette.shamrockGreen}`
        : `1px solid ${colorPalette.purpleBlue}`,
  borderRadius: '50%',
  color: type == 'problem' ? colorPalette.chilliPepper : 'transparent',
  display: 'flex',
  flexShrink: 0,
  height: '30px',
  justifyContent: 'center',
  left: '-8px',
  position: 'absolute',
  top: '-10px',
  width: '30px',
  zIndex: 3
}));

export const ReactionButtons = styled(Box)<{ type: string }>(({ type }) => ({
  alignItems: 'center',
  backgroundColor:
    type == 'product'
      ? colorPalette.magnolia
      : type == 'problem'
        ? colorPalette.amour
        : type == 'solution'
          ? colorPalette.snowyMint
          : colorPalette.white,
  borderRadius: '30px',
  display: 'flex',
  justifyContent: 'center',
  minHeight: '45px',
  minWidth: '113px',
  padding: '10px 18px'
}));

export const StyledLikes = styled(Typography)({
  color: 'black',
  fontFamily: 'montserrat',
  fontSize: '16px',
  fontWeight: '500',
  margin: '0px 10px 0px 10px'
});

const Title = styled(Typography)({
  fontFamily: 'Montserrat',
  fontSize: '18px',
  fontWeight: '600',
  lineHeight: '23px',
  textAlign: 'left'
});

const CoinedButton = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    fontSize: '1.75rem',
    padding: '0px 1.5625rem'
  },
  fontSize: '1rem',
  fontWeight: '700',
  letterSpacing: '-0.01em',
  lineHeight: '2.1331rem',
  padding: '0px 0.75rem',
  textAlign: 'center'
}));

const DividerLine = styled(Divider)({
  backgroundColor: colorPalette.explosiveGrey,
  margin: '0.625rem -1.125rem'
});

const OwnerAvatar = styled(Avatar)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    height: '3.125rem',
    width: '3.125rem'
  },
  height: '2.5rem',
  marginLeft: '0.625rem',
  width: '2.5rem'
}));

const SecondPart = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: '0.625rem',
  fontSize: '1rem',
  [theme.breakpoints.up('md')]: {
    fontSize: '1.3rem'
  }
}));

const TagBox = styled(Box)(({ theme }) => ({
  maxHeight: '6.25rem',
  overflow: 'auto'
}));

const ContestBlock = styled(Box)({
  fontWeight: 'bold',
  maxHeight: '6.25rem',
  overflowX: 'clip',
  overflowY: 'auto',
  textDecoration: 'underline',
  width: '100%',
  marginBottom: '0.625rem'
});

const NodeIcon = styled(Box)<{ backGroundColor: string; color: string }>(
  ({ backGroundColor, color }) => ({
    alignItems: 'center',
    backgroundColor: backGroundColor,
    borderRadius: '100%',
    color: color,
    display: 'flex',
    height: '4.375rem',
    justifyContent: 'center',
    marginLeft: '-0.25rem',
    marginRight: '0.9375rem',
    width: '4.375rem'
  })
);

const NodeText = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  height: '4.375rem',
  width: '20.3125rem'
});

const NodeTextTypo = styled(Typography)({
  display: '-webkit-box',
  fontSize: '0.75rem',
  fontWeight: '500',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 3
});

const NodeNumber = styled(Box)<{ backgroundColor: string }>(
  ({ backgroundColor }) => ({
    alignItems: 'center',
    backgroundColor: backgroundColor,
    borderRadius: '100%',
    display: 'flex',
    height: '1.875rem',
    justifyContent: 'center',
    position: 'absolute',
    right: '2.0625rem',
    width: '1.875rem'
  })
);

const NodeNumberTypo = styled(Typography)<{ color: string }>(({ color }) => ({
  color: color,
  fontSize: '0.6875rem',
  fontWeight: '600'
}));

const Footer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: '0.625rem 0rem',
  paddingBottom: '1.875rem'
});

const InnerFooter = styled(Box)({
  alignItems: 'center',
  backgroundColor: colorPalette.magnolia,
  display: 'flex',
  height: '60px',
  justifyContent: 'center',
  marginBottom: '0.9375rem',
  width: '100%'
});

const FooterTitle = styled(Typography)({
  color: colorPalette.purple,
  fontSize: '0.875rem',
  fontWeight: 'bold'
});

const FooterNode = styled(Box)<{ borderColor: string }>(({ borderColor }) => ({
  alignItems: 'center',
  border: `1px solid ${borderColor}`,
  borderRadius: '3.125rem',
  display: 'flex',
  margin: '0.3125rem',
  padding: '0px 0.25rem',
  width: '90%'
}));

const StyledAvatar = styled(Avatar)({
  borderRadius: '0.375rem',
  width: '8.125rem',
  height: '8.125rem',
  objectFit: 'cover'
});

export default CardDetail;
