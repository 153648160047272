import React, { useCallback, useContext } from 'react';

import ModalWindow from 'modals/ModalWindow';
import { PsButton } from 'components/common/PsButton';
import { PsLogo } from 'components/common/PsLogo';
import { ModalContext } from 'contexts/ModalContext';
import { ModalDataContext } from 'contexts/ModalDataContext';
import styles from 'modals/SuccessModal/SuccessModal.module.scss';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const PayOutRequestSuccess: React.FC = () => {
  const { closeModal } = useContext(ModalContext);
  const { values, resetFields } = useContext(ModalDataContext);
  const theme = useTheme();
  const handleClick = useCallback(() => {
    closeModal();
    resetFields();
  }, [closeModal, resetFields]);

  return (
    <ModalWindow title="Congratulations!" titleCenter>
      <div className={styles.wrapper}>
        <div className={styles.iconHolder}>
          <PsLogo size={90} color={theme.palette.secondary.main} small />
          <Typography className={styles.iconHolderSecondary}>
            {values.title} IdeaCoins
          </Typography>
        </div>
        <br />
        <br />
        <Typography className={styles.textWide}>Pay out success</Typography>
        <PsButton onClick={handleClick} fullWidth>
          Close
        </PsButton>
      </div>
    </ModalWindow>
  );
};

export default PayOutRequestSuccess;
