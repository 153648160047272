import {
  DISTRIBUTE_REWARD,
  DISTRIBUTE_REWARD_SUCCESS,
  GET_REWARD_POOL_THRESHOLD,
  GET_REWARD_POOL_THRESHOLD_SUCCESS
} from './types';

export const distributeReward = (payload) => ({
  type: DISTRIBUTE_REWARD,
  payload
});

export const distributeRewardSuccess = () => ({
  type: DISTRIBUTE_REWARD_SUCCESS,
  payload: {}
});

export const getRewardPoolThreshold = () => ({
  type: GET_REWARD_POOL_THRESHOLD,
  payload: {}
});

export const getRewardPoolThresholdSuccess = (threshold) => ({
  type: GET_REWARD_POOL_THRESHOLD_SUCCESS,
  payload: { threshold }
});
