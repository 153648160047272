import React from 'react';
export const ProblemIcon = ({
  size = 24,
  color = '#DE3E4B',
  className
}: {
  size?: number;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.53058 5.53631L4.54205 3.54778C4.2674 3.27314 3.82242 3.27314 3.54778 3.54778C3.27314 3.82242 3.27314 4.2674 3.54778 4.54205L5.53631 6.53058C5.81095 6.80522 6.25594 6.80522 6.53058 6.53058C6.80522 6.25594 6.80522 5.81095 6.53058 5.53631Z"
        fill={color}
      />
      <path
        d="M3.5625 11.2969H0.703125C0.314484 11.2969 0 11.6114 0 12C0 12.3886 0.314484 12.7031 0.703125 12.7031H3.5625C3.95114 12.7031 4.26562 12.3886 4.26562 12C4.26562 11.6114 3.95114 11.2969 3.5625 11.2969Z"
        fill={color}
      />
      <path
        d="M23.2969 11.2969H20.4375C20.0489 11.2969 19.7344 11.6114 19.7344 12C19.7344 12.3886 20.0489 12.7031 20.4375 12.7031H23.2969C23.6855 12.7031 24 12.3886 24 12C24 11.6114 23.6855 11.2969 23.2969 11.2969Z"
        fill={color}
      />
      <path
        d="M20.4515 3.54778C20.1768 3.27314 19.7318 3.27314 19.4572 3.54778L17.4687 5.53631C17.194 5.81095 17.194 6.25594 17.4687 6.53058C17.7433 6.80522 18.1883 6.80522 18.4629 6.53058L20.4515 4.54205C20.7261 4.2674 20.7261 3.82247 20.4515 3.54778Z"
        fill={color}
      />
      <path
        d="M12 0C11.6114 0 11.2969 0.314484 11.2969 0.703125V3.5625C11.2969 3.95114 11.6114 4.26562 12 4.26562C12.3886 4.26562 12.7031 3.95114 12.7031 3.5625V0.703125C12.7031 0.314484 12.3886 0 12 0Z"
        fill={color}
      />
      <path
        d="M9.1875 21.1875V21.8906C9.1875 23.0538 10.1337 24 11.2969 24H12.7031C13.8663 24 14.8125 23.0538 14.8125 21.8906V21.1875H9.1875Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4779 5.85357C12.4185 5.38951 14.4014 5.8254 15.9341 7.03478C17.4529 8.24415 18.3248 10.0583 18.3248 11.9989C18.3248 13.6864 17.6639 15.2754 16.4826 16.4707C15.8638 17.0754 15.5123 18.0082 15.5123 18.9223V19.077C15.5123 19.4707 15.2029 19.7801 14.8091 19.7801H9.18414C8.79034 19.7801 8.48101 19.4707 8.48101 19.077V18.9223C8.48101 17.9942 8.14351 17.0895 7.5669 16.5129C5.94976 14.9238 5.31695 12.6457 5.86539 10.3958C6.41387 8.15978 8.22789 6.37389 10.4779 5.85357ZM11.6086 13.2366C11.4517 13.4654 11.3732 13.7563 11.3732 14.1094H12.6192C12.6192 13.874 12.6781 13.6714 12.7958 13.5014C12.9201 13.3314 13.1065 13.1353 13.355 12.913C13.5578 12.7299 13.7213 12.5698 13.8456 12.4325C13.9698 12.2886 14.0745 12.1154 14.1595 11.9127C14.2511 11.7101 14.2969 11.4747 14.2969 11.2067C14.2969 10.651 14.0778 10.2162 13.6395 9.90239C13.2078 9.58205 12.6257 9.42188 11.8932 9.42188C11.2914 9.42188 10.7682 9.52648 10.3234 9.73568C9.87863 9.93835 9.51562 10.2325 9.23438 10.6183L10.186 11.2361C10.5458 10.7327 11.0789 10.481 11.7852 10.481C12.1581 10.481 12.4557 10.566 12.6781 10.7359C12.9004 10.9059 13.0116 11.1282 13.0116 11.4028C13.0116 11.5989 12.9528 11.7754 12.835 11.9323C12.7238 12.0827 12.5505 12.2625 12.315 12.4717C12.0076 12.7463 11.7722 13.0012 11.6086 13.2366ZM11.432 16.2374C11.5825 16.3812 11.7722 16.4531 12.0011 16.4531C12.2235 16.4531 12.4066 16.3812 12.5505 16.2374C12.7009 16.087 12.7762 15.904 12.7762 15.6882C12.7762 15.4725 12.7009 15.2927 12.5505 15.1489C12.4066 15.005 12.2235 14.9331 12.0011 14.9331C11.7722 14.9331 11.5825 15.005 11.432 15.1489C11.2882 15.2927 11.2162 15.4725 11.2162 15.6882C11.2162 15.904 11.2882 16.087 11.432 16.2374Z"
        fill={color}
      />
    </svg>
  );
};

export default ProblemIcon;
