import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import PsButton from 'components/common/PsButton';
import { Tag } from 'components/CardTag';
import { validateEmail } from 'helpers/validateEmail';
import Modal from 'modals/Modal';
import Actions from 'redux-state/actions';
import {
  GetCommonsStatusCode,
  GetCommonsErrorMessage,
  GetCreateEmployeeLoading
} from 'redux-state/commons/selectors';
import { Constants, ERRORS } from 'utilities/constants';
import { PsTheme, colorPalette } from '../../theme';

const StyledMainBox = styled(Box)({
  backgroundColor: colorPalette.white,
  borderRadius: '8px',
  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
  display: 'flex',
  flexDirection: 'column',
  height: 'auto',
  padding: '40px',
  paddingTop: '60px'
});

const StyledHeaderTypography = styled(Typography)({
  color: `${colorPalette.dimBlack} !important`,
  fontSize: '23px !important',
  fontWeight: '600!important',
  paddingBottom: '0px',
  textAlign: 'center',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '4',
  WebkitBoxOrient: 'vertical'
});

const StyledBodyTypography = styled(Typography)({
  color: `${colorPalette.dimBlack} !important`,
  fontSize: '14px !important',
  fontWeight: '700 !important',
  marginTop: '10px',
  paddingBottom: '3px'
});

const StyledNameTypography = styled(Typography)({
  color: `${colorPalette.dimBlack} !important`,
  fontSize: '14px!important',
  fontWeight: '700!important',
  marginTop: '20px',
  paddingBottom: '3px'
});

const StyledBodyFieldText = styled(TextField)({
  borderRadius: '8px'
});

const StyledNameFieldText = styled(TextField)({
  borderRadius: '8px'
});

const StyledFooterBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  gap: '4px',
  justifyContent: 'space-between',
  marginTop: '20px'
});

const StyledCancelButton = styled(PsButton)({
  backgroundColor: `${colorPalette.lightPurple} !important`,
  borderRadius: '30px !important',
  color: `${colorPalette.purple} !important`,
  fontSize: '14px!important',
  fontWeight: 'bold',
  height: '45px',
  marginRight: '8px',
  outline: 'none',
  padding: '8px 0px!important',
  width: '140px'
});

const StyledSaveButton = styled(PsButton)({
  borderRadius: '30px !important',
  color: colorPalette.white,
  fontSize: '14px !important',
  fontWeight: 'bold',
  height: '45px',
  marginRight: '8px',
  outline: 'none',
  padding: '8px 0px !important',
  width: '140px'
});

const StyledMultiplierIdeaPts = styled(Typography)({
  color: colorPalette.purple,
  fontSize: '20px',
  fontWeight: 500
});

const useStyles = makeStyles()((theme) => {
  const psTheme = theme as PsTheme;
  return {
    modalRoot: {
      maxHeight: '100vh',
      overflow: 'auto',
      paddingBottom: 50,
      paddingTop: '8%'
    },
    modalPaper: {
      backgroundColor: psTheme.palette.background.paper,
      borderRadius: 8,
      margin: '0 auto',
      outline: 'none',
      padding: '0px 0px',
      position: 'relative',
      width: '610px',
      '@media (max-width:900px)': {
        marginTop: '50px',
        width: '95%'
      }
    }
  };
});

interface AddEmployeeModalProps {
  isOpen: boolean;
  onClose: () => void;
  tag: Tag;
}

interface FieldsValidityType {
  email: boolean;
  firstName: boolean;
  lastName: boolean;
}

const AddEmployeeModal: React.FC<AddEmployeeModalProps> = ({
  isOpen,
  onClose,
  tag
}) => {
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [fieldsValidity, setFieldsValidity] = useState<FieldsValidityType>({
    email: true,
    firstName: true,
    lastName: true
  });

  const statusCode = GetCommonsStatusCode();
  const errorMessage = GetCommonsErrorMessage();
  const loading = GetCreateEmployeeLoading();

  const { classes } = useStyles();

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const onAddEmployeeClick = () => {
    const firstNameIsEmpty = firstName.trim() === '';
    const lastNameIsEmpty = lastName.trim() === '';
    const emailIsValid = validateEmail(email);

    setFieldsValidity({
      firstName: !firstNameIsEmpty,
      lastName: !lastNameIsEmpty,
      email: emailIsValid
    });
    if (!firstNameIsEmpty && !lastNameIsEmpty && emailIsValid) {
      const payload = {
        email,
        tagId: tag.id,
        username: `${firstName} ${lastName}`
      };
      dispatch(Actions.createEmployeeProfile(payload));
    }
  };

  const onModalClose = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setFieldsValidity({
      firstName: true,
      lastName: true,
      email: true
    });
    dispatch(Actions.updateErrorStatus(null, ''));
    onClose();
  };

  const getEmailHelperText = () => {
    if (!fieldsValidity.email) {
      dispatch(Actions.updateErrorStatus(null, ''));
      return ERRORS.INVALID_EMAIL;
    } else if (statusCode !== 200) {
      return errorMessage;
    } else {
      return '';
    }
  };

  useEffect(() => {
    if (statusCode == 200) {
      onModalClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusCode]);

  return (
    <Modal
      open={isOpen}
      onClose={onModalClose}
      className={classes.modalPaper}
      modalRootClassName={classes.modalRoot}
    >
      <StyledMainBox>
        <StyledHeaderTypography>
          Add {tag?.name} Employee
        </StyledHeaderTypography>
        <StyledNameTypography>{Constants.FIRST_NAME}</StyledNameTypography>
        <StyledNameFieldText
          error={!fieldsValidity.firstName}
          helperText={
            !fieldsValidity.firstName ? Constants.FIRST_NAME_REQUIRED : ''
          }
          multiline
          onChange={handleFirstNameChange}
          rows={1}
          value={firstName}
        />
        <StyledBodyTypography>{Constants.LAST_NAME}</StyledBodyTypography>
        <StyledNameFieldText
          error={!fieldsValidity.lastName}
          helperText={
            !fieldsValidity.lastName ? Constants.LAST_NAME_REQUIRED : ''
          }
          multiline
          onChange={handleLastNameChange}
          rows={1}
          value={lastName}
        />
        <StyledBodyTypography>Email</StyledBodyTypography>
        <StyledBodyFieldText
          error={
            !fieldsValidity.email || (statusCode !== 200 && statusCode !== null)
          }
          helperText={getEmailHelperText()}
          multiline
          onChange={handleEmailChange}
          rows={1}
          value={email}
        />
        <StyledFooterBox>
          <StyledMultiplierIdeaPts>
            {Constants.EARN_250_PTS}
          </StyledMultiplierIdeaPts>
          <Box>
            <StyledCancelButton onClick={onModalClose}>
              {Constants.CANCEL}
            </StyledCancelButton>
            <StyledSaveButton disabled={loading} onClick={onAddEmployeeClick}>
              {Constants.ADD_EMPLOYEE}
            </StyledSaveButton>
          </Box>
        </StyledFooterBox>
      </StyledMainBox>
    </Modal>
  );
};

export default AddEmployeeModal;
