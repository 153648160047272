import React from 'react';
import { FunctionComponent } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const ProductsIcon: FunctionComponent<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props} viewBox="0 0 24.167 26.703">
    <path
      fill="currentColor"
      d="M12.083 26.603a3.546 3.546 0 0 1-1.77-.474l-8.538-4.88A3.553 3.553 0 0 1 0 18.184V8.42a3.552 3.552 0 0 1 1.77-3.064L10.317.472a3.541 3.541 0 0 1 3.537 0l8.538 4.879a3.553 3.553 0 0 1 1.775 3.065v9.764a3.552 3.552 0 0 1-1.767 3.067l-8.55 4.884a3.541 3.541 0 0 1-1.767.472Zm0-24.4a1.342 1.342 0 0 0-.67.18L2.866 7.264A1.343 1.343 0 0 0 2.2 8.421v9.762a1.344 1.344 0 0 0 .67 1.159l8.538 4.879a1.341 1.341 0 0 0 1.345 0l8.547-4.882a1.343 1.343 0 0 0 .666-1.157V8.42a1.344 1.344 0 0 0-.67-1.159l-8.538-4.879a1.347 1.347 0 0 0-.675-.185Z"
      data-name="Path 273"
    />
    <path
      fill="currentColor"
      d="M12.084 14.411a1.1 1.1 0 0 1-.551-.148L.879 8.103a1.1 1.1 0 0 1 1.1-1.9l10.1 5.844 10.1-5.844a1.1 1.1 0 0 1 1.1 1.9l-10.644 6.163a1.1 1.1 0 0 1-.551.145Z"
      data-name="Path 274"
    />
    <path
      fill="currentColor"
      d="M12.083 26.701a1.1 1.1 0 0 1-1.1-1.1v-12.3a1.1 1.1 0 0 1 2.2 0v12.3a1.1 1.1 0 0 1-1.1 1.1Z"
      data-name="Path 275"
    />
  </SvgIcon>
);
