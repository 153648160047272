import { Profile } from 'components/CardProfile';
import {
  FORGET_PASSWORD_RESPONSE,
  FORGET_PASSWORD,
  GET_PROFILE_SOCIAL_AUTH_KEYS_SUCCESS,
  GET_PROFILE_SOCIAL_AUTH_KEYS,
  GET_PROFILE_SUCCESS,
  GET_PROFILE,
  GET_PUBLIC_PROFILE_SUCCESS,
  GET_PUBLIC_PROFILE,
  LOG_IN_SUCCESS,
  LOG_IN,
  RESET_PASSWORD_RESPONSE,
  RESET_PASSWORD,
  SET_LOGIN_USER,
  SIGN_UP_SUCCESS,
  SIGN_UP,
  SOCIAL_DISCONNECT,
  SOCIAL_DISCORD_LOG_IN_SUCCESS,
  SOCIAL_DISCORD_LOG_IN,
  SOCIAL_INSTAGRAM_LOG_IN_SUCCESS,
  SOCIAL_INSTAGRAM_LOG_IN,
  SOCIAL_LINKEDIN_LOG_IN_SUCCESS,
  SOCIAL_LINKEDIN_LOG_IN,
  SOCIAL_LOG_IN_SUCCESS,
  SOCIAL_LOG_IN,
  SOCIAL_TIKTOK_LOG_IN_SUCCESS,
  SOCIAL_TIKTOK_LOG_IN,
  SOCIAL_TWITTER_LOG_IN,
  SOCIAL_TWITTER_LOG_IN_SUCCESS
} from './types';
import { CreateProfilePayload } from '../commons/interface';

export const signUp = (payload: CreateProfilePayload) => ({
  type: SIGN_UP,
  payload
});

export const updateSignUpStatus = () => ({
  type: SIGN_UP_SUCCESS,
  payload: {}
});

export const logIn = (payload: CreateProfilePayload) => ({
  type: LOG_IN,
  payload
});

export const logInSuccess = (payload) => ({
  type: LOG_IN_SUCCESS,
  payload
});

export const forgetPassword = (payload: { email: string }) => ({
  type: FORGET_PASSWORD,
  payload
});

export const forgetPasswordResponse = (payload: {
  resetPasswordStatus?: string;
}) => ({
  type: FORGET_PASSWORD_RESPONSE,
  payload
});

export const getProfile = (id: string | number, useKey?: boolean) => ({
  type: GET_PROFILE,
  payload: { id, useKey }
});

export const getProfileSuccess = (payload) => ({
  type: GET_PROFILE_SUCCESS,
  payload
});

export const getPublicProfile = (userKey?: string | number) => ({
  type: GET_PUBLIC_PROFILE,
  payload: { userKey }
});

export const getPublicProfileSuccess = (payload) => ({
  type: GET_PUBLIC_PROFILE_SUCCESS,
  payload
});

export const resetPassword = (payload: {
  newPassword: string;
  resetToken: string;
}) => ({
  type: RESET_PASSWORD,
  payload
});

export const resetPasswordResponse = (payload: {
  resetPasswordStatus?: string;
}) => ({
  type: RESET_PASSWORD_RESPONSE,
  payload
});

export const setLoginUser = (payload: Profile) => ({
  type: SET_LOGIN_USER,
  payload
});

export const socialDisconnect = (payload) => ({
  payload,
  type: SOCIAL_DISCONNECT
});

export const socialLogIn = (
  payload,
  share?: boolean,
  userId?: string | number
) => ({
  payload,
  share,
  userId,
  type: SOCIAL_LOG_IN
});

export const socialLogInSuccess = () => ({
  type: SOCIAL_LOG_IN_SUCCESS
});

export const getSocialAuthKeys = (payload) => ({
  payload,
  type: GET_PROFILE_SOCIAL_AUTH_KEYS
});

export const getSocialAuthKeysSuccess = (payload) => ({
  payload,
  type: GET_PROFILE_SOCIAL_AUTH_KEYS_SUCCESS
});

export const socialDiscordLogIn = (
  payload,
  share?: boolean,
  userId?: string | number
) => ({
  payload,
  share,
  userId,
  type: SOCIAL_DISCORD_LOG_IN
});

export const socialDiscordLogInSuccess = () => ({
  type: SOCIAL_DISCORD_LOG_IN_SUCCESS
});

export const socialLinkedinLogIn = (
  payload,
  share?: boolean,
  userId?: string | number
) => ({
  payload,
  share,
  userId,
  type: SOCIAL_LINKEDIN_LOG_IN
});

export const socialLinkedinLogInSuccess = () => ({
  type: SOCIAL_LINKEDIN_LOG_IN_SUCCESS
});

export const socialInstagramLogIn = (
  payload,
  share?: boolean,
  userId?: string | number
) => ({
  payload,
  share,
  userId,
  type: SOCIAL_INSTAGRAM_LOG_IN
});

export const socialInstagramLogInSuccess = () => ({
  type: SOCIAL_INSTAGRAM_LOG_IN_SUCCESS
});

export const socialTiktokLogIn = (
  payload,
  share?: boolean,
  userId?: string | number
) => ({
  payload,
  share,
  userId,
  type: SOCIAL_TIKTOK_LOG_IN
});

export const socialTiktokLogInSuccess = () => ({
  type: SOCIAL_TIKTOK_LOG_IN_SUCCESS
});

export const socialTwitterLogIn = (
  payload,
  share?: boolean,
  userId?: string | number
) => ({
  payload,
  share,
  userId,
  type: SOCIAL_TWITTER_LOG_IN
});

export const socialTwitterLogInSuccess = () => ({
  type: SOCIAL_TWITTER_LOG_IN_SUCCESS
});
