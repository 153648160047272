import React from 'react';
export const CheckIcon = ({
  size = 30,
  color = '#008954',
  className
}: {
  size?: number;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g data-name="Group 404" transform="translate(-815 -283.809)">
        <path
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.5"
          d="m836.561 293.984-9.476 9.471-4.3-4.305"
          data-name="Icon feather-check"
        />
      </g>
    </svg>
  );
};
