import React, { useEffect, useCallback, useState } from 'react';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import { Picture } from 'helpers';
import { Tag } from 'components/CardTag';
import { Constants, ERRORS } from 'utilities/constants';
import { CompanyField } from 'components/common/CompanyField';
import { ProductField } from 'components/common/ProductField';
import { DescriptionField } from 'components/common/DescriptionField';
import { ImageContainer } from 'components/common/ImageContainer';
import PsInput from 'components/common/PsInput';
import { ShortEvent } from 'components/common/PsFormControl';
import { TagsField } from 'components/common/TagsField';
import {
  StyledDescriptionExampleText,
  StyledFieldLabel,
  TextWrapper
} from './StyledComponents';
import { OptionType } from '../Common/interface';

interface ContentProps {
  companyId?: string;
  companyName?: string;
  description?: string;
  isIdeaMapPopUp?: boolean;
  onChange?: (e: ShortEvent) => void;
  parentNodeId?: string;
  picture?: Picture;
  problemTitle: string;
  productTags?: Array<Tag>;
  selectedTags?: Array<OptionType | null>;
  setDescription?: React.Dispatch<React.SetStateAction<string>>;
  setPicture?: React.Dispatch<React.SetStateAction<Picture>>;
  setProblemTitle?: React.Dispatch<React.SetStateAction<string>>;
  setSelectedTags?: React.Dispatch<
    React.SetStateAction<Array<OptionType | number>>
  >;
  selectedCompany?: OptionType | null;
  setSelectedCompany?: React.Dispatch<React.SetStateAction<OptionType | null>>;
  selectedProduct?: OptionType | null;
  setSelectedProduct?: React.Dispatch<React.SetStateAction<OptionType | null>>;
  startLoading?: () => void;
  stopLoading?: () => void;
  preSelectedProduct?: OptionType;
  preSelectedProblem?: string;
  preSelectedCompany?: OptionType;
}

const problemFieldLabel = (
  <StyledFieldLabel>{Constants.INPUT_PROBLEM_FIELD_LABEL}</StyledFieldLabel>
);
const descriptionExampleText = (
  <StyledDescriptionExampleText>
    {Constants.PROBLEM_HELPER_TEXT}
  </StyledDescriptionExampleText>
);

export const Content: React.FC<ContentProps> = ({
  companyId,
  companyName,
  description,
  picture,
  problemTitle,
  selectedCompany,
  setDescription,
  setPicture,
  setSelectedCompany,
  selectedProduct,
  setSelectedProduct,
  setProblemTitle,
  setSelectedTags,
  startLoading,
  stopLoading,
  isIdeaMapPopUp,
  productTags,
  selectedTags,
  preSelectedProduct,
  preSelectedProblem,
  preSelectedCompany
}) => {
  const defaultProblemTitle = Constants.DEFAULT_PROBLEM_TITLE;
  const [finalTitle, setFinalTitle] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (!finalTitle) {
      setFinalTitle(defaultProblemTitle);
    }
  }, [defaultProblemTitle, finalTitle]);

  const onProblemNameChange = useCallback(
    (e: ShortEvent) => {
      const { value = '' } = e.target;
      const wordCount = value.trim().split(/\s+/).length;
      const isWordLimitExceeded = wordCount > 50;
      setErrorMessage(isWordLimitExceeded ? ERRORS.WORD_LIMIT_EXCEEDED : '');

      if (!isWordLimitExceeded) {
        const rawVal = value.slice(defaultProblemTitle.length);
        const finalTitle = `${defaultProblemTitle}${rawVal}`;
        setProblemTitle(rawVal);
        setFinalTitle(finalTitle);
      }
    },
    [defaultProblemTitle]
  );

  return (
    <Box>
      <TextWrapper
        helperText={errorMessage || Constants.PROBLEM_HELPER_TEXT}
        label={problemFieldLabel}
        placeholder={Constants.DEFAULT_PROBLEM_TITLE}
        required
      >
        <PsInput
          name={Constants.TITLE_PROBLEM}
          onChange={onProblemNameChange}
          placeHolder={Constants.DEFAULT_PROBLEM_TITLE}
          value={preSelectedProblem || finalTitle}
          error={!!errorMessage}
        />
      </TextWrapper>

      <Grid
        direction={isXs ? 'column' : 'row'}
        container
        rowSpacing={1}
        columnSpacing={{ xs: 2 }}
      >
        <Grid
          item
          xs="auto"
          sm={!isIdeaMapPopUp ? 6 : 12}
          md={!isIdeaMapPopUp ? 6 : 12}
        >
          <TagsField
            label={Constants.PROBLEM_TAGS_LABEL}
            placeholder={Constants.SELECT}
            title={preSelectedProblem || problemTitle}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
            startLoading={startLoading}
            stopLoading={stopLoading}
            productTags={productTags}
          />
        </Grid>

        {!isIdeaMapPopUp && (
          <Grid sx={{ flex: 1 }} item xs={12} sm={12} md={6}>
            <ProductField
              selectedProduct={preSelectedProduct || selectedProduct}
              setSelectedProduct={setSelectedProduct}
              companyId={selectedCompany?.value}
            />
          </Grid>
        )}
      </Grid>
      <Grid
        direction={isXs ? 'column' : 'row'}
        container
        rowSpacing={1}
        columnSpacing={{ xs: 2 }}
      >
        <Grid item xs={12} sm={12} md={6}>
          <CompanyField
            companyId={companyId || preSelectedCompany?.value}
            companyName={companyName || preSelectedCompany?.label}
            isIdeaMapPopUp={isIdeaMapPopUp}
            selectedCompany={selectedCompany || preSelectedCompany}
            setSelectedCompany={setSelectedCompany}
          />
          <DescriptionField
            description={description}
            exampleText={descriptionExampleText}
            label={Constants.DESCRIBE_PROBLEM}
            parentNode={preSelectedProduct || selectedProduct}
            setDescription={setDescription}
            title={preSelectedProblem || problemTitle}
            type={Constants.PROBLEMS}
          />
        </Grid>
        <Grid item md={6} xs={12} sm={12}>
          <ImageContainer
            description={description}
            parentNode={preSelectedProduct || selectedProduct}
            picture={picture}
            setPicture={setPicture}
            supportText={true}
            title={preSelectedProblem || problemTitle}
            type={Constants.PROBLEMS}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
