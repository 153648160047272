import React, { useState, useContext, useRef, useEffect, useMemo } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { PsTheme } from '../../theme';
import { GraphAPI } from './GraphAPI';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root' | 'holder' | 'tooltip';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      position: 'relative',
      paddingTop: '60%'
    },
    holder: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      '& svg': {
        display: 'block',
        width: '100%',
        height: '100%'
      },
      '& .linkH': {
        stroke: '#999',
        strokeOpacity: 0.6,
        strokeWidth: 1
      },
      '& .nodeH': {
        stroke: '#fff',
        strokeWidth: 2
      },
      '& .node': {
        cursor: 'pointer'
      },
      '& .node .bg': {
        cursor: 'pointer',
        fill: '#9D00A0',
        transition: 'stroke 0.3s ease'
      },
      '& .node .bg.problem': {
        fill: '#de3e4b'
      },
      '& .node .bg.solution': {
        fill: '#fbca66'
      },
      '& .node .bg.invention': {
        fill: '#1657c1'
      },
      '& .node:hover .bg': {
        stroke: '#9D00A0'
      },
      '& .node:hover .bg.problem': {
        stroke: '#de3e4b'
      },
      '& .node:hover .bg.solution': {
        stroke: '#fbca66'
      },
      '& .node:hover .bg.invention': {
        stroke: '#1657c1'
      },
      '& .node image.main': {
        opacity: 0,
        transition: 'opacity 0.3s ease'
      },
      '& .node image.main.loaded': {
        opacity: 1
      }
    },
    tooltip: {
      position: 'absolute',
      textAlign: 'center',
      padding: '10px',
      font: '12px sans-serif',
      background: 'lightsteelblue',
      border: 0,
      borderRadius: 8,
      pointerEvents: 'none',
      zIndex: 100
    }
  };
});

type GraphChartProps = {
  className?: string;
  nodesData: Array<any>;
  linksData: Array<any>;
  onNodeClick?: (item: any) => void;
};

const GraphChartView = ({
  className,
  nodesData = [],
  linksData = [],
  onNodeClick
}: GraphChartProps) => {
  const containerRef: React.Ref<HTMLDivElement> = useRef(null);
  const state = useRef<{ api: GraphAPI | undefined }>({ api: undefined });

  const links = useMemo(() => {
    return linksData.map((d) => Object.assign({}, d));
  }, [linksData]);

  const nodes = useMemo(() => {
    return nodesData.map((d) => Object.assign({}, d));
  }, [nodesData]);
  const { classes } = useStyles();

  let rootClassName = classes.root;
  if (className) {
    rootClassName += ' ' + className;
  }

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }
    state.current.api = new GraphAPI(containerRef.current, {
      nodes,
      links,
      onNodeClick,
      classes
    });
    return () => {
      if (state.current.api) {
        state.current.api.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (state.current.api && containerRef.current) {
      state.current.api.update({ nodes, links, onNodeClick });
    }
  }, [links, nodes]);

  return (
    <div className={rootClassName}>
      <div ref={containerRef} className={classes.holder} />
    </div>
  );
};

export const GraphChart = styled(GraphChartView)({});

export default GraphChart;
