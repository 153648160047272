import React, { useCallback } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import { makeStyles } from 'tss-react/mui';
import { PsTheme, colorPalette } from 'theme';
import styles from 'modals/ModalWindow.module.scss';
import { Constants } from 'utilities/constants';
import { StepComponentProps } from './types';

const useStyles = makeStyles()(() => {
  const psTheme = useTheme() as PsTheme;
  return {
    root: {
      '& a': {
        color: psTheme.palette.primary.main
      }
    }
  };
});

const PsCheckboxView = (props: CheckboxProps) => {
  const { classes: checkboxStyles } = useStyles();
  const newProps = { ...props, checkboxStyles };
  return <Checkbox color="default" {...newProps} />;
};

const PsCheckbox = styled(PsCheckboxView)({});

const AcceptConditionsStepView: React.FC<StepComponentProps> = ({
  termsAgree,
  setTermsAgree,
  onFileViaMindMiner
}) => {
  const onTermsChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setTermsAgree(event.target.checked);
    },
    [setTermsAgree]
  );

  return (
    <div className={styles.stepWrapper} style={{ padding: 0 }}>
      <StyledLink onClick={() => onFileViaMindMiner(false, true)}>
        {Constants.FILE_VIA_MINDMINER}
      </StyledLink>
      <FormControlLabel
        className={styles.termsRegister}
        control={
          <PsCheckbox
            checked={termsAgree}
            onChange={onTermsChange}
            name="checkedG"
          />
        }
        label={Constants.PATENT_APPLICATION_TERMS_CONDITION}
      />
    </div>
  );
};

const StyledLink = styled(Typography)({
  color: colorPalette.purple,
  cursor: 'pointer',
  fontSize: '1rem',
  fontWeight: 'bold',
  marginLeft: '.2rem',
  overflow: 'hidden',
  textDecoration: 'underline',
  textOverflow: 'ellipsis'
});

export default AcceptConditionsStepView;
