/* eslint-disable max-lines-per-function */
import { NotificationCard } from './NotificationCard';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useDispatch } from 'react-redux';
import Actions from 'redux-state/actions';
import { GetUser } from 'redux-state/onboarding/selectors';
import {
  GetNotifications,
  GetNotificationsLoader,
  GetUnreadNotificationCount
} from 'redux-state/profile/selectors';
import { Constants, TAG_TYPES } from 'utilities/constants';
import { LoadingBox } from '../RewardsPoolPopup/styledComponents';
import {
  NoNotificationsTypography,
  StyledNotificationCard
} from './styledComponents';

export const NotificationBody = ({
  setUnreadCount
}: {
  setUnreadCount?: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const user = GetUser();
  const dispatch = useDispatch();
  const lastNotificationRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [hideSelfNotifications, setHideSelfNotifications] =
    useState<boolean>(true);
  const [lastNotificationIndex, setLastNotificationIndex] = useState<number>();
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const notifications = GetNotifications();
  const loader = GetNotificationsLoader();
  const count = GetUnreadNotificationCount();

  const pagination = useMemo(
    () => ({
      page: 0,
      perPage: rowsPerPage
    }),
    [rowsPerPage]
  );

  useEffect(() => {
    if (count && setUnreadCount) {
      setUnreadCount(count);
    }
  }, [count, setUnreadCount]);

  useEffect(() => {
    if (notifications) {
      setLastNotificationIndex(notifications?.length);
    }
  }, [notifications]);

  useEffect(() => {
    if (user) {
      dispatch(
        Actions.getUnreadNotificationCount(
          user.id,
          Constants.NOTIFICATION,
          { $ne: TAG_TYPES.TAG },
          { $eq: false },
          { $ne: hideSelfNotifications }
        )
      );
      dispatch(
        Actions.getNotifications(
          pagination,
          user.id,
          { $ne: TAG_TYPES.TAG },
          { $ne: hideSelfNotifications }
        )
      );
    }
  }, [dispatch, hideSelfNotifications, pagination, user]);

  useEffect(() => {
    if (lastNotificationRef.current) {
      const lastNotificationObserver = new IntersectionObserver(
        (entries) => {
          const lastNotification = entries[0];
          if (lastNotification.isIntersecting) {
            setRowsPerPage((prevRowsPerPage) => prevRowsPerPage + 5);
          }
        },
        { threshold: 0.5 }
      );
      lastNotificationObserver.observe(lastNotificationRef.current);
    }
  }, []);

  return (
    <>
      {loader && (
        <LoadingBox>
          <CircularProgress />
        </LoadingBox>
      )}
      <Box sx={{ paddingLeft: '5px' }}>
        {notifications && notifications?.length > 0 ? (
          notifications?.map((notification, index) => {
            const isLast = index === lastNotificationIndex;
            return (
              <div
                ref={isLast ? lastNotificationRef : null}
                key={notification?.id}
                data-id={notification.id}
              >
                <NotificationCard
                  notification={notification}
                  setUnreadCount={setUnreadCount && setUnreadCount}
                />
              </div>
            );
          })
        ) : (
          <StyledNotificationCard>
            <NoNotificationsTypography>
              {Constants.NO_NOTIFICATIONS}
            </NoNotificationsTypography>
          </StyledNotificationCard>
        )}
      </Box>
    </>
  );
};
