import React, { useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { CoinIcon } from 'components/icons/CoinIcon';
import { StepperNav } from 'components/VerticalStepper';
import { Problem } from 'components/CardProblem';
import { ModalContext } from 'contexts/ModalContext';
import { PsTheme } from '../../theme';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      marginLeft: 60
    }
  };
});

export type NextStepsProblemProps = {
  problem: Problem;
};

const NextStepsProblemView = ({ problem }: NextStepsProblemProps) => {
  const { openSolutionCreate, openInventionCreate } = useContext(ModalContext);
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      {' '}
      <StepperNav
        steps={[
          {
            stepContent: () => (
              <div style={{ fontSize: 18 }}>Problem Created</div>
            ),
            stepStatusCircleSize: 20,
            stepStateColor: '#9169FF'
          },
          {
            stepContent: () => (
              <div
                style={{
                  fontSize: 18,
                  color: '#632DDD',
                  fontWeight: 600
                }}
              >
                <CoinIcon color="#632DDD" />{' '}
                <a
                  onClick={() => {
                    openSolutionCreate({ problemId: problem.id });
                  }}
                  style={{ marginLeft: '.5rem' }}
                >
                  Solve this problem to earn 1 IdeaPoint & royalties
                </a>
              </div>
            ),
            stepStatusCircleSize: 20,
            stepStateColor: '#FBCA67'
          },
          {
            stepContent: () => (
              <div
                style={{
                  fontSize: 18,
                  color: '#632DDD',
                  fontWeight: 600
                }}
              >
                <CoinIcon color="#632DDD" />{' '}
                <a
                  onClick={() => {
                    openInventionCreate({
                      problemId: problem.id,
                      problemTitle: problem.title
                    });
                  }}
                  style={{ marginLeft: '.5rem' }}
                >
                  Combine solution with others into an invention
                </a>
              </div>
            ),
            stepStatusCircleSize: 20,
            stepStateColor: 'white'
          },
          {
            stepContent: () => (
              <div style={{ fontSize: 18 }}>
                <CoinIcon color="#632DDD" />{' '}
                <a style={{ marginLeft: '.5rem' }}></a> Mint idea NFT to make
                patent pending
              </div>
            ),
            stepStatusCircleSize: 20,
            stepStateColor: 'white'
          },
          {
            stepContent: () => (
              <div style={{ fontSize: 18 }}>
                Sell your idea NFT to earn crypto
              </div>
            ),
            stepStatusCircleSize: 20,
            stepStateColor: 'white'
          }
        ]}
      />
    </div>
  );
};

export const NextStepsProblem = styled(NextStepsProblemView)({});

export default NextStepsProblem;
