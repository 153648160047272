import React, {
  useContext,
  useCallback,
  useRef,
  useState,
  FunctionComponent
} from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { Tags } from 'components/Tag';
import { DataContext } from 'contexts/DataContext';
import { Profile } from 'components/CardProfile';
import { AvaImage } from 'components/card';
import { Picture, getPlaceholderImage, placeholderType } from '../../helpers';
import { PsTheme } from '../../theme';
import classnames from 'classnames';
import Avatar from '@mui/material/Avatar';
import { Link } from 'react-router-dom';
import Popover from '@mui/material/Popover';
import { makeStyles } from 'tss-react/mui';

const DEFAULT_IMAGE: Picture = {
  title: 'profile',
  contentType: 'image/svg+xml',
  url: getPlaceholderImage(placeholderType.U)
};

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    text: {
      position: 'relative',
      display: 'inline-block',
      cursor: 'pointer',
      textDecoration: 'none',
      '&:hover span': {
        textDecoration: 'underline'
      }
    },
    name: {
      color: psTheme.palette.primary.main,
      fontWeight: 600
    },
    popover: {
      pointerEvents: 'none'
    },
    paper: {
      pointerEvents: 'auto',
      borderRadius: 2,
      border: '1px solid rgba(121, 114, 140, 0.22)',
      boxShadow:
        '0px 4px 8px rgba(10, 0, 32, 0.02), 0px 4px 84px rgba(7, 0, 21, 0.04)'
    },
    preview: {
      display: 'flex',
      padding: 11
    },
    previewLeft: {
      flexShrink: 0
    },
    previewImage: {
      display: 'block',
      width: 150,
      height: 150,
      borderRadius: 10,
      objectFit: 'cover'
    },
    previewRight: {
      width: 245,
      paddingLeft: 22,
      flexGrow: 0,
      overflow: 'hidden'
    },
    previewName: {
      marginBottom: 12,
      fontSize: 20,
      fontWeight: 600,
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    previewTags: {
      marginBottom: 6,
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    previewLink: {
      fontSize: 16,
      fontWeight: 600,
      color: psTheme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    root: {
      padding: '4px 0',
      display: 'flex',
      alignItems: 'center',
      fontSize: '16px',
      lineHeight: '22px',
      letterSpacing: '0.01em',
      color: 'rgba(3,31,70,.7)',
      fontFamily: psTheme.typography.fontFamily
    },
    avatar: {
      width: '44px',
      height: '44px',
      marginRight: '12px'
    },
    ownerInfo: {
      display: 'flex',
      flexDirection: 'column'
    }
  };
});

type ProfileNameProps = {
  className?: string;
  id?: string | number;
  ownerKey?: string;
  ownerPicture?: string;
  avatarText?: string;
  children?: any;
};

const ProfileNameWithAvatarIconView: FunctionComponent<ProfileNameProps> = ({
  className = '',
  id,
  ownerKey,
  ownerPicture,
  avatarText,
  children
}) => {
  const { classes } = useStyles();
  const { dataProvider } = useContext(DataContext);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const anchorElCache = useRef<HTMLElement | null>();
  const [profile, setProfile] = useState<Profile | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const open = Boolean(anchorEl);
  const url = `/profiles/${ownerKey || id}`;

  const handlePopoverOpen = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (id && !profile && !loading) {
        setLoading(true);
        dataProvider
          .getOne<Profile>('profiles', { id })
          .then(({ data }) => {
            setProfile(data);
          })
          .catch((err) => {
            console.error(err);
          });
      }
      anchorElCache.current = event.currentTarget;
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleKeepPopoverOpen = useCallback(() => {
    if (anchorElCache.current) {
      setAnchorEl(anchorElCache.current);
    }
  }, [setAnchorEl]);

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  let profileImage = DEFAULT_IMAGE;
  if (profile && profile.files && profile.files.length) {
    profileImage = profile.files[0];
  }

  return (
    <div className={classnames({ [className]: className })}>
      <div className={classnames(classes.root, { [className]: className })}>
        <Avatar
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          className={classes.avatar}
          src={ownerPicture}
        >
          {avatarText}
        </Avatar>
        <div className={classes.ownerInfo}>
          <div>Created by</div>
          <Link to={url}>
            <a
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              className={classes.text}
            >
              <span className={classes.name}>{children}</span>
            </a>
          </Link>
        </div>
      </div>
      {profile ? (
        <Popover
          id="profile-info"
          className={classes.popover}
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          classes={{ paper: classes.paper }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          PaperProps={{
            onMouseEnter: handleKeepPopoverOpen,
            onMouseLeave: handlePopoverClose
          }}
        >
          <div className={classes.preview}>
            <div className={classes.previewLeft}>
              <AvaImage
                className={classes.previewImage}
                image={profileImage}
                imageAlt={
                  typeof children === 'string' ? children : profileImage.title
                }
              />
            </div>
            <div className={classes.previewRight}>
              <div className={classes.previewName}>{children}</div>
              <Tags className={classes.previewTags} tags={profile.tags || []} />
              <Link to={url}>
                <a className={classes.previewLink}>Learn More</a>
              </Link>
            </div>
          </div>
        </Popover>
      ) : null}
    </div>
  );
};

export const ProfileNameWithAvatarIcon = styled(ProfileNameWithAvatarIconView)(
  {}
);
