import React, { useMemo } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import TestimonialUserOne from 'assets/images/TestimonialUserOne.png';
import TestimonialUserTwo from 'assets/images/TestimonialUserTwo.png';
import { CarouselSlider } from 'components/common/CarouselSlider/CarouselSlider';
import { BackgroundFooterLandingPage } from 'components/icons/BackgroundFooterLandingPage';
import { CalendarGreenImage } from 'components/icons/CalendarGreenImage';
import { PlaybackGreenImage } from 'components/icons/PlaybackGreenImage';
import { RoadmapGreenImage } from 'components/icons/RoadmapGreenImage';
import { ShareGreenImage } from 'components/icons/ShareGreenImage';
import { UsersGreenImage } from 'components/icons/UsersGreenImage';
import { useIsMediumScreen } from 'theme';
import { Constants } from 'utilities/constants';
import {
  StyledFooterCardContainer,
  StyledFooterCardContent,
  StyledFooterCardHeader,
  StyledFooterDivider,
  StyledFooterInnovationTextContainer,
  StyledFooterInnovationTypography,
  StyledFooterMainBox,
  StyledFooterSeeContainer,
  StyledFooterSeeTypography,
  StyledFooterVideoBox,
  StyledFooterVideoContainer
} from './StyledComponents';

export const FooterSection = () => {
  const isMediumScreen = useIsMediumScreen();
  const items = useMemo(
    () => [
      {
        avatar: TestimonialUserOne,
        name: Constants.TESTIMONIAL_ONE_NAME,
        text: Constants.TESTIMONIAL_ONE_CONTENT
      },
      {
        avatar: TestimonialUserTwo,
        name: Constants.TESTIMONIAL_ONE_NAME,
        text: Constants.TESTIMONIAL_TWO_CONTENT
      },
      {
        avatar: TestimonialUserOne,
        name: Constants.TESTIMONIAL_ONE_NAME,
        text: Constants.TESTIMONIAL_THREE_CONTENT
      }
    ],
    []
  );

  return (
    <StyledFooterMainBox>
      <BackgroundFooterLandingPage />
      <StyledFooterVideoContainer>
        <StyledFooterVideoBox>
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/PYZO0V_izNw?si=lxN5w7dBbUGIqE5P"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
          ></iframe>
        </StyledFooterVideoBox>
      </StyledFooterVideoContainer>
      <Container>
        <StyledFooterInnovationTextContainer>
          <StyledFooterInnovationTypography gutterBottom>
            Innovation Powered by
          </StyledFooterInnovationTypography>
          <StyledFooterInnovationTypography gutterBottom>
            the Crowd
          </StyledFooterInnovationTypography>
        </StyledFooterInnovationTextContainer>

        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <StyledFooterCardContainer>
              <RoadmapGreenImage />
              <Box>
                <StyledFooterCardHeader>Earn Royalties</StyledFooterCardHeader>
                <StyledFooterCardContent>
                  When you create open solutions that others use in their
                  concepts, you earn crypto royalties.
                </StyledFooterCardContent>
              </Box>
            </StyledFooterCardContainer>
          </Grid>

          <Grid item xs={12} md={4}>
            <StyledFooterCardContainer>
              <UsersGreenImage />
              <Box>
                <StyledFooterCardHeader>
                  Patent Pending Assets
                </StyledFooterCardHeader>
                <StyledFooterCardContent>
                  MindMiners AI-empowered system turns your concepts into patent
                  applications.
                </StyledFooterCardContent>
              </Box>
            </StyledFooterCardContainer>
          </Grid>

          <Grid item xs={12} md={4}>
            <StyledFooterCardContainer>
              <ShareGreenImage />
              <Box>
                <StyledFooterCardHeader>
                  Tokenize Products
                </StyledFooterCardHeader>
                <StyledFooterCardContent>
                  You can use MindMiner to create a patent-pending digital asset
                  to sell rights to your inventions.
                </StyledFooterCardContent>
              </Box>
            </StyledFooterCardContainer>
          </Grid>

          <Grid item xs={12} md={4}>
            <StyledFooterCardContainer>
              <RoadmapGreenImage />
              <Box>
                <StyledFooterCardHeader>Raise Funding</StyledFooterCardHeader>
                <StyledFooterCardContent>
                  Enable staking and pre-sales to crowdfund your invention’s
                  patent-pending token.
                </StyledFooterCardContent>
              </Box>
            </StyledFooterCardContainer>
          </Grid>

          <Grid item xs={12} md={4}>
            <StyledFooterCardContainer>
              <CalendarGreenImage />
              <Box>
                <StyledFooterCardHeader>
                  Structured Brainstorming
                </StyledFooterCardHeader>
                <StyledFooterCardContent>
                  Our proprietary AI-enabled tools make innovation easier and
                  empower structured ideation.
                </StyledFooterCardContent>
              </Box>
            </StyledFooterCardContainer>
          </Grid>

          <Grid item xs={12} md={4}>
            <StyledFooterCardContainer>
              <PlaybackGreenImage />
              <Box>
                <StyledFooterCardHeader>
                  Sell Improvements
                </StyledFooterCardHeader>
                <StyledFooterCardContent>
                  Allow others to combine with their own innovation by buying
                  your patent-pending tokens.
                </StyledFooterCardContent>
              </Box>
            </StyledFooterCardContainer>
          </Grid>
        </Grid>

        {!isMediumScreen && <StyledFooterDivider />}
        <StyledFooterSeeContainer>
          <StyledFooterSeeTypography gutterBottom>
            See what others are saying
          </StyledFooterSeeTypography>
          <StyledFooterSeeTypography gutterBottom>
            about MindMiner
          </StyledFooterSeeTypography>
        </StyledFooterSeeContainer>

        <CarouselSlider items={items} />
      </Container>
    </StyledFooterMainBox>
  );
};
