/* eslint-disable */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon
} from '@mui/icons-material';
import { AccordionDetails, Box, Grid, styled, Typography } from '@mui/material';
import { TagInfo } from 'components/CardTag';
import { GraphData } from 'components/GraphDeps';
import ProfileIcons from 'components/RankingBar/ProfileIconsView/ProfileIconsView';
import UserStatsView from 'components/RankingBar/UserStatsView/UserStatsView';
import { DataContext, PsRecord } from 'contexts/DataContext';
import { Picture } from 'helpers';
import { showUserIdeaBalance } from 'helpers/blockchain';
import { GetPublicUser, GetUser } from 'redux-state/selectors';
import { useIsMediumScreen } from 'theme';
import { Constants, ERRORS } from 'utilities/constants';
import {
  GridItem,
  MainAccordionContainer,
  MainBox,
  StyledAccordionContainer,
  StyledBlock,
  StyledBox,
  ViewStatsButton
} from './StyledComponents';

export type OwnerInfo = {
  key?: string;
  username?: string;
  picture?: Picture;
};

export type Profile = PsRecord & {
  email?: string;
  employer?: string | number;
  files?: Array<Picture>;
  firstName?: string;
  instagramUrl?: string;
  inventorAddress?: string;
  inventorAddressCity?: string;
  inventorAddressState?: string;
  inventorAddressZip?: string;
  invited?: boolean;
  isOpenCtaModal?: boolean;
  key: string;
  lastName?: string;
  linkedinUrl?: string;
  location?: string;
  locationTags?: Array<string | number>;
  locationTagsInfo?: Array<TagInfo>;
  school?: string;
  schoolTags?: Array<string | number>;
  schoolTagsInfo?: Array<TagInfo>;
  share?: number;
  tags?: string[];
  tagsInfo?: Array<TagInfo>;
  termsAgree?: boolean;
  username?: string;
  workplace?: string;
  workplaceTags?: Array<string | number>;
  workplaceTagsInfo?: Array<TagInfo>;
  youtubeUrl?: string;
};

export type ProfileStatsItems = {
  inventions?: number;
  problems?: number;
  solutions?: number;
};

export type ProfileStatsRest = {
  campaigns?: number;
  claimed?: number;
  credits?: number;
  creditsProgress?: number;
  earned?: number;
  inWallet?: number;
  nft?: number;
  unrealized?: number;
};

export type ProfileStats = ProfileStatsItems & ProfileStatsRest;

export type RankingBarProps = {
  stats?: ProfileStats;
  setActiveTab?: React.Dispatch<React.SetStateAction<number>>;
  isCurrentUser?: boolean;
};

export type CustomizedStatsViewProps = {
  count: number;
  iconUrl: string;
  onWithdraw?: () => void;
  type: string;
  withdraw?: boolean;
  handleActiveTab?: () => void;
};

const RankingBarView = ({ isCurrentUser }: RankingBarProps) => {
  const user = GetUser();
  const profile = GetPublicUser();
  const { dataProvider } = useContext(DataContext);
  const [ideaCoins, setIdeaCoins] = useState<number | undefined>();
  const [contests, setContests] = useState<Array<TagInfo>>([]);
  const [graph, setGraph] = useState<GraphData | undefined>();
  const isMediumScreen = useIsMediumScreen();

  useEffect(() => {
    const fetchContests = async () => {
      try {
        const contestResponse = await dataProvider.getProfileContests<
          Array<TagInfo>
        >({
          id: isCurrentUser ? user?.id : profile?.id
        });
        setContests(contestResponse.data);
      } catch (err) {
        console.error(err);
        setContests([]);
      }
    };
    if (user || profile) {
      fetchContests();
    }
  }, [dataProvider, user?.id, profile?.id, setContests]);

  useEffect(() => {
    const fetchGraphData = async () => {
      try {
        const graphResponse = await dataProvider.getGraph<GraphData>(
          Constants.PROFILES,
          isCurrentUser
            ? (user?.id ?? user?._id)
            : (profile?.id ?? profile?._id),
          Constants.PROFILE
        );
        setGraph(graphResponse);
      } catch (err) {
        console.error(err);
        setGraph(undefined);
      }
    };
    if (user || profile) {
      fetchGraphData();
    }
  }, [dataProvider, user?.id, profile?.id, setGraph]);

  useEffect(() => {
    const fetchIdeaCoins = async () => {
      if (user) {
        try {
          const balance: number = await showUserIdeaBalance(user.walletAddress);
          setIdeaCoins(balance);
        } catch (error) {
          console.error(ERRORS.GET_IDEA_COINS, error.message);
        }
      }
    };
    fetchIdeaCoins();
  }, [user]);

  const [accordionExpanded, setAccordionExpanded] = useState<boolean>(false);

  const handleAccordionChange = () => {
    setAccordionExpanded(!accordionExpanded);
  };

  const handleButtonClick = () => {
    setAccordionExpanded(!accordionExpanded);
  };

  const StatView = useMemo(() => {
    return (
      <>
        <StyledBlock>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <UserStatsView />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <ProfileIcons />
            </Grid>
          </Grid>
        </StyledBlock>
      </>
    );
  }, []);

  return (
    <>
      <MainBox display={isMediumScreen ? 'flex' : null}>
        <Box>
          {isMediumScreen ? (
            <MainAccordionContainer>
              <StyledAccordionContainer
                expanded={accordionExpanded}
                onChange={handleAccordionChange}
              >
                <AccordionDetails>
                  <Grid container spacing={3}>
                    <GridItem item xs={12} md={6} lg={4}>
                      {StatView}
                    </GridItem>
                  </Grid>
                </AccordionDetails>
              </StyledAccordionContainer>
            </MainAccordionContainer>
          ) : (
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                  {StatView}
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
        <Box>
          {isMediumScreen && (
            <ViewStatsButton onClick={handleButtonClick}>
              <StyledBox>
                <Typography sx={{ textTransform: 'none' }}>
                  {accordionExpanded
                    ? Constants.HIDE_STATS
                    : Constants.SHOW_STATS}
                </Typography>
                {accordionExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </StyledBox>
            </ViewStatsButton>
          )}
        </Box>
      </MainBox>
    </>
  );
};

export const RankingBar = styled(RankingBarView)({});

export default RankingBar;
