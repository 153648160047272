import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { CircularProgress } from '@mui/material';
import { BaseModal } from 'modals/Common/BaseModal';
import { useDispatch } from 'react-redux';
import Actions from 'redux-state/actions';
import { GetNftDeployLoader } from 'redux-state/selectors';
import { colorPalette } from 'theme';
import {
  ASSET_TYPES,
  Breakpoints,
  Constants,
  TRANSACTION_TYPE,
  VARIANT
} from 'utilities/constants';
import { ContentSection } from '../Common/ContentSection';
import { HeaderSection } from '../Common/HeaderSection';
import {
  BorderBox,
  ButtonBox,
  DescrText,
  HeadingText,
  MessageText,
  StyledButton,
  Title,
  TransferAmount,
  ValueText
} from './styledComponents';

interface TxApprovalModalProps {
  amount?: number;
  destAddress?: string;
  from?: string;
  gasFee?: string;
  onConfirm?: (transactionHash?: string, tokenId?: string) => void;
  onReject?: () => void;
  open?: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  transactionType?: string;
  type: string;
}

export const TxApprovalModal: React.FC<TxApprovalModalProps> = ({
  open,
  amount,
  destAddress,
  from,
  gasFee,
  onConfirm,
  onReject,
  transactionType = TRANSACTION_TYPE.SEND,
  type
}) => {
  const nftDeployLoader = GetNftDeployLoader();
  const dispatch = useDispatch();

  const messages = useMemo(
    () => ({
      [`${ASSET_TYPES.IDEACOINS}-${TRANSACTION_TYPE.APPROVAL}`]: `for the approval transaction`,
      [`${ASSET_TYPES.IDEACOINS}-${TRANSACTION_TYPE.SEND}`]: `for the TransferFrom transaction`,
      [`${ASSET_TYPES.MATIC}-${TRANSACTION_TYPE.SEND}`]: `to complete this transaction`,
      [`${ASSET_TYPES.NFT}-${TRANSACTION_TYPE.SEND}`]: `to transform this patent into an NFT`
    }),
    []
  );

  const handleClose = useCallback(() => {
    if (nftDeployLoader) return;
    dispatch(Actions.setTokenURI({ tokenURI: '' }));
    dispatch(
      Actions.openTxApprovalModal({
        txApprovalModalObj: {
          type: '',
          open: false,
          gasFee: 0
        }
      })
    );
    onReject?.();
  }, [nftDeployLoader, dispatch, onReject]);

  const GasFeeView = useMemo(() => {
    return (
      <>
        <BorderBox>
          <DescrText color={colorPalette.lightBlack} fontWeight={'600'}>
            {Constants.ESTIMATED_GAS_FEE}
          </DescrText>
          <ValueText>{gasFee}</ValueText>
        </BorderBox>
        <MessageText>{`You need ${gasFee} more ${Constants.MATIC} ${
          messages[`${type}-${transactionType}`]
        }`}</MessageText>
      </>
    );
  }, [gasFee, type, transactionType, messages]);

  const AddressField = useCallback(
    ({ label, address }: { label: string; address: string }) => (
      <>
        <HeadingText>{label}</HeadingText>
        <BorderBox shadow>
          <DescrText>{address}</DescrText>
        </BorderBox>
      </>
    ),
    []
  );

  const onConfirmClick = useCallback(async () => onConfirm(), [onConfirm]);

  return (
    <BaseModal
      open={open}
      onClose={handleClose}
      maxWidth={Breakpoints.EXTRA_SMALL}
    >
      <HeaderSection>
        <Title>{type == ASSET_TYPES.NFT ? Constants.MINT_NFT : type}</Title>
      </HeaderSection>
      <ContentSection>
        {type != ASSET_TYPES.NFT && (
          <>
            <AddressField label={Constants.FROM} address={from} />
            <AddressField label={Constants.TO} address={destAddress} />
            <TransferAmount>{`${amount} ${type}`}</TransferAmount>
          </>
        )}
        {GasFeeView}
        <ButtonBox>
          <StyledButton variant={VARIANT.OUTLINED} onClick={handleClose}>
            {Constants.REJECT}
          </StyledButton>
          <StyledButton variant={VARIANT.CONTAINED} onClick={onConfirmClick}>
            {nftDeployLoader ? (
              <CircularProgress size={20} sx={{ color: colorPalette.white }} />
            ) : transactionType == TRANSACTION_TYPE.APPROVAL ? (
              Constants.APPROVE
            ) : (
              Constants.CONFIRM
            )}
          </StyledButton>
        </ButtonBox>
      </ContentSection>
    </BaseModal>
  );
};
