import React, { FC, useCallback } from 'react';
import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import dataProvider from 'dataPrvider';
import { Constants } from 'utilities/constants';
import { GetUser } from 'redux-state/selectors';
import useRouter from 'hooks/useRouter';
import { AddToConceptRectangle } from 'components/icons/AddToConceptRectangle';
import { ContestWinRectangle } from 'components/icons/ContestWinRectangle';
import { FollowIconRectangle } from 'components/icons/FollowIconRectangle';
import { ImproveCounterRectangle } from 'components/icons/ImproveCounterRectangle';
import { SolveCounterRectangle } from 'components/icons/SolveCounterRectangle';
import { ShareIconRectangle } from 'components/icons/ShareIconRectangle';
import { VoteIconRectangle } from 'components/icons/VoteIconRectangle';

const contentMapper = {
  [Constants.PRODUCTS]: [
    {
      icon: <ImproveCounterRectangle />,
      text: Constants.PRODUCT_IMPROVE
    },
    {
      icon: <VoteIconRectangle />,
      text: Constants.VOTE_CTA
    },
    { icon: <ShareIconRectangle />, text: Constants.SHARE_CTA }
  ],
  [Constants.SOLUTIONS]: [
    {
      icon: <ImproveCounterRectangle />,
      text: Constants.IMPROVE_SOLUTION
    },
    {
      icon: <AddToConceptRectangle />,
      text: Constants.SOLUTION_ADD_TO_CONCEPT
    },
    {
      icon: <VoteIconRectangle />,
      text: Constants.VOTE_CTA
    },
    { icon: <ShareIconRectangle />, text: Constants.SHARE_CTA }
  ],
  [Constants.PROBLEMS]: [
    {
      icon: <SolveCounterRectangle />,
      text: Constants.PROBLEM_SOLVE
    },
    {
      icon: <VoteIconRectangle />,
      text: Constants.VOTE_CTA
    },
    { icon: <ShareIconRectangle />, text: Constants.SHARE_CTA }
  ],
  [Constants.INVENTIONS]: [
    {
      icon: <ImproveCounterRectangle />,
      text: Constants.INVENTION_IMPROVE
    },
    {
      icon: <ImproveCounterRectangle />,
      text: Constants.INVENTION_SELL_PURCHASE
    },
    {
      icon: <ImproveCounterRectangle />,
      text: Constants.INVENTION_STAKE
    },
    {
      icon: <VoteIconRectangle />,
      text: Constants.VOTE_CTA
    },
    { icon: <ShareIconRectangle />, text: Constants.SHARE_CTA }
  ],
  [Constants.CONTESTS]: [
    {
      icon: <ContestWinRectangle />,
      text: Constants.CONTEST_WIN
    },
    {
      icon: <VoteIconRectangle />,
      text: Constants.VOTE_CTA
    },
    { icon: <ShareIconRectangle />, text: Constants.SHARE_CTA }
  ],
  [Constants.COMMUNITIES]: [
    {
      icon: <FollowIconRectangle />,
      text: Constants.COMMUNITY_FOLLOW_CTA
    },
    { icon: <ShareIconRectangle />, text: Constants.SHARE_CTA }
  ]
};

export const Content: FC = () => {
  const router = useRouter();
  const { showModal } = router.query;
  const user = GetUser();
  const contentItems =
    typeof showModal === 'string' ? contentMapper[showModal] : undefined;

  const handleCheckboxChange = useCallback(
    async (event) => {
      const isOpenCtaModal = event.target.checked;
      try {
        await dataProvider.updateCtaModal({
          userId: user.id,
          isOpenCtaModal
        });
      } catch (error) {
        console.log('Error occured:', error);
      }
    },
    [user.id]
  );
  return (
    <Grid container sx={{ alignItems: 'center' }}>
      {contentItems?.map((item) => (
        <>
          <Grid item xs={12} md={4}>
            {item.icon}
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography>{item.text}</Typography>
          </Grid>
        </>
      ))}
      <Grid item xs={12} md={12}>
        <FormControlLabel
          control={<Checkbox name={Constants.DONT_SHOW_MODAL} />}
          label={Constants.DONT_SHOW_MODAL}
          labelPlacement={Constants.END}
          onChange={handleCheckboxChange}
        />
      </Grid>
    </Grid>
  );
};
