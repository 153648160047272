import React from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { ProfileNameWithAvatarIcon } from 'components/CardProfile/ProfileNameWithAvatarIcon';
import { PsTheme } from '../../theme';
import classnames from 'classnames';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      padding: '4px 0',
      display: 'flex',
      alignItems: 'center',
      fontSize: '16px',
      lineHeight: '22px',
      letterSpacing: '0.01em',
      color: 'rgba(3,31,70,.7)',
      fontFamily: psTheme.typography.fontFamily
    },
    avatar: {
      width: '44px',
      height: '44px',
      marginRight: '12px'
    },
    ownerInfo: {
      display: 'flex',
      flexDirection: 'column'
    }
  };
});

type CardOwnerProps = {
  className?: string;
  owner?: string | number;
  ownerKey?: string;
  ownerName?: string;
  ownerPicture?: string;
  createdAt?: string;
  big?: boolean;
};

const CardOwnerView = ({
  className = '',
  owner,
  ownerKey,
  ownerName,
  ownerPicture
}: CardOwnerProps) => {
  const { classes } = useStyles();
  if (!ownerName) {
    return null;
  }

  const ownerNameFirstLetter = ownerName.substring(0, 1).toUpperCase();

  return (
    <div className={classnames(classes.root, { [className]: className })}>
      <ProfileNameWithAvatarIcon
        id={owner}
        ownerKey={ownerKey}
        avatarText={ownerNameFirstLetter}
        ownerPicture={ownerPicture}
      >
        {ownerName}
      </ProfileNameWithAvatarIcon>
    </div>
  );
};

export const CardOwner = styled(CardOwnerView)({});
