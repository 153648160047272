import { Loop } from '@mui/icons-material';
import { Box, styled, TextField, Typography } from '@mui/material';
import PsButton from 'components/common/PsButton';
import { colorPalette } from 'theme';

export const NameFieldMainBox = styled(Box)({
  alignItems: 'flex-end',
  display: 'flex',
  flexDirection: 'row'
});

export const DescriptionBox = styled(Box)({
  width: '50%'
});

export const ImageMainBox = styled(Box)({
  marginLeft: '2rem',
  width: '50%'
});

export const ImageBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  justifyContent: 'center',
  width: '100%'
});

export const ImageIconsBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '1rem',
  marginTop: '0.9375rem'
});

export const DetailsBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%'
});

export const DetailsMainBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: '1.5rem',
  marginTop: '1.5rem'
});

export const AiIconBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  height: '56px',
  justifyContent: 'center',
  marginLeft: '1rem',
  width: '48px'
});

export const NameFieldBox = styled(Box)({
  width: '100%'
});

export const FeatureImage = styled('img')({
  borderRadius: '6px',
  height: '171px',
  objectFit: 'cover',
  overflow: 'hidden',
  width: '100%'
});

export const StyledBodyFieldText = styled(TextField)({
  borderRadius: '8px',
  width: '100%'
});

export const StyledIconButton = styled(PsButton)({
  boxShadow: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid',
  borderColor: colorPalette.purple,
  borderRadius: '6px',
  backgroundColor: 'transparent',
  height: '48px',
  width: '48px',
  marginBottom: '1rem',
  '&:hover': {
    backgroundColor: colorPalette.transparent,
    borderColor: colorPalette.purple,
    boxShadow: `0px 0px 10px 0px ${colorPalette.purple}65`
  }
});

export const StyledBodyTypography = styled(Typography)({
  color: `${colorPalette.dimBlack} !important`,
  fontSize: '15px !important',
  fontWeight: '500 !important',
  paddingBottom: '3px'
});

export const StyledUploadButtonContainer = styled(Box)({
  alignSelf: 'center',
  display: 'grid',
  margin: 'auto'
});

export const StyledImageText = styled(Typography)({
  color: colorPalette.spanishGray,
  fontSize: '1rem',
  fontWeight: 500
});

export const StyledLoopIcon = styled(Loop)<{ loading: boolean }>(
  ({ loading }) => ({
    color: colorPalette.purple,
    animation: loading ? 'rotate 1s linear infinite' : 'none',
    '@keyframes rotate': {
      from: {
        transform: 'rotate(360deg)'
      },
      to: {
        transform: 'rotate(0deg)'
      }
    }
  })
);
