import React from 'react';
import {
  ActionFunction,
  LoaderFunction,
  ShouldRevalidateFunction
} from 'react-router-dom';

import Layout from './layout';

import Activities from './pages/activity';
import ChallengeList from './pages/challenges';
import Challenge from './pages/challenges/[key]';
import Community from './pages/community';
import ContestList from './pages/contests';
import Contest from './pages/contests/[key]';
import ErrorElement from './pages/error';
import Home from './pages/home';
import InventionList from './pages/inventions';
import Invention from './pages/inventions/[key]';
import { LandingPage } from './pages/landingPage';
import { LinkedAccounts } from './pages/linkedAccounts';
import NewIdeaMap from './pages/newIdeaMap';
import PriorArts from './pages/prior-arts';
import { PrivacyPolicy } from './pages/privacy';
import ProblemList from './pages/problems';
import Problem from './pages/problems/[key]';
import ProductList from './pages/products';
import Product from './pages/products/[key]';
import Profile from './pages/profiles';
import SolutionList from './pages/solutions';
import Solution from './pages/solutions/[key]';
import Tag from './pages/tags/[key]';
import { Terms } from './pages/terms';

export enum RouteID {
  Activities = 'activities',
  Challenge = 'challenge',
  Challenges = 'challenges',
  Communities = 'communities',
  Contest = 'contest',
  Contests = 'contests',
  Error = 'error',
  Home = 'home',
  Invention = 'invention',
  Inventions = 'inventions',
  LandingPage = 'landingPage',
  LinkedAccounts = 'linkedaccounts',
  NewContests = 'newContests',
  NewIdeaMap = 'newIdeaMap',
  PriorArt = 'priorart',
  PriorArts = 'priorarts',
  Privacy = 'privacy',
  Problem = 'problem',
  Problems = 'problems',
  Product = 'product',
  Products = 'products',
  Profiles = 'profiles',
  Solution = 'solution',
  Solutions = 'solutions',
  Tag = 'tags',
  Terms = 'terms'
}

// RouteConfig is an explicit interface defined to be a superset of RouteObject from react-router-dom.
interface RouteConfig {
  caseSensitive?: boolean;
  path?: string;
  id?: string;
  loader?: LoaderFunction;
  action?: ActionFunction;
  hasErrorBoundary?: boolean;
  shouldRevalidate?: ShouldRevalidateFunction;
  handle?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  index?: boolean;
  children?: RouteConfig[];
  element?: React.ReactNode | null;
  errorElement?: React.ReactNode | null;
  key?: string;
}

export const AppRoutes: RouteConfig[] = [
  {
    path: '/',
    element: <LandingPage />,
    index: true,
    key: RouteID.LandingPage,
    errorElement: <ErrorElement />
  },
  {
    element: <Layout />,
    errorElement: <ErrorElement />,
    children: [
      {
        path: 'feeds',
        element: <Home />,
        key: RouteID.Home,
        errorElement: <ErrorElement />
      },
      {
        path: 'problems/:key',
        key: RouteID.Problem,
        element: <Problem />,
        errorElement: <ErrorElement />
      },
      {
        path: 'linkedaccounts',
        key: RouteID.LinkedAccounts,
        element: <LinkedAccounts />,
        errorElement: <ErrorElement />
      },
      {
        path: 'problems',
        key: RouteID.Problems,
        element: <ProblemList />,
        errorElement: <ErrorElement />
      },
      {
        path: 'solutions/:key',
        key: RouteID.Solution,
        element: <Solution />,
        errorElement: <ErrorElement />
      },
      {
        path: 'solutions',
        key: RouteID.Solutions,
        element: <SolutionList />,
        errorElement: <ErrorElement />
      },
      {
        path: 'products/:key',
        key: RouteID.Product,
        element: <Product />,
        errorElement: <ErrorElement />
      },
      {
        path: 'products',
        key: RouteID.Products,
        element: <ProductList />,
        errorElement: <ErrorElement />
      },
      {
        path: 'contests/:key',
        key: RouteID.Contest,
        element: <Contest />,
        errorElement: <ErrorElement />
      },
      {
        path: 'contests',
        key: RouteID.Contests,
        element: <ContestList />,
        errorElement: <ErrorElement />
      },
      {
        path: 'challenges/:key',
        key: RouteID.Challenge,
        element: <Challenge />,
        errorElement: <ErrorElement />
      },
      {
        path: 'challenges',
        key: RouteID.Challenges,
        element: <ChallengeList />,
        errorElement: <ErrorElement />
      },
      {
        path: 'inventions/:key',
        key: RouteID.Invention,
        element: <Invention />,
        errorElement: <ErrorElement />
      },
      {
        path: 'inventions',
        key: RouteID.Inventions,
        element: <InventionList />,
        errorElement: <ErrorElement />
      },
      {
        path: 'activites',
        key: RouteID.Activities,
        element: <Activities />,
        errorElement: <ErrorElement />
      },
      {
        path: 'priorarts',
        key: RouteID.PriorArts,
        element: <PriorArts />,
        errorElement: <ErrorElement />
      },
      {
        path: 'profiles/:id',
        key: RouteID.Profiles,
        element: <Profile />,
        errorElement: <ErrorElement />
      },
      {
        path: 'tags/:key',
        key: RouteID.Tag,
        element: <Community />,
        errorElement: <ErrorElement />
      },
      {
        path: 'newIdeaMap/:id',
        key: RouteID.NewIdeaMap,
        element: <NewIdeaMap />
      },
      {
        path: 'communities/:key',
        key: RouteID.Communities,
        element: <Community />,
        errorElement: <ErrorElement />
      },
      {
        path: 'terms',
        key: RouteID.Terms,
        element: <Terms />,
        errorElement: <ErrorElement />
      },
      {
        path: 'privacy-policy',
        key: RouteID.Privacy,
        element: <PrivacyPolicy />,
        errorElement: <ErrorElement />
      },
      {
        path: 'error',
        key: RouteID.Error,
        element: <ErrorElement />
      }
    ]
  },
  {
    path: '*',
    element: <ErrorElement />,
    errorElement: <ErrorElement />,
    key: RouteID.Error
  }
];

const _getRoutePathById = (
  routeCfg: RouteConfig,
  acc: string,
  id: RouteID
): string | null => {
  const rawCurrentPath = !routeCfg.path
    ? acc
    : routeCfg.path[0] === '/' || acc[acc.length - 1] === '/'
      ? `${acc}${routeCfg?.path}`
      : `${acc}/${routeCfg?.path}`;
  const currentPath = rawCurrentPath.replace('//', '/');

  if (routeCfg?.key && routeCfg?.key === id) {
    return currentPath;
  }
  if (routeCfg?.children) {
    for (const routeChild of routeCfg.children) {
      const path = _getRoutePathById(routeChild, currentPath, id);
      if (path) {
        return path;
      }
    }
  }
  return null;
};

export const getRoutePathById = (() => {
  const memo: { [key in RouteID]?: string } = {};

  return (id: RouteID): string => {
    const cachedRoute = memo[id];
    if (cachedRoute) {
      return cachedRoute;
    }

    for (const routeCfg of AppRoutes) {
      const path = _getRoutePathById(routeCfg, '', id);
      if (path) {
        return path;
      }
    }
    throw new Error('path cannot be found');
  };
})();
