import React, { useContext, useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { Tab, Tabs, TabContent } from 'components/Tabs';
import { Contest, CardContestSmall } from 'components/CardContest';
import { DataContext } from 'contexts/DataContext';
import { PsTheme } from '../../theme';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      marginBottom: 20
    }
  };
});

export type TagContestListProps = {
  className?: string;
  tag: string | number;
};

const TagContestListView = ({ className, tag }: TagContestListProps) => {
  const { dataProvider, contestsListCache } = useContext(DataContext);
  const [pending, setPending] = useState<Array<Contest>>([]);
  const [active, setActive] = useState<Array<Contest>>([]);
  const [passed, setPassed] = useState<Array<Contest>>([]);
  const [tagTab, setTagTab] = useState('');
  const { classes } = useStyles();
  useEffect(() => {
    dataProvider
      .getList<Contest>('contests', {
        pagination: { page: 1, perPage: 5 },
        sort: { field: 'createdAt', order: 'DESC' },
        filter: { tag }
      })
      .then(({ data, total }) => {
        const pending: Array<Contest> = [];
        const active: Array<Contest> = [];
        const passed: Array<Contest> = [];
        const now = new Date();
        data.forEach((item) => {
          const start = new Date(item.startDate);
          const end = new Date(item.endDate);
          if (now < start) {
            // not started
            pending.push(item);
          } else if (now < end) {
            // active
            active.push(item);
          } else {
            // passed
            passed.push(item);
          }
        });
        setPending(pending);
        setActive(active);
        setPassed(passed);
        if (passed.length) {
          setTagTab('Previous Contests');
        }
        if (pending.length) {
          setTagTab('Pending Contests');
        }
        if (active.length) {
          setTagTab('Active Contests');
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [contestsListCache]);

  let rootClassName = classes.root;
  if (className) {
    rootClassName += ' ' + className;
  }

  return (
    <div className={rootClassName}>
      <Tabs>
        {active && active.length ? (
          <Tab label="Active Contests" value={tagTab} setValue={setTagTab} />
        ) : null}
        {pending && pending.length ? (
          <Tab label="Pending Contests" value={tagTab} setValue={setTagTab} />
        ) : null}
        {passed && passed.length ? (
          <Tab label="Previous Contests" value={tagTab} setValue={setTagTab} />
        ) : null}
      </Tabs>

      <TabContent label="Active Contests" value={tagTab}>
        {active && active.length
          ? active.map((item) => (
              <CardContestSmall key={item.id} contest={item} type="active" />
            ))
          : null}
      </TabContent>
      <TabContent label="Pending Contests" value={tagTab}>
        {pending && pending.length
          ? pending.map((item) => (
              <CardContestSmall key={item.id} contest={item} />
            ))
          : null}
      </TabContent>
      <TabContent label="Previous Contests" value={tagTab}>
        {passed && passed.length
          ? passed.map((item) => (
              <CardContestSmall key={item.id} contest={item} />
            ))
          : null}
      </TabContent>
    </div>
  );
};

export const TagContestList = styled(TagContestListView)({});

export default TagContestList;
