import React, { Dispatch, SetStateAction } from 'react';
import SubTypes from './SubTypes';
import Types from './Types';
import {
  StyledContainer,
  StyledSubContainer
} from 'modals/ImproveModal/StyledComponents';
import { Constants } from 'utilities/constants';

interface Step1Props {
  handleNext: () => void;
  setSubtype: Dispatch<SetStateAction<string>>;
  setType: Dispatch<SetStateAction<string>>;
  type: string;
}

const reduceOptions = [
  { title: 'Complexity', imagePath: '/icons/complexity.svg' },
  { title: 'Precision', imagePath: '/icons/precision.svg' },
  { title: 'Immaturity', imagePath: '/icons/immaturity.svg' },
  { title: 'Danger', imagePath: '/icons/danger.svg' },
  { title: 'Needed Skill', imagePath: '/icons/needed_skill.svg' },
  { title: 'Variability', imagePath: '/icons/variability.svg' },
  { title: 'Sensitivity', imagePath: '/icons/sensitivity.svg' }
];

const increaseOptions = [
  { title: 'Performability', imagePath: '/icons/performability.svg' },
  { title: 'Usability', imagePath: '/icons/usability.svg' },
  { title: 'Affordability', imagePath: '/icons/affordability.svg' },
  { title: 'Maintainability', imagePath: '/icons/maintainability.svg' },
  { title: 'Featureability', imagePath: '/icons/featureability.svg' },
  { title: 'Durability', imagePath: '/icons/durability.svg' },
  { title: 'Deliverability', imagePath: '/icons/deliverability.svg' },
  { title: 'Imageability', imagePath: '/icons/imageability.svg' }
];

const Step1: React.FC<Step1Props> = ({
  handleNext,
  setSubtype,
  setType,
  type
}) => {
  const selectSubType = (subType: string) => () => {
    setSubtype(subType);
    handleNext();
  };

  return (
    <StyledContainer>
      <Types selectedEntity={type} setSelectedEntity={setType} />
      <StyledSubContainer>
        {(type === Constants.REDUCE ? reduceOptions : increaseOptions).map(
          (item) => (
            <SubTypes
              coin="1.8"
              imagePath={item.imagePath}
              key={item.title}
              onClick={selectSubType(item.title)}
              title={item.title}
            />
          )
        )}
      </StyledSubContainer>
    </StyledContainer>
  );
};

export default Step1;
