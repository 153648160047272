import React, { useCallback, useEffect, useState } from 'react';
import { Box, Typography, styled } from '@mui/material';
import SolutionsIcon from 'assets/icons/solutions.svg';
import ProblemsIcon from 'assets/icons/problems.svg';
import Config from 'config/config';
import { Problem } from '../CardProblem';
import { Solution } from '../CardSolution';
import { colorPalette } from '../../theme';
import useRouter from 'hooks/useRouter';
import { Constants } from 'utilities/constants';

interface ListItemViewProps {
  problems?: Array<Problem>;
  solutions?: Array<Solution>;
  type?: 'problems' | 'solutions';
  id?: string | number;
  expandedId?: string;
  setExpandedId?: React.Dispatch<React.SetStateAction<boolean>>;
  boxWidth?: number;
  useSameTab?: boolean;
  textWidth?: number;
}

interface ItemProps {
  item: Problem | Solution;
  icon: string;
}

const ListItemView: React.FC<ListItemViewProps> = ({
  expandedId,
  id,
  problems = [],
  setExpandedId,
  solutions = [],
  type = '',
  boxWidth,
  useSameTab = false,
  textWidth
}) => {
  const [totalItems, setTotalItems] = useState(0);
  const router = useRouter();
  const clientUrl = Config.NEXT_PUBLIC_CLIENT_URL;

  useEffect(() => {
    const total = (solutions?.length ?? 0) + (problems?.length ?? 0);
    setTotalItems(total);
  }, [solutions, problems, setExpandedId]);

  const handleClick = useCallback(
    (key) => {
      const basePath = `${type}/${key}`;
      if (useSameTab) {
        router.push(`/${basePath}`);
      } else {
        const url = `${clientUrl}${basePath}`;
        window.open(url, Constants._BLANK);
      }
    },
    [clientUrl, router, type, useSameTab]
  );

  const Item: React.FC<ItemProps> = ({ item, icon }) => {
    return (
      <StyledAlignedBox width={boxWidth} onClick={() => handleClick(item.key)}>
        <StyledIcon alt="question mark" src={icon} />
        <StyledReadMore
          lineHeight={1.5}
          marginLeft={1}
          marginRight={1}
          marginTop={-0.2}
          noWrap
          overflow="hidden"
          textOverflow="ellipsis"
          variant="body2"
          width={textWidth}
          sx={{
            textDecoration: 'underline',
            textDecorationColor: colorPalette.purple
          }}
        >
          {item?.teaser}
        </StyledReadMore>
      </StyledAlignedBox>
    );
  };

  const SliceView = ({
    items = [],
    icon
  }: {
    items: (Problem | Solution)[];
    icon: string;
  }) => {
    return (
      <>
        {items
          .slice(0, 1)
          ?.map((item, index) => (
            <Item icon={icon} item={item} key={`solution-${index}`} />
          ))}
      </>
    );
  };

  const MapView = ({
    items = [],
    icon
  }: {
    items: (Problem | Solution)[];
    icon: string;
  }) => {
    return (
      <>
        {items?.map((item, index) => (
          <Item icon={icon} item={item} key={`solution-${index}`} />
        ))}
      </>
    );
  };

  return (
    <StyledBox>
      {totalItems > 1 && expandedId != id ? (
        <>
          <SliceView items={solutions} icon={SolutionsIcon} />
          <SliceView items={problems} icon={ProblemsIcon} />
        </>
      ) : (
        <>
          <MapView items={solutions} icon={SolutionsIcon} />
          <MapView items={problems} icon={ProblemsIcon} />
        </>
      )}
    </StyledBox>
  );
};

const StyledReadMore = styled(Typography)({
  marginLeft: '7px',
  fontSize: '13px',
  textDecoration: 'none',
  fontWeight: 'bold',
  cursor: 'pointer',
  color: colorPalette.purple,
  '@media (max-width: 576px)': {
    fontSize: '12px'
  }
});

const StyledAlignedBox = styled(Box)({
  alignItems: 'center',
  display: 'flex'
});

const StyledBox = styled(Box)({
  minWidth: '95px !important',
  marginTop: '17px'
});

const StyledIcon = styled('img')({
  height: '15px',
  width: '15px'
});

export { ListItemView };
