import React, { ReactNode, useCallback, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import dataProvider from 'dataPrvider';
import { debounce } from 'lodash';
import PsFormControl, { ShortEvent } from 'components/common/PsFormControl';
import {
  StyledBodyTypography,
  StyledDescriptionField
} from './StyledComponents';
import { useDispatch } from 'react-redux';
import Actions from 'redux-state/actions';
import { Constants } from 'utilities/constants';

import { GetGeneratedDescription } from 'redux-state/selectors';

export interface OptionType {
  label: string;
  raw?: string;
  value: number | string;
}

interface DescriptionFieldProps {
  description?: string;
  exampleText?: ReactNode | string;
  label?: string;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  title?: string;
  parentNode?: OptionType | null;
  type?: string;
}

export const DescriptionField: React.FC<DescriptionFieldProps> = ({
  description,
  exampleText,
  label,
  setDescription,
  title,
  parentNode,
  type
}) => {
  const dispatch = useDispatch();
  const generatedDescription = GetGeneratedDescription();

  useEffect(() => {
    if (generatedDescription) {
      setDescription(generatedDescription);
    }
  }, [generatedDescription]);

  const onDescrChange = useCallback(
    (e: ShortEvent) => {
      setDescription(e.target.value);
    },
    [setDescription]
  );

  const triggerSearch = (title, parentNode) => {
    if (!title?.trim() || !parentNode?.label) return;

    const infoMap = {
      [Constants.PROBLEMS]: 'parentProduct',
      [Constants.SOLUTIONS]: 'parentProblem',
      [Constants.PRODUCTS]: 'companyName'
    };

    const infoKey = infoMap[type];

    const additionalInfo = infoKey ? { [infoKey]: parentNode?.label } : {};

    dispatch(
      Actions.generateDescriptionFromAI({
        title,
        type,
        additionalInfo
      })
    );
  };

  const debouncedHandleInput = useCallback(
    debounce((value, parentNode) => {
      triggerSearch(value, parentNode);
    }, 2000),
    [title, parentNode]
  );

  useEffect(() => {
    if (title) {
      debouncedHandleInput(title, parentNode);
    }
    return () => {
      debouncedHandleInput.cancel();
    };
  }, [title, parentNode, debouncedHandleInput]);

  return (
    <PsFormControl label={label} required>
      <StyledDescriptionField
        rows={6}
        multiline
        value={description}
        onChange={onDescrChange}
      />
      {exampleText && <Typography>{exampleText}</Typography>}
    </PsFormControl>
  );
};
