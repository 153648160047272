import React from 'react';
import { Box } from '@mui/material';
import { colorPalette } from 'theme';
import { Constants } from 'utilities/constants';
import { GreenCounter } from './GreenCounter';
import {
  StyledCreateButton,
  StyledCreateButtonTypography
} from './StyledComponents';

interface CounterButtonProps {
  disabled?: boolean;
  handleClick?: () => void;
  isCreateButton?: boolean;
  text?: string;
}

const CounterButton: React.FC<CounterButtonProps> = ({
  handleClick,
  isCreateButton,
  text,
  disabled
}) => {
  const counterColor = disabled ? colorPalette.grey : colorPalette.purple;
  return (
    <Box>
      <StyledCreateButton
        isCreateButton={isCreateButton}
        onClick={handleClick}
        variant="contained"
        disabled={disabled}
      >
        <GreenCounter
          bgColor={counterColor}
          coinColor={colorPalette.white}
          coins={'+1'}
          height={Constants.LARGE_33}
          textColor={colorPalette.white}
          width={Constants.LARGE_33}
        />
        ,<StyledCreateButtonTypography>{text}</StyledCreateButtonTypography>
      </StyledCreateButton>
    </Box>
  );
};

export default CounterButton;
