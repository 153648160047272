import React from 'react';
import { Box, Grid } from '@mui/material';
import { colorPalette } from 'theme';
import { TagInfo } from 'components/CardTag';
import { getBasePath } from 'helpers/common';
import { Constants } from 'utilities/constants';
import { Node } from 'interface/common';
import { ChipList } from 'components/Tag';
import { LogoMindminer } from 'components/icons/LogoMindminer';
import Footer from '../Footer';
import {
  ContentContainer,
  HeadingVotePanelContainer,
  IdeaIconContainer,
  StyledHeading,
  StyledTextContentWrapper,
  StyledSubContentWrapper,
  StyledTextWrapper,
  StyledTypography,
  TextContainer
} from '../StyledComponents';

interface ProductContentProps {
  displayedTags?: Array<TagInfo>;
  item: Node;
  onShowAllTagsClick?: () => void;
  showAllTags?: boolean;
  votingPanel?: React.ReactNode;
}

export const ProductContent: React.FC<ProductContentProps> = ({
  displayedTags,
  item,
  onShowAllTagsClick,
  showAllTags,
  votingPanel
}) => {
  const { title, body, ideaPoints, type } = item;
  const relatedProblems = item.children.map((child) => ({
    childName: child.teaser || child.shortTitle
  }));

  return (
    <ContentContainer>
      <Grid md={12} sm={12}>
        <HeadingVotePanelContainer>
          <StyledTypography type={type}>
            {Constants.PRODUCT_HEADING}:
          </StyledTypography>
          <Grid>{votingPanel}</Grid>
        </HeadingVotePanelContainer>
        <StyledTextWrapper>{title}</StyledTextWrapper>
        <ChipList
          list={displayedTags}
          max={5}
          showAll={showAllTags}
          onShowAllTagsClick={onShowAllTagsClick}
          getName={(probTag) => {
            const name = (probTag as TagInfo).name || '';
            return name;
          }}
          getUrl={(probTag) => {
            const basePath = getBasePath(probTag);
            return `/${basePath}/${
              (probTag as TagInfo).key || (probTag as TagInfo).id
            }`;
          }}
        />
        <StyledHeading>{Constants.RELATED_PROBLEMS}:</StyledHeading>
        <StyledSubContentWrapper>
          {relatedProblems.map((problem) => (
            <Box key={problem.childName}>{problem.childName}</Box>
          ))}
        </StyledSubContentWrapper>
        <StyledHeading>{Constants.C_CONTESTS}:</StyledHeading>
        <StyledSubContentWrapper>
          {Constants.PRODUCT_CONTEST}
        </StyledSubContentWrapper>
        <StyledHeading>{Constants.DESCRIPTION}:</StyledHeading>
        <StyledSubContentWrapper>{body}</StyledSubContentWrapper>
        <StyledHeading>{Constants.TOTAL_EARNINGS}:</StyledHeading>
        <TextContainer>
          <IdeaIconContainer>
            <LogoMindminer fill={colorPalette.purple} width={36} height={36} />
            <Box>
              <StyledTextContentWrapper>
                <span style={{ color: colorPalette.purple, fontWeight: 700 }}>
                  {ideaPoints} {Constants.IDEA_POINTS}
                </span>
                {Constants.PROBLEM_IDEAPOINTS_TEXT}
              </StyledTextContentWrapper>
            </Box>
          </IdeaIconContainer>
        </TextContainer>
        <Footer item={item} />
      </Grid>
    </ContentContainer>
  );
};
