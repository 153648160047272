import React, { useCallback, useContext, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import PsButton from '../common/PsButton';
import { PsTheme } from '../../theme';
import { Application } from '../CardApplication';
import DataContext from '../../../src/contexts/DataContext';
import { DeployNft } from '../../../src/modals/DeployNft';

type DeployNftButtonProps = {
  application: Application;
  title: string;
};

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    actionButton: {
      fontSize: 20,
      minWidth: 'auto',
      marginTop: '17px',
      marginRight: 27,
      borderRadius: 15,
      '& .MuiButton-label': {
        textAlign: 'center'
      },
      [psTheme.breakpoints.down('xs')]: {
        marginBottom: 10,
        marginRight: 0
      }
    },
    iconNft: {
      marginLeft: -10,
      marginRight: 10
    },
    iconNftCoins: {
      display: 'inline-flex',
      marginLeft: -10,
      marginRight: 10,
      '& svg:first-child': {
        marginRight: -5
      }
    }
  };
});

export const DeployNftButton = ({
  title,
  application
}: DeployNftButtonProps) => {
  const { classes } = useStyles();
  const { refreshApplicationDetail } = useContext(DataContext);
  const [modalOpen, setModalOpen] = useState(false);

  const onDeploy = useCallback(() => {
    refreshApplicationDetail();
  }, [refreshApplicationDetail]);

  return (
    <>
      {modalOpen && (
        <DeployNft
          application={application}
          onClose={() => setModalOpen(false)}
          onDeploy={onDeploy}
          open={modalOpen}
        />
      )}
      <PsButton
        className={classes.actionButton}
        onClick={() => setModalOpen(true)}
        startIcon={<div className={classes.iconNftCoins}></div>}
      >
        {title}
      </PsButton>
    </>
  );
};
