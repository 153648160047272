import React from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { PsTheme } from '../theme';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      width: '75.7%',
      flexGrow: 1,
      [psTheme.breakpoints.down('sm')]: {
        width: '100%'
      }
    }
  };
});

type ContentBarProps = {
  className?: string;
  children?: any;
};

const ContentBarView = ({ className, children }: ContentBarProps) => {
  const { classes } = useStyles();
  return <div className={`${classes.root} ${className}`}>{children}</div>;
};

export const ContentBar = styled(ContentBarView)({});

export default ContentBar;
