import React, { useContext, useState, useCallback, useEffect } from 'react';
import { PsFormControl, ShortEvent } from 'components/common/PsFormControl';
import { PsInput } from 'components/common/PsInput';
import { ModalDataContext } from 'contexts/ModalDataContext';
import { AuthContext } from 'contexts/AuthContext';
import styles from 'modals/ModalWindow.module.scss';
import Typography from '@mui/material/Typography';

const NameAndEmailStep: React.FC = () => {
  const { user } = useContext(AuthContext);
  const { values, errors, updateField, setErrors } =
    useContext(ModalDataContext);

  // eslint-disable-next-line prefer-const
  let [userFirstName = '', ...names] = (user ? user.username || '' : '').split(
    ' '
  );
  let userLastName = names.join(' ');

  userFirstName = userFirstName || (user ? user.firstName : '') || '';
  userLastName = userLastName || (user ? user.lastName : '') || '';

  const initFirstName = values.titleContest || userFirstName || '';
  const initLastName = values.titleChallenge || userLastName || '';
  const initEmail = values.titleProblem || (user ? user.email || '' : '');

  const [firstName, setFirstName] = useState<string>(initFirstName);
  const [lastName, setLastName] = useState<string>(initLastName);
  const [email, setEmail] = useState<string>(initEmail);

  useEffect(() => {
    // eslint-disable-next-line prefer-const
    let [userFirstName = '', ...names] = (
      user ? user.username || '' : ''
    ).split(' ');
    let userLastName = names.join(' ');

    userFirstName = userFirstName || (user ? user.firstName : '') || '';
    userLastName = userLastName || (user ? user.lastName : '') || '';

    const initFirstName = values.titleContest || userFirstName || '';
    const initLastName = values.titleChallenge || userLastName || '';
    const initEmail = values.titleProblem || (user ? user.email || '' : '');

    setFirstName(initFirstName);
    updateField('titleContest', initFirstName);
    setLastName(initLastName);
    updateField('titleChallenge', initLastName);
    setEmail(initEmail);
    updateField('titleProblem', initEmail);
  }, [user]);

  const onFirstNameChange = useCallback(
    (e: ShortEvent) => {
      const { value } = e.target;
      setFirstName(value);
      updateField('titleContest', value);
      setErrors({});
    },
    [setFirstName, updateField, setErrors]
  );

  const onLastNameChange = useCallback(
    (e: ShortEvent) => {
      const { value } = e.target;
      setLastName(value);
      updateField('titleChallenge', value);
      setErrors({});
    },
    [setLastName, updateField, setErrors]
  );

  const onEmailChange = useCallback(
    (e: ShortEvent) => {
      const { value } = e.target;
      setEmail(value);
      updateField('titleProblem', value);
      setErrors({});
    },
    [setEmail, updateField, setErrors]
  );

  return (
    <div className={styles.stepWrapper}>
      <Typography className={styles.text}>
        Confirm your name and e-mail address to allocate your inventor rights
      </Typography>

      <PsFormControl
        placeholder="Type first name"
        label="First Name"
        helperText={errors.titleContest}
        error={!!errors.titleContest}
        labelLight
      >
        <PsInput
          name="firstName"
          value={firstName}
          onChange={onFirstNameChange}
        />
      </PsFormControl>

      <PsFormControl
        placeholder="Type last name"
        label="Last Name"
        helperText={errors.titleChallenge}
        error={!!errors.titleChallenge}
        labelLight
      >
        <PsInput name="lastName" onChange={onLastNameChange} value={lastName} />
      </PsFormControl>

      <PsFormControl
        placeholder="Type email address"
        label="Email"
        helperText={errors.titleProblem}
        error={!!errors.titleProblem}
        labelLight
      >
        <PsInput value={email} onChange={onEmailChange} name="email" />
      </PsFormControl>
    </div>
  );
};

export default NameAndEmailStep;
