import React, { FC, useMemo } from 'react';
import {
  Box,
  Breadcrumbs,
  Link,
  styled,
  SvgIconTypeMap,
  Typography
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { GrNext } from 'react-icons/gr';
import PsLogo from '../common/PsLogo';
import { BreadCrumbIcon, IBreadCrumb } from './useBreadCrumb';
import { colorPalette } from '../../theme';

type SvgType = OverridableComponent<SvgIconTypeMap<unknown, 'svg'>> & {
  muiName: string;
};

export interface IEnumType {
  [key: string]: SvgType | React.ElementType;
}

const PossibleIcons: IEnumType = Object.freeze({
  [BreadCrumbIcon.MIND_MINER_ICON]: () => (
    <PsLogo size={30} color={colorPalette.grey} small />
  )
});

export * from './useBreadCrumb';

export const BreadCrumbComponent: FC<IBreadCrumb> = ({ breadCrumbOptions }) => {
  const body = useMemo((): React.ReactNode => {
    const lastIndex = breadCrumbOptions.length - 1;
    return breadCrumbOptions.map(({ icon, label, url }, index) => {
      const IconToRender = icon && PossibleIcons[icon];
      return (
        <Box
          key={`${index}-icon`}
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          {index === 0 && IconToRender && (
            <IconToRender sx={{ height: '1.5rem', width: '1.5rem' }} />
          )}
          {index === lastIndex ? (
            <BreadcrumbLabel>{label}</BreadcrumbLabel>
          ) : (
            <Link href={url}>
              <BreadcrumbLabel>{label}</BreadcrumbLabel>
            </Link>
          )}
        </Box>
      );
    });
  }, [breadCrumbOptions]);

  return (
    <Breadcrumbs
      sx={{
        flexWrap: 'nowrap'
      }}
      separator={<GrNext color={colorPalette.grey} />}
      color={colorPalette.grey}
    >
      {body}
    </Breadcrumbs>
  );
};

const BreadcrumbLabel = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    fontSize: '1rem'
  },
  marginLeft: '0.188rem',
  maxWidth: '200px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textTransform: 'capitalize',
  whiteSpace: 'nowrap',
  fontSize: '0.75rem'
}));
