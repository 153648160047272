import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useDispatch } from 'react-redux';
import { FormControlLabel, Link, Typography } from '@mui/material';
import { Tag } from 'components/CardTag';
import Checkbox from 'components/Checkbox';
import PsButton from 'components/common/PsButton';
import PsFormControl from 'components/common/PsFormControl';
import PsInput from 'components/common/PsInput';
import DataContext from 'contexts/DataContext';
import ModalContext, { ModalComponentsKeys } from 'contexts/ModalContext';
import ModalDataContext from 'contexts/ModalDataContext';
import dataProvider from 'dataPrvider';
import ModalWindow from 'modals/ModalWindow';
import Actions from 'redux-state/actions';
import { GetUser } from 'redux-state/onboarding/selectors';
import { GetOwnedCommunity } from 'redux-state/selectors';
import {
  Constants,
  LABELS,
  TAG_TYPES,
  VARIANT,
  ERRORS
} from 'utilities/constants';
import {
  ActionsBox,
  LoaderWrapper,
  StyledButton,
  StyledCircularProgress,
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle
} from './StyledComponents';

interface IAddNewCompanyProps {
  initialValue?: string;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IErrors {
  [key: string]: string;
}

export const AddNewCompany: React.FC<IAddNewCompanyProps> = ({
  initialValue,
  setOpen
}) => {
  const dispatch = useDispatch();
  const { openModal } = useContext(ModalContext);
  let isValidName = undefined;
  const [showNameModal, setShowNameModal] = useState<boolean>(false);
  const { id, subscription, subscriptionTag } = GetUser();
  const ownedCommunity = GetOwnedCommunity();
  const [companyName, setCompanyName] = useState<string>('');
  const { updateField } = useContext(ModalDataContext);
  const [loader, setLoader] = useState<boolean>(false);
  const [errors, setErrors] = useState<IErrors>({});
  const { setAlertContent } = useContext(DataContext);
  const [name, setName] = useState<string>(initialValue ?? '');
  const [isRepresentative, setIsRepresentative] = useState<boolean>(false);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const user = GetUser();

  const validate = useCallback(() => {
    const errorsObj: IErrors = {};
    if (!name) {
      errorsObj.name = 'Title is required';
    } else {
      delete errorsObj.name;
    }
    return errorsObj;
  }, [name]);

  useEffect(() => {
    if (id) {
      dispatch(Actions.getProfile(id));
    }
  }, [dispatch, id]);

  const { isFormInvalid } = useMemo(() => ({ isFormInvalid: !name }), [name]);

  const cb = (data: Tag, error: Error) => {
    setLoader(false);
    if (error) {
      const match = error.message?.match(/dup key: { name: "(.+)" }/);
      if (match) {
        setAlertContent(<>{ERRORS.COMPANY_ALREADY_EXISTS}</>);
      }
      return;
    }
    const { name, id } = data;
    if (
      (subscription && !subscriptionTag) ||
      (subscription && subscriptionTag)
    ) {
      // need to show message to the user regarding existing subscription
      window.location.replace(`/communities/${id}`);
    } else {
      updateField('parentProblem', id);
      updateField('parentProblemLabel', name);
      updateField('parentSolutionOnly', true);
      openModal(ModalComponentsKeys.companySubscription);
    }
  };

  const handleSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();
      setLoader(true);
      isValidName = await dataProvider.validateCompanyName(name);
      const errorsObj = validate();
      if (Object.keys(errorsObj).length) {
        setLoader(false);
        setErrors(errorsObj);
        return;
      }

      const payload = {
        cb,
        isRepresentative,
        name,
        type: TAG_TYPES.WORKPLACE,
        owner: isRepresentative ? user.id : null
      };

      if (isValidName == true) {
        dispatch(Actions.addCompany(payload));
      } else {
        setShowNameModal(true);
      }
    },
    [
      dispatch,
      isRepresentative,
      name,
      openModal,
      setAlertContent,
      isValidName,
      subscription,
      subscriptionTag,
      updateField,
      validate
    ]
  );

  const handleChange = useCallback(
    (event: any) => {
      const { name, value, checked } = event.target;
      if (name === Constants.REPRESENTATIVE) {
        if (checked && ownedCommunity) {
          setOpenConfirmation(true);
          return;
        } else {
          setIsRepresentative(checked);
        }
      } else if (name === Constants.NAME) {
        setName(value);
      }
    },
    [subscription, ownedCommunity]
  );

  const handleConfirmation = (confirm: boolean) => {
    setOpenConfirmation(false);
  };

  const handleCancel = () => {
    setShowNameModal(false);
    setOpen(false);
  };

  const handleYes = () => {
    setShowNameModal(false);
    const payload = {
      cb,
      isRepresentative,
      name,
      type: TAG_TYPES.WORKPLACE,
      owner: isRepresentative ? user.id : null
    };
    dispatch(Actions.addCompany(payload));
  };

  useEffect(() => {
    if (id) {
      dispatch(Actions.getOwnedCommunity({ userId: id }));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (ownedCommunity) {
      setCompanyName(ownedCommunity.name);
    }
  }, [ownedCommunity]);

  return (
    <>
      <ModalWindow title={Constants.ADD_NEW_COMPANY}>
        <form onSubmit={handleSubmit}>
          <PsFormControl error={!!errors.name}>
            <PsInput
              name={Constants.NAME}
              onChange={handleChange}
              value={name}
            />
          </PsFormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={isRepresentative}
                onChange={handleChange}
                name={Constants.REPRESENTATIVE}
              />
            }
            label={LABELS.ARE_YOU_A_REPRESENTATIVE_OF_THE_COMPANY}
          />
          <ActionsBox>
            {!loader ? (
              <PsButton
                classes={Constants.BUTTON_STYLES}
                disabled={isFormInvalid}
                fullWidth
                type={Constants.S_SUBMIT}
              >
                {Constants.ADD}
              </PsButton>
            ) : (
              <LoaderWrapper>
                <StyledCircularProgress />
              </LoaderWrapper>
            )}
          </ActionsBox>
        </form>
      </ModalWindow>
      <StyledDialog
        open={openConfirmation}
        onClose={() => handleConfirmation(false)}
        aria-labelledby={Constants.CONFIRMATION_DIALOG_TITLE}
      >
        <StyledDialogTitle id={Constants.CONFIRMATION_DIALOG_TITLE}>
          {Constants.WARNING}
        </StyledDialogTitle>
        <StyledDialogContent dividers>
          <Typography variant={VARIANT.BODY1}>
            You are already the owner of {companyName}. You can only manage one
            MindMiner company profile at a time. To claim ownership of this
            company, you must first remove yourself as the owner of{' '}
            {companyName}. For more assistance, please send a message to{' '}
            <Link href="mailto:help@mindminer.ai">help@mindminer.ai</Link>.
          </Typography>
        </StyledDialogContent>
        <StyledDialogActions>
          <StyledButton
            onClick={() => handleConfirmation(false)}
            variant={VARIANT.OUTLINED}
            color={VARIANT.PRIMARY}
          >
            {Constants.CANCEL}
          </StyledButton>
        </StyledDialogActions>
      </StyledDialog>
      <StyledDialog
        open={showNameModal}
        onClose={() => setShowNameModal(false)}
        aria-labelledby={Constants.CONFIRMATION_DIALOG_TITLE}
      >
        <StyledDialogTitle id={Constants.CONFIRMATION_DIALOG_TITLE}>
          {Constants.WARNING}
        </StyledDialogTitle>
        <StyledDialogContent dividers>
          <Typography variant={VARIANT.BODY1}>
            The name {name} is invalid.
          </Typography>
          <Typography variant={VARIANT.BODY1}>
            Are you sure you want to continue?
          </Typography>
        </StyledDialogContent>
        <StyledDialogActions>
          <StyledButton
            onClick={handleCancel}
            variant={VARIANT.OUTLINED}
            color={VARIANT.PRIMARY}
          >
            {Constants.CANCEL}
          </StyledButton>
          <StyledButton
            onClick={handleYes}
            variant={VARIANT.OUTLINED}
            color={VARIANT.PRIMARY}
          >
            {Constants.YES}
          </StyledButton>
        </StyledDialogActions>
      </StyledDialog>
    </>
  );
};
