export const DELETE_PROFILE_ITEM = 'DELETE_PROFILE_ITEM';
export const EDIT_PROFILE = 'EDIT_PROFILE';
export const EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS';
export const E_FILE_PATENT = 'E_FILE_PATENT';
export const E_FILE_PATENT_SUCCESS = 'E_FILE_PATENT_SUCCESS';
export const EDIT_PROFILE_ITEM = 'EDIT_PROFILE_ITEM';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_UNREAD_NOTIFICATION_COUNT = 'GET_UNREAD_NOTIFICATION_COUNT';
export const GET_UNREAD_NOTIFICATION_COUNT_SUCCESS =
  'GET_UNREAD_NOTIFICATION_COUNT_SUCCESS';
export const GET_PROFILE_CONCEPTS = 'GET_PROFILE_CONCEPTS';
export const GET_PROFILE_CONCEPTS_SUCCESS = 'GET_PROFILE_CONCEPTS_SUCCESS';
export const GET_PROFILE_CONTESTS = 'GET_PROFILE_CONTESTS';
export const GET_PROFILE_CONTESTS_SUCCESS = 'GET_PROFILE_CONTESTS_SUCCESS';
export const GET_PROFILE_INVENTIONS = 'GET_PROFILE_INVENTIONS';
export const GET_PROFILE_INVENTIONS_SUCCESS = 'GET_PROFILE_INVENTIONS_SUCCESS';
export const GET_PROFILE_PROBLEMS = 'GET_PROFILE_PROBLEMS';
export const GET_PROFILE_PROBLEMS_COUNT = 'GET_PROFILE_PROBLEMS_COUNT';
export const GET_PROFILE_PROBLEMS_COUNT_SUCCESS =
  'GET_PROFILE_PROBLEMS_COUNT_SUCCESS';
export const GET_PROFILE_SOLUTIONS_COUNT = 'GET_PROFILE_SOLUTIONS_COUNT';
export const GET_PROFILE_SOLUTIONS_COUNT_SUCCESS =
  'GET_PROFILE_SOLUTIONS_COUNT_SUCCESS';
export const GET_PROFILE_CONCEPTS_COUNT = 'GET_PROFILE_CONCEPTS_COUNT';
export const GET_PROFILE_CONCEPTS_COUNT_SUCCESS =
  'GET_PROFILE_CONCEPTS_COUNT_SUCCESS';
export const GET_PROFILE_INVENTIONS_COUNT = 'GET_PROFILE_INVENTIONS_COUNT';
export const GET_PROFILE_INVENTIONS_COUNT_SUCCESS =
  'GET_PROFILE_INVENTIONS_COUNT_SUCCESS';
export const GET_PROFILE_CONTESTS_COUNT = 'GET_PROFILE_CONTESTS_COUNT';
export const GET_PROFILE_CONTESTS_COUNT_SUCCESS =
  'GET_PROFILE_CONTESTS_COUNT_SUCCESS';
export const GET_PROFILE_AGREEMENTS_COUNT = 'GET_PROFILE_AGREEMENTS_COUNT';
export const GET_PROFILE_AGREEMENTS_COUNT_SUCCESS =
  'GET_PROFILE_AGREEMENTS_COUNT_SUCCESS';
export const GET_PROFILE_PROBLEMS_SUCCESS = 'GET_PROFILE_PROBLEMS_SUCCESS';
export const GET_PROFILE_AGREEMENTS = 'GET_PROFILE_AGREEMENTS';
export const GET_PROFILE_AGREEMENTS_SUCCESS = 'GET_PROFILE_AGREEMENTS_SUCCESS';
export const GET_PROFILE_PRODUCTS = 'GET_PROFILE_PRODUCTS';
export const GET_PROFILE_PRODUCTS_SUCCESS = 'GET_PROFILE_PRODUCTS_SUCCESS';
export const GET_PROFILE_SOLUTIONS = 'GET_PROFILE_SOLUTIONS';
export const GET_PROFILE_SOLUTIONS_SUCCESS = 'GET_PROFILE_SOLUTIONS_SUCCESS';
export const GET_REWARD_HISTORY = 'GET_REWARD_HISTORY';
export const GET_REWARD_HISTORY_SUCCESS = 'GET_REWARD_HISTORY_SUCCESS';
export const GET_USER_RANKING = 'GET_USER_RANKING';
export const GET_USER_RANKING_SUCCESS = 'GET_USER_RANKING_SUCCESS';
export const GET_USER_IDEAPOINTS = 'GET_USER_IDEAPOINTS';
export const GET_USER_IDEAPOINTS_SUCCESS = 'GET_USER_IDEAPOINTS_SUCCESS';
export const MARK_ALL_READ = 'MARK_ALL_READ';
export const MARK_MULTIPLE_READ = 'MARK_MULTIPLE_READ';
export const NFT_DEPLOY_FINISH = 'NFT_DEPLOY_FINISH';
export const NFT_DEPLOY_FINISH_SUCCESS = 'NFT_DEPLOY_FINISH_SUCCESS';
export const NFT_DEPLOY_START = 'NFT_DEPLOY_START';
export const NFT_DEPLOY_START_SUCCESS = 'NFT_DEPLOY_START_SUCCESS';
export const NFT_DEPLOY_WITH_METAMASK = 'NFT_DEPLOY_WITH_METAMASK';
export const NFT_DEPLOY_WITH_METAMASK_SUCCESS =
  'NFT_DEPLOY_WITH_METAMASK_SUCCESS';
export const SET_ARCHIVE_ITEM = 'SET_ARCHIVE_ITEM';
export const SET_PIN_ITEM = 'SET_PIN_ITEM';
export const TOGGLE_ERROR = 'TOGGLE_ERROR';
export const TOGGLE_READ_STATUS = 'TOGGLE_READ_STATUS';
export const UPDATE_ERROR_STATUS = 'UPDATE_ERROR_STATUS';
export const OPEN_TX_APPROVAL_MODAL = 'OPEN_TX_APPROVAL_MODAL';
export const TOGGLE_DEPLOY_NFT_LOADER = 'TOGGLE_DEPLOY_NFT_LOADER';
export const SET_TOKEN_URI = 'SET_TOKEN_URI';
export const GET_PROFILE_MUTUAL_TAGS = 'GET_PROFILE_MUTUAL_TAGS';
export const GET_PROFILE_MUTUAL_TAGS_SUCCESS =
  'GET_PROFILE_MUTUAL_TAGS_SUCCESS';
