import React, { useContext, useEffect, useState } from 'react';
import { styled, useTheme, lighten } from '@mui/material/styles';

import { DataContext } from 'contexts/DataContext';
import { PsLogo } from 'components/common/PsLogo';
import user65x65 from 'assets/images/user_67x65.jpeg';
import { Picture } from '../../helpers';
import { PsTheme } from '../../theme';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

type Entity = {
  id: string;
  key: string;
  type: string;
  name: string;
  amount: number;
  tag?: { id: string | number; name: string };
  files: Array<Picture>;
};

type ClassKey =
  | 'root'
  | 'loading'
  | 'header'
  | 'user'
  | 'number'
  | 'photo'
  | 'userPhoto'
  | 'info'
  | 'top'
  | 'coins'
  | 'bottom';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      padding: '0 15px'
    },
    loading: {
      lineHeight: '50px',
      textAlign: 'center'
    },
    header: {
      margin: '0 10px 16px',
      paddingTop: 5,
      fontSize: 24,
      fontWeight: 600,
      textTransform: 'uppercase'
    },
    user: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      margin: '16px 0',
      fontSize: 20,
      fontWeight: 600
    },
    number: {
      width: 48,
      flexShrink: 0,
      paddingLeft: 7
    },
    photo: {
      display: 'block',
      width: 55,
      flexShrink: 0,
      marginRight: 43
    },
    userPhoto: {
      display: 'block',
      width: 55,
      height: 55,
      borderRadius: 10,
      objectFit: 'cover'
    },
    info: {
      flexGrow: 1
    },
    top: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: 10
    },
    coins: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontWeight: 'normal',
      '& > svg': {
        marginLeft: 12
      }
    },
    bottom: {
      height: 3,
      background: lighten(psTheme.palette.primary.main, 0.8),
      borderRadius: 1,
      '& > div': {
        height: 3,
        background: psTheme.palette.primary.main,
        borderRadius: 1
      }
    }
  };
});

export type TagTopUsersProps = {
  id: string | number;
};

const TagTopUsersView = ({ id }: TagTopUsersProps) => {
  const psTheme: PsTheme = useTheme();
  const { dataProvider } = useContext(DataContext);
  const [users, setUsers] = useState<Array<Entity>>([]);
  const [loading, setLoading] = useState(true);
  const [maxAmount, setMaxAmount] = useState(0);
  const { classes } = useStyles();

  useEffect(() => {
    setLoading(true);
    dataProvider
      .getTagTopUsers<Entity>(id)
      .then(({ data }) => {
        setUsers(data);
        let max = 0;
        data.forEach((item) => {
          if (item.amount && item.amount > max) {
            max = item.amount;
          }
        });
        setMaxAmount(max);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className={classes.root}>
      <Typography className={classes.header}>Top 10</Typography>
      {loading ? (
        <Typography className={classes.loading} variant="body2">
          Loading...
        </Typography>
      ) : null}
      <div>
        {users.map((user, index) => {
          let src = user65x65;
          if (user.files && user.files.length) {
            src = user.files[0].url;
          }
          const name = user.name;
          const amount = user.amount ? user.amount.toFixed(2) : user.amount;
          let persents = '0';
          if (maxAmount && user.amount) {
            persents = `${(user.amount / maxAmount) * 100}%`;
          }
          return (
            <div key={user.id} className={classes.user} title={name}>
              <div className={classes.number}>{index + 1}.</div>
              <Link to={`/profiles/${user.key || user.id}`}>
                <a className={classes.photo}>
                  <img className={classes.userPhoto} src={src} alt={name} />
                </a>
              </Link>
              <div className={classes.info}>
                <div className={classes.top}>
                  <div>{name}</div>
                  <div className={classes.coins}>
                    {amount}{' '}
                    <PsLogo
                      size={30}
                      color={psTheme.palette.primary.main}
                      small
                    />
                  </div>
                </div>
                <div className={classes.bottom}>
                  <div style={{ width: persents }}></div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const TagTopUsers = styled(TagTopUsersView)({});

export default TagTopUsers;
