import React from 'react';
import { Container, Box, Typography } from '@mui/material';
import Config from 'config/config';

export const Terms = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Terms and Services
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Welcome to MindMiner! Please read these terms and services carefully
          before using our app.
        </Typography>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          1. Acceptance of Terms
        </Typography>
        <Typography variant="body1" paragraph>
          By using MindMiner, you agree to be bound by these terms and services.
          If you do not agree, please do not use our app.
        </Typography>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          2. Use of the App
        </Typography>
        <Typography variant="body1" paragraph>
          You agree to use MindMiner only for lawful purposes and in a way that
          does not infringe on the rights of others or restrict their use of the
          app.
        </Typography>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          3. Intellectual Property
        </Typography>
        <Typography variant="body1" paragraph>
          All content on MindMiner, including text, graphics, and logos, is the
          property of MindMiner or its content suppliers and protected by
          intellectual property laws.
        </Typography>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          4. Limitation of Liability
        </Typography>
        <Typography variant="body1" paragraph>
          MindMiner is not liable for any damages that may occur from the use of
          our app. We provide the app as is without any warranties.
        </Typography>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          5. Changes to Terms
        </Typography>
        <Typography variant="body1" paragraph>
          We may update these terms and services from time to time. You are
          encouraged to review this page periodically for any changes.
        </Typography>
      </Box>

      <Box>
        <Typography variant="h5" component="h2" gutterBottom>
          6. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about these terms and services, please
          contact us at {Config.SUPPORT_EMAIL}
        </Typography>
      </Box>
    </Container>
  );
};
