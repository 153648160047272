import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

interface LogoProps extends SvgIconProps {
  width?: number | string;
  height?: number | string;
}

export const SnapchatIcon: React.FC<LogoProps> = ({
  width = '1.9rem',
  height = '1.9rem',
  ...props
}) => {
  return (
    <SvgIcon sx={{ width: width, height: height }} viewBox="0 0 1024 1024">
      <circle cx="512" cy="512" r="512" style={{ color: '#FFFC00' }} />
      <path
        d="M516.4 272.9c21.1 0 92.7 5.9 126.5 81.5 11.3 25.5 8.6 68.7 6.4 103.4l-.1 1.3c-.3 3.8-.5 7.4-.6 10.9 1.6 1 4.3 1.9 8.6 1.9 6.4-.3 14.1-2.6 22-6.4 3.5-1.9 7.3-2.2 9.9-2.2 3.9 0 7.7.6 10.9 1.9 9.6 3.2 15.7 10.2 15.7 17.9.3 9.6-8.3 17.9-25.9 24.9-1.9.6-4.5 1.6-7.3 2.5-9.6 2.9-24.3 7.7-28.4 17.3-1.9 4.8-1.3 11.2 2.6 18.5l.3.3c1.3 2.9 32.6 74.1 102.2 85.6 5.4.9 9.3 5.8 9 10.9 0 1.6-.3 3.2-1 4.8C762.1 660 740 669 700.1 675c-1.3 1.9-2.6 8-3.5 12.2-.6 3.8-1.6 7.7-2.9 11.8-1.6 5.8-5.8 8.6-11.8 8.6h-.6c-2.9 0-6.7-.7-11.5-1.6-7.7-1.6-16.3-2.9-27.2-2.9-6.4 0-12.8.3-19.5 1.6-12.8 2.2-24 9.9-36.8 18.9-18.2 12.8-39 27.5-70.3 27.5-1.3 0-2.5-.3-3.8-.3H509c-31.3 0-51.8-14.4-70-27.5-12.8-9-23.6-16.6-36.4-18.9-6.7-1-13.4-1.6-19.8-1.6-11.5 0-20.4 1.9-27.1 3.2-4.5.9-8.3 1.6-11.5 1.6-8 0-11.2-4.8-12.4-9-1.3-4.1-1.9-8.3-2.9-12.1-1-3.9-2.2-10.5-3.5-12.2-40.9-4.7-62.9-13.7-68-26.2-.7-1.3-1.1-3.2-1.2-4.8-.3-5.2 3.5-9.9 9-10.9 69.6-11.5 100.9-82.8 102.2-85.8l.3-.6c3.8-7.4 4.8-13.8 2.5-18.5-4.2-9.3-18.9-14-28.4-17.3-2.6-.6-5.1-1.6-7.4-2.5-23.6-9.3-26.8-19.8-25.5-27.2 1.9-10.2 14.4-16.9 24.9-16.9 3.1 0 5.8.6 8.2 1.6 9 4.1 16.8 6.4 23.6 6.4 5 0 8.2-1.3 9.9-2.2l-1-12.1c-2.1-34.7-4.8-77.9 6.5-103.2 32.8-75.2 104.2-81 125.3-81l8.9-.3 1.2.1z"
        style={{ color: '#fff' }}
      />
    </SvgIcon>
  );
};
