import React, { FunctionComponent } from 'react';
import { CancelOutlined } from '@mui/icons-material';
import { Divider } from '@mui/material';
import SimpleIconButton from 'components/SimpleIconButton';
import { Breakpoints, Constants, VARIANT } from 'utilities/constants';
import { BaseModal } from '../Common/BaseModal';
import { HeaderSection } from '../Common/HeaderSection';
import { AllConceptsView } from './AllConceptsView';
import {
  AllConceptsWrapper,
  HeaderBox,
  TitleTypography
} from './styledComponents';

interface AllConceptsModalProps {
  onClose: () => void;
  open: boolean;
}

export const AllConceptsModal: FunctionComponent<AllConceptsModalProps> = ({
  onClose,
  open
}) => {
  return (
    <BaseModal open={open} maxWidth={Breakpoints.SMALL} onClose={onClose}>
      <HeaderSection>
        <HeaderBox>
          <TitleTypography>{Constants.ALL_CONCEPTS}</TitleTypography>
          <SimpleIconButton
            Icon={CancelOutlined}
            color={VARIANT.PRIMARY}
            onClick={onClose}
          />
        </HeaderBox>
      </HeaderSection>
      <Divider />
      <AllConceptsWrapper>
        <AllConceptsView />
      </AllConceptsWrapper>
    </BaseModal>
  );
};
