import React, { useContext, useCallback } from 'react';
import { styled, Theme, useTheme } from '@mui/material/styles';

import { PsTheme } from '../../theme';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      display: 'flex',
      background: '#ffffff',
      border: '1px solid rgba(121, 114, 140, 0.11)',
      boxSizing: 'border-box',
      boxShadow:
        '0px 4px 8px rgba(10, 0, 32, 0.02), 0px 4px 84px rgba(7, 0, 21, 0.04)',
      borderRadius: 24,
      overflow: 'hidden',
      width: '100%',
      minHeight: 250,
      marginBottom: 20,
      [psTheme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        borderRadius: 10
      }
    }
  };
});

type CardProps = {
  children: React.ReactNode;
  className?: string;
  classes?: any;
};

const CardView = ({ className, children }: CardProps) => {
  const { classes } = useStyles();

  let rootClassName = classes.root;
  if (className) {
    rootClassName += ' ' + className;
  }
  return <div className={rootClassName}>{children}</div>;
};

export const Card = styled(CardView)({});

export default Card;
