import {
  CallEffect,
  PutEffect,
  call,
  put,
  takeLatest
} from 'redux-saga/effects';
import { ERRORS } from 'utilities/constants';
import * as Actions from './actions';
import { Api } from './api';
import { DISTRIBUTE_REWARD, GET_REWARD_POOL_THRESHOLD } from './types';
import { getProfile } from '../onboarding/actions';

function* distributeReward(action: {
  type: string;
  payload: { userId: string | number };
}): Generator<CallEffect | PutEffect, void, any> {
  try {
    const response = yield call(Api.distributeReward);
    if (response) {
      yield put(Actions.distributeRewardSuccess());
      yield put(getProfile(action.payload.userId));
    }
  } catch (error) {
    alert(error.message);
    yield put(Actions.distributeRewardSuccess());
  }
}

function* getRewardPoolThreshold(): Generator<
  CallEffect | PutEffect,
  void,
  any
> {
  try {
    const response = yield call(Api.getRewardPoolThreshold);
    if (response) {
      yield put(Actions.getRewardPoolThresholdSuccess(response.data.threshold));
    }
  } catch (error) {
    console.log(ERRORS.FETCHING_THRESHOLD, error.errorMessage);
  }
}

function* mySaga() {
  yield takeLatest(DISTRIBUTE_REWARD, distributeReward);
  yield takeLatest(GET_REWARD_POOL_THRESHOLD, getRewardPoolThreshold);
}

export default mySaga;
