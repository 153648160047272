import React, { useState, useCallback, useContext } from 'react';
import { styled, Theme, useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';

import Step from 'components/Step';
import Stepper from 'components/Stepper';
import { PsLogo } from 'components/common/PsLogo';
import { Profile } from 'components/CardProfile';
import { PsButton } from 'components/common/PsButton';

import { DataContext } from 'contexts/DataContext';
import { ModalComponentsKeys, ModalContext } from 'contexts/ModalContext';
import {
  ModalDataContext,
  RegisterPopupSteps
} from 'contexts/ModalDataContext';

import { getQuery } from 'helpers';
import useRouter from 'hooks/useRouter';
import ModalWindow from 'modals/ModalWindow';
import styles from 'modals/ModalWindow.module.scss';
import { getProfile } from 'redux-state/onboarding/actions';
import { useStyles } from 'utilities/Styles';
import { REGISTER_STEPS_CONFIG } from './config';
import { GetUser } from 'redux-state/selectors';

const steps: string[] = ['Name and E-mail', 'Your Tags'];

const useButtonClasses = makeStyles()((theme: Theme) => {
  return {
    root: {
      margin: '8px 0',
      fontWeight: 400
    }
  };
});

export const RegisterPopup: React.FC = () => {
  const registerModalClasses = useStyles();

  const user = GetUser();
  const { dataProvider } = useContext(DataContext);
  const { openModal, closeModal } = useContext(ModalContext);
  const { values, setErrors, setType, resetFields } =
    useContext(ModalDataContext);

  const theme = useTheme();
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [prevSteps, setPrevSteps] = useState<string[]>([]);
  const [globalError, setGlobalError] = useState('');
  const [activeStepNumber, setActiveStepNumber] = useState<number>(0);
  const [currentStep, setCurrentStep] = useState<string | undefined>(
    RegisterPopupSteps.NameAndEmailStep
  );

  const dispatch = useDispatch();

  const buttonClasses = useButtonClasses();

  const {
    Component,
    nextStep,
    validate,
    isNextStepBlocked,
    isFinalStep,
    nextButtonTitle,
    backButtonTitle
  } = REGISTER_STEPS_CONFIG[currentStep || ''];

  const StepComponent = Component as React.FC;

  const handleClickBack = useCallback(() => {
    if (activeStepNumber === 0) {
      closeModal();
      if (!user) {
        // register
        resetFields();

        const { openPopup, ...newQuery } = getQuery();
        if (openPopup) {
          router.query = newQuery;
          router.replace(router.pathname);
        }
      }
      return;
    }
    setActiveStepNumber((prev) => Math.max(0, prev - 1));
    setCurrentStep(prevSteps[prevSteps.length - 1]);
    setPrevSteps(prevSteps.slice(0, prevSteps.length - 1));
    setGlobalError('');
  }, [prevSteps]);

  const handleClickNext = () => {
    handleNextStep();
  };

  const handleClickForceNext = () => {
    handleNextStep(true);
  };

  const handleNextStep = async (skipValidation = false) => {
    if (!skipValidation && typeof validate === 'function') {
      const { success, errors } = validate(values);

      if (!success) {
        setErrors({ ...errors });

        return;
      }
    }

    if (isFinalStep) {
      const profileInfo = {
        username: `${values.titleContest} ${values.titleChallenge}`,
        location: values.parentProblem,
        locationRaw: values.parentProblemLabel,
        school: values.parentSolution,
        schoolRaw: values.parentSolutionLabel,
        workplace: values.mainProblem,
        workplaceRaw: values.mainProblemLabel,
        tags: values.tags || [],
        isFirstLogin: false
      };

      setLoading(true);

      if (!user) {
        // register
        // @ts-ignore
        profileInfo.email = values.titleProblem;

        await dataProvider
          .registerUser<Profile>(profileInfo)
          .then(({ data }) => {
            setType(ModalComponentsKeys.registerPopup);
            openModal(ModalComponentsKeys.success);

            const { openPopup, ...newQuery } = getQuery();
            if (openPopup) {
              router.query = newQuery;
              router.replace(router.pathname);
            }
          })
          .catch((err) => {
            setGlobalError(err.message);
            console.error(err);
          })
          .finally(() => {
            dispatch(getProfile(user._id));
            setLoading(false);
          });
      } else {
        // update
        // @ts-ignore
        profileInfo.key = values.username;

        await dataProvider
          .update<Profile>('profiles', { id: user.id, data: profileInfo })
          .then(({ data }) => {
            if (user.inviteUrl && user.inviteUrl !== '/') {
              router.push(user.inviteUrl);
            }
            closeModal();
            resetFields();
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            dispatch(getProfile(user._id));
            setLoading(false);
          });
      }

      return;
    }

    setGlobalError('');

    setPrevSteps([...prevSteps, currentStep || '']);
    setErrors({});
    setActiveStepNumber((prev) => prev + 1);
    if (typeof nextStep === 'function') {
      setCurrentStep(nextStep(values));
      return;
    }

    setCurrentStep(nextStep);
  };

  const isNextStepDisabled: boolean =
    typeof isNextStepBlocked === 'function' && isNextStepBlocked(values);

  const title = !user ? 'Welcome to MindMiner' : 'Set Up MindMiner Profile';
  const subTitle = !user
    ? 'You can brainstorm and protect solutions. You can also earn IdeaCoins and mint Idea NFTs.'
    : 'Start Minting idea NFTs & Earning IdeaCoins for Free';

  return (
    <ModalWindow
      title={title}
      subTitle={subTitle}
      titleAfter={
        <span className={styles.modalTitleIcon}>
          Earn: <PsLogo size={23} color={theme.palette.primary.main} small />
        </span>
      }
    >
      <Stepper className={styles.stepper} activeStep={activeStepNumber}>
        {steps.map((step) => (
          <Step key={step}>{step}</Step>
        ))}
      </Stepper>
      <StepComponent />

      {globalError ? (
        <Typography className={styles.globalError}>{globalError}</Typography>
      ) : null}

      <div className={styles.actions}>
        <PsButton
          disabled={isNextStepDisabled || loading}
          onClick={handleClickNext}
          fullWidth
          classes={buttonClasses}
        >
          {nextButtonTitle || 'Next'}
        </PsButton>
        <PsButton
          onClick={handleClickBack}
          fullWidth
          cancel
          classes={buttonClasses}
        >
          {backButtonTitle || 'Back'}
        </PsButton>
      </div>
    </ModalWindow>
  );
};

export default RegisterPopup;
