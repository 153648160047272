import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import { Avatar, Typography, Box } from '@mui/material';
import { colorPalette } from '../../theme';

export const StyledTab = styled(Tab)<{ isMediumScreen: boolean }>(
  ({ theme, isMediumScreen }) => ({
    alignItems: 'center',
    backgroundColor: colorPalette.brightGray,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: isMediumScreen ? 'center' : 'flex-start',
    minHeight: '90px !important',
    paddingLeft: isMediumScreen ? 'none' : '55px',
    position: 'relative',
    textTransform: 'none',
    '& .tab-label': {
      fontSize: '16px',
      marginLeft: '8px',
      color: theme.palette.text.secondary
    },
    '&.Mui-selected': {
      backgroundColor: 'white',
      '& .tab-label': {
        fontWeight: 'bold',
        color: colorPalette.darkPurple
      }
    }
  })
);

export const StyledTabs = styled(Tabs)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    width: isMediumScreen ? '100%' : '20%',
    height: isMediumScreen ? '53px' : 'none',
    flexDirection: isMediumScreen ? 'row' : 'column',
    '& .MuiTabs-flexContainer': {
      flexDirection: isMediumScreen ? 'row' : 'column'
    }
  })
);

export const StyledBox = styled(Box)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    backgroundColor: colorPalette.brightGray,
    border: `1px solid ${colorPalette.brightGray}`,
    borderRadius: '15px',
    boxShadow: '0px 7px 80px rgba(3, 31, 70, 0.05)',
    display: 'flex',
    flexDirection: isMediumScreen ? 'column' : 'row',
    marginTop: isMediumScreen ? '2%' : '1%',
    overflow: 'hidden',
    width: '100%'
  })
);

export const StyledTabContent = styled(Box)({
  display: 'flex',
  alignItems: 'center'
});

export const StyledTabLabel = styled(Typography)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    fontSize: isMediumScreen ? '12.74px' : '1rem',
    marginTop: isMediumScreen ? '-25px' : '2px'
  })
);

export const StyledTabIcon = styled(Avatar)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    color: 'inherit',
    height: isMediumScreen ? '19px' : '30px',
    marginRight: '7px',
    marginTop: isMediumScreen ? '-25px' : 'none',
    width: isMediumScreen ? '19px' : '30px'
  })
);
