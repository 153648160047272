import { Box, Button, styled, Typography } from '@mui/material';
import { colorPalette } from 'theme';

export const StyledMainBox = styled(Box)({
  alignItems: 'flex-start',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  height: 'fit-content',
  justifyContent: 'center',
  position: 'relative',
  textAlign: 'left',
  width: '100%'
});

export const StyledImageBox = styled(Box)({
  borderRadius: '0.5rem',
  left: 0,
  marginTop: '0.625rem',
  overflow: 'hidden',
  top: 0,
  maxHeight: '12.1875rem',
  zIndex: 0
});

export const StyledContentBox = styled(Box)({
  padding: '1.5rem',
  paddingTop: '1.25rem',
  position: 'absolute',
  width: '100%',
  zIndex: 1
});

export const StyledHeaderTypography = styled(Typography)({
  fontSize: '1.4375rem',
  fontWeight: 700,
  lineHeight: '1.625rem'
});

export const StyledContentTypography = styled(Typography)({
  fontSize: '0.75rem',
  fontWeight: 500,
  lineHeight: '3.8rem'
});

export const StyledGetStartedButton = styled(Button)({
  borderColor: colorPalette.purple,
  borderRadius: '1.5rem',
  borderWidth: '0.125rem',
  color: colorPalette.purple,
  fontSize: '0.7119rem',
  fontWeight: 700,
  maxHeight: '1.9712rem',
  maxWidth: '6.625rem',
  textTransform: 'none',
  '&:hover': {
    borderWidth: '0.125rem'
  }
});
