import React from 'react';
import PlaceHolderImage from 'assets/placeholder/problem.svg';
import { Application } from '../CardApplication';
import { Problem } from '../CardProblem';
import { Product } from '../CardProduct';
import { Profile } from '../CardProfile';
import { Solution } from '../CardSolution';
import ListView from '../ListView';

interface ItemListProps {
  feed?: Problem | Solution | Product | Application;
  filters?: string[];
  pagination?: { page: number; perPage: number };
  profile?: Profile;
  setRowsPerPage?: React.Dispatch<React.SetStateAction<number>>;
  sortType?: string;
}

const FeedView: React.FC<ItemListProps> = ({
  feed,
  filters,
  pagination,
  setRowsPerPage,
  sortType
}) => {
  return (
    <ListView
      feed={feed}
      filters={filters}
      pagination={pagination}
      placeHolderImage={PlaceHolderImage}
      setRowsPerPage={setRowsPerPage}
      sortType={sortType}
    />
  );
};

export default FeedView;
