import React, { useState } from 'react';
import { Checkbox, Radio, RadioGroup } from '@mui/material';
import { Constants, VARIANT } from 'utilities/constants';
import {
  ConfirmButton,
  DrawerTitle,
  LabelText,
  StyledDivider,
  StyledDrawer,
  StyledFormControlLabel
} from './styledComponent';
import { Option } from '../interfaces';

interface SelectionDrawerProps {
  onClose: () => void;
  // eslint-disable-next-line no-unused-vars
  onConfirm: (value: Option | Option[]) => void;
  open: boolean;
  options: Option[];
  title: string;
  maxSelection?: number;
}

export const SelectionDrawer: React.FC<SelectionDrawerProps> = ({
  onClose,
  onConfirm,
  open,
  options,
  title,
  maxSelection = 1
}) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const handleSelectionChange = (value: string) => {
    if (maxSelection === 1) {
      setSelectedOptions([value]);
    } else {
      setSelectedOptions((prevSelected) => {
        if (prevSelected.includes(value)) {
          return prevSelected.filter((option) => option !== value);
        } else if (prevSelected.length < maxSelection) {
          return [...prevSelected, value];
        }
        return prevSelected;
      });
    }
  };

  const handleConfirm = () => {
    if (selectedOptions.length > 0) {
      const selectedValues = options.filter((option) =>
        selectedOptions.includes(option.value)
      );
      onConfirm(maxSelection === 1 ? selectedValues[0] : selectedValues);
    }
  };

  return (
    <StyledDrawer
      anchor={VARIANT.BOTTOM}
      BackdropProps={{ invisible: true }}
      onClose={onClose}
      open={open}
      PaperProps={{ elevation: 1 }}
    >
      <DrawerTitle variant={VARIANT.H6}>{title}</DrawerTitle>
      <StyledDivider />
      <RadioGroup
        onChange={(e) => handleSelectionChange(e.target.value)}
        value={selectedOptions}
      >
        {options.map((option) => (
          <StyledFormControlLabel
            control={
              maxSelection === 1 ? (
                <Radio />
              ) : (
                <Checkbox
                  checked={selectedOptions.includes(option.value)}
                  onChange={() => handleSelectionChange(option.value)}
                />
              )
            }
            key={option.value}
            label={<LabelText noWrap>{option.label}</LabelText>}
            value={option.value}
          />
        ))}
      </RadioGroup>
      <ConfirmButton
        color={VARIANT.PRIMARY}
        onClick={handleConfirm}
        variant={VARIANT.CONTAINED}
      >
        {Constants.CONFIRM}
      </ConfirmButton>
    </StyledDrawer>
  );
};
