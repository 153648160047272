import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  styled,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import AvatarGroup from '@mui/material/AvatarGroup';
import { colorPalette } from 'theme';

const colorMapper = {
  0: colorPalette.red,
  1: colorPalette.forestGreen
};

const typeStyles = {
  0: {
    background: colorPalette.blushPink
  },
  1: {
    background: colorPalette.seaGreen
  }
};

const FONT_WEIGHT_700 = 700;
const FONT_WEIGHT_600 = 600;

interface StyledGridItemProps {
  isMediumScreen: boolean;
}

export const StyledTabIcon = styled(Avatar)<{
  isMediumScreen: boolean;
  active?: number;
}>(({ isMediumScreen, active }) => ({
  color: colorMapper[active],
  backgroundColor: typeStyles[active]?.background,
  height: isMediumScreen ? '1.5rem' : '1.75rem',
  width: isMediumScreen ? '1.5rem' : '1.75rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: isMediumScreen ? '0.75rem' : '0.875rem',
  fontWeight: FONT_WEIGHT_600,
  marginRight: '0.625rem'
}));

export const StyledTabLabelCustom = styled(Typography)<{
  active?: boolean;
}>(({ active }) => ({
  fontSize: '1.125rem',
  fontWeight: active ? FONT_WEIGHT_700 : FONT_WEIGHT_600,
  whiteSpace: 'nowrap',
  color: active && colorPalette.purple
}));

export const Highlight = styled(Box)({
  color: `${colorPalette.purple}`,
  cursor: 'pointer',
  display: 'inline-block',
  fontSize: '0.625rem',
  fontWeight: 700,
  textDecoration: 'underline',
  verticalAlign: 'baseline'
});

export const StyledTagsContainer = styled(Container)({
  backgroundColor: colorPalette.white,
  height: 'fit-content',
  padding: '0.75rem',
  width: '100%'
});

export const StyledTagsButtonContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column'
});

export const StyledTagButton = styled(Button)({
  fontWeight: 600,
  textTransform: 'capitalize'
});

export const StyledTabs = styled(Tabs)(() => ({
  width: '100%'
}));

export const StyledTabLabel = styled(Typography)(() => ({
  fontSize: '0.8125rem',
  fontWeight: 600,
  lineHeight: '1.2',
  whiteSpace: 'nowrap'
}));

export const StyledTab = styled(Tab)<{ active: boolean }>(({ active }) => ({
  alignItems: 'center',
  backgroundColor: active ? colorPalette.lavenderWhisper : 'white',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  minHeight: '3.9375rem !important',
  position: 'relative',
  textTransform: 'none',
  '& .tab-label': {
    color: colorPalette.grey,
    fontSize: '1rem',
    marginLeft: '0.5rem'
  },
  '&.Mui-selected': {
    '& .tab-label': {
      color: colorPalette.purple
    }
  }
}));

export const StyledText = styled(Typography)({
  cursor: 'pointer',
  fontSize: '0.875rem',
  fontWeight: 600,
  lineHeight: '1.2',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textTransform: 'capitalize',
  whiteSpace: 'nowrap',
  width: '6.875rem',
  ':hover': { textDecoration: 'underline' }
});

export const StyledIcon = styled(Avatar)({
  height: '0.625rem',
  width: '1.125rem'
});

export const CommunityAvatar = styled(Avatar)(() => ({
  height: '2.875rem',
  marginTop: '0.25rem',
  width: '2.875rem'
}));

export const StyledBoxContent = styled(Box)(({ theme }) => ({
  marginTop: '0',
  overflowY: 'auto',
  maxHeight: 'calc(100vh - 150px)',
  padding: '1rem',

  [theme.breakpoints.down('sm')]: {
    padding: '0.5rem'
  }
}));

export const StyledProblemButton = styled(Button)(() => ({
  backgroundColor: colorPalette.lightPurple,
  border: `0.125rem solid ${colorPalette.purple}`,
  borderRadius: '1.25rem',
  boxShadow: '0rem 0.25rem 0.375rem rgba(0, 0, 0, 0.1)',
  color: colorPalette.purple,
  fontSize: '1rem',
  fontWeight: 'bold',
  padding: '0.5rem 25px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: colorPalette.lightPurple
  }
}));

export const StyledGridItem = styled(Grid)<StyledGridItemProps>(
  ({ isMediumScreen, theme }) => ({
    borderRight: isMediumScreen
      ? 'none'
      : `1px solid ${colorPalette.whiteSilver}`,
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 70px)',
    padding: 0,
    backgroundColor: 'white',

    [theme.breakpoints.down('md')]: {
      borderRight: 'none'
    }
  })
);

export const StyledTagsSubContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between'
});

export const StyledTagsViewDivider = styled(Divider)({
  marginBottom: '0.5rem',
  marginTop: '0.5rem'
});

export const StyledStatsContent = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between'
});

export const StyledAvatarGroup = styled(AvatarGroup)({
  color: colorPalette.purple
});

export const OverflowAvater = styled(Avatar)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: colorPalette.purple,
  border: '0.125rem',
  color: 'white',
  cursor: 'pointer',
  display: 'flex',
  fontSize: '0.625rem',
  fontWeight: 'bold',
  height: theme.spacing(3),
  justifyContent: 'center',
  paddingLeft: '0.2rem',
  width: theme.spacing(3)
}));

export const StyledTypography = styled(Typography)({
  color: colorPalette.purple,
  fontSize: '0.75rem',
  fontWeight: '600'
});

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: colorPalette.lavenderPurple,
  border: '0.125rem',
  borderColor: colorPalette.white,
  color: 'inherit',
  cursor: 'pointer',
  height: theme.spacing(3),
  width: theme.spacing(3)
}));

export const StyledBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  gap: '0.625rem',
  marginTop: '0.25rem'
});

export const SolveButtonStyle = {
  backgroundColor: colorPalette.lightViolet,
  borderRadius: '1.25rem',
  boxShadow: '0rem 0.25rem 0.375rem rgba(0, 0, 0, 0.1)',
  color: colorPalette.white,
  fontSize: '1rem',
  fontWeight: 'bold',
  padding: '0.5rem 1.25rem',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: colorPalette.purple
  }
};
