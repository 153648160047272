import { ethers } from 'ethers';
import Web3Modal from 'web3modal';
import contract from './contract/NFT.json';
import marketContract from './contract/NFTMarket.json';
import rewardsContract from './contract/Rewards.json';
import Config from 'config/config';

export const WALLET_NETWORK = Config.NEXT_PUBLIC_WALLET_NETWORK || '';
export const NFT_NETWORK = Config.NEXT_PUBLIC_NFT_NETWORK || '';

const delay = async (time: number) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
};

export const chain = {
  POLYGON: 'polygon',
  SEPOLIA: 'sepolia',
  RINKEBY: 'rinkeby',
  MAINNET: 'mainnet',
  BINANCE: 'binance',
  BINANCE_TEST: 'binance testnet'
};

export const chainIds: Record<string, string> = {
  '1': 'mainnet',
  '3': 'ropsten',
  '4': 'rinkeby',
  '42': 'kovan',
  '97': 'binance testnet',
  '56': 'binance',
  '137': 'polygon',
  '11155111': 'sepolia'
};

export const chainNames: Record<string, string> = {
  mainnet: '1',
  ropsten: '3',
  rinkeby: '4',
  kovan: '42',
  'binance testnet': '97',
  binance: '56',
  polygon: '137',
  sepolia: '11155111'
};

export class WalletError extends Error {
  code?: string;
  neededName?: string;

  static codes = {
    MataMaskNotInstalled: 'MataMaskNotInstalled',
    WrongNetwork: 'WrongNetwork',
    TestNetwork: 'TestNetwork'
  };
}

export type MarketInfo = {
  networkRpc: string;
  contractAddress: string;
  contractMarketAddress: string;
};

export type MarketItem = {
  itemId: string;
  tokenId: string;
  description: string;
  image?: string;
  name: string;
  owner: string;
  price: string;
  seller: string;
  selling: boolean;
};

export type MarketItemDetailed = MarketItem & {
  app: {
    id: string | number;
    body?: string;
    owner?: string | number;
    ownerName?: string;
  };
};

export const WalletType = {
  WALLET_CONNECT: 'WALLET_CONNECT',
  PORTIS: 'PORTIS'
};

export class WalletProvider {
  private provider!: ethers.providers.Web3Provider;
  private oldValue: boolean | undefined;
  timer: any;

  constructor() {
    if (typeof window !== 'undefined') {
      // @ts-ignore
      window._walletProvider = this;

      // @ts-ignore
      // @ts-ignore
    }
  }

  async magicIsConnected() {
    try {
      // @ts-ignore
      await this.magic.wallet.getInfo();
      return true;
    } catch (err) {
      return false;
    }
  }

  async magicGetInfo() {
    try {
      const [address] = await this.provider.listAccounts();
      return { address };
    } catch (err) {
      return undefined;
    }
  }

  async getRewardsBalance(address: string) {
    try {
      const signer = this.provider.getSigner();

      const MyNFT = new ethers.Contract(
        Config.NEXT_PUBLIC_WALLET_ADDRESS || '',
        rewardsContract.abi,
        signer
      );

      const balanceBig = await MyNFT.balanceOf(address);
      const balanceStr = ethers.utils.formatUnits(balanceBig, 21);
      return parseInt(balanceStr);
    } catch (err) {
      console.error(err);
      return 0;
    }
  }

  async magicConnect() {
    try {
      const [address] = await this.provider.listAccounts();
      console.log(address);
    } catch (error) {
      console.log('Magic connection failed:', error);
    }
  }

  async magicDisconnect() {
    // @ts-ignore
    await this.magic.connect.disconnect();
  }

  async magicShowWallet() {
    try {
      // @ts-ignore
      await this.magic.wallet.showUI();
    } catch (err) {
      console.error(err);
    }
  }

  async magicShowWalletTokens() {
    try {
      // @ts-ignore
      await this.magic.wallet.showSendTokensUI();
    } catch (err) {
      console.error(err);
    }
  }
  async magicShowWalletInfo() {
    try {
      // @ts-ignore
      const data = await this.magic.wallet.getInfo();
      return data;
    } catch (err) {
      console.error(err);
    }
  }

  magicOn(cb: (connected: boolean) => void) {
    this.checkConnect(cb).catch((err) => {
      console.error(err);
    });
  }

  magicOff() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  async deployNft(
    contractAddress: string,
    tokenURI: string,
    transferAddress: string
  ): Promise<string[]> {
    try {
      if (!this.provider) {
        throw new Error('No NFT WalletConnect provider found');
      }
      const signer = this.provider.getSigner();

      const MyNFT = new ethers.Contract(contractAddress, contract, signer);

      const txn = await MyNFT.mintNFT(tokenURI);
      const res = await txn.wait();
      const event = res.events[0];
      const value = event.args[2];
      const tokenId = value.toNumber();

      await this.waitOverlayClose();

      return [res.transactionHash, tokenId];
    } catch (err) {
      console.error(err);
    }
    return [];
  }

  async buyNft(item: MarketItemDetailed, info: MarketInfo): Promise<void> {
    if (!this.provider) {
      throw new Error('No wallet provider found');
    }

    const signer = this.provider.getSigner();
    const contract = new ethers.Contract(
      info.contractMarketAddress,
      marketContract.abi,
      signer
    );

    const price = ethers.utils.parseUnits(item.price || '0.1', 'ether');
    const transaction = await contract.createMarketSale(
      info.contractAddress,
      item.tokenId || '1',
      {
        value: price
      }
    );
    await transaction.wait();
  }

  async sellNft(
    item: MarketItemDetailed,
    info: MarketInfo,
    priceStr: string
  ): Promise<void> {
    if (!this.provider) {
      throw new Error('No wallet provider found');
    }

    const signer = this.provider.getSigner();
    const contract = new ethers.Contract(
      info.contractMarketAddress,
      marketContract.abi,
      signer
    );

    const price = ethers.utils.parseUnits(priceStr, 'ether');
    const transaction = await contract.startMarketItemSell(
      info.contractAddress,
      item.itemId,
      price
    );
    await transaction.wait();
  }

  async sellNftCancel(
    item: MarketItemDetailed,
    info: MarketInfo
  ): Promise<void> {
    if (!this.provider) {
      throw new Error('No NFT WalletConnect provider found');
    }

    const signer = this.provider.getSigner();
    const contract = new ethers.Contract(
      info.contractMarketAddress,
      marketContract.abi,
      signer
    );

    const transaction = await contract.stopMarketItemSell(
      info.contractAddress,
      item.itemId
    );
    await transaction.wait();
  }

  async deployWithMetamask(
    contractAddress: string,
    tokenURI: string,
    transferAddress: string
  ): Promise<string[]> {
    try {
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();

      // Use the Web3Provider constructor and pass the injected provider
      const provider = new ethers.providers.Web3Provider(connection);
      const signer = provider.getSigner();

      const currentContract = new ethers.Contract(
        contractAddress,
        contract,
        signer
      );
      // Create a NFT
      const nftCreationToken = await currentContract.mintNFT(tokenURI);
      const response = await nftCreationToken.wait();
      const event = response.events[0];
      // extract token Id
      const value = event.args[2];
      const tokenId = value.toNumber();

      // Transferring NFT ownership to current user

      return [response.transactionHash, tokenId];
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  private async waitOverlayClose(): Promise<void> {
    // @ts-ignore
    if (this.magic.connect.overlay.activeElement) {
      await delay(2000);
      return this.waitOverlayClose();
    }
  }

  private async checkConnect(cb: (connected: boolean) => void) {
    const newValue = await this.magicIsConnected();
    if (newValue !== this.oldValue) {
      this.oldValue = newValue;
      cb(newValue);
    }
  }
}

export const walletProvider = new WalletProvider();

export default walletProvider;
