import { Box, styled, Typography } from '@mui/material';
import { colorPalette } from 'theme';

export const StyledCrossIconBox = styled(Box)({
  background: colorPalette.purple,
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%'
});

export const StyledHeaderBox = styled(Box)({
  alignItems: 'center',
  background: colorPalette.purple,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
});

export const SubCardWrapper = styled(Box)({
  marginBottom: '.8125rem',
  width: '86%'
});

export const WhiteSpace = styled(Box)({
  height: '5rem'
});

export const SubsWrapper = styled(Box)({
  padding: '1.25rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '100%',
  marginTop: '-5.3125rem'
});

export const StyledHeaderTypography = styled(Typography)({
  color: colorPalette.white,
  fontSize: '1.625rem',
  fontWeight: 700,
  marginTop: '1.25rem'
});

export const StyledSubHeaderTypography = styled(Typography)({
  color: colorPalette.white,
  fontSize: '0.78rem',
  fontWeight: 400,
  lineHeight: 1.2
});
