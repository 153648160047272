import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Box, Typography, IconButton, Modal } from '@mui/material';
import { Inventory } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { CustomMenu } from 'components/CustomMenu';
import AuthContext, { formTypes } from 'contexts/AuthContext';
import getQueryParams from 'helpers/getQueryParams';

import {
  GetProfileAgreements,
  GetProfileAgreementsCount
} from 'redux-state/selectors';
import Actions from 'redux-state/actions';
import { Constants } from 'utilities/constants';
import { DateText, StyledBox, StyledMainBox } from './styles';
import { Application } from '../CardApplication';
import { Problem } from '../CardProblem';
import { Solution } from '../CardSolution';
import { Profile } from '../CardProfile';
import { GetActions } from '../CustomMenu/actions';
import { getHeaders } from '../TableView/getHeaders';
import { ListItemView } from '../ListItemView';
import TableView from '../TableView';
import { ToolTip } from '../ToolTip';
import DataNotFound from '../DataNotFound';
import AgreementIcon from 'assets/icons/file.svg';
import { AgreementViewProps, ListItemViewProps } from './interface';

const AgreementView: React.FC<AgreementViewProps> = ({ profile }) => {
  const dispatch = useDispatch();

  const agreements = GetProfileAgreements();
  const agreementsCount = GetProfileAgreementsCount();
  const { user, setAuthModalOpened } = useContext(AuthContext);

  const paramsPage = Number(getQueryParams(Constants.PAGE));
  const paramsPerPage = Number(getQueryParams(Constants.PER_PAGE));

  const [page, setPage] = useState<number>(paramsPage ?? 0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(paramsPerPage || 5);
  const pagination = useMemo(
    () => ({
      page: page,
      perPage: rowsPerPage
    }),
    [page, rowsPerPage]
  );
  const isCurrentUser = !!(user && (user.id === profile.id || user.isAdmin));
  const hasEmptyData = !!(agreementsCount === 0);

  useEffect(() => {
    if (profile) {
      dispatch(Actions.getProfileAgreements(profile.id, pagination));
    }
  }, [dispatch, profile.id, pagination]);

  useEffect(() => {
    if (paramsPage && paramsPerPage) {
      setPage(paramsPage);
      setRowsPerPage(paramsPerPage);
    }
  }, [paramsPage, paramsPerPage]);

  const Item = useCallback(
    ({ type, problems, solutions, id }: ListItemViewProps) => {
      return (
        <ListItemView
          id={id}
          problems={problems}
          solutions={solutions}
          type={type}
          useSameTab={true}
        />
      );
    },
    []
  );

  const headers: Array<string> = useMemo(() => {
    return getHeaders(Constants.AGREEMENT, user, isCurrentUser);
  }, [isCurrentUser, user]);

  const getActions = useMemo(() => {
    return (item: Application) => {
      return GetActions({
        item,
        profile,
        type: Constants.USER_AGREEMENTS,
        isFiled: false,
        pagination
      });
    };
  }, [pagination, profile]);

  const handlePdfPreview = (url: string) => {
    window.open(url, '_blank');
  };

  const getCells = useMemo(() => {
    return (icon, item) => {
      const formattedDate = new Intl.DateTimeFormat('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }).format(new Date(item.createdAt));
      const baseCells = {
        name: (
          <Item id={item._id} solutions={[item.additional]} type="solutions" />
        ),
        url: (
          <Box width={250}>
            <IconButton
              color="primary"
              onClick={() => handlePdfPreview(item.fileUrl)}
            >
              <Typography>{Constants.PREVIEW_PDF}</Typography>
            </IconButton>
          </Box>
        ),
        date: <DateText>{formattedDate}</DateText>
      };
      if (isCurrentUser) {
        baseCells['actions'] = (
          <CustomMenu
            actions={getActions(item)}
            isFiled={false}
            item={item}
            itemType={Constants.USER_AGREEMENTS}
            pagination={pagination}
          />
        );
      }
      return baseCells;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Item, isCurrentUser, getActions, pagination]);

  const rows = {
    component: (icon, item) => getCells(icon, item),
    items: agreements,
    pinnedItems: []
  };

  return (
    <StyledMainBox>
      <StyledBox>
        {agreements.length === 0 && (
          <DataNotFound
            iconUrl={AgreementIcon}
            text={Constants.USER_AGREEMENTS}
          />
        )}
        {!hasEmptyData && agreements.length > 0 && (
          <TableView
            headers={headers}
            page={page}
            rows={rows}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            totalRows={agreementsCount}
            type={Constants.USER_AGREEMENTS}
          />
        )}
      </StyledBox>
    </StyledMainBox>
  );
};

export { AgreementView };
