import React from 'react';
import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const Root = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: '84vh',
  justifyContent: 'center'
});

export const Logo = styled('img')(({ theme }) => ({
  marginBottom: theme.spacing(3)
}));
