// StakingSetupDialog.js
import React from 'react';
import { Button, DialogActions, DialogContent } from '@mui/material';
import {
  StyledDialog,
  StyledDialogTitle,
  StyledDialogContentText,
  StyledTextField,
  StyledButton,
  StyledTypography
} from './StyledComponents';
import { Constants, VARIANT } from 'utilities/constants';

const StakingSetupDialog = ({
  isOpen,
  onClose,
  formData,
  isThresholdValid,
  handleInputChange,
  handleCreateStaking,
  basicThreshold
}) => {
  return (
    <StyledDialog open={isOpen} onClose={onClose}>
      <StyledDialogTitle>Start Staking</StyledDialogTitle>
      <DialogContent>
        <StyledDialogContentText>
          <StyledTypography>
            {Constants.BASED_ON_QUOTE}
            {Constants.PRICE_PER_PRODUCT}
            {Constants.USD_SYMBOL}
            {basicThreshold}
          </StyledTypography>
        </StyledDialogContentText>
        <StyledTextField
          margin={Constants.DENSE}
          label={Constants.NUMBER_OF_PRODUCTS_WITH_SPACE}
          name={Constants.NUMBER_OF_PRODUCTS}
          type={VARIANT.NUMBER}
          fullWidth
          variant={VARIANT.OUTLINED}
          value={formData.numberOfProducts}
          onChange={handleInputChange}
          placeholder={Constants.ENTER_NUMBER_OF_PRODUCTS}
          inputProps={{ min: 1, step: 1 }}
        />
        <StyledTextField
          margin={Constants.DENSE}
          label={Constants.TOTAL_STAKING_THRESHOLD_WITH_SPACE}
          name={Constants.TOTAL_STAKING_THRESHOLD}
          type={VARIANT.NUMBER}
          fullWidth
          variant={VARIANT.OUTLINED}
          value={formData.stakingThreshold}
          InputProps={{ readOnly: true }}
          error={!isThresholdValid}
          helperText={
            !isThresholdValid
              ? `${Constants.THRESHOLD_CAN_NOT_BE_LESS_THAN} ${basicThreshold}`
              : ''
          }
        />
      </DialogContent>
      <DialogActions>
        <StyledButton
          onClick={handleCreateStaking}
          color={VARIANT.PRIMARY}
          variant={VARIANT.CONTAINED}
          disabled={!isThresholdValid}
        >
          Start Staking
        </StyledButton>
        <Button onClick={onClose} color={VARIANT.SECONDARY}>
          Cancel
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default StakingSetupDialog;
