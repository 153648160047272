import {
  ValidateFormType,
  DownloadPatentSteps,
  ModalDataValuesType
} from 'contexts/ModalDataContext';
import AcceptConditionsStep from './steps/AcceptConditionsStep';
import DownloadStep from './steps/DownloadStep';
import { StepComponentProps } from './steps/types';

interface DownloadPatentStepsConfig {
  backButtonTitle?: string;
  Component: React.FC<StepComponentProps>;
  isFinalStep?: boolean;
  isNextStepBlocked?: ((data: { termsAgree: boolean }) => boolean) | boolean;
  modalTitle?: string;
  name?: string;
  nextButtonTitle?: string;
  nextStep?: string;
  onAsyncSubmit?: (
    data: ModalDataValuesType
  ) => Promise<ModalDataValuesType | any>;
  validate?: (data: { termsAgree: boolean }) => ValidateFormType;
}
export const DOWNLOAD_PATENT_STEPS: {
  [name: string]: DownloadPatentStepsConfig;
} = {
  [DownloadPatentSteps.AcceptConditionsStep]: {
    Component: AcceptConditionsStep,
    isNextStepBlocked: (data) => {
      return !data.termsAgree;
    },
    nextStep: DownloadPatentSteps.DownloadStep,
    validate: (data): ValidateFormType => {
      if (!data.termsAgree) {
        return {
          success: false,
          errors: { termsAgree: 'Terms required' }
        };
      }
      return { success: true };
    }
  },
  [DownloadPatentSteps.DownloadStep]: {
    Component: DownloadStep,
    isFinalStep: true,
    isNextStepBlocked: (data) => {
      return false;
    },
    validate: (data): ValidateFormType => {
      return { success: true };
    }
  }
};
