import React, { useCallback } from 'react';
import { Select } from 'components/Select';
import { useStyles } from '../../../utilities/Styles';
import { Box, SelectChangeEvent, Typography } from '@mui/material';

const dropdownOptions = {
  ACTIVE_AND_EXPIRED: [
    {
      label: 'Active',
      value: 'all-active'
    },
    {
      label: 'Expired',
      value: 'all-expired'
    }
  ],
  CHALLENGE: [
    {
      label: 'Current challenges',
      value: 'current'
    },
    {
      label: 'All time challenges',
      value: 'all'
    }
  ],
  PER_PAGE_ITEMS: [
    {
      label: '5',
      value: 5
    },
    {
      label: '10',
      value: 10
    },
    {
      label: '15',
      value: 15
    }
  ],
  RATING: [
    {
      label: 'Top last 24 hours rated',
      value: 'votes1d'
    },
    {
      label: 'Top last 48 hours rated',
      value: 'votes2d'
    },
    {
      label: 'Top last week rated',
      value: 'votes7d'
    },
    {
      label: 'Top last month rated',
      value: 'votes30d'
    },
    {
      label: 'Top rated',
      value: 'votes'
    },
    {
      label: 'Date of creation',
      value: 'createdAt'
    }
  ]
};

export const enum SearchFilterTypes {
  ACTIVE_AND_EXPIRED = 'ACTIVE_AND_EXPIRED',
  CHALLENGE = 'CHALLENGE',
  PER_PAGE_ITEMS = 'PER_PAGE_ITEMS',
  RATING = 'RATING'
}

interface ISearchFilters {
  onChange: (e: any) => void;
  options?: { value: string | number; label: string }[];
  title?: string;
  type: SearchFilterTypes;
  value: string | number;
}

export const SearchFilter = (props: ISearchFilters) => {
  const {
    onChange,
    options,
    title,
    type = 'ACTIVE_AND_EXPIRED',
    value
  } = props;

  const {
    select: { classes }
  } = useStyles();

  return (
    <Box className={classes.itemsSelectWrapper}>
      <Typography component={'span'}>{title}</Typography>
      <Select
        classes={classes.itemsSelect}
        onChange={onChange}
        options={dropdownOptions[type] || options}
        value={value}
      />
    </Box>
  );
};
