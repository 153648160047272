import React, { useState, useContext, useCallback, useEffect } from 'react';
import { styled, Theme, useTheme } from '@mui/material/styles';

import Card from '@mui/material/Card';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';

import { PsButton } from 'components/common/PsButton';
import { AuthContext } from 'contexts/AuthContext';
import { DataContext } from 'contexts/DataContext';
import { PsTheme } from '../../theme';
import { makeStyles } from 'tss-react/mui';

type Term = {
  type: string;
  title: string;
  body?: string;
};

type ClassKey =
  | 'root'
  | 'holder'
  | 'card'
  | 'closeBtn'
  | 'title'
  | 'text'
  | 'buttons'
  | 'loading';

const useStyles = makeStyles()((theme) => {
  const psTheme = theme as PsTheme;
  return {
    root: {},
    holder: {
      position: 'relative',
      display: 'flex',
      height: '100%',
      padding: 20,
      alignItems: 'flex-start',
      justifyContent: 'center',
      overflow: 'auto'
    },
    card: {
      overflow: 'visible',
      padding: '37px 24px 22px',
      borderRadius: 15,
      position: 'relative',
      width: '100%',
      maxWidth: 650,
      boxShadow:
        'inset 32.6px -32.6px 32.6px rgba(194, 194, 194, 0.176), inset -32.6px 32.6px 32.6px rgba(255, 255, 255, 0.176)',
      [psTheme.breakpoints.down('sm')]: {
        margin: '0 auto'
      }
    },
    closeBtn: {
      position: 'absolute',
      top: 15,
      right: 15
    },
    title: {
      fontSize: 30,
      lineHeight: '30px',
      fontWeight: 600,
      marginTop: -10,
      marginBottom: 10
    },
    text: {
      marginBottom: 20
    },
    buttons: {
      '& > button': {
        width: '100%'
      }
    },
    loading: {
      textAlign: 'center'
    }
  };
});

type TermsPopupProps = {
  classes?: any;
};

const TermsPopupView = () => {
  const { user, termsModalOpened, setTermsModalOpened } =
    useContext(AuthContext);
  const { dataProvider } = useContext(DataContext);
  const [term, setTerm] = useState<Term | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [termHtml, setTermHtml] = useState<{ __html: string }>({ __html: '' });

  const { classes } = useStyles();

  useEffect(() => {
    if (!termsModalOpened) {
      setTerm(undefined);
      setTermHtml({ __html: '' });
      return;
    }

    setLoading(true);

    dataProvider
      .getList<Term>('terms', {
        pagination: { page: 1, perPage: 1 },
        sort: { field: 'createdAt', order: 'DESC' },
        filter: {
          type: termsModalOpened
        }
      })
      .then(({ data }) => {
        setTerm(data[0]);
        setTermHtml({ __html: data[0] ? data[0].body || '' : '' });
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [termsModalOpened]);

  const onCloseClick = useCallback(
    (e?: React.MouseEvent) => {
      if (e) {
        e.preventDefault();
      }
      setTermsModalOpened('');
    },
    [setTermsModalOpened]
  );

  return (
    <Modal className={classes.root} open={!!termsModalOpened}>
      <div className={classes.holder}>
        <Card className={classes.card} elevation={3}>
          <CardContent>
            {!term ? (
              <div className={classes.loading}>Loading...</div>
            ) : (
              <div>
                <Typography className={classes.title}>{term.title}</Typography>
                <Typography
                  className={classes.text}
                  component="div"
                  dangerouslySetInnerHTML={termHtml}
                />
                <div className={classes.buttons}>
                  <PsButton
                    color="secondary"
                    type="submit"
                    small
                    onClick={onCloseClick}
                  >
                    Agree
                  </PsButton>
                </div>
              </div>
            )}
            <IconButton
              className={classes.closeBtn}
              size="small"
              onClick={onCloseClick}
            >
              <CloseIcon />
            </IconButton>
          </CardContent>
        </Card>
      </div>
    </Modal>
  );
};

export const TermsPopup = styled(TermsPopupView)({});

export default TermsPopup;
