import React from 'react';
import { Box } from '@mui/material';
import { useIsMediumScreen } from 'theme';
import { Constants, VARIANT } from 'utilities/constants';
import {
  StyledActionButtonContainer,
  StyledAddToIconContainer,
  StyledButtonMainContainer,
  StyledButtonTextContainer
} from './StyledComponents';
import { HexagonalAddToIcon } from '../icons/HexagonalAddToIcon';

interface RenderAddToConceptBtnProps {
  disabled?: boolean;
  itemType: string;
  onClick: () => void;
}

export const RenderAddToConceptBtn: React.FC<RenderAddToConceptBtnProps> = ({
  disabled = false,
  itemType,
  onClick
}) => {
  const isMediumScreen = useIsMediumScreen();

  return (
    <StyledButtonMainContainer
      disabled={disabled}
      isMediumScreen={isMediumScreen}
      itemType={itemType}
      onClick={onClick}
      variant={VARIANT.CONTAINED}
    >
      <StyledActionButtonContainer>
        <StyledButtonTextContainer>
          <Box sx={{ ml: 0.5 }}>
            {disabled ? Constants.ADDED_TO_CONCEPT : Constants.ADD_TO_CONCEPT}
          </Box>
        </StyledButtonTextContainer>
        <StyledAddToIconContainer>
          <HexagonalAddToIcon />
        </StyledAddToIconContainer>
      </StyledActionButtonContainer>
    </StyledButtonMainContainer>
  );
};
