import React from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { PsChip } from 'components/common/PsChipNew';
import { PsTheme } from '../../theme';
import useRouter from 'hooks/useRouter';
import { makeStyles } from 'tss-react/mui';

export type ChipListItem = {
  key: string;
  type?: string;
  name?: string;
};

const useStyles = makeStyles()((theme) => {
  const psTheme = theme as PsTheme;
  return {
    root: {
      '& > div': {
        maxWidth: '100%',
        fontWeight: 600
      }
    },
    more: {
      position: 'relative',
      top: -2,
      fontWeight: 700,
      whiteSpace: 'nowrap',
      color: psTheme.palette.primary.main
    }
  };
});

type ChipListProps = {
  className?: string;
  list: Array<ChipListItem>;
  max?: number;
  showAll?: boolean;
  getUrl?: (item: ChipListItem) => string;
  getName?: (item: ChipListItem) => string;
  getColor?: (item: ChipListItem) => string;
  onShowAllTagsClick?: (e: React.MouseEvent) => void;
};

const ChipListView = ({
  className,
  list = [],
  max = 0,
  showAll,
  getUrl,
  getName,
  getColor,
  onShowAllTagsClick
}: ChipListProps) => {
  const { classes } = useStyles();
  const router = useRouter();
  const rest = list.length - max;

  if (!list.length) {
    return null;
  }

  let rootClassName = classes.root;
  if (className) {
    rootClassName += ' ' + className;
  }

  return (
    <div className={rootClassName}>
      {list.map((item, i) => {
        const onClick = () => {
          const url = getUrl ? getUrl(item) : '';
          window.open(url, '_blank');
        };
        const name = getName ? getName(item) : '';
        const color = getColor ? getColor(item) : '';

        if (max && i >= max && !showAll) {
          return null;
        }

        return (
          <PsChip
            className="chip_margin_top"
            key={item.key}
            label={name}
            title={name}
            textColor={color}
            onClick={(e) => {
              e.stopPropagation();
              onClick();
            }}
            size="small"
            style={{ fontWeight: 'bold' }}
          />
        );
      })}
      {rest > 0 && (
        <PsChip
          className="chip_margin_top"
          label={showAll ? `-${rest}` : `+${rest}`}
          onClick={onShowAllTagsClick}
          size="small"
          style={{ fontWeight: 'bold', cursor: 'pointer' }}
        />
      )}
    </div>
  );
};

export const ChipList = styled(ChipListView)({
  fontWeight: '700'
});

export default ChipList;
