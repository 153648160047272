import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { useIsMediumScreen } from 'theme';
import { Constants, VARIANT } from 'utilities/constants';
import {
  StyledActionButtonContainer,
  StyledButtonEarnPointsTextContainer,
  StyledButtonMainContainer,
  StyledButtonTextContainer
} from './StyledComponents';

interface RenderActionButtonProps {
  itemType: string;
  onClick: () => void;
}

export const RenderActionButton: React.FC<RenderActionButtonProps> = ({
  itemType,
  onClick
}) => {
  const isMediumScreen = useIsMediumScreen();
  const typeToButtonTextMapper = useMemo(
    () => ({
      [Constants.PROBLEM]: Constants.SOLVE,
      [Constants.APPLICATION]: Constants.IMPROVE,
      [Constants.SOLUTION]: Constants.IMPROVE,
      [Constants.PRODUCT]: Constants.IMPROVE,
      [Constants.CREATE]: Constants.C_CREATE
    }),
    []
  );

  const typeToButtonEarnIdeaPoints = useMemo(
    () => ({
      [Constants.PROBLEM]: Constants.EARN_IDEAPOINTS,
      [Constants.APPLICATION]: Constants.EARN_IDEAPOINTS,
      [Constants.PRODUCT]: Constants.EARN_IDEAPOINTS,
      [Constants.CREATE]: Constants.EARN_IDEAPOINTS
    }),
    []
  );

  return (
    <StyledButtonMainContainer
      itemType={itemType}
      onClick={onClick}
      variant={VARIANT.CONTAINED}
      isMediumScreen={isMediumScreen}
    >
      <StyledActionButtonContainer>
        <StyledButtonTextContainer>
          <Box sx={{ ml: 0.5 }}>{typeToButtonTextMapper[itemType]}</Box>
        </StyledButtonTextContainer>
        <StyledButtonEarnPointsTextContainer>
          {typeToButtonEarnIdeaPoints[itemType]}
        </StyledButtonEarnPointsTextContainer>
      </StyledActionButtonContainer>
    </StyledButtonMainContainer>
  );
};
