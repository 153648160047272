import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid, LinearProgress, styled } from '@mui/material';
import { AgreementView } from 'components/AgreementView';
import { Application } from 'components/CardApplication';
import {
  Profile,
  ProfileStats,
  ProfileStatsItems,
  ProfileStatsRest
} from 'components/CardProfile';
import { PsHttpError } from 'components/common/PsHttpError';
import { CommunitiesListView } from 'components/CommunitiesListView';
import { ConceptView } from 'components/ConceptView';
import { ContestsView } from 'components/ContestsView';
import Helmet from 'components/Helmet/index';
import { InventionView } from 'components/InventionView';
import { ProblemsView } from 'components/ProblemView';
import { UnlockYourRoyalties } from 'components/UnlockYourRoyalties';
import UserProfileCover from 'components/ProfileHeader/ProfileCover/UserProfileCover';
import UserProfileDetail from 'components/ProfileHeader/ProfileDetail/UserProfileDetail';
import RankingBar from 'components/RankingBar/RankingBar';
import { SolutionsView } from 'components/SolutionView';
import TabsView from 'components/TabsView';
import { SideMenu } from 'layout';
import Config from 'config/config';
import { AuthContext } from 'contexts/AuthContext';
import { DataContext } from 'contexts/DataContext';
import ModalDataContext from 'contexts/ModalDataContext';
import { FetchError } from 'fetchUtils';
import { finalizeType } from 'helpers';
import { getIPAddress } from 'helpers/getIPAddress';
import getQueryParams from 'helpers/getQueryParams';
import AgreementDocModal from 'modals/AgreementDocument/AgreementDocModal';

import {
  GetEditProfileLoader,
  GetFilePatentLoader,
  GetUser,
  GetUserLoading
} from 'redux-state/selectors';
import { colorPalette, useIsMediumScreen } from 'theme';
import { Constants } from 'utilities/constants';
import NotFoundPage from 'pages/error';
import useRouter from 'hooks/useRouter';
import { useDispatch } from 'react-redux';
import { getPublicProfile } from 'redux-state/onboarding/actions';

const HeadInfo = ({ profile }: { profile?: Profile }) => {
  if (!profile) {
    return null;
  }

  const image = (profile.files || []).find((url: any) => !!url);
  const imageUrl = image ? image.url : 'assets/placeholder/profile.svg';
  const url = 'assets/profiles/' + (profile.key || profile.id);
  const title = profile.username || 'Profile';
  const description = `Create solutions with ${title} on MindMiner`;
  const keywords = (profile.tagsInfo || [])
    .map((tag: any) => tag.name)
    .join(', ');

  return (
    <header>
      <title>MindMiner - {title}</title>
      <meta name="keywords" content={keywords} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
    </header>
  );
};

const TagDetailsView = () => {
  const { id: key } = useParams();
  const conceptId = getQueryParams('id');
  const currentTab = getQueryParams('currentTab');
  const page = getQueryParams('page');
  const perPage = getQueryParams('perPage');

  const dispatch = useDispatch();

  const loginUser = GetUser();
  const profileEditLoader = GetEditProfileLoader();
  const patentFileLoader = GetFilePatentLoader();
  const getUserLoading = GetUserLoading();

  const isMediumScreen = useIsMediumScreen();

  const { user, loading: userLoading } = useContext(AuthContext);
  const { dataProvider } = useContext(DataContext);
  const { updateField, values } = useContext(ModalDataContext);

  const [activeTab, setActiveTab] = useState<string>(Constants.PROBLEMS);
  const [application, setApplication] = useState<Application | undefined>();
  const [archiveStatus, setArchiveStatus] = useState(false);
  const [error, setError] = useState<FetchError | undefined>();
  const [isCurrentUser, setIsCurrentUser] = useState(false);
  const [loading, setLoading] = useState(true);
  const [payProductId, setPayProductId] = useState<string>('');
  const [profile, setProfile] = useState<Profile | undefined>();
  const [showErrorPage, setShowErrorPage] = useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [signedUrl, setSignedUrl] = useState<string>('');
  const [statsItems, setStatsItems] = useState<ProfileStatsItems | undefined>();
  const [statsRest, setStatsRest] = useState<ProfileStatsRest | undefined>();
  const [tabs, setTabs] = useState([]);
  const [viewOwnProfile, setViewOwnProfile] = useState<boolean>(false);

  const handleArchiveToggle = (archive) => {
    if ((archiveStatus && archive) || (!archiveStatus && !archive)) return;
    setArchiveStatus(archive);
  };

  useEffect(() => {
    if (user && loginUser && (user.id === loginUser.id || user.isAdmin)) {
      setIsCurrentUser(true);
    }
  }, [user, loginUser]);

  const isAiProfile = profile?.email?.includes('@mm-test.com');
  const allTabs = useMemo(() => {
    setViewOwnProfile(profile?.key === user?.key);

    return [
      {
        content: (
          <ProblemsView
            profile={profile}
            archiveStatus={archiveStatus}
            handleArchiveToggle={handleArchiveToggle}
          />
        ),
        key: Constants.PROBLEMS,
        title: viewOwnProfile ? Constants.MY_PROBLEMS : Constants.C_PROBLEMS
      },
      {
        content: (
          <SolutionsView
            profile={profile}
            archiveStatus={archiveStatus}
            handleArchiveToggle={handleArchiveToggle}
          />
        ),
        key: Constants.SOLUTIONS,
        title: viewOwnProfile ? Constants.MY_SOLUTIONS : Constants.C_SOLUTIONS
      },
      !isAiProfile &&
        loginUser &&
        viewOwnProfile && {
          content: (
            <ConceptView
              profile={loginUser}
              archiveStatus={archiveStatus}
              handleArchiveToggle={handleArchiveToggle}
            />
          ),
          key: Constants.CONCEPTS,
          title: Constants.MY_CONCEPTS
        },
      !isAiProfile && {
        content: (
          <InventionView
            profile={profile}
            archiveStatus={archiveStatus}
            handleArchiveToggle={handleArchiveToggle}
          />
        ),
        key: Constants.INVENTIONS,
        title: viewOwnProfile ? Constants.MY_INVENTIONS : Constants.C_INVENTIONS
      },
      !isAiProfile && {
        content: (
          <ContestsView
            profile={profile}
            archiveStatus={archiveStatus}
            handleArchiveToggle={handleArchiveToggle}
          />
        ),
        key: Constants.CONTESTS,
        title: viewOwnProfile ? Constants.MY_CONTESTS : Constants.C_CONTESTS
      },
      !isAiProfile &&
        loginUser &&
        viewOwnProfile && {
          content: (
            <AgreementView
              profile={profile}
              archiveStatus={archiveStatus}
              handleArchiveToggle={handleArchiveToggle}
            />
          ),
          key: Constants.AGREEMENTS,
          title: Constants.MY_AGREEMENTS
        }
    ].filter(Boolean);
  }, [profile, archiveStatus, loginUser?.id, viewOwnProfile, user?.id]);

  useEffect(() => {
    const initialTabKey = currentTab || Constants.PROBLEMS;
    const getTabIndexByKey = (key: string, tabs: any[]) =>
      tabs.findIndex((tab) => tab.key === key);
    setActiveTab(
      allTabs[getTabIndexByKey(initialTabKey, allTabs)]?.key ||
        Constants.PROBLEMS
    );
  }, [allTabs, currentTab]);

  useEffect(() => {
    if (!userLoading && profile) {
      setTabs(
        viewOwnProfile
          ? allTabs
          : allTabs.filter((tab) => tab.key !== Constants.CONCEPTS)
      );
    }
  }, [allTabs, profile, userLoading, viewOwnProfile]);

  useEffect(() => {
    if (conceptId) {
      dataProvider
        .getOne<Application>('applications', { id: conceptId as string })
        .then((data) => {
          setApplication(data.data);
          updateField('application', data.data);
        })
        .catch(console.error)
        .finally(() => setLoading(false));
    }
  }, [conceptId, dataProvider, updateField]);

  useEffect(() => {
    if (payProductId || !loginUser) return;
    dataProvider
      .getPayOptions()
      .then((list) => {
        const payProduct = list.find((item) => item.type === finalizeType.OWN);
        setPayProductId((payProduct?.id ?? '') as string);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [dataProvider, payProductId, loginUser]);

  useEffect(() => {
    if (!user || !application) return;
    const showAgreementDoc = new URLSearchParams(window.location.search)?.get(
      'showAgreementDoc'
    );
    if (showAgreementDoc) setShowSuccessModal(true);
  }, [application, setShowSuccessModal, user]);

  const getUserProfile = async () => {
    const { data: profileData } = await dataProvider.getOneByKey<Profile>(
      Constants.PROFILES,
      { key: key as string }
    );

    if (!profileData) setShowErrorPage(true);
    setProfile(profileData);
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  useEffect(() => {
    if (profile && !patentFileLoader) {
      setLoading(true);
      Promise.all([
        dataProvider.getProfileStats<ProfileStats>({ id: profile.id }, 'items'),
        dataProvider.getProfileStats<ProfileStats>({ id: profile.id }, 'rest')
      ])
        .then(([itemsData, restData]) => {
          setStatsItems(itemsData.data);
          setStatsRest(restData.data);
        })
        .catch((error) => setError(error.message))
        .finally(() => setLoading(false));
    }
  }, [profile, patentFileLoader]);

  const onSave = async (sign: string) => {
    setLoading(true);
    const ipAddress = await getIPAddress();

    if (sign && ipAddress) {
      const appId = values.application ? values.application.id : '';
      dataProvider
        .payOptionSign(appId, sign, ipAddress)
        .then((data) => {
          dataProvider
            .getOne<Application>('applications', { id: appId })
            .then(({ data1 = {} }: any) => {
              const paginationParams =
                page && perPage ? `&page=${page}&perPage=${perPage}` : '';
              updateField('payProducts', data1.products || []);
              setSignedUrl(data.link);
              setShowSuccessModal(false);
              window.location.href = `${Config.NEXT_PUBLIC_CLIENT_URL}${Constants.PROFILES}/${loginUser.key}?currentTab=${Constants.CONCEPTS}${paginationParams}&id=${conceptId}`;
            })
            .catch(console.error);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    }
  };

  if (showErrorPage) {
    return <NotFoundPage />;
  }
  const router = useRouter();

  const { id } = router.query;

  useEffect(() => {
    if (id) {
      dispatch(getPublicProfile(id as string));
    }
  }, [dispatch, id]);

  return (
    <>
      <Helmet title="Profiles" />
      <HeadInfo profile={loginUser} />
      {!isMediumScreen && (
        <SideMenu active={Constants.ALL} isHomePage={false} />
      )}
      {getUserLoading && <LinearProgress value={10} />}(
      <StyledContainer isUserLoading={loading}>
        {profileEditLoader && <LinearProgress value={10} />}
        <StyledCardContainer>
          {loading && <LinearProgress value={10} />}
          <PsHttpError error={error} />
          {profile && !loading && tabs.length ? (
            <MainContainer isMediumScreen={isMediumScreen}>
              <StyledLeftBox isMediumScreen={isMediumScreen}>
                <UserProfileCover />
                <UserProfileDetail />
                {!isAiProfile && <RankingBar isCurrentUser={isCurrentUser} />}
                <TabsView
                  activeTab={activeTab}
                  archiveStatus={archiveStatus}
                  handleArchiveToggle={handleArchiveToggle}
                  setActiveTab={setActiveTab}
                  setArchiveStatus={setArchiveStatus}
                  tabs={tabs}
                  viewOwnProfile={viewOwnProfile}
                />
              </StyledLeftBox>
              <StyledRightBox isMediumScreen={isMediumScreen}>
                <StyledSideBarContainer>
                  <UnlockYourRoyalties />
                  <CommunitiesListView />
                </StyledSideBarContainer>
              </StyledRightBox>
            </MainContainer>
          ) : null}
          <AgreementDocModal
            loading={loading}
            onSave={onSave}
            setLoading={setLoading}
            setShowDocModal={setShowSuccessModal}
            showDocModal={showSuccessModal}
            signedUrl={signedUrl}
            applicationId={values?.application?.id}
          />
        </StyledCardContainer>
      </StyledContainer>
      )
    </>
  );
};

const MainContainer = styled(Grid)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    display: 'flex',
    flexDirection: isMediumScreen ? 'column' : 'row',
    gap: '0.9375rem',
    margin: '0 auto',
    paddingLeft: '0.625rem',
    width: '100%'
  })
);

const StyledContainer = styled(Box)<{ isUserLoading: boolean }>(
  ({ isUserLoading }) => ({
    background: colorPalette.ashWhite,
    border: '1px solid #E8ECF0',
    display: 'flex',
    filter: isUserLoading ? 'blur(8px)' : 'none',
    flexDirection: 'column',
    height: '100%',
    padding: '0px 0px 48px 80px',
    pointerEvents: isUserLoading ? 'none' : 'auto',
    userSelect: isUserLoading ? 'none' : 'auto',
    width: '100%',
    '@media (max-width:900px)': {
      padding: '0px'
    }
  })
);

const StyledLeftBox = styled(Box)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    width: isMediumScreen ? '100%' : '75%',
    overflowX: 'hidden'
  })
);

const StyledRightBox = styled(Box)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    width: isMediumScreen ? '100%' : '25%'
  })
);

const StyledSideBarContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  paddingRight: '0.625rem'
});

const StyledCardContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap'
});

const TagDetails = styled(TagDetailsView)({});

export default TagDetails;
