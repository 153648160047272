import {
  GET_PRODUCTS,
  GET_PRODUCTS_SUCCESS,
  GET_COMPANIES,
  GET_COMPANIES_SUCCESS,
  GET_TAGS_SUCCESS,
  GET_TAGS,
  GET_TAGS_LOADER,
  GET_LOCATION_TAGS_SUCCESS,
  GET_WORKPLACE_TAGS_SUCCESS,
  GET_LOCATION_TAGS,
  GET_WORKPLACE_TAGS,
  GET_UNIVERSITY_TAGS_SUCCESS,
  GET_RELATED_TAGS_SUCCESS
} from './types';

const INITIAL_STATE = {
  loading: false,
  products: [],
  companies: [],
  tags: [],
  locationTags: [],
  locationTagsCount: 0,
  tagsCount: 0,
  tagsLoader: false,
  workplaceTags: [],
  workplaceTagsCount: 0,
  universityTags: [],
  universityTagsCount: 0,
  relatedTags: [],
  relatedTagsCount: 0
};

const reducer = (
  state = INITIAL_STATE,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case GET_PRODUCTS:
      return { ...state, loading: true };
    case GET_PRODUCTS_SUCCESS:
      return { ...state, products: action.payload, loading: false };
    case GET_COMPANIES:
      return { ...state, loading: true };
    case GET_TAGS:
      return { ...state, loading: true };
    case GET_COMPANIES_SUCCESS:
      return { ...state, companies: action.payload, loading: false };
    case GET_TAGS_LOADER:
      return { ...state, tagsLoader: action.payload };
    case GET_TAGS_SUCCESS:
      return {
        ...state,
        loading: false,
        tags: action.payload.data,
        tagsCount: action.payload.total
      };
    case GET_LOCATION_TAGS:
      return { ...state, loading: true };
    case GET_WORKPLACE_TAGS:
      return { ...state, loading: true };
    case GET_LOCATION_TAGS_SUCCESS:
      return {
        ...state,
        loading: false,
        locationTags: action.payload.data,
        locationTagsCount: action.payload.total
      };
    case GET_WORKPLACE_TAGS_SUCCESS:
      return {
        ...state,
        loading: false,
        workplaceTags: action.payload.data,
        workplaceTagsCount: action.payload.total
      };
    case GET_UNIVERSITY_TAGS_SUCCESS:
      return {
        ...state,
        loading: false,
        universityTags: action.payload.data,
        universityTagsCount: action.payload.total
      };
    case GET_RELATED_TAGS_SUCCESS:
      return {
        ...state,
        loading: false,
        relatedTags: action.payload.data,
        relatedTagsCount: action.payload.total
      };
    default:
      return state;
  }
};

export default reducer;
