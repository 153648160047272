import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { fabric } from 'fabric';
import {
  Box,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import AuthContext from 'contexts/AuthContext';
import PsButton from 'components/common/PsButton';
import { BillingAddressType } from '../../../src/authProvider';
import dataProvider from '../../../src/dataPrvider';
import { GetUser } from 'redux-state/selectors';

export type SignaturePadProps = {
  onSave: any;
  loading: boolean;
  signedUrl: string;
  onClose: any;
};

type AddressFieldsProps = {
  address: BillingAddressType;
  onAddressChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const useStyles = makeStyles()(() => {
  return {
    field: {
      fontWeight: '600'
    }
  };
});

const AddressFields = ({ address, onAddressChange }: AddressFieldsProps) => {
  const { classes } = useStyles();
  const {
    city = '',
    country = '',
    line1 = '',
    line2 = '',
    postalCode = '',
    state = ''
  } = address;
  return (
    <Box className={classes.field}>
      <Typography variant="overline" component="span">
        Address
      </Typography>
      <TextField
        fullWidth
        label="Line 1"
        margin="normal"
        name="line1"
        onChange={onAddressChange}
        value={line1}
        variant="outlined"
      />
      <TextField
        fullWidth
        label="Line 2"
        margin="normal"
        name="line2"
        onChange={onAddressChange}
        value={line2}
        variant="outlined"
      />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="City"
            margin="normal"
            name="city"
            onChange={onAddressChange}
            value={city}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Postal Code"
            margin="normal"
            name="postalCode"
            onChange={onAddressChange}
            value={postalCode}
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Country"
            margin="normal"
            name="country"
            onChange={onAddressChange}
            value={country}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="State"
            margin="normal"
            name="state"
            onChange={onAddressChange}
            value={state}
            variant="outlined"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const SignaturePad = ({
  loading,
  onClose,
  onSave,
  signedUrl
}: SignaturePadProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const canvasRef = useRef<fabric.Canvas | null>(null);
  const user = GetUser();

  const [address, setAddress] = useState<BillingAddressType>(
    {} as unknown as BillingAddressType
  );

  const handleAddressChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setAddress((prevAddress: BillingAddressType) => ({
        ...prevAddress,
        [name]: value
      }));
    },
    []
  );

  useEffect(() => {
    if ((address && address.country) || !user) return;
    if (user?.id) {
      dataProvider
        .getPrimaryBillingAddress(user.id)
        .then((res: BillingAddressType) => {
          setAddress(res);
        });
    }
  }, [address, user]);

  const state = useRef<{
    initiated: boolean;
    canvasFabric: fabric.Canvas | undefined;
  }>({
    initiated: false,
    canvasFabric: undefined
  });

  const [sign, setSign] = useState<string>('');
  const [agreement, setAgreement] = useState<boolean>(false);

  useEffect(() => {
    if (state.current.initiated) {
      return;
    }
    state.current.initiated = true;
    const canvasFabric = new fabric.Canvas('drawCanvas', {
      width: containerRef.current?.clientWidth || 1000,
      height: 200,
      backgroundColor: '#fff'
    });

    canvasFabric.on('object:modified', handleCanvasChange);
    canvasFabric.on('object:added', handleCanvasChange);

    canvasFabric.isDrawingMode = true;
    canvasFabric.freeDrawingBrush = new fabric.PencilBrush(canvasFabric);
    canvasFabric.freeDrawingBrush.color = '#000';
    canvasFabric.freeDrawingBrush.width = 3;
    canvasFabric.renderAll();

    state.current.canvasFabric = canvasFabric;
  }, [containerRef]);

  const handleCanvasChange = () => {
    const canvas = state.current.canvasFabric;
    if (canvas) {
      const sign = canvas.toDataURL();
      setSign(sign);
    }
  };

  const onContainerRef = (ref: HTMLDivElement | null) => {
    containerRef.current = ref;
  };

  const removeSign = () => {
    const canvas = canvasRef.current;
    if (state.current.canvasFabric) {
      state.current.canvasFabric.clear().renderAll();
      canvas?.clear().renderAll();
      setSign('');
    }
  };

  const Text = ({ label, value }: any) => {
    return (
      <>
        <Typography variant="subtitle1" component="span" color="textSecondary">
          {label}
        </Typography>
        <Typography
          variant="subtitle2"
          component="span"
          style={{ marginBottom: 10 }}
        >
          {value}
        </Typography>
      </>
    );
  };

  const onSubmit = useCallback(
    (sign) => {
      const { _id, ...rest } = address;
      dataProvider.updateBillingAddress(_id, rest);
      onSave(sign);
    },
    [address, onSave]
  );

  const AddressFieldMarkup = useMemo(
    () => (
      <AddressFields address={address} onAddressChange={handleAddressChange} />
    ),
    [address, handleAddressChange]
  );

  return (
    <div
      style={{
        background: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: 20
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Text label={'Name:'} value={user?.username} />
        <Text label={'Email:'} value={user?.email} />
      </div>
      <div>
        <div className="sign_header_container">
          <Typography
            variant="overline"
            component="span"
            style={{ marginBottom: 10 }}
          >
            Signature
          </Typography>
          {!loading && !signedUrl && (
            <IconButton
              aria-label="delete"
              className="delete_icon_imp"
              onClick={removeSign}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            flex: 0.4,
            flexDirection: 'column',
            border: '1px solid grey'
          }}
        >
          <div ref={onContainerRef}>
            <canvas id="drawCanvas" />
          </div>
        </div>
      </div>
      {AddressFieldMarkup}
      <div style={{ alignItems: 'flex-start' }}>
        {!loading && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <Checkbox
              className="sign_check_box"
              size="small"
              checked={agreement}
              onChange={() => setAgreement(!agreement)}
              name="checkedA"
            />
            <Typography variant="caption" component="span">
              I acknowledge that I have read the document and have signed it.
            </Typography>
          </div>
        )}
        <PsButton
          smallest
          className="submit_margin_top_imp"
          fullWidth
          disabled={!agreement || sign == '' || loading}
          onClick={() => (signedUrl ? onClose() : onSubmit(sign))}
        >
          {loading ? (
            <CircularProgress size={20} color="inherit" />
          ) : signedUrl ? (
            'Close'
          ) : (
            'Submit'
          )}
        </PsButton>
      </div>
    </div>
  );
};

export default SignaturePad;
