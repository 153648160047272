import React, { useContext, useState, useCallback, useEffect } from 'react';
import { Problem } from 'components/CardProblem';
import PsFormControl, { ShortEvent } from 'components/common/PsFormControl';
import { PsInput } from 'components/common/PsInput';
import { PsSelect, PsOptionType } from 'components/common/PsSelect';
import {
  ModalDataContext,
  StepComponentType,
  StepComponentProps
} from 'contexts/ModalDataContext';
import { DataContext } from 'contexts/DataContext';
import ProblemModal from 'modals/AddProblem';
import styles from 'modals/ModalWindow.module.scss';

export const defaultInventionTitle = 'The invention of ';

const ApplicationName: StepComponentType<StepComponentProps> = ({
  onNotify
}: StepComponentProps) => {
  const { values, errors, updateField, handleInputChange } =
    useContext(ModalDataContext);
  const { dataProvider } = useContext(DataContext);
  const [title, setTitle] = useState(values.titleApplication);
  const [mainProblem, setMainProblem] = useState(values.mainProblem);
  const [problems, setProblems] = useState<Array<PsOptionType>>([]);
  const [isProblemModalOpen, setIsProblemModalOpen] = useState<boolean>(false);

  let helperText = '';
  if (errors && errors.titleApplication) {
    helperText = errors.titleApplication;
  }

  useEffect(() => {
    if (!values.titleApplication) {
      updateField('titleApplication', defaultInventionTitle);
      setTitle(defaultInventionTitle);
    }
    const first = {
      value: '--create--',
      label: `Create New Problem`,
      raw: ''
    };
    dataProvider
      .getList<Problem>('problems', {
        pagination: { page: 1, perPage: 50000 },
        sort: { field: 'title', order: 'ASC' },
        filter: { $custom: { type: 'unlimit' } }
      })
      .then(({ data = [] }) => {
        const list: Array<PsOptionType> = data.map((item) => ({
          value: `${item.id}`,
          label: item.title || '(empty)'
        }));
        setProblems([first, ...list]);
      })
      .catch(() => {
        setProblems([first]);
      });
  }, []);

  const onTitleChange = useCallback(
    (e: ShortEvent) => {
      const { value = '' } = e.target;
      const rawVal = value.slice(defaultInventionTitle.length);
      const finalTitle = `${defaultInventionTitle}${rawVal}`;
      setTitle(finalTitle);
      updateField('titleApplication', finalTitle);
    },
    [setTitle, updateField]
  );

  const onMainProblemChange = useCallback(
    (e: ShortEvent) => {
      if (e.target.value && e.target.value.startsWith('--create--')) {
        setIsProblemModalOpen(true);
        handleInputChange({
          target: { value: undefined, name: e.target.name }
        });
        setMainProblem(undefined);
        //
        return;
      }
      handleInputChange(e);
      setMainProblem(e.target.value);
    },
    [setMainProblem, handleInputChange]
  );

  const onMainProblemInputChange = (text: string) => {
    const first = problems[0];
    const isFirstCreate = first && first.value.startsWith('--create--');

    if (text) {
      const first = {
        value: `--create--, ${text}`,
        label: `Create New Problem`,
        raw: text
      };
      if (isFirstCreate) {
        const [, ...rest] = problems;
        setProblems([first, ...rest]);
      } else {
        setProblems([first, ...problems]);
      }
    }
  };

  const handleCloseProblemModal = () => {
    setIsProblemModalOpen(false);
  };

  return (
    <div className={styles.stepWrapper}>
      <PsFormControl
        placeholder="Name of invention"
        label="What is the name of your invention?"
        helperText={helperText}
        error={!!errors.titleApplication}
      >
        <PsInput
          name="titleApplication"
          value={title}
          onChange={onTitleChange}
        />
      </PsFormControl>

      <PsFormControl
        placeholder="Main problem"
        label="What is the main problem your invention solves?"
      >
        <PsSelect
          name="mainProblem"
          options={problems}
          maxMenuHeight={270}
          onChange={onMainProblemChange}
          onInputChange={onMainProblemInputChange}
          value={mainProblem}
        />
      </PsFormControl>
      {isProblemModalOpen && (
        <ProblemModal
          open={isProblemModalOpen}
          onClose={handleCloseProblemModal}
        />
      )}
    </div>
  );
};

export default ApplicationName;
