export const AI_ANSWER_SEARCH = 'AI_ANSWER_SEARCH';
export const AI_ANSWER_SEARCH_SUCCESS = 'AI_ANSWER_SEARCH_SUCCESS';
export const CREATE_EMPLOYEE_PROFILE = 'CREATE_EMPLOYEE_PROFILE';
export const CREATE_EMPLOYEE_PROFILE_SUCCESS =
  'CREATE_EMPLOYEE_PROFILE_SUCCESS';
export const DOWNLOAD_PATENT_APP = 'DOWNLOAD_PATENT_APP';
export const DOWNLOAD_PATENT_APP_SUCCESS = 'DOWNLOAD_PATENT_APP_SUCCESS';
export const EDIT_TAG = 'EDIT_TAG';
export const EDIT_TAG_SUCCESS = 'EDIT_TAG_SUCCESS';
export const GET_AI_IMAGE = 'GET_AI_IMAGE';
export const GET_AI_IMAGE_SUCCESS = 'GET_AI_IMAGE_SUCCESS';
export const GET_AI_TITLE = 'GET_AI_TITLE';
export const GET_AI_TITLE_SUCCESS = 'GET_AI_TITLE_SUCCESS';
export const GET_EMPLOYEES = 'GET_EMPLOYEES';
export const GET_EMPLOYEES_SUCCESS = 'GET_EMPLOYEES_SUCCESS';
export const GET_FEED = 'GET_FEED';
export const GET_FEED_SUCCESS = 'GET_FEED_SUCCESS';
export const GET_RELATED_ITEMS = 'GET_RELATED_ITEMS';
export const GET_RELATED_ITEMS_SUCCESS = 'GET_RELATED_ITEMS_SUCCESS';
export const GET_TAG = 'GET_TAG';
export const GET_OWNED_COMMUNITY = 'GET_OWNED_COMMUNITY';
export const GET_TAGS_BY_USER_ID_SUCCESS = 'GET_TAGS_BY_USER_ID_SUCCESS';
export const GET_TAG_FOLLOWERS = 'GET_TAG_FOLLOWERS';
export const GET_TAG_FOLLOWERS_SUCCESS = 'GET_TAG_FOLLOWERS_SUCCESS';
export const GET_TAG_SUCCESS = 'GET_TAG_SUCCESS';
export const GET_TAG_USERS = 'GET_TAG_USERS';
export const GET_TAG_USERS_SUCCESS = 'GET_TAG_USERS_SUCCESS';
export const UNLINK_TAG = 'UNLINK_TAG';
export const UPDATE_ERROR_STATUS = 'UPDATE_ERROR_STATUS';
export const VOTE_ITEM = 'VOTE_ITEM';
export const GET_RELATED_COMPANIES = 'GET_COMPANY_PRODUCTS';
export const OPEN_AUTH_MODAL = 'OPEN_AUTH_MODAL';
export const SET_RELATED_COMPANIES = 'SET_COMPANY_PRODUCTS';
export const GET_RELATED_COMPANIES_SUCCESS = 'GET_COMPANY_PRODUCTS_SUCCESS';
export const GET_FOLLOWING_TAGS_COUNT = 'GET_FOLLOWING_TAGS_COUNT';
export const GET_FOLLOWING_TAGS_COUNT_SUCCESS =
  'GET_FOLLOWING_TAGS_COUNT_SUCCESS';
export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAILURE = 'GET_PRODUCT_FAILURE';
export const GET_PROBLEM = 'GET_PROBLEM';
export const GET_PROBLEM_SUCCESS = 'GET_PROBLEM_SUCCESS';
export const GET_PROBLEM_FAILURE = 'GET_PROBLEM_FAILURE';
export const GET_RELATION_PROMPT = 'GET_RELATION_PROMPT';
export const GET_RELATION_PROMPT_SUCCESS = 'GET_RELATION_PROMPT_SUCCESS';
export const CALCULATE_MULTIPLIER_PTS = 'CALCULATE_MULTIPLIER_IDEAPOINTS';
export const CALCULATE_MULTIPLIER_PTS_SUCCESS =
  'CALCULATE_MULTIPLIER_IDEAPOINTS_SUCCESS';
export const CALCULATE_MULTIPLIER_PTS_FAILURE =
  'CALCULATE_MULTIPLIER_IDEAPOINTS_FAILURE';
export const TOGGLE_SUBSCRIPTION_MODAL = 'TOGGLE_SUBSCRIPTION_MODAL';
export const TOGGLE_CREDITS_TOPUP_MODAL = 'TOGGLE_CREDITS_TOPUP_MODAL';
