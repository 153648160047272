import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useDispatch } from 'react-redux';
import { Inventory } from '@mui/icons-material';
import { Box, IconButton, LinearProgress } from '@mui/material';
import ProductIcon from 'assets/icons/products.svg';
import ArchiveToggleButton from 'components/ArchiveToggleButton';
import AuthContext from 'contexts/AuthContext';
import Actions from 'redux-state/actions';
import {
  GetProfileContests,
  GetProfileContestsCount,
  GetProfileContestsLoader,
  GetProfilePinnedContests,
  GetUser
} from 'redux-state/selectors';
import { Constants } from 'utilities/constants';
import { Contest } from '../CardContest';
import { Problem } from '../CardProblem';
import { Profile } from '../CardProfile';
import { Solution } from '../CardSolution';
import { CustomMenu } from '../CustomMenu';
import { GetActions } from '../CustomMenu/actions';
import DataNotFound from '../DataNotFound';
import { LinkView } from '../LinkView';
import { ListItemView } from '../ListItemView';
import TableView from '../TableView';
import { getHeaders } from '../TableView/getHeaders';
import { ToolTip } from '../ToolTip';
import { ArrowIconButton } from '../common/ArrowIcon';
import { ButtonContainer, StyledBox, StyledMainBox } from './styles';
interface ItemListProps {
  archiveStatus: boolean;
  handleArchiveToggle?: (archive: boolean) => void;
  profile: Profile;
}

interface ListItemViewProps {
  expandedId: string;
  id: string;
  problems?: Array<Problem>;
  setExpandedId: React.Dispatch<React.SetStateAction<boolean>>;
  solutions?: Array<Solution>;
  type: 'problems' | 'solutions';
}

const ContestsView: React.FC<ItemListProps> = ({
  profile,
  archiveStatus,
  handleArchiveToggle
}) => {
  const user = GetUser();
  const [expandedId, setExpandedId] = useState();
  const [expandedIcon, setExpandedIcon] = useState<boolean>(true);

  const toggleExpandText = (id, expandedIcon) => {
    setExpandedId((prevId) => (prevId !== id ? id : null));
    setExpandedIcon(!expandedIcon);
  };

  const dispatch = useDispatch();

  const contests = GetProfileContests();
  const pinnedContests = GetProfilePinnedContests();
  const contestsLoader = GetProfileContestsLoader();
  const contestsCount = GetProfileContestsCount();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const pagination = useMemo(
    () => ({
      page: page,
      perPage: rowsPerPage
    }),
    [page, rowsPerPage]
  );

  const isCurrentUser = !!(user && (user.id === profile.id || user.isAdmin));
  const hasEmptyData = !!(contestsCount === 0 && pinnedContests?.length === 0);

  useEffect(() => {
    if (profile) {
      dispatch(
        Actions.getProfileContests(profile.id, pagination, null, archiveStatus)
      );
    }
  }, [dispatch, pagination, profile, archiveStatus]);

  useEffect(() => {
    if (contests?.length < 1 || archiveStatus) {
      setPage(0);
    }
  }, [contests, archiveStatus]);

  const Item = useCallback(
    ({
      type,
      problems,
      solutions,
      id,
      expandedId,
      setExpandedId
    }: ListItemViewProps) => {
      return (
        <ListItemView
          problems={problems}
          solutions={solutions}
          type={type}
          id={id}
          expandedId={expandedId}
          setExpandedId={setExpandedId}
        />
      );
    },
    []
  );

  const headers: Array<string> = useMemo(() => {
    return getHeaders('Contest', user, isCurrentUser);
  }, [isCurrentUser, user]);

  const getActions = useMemo(() => {
    return (item: Contest) => {
      return GetActions({
        item,
        profile,
        type: 'contests',
        pagination
      });
    };
  }, [pagination, profile]);

  const handleUnarchiveToggle = useCallback(
    (item) => {
      if (user) {
        dispatch(
          Actions.setArchiveItem(
            item._id,
            'contests',
            false,
            profile.id,
            false,
            pagination
          )
        );
      } else {
        dispatch(Actions.openAuthModal(true));
      }
    },
    [user, dispatch, profile, pagination]
  );

  const getCustomItem = (item) => {
    return item.problem ? [item.problem] : item.problems;
  };

  const getCells = useMemo(() => {
    return (icon, item) => {
      const baseCells = {
        icon: (
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => toggleExpandText(item._id, expandedIcon)}
          >
            <ArrowIconButton
              expandedId={expandedId}
              id={item._id}
            ></ArrowIconButton>
          </IconButton>
        ),
        item: (
          <LinkView
            id={item.key}
            title={item.title}
            type={Constants.CONTESTS}
            useSameTab={true}
            reRoute={(item.status !== Constants.DRAFT) as boolean}
          />
        ),
        problem: (
          <Box>
            <Item
              expandedId={expandedId}
              id={item._id}
              problems={getCustomItem(item)}
              setExpandedId={setExpandedId}
              type="problems"
            />
            <Item
              expandedId={expandedId}
              id={item._id}
              setExpandedId={setExpandedId}
              solutions={item.solutions}
              type="solutions"
            />
          </Box>
        )
      };
      if (isCurrentUser) {
        baseCells['actions'] = item.isArchived ? (
          <Box onClick={() => handleUnarchiveToggle(item)}>
            <ToolTip
              color="primary"
              Icon={<Inventory color="primary" />}
              title="Unarchive"
            />
          </Box>
        ) : (
          <CustomMenu
            actions={getActions(item)}
            isArchived={archiveStatus}
            item={item}
            itemType={'contests'}
            pagination={pagination}
            title="Contest"
          />
        );
      }

      return baseCells;
    };
  }, [
    expandedId,
    Item,
    isCurrentUser,
    expandedIcon,
    getActions,
    pagination,
    handleUnarchiveToggle
  ]);

  const rows = {
    component: (icon, item) => getCells(icon, item),
    items: contests,
    pinnedItems: pinnedContests
  };

  return (
    <StyledMainBox>
      <ButtonContainer>
        <ArchiveToggleButton
          archiveStatus={archiveStatus}
          handleArchiveToggle={handleArchiveToggle}
        />
      </ButtonContainer>
      <StyledBox>
        {contestsLoader && <LinearProgress value={contestsCount} />}
        {!contestsLoader && hasEmptyData && (
          <DataNotFound iconUrl={ProductIcon} text="contest" />
        )}
        {!hasEmptyData && (
          <TableView
            expandedId={expandedId}
            headers={headers}
            page={page}
            rows={rows}
            rowsPerPage={rowsPerPage}
            setExpandedId={setExpandedId}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            totalRows={contestsCount - pinnedContests.length}
            type={'contests'}
          />
        )}
      </StyledBox>
    </StyledMainBox>
  );
};

export { ContestsView };
