import React, { useCallback, useContext } from 'react';
import ModalWindow from 'modals/ModalWindow';
import { PsButton } from 'components/common/PsButton';
import { ModalContext } from 'contexts/ModalContext';
import { ModalDataContext } from 'contexts/ModalDataContext';
import { PsLogo } from 'components/common/PsLogo';
import styles from 'modals/SuccessModal/SuccessModal.module.scss';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const BrainstormSolutionSuccess: React.FC = () => {
  const { closeModal } = useContext(ModalContext);
  const { values, resetFields } = useContext(ModalDataContext);

  const theme = useTheme();
  const handleClick = useCallback(() => {
    closeModal();
    resetFields();
  }, [closeModal, resetFields]);

  const improveName =
    values.brainMethod?.toLowerCase() + ' ' + values.brainObject?.toLowerCase();

  return (
    <ModalWindow title="Congratulations!" titleCenter>
      <div className={styles.wrapper}>
        <div className={styles.iconHolder}>
          <PsLogo size={90} color={theme.palette.primary.main} small />
          <Typography className={styles.iconHolderPrimary}>
            <span>
              <b>+2</b> IdeaCoins{' '}
            </span>
            Earned!
          </Typography>
        </div>
        <br />
        <br />
        <Typography className={styles.textWide}>
          You improved the solution of <b>{values.parentSolutionLabel}</b> to
          the problem of <b>{values.parentProblemLabel}</b> with the improvement
          of <b>{improveName}</b>.
        </Typography>
        <PsButton onClick={handleClick} fullWidth>
          Close
        </PsButton>
      </div>
    </ModalWindow>
  );
};

export default BrainstormSolutionSuccess;
