import React, { useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { CoinIcon } from 'components/icons/CoinIcon';
import { StepperNav } from 'components/VerticalStepper';
import { Application } from 'components/CardApplication';
import { DataContext } from 'contexts/DataContext';
import { ModalContext } from 'contexts/ModalContext';
import { PsTheme } from '../../theme';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      marginLeft: 60
    }
  };
});

export type NextStepsApplicationProps = {
  application: Application;
};

const NextStepsApplicationView = ({
  application
}: NextStepsApplicationProps) => {
  const { openInventionBuildPatent } = useContext(ModalContext);
  const { showSharePopup } = useContext(DataContext);
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      {' '}
      <StepperNav
        steps={[
          {
            stepContent: () => (
              <div style={{ fontSize: 18 }}>Invention Created</div>
            ),
            stepStatusCircleSize: 20,
            stepStateColor: '#031F46'
          },
          {
            stepContent: () => (
              <div
                style={{
                  fontSize: 18,
                  color: '#632DDD',
                  fontWeight: 600
                }}
              >
                <CoinIcon color="#632DDD" />{' '}
                <a style={{ marginLeft: '.5rem' }}></a> Add additional solutions
                to the invention
              </div>
            ),
            stepStatusCircleSize: 20,
            stepStateColor: 'white'
          },
          {
            stepContent: () => (
              <div style={{ fontSize: 18 }}>
                Complete details for each solution
              </div>
            ),
            stepStatusCircleSize: 20,
            stepStateColor: 'white'
          },
          {
            stepContent: () => (
              <div
                style={{
                  fontSize: 18,
                  color: '#632DDD',
                  fontWeight: 600
                }}
              >
                <CoinIcon color="#632DDD" />{' '}
                <a
                  onClick={() => {
                    openInventionBuildPatent({ application });
                  }}
                  style={{ marginLeft: '.5rem' }}
                >
                  Mint idea NFT to make patent pending
                </a>
              </div>
            ),
            stepStatusCircleSize: 20,
            stepStateColor: 'white'
          },
          {
            stepContent: () => (
              <div
                style={{
                  fontSize: 18,
                  color: '#632DDD',
                  fontWeight: 600
                }}
              >
                <CoinIcon color="#632DDD" />{' '}
                <a
                  onClick={() => {
                    showSharePopup({
                      application,
                      tagInfo: application?.tagsInfo
                    });
                  }}
                  style={{ marginLeft: '.5rem' }}
                >
                  Share protected invention to get improvements
                </a>
              </div>
            ),
            stepStatusCircleSize: 20,
            stepStateColor: 'white'
          },
          {
            stepContent: () => (
              <div style={{ fontSize: 18 }}>
                Sell your idea NFT to earn crypto
              </div>
            ),
            stepStatusCircleSize: 20,
            stepStateColor: 'white'
          }
        ]}
      />
    </div>
  );
};

export const NextStepsApplication = styled(NextStepsApplicationView)({});

export default NextStepsApplication;
