import {
  Button,
  Divider,
  Drawer,
  Typography,
  FormControlLabel,
  styled
} from '@mui/material';

export const StyledDrawer = styled(Drawer)({
  '& .MuiDrawer-paper': {
    borderBottomLeftRadius: '5px',
    borderTopLeftRadius: '5px',
    boxSizing: 'border-box',
    marginLeft: 'auto',
    marginRight: '8px',
    padding: '24px',
    width: '64%'
  }
});

export const StyledDivider = styled(Divider)({
  marginTop: '9px',
  marginBottom: '9px'
});

export const StyledFormControlLabel = styled(FormControlLabel)({
  maxWidth: '100%'
});

export const DrawerTitle = styled(Typography)({
  fontWeight: 'bold',
  textAlign: 'left'
});

export const LabelText = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
});

export const ConfirmButton = styled(Button)({
  marginTop: '24px',
  padding: '12px',
  width: '100%'
});
