/* eslint-disable */
import React, { useCallback } from 'react';
import {
  CheckCircleOutline,
  HistoryToggleOff,
  Lock
} from '@mui/icons-material';
import {
  Box,
  Grid,
  styled,
  TableCell,
  tableCellClasses,
  TableRow,
  Typography
} from '@mui/material';
import { Application } from 'components/CardApplication';
import { Contest } from 'components/CardContest';
import { Problem } from 'components/CardProblem';
import { Concept, Product } from 'components/CardProduct';
import { Profile } from 'components/CardProfile';
import { Solution } from 'components/CardSolution';
import { TagInfo } from 'components/CardTag';
import { UserAgreement } from 'components/common/Types';
import { ExpandText } from 'components/ExpandText';
import { LogoMindminer } from 'components/icons/LogoMindminer';
import { LinkView } from 'components/LinkView';
import { ListItemView } from 'components/ListItemView';
import { Transaction } from 'components/MyWallet/tabs/TransactionsView';
import { StatusView } from 'components/StatusView';
import { ChipList } from 'components/Tag';
import { ToolTip } from 'components/ToolTip';
import { getBasePath } from 'helpers/common';
import { colorPalette, useIsMediumScreen } from 'theme';
import { Constants } from 'utilities/constants';
import {
  StyledBox,
  StyledIdeapointsBox,
  StyledIdeapointsTypography,
  StyledParentBox,
  StyledStatusTypography,
  StyledTypography
} from './StyledComponents';

const StyledTableCell = styled(TableCell)({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 12,
    verticalAlign: 'bottom',
    padding: '0.625rem 1.25rem 0.625rem 0rem',
    background: colorPalette.lightShadow
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    verticalAlign: 'top',
    padding: '.9375rem .25rem .9375rem'
  }
});

interface ExpandedRowContentProps {
  expandedId?: string;
  setExpandedId?: React.Dispatch<React.SetStateAction<boolean>>;
  item?:
    | Application
    | Concept
    | Contest
    | Problem
    | Product
    | Profile
    | Solution
    | UserAgreement
    | Transaction;
}

export function ExpandedRowContent({
  expandedId,
  setExpandedId,
  item
}: ExpandedRowContentProps) {
  const getStatusViewProps = useCallback((application) => {
    if (application?.envelopeId) {
      return {
        backgroundColor: colorPalette.skin,
        color: colorPalette.softOrange,
        status: Constants.AWAITING_MINDMINER_PATENT_FILING
      };
    }
    if (application?.isFiled || application?.isDownloaded) {
      return {
        backgroundColor: colorPalette.paleBlue,
        color: colorPalette.steelBlue,
        status: application.isFiled
          ? Constants.MARKED_AS_PATENT_FILED
          : Constants.AWAITING_YOUR_PATENT_FILING
      };
    }

    return null;
  }, []);

  const getStatus = useCallback(
    (items) => {
      const statusProps = getStatusViewProps(items);

      return statusProps ? (
        <StatusView {...statusProps} />
      ) : (
        <Typography color={colorPalette.dimBlack}>{Constants.NA}</Typography>
      );
    },
    [getStatusViewProps]
  );

  const isMediumScreen = useIsMediumScreen();

  return (
    <TableRow>
      <StyledTableCell padding="none" colSpan={4}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={5} lg={5}>
            <Box sx={{ marginLeft: '7%' }}>
              <ListItemView
                boxWidth={isMediumScreen ? 300 : 550}
                expandedId={expandedId}
                id={item.id}
                setExpandedId={setExpandedId}
                solutions={item?.solutions}
                problems={item?.problem || item?.problems}
                type={Constants.SOLUTIONS}
                useSameTab={true}
                textWidth={1000}
              />
            </Box>
          </Grid>

          {item?.tagsInfo?.length > 0 && (
            <Grid item xs={12} sm={4} md={3} lg={4}>
              <StyledBox>
                <ChipList
                  list={item?.tagsInfo}
                  max={10}
                  getName={(tag) => {
                    const name = (tag as TagInfo).name || '';
                    return name;
                  }}
                  getUrl={(tagUrl) => {
                    const basePath = getBasePath(tagUrl);
                    return `/${basePath}/${
                      (tagUrl as TagInfo).key || (tagUrl as TagInfo).id
                    }`;
                  }}
                />
              </StyledBox>
            </Grid>
          )}

          <Grid item xs={12} sm={2} md={4} lg={3}>
            <StyledBox>
              <StyledIdeapointsBox>
                <LogoMindminer
                  fill={colorPalette.purple}
                  width={20}
                  height={20}
                />
                <StyledIdeapointsTypography>
                  {item.ideaPoints ?? 0}
                </StyledIdeapointsTypography>
                <StyledTypography>IdeaPoints Generated</StyledTypography>
              </StyledIdeapointsBox>
            </StyledBox>
          </Grid>
        </Grid>
      </StyledTableCell>
    </TableRow>
  );
}
