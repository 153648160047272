import { all, call, spawn } from '@redux-saga/core/effects';
import graphSaga from './ideamap/saga';
import homeSaga from './home/saga';
import profileSaga from './profile/saga';
import commonsSaga from './commons/saga';
import contestViewSaga from './contestView/saga';
import onboardingSaga from './onboarding/saga';
import rewardsSaga from './rewards/saga';
import shareSaga from './share/saga';

function* rootSaga() {
  const sagas = [
    commonsSaga,
    contestViewSaga,
    graphSaga,
    homeSaga,
    onboardingSaga,
    profileSaga,
    rewardsSaga,
    shareSaga
  ];

  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            console.log(e);
          }
        }
      })
    )
  );
}

export default rootSaga;
