import React, { useContext, useState } from 'react';
import { styled, Theme } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';

import { AuthContext } from 'contexts/AuthContext';
import { PsButton } from 'components/common/PsButton';
import { PsLogo } from 'components/common/PsLogo';
import { PsNFT } from 'components/common/PsNFT';
import { WarningDialog } from 'components/common/WarningDialog';
import { PsTheme } from '../../theme';
import { makeStyles } from 'tss-react/mui';
import { GetUser } from 'redux-state/selectors';

type ClassKey = 'actionButton' | 'iconNft' | 'iconNftCoins';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    actionButton: {
      fontSize: 20,
      minWidth: '38.5%',
      marginRight: 27,
      borderRadius: 15,
      '& .MuiButton-label': {
        textAlign: 'center'
      },
      [psTheme.breakpoints.down('xs')]: {
        marginBottom: 10,
        marginRight: 0
      }
    },
    iconNft: {
      marginLeft: -10,
      marginRight: 10
    },
    iconNftCoins: {
      display: 'inline-flex',
      marginLeft: -10,
      marginRight: 10,
      '& svg:first-child': {
        marginRight: -5
      }
    }
  };
});

export type ActionButtonsProps = {
  className?: string;
  nftUrl?: string;

  isFiled?: boolean;
  isPaid?: boolean;
  inPayment?: boolean;
  isPublic?: boolean;
  isFinalized?: boolean;
  isShared?: boolean;
  isExclusivityPaid?: boolean;
  isExclusivityExpired?: boolean;
  owner?: string | number;

  onPatentClick?: () => void;
  onDeployNftClick?: () => void;
};

const ActionButtonsView = ({
  className,
  nftUrl,
  isFiled,
  inPayment,
  isPaid,
  isPublic,
  isFinalized,
  isShared,
  isExclusivityPaid,
  isExclusivityExpired,
  owner,
  onPatentClick,
  onDeployNftClick
}: ActionButtonsProps) => {
  const user = GetUser();
  const { classes } = useStyles();
  const [nftWarning, setNftWarning] = useState(false);

  const canEdit = user && (user.id === owner || user.isAdmin);
  const canNftDeploy = user && user.isAdmin;

  const showNft = isShared ? isFiled && isExclusivityPaid : isFiled;
  const showBuyExclusivity =
    isFiled &&
    isShared &&
    !isExclusivityPaid &&
    !isExclusivityExpired &&
    canEdit;

  const onDeployClick = () => {
    if (isPublic) {
      if (onDeployNftClick) {
        onDeployNftClick();
      }
    } else {
      setNftWarning(true);
    }
  };

  const onDeployNftApproveClick = (agree: boolean) => {
    if (agree && onDeployNftClick) {
      onDeployNftClick();
    }
    setNftWarning(false);
  };

  return (
    <>
      {!isFinalized && canEdit && !isPaid ? (
        <PsButton
          className={classes.actionButton}
          onClick={onPatentClick}
          startIcon={
            <PsNFT className={classes.iconNft} size={50} color="white" />
          }
        >
          <span>
            <b>Mint Idea NFT</b>
            <br />
            File a Patent Application
          </span>
        </PsButton>
      ) : null}

      {showBuyExclusivity ? (
        <PsButton
          className={classes.actionButton}
          onClick={onPatentClick}
          startIcon={
            <PsNFT className={classes.iconNft} size={50} color="white" />
          }
        >
          Buy Invention NFT
        </PsButton>
      ) : null}

      {showNft && canNftDeploy && !nftUrl ? (
        <PsButton
          className={classes.actionButton}
          onClick={onDeployClick}
          startIcon={
            <div className={classes.iconNftCoins}>
              <PsLogo size={50} color="white" small />
              <PsNFT size={50} color="white" />
            </div>
          }
        >
          Accept NFT & Sell Invention on OpenSea
        </PsButton>
      ) : null}

      <WarningDialog
        open={nftWarning}
        onSubmit={onDeployNftApproveClick}
        yes="Proceed"
        no="Cancel"
      >
        This will change the status of your patent pending invention from
        private to public. Are you sure you want to allow others to see this
        invention?
      </WarningDialog>
    </>
  );
};

export const ActionButtons = styled(ActionButtonsView)({});

export default ActionButtons;
