import React from 'react';
import { Container, Grid } from '@mui/material';
import { LandingPageTokensImage } from 'components/icons/LandingPageTokensImage';
import { useIsMediumScreen } from 'theme';
import {
  StyledRoyaltiesMainBox,
  StyledWinRoyaltiesContentTypography,
  StyledWinRoyaltiesHeaderTypography,
  StyledWinRoyaltiesSubContentTypography,
  StyledWinRoyaltiesSubHeaderTypography
} from './StyledComponents';

export const PatentPendingSection = () => {
  const isMediumScreen = useIsMediumScreen();
  return (
    <StyledRoyaltiesMainBox>
      <Container maxWidth="lg">
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <StyledWinRoyaltiesHeaderTypography variant="h4" gutterBottom>
              Create Patent Pending Tokens
            </StyledWinRoyaltiesHeaderTypography>
            <StyledWinRoyaltiesSubHeaderTypography variant="body1" gutterBottom>
              MindMiner empowers you to transform product ideas and inventions
              into transactable assets.
            </StyledWinRoyaltiesSubHeaderTypography>
            <StyledWinRoyaltiesContentTypography gutterBottom>
              Create Patent Pending Products
            </StyledWinRoyaltiesContentTypography>
            <StyledWinRoyaltiesSubContentTypography
              variant="body2"
              gutterBottom
            >
              MindMiner helps you simultaneously create and protect new product
              concepts.
            </StyledWinRoyaltiesSubContentTypography>

            <StyledWinRoyaltiesContentTypography gutterBottom>
              Deeply Engage With Consumers
            </StyledWinRoyaltiesContentTypography>
            <StyledWinRoyaltiesSubContentTypography variant="body2">
              Your consumers are the reason your brand is successful.
              Incentivize them to improve your products.
            </StyledWinRoyaltiesSubContentTypography>
          </Grid>
          {!isMediumScreen && (
            <Grid
              item
              xs={12}
              md={6}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <LandingPageTokensImage
                sx={{ maxWidth: '100%', height: 'auto' }}
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </StyledRoyaltiesMainBox>
  );
};
