import React from 'react';
import { ButtonWithCounter } from '../common/ButtonWithCounter';
import { colorPalette } from 'theme';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import useRouter from 'hooks/useRouter';
import { Contest } from '../CardContest';

interface ContestProps {
  contests: Array<Contest>;
}

const CoinedButton = styled(Typography)({
  fontSize: '16px',
  fontWeight: '700',
  letterSpacing: '-0.01em',
  lineHeight: '34.13px',
  padding: '0px 3px',
  textAlign: 'center'
});

const MainBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  marginBottom: '8px'
});

const ContestsAndChallengesView: React.FC<ContestProps> = ({ contests }) => {
  const router = useRouter();
  const onClickRedirect = (contest) => {
    router.push(`/contests/${contest.key || contest.id}`);
  };
  return (
    <Box>
      {contests.map((contest, index) => (
        <MainBox key={index}>
          <Box display={'flex'} flexDirection={'row'} alignSelf={'center'}>
            <Box>
              <EmojiEventsIcon sx={{ color: colorPalette.golden }} />
            </Box>
            <Box
              sx={{ cursor: 'pointer' }}
              onClick={() => onClickRedirect(contest)}
            >
              {contest.title.length > 12
                ? `${contest.title.slice(0, 12)}...`
                : contest.title}
            </Box>
          </Box>
        </MainBox>
      ))}
    </Box>
  );
};

export default ContestsAndChallengesView;
