import React, { useCallback, useMemo } from 'react';
import { BigCounter } from 'components/common/BigCounter';
import { Node } from '../Content';
import IdentifierIcon from 'components/IdentifierIcon';
import { colorPalette } from '../../../theme';

import {
  ButtonContainer,
  ImageContainer,
  StackBox,
  StackButton,
  StyledAvatar,
  StyledBox,
  StyledInnerBox,
  StyledTitle,
  TextContainer,
  TitleContainer
} from './StyledComponents';

interface NodeContentProps {
  node?: Node;
  problem?: Node;
  selectedNode?: Node;
  setSelectedNode?: React.Dispatch<React.SetStateAction<Node | null>>;
  type?: string;
}

const NodeContent: React.FC<NodeContentProps> = ({
  node,
  problem,
  selectedNode,
  setSelectedNode,
  type
}) => {
  const { ownerImage, image, shortTitle } = node;
  const handleNodeContentClick = useCallback(
    (node) => {
      setSelectedNode(node);
    },
    [setSelectedNode]
  );

  const NodeContents = useMemo(
    () => (
      <StyledBox
        datatype={type}
        draggable="true"
        isProblemSelected={problem?.key === node?.key}
        isSolutionSelected={selectedNode?.key == node?.key}
        onClick={() => handleNodeContentClick(node)}
        onDragStart={(e) => e.preventDefault()}
        type={type}
      >
        <IdentifierIcon type={type} image={ownerImage} />
        <StyledInnerBox>
          <TextContainer>
            <ImageContainer>
              <StyledAvatar src={image} alt="icon" />
            </ImageContainer>
            <TitleContainer>
              <StyledTitle type={type}>{shortTitle}</StyledTitle>
            </TitleContainer>
          </TextContainer>
          <ButtonContainer>
            <>
              <StackBox>
                <StackButton>
                  <BigCounter coins={314} textColor={colorPalette.black} />
                </StackButton>
              </StackBox>
            </>
          </ButtonContainer>
        </StyledInnerBox>
      </StyledBox>
    ),
    [
      type,
      problem?.key,
      node,
      selectedNode?.key,
      ownerImage,
      image,
      shortTitle,
      handleNodeContentClick
    ]
  );

  return NodeContents;
};

export default NodeContent;
