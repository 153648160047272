import React from 'react';

function SolutionIcon() {
  return (
    <svg
      width="82"
      height="82"
      viewBox="0 0 82 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41 82C63.6437 82 82 63.6437 82 41C82 18.3563 63.6437 0 41 0C18.3563 0 0 18.3563 0 41C0 63.6437 18.3563 82 41 82Z"
        fill="#B1F4D4"
      />
      <path
        d="M33.9413 57.364C33.0352 57.3642 32.1662 57.0045 31.5253 56.364L19.7583 44.593C19.1305 43.9496 18.7816 43.0847 18.7872 42.1858C18.7927 41.2868 19.1523 40.4263 19.7879 39.7907C20.4236 39.155 21.2841 38.7955 22.183 38.7899C23.082 38.7844 23.9469 39.1333 24.5903 39.761L33.9413 49.112L57.4073 25.644C58.0507 25.0163 58.9156 24.6674 59.8146 24.6729C60.7135 24.6785 61.574 25.038 62.2097 25.6737C62.8453 26.3093 63.2049 27.1698 63.2104 28.0688C63.216 28.9677 62.8671 29.8326 62.2393 30.476L36.3573 56.363C35.7166 57.0039 34.8475 57.3639 33.9413 57.364Z"
        fill="#008954"
      />
    </svg>
  );
}

export default SolutionIcon;
