import React, { useCallback, useEffect, useState } from 'react';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import { Picture } from 'helpers';
import { Constants, ERRORS } from 'utilities/constants';
import { CompanyField } from 'components/common/CompanyField';
import { DescriptionField } from 'components/common/DescriptionField';
import { ImageContainer } from 'components/common/ImageContainer';
import PsInput from 'components/common/PsInput';
import PsFormControl, { ShortEvent } from 'components/common/PsFormControl';
import { StyledFieldLabel } from 'components/common/StyledComponents';
import { TagsField } from 'components/common/TagsField';
import { StyledDescriptionExampleText } from './StyledComponents';
import { OptionType } from '../Common/interface';

interface ContentProps {
  description?: string;
  picture?: Picture;
  selectedCompany?: OptionType | null;
  selectedTags?: Array<OptionType | null>;
  setDescription?: React.Dispatch<React.SetStateAction<string>>;
  setPicture?: React.Dispatch<React.SetStateAction<Picture>>;
  setSelectedCompany?: React.Dispatch<React.SetStateAction<OptionType | null>>;
  setSelectedTags?: React.Dispatch<
    React.SetStateAction<Array<OptionType | null>>
  >;
  setTitle?: React.Dispatch<React.SetStateAction<string>>;
  startLoading?: () => void;
  stopLoading?: () => void;
  title: string;
}

const descriptionExampleText = (
  <StyledDescriptionExampleText>
    {Constants.PRODUCT_HELPER_TEXT}
  </StyledDescriptionExampleText>
);

const ProductFieldLabel = (
  <StyledFieldLabel>{Constants.PRODUCT_TITLE}</StyledFieldLabel>
);

export const Content: React.FC<ContentProps> = ({
  description,
  picture,
  selectedCompany,
  selectedTags,
  setDescription,
  setPicture,
  setSelectedCompany,
  setSelectedTags,
  setTitle,
  startLoading,
  stopLoading,
  title
}) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const [errorMessage, setErrorMessage] = useState<string>('');

  const onTitleChange = useCallback(
    (e: ShortEvent) => {
      const { value = '' } = e.target;
      const wordCount = value.trim().split(/\s+/).length;
      const isWordLimitExceeded = wordCount > 50;
      setErrorMessage(isWordLimitExceeded ? ERRORS.WORD_LIMIT_EXCEEDED : '');

      if (!isWordLimitExceeded) {
        setTitle(e.target.value);
      }
    },
    [setTitle]
  );

  useEffect(() => {
    if (selectedCompany) {
      setSelectedTags([...selectedTags, selectedCompany]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany]);

  return (
    <Box>
      <PsFormControl
        label={ProductFieldLabel}
        placeholder={Constants.ENTER_PRODUCT_TITLE}
        helperText={errorMessage}
        required
      >
        <PsInput
          name={Constants.TITLE_PRODUCT}
          onChange={onTitleChange}
          value={title}
          error={!!errorMessage}
        />
      </PsFormControl>
      <TagsField
        label={Constants.PRODUCT_TAGS_LABEL}
        placeholder={Constants.SELECT}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        startLoading={startLoading}
        stopLoading={stopLoading}
        title={title}
        companyName={selectedCompany?.label}
        type={Constants.PRODUCTS}
      />
      <Grid
        columnSpacing={{ xs: 2 }}
        container
        direction={isXs ? Constants.COLUMN : Constants.row}
        rowSpacing={1}
      >
        <Grid item xs={12} sm={12} md={6}>
          <CompanyField
            selectedCompany={selectedCompany}
            setSelectedCompany={setSelectedCompany}
          />
          <DescriptionField
            parentNode={selectedCompany}
            description={description}
            exampleText={descriptionExampleText}
            label={Constants.DESCRIBE_PRODUCT}
            setDescription={setDescription}
            title={title}
            type={Constants.PRODUCTS}
          />
        </Grid>
        <Grid item md={6} xs={12} sm={12}>
          <ImageContainer
            parentNode={selectedCompany}
            description={description}
            picture={picture}
            setPicture={setPicture}
            supportText
            title={title}
            type={Constants.PRODUCTS}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
