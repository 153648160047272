import React from 'react';
export const InventionIcon = ({
  size = 24,
  color = '#1757C1',
  className
}: {
  size?: number;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0004 23C12.9508 23 13.7714 22.4358 14.1463 21.625H9.85449C10.2294 22.4358 11.05 23 12.0004 23Z"
        fill={color}
      />
      <path
        d="M11.3555 17.3281V12.1157C9.88629 11.8562 8.77735 10.7277 8.77735 9.37892C8.77735 9.02296 9.06593 8.73439 9.42188 8.73439C9.77784 8.73439 10.0664 9.02296 10.0664 9.37892C10.0664 10.0324 10.6053 10.5897 11.3555 10.7966V1.44052C10.9131 1.15718 10.3938 1 9.85157 1C8.49612 1 7.33991 1.9787 7.1044 3.2996C5.55224 3.75353 4.48047 5.17317 4.48047 6.80079C4.48047 7.67108 4.78856 8.50278 5.35205 9.16407C4.7886 9.82536 4.48047 10.6571 4.48047 11.5274C4.48047 13.155 5.55224 14.5746 7.1044 15.0285C7.33987 16.3494 8.49612 17.3281 9.85157 17.3281H11.3555Z"
        fill={color}
      />
      <path
        d="M8.77734 19.2617C8.77734 19.855 9.2583 20.336 9.85157 20.336H14.1485C14.7418 20.336 15.2227 19.855 15.2227 19.2617V18.6172H8.77734V19.2617Z"
        fill={color}
      />
      <path
        d="M16.8956 3.29961C16.6601 1.97874 15.5039 1 14.1484 1C13.6062 1 13.0869 1.15718 12.6445 1.44052V6.2125C14.1137 6.47199 15.2227 7.60048 15.2227 8.94927C15.2227 9.30523 14.9341 9.59381 14.5781 9.59381C14.2222 9.59381 13.9336 9.30523 13.9336 8.94927C13.9336 8.29576 13.3947 7.73854 12.6445 7.5316V17.3282H14.1484C15.5039 17.3282 16.6601 16.3495 16.8956 15.0286C18.4478 14.5747 19.5195 13.155 19.5195 11.5274C19.5195 10.6571 19.2115 9.82541 18.648 9.16412C19.2115 8.50283 19.5195 7.67112 19.5195 6.80083C19.5196 5.17317 18.4478 3.75357 16.8956 3.29961Z"
        fill={color}
      />
      <path
        d="M21.0672 4.65256C20.8589 4.65256 20.6545 4.55176 20.5303 4.36544C20.3329 4.06926 20.4129 3.66909 20.7091 3.47164L21.9982 2.61226C22.2943 2.41473 22.6945 2.49483 22.892 2.79101C23.0894 3.0872 23.0094 3.48737 22.7132 3.68481L21.4241 4.54419C21.3143 4.61746 21.19 4.65256 21.0672 4.65256Z"
        fill={color}
      />
      <path
        d="M22.3552 15.3947C22.2324 15.3947 22.1082 15.3596 21.9983 15.2864L20.7092 14.427C20.413 14.2295 20.333 13.8294 20.5304 13.5332C20.7279 13.2369 21.1281 13.157 21.4242 13.3544L22.7133 14.2138C23.0095 14.4112 23.0895 14.8114 22.8921 15.1076C22.7679 15.2939 22.5635 15.3947 22.3552 15.3947Z"
        fill={color}
      />
      <path
        d="M2.93299 4.65251C2.81014 4.65251 2.68592 4.61745 2.57605 4.54419L1.28698 3.6848C0.99079 3.48736 0.910739 3.08719 1.10818 2.791C1.30567 2.49478 1.70588 2.41477 2.00198 2.61225L3.29105 3.47163C3.58724 3.66908 3.66729 4.06925 3.46985 4.36543C3.34567 4.5517 3.14126 4.65251 2.93299 4.65251Z"
        fill={color}
      />
      <path
        d="M1.64496 15.3947C1.43669 15.3947 1.23228 15.2939 1.10806 15.1076C0.910616 14.8114 0.990668 14.4113 1.28685 14.2138L2.57593 13.3544C2.87207 13.1569 3.27228 13.237 3.46973 13.5332C3.66717 13.8294 3.58712 14.2296 3.29093 14.427L2.00186 15.2864C1.89203 15.3596 1.76781 15.3947 1.64496 15.3947Z"
        fill={color}
      />
      <path
        d="M2.93349 9.59376H1.64441C1.28846 9.59376 0.999878 9.30518 0.999878 8.94922C0.999878 8.59327 1.28846 8.30469 1.64441 8.30469H2.93349C3.28944 8.30469 3.57802 8.59327 3.57802 8.94922C3.57802 9.30518 3.28944 9.59376 2.93349 9.59376Z"
        fill={color}
      />
      <path
        d="M22.3556 9.59376H21.0665C20.7106 9.59376 20.422 9.30518 20.422 8.94922C20.422 8.59327 20.7106 8.30469 21.0665 8.30469H22.3556C22.7116 8.30469 23.0001 8.59327 23.0001 8.94922C23.0001 9.30518 22.7116 9.59376 22.3556 9.59376Z"
        fill={color}
      />
    </svg>
  );
};

export default InventionIcon;
