import React, { Dispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import { CancelOutlined } from '@mui/icons-material';
import Actions from 'redux-state/actions';
import { VARIANT } from 'utilities/constants';
import { ChatUI } from '../ChatUI';
import SimpleIconButton from '../SimpleIconButton';
import { CloseIconWrapper, StyledDrawer } from './styledComponents';

interface ChatBotDrawerProps {
  graphData?: any;
  graphType?: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const ChatBotDrawer: React.FC<ChatBotDrawerProps> = ({
  graphData,
  graphType,
  open,
  setOpen
}) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
    dispatch(Actions.toggleConceptCart(false));
  };

  return (
    <StyledDrawer
      anchor={VARIANT.RIGHT}
      onClose={handleClose}
      open={open}
      variant={VARIANT.PERSISTENT}
    >
      <CloseIconWrapper>
        <SimpleIconButton
          color={VARIANT.PRIMARY}
          Icon={CancelOutlined}
          onClick={handleClose}
        />
      </CloseIconWrapper>
      <ChatUI graphData={graphData} graphType={graphType} />
    </StyledDrawer>
  );
};
