import React, { useEffect, useState } from 'react';
import { GetPublicUser, GetUser } from 'redux-state/selectors';
import Actions from 'redux-state/actions';
import getQueryParams from 'helpers/getQueryParams';
import { ConceptDetailView } from 'components/ConceptView/ConceptDetail';
import {
  StyledBoxContent,
  StyledGridItem,
  StyledProblemButton,
  StyledTab,
  StyledTabIcon,
  StyledTabLabel,
  StyledTabLabelCustom
} from './StyledComponents';
import { Box, Button, Grid, Tabs } from '@mui/material';
import dataProvider from 'dataPrvider';
import { colorPalette, useIsMediumScreen } from 'theme';
import { Constants, VARIANT } from 'utilities/constants';
import { NodeContentBase } from 'components/InteractiveNodeContainer/NodeContent/NodeContentBase';
import { HexDoneIcon } from 'components/icons/HexDoneIcon';
import { HexPlusIcon } from 'components/icons/HexPlusIcon';
import SolutionModal from 'modals/AddSolution';
import { isObjectEmpty } from 'helpers';
import { useDispatch } from 'react-redux';
import { toastify } from 'pages/newContests/toastify';

const TOASTIFY_DURATION = 2000;

interface InventionPublicViewProps {
  inventionKey?: string;
  graphData?: any;
}

/* eslint-disable max-lines-per-function */
const InventionPublicView = ({
  inventionKey,
  graphData
}: InventionPublicViewProps) => {
  const user = GetUser();
  const currentTab = getQueryParams('currentTab');
  const isMediumScreen = useIsMediumScreen();
  const tabMapper = { Problems: 0, Solutions: 1 };
  const initialTab =
    currentTab && tabMapper[currentTab] ? tabMapper[currentTab] : 0;
  const [activeTab, setActiveTab] = useState<number>(initialTab);
  const [invention, setInvention] = useState<any>();
  const [isSolModalOpen, setIsSolModalOpen] = useState<boolean>(false);

  const activeConcept = user?.activeConcept || {};

  const dispatch = useDispatch();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) =>
    setActiveTab(newValue);

  const isSolutionSelected = (solutionId) => {
    return activeConcept?.selected?.includes(solutionId);
  };

  const ProblemButton = [
    {
      component: () => (
        <StyledProblemButton>{Constants.SOLVE}</StyledProblemButton>
      ),
      onClick: () => setIsSolModalOpen(true)
    }
  ];

  const SolutionButton = [
    {
      component: ({ node }) => (
        <Button>
          {isSolutionSelected(node.id) ? <HexDoneIcon /> : <HexPlusIcon />}
        </Button>
      ),
      onClick: (event, node) => {
        if (!isSolutionSelected(node.id)) {
          event.stopPropagation();
          const { id: solutionId, problem } = node;
          if (isObjectEmpty(activeConcept)) {
            const appInfo = {
              title: '',
              selected: [solutionId],
              problems: [problem]
            };
            dispatch(Actions.createConcept(appInfo));
          } else {
            const existingProbIds = activeConcept.problems.map(({ id }) => id);

            const newProblems = existingProbIds.includes(problem)
              ? existingProbIds
              : [...existingProbIds, problem];

            const data = {
              selected: [solutionId, ...activeConcept.selected],
              problems: newProblems
            };

            dispatch(Actions.updateConcept(activeConcept.id, data));
          }
          toastify(
            `${Constants.SOLUTION_ADDED_TO} ${Constants.CONCEPT}`,
            VARIANT.SUCCESS,
            VARIANT.BOTTOM_LEFT,
            TOASTIFY_DURATION
          );
        }
      }
    }
  ];

  const NodesView = ({ type }) => {
    const items =
      type === Constants.PROBLEMS ? invention.problems : invention.solutions;
    const buttonList =
      type === Constants.PROBLEMS ? ProblemButton : SolutionButton;

    return items.map((item) => (
      <Box key={item.id} sx={{ marginBottom: '0.625rem' }}>
        <NodeContentBase
          node={item}
          showAvatar={false}
          buttonList={buttonList}
          tags={graphData.tags}
        />
      </Box>
    ));
  };
  const tabs = [
    {
      title: (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <StyledTabIcon active={0} isMediumScreen={isMediumScreen}>
            {invention?.problems.length}
          </StyledTabIcon>
          <StyledTabLabelCustom active={activeTab === 0}>
            {Constants.SOLVED_PROBLEMS}
          </StyledTabLabelCustom>
        </Box>
      ),
      content: <NodesView type={Constants.PROBLEMS} />,
      key: Constants.PROBLEM
    },
    {
      title: (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <StyledTabIcon active={1} isMediumScreen={isMediumScreen}>
            {invention?.selected?.length}
          </StyledTabIcon>
          <StyledTabLabelCustom active={activeTab === 1}>
            {Constants.SOLUTION_ADDED}
          </StyledTabLabelCustom>
        </Box>
      ),
      content: <NodesView type={Constants.SOLUTIONS} />,
      key: Constants.SOLUTIONS
    }
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: applicationData } = await dataProvider.getOneByKey(
          'applications',
          { key: inventionKey as string }
        );
        setInvention(applicationData);
      } catch (error) {
        console.error('Error fetching application data:', error);
      }
    };
    if (inventionKey) fetchData();
  }, [inventionKey]);

  const profile = GetPublicUser();

  return graphData && Object.keys(graphData).length ? (
    <>
      <Grid
        container
        spacing={0}
        sx={{
          width: '90%',
          margin: 'auto',
          marginTop: '35px',
          border: '1px solid lightgrey',
          borderRadius: '10px',
          overflow: 'hidden'
        }}
      >
        <StyledGridItem item lg={6} sm={12} isMediumScreen={isMediumScreen}>
          <ConceptDetailView
            selectedConceptId={null}
            publicViewInvetion={invention}
            profile={profile}
            archiveStatus={null}
            pagination={null}
            fetchProfileConcepts={null}
            isConcept={false}
            showShareOption={false}
            showMoreVertIcon={false}
          />
        </StyledGridItem>
        <Grid
          item
          lg={6}
          sm={12}
          sx={{
            overflowY: 'auto',
            maxHeight: 'calc(100vh - 70px)',
            padding: '0'
          }}
        >
          <Tabs
            orientation="horizontal"
            aria-label="secondary tabs example"
            indicatorColor="primary"
            onChange={handleTabChange}
            scrollButtons={true}
            TabIndicatorProps={{
              style: {
                backgroundColor: colorPalette.purple
              }
            }}
            value={activeTab}
            variant="fullWidth"
          >
            {tabs.map((tab, index) => (
              <StyledTab
                key={tab.key}
                label={<StyledTabLabel>{tab.title}</StyledTabLabel>}
                active={index === activeTab}
              />
            ))}
          </Tabs>
          <StyledBoxContent>{tabs[activeTab].content}</StyledBoxContent>
        </Grid>
      </Grid>
      {isSolModalOpen && (
        <SolutionModal
          open={isSolModalOpen}
          onClose={() => setIsSolModalOpen(false)}
        />
      )}
    </>
  ) : null;
};
/* eslint-disable max-lines-per-function */

export default InventionPublicView;
