import React, { useState, useRef, useEffect } from 'react';
import { styled, Theme, useTheme } from '@mui/material/styles';

import { Picture, getPlaceholderImage, getFileTitle } from '../../helpers';
import { PsTheme } from '../../theme';
import { Link } from 'react-router-dom';
import user65x65 from 'assets/images/user_67x65.jpeg';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root' | 'inner' | 'placeholder' | 'img';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      display: 'inline-block',
      textDecoration: 'none'
    },
    inner: {
      display: 'block',
      position: 'relative',
      paddingTop: '100%'
    },
    placeholder: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: 10,
      objectFit: 'cover',
      opacity: 1,
      transition: 'opacity 0.3s ease',
      '&.loaded': {
        opacity: 0
      },
      '&.round': {
        borderRadius: '50%'
      }
    },
    img: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: 15,
      objectFit: 'cover',
      opacity: 0,
      transition: 'opacity 0.3s ease',
      '&.loaded': {
        opacity: 1
      },
      '&.round': {
        borderRadius: '50%'
      }
    }
  };
});

type AvaImageProps = {
  classes?: any;
  children?: React.ReactNode;
  className?: string;
  type?: string;
  image?: Picture;
  imageAlt?: string;
  href?: string;
  target?: string;
  round?: boolean;
};

const AvaImageView = ({
  className,
  image,
  imageAlt,
  type,
  href,
  target,
  round
}: AvaImageProps) => {
  const [loaded, setLoaded] = useState(false);
  const imgRef = useRef<HTMLImageElement>(null);
  const { classes } = useStyles();

  useEffect(() => {
    setLoaded(false);
  }, [image, image ? image.url : '']);

  useEffect(() => {
    const handleLoad = () => {
      setLoaded(true);
    };

    if (imgRef.current) {
      if (imgRef.current.complete) {
        if (imgRef.current.naturalHeight) {
          setLoaded(true);
        }
      } else {
        imgRef.current.addEventListener('load', handleLoad);
      }
    }

    return () => {
      if (imgRef.current) {
        imgRef.current.removeEventListener('load', handleLoad);
      }
    };
  }, [imgRef.current, image]);

  let rootClassName = classes.root;
  if (className) {
    rootClassName += ' ' + className;
  }
  const def = type ? getPlaceholderImage(type) : user65x65;

  const Placeholder = (
    <img
      className={`${classes.placeholder} ${loaded ? 'loaded' : ''} ${
        round ? 'round' : ''
      }`}
      src={def}
      alt={type}
    />
  );

  const Image = image ? (
    <img
      ref={imgRef}
      className={`${classes.img} ${loaded ? 'loaded' : ''} ${
        round ? 'round' : ''
      }`}
      src={image.url}
      alt={imageAlt || getFileTitle(image.title)}
    />
  ) : null;

  if (href) {
    return (
      <Link to={href}>
        <a className={rootClassName} target={target}>
          <span className={classes.inner}>
            {Placeholder}
            {Image}
          </span>
        </a>
      </Link>
    );
  }

  return (
    <div className={rootClassName}>
      <div className={classes.inner}>
        {Placeholder}
        {Image}
      </div>
    </div>
  );
};

export const AvaImage = styled(AvaImageView)({});

export default AvaImage;
