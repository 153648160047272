import { Box, styled } from '@mui/material';
import Drawer from '@mui/material/Drawer';

export const CloseIconWrapper = styled(Box)(() => ({
  position: 'absolute',
  top: '.5rem',
  right: '.625rem'
}));

export const StyledDrawer = styled(Drawer)(() => ({
  '& .MuiDrawer-paper': {
    width: '65%',
    boxShadow: '-4px 0 10px rgba(0, 0, 0, 0.2)',
    height: 'calc(100% - 80px)',
    position: 'fixed',
    top: '80px',
    zIndex: 200
  }
}));
