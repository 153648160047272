import React from 'react';
import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';

const StyledTypography = styled(Typography)({
  color: 'red',
  fontWeight: '600',
  fontSize: '20px',
  textAlign: 'center',
  width: '100%'
});

interface ErrorViewProps {
  errorMessage: string;
}

const ErrorView: React.FC<ErrorViewProps> = ({ errorMessage }) => {
  return <StyledTypography>{errorMessage}</StyledTypography>;
};

export default ErrorView;
