import React, { useContext, useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import {
  Card,
  CardImage,
  CardBody,
  CardText,
  CardTagTitle
} from 'components/card';
import { getPlaceholderImage, placeholderType } from '../../helpers';
import { AuthContext } from 'contexts/AuthContext';
import { DataContext } from 'contexts/DataContext';
import { PsButton } from 'components/common/PsButton';
import { PsLogo } from 'components/common/PsLogo';
import { Tag, Contributors } from 'components/CardTag';
import { PsChip } from 'components/common/PsChip';
import { ShareBtn } from 'components/ShareBtn';
import { Contest, ContestTopUser, Duration } from 'components/CardContest';
import { CountDownTimer } from 'components/CountDownTimer';
import { PsTheme } from '../../theme';
import useRouter from 'hooks/useRouter';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

type ClassKey =
  | 'root'
  | 'top'
  | 'bottom'
  | 'headerRow'
  | 'title'
  | 'tagHolder'
  | 'tags'
  | 'body'
  | 'actionButton'
  | 'tagsButton'
  | 'timeRow'
  | 'timeRight'
  | 'moderator'
  | 'quote'
  | 'share'
  | 'solveBtn'
  | 'coins'
  | 'users';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      flexDirection: 'column'
    },
    top: {
      display: 'flex',
      width: '100%',
      [psTheme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    },
    bottom: {
      padding: '10px 10px'
    },
    headerRow: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 9
    },
    titleHolder: {
      position: 'relative'
    },
    title: {
      fontWeight: 'bold',
      fontSize: 24,
      margin: '0 0 7px 0'
    },
    tagHolder: {
      fontSize: 20,
      fontWeight: 600,
      marginRight: 10
    },
    tags: {
      '& > div': {
        marginTop: 4,
        marginBottom: 4
      }
    },
    body: {
      marginBottom: 10
    },
    actionButton: {
      marginRight: 10
    },
    tagsButton: {
      marginTop: 10
    },
    timeRow: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      [psTheme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'stretch'
      }
    },
    timeRight: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      flexWrap: 'wrap',
      '& > div': {
        color: 'red',
        fontWeight: 400
      }
    },
    moderator: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 10,
      '& > a': {
        marginRight: 15
      },
      '& img': {
        display: 'block',
        width: 55,
        height: 55,
        borderRadius: 10,
        objectFit: 'cover'
      },
      '& > div': {
        display: 'flex',
        flexWrap: 'wrap'
      },
      '& > div > a': {
        fontWeight: 600,
        textDecoration: 'none',
        color: 'black'
      },
      '& > div > a:hover': {
        textDecoration: 'underline'
      }
    },
    quote: {
      marginBottom: 10,
      padding: '16px 25px 5px',
      borderRadius: 10,
      background: '#F5F3FA',
      textAlign: 'center',
      fontSize: 17,
      '& > p': {
        marginBottom: 10,
        lineHeight: '20px'
      },
      '& > p b': {
        fontWeight: 600
      },
      '& > p span.no': {
        color: 'red'
      },
      '& > p span.yes': {
        color: 'green'
      },
      '& > p a': {
        color: psTheme.palette.primary.main,
        textDecoration: 'none'
      },
      '& > p a:hover': {
        textDecoration: 'underline'
      },
      '& > h6': {
        fontSize: 19,
        lineHeight: '25px',
        fontWeight: 600,
        marginBottom: 10
      }
    },
    share: {
      marginBottom: 10,
      marginLeft: 10
    },
    solveBtn: {
      marginBottom: 6,
      paddingLeft: 15,
      paddingRight: 15,
      borderRadius: 10,
      lineHeight: '28px',
      minHeight: 40
    },
    coins: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 10,
      '& div:first-child': {
        paddingTop: 5,
        paddingRight: 15
      },
      '& div p': {
        fontSize: 15,
        lineHeight: '20px',
        marginTop: 9,
        marginBottom: 5
      },
      '& div p span': {
        display: 'inline-block',
        paddingTop: 10,
        paddingBottom: 10,
        fontSize: 20
      },
      '& div p b': {
        fontSize: 18,
        fontWeight: 600
      }
    },
    users: {}
  };
});

export type CardContestDetailProps = {
  contest: Contest;
  isDetailed?: boolean;
  onTopUsers?: (top: Array<ContestTopUser>) => void;
  onSolveBtnClick?: () => void;
};

const CardContestDetailView = ({
  contest,
  onTopUsers,
  onSolveBtnClick
}: CardContestDetailProps) => {
  const {
    id,
    key,
    title,
    body,
    files,
    tag,
    bountyCoins = 0,
    bountyNft = 0,
    startDate,
    endDate,
    wins,
    owner,
    ownerInfo,
    solutions = [],
    applications = []
  } = contest;
  const { user } = useContext(AuthContext);
  const { dataProvider } = useContext(DataContext);
  const router = useRouter();
  const image = files && files.length ? files[0] : undefined;
  const [topUsers, setTopUsers] = useState<Array<ContestTopUser>>([]);
  const [tagDetail, setTagDetail] = useState<Tag>();
  const [nftCount, setNftCount] = useState<number | undefined>();
  const { classes } = useStyles();
  const url = `/contests/${key || id}`;

  let moderatorPicture = getPlaceholderImage(placeholderType.U);
  if (ownerInfo && ownerInfo.picture) {
    moderatorPicture = ownerInfo.picture.url;
  }

  let isExpired = false;
  const end = new Date(endDate);
  const now = new Date();
  if (now > end) {
    isExpired = true;
  }

  useEffect(() => {
    dataProvider
      .getOne<Tag>('tags', { id: tag })
      .then(({ data }) => {
        setTagDetail(data);
      })
      .catch((err) => {
        setTagDetail(undefined);
        console.error(err);
      });

    dataProvider
      .getContestNFTCount<{ count: number }>(id)
      .then(({ count }) => {
        setNftCount(count);
      })
      .catch((err) => {
        setNftCount(0);
        console.error(err);
      });

    dataProvider
      .getContestTopUsers<Array<ContestTopUser>>(id)
      .then((tops) => {
        setTopUsers(tops);
        if (onTopUsers) {
          onTopUsers(tops);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [id]);

  const onTagClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (tagDetail) {
      router.push(`/tags/${tagDetail.key || tagDetail.id}`);
    }
  };

  return (
    <Card className={classes.root}>
      <div className={classes.top}>
        <CardImage image={image} type={placeholderType.P} />
        <CardBody>
          <CardTagTitle title={title}>
            {tagDetail ? (
              <PsChip
                label={tagDetail.name}
                textColor={tagDetail.color}
                size="medium"
                onClick={onTagClick}
              />
            ) : null}
          </CardTagTitle>
          <CardText className={classes.body}>{body}</CardText>
          <div className={classes.coins}>
            <div>
              <PsLogo size={55} color="#632DDD" small />
            </div>
            <div>
              <Typography>
                <span>
                  <b>{bountyCoins} IdeaCoins</b> Jackpot
                </span>
                <br />
                Win by solving below
              </Typography>
            </div>
          </div>
        </CardBody>
      </div>
      <div className={classes.bottom}>
        <div className={classes.timeRow}>
          <div className={classes.moderator}>
            <Link to={`/profiles/${ownerInfo ? ownerInfo.key : owner}`}>
              <img
                src={moderatorPicture}
                alt={ownerInfo && ownerInfo.username}
              />
            </Link>
            <div>
              <Link to={`/profiles/${ownerInfo ? ownerInfo.key : owner}`}>
                <span>{ownerInfo && ownerInfo.username}</span>
              </Link>
              <span>, Moderator</span>
            </div>
          </div>
          <div className={classes.timeRight}>
            <Duration startDate={startDate} endDate={endDate} empty />
            <PsButton
              className={classes.solveBtn}
              color="secondary"
              onClick={onSolveBtnClick}
            >
              Solve to Win IdeaCoins
            </PsButton>
            <ShareBtn className={classes.share} contest={contest} />
          </div>
        </div>
        <div className={classes.quote}>
          {isExpired ? (
            <>
              <Typography>This contest has expired.</Typography>
              <Typography>
                Click <Link to="/contests">here</Link> to participate in other
                contests and earn IdeaCoins
              </Typography>
            </>
          ) : (
            <>
              <Typography>
                Minimum NFT Goal: <b>{bountyNft} NFTs</b> to Unlock Jackpot
              </Typography>
              <Typography>
                Invention Progress:{' '}
                <span
                  className={`${(nftCount || 0) >= bountyNft ? 'yes' : 'no'}`}
                >
                  <b>
                    {typeof nftCount === 'undefined' ? '..' : nftCount} NFTs
                  </b>{' '}
                  Created
                </span>
              </Typography>
              <Typography variant="h6">
                Add a solution below to a invention and create a NFT to help
                unlock the jackpot
              </Typography>
            </>
          )}
        </div>

        <Contributors className={classes.users} users={topUsers} />
        {isExpired ? null : (
          <CountDownTimer
            startDate={startDate ? startDate : ''}
            endDate={endDate ? endDate : ''}
          />
        )}
      </div>
    </Card>
  );
};

export const CardContestDetail = styled(CardContestDetailView)({});

export default CardContestDetail;
