import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const toastify = (message, type = 'default', position = 'top-right', autoClose = 2000, className = '') => {
  const toastConfig = {
    className: `toast-message ${className}`,
    hideProgressBar: true,
    position,
    autoClose
  };

  switch (type) {
    case 'success':
      toast.success(message, toastConfig);
      break;
    case 'error':
      toast.error(message, toastConfig);
      break;
    case 'warning':
      toast.warning(message, toastConfig);
      break;
    case 'info':
      toast.info(message, toastConfig);
      break;
    default:
      toast(message, toastConfig);
      break;
  }
};
