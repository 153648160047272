import {
  Box,
  Button,
  FormControl,
  IconButton,
  ListItemIcon,
  SnackbarContent,
  styled,
  TextField,
  Typography
} from '@mui/material';
import { colorPalette, useIsMediumScreen } from 'theme';
import { Constants } from 'utilities/constants';
import PsButton from './PsButton';
import { Loop } from '@mui/icons-material';
import PsSearchSelect from './PsSearchSelect';

export const AddToButDetail = styled(Button)<{
  isCurrentConceptSolution: boolean;
  responsive?: boolean;
}>(({ isCurrentConceptSolution, responsive, theme }) => ({
  backgroundColor: isCurrentConceptSolution
    ? colorPalette.explosiveGrey
    : colorPalette.lightGreen,
  borderRadius: '50%',
  color: isCurrentConceptSolution ? colorPalette.grey : colorPalette.white,
  padding: '.625rem 0px',
  minWidth: '2.8125rem',
  minHeight: '2.8125rem',
  marginLeft: '.625rem',
  [theme.breakpoints.up('md')]: {
    display: responsive ? 'none' : 'inline-flex'
  },
  [theme.breakpoints.down('sm')]: {
    padding: '0px',
    minWidth: '1.875rem',
    minHeight: '1.875rem',
    marginLeft: '0',
    '& svg': {
      width: '.9375rem',
      height: '.9375rem'
    }
  }
}));

export const AddToTypo = styled(Typography)({
  lineHeight: '1.25rem',
  fontSize: '.875rem',
  whiteSpace: 'nowrap'
});

export const ActiveConcept = styled(Typography)<{ isPointer?: boolean }>(
  ({ isPointer }) => ({
    color: colorPalette.purple,
    cursor: isPointer ? 'pointer' : 'auto',
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '17.0704px',
    margin: '0px 0px 0px 20px',
    maxWidth: '340px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  })
);

export const StyledBoxFlex = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '& .HistoryBtn svg': {
    width: '2.2rem',
    height: '2.2rem',
    [theme.breakpoints.down('sm')]: {
      width: '1.5rem',
      height: '1.5rem'
    }
  }
}));

export const StyledMainContainer = styled(Box)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    alignItems: isMediumScreen ? 'left' : 'center',
    background: `${colorPalette.white}`,
    borderRadius: '4px',
    boxSizing: 'border-box',
    display: 'flex',
    flex: 1,
    flexDirection: isMediumScreen ? 'column' : 'row',
    justifyContent: 'space-between',
    marginTop: '0.625rem',
    padding: '0.75rem 0'
  })
);

export const StyledMainSortContainer = styled(Box)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    background: `${colorPalette.white}`,
    borderRadius: '4px',
    boxSizing: 'border-box',
    display: 'flex',
    flex: 1,
    flexDirection: isMediumScreen ? 'column' : 'row',
    justifyContent: 'space-between',
    marginTop: '0.625rem',
    padding: '0.75rem 0'
  })
);

export const StyledTypographyContainer = styled(Box)<{
  isMediumScreen: boolean;
}>(({ isMediumScreen }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'center'
}));

export const StyledTypography = styled(Typography)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    color: `${colorPalette.dimBlack}`,
    fontWeight: '600',
    marginRight: '20px'
  })
);

export const StyledSelectedItemContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex'
});

export const StyledImage = styled('img')({
  height: '20px',
  marginRight: '8px',
  width: '20px'
});

export const StyledFormControl = styled(FormControl)({
  borderRadius: '8px',
  boxShadow: `0 1px 7px ${colorPalette.ebb}`,
  flex: 1,
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent'
    },
    '&:hover fieldset': {
      borderColor: 'transparent'
    }
  }
});

export const StyledCustomFormControl = styled(FormControl)({
  borderRadius: '8px',
  boxShadow: `0 1px 7px ${colorPalette.ebb}`,
  width: '50%',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent'
    },
    '&:hover fieldset': {
      borderColor: 'transparent'
    }
  }
});

export const StyledListItemIcon = styled(ListItemIcon)({
  '& img': {
    height: '20px',
    width: '20px'
  }
});

export const StyledButton = styled('button')<{ selected: boolean }>(
  ({ selected }) => ({
    alignItems: 'center',
    background: selected ? colorPalette.white : 'initial',
    border: `1px solid ${colorPalette.white}`,
    borderRadius: selected && '8px',
    boxShadow: selected && `0 1px 7px ${colorPalette.ebb}`,
    cursor: 'pointer',
    display: 'flex',
    marginRight: '10px',
    padding: '5px 10px',
    '&:hover': {
      background: colorPalette.white
    }
  })
);

export const StyledIcon = styled('img')({
  height: '20px',
  marginRight: '6px',
  width: '20px'
});

export const StyledButtonContainer = styled(Button)<{
  version?: string;
}>(({ version }) => {
  if (version === 'outlined') {
    return {
      background: colorPalette.white,
      border: `0.08rem solid ${colorPalette.purple}`,
      borderRadius: '3.125rem',
      color: colorPalette.purple,
      display: 'inline-block',
      fontSize: '0.6875rem',
      fontWeight: '700',
      height: '1.7813rem',
      minWidth: '6.25rem',
      padding: '0.2rem 0.5rem',
      textAlign: 'center',
      textTransform: 'capitalize',
      '&:hover': {
        background: colorPalette.ashWhite
      },
      '&:disabled': {
        color: colorPalette.purple,
        border: '0px solid transparent',
        background: colorPalette.lightPurple
      }
    };
  }
  return {
    background: colorPalette.aquaGreen,
    borderRadius: '3.125rem',
    color: colorPalette.lightGreen,
    display: 'inline-block',
    fontSize: '0.9375rem',
    fontWeight: 550,
    height: '2.125rem',
    minWidth: '6.25rem',
    padding: '0.2rem 0.5rem',
    textAlign: 'center',
    textTransform: 'capitalize',
    '&:hover': {
      background: colorPalette.aquaGreen,
      color: colorPalette.lightGreen
    }
  };
});

export const StyledIconButton = styled(PsButton)({
  alignItems: 'center',
  backgroundColor: 'transparent',
  border: '1px solid',
  borderColor: colorPalette.purple,
  borderRadius: '6px',
  boxShadow: 'none',
  display: 'flex',
  height: '48px',
  justifyContent: 'center',
  marginBottom: '1rem',
  width: '35px',
  padding: '0 0',
  '&:hover': {
    backgroundColor: colorPalette.transparent,
    borderColor: colorPalette.purple,
    boxShadow: `0px 0px 10px 0px ${colorPalette.purple}65`
  }
});

export const ImageIconsBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '1rem'
});

export const StyledLoopIcon = styled(Loop)<{ loading: boolean }>(
  ({ loading }) => ({
    color: colorPalette.purple,
    animation: loading ? 'rotate 1s linear infinite' : 'none',
    '@keyframes rotate': {
      from: {
        transform: 'rotate(360deg)'
      },
      to: {
        transform: 'rotate(0deg)'
      }
    }
  })
);

export const StyledCreateButton = styled(Button)<{
  isCreateButton: boolean;
}>(({ isCreateButton }) => ({
  alignItems: 'center',
  backgroundColor: isCreateButton
    ? colorPalette.purple
    : colorPalette.greenHaze,
  borderRadius: '6px',
  display: 'flex',

  justifyContent: 'center',

  '&:hover': {
    backgroundColor: isCreateButton
      ? colorPalette.purple
      : colorPalette.greenHaze
  }
}));

export const StyledCreateButtonTypography = styled(Typography)(() => ({
  fontSize: '19px',
  fontWeight: '600',
  marginLeft: '12px',
  marginTop: '3px',
  textTransform: 'none'
}));

export const StyledImageBox = styled(Box)({
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  flex: '1',
  border: '2px dashed hsl(0deg 0% 67.72%)',
  padding: '0.5rem',
  minHeight: '18.6875rem'
});

export const StyledImageContainer = styled('img')({
  borderRadius: '6px',
  height: '17rem',
  objectFit: 'cover',
  overflow: 'hidden',
  width: '100%'
});

export const StyledTagFieldLabel = styled(Typography)({
  fontWeight: 500
});

export const DescriptionBox = styled(Box)({
  width: '50%'
});

export const StyledBodyTypography = styled(Typography)({
  color: `${colorPalette.dimBlack} !important`,
  fontSize: '15px !important',
  fontWeight: '500 !important',
  paddingBottom: '3px'
});

export const StyledBodyFieldText = styled(TextField)({
  borderRadius: '8px',
  width: '100%'
});

export const StyledImageSupportText = styled(Typography)({
  color: colorPalette.gray,
  fontSize: '12px',
  fontWeight: '500'
});

export const StyledImageMainContainer = styled(Box)({
  display: 'flex',
  width: '100%',
  marginTop: '2.1rem'
});

export const StyledSearchSelect = styled(PsSearchSelect)({
  background: 'blue',
  '& .css-13cymwt-control': {
    boxShadow: '0px 0px 16px 0px #00000014',
    border: '1px solid #E4E4E4',
    padding: '0.5rem 0'
  }
});

export const StyledIconButten = styled(IconButton)({
  backgroundColor: colorPalette.white,
  borderRadius: '.375rem',
  justifyContent: 'center',
  marginLeft: '.375rem',
  minHeight: '3.25rem',
  minWidth: '3.25rem',
  padding: '.5rem'
});

export const StyledDescriptionField = styled(TextField)({
  width: '100%',
  marginBottom: '0.5rem',
  '&:hover': {
    borderColor: colorPalette.purple
  },
  '& fieldset': {
    borderColor: colorPalette.lightGrey
  }
});

export const StyledGenerateButtonContainer = styled(Box)({
  alignSelf: 'center',
  display: 'grid',
  margin: 'auto'
});

export const StyledGenerateImageText = styled(Typography)({
  color: colorPalette.spanishGray,
  fontSize: '18px',
  fontWeight: 600
});

export const StyledFieldLabel = styled(Typography)({
  display: 'inline-block',
  fontWeight: 600,
  marginBottom: '-0.3125rem'
});

export const StyledSnackbarContent = styled(SnackbarContent)({
  backgroundColor: colorPalette.red,
  fontWeight: 600,
  maxWidth: '21.875rem'
});

export const StyledTextModal = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '80vh',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[24],
  padding: theme.spacing(4),
  overflowY: 'scroll',
  maxWidth: '1050px',
  width: '100%'
}));

export const StyledButtonMainContainer = styled(Button)<{
  itemType: string;
  isMediumScreen: boolean;
}>(({ itemType, theme, isMediumScreen }) => ({
  backgroundColor:
    itemType === Constants.PROBLEM || itemType === Constants.SOLUTION
      ? colorPalette.lightGreen
      : '',
  '&:hover': {
    backgroundColor:
      itemType === Constants.PROBLEM || itemType === Constants.SOLUTION
        ? colorPalette.lightGreen
        : ''
  },
  borderRadius: itemType === Constants.CREATE ? '.5rem' : '3rem',
  maxWidth: '33rem',
  minWidth:
    itemType === Constants.CREATE ? '100%' : isMediumScreen ? '25rem' : '33rem',
  color: colorPalette.white,
  display: 'flow',
  fontSize: '.75rem',
  fontWeight: '700',
  padding: '.5rem .8rem',
  textTransform: 'capitalize',
  '&:disabled': {
    backgroundColor: colorPalette.doveGray,
    color: colorPalette.white,
    cursor: 'not-allowed',
    opacity: 1
  },
  [theme.breakpoints.up('md')]: {
    padding: '.6rem 2.8rem',
    fontSize: '1rem'
  }
}));

export const StyledActionButtonContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between'
});

export const StyledButtonTextContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  fontSize: '1.75rem',
  fontWeight: 700
});

export const StyledIdeaIconTextContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex'
});

export const StyledIdeadPointsIconContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  marginRight: '.5rem'
});

export const StyledButtonEarnPointsTextContainer = styled(Box)({
  fontSize: '1.375rem',
  fontWeight: 500
});

export const StyledAddToIconContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  fontSize: '1.375rem',
  fontWeight: 500
});
