import {
  DISTRIBUTE_REWARD,
  DISTRIBUTE_REWARD_SUCCESS,
  GET_REWARD_POOL_THRESHOLD_SUCCESS
} from './types';

const INITIAL_STATE = {
  distributeRewardLoading: false,
  rewardPoolThreshold: undefined
};

const reducer = (
  state = INITIAL_STATE,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case DISTRIBUTE_REWARD:
      return { ...state, distributeRewardLoading: true };
    case DISTRIBUTE_REWARD_SUCCESS:
      return { ...state, distributeRewardLoading: false };
    case GET_REWARD_POOL_THRESHOLD_SUCCESS:
      return { ...state, rewardPoolThreshold: action.payload.threshold };
    default:
      return state;
  }
};

export default reducer;
