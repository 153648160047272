import {
  CallEffect,
  PutEffect,
  call,
  put,
  takeLatest
} from 'redux-saga/effects';
import { Constants } from 'utilities/constants';
import { setGraph } from '../ideamap/actions';
import { getProfileContests } from '../profile/actions';
import * as Actions from './actions';
import { Api } from './api';
import { GetContestPayload } from './interface';
import {
  CREATE_CONTEST,
  GENERATE_AI_COVER_IMAGE,
  GENERATE_AI_DESCRIPTION,
  GENERATE_AI_IMAGE,
  GET_CONTESTS,
  GET_PROBLEMS_FROM_ID,
  GET_SOLUTIONS_WITH_OWNER_INFO,
  UPDATE_CONTEST
} from './types';

function* createContest(action) {
  try {
    const response = yield call(Api.createContest, {
      data: action.payload.data
    });
    if (response) {
      yield put(Actions.setContest(response.data));
      if (response.data.status !== Constants.DRAFT) {
        window.location.href = `/contests/${response.data.key}`;
      }
    }
  } catch (error) {
    console.log('error', error);
  }
}

function* getContests(action: {
  type: string;
  payload: GetContestPayload;
}): Generator<CallEffect | PutEffect, void, any> {
  try {
    const response = yield call(Api.getContests, action.payload);
    if (response) {
      yield put(Actions.getContestSuccess(response.data));
    }
  } catch (error) {
    const errorMessage = error.message;
    console.log('Error in getting contests.', errorMessage);
  }
}

function* getProblemsFromId(action) {
  try {
    const response = yield call(Api.getProblemsFromId, {
      data: action.payload.data
    });
    if (response) {
      yield put(Actions.setProblems(response.data));
    }
  } catch (error) {
    console.log('error', error);
  }
}

function* updateContest(action) {
  try {
    const response = yield call(Api.updateContest, {
      id: action.payload.id,
      data: action.payload.data
    });
    if (response) {
      yield put(setGraph({}));
      yield put(Actions.setContest(response.data));
      yield put(
        getProfileContests(
          action.payload.userId,
          action.payload.pagination,
          true,
          action.payload.isArchived
        )
      );
    }
  } catch (error) {
    console.log('error', error);
  }
}

function* getSolutionsWithOwnerInfo(data) {
  try {
    yield put(Actions.togLoadLeaderboard(true));
    const response = yield call(Api.getSolutionsWithOwnerInfo, { data });
    if (response) {
      yield put(Actions.setSolutionsWithOwnerInfo(response));
      yield put(Actions.togLoadLeaderboard(false));
    }
  } catch (error) {
    console.log('Error', error);
  }
}

function* generateDescriptionFromAI(payload) {
  try {
    const response = yield call(Api.generateDescriptionFromAI, payload);
    if (response) {
      yield put(Actions.generateDescriptionFromAISuccess(response));
    } else {
      yield put(Actions.generateDescriptionFromAIFail());
    }
  } catch (err) {
    console.log('error', err);
  }
}

function* generateImageFromAI(payload) {
  try {
    const response = yield call(Api.generateImageFromAI, { payload });
    if (response) {
      yield put(Actions.generateImageFromAISuccess(response));
    } else {
      yield put(Actions.generateImageFromAIFail());
    }
  } catch (err) {
    console.log('error', err);
  }
}

function* generateCoverImageFromAI(payload) {
  try {
    const response = yield call(Api.generateCoverImageFromAI, { payload });
    if (response) {
      yield put(Actions.generateCoverImageFromAISuccess(response));
    } else {
      yield put(Actions.generateCoverImageFromAIFail());
    }
  } catch (err) {
    yield put(Actions.generateCoverImageFromAIFail());
    yield put(Actions.generateCoverImageFromAISuccess({}));
    console.log('error', err);
  }
}

function* mySaga() {
  yield takeLatest(CREATE_CONTEST, createContest);
  yield takeLatest(GENERATE_AI_COVER_IMAGE, generateCoverImageFromAI);
  yield takeLatest(GENERATE_AI_DESCRIPTION, generateDescriptionFromAI);
  yield takeLatest(GENERATE_AI_IMAGE, generateImageFromAI);
  yield takeLatest(GET_CONTESTS, getContests);
  yield takeLatest(GET_PROBLEMS_FROM_ID, getProblemsFromId);
  yield takeLatest(GET_SOLUTIONS_WITH_OWNER_INFO, getSolutionsWithOwnerInfo);
  yield takeLatest(UPDATE_CONTEST, updateContest);
}

export default mySaga;
