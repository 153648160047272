import React, { useEffect, useState } from 'react';
import { BigNumber, ethers } from 'ethers';
import { CircularProgress, Grid } from '@mui/material';
import Config from 'config/config';
import NftAbi from 'contract/NFT.json';
import { fetchNftMetadata, sepolia } from 'helpers/blockchain';
import { GetUser } from 'redux-state/selectors';
import { Constants, ERRORS } from 'utilities/constants';
import { NftCard } from '../NftCard';
import { NotFoundText, ProgressWrapper, StyledGrid } from '../styledComponents';

const provider = new ethers.providers.JsonRpcProvider(sepolia.rpcUrl);
const nftContract = new ethers.Contract(
  Config.NFT_CONTRACT_ADDRESS,
  NftAbi,
  provider
);

export const NftView = () => {
  const user = GetUser();
  const [tokenData, setTokenData] = useState<
    Array<{
      id: number;
      image: string;
      name: string;
    }>
  >([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchTokenIdAndUri() {
      try {
        const [rawTokenIds, uris] = await nftContract.getTokenIdsAndUris(
          user.walletAddress
        );
        const tokenIds = rawTokenIds.map((id: BigNumber) => id.toString());
        const metadataResults = await Promise.all(
          uris.map((uri) => fetchNftMetadata(uri))
        );
        const data = tokenIds.reduce((acc, tokenId, index) => {
          acc.push({ id: tokenId, ...metadataResults[index] });
          return acc;
        }, []);
        setTokenData(data);
        setLoading(false);
      } catch (error) {
        console.error(ERRORS.FETCH_TOKEN_ID_AND_URIS, error);
      }
    }
    fetchTokenIdAndUri();
  }, [user.walletAddress]);

  return (
    <>
      {loading && (
        <ProgressWrapper>
          <CircularProgress />
        </ProgressWrapper>
      )}
      {!loading && !tokenData.length && (
        <NotFoundText>{Constants.NO_NFT_MINTED}</NotFoundText>
      )}
      {tokenData.length > 0 && (
        <StyledGrid container spacing={1.5}>
          {tokenData.map((token, index) => (
            <Grid item xs={12} sm={4} md={4} lg={4} key={index}>
              <NftCard
                tokenId={String(token.id)}
                image={token.image}
                name={token.name}
              />
            </Grid>
          ))}
        </StyledGrid>
      )}
    </>
  );
};
