import React from 'react';
import { ethers } from 'ethers';
import { useSearchParams } from 'react-router-dom';
import inventionImage from 'assets/placeholder/invention.svg';
import problemImage from 'assets/placeholder/problem.svg';
import profileImage from 'assets/placeholder/profile.svg';
import solutionImage from 'assets/placeholder/solution.svg';
import tagImage from 'assets/placeholder/tag.svg';
import { ChallengeIcon } from 'components/icons/ChallengeIcon';
import { ContestIcon } from 'components/icons/ContestIcon';
import { FeedIcon } from 'components/icons/FeedIcon';
import { HomeIcon } from 'components/icons/HomeIcon';
import { InventionIcon } from 'components/icons/InventionIcon';
import { ProblemIcon } from 'components/icons/ProblemIcon';
import { ProductIcon } from 'components/icons/ProductIcon';
import { SolutionIcon } from 'components/icons/SolutionIcon';
import { REACTION_TYPES } from 'utilities/constants';
import * as pdfjs from 'pdfjs-dist';
import {
  PDFDocumentProxy,
  RenderParameters
} from 'pdfjs-dist/types/display/api';
import Tesseract from 'tesseract.js';
import Config from 'config/config';

const provider = new ethers.providers.JsonRpcProvider(Config.INFURA_URL);
const wallet = new ethers.Wallet(Config.PRIVATE_KEY, provider);

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export const links: { link: string; title: string }[] = [
  { title: 'Home', link: '/' },
  { title: 'My Feed', link: '/activity' },
  { title: 'Problems', link: '/problems' },
  { title: 'Solutions', link: '/solutions' },
  { title: 'Inventions', link: '/inventions' },
  { title: 'Products', link: '/products' },
  { title: 'Problem Contests', link: '/contests' },
  { title: 'Group Challenges', link: '/challenges' }
];

export const navLinks: {
  link: string;
  title: string;
  icon: React.ReactElement;
  authRestricted: boolean;
}[] = [
  {
    title: 'Home',
    link: '/',
    icon: React.createElement(HomeIcon),
    authRestricted: false
  },
  {
    title: 'My Feed',
    link: '/activity',
    icon: React.createElement(FeedIcon),
    authRestricted: false
  },
  {
    title: 'Problems',
    link: '/problems',
    icon: React.createElement(ProblemIcon),
    authRestricted: false
  },
  {
    title: 'Solutions',
    link: '/solutions',
    icon: React.createElement(SolutionIcon),
    authRestricted: false
  },
  {
    title: 'Inventions',
    link: '/inventions',
    icon: React.createElement(InventionIcon),
    authRestricted: false
  },
  {
    title: 'Products',
    link: '/products',
    icon: React.createElement(ProductIcon),
    authRestricted: false
  },
  {
    title: 'Problem Contests',
    link: '/contests',
    icon: React.createElement(ContestIcon),
    authRestricted: false
  },
  {
    title: 'Group Challenges',
    link: '/challenges',
    icon: React.createElement(ChallengeIcon),
    authRestricted: false
  }
];

export const finalizeType = {
  OWN: 'own',
  SHARE: 'share',
  OPEN: 'open'
};

export const subscriptionType = {
  FREE: 'free',
  INDIVIDUAL: 'individual',
  INDIVIDUAL_TOP: 'individualTop',
  COMPANY: 'company',
  COMPANY_TOP: 'companyTop'
};

export const rewardType = {
  APP_PAY: 'app-pay',
  SHARE_FB: 'share-fb',
  SHARE_LN: 'share-ln',
  SHARE_TW: 'share-tw',
  SHARE_IN: 'share-in',
  SHARE_WA: 'share-wa',
  SHARE_DC: 'share-dc',
  NFT_DEPLOY: 'nft-deploy',
  NFT_DEPLOY_MM: 'nft-deploy-mm',
  SOLUTION_IMPROVE: 'solution-improve',
  SOLUTION_CREATE: 'solution-create',
  APP_IMPROVE: 'app-improve',
  CONTEST_WIN: 'contest-win',
  CONTEST_OWNER_WIN: 'contest-owner-win',
  CHALLENGE_WIN: 'challenge-win',
  SOLUTION_VOTE: 'solution-vote'
};

export const rewardResource = {
  PROBLEM: 'problems',
  SOLUTION: 'solutions',
  APPLICATION: 'applications',
  PRODUCT: 'products',
  CONTEST: 'contests',
  TAG: 'tags',
  PROFILE: 'profiles'
};

export const placeholderType = {
  P: 'problem',
  S: 'solution',
  I: 'invention',
  A: 'application',
  T: 'tag',
  U: 'profile'
};

export const activityItem = rewardResource;

export const activityAction = {
  CREATE: 'create',
  SHARE: 'share',
  LIKE: 'voteLike',
  DISLIKE: 'voteDislike',
  SOLVE: 'solve',
  IMPROVE: 'improve',
  ADD_CONTEST_SOLUTION: 'addContestSolution'
};

export type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<
  T,
  Exclude<keyof T, Keys>
> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
  }[Keys];

export type Picture = {
  contentType: string;
  url: string;
  title: string;
};

export type PdfReaderTypes = {
  receiptNumber: string;
  filingDate: string;
};

export const getOwnerMaticBalance = async () => {
  const ownerMaticBalance = await provider.getBalance(wallet.address);
  return ethers.utils.formatEther(ownerMaticBalance);
};

export const formatNumber = (num: number): string => {
  if (num <= 999) {
    return num.toString();
  }
  const suffixes = ['', 'k', 'm', 'b', 't'];
  const suffixNum = Math.floor(Math.log10(num) / 3);
  const shortNum = (num / Math.pow(1000, suffixNum)).toFixed(1);
  return shortNum + suffixes[suffixNum];
};

export const getOrdinal = (index) => {
  if (index === 1) return '1st';
  if (index === 2) return '2nd';
  if (index === 3) return '3rd';
  return `${index}th`; // For 4th and beyond
};

const extractTextUsingRegex = (
  text = '',
  regex = /APPLICATION FILING or GRP ART\s*([\s\S]*?(\d{2}\/\d{2}\/\d{4}))/
) => {
  // Use the `exec` method to find the first match in the paragraph
  const match = regex.exec(text);

  // Check if there is a match
  if (match) {
    const nextLineContent = match[1].trim(); // Extract the captured content and trim whitespace
    const splittedText = nextLineContent.split(' ');
    return { receiptNumber: splittedText[0], filingDate: splittedText[1] };
  }
};

export const extractReceiptUsingOCR = async (
  file: File
): Promise<PdfReaderTypes> => {
  // Initialize PDF.js
  const arrayBuffer = await file.arrayBuffer();
  const pdfData = new Uint8Array(arrayBuffer);
  const pdf: PDFDocumentProxy = await pdfjs.getDocument({
    data: pdfData
  }).promise;
  // Iterate through each page and extract text
  for (let pageNumber = 1; pageNumber <= 1; pageNumber++) {
    const page = await pdf.getPage(pageNumber);
    const viewport = page.getViewport({ scale: 2.5 }); // Adjust the scale as needed

    // Convert PDF page to image
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    const renderContext = {
      canvasContext: context,
      viewport: viewport
    };

    await page.render(renderContext as RenderParameters).promise;
    const imageData = canvas.toDataURL('image/png');

    // Extract text using Tesseract.js
    const {
      data: { text }
    } = await Tesseract.recognize(
      imageData,
      'eng' // Language code (English in this case)
      // { logger: (info) => console.log(info) }, // Optional logger function
    );

    return extractTextUsingRegex(text) ?? { receiptNumber: '', filingDate: '' };
  }
  return { receiptNumber: '', filingDate: '' };
};

export const readTextFromPdf = (file: File): Promise<PdfReaderTypes> => {
  return new Promise((resolve, reject) => {
    // Check if the uploaded file is a PDF
    if (file && file.type === 'application/pdf') {
      const reader = new FileReader();

      reader.onload = async (event: ProgressEvent<FileReader>) => {
        if (!event?.target) return;
        const arrayBuffer = event.target.result;
        const pdfData = new Uint8Array(arrayBuffer as ArrayBufferLike);

        // Load PDF using pdfjs
        const loadingTask = pdfjs.getDocument(pdfData);
        try {
          const pdf = await loadingTask.promise;

          // Extract text from each page
          for (let pageNum = 1; pageNum <= 1; pageNum++) {
            const page = await pdf.getPage(pageNum);
            const textContent = await page.getTextContent();
            const pageText = textContent.items
              .map((item) => item.str)
              .join(' ');

            if (!pageText) {
              throw 'Not able to read text';
            }
            const requiredText = extractTextUsingRegex(
              pageText,
              /TIME ATTORNEY DOCKET #\s*([\s\S]*?(\d{2}\/\d{2}\/\d{4}))/
            );

            if (requiredText) {
              resolve(requiredText);
            }
          }
          reject('Receipt and Filing date not found');
        } catch (error) {
          reject(error);
        }
      };
      reader.readAsArrayBuffer(file);
    } else {
      reject('Invalid file type');
    }
  });
};

export const getPlaceholderImage = (type?: string) => {
  switch (type) {
    case 'problem': {
      return problemImage;
    }
    case 'solution': {
      return solutionImage;
    }
    case 'invention':
    case 'application': {
      return inventionImage;
    }
    case 'tag': {
      return tagImage;
    }
    default: {
      return profileImage;
    }
  }
};

export const getPlaceholderType = (activityType?: string) => {
  switch (activityType) {
    case activityItem.PROBLEM: {
      return placeholderType.P;
    }
    case activityItem.SOLUTION: {
      return placeholderType.S;
    }
    case activityItem.APPLICATION: {
      return placeholderType.I;
    }
    case activityItem.TAG: {
      return placeholderType.T;
    }
    case activityItem.CONTEST: {
      return placeholderType.T;
    }
    default: {
      return placeholderType.U;
    }
  }
};

export const convertFileToBase64 = (
  file: File
): Promise<{ base64: string; file: File }> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve({ file, base64: reader.result as string });
    reader.onerror = reject;
    reader.onabort = reject;
    reader.readAsDataURL(file);
  });

export const convertUrlToBase64 = (url: string): Promise<string> =>
  fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result as string);
          reader.onerror = reject;
          reader.onabort = reject;
          reader.readAsDataURL(blob);
        })
    );

const getMonth = (m: number): string => {
  let month = '';
  switch (m) {
    case 0: {
      month = 'January';
      break;
    }
    case 1: {
      month = 'February';
      break;
    }
    case 2: {
      month = 'March';
      break;
    }
    case 3: {
      month = 'April';
      break;
    }
    case 4: {
      month = 'May';
      break;
    }
    case 5: {
      month = 'June';
      break;
    }
    case 6: {
      month = 'July';
      break;
    }
    case 7: {
      month = 'August';
      break;
    }
    case 8: {
      month = 'September';
      break;
    }
    case 9: {
      month = 'October';
      break;
    }
    case 10: {
      month = 'November';
      break;
    }
    case 11: {
      month = 'December';
      break;
    }
  }
  return month;
};

const getMonthShort = (m: number): string => {
  let month = '';
  switch (m) {
    case 0: {
      month = 'Jan';
      break;
    }
    case 1: {
      month = 'Feb';
      break;
    }
    case 2: {
      month = 'Mar';
      break;
    }
    case 3: {
      month = 'Apr';
      break;
    }
    case 4: {
      month = 'May';
      break;
    }
    case 5: {
      month = 'Jun';
      break;
    }
    case 6: {
      month = 'Jul';
      break;
    }
    case 7: {
      month = 'Aug';
      break;
    }
    case 8: {
      month = 'Sep';
      break;
    }
    case 9: {
      month = 'Oct';
      break;
    }
    case 10: {
      month = 'Nov';
      break;
    }
    case 11: {
      month = 'Dec';
      break;
    }
  }
  return month;
};

export const getDateStr = (
  dateStr: string | undefined,
  short?: boolean
): string => {
  let res = ''; // January 29, 2021
  if (dateStr) {
    const date = new Date(dateStr);
    const month = short
      ? getMonthShort(date.getMonth())
      : getMonth(date.getMonth());
    res = `${month} ${date.getDate()}, ${date.getFullYear()}`;
  }
  return res;
};

type MyRecord = {
  id: string | number;
  title: string;
};

type Tree = MyRecord & {
  subProblems?: Array<Tree>;
  solutions?: Array<Tree>;
  subSolutions?: Array<Tree>;
};

export type Item = MyRecord & {
  level: number;
  type: string;
  parents: Array<string | number>;
  childs: Array<string | number>;
};

export const flatList = (
  tree: Tree,
  list: Array<Item> = [],
  level = 0,
  type = 'problem',
  parents: Array<string | number> = [],
  childs: Array<Array<string | number>> = []
) => {
  const { subProblems, solutions, subSolutions, ...rest } = tree;

  const item = {
    ...rest,
    level: level,
    type,
    parents
  } as Item;
  const itemChilds: Array<string | number> = [];
  const itemParents = [...parents] as Array<string | number>;
  if (level !== 0) {
    itemParents.push(item.id);
  }

  const myChilds = [...childs] as Array<Array<string | number>>;
  myChilds.forEach((arr) => arr.push(item.id));
  myChilds.push(itemChilds);

  list.push(item);
  if (subProblems && subProblems.length) {
    subProblems.forEach((node) => {
      flatList(node, list, level + 1, 'subproblem', itemParents, myChilds);
    });
  }
  if (solutions && solutions.length) {
    solutions.forEach((node) => {
      flatList(node, list, level + 1, 'solution', itemParents, myChilds);
    });
  }
  if (subSolutions && subSolutions.length) {
    subSolutions.forEach((node) => {
      flatList(node, list, level + 1, 'subsolution', itemParents, myChilds);
    });
  }
  item.childs = itemChilds;
  return list;
};

export const isTitleValid = (title: string, start: string): boolean => {
  if (!title.startsWith(start)) {
    return false;
  }
  let rest = title.slice(start.length);
  rest = rest.trim();
  if (!rest) {
    return false;
  }
  return true;
};

export const isObjectEmpty = (obj) => {
  return obj && Object.keys(obj).length == 0;
};

export const getListPerPage = (): number => {
  const defValue = 5;
  if (
    typeof window !== 'undefined' &&
    window &&
    window.localStorage &&
    window.localStorage['ListPerPage']
  ) {
    const resStr = window.localStorage['ListPerPage'];
    const res = parseInt(resStr);
    return Number.isNaN(res) ? defValue : res;
  }
  return defValue;
};

export const setListPerPage = (val: number): void => {
  if (typeof window !== 'undefined' && window && window.localStorage) {
    window.localStorage['ListPerPage'] = val;
  }
};

export const getListSortBy = (): string => {
  const defValue = 'votes1d';
  if (
    typeof window !== 'undefined' &&
    window &&
    window.localStorage &&
    window.localStorage['ListSortBy']
  ) {
    const resStr = window.localStorage['ListSortBy'];
    return resStr || defValue;
  }
  return defValue;
};

export const setListSortBy = (val: string): void => {
  if (typeof window !== 'undefined' && window && window.localStorage) {
    window.localStorage['ListSortBy'] = val;
  }
};

export const setDocumentTitle = (pageType: string, itemTitle?: string) => {
  let title = 'MindMiner';
  if (itemTitle) {
    title += ` - ${itemTitle}`;
    document.title = title;
    return;
  }
  if (pageType === 'Home') {
    title += ` - Home`;
  }
  if (pageType === 'Application') {
    title += ` - Inventions`;
  }
  if (pageType === 'Solution') {
    title += ` - Solutions`;
  }
  if (pageType === 'Problem') {
    title += ` - Problems`;
  }
  document.title = title;
};

export const useRemoveQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const removeQueryParams = (params: Array<string>) => {
    params.forEach((param) => {
      searchParams.delete(param);
    });
    setSearchParams(searchParams);
  };

  return removeQueryParams;
};

export const removeDuplicates = (items: Array<any> = []): Array<any> => {
  const map: any = {};
  const res = items
    .map((str) => {
      if (!map[str]) {
        map[str] = true;
        return str;
      }
      return '';
    })
    .filter((str) => str);
  return res;
};

export const getQuery = (): Record<string, string> => {
  if (typeof window !== 'undefined') {
    const vars = window.location.search.replace('?', '').split('&');
    const data: Record<string, any> = {};
    vars.forEach((varStr) => {
      const parts = varStr.split('=');
      if (parts.length === 2) {
        data[parts[0]] = decodeURIComponent(parts[1]);
      }
    });
    return data;
  }
  return {};
};

export const getFileTitle = (fileName: string): string => {
  const extIndex = fileName?.lastIndexOf('.');
  return fileName?.slice(0, extIndex);
};

export const updateFileName = (fileName: string, newTitle: string): string => {
  const extIndex = fileName?.lastIndexOf('.');
  const ext = fileName?.slice(extIndex);
  return newTitle + ext;
};

const anArticleLetters = ['a', 'e', 'i', 'o', 'u'];

export const getArticle = (nextWord = ''): string => {
  if (!nextWord) {
    return '';
  }
  const nextWordLetter = nextWord[0].toLowerCase();
  if (anArticleLetters.includes(nextWordLetter)) {
    return 'an';
  }
  return 'a';
};

const defaultSymbols = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

interface AbbreviateOptions {
  padding?: boolean;
  symbols?: string[];
}

const defaultOptions = {
  padding: true,
  symbols: defaultSymbols
};

export const abbreviateNumber = (
  num: number,
  digit = 1,
  options?: AbbreviateOptions | AbbreviateOptions['symbols']
) => {
  // Previous options style
  if (Array.isArray(options)) {
    options = { symbols: options };
  }

  const { symbols, padding } = Object.assign({}, defaultOptions, options);

  // handle negatives
  const sign = Math.sign(num) >= 0;
  num = Math.abs(num);

  // what tier? (determines SI symbol)
  const tier = (Math.log10(num) / 3) | 0;

  // if zero, we don't need a suffix
  if (tier == 0) return (!sign ? '-' : '') + num.toString();

  // get suffix and determine scale
  const suffix = symbols[tier];
  if (!suffix) throw new RangeError();

  const scale = Math.pow(10, tier * 3);

  // scale the number
  const scaled = num / scale;

  let rounded = scaled.toFixed(digit);
  if (!padding) {
    rounded = String(Number(rounded));
  }

  // format number and add suffix
  return (!sign ? '-' : '') + rounded + suffix;
};

export const limitText = (text: string, size: number) => {
  if (text.length < size) {
    return text;
  }
  return text.slice(0, size) + '...';
};

const propsMap = {
  descFurther: 'description',
  descMaterials: 'materials',
  descDimensions: 'dimensions',
  descSteps: 'following steps',
  relations: 'relation to another solution',
  files: 'images'
};

export function makeSolutionValidation(solution: any): Array<string> {
  const {
    descFurther,
    descMaterials,
    descDimensions,
    descSteps,
    relations,
    files
  } = solution;
  const errors: Array<string> = [];
  if (!descFurther) {
    errors.push(propsMap.descFurther);
  }
  if (!descMaterials) {
    errors.push(propsMap.descMaterials);
  }
  if (!descDimensions) {
    errors.push(propsMap.descDimensions);
  }
  if (!descSteps) {
    errors.push(propsMap.descSteps);
  }
  if (!relations || !relations.length) {
    errors.push(propsMap.relations);
  }
  if (!files || !files.length) {
    errors.push(propsMap.files);
  }
  return errors;
}

export const findNode = (currentNode, targetId) => {
  // Check if currentNode matches targetId
  if (currentNode.id === targetId) {
    return currentNode;
  }

  // If currentNode doesn't match targetId, recursively search in its children
  if (!currentNode.children) {
    return false;
  }

  for (let i = 0; i < currentNode.children.length; i++) {
    // Recursively update children nodes
    const hasFound = findNode(currentNode.children[i], targetId);
    if (hasFound) return hasFound;
  }
  return null;
};

export const handleReaction = (currentNode, targetId, type) => {
  // Check if currentNode matches targetId
  if (currentNode.id === targetId) {
    if (type === REACTION_TYPES.LIKE) {
      // Toggle like status and update likes count
      currentNode.likes += currentNode.isLiked ? -1 : 1;
      if (!currentNode.isLiked && currentNode.dislikes > 0) {
        currentNode.dislikes--;
      }
      currentNode.isLiked = !currentNode.isLiked;
    } else if (currentNode.big && type === REACTION_TYPES.DISLIKE) {
      // Remove the currentNode if it's a big node and dislike action
      for (const key in currentNode) {
        delete currentNode[key];
      }
    }
    return true;
  }

  // If currentNode doesn't match targetId, recursively search in its children
  if (!currentNode.children) {
    return false;
  }

  let hasFound = false;
  for (let i = 0; i < currentNode.children.length; i++) {
    // Recursively update children nodes
    hasFound = handleReaction(currentNode.children[i], targetId, type);
    if (hasFound) break;
  }

  // If targetId is found and updated, perform additional actions
  if (hasFound) {
    if (type === 'like' && currentNode.children.length >= 2) {
      // Sort children nodes by likes count if like action and more than two children
      currentNode.children.sort((a, b) => b.likes - a.likes);
    }
    if (type === 'dislike') {
      // Filter out the disliked node
      currentNode.children = currentNode.children.filter(
        (node) => node.id !== targetId
      );
    }
  }
};

export const isObjectId = (input: string) => {
  return /^[0-9a-fA-F]{24}$/.test(input);
};

export const conceptCartSearch = (data, inputValue) => {
  if (!inputValue.trim()) {
    return data ?? [];
  }
  return (data ?? []).filter((concept) =>
    concept.title.toLowerCase().includes(inputValue.trim().toLowerCase())
  );
};
