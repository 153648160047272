import { Box, Button, styled } from '@mui/material';
import { colorPalette } from 'theme';

export const StyledMainBox = styled(Box)({
  backgroundColor: colorPalette.white,
  height: 'auto',
  width: '100%'
});

export const StyledBox = styled(Box)({
  backgroundColor: colorPalette.white,
  height: 'auto',
  padding: '15px',
  width: '100%'
});

export const ButtonContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  padding: '15px 15px 0px 0px'
});

export const AddToBut = styled(Button)<{ isCurrentConceptSolution?: boolean }>(
  ({ isCurrentConceptSolution = false, theme }) => ({
    backgroundColor: isCurrentConceptSolution
      ? colorPalette.explosiveGrey
      : colorPalette.lightGreen,
    borderRadius: '1.5625rem',
    color: isCurrentConceptSolution ? colorPalette.grey : colorPalette.white,
    padding: '.625rem .9375rem',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  })
);
