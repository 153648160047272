import {
  CIRCULAR_PROGRESS_VALUE,
  CIRCULAR_PROGRESS_VALUE_FOR_PRODUCT,
  CREATE_CONCEPT,
  GENERATE_AI,
  GET_CHILDREN_GRAPH,
  GET_CONCEPT,
  GET_CONCEPTS_SUCCESS,
  GET_CONTESTS_BY_PROBLEM_AND_SOLUTION,
  GET_GRAPH,
  SET_CONCEPT_DATA,
  SET_GRAPH_DATA,
  SET_SELECTED_ITEM,
  TOGGLE_CONCEPT_CART,
  TOGGLE_LOADER_AI_GENERATION,
  TOGGLE_LOADER_CONTESTS,
  TOGGLE_LOADER_IMAGE_GENERATION,
  TOGGLE_LOADER_IMAGE_UPLOAD,
  TOGGLE_LOADER_NEW_CONCEPT,
  TOGGLE_LOADER_SOLUTION,
  TOGGLE_SIDE_DRAWER,
  UPDATE_CONCEPT,
  UPDATE_CONCEPT_SUCCESS
} from './types';

export const createConcept = (data) => ({
  type: CREATE_CONCEPT,
  payload: { data }
});

export const generateTitle = (conceptId) => ({
  type: GENERATE_AI,
  payload: { conceptId }
});

export const getChildrenGraph = (id, type, mapType) => ({
  type: GET_CHILDREN_GRAPH,
  payload: { id, mapType, type }
});

export const getConcept = (userId) => ({
  type: GET_CONCEPT,
  payload: { userId }
});

export const getConceptSuccess = (payload) => ({
  type: GET_CONCEPTS_SUCCESS,
  payload
});

export const getContestsByProblemOrSolution = (id, type) => ({
  type: GET_CONTESTS_BY_PROBLEM_AND_SOLUTION,
  payload: { id, type }
});

export const getGraph = (id, type, mapType) => ({
  type: GET_GRAPH,
  payload: { id, type, mapType }
});

export const setConcept = (data) => ({
  type: SET_CONCEPT_DATA,
  payload: { data }
});

export const setGraph = (data) => ({
  type: SET_GRAPH_DATA,
  payload: { data }
});

export const setSelectedItem = (payload) => ({
  type: SET_SELECTED_ITEM,
  payload
});

export const toggleConceptCart = (payload: boolean) => ({
  type: TOGGLE_CONCEPT_CART,
  payload
});

export const togLoadAiGeneration = (payload: boolean) => ({
  type: TOGGLE_LOADER_AI_GENERATION,
  payload
});

export const togLoadContests = (payload: boolean) => ({
  type: TOGGLE_LOADER_CONTESTS,
  payload
});

export const togLoadImageGeneration = (payload: boolean) => ({
  type: TOGGLE_LOADER_IMAGE_GENERATION,
  payload
});

export const togLoadImageUpload = (payload: boolean) => ({
  type: TOGGLE_LOADER_IMAGE_UPLOAD,
  payload
});

export const togLoadNewConcept = (payload: boolean) => ({
  type: TOGGLE_LOADER_NEW_CONCEPT,
  payload
});

export const togLoadSolution = (payload: boolean) => ({
  type: TOGGLE_LOADER_SOLUTION,
  payload
});

export const toggleSideDrawer = (payload: boolean) => ({
  type: TOGGLE_SIDE_DRAWER,
  payload
});

export const updateConcept = (id, data, loading = true) => ({
  type: UPDATE_CONCEPT,
  payload: { id, data }
});

export const updateConceptSuccess = (payload) => ({
  type: UPDATE_CONCEPT_SUCCESS,
  payload
});

export const setCircularProgressValueForProduct = (productId, payload) => ({
  type: CIRCULAR_PROGRESS_VALUE_FOR_PRODUCT,
  payload: { productId, value: payload }
});

export const setCircularProgressValue = (payload: number) => ({
  type: CIRCULAR_PROGRESS_VALUE,
  payload
});
