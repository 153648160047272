import { Box, TextField, Typography, styled } from '@mui/material';
import { PsButton } from 'components/common/PsButton';
import { colorPalette } from 'theme';

export const MainContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  backgroundColor: colorPalette.white,
  borderRadius: '7px',
  padding: '1.25rem'
});

export const NameFieldBox = styled(Box)({
  marginBottom: '1.5rem',
  width: '100%'
});

export const DescrMainBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between'
});

export const NameTextField = styled(TextField)({
  borderRadius: '8px',
  width: '100%',
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: colorPalette.purple,
      boxShadow: `0px 0px 10px 0px ${colorPalette.purple}50`
    }
  }
});

export const TextFieldBox = styled(Box)({
  marginBottom: '1.5rem',
  width: '49%'
});

export const StyledBodyFieldText = styled(TextField)({
  borderRadius: '8px',
  width: '100%'
});

export const StyledBodyTypography = styled(Typography)({
  color: `${colorPalette.lightBlack} !important`,
  fontSize: '18px !important',
  fontWeight: '600 !important',
  paddingBottom: '3px'
});

export const TitleText = styled(Typography)({
  color: colorPalette.black,
  fontSize: '26px',
  fontWeight: '700',
  marginRight: '2rem',
  padding: '0',
  whiteSpace: 'nowrap',
  width: '40%'
});

export const StyledSaveButton = styled(PsButton)({
  borderRadius: '6px !important',
  color: colorPalette.white,
  fontSize: '18px !important',
  fontWeight: 'bold',
  height: '45px',
  outline: 'none',
  padding: '8px 0px !important',
  width: '218px'
});

export const StyledFooterBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between'
});
