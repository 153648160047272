import React from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { Tag } from 'components/CardTag';
import {
  Card,
  CardImage,
  CardBody,
  CardTitle,
  CardOwner,
  CardText,
  CardActions,
  CardRow,
  CardCell
} from 'components/card-small';
import { placeholderType } from '../../helpers';
import { ShareBtn } from 'components/ShareBtn';
import { PsTheme } from '../../theme';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root' | 'tags';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {},
    tags: {
      '& > div': {
        marginTop: 4,
        marginBottom: 4
      }
    }
  };
});

export type CardTagSmallProps = {
  tag: Tag;
  hideShare?: boolean;
  hrefTarget?: string;
};

const CardTagSmallView = ({
  tag,
  hrefTarget,
  hideShare = false
}: CardTagSmallProps) => {
  const { classes } = useStyles();
  const { id, key, name, body, owner, ownerInfo, createdAt, files } = tag;
  const image = files && files.length ? files[0] : undefined;
  const url = `/tags/${key || id}`;

  return (
    <Card>
      <CardImage
        image={image}
        href={url}
        hrefTarget={hrefTarget}
        type={placeholderType.T}
      />
      <CardBody>
        <CardTitle title={name} href={url} hrefTarget={hrefTarget} />
        <CardOwner
          owner={owner}
          ownerKey={ownerInfo && ownerInfo.key}
          ownerName={ownerInfo && ownerInfo.username}
          createdAt={createdAt}
        />
        <CardText>{body}</CardText>
        <CardRow>
          <CardCell>
            <CardActions>
              {hideShare ? null : <ShareBtn tag={tag} />}
            </CardActions>
          </CardCell>
        </CardRow>
      </CardBody>
    </Card>
  );
};

export const CardTagSmall = styled(CardTagSmallView)({});

export default CardTagSmall;
