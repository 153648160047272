import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { ModalComponentsKeys } from 'contexts/ModalContext';
import ProblemModal from 'modals/AddProblem';
import ProductModal from 'modals/AddProduct';
import SolutionModal from 'modals/AddSolution';
import Actions from 'redux-state/actions';
import { GetUser } from 'redux-state/selectors';
import { colorPalette } from 'theme';
import { makeStyles } from 'tss-react/mui';

interface DataNotFoundProps {
  iconUrl: string;
  isInventionView?: boolean;
  modal?: string;
  onClick?: () => void;
  text: string;
  useDefaultOnClick?: boolean;
}

const useStyles = makeStyles()(() => {
  return {
    container: {
      height: '50vh'
    },
    subContainer: {
      textAlign: 'center'
    },
    clickableText: {
      color: colorPalette.darkPurple
    }
  };
});

const DataNotFound: React.FC<DataNotFoundProps> = ({
  iconUrl,
  isInventionView = false,
  modal = '',
  text
}) => {
  const { classes } = useStyles();
  const user = GetUser();
  const dispatch = useDispatch();
  const [isProbModalOpen, setisProbModalOpen] = useState<boolean>(false);
  const [isSolModalOpen, setIsSolModalOpen] = useState<boolean>(false);
  const [isProductModalOpen, setIsProductModalOpen] = useState<boolean>(false);

  const handleOpenModal = () => {
    if (user) {
      switch (modal) {
        case ModalComponentsKeys.addNewProblem:
          setisProbModalOpen(true);
          break;
        case ModalComponentsKeys.addNewSolution:
          setIsSolModalOpen(true);
          break;
        case ModalComponentsKeys.addNewProduct:
          setIsProductModalOpen(true);
          break;
        default:
          break;
      }
    } else {
      dispatch(Actions.openAuthModal(true));
    }
  };

  const handleCloseModal = () => {
    setisProbModalOpen(false);
    setIsSolModalOpen(false);
    setIsProductModalOpen(false);
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.container}
    >
      <Grid item>
        <div className={classes.subContainer}>
          <img src={iconUrl} alt="iconImage" />
          <Typography>
            You havent created any{' '}
            {text === 'userAgreements' ? 'user agreement' : text}s yet
          </Typography>
          {!isInventionView && (
            <>
              <Typography fontWeight="bold">
                <span
                  className={classes.clickableText}
                  onClick={handleOpenModal}
                >
                  Create a new{' '}
                  {text === 'userAgreements' ? 'user agreement' : text}
                </span>{' '}
                or go on <span className={classes.clickableText}>IdeaMap</span>{' '}
                to explore and improve{' '}
              </Typography>
              <Typography>others and earn IdeaPoints!</Typography>
            </>
          )}
        </div>
      </Grid>

      {isProbModalOpen && (
        <ProblemModal open={isProbModalOpen} onClose={handleCloseModal} />
      )}
      {isSolModalOpen && (
        <SolutionModal open={isSolModalOpen} onClose={handleCloseModal} />
      )}
      {isProductModalOpen && (
        <ProductModal open={isProductModalOpen} onClose={handleCloseModal} />
      )}
    </Grid>
  );
};

export default DataNotFound;
