import React, { useContext, useState, useEffect } from 'react';
import { styled, useTheme, alpha } from '@mui/material/styles';

import { ModalDataContext } from 'contexts/ModalDataContext';
import { DataContext, PayOption } from 'contexts/DataContext';
import { Radio } from 'components/Radio/Radio';
import { PsLogo } from 'components/common/PsLogo';
import { PsNFT } from 'components/common/PsNFT';
import { finalizeType } from '../../../helpers';
import styles from 'modals/ModalWindow.module.scss';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const RadioText = ({ option }: { option: PayOption }) => {
  return (
    <table className={styles.payOptionsTable}>
      <tbody>
        <tr className={styles.payOptionsRow}>
          <td className={styles.payOptionsCellLogo}>
            <PsLogo size={50} color="black" small />
            <PsNFT size={50} color="black" />
          </td>
          <td className={styles.payOptionsCell}>
            <div className={styles.payOptionsCellTitle}>{option.title}</div>
            <div>
              {option.subTitle}
              <br />
              <b>{option.priceInfo}</b>
            </div>
            <div className={styles.payOptionsCellCredits}>
              1 MindMiner Credit Deducted
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  return {
    root: {
      borderRadius: 8,
      margin: '8px 0',
      border: `solid 1px ${alpha(theme.palette.primary.main, 0.15)}`,
      background: 'rgb(248, 245, 255, 0.5)',
      color: theme.palette.primary.main,
      '&:hover': {
        border: `solid 1px ${alpha(theme.palette.primary.main, 0.5)}`
      }
    }
  };
});

export const ChooseTypeStep: React.FC = () => {
  const { values, updateField } = useContext(ModalDataContext);
  const { dataProvider } = useContext(DataContext);
  const { classes } = useStyles();
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState<Array<PayOption>>(
    values.payOptions || []
  );
  const [selectedEntity, setSelectedEntity] = useState<string | undefined>(
    `${values.productId}`
  );

  const handleEntityChange = (e: React.ChangeEvent, value: string) => {
    setSelectedEntity(value);
    const selected = options.find((item) => item.id === value);
    updateField('productId', value);
    if (selected) {
      updateField('finalizeType', selected.type);
    }
  };

  useEffect(() => {
    setLoading(true);
    if (values.payOptions) {
      setOptions(values.payOptions);
      setLoading(false);
      return;
    }
    dataProvider
      .getPayOptions()
      .then((list) => {
        let products = list;
        if (values.parentProblemOnly) {
          products = list.filter((item) => item.type !== finalizeType.SHARE);
        }
        if (values.application && values.application.parentApplication) {
          products = list.filter((item) => item.type === finalizeType.OWN);
        }
        const sortedList = products.sort((a, b) =>
          a.sortIndex > b.sortIndex ? 1 : -1
        );
        updateField('payOptions', sortedList);
        setOptions(sortedList);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className={styles.stepWrapper}>
      {loading ? <Typography>Loading...</Typography> : null}
      <RadioGroup
        onChange={handleEntityChange}
        value={selectedEntity}
        aria-label="entity"
        name="entity"
      >
        {options.map((option) => {
          return (
            <FormControlLabel
              className={styles.payOption}
              key={option.id}
              classes={classes}
              value={option.id}
              control={<Radio />}
              label={<RadioText option={option} />}
            />
          );
        })}
      </RadioGroup>
    </div>
  );
};

export default ChooseTypeStep;
