import React from 'react';
import { styled, useTheme, alpha } from '@mui/material/styles';

import PsInputLabel from 'components/common/PsInputLabel';
import { PsTheme } from '../../theme';
import FormHelperText from '@mui/material/FormHelperText';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'wrapper' | 'isPlaceholder' | 'helperText';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    wrapper: {
      marginBottom: 10,
      marginTop: 30,
      width: '100%',
      [psTheme.breakpoints.down('xs')]: {
        marginBottom: 20
      }
    },
    isPlaceholder: {
      background: 'rgb(248, 245, 255, 0.5)',
      '& > fieldset': {
        borderColor: alpha(psTheme.palette.third.main, 0.5),
        borderWidth: 1
      }
    },
    helperText: {
      margin: '3px 14px 0',
      lineHeight: 1.33,
      color: alpha(psTheme.palette.third.main, 0.5),
      '& .ai-generated': {
        color: psTheme.palette.primary.main
      },
      '& .ai-generated b': {
        color: 'black',
        fontWeight: 'normal'
      }
    }
  };
});

export type ShortEvent = {
  target: {
    value: any;
    name: string | undefined;
    label?: any;
    rawValue?: any;
  };
};

export type PsFormControlProps = {
  className?: string;
  children: React.ReactNode;
  id?: string;
  name?: string;
  placeholder?: string;
  label?: React.ReactNode;
  labelInfo?: string | boolean;
  labelLight?: boolean;
  helperText?: React.ReactNode;
  disabled?: boolean;
  error?: boolean;
  onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onLabelInfoClick?: () => void;
  required?: boolean;
};

const PsFormControlView = (props: PsFormControlProps) => {
  const {
    className = '',
    name,
    label,
    labelLight,
    labelInfo,
    onLabelInfoClick,
    helperText,
    error,
    required,
    children
  } = props;
  const { classes } = useStyles();

  return (
    <div className={`${classes.wrapper} ${className}`}>
      {label ? (
        <PsInputLabel
          // id={id}
          name={name}
          label={label}
          labelInfo={labelInfo}
          labelLight={labelLight}
          onLabelInfoClick={onLabelInfoClick}
          required={required}
        />
      ) : null}
      {children}
      {helperText ? (
        <FormHelperText className={classes.helperText} error={error}>
          {helperText}
        </FormHelperText>
      ) : null}
    </div>
  );
};

export const PsFormControl = styled(PsFormControlView)({});

export default PsFormControl;
