import { useSelector } from 'react-redux';
import { RootState } from '..';

export const GetProblems = () =>
  useSelector((state: RootState) => state.contestView.problems);

export const GetContests = () =>
  useSelector((state: RootState) => state.contestView.contests);

export const GetContest = () =>
  useSelector((state: RootState) => state.contestView.contest);

export const GetSolutionsWithOwnerInfo = () =>
  useSelector((state: RootState) => state.contestView.solutions);

export const GetLoaderLeaderboard = () =>
  useSelector((state: RootState) => state.contestView.leaderboardLoader);

export const GetGeneratedDescription = () =>
  useSelector((state: RootState) => state.contestView.generatedDescription);

export const GetGeneratedDescriptionLoader = () =>
  useSelector(
    (state: RootState) => state.contestView.generatedDescriptionLoading
  );

export const GetGeneratedImage = () =>
  useSelector((state: RootState) => state.contestView.generatedImage);

export const GetGenerateImageLoader = () =>
  useSelector((state: RootState) => state.contestView.generatedImageLoading);

export const GetCoverImage = () =>
  useSelector((state: RootState) => state.contestView.generatedCoverImage);

export const GetCoverImageLoader = () =>
  useSelector(
    (state: RootState) => state.contestView.generatedCoverImageLoading
  );
