import React from 'react';
import { Avatar, Box, styled } from '@mui/material';
import { LinkView } from '../LinkView';
import { useIsMediumScreen } from '../../theme';

interface Props {
  iconClassName?: string;
  iconImage: string;
  id?: string;
  imgClassName?: string;
  isTagView?: boolean;
  mainItemClassName?: string;
  placeholderImage?: string;
  tagImage?: string;
  textWidth?: number;
  title: string;
  titleClassName?: string;
  type?: string;
  useSameTab?: boolean;
}

const ImageView: React.FC<Props> = ({
  iconClassName = '',
  iconImage,
  id,
  imgClassName = '',
  isTagView,
  mainItemClassName = '',
  placeholderImage,
  tagImage,
  textWidth,
  title,
  titleClassName = '',
  type,
  useSameTab = false
}) => {
  const isMediumScreen = useIsMediumScreen();
  if (isTagView) {
    return (
      <StyledMainItem className={mainItemClassName}>
        {tagImage && (
          <StyledTagImage
            alt="tag_image"
            className={iconClassName}
            isMediumScreen={isMediumScreen}
            src={tagImage}
          />
        )}
        <LinkView
          height={'1.25rem'}
          id={id}
          textWidth={textWidth}
          title={title}
          type={type}
          useSameTab={useSameTab}
        />
      </StyledMainItem>
    );
  } else {
    return (
      <StyledMainItem className={mainItemClassName}>
        <StyledMainItem>
          <StyledPlaceholderImage
            isMediumScreen={isMediumScreen}
            className={imgClassName}
            alt="placeholder_image"
            src={placeholderImage}
          />
          {iconImage && (
            <StyledIconImage
              isMediumScreen={isMediumScreen}
              src={iconImage}
              alt="problem_icon"
              className={iconClassName}
            />
          )}
        </StyledMainItem>
        <LinkView
          textWidth={textWidth}
          id={id}
          title={title}
          type={type}
          useSameTab={useSameTab}
        />
      </StyledMainItem>
    );
  }
};

const StyledMainItem = styled(Box)({
  alignItems: 'flex-start',
  display: 'flex',
  flexWrap: 'nowrap',
  wordWrap: 'break-word'
});

const StyledPlaceholderImage = styled(Avatar)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    height: isMediumScreen ? '30px' : '45px',
    width: isMediumScreen ? '30px' : '45px'
  })
);

const StyledIconImage = styled(Avatar)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    height: isMediumScreen ? '15px' : '20px',
    left: isMediumScreen ? '-14px' : '-15px',
    position: 'relative',
    top: isMediumScreen ? -5 : 0,
    width: isMediumScreen ? '15px' : '20px',
    zIndex: 2
  })
);

const StyledTagImage = styled(Avatar)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    height: isMediumScreen ? '25px' : '35px',
    width: isMediumScreen ? '25px' : '35px',
    position: 'relative',
    top: isMediumScreen ? -3 : 0,
    zIndex: 1,
    marginRight: '16px'
  })
);

export { ImageView };
