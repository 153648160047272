import dataProvider, { PsRecord, UpdateParams } from 'dataPrvider';
import { Constants, ERRORS, PATH_NAMES } from 'utilities/constants';
import walletProvider from 'walletProvider';
import { Notification, RewardHistory } from './interface';

export const Api = {
  editProfileItem: async (payload) => {
    try {
      const { id, type, data } = payload;
      const response = await dataProvider.update(type, { id, data });
      if (response) {
        return true;
      }
    } catch (e) {
      console.log('Error in Editing Profile Item', e);
      return false;
    }
  },
  editProfile: async (payload) => {
    try {
      const { id, data } = payload;
      const response = await dataProvider.update(Constants.PROFILES, {
        id,
        data
      });
      return response?.data;
    } catch (e) {
      console.log('Error in Editing Profile Item', e);
      return false;
    }
  },
  eFilePatent: async (payload) => {
    try {
      const { id, number, date } = payload;
      const data = {
        number,
        date
      };
      const response = await dataProvider.fileApplication(id, data);
      if (response) {
        return true;
      }
    } catch (e) {
      console.log('Error in E-file Patent', e);
      return false;
    }
  },
  nftDeployStart: async (id: string | number) => {
    try {
      const response = await dataProvider.nftDeployStart(id);
      return response;
    } catch (e) {
      return [];
    }
  },
  deployWithMetamask: async (
    contractAddress: string,
    tokenURI: string,
    walletAddress: string
  ) => {
    try {
      const response = await walletProvider.deployWithMetamask(
        contractAddress,
        tokenURI,
        walletAddress
      );
      return response;
    } catch (error) {
      console.error(ERRORS.DEPLOY_WITH_METAMASK, error);
      throw error;
    }
  },
  nftDeployFinish: async (id, payload) => {
    try {
      const response = await dataProvider.nftDeployFinish(id, payload);
      return response;
    } catch (error) {
      console.error(ERRORS.NFT_DEPLOY_FINISH, error);
      throw error;
    }
  },
  getUserRanking: async (userKey: string) => {
    try {
      const response = await dataProvider.getUserRanking(
        PATH_NAMES.USER_RANKING,
        {
          filter: {
            $custom: {
              category: Constants.PROFILE,
              userKey: userKey
            }
          },
          sort: { field: Constants.IDEA_POINTS, order: Constants.DESC }
        }
      );
      return response?.rank;
    } catch (error) {
      console.error(ERRORS.USER_RANKING, error);
      throw error;
    }
  },
  getUserIdeaPoints: async (userId: string | number) => {
    try {
      const response = await dataProvider.getUserIdeaPoints(
        PATH_NAMES.USER_IDEAPOINTS,
        {
          filter: {
            $custom: {
              category: Constants.PROFILE,
              userId: userId
            }
          }
        }
      );
      return response?.ideaPoints;
    } catch (error) {
      console.error(ERRORS.USER_IDEAPOINTS, error);
      throw error;
    }
  },
  getProfileMutualTags: async (
    userRank: boolean,
    key: string,
    pagination: { page: number; perPage: number },
    filter: string
  ) => {
    try {
      const response = await dataProvider.getMutualTags(
        userRank,
        pagination,
        key,
        filter
      );
      return response;
    } catch (error) {
      console.error(ERRORS.USER_RANKING, error);
      throw error;
    }
  },
  getProfileItems: async (
    itemType: string,
    filterType: string,
    field: string,
    profileId?: string | number,
    pagination?: {
      page: number;
      perPage: number;
    },
    getPinItems?: boolean,
    isArchived?: boolean,
    isFiled?: boolean
  ) => {
    try {
      const data = await dataProvider.getList<PsRecord>(itemType, {
        pagination: pagination,
        sort: { field: field, order: 'DESC' },
        filter: {
          owner: profileId,
          $custom: {
            type: filterType,
            category: 'profile',
            getPinItems: getPinItems,
            isArchived: isArchived,
            isFiled: isFiled
          }
        }
      });
      return {
        items: data.data,
        pinnedItems: data.pinnedItems,
        count: data.total,
        updatePinItems: data.updatePinItems
      };
    } catch (e) {
      return [];
    }
  },
  getProfileItemsCount: async (
    itemType: string,
    profileId?: string | number,
    isFiled?: boolean
  ) => {
    try {
      const data = await dataProvider.getProfileItemsCount(itemType, {
        pagination: null,
        sort: null,
        filter: {
          owner: profileId,
          $custom: {
            isFiled: isFiled
          }
        }
      });
      return data;
    } catch (e) {
      return [];
    }
  },
  updateCtaModal: async (payload) => {
    try {
      return await dataProvider.updateCtaModal(payload.payload);
    } catch (error) {
      console.log('error', error);
    }
  },
  getNotifications: async (payload: {
    pagination?: { page: number; perPage: number };
    userId?: string | number | null;
    itemType?: { $ne: string | null };
    self?: { $ne: boolean };
  }) => {
    try {
      const {
        pagination = { page: 0, perPage: 5 },
        userId,
        itemType,
        self
      } = payload;
      const data = await dataProvider.getList<Notification>(
        Constants.NOTIFICATION,
        {
          pagination,
          sort: null,
          filter: {
            $custom: {
              userId: userId,
              category: Constants.NOTIFICATION,
              itemType: itemType,
              self: self
            }
          }
        }
      );
      return data;
    } catch (e) {
      return [];
    }
  },
  getUnreadNotificationCount: async (payload: {
    userId: string;
    category: string;
    itemType: { $ne: string };
    read?: { $eq: boolean };
    self?: { $ne: boolean };
  }) => {
    try {
      const { userId, category, itemType, read, self } = payload;
      const data = await dataProvider.getUnreadNotificationCount({
        filter: {
          $custom: {
            userId,
            category,
            itemType,
            read,
            self
          }
        }
      });
      return data;
    } catch (e) {
      return [];
    }
  },
  toggleReadStatus: async (payload: {
    id: string | number | null;
    status: boolean;
  }) => {
    const { id, status } = payload;
    try {
      return await dataProvider.toggleNotificationReadStatus(id, status);
    } catch (e) {
      console.log('Error:', e);
    }
  },
  markAllAsRead: async (payload) => {
    try {
      return await dataProvider.markAllNotificationsAsRead(payload);
    } catch (e) {
      console.log('Error:', e);
    }
  },
  markMultipleAsRead: async (payload) => {
    try {
      return await dataProvider.markMultipleNotificationsAsRead(payload);
    } catch (e) {
      console.log('Error:', e);
    }
  },
  getRewardHistory: async (payload: {
    pagination?: { page: number; perPage: number };
    userId?: string | number | null;
    itemType?: { $ne: string | null };
    ideaPoints?: { $ne: number | null };
  }) => {
    try {
      const {
        pagination = { page: 0, perPage: 5 },
        userId,
        itemType,
        ideaPoints
      } = payload;
      const data = await dataProvider.getList<RewardHistory>(
        Constants.REWARD_HISTORY,
        {
          pagination,
          sort: null,
          filter: {
            $custom: {
              userId: userId,
              category: Constants.REWARD,
              itemType: itemType,
              ideaPoints: ideaPoints
            }
          }
        }
      );
      return data;
    } catch (e) {
      return [];
    }
  },
  setPinItem: async <T>(
    id: string,
    type: string,
    isPinned: boolean,
    isFiled: boolean
  ) => {
    try {
      await dataProvider.pin<T>(type, id, isPinned, isFiled);
      return true;
    } catch (e) {
      return false;
    }
  },
  deleteProfileItem: async (itemType: string, itemId: string) => {
    try {
      await dataProvider.delete(
        itemType,
        {
          id: itemId
        },
        true
      );
      return true;
    } catch (error) {
      throw new Error(error);
    }
  },
  setArchiveItem: async <T>(id: string, type: string, isArchived: boolean) => {
    const updateParams: UpdateParams = {
      id: id,
      data: {
        isArchived: isArchived
      }
    };
    try {
      await dataProvider.update<T>(type, updateParams);
      return true;
    } catch (e) {
      return false;
    }
  },
  getInventionStakerByApplicationId: async <T>(id) => {
    try {
      const result = await dataProvider.getInventionStakerByApplicationId({
        applicationId: id
      });
      return result;
    } catch (error) {
      throw new Error(error);
    }
  }
};
