import React from 'react';
export const CoinsCounter = ({
  coins,
  width = '50',
  height = '50'
}: {
  coins: number | string;
  width?: string;
  height?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 50 50"
  >
    <defs>
      <clipPath id="Counter">
        <path
          data-name="Rectangle 901"
          fill="currentColor"
          d="M0 0h40.21v40.196H0z"
        />
      </clipPath>
    </defs>
    <g data-name="Ellipse 53" fill="#fff" stroke="#fff">
      <circle cx="25" cy="25" r="25" stroke="none" />
      <circle cx="25" cy="25" r="24.5" fill="none" />
    </g>
    <g data-name="Group 374">
      <text
        data-name="1"
        transform="translate(25 30)"
        fill="#632ddd"
        fontSize="13"
        fontFamily="Montserrat"
        fontWeight="700"
        textAnchor="middle"
      >
        <tspan x="0" y="0">
          {coins}
        </tspan>
      </text>
      <g data-name="Group 314">
        <g
          data-name="Group 310"
          transform="translate(4.688 4.694)"
          clipPath="url(#Counter)"
          fill="#632ddd"
        >
          <path
            data-name="Path 469"
            d="M15.4 9.721a2.936 2.936 0 0 0-2.6-1.58H6.836a5.426 5.426 0 0 1-2.429-.59c-.152.187-.291.386-.433.58s-.311.393-.454.6a20.181 20.181 0 0 0-2.049 3.761c-.1.245-.187.5-.279.748s-.177.54-.258.794h9.531A11.421 11.421 0 0 1 15.4 9.721"
          />
          <path
            data-name="Path 470"
            d="M10.576 26.339H1.007c.082.255.181.5.273.75s.174.505.274.75a20.039 20.039 0 0 0 2.057 3.714c.146.207.3.4.453.6s.294.41.452.612a5.458 5.458 0 0 1 2.325-.532l5.96-.006a2.941 2.941 0 0 0 2.675-1.728 11.435 11.435 0 0 1-4.9-4.165"
          />
          <path
            data-name="Path 471"
            d="M29.635 13.848h9.574c-.082-.255-.181-.5-.274-.751s-.173-.5-.274-.748a20.05 20.05 0 0 0-2.051-3.723c-.144-.206-.3-.4-.452-.6s-.294-.41-.453-.6a5.445 5.445 0 0 1-2.325.537h-5.972a2.941 2.941 0 0 0-2.675 1.728 11.431 11.431 0 0 1 4.9 4.165"
          />
          <path
            data-name="Path 472"
            d="M24.76 30.49a2.942 2.942 0 0 0 2.648 1.679h5.975a5.449 5.449 0 0 1 2.361.553c.159-.195.3-.4.453-.6s.308-.4.453-.612a20.079 20.079 0 0 0 2.038-3.734c.1-.245.184-.5.274-.751s.192-.49.274-.75h-9.561a11.432 11.432 0 0 1-4.915 4.22"
          />
          <path
            data-name="Path 473"
            d="M40.031 17.554c-.032-.252-.073-.5-.114-.75s-.065-.5-.114-.75h-9.05a11.343 11.343 0 0 1 .75 4.039 11.354 11.354 0 0 1-.731 3.984h9.041c.049-.245.073-.5.114-.75s.084-.5.115-.754a20.191 20.191 0 0 0 .168-2.477 20.091 20.091 0 0 0-.179-2.543"
          />
          <path
            data-name="Path 474"
            d="M9.391 16.238H.378c-.048.245-.075.5-.114.748s-.086.5-.115.756A20.105 20.105 0 0 0 0 20.092a20.069 20.069 0 0 0 .207 2.722l.088.573c.042.245.065.5.115.75h9.049a11.239 11.239 0 0 1-.069-7.9"
          />
          <path
            data-name="Path 475"
            d="M22.681 9a5.114 5.114 0 0 1 4.731-3.178h5.969a3.276 3.276 0 0 0 .77-.1c-.233-.226-.479-.439-.721-.656a18.92 18.92 0 0 0-.884-.745 20.1 20.1 0 0 0-25.13.179c-.281.229-.546.48-.816.726-.229.208-.47.408-.69.627A3.292 3.292 0 0 0 6.834 6h5.968a5.11 5.11 0 0 1 4.656 3.013A11.072 11.072 0 0 1 22.681 9"
          />
          <path
            data-name="Path 476"
            d="M17.537 31.189a5.113 5.113 0 0 1-4.733 3.181h-5.97a3.4 3.4 0 0 0-.762.094c.234.228.48.443.725.658.29.257.58.507.885.747a20.011 20.011 0 0 0 7.568 3.707 19.759 19.759 0 0 0 2.325.441 20 20 0 0 0 2.522.179h.014a19.919 19.919 0 0 0 2.533-.177 20.24 20.24 0 0 0 9.983-4.215c.295-.237.579-.49.857-.734.239-.214.483-.421.711-.646a3.332 3.332 0 0 0-.822-.114h-5.962a5.118 5.118 0 0 1-4.716-3.127 11.1 11.1 0 0 1-5.156 0"
          />
        </g>
      </g>
    </g>
  </svg>
);
