/* eslint-disable import/named */
import {
  InputProps,
  StyledBodyTypography,
  StyledTextField
} from '../../styledComponents';
import { TextFieldProps } from '@mui/material/TextField';

type TextFieldWithHeadingProps = TextFieldProps & {
  heading: string;
};

export const TextFieldWithHeading = ({
  heading,
  value,
  onChange,
  ...props
}: TextFieldWithHeadingProps) => {
  return (
    <>
      <StyledBodyTypography>{heading}</StyledBodyTypography>
      <StyledTextField
        value={value}
        onChange={onChange}
        InputProps={InputProps}
        {...props}
      />
    </>
  );
};
