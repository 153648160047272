import { Node } from 'interface/common';
import { Application } from 'components/CardApplication';
import { Concept } from 'src/components/CardProduct';
import {
  SET_CONCEPT_DATA,
  SET_GRAPH_DATA,
  TOGGLE_CONCEPT_CART,
  TOGGLE_LOADER_AI_GENERATION,
  TOGGLE_LOADER_CONTESTS,
  TOGGLE_LOADER_IMAGE_GENERATION,
  TOGGLE_LOADER_IMAGE_UPLOAD,
  TOGGLE_LOADER_NEW_CONCEPT,
  TOGGLE_LOADER_SOLUTION,
  TOGGLE_SIDE_DRAWER,
  CIRCULAR_PROGRESS_VALUE_FOR_PRODUCT,
  CIRCULAR_PROGRESS_VALUE,
  SET_SELECTED_ITEM,
  UPDATE_CONCEPT,
  UPDATE_CONCEPT_SUCCESS,
  GET_CONCEPT,
  GET_CONCEPTS_SUCCESS
} from './types';

const INITIAL_STATE = {
  aiGenerationLoader: false,
  concept: {},
  contestsLoader: false,
  graph: undefined,
  getConceptsLoader: false,
  imageGenerationLoader: false,
  imageUploadLoader: false,
  isConceptCartOpen: false,
  isSideDrawerOpen: false,
  newConceptLoader: false,
  solutionLoader: false,
  circularProgressValueForProduct: {},
  circularProgressValue: 0,
  selectedItem: null,
  updateConceptLoader: false
};

type Action =
  | { type: typeof SET_CONCEPT_DATA; payload: { data: Concept } }
  | { type: typeof SET_GRAPH_DATA; payload: { data: Node[] } }
  | { type: typeof TOGGLE_CONCEPT_CART; payload: boolean }
  | { type: typeof TOGGLE_LOADER_AI_GENERATION; payload: boolean }
  | { type: typeof TOGGLE_LOADER_CONTESTS; payload: boolean }
  | { type: typeof TOGGLE_LOADER_IMAGE_GENERATION; payload: boolean }
  | { type: typeof TOGGLE_LOADER_IMAGE_UPLOAD; payload: boolean }
  | { type: typeof TOGGLE_LOADER_NEW_CONCEPT; payload: boolean }
  | { type: typeof TOGGLE_LOADER_SOLUTION; payload: boolean }
  | { type: typeof TOGGLE_SIDE_DRAWER; payload: boolean }
  | {
      type: typeof CIRCULAR_PROGRESS_VALUE_FOR_PRODUCT;
      payload: { productId: string; value: number };
    }
  | { type: typeof CIRCULAR_PROGRESS_VALUE; payload: number }
  | { type: typeof SET_SELECTED_ITEM; payload: Node }
  | {
      type: typeof UPDATE_CONCEPT;
      payload: { id: string; data: any; loading?: boolean };
    }
  | { type: typeof UPDATE_CONCEPT_SUCCESS; payload: {} }
  | { type: typeof GET_CONCEPT; payload: { userId: string | number } }
  | { type: typeof GET_CONCEPTS_SUCCESS; payload: {} };

const reducer = (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case GET_CONCEPT:
      return { ...state, getConceptsLoader: true };
    case GET_CONCEPTS_SUCCESS:
      return { ...state, getConceptsLoader: false };
    case SET_CONCEPT_DATA:
      return { ...state, concept: action.payload.data, solutionLoader: false };
    case SET_GRAPH_DATA:
      return { ...state, graph: action.payload.data };
    case TOGGLE_CONCEPT_CART:
      return { ...state, isConceptCartOpen: action.payload };
    case TOGGLE_LOADER_AI_GENERATION:
      return { ...state, aiGenerationLoader: action.payload };
    case TOGGLE_LOADER_CONTESTS:
      return { ...state, contestsLoader: action.payload };
    case TOGGLE_LOADER_IMAGE_GENERATION:
      return { ...state, imageGenerationLoader: action.payload };
    case TOGGLE_LOADER_IMAGE_UPLOAD:
      return { ...state, imageUploadLoader: action.payload };
    case TOGGLE_LOADER_NEW_CONCEPT:
      return { ...state, newConceptLoader: action.payload };
    case TOGGLE_LOADER_SOLUTION:
      return { ...state, solutionLoader: action.payload };
    case TOGGLE_SIDE_DRAWER:
      return { ...state, isSideDrawerOpen: action.payload };
    case CIRCULAR_PROGRESS_VALUE_FOR_PRODUCT:
      return {
        ...state,
        [action.payload.productId]: action.payload.value
      };
    case CIRCULAR_PROGRESS_VALUE:
      return { ...state, circularProgressValue: action.payload };
    case SET_SELECTED_ITEM:
      return { ...state, selectedItem: action.payload };
    case UPDATE_CONCEPT:
      return { ...state, updateConceptLoader: action.payload.loading };
    case UPDATE_CONCEPT_SUCCESS:
      return { ...state, updateConceptLoader: false };
    default:
      return state;
  }
};

export default reducer;
