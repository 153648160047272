import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

interface LogoProps extends SvgIconProps {
  width?: number | string;
  height?: number | string;
}

export const TiktokIcon: React.FC<LogoProps> = ({
  width = '1.9rem',
  height = '1.9rem',
  ...props
}) => {
  return (
    <SvgIcon
      sx={{ width: width, height: height }}
      style={{ color: '#000' }}
      viewBox="0 0 1000 1000"
    >
      <path
        d="M500,70C262.5,70,70,262.5,70,500c0,237.5,192.5,430,430,430c237.5,0,430-192.5,430-430C930,262.5,737.5,70,500,70z
	 M726.7,441c-44.2,2-83.3-12.6-121.6-37.1c-0.6,6.1-1.3,10.6-1.3,15.1c-0.1,54.5,0.2,108.9-0.1,163.4
	c-0.5,91.9-66.3,159-161.7,165.2c-76.3,5-152.7-61.4-164.7-143.1c-14.3-97.6,68.1-188.8,166.5-184c5.3,0.3,10.6,1.3,16.8,2.1
	c0,28.9,0,56.9,0,86.8c-4.7,0-9.3,0.2-13.9,0c-44.5-2.4-80,28.4-81.6,70.9c-1.5,40.5,30.3,75.5,70,77c44.9,1.7,79.5-29.8,79.7-74.6
	c0.6-108.9,0.2-217.8,0.2-326.8c0-7.8,0-15.6,0-24.4c31.1,0,59.1,0,87.2,0c8.5,74.5,51.5,113.8,124.3,124
	C726.7,383.6,726.7,411.3,726.7,441z"
      />
    </SvgIcon>
  );
};
