import React from 'react';
export const HomeIcon = ({
  size = 24,
  color = '#5C30D5',
  className
}: {
  size?: number;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.3529 10.4388C23.3524 10.4383 23.3518 10.4377 23.3513 10.4372L13.5612 0.647461C13.1439 0.22998 12.5891 0 11.9989 0C11.4088 0 10.854 0.229797 10.4365 0.647278L0.651514 10.4321C0.648218 10.4354 0.644922 10.4388 0.641626 10.4421C-0.215307 11.304 -0.213842 12.7024 0.645837 13.5621C1.0386 13.955 1.55734 14.1826 2.11196 14.2064C2.13448 14.2086 2.15719 14.2097 2.18008 14.2097H2.57028V21.4144C2.57028 22.84 3.73025 24 5.15627 24H8.98647C9.37466 24 9.6896 23.6852 9.6896 23.2969V17.6484C9.6896 16.9979 10.2188 16.4687 10.8693 16.4687H13.1285C13.7791 16.4687 14.3082 16.9979 14.3082 17.6484V23.2969C14.3082 23.6852 14.623 24 15.0114 24H18.8416C20.2676 24 21.4276 22.84 21.4276 21.4144V14.2097H21.7894C22.3794 14.2097 22.9342 13.9799 23.3518 13.5624C24.2124 12.7013 24.2128 11.3005 23.3529 10.4388V10.4388Z"
        fill={color}
      />
    </svg>
  );
};

export default HomeIcon;
