import ConfirmInformationStep from './steps/ConfirmInformationStep';
import InviteUsersStep from './steps/InviteUsersStep';

import {
  ValidateFormType,
  FormStepConfig,
  CompanySubscriptionFinalizeSteps
} from 'contexts/ModalDataContext';

export const SUBSCRIPTION_STEPS_CONFIG: {
  [name: string]: FormStepConfig;
} = {
  [CompanySubscriptionFinalizeSteps.ConfirmInformationStep]: {
    Component: ConfirmInformationStep,
    nextStep: CompanySubscriptionFinalizeSteps.InviteUsersStep,
    isNextStepBlocked: (data) => {
      return !data.termsAgree;
    },
    validate: (data): ValidateFormType => {
      if (data.termsAgree) {
        return { success: true };
      }
      return { success: false, errors: { title: 'required' } };
    }
  },
  [CompanySubscriptionFinalizeSteps.InviteUsersStep]: {
    isFinalStep: true,
    Component: InviteUsersStep,
    isNextStepBlocked: (data) => {
      return !data.termsAgree;
    },
    validate: (data): ValidateFormType => {
      if (data.termsAgree) {
        return { success: true };
      }
      return { success: false, errors: { description: 'required' } };
    },
    nextButtonTitle: 'Finalize'
  }
};
