import React, { FC } from 'react';
import { Box, styled } from '@mui/material';
import { StyledVoteButton } from 'pages/home/StyledComponents';
import { PiArrowFatUp, PiArrowFatUpDuotone } from 'react-icons/pi';
import { useIsMediumScreen } from 'theme';
import { Constants } from 'utilities/constants';

interface LikeProps {
  disabledButtons?: boolean;
  handleActionButton: (action: string) => void;
  isLiked: boolean;
}

const Like: FC<LikeProps> = ({
  disabledButtons,
  handleActionButton,
  isLiked
}) => {
  const isMediumScreen = useIsMediumScreen();

  const handleClick = (event) => {
    event.stopPropagation();
    handleActionButton('like');
  };

  const LikeIcon = isLiked ? PiArrowFatUpDuotone : PiArrowFatUp;

  return (
    <StyledBox>
      <StyledVoteButton disabled={disabledButtons}>
        <LikeIcon
          color="green"
          size={isMediumScreen ? Constants.MEDIUM_20 : Constants.MEDIUM_25}
          onClick={handleClick}
        />
      </StyledVoteButton>
    </StyledBox>
  );
};

const StyledBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center'
});

export default Like;
