import React, { useContext, useState, useCallback, useEffect } from 'react';
import { Application } from 'components/CardApplication';
import PsFormControl, { ShortEvent } from 'components/common/PsFormControl';
import { PsSelect, PsOptionType } from 'components/common/PsSelect';
import {
  ModalDataContext,
  StepComponentType,
  StepComponentProps
} from 'contexts/ModalDataContext';
import { DataContext } from 'contexts/DataContext';
import { AuthContext } from 'contexts/AuthContext';
import styles from 'modals/ModalWindow.module.scss';

const ApplicationSelect: StepComponentType<StepComponentProps> = ({
  onNotify
}: StepComponentProps) => {
  const { user } = useContext(AuthContext);
  const { values, handleInputChange } = useContext(ModalDataContext);
  const { dataProvider } = useContext(DataContext);
  const [applications, setApplications] = useState<Array<PsOptionType>>([]);
  const [appValue, setAppValue] = useState<string | undefined>('');

  useEffect(() => {
    if (!user) {
      return;
    }

    const first = {
      value: '--create--',
      label: `Create New Invention`,
      raw: ''
    };

    Promise.resolve()
      .then(() => {
        if (values.applications) {
          return { data: values.applications };
        } else {
          return dataProvider.getList<Application>('applications', {
            pagination: { page: 1, perPage: 50000 },
            sort: { field: 'title', order: 'ASC' },
            filter: {
              owner: user.id
            }
          });
        }
      })
      .then(({ data }) => {
        const applications: Array<PsOptionType> = data.map(
          (app: Application) => {
            return {
              value: `${app.id}`,
              label: app.title || '(empty)'
            };
          }
        );
        setApplications([first, ...applications]);
      })
      .catch((err) => {
        setApplications([first]);
        console.error(err);
      });
  }, []);

  const onAppChange = useCallback(
    (e: ShortEvent) => {
      if (e.target.value && e.target.value.startsWith('--create--')) {
        if (onNotify) {
          onNotify('create app');
        }
        handleInputChange({
          target: { value: undefined, name: e.target.name }
        });
        setAppValue(undefined);
        //
        return;
      }
      handleInputChange(e);
      setAppValue(e.target.value);
    },
    [setAppValue, handleInputChange, onNotify]
  );

  const onAppInputChange = (text: string) => {
    const first = applications[0];
    const isFirstCreate = first && first.value.startsWith('--create--');

    if (text) {
      const first = {
        value: `--create--, ${text}`,
        label: `Create New Invention`,
        raw: text
      };
      if (isFirstCreate) {
        const [, ...rest] = applications;
        setApplications([first, ...rest]);
      } else {
        setApplications([first, ...applications]);
      }
    }
  };

  return (
    <div className={styles.stepWrapper}>
      <PsFormControl placeholder="Select invention" label="Select invention:">
        <PsSelect
          maxMenuHeight={270}
          name="selectApplication"
          onChange={onAppChange}
          onInputChange={onAppInputChange}
          options={applications}
          value={appValue}
        />
      </PsFormControl>
    </div>
  );
};

export default ApplicationSelect;
