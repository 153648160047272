import React, { ReactNode } from 'react';
import { styled, useTheme, darken, alpha } from '@mui/material/styles';

import { PsTheme } from '../../theme';
import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { makeStyles } from 'tss-react/mui';
import { ShortEvent } from './PsFormControl';
import { RestartAlt } from '@mui/icons-material';

type ClassKey = 'root' | 'input' | 'notchedOutline';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      // resize: 'vertical',
      // overflow: 'hidden',
      padding: 0,
      fontSize: 14,
      '&.Mui-disabled': {
        background: '#f7f7f7'
      },
      '&.Mui-focused > .MuiOutlinedInput-notchedOutline': {
        borderWidth: 1
      },
      '&.Mui-disabled > .MuiOutlinedInput-notchedOutline': {
        borderColor: alpha(psTheme.palette.third.main, 0.2)
      },
      '&:not(.Mui-disabled):hover > .MuiOutlinedInput-notchedOutline': {
        borderColor: alpha(psTheme.palette.primary.main, 1)
      }
    },
    input: {
      padding: '15px 20px',
      height: '80%',
      // color: psTheme.palette.third.main,
      lineHeight: 1.6
    },
    notchedOutline: {
      borderColor: alpha(psTheme.palette.primary.main, 1),
      borderWidth: 1,
      borderRadius: 8
    },
    error: {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'red !important'
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'darkred'
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'darkred'
      }
    }
  };
});

export type PsInputProps = {
  className?: string;
  endAdornment?: React.ReactNode;
  fullWidth?: boolean;
  multiline?: boolean;
  name?: string;
  error?: boolean;
  onChange: (e: ShortEvent) => void;
  placeHolder?: string;
  rows?: number;
  style?: React.CSSProperties;
  type?: string;
  value?: string;
};

export const PsInputView = ({
  className,
  endAdornment,
  fullWidth,
  multiline,
  name,
  onChange,
  placeHolder,
  rows,
  style,
  type,
  value,
  error = false,
  ...rest
}: PsInputProps) => {
  const { classes } = useStyles();

  return (
    <OutlinedInput
      type={type}
      style={style}
      value={value}
      multiline={multiline}
      rows={rows}
      fullWidth={true}
      onChange={onChange}
      classes={classes}
      className={className}
      autoComplete="off"
      endAdornment={endAdornment}
      name={name}
      error={error}
      placeholder={placeHolder}
      {...rest}
    />
  );
};

export const PsInput = styled(PsInputView)({});

export default PsInput;
