import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { DiscordIcon } from 'components/icons/DiscordRoundIcon';
import { FacebookColoredIcon } from 'components/icons/FacebookColoredIcon';
import { GoogleColoredIcon } from 'components/icons/GoogleColoredIcon';
import { InstagramIcon } from 'components/icons/InstagramIcon';
import { LinkedinIcon } from 'components/icons/LinkedinIcon';
import { SnapchatIcon } from 'components/icons/SnapchatIcon';
import { TiktokIcon } from 'components/icons/TiktokIcon';
import { XIcon } from 'components/icons/XIcon';
import { YoutubeIcon } from 'components/icons/YoutubeIcon';
import { SideMenu } from 'layout/SideMenu';
import Actions from 'redux-state/actions';
import { GetSocialAuthKey, GetUser } from 'redux-state/selectors';
import { useIsMediumScreen } from 'theme';
import { Constants, VARIANT } from 'utilities/constants';
import { SocialIcons } from './SocialIcons';
import {
  SocialLinksContainer,
  StyledHeaderBox,
  StyledHeaderTypography,
  StyledMainBox,
  StyledSubHeaderBox,
  StyledSubHeaderTypography
} from './StyledComponents';

export const LinkedAccounts = () => {
  const dispatch = useDispatch();
  const isMediumScreen = useIsMediumScreen();
  const user = GetUser();
  const socialAuthKeys = GetSocialAuthKey();

  type ShareStatus = {
    reddit: boolean;
    facebook: boolean;
    google: boolean;
    instagram: boolean;
    linkedin: boolean;
    youtube: boolean;
    twitter: boolean;
    snapchat: boolean;
    tiktok: boolean;
    discord: boolean;
  };

  const initialShareState = {
    reddit: false,
    facebook: false,
    google: false,
    instagram: false,
    linkedin: false,
    youtube: false,
    twitter: false,
    snapchat: false,
    tiktok: false,
    discord: false
  };

  const [shareStatus, setShareStatus] =
    useState<ShareStatus>(initialShareState);
  const [initialShareStatus, setInitialShareStatus] =
    useState<ShareStatus>(initialShareState);

  useEffect(() => {
    if (user) {
      dispatch(Actions.getSocialAuthKeys(user?.id));
    }
  }, [dispatch, user?.id]);

  useEffect(() => {
    if (socialAuthKeys) {
      const updatedShareStatus = { ...initialShareState };
      socialAuthKeys.forEach((key) => {
        updatedShareStatus[key.type] = key.isShared;
      });
      setInitialShareStatus(updatedShareStatus);
      setShareStatus(updatedShareStatus);
    }
  }, [socialAuthKeys]);

  const handleShareStatusChange = (platform, value) => {
    setShareStatus((prevStatus) => ({
      ...prevStatus,
      [platform]: value
    }));
  };

  const iconData = [
    {
      component: <FacebookColoredIcon width="4.0625rem" height="4.0625rem" />,
      platform: Constants.FACEBOOK,
      onClick: () =>
        dispatch(
          Actions.socialLogIn(
            Constants.FACEBOOK,
            shareStatus.facebook,
            user?.id
          )
        )
    },
    {
      component: <GoogleColoredIcon width="4.0625rem" height="4.0625rem" />,
      platform: Constants.GOOGLE,
      onClick: () =>
        dispatch(
          Actions.socialLogIn(Constants.GOOGLE, shareStatus.google, user?.id)
        )
    },
    {
      component: <InstagramIcon width="4.0625rem" height="4.0625rem" />,
      platform: Constants.INSTAGRAM,
      onClick: () =>
        dispatch(
          Actions.socialInstagramLogIn(
            Constants.INSTAGRAM,
            shareStatus.instagram,
            user?.id
          )
        )
    },
    {
      component: <LinkedinIcon width="4.0625rem" height="4.0625rem" />,
      platform: Constants.LINKEDIN,
      onClick: () =>
        dispatch(
          Actions.socialLinkedinLogIn(
            Constants.LINKEDIN,
            shareStatus.linkedin,
            user?.id
          )
        )
    },
    {
      component: <YoutubeIcon width="4.0625rem" height="4.0625rem" />,
      platform: Constants.YOUTUBE,
      onClick: () =>
        dispatch(
          Actions.socialLogIn(Constants.YOUTUBE, shareStatus.youtube, user?.id)
        )
    },
    {
      component: <XIcon width="4.0625rem" height="4.0625rem" />,
      platform: Constants.TWITTER,
      onClick: () =>
        dispatch(
          Actions.socialTwitterLogIn(
            Constants.TWITTER,
            shareStatus.twitter,
            user?.id
          )
        )
    },
    {
      component: <SnapchatIcon width="4.0625rem" height="4.0625rem" />,
      platform: Constants.SNAPCHAT,
      onClick: () =>
        dispatch(
          Actions.socialLogIn(
            Constants.SNAPCHAT,
            shareStatus.snapchat,
            user?.id
          )
        )
    },
    {
      component: <TiktokIcon width="4.6875rem" height="4.6875rem" />,
      platform: Constants.TIKTOK,
      onClick: () =>
        dispatch(
          Actions.socialTiktokLogIn(
            Constants.TIKTOK,
            shareStatus.tiktok,
            user?.id
          )
        )
    },
    {
      component: <DiscordIcon width="4.0625rem" height="4.0625rem" />,
      platform: Constants.DISCORD,
      onClick: () =>
        dispatch(
          Actions.socialDiscordLogIn(
            Constants.DISCORD,
            shareStatus.discord,
            user?.id
          )
        )
    }
  ];

  return (
    <Box>
      <SideMenu active={Constants.ALL} isHomePage={false} />
      <StyledMainBox isMediumScreen={isMediumScreen}>
        <StyledHeaderBox>
          <StyledHeaderTypography variant="h5">
            {Constants.CONNECT_SOCIAL_ACCOUNTS}
          </StyledHeaderTypography>
        </StyledHeaderBox>
        <StyledSubHeaderBox>
          <StyledSubHeaderTypography variant={VARIANT.BODY2}>
            {Constants.TOGGLE_ON}
          </StyledSubHeaderTypography>
          <StyledSubHeaderTypography variant={VARIANT.BODY2}>
            {Constants.AMPLIFY_IDEAS}
          </StyledSubHeaderTypography>
        </StyledSubHeaderBox>
        <SocialLinksContainer isMediumScreen={isMediumScreen}>
          {iconData.map((icon) => {
            const platformKey = socialAuthKeys?.find(
              (key) => key.type === icon.platform
            );
            const username = platformKey?.username;
            const email = platformKey?.email;
            const connected =
              Boolean(platformKey) &&
              shareStatus[icon.platform] === initialShareStatus[icon.platform];
            const share = shareStatus[icon.platform];
            const id = platformKey?.id;

            return (
              <SocialIcons
                key={icon.platform}
                share={share}
                setShare={(value) =>
                  handleShareStatusChange(icon.platform, value)
                }
                icon={{ ...icon, username, email, connected, id }}
              />
            );
          })}
        </SocialLinksContainer>
      </StyledMainBox>
    </Box>
  );
};
