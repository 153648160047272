import React, { useContext, useState, useEffect } from 'react';
import { styled, useTheme, alpha } from '@mui/material/styles';

import { AuthContext } from 'contexts/AuthContext';
import { ModalDataContext } from 'contexts/ModalDataContext';
import styles from 'modals/ModalWindow.module.scss';
import { Radio } from 'components/Radio/Radio';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

type PaymentType = {
  id: string;
  title: string;
};

const defaultOptions: Array<PaymentType> = [
  { id: 'docusign', title: 'Pay with Credit Card' },
  { id: 'coinbase', title: 'Pay with Crypto' }
];

const RadioText = ({ option }: { option: PaymentType }) => {
  const mainRow = (
    <tr className={styles.payOptionsRow}>
      <td className={styles.payOptionsCell}>{option.title}</td>
    </tr>
  );

  return (
    <table className={styles.payOptionsTable}>
      <tbody>{mainRow}</tbody>
    </table>
  );
};

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  return {
    root: {
      borderRadius: 8,
      margin: '8px 0',
      border: `solid 1px ${alpha(theme.palette.primary.main, 0.15)}`,
      background: 'rgb(248, 245, 255, 0.5)',
      color: theme.palette.primary.main,
      '&:hover': {
        border: `solid 1px ${alpha(theme.palette.primary.main, 0.5)}`
      }
    }
  };
});

export const ChoosePaymentStep: React.FC = () => {
  const { user } = useContext(AuthContext);
  const { values, updateField } = useContext(ModalDataContext);
  const { classes } = useStyles();
  const [selectedEntity, setSelectedEntity] = useState<string | undefined>(
    `${values.brainMethod}`
  );
  const [options, setOptions] = useState<Array<PaymentType>>(defaultOptions);

  useEffect(() => {
    if (user && user.isAdmin) {
      setOptions([...defaultOptions, { id: 'skip', title: 'Skip' }]);
    }
  }, [user]);

  const handleEntityChange = (e: React.ChangeEvent, value: string) => {
    setSelectedEntity(value);
    updateField('brainMethod', value);
  };

  return (
    <div className={styles.stepWrapper}>
      <Typography className={styles.text}>Select payment option</Typography>

      <RadioGroup
        onChange={handleEntityChange}
        value={selectedEntity}
        aria-label="method"
        name="method"
      >
        {options.map((option) => {
          return (
            <FormControlLabel
              className={styles.payOption}
              key={option.id}
              classes={classes}
              value={option.id}
              control={<Radio />}
              label={<RadioText option={option} />}
            />
          );
        })}
      </RadioGroup>
    </div>
  );
};

export default ChoosePaymentStep;
