import React from 'react';
import { styled, Theme, useTheme } from '@mui/material/styles';

import { PsTheme } from '../../theme';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      [psTheme.breakpoints.down('xs')]: {
        marginTop: 15
      }
    }
  };
});

type CardCellProps = {
  children?: React.ReactNode;
  className?: string;
};

const CardCellView = ({ className, children }: CardCellProps) => {
  const { classes } = useStyles();
  let rootClassName = classes.root;
  if (className) {
    rootClassName += ' ' + className;
  }

  return <div className={rootClassName}>{children}</div>;
};

export const CardCell = styled(CardCellView)({});

export default CardCell;
