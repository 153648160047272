import React from 'react';
import { styled, useTheme, alpha, lighten } from '@mui/material/styles';

import { PsTheme } from '../../theme';
import Chip, { ChipProps } from '@mui/material/Chip';
import { makeStyles } from 'tss-react/mui';

const DeleteIcon = (props: any) => {
  return (
    <svg
      focusable="false"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      {...props}
    >
      <path
        d="M11 3L3 11"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 3L11 11"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

type ClassKey = 'root' | 'label' | 'deleteIcon';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      height: 34,
      margin: '0 8px 8px 0',
      fontSize: 15,
      borderRadius: 30,
      maxWidth: '100%'
    },
    sizeSmall: {
      height: 20,
      fontSize: 13
    },
    label: {
      paddingLeft: 16,
      paddingRight: 16
    },
    labelSmall: {
      paddingLeft: 10,
      paddingRight: 10
    },
    deleteIcon: {
      flexShrink: 0,
      cursor: 'pointer',
      width: 14,
      height: 14,
      margin: '0px 10px 0 -8px',
      padding: 3,
      borderRadius: 10,
      color: alpha(psTheme.palette.third.main, 0.4),
      '&:hover': {
        color: alpha(psTheme.palette.third.main, 1)
      }
    }
  };
});

export type PsChipProps = ChipProps & {
  textColor?: string;
};

const defaultColor = '#07A854';

export const PsChipView = (props: PsChipProps) => {
  const { children, textColor = '#07A854', ...rest } = props;
  const { classes } = useStyles();
  let finalColor = textColor;
  if (!finalColor || !finalColor.startsWith('#')) {
    finalColor = defaultColor;
  }

  const deleteIcon = rest.onDelete ? <DeleteIcon /> : undefined;

  const onRef = (el: any) => {
    if (el && el.style) {
      //const color = '#07A854';
      const backgroundColor = lighten(finalColor, 0.85);
      el.style.color = finalColor;
      el.style.backgroundColor = backgroundColor;
    }
  };

  return (
    <Chip deleteIcon={deleteIcon} classes={classes} {...rest} ref={onRef} />
  );
};

export const PsChip = styled(PsChipView)({});

export default PsChip;
