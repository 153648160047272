import React, { useState, useCallback, useContext, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import ModalWindow from 'modals/ModalWindow';
import { PsButton } from 'components/common/PsButton';
import Step from 'components/Step';
import Stepper from 'components/Stepper';
import { Problem } from 'components/CardProblem';
import { Solution } from 'components/CardSolution';
import { Application } from 'components/CardApplication';
import { ModalContext, ModalComponentsKeys } from 'contexts/ModalContext';
import { ModalDataContext, AddFullFlowSteps } from 'contexts/ModalDataContext';
import { DataContext } from 'contexts/DataContext';
import { AuthContext } from 'contexts/AuthContext';
import {
  ADD_FULL_FLOW_CONFIG,
  stepName,
  defaultProblemTitle,
  defaultSolutionTitle,
  defaultInventionTitle
} from './config';
import styles from 'modals/ModalWindow.module.scss';
import { makeStyles } from 'tss-react/mui';
import useRouter from 'hooks/useRouter';

const steps: string[] = [
  'Problem Create',
  'Solution Create',
  'Invention Create'
];

const useButtonClasses = makeStyles()(() => {
  return {
    root: {
      margin: '8px 0',
      fontWeight: 400
    }
  };
});

const modalTitle = {
  PROBLEM: 'Add New Problem',
  SOLUTION: 'Add New Solution',
  APPLICATION: 'Add New Application',
  TYPE: 'Add New'
};

const AddNewFullFlow: React.FC = () => {
  const { user } = useContext(AuthContext);
  const { openModal, closeModal } = useContext(ModalContext);
  const { values, setErrors, updateField, setType } =
    useContext(ModalDataContext);
  const { dataProvider } = useContext(DataContext);
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [prevSteps, setPrevSteps] = useState<string[]>([]);
  const [activeStepNumber, setActiveStepNumber] = useState<number>(0);
  const [currentStep, setCurrentStep] = useState<string | undefined>(
    AddFullFlowSteps.ProblemName
  );
  const [createdProblem, setCreatedProblem] = useState<{
    id: string | number;
    label: string;
  }>();
  const [createdSolutions, setCreatedSolutions] = useState<
    Array<{ id: string | number; label: string }>
  >([]);

  const buttonClasses = useButtonClasses();

  const {
    Component,
    nextStep,
    validate,
    isNextStepBlocked,
    name,
    isFinalStep,
    nextButtonTitle,
    backButtonTitle
  } = ADD_FULL_FLOW_CONFIG[currentStep || ''];

  const StepComponent = Component as React.FC;

  useEffect(() => {
    if (
      user &&
      user.userData &&
      user.userData.tags &&
      user.userData.tags.length
    ) {
      const tags = user.userData.tags.map((t) => t.id);
      updateField('tags', tags);
    }
  }, []);

  const handleClickBack = () => {
    if (
      name === stepName.PROBLEM_START ||
      name === stepName.SELECT_TYPE ||
      (name === stepName.SOLUTION_START && !createdSolutions.length)
    ) {
      closeModal();
      return;
    }
    setActiveStepNumber((prev) => Math.max(0, prev - 1));
    setCurrentStep(prevSteps[prevSteps.length - 1]);
    setPrevSteps(prevSteps.slice(0, prevSteps.length - 1));
  };

  const handleNextStep = async () => {
    if (typeof validate === 'function') {
      const { success, errors } = validate(values);

      if (!success) {
        setErrors({ ...errors });

        return;
      }
    }

    if (name === stepName.PROBLEM_END) {
      const problemInfo = {
        title: (values.titleProblem || '').replace(defaultProblemTitle, ''),
        tags: values.tagsProblem,
        body: values.descriptionProblem,
        files: values.filesProblem,
        parentProblem: values.rootProblem,
        isPublic: true
      };

      setLoading(true);

      dataProvider
        .create<Problem>('problems', { data: problemInfo })
        .then(({ data }) => {
          setCreatedProblem({ id: data.id, label: data.title || '(empty)' });
          router.push(`/problems/${data.key || data.id}`);
          // clear prev
          updateField('titleProblem', undefined);
          updateField('descriptionProblem', undefined);
          updateField('filesProblem', []);
          updateField('parentProblem', data.id);
          updateField('parentProblemLabel', data.title);
          updateField('parentProblemHide', true);
          updateField('parentSolutionHide', true);
          updateField('mainProblem', data.id);
          updateField('mainProblemLabel', data.title);
          // go next
          setPrevSteps([...prevSteps, currentStep || '']);
          setErrors({});
          setActiveStepNumber((prev) => prev + 1);
          if (typeof nextStep === 'function') {
            setCurrentStep(nextStep(values));
            return;
          }
          setCurrentStep(nextStep);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });

      return;
    } else if (name === stepName.SOLUTION_END) {
      const solutionInfo = {
        title: (values.titleSolution || '').replace(defaultSolutionTitle, ''),
        tags: values.tagsSolution,
        body: values.descriptionSolution,
        files: values.filesSolution,
        problem: values.parentProblem,
        isPublic: values.finalizeType === 'public'
      };

      setLoading(true);

      dataProvider
        .create<Solution>('solutions', { data: solutionInfo })
        .then(({ data }) => {
          setCreatedSolutions([
            ...createdSolutions,
            { id: data.id, label: data.title || '(empty)' }
          ]);
          router.push(`/solutions/${data.key || data.id}`);
          // clear prev
          updateField('titleSolution', undefined);
          updateField('descriptionSolution', undefined);
          updateField('filesSolution', []);
          updateField('parentProblem', createdProblem?.id);
          updateField('parentProblemLabel', createdProblem?.label);
          updateField('parentProblemHide', true);
          updateField('parentSolutionHide', true);
          updateField('mainProblem', createdProblem?.id);
          updateField('mainProblemLabel', createdProblem?.label);
          // go next
          setPrevSteps([...prevSteps, currentStep || '']);
          setErrors({});
          setActiveStepNumber((prev) => prev + 1);
          if (typeof nextStep === 'function') {
            setCurrentStep(nextStep(values));
            return;
          }

          setCurrentStep(nextStep);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });

      return;
    } else if (name === stepName.SELECT_TYPE) {
      setPrevSteps([currentStep || '']);
      setErrors({});
      if (values.brainObject === ModalComponentsKeys.addNewSolution) {
        // solution
        setActiveStepNumber(3);
        setCurrentStep(AddFullFlowSteps.SolutionName);
      } else {
        // invention
        updateField('mainProblem', createdProblem?.id);
        updateField('mainProblemLabel', createdProblem?.label);
        updateField(
          'selected',
          createdSolutions.map((el) => el.id)
        );
        setActiveStepNumber(7);
        setCurrentStep(AddFullFlowSteps.ApplicationName);
      }
      return;
    } else if (isFinalStep) {
      const appInfo = {
        title: (values.titleApplication || '').replace(
          defaultInventionTitle,
          ''
        ),
        body: values.descriptionApplication,
        problem: values.mainProblem,
        selected: [values.mainProblem, ...values.selected],
        isPublic: false
      };

      setLoading(true);

      dataProvider
        .create<Application>('applications', { data: appInfo })
        .then(({ data }) => {
          router.push(`/inventions/${data.key || data.id}`);
          setType(ModalComponentsKeys.addNewInvention);
          openModal(ModalComponentsKeys.success);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });

      return;
    }

    setPrevSteps([...prevSteps, currentStep || '']);
    setErrors({});
    setActiveStepNumber((prev) => prev + 1);
    if (typeof nextStep === 'function') {
      setCurrentStep(nextStep(values));
      return;
    }

    setCurrentStep(nextStep);
  };

  const isNextStepDisabled: boolean =
    typeof isNextStepBlocked === 'function' && isNextStepBlocked(values);

  let stepperStep = 0;
  let title = modalTitle.PROBLEM;
  let backButtonTitleFinal = backButtonTitle || 'Back';
  if (activeStepNumber >= 3 && activeStepNumber <= 5) {
    stepperStep = 1;
    title = modalTitle.SOLUTION;
    if (activeStepNumber === 3 && createdSolutions.length) {
      backButtonTitleFinal = 'Back';
    }
  }
  if (activeStepNumber > 5 && activeStepNumber <= 6) {
    // select
    stepperStep = 3;
    title = modalTitle.TYPE;
  }
  if (activeStepNumber > 6) {
    stepperStep = 2;
    title = modalTitle.APPLICATION;
  }

  return (
    <ModalWindow title={title}>
      <Stepper className={styles.stepper} activeStep={stepperStep}>
        {steps.map((step) => (
          <Step key={step}>{step}</Step>
        ))}
      </Stepper>
      <StepComponent />
      <div className={styles.actions}>
        <PsButton
          disabled={isNextStepDisabled || loading}
          onClick={handleNextStep}
          fullWidth
          classes={buttonClasses}
        >
          {nextButtonTitle || 'Next'}
        </PsButton>
        <PsButton
          onClick={handleClickBack}
          fullWidth
          cancel
          classes={buttonClasses}
        >
          {backButtonTitleFinal}
        </PsButton>
      </div>
    </ModalWindow>
  );
};

export default AddNewFullFlow;
