import React from 'react';
import { useIsMediumScreen } from 'theme';
import { Constants } from 'utilities/constants';
import { RenderActionButton } from '../../RenderActionButton';
import { RenderAddToConceptBtn } from '../../RenderAddToConceptBtn';
import { StyledHeaderWrapper } from '../StyledComponents';

interface HeaderProps {
  drawerItemType: string;
  handleClick?: () => void;
  isCurrentConceptSolution?: boolean;
  openAddToConceptModal: () => void;
}

export const Header: React.FC<HeaderProps> = ({
  drawerItemType,
  handleClick,
  isCurrentConceptSolution = false,
  openAddToConceptModal
}) => {
  const isMediumScreen = useIsMediumScreen();

  return (
    <>
      {isMediumScreen &&
        drawerItemType !== Constants.APPLICATION &&
        drawerItemType !== Constants.SOLUTION && (
          <StyledHeaderWrapper>
            <RenderActionButton
              itemType={drawerItemType}
              onClick={handleClick}
            />
          </StyledHeaderWrapper>
        )}

      {isMediumScreen && drawerItemType === Constants.SOLUTION && (
        <StyledHeaderWrapper>
          <RenderAddToConceptBtn
            disabled={isCurrentConceptSolution}
            itemType={drawerItemType}
            onClick={openAddToConceptModal}
          />
        </StyledHeaderWrapper>
      )}
    </>
  );
};
