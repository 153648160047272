import { Contest } from 'components/CardContest';
import { GetContestPayload } from './interface';
import {
  CREATE_CONTEST,
  GENERATE_AI_COVER_IMAGE_FAIL,
  GENERATE_AI_COVER_IMAGE_SUCCESS,
  GENERATE_AI_COVER_IMAGE,
  GENERATE_AI_DESCRIPTION_FAIL,
  GENERATE_AI_DESCRIPTION_SUCCESS,
  GENERATE_AI_DESCRIPTION,
  GENERATE_AI_IMAGE_FAIL,
  GENERATE_AI_IMAGE_SUCCESS,
  GENERATE_AI_IMAGE,
  GET_CONTESTS_SUCCESS,
  GET_CONTESTS,
  GET_PROBLEMS_FROM_ID,
  GET_SOLUTIONS_WITH_OWNER_INFO,
  SET_CONTEST_DATA,
  SET_PROBLEMS,
  SET_SOLUTIONS_WITH_OWNER_INFO,
  TOGGLE_LOADER_LEADERBOARD,
  UPDATE_CONTEST
} from './types';

export const createContest = (data) => ({
  type: CREATE_CONTEST,
  payload: { data }
});

export const getContests = (payload: GetContestPayload) => ({
  type: GET_CONTESTS,
  payload
});

export const getContestSuccess = (payload: Array<Contest>) => ({
  type: GET_CONTESTS_SUCCESS,
  payload
});

export const setContest = (data) => ({
  type: SET_CONTEST_DATA,
  payload: { data }
});

export const getProblemsFromId = (data) => ({
  type: GET_PROBLEMS_FROM_ID,
  payload: { data }
});

export const setProblems = (data) => ({
  type: SET_PROBLEMS,
  payload: { data }
});

export const updateContest = (
  id,
  data,
  userId: string | number,
  pagination?: { page: number; perPage: number },
  isArchived?: boolean
) => ({
  type: UPDATE_CONTEST,
  payload: { id, data, userId, pagination, isArchived }
});

export const setSolutionsWithOwnerInfo = (data) => ({
  type: SET_SOLUTIONS_WITH_OWNER_INFO,
  payload: { data }
});

export const getSolutionsWithOwnerInfo = (payload: string) => ({
  type: GET_SOLUTIONS_WITH_OWNER_INFO,
  payload
});

export const togLoadLeaderboard = (payload: boolean) => ({
  type: TOGGLE_LOADER_LEADERBOARD,
  payload
});

export const generateDescriptionFromAI = (payload) => {
  return {
    type: GENERATE_AI_DESCRIPTION,
    payload
  };
};

export const generateDescriptionFromAISuccess = (payload) => ({
  type: GENERATE_AI_DESCRIPTION_SUCCESS,
  payload
});

export const generateDescriptionFromAIFail = () => ({
  type: GENERATE_AI_DESCRIPTION_FAIL
});

export const generateImageFromAI = (payload) => ({
  type: GENERATE_AI_IMAGE,
  payload
});

export const generateImageFromAISuccess = (payload) => ({
  type: GENERATE_AI_IMAGE_SUCCESS,
  payload
});

export const generateImageFromAIFail = () => ({
  type: GENERATE_AI_IMAGE_FAIL
});

export const generateCoverImageFromAI = (payload) => ({
  type: GENERATE_AI_COVER_IMAGE,
  payload
});

export const generateCoverImageFromAISuccess = (payload) => ({
  type: GENERATE_AI_COVER_IMAGE_SUCCESS,
  payload
});

export const generateCoverImageFromAIFail = () => ({
  type: GENERATE_AI_COVER_IMAGE_FAIL
});
