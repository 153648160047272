/* eslint-disable */
import React, { useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

interface ChecklistItemProps {
  text: string;
  isFulfilled: boolean;
}

const ChecklistItem: React.FC<ChecklistItemProps> = React.memo(
  ({ text, isFulfilled }) => {
    return (
      <Box display="flex" alignItems="center" mb={1}>
        {isFulfilled ? (
          <CheckCircleIcon color="success" fontSize="small" sx={{ mr: 1 }} />
        ) : (
          <CancelIcon color="error" fontSize="small" sx={{ mr: 1 }} />
        )}
        <Typography
          variant="body2"
          color={isFulfilled ? 'textPrimary' : 'error'}
        >
          {text}
        </Typography>
      </Box>
    );
  }
);

const ConceptCheckList = ({ fulfilled, warnings }) => {
  const renderItem = useCallback((text, isFulfilled) => {
    return <ChecklistItem key={text} text={text} isFulfilled={isFulfilled} />;
  }, []);

  return (
    <Box>
      {fulfilled.map((solution) => renderItem(solution, true))}
      {warnings.map((issue) => renderItem(issue, false))}
    </Box>
  );
};

export { ConceptCheckList };
