import React, { useMemo, useState } from 'react';
import { Typography, styled } from '@mui/material';

export type ReadMoreProps = {
  body: string;
  length: number;
  textWidth?: number;
  sameLine?: boolean;
  expandedId?: string;
  setExpandedId?: string;
  id?: string;
};

const ReadMore = ({ body, length, textWidth, sameLine }: ReadMoreProps) => {
  const [expanded, setExpanded] = useState(false);

  const toggleReadMore = () => {
    setExpanded(!expanded);
  };

  const renderBody = useMemo(() => {
    return (
      <StyledTypography width={textWidth} lineHeight={1.5} variant="body2">
        {expanded ? body : body?.slice(0, length)}
        {body?.length > length &&
          (sameLine ? (
            <span
              style={{
                fontSize: '14px',
                fontWeight: '500',
                lineHeight: '22px',
                textAlign: 'left',
                color: '#632DDD',
                textDecoration: 'underline',
                marginLeft: '4px',
                cursor: 'pointer'
              }}
              onClick={toggleReadMore}
            >
              {expanded ? 'Less' : 'More'}
            </span>
          ) : (
            <StyledReadMore onClick={toggleReadMore}>
              {expanded ? 'Show less' : 'Show more'}
            </StyledReadMore>
          ))}
      </StyledTypography>
    );
  }, [body, length, textWidth, sameLine, expanded, toggleReadMore]);

  return renderBody;
};

const StyledTypography = styled(Typography)({
  wordWrap: 'break-word'
});

const StyledReadMore = styled(Typography)({
  marginLeft: '7px',
  fontSize: '15px',
  textDecoration: 'none',
  fontWeight: 'bold',
  cursor: 'pointer',
  color: '#632DDD',
  '@media (max-width: 576px)': {
    fontSize: '12px'
  }
});

export { ReadMore };
