import React, { useCallback, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { styled, useTheme } from '@mui/material/styles';

import IconButton from '@mui/material/IconButton';
import { DownVoteIcon, UpVoteIcon } from 'assets/icons';
import { AuthContext, Profile } from 'contexts/AuthContext';
import { DataContext } from 'contexts/DataContext';
import Actions from 'redux-state/actions';
import { PsTheme } from 'theme';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'viewOnly' | 'votesBlock' | 'voteBlock' | 'voteCounter';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    viewOnly: {
      pointerEvents: 'none'
    },
    votesBlock: {
      marginLeft: 'auto',
      display: 'flex',
      alignItems: 'baseline'
    },
    voteBlock: {
      fontSize: 18,
      lineHeight: '22px',
      color: psTheme.palette.third.main,
      display: 'flex',
      alignItems: 'center',
      '&:first-child': {
        marginRight: 28
      },
      '& > button': {
        position: 'relative'
      },
      '& > button img': {
        opacity: 0.7
      }
    },
    voteCounter: {
      paddingTop: 5,
      opacity: 0.7
    }
  };
});

type VotesProps = {
  className?: string;
  resource: string;
  itemId: string | number;
  likes: number;
  dislikes: number;
  user?: Profile;
};

const VotesView = ({
  className,
  resource,
  itemId,
  likes: initLikes = 0,
  dislikes: initDislikes = 0,
  user
}: VotesProps) => {
  const { classes } = useStyles();
  const { refreshUserBalance } = useContext(AuthContext);
  const { dataProvider } = useContext(DataContext);
  const dispatch = useDispatch();
  const [likes, setLikes] = useState(initLikes);
  const [dislikes, setDislikes] = useState(initDislikes);

  const voteItem = useCallback(
    (type: number) => {
      if (user) {
        dataProvider
          .voteItem(resource, itemId, type)
          .then((res) => {
            setLikes(res.likes);
            setDislikes(res.dislikes);
            refreshUserBalance();
          })
          .catch((err) => {
            // console.error(err);
          });
      } else {
        dispatch(Actions.openAuthModal(true));
      }
    },
    [user, resource, itemId, refreshUserBalance]
  );

  const onLikeClick = (e: React.MouseEvent) => {
    e.preventDefault();
    voteItem(1);
  };

  const onDislikeClick = (e: React.MouseEvent) => {
    e.preventDefault();
    voteItem(-1);
  };

  let rootClassName = classes.votesBlock;
  if (className) {
    rootClassName += ' ' + className;
  }
  if (!user) {
    rootClassName += ' ' + classes.viewOnly;
  }

  return (
    <div className={rootClassName}>
      <div className={classes.voteBlock}>
        <IconButton color="primary" aria-label="like" onClick={onLikeClick}>
          <img src={UpVoteIcon} alt="like image" />
        </IconButton>
        <span className={classes.voteCounter}>{likes}</span>
      </div>
      <div className={classes.voteBlock}>
        <IconButton color="primary" aria-label="like" onClick={onDislikeClick}>
          <img src={DownVoteIcon} alt="dislike image" />
        </IconButton>
        <span className={classes.voteCounter}>{dislikes}</span>
      </div>
    </div>
  );
};

export const Votes = styled(VotesView)({});

export default Votes;
