import React from 'react';
export const BrainIcon = ({
  size = 30,
  color = '',
  className
}: {
  size?: number;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
    >
      <g
        id="Group_19274"
        data-name="Group 19274"
        transform="translate(-548.075 -865)"
      >
        <circle
          id="Ellipse_7"
          data-name="Ellipse 7"
          cx="15"
          cy="15"
          r="15"
          transform="translate(548.075 865)"
          fill="#d6c7f8"
        />
        <g id="brain" transform="translate(553.917 870.842)">
          <path
            id="Path_1564"
            data-name="Path 1564"
            d="M2.9,3.4q0,.125.009.25A3.4,3.4,0,0,0,.765,9.158a3.4,3.4,0,0,0,2.142,5.509q-.009.125-.009.25a3.4,3.4,0,0,0,5.724,2.476V.922A3.4,3.4,0,0,0,2.9,3.4Z"
            transform="translate(0 0)"
            fill="#5b38d5"
          />
          <circle
            id="Ellipse_4232"
            data-name="Ellipse 4232"
            cx="0.599"
            cy="0.599"
            r="0.599"
            transform="translate(11.554 4.965)"
            fill="#5b38d5"
          />
          <path
            id="Path_1565"
            data-name="Path 1565"
            d="M279.621,7.012a3.4,3.4,0,0,0-2.907-3.363q.009-.125.009-.25A3.4,3.4,0,0,0,271,.922V5.044h1.165a1.61,1.61,0,1,1,0,1.073H271v2.5h2.6a1.61,1.61,0,1,1,0,1.073H271v2.5h1.165a1.61,1.61,0,1,1,0,1.073H271v4.122a3.4,3.4,0,0,0,5.724-2.476q0-.125-.009-.25a3.4,3.4,0,0,0,2.142-5.509A3.4,3.4,0,0,0,279.621,7.012Z"
            transform="translate(-261.305 0)"
            fill="#5b38d5"
          />
          <circle
            id="Ellipse_4233"
            data-name="Ellipse 4233"
            cx="0.599"
            cy="0.599"
            r="0.599"
            transform="translate(11.554 12.153)"
            fill="#5b38d5"
          />
          <circle
            id="Ellipse_4234"
            data-name="Ellipse 4234"
            cx="0.599"
            cy="0.599"
            r="0.599"
            transform="translate(13.351 8.559)"
            fill="#5b38d5"
          />
        </g>
      </g>
    </svg>
  );
};
