import React from 'react';
export const ContestIcon = ({
  size = 24,
  color = '#E78136',
  className
}: {
  size?: number;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2_169)">
        <path
          d="M14.1167 15.6941C14.1167 15.4744 13.9428 15.34 13.7303 15.3952C13.7303 15.3952 12.7349 15.6539 12.0002 15.6539C11.2656 15.6539 10.2702 15.3952 10.2702 15.3952C10.0577 15.34 9.88379 15.4744 9.88379 15.6941V16.852C9.88379 17.0716 10.0635 17.2512 10.2831 17.2512H13.7174C13.937 17.2512 14.1167 17.0715 14.1167 16.852V15.6941Z"
          fill={color}
        />
        <path
          d="M18.0695 0.798707C18.0695 0.357657 17.7118 0 17.2708 0H6.72839C6.28734 0 5.92969 0.357657 5.92969 0.798707V7.38772C5.92969 9.4501 6.5431 11.3274 7.65688 12.6738C8.77801 14.029 10.3203 14.7754 11.9996 14.7754C13.6789 14.7754 15.2212 14.0291 16.3423 12.6738C17.4561 11.3274 18.0695 9.4501 18.0695 7.38772V0.798707Z"
          fill={color}
        />
        <path
          d="M5.17002 8.98713C4.07709 8.58042 3.57382 7.63715 3.57382 6.06907V2.50163H5.05139V0.904297H2.77527C2.33414 0.904297 1.97656 1.26187 1.97656 1.703V6.06899C1.97656 8.7325 3.25236 10.355 5.59822 10.734C5.40549 10.1797 5.26117 9.59497 5.17002 8.98713Z"
          fill={color}
        />
        <path
          d="M6.72839 18.4492C6.28734 18.4492 5.92969 18.8068 5.92969 19.2479V23.2013C5.92969 23.6424 6.28734 24 6.72839 24H17.2709C17.7119 24 18.0696 23.6424 18.0696 23.2013V19.2479C18.0696 18.8068 17.7119 18.4492 17.2709 18.4492H6.72839Z"
          fill={color}
        />
        <path
          d="M21.2244 0.904297H18.9482V2.50163H20.4258V6.06899C20.4258 7.63707 19.9225 8.58034 18.8296 8.98705C18.7384 9.59489 18.5942 10.1797 18.4014 10.734C20.7472 10.355 22.023 8.7325 22.023 6.06899V1.703C22.0231 1.26187 21.6655 0.904297 21.2244 0.904297Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2_169">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ContestIcon;
