import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { MESSAGE_TYPES, VARIANT } from 'utilities/constants';
import { ChatMessage } from './ChatMessage';
import { Message } from './interfaces';

const TIME = 360;

interface ChatConversationProps {
  expandedNode?: {
    key: string;
    msgId: string;
  };
  messages: Message[];
  setMessages: Dispatch<SetStateAction<Message[]>>;
}

const ChatConversationComponent: React.FC<ChatConversationProps> = ({
  expandedNode,
  messages,
  setMessages
}) => {
  const chatEndRef = useRef<HTMLDivElement | null>(null);
  const msgRef = useRef<HTMLDivElement | null>(null);
  const isInitialRender = useRef(true);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    const elementToScroll = msgRef.current || chatEndRef.current;
    if (elementToScroll) {
      const timer = setTimeout(() => {
        elementToScroll.scrollIntoView({
          behavior: VARIANT.SMOOTH,
          block: VARIANT.END
        });
        msgRef.current = null;
      }, TIME);

      return () => clearTimeout(timer);
    }
  }, [messages]);

  const regularMessages: Message[] = [];
  const actionMessages: Message[] = [];

  messages.forEach((message) => {
    if (message.type === MESSAGE_TYPES.ACTION) {
      actionMessages.push(message);
    } else {
      regularMessages.push(message);
    }
  });

  return (
    <Box>
      {regularMessages.map((message) => (
        <Box
          key={message.id}
          ref={expandedNode?.msgId === message.id ? msgRef : null}
        >
          <ChatMessage
            expandedNode={expandedNode}
            message={message}
            setMessages={setMessages}
          />
        </Box>
      ))}
      {actionMessages.map((message) => (
        <ChatMessage key={message.id} message={message} />
      ))}
      <Box role="scrollTarget" ref={chatEndRef} />
    </Box>
  );
};

export const ChatConversation = React.memo(
  ChatConversationComponent,
  (prevProps, nextProps) => {
    return (
      prevProps.messages === nextProps.messages &&
      prevProps.expandedNode === nextProps.expandedNode
    );
  }
);
