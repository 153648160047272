import React, { useMemo } from 'react';
import { Typography, styled } from '@mui/material';

export type ExpandTextProps = {
  body: string;
  expandedId?: string;
  id?: string | number;
  length: number;
  setExpandedId?: React.Dispatch<React.SetStateAction<boolean>>;
  textWidth: number;
};
const ExpandText = ({
  body,
  expandedId,
  id,
  length,
  textWidth
}: ExpandTextProps) => {
  const renderBody = useMemo(() => {
    return (
      <StyledTypography width={textWidth} lineHeight={1.5} variant="body2">
        {expandedId == id ? body : body?.slice(0, length)}
      </StyledTypography>
    );
  }, [textWidth, expandedId, id, body, length]);

  return renderBody;
};

const StyledTypography = styled(Typography)({
  wordWrap: 'break-word',
  alignSelf: 'center',
  '@media (max-width: 896px)': {
    lineHeight: 1,
    fontSize: '12px',
    paddingLeft: '3px',
    paddingRight: '3px'
  }
});

export { ExpandText };
