/* eslint-disable no-magic-numbers */
import { Button, Typography } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { GetUser } from 'redux-state/selectors';
import Actions from 'redux-state/actions';
import { Constants } from 'utilities/constants';
import {
  FirstItemGrid,
  MainContainerGrid,
  ThirdItemGrid
} from './styledComponents';

export const NotificationHeader = ({
  setUnreadCount,
  handleClose
}: {
  setUnreadCount?: any;
  handleClose: () => void;
}) => {
  const user = GetUser();
  const dispatch = useDispatch();

  const markMultipleAsRead = () => {
    if (user) {
      dispatch(Actions.markAllAsRead(user?._id ?? user?.id));
      if (setUnreadCount) {
        setUnreadCount(0);
      }
    }
    handleClose();
  };
  return (
    <MainContainerGrid container>
      <FirstItemGrid item md={4} xs={12}>
        <Typography
          style={{
            fontSize: '20px',
            fontWeight: 700
          }}
        >
          Notifications
        </Typography>
      </FirstItemGrid>
      {setUnreadCount && (
        <ThirdItemGrid item md={4} xs={12}>
          <Button
            sx={{ margin: '.3125rem 0 0 0', right: 0 }}
            onClick={markMultipleAsRead}
          >
            <Typography sx={{ fontSize: '.75rem', lineHeight: '1.0281rem' }}>
              {Constants.MARK_ALL_AS_READ}
            </Typography>
          </Button>
        </ThirdItemGrid>
      )}
    </MainContainerGrid>
  );
};
