import React, { useCallback, useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { PriorArt } from 'components/CardPriorArt';
import { PsButton } from 'components/common/PsButton';
import { ModalContext } from 'contexts/ModalContext';
import { getDateStr } from '../../helpers';
import { PsTheme } from '../../theme';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'row' | 'cell' | 'title' | 'text';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    row: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 20,
      padding: '0 15px',
      color: '#5e5e5e',
      '& a': {
        color: '#632DDD',
        textDecoration: 'none'
      },
      '& a:hover': {
        textDecoration: 'underline'
      }
    },
    cell: {},
    title: {
      marginBottom: 10
    },
    text: {
      paddingLeft: 30
    }
  };
});

export type CardPriorArtSmallProps = {
  priorArt: PriorArt;
  vote?: boolean;
};

const CardPriorArtSmallView = ({
  priorArt,
  vote = true
}: CardPriorArtSmallProps) => {
  const {
    id,
    key,
    title,
    alias,
    tagsInfo = [],
    createdAt,
    files,
    likes,
    dislikes,
    reward
  } = priorArt;
  const { openInventionImprove } = useContext(ModalContext);
  const { classes } = useStyles();

  const url = `/prior-arts/${key || id}`;

  const onImproveClick = useCallback(() => {
    openInventionImprove({
      inventionId: id,
      inventionTitle: title,
      type: 'prior-art'
    });
  }, [openInventionImprove, id, title]);

  return (
    <div className={classes.row}>
      <div className={classes.cell}>
        <div className={classes.title}>
          <Link to={url}>{title}</Link>
        </div>
        <div className={classes.text}>
          {alias} {getDateStr(createdAt)}{' '}
          <a href={priorArt.url} target="_blank" rel="noreferrer">
            View on Google Patents
          </a>
        </div>
      </div>
      <div className={classes.cell}>
        <PsButton color="secondary" smallest onClick={onImproveClick}>
          Improve
        </PsButton>
      </div>
    </div>
  );
};

export const CardPriorArtSmall = styled(CardPriorArtSmallView)({});

export default CardPriorArtSmall;
