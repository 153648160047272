import React, { useCallback, useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import ModalWindow from 'modals/ModalWindow';
import { PsButton } from 'components/common/PsButton';
import { PsLogo } from 'components/common/PsLogo';
import { ModalContext } from 'contexts/ModalContext';
import { ModalDataContext } from 'contexts/ModalDataContext';
import styles from 'modals/SuccessModal/SuccessModal.module.scss';
import Typography from '@mui/material/Typography';

export const ImproveApplicationSuccess: React.FC = () => {
  const { closeModal } = useContext(ModalContext);
  const { values, resetFields } = useContext(ModalDataContext);
  const theme = useTheme();
  const handleClick = useCallback(() => {
    closeModal();
    resetFields();
  }, [closeModal, resetFields]);

  return (
    <ModalWindow title="Congratulations!" titleCenter>
      <div className={styles.wrapper}>
        <div className={styles.iconRow}>
          <PsLogo size={90} color={theme.palette.primary.main} small />
          <Typography className={styles.iconHolderPrimary} component="div">
            <span>+{values.productRaw}</span>
            <br />
            IdeaCoins
          </Typography>
        </div>
        <br />
        <Typography className={styles.textSmall}>
          You can claim these IdeaCoins once someone incorporates this solution
          into an invention&apos;s patent application
        </Typography>
        <Typography className={styles.textWide}>
          You created the solution of <b>{values.parentSolutionLabel}</b> for
          the <b>{values.productId}</b> problem of{' '}
          <b>{values.parentProblemLabel}</b>
        </Typography>
        <PsButton onClick={handleClick} fullWidth>
          Close
        </PsButton>
      </div>
    </ModalWindow>
  );
};

export default ImproveApplicationSuccess;
