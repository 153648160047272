import React, { useCallback, useContext, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import {
  Card,
  CardImage,
  CardBody,
  CardTitle,
  CardOwner,
  CardText,
  CardActions
} from 'components/card';
import { PsButton } from 'components/common/PsButton';
import { TagInfo } from 'components/CardTag';
import { OwnerInfo } from 'components/CardProfile';
import { Tags } from 'components/Tag';
import { ShareBtn } from 'components/ShareBtn';
import { PsRecord } from 'contexts/DataContext';
import { AuthContext } from 'contexts/AuthContext';
import { ModalComponentsKeys, ModalContext } from 'contexts/ModalContext';
import { ModalDataContext } from 'contexts/ModalDataContext';
import SolutionModal from 'modals/AddSolution';
import { Picture, placeholderType } from '../../helpers';
import { PsTheme } from '../../theme';
import { makeStyles } from 'tss-react/mui';

export type Problem = PsRecord & {
  key: string;
  title?: string;
  teaser?: string;
  body?: string;
  owner?: string | number;
  ownerInfo?: OwnerInfo;
  files?: Array<Picture>;
  createdAt?: string;
  tags?: string[];
  tagsInfo?: Array<TagInfo>;
  likes: number;
  dislikes: number;
  isPublic: boolean;
  parentApplication?: string | number;
  parentApplicationKey?: string;
  parentApplicationTitle?: string;
  parentApplicationProblem?: string | number;
  parentApplicationProblemKey?: string;
  parentApplicationProblemName?: string;
  parentProduct?: string | number;
  parentProductKey?: string;
  parentProductTitle?: string;
  parentPriorArt?: string | number;
  parentPriorArtKey?: string;
  parentPriorArtTitle?: string;
};

type ClassKey = 'root' | 'tags' | 'actionButton' | 'tagsButton';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {},
    tags: {
      '& > div': {
        marginTop: 4,
        marginBottom: 4
      }
    },
    actionButton: {
      marginRight: 10
    },
    tagsButton: {
      marginTop: 10
    }
  };
});

export type ProblemCardProps = {
  problem: Problem;
  isDetailed?: boolean;
};

const CardProblemView = ({ problem, isDetailed = false }: ProblemCardProps) => {
  const {
    id,
    key,
    title,
    teaser,
    body,
    owner,
    ownerInfo,
    createdAt,
    files,
    tags = [],
    tagsInfo = [],
    likes,
    dislikes,
    isPublic = false
  } = problem;
  const { user } = useContext(AuthContext);
  const { openModal, openSolutionCreate } = useContext(ModalContext);
  const { updateField } = useContext(ModalDataContext);
  const { classes } = useStyles();
  const [isSolModalOpen, setIsSolModalOpen] = useState<boolean>(false);

  const image = files && files.length ? files[0] : undefined;
  const url = `/problems/${key || id}`;

  const onSolveClick = useCallback(() => {
    setIsSolModalOpen(true);
  }, []);

  const onManageTagsClick = useCallback(() => {
    updateField('title', 'problems');
    updateField('productId', id);
    updateField('tags', tags);
    openModal(ModalComponentsKeys.manageTags);
  }, [updateField, openModal, id, tags]);

  const showEditTags = (user?.userData?.moderator || []).length;

  return (
    <Card>
      <CardImage
        href={isDetailed ? '' : url}
        image={image}
        type={placeholderType.P}
      />
      <CardBody>
        <CardTitle title={teaser || title} href={isDetailed ? undefined : url}>
          <Tags
            className={classes.tags}
            tagsList={tagsInfo}
            edit={isDetailed && showEditTags ? 'before' : ''}
            onEditClick={onManageTagsClick}
          />
        </CardTitle>
        <CardOwner
          ownerName={ownerInfo && ownerInfo.username}
          ownerKey={ownerInfo && ownerInfo.key}
          owner={owner}
          createdAt={createdAt}
        />
        <CardText>{body}</CardText>
        <CardActions
          resource="problems"
          resourceId={id}
          likes={likes}
          dislikes={dislikes}
          user={user}
        >
          <PsButton
            className={classes.actionButton}
            onClick={onSolveClick}
            coins="+1"
          >
            Solve
          </PsButton>
          <ShareBtn problem={problem} tagsList={tagsInfo} />
        </CardActions>
      </CardBody>
      {isSolModalOpen && (
        <SolutionModal
          open={isSolModalOpen}
          onClose={() => setIsSolModalOpen(false)}
        />
      )}
    </Card>
  );
};

export const CardProblem = styled(CardProblemView)({});

export default CardProblem;
