import React from 'react';
import { Box, Typography } from '@mui/material';
import { Picture } from 'helpers';
import { ERRORS } from 'utilities/constants';
import { LinkView } from '../LinkView';
import { MainBox, StyledAvatar, StyledTitle, SubTitle } from './styles';

interface OwnerViewProps {
  leader: { key?: string; username?: string; picture?: Picture };
  subtitle: string;
  icon: string;
}
const OwnerView = ({ leader, subtitle, icon }: OwnerViewProps) => {
  return (
    <MainBox>
      <StyledAvatar src={icon} alt={'community leader'} />
      <Box marginLeft={1} sx={{ display: 'flex', flexDirection: 'column' }}>
        <SubTitle>{subtitle}</SubTitle>
        <StyledTitle>
          {leader?.username ? (
            <LinkView
              height="none"
              id={leader.key}
              title={leader.username}
              type="profiles"
            />
          ) : (
            <Typography variant="caption">{ERRORS.NO_OWNER}</Typography>
          )}
        </StyledTitle>
      </Box>
    </MainBox>
  );
};

export default OwnerView;
