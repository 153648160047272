import ChallengeName from './steps/ChallengeName';
import ChallengeDate from './steps/ChallengeDate';
import ChallengeInternalReward from './steps/ChallengeInternalReward';
import ChallengeExternalReward from './steps/ChallengeExternalReward';
import { ContestName } from 'modals/AddNewContest/steps/ContestName';
import { ContestDescription } from 'modals/AddNewContest/steps/ContestDescription';
import { ContestTypes } from 'modals/AddNewContest/steps/ContestTypes';
import { ContestBounty } from 'modals/AddNewContest/steps/ContestBounty';
import { ContestProblems } from 'modals/AddNewContest/steps/ContestProblems';
import {
  ValidateFormType,
  FormStepConfig,
  AddChallengeSteps,
  AddContestSteps
} from 'contexts/ModalDataContext';

export const stepName = {
  CONTEST_START: 'contestStart',
  CONTEST_END: 'contestEnd'
};

export const ADD_CHALLENGE_STEPS_CONFIG: {
  [name: string]: FormStepConfig;
} = {
  [AddChallengeSteps.ChallengeName]: {
    Component: ChallengeName as React.FC,
    nextStep: AddChallengeSteps.ChallengeDate,
    isNextStepBlocked: (data) => {
      return (
        !data.titleChallenge ||
        !data.challengeTags ||
        !data.challengeTags.length ||
        !data.challengeContests ||
        !data.challengeContests.length
      );
    },
    validate: (data): ValidateFormType => {
      if (!data.titleChallenge) {
        return { success: false, errors: { titleChallenge: 'required' } };
      }
      if (!data.challengeTags || !data.challengeTags.length) {
        return { success: false, errors: { challengeTags: 'required' } };
      }
      if (!data.challengeContests || !data.challengeContests.length) {
        return { success: false, errors: { challengeContests: 'required' } };
      }
      return { success: true };
    }
  },
  [AddChallengeSteps.ChallengeDate]: {
    nextStep: AddChallengeSteps.ChallengeInternalReward,
    Component: ChallengeDate as React.FC,
    isNextStepBlocked: (data) => {
      if (!data.challengeStart || !data.challengeEnd) {
        return true;
      }
      const start = new Date(data.challengeStart as string);
      const end = new Date(data.challengeEnd as string);
      return end < start;
    },
    validate: (data): ValidateFormType => {
      if (data.challengeStart && data.challengeEnd) {
        return { success: true };
      }
      return { success: false, errors: { challengeStart: 'required' } };
    }
  },
  [AddChallengeSteps.ChallengeInternalReward]: {
    nextStep: AddChallengeSteps.ChallengeExternalReward,
    Component: ChallengeInternalReward as React.FC,
    isNextStepBlocked: (data) => {
      return false;
    },
    validate: (data): ValidateFormType => {
      return { success: true };
    }
  },
  [AddChallengeSteps.ChallengeExternalReward]: {
    isFinalStep: true,
    Component: ChallengeExternalReward as React.FC,
    isNextStepBlocked: (data) => {
      return false;
    },
    validate: (data): ValidateFormType => {
      return { success: true };
    },
    nextButtonTitle: 'Finish'
  },
  [AddContestSteps.ContestName]: {
    Component: ContestName as React.FC,
    nextStep: AddContestSteps.ContestDescription,
    name: stepName.CONTEST_START,
    modalTitle: 'Add New Contest',
    isNextStepBlocked: (data) => {
      return !data.titleContest || !data.contestTag;
    },
    validate: (data): ValidateFormType => {
      if (data.titleContest && data.contestTag) {
        return { success: true };
      }
      return { success: false, errors: { titleContest: 'required' } };
    }
  },
  [AddContestSteps.ContestDescription]: {
    Component: ContestDescription as React.FC,
    nextStep: AddContestSteps.ContestTypes,
    modalTitle: 'Add New Contest',
    isNextStepBlocked: (data) => {
      return !data.descriptionContest;
    },
    validate: (data): ValidateFormType => {
      if (data.descriptionContest) {
        return { success: true };
      }
      return { success: false, errors: { descriptionContest: 'required' } };
    }
  },
  [AddContestSteps.ContestTypes]: {
    nextStep: AddContestSteps.ContestBounty,
    Component: ContestTypes as React.FC,
    modalTitle: 'Add New Contest',
    isNextStepBlocked: (data) => {
      if (!data.contestStart || !data.contestEnd) {
        return true;
      }
      const start = new Date(data.contestStart as string);
      const end = new Date(data.contestEnd as string);
      return end < start;
    },
    validate: (data): ValidateFormType => {
      if (data.contestStart && data.contestEnd) {
        return { success: true };
      }
      return { success: false, errors: { contestStart: 'required' } };
    }
  },
  [AddContestSteps.ContestBounty]: {
    nextStep: AddContestSteps.ContestProblems,
    Component: ContestBounty as React.FC,
    modalTitle: 'Add New Contest',
    isNextStepBlocked: (data) => {
      return false;
    },
    validate: (data): ValidateFormType => {
      return { success: true };
    }
  },
  [AddContestSteps.ContestProblems]: {
    isFinalStep: true,
    Component: ContestProblems as React.FC,
    name: stepName.CONTEST_END,
    isNextStepBlocked: (data) => {
      if (!data.contestProblems || data.contestProblems.length === 0)
        return true;
      return false;
    },
    validate: (data): ValidateFormType => {
      if (data.contestProblems && data.contestProblems.length) {
        return { success: true };
      }
      return { success: false, errors: { contestProblems: 'required' } };
    }
  }
};
