/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import useRouter from 'hooks/useRouter';
import TagsView from 'pages/home/TagsView';
import Actions from 'redux-state/actions';
import {
  GetFollowingTagsCount,
  GetPublicUser,
  GetTags,
  GetUser
} from 'redux-state/selectors';
import { Constants, NUMBERS } from 'utilities/constants';
import {
  StyledBox,
  StyledClickableText,
  StyledProfileAvatar,
  StyledTypography
} from './StyledComponents';

export const ProfileAvatar: React.FC<{
  isMediumScreen: boolean;
  isCurrentUser?: boolean;
}> = ({ isMediumScreen, isCurrentUser }) => {
  const tags = GetTags();
  const user = GetUser();
  const profile = GetPublicUser();
  const router = useRouter();
  const dispatch = useDispatch();
  const totalFollowingCommunities = GetFollowingTagsCount();
  const { id } = router.query;
  const files = isCurrentUser ? user?.files : profile?.files;
  const image = files && files.length ? files[0]?.url : undefined;
  const MAX_COMMUNITIES_DISPLAY = 500;
  const [open, setOpen] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchFilter, setSearchFilter] = useState<string>(searchValue);
  const [filterType, setFilterType] = useState<string>(Constants.ALL);

  const handleOpen = () => {
    setFilterType(Constants.FOLLOWED);
    setOpen(true);
  };

  const paginationOptions = useMemo(
    () => ({
      page: page,
      rowsPerPage: rowsPerPage,
      setPage: setPage,
      setRowsPerPage: setRowsPerPage
    }),
    [page, rowsPerPage, setRowsPerPage, setPage]
  );

  const pagination = useMemo(
    () => ({
      page: page,
      perPage: rowsPerPage
    }),
    [page, rowsPerPage]
  );

  const handleClose = () => {
    setOpen(false);
    setRowsPerPage(5);
    setPage(0);
    setSearchValue('');
    setFilterType(Constants.ALL);
  };

  useEffect(() => {
    dispatch(
      Actions.getTags(
        {
          item: Constants.TAGS,
          pagination,
          userId: profile?.id
        },
        filterType,
        searchFilter,
        { field: Constants.IDEA_POINTS, order: Constants.DESC }
      )
    );
  }, [dispatch, pagination, filterType, searchFilter]);

  useEffect(() => {
    dispatch(Actions.getFollowingTagsCount(id as string));
  }, [dispatch, id]);

  return (
    <StyledBox>
      <StyledProfileAvatar src={image} />
      <Box sx={{ ml: 2 }}>
        {isMediumScreen ? (
          <StyledTypography isMediumScreen={isMediumScreen}>
            {isCurrentUser ? user?.username : profile?.username}
          </StyledTypography>
        ) : (
          <StyledTypography isMediumScreen={isMediumScreen}>
            {isCurrentUser ? user?.username : profile?.username}
          </StyledTypography>
        )}
        {totalFollowingCommunities?.totalCount != null && (
          <StyledClickableText onClick={handleOpen}>
            {`${Constants.FOLLOWING_TEXT} ${
              totalFollowingCommunities.totalCount > MAX_COMMUNITIES_DISPLAY
                ? NUMBERS.FIVE_HUNDRES_STRING
                : totalFollowingCommunities.totalCount
            } ${Constants.COMMUNITIES}`}
          </StyledClickableText>
        )}
      </Box>
      {open && (
        <TagsView
          open={open}
          handleClose={handleClose}
          tags={tags}
          paginationOptions={paginationOptions}
          pagination={pagination}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          searchFilter={searchFilter}
          setSearchFilter={setSearchFilter}
          filterType={filterType}
          setFilterType={setFilterType}
        />
      )}
    </StyledBox>
  );
};
