import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import {
  Box,
  Card,
  FormControl,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  Typography,
  styled
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { loadStripe } from '@stripe/stripe-js';
import { Application } from 'components/CardApplication';
import { ModalDataContext } from 'contexts/ModalDataContext';
import { ShareInfoModal } from 'modals/ShareInfoModal';
import Actions from 'redux-state/actions';
import { GetConceptData, GetUser } from 'redux-state/selectors';
import { Constants } from 'utilities/constants';
import PsButton from '../../components/common/PsButton';
import PsLogo from '../../components/common/PsLogo';
import WarningDialog from '../../components/common/WarningDialog';
import AuthContext from '../../contexts/AuthContext';
import DataContext from '../../contexts/DataContext';
import { finalizeType } from '../../helpers';
import useRouter from '../../hooks/useRouter';
import { PsTheme, colorPalette } from '../../theme';
import ModalWindow from '../ModalWindow';
import { useRemoveQueryParams } from 'helpers';

type MakePatentPendingModalProps = {
  open: boolean;
  concept: Application;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onPatentDownloadClick?: (
    isOpenDownloadModal: boolean,
    isOpenPatentPendingModal: boolean
  ) => void;
  pagination?: { page: number; perPage: number };
};

const useStyles = makeStyles()(() => {
  const psTheme = useTheme() as PsTheme;
  return {
    root: {
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`,
      position: 'fixed',
      outline: 0,
      width: '100%',
      maxWidth: 650,
      '&.wide': {
        maxWidth: 1100,
        '& .actions': {
          flexDirection: 'row-reverse'
        },
        '& .actions > button:first-child': {
          width: '50%',
          marginLeft: 12
        },
        '& .actions > button:last-child': {
          width: '50%',
          marginRight: 12
        },
        [psTheme.breakpoints.down('xs')]: {
          '& .actions': {
            flexDirection: 'column'
          },
          '& .actions > button:first-child': {
            width: '100%',
            marginLeft: 0
          },
          '& .actions > button:last-child': {
            width: '100%',
            marginRight: 0
          }
        }
      }
    },
    title: {
      alignItems: 'center',
      color: colorPalette.black,
      fontWeight: '600',
      marginTop: '12.8px'
    },
    bold: {
      fontWeight: '700'
    },
    text: {
      color: colorPalette.black,
      fontSize: '25px'
    },
    subText: {
      color: colorPalette.black,
      fontSize: '15px'
    },
    disabled: {
      color: colorPalette.explosiveGrey,
      fontSize: '25px'
    },
    modalTitleIcon: {
      fontSize: '19px',
      svg: {
        position: 'relative',
        top: '4px'
      },
      whiteSpace: 'nowrap'
    },
    btn: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '24px'
    },
    radioBtn: {
      color: colorPalette.red,
      marginTop: '3.2px'
    }
  };
});

const ModalWindowStyleProps = { maxWidth: '650px', margin: '0 auto' };
const RadioButtonStyleProps = { marginBottom: '30px' };
const CardStyleProps = { boxShadow: 'none', padding: '20px 10px 0px' };
const FormControlStyleProps = { marginTop: '3.2px' };
const FormLabelStyleProps = { alignItems: 'flex-start' };
const CancelBtnStyleProps = { width: 'auto' };

const radioOptions = {
  APPLY_MINDMINER_CREDIT: 'Apply 1 MindMiner Credit',
  DOWNLOAD_PATENT_APPLICATION: 'Download Patent Application',
  MINT_PATENT_PENDING_TOKEN: 'Mint Patent Pending Token',
  SUBSCRIBE_TO_MINDMINER: 'Subscribe'
};

export const MakePatentPendingModal: React.FC<MakePatentPendingModalProps> = ({
  open,
  concept,
  setOpen,
  onPatentDownloadClick,
  pagination
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const removeQueryParams = useRemoveQueryParams();
  const user = GetUser();
  const conceptsData = GetConceptData();
  const { classes } = useStyles();
  const { updateField } = useContext(ModalDataContext);
  const { dataProvider, refreshApplicationDetail, refreshProfileStats } =
    useContext(DataContext);
  const { userCredits } = useContext(AuthContext);
  const stripeKey = (user && user.userData?.STRIPE_PUB_KEY) || '';
  const [stripe, setStripe] = useState<unknown>(null);
  const [selectedOption, setSelectedOption] = useState<string>(
    radioOptions.MINT_PATENT_PENDING_TOKEN
  );
  const [currentModal, setCurrentModal] = useState<string>('payment');
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState('');
  const [isShareInfoModalOpen, setShareInfoModalOpen] =
    useState<boolean>(false);
  const router = useRouter();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initializeStripe = () => {
    loadStripe(stripeKey)
      .then((res) => {
        setStripe(res);
      })
      .catch((err) => {
        // Need to handle error
        console.log('error occured', err);
      });
  };

  useEffect(() => {
    if (stripe || !stripeKey) return;
    initializeStripe();
  }, [initializeStripe, stripe, stripeKey]);

  useEffect(() => {
    if (!conceptsData?.data) {
      dispatch(Actions.getConcept(user?.id ?? user?._id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, user?._id]);

  const activateAnotherConcept = () => {
    //ConceptData is sorted in Descending order using updatedAt field
    if (conceptsData.data && conceptsData.data.length > 1) {
      const newActiveConcept =
        user.activeConcept.id === conceptsData.data[0].id
          ? conceptsData.data[1]
          : conceptsData.data[0];
      dispatch(
        Actions.editProfile(user?.id, {
          activeConcept: newActiveConcept
        })
      );
    } else {
      const appInfo = {
        title: '',
        selected: [],
        problems: []
      };
      dispatch(Actions.createConcept(appInfo));
    }
  };

  const handlePayment = useCallback(async () => {
    try {
      let redirectURL = '';
      let paginationParams;
      const baseUrl = window.location.origin;
      switch (selectedOption) {
        case radioOptions.MINT_PATENT_PENDING_TOKEN:
          paginationParams = pagination
            ? `&page=${pagination.page}&perPage=${pagination.perPage}`
            : '';
          redirectURL = `${baseUrl}/profiles/${user?.key}?showAgreementDoc=true&currentTab=${Constants.CONCEPTS}${paginationParams}&id=${concept.id}`;
          break;
        case radioOptions.SUBSCRIBE_TO_MINDMINER:
          redirectURL = `${baseUrl}/profiles/${user?.key}?showInventionModal=true&currentTab=${Constants.CONCEPTS}&${Constants.LOCK_CONCEPT_MODAL}=true&${Constants.PATENT_FILE_FLOW}=${Constants.VIA_SELF}&id=${concept.id}`;
          break;
        default:
          redirectURL = `${baseUrl}/profiles/${user?.key}?currentTab=${Constants.CONCEPTS}`;
          break;
      }

      // TODO: Update static values
      const items =
        selectedOption === radioOptions.MINT_PATENT_PENDING_TOKEN
          ? [{ name: Constants.PATENT_PENDING_TOKEN, price: 349, quantity: 1 }]
          : [{ name: Constants.SUBSCRIPTION, price: 234, quantity: 1 }];
      dataProvider
        .initialPaymentSession<{ sessionId: string; type: string }>(
          concept.id,
          redirectURL,
          items,
          pagination
        )
        .then(
          async (data: {
            sessionId: string;
            type: string;
            stripeSessionId: string;
          }) => {
            if (data.stripeSessionId) {
              return loadStripe(stripeKey).then(async (stripe) => {
                if (stripe) {
                  activateAnotherConcept();
                  await stripe.redirectToCheckout({
                    sessionId: data.stripeSessionId
                  });
                }
              });
            } else {
              location.reload();
            }
          }
        )
        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      // TODO: Need to handle error
      console.log('Error processing payment:', error);
    }
  }, [
    concept?.id,
    dataProvider,
    dispatch,
    pagination,
    selectedOption,
    stripeKey,
    user?.key
  ]);

  const onClose = useCallback(() => {
    setOpen(false);
    setCurrentModal('payment');
    setSelectedOption(radioOptions.MINT_PATENT_PENDING_TOKEN);
  }, [setOpen]);

  const onSubmitPaymentModal = useCallback(async () => {
    updateField('application', {});
    refreshApplicationDetail();
    refreshProfileStats();
    if (!stripe) {
      console.error('Stripe.js has not loaded.');
      return;
    }

    if (selectedOption === radioOptions.MINT_PATENT_PENDING_TOKEN) {
      setLoading(true);
      dataProvider
        .payOptionStart(concept.id, {
          finalizeType: concept.finalizeType || finalizeType.OWN
        })
        .then(async () => {
          await handlePayment();
        })
        .catch((error) => {
          setApiError(error.message);
          setLoading(false);
        });
    } else if (selectedOption === radioOptions.DOWNLOAD_PATENT_APPLICATION) {
      if (user?.subscription) {
        if (window.location.pathname.includes(Constants.PROFILES)) {
          onPatentDownloadClick(true, false);
        } else {
          activateAnotherConcept();
          router.push(
            `/profiles/${user?.key}?showInventionModal=true&currentTab=${Constants.CONCEPTS}&${Constants.LOCK_CONCEPT_MODAL}=true&${Constants.PATENT_FILE_FLOW}=${Constants.VIA_SELF}&id=${concept.id}`
          );
        }
      } else {
        userCredits > 0
          ? setSelectedOption(radioOptions.APPLY_MINDMINER_CREDIT)
          : setSelectedOption(radioOptions.SUBSCRIBE_TO_MINDMINER);
        setCurrentModal('subscription');
      }
    }
  }, [
    concept?.finalizeType,
    concept?.id,
    dataProvider,
    dispatch,
    handlePayment,
    onPatentDownloadClick,
    refreshApplicationDetail,
    refreshProfileStats,
    router,
    selectedOption,
    stripe,
    updateField,
    user?.key,
    user?.subscription,
    userCredits
  ]);

  const onSubmitSubscriptionModal = useCallback(async () => {
    if (selectedOption === radioOptions.SUBSCRIBE_TO_MINDMINER) {
      setLoading(true);
      await handlePayment();
    }

    if (concept && selectedOption === radioOptions.APPLY_MINDMINER_CREDIT) {
      dataProvider
        .updateCredits(user.id, { sign: -1, amount: 1 })
        .then(() => {
          if (window.location.pathname.includes(Constants.PROFILES)) {
            onPatentDownloadClick(true, false);
            setOpen(false);
          } else {
            activateAnotherConcept();
            router.push(
              `/profiles/${user?.key}?showInventionModal=true&currentTab=${Constants.CONCEPTS}&${Constants.LOCK_CONCEPT_MODAL}=true&${Constants.PATENT_FILE_FLOW}=${Constants.VIA_SELF}&id=${concept.id}`
            );
          }
        })
        .catch((error) => {
          setApiError(error);
        });
    }
  }, [
    concept,
    dataProvider,
    dispatch,
    handlePayment,
    router,
    selectedOption,
    user?.id
  ]);

  const onSubmitMintToken = useCallback(() => {
    navigate(Constants.MODAL_PROBLEM);
    setShareInfoModalOpen(true);
  }, [navigate]);

  const handleShareInfoModalClose = useCallback(() => {
    setShareInfoModalOpen(false);
    removeQueryParams([Constants.SHOW_MODAL]);
  }, [removeQueryParams]);

  const handleSubmit = useCallback(async () => {
    if (selectedOption === radioOptions.MINT_PATENT_PENDING_TOKEN) {
      if (user?.ideaPoints > 0) {
        onSubmitPaymentModal();
      } else if (user?.ideaPoints === 0) {
        onSubmitMintToken();
      }
    } else {
      const callSubmit = {
        payment: onSubmitPaymentModal,
        subscription: onSubmitSubscriptionModal
      };
      callSubmit[currentModal]();
    }
  }, [
    currentModal,
    onSubmitPaymentModal,
    onSubmitMintToken,
    selectedOption,
    user?.ideaPoints,
    onSubmitSubscriptionModal
  ]);

  const onBackClick = useCallback(() => {
    setSelectedOption(radioOptions.DOWNLOAD_PATENT_APPLICATION);
    setCurrentModal('payment');
  }, []);

  const title = useMemo(
    () => (
      <>
        <StyledTitleTypo>Make </StyledTitleTypo>
        <StyledBoldTypo>{concept?.title}</StyledBoldTypo>
        <StyledTitleBox> Patent Pending: </StyledTitleBox>
      </>
    ),
    [concept?.title]
  );

  const activeClass = userCredits === 0 ? classes.disabled : classes.text;
  const {
    creditMindMinerMarkup,
    subscribeDownloadPatentMarkup,
    pendingTokenMarkup,
    downloadPatentMarkup
  } = useMemo(() => {
    // TODO:// Need to add buy credits page.
    const creditMindMinerMarkup = (
      <>
        <Box className={activeClass}>Apply 1 MindMiner Credit</Box>
        {userCredits === 0 ? <Link to="/buycredits">Buy Credits</Link> : null}
      </>
    );

    const subscribeDownloadPatentMarkup = (
      <>
        <StyledText>Subscribe to Mindminer</StyledText>
      </>
    );
    const pendingTokenMarkup = (
      <>
        <StyledText>Mint Patent Pending Token</StyledText>
        <StyledSubText>
          {' '}
          Protect and sell the rights to your new concept
        </StyledSubText>
      </>
    );
    const downloadPatentMarkup = (
      <>
        <StyledText>Download Patent Application</StyledText>
        <StyledSubText
        //  component={'span'}
        >
          Handle the filing of your patent on your own
        </StyledSubText>
      </>
    );
    return {
      creditMindMinerMarkup,
      subscribeDownloadPatentMarkup,
      pendingTokenMarkup,
      downloadPatentMarkup
    };
  }, [activeClass, userCredits]);

  const ModalContent = useMemo(
    () =>
      currentModal === 'payment' ? (
        <Box>
          <Card sx={CardStyleProps}>
            <FormControl sx={FormControlStyleProps}>
              <StyledRadioButton
                onChange={(e) => setSelectedOption(e.target.value)}
                value={selectedOption}
              >
                <FormControlLabel
                  control={<Radio />}
                  label={pendingTokenMarkup}
                  style={RadioButtonStyleProps}
                  sx={FormLabelStyleProps}
                  value={radioOptions.MINT_PATENT_PENDING_TOKEN}
                />
                <FormControlLabel
                  control={<Radio />}
                  label={downloadPatentMarkup}
                  style={RadioButtonStyleProps}
                  sx={FormLabelStyleProps}
                  value={radioOptions.DOWNLOAD_PATENT_APPLICATION}
                />
              </StyledRadioButton>
            </FormControl>

            <StyledButton>
              <PsButton
                disabled={loading}
                loading={loading}
                onClick={handleSubmit}
              >
                {Constants.SUBMIT}
              </PsButton>
              <StyledModalTitleIcon>
                {Constants.EARN_500_PTS}
              </StyledModalTitleIcon>
            </StyledButton>
          </Card>
        </Box>
      ) : (
        <Box>
          <Card sx={CardStyleProps}>
            <FormControl sx={FormControlStyleProps}>
              <StyledRadioButton
                onChange={(e) => setSelectedOption(e.target.value)}
                value={selectedOption}
              >
                <FormControlLabel
                  control={<Radio disabled={userCredits === 0} />}
                  label={creditMindMinerMarkup}
                  style={RadioButtonStyleProps}
                  sx={FormLabelStyleProps}
                  value={radioOptions.APPLY_MINDMINER_CREDIT}
                />
                <FormControlLabel
                  control={<Radio />}
                  label={subscribeDownloadPatentMarkup}
                  style={RadioButtonStyleProps}
                  sx={FormLabelStyleProps}
                  value={radioOptions.SUBSCRIBE_TO_MINDMINER}
                />
              </StyledRadioButton>
            </FormControl>

            <StyledButton>
              <PsButton
                disabled={loading}
                loading={loading}
                onClick={handleSubmit}
              >
                {Constants.SUBMIT}
              </PsButton>
              <PsButton onClick={onBackClick}>Back</PsButton>
            </StyledButton>
          </Card>
        </Box>
      ),
    [
      creditMindMinerMarkup,
      currentModal,
      downloadPatentMarkup,
      handleSubmit,
      loading,
      onBackClick,
      pendingTokenMarkup,
      selectedOption,
      subscribeDownloadPatentMarkup,
      theme.palette.primary.main,
      userCredits
    ]
  );

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <StyledRoot>
          <ModalWindow titleCenter title={title} style={ModalWindowStyleProps}>
            {ModalContent}
            {isShareInfoModalOpen && (
              <ShareInfoModal
                open={isShareInfoModalOpen}
                onClose={handleShareInfoModalClose}
              />
            )}
          </ModalWindow>
        </StyledRoot>
      </Modal>
      {apiError && (
        <WarningDialog
          no=""
          onSubmit={() => setApiError('')}
          open={!!apiError}
          yes="Cancel"
        >
          {apiError}
        </WarningDialog>
      )}
    </>
  );
};

const StyledTitleTypo = styled(Typography)({
  alignItems: 'center',
  color: colorPalette.black,
  fontWeight: 600,
  marginTop: '12.8px'
});

const StyledTitleBox = styled(Box)({
  alignItems: 'center',
  color: colorPalette.black,
  fontWeight: 600,
  marginTop: '12.8px'
});

const StyledBoldTypo = styled(Typography)({ fontWeight: 700 });

const StyledText = styled(Box)({
  color: colorPalette.black,
  fontSize: '25px'
});

const StyledSubText = styled(Typography)({
  color: colorPalette.black,
  fontSize: '15px'
});

const StyledRadioButton = styled(RadioGroup)({
  color: colorPalette.red,
  marginTop: '3.2px'
});

const StyledButton = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '24px'
});

const StyledModalTitleIcon = styled(Typography)({
  color: colorPalette.purple,
  fontSize: '19px',
  fontWeight: 500,
  svg: {
    position: 'relative',
    top: '4px'
  },
  whiteSpace: 'nowrap'
});

const StyledRoot = styled(Box)({
  top: `50%`,
  left: `50%`,
  transform: `translate(-50%, -50%)`,
  position: 'fixed',
  outline: 0,
  width: '100%',
  maxWidth: 650,
  '&.wide': {
    maxWidth: 1100,
    '& .actions': {
      flexDirection: 'row-reverse'
    },
    '& .actions > button:first-child': {
      width: '50%',
      marginLeft: 12
    },
    '& .actions > button:last-child': {
      width: '50%',
      marginRight: 12
    },
    '@media (max-width: 650px)': {
      '& .actions': {
        flexDirection: 'column'
      },
      '& .actions > button:first-child': {
        width: '100%',
        marginLeft: 0
      },
      '& .actions > button:last-child': {
        width: '100%',
        marginRight: 0
      }
    }
  }
});
