import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Actions from 'redux-state/actions';
import { Box, Modal, Typography, Button, Snackbar, Alert } from '@mui/material';
import logo from 'assets/images/logo.svg';
import {
  ALREDAY_REQUESTED_QUOTATION_MODAL_TEXT,
  COMPLETE_STAKING_TEXT,
  Constants,
  QUOTATION_STATUS,
  REQUEST_QUOTATION_ALERT_TEXT,
  REQUEST_QUOTATION_MODAL_TEXT,
  STAKING_STATUS
} from 'utilities/constants';
import { GetUser } from 'redux-state/selectors';

interface RequestQuotationModalProps {
  isOpen: boolean;
  onClose: () => void;
  quotationStatus: string;
  stakingStatus: boolean;
  inventionStakingStatus: string;
  conceptId: any;
  owner: string;
  fetchProfileConcepts: () => void;
}

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4
};

const RequestQuotationModal: React.FC<RequestQuotationModalProps> = ({
  isOpen,
  onClose,
  quotationStatus,
  stakingStatus,
  inventionStakingStatus,
  conceptId,
  owner,
  fetchProfileConcepts
}) => {
  const dispatch = useDispatch();
  const user = GetUser();

  const [isThankYouMessageVisible, setIsThankYouMessageVisible] =
    useState(false);

  const handleGetQuote = () => {
    setIsThankYouMessageVisible(true);
    dispatch(
      Actions.updateConcept(conceptId, {
        quotationStatus: QUOTATION_STATUS.SENT
      })
    );
    fetchProfileConcepts();
    onClose();
  };

  const handleCloseThankYouMessage = () => {
    setIsThankYouMessageVisible(false);
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Box display="flex" justifyContent="center" mb={2}>
            <img
              src={logo}
              alt={Constants.MINDMINER}
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </Box>

          {stakingStatus ? (
            <Typography id="modal-description" sx={{ mt: 2 }}>
              {Constants.YOU_HAVE_ALREADY_STARTED_STAKING}
            </Typography>
          ) : owner !== (user?.id ?? user?._id) ? (
            <Typography id="modal-description" sx={{ mt: 2 }}>
              Owner did not start Staking campaign yet.
            </Typography>
          ) : quotationStatus === QUOTATION_STATUS.NOT_SENT ? (
            <>
              <Typography id="modal-description" sx={{ mt: 2 }}>
                {REQUEST_QUOTATION_MODAL_TEXT}
              </Typography>
              <Box mt={4} textAlign="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleGetQuote}
                >
                  {Constants.GET_MANUFACTURER_QUOTE}
                </Button>
              </Box>
            </>
          ) : quotationStatus === QUOTATION_STATUS.RECEIVED &&
            !stakingStatus &&
            inventionStakingStatus === STAKING_STATUS.FULFILLED ? (
            <Typography sx={{ mt: 2 }}>{COMPLETE_STAKING_TEXT}</Typography>
          ) : (
            <Typography id="modal-description" sx={{ mt: 2 }}>
              {ALREDAY_REQUESTED_QUOTATION_MODAL_TEXT}
            </Typography>
          )}
        </Box>
      </Modal>

      <Snackbar
        open={isThankYouMessageVisible}
        autoHideDuration={6000}
        onClose={handleCloseThankYouMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseThankYouMessage}
          severity="success"
          sx={{ width: '100%' }}
        >
          {REQUEST_QUOTATION_ALERT_TEXT}
        </Alert>
      </Snackbar>
    </>
  );
};

export default RequestQuotationModal;
