import React from 'react';
export const TreeIcon = ({
  size = 24,
  color = '#5C30D5',
  className
}: {
  size?: number;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 9.5V0.5C6 0.229167 5.77083 0 5.5 0H0.5C0.229168 0 0 0.229167 0 0.5V9.5C0 9.77083 0.229168 10 0.5 10H5.5C5.77083 10 6 9.77083 6 9.5ZM24 5.5V0.5C24 0.229167 23.7708 0 23.5 0H18.5C18.2292 0 18 0.229167 18 0.5V5.5C18 5.77083 18.2292 6 18.5 6H23.5C23.7708 6 24 5.77083 24 5.5ZM24 14.5V9.5C24 9.22917 23.7708 9 23.5 9H18.5C18.2292 9 18 9.22917 18 9.5V14.5C18 14.7708 18.2292 15 18.5 15H23.5C23.7708 15 24 14.7708 24 14.5ZM24 23.5V18.5C24 18.2292 23.7708 18 23.5 18H18.5C18.2292 18 18 18.2292 18 18.5V23.5C18 23.7708 18.2292 24 18.5 24H23.5C23.7708 24 24 23.7708 24 23.5ZM17 2.25C17 2.10417 16.8958 2 16.75 2H16C14.6875 2 13.5625 1.54167 12.375 1.0625C11.0625 0.541667 9.6875 0 8 0H7.25C7.10416 0 7 0.104167 7 0.25V1.75C7 1.89583 7.10416 2 7.25 2H8C9.3125 2 10.4375 2.45833 11.625 2.9375C12.9375 3.45833 14.3125 4 16 4H16.75C16.8958 4 17 3.89583 17 3.75V2.25ZM17 11.25C17 11.1042 16.8958 11 16.75 11H16C15.0208 11 13.8542 9.35417 12.8125 7.91667C11.4375 6 10.0208 4 8 4H7.25C7.10416 4 7 4.10417 7 4.25V5.75C7 5.89583 7.10416 6 7.25 6H8C8.97917 6 10.1458 7.64583 11.1875 9.08333C12.5625 11 13.9792 13 16 13H16.75C16.8958 13 17 12.8958 17 12.75V11.25ZM17 20.25C17 20.1042 16.8958 20 16.75 20H16C15.125 20 13.3542 16.4583 12.3958 14.5625C10.7083 11.2083 9.25 8.27083 7.27083 8.02083C7.125 8 7 8.125 7 8.27083V9.79167C7 9.89583 7.0625 10 7.16667 10.0417C8.08333 10.3958 9.70833 13.6667 10.6042 15.4583C12.3542 18.9792 13.875 22 16 22H16.75C16.8958 22 17 21.8958 17 21.75V20.25H17Z"
        fill={color}
      />
    </svg>
  );
};

export default TreeIcon;
