import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { Box, Drawer, IconButton, LinearProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { styled, useTheme } from '@mui/material/styles';
import { CardDetail } from './CardDetail';
import { IoClose } from 'react-icons/io5';
import { Product } from 'components/CardProduct';
import { PsTheme, colorPalette } from '../../theme';
import { RiShareBoxLine } from 'react-icons/ri';
import { Node } from 'interface/common';

interface Props {
  open: boolean;
  item: Node;
  type: string;
  loading: boolean;
  onClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
  product?: Product;
  votingPanel?: React.ReactNode;
  openAddToConceptModal: () => void;
  isCurrentConceptSolution: boolean;
}

const useStyles = makeStyles()(() => {
  return {
    title: {
      fontSize: '30px',
      fontWeight: 600,
      marginBottom: 10,
      textAlign: 'center'
    },
    buttons: {
      width: 1,
      '& [aria-label="Next"]': {
        width: 48,
        marginLeft: -53
      },
      '& [aria-label="Previous"]': {
        width: 48,
        marginLeft: 5
      }
    },
    drawerHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    drawerHeaderCancel: {},
    drawerHeaderOpen: {
      padding: '0 20px',
      '& a': {
        color: colorPalette.purple,
        textDecoration: 'none'
      },
      '& a:hover': {
        textDecoration: 'underline'
      }
    },
    paper: {
      marginTop: 90,
      maxWidth: 600,
      paddingBottom: '60px',
      width: '100%'
    }
  };
});

export const EntityDrawerView: FunctionComponent<Props> = ({
  item,
  loading,
  onClose,
  open,
  type,
  votingPanel,
  openAddToConceptModal,
  isCurrentConceptSolution
}) => {
  const { classes } = useStyles();

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.paper }}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onClose={onClose}
      open={open}
      sx={{ width: '900px' }}
    >
      <TopRightBox>
        <Box>
          {item ? (
            <Link target="_blank" to={`/${type}s/${item.key}`}>
              <RiShareBoxLine size={25} color={colorPalette.purple} />
            </Link>
          ) : null}
        </Box>
        <IconContainer onClick={onClose}>
          <IoClose size={20} color={colorPalette.purple} />
        </IconContainer>
      </TopRightBox>
      {loading && <LinearProgress />}
      {item ? (
        <CardDetail
          openAddToConceptModal={openAddToConceptModal}
          item={item}
          votingPanel={votingPanel}
          isCurrentConceptSolution={isCurrentConceptSolution}
        />
      ) : null}
    </Drawer>
  );
};

const TopRightBox = styled(Box)({
  display: 'flex',
  position: 'absolute',
  marginBottom: '5px',
  alignItems: 'center',
  top: 10,
  right: 10
});

export const IconContainer = styled(IconButton)({
  border: `1px solid ${colorPalette.explosiveGrey}`,
  padding: '3px',
  marginLeft: '10px'
});
