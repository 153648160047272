import React from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { PsTheme } from '../../theme';
import IconButton from '@mui/material/IconButton';
import { FirstPage, LastPage } from '@mui/icons-material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root' | 'btn' | 'text' | 'dots';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    btn: {
      minWidth: 40,
      marginLeft: 2,
      marginRight: 2,
      '& > .MuiButton-label': {
        paddingLeft: 5,
        paddingRight: 5
      },
      '&.active': {
        background: '#ccc'
      }
    },
    text: {
      display: 'inline-block',
      width: '1.5rem',
      height: '1.5rem',
      fontSize: 18,
      lineHeight: '1.7rem',
      color: 'black'
    },
    dots: {
      marginLeft: 2,
      marginRight: 2
    }
  };
});

type PaginationProps = {
  className?: string;
  total: number;
  active: number;
  visible?: number;
  onChange?: (page: number) => void;
};

const PaginationView = ({
  className,
  active,
  total,
  visible = 5,
  onChange = (page: number) => {
    console.log(page);
  }
}: PaginationProps) => {
  const { classes } = useStyles();
  let rootClassName = classes.root;
  if (className) {
    rootClassName += ' ' + className;
  }

  if (total <= 1) {
    return null;
  }

  const isFirstDisabled = active === 0;
  const isPrevDisabled = active === 0;

  const isNextDisabled = active === total - 1;
  const isLastDisabled = active === total - 1;

  const onFirstClick = () => {
    onChange(0);
  };

  const onPrevClick = () => {
    onChange(active - 1);
  };

  const onNextClick = () => {
    onChange(active + 1);
  };

  const onLastClick = () => {
    onChange(total - 1);
  };

  const onNumberClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const indexStr = e.currentTarget.dataset['index'];
    onChange(parseInt(indexStr || '0', 10));
  };

  const pageRangeDisplayed = total > visible ? visible : total || 1;
  const rest = Math.ceil(pageRangeDisplayed / 2);
  const rightSideCount = rest + active;
  let counter = rightSideCount <= total ? rightSideCount : total;
  let index = rightSideCount <= total ? rightSideCount : total;
  if (pageRangeDisplayed < total && rightSideCount <= total) {
    if (rightSideCount - pageRangeDisplayed < 0) {
      counter = pageRangeDisplayed;
      index = 0;
    } else {
      counter = rightSideCount;
      index = rightSideCount - pageRangeDisplayed;
    }
  } else {
    counter = total;
    index = total > pageRangeDisplayed ? total - pageRangeDisplayed : 0;
  }

  const items: Array<number> = [];
  for (index; index < counter; index += 1) {
    items.push(index);
  }

  return (
    <div className={rootClassName}>
      <IconButton
        className={classes.btn}
        disabled={isFirstDisabled}
        onClick={onFirstClick}
      >
        <FirstPage />
      </IconButton>
      <IconButton
        className={classes.btn}
        disabled={isPrevDisabled}
        onClick={onPrevClick}
      >
        <KeyboardArrowLeftIcon />
      </IconButton>

      {items.map((index) => (
        <IconButton
          key={`it-${index}`}
          className={`${classes.btn} ${index === active ? 'active' : ''}`}
          data-index={index}
          onClick={onNumberClick}
        >
          <span className={classes.text}>{index + 1}</span>
        </IconButton>
      ))}

      {/*<span className={classes.dots}>...</span>*/}

      <IconButton
        className={classes.btn}
        disabled={isNextDisabled}
        onClick={onNextClick}
      >
        <KeyboardArrowRightIcon />
      </IconButton>
      <IconButton
        className={classes.btn}
        disabled={isLastDisabled}
        onClick={onLastClick}
      >
        <LastPage />
      </IconButton>
    </div>
  );
};

export const Pagination = styled(PaginationView)({});

export default Pagination;
