import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Application } from 'components/CardApplication';
import { Profile } from 'components/CardProfile';
import Step from 'components/Step';
import Stepper from 'components/Stepper';
import { PsButton } from 'components/common/PsButton';
import { PsLogo } from 'components/common/PsLogo';
import pusher from 'config/pusherConfig';
import { AuthContext } from 'contexts/AuthContext';
import DataContext from 'contexts/DataContext';
import {
  DownloadPatentSteps,
  ModalDataContext
} from 'contexts/ModalDataContext';
import { ActionSection } from 'modals/Common/ActionSection';
import { BaseModal } from 'modals/Common/BaseModal';
import { ContentSection } from 'modals/Common/ContentSection';
import { HeaderSection } from 'modals/Common/HeaderSection';
import styles from 'modals/ModalWindow.module.scss';
import Actions from 'redux-state/actions';
import { makeStyles } from 'tss-react/mui';
import { colorPalette } from 'theme';
import { Constants } from 'utilities/constants';
import { DOWNLOAD_PATENT_STEPS } from './DownloadPatentAppSteps';
import { StepComponentProps } from './steps/types';
import {
  EarnBox,
  EarnTypography,
  HeaderMainBox,
  SubtitleTypography,
  TitleTypography
} from './styles';

const steps: string[] = [Constants.ACCEPT_CONDITIONS, Constants.DOWNLOAD];

const useButtonClasses = makeStyles()(() => {
  return {
    root: {
      margin: '.5rem 0',
      fontWeight: 400
    }
  };
});

interface DownloadPatentApplicationProps {
  application: Application;
  open: boolean;
  onFileViaMindMiner?: (
    isOpenDownloadModal: boolean,
    isOpenPatentPendingModal: boolean
  ) => void;
  pagination: { page: number; perPage: number };
  profile: Profile;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const DownloadPatentAppModal: React.FC<
  DownloadPatentApplicationProps
> = ({
  application,
  open,
  onFileViaMindMiner,
  pagination,
  profile,
  setOpen
}) => {
  const { setErrors } = useContext(ModalDataContext);
  const theme = useTheme();
  const { showToast } = useContext(DataContext);
  const [prevSteps, setPrevSteps] = useState<string[]>([]);
  const [termsAgree, setTermsAgree] = useState<boolean>(false);
  const [activeStepNumber, setActiveStepNumber] = useState<number>(0);
  const [currentStep, setCurrentStep] = useState<string | undefined>(
    DownloadPatentSteps.AcceptConditionsStep
  );

  const buttonClasses = useButtonClasses();
  const dispatch = useDispatch();

  const user = profile;

  const {
    Component,
    nextStep,
    validate,
    isNextStepBlocked,
    isFinalStep,
    nextButtonTitle,
    backButtonTitle
  } = DOWNLOAD_PATENT_STEPS[currentStep || ''];

  const StepComponent = Component as React.FC<StepComponentProps>;

  const handleClickBack = () => {
    if (activeStepNumber === 0) {
      handleClose();
      return;
    }

    setActiveStepNumber((prev) => Math.max(0, prev - 1));
    setCurrentStep(prevSteps[prevSteps.length - 1]);
    setPrevSteps(prevSteps.slice(0, prevSteps.length - 1));
  };

  const handleNextStep = async () => {
    if (typeof validate === 'function') {
      const data = { termsAgree };
      const { success, errors } = validate(data);

      if (!success) {
        setErrors({ ...errors });
        return;
      }
    }

    if (isFinalStep) {
      const appId = application.id;
      const title = `${user.firstName}_${application.title.slice(
        0,
        10
      )}_${appId}.pdf`;

      dispatch(
        Actions.downloadPatentApp({
          appId,
          pagination,
          profileId: user?.id ?? user?._id,
          title
        })
      );
      handleClose();
      return;
    }

    setPrevSteps([...prevSteps, currentStep || '']);
    setErrors({});
    setActiveStepNumber((prev) => prev + 1);
    if (nextStep) {
      setCurrentStep(nextStep);
      return;
    }

    setCurrentStep(nextStep);
  };

  const handleClose = useCallback(() => {
    setOpen(false);
    setTermsAgree(false);
  }, [setOpen]);

  const handleNewPdf = (data) => {
    const message = data.message;
    showToast(message, {
      style: {
        position: 'absolute',
        bottom: 0,
        left: 77,
        backgroundColor: colorPalette.white,
        color: colorPalette.black
      },
      autoHideDuration: 5000
    });
    if (data?.error) {
      dispatch(Actions.downloadPatentAppSuccess());
    }
    if (data?.url) {
      dispatch(Actions.getProfileConcepts(user?.id ?? user?._id, pagination));
      dispatch(Actions.downloadPatentAppSuccess());
      const link = document.createElement('a');
      link.href = data.url;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  useEffect(() => {
    const patentApplicationStart = pusher.subscribe(
      `patent-pdf-${user?.id ?? user?._id}`
    );
    const patentApplicationReady = pusher.subscribe(
      `patent-pdf-${user?.id ?? user?._id}`
    );

    patentApplicationStart.bind('pdf-start', handleNewPdf);
    patentApplicationReady.bind('pdf-ready', handleNewPdf);

    return () => {
      patentApplicationReady.unbind('pdf-start');
      patentApplicationReady.unbind('pdf-ready');
      pusher.unsubscribe(`patent-pdf-${user?.id ?? user?._id}`);
    };
  }, [user?.id ?? user?._id]);

  useEffect(() => {
    if (open) {
      setPrevSteps([]);
      setActiveStepNumber(0);
      setCurrentStep(DownloadPatentSteps.AcceptConditionsStep);
    }
  }, [open]);

  const title = 'Download patent application template for';
  const subTitle = <b>{application ? application.title : ''}</b>;

  const isNextStepDisabled: boolean =
    typeof isNextStepBlocked === 'function' &&
    isNextStepBlocked({ termsAgree });

  return (
    <BaseModal open={open} onClose={handleClose}>
      <HeaderSection>
        <HeaderMainBox>
          <Box>
            <TitleTypography>{title}</TitleTypography>
            <SubtitleTypography>{subTitle}</SubtitleTypography>
          </Box>
          <EarnBox>
            <EarnTypography>{`${Constants.EARN}: `}</EarnTypography>
            <PsLogo size={23} color={theme.palette.primary.main} small />
          </EarnBox>
        </HeaderMainBox>
        <Stepper className={styles.stepper} activeStep={activeStepNumber}>
          {steps.map((step) => (
            <Step key={step}>{step}</Step>
          ))}
        </Stepper>
      </HeaderSection>
      <ContentSection>
        <StepComponent
          application={application}
          setTermsAgree={setTermsAgree}
          onFileViaMindMiner={onFileViaMindMiner}
        />
      </ContentSection>
      <ActionSection>
        <div className={`${styles.actions} actions`}>
          <PsButton
            disabled={isNextStepDisabled}
            onClick={handleNextStep}
            fullWidth
            classes={buttonClasses}
          >
            {nextButtonTitle || Constants.ACCEPT_AND_CONTINUE}
          </PsButton>
          <PsButton
            onClick={handleClickBack}
            fullWidth
            cancel
            classes={buttonClasses}
          >
            {backButtonTitle || Constants.BACK}
          </PsButton>
        </div>
      </ActionSection>
    </BaseModal>
  );
};

export default DownloadPatentAppModal;
