import * as React from 'react';
import styles from './Stepper.module.scss';

export interface StepperProps {
  activeStep: number;
  children: React.ReactElement[] | React.ReactElement;
  className: string;
}

const Stepper: React.FC<StepperProps> = ({
  activeStep = 0,
  children,
  className
}) => {
  const childrenArray = React.Children.toArray(children);

  const steps = childrenArray.map((step, index) => {
    const state = {
      index,
      active: false,
      completed: false
    };

    if (activeStep === index) {
      state.active = true;
    } else if (activeStep > index) {
      state.completed = true;
    }

    if (React.isValidElement(step)) {
      return React.cloneElement(step, {
        last: index + 1 === childrenArray.length,
        ...state,
        ...step.props
      });
    }

    return step;
  });
  let rootClassName = styles.container;
  if (className) {
    rootClassName += ' ' + className;
  }
  return <div className={rootClassName}>{steps}</div>;
};

export default Stepper;
