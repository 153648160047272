import React, { useCallback, useContext, useEffect, useState } from 'react';
import { styled, Theme, useTheme } from '@mui/material/styles';

import { PsButton } from 'components/common/PsButton';
import {
  Card,
  CardImage,
  CardBody,
  CardTitle,
  CardOwner,
  CardText,
  CardActions
} from 'components/card';
import { Tags } from 'components/Tag';
import { ShareBtn } from 'components/ShareBtn';
import { OwnerInfo } from 'components/CardProfile';
import { TagInfo } from 'components/CardTag';
import { Constants } from 'utilities/constants';
import ImproveModal from 'modals/ImproveModal';
import { PsRecord, PayProduct } from '../../dataPrvider';
import { AuthContext } from 'contexts/AuthContext';
import { ModalContext } from 'contexts/ModalContext';
import { GetUser } from 'redux-state/selectors';
import { VisibilityStatus } from './VisibilityStatus';
import { Picture, placeholderType } from '../../helpers';
import { PsTheme } from '../../theme';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root' | 'tags' | 'actionButton';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {},
    tags: {
      '& > div': {
        marginTop: 4,
        marginBottom: 4
      }
    },
    actionButton: {
      marginRight: 10
    }
  };
});

export type Application = PsRecord & {
  key: string;
  title?: string;
  teaser?: string;
  body?: string;
  owner?: string | number;
  ownerInfo?: OwnerInfo;
  files?: Array<Picture>;
  createdAt?: string;
  problem: string;
  tags?: string[];
  tagsInfo?: Array<TagInfo>;
  fillingPatentReceipt?: string;
  fillingPatentNumber?: string;
  fillingTime?: string;
  selected: Array<string | number>;
  isPublic: boolean;
  finalizeType?: 'own' | 'share' | 'open';
  paymentType?: 'docusign' | 'coinbase' | 'credits';
  inPayment?: boolean;
  isFinalized?: boolean;
  isPaid?: boolean;
  isShared?: boolean;
  isExclusivityPaid?: boolean;
  isExclusivityExpired?: boolean;
  isDownloaded?: boolean;
  isLocked?: boolean;
  products?: Array<PayProduct>;
  likes: number;
  dislikes: number;
  isFiled?: boolean;
  nftPrice?: string;
  nftTokenId?: string;
  nftTransactionUrl?: string;
  nftTransactionUrl2?: string;
  name?: string;
  reward?: number;
  termsAgree?: boolean;
  parentApplication?: string | number;
  parentApplicationKey?: string;
  parentApplicationTitle?: string;
  parentApplicationOwner?: string | number;
  parentApplicationOwnerKey?: string;
  parentApplicationOwnerName?: string;
  parentProduct?: string | number;
  parentProductKey?: string;
  parentProductTitle?: string;
  parentProductOwner?: string | number;
  parentProductOwnerKey?: string;
  parentProductOwnerName?: string;
  parentPriorArt?: string | number;
  parentPriorArtKey?: string;
  parentPriorArtTitle?: string;
};

export type ApplicationCardProps = {
  application: Application;
};

const CardApplicationView = ({ application }: ApplicationCardProps) => {
  const {
    id,
    key,
    title,
    teaser,
    body,
    owner,
    ownerInfo,
    createdAt,
    files,
    isFiled,
    finalizeType,
    tags = [],
    tagsInfo = [],
    likes,
    dislikes,
    isPublic = false
  } = application;
  const { classes } = useStyles();
  const user = GetUser();
  const { openInventionImprove } = useContext(ModalContext);
  const [isImproveModalOpen, setIsImproveModalOpen] = useState<boolean>(false);
  const [invemtionTitle, setInventionTitle] = useState<string>('');
  const [inventionId, setInventionId] = useState<string>('');
  const [inventionKey, setInventionKey] = useState<string>('');
  const [modalType, setModalType] = useState<string>('');

  const image = files && files.length ? files[0] : undefined;
  const url = `/inventions/${key || id}`;

  const onImproveClick = useCallback(() => {
    setIsImproveModalOpen(true);
    setInventionTitle(teaser || title);
    setInventionId(id.toString());
    setInventionKey(key);
    setModalType(Constants.Invention);
  }, [id, key, teaser, title]);

  return (
    <Card>
      <CardImage href={url} image={image} type={placeholderType.A} />
      <CardBody>
        <CardTitle
          title={teaser || title}
          href={url}
          beforeTitle={<VisibilityStatus application={application} />}
        >
          <Tags className={classes.tags} tagsList={tagsInfo} />
        </CardTitle>
        <CardOwner
          ownerName={ownerInfo && ownerInfo.username}
          ownerKey={ownerInfo && ownerInfo.key}
          owner={owner}
          createdAt={createdAt}
        />
        <CardText>{body}</CardText>
        <CardActions
          resource="applications"
          resourceId={id}
          likes={likes}
          dislikes={dislikes}
          user={user}
        >
          <>
            <PsButton
              className={classes.actionButton}
              onClick={onImproveClick}
              coins="up to 30"
            >
              Improve
            </PsButton>
            <ShareBtn application={application} tagsList={tagsInfo} />
          </>
        </CardActions>
      </CardBody>
      {isImproveModalOpen && (
        <ImproveModal
          isImproveModalOpen={isImproveModalOpen}
          modalType={modalType}
          productId={inventionId}
          productKey={inventionKey}
          setIsImproveModalOpen={setIsImproveModalOpen}
          shortTitle={invemtionTitle}
          title={invemtionTitle}
        />
      )}
    </Card>
  );
};

export const CardApplication = styled(CardApplicationView)({});

export default CardApplication;
