import { Box, Typography, styled } from '@mui/material';
import { colorPalette } from 'theme';

export const ImageContainer = styled(Box)({
  position: 'relative',
  width: '100%'
});

export const ImageBox = styled('img')({
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  borderTopLeftRadius: '0.625rem',
  borderTopRightRadius: '0.625rem',
  height: '11.7rem',
  objectFit: 'cover',
  width: '100%'
});

export const IconsBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  position: 'absolute',
  right: '1.875rem',
  top: '1.5rem'
});

export const StyledEditCursor = styled(Box)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    alignItems: 'center',
    background: colorPalette.lightPurple,
    borderRadius: '1.25rem',
    cursor: 'pointer',
    display: 'flex',
    height: isMediumScreen ? '1.9375rem' : '2.5rem',
    justifyContent: 'center',
    width: isMediumScreen ? '1.9375rem' : '2.5rem'
  })
);

export const StyledShareCursor = styled(Box)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    alignItems: 'center',
    background: colorPalette.lightPurple,
    borderRadius: '1.25rem',
    cursor: 'pointer',
    display: 'flex',
    height: isMediumScreen ? '1.9375rem' : '2.5rem',
    justifyContent: 'center',
    width: isMediumScreen ? '1.9375rem' : '2.5rem'
  })
);

export const EarningsContainer = styled(Box)({
  bottom: '1.5625rem',
  color: colorPalette.white,
  margin: '0rem',
  marginBottom: '0.5rem',
  position: 'absolute',
  right: '1rem'
});

export const StyledBlock = styled(Box)({
  background: 'rgba(233, 232, 226, 0.9)',
  borderRadius: 40,
  color: 'black',
  gap: '0rem',
  height: '2.8125rem',
  opacity: '0rem',
  padding: '0.4375rem 0.75rem',
  top: '6.6875rem'
});

export const StyledEarningContent = styled(Box)({
  alignItems: 'center',
  display: 'flex'
});

export const MainBox = styled(Box)({
  flexWrap: 'wrap',
  justifyContent: 'center',
  marginTop: '0.625rem'
});

export const CustomEarningsWrapper = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row'
});

export const EarningItem = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  marginRight: '0.9375rem',
  '&:last-child': {
    marginRight: 0
  },
  '& img': {
    width: '1.875rem',
    height: '1.875rem'
  },
  minWidth: 0
});

export const EarningText = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '0.3125rem',
  minWidth: 0
});

export const EarningNumber = styled(Typography)({
  fontFamily: 'Montserrat',
  fontSize: '1rem',
  fontWeight: 700,
  lineHeight: '0.9944rem',
  textAlign: 'left'
});

export const EarningLabel = styled(Typography)({
  fontSize: '0.625rem',
  lineHeight: '0.75rem',
  whiteSpace: 'nowrap'
});
