import React from 'react';
import { BaseModal } from 'modals/Common/BaseModal';
import { ContentSection } from '../Common/ContentSection';
import { HeaderSection } from '../Common/HeaderSection';
import { Breakpoints, Constants, VARIANT } from 'utilities/constants';
import { ButtonBox, StyledButton, StyledText, Title } from './StyledComponents';

interface FinalizeConceptModalProps {
  approveImprovements?: () => void;
  approveMintToken?: () => void;
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FinalizeConceptModal: React.FC<FinalizeConceptModalProps> = ({
  open,
  approveImprovements,
  approveMintToken,
  setOpen
}) => {
  const handleMint = () => {
    approveMintToken();
    setOpen(false);
  };

  const handleImprove = () => {
    approveImprovements();
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    <BaseModal
      open={open}
      onClose={handleCloseModal}
      maxWidth={Breakpoints.EXTRA_SMALL}
    >
      <HeaderSection>
        <Title>{Constants.FINALIZE_CONCEPT_TEXT}</Title>
      </HeaderSection>
      <ContentSection>
        <StyledText>{Constants.IMPROVE_YOUR_CONCEPT_TEXT}</StyledText>
        <ButtonBox>
          <StyledButton
            variant={VARIANT.CONTAINED}
            onClick={() => handleImprove()}
          >
            {Constants.IMPROVE_BUTTON_TEXT}
          </StyledButton>
          <StyledButton variant={VARIANT.OUTLINED} onClick={handleMint}>
            {Constants.MINT_PATENT_BUTTON_TEXT}
          </StyledButton>
        </ButtonBox>
      </ContentSection>
    </BaseModal>
  );
};
