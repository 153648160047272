import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { CiLock } from 'react-icons/ci';
import { GrCopy } from 'react-icons/gr';
import { useDispatch } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Badge,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { EditButton } from 'components/common/PsButton';
import Vote from 'components/common/Vote';
import { LinkView } from 'components/LinkView';
import { CustomScrollbar } from 'components/PerfectScrollbar';
import { ShareBtnSimple } from 'components/ShareBtn';
import AuthContext from 'contexts/AuthContext';
import { findNode } from 'helpers';
import { useFetchGraph } from 'hooks/useFetchGraph';
import useRouter from 'hooks/useRouter';
import { SideMenu } from 'layout';
import { SeeAllContestsModal } from 'modals/SeeAllContestsModal';
import moment from 'moment';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Actions from 'redux-state/actions';
import { GetToggleConceptCart } from 'redux-state/ideamap/selectors';
import {
  GetLoaderLeaderboard,
  GetSolutionsWithOwnerInfo
} from 'redux-state/selectors';
import { colorPalette, useIsMediumScreen } from 'theme';
import { Constants, REACTION_TYPES } from 'utilities/constants';
import First from '../newContests/badgeFirst';
import Second from '../newContests/badgeSecond';
import Third from '../newContests/badgeThird';
import { ContestModal } from '../newContests/ContestModal';
import { CountdownMonths } from '../newContests/CountdownMonths';
import { DescriptionModal } from '../newContests/DescriptionModal';
import Frame1 from '../newContests/Frame1';
import Frame2 from '../newContests/Frame2';
import HexagonIcon from '../newContests/HexagonIcon';
import Leaf from '../newContests/leaf';
import {
  BannerBox,
  BannerTypography,
  ContestOwnerAvatar,
  IdeaMapGridItem,
  InfoGridItem,
  JackpotGrid,
  JackpotGridItem,
  LockedBoxGrid,
  MainGridContainer,
  ProgressBarBox,
  SecondaryGridContainer,
  StyledAddedTypography,
  StyledBannerTypography,
  StyledCiCircleInfo,
  StyledCoinsTypo,
  StyledContest,
  StyledContestAvatar,
  StyledContestDetail,
  StyledFrameBox,
  StyledGridContainer,
  StyledGridInnerContainer,
  StyledGridJackpot,
  StyledGridTitle,
  StyledHoverBox,
  StyledIconButtonDupe,
  StyledIconButtonEdit,
  StyledIconButtonShare,
  StyledIdeacoinsTypo,
  StyledIdeaMap,
  StyledItemName,
  StyledJackpotTypo,
  StyledLeaderboard,
  StyledLeaderboardTypo,
  StyledLeaderboardTypo2,
  StyledLockedBox,
  StyledLockedTypo,
  StyledMomentTypo,
  StyledNftBox,
  StyledNftTypo,
  StyledOwnerName,
  StyledRemainaingTime,
  StyledTableRow,
  StyledTimeBox1,
  StyledTimeBox2,
  StyledTitleContest,
  StyledTyp1,
  StyledTypoTitle,
  StyledUnlockTypo
} from '../newContests/StyledComponents';
import { toastify } from '../newContests/toastify';
import NewIdeaMap from '../newIdeaMap';
import { CircularBox } from '../newIdeaMap/StyledComponents';
import TopSection from 'components/TopSection';
import { StyledBoxFlex } from 'components/common/StyledComponents';

const ContestDetailsView = () => {
  const router = useRouter();
  const location = useLocation();
  const { key } = router.query;
  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);
  const isMediumScreen = useIsMediumScreen();
  const [descriptionModal, setDescriptionModal] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [requiredNFT, setRequiredNFT] = useState<number>();
  const queryParams = queryString.parse(location.search);
  const participate = queryParams.participate === Constants.TRUE;
  const [timeDescription, setTimeDescription] = useState<string>(
    Constants.CONTEST_REMAINING_TIME
  );
  const [selectedContest, setSelectedContest] = useState(null);
  const { graphData, fetchChildrenGraph } = useFetchGraph({
    type: Constants.CONTESTS,
    id: key as string,
    mapType: Constants.CONTEST
  }) as any;
  const leaderboardData = GetSolutionsWithOwnerInfo();
  const loader = GetLoaderLeaderboard();
  const [openSeeAllContestsModal, setOpenSeeAllContestsModal] =
    useState<boolean>(false);

  const solutionCount = useMemo(() => {
    return (
      graphData?.children?.filter(
        (child) => child.ref === Constants.C_APPLICATION
      ).length || 0
    );
  }, [graphData]);

  const updateRequiredNFT = useCallback(
    (graphData) => {
      const nftCount = graphData.bountyNft - solutionCount;
      setRequiredNFT(nftCount >= 0 ? nftCount : 0);
    },
    [solutionCount]
  );

  const processGraphData = useCallback(() => {
    try {
      dispatch(Actions.getSolutionsWithOwnerInfo(key as string));
      updateRequiredNFT(graphData);
    } catch (error) {
      console.error(Constants.ERROR_PROCESSING_GRAPH_DATA, error);
    }
  }, [dispatch, graphData, key, updateRequiredNFT]);

  useEffect(() => {
    if (participate) {
      toastify(
        Constants.SOLVE_PROBLEM,
        Constants.INFO,
        Constants.TOP_RIGHT,
        2000
      );
    }
  }, []);

  useEffect(() => {
    if (!leaderboardData?.data?.length) {
      processGraphData();
    }
  }, [dispatch, leaderboardData?.data?.length, processGraphData]);

  const canEdit = user && (user.id === graphData?.owner?._id || user.isAdmin);
  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);
  const isGraphDataExists: boolean =
    graphData && Object.keys(graphData).length ? true : false;
  const isConceptDrawerOpen = GetToggleConceptCart();

  const progress: number = isGraphDataExists
    ? Math.max(0, Math.min(100, (solutionCount - graphData.bountyNft) * 100))
    : 0;

  useEffect(() => {
    processGraphData();
  }, [graphData, processGraphData]);

  const onSeeAllContestsClick = useCallback(() => {
    setOpenSeeAllContestsModal(true);
  }, []);

  const toggleDescriptionModal = () => {
    setDescriptionModal(!descriptionModal);
  };

  const toggleEditOrDupeModal = (contest, isDuplicate) => {
    if (editModalOpen) {
      setEditModalOpen(false);
      setSelectedContest(null);
    } else {
      setIsDuplicate(isDuplicate);
      setSelectedContest(contest);
    }
    setEditModalOpen(!editModalOpen);
  };

  const LeaderBoardContent = () => {
    return (
      <TableContainer>
        <ToastContainer />
        <Table>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: colorPalette.ashWhite,
                height: '2.8125rem'
              }}
            >
              <TableCell>
                <StyledTableRow>Participant</StyledTableRow>
              </TableCell>
              <TableCell sx={{ textAlign: 'center' }}>
                <StyledTableRow>Solutions</StyledTableRow>
              </TableCell>
              <TableCell sx={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
                <StyledTableRow>Total Rating</StyledTableRow>
              </TableCell>
            </TableRow>
          </TableHead>
          {loader ? (
            <TableCell colSpan={3}>
              <LinearProgress />
            </TableCell>
          ) : (
            <TableBody>
              {leaderboardData?.data?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ width: '50%', textAlign: 'start' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar
                        sx={{
                          width: 40,
                          height: 40
                        }}
                        src={item?.image}
                      />
                      <Badge
                        badgeContent={
                          index === 0 ? (
                            <First />
                          ) : index === 1 ? (
                            <Second />
                          ) : index === 2 ? (
                            <Third />
                          ) : null
                        }
                        sx={{
                          position: 'relative',
                          top: -13,
                          right: 3
                        }}
                      />
                      <StyledItemName sx={{ marginLeft: '1rem' }}>
                        <LinkView
                          id={item.key}
                          title={item.ownerName}
                          type="profiles"
                        />
                      </StyledItemName>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>
                    <LinkView
                      currentTab="Solutions"
                      id={item.key}
                      title={item.count}
                      type="profiles"
                    />
                  </TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>
                    <Vote
                      likeCount={item.totalLikes}
                      dislikeCount={item?.totalDislikes}
                      disabledButtons={true}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    );
  };

  return (
    <>
      <SideMenu active="contest" />
      {isGraphDataExists ? (
        <StyledGridContainer isConceptDrawerOpen={isConceptDrawerOpen}>
          <TopSection title={graphData?.title} type={graphData?.type} />
          {graphData.status === Constants.PASSED && (
            <Grid container>
              <Grid item lg={isConceptDrawerOpen ? 12 : 8}>
                <BannerBox>
                  <BannerTypography>
                    {Constants.END_CONTEST_COMMON}
                    {canEdit ? (
                      <>
                        {Constants.END_CONTEST_START}
                        <StyledBannerTypography
                          onClick={() => toggleEditOrDupeModal(graphData, true)}
                        >
                          {Constants.END_CONTEST_DUPLICATE}
                        </StyledBannerTypography>
                        {Constants.END_CONTEST_OR}
                        <StyledBannerTypography
                          onClick={() =>
                            toggleEditOrDupeModal(graphData, false)
                          }
                        >
                          {Constants.END_CONTEST_EDIT_IT}
                        </StyledBannerTypography>
                        {Constants.FULL_STOP}
                      </>
                    ) : (
                      <>
                        {Constants.END_CONTEST_MIDDLE}
                        <StyledBannerTypography onClick={onSeeAllContestsClick}>
                          {Constants.END_CONTEST_CLICK_HERE}
                        </StyledBannerTypography>
                        {Constants.FULL_STOP}
                      </>
                    )}
                  </BannerTypography>
                </BannerBox>
              </Grid>
            </Grid>
          )}
          <Box
            sx={{
              opacity: graphData.status === Constants.PASSED ? 0.4 : 1,
              pointerEvents:
                graphData.status === Constants.PASSED
                  ? Constants.NONE
                  : Constants.ALL
            }}
          >
            <Grid
              container
              sx={{ marginTop: isConceptDrawerOpen ? '4.375rem' : 0 }}
            >
              <StyledGridInnerContainer item lg={isConceptDrawerOpen ? 9 : 6}>
                {isConceptDrawerOpen && (
                  <StyledTimeBox1>
                    <StyledTyp1>{timeDescription}</StyledTyp1>
                    <CountdownMonths
                      startTime={graphData.startDate}
                      endTime={graphData.endDate}
                      setTimeDescription={setTimeDescription}
                    />
                  </StyledTimeBox1>
                )}
                <StyledTitleContest>
                  <StyledContestAvatar src={graphData?.files[0]?.url} />
                  <StyledGridTitle>
                    <StyledBoxFlex>
                      <StyledBoxFlex>
                        <StyledContest>Contest:</StyledContest>
                        <StyledTypoTitle>{graphData.title}</StyledTypoTitle>
                      </StyledBoxFlex>
                      <Box sx={{ minWidth: '4.6875rem', margin: '0 0 0.5rem' }}>
                        {canEdit && graphData.status !== Constants.ACTIVE && (
                          <>
                            <StyledIconButtonDupe>
                              <IconButton
                                onClick={() =>
                                  toggleEditOrDupeModal(graphData, true)
                                }
                              >
                                <GrCopy size={20} color={colorPalette.purple} />
                              </IconButton>
                            </StyledIconButtonDupe>
                            <StyledIconButtonEdit>
                              <EditButton
                                style={{
                                  margin: '0.625rem 0.25rem 0.625rem 0.625rem',
                                  background: colorPalette.transparent
                                }}
                                onClick={() =>
                                  toggleEditOrDupeModal(graphData, false)
                                }
                              ></EditButton>
                            </StyledIconButtonEdit>
                          </>
                        )}
                        <StyledIconButtonShare>
                          <ShareBtnSimple tag={graphData.tag?._id} />
                        </StyledIconButtonShare>
                      </Box>
                    </StyledBoxFlex>
                    <MainGridContainer container>
                      <Grid item sx={{ display: 'flex' }}>
                        <StyledAddedTypography>Added</StyledAddedTypography>
                        <StyledMomentTypo>
                          {moment(graphData.updatedAt).format(
                            Constants.TIME_FORMAT
                          )}
                        </StyledMomentTypo>
                      </Grid>
                      <Grid item sx={{ margin: '0px 10px' }}>
                        |
                      </Grid>
                      <Grid item sx={{ marginRight: '10px' }}>
                        <StyledMomentTypo>Community Leader</StyledMomentTypo>
                      </Grid>
                      <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                        <ContestOwnerAvatar
                          src={graphData.owner?.files[0]?.url}
                        />
                        <StyledOwnerName>
                          {graphData?.owner?.username}
                        </StyledOwnerName>
                      </Grid>
                    </MainGridContainer>
                    <CustomScrollbar>
                      <StyledContestDetail>
                        {graphData.body}
                      </StyledContestDetail>
                    </CustomScrollbar>
                  </StyledGridTitle>
                </StyledTitleContest>
              </StyledGridInnerContainer>
              <JackpotGrid item lg={isConceptDrawerOpen ? 3 : 2}>
                <StyledGridJackpot container>
                  <StyledFrameBox>
                    <Frame1 />
                  </StyledFrameBox>
                  <StyledFrameBox sx={{ bottom: -5, right: 1 }}>
                    <Frame2 />
                  </StyledFrameBox>
                  <StyledHoverBox>
                    {progress >= 100
                      ? Constants.CONTEST_MODAL_JACKPOT_INFO
                      : `The Jackpot is currently locked; ${requiredNFT} NFTs are needed to unlock it.`}
                  </StyledHoverBox>
                  <LockedBoxGrid item sm={2}>
                    <StyledLockedBox>
                      {progress < 100 && (
                        <>
                          <CiLock size={12} color={colorPalette.purple} />
                          <StyledLockedTypo>Locked</StyledLockedTypo>
                        </>
                      )}
                    </StyledLockedBox>
                  </LockedBoxGrid>
                  <JackpotGridItem item sm={8}>
                    <StyledJackpotTypo>Jackpot</StyledJackpotTypo>
                    <StyledCoinsTypo>
                      <Leaf text={graphData.bountyCoins} />
                    </StyledCoinsTypo>
                    <StyledIdeacoinsTypo>Ideacoins</StyledIdeacoinsTypo>
                  </JackpotGridItem>
                  <InfoGridItem item lg={2}>
                    <StyledCiCircleInfo
                      className="info-icon"
                      color={colorPalette.white}
                      onClick={toggleDescriptionModal}
                      size={20}
                    />
                  </InfoGridItem>
                </StyledGridJackpot>
                <DescriptionModal
                  onClose={toggleDescriptionModal}
                  open={descriptionModal}
                />
              </JackpotGrid>
              {!isConceptDrawerOpen && (
                <StyledGridInnerContainer item lg={4}>
                  <StyledRemainaingTime>
                    <StyledTimeBox2>
                      <StyledTyp1>{timeDescription}</StyledTyp1>
                      <CountdownMonths
                        startTime={graphData.startDate}
                        endTime={graphData.endDate}
                        setTimeDescription={setTimeDescription}
                      />
                    </StyledTimeBox2>
                    <StyledNftBox>
                      <StyledBoxFlex>
                        <StyledNftTypo>{requiredNFT} NFTs</StyledNftTypo>
                        <Box sx={{ px: '0.1875rem' }} />
                        <StyledUnlockTypo>
                          required to unlock jackpot
                        </StyledUnlockTypo>
                      </StyledBoxFlex>
                      <ProgressBarBox>
                        <Box className="progressLoader">
                          <HexagonIcon left={progress} number={solutionCount} />
                        </Box>
                        <LinearProgress
                          variant="determinate"
                          value={progress}
                          sx={{ width: '100%', height: '0.5rem' }}
                        />
                        <HexagonIcon number={graphData.bountyNft} />
                      </ProgressBarBox>
                    </StyledNftBox>
                  </StyledRemainaingTime>
                </StyledGridInnerContainer>
              )}
            </Grid>
            <SecondaryGridContainer container>
              <IdeaMapGridItem item lg={isConceptDrawerOpen ? 12 : 8}>
                <StyledIdeaMap sx={{ marginTop: '0' }}>
                  <NewIdeaMap
                    id={key as string}
                    type={Constants.CONTESTS}
                    topSection={false}
                    renderParent={false}
                    graphData={graphData}
                    fetchChildrenGraph={fetchChildrenGraph}
                  />
                </StyledIdeaMap>
              </IdeaMapGridItem>
              {!isConceptDrawerOpen &&
                (!isMediumScreen ? (
                  <Grid item lg={4} sx={{ padding: '0.5rem' }}>
                    <StyledLeaderboard>
                      <StyledLeaderboardTypo>
                        {Constants.LEADER_BOARD}
                      </StyledLeaderboardTypo>
                      <LeaderBoardContent />
                    </StyledLeaderboard>
                  </Grid>
                ) : (
                  <Accordion sx={{ margin: '0.5rem' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      sx={{ margin: 0 }}
                    >
                      <StyledLeaderboardTypo2>
                        {Constants.LEADER_BOARD}
                      </StyledLeaderboardTypo2>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <LeaderBoardContent />
                    </AccordionDetails>
                  </Accordion>
                ))}
            </SecondaryGridContainer>
          </Box>
        </StyledGridContainer>
      ) : (
        <CircularBox>
          <CircularProgress />
        </CircularBox>
      )}
      {selectedContest && (
        <ContestModal
          open={editModalOpen}
          onClose={toggleEditOrDupeModal}
          tag={graphData.tag}
          contest={selectedContest}
          isDuplicate={isDuplicate}
        />
      )}
      {openSeeAllContestsModal && (
        <SeeAllContestsModal
          open={openSeeAllContestsModal}
          setOpen={setOpenSeeAllContestsModal}
          toggleContestModal={toggleEditOrDupeModal}
          tag={graphData.tag}
          showCreateButton={false}
        />
      )}
    </>
  );
};

export default ContestDetailsView;
