/* eslint-disable */
import { Activity } from 'components/CardFeed';
import { Problem } from 'components/CardProblem';
import { Profile } from 'components/CardProfile';
import { Solution } from 'components/CardSolution';
import { Tag } from 'components/CardTag';
import { convertUrlToBase64 } from 'helpers';
import {
  CallEffect,
  PutEffect,
  call,
  put,
  takeLatest
} from 'redux-saga/effects';
import { getContests } from 'redux-state/contestView/actions';
import { getProfileConcepts } from 'redux-state/profile/actions';
import { Constants, ERRORS, VOTE_CRITERIA } from 'utilities/constants';
import * as HomeActions from '../home/actions';
import * as ProfileActions from '../profile/actions';
import * as Actions from './actions';
import { Api } from './api';
import {
  AiAnswerSearchPayload,
  CreateProfilePayload,
  DownloadPatentAppPayload,
  editTagPayload,
  getEmployeesPayload,
  getEmployeesResponseType,
  getRelatedItemsPayload,
  getRelatedItemsResponse,
  getTagUsersPayload,
  getTagUsersResponseType,
  unlinkTagPayload
} from './interface';
import {
  AI_ANSWER_SEARCH,
  CREATE_EMPLOYEE_PROFILE,
  DOWNLOAD_PATENT_APP,
  EDIT_TAG,
  GET_AI_IMAGE,
  GET_AI_TITLE,
  GET_EMPLOYEES,
  GET_FEED,
  GET_FOLLOWING_TAGS_COUNT,
  GET_OWNED_COMMUNITY,
  GET_PROBLEM,
  GET_PRODUCT,
  GET_RELATED_COMPANIES,
  GET_RELATED_ITEMS,
  GET_RELATION_PROMPT,
  GET_TAG_FOLLOWERS,
  GET_TAG_USERS,
  GET_TAG,
  UNLINK_TAG,
  VOTE_ITEM,
  CALCULATE_MULTIPLIER_PTS
} from './types';

function* aiAnswerSearch(action: {
  type: string;
  payload: AiAnswerSearchPayload;
}): Generator<CallEffect | PutEffect, void, { text: string }> {
  try {
    const response: { text: string } = yield call(
      Api.aiAnswerSearch,
      action.payload
    );
    if (response) {
      yield put(Actions.aiAnswerSearchSuccess(response));
    }
  } catch (error) {
    console.log('Error in Ai Answer Search', error);
    yield put(Actions.updateErrorStatus(400, error.message));
  }
}

function* createEmployeeProfile(action: {
  type: string;
  payload: CreateProfilePayload;
}): Generator<CallEffect | PutEffect, void, Profile> {
  const { tagId } = action.payload;
  try {
    const response: Profile = yield call(
      Api.createEmployeeProfile,
      action.payload
    );
    if (response.data) {
      yield put(Actions.createEmployeeProfileSuccess());
      yield put(Actions.getEmployees({ tagId: tagId }));
      yield put(Actions.updateErrorStatus(200, ''));
      yield put(Actions.getTagUsers({ tagId, filterType: Constants.ALL }));
    } else {
      yield put(Actions.updateErrorStatus(500, ERRORS.UNEXPECTED_ERROR));
    }
  } catch (error) {
    yield put(Actions.updateErrorStatus(409, error.message));
  }
}

function* downloadPatentApp(action: {
  type: string;
  payload: DownloadPatentAppPayload;
}): Generator<CallEffect | PutEffect, void, Profile> {
  try {
    const { profileId, pagination } = action.payload;
    yield call(Api.downloadPatentApp, action.payload);
    yield put(getProfileConcepts(profileId, pagination));
  } catch (error) {
    yield put(Actions.downloadPatentAppSuccess());
    const errorMessage = error.message;
    console.log('Error in downloading patent applicaion.', errorMessage);
  }
}

function* getEmployees(action: {
  type: string;
  payload: getEmployeesPayload;
}): Generator<CallEffect | PutEffect, void, getEmployeesResponseType> {
  try {
    const response: getEmployeesResponseType = yield call(
      Api.getEmployees,
      action.payload
    );
    if (response.data) {
      yield put(Actions.getEmployeesSuccess(response));
    }
  } catch (error) {
    yield put(Actions.getEmployeesSuccess({ data: [], total: 0 }));
    const errorMessage = error.message;
    console.log('Error in creating profile: ', errorMessage);
  }
}

function* getTagUsers(action: {
  type: string;
  payload: getTagUsersPayload;
}): Generator<CallEffect | PutEffect, void, getTagUsersResponseType> {
  try {
    const response: getTagUsersResponseType = yield call(
      Api.getTagUsers,
      action.payload
    );
    if (response.data) {
      yield put(Actions.getTagUsersSuccess(response));
    }
  } catch (error) {
    const errorMessage = error.message;
    yield put(Actions.getTagUsersSuccess({ data: [], total: 0 }));
    console.log('Error in creating profile: ', errorMessage);
  }
}

function* getRelatedCompanies(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const response: any = yield call(Api.getRelatedCompanies, action.payload);
    if (response) {
      yield put(Actions.getRelatedCompaniesSuccess(response));
      yield put(Actions.setRelatedCompanies(response));
    }
  } catch (error) {
    yield put(Actions.getRelatedCompaniesSuccess({ data: [] }));
    const errorMessage = error.message;
    console.log('Error in getting  company products: ', errorMessage);
  }
}

function* getRelatedItems(action: {
  type: string;
  payload: getRelatedItemsPayload;
}): Generator<CallEffect | PutEffect, void, getRelatedItemsResponse> {
  const { item } = action.payload;
  try {
    const response: getRelatedItemsResponse = yield call(
      Api.getRelatedItems,
      action.payload
    );
    if (response) {
      yield put(Actions.getRelatedItemsSuccess({ item, ...response }));
    }
  } catch (error) {
    yield put(
      Actions.getRelatedItemsSuccess({ item: '', items: [], count: 0 })
    );
    const errorMessage = error.message;
    console.log('Error getting related Item: ', errorMessage);
  }
}

function* getOwnedCommunity(action: {
  type: string;
  payload: { userId: string };
}) {
  try {
    const response = yield call(Api.getOwnedCommunity, action.payload);
    yield put(Actions.getTagsByUserIdSuccess(response));
  } catch (error) {
    console.log('Error', error);
  }
}

function* editTag(action: {
  type: string;
  payload: editTagPayload;
}): Generator<CallEffect | PutEffect, void, { data: Tag }> {
  try {
    const response: { data: Tag } = yield call(Api.editTag, action.payload);
    if (response) {
      yield put(Actions.getTag(response.data.key, action.payload.pagination));
    }
    if (action.payload.userId) {
      yield put(Actions.getOwnedCommunity({ userId: action.payload.userId }));
    }
  } catch (error) {
    console.log('error', error);
  }
}

function* getAiTitle(action: {
  type: string;
  payload: {
    conceptId: string | number;
  };
}): Generator<CallEffect | PutEffect, void, Activity> {
  try {
    const response: Activity = yield call(Api.generateAiTitle, {
      conceptId: action.payload.conceptId
    });
    if (response) {
      yield put(Actions.getAiTitleSuccess({ title: response.data.title }));
    }
  } catch (error) {
    yield put(Actions.getAiTitleSuccess({ title: '' }));
    console.log('error', error);
  }
}

function* getRelationPrompt(action: {
  type: string;
  payload: {
    conceptTitle: string;
    productTitle: string;
    firstSolutionTitle: string;
    secondSolutionTitle: string;
    remainingSolutions: Array<Solution>;
    problemSolvedBySolution: Array<Problem>;
  };
}): Generator<CallEffect | PutEffect, void, string> {
  try {
    const response = yield call(Api.getRelationPrompt, action.payload);
    if (response) {
      yield put(Actions.getRelationPromptSuccess(response));
    }
  } catch (error) {
    yield put(Actions.getRelationPromptSuccess(''));
    console.log('error', error);
  }
}

function* getAiImage(action: {
  type: string;
  payload: {
    query: string;
  };
}): Generator<CallEffect | PutEffect, void, any> {
  try {
    const response = yield call(Api.generateAiImage, action.payload);
    if (response) {
      const base64 = yield call(convertUrlToBase64, response.url);
      const aiPicture = {
        contentType: 'image/jpeg',
        title: `Ai Image`,
        url: base64
      };
      yield put(Actions.getAiImageSuccess(aiPicture));
    }
  } catch (error) {
    console.log('Error in Generating Ai Image', error);
  }
}

function* getFeed(action: {
  type: string;
  payload: {
    pagination: { page: number; perPage: number };
    key?: string | null;
    feedType?: string;
    filters?: Array<string> | null;
    sortType?: string | null;
  };
}): Generator<CallEffect | PutEffect, void, Activity> {
  try {
    const response: Activity = yield call(Api.getFeed, action.payload);
    yield put(Actions.getFeedSuccess(response, action.payload.feedType));
  } catch (error) {
    console.log('error', error);
  }
}

function* getTag(action: {
  type: string;
  payload: { key: string; pagination: { page: number; perPage: number } };
}): Generator<CallEffect | PutEffect, void, Tag> {
  try {
    const response: Tag = yield call(Api.getTag, action.payload);
    yield put(Actions.getTagSuccess(response));
  } catch (error) {
    yield put(
      Actions.getTagSuccess({
        id: '',
        key: ''
      })
    );
    console.log('error', error);
  }
}

function* unlinkTag(action: {
  type: string;
  payload: unlinkTagPayload;
}): Generator<CallEffect | PutEffect, void, boolean> {
  try {
    const { type, itemId, tagKey, pagination } = action.payload;
    const response: boolean = yield call(Api.unlinkTag, type, itemId, tagKey);
    if (response) {
      yield put(
        Actions.getRelatedItems({
          resource: Constants.TAGS,
          key: tagKey as string,
          item: type,
          pagination
        })
      );
    }
  } catch (error) {
    console.log('error', error);
  }
}

function* getTagFollowers(action: {
  actionType?: string;
  filterType?: string;
  followers?: string | number;
  itemType?: string;
  key?: string | string[];
  pagination?: { page: number; perPage: number };
  searchFilter?: string;
  tagId: string | number;
  type: string;
  sort?: { field: string; order: string };
  userKey?: string | null;
}): Generator<CallEffect | PutEffect, void, any> {
  try {
    const {
      tagId,
      followers,
      actionType,
      key,
      itemType,
      pagination,
      filterType,
      searchFilter,
      sort
    } = action;
    const response = yield call(
      Api.getTagFollowers,
      tagId,
      followers,
      actionType
    );
    yield put(
      HomeActions.getTags(
        {
          key: key,
          item: itemType,
          pagination
        },
        filterType,
        searchFilter,
        sort,
        false
      )
    );
    yield put(ProfileActions.getProfileMutualTags(true));
    if (action.userKey) {
      yield put(Actions.getFollowingTagsCount(action.userKey));
    }
  } catch (error) {
    console.log('error', error);
  }
}

function* voteItem(action) {
  try {
    const { itemType, itemId, voteType, voteCriteria, data } = action.payload;
    const response = yield call(
      Api.voteItem,
      itemType,
      itemId,
      voteType,
      voteCriteria
    );
    const { communityTagId, filters, pagination, tagId, tagType, sortType } =
      data;
    if (response && voteCriteria !== VOTE_CRITERIA.IDEAMAP) {
      yield put(
        itemType == Constants.CONTESTS
          ? getContests({ tagId, pagination })
          : Actions.getFeed(
              pagination,
              communityTagId,
              tagType,
              filters,
              sortType
            )
      );
    }
  } catch (error) {
    console.log('error', error);
  }
}

function* getFollowingTagsCount(action: {
  type: string;
  payload: {
    userKey: string | number;
    onlyCount: boolean;
  };
}): Generator<CallEffect | PutEffect, void, number> {
  try {
    const response = yield call(Api.getFollowingTagsCount, action.payload);
    if (response) {
      yield put(Actions.getFollowingTagsCountSuccess(response));
    }
  } catch (error) {
    console.log('error', error);
  }
}

function* getProduct(action) {
  try {
    const response = yield call(Api.getProduct, action.payload);
    if (response) {
      yield put(Actions.getProductSuccess(response));
    } else {
      yield put(Actions.getProductFailure());
    }
  } catch (error) {
    console.log(ERRORS.PRODUCT_ERROR, error);
  }
}

function* getProblem(action) {
  try {
    const response = yield call(Api.getProblem, action.payload);
    if (response) {
      yield put(Actions.getProblemSuccess(response));
    } else {
      yield put(Actions.getProblemFailure());
    }
  } catch (error) {
    console.log(ERRORS.PROBLEM_ERROR, error);
    console.log('product error', error);
  }
}

function* calculateMultiplierIdeaPoints(action) {
  try {
    const { defaultIdeaPoints, likes, dislikes } = action.payload;
    const response = yield call(
      Api.calculateMultiplierIdeaPoints,
      defaultIdeaPoints,
      likes,
      dislikes
    );
    if (Number.isInteger(response?.ideaPoints)) {
      yield put(Actions.calculateMultiplierIdeaPointsSuccess(response));
    } else {
      yield put(Actions.calculateMultiplierIdeaPointsFailure());
    }
  } catch (error) {}
}

function* mySaga() {
  yield takeLatest(AI_ANSWER_SEARCH, aiAnswerSearch);
  yield takeLatest(CREATE_EMPLOYEE_PROFILE, createEmployeeProfile);
  yield takeLatest(DOWNLOAD_PATENT_APP, downloadPatentApp);
  yield takeLatest(EDIT_TAG, editTag);
  yield takeLatest(GET_AI_IMAGE, getAiImage);
  yield takeLatest(GET_AI_TITLE, getAiTitle);
  yield takeLatest(GET_EMPLOYEES, getEmployees);
  yield takeLatest(GET_FEED, getFeed);
  yield takeLatest(GET_FOLLOWING_TAGS_COUNT, getFollowingTagsCount);
  yield takeLatest(GET_RELATED_COMPANIES, getRelatedCompanies);
  yield takeLatest(GET_RELATED_ITEMS, getRelatedItems);
  yield takeLatest(GET_TAG_FOLLOWERS, getTagFollowers);
  yield takeLatest(GET_TAG_USERS, getTagUsers);
  yield takeLatest(GET_TAG, getTag);
  yield takeLatest(GET_OWNED_COMMUNITY, getOwnedCommunity);
  yield takeLatest(UNLINK_TAG, unlinkTag);
  yield takeLatest(VOTE_ITEM, voteItem);
  yield takeLatest(GET_PRODUCT, getProduct);
  yield takeLatest(GET_PROBLEM, getProblem);
  yield takeLatest(GET_RELATION_PROMPT, getRelationPrompt);
  yield takeLatest(CALCULATE_MULTIPLIER_PTS, calculateMultiplierIdeaPoints);
}

export default mySaga;
