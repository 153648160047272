import React from 'react';
import { FunctionComponent } from 'react';
import classnames from 'classnames';
import { styled, useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { PsTheme } from '../../theme';

type CardProps = {
  classes?: any;
  children?: any;
  className?: string;
};

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      background: '#FFFFFF',
      //border: '1px solid #E6E6E6',
      //boxShadow: '0px 3px 6px #0000001A',
      //borderRadius: '31px',
      padding: '8px',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    }
  };
});

const CardView: FunctionComponent<CardProps> = ({
  className = '',
  children
}) => {
  const { classes } = useStyles();
  return (
    <div
      className={classnames(classes.root, {
        [className]: className
      })}
    >
      {children}
    </div>
  );
};

export const Card = styled(CardView)({});
