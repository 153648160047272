import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Link,
  Select,
  Skeleton,
  styled,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import { ArrowOutward, Close, ContentCopy } from '@mui/icons-material';
import { colorPalette } from 'theme';

export const HeaderBox = styled(Box)({
  alignItems: 'center',
  backgroundColor: colorPalette.purple,
  display: 'flex',
  justifyContent: 'center'
});

export const StyledArrowOutward = styled(ArrowOutward)({
  color: colorPalette.softRed,
  height: '1.125rem',
  width: '1.125rem'
});

export const StyledArrowInwards = styled(ArrowOutward)({
  color: colorPalette.greenHaze,
  height: '1.125rem',
  rotate: '180deg',
  width: '1.125rem'
});

export const AddressViewWrapper = styled(Box)({
  alignSelf: 'center',
  marginLeft: 'auto',
  marginRight: 'auto'
});

export const MainInfoBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: '.75rem',
  justifyContent: 'center'
});

export const ProgressWrapper = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  margin: '.9375rem 0px'
});

export const AddressContainer = styled(Box)({
  alignItems: 'center',
  backgroundColor: `${colorPalette.white}12`,
  borderRadius: '1.5625rem',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  margin: '.625rem 1.25rem',
  padding: '.5rem'
});

export const AmountBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
});

export const StyledTypography = styled(Typography)({
  fontSize: '.775rem'
});

export const EquivalentBalance = styled(Typography)({
  fontSize: '.9375rem',
  fontWeight: '400'
});

export const IconText = styled(Typography)({
  fontSize: '.625rem',
  fontWeight: '400',
  marginTop: '.125rem'
});

export const AddressText = styled(Typography)({
  color: colorPalette.white,
  fontSize: '.875rem',
  fontWeight: '400'
});

export const CopyButton = styled(IconButton)({
  color: colorPalette.white,
  marginLeft: '.5rem',
  padding: 0
});

export const StyledCard = styled(Card)({
  cursor: 'pointer',
  height: '11.875rem',
  maxWidth: '15.625rem'
});

export const StyledSelect = styled(Select)<{ bgColor?: string }>(
  ({ bgColor }) => ({
    backgroundColor: bgColor ?? `${colorPalette.gray}12`,
    borderRadius: '50px',
    color: `${colorPalette.black}95`,
    fontWeight: '550',
    height: '2.5rem',
    paddingLeft: '.3125rem',
    '& .MuiSelect-icon': {
      right: '.625rem'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    },
    '&:hover': {
      backgroundColor: colorPalette.ashWhite
    }
  })
);

export const StyledCardContent = styled(CardContent)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '.5rem .625rem'
});

export const StyledCloseButton = styled(IconButton)({
  backgroundColor: `${colorPalette.white}12`,
  marginLeft: 'auto',
  marginRight: '1.25rem',
  textAlign: 'left'
});

export const RefreshIcon = styled(IconButton)({
  color: colorPalette.purple,
  position: 'absolute',
  top: '.1875rem',
  right: '1.25rem'
});

export const StyledCopyIcon = styled(ContentCopy)({
  color: colorPalette.white,
  height: '.875rem',
  padding: 0,
  width: '.875rem'
});

export const StyledCloseIcon = styled(Close)({
  color: colorPalette.white,
  height: '1.125rem',
  width: '1.125rem'
});

export const StyledCardMedia = styled(CardMedia)({
  height: '9.375rem',
  objectFit: 'cover',
  width: '15.625rem'
});

export const MainBalanceText = styled(Typography)({
  color: colorPalette.black,
  fontSize: '2rem',
  fontWeight: '500',
  lineHeight: 1,
  '@media (max-width: 900px)': {
    fontSize: '1.75rem'
  }
});

export const CardNameText = styled(Typography)({
  fontWeight: '600',
  fontSize: '.875rem',
  '@media (max-width:56.25rem)': {
    fontSize: '.75rem'
  }
});

export const NoTransactionText = styled(Typography)({
  fontSize: '.875rem',
  fontWeight: '500',
  marginTop: '.625rem'
});

export const CardTokenText = styled(Typography)({
  fontWeight: '400',
  fontSize: '.75rem',
  color: colorPalette.purple,
  '@media (max-width:56.25rem)': {
    fontSize: '.625rem'
  }
});

export const ButtonBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  gap: '1.125rem',
  justifyContent: 'center'
});

export const LogoWrapper = styled(Box)({
  marginLeft: '.9375rem'
});

export const StyledIconButton = styled(IconButton)({
  alignItems: 'center',
  borderRadius: '50%',
  boxShadow: '0 0px 6px rgba(0, 0, 0, 0.12)',
  color: colorPalette.purple,
  display: 'flex',
  flexDirection: 'column',
  height: '2rem',
  justifyContent: 'center',
  padding: '.5rem',
  width: '32px',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.1)'
  }
});

export const StyledMainBox = styled(Box)({
  position: 'relative',
  backgroundColor: 'white',
  height: 'auto',
  width: '100%'
});

export const MainContent = styled(Box)({
  borderBottom: `1px solid ${colorPalette.lightGrey}`,
  borderTop: `1px solid ${colorPalette.lightGrey}`,
  padding: '1.5625rem'
});

export const NotFoundText = styled(Typography)({
  fontSize: '14',
  fontWeight: '500',
  marginTop: '.625rem'
});

export const StyledSkeleton = styled(Skeleton)<{
  width?: string;
  height?: string;
}>(({ width, height }) => ({
  height: height,
  width: width
}));

export const StyledBox = styled(Box)({
  backgroundColor: 'white',
  height: 'auto',
  padding: '.9375rem',
  width: '100%'
});

export const ButtonsWrapper = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column'
});

export const FromBox = styled(Box)({
  display: 'flex',
  alignItems: 'center'
});

export const StyledTabs = styled(Tabs)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    flexDirection: isMediumScreen ? 'row' : 'column',
    width: isMediumScreen ? '100%' : '20%',
    '& .MuiTabs-flexContainer': {
      flexDirection: isMediumScreen ? 'row' : 'column'
    }
  })
);

export const TabsMainBox = styled(Box)<{ isMediumScreen?: boolean }>(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '55vh',
  overflow: 'hidden'
}));

export const StyledTab = styled(Tab)<{ isMediumScreen: boolean }>(
  ({ theme, isMediumScreen }) => ({
    alignItems: 'center',
    backgroundColor: colorPalette.brightGray,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: isMediumScreen ? 'center' : 'flex-start',
    minHeight: '5.625rem !important',
    paddingLeft: isMediumScreen ? 'none' : '3.4375rem',
    position: 'relative',
    textTransform: 'none',
    '& .tab-label': {
      fontSize: '1rem',
      marginLeft: '.5rem',
      color: theme.palette.text.secondary
    },
    '&.Mui-selected': {
      backgroundColor: 'white',
      '& .tab-label': {
        fontWeight: 'bold',
        color: colorPalette.darkPurple
      }
    }
  })
);

export const StyledTabContent = styled(Box)({
  alignItems: 'center',
  display: 'flex'
});

export const SelectWrapper = styled(Box)({
  position: 'absolute',
  right: '1.5625rem',
  top: '90px'
});

export const StyledGrid = styled(Grid)({
  justifyContent: 'left',
  marginTop: '.3125rem',
  paddingRight: '.5rem',
  '@media (max-width: 900px)': {
    justifyContent: 'center'
  }
});

export const StyledLink = styled(Link)({
  color: colorPalette.black,
  display: '-webkit-box',
  fontSize: '.775rem',
  overflow: 'hidden',
  textDecoration: 'none',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: '1',
  whiteSpace: 'pre-wrap'
});

export const StyledTabLabel = styled(Typography)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    fontSize: isMediumScreen ? '.7963rem' : '1rem',
    marginTop: isMediumScreen ? '-1.5625rem' : '.125rem'
  })
);

export const StyledTabIcon = styled(Avatar)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    color: 'inherit',
    height: isMediumScreen ? '1.1875rem' : '1.875rem',
    marginRight: '.4375rem',
    marginTop: isMediumScreen ? '-1.5625rem' : 'none',
    width: isMediumScreen ? '1.1875rem' : '1.875rem'
  })
);
