import React, { useContext, useState, useCallback, useEffect } from 'react';
import { ModalDataContext } from 'contexts/ModalDataContext';
import { DataContext } from 'contexts/DataContext';
import { Problem } from 'components/CardProblem';
import { PsFormControl, ShortEvent } from 'components/common/PsFormControl';
import { PsInput } from 'components/common/PsInput';
import { PsSelect, PsOptionType } from 'components/common/PsSelect';
import styles from 'modals/ModalWindow.module.scss';
import Typography from '@mui/material/Typography';

export const defaultProblemTitle = 'The problem of ';

const ProblemName: React.FC = () => {
  const { values, errors, updateField, handleInputChange } =
    useContext(ModalDataContext);
  const { dataProvider } = useContext(DataContext);
  const [title, setTitle] = useState(values.titleProblem);
  const [parentProblem, setParentProblem] = useState(values.rootProblem);
  const [problems, setProblems] = useState<Array<PsOptionType>>([]);

  let helperText =
    'Example: The problem of the buildup of fluid energy to prevent time travel';
  if (errors && errors.titleProblem) {
    helperText = errors.titleProblem;
  }

  useEffect(() => {
    if (!values.titleProblem) {
      updateField('titleProblem', defaultProblemTitle);
      setTitle(defaultProblemTitle);
    }
    dataProvider
      .getList<Problem>('problems', {
        pagination: { page: 1, perPage: 50000 },
        sort: { field: 'title', order: 'ASC' },
        filter: { $custom: { type: 'unlimit' } }
      })
      .then(({ data = [] }) => {
        const list: Array<PsOptionType> = data.map((item: Problem) => ({
          value: `${item.id}`,
          label: item.title || '(empty)'
        }));
        setProblems(list);
      })
      .catch(() => {
        setProblems([]);
      });
  }, []);

  const onProblemNameChange = useCallback(
    (e: ShortEvent) => {
      const { value = '' } = e.target;
      const rawVal = value.slice(defaultProblemTitle.length);
      const finalTitle = `${defaultProblemTitle}${rawVal}`;
      setTitle(finalTitle);
      updateField('titleProblem', finalTitle);
    },
    [setTitle, updateField]
  );

  const onParentProblemChange = useCallback(
    (e: ShortEvent) => {
      handleInputChange(e);
      setParentProblem(e.target.value);
    },
    [setParentProblem, handleInputChange]
  );

  const titleLabel = values.titleProblemLabel || "What's your problem?";

  return (
    <div className={styles.stepWrapper}>
      {values.introProblem ? (
        <Typography className={styles.text}>{values.introProblem}</Typography>
      ) : null}

      <PsFormControl
        placeholder="The problem of "
        label={titleLabel}
        // labelInfo={true}
        helperText={helperText}
        error={!!errors.titleProblem}
      >
        <PsInput
          name="titleProblem"
          value={title}
          onChange={onProblemNameChange}
        />
      </PsFormControl>

      <div className={values.rootProblemHide ? styles.hideFields : ''}>
        <PsFormControl
          placeholder="Optional: Choose a parent problem"
          label="Parent problem"
        >
          <PsSelect
            maxMenuHeight={270}
            name="rootProblem"
            onChange={onParentProblemChange}
            options={problems}
            value={parentProblem}
          />
        </PsFormControl>
      </div>
    </div>
  );
};

export default ProblemName;
