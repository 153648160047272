import React from 'react';
import { Box, Typography } from '@mui/material';
import logo from 'assets/images/logo.svg';
import Helmet from 'components/Helmet';
import { Constants, HEADINGS } from 'utilities/constants';
import { Logo, Root } from './StyledComponents';

const NotFoundPage = () => {
  return (
    <Root>
      <Helmet title={Constants.ERROR} />
      <Logo src={logo} alt={Constants.MINDMINER} />
      <Typography variant={HEADINGS.H3}>{Constants.PAGE_NOT_FOUND}</Typography>
    </Root>
  );
};

export default NotFoundPage;
