import React, { useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import useRouter from 'hooks/useRouter';

import { AuthGuardLink } from 'components/AuthGuardLink';
import { AuthContext } from 'contexts/AuthContext';
import { navLinks } from '../helpers';
import { PsTheme } from '../theme';

type ClassKey = 'root';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      width: '24.3%',
      flexShrink: 0,
      paddingRight: 30,
      fontSize: 18,
      fontWeight: 600,
      color: '#000',
      '& ul': {
        position: 'sticky',
        top: 100,
        listStyle: 'none',
        margin: 0,
        padding: 0
      },
      '& li': {
        // marginBottom: 10,
      },
      '& a': {
        width: '100%',
        display: 'inline-flex',
        alignItems: 'center',
        padding: '16px 20px',
        textDecoration: 'none',
        color: 'inherit',
        borderRadius: 12,
        transition: 'color 0.3s ease'
      },
      '& a:hover': {
        color: psTheme.palette.primary.main
      },
      '& a.active': {
        background: '#F6F4FB'
      },
      '& a > svg': {
        marginRight: 20
      },
      '& a > span': {},
      [psTheme.breakpoints.down('sm')]: {
        display: 'none'
      }
    }
  };
});

type NavBarProps = {
  className?: string;
};

const NavBarView = ({ className }: NavBarProps) => {
  const { user } = useContext(AuthContext);
  const router = useRouter();
  const { classes } = useStyles();

  let search = '';
  if (typeof window !== 'undefined') {
    search = window.location.search;
  }

  return (
    <div className={`${classes.root} ${className} navBar`}>
      <ul>
        {navLinks.map((item) => {
          let match = router.pathname === item.link;
          if (
            item.link !== '/' &&
            item.link !== '/activity' &&
            router.pathname.startsWith(item.link)
          ) {
            match = true;
          }

          if (item.link === '/activity' && !user) {
            return null;
          }

          return (
            <li key={item.link}>
              <AuthGuardLink
                href={`${item.link}${
                  item.link !== '/' && item.link !== '/activity' ? search : ''
                }`}
                authRestricted={item.authRestricted}
              >
                <a className={match ? 'active' : ''}>
                  {item.icon}
                  <span>{item.title}</span>
                </a>
              </AuthGuardLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export const NavBar = styled(NavBarView)({});

export default NavBar;
