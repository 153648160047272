import React, { FunctionComponent, useMemo } from 'react';
import { CSSObject } from '@emotion/serialize';
import { styled, useTheme, alpha } from '@mui/material/styles';

import SelectSearch, {
  components,
  StylesConfig,
  OptionProps,
  DropdownIndicatorProps,
  Options,
  ControlProps,
  GroupBase,
  CSSObjectWithLabel,
  ValueContainerProps
} from 'react-select';
import AsyncSelect from 'react-select/async';
import { ShortEvent } from 'components/common/PsFormControl';
import { PsTheme } from '../../theme';
import { makeStyles } from 'tss-react/mui';

export type PsGroup<T> = GroupBase<T>;

declare module 'react-select/dist/declarations/src/Select' {
  export interface Props<
    Option,
    IsMulti extends boolean,
    Group extends GroupBase<Option>
  > {
    control: string;
    controlOpened: string;
    controlHasValue: string;
    controlDisabled: string;
    option: string;
    optionSelected: string;
    optionFocused: string;
    dropdownIndicator: string;
    dropdownIndicatorOpened: string;
  }
}

type Classes = {
  [key: string]: any;
};

type GetCustomComponentsProps = {
  checkboxClasses: Classes;
};

type CheckBoxIconProps = {
  classes?: Classes;
  isFocused?: boolean;
  isSelected?: boolean;
};

const CheckBoxIcon = ({
  classes = {},
  isFocused,
  isSelected
}: CheckBoxIconProps) => {
  let className = classes.optionIcon;
  if (isSelected) {
    className += ' ' + classes.optionIconSelected;
  }
  if (isFocused) {
    className += ' ' + classes.optionIconFocused;
  }
  return (
    <svg className={className} fill="none" width="24" height="24">
      <rect
        x="0.5"
        y="0.5"
        width="23"
        height="23"
        rx="3.5"
        fill="transparent"
        fillOpacity="0.4"
        stroke="currentColor"
      />
      <g transform="translate(5 6)">
        <path
          d="M13 1.5L4.75 9.75L1 6"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export type PsOptionType = {
  label: string;
  value: string;
  raw?: string;
};

const customStyles: StylesConfig<PsOptionType, true> = {
  valueContainer: (
    provided: CSSObjectWithLabel,
    props: ValueContainerProps<PsOptionType, true>
  ) => {
    return {
      ...provided,
      padding: 0,
      minHeight: props.selectProps.isSearchable ? 52 : 46
    };
  },
  singleValue: (provided: CSSObject) => ({
    ...provided,
    marginLeft: 10,
    marginRight: 10
  }),
  input: (provided: CSSObject) => ({
    ...provided,
    margin: '0 0 0 10px',
    paddingTop: 0,
    paddingBottom: 0
  }),
  placeholder: (provided: CSSObject) => ({
    ...provided,
    maxWidth: '100%',
    marginLeft: 0,
    marginRight: 0,
    paddingLeft: 11,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }),
  option: () => {
    return {};
  },
  noOptionsMessage: (provided: CSSObject) => ({
    ...provided
  }),
  indicatorsContainer: (provided: CSSObject) => {
    return {
      ...provided,
      width: 50
    };
  },
  indicatorSeparator: () => {
    return {
      display: 'none'
    };
  },
  menu: (provided: CSSObject) => ({
    ...provided,
    marginTop: 0,
    borderRadius: '0 0 10px 10px',
    border: 'solid 1px rgba(3, 31, 70, 0.5)',
    borderTopWidth: 0,
    boxShadow:
      '0px 4px 8px rgba(10, 0, 32, 0.05), 0px 4px 84px rgba(7, 0, 21, 0.05)',
    zIndex: 5
  }),
  menuList: (provided: CSSObject) => ({
    ...provided,
    paddingTop: 10,
    paddingBottom: 10
  }),
  dropdownIndicator: () => {
    return {};
  }
};

const getCustomComponents = ({
  checkboxClasses
}: GetCustomComponentsProps) => ({
  Control: (props: ControlProps<PsOptionType, true>) => {
    const { menuIsOpen } = props.selectProps;
    const classes = props.selectProps;
    let className = props.className + ' ' + classes.control;
    if (menuIsOpen) {
      className += ' ' + classes.controlOpened;
    }
    if (props.hasValue && !menuIsOpen) {
      className += ' ' + classes.controlHasValue;
    }
    if (props.isDisabled && !menuIsOpen) {
      className += ' ' + classes.controlDisabled;
    }
    return <components.Control {...props} className={className} />;
  },
  Option: (props: OptionProps<PsOptionType, true>) => {
    const classes = props.selectProps;
    let className = props.className + ' ' + classes.option;
    if (props.isSelected) {
      className += ' ' + classes.optionSelected;
    }
    if (props.isFocused) {
      className += ' ' + classes.optionFocused;
    }
    return (
      <div {...props.innerProps} className={className + 'option'}>
        <CheckBoxIcon
          classes={checkboxClasses}
          isFocused={props.isFocused}
          isSelected={props.isSelected}
        />
        <span className="option_label">{props.label}</span>
      </div>
    );
  },
  DropdownIndicator: (props: DropdownIndicatorProps<PsOptionType, true>) => {
    const { menuIsOpen } = props.selectProps;
    const classes = props.selectProps;
    let className = props.className + ' ' + classes.dropdownIndicator;
    if (menuIsOpen) {
      className += ' ' + classes.dropdownIndicatorOpened;
    }
    return (
      <components.DropdownIndicator {...props} className={className}>
        <svg
          width="30px"
          height="30px"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="0" fill="none" width="24" height="24" />
          <g>
            <path d="M7 10l5 5 5-5" fill="#451f9a" />
          </g>
        </svg>
      </components.DropdownIndicator>
    );
  }
});

type ClassKey =
  | 'root'
  | 'control'
  | 'controlOpened'
  | 'controlDisabled'
  | 'controlHasValue'
  | 'option'
  | 'optionSelected'
  | 'optionFocused'
  | 'optionIcon'
  | 'optionIconSelected'
  | 'optionIconFocused'
  | 'dropdownIndicator'
  | 'dropdownIndicatorOpened';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      marginTop: 10,
      fontFamily: psTheme.typography.fontFamily,
      fontSize: 15
    },
    control: {
      display: 'flex',
      borderRadius: 10,
      boxShadow: 'none',
      border: `solid 1px ${alpha(psTheme.palette.third.main, 0.5)}`,
      background: 'none',
      outline: 'none',
      cursor: 'pointer',
      fontFamily: psTheme.typography.fontFamily,
      '& input': {
        fontFamily: psTheme.typography.fontFamily
      }
    },
    controlOpened: {
      borderRadius: '10px 10px 0 0'
    },
    controlHasValue: {
      border: `solid 1px ${alpha(psTheme.palette.primary.main, 1)}`
    },
    controlDisabled: {
      background: '#f7f7f7',
      border: `solid 1px ${alpha(psTheme.palette.third.main, 0.2)}`,
      '& svg.dropdown-icon': {
        color: alpha(psTheme.palette.third.main, 0.5)
      }
    },
    option: {
      display: 'flex',
      alignItems: 'center',
      boxSizing: 'border-box',
      padding: '9px 19px',
      fontSize: '16px',
      lineHeight: '18px',
      minHeight: 42,
      cursor: 'pointer',
      backgroundColor: 'transparent',
      color: psTheme.palette.third.main,
      userSelect: 'none',
      '-webkit-tap-highlight-color': 'rgba(0, 0, 0, 0)',
      '& > svg': {
        flexShrink: 0,
        marginRight: 12
      },
      '& > span': {
        paddingTop: 2,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    },
    optionSelected: {
      color: psTheme.palette.secondary.main
    },
    optionFocused: {
      color: psTheme.palette.secondary.main
    },
    optionIcon: {
      overflow: 'visible',
      marginRight: '.45rem',
      '& > g': {
        display: 'none'
      }
    },
    optionIconSelected: {
      color: psTheme.palette.secondary.main,
      '& > g': {
        display: 'block'
      }
    },
    optionIconFocused: {
      color: psTheme.palette.secondary.main
    },
    dropdownIndicator: {
      '& svg': {
        fill: 'currentColor',
        width: '1em',
        height: '1em',
        display: 'inline-block',
        fontSize: '32px',
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        userSelect: 'none',
        flexShrink: 0,
        top: 'calc(50% - 16px)',
        right: 13,
        color: psTheme.palette.primary.main,
        position: 'absolute',
        pointerEvents: 'none',
        transform: 'rotate(0)'
      }
    },
    dropdownIndicatorOpened: {
      '& svg': {
        transform: 'rotate(180deg)'
      }
    }
  };
});

type PsSelectProps = {
  className?: string;
  options?: Array<PsOptionType>;
  value?: string;
  name?: string;
  placeholder?: string;
  isMulti?: true | undefined;
  isSearch?: boolean;
  maxMenuHeight?: number;
  disabled?: boolean;
  onChange?: (event: ShortEvent) => void;
  onInputChange?: (query: string) => void;
  separator?: string;
  dataLoader?: (
    query: string,
    callback: (
      options: readonly (PsOptionType | PsGroup<PsOptionType>)[]
    ) => void
  ) => Promise<readonly (PsOptionType | PsGroup<PsOptionType>)[]>;
  valueObj?: PsOptionType | Array<PsOptionType> | undefined;
};

const PsSelectView: FunctionComponent<PsSelectProps> = (
  props: PsSelectProps
) => {
  const stringToValue: any = (
    valueStr: string | undefined,
    options: Array<PsOptionType> = [],
    isMulti: boolean | undefined
  ): PsOptionType | Array<PsOptionType> => {
    const res: Array<PsOptionType> = [];
    if (!valueStr) {
      return res;
    }
    let findStrs = [valueStr];
    if (isMulti) {
      findStrs = valueStr.split(props.separator || ',');
    }
    findStrs.forEach((str) => {
      const obj = options.find((i) => i.value === str);
      if (obj) {
        res.push(obj);
      }
    });
    return isMulti ? res : res[0];
  };

  const valueToString = (
    value: PsOptionType | Options<PsOptionType>,
    key: keyof PsOptionType = 'value'
  ): string => {
    const isOptions = isOptionArray();

    let valueStr = '';
    if (value) {
      if (isOptions(value)) {
        valueStr = value
          .map((item: { [x: string]: any }) => item[key])
          .join(props.separator || ',');
      } else {
        valueStr = value[key] || '';
      }
    }
    return valueStr;
  };

  const onchange = (value: Options<PsOptionType>) => {
    if (props.onChange) {
      const valueStr = valueToString(value);
      props.onChange({
        target: {
          value: valueStr,
          name: props.name,
          label: valueToString(value, 'label'),
          rawValue: valueToString(value, 'raw')
        }
      });
    }
  };

  const onInputChange = (newValue: string) => {
    if (props.onInputChange) {
      props.onInputChange(newValue);
    }
  };

  const isOptionArray = () => {
    return (x: any): x is Options<PsOptionType> => {
      return Array.isArray(x);
    };
  };

  let defaultOptions: Array<PsOptionType> = [];
  if (props.dataLoader && props.value) {
    defaultOptions = [
      {
        value: props.value,
        label: props.value
      }
    ];
  }

  const {
    value,
    onChange,
    isMulti,
    disabled,
    maxMenuHeight = 487,
    options,
    dataLoader,
    className,
    ...rest
  } = props;
  const { classes } = useStyles();

  let rootClassName = classes.root;
  if (className) {
    rootClassName += ' ' + className;
  }

  const { customComponents } = useMemo(
    () => ({
      customComponents: getCustomComponents({ checkboxClasses: classes })
    }),
    [classes]
  );

  if (dataLoader) {
    return (
      <div className={rootClassName}>
        <AsyncSelect
          control={''}
          dropdownIndicator={''}
          option={''}
          controlOpened={''}
          controlDisabled={''}
          defaultInputValue={value}
          controlHasValue={''}
          optionSelected={''}
          optionFocused={''}
          dropdownIndicatorOpened={''}
          {...rest}
          value={props.valueObj}
          isDisabled={disabled}
          isMulti={isMulti}
          controlShouldRenderValue={!isMulti}
          onChange={onchange}
          isClearable={false}
          styles={customStyles}
          components={customComponents}
          hideSelectedOptions={false}
          maxMenuHeight={maxMenuHeight}
          loadOptions={dataLoader}
          defaultOptions={defaultOptions}
          onInputChange={onInputChange} // menuIsOpen
          closeMenuOnSelect={false}
        />
      </div>
    );
  }

  return (
    <div className={rootClassName}>
      <SelectSearch
        control={''}
        dropdownIndicator={''}
        option={''}
        controlOpened={''}
        controlDisabled={''}
        controlHasValue={''}
        optionSelected={''}
        optionFocused={''}
        dropdownIndicatorOpened={''}
        {...rest}
        value={props.valueObj}
        options={options}
        isDisabled={disabled}
        isMulti={isMulti}
        controlShouldRenderValue={!isMulti}
        onChange={onchange}
        isClearable={false}
        styles={customStyles}
        components={customComponents}
        hideSelectedOptions={false}
        maxMenuHeight={maxMenuHeight}
        // selectProps={classes}
        onInputChange={onInputChange}
        // menuIsOpen
        closeMenuOnSelect={false}
      />
    </div>
  );
};

export const PsSelect = styled(PsSelectView)({});

export default PsSelect;
