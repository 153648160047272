import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useDispatch } from 'react-redux';
import { Box, LinearProgress, styled, Typography } from '@mui/material';
import SolutionIcon from 'assets/icons/solutions.svg';
import AuthContext from 'contexts/AuthContext';
import { ModalComponentsKeys } from 'contexts/ModalContext';
import useRouter from 'hooks/useRouter';
import Actions from 'redux-state/actions';
import {
  GetLoader,
  GetRelatedSolutions,
  GetRelatedSolutionsCount
} from 'redux-state/commons/selectors';
import { Constants } from 'utilities/constants';
import { Problem } from '../CardProblem';
import { Profile } from '../CardProfile';
import { Solution } from '../CardSolution';
import { CustomMenu } from '../CustomMenu';
import { GetActions } from '../CustomMenu/actions';
import DataNotFound from '../DataNotFound';
import { ImageView } from '../ImageView';
import { ListItemView } from '../ListItemView';
import TableView from '../TableView';
import { getHeaders } from '../TableView/getHeaders';

interface ItemListProps {
  profile?: Profile;
  paginationOptions?: {
    page: number;
    rowsPerPage: number;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  };
}

const StyledBox = styled(Box)({
  backgroundColor: 'white',
  height: 'auto',
  padding: '15px',
  width: '100%'
});

interface ListItemViewProps {
  expandedId: string;
  id: string;
  problems: Array<Problem>;
  setExpandedId: React.Dispatch<React.SetStateAction<boolean>>;
}

const SolutionView: React.FC<ItemListProps> = ({
  profile,
  paginationOptions
}) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { key } = router.query;

  const solutions: Array<Solution> = GetRelatedSolutions();
  const solutionsCount: number = GetRelatedSolutionsCount();
  const solutionLoader = GetLoader();

  const { user } = useContext(AuthContext);
  const [expandedId, setExpandedId] = useState();

  const { page, rowsPerPage, setPage, setRowsPerPage } = paginationOptions;
  const pagination = useMemo(
    () => ({
      page: page,
      perPage: rowsPerPage
    }),
    [page, rowsPerPage]
  );

  const isCurrentUser = !!(user && (user.id === profile?.id || user?.isAdmin));
  const hasEmptyData = solutions.length == 0;

  useEffect(() => {
    dispatch(
      Actions.getRelatedItems({
        resource: 'tags',
        key: key as string,
        item: Constants.SOLUTIONS,
        pagination
      })
    );
  }, [dispatch, key, pagination]);

  useEffect(() => {
    if (solutions.length < 1) {
      setPage(0);
    }
  }, [setPage, solutions]);

  const Item = useCallback(
    ({ problems, id, expandedId, setExpandedId }: ListItemViewProps) => {
      return (
        <ListItemView
          expandedId={expandedId}
          id={id}
          problems={problems}
          setExpandedId={setExpandedId}
          type="problems"
        />
      );
    },
    []
  );

  const headers: Array<string> = useMemo(() => {
    return getHeaders(Constants.COMMUNITY_SOLUTION, user, isCurrentUser);
  }, [isCurrentUser, user]);

  const getActions = useMemo(() => {
    return (item: Solution) => {
      return GetActions({
        item,
        profile,
        type: 'Community',
        pagination
      });
    };
  }, [pagination, profile]);

  const getCustomItem = (item) => {
    return Array.isArray(item.problem) ? item.problem : [item.problem];
  };

  const getCells = useMemo(() => {
    return (icon, item) => {
      const baseCells = {
        item: (
          <ImageView
            iconImage={icon}
            placeholderImage={item?.files[0]?.url || icon}
            title={item.title}
            id={item.id}
            type="solutions"
            useSameTab
          />
        ),
        problem: (
          <Item
            problems={getCustomItem(item)}
            expandedId={expandedId}
            id={item.id}
            setExpandedId={setExpandedId}
          />
        ),
        ideaPoints: <Typography>{item.ideaPoints ?? 0}</Typography>
      };
      if (isCurrentUser) {
        baseCells['actions'] = (
          <CustomMenu
            showPin={false}
            actions={getActions(item)}
            item={item}
            itemType={'solutions'}
            title="solution"
            pagination={pagination}
            type={'Community'}
            tagKey={key as string}
          />
        );
      }

      return baseCells;
    };
  }, [Item, expandedId, isCurrentUser, getActions, pagination, key]);

  const rows = {
    component: (icon, item) => getCells(icon, item),
    items: solutions,
    pinnedItems: []
  };

  return (
    <StyledBox>
      {solutionLoader && <LinearProgress value={solutionsCount} />}
      {!solutionLoader && solutionsCount === 0 && (
        <DataNotFound
          iconUrl={SolutionIcon}
          modal={ModalComponentsKeys.addNewSolution}
          text={Constants.SOLUTION}
        />
      )}
      {!hasEmptyData && (
        <TableView
          headers={headers}
          page={page}
          rows={rows}
          rowsPerPage={rowsPerPage}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          totalRows={solutionsCount}
          type={'solutions'}
        />
      )}
    </StyledBox>
  );
};

export default SolutionView;
