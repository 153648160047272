/* eslint-disable max-lines-per-function */
import React from 'react';

export const AiImageIcon = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="28px"
      height="28px"
      viewBox="0 0 500.000000 500.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
        fill="#632DDD"
        stroke="none"
      >
        <path
          d="M1745 4916 c-91 -19 -170 -61 -240 -130 -188 -185 -203 -475 -35
-677 l39 -46 3 -284 3 -284 62 -110 c58 -104 62 -116 72 -207 6 -54 11 -99 11
-101 0 -3 -18 -16 -40 -31 l-40 -26 -242 0 c-237 1 -247 2 -393 33 l-150 33 4
110 c1 60 9 242 15 404 7 162 10 306 6 319 -14 56 -97 79 -277 81 -61 0 -76
-4 -98 -22 -28 -25 -22 7 -105 -613 -17 -126 -34 -250 -38 -274 l-7 -44 -110
-42 c-134 -50 -151 -59 -171 -89 -13 -20 -15 -61 -12 -270 3 -286 -3 -268 118
-315 59 -23 75 -33 73 -47 -2 -11 -12 -89 -23 -174 -11 -85 -29 -240 -39 -345
-17 -162 -23 -197 -44 -235 -55 -98 -81 -182 -81 -255 0 -62 5 -80 43 -155 23
-47 64 -121 91 -165 53 -84 70 -129 70 -182 0 -19 10 -43 26 -62 36 -43 77
-41 178 8 153 74 279 206 321 336 27 83 25 281 -3 377 -18 64 -18 76 -6 210 7
79 18 242 24 362 6 121 12 222 15 224 2 2 40 15 85 27 93 28 206 45 289 45
l58 0 42 -59 c85 -122 233 -228 391 -281 100 -33 289 -39 397 -12 137 35 241
91 341 185 174 165 256 388 231 629 -24 237 -168 459 -366 568 l-43 24 0 283
c0 208 3 283 12 283 15 0 1268 -501 1268 -507 0 -2 -56 -111 -124 -241 -69
-130 -128 -249 -131 -263 -5 -19 15 -72 81 -218 l87 -192 -16 -33 c-12 -27
-26 -38 -65 -50 -144 -48 -266 -175 -312 -324 -18 -56 -20 -93 -20 -308 l0
-243 -221 -3 -221 -3 -70 -75 c-38 -41 -99 -108 -135 -148 l-67 -72 -134 0
-134 0 -29 -29 -29 -29 0 -516 c0 -378 3 -522 12 -541 26 -58 -60 -55 1522
-55 902 0 1464 4 1480 10 56 21 56 24 56 575 0 541 -1 549 -49 575 -11 5 -76
10 -144 10 l-125 0 -63 67 c-35 37 -100 105 -145 150 l-80 83 -212 0 -212 0 0
108 c0 209 -8 378 -20 424 l-12 47 107 208 c59 114 150 291 203 393 52 102
100 190 106 196 6 7 41 21 77 33 366 120 465 587 179 844 -59 53 -151 99 -230
116 -30 7 -303 111 -606 232 l-551 220 -38 102 c-42 116 -60 140 -119 161 -38
13 -118 46 -391 160 -61 25 -126 46 -145 46 -19 0 -96 -16 -170 -35 l-134 -34
-42 39 c-55 51 -150 98 -233 116 -78 16 -125 16 -206 0z m240 -209 c66 -34
118 -89 151 -160 22 -50 26 -69 22 -130 -5 -91 -34 -153 -104 -216 -68 -62
-134 -85 -224 -79 -284 21 -391 381 -165 554 90 68 222 81 320 31z m771 -161
c203 -84 230 -98 243 -124 22 -48 11 -58 -71 -66 -40 -4 -122 -13 -182 -20
l-108 -13 -141 57 -141 56 -13 69 c-7 39 -11 75 -10 80 3 8 149 52 183 54 7 1
114 -41 240 -93z m-131 -409 c22 -1 146 12 275 27 l235 29 433 -173 c237 -95
432 -174 432 -177 0 -2 -29 -31 -64 -63 -67 -63 -131 -173 -143 -244 -3 -20
-9 -36 -13 -36 -8 0 -911 359 -1526 607 -8 3 3 30 28 72 l39 69 132 -55 c76
-31 149 -55 172 -56z m-775 -202 c41 0 92 4 113 8 l37 8 0 -432 0 -431 -27 7
c-16 4 -50 10 -78 14 l-50 6 -7 50 c-14 107 -9 140 32 241 45 110 49 150 20
187 -41 52 -114 43 -156 -20 -12 -18 -24 -29 -28 -26 -3 4 -6 96 -6 205 l0
199 38 -8 c20 -4 71 -8 112 -8z m-1224 -327 c-4 -112 -14 -349 -22 -528 -7
-179 -14 -346 -14 -372 0 -44 -2 -48 -23 -48 -29 0 -115 29 -122 41 -3 4 -1
37 4 71 6 35 24 169 41 298 44 333 90 669 96 708 4 24 10 32 26 32 20 0 20 -2
14 -202z m3787 92 c101 -51 177 -171 177 -280 0 -111 -74 -226 -178 -277 -75
-36 -182 -39 -253 -6 -69 31 -128 90 -161 158 -75 158 4 348 174 417 63 26
178 20 241 -12z m-681 -387 c47 -18 68 -31 68 -43 0 -10 -59 -124 -130 -254
-72 -130 -138 -252 -147 -271 -9 -19 -20 -35 -24 -35 -3 0 -12 15 -20 33 -7
17 -25 58 -40 90 l-28 58 117 224 c64 124 121 225 127 225 5 0 40 -12 77 -27z
m-1484 -228 c13 -11 46 -53 73 -93 110 -164 127 -366 45 -544 -82 -180 -253
-305 -446 -327 -126 -14 -222 4 -336 62 -51 26 -134 92 -134 107 0 15 215 1
257 -16 21 -9 73 -19 115 -22 99 -6 186 16 266 68 70 45 112 94 154 178 26 52
32 79 36 149 6 106 -9 174 -53 248 -33 54 -35 63 -35 147 0 99 -5 96 58 43z
m1700 -38 c20 -22 145 -90 184 -101 14 -4 -1 -38 -83 -198 -54 -106 -127 -248
-162 -315 -34 -68 -65 -123 -69 -123 -4 0 -30 17 -57 38 -28 20 -72 45 -98 55
-26 10 -58 23 -71 28 l-22 9 42 78 c203 371 304 552 309 552 3 0 15 -10 27
-23z m-1966 -162 c89 -59 132 -170 104 -268 -19 -66 -72 -128 -133 -157 -68
-32 -162 -26 -229 16 -72 44 -108 108 -112 198 -3 64 0 76 28 123 70 121 230
162 342 88z m-1069 -16 c37 -10 37 -10 23 -38 -25 -48 -82 -91 -121 -91 l-35
0 0 76 0 76 48 -7 c26 -3 64 -11 85 -16z m-649 -66 c-9 -36 -7 -36 -38 -22
-32 15 -33 27 -3 39 40 16 48 12 41 -17z m1171 5 c-4 -13 -9 -59 -12 -104 -5
-74 5 -134 32 -206 4 -10 -28 -12 -157 -10 -248 5 -389 -15 -623 -88 -118 -37
-115 -38 -300 35 -93 36 -173 72 -177 78 -10 16 -10 65 0 70 4 3 70 -20 147
-50 77 -30 163 -57 190 -60 56 -6 160 19 345 82 93 32 120 37 240 42 130 6
136 7 162 34 30 29 37 74 18 110 -16 29 -64 49 -120 49 -27 0 -50 2 -50 4 0 2
3 11 6 20 5 14 29 16 156 16 l150 0 -7 -22z m2135 -569 c68 -21 132 -78 170
-153 l35 -69 3 -238 4 -239 -311 0 -311 0 0 34 0 35 225 3 225 3 27 27 c39 39
39 100 0 134 -28 24 -30 24 -253 24 l-224 0 0 103 c0 125 16 178 72 242 89
102 205 134 338 94z m-3097 -40 c33 -11 68 -19 79 -19 20 0 21 -4 14 -77 -3
-42 -6 -96 -6 -121 l0 -44 -102 7 c-57 4 -106 10 -110 14 -6 5 18 226 28 260
3 8 11 10 22 6 9 -4 43 -16 75 -26z m27 -448 l45 -6 -4 -45 c-1 -25 -5 -47 -7
-49 -5 -5 -213 15 -220 22 -4 3 -3 27 0 52 l7 45 67 -6 c37 -4 87 -10 112 -13z
m0 -291 c42 -11 63 -83 64 -220 1 -106 -1 -119 -26 -166 -26 -49 -130 -154
-154 -154 -6 0 -18 17 -28 37 -9 20 -46 84 -80 142 -34 58 -67 120 -73 138
-14 40 -2 98 39 181 l31 63 101 -7 c55 -4 112 -10 126 -14z m3862 -157 c26
-26 48 -51 48 -55 0 -4 -52 -8 -116 -8 -114 0 -116 0 -145 -29 -47 -48 -38
-116 21 -146 24 -12 82 -15 338 -15 l309 0 -1 -392 -1 -393 -1347 -3 -1348 -2
0 395 0 394 326 3 326 3 24 28 c32 37 32 87 0 124 -24 27 -27 28 -150 33
l-126 5 46 53 46 52 851 0 851 0 48 -47z"
        />
        <path
          d="M1785 4494 c-57 -61 -17 -154 65 -154 56 0 90 34 90 90 0 56 -34 90
-90 90 -31 0 -47 -6 -65 -26z"
        />
        <path
          d="M3038 4102 c-52 -31 -57 -103 -11 -144 17 -16 403 -180 485 -207 29
-10 42 -10 60 0 46 24 58 41 58 84 0 26 -7 51 -17 64 -19 22 -497 221 -530
221 -10 0 -30 -8 -45 -18z"
        />
        <path
          d="M4238 3504 c-80 -43 -56 -174 32 -174 60 0 99 34 100 88 0 44 -14 68
-50 87 -36 18 -47 18 -82 -1z"
        />
        <path
          d="M1786 2745 c-58 -54 -20 -155 59 -155 58 0 90 26 100 82 5 26 -19 74
-44 88 -35 18 -86 12 -115 -15z"
        />
        <path
          d="M3403 1996 c-22 -19 -28 -32 -28 -66 0 -54 38 -90 93 -90 83 1 120
85 66 149 -33 40 -89 43 -131 7z"
        />
        <path
          d="M3272 1075 c-24 -20 -37 -49 -67 -142 -20 -65 -59 -190 -87 -278 -63
-197 -67 -231 -39 -267 42 -53 142 -40 157 19 6 22 11 23 88 23 80 0 81 -1 98
-30 31 -55 92 -64 139 -22 24 21 31 36 31 62 0 28 -79 297 -169 573 -26 81
-95 109 -151 62z m71 -435 c4 -15 0 -20 -14 -20 -20 0 -23 8 -13 34 7 20 20
13 27 -14z"
        />
        <path
          d="M3744 1090 c-53 -21 -54 -30 -54 -370 0 -315 0 -317 23 -338 30 -28
71 -37 106 -22 53 22 55 34 54 373 l-1 311 -31 28 c-32 29 -59 34 -97 18z"
        />
      </g>
    </svg>
  );
};
