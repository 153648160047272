import {
  Avatar,
  Box,
  CircularProgress,
  Drawer,
  Grid,
  IconButton,
  Typography,
  styled
} from '@mui/material';
import { colorPalette } from 'theme';
import { Constants } from 'utilities/constants';

export const StyledDrawer = styled(Drawer)({
  '& .MuiDrawer-paper': {
    marginTop: '5rem',
    maxHeight: '97vh',
    maxWidth: '45.625rem',
    paddingBottom: '3.75rem',
    width: '100%'
  }
});

export const TypeAvatar = styled(Avatar)<{ loader?: boolean }>(
  ({ loader }) => ({
    borderRadius: '0',
    filter: loader ? 'blur(5px)' : 'none',
    height: '100%',
    transition: 'filter 0.3s ease',
    width: '100%'
  })
);

export const StyledCircularProgress = styled(CircularProgress)({
  left: '50%',
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 2
});

export const StyledOwnerImage = styled(Avatar)({
  width: '6rem',
  height: '6rem'
});

export const StyledOwnerImageBottom = styled(Avatar)({
  width: '3.375rem',
  height: '3.375rem'
});

export const StyledGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  gap: '0.125rem'
});

export const StyledIconButton = styled(IconButton)({
  '&:hover': { backgroundColor: 'transparent' }
});

export const OwnerImageContainer = styled(Box)({
  left: '2rem',
  position: 'relative',
  top: '-75px',
  width: 'fit-content',
  border: '4px solid white',
  borderRadius: '50%'
});

export const AttachedIcon = styled(Box)<{ type: string }>(({ type }) => ({
  alignItems: 'center',
  backgroundColor:
    type === 'product'
      ? colorPalette.cosmicLatte
      : type === 'problem'
        ? colorPalette.danger
        : type === 'solution'
          ? colorPalette.magicMint
          : colorPalette.white,
  border:
    type === 'problem'
      ? `1px solid ${colorPalette.chilliPepper}`
      : type === 'solution'
        ? `1px solid ${colorPalette.shamrockGreen}`
        : `1px solid ${colorPalette.purpleBlue}`,
  borderRadius: '50%',
  color: type === 'problem' ? colorPalette.chilliPepper : 'transparent',
  display: 'flex',
  height: '5rem',
  justifyContent: 'center',
  width: '5rem'
}));

export const StyledTypography = styled(Typography)<{ type?: string }>(
  ({ type }) => ({
    color:
      type === Constants.PROBLEM
        ? colorPalette.red
        : type === Constants.SOLUTION
          ? colorPalette.lightGreen
          : type === Constants.PRODUCT
            ? colorPalette.purple
            : type === Constants.APPLICATION
              ? colorPalette.goldenYellow
              : '',
    fontSize: '2rem',
    fontWeight: 700
  })
);

export const ContentContainer = styled(Box)({
  overflowX: 'hidden',
  overflowY: 'auto',
  padding: '0 1.25rem 0 1.25rem'
});

export const HeadingVotePanelContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between'
});

export const StyledTextWrapper = styled(Typography)({
  fontSize: '1.375rem',
  fontWeight: 500,
  lineHeight: '2.375rem',
  marginBottom: '.875rem',
  textAlign: 'justify'
});

export const TextContainer = styled(Box)({
  width: '100%',
  fontSize: '1.375rem'
});

export const StyledPurpleText = styled(Typography)({
  color: colorPalette.purple,
  fontWeight: 600
});

export const StyledMoreText = styled(Typography)({
  background: colorPalette.lightPurple,
  borderRadius: '4px',
  color: colorPalette.purple,
  cursor: 'pointer',
  display: 'inline',
  fontSize: '.875rem',
  fontWeight: 600,
  height: '1.5rem',
  marginLeft: 1,
  padding: '0 .25rem 0 .25rem',
  whiteSpace: 'nowrap'
});

export const StyledRemaingTagsContainer = styled(Box)({
  background: colorPalette.lightGrey,
  boxShadow: '0px .25rem .75rem rgba(0, 0, 0, 0.1)',
  left: '9.125rem',
  marginTop: 1,
  padding: '.125rem .5rem .125rem .5rem',
  position: 'absolute',
  zIndex: 10
});

export const StyledHeading = styled(Typography)({
  color: colorPalette.purple,
  fontSize: '24px',
  fontWeight: '700',
  paddingTop: '1.5rem'
});

export const StyledTagName = styled(Typography)({
  color: colorPalette.purple,
  fontSize: '14px',
  fontWeight: 500,
  marginBottom: '.25rem'
});

export const StyledNoTagsContainer = styled(Box)({
  color: colorPalette.purple,
  display: 'inline',
  fontWeight: 600,
  marginTop: '12%'
});

export const FooterImageContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'nowrap',
  gap: '-1rem',
  marginBottom: '-2.5rem',
  marginTop: '.875rem',
  '& > *': {
    marginRight: '-.25rem'
  }
});

export const IdeaIconContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  gap: '.875rem'
});

export const StyledHeaderMainContainer = styled(Box)({
  position: 'relative'
});

export const StyledBgImageContainer = styled(Box)({
  alignItems: 'center',
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  left: 0,
  position: 'absolute',
  right: 0,
  top: 0,
  zIndex: 0
});

export const StyledBgImageContent = styled(Box)({
  alignItems: 'flex-end',
  display: 'flex',
  flexDirection: 'column',
  height: '18.625rem',
  justifyContent: 'space-between',
  padding: '1.25rem 1.25rem 1.25rem 0',
  position: 'relative',
  zIndex: 1
});

export const StyledSubContentWrapper = styled(Typography)({
  fontSize: '1.25rem',
  fontWeight: 500,
  lineHeight: '2.375rem',
  textAlign: 'justify'
});

export const StyledTextContentWrapper = styled(Typography)({
  fontSize: '1.25rem'
});

export const StyledHeaderWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center'
});
