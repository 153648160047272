import React, { useCallback, useContext, useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { ModalDataContext } from 'contexts/ModalDataContext';
import { DataContext } from 'contexts/DataContext';
import { AuthContext } from 'contexts/AuthContext';
import { PsFormControl, ShortEvent } from 'components/common/PsFormControl';
import { PsInputLabel } from 'components/common/PsInputLabel';
import { PsOptionType, PsSelect } from 'components/common/PsSelect';
import { Profile } from 'components/CardProfile';
import { ImageField } from 'components/ImageField';
import styles from 'modals/ModalWindow.module.scss';
import { PsTheme } from '../../../theme';
import Grid from '@mui/material/Grid';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Hidden from '@mui/material/Hidden';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root' | 'input' | 'imageLabel';

const useStyles = makeStyles()(() => {
  const psTheme = useTheme() as PsTheme;
  return {
    root: {},
    input: {
      width: '100%',
      '& input': {
        padding: '13px 14px'
      },
      '& fieldset': {
        borderRadius: 8
      }
    },
    imageLabel: {
      marginTop: 10,
      marginBottom: 10
    }
  };
});

const ContestBountyView = () => {
  const { classes } = useStyles();
  const { user } = useContext(AuthContext);
  const { dataProvider } = useContext(DataContext);
  const { values, updateField, handleInputChange } =
    useContext(ModalDataContext);

  const initStartDate = values.challengeStart
    ? new Date(values.challengeStart)
    : null;
  const initEndDate = values.challengeEnd
    ? new Date(values.challengeEnd)
    : null;
  const [startDate, setStartDate] = useState<Date | null>(initStartDate);
  const [endDate, setEndDate] = useState<Date | null>(initEndDate);
  const [owners, setOwners] = useState<Array<PsOptionType>>([]);
  const [ownerValue, setOwnerValue] = useState<string>(
    values.challengeOwner || ''
  );

  useEffect(() => {
    dataProvider
      .getList<Profile>('profiles', {
        pagination: { page: 1, perPage: 50000 },
        sort: { field: 'name', order: 'ASC' },
        filter: {}
      })
      .then(({ data = [] }) => {
        const list: Array<PsOptionType> = data.map((item: Profile) => ({
          value: `${item.id}`,
          label: item.username || '(empty)'
        }));
        setOwners(list);
      })
      .catch(() => {
        setOwners([]);
      });
  }, []);

  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date);
    const dateStr = date ? date.toISOString() : '';
    updateField('challengeStart', dateStr);
  };

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
    const dateStr = date ? date.toISOString() : '';
    updateField('challengeEnd', dateStr);
  };

  const onOwnerChange = useCallback(
    (event: ShortEvent) => {
      const { value } = event.target;
      setOwnerValue(value);
      if (value) {
        updateField('challengeOwner', value);
      } else {
        updateField('challengeOwner', '');
      }
    },
    [setOwnerValue, updateField]
  );

  return (
    <div className={styles.stepWrapper}>
      <PsInputLabel label="Whats Challenge Length" />
      <Grid container spacing={2}>
        <Grid item xs={6} sm={5}>
          <DatePicker
            className={classes.input}
            // disableToolbar
            // variant="inline"
            format="MMM DD, YYYY"
            // margin="normal"
            // id="date-start"
            // placeholder="Enter Start Date"
            value={startDate}
            onChange={handleStartDateChange}
            // autoOk={true}
            disablePast={true}
            // inputVariant="outlined"
            // helperText="Start Date"
          />
        </Grid>
        <Hidden xsDown={true}>
          <Grid item sm={2}></Grid>
        </Hidden>
        <Grid item xs={6} sm={5}>
          <DatePicker
            className={classes.input}
            // disableToolbar
            // variant="inline"
            format="MMM DD, YYYY"
            // margin="normal"
            // id="date-end"
            // placeholder="Enter End Date"
            value={endDate}
            onChange={handleEndDateChange}
            // autoOk={true}
            disablePast={true}
            // inputVariant="outlined"
            // helperText="End Date"
          />
        </Grid>
      </Grid>

      <PsInputLabel
        className={classes.imageLabel}
        label="Whats Challenge Image"
      />
      <ImageField
        name="filesChallenge"
        value={values.filesChallenge}
        onChange={handleInputChange}
      />

      {user && user.isAdmin ? (
        <PsFormControl
          placeholder="Select appropriate challenge owner"
          label="Select challenge owner"
        >
          <PsSelect
            maxMenuHeight={270}
            name="challengeOwner"
            onChange={onOwnerChange}
            options={owners}
            value={ownerValue}
          />
        </PsFormControl>
      ) : null}
    </div>
  );
};

export const ChallengeDate = styled(ContestBountyView)({});

export default ChallengeDate;
