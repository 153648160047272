import dataProvider from 'dataPrvider';

export const Api = {
  shareToSocialMedia: async (payload) => {
    try {
      return await dataProvider.shareToSocialMedia(payload);
    } catch (err) {
      console.log('err', err.message);
    }
  }
};
