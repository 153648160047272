/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useDispatch } from 'react-redux';
import { Box, Divider, LinearProgress } from '@mui/material';
import ideaCoinPurpleIcon from 'assets/icons/ideaCoinPurple.svg';
import dataProvider from 'dataPrvider';
import { isObjectEmpty } from 'helpers';
import { generateUniqueId } from 'helpers/common';
import { toastify } from 'pages/newContests/toastify';
import Actions from 'redux-state/actions';
import {
  GetAiAnswer,
  GetConceptData,
  GetEditProfileItemLoader,
  GetLoader,
  GetRelationPrompt,
  GetRelationPromptLoader,
  GetSelectedItem,
  GetUser
} from 'redux-state/selectors';
import {
  Constants,
  MESSAGE_ROLES,
  MESSAGE_TYPES,
  VARIANT
} from 'utilities/constants';
import { ChatConversation } from './ChatConversation';
import { ChatInput } from './ChatInput';
import {
  findProblemByChild,
  handleSolutionDelete,
  markSolutionAsAdded
} from './helpers';
import { Chip, Message, Option } from './interfaces';
import { SelectionDrawer } from './SelectionDrawer';
import {
  ChatContainer,
  ChatContent,
  CoversionPaper,
  CoversionWrapper,
  HeaderMainBox,
  HeaderText,
  HeaderTextWrapper,
  InputWrapper,
  LinkWrapper,
  LoaingWrapper,
  MessageText,
  StyledAvatar
} from './styledComponents';

const TOASTIFY_DURATION = 2000;

const CHIPS = {
  ADD_PROBLEM: 'Add Problem',
  EDIT_SOLUTION: 'Edit Solution',
  GENERATE_AI_PROBLEMS: 'Generate Ai Problems',
  GENERATE_AI_SOLUTIONS: 'Generate Ai Solutions',
  GENERATE_OWN_PROBLEM: 'Generate Own Problem',
  GENERATE_OWN_SOLUTION: 'Generate Own Solution',
  REMOVE_SOLUTION: 'Remove Solution',
  SOLUTION_RELATIONSHIP: 'Solution Relationship'
};

export const ChatUI: React.FC<{
  graphType?: string;
  graphData?: any;
}> = ({ graphData, graphType }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isInputDisabled, setIsInputDisabled] = useState<boolean>(true);
  const [generatedProblems, setGeneratedProblems] = useState([]);
  const [openSelectionDrawer, setOpenSelectionDrawer] = useState(false);
  const [selectedOption, setSelectedOption] = useState<Option>();
  const [options, setOptions] = useState<Option[]>([]);
  const [placeholderText, setPlaceholderText] = useState<string>('');
  const [expandedNode, setExpandedNode] = useState<{
    key: string;
    msgId: string;
  }>();
  const [selectionDrawerTitle, setSelectionDrawerTitle] = useState<string>();
  const [maxSelection, setMaxSelection] = useState<number>(1);
  const [selectedSolutions, setSelectedSolutions] = useState<string[]>([]);

  const user = GetUser();
  const activeConcept = useMemo(() => user?.activeConcept || {}, [user]);
  const conceptData = GetConceptData();
  const promptLoader = GetRelationPromptLoader();
  const editSolutionLoader = GetEditProfileItemLoader();
  const aiAnswerLoader = GetLoader();
  const aiAnswer = GetAiAnswer();
  const selectedItem = GetSelectedItem();
  const relationPrompt = GetRelationPrompt();

  const dispatch = useDispatch();

  const loadingRef = useRef<boolean>(false);
  const generatedProblemsRef = useRef([]);
  const activeConceptRef = useRef<typeof activeConcept | null>(null);
  const messagesRef = useRef<Message[]>([]);
  const selectedConceptRef = useRef<typeof selectedConcept | null>(null);
  const savedProblemsMap = useRef<Record<string, number>>({});
  const chatRef = useRef(null);

  const INIT_MESSAGES = [
    {
      id: generateUniqueId(),
      text: 'Hello! 👋 Welcome to MindMiner assistant.',
      role: MESSAGE_ROLES.BOT,
      type: MESSAGE_TYPES.TEXT
    },
    {
      id: generateUniqueId(),
      text: '● To get started, type a title to generate a problem or click the chip to create AI Generated Problems',
      role: MESSAGE_ROLES.BOT,
      type: MESSAGE_TYPES.TEXT
    },
    {
      id: generateUniqueId(),
      data: [graphData],
      title: (
        <MessageText>
          ● You can create AI-generated problems and solutions for product{' '}
          <strong>{graphData?.title}</strong>
        </MessageText>
      ),
      role: MESSAGE_ROLES.BOT,
      type: MESSAGE_TYPES.PRODUCT
    }
  ];

  const [prompt, setPrompt] = useState<string>('');

  const generateAiItems = (type: string, params) => {
    return dataProvider.generateMore(type, params);
  };

  const generateAiSolutions = useCallback(
    async (event, problem, msgId) => {
      if (loadingRef.current) return;
      setExpandedNode(null);
      setIsInputDisabled(true);
      if (event) event.stopPropagation();

      try {
        if (graphData) {
          setLoading(true);
          const res = await generateAiItems(Constants.PROBLEMS, {
            problem,
            saveResult: false
          });

          const aiSolutions = res.generatedSolutions.map((solution) => ({
            ...solution,
            type: Constants.SOLUTION
          }));

          let order;
          setMessages((prevMessages) => {
            const newMessages = prevMessages.map((message) => {
              if (message.id === msgId) {
                return {
                  ...message,
                  data: message.data.map((item, index) => {
                    if (item.key === problem.key) {
                      order = index;
                      return {
                        ...item,
                        children: item.children
                          ? [...item.children, ...aiSolutions]
                          : [...aiSolutions]
                      };
                    }
                    return item;
                  })
                };
              }
              return message;
            });
            return newMessages;
          });

          setExpandedNode({ key: `${problem.key}-${order}`, msgId });

          toastify(
            `${Constants.SOLUTION_ADDED_TO} ${problem.shortTitle ?? problem.title} ${Constants.PROBLEM}`,
            VARIANT.SUCCESS,
            VARIANT.BOTTOM_LEFT,
            TOASTIFY_DURATION
          );
        }
      } catch (error) {
        console.error(error.message);
      } finally {
        setLoading(false);
      }
    },
    [graphData]
  );

  const selectedConcept = useMemo(() => {
    return (
      activeConcept &&
      Object?.keys(activeConcept)?.length &&
      conceptData?.data?.find((concept) => concept?.id === activeConcept?.id)
    );
  }, [activeConcept, conceptData?.data]);
  selectedConceptRef.current = selectedConcept;

  const addSolutionToConcept = useCallback(
    async (event, solution, msgId) => {
      if (loadingRef.current) return;
      setIsInputDisabled(true);
      setExpandedNode(null);
      event.preventDefault();
      event.stopPropagation();
      setLoading(true);
      const problem = findProblemByChild(
        solution.key,
        msgId,
        messagesRef.current
      );

      let problemId = problem?.id;
      if (!(problem?.key in savedProblemsMap.current)) {
        problem.notGenerateRelatedSolutions = true;
        // eslint-disable-next-line no-unused-vars
        const { children, ...problemWithoutChildren } = problem;
        const createdProblem: any = await dataProvider.create(
          Constants.PROBLEMS,
          {
            data: problemWithoutChildren
          }
        );
        problemId = createdProblem.data.id;
        // eslint-disable-next-line require-atomic-updates
        savedProblemsMap.current[problem?.key] = problemId;
        dispatch(Actions.getGraph(graphData?.id, graphType, graphType));
      } else {
        problemId = savedProblemsMap.current[problem?.key];
      }

      const solutionInfo = {
        ...solution,
        problem: problemId,
        notGenerateRelatedProblems: true
      };
      const createdSolution: any = await dataProvider.create(
        Constants.SOLUTIONS,
        {
          data: solutionInfo
        }
      );

      if (isObjectEmpty(activeConceptRef.current)) {
        const appInfo = {
          title: '',
          selected: [createdSolution.data.id],
          problems: [problemId]
        };
        dispatch(Actions.createConcept(appInfo));
      } else {
        const existingProbIds = selectedConceptRef.current.problems.map(
          ({ id }) => id
        );
        const newProblems = existingProbIds.includes(problemId)
          ? existingProbIds
          : [...existingProbIds, problemId];

        const data = {
          selected: [
            createdSolution.data.id,
            ...selectedConceptRef.current.selected
          ],
          problems: newProblems
        };
        dispatch(Actions.updateConcept(activeConceptRef.current?.id, data));
      }
      markSolutionAsAdded(problem.key, solution.key, msgId, setMessages);
      toastify(
        `${Constants.SOLUTION_ADDED_TO} ${Constants.CONCEPT}`,
        VARIANT.SUCCESS,
        VARIANT.BOTTOM_LEFT,
        TOASTIFY_DURATION
      );
      setLoading(false);
    },
    [dispatch, graphData, graphType]
  );

  const onAiProblemsGenChipClick = useCallback(async () => {
    if (loadingRef.current) return;
    setIsInputDisabled(true);
    try {
      if (graphData) {
        setLoading(true);

        const res = await generateAiItems(Constants.COMPANY_PRODUCTS, {
          id: graphData?.id,
          title: graphData?.title,
          key: graphData?.key,
          saveResult: false
        });

        const aiProblems = res.generatedProblems.map((problem) => ({
          ...problem,
          type: Constants.PROBLEM
        }));

        const newMessages = {
          id: generateUniqueId(),
          data: aiProblems,
          type: MESSAGE_TYPES.AI_PROBLEMS,
          title: `${Constants.AI_PROBLEMS_MSG_TEXT} for ${graphData.title}`,
          role: MESSAGE_ROLES.BOT,
          onProblemClick: generateAiSolutions,
          onSolutionClick: addSolutionToConcept
        };

        const newProblems = aiProblems.map((problem) => ({
          ...problem,
          msgId: newMessages.id
        }));

        setGeneratedProblems((prev) => [...prev, ...newProblems]);
        setMessages((prevMessages) => [...prevMessages, newMessages]);
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  }, [addSolutionToConcept, generateAiSolutions, graphData]);

  const generateProblemByPrompt = useCallback(async () => {
    try {
      const title = prompt;
      const description = await dataProvider.generateDescriptionFromAI({
        title,
        type: Constants.PROBLEMS,
        additionalInfo: { companyName: graphData?.title }
      });

      const newProblem = {
        title,
        key: title.split(' ').join('-'),
        body: description.text,
        owner: user?.id,
        parentProduct: graphData?.id,
        isAiGenerated: false,
        isPublic: true,
        type: Constants.PROBLEM,
        tags: graphData.tags.map((tag) => tag._id)
      };

      const newMessages = {
        id: generateUniqueId(),
        data: [newProblem],
        role: MESSAGE_ROLES.BOT,
        type: MESSAGE_TYPES.AI_PROBLEMS,
        title: `${Constants.AI_PROBLEMS_MSG_TEXT} for ${graphData.title}`,
        onProblemClick: generateAiSolutions,
        onSolutionClick: addSolutionToConcept
      };

      setMessages((prevMessages) => [...prevMessages, newMessages]);
      setGeneratedProblems((pre) => [
        ...pre,
        {
          ...newProblem,
          msgId: newMessages.id
        }
      ]);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error.message);
    }
  }, [addSolutionToConcept, generateAiSolutions, graphData, prompt, user?.id]);

  const generateSolutionByPrompt = useCallback(async () => {
    try {
      const title = prompt;
      const { msgId, value: problemKey, label: problemTeaser } = selectedOption;
      const description = await dataProvider.generateDescriptionFromAI({
        title,
        type: Constants.SOLUTIONS,
        additionalInfo: { parentProblem: problemTeaser }
      });
      const newSolution = {
        title,
        key: title.split(' ').join('-'),
        owner: user?.id,
        body: description.text,
        isAiGenerated: false,
        type: Constants.SOLUTION,
        tags: graphData.tags.map((tag) => tag._id)
      };
      let order;
      setMessages((prevMessages) => {
        const newMessages = prevMessages.map((message) => {
          if (message.id === msgId) {
            return {
              ...message,
              data: message.data.map((item, index) => {
                if (item.key === problemKey) {
                  order = index;
                  return {
                    ...item,
                    children: item.children
                      ? [...item.children, newSolution]
                      : [newSolution]
                  };
                }
                return item;
              })
            };
          }
          return message;
        });
        setExpandedNode({ key: `${problemKey}-${order}`, msgId });
        return newMessages;
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error.message);
    }
  }, [graphData, prompt, selectedOption, user]);

  const onSubmit = useCallback(async () => {
    if (loadingRef.current || prompt === '') return;
    const isSolutionGen =
      selectedOption?.type === CHIPS.GENERATE_OWN_SOLUTION ? true : false;
    setPrompt('');
    setIsInputDisabled(true);

    if (!isSolutionGen) {
      setMessages((prev) => [
        ...prev,
        {
          id: generateUniqueId(),
          text: prompt,
          role: MESSAGE_ROLES.USER,
          type: MESSAGE_TYPES.TEXT
        }
      ]);
    }

    setLoading(true);

    if (isSolutionGen) {
      await generateSolutionByPrompt();
    } else {
      await generateProblemByPrompt();
    }
    setLoading(false);
  }, [
    generateProblemByPrompt,
    generateSolutionByPrompt,
    prompt,
    selectedOption
  ]);

  const toggleSelectionDrawer = () => {
    setOpenSelectionDrawer(!openSelectionDrawer);
  };

  const handleConfirmSelection = (option: Option) => {
    switch (option.type) {
      case CHIPS.GENERATE_AI_SOLUTIONS: {
        const problem = generatedProblems.find(
          (prob) => prob.key === option.value
        );
        generateAiSolutions(null, problem, option.msgId);
        break;
      }

      case CHIPS.GENERATE_OWN_SOLUTION: {
        setSelectedOption(option);
        setIsInputDisabled(false);
        break;
      }

      case CHIPS.EDIT_SOLUTION: {
        const solution = selectedConceptRef.current.solutions.find(
          (item) => item.key === option.value
        );
        const newMessages = {
          id: generateUniqueId(),
          data: solution,
          type: MESSAGE_TYPES.EDIT_SOLUTION,
          title: `${Constants.C_EDIT} ${Constants.C_SOLUTION}`,
          role: MESSAGE_ROLES.BOT
        };
        setMessages((prevMessages) => [...prevMessages, newMessages]);
        break;
      }

      case CHIPS.REMOVE_SOLUTION: {
        handleSolutionDelete(
          option.value,
          selectedConceptRef.current,
          dispatch,
          user
        );
        break;
      }

      default:
        break;
    }

    if (
      Array.isArray(option) &&
      option[0].type === CHIPS.SOLUTION_RELATIONSHIP
    ) {
      if (option.length < 2) {
        toastify(
          Constants.SELECT_TWO_SOLUTIONS,
          VARIANT.INFO,
          VARIANT.BOTTOM_LEFT,
          TOASTIFY_DURATION
        );
        return;
      }
      const remainingSolutions = selectedConceptRef?.current?.solutions?.filter(
        (solution) =>
          solution?.teaser !== option[0]?.label &&
          solution?.teaser !== option[1]?.label
      );
      const problemSolvedBySolution =
        selectedConceptRef?.current?.problems?.filter((problem) =>
          problem?.children?.some((child) =>
            options?.some((item) => child?.solutionTeaser === item?.label)
          )
        );
      dispatch(
        Actions.getRelationPrompt({
          conceptTitle: activeConceptRef?.current?.title,
          productTitle: graphData?.title,
          firstSolutionTitle: option[0]?.label,
          secondSolutionTitle: option[1]?.label,
          remainingSolutions,
          problemSolvedBySolution
        })
      );
      setSelectedSolutions([option[0].label, option[1].label]);
    }

    toggleSelectionDrawer();
  };

  useEffect(() => {
    if (relationPrompt) {
      dispatch(Actions.aiAnswerSearch({ prompt: relationPrompt }));
      dispatch(Actions.getRelationPromptSuccess(''));
    }
  }, [dispatch, relationPrompt]);

  useEffect(() => {
    if (aiAnswer) {
      const newMessage = {
        id: generateUniqueId(),
        data: {
          solution1: selectedSolutions[0],
          solution2: selectedSolutions[1],
          relationship: aiAnswer
        },
        role: MESSAGE_ROLES.BOT,
        text: aiAnswer,
        title: Constants.SOLUTIONS_RELATIONSHIP,
        type: MESSAGE_TYPES.SOLUTION_RELATIONSHIP
      };
      setMessages((pre) => [...pre, newMessage]);
      dispatch(Actions.aiAnswerSearchSuccess({ text: '' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aiAnswer]);

  const selectionDrawerTitleMap = useRef({});

  const onSolutionGenChipClick = (chip) => {
    setPlaceholderText(`${Constants.TYPE_TITLE} ${Constants.SOLUTION}...`);
    setIsInputDisabled(true);

    if (!generatedProblemsRef.current.length) {
      toastify(
        Constants.GENERATE_PROBLEMS_FIRST,
        VARIANT.INFO,
        VARIANT.BOTTOM_LEFT,
        TOASTIFY_DURATION
      );
      return;
    }

    setSelectionDrawerTitle(`Select Problem for ${graphData.title}`);
    setMaxSelection(1);
    setOptions(
      generatedProblemsRef.current.map((problem) => ({
        label: problem.title,
        value: problem.key,
        msgId: problem.msgId,
        type: chip.text
      }))
    );
    toggleSelectionDrawer();
  };

  const onGenOwnProblemChipClick = () => {
    setSelectedOption(null);
    setPlaceholderText(`${Constants.TYPE_TITLE} ${Constants.PROBLEM}...`);
    setIsInputDisabled(false);
  };

  const onSolutionModChipClick = (chip) => {
    setIsInputDisabled(true);
    if (isObjectEmpty(activeConceptRef.current)) {
      toastify(
        Constants.CREATE_OR_ACTIVATE_CONCEPT,
        VARIANT.INFO,
        VARIANT.BOTTOM_LEFT,
        TOASTIFY_DURATION
      );
      return;
    }

    if (chip.text === CHIPS.SOLUTION_RELATIONSHIP) {
      if (selectedConceptRef.current.solutions.length < 2) {
        toastify(
          Constants.ADD_MORE_SOLUTIONS_TO_CONCEPT,
          VARIANT.INFO,
          VARIANT.BOTTOM_LEFT,
          TOASTIFY_DURATION
        );
        return;
      }
      setMaxSelection(2);
    } else {
      setMaxSelection(1);
    }

    setSelectionDrawerTitle(selectionDrawerTitleMap.current[chip.text]);
    setOptions(
      selectedConceptRef.current.solutions.map((solution) => ({
        label: solution?.teaser || solution.title,
        value: solution.key,
        type: chip.text
      }))
    );
    toggleSelectionDrawer();
  };

  const handleChipClick = (chip: Chip) => {
    if (loadingRef.current) return;
    setExpandedNode(null);

    switch (chip.text) {
      case CHIPS.GENERATE_AI_PROBLEMS:
        onAiProblemsGenChipClick();
        break;

      case CHIPS.GENERATE_AI_SOLUTIONS:
      case CHIPS.GENERATE_OWN_SOLUTION:
        onSolutionGenChipClick(chip);
        break;

      case CHIPS.GENERATE_OWN_PROBLEM:
        onGenOwnProblemChipClick();
        break;

      case CHIPS.EDIT_SOLUTION:
      case CHIPS.SOLUTION_RELATIONSHIP:
      case CHIPS.REMOVE_SOLUTION:
        onSolutionModChipClick(chip);
        break;

      default:
        break;
    }
  };

  const INIT_CHIPS = [
    {
      id: generateUniqueId(),
      role: MESSAGE_ROLES.BOT,
      type: MESSAGE_TYPES.ACTION,
      onClick: handleChipClick,
      data: [
        { id: generateUniqueId(), text: CHIPS.GENERATE_AI_PROBLEMS },
        { id: generateUniqueId(), text: CHIPS.GENERATE_AI_SOLUTIONS },
        { id: generateUniqueId(), text: CHIPS.GENERATE_OWN_PROBLEM },
        { id: generateUniqueId(), text: CHIPS.GENERATE_OWN_SOLUTION },
        { id: generateUniqueId(), text: CHIPS.EDIT_SOLUTION },
        { id: generateUniqueId(), text: CHIPS.SOLUTION_RELATIONSHIP },
        { id: generateUniqueId(), text: CHIPS.REMOVE_SOLUTION }
      ]
    }
  ];

  const [messages, setMessages] = useState<Message[]>([
    ...INIT_MESSAGES,
    ...INIT_CHIPS
  ]);

  useEffect(() => {
    messagesRef.current = messages;
    activeConceptRef.current = activeConcept;
    selectedConceptRef.current = selectedConcept;
    loadingRef.current = loading;
    generatedProblemsRef.current = generatedProblems;
  }, [activeConcept, generatedProblems, loading, messages, selectedConcept]);

  useEffect(() => {
    selectionDrawerTitleMap.current = {
      [CHIPS.EDIT_SOLUTION]: `Select Solution to Edit from ${activeConcept?.title}`,
      [CHIPS.REMOVE_SOLUTION]: `Select Solution to Remove from ${activeConcept?.title} `,
      [CHIPS.SOLUTION_RELATIONSHIP]: `Select 2 solutions to describe their relationship in the context of ${activeConcept?.title}`
    };
  }, [activeConcept]);

  useEffect(() => {
    setLoading(editSolutionLoader || aiAnswerLoader || promptLoader);
  }, [aiAnswerLoader, editSolutionLoader, promptLoader]);

  useEffect(() => {
    if (!selectedItem) return;
    const newMessage = {
      id: generateUniqueId(),
      data: selectedItem,
      role: MESSAGE_ROLES.BOT,
      type: MESSAGE_TYPES.ITEM_DETAILS
    };
    setMessages((pre) => [...pre, newMessage]);
    dispatch(Actions.setSelectedItem(null));
  }, [dispatch, selectedItem]);

  useEffect(() => {
    if (!isInputDisabled && chatRef.current) {
      chatRef.current.scrollTo({
        top: chatRef.current.scrollHeight,
        behavior: VARIANT.SMOOTH
      });
    }
  }, [isInputDisabled]);

  return (
    <ChatContainer>
      <Box>
        <HeaderMainBox>
          <StyledAvatar
            src={ideaCoinPurpleIcon}
            alignSelf={VARIANT.CENTER}
            size="1.875rem"
          />
          <HeaderTextWrapper>
            <HeaderText>
              {Constants.IMPROVE} <LinkWrapper>{graphData?.title}</LinkWrapper>{' '}
              to {Constants.EARN_MORE}
            </HeaderText>
          </HeaderTextWrapper>
        </HeaderMainBox>
        {loading ? (
          <LoaingWrapper>
            <LinearProgress />
          </LoaingWrapper>
        ) : (
          <Divider />
        )}
      </Box>
      <ChatContent>
        <CoversionWrapper>
          <CoversionPaper ref={chatRef}>
            <ChatConversation
              messages={messages}
              expandedNode={expandedNode}
              setMessages={setMessages}
            />
            {!isInputDisabled && (
              <InputWrapper disabled={isInputDisabled}>
                <ChatInput
                  value={prompt}
                  setValue={setPrompt}
                  onSubmit={onSubmit}
                  disabled={isInputDisabled}
                  placeholderText={placeholderText}
                />
              </InputWrapper>
            )}
          </CoversionPaper>
        </CoversionWrapper>
      </ChatContent>
      {options && (
        <SelectionDrawer
          maxSelection={maxSelection}
          onClose={toggleSelectionDrawer}
          onConfirm={handleConfirmSelection}
          open={openSelectionDrawer}
          options={options}
          title={selectionDrawerTitle}
        />
      )}
    </ChatContainer>
  );
};
