import React, { useRef, useEffect, useState } from 'react';
import { styled, Theme, useTheme } from '@mui/material/styles';

import { Picture, getPlaceholderImage, getFileTitle } from '../../helpers';
import { PsTheme } from '../../theme';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root' | 'inner' | 'placeholder' | 'img';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      display: 'block',
      textDecoration: 'none',
      width: '30%',
      padding: 11,
      [psTheme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    inner: {
      display: 'block',
      position: 'relative',
      paddingTop: '73%',
      [psTheme.breakpoints.down('sm')]: {
        paddingTop: '40%'
      },
      [psTheme.breakpoints.down('xs')]: {
        paddingTop: '55%'
      }
    },
    placeholder: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: 15,
      objectFit: 'cover',
      opacity: 1,
      transition: 'opacity 0.3s ease',
      '&.loaded': {
        opacity: 0
      }
    },
    img: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: 15,
      objectFit: 'cover',
      opacity: 0,
      transition: 'opacity 0.3s ease',
      '&.loaded': {
        opacity: 1
      }
    }
  };
});

type CardImageProps = {
  children?: React.ReactNode;
  className?: string;
  href?: string;
  hrefTarget?: string;
  image?: Picture;
  imageAlt?: string;
  type?: string;
};

const CardImageView = ({
  className,
  href,
  hrefTarget,
  image,
  imageAlt,
  type,
  children
}: CardImageProps) => {
  const [loaded, setLoaded] = useState(false);
  const imgRef = useRef<HTMLImageElement>(null);
  const { classes } = useStyles();

  useEffect(() => {
    setLoaded(false);
  }, [image, image ? image.url : '']);

  useEffect(() => {
    if (imgRef.current) {
      if (imgRef.current.complete) {
        if (imgRef.current.naturalHeight) {
          setLoaded(true);
        }
      } else {
        imgRef.current.addEventListener('load', () => {
          setLoaded(true);
        });
      }
    }
  }, [imgRef.current]);

  let rootClassName = classes.root;
  if (className) {
    rootClassName += ' ' + className;
  }
  const def = getPlaceholderImage(type);

  const Placeholder = (
    <img
      className={`${classes.placeholder} ${loaded ? 'loaded' : ''}`}
      src={def}
      alt={type}
    />
  );

  const Image = image ? (
    <img
      ref={imgRef}
      className={`${classes.img} ${loaded ? 'loaded' : ''}`}
      src={image.url}
      alt={imageAlt || getFileTitle(image.title)}
    />
  ) : null;

  if (href) {
    return (
      <Link to={href}>
        <a className={rootClassName} target={hrefTarget}>
          <span className={classes.inner}>
            {Placeholder}
            {Image}
            {children}
          </span>
        </a>
      </Link>
    );
  }

  return (
    <div className={rootClassName}>
      <div className={classes.inner}>
        {Placeholder}
        {Image}
        {children}
      </div>
    </div>
  );
};

export const CardImage = styled(CardImageView)({});

export default CardImage;
