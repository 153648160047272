import { SHARE_TO_SOCIAL_MEDIA_SUCCESS } from './types';
import { Share } from './interface';

const INITIAL_STATE = {
  shareStatus: null
};

const reducer = (
  state = INITIAL_STATE,
  action: { type: string; payload: Share }
) => {
  switch (action.type) {
    case SHARE_TO_SOCIAL_MEDIA_SUCCESS:
      return { ...state, shareStatus: action.payload };
    default:
      return state;
  }
};

export default reducer;
