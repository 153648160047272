import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import Actions from 'redux-state/actions';
import { GetUser } from 'redux-state/selectors';
import { Constants, VARIANT } from 'utilities/constants';
import { StyledButtonContainer } from './StyledComponents';

type FollowProps = {
  actionType?: string;
  id: string | number;
  onClick: () => void;
  type: Constants.PROFILE | Constants.TAG;
  version?: VARIANT.DEFAULT | VARIANT.OUTLINED;
  disabled?: boolean;
};

export const Follow = ({
  actionType,
  id,
  onClick,
  type,
  version = VARIANT.DEFAULT,
  disabled
}: FollowProps) => {
  const dispatch = useDispatch();
  const [subscribed, setSubscribed] = useState<boolean>(
    actionType === Constants.UNFOLLOW
  );
  const user = GetUser();

  const onBtnClick = (e: React.MouseEvent) => {
    if (user) {
      e.preventDefault();
      const newSubscribedStatus = !subscribed;
      setSubscribed(newSubscribedStatus);
      onClick();
    } else {
      dispatch(Actions.openAuthModal(true));
    }
  };

  return (
    <StyledButtonContainer
      onClick={onBtnClick}
      version={version}
      disabled={disabled}
    >
      {disabled
        ? Constants.MY_COMMUNITY
        : subscribed
          ? Constants.FOLLOWING_TEXT
          : Constants.FOLLOW}
    </StyledButtonContainer>
  );
};
