import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { AuthContext } from 'contexts/AuthContext';
import { DataContext } from 'contexts/DataContext';
import { PsLogo } from 'components/common/PsLogo';
import { PsIconButton } from 'components/common/PsIconButton';
import { ShareBtn } from 'components/ShareBtn';
import { Tags } from 'components/Tag/Tags';
import { TagInfo, Contributors } from 'components/CardTag';
import { ContestIcon } from 'components/icons/ContestIcon';
import { EditIcon } from 'components/icons/EditIcon';
import { Challenge, ModalEditChallenge } from 'components/CardChallenge';
import { ChallengeTopUser, ChallengeTopGroup } from 'components/LeaderBoard';
import { getFileTitle } from '../../helpers';
import { PsTheme } from '../../theme';
import bg from 'assets/background.svg';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';

type ClassKey =
  | 'root'
  | 'title'
  | 'info'
  | 'text'
  | 'tags'
  | 'rewards'
  | 'leaders'
  | 'prizes'
  | 'coins'
  | 'prize'
  | 'participants'
  | 'introBlock'
  | 'introBlockBg'
  | 'introBlockContent'
  | 'introBlockImageHolder'
  | 'introBlockImage'
  | 'introBlockText'
  | 'introBlockTextMobile'
  | 'introBlockActions';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {},
    title: {
      fontSize: 30,
      '& > svg': {
        margin: '0 10px 0 0'
      }
    },
    info: {
      padding: '0 20px'
    },
    text: {
      marginBottom: 10,
      '& a': {
        color: psTheme.palette.primary.main,
        textDecoration: 'none'
      },
      '& a:hover': {
        textDecoration: 'underline'
      }
    },
    tags: {
      padding: '15px 0'
    },
    rewards: {
      padding: '15px 20px',
      marginBottom: 15,
      background: '#ffffff',
      border: '1px solid #E8ECF0',
      boxSizing: 'border-box',
      boxShadow: '0px 7px 80px rgba(3, 31, 70, 0.05)',
      borderRadius: 15,
      '& b': {
        fontWeight: 600
      }
    },
    leaders: {
      paddingTop: 5,
      '& table': {
        width: '100%',
        marginBottom: 5,
        borderCollapse: 'collapse',
        '& td:first-child': {
          width: '1%',
          paddingBottom: 3,
          paddingRight: 5
        },
        '& td:last-child': {
          paddingLeft: 5,
          paddingRight: 0,
          textAlign: 'right'
        }
      },
      '& > div': {
        fontSize: 12,
        textAlign: 'right'
      },
      '& > div a': {
        textDecoration: 'none',
        textAlign: 'right',
        fontWeight: 600,
        color: psTheme.palette.primary.main
      },
      '& > div a:hover': {
        textDecoration: 'underline'
      }
    },
    prizes: {
      paddingTop: 5,
      '& img': {
        width: 65
      },
      '& b': {
        fontWeight: 600
      },
      '& table': {
        width: '100%',
        marginBottom: 5,
        borderCollapse: 'collapse',
        '& td': {
          verticalAlign: 'top'
        },
        '& td:first-child': {
          width: '1%',
          textAlign: 'right',
          paddingBottom: 3,
          paddingRight: 15
        },
        '& td:last-child': {
          paddingLeft: 0,
          paddingRight: 0,
          lineHeight: '17px'
        },
        '& tr:first-child td:last-child': {
          paddingTop: 8
        },
        '& tr:last-child td:last-child': {
          paddingTop: 5
        }
      }
    },

    coins: {
      display: 'flex',
      alignItems: 'center',
      '& > svg': {
        marginLeft: 15,
        marginRight: 15
      }
    },
    prize: {},
    participants: {
      padding: '0 15px',
      '& > p': {
        marginBottom: 5
      }
    },
    introBlock: {
      marginBottom: 10
    },
    introBlockBg: {
      minHeight: 200,
      fontSize: 40,
      fontWeight: 600,
      padding: '70px 20px 20px 190px',
      color: '#fff',
      borderRadius: '15px 15px 0px 0px',
      background: psTheme.palette.primary.main,
      backgroundImage: `url("${bg}")`,
      [psTheme.breakpoints.down('xs')]: {
        fontSize: 35,
        padding: '40px 20px 55px 20px'
      }
    },
    introBlockContent: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    introBlockImageHolder: {
      marginTop: -78,
      marginLeft: 20,
      [psTheme.breakpoints.down('xs')]: {
        marginTop: -57,
        marginLeft: 10
      }
    },
    introBlockImage: {
      position: 'relative',
      height: 156,
      width: 156,
      borderRadius: '50%',
      background: '#ccc',
      border: 'solid 4px #fff',
      '& > img': {
        position: 'absolute',
        top: 0,
        left: 0,
        borderRadius: '50%',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        opacity: 0,
        transition: 'opacity 0.3s ease'
      },
      '& > img.loaded': {
        opacity: 1
      },
      [psTheme.breakpoints.down('xs')]: {
        height: 116,
        width: 116
      }
    },
    introBlockText: {
      flexGrow: 1,
      padding: '15px 20px',
      [psTheme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    introBlockTextMobile: {
      display: 'none',
      padding: '0 15px 20px',
      [psTheme.breakpoints.down('xs')]: {
        display: 'block'
      }
    },
    introBlockActions: {
      padding: '25px 0',
      whiteSpace: 'nowrap',
      '& > span': {
        marginRight: 30,
        marginLeft: 20
      },
      '& > button': {}
    }
  };
});

export type CardChallengeDetailProps = {
  challenge: Challenge;
  isDetailed?: boolean;
};

const CardChallengeDetailView = ({
  challenge: initChallenge
}: CardChallengeDetailProps) => {
  const { classes } = useStyles();

  const { user, loading } = useContext(AuthContext);
  const { dataProvider } = useContext(DataContext);
  const [challenge, setChallenge] = useState<Challenge>(initChallenge);
  const [topTags, setTopTags] = useState<Array<ChallengeTopGroup>>([]);
  const [topUsers, setTopUsers] = useState<Array<ChallengeTopUser>>([]);

  const [editOpened, setEditOpened] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const imageRef = useRef<HTMLImageElement>(null);

  const {
    id,
    key,
    title,
    header,
    tagsInfo = [],
    internalReward,
    externalReward,
    files,
    files2,
    owner
  } = challenge;

  const externalRewardImage = files2 && files2.length ? files2[0] : undefined;
  const image = files && files.length ? files[0] : undefined;
  let canEdit = false;
  if (user && user.isAdmin) {
    canEdit = true;
  }
  if (user && user.id === owner) {
    canEdit = true;
  }

  let userTags: Array<TagInfo> = [];
  const userTagsMatch: Array<TagInfo> = [];
  if (user && user.locationTagsInfo) {
    userTags = userTags.concat(user.locationTagsInfo);
  }
  if (user && user.schoolTagsInfo) {
    userTags = userTags.concat(user.schoolTagsInfo);
  }
  if (user && user.workplaceTagsInfo) {
    userTags = userTags.concat(user.workplaceTagsInfo);
  }
  userTags.forEach((tag) => {
    const match = tagsInfo.find((chTag) => tag.id === chTag.id);
    if (match) {
      userTagsMatch.push(tag);
    }
  });

  useEffect(() => {
    setChallenge(initChallenge);
  }, [initChallenge]);

  useEffect(() => {
    dataProvider
      .getChallengeTopTags<Array<ChallengeTopGroup>>(initChallenge.id)
      .then((list = []) => {
        setTopTags(list.slice(0, 3));
      })
      .catch((err) => {
        console.error(err);
        setTopTags([]);
      });

    dataProvider
      .getChallengeTopUsers<Array<ChallengeTopUser>>(initChallenge.id)
      .then((list = []) => {
        setTopUsers(list);
      })
      .catch((err) => {
        console.error(err);
        setTopUsers([]);
      });
  }, [challenge]);

  useEffect(() => {
    setImageLoaded(false);
  }, [image, image ? image.url : '']);

  useEffect(() => {
    if (imageRef.current) {
      if (imageRef.current.complete) {
        if (imageRef.current.naturalHeight) {
          setImageLoaded(true);
        }
      } else {
        imageRef.current.addEventListener('load', () => {
          setImageLoaded(true);
        });
      }
    }
  }, [imageRef.current, image, image ? image.url : '']);

  const onEditUpdate = useCallback(
    (newChallenge: Challenge) => {
      setChallenge(newChallenge);
      setEditOpened(false);
    },
    [setChallenge, setEditOpened]
  );

  const onEditOpen = useCallback(() => {
    setEditOpened(true);
  }, [setEditOpened]);

  const onEditClose = useCallback(() => {
    setEditOpened(false);
  }, [setEditOpened]);

  return (
    <div className={classes.root}>
      <div className={classes.introBlock}>
        <div className={classes.introBlockBg}>{header || ''}</div>
        <div className={classes.introBlockContent}>
          <div className={classes.introBlockImageHolder}>
            <div className={classes.introBlockImage}>
              {image ? (
                <img
                  ref={imageRef}
                  className={`${imageLoaded ? 'loaded' : ''}`}
                  src={image.url}
                  alt={getFileTitle(image.title)}
                />
              ) : null}
            </div>
          </div>
          <div className={classes.introBlockText}>
            <div className={classes.title}>
              <ContestIcon />
              {title}
            </div>
          </div>
          <div className={classes.introBlockActions}>
            {canEdit ? (
              <PsIconButton onClick={onEditOpen}>
                <EditIcon />
              </PsIconButton>
            ) : null}
            <ShareBtn challenge={challenge} />
          </div>
        </div>
        <div className={classes.introBlockTextMobile}>
          <div className={classes.title}>
            <ContestIcon />
            {title}
          </div>
        </div>
      </div>
      <div className={classes.info}>
        {user ? (
          <div className={classes.text}>
            {userTagsMatch.length ? (
              <>
                You are competing as part of
                {userTagsMatch.map((userTag, index) => {
                  return (
                    <>
                      {index === 0 ? ' ' : ', '}
                      <Link to={`/tags/${userTag.key}`}>
                        <a target="_blank">{userTag.name}</a>
                      </Link>
                    </>
                  );
                })}
              </>
            ) : (
              <>
                <br />
                <br />
                You are not part of a{' '}
                {tagsInfo.map((tag, index) => {
                  return (
                    <>
                      {index === 0 || index === tagsInfo.length - 1 ? '' : ', '}
                      {index === tagsInfo.length - 1 ? ' or ' : ''}
                      <Link to={`/tags/${tag.key}`}>
                        <a target="_blank">{tag.name}</a>
                      </Link>
                    </>
                  );
                })}{' '}
                participating in this challenge. You can update those on your{' '}
                <Link to={`/profiles/${user.key}`}>
                  <a target="_blank">profile page</a>
                </Link>
                .
              </>
            )}
          </div>
        ) : null}

        <Tags className={classes.tags} tagsList={tagsInfo} />
      </div>

      <div className={classes.rewards}>
        <Grid container spacing={2}>
          {topTags && topTags.length ? (
            <Grid item sm={6} xs={12}>
              <Typography>
                <b>Leaders</b>
              </Typography>
              <div className={classes.leaders}>
                <table>
                  <tbody>
                    {topTags.map((topTag, index) => {
                      return (
                        <tr key={topTag.id}>
                          <td>{index + 1}.</td>
                          <td>{topTag.name}</td>
                          <td>
                            <b>
                              {topTag.rewardsSum
                                ? topTag.rewardsSum.toFixed(2)
                                : topTag.rewardsSum}
                            </b>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div>
                  <Link to={`/challenges/leaderboard/${key}`}>
                    <a>see full challenge leaderboard</a>
                  </Link>
                </div>
              </div>
            </Grid>
          ) : null}
          <Grid item sm={6} xs={12}>
            <Typography>
              <b>Individual Prizes</b>
            </Typography>
            <div className={classes.prizes}>
              <Typography className={classes.prize} component="div">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <PsLogo color="#632DDD" size={30} small />
                      </td>
                      <td>{internalReward} IdeaCoin Jackpot</td>
                    </tr>
                    <tr>
                      <td>
                        {externalRewardImage ? (
                          <img
                            src={externalRewardImage.url}
                            alt={externalRewardImage.title}
                          />
                        ) : null}
                      </td>
                      <td>
                        <b>{externalReward}</b>
                        {/*<br />*/}
                        {/*65” Television*/}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>

      {topUsers && topUsers.length ? (
        <div className={classes.participants}>
          <Typography>
            <b>Participants</b>
          </Typography>
          <Contributors users={topUsers} />
        </div>
      ) : null}

      <ModalEditChallenge
        app={challenge}
        open={editOpened}
        onUpdate={onEditUpdate}
        onClose={onEditClose}
      />
    </div>
  );
};

export const CardChallengeDetail = styled(CardChallengeDetailView)({});

export default CardChallengeDetail;
