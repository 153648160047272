import React, { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';
import { ButtonWithCounter } from 'components/common/ButtonWithCounter';
import { AddToButDetail } from 'components/common/StyledComponents';
import { HexDoneIcon } from 'components/icons/HexDoneIcon';
import { HexPlusOutlinedIcon } from 'components/icons/HexPlusOutlinedIcon';
import IdeaMapDetailsIcon from 'components/icons/IdeaMapDetailsIcon';
import useRouter from 'hooks/useRouter';
import { NodeContentProps } from 'interface/common';
import {
  StyledButton,
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle
} from 'modals/AddNewCompany/StyledComponents';
import Actions from 'redux-state/actions';
import { GetGraphData, GetUser } from 'redux-state/selectors';
import { colorPalette, theme, useIsMediumScreen } from 'theme';
import { Constants, VARIANT } from 'utilities/constants';
import { NodeContentBase } from './NodeContentBase';
import {
  AddToBox,
  AddToBut,
  AddToButton,
  DetailBox,
  VotingBox
} from './StyledComponents';

/* eslint-disable max-lines-per-function */
const NodeContent: FC<NodeContentProps> = React.memo(
  ({
    addNewConcept,
    buttonList,
    ideaMapType,
    image,
    isConceptDrawerOpen = false,
    isCurrentConceptSolution = false,
    messageId,
    node,
    openAddToConceptModal,
    selectedNode,
    setDrawerItem,
    setDrawerItemType,
    setIsDetailDrawer,
    setIsSolutionModalOpen,
    setProblem,
    showAvatar = true,
    VotingSection
  }) => {
    const { title, type } = node;
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const dispatch = useDispatch();
    const router = useRouter();
    const { referralCode } = router.query;
    const user = GetUser();
    const isMediumScreen = useIsMediumScreen();
    const activeProduct = GetGraphData();
    const [confirmationModal, setConfirmationModal] = useState<boolean>(false);

    const handleClose = (event) => {
      setAnchorEl(null);
      event.stopPropagation();
    };

    const handleClickButton = useCallback(() => {
      if (!user) {
        dispatch(Actions.openAuthModal(true));
        return;
      }

      switch (type) {
        case Constants.APPLICATION:
          dispatch(Actions.toggleConceptCart(true));
          addNewConcept?.(node?.selected, node?.problems, title);
          break;

        case Constants.PRODUCT:
          dispatch(Actions.toggleConceptCart(true));
          if (activeProduct?.id === user?.activeConcept?.parentProduct) {
            setConfirmationModal(true);
          } else {
            addNewConcept?.([], [], title, activeProduct?.id);
          }
          break;

        case Constants.PROBLEM:
          setIsSolutionModalOpen?.(true);
          setProblem?.(node);
          break;

        default:
          break;
      }
    }, [
      addNewConcept,
      dispatch,
      node,
      setIsSolutionModalOpen,
      setProblem,
      activeProduct?.id,
      user?.activeConcept?.id,
      title,
      type,
      user
    ]);

    const handleCreateNewConcept = () => {
      setConfirmationModal(false);
      addNewConcept?.([], [], title, activeProduct?.id);
    };

    const handleNodeContentClick = useCallback(
      ({ key }) => {
        const typeMapping = {
          [Constants.COMPANY_PRODUCTS]: Constants.SOLUTIONS,
          [Constants.PROBLEMS]: Constants.SOLUTIONS,
          [Constants.SOLUTIONS]: Constants.PROBLEMS,
          [Constants.APPLICATIONS]: Constants.PROBLEMS,
          [Constants.CONTESTS]: Constants.SOLUTIONS
        };

        if (type === Constants.SOLUTION && typeMapping[ideaMapType]) {
          window.location.href = `/${typeMapping[ideaMapType]}/${key}`;
        }
      },
      [ideaMapType, type]
    );

    const onDetailButtonClick = useCallback(
      (e) => {
        e.stopPropagation();
        setDrawerItem?.(node);
        setDrawerItemType?.(type);
        dispatch(Actions.toggleConceptCart(false));
        setIsDetailDrawer?.((prev) => !prev);
      },
      [
        dispatch,
        node,
        setDrawerItem,
        setDrawerItemType,
        setIsDetailDrawer,
        type
      ]
    );

    const renderAddToButton = (
      isCurrentConceptSolution: boolean,
      openAddToConceptModal: () => void
    ) => (
      <Box>
        <AddToBut
          onClick={openAddToConceptModal}
          variant="contained"
          disabled={isCurrentConceptSolution}
          isCurrentConceptSolution={isCurrentConceptSolution}
          sx={{
            padding: isCurrentConceptSolution
              ? '0px !important'
              : '8px !important',
            minWidth: '0px',
            borderRadius: '50%'
          }}
        >
          {isCurrentConceptSolution ? (
            <HexDoneIcon
              height={'4.0625rem'}
              width="4.0625rem"
              variant={VARIANT.OUTLINED}
              strokeColor={colorPalette.gray}
            />
          ) : (
            <HexPlusOutlinedIcon
              height={'2.75rem'}
              width="2.75rem"
              color={colorPalette.white}
            />
          )}
        </AddToBut>
        <AddToButDetail
          onClick={openAddToConceptModal}
          variant="contained"
          disabled={isCurrentConceptSolution}
          isCurrentConceptSolution={isCurrentConceptSolution}
          responsive={true}
        >
          {isCurrentConceptSolution ? (
            <HexDoneIcon
              height={'1.25rem'}
              width="1.25rem"
              variant={VARIANT.OUTLINED}
              strokeColor={colorPalette.gray}
            />
          ) : (
            <HexPlusOutlinedIcon
              height={'1.25rem'}
              width="1.25rem"
              color={colorPalette.white}
            />
          )}
        </AddToButDetail>
      </Box>
    );

    const renderButtonWithCounter = (
      type: string,
      handleClickButton: () => void
    ) => {
      const coinsMapper = {
        [Constants.PROBLEM]: Constants.PLUS_ONE,
        [Constants.PRODUCT]: Constants.PLUS_FIVE_HUNDRED,
        [Constants.APPLICATION]: Constants.PLUS_FIVE_HUNDRED
      };

      const label =
        type === Constants.PRODUCT || type === Constants.APPLICATION
          ? Constants.IMPROVE
          : type === Constants.PROBLEM
            ? Constants.SOLVE
            : null;

      if (!label) return null;

      const coins = coinsMapper[type] ?? Constants.PLUS_ZERO;

      return (
        <ButtonWithCounter
          bigCounter
          coins={coins}
          isReferred={!!referralCode}
          onClick={handleClickButton}
          sx={{
            display: 'none',
            [theme.breakpoints.up('md')]: {
              display: 'flex'
            }
          }}
        >
          {label}
        </ButtonWithCounter>
      );
    };

    return (
      <>
        <NodeContentBase
          buttonList={buttonList}
          image={image}
          isCurrentConceptSolution={isCurrentConceptSolution}
          messageId={messageId}
          node={node}
          onNodeClick={() => handleNodeContentClick(node)}
          selectedNode={selectedNode}
          showAvatar={showAvatar}
        >
          {!buttonList && (
            <>
              {!isMediumScreen && <VotingBox>{VotingSection}</VotingBox>}
              {!isMediumScreen && (
                <DetailBox>
                  <Button disableRipple onClick={onDetailButtonClick}>
                    <IdeaMapDetailsIcon />
                  </Button>
                </DetailBox>
              )}
              <AddToBox>
                <AddToButton onClick={(event) => event.stopPropagation()}>
                  {node.type === Constants.SOLUTION
                    ? renderAddToButton(
                        isCurrentConceptSolution,
                        openAddToConceptModal
                      )
                    : renderButtonWithCounter(node.type, handleClickButton)}
                </AddToButton>
              </AddToBox>

              {isMediumScreen && (
                <>
                  <IconButton
                    onClick={(event) => {
                      setAnchorEl(event.currentTarget);
                      event.stopPropagation();
                    }}
                    className="HistoryBtn"
                  >
                    <MoreVertIcon sx={{ color: colorPalette.darkPurple }} />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    <MenuItem>
                      <Box sx={{ display: 'flex' }}>
                        <VotingBox>{VotingSection}</VotingBox>
                        <DetailBox onClick={onDetailButtonClick}>
                          <Button disableRipple>
                            <IdeaMapDetailsIcon />
                          </Button>
                        </DetailBox>
                      </Box>
                    </MenuItem>
                  </Menu>
                </>
              )}
            </>
          )}
        </NodeContentBase>
        {confirmationModal && (
          <StyledDialog
            open={confirmationModal}
            onClose={() => setConfirmationModal(false)}
            aria-labelledby={Constants.CONFIRMATION_DIALOG_TITLE}
          >
            <StyledDialogTitle id={Constants.CONFIRMATION_DIALOG_TITLE}>
              {Constants.WARNING}
            </StyledDialogTitle>
            <StyledDialogContent dividers>
              <Typography variant={VARIANT.BODY1}>
                You are already have an active concept{' '}
                <b>{user?.activeConcept?.title}</b> regarding product{' '}
                <b>{activeProduct?.title}</b>. Do you want to create a new
                concept anyway?
              </Typography>
            </StyledDialogContent>
            <StyledDialogActions>
              <StyledButton
                onClick={() => setConfirmationModal(false)}
                variant={VARIANT.OUTLINED}
                color={VARIANT.PRIMARY}
              >
                {Constants.CANCEL}
              </StyledButton>
              <StyledButton
                onClick={() => handleCreateNewConcept()}
                variant={VARIANT.OUTLINED}
                color={VARIANT.PRIMARY}
              >
                {Constants.YES}
              </StyledButton>
            </StyledDialogActions>
          </StyledDialog>
        )}
      </>
    );
  }
);
/* eslint-disable max-lines-per-function */

NodeContent.displayName = 'NodeContent';

export default NodeContent;
