export const ADD_TAG = 'ADD_TAG';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const GET_COMPANIES = 'GET_COMPANIES';
export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_TAGS = 'GET_TAGS';
export const GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS';
export const GET_TAGS_LOADER = 'GET_TAGS_LOADER';
export const GET_LOCATION_TAGS_SUCCESS = 'GET_LOCATION_TAGS_SUCCESS';
export const GET_WORKPLACE_TAGS_SUCCESS = 'GET_WORKPLACE_TAGS_SUCCESS';
export const GET_LOCATION_TAGS = 'GET_LOCATION_TAGS';
export const GET_WORKPLACE_TAGS = 'GET_WORKPLACE_TAGS';
export const GET_UNIVERSITY_TAGS = 'GET_UNIVERSITY_TAGS';
export const GET_UNIVERSITY_TAGS_SUCCESS = 'GET_UNIVERSITY_TAGS_SUCCESS';
export const GET_RELATED_TAGS = 'GET_RELATED_TAGS';
export const GET_RELATED_TAGS_SUCCESS = 'GET_RELATED_TAGS_SUCCESS';
