import React, { useContext, useEffect, useMemo, useState } from 'react';
import PlaceHolderImage from 'assets/placeholder/problem.svg';
import AuthContext from 'contexts/AuthContext';
import useRouter from 'hooks/useRouter';
import { StyledMainWrapper } from 'pages/home/StyledComponents';
import { useDispatch } from 'react-redux';
import Actions from 'redux-state/actions';
import { GetTagFeed, GetTagActivityCount } from 'redux-state/commons/selectors';
import { TAG_TYPES } from 'utilities/constants';
import { Profile } from '../CardProfile';
import ListView from '../ListView';

interface ItemListProps {
  profile?: Profile;
}

const NewsFeedView: React.FC<ItemListProps> = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { user } = useContext(AuthContext);
  const feed = GetTagFeed();
  const itemsCount = GetTagActivityCount();

  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [initialFetchCompleted, setInitialFetchCompleted] =
    useState<boolean>(false);

  const { key } = router.query;

  const pagination = useMemo(
    () => ({
      page: 0,
      perPage: rowsPerPage
    }),
    [rowsPerPage]
  );

  useEffect(() => {
    if (!initialFetchCompleted && itemsCount === 0 && feed.length === 0) {
      dispatch(Actions.getFeed(pagination, key as string, TAG_TYPES.TAG_FEED));
      setInitialFetchCompleted(true);
    } else if (itemsCount > 0 && itemsCount !== feed.length) {
      dispatch(Actions.getFeed(pagination, key as string, TAG_TYPES.TAG_FEED));
    }
  }, [dispatch, pagination, itemsCount, feed.length, initialFetchCompleted]);

  return (
    <StyledMainWrapper>
      {user && (
        <ListView
          feed={feed}
          isCommunityFeed={true}
          communityTagId={key as string}
          placeHolderImage={PlaceHolderImage}
          pagination={pagination}
          setRowsPerPage={setRowsPerPage}
        />
      )}
    </StyledMainWrapper>
  );
};
export default NewsFeedView;
