import React from 'react';
import { Modal, Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { StyledTextModal } from './StyledComponents';

interface TextModalProps {
  open: boolean;
  onClose: () => void;
  text: string;
}

const TextModal: React.FC<TextModalProps> = ({ open, onClose, text }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <StyledTextModal>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold' }}>
          Terms and Conditions
        </Typography>
        <Typography dangerouslySetInnerHTML={{ __html: text }} sx={{ mt: 2 }} />
      </StyledTextModal>
    </Modal>
  );
};

export default TextModal;
