import React from 'react';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';
import docImage from 'assets/icons-new/doc-file.png';
import styles from 'modals/ModalWindow.module.scss';
import { StepComponentProps } from './types';
import { PsTheme } from '../../../theme';

const useStyles = makeStyles()(() => {
  const psTheme = useTheme() as PsTheme;
  return {
    root: {},
    row: {
      display: 'flex',
      alignItems: 'center'
    },
    cellImage: {
      width: '35%',
      flexShrink: 0
    },
    cellText: {},
    image: {
      padding: '.625rem',
      '& img': {
        display: 'block',
        width: '100%',
        height: 'auto'
      }
    },
    text: {
      fontSize: 24,
      fontWeight: 700,
      '& span': {
        color: psTheme.palette.primary.main
      },
      lineHeight: '2rem'
    }
  };
});

const DownloadStepView: React.FC<StepComponentProps> = ({ application }) => {
  const { classes } = useStyles();

  return (
    <div className={styles.stepWrapper}>
      <div className={classes.row}>
        <div className={classes.cellImage}>
          <div className={classes.image}>
            <img src={docImage} alt="doc-file" />
          </div>
        </div>
        <div className={classes.cellText}>
          <Typography className={classes.text}>
            Download the <span>{application ? application.title : ''}</span>{' '}
            Patent Application Template
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default DownloadStepView;
