import { CompareArrows } from '@mui/icons-material';
import { Box, IconButton, styled, TextField, Typography } from '@mui/material';
import { colorPalette } from 'theme';

export const StyledBodyFieldText = styled(TextField)({
  borderRadius: '.5rem',
  width: '100%'
});

export const StyledBodyTypography = styled(Typography)({
  color: `${colorPalette.dimBlack} !important`,
  fontSize: '.875rem !important',
  fontWeight: '500 !important'
});

export const StyledTypography = styled(Typography)({
  fontWeight: '600',
  lineHeight: '1.4',
  color: colorPalette.purple,
  fontSize: '18'
});

export const ButtonTypography = styled(Typography)({
  fontWeight: '600',
  lineHeight: '1',
  color: colorPalette.purple,
  fontSize: '18',
  marginTop: '.1875rem',
  marginLeft: '-.25rem'
});

export const StyledIconButton = styled(IconButton)({
  color: colorPalette.purple
});

export const DropDownsMainBox = styled(Box)({
  alignItems: 'flex-end',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  margin: '-1rem 0 1.2rem 0'
});

export const DropDownBox = styled(Box)({
  width: '40%'
});

export const RelationsBox = styled(Box)({
  marginBottom: '0.2rem',
  width: '100%'
});

export const ButtonsBox = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '.25rem',
  width: '100%'
});

export const LinkViewWrapper = styled(Box)({
  display: 'inline-block',
  verticalAlign: 'baseline'
});

export const StyledCompareArrows = styled(CompareArrows)({
  height: '26px',
  width: '30px',
  color: colorPalette.purple
});

export const RelationIconBox = styled(Box)({
  alignItems: 'center',
  marginBottom: '1rem',
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column'
});
