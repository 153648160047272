import { OverviewConcept } from 'components/OverviewConcept';
import { ReviewSolutions } from './steps/ReviewSolutions/index';
import { SolutionsRelationship } from 'components/SolutionsRelationship';
import { StepComponentProps } from './types';

export const STEP_NAME = {
  SOLUTIONS: 'Solutions',
  RELATIONSHIP: 'Relationship',
  OVERVIEW: 'Overview'
};

export type LockConceptConfig = {
  Component: React.FC<StepComponentProps>;
  nextStep?: string;
  title: string;
  subtitle?: string;
  isNextStepBlocked?: boolean;
  isFinalStep?: boolean;
  nextButtonTitle?: string;
};

export const LOCK_CONCEPT_STEPS: {
  [name: string]: LockConceptConfig;
} = {
  [STEP_NAME.SOLUTIONS]: {
    Component: ReviewSolutions,
    nextStep: STEP_NAME.RELATIONSHIP,
    title:
      'Once you generate a patent application for your concept, it will be locked.',
    subtitle: 'Let’s first make sure that everything looks correct.'
  },
  [STEP_NAME.RELATIONSHIP]: {
    Component: SolutionsRelationship,
    nextStep: STEP_NAME.OVERVIEW,
    title: 'Confirm the details of how each solution relates to another:'
  },
  [STEP_NAME.OVERVIEW]: {
    Component: OverviewConcept,
    nextStep: STEP_NAME.OVERVIEW,
    title: 'Confirm the details of the overall concept:',
    isFinalStep: true
  }
};
