import React, { useContext, useState, useCallback, useEffect } from 'react';
import { PsFormControl, ShortEvent } from 'components/common/PsFormControl';
import PsInputLabel from 'components/common/PsInputLabel';
import { PsButton } from 'components/common/PsButton';
import { PsSelect, PsOptionType } from 'components/common/PsSelect';
import { ModalDataContext } from 'contexts/ModalDataContext';
import { ModalContext, ModalComponentsKeys } from 'contexts/ModalContext';
import { DataContext } from 'contexts/DataContext';
import { Tag } from 'components/CardTag';
import styles from 'modals/ModalWindow.module.scss';
import Typography from '@mui/material/Typography';

const ChooseCompanyStep: React.FC = () => {
  const { openModal } = useContext(ModalContext);
  const { values, handleInputChange, updateField } =
    useContext(ModalDataContext);
  const { dataProvider } = useContext(DataContext);
  const [tags, setTags] = useState<Array<PsOptionType>>([]);
  const [tag, setTag] = useState(values.parentProblem);

  useEffect(() => {
    dataProvider
      .getList<Tag>('tags', {
        pagination: { page: 1, perPage: 50000 },
        sort: { field: 'name', order: 'ASC' },
        filter: { $custom: { type: 'workspacesEmpty' } }
      })
      .then(({ data }) => {
        const tags: Array<PsOptionType> = data.map((tag) => ({
          label: `${tag.name}`,
          value: `${tag.id}`
        }));
        setTags(tags);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const onTagChange = useCallback(
    (e: ShortEvent) => {
      handleInputChange(e);
      setTag(e.target.value);
    },
    [setTag, handleInputChange]
  );

  const onCreateClick = (e: React.MouseEvent) => {
    e.preventDefault();
    updateField('parentProblem', undefined);
    updateField('parentProblemLabel', undefined);
    updateField('parentProblemOnly', true);
    openModal(ModalComponentsKeys.companySubscription);
  };

  return (
    <div className={styles.stepWrapper}>
      <Typography className={styles.textSmall}>
        If your company profile page already exists on MindMiner, please start
        typing the company name below. Otherwise, please create a new company
        profile page
      </Typography>

      <PsFormControl
        //placeholder="Choose a Brainstorming Action"
        label="Type Company Name:"
      >
        <PsSelect
          maxMenuHeight={290}
          name="parentProblem"
          onChange={onTagChange}
          options={tags}
          value={tag}
        />
      </PsFormControl>

      <PsInputLabel label="or:" />
      <div>
        <PsButton onClick={onCreateClick}>Create New Company Profile</PsButton>
      </div>
    </div>
  );
};

export default ChooseCompanyStep;
