import React, { useState, useCallback, useContext, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import ModalWindow from 'modals/ModalWindow';
import { PsButton } from 'components/common/PsButton';
import Step from 'components/Step';
import Stepper from 'components/Stepper';
import { Problem } from 'components/CardProblem';
import { Product } from 'components/CardProduct';
import { Tag } from 'components/CardTag';
import { ModalContext, ModalComponentsKeys } from 'contexts/ModalContext';
import { ModalDataContext, AddProblemSteps } from 'contexts/ModalDataContext';
import { DataContext } from 'contexts/DataContext';
import { AuthContext } from 'contexts/AuthContext';
import useRouter from 'hooks/useRouter';
import styles from 'modals/ModalWindow.module.scss';
import { useDispatch } from 'react-redux';
import Actions from 'redux-state/actions';
import { makeStyles } from 'tss-react/mui';
import { Constants } from 'utilities/constants';
import { ADD_PROBLEM_STEPS_CONFIG, defaultProblemTitle } from './config';

const steps: string[] = ['Problem Name', 'Tag Name', 'Finalize Problem'];

const useButtonClasses = makeStyles()(() => {
  return {
    root: {
      margin: '8px 0',
      fontWeight: 400
    }
  };
});

const AddNewProblem: React.FC = () => {
  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);
  const {
    openModal,
    closeModal,
    triggerActionCallback,
    isRedirectionEnabled,
    setIsRedirectionEnabled
  } = useContext(ModalContext);
  const { values, setErrors, updateField, resetFields } =
    useContext(ModalDataContext);
  const { dataProvider } = useContext(DataContext);
  const router = useRouter();
  const { key } = router.query;
  const [loading, setLoading] = useState(false);
  const [prevSteps, setPrevSteps] = useState<string[]>([]);
  const [activeStepNumber, setActiveStepNumber] = useState<number>(0);
  const [currentStep, setCurrentStep] = useState<string | undefined>(
    AddProblemSteps.ProblemName
  );

  const buttonClasses = useButtonClasses();

  const {
    Component,
    nextStep,
    validate,
    isNextStepBlocked,
    isFinalStep,
    nextButtonTitle
  } = ADD_PROBLEM_STEPS_CONFIG[currentStep || ''];

  const StepComponent = Component as React.FC;

  useEffect(() => {
    if (
      user &&
      user.userData &&
      user.userData.tags &&
      user.userData.tags.length
    ) {
      const tags = user.userData.tags.map((t) => t.id);
      updateField('tags', tags);
    }
  }, []);

  const handleClickBack = useCallback(() => {
    if (activeStepNumber === 0) {
      openModal(ModalComponentsKeys.addNewEntity);
      return;
    }
    setActiveStepNumber((prev) => Math.max(0, prev - 1));
    setCurrentStep(prevSteps[prevSteps.length - 1]);
    setPrevSteps(prevSteps.slice(0, prevSteps.length - 1));
  }, [prevSteps]);

  const handleNextStep = async () => {
    if (typeof validate === 'function') {
      const { success, errors } = validate(values);

      if (!success) {
        setErrors({ ...errors });

        return;
      }
    }

    if (isFinalStep) {
      let createCompany = false;
      let createCompanyName = values.company || '';
      if (values.company === '--create--') {
        createCompany = true;
        createCompanyName = values.companyRaw || '';
      }

      let createProduct = false;
      let createProductName = values.product || '';
      if (values.product === '--create--') {
        createProduct = true;
        createProductName = values.productRaw || '';
      }

      const problemInfo: Record<any, any> = {
        title: (values.titleProblem || '').replace(defaultProblemTitle, ''),
        tags: values.tagsProblem,
        pagination: values.pagination,
        isCommunity: values.isCommunity,
        body: values.descriptionProblem,
        files: values.filesProblem,
        parentProblem: values.rootProblem,
        parentProduct: values.rootProblemHide
          ? values.selectApplication
          : undefined,
        isPublic: true
      };

      setLoading(true);

      Promise.resolve()
        .then(() => {
          if (createCompany) {
            return dataProvider
              .create<Tag>('tags', {
                data: { name: createCompanyName, type: 'workplace' }
              })
              .then(({ data }) => data.id);
          }
          return createCompanyName;
        })
        .then((companyId) => {
          if (companyId) {
            problemInfo.tags.push(companyId);
          }
          if (createProduct) {
            return dataProvider
              .create<Product>('company-products', {
                data: {
                  title: createProductName,
                  company: companyId,
                  owner: user ? user.id : undefined
                }
              })
              .then(({ data }) => data.id);
          }
          return createProductName;
        })
        .then((productId) => {
          if (productId) {
            problemInfo.parentProduct = productId;
          }
          return dataProvider.create<Problem>('problems', {
            data: problemInfo
          });
        })
        .then(({ data }) => {
          if (problemInfo.isCommunity) {
            dispatch(
              Actions.getRelatedItems({
                resource: Constants.TAGS,
                key: key as string,
                item: Constants.PROBLEMS,
                pagination: problemInfo.pagination
              })
            );
          }
          if (!isRedirectionEnabled) {
            triggerActionCallback();
            closeModal();
            setIsRedirectionEnabled(true);
          } else {
            router.push(`/problems/${data.key}`);
            openModal(ModalComponentsKeys.success);
          }
          resetFields();
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });

      return;
    }

    setPrevSteps([...prevSteps, currentStep || '']);
    setErrors({});
    setActiveStepNumber((prev) => prev + 1);
    if (typeof nextStep === 'function') {
      setCurrentStep(nextStep(values));
      return;
    }

    setCurrentStep(nextStep);
  };

  const isNextStepDisabled: boolean =
    typeof isNextStepBlocked === 'function' && isNextStepBlocked(values);

  const title = values.modalTitle || 'Add New Problem';

  return (
    <ModalWindow title={title}>
      <Stepper className={styles.stepper} activeStep={activeStepNumber}>
        {steps.map((step) => (
          <Step key={step}>{step}</Step>
        ))}
      </Stepper>
      <StepComponent />
      <div className={styles.actions}>
        <PsButton
          disabled={isNextStepDisabled || loading}
          loading={loading}
          onClick={handleNextStep}
          fullWidth
          classes={buttonClasses}
        >
          {nextButtonTitle || 'Next'}
        </PsButton>
        <PsButton
          onClick={handleClickBack}
          fullWidth
          cancel
          classes={buttonClasses}
        >
          Back
        </PsButton>
      </div>
    </ModalWindow>
  );
};

export default AddNewProblem;
