import {
  Accordion,
  Avatar,
  Badge,
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  LinearProgress,
  LinearProgressProps,
  styled,
  Typography
} from '@mui/material';
import { colorPalette } from '../../theme';

export const StyledDrawer = styled(Drawer)<{
  open: boolean;
  leftMarg: boolean;
}>(({ open, leftMarg, theme }) => ({
  width: open ? '30%' : '0rem',
  zIndex: 9,
  marginLeft: '0rem',
  [theme.breakpoints.up('md')]: {
    marginLeft: open && leftMarg ? '0.5rem' : '0rem'
  },
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    height: '100vh',
    left: '0',
    maxWidth: '30%',
    padding: '1.5rem 1rem 1rem',
    paddingBottom: '3.75rem',
    position: 'fixed',
    top: '9rem',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      left: '5.0625rem',
      top: '5.625rem',
      maxWidth: '30%'
    }
  }
}));

export const StyledClose = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  right: '0.1875rem',
  top: '0.1875rem',
  zIndex: '.625rem'
}));

export const StyledMainBox = styled(Box)({
  backgroundColor: colorPalette.white,
  height: 'auto',
  width: '100%'
});

export const StyledBox = styled(Box)({
  backgroundColor: colorPalette.white,
  height: 'auto',
  padding: '.9375rem',
  width: '100%'
});

export const ButtonContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '.9375rem .9375rem 0rem 0rem',
  width: '100%'
});

export const StyledDownloadButton = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  minWidth: 'auto'
});

export const SolutionBar = styled(Box)({
  maxHeight: '17.5rem',
  overflowX: 'clip',
  overflowY: 'auto',
  position: 'relative',
  width: '100%',
  marginLeft: '.1875rem'
});

export const ProblemContainer = styled(Box)({
  alignItems: 'center',
  border: `.0625rem solid ${colorPalette.red}`,
  borderRadius: '3.125rem',
  display: 'flex',
  height: '3.125rem',
  justifyContent: 'space-between',
  margin: '.3125rem 0',
  padding: '0rem .25rem',
  width: '100%'
});

export const ProbSolContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center'
});

export const ProblemIcon = styled(Box)({
  alignItems: 'center',
  backgroundColor: colorPalette.pink,
  borderRadius: '100%',
  color: colorPalette.bloodRed,
  display: 'flex',
  height: '2.5rem',
  justifyContent: 'center',
  marginRight: '.625rem',
  width: '2.5rem'
});

export const TeaserTypo = styled(Typography)({
  '-webkit-box-orient': 'vertical',
  '-webkit-line-clamp': '2',
  display: '-webkit-box',
  fontSize: 'small',
  lineHeight: '20px',
  overflow: 'hidden',
  overflowX: 'hidden',
  textOverflow: 'ellipsis',
  width: '100%'
});

export const SolutionContainer = styled(Box)({
  alignItems: 'center',
  border: `.0625rem solid ${colorPalette.lightGreen}`,
  borderRadius: '3.125rem',
  display: 'flex',
  height: '3.125rem',
  justifyContent: 'space-between',
  margin: '.3125rem 0',
  padding: '0rem .25rem',
  width: '100%'
});

export const SolutionIcon = styled(Box)({
  alignItems: 'center',
  backgroundColor: colorPalette.mintTulip,
  borderRadius: '100%',
  color: colorPalette.green,
  display: 'flex',
  height: '2.5rem',
  justifyContent: 'center',
  marginRight: '.625rem',
  minWidth: '2.5rem',
  width: '2.5rem'
});

export const StyledDetailBox = styled(Box)({
  padding: '0.625rem'
});

export const StyledAccordion = styled(Accordion)({
  borderRadius: '0.5rem',
  boxShadow: '0rem 0rem 0.6875rem 0rem rgba(0, 0, 0, 0.08)',
  gap: '0rem',
  justifyContent: 'space-between',
  marginBottom: '1.25rem',
  opacity: 1,
  '&::before': {
    content: 'none'
  }
});

export const StyledProgressAccordion = styled(StyledAccordion)({
  marginTop: '16px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)'
});

export const StyledVideoBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '200px',
  backgroundColor: colorPalette.whiteGrey,
  marginTop: '2px'
});

export const StyledVideo = styled('video')({
  borderRadius: '0.5rem',
  height: '18.75rem',
  objectFit: 'cover',
  width: '100%'
});

export const StyledNoVideoBox = styled(StyledVideoBox)({
  justifyContent: 'center',
  alignItems: 'center',
  height: '200px'
});

export const StyledGrid = styled(Grid)({
  display: 'flex',
  flexWrap: 'wrap',
  marginTop: '8px'
});

export const StyledMintButton = styled(Button)({
  marginTop: '24px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

export const StyledClickableText = styled(Typography)({
  color: colorPalette.purple,
  fontFamily: 'Montserrat',
  fontSize: '0.75rem',
  fontWeight: 600,
  lineHeight: '0.9144rem',
  minWidth: 0,
  padding: 0,
  textAlign: 'left',
  textDecoration: 'underline',
  textTransform: 'none'
});

export const StyledClickableText1 = styled(Typography)({
  color: colorPalette.purple,
  fontFamily: 'Montserrat',
  fontSize: '0.75rem',
  fontWeight: 'bold',
  lineHeight: '0.9144rem',
  minWidth: 0,
  padding: 0,
  textAlign: 'left',
  textTransform: 'none'
});

export const StyledIconButton = styled(IconButton)({
  color: colorPalette.black
});

export const StyledIdeaPointsText = styled(Typography)({
  color: colorPalette.purple,
  fontFamily: 'Montserrat',
  fontSize: '0.75rem',
  fontWeight: 600,
  lineHeight: '0.9144rem',
  minWidth: 0,
  padding: 0,
  textAlign: 'left',
  textDecoration: 'underline',
  textTransform: 'none',
  marginLeft: '4px'
});

export const StyledIdeaPointsCount = styled(Typography)({
  color: colorPalette.purple,
  fontFamily: 'Montserrat',
  fontSize: '0.75rem',
  fontWeight: 'bold',
  lineHeight: '0.9144rem',
  minWidth: 0,
  padding: 0,
  textAlign: 'left',
  textTransform: 'none',
  marginLeft: '2px'
});

export const StyledBoxHeadingList = styled(Box)(() => ({
  display: 'flex',
  color: colorPalette.purple,
  alignItems: 'center',
  justifyContent: 'flex-start',
  backgroundColor: colorPalette.seaGreen,
  padding: '1rem',
  borderRadius: '0.5rem',
  marginBottom: '1rem'
}));

export const StyledIconsRow = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: '1rem'
}));

export const StyledIconsRowItem = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: '2rem'
}));

export const StyledConceptDetailBox = styled(Box)(() => ({
  marginTop: '2rem',
  marginBottom: '2.5rem',
  padding: '1rem',
  borderRadius: '0.9375rem',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)'
}));

export const StyledStakingMainBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '1rem'
}));

export const StyledStakingInternalBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '1rem',
  marginLeft: '1rem'
}));

export const StyledStakingSimpleTextBox = styled(Box)(() => ({
  color: colorPalette.purple,
  fontSize: '0.875rem'
}));

export const StyledStakingNumericTextBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  fontSize: '1.75rem',
  fontWeight: 'bold',
  '&.normal': {
    fontWeight: 'normal'
  }
}));

export const StyledTitle = styled(Typography)(() => ({
  color: colorPalette.purple,
  fontWeight: '600',
  fontSize: '1.25rem',
  lineHeight: '1.52rem',
  width: '26.5rem'
}));

export const StyledPoweredByBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end'
}));

interface StyledLinearProgressProps extends LinearProgressProps {
  colorGreen?: boolean;
}

export const StyledLinearProgress = styled(
  LinearProgress
)<StyledLinearProgressProps>(({ colorGreen }) => ({
  height: 10,
  borderRadius: 5,
  width: '100%',
  marginRight: 2,
  backgroundColor: colorPalette.whiteGrey,
  '& .MuiLinearProgress-bar': {
    backgroundColor: colorGreen ? colorPalette.green : colorPalette.purple,
    borderRadius: 20
  }
}));

export const StyledVideoMainBox = styled(Box)(() => ({
  alignItems: 'center',
  backgroundColor: colorPalette.whisper,
  borderRadius: '0.5rem',
  display: 'flex',
  height: '12.5rem',
  justifyContent: 'center',
  marginBottom: '1.25rem',
  video: {
    borderRadius: '0.5rem',
    height: '12.5rem',
    objectFit: 'fill',
    width: '100%'
  }
}));

export const StyledImageBox = styled(Box)(() => ({
  alignItems: 'left',
  display: 'flex',
  flexDirection: 'row',
  gap: '0.625rem',
  justifyContent: 'left',
  marginBottom: '1.25rem',
  img: {
    borderRadius: '0.375rem',
    height: '2.375rem',
    width: '2.375rem'
  },
  cursor: 'pointer'
}));

export const StyledParentContainer = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between'
}));

export const StyledPatentText = styled(Box)(() => ({
  display: 'inline',
  fontSize: '0.875rem',
  fontWeight: 'normal'
}));

export const StyledButton = styled(Button)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    background: colorPalette.linkWater,
    borderRadius: '1.375rem',
    height: '2.5rem',
    left: isMediumScreen ? '-1.625rem' : '0rem',
    minWidth: '0rem',
    top: isMediumScreen ? '-0.625rem' : '0rem',
    width: '2.5rem'
  })
);

export const StyledTypography = styled(Typography)(() => ({
  color: colorPalette.lightGreyish,
  fontSize: '1rem',
  fontWeight: 600
}));

export const StyledShareImage = styled(Avatar)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    height: '1.1875rem',
    left: isMediumScreen ? '0.375rem' : '0.625rem',
    position: 'absolute',
    top: isMediumScreen ? '0.5625rem' : '0.6875rem',
    width: '1.375rem'
  })
);

export const StyledBadge = styled(Badge)(({ theme }) => ({
  position: 'absolute',
  top: '-1.375rem',
  '& .MuiBadge-badge': {
    alignItems: 'center',
    background: colorPalette.lightPurple,
    borderRadius: '50%',
    display: 'flex',
    height: 35,
    justifyContent: 'center',
    width: 35
  }
}));

export const StyledTextBox = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end'
});

export const StyledBoxTypography = styled('span')({
  fontSize: '0.75rem',
  fontWeight: 500,
  marginBottom: '0.625rem',
  whiteSpace: 'nowrap',
  display: 'inline'
});

export const PurpleText = styled(Typography)({
  color: colorPalette.purple,
  whiteSpace: 'nowrap',
  display: 'inline',
  fontWeight: 700
});

export const StyledNumber = styled(Typography)({
  color: colorPalette.silverGrey,
  fontSize: '1.125rem',
  fontWeight: 500
});

export const StyledStakedText = styled(Typography)({
  fontSize: '0.75rem',
  fontWeight: 500
});

export const StyledTagsDetailsText = styled(Typography)({
  color: colorPalette.lightGreyish,
  fontSize: '0.875rem',
  fontWeight: 600,
  lineHeight: '1.067rem',
  textAlign: 'left',
  textUnderlinePosition: 'from-font',
  textDecorationSkipInk: 'none'
});

export const StyledImagesPlusMoreBox = styled(Box)(() => ({
  cursor: 'pointer',
  marginLeft: '8px',
  marginTop: '7px',
  color: colorPalette.purple
}));

export const StyledTitleForGallery = styled(Typography)(() => ({
  variants: 'h6',
  marginBottom: '2rem',
  fontWeight: 'bold',
  textAlign: 'center'
}));
