import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps
} from '@mui/material';

interface StatusTypographyProps extends TypographyProps {
  color?: string;
}

export const StatusTypography = styled(Typography)<StatusTypographyProps>(
  ({ color }) => ({
    color: color,
    fontSize: '.625rem',
    fontWeight: '600',
    lineHeight: '.7619rem',
    textAlign: 'center'
  })
);

interface StatusBoxProps extends BoxProps {
  backgroundColor?: string;
}

export const StatusBox = styled(Box)<StatusBoxProps>(({ backgroundColor }) => ({
  alignItems: 'center',
  backgroundColor: backgroundColor,
  borderRadius: '.375rem',
  display: 'flex',
  height: '2.25rem',
  justifyContent: 'center',
  padding: '0px .1875rem',
  width: '7.8125rem'
}));
