import React, { useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { PsTheme } from '../../theme';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root' | 'title' | 'content';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      padding: '20px 10px'
    },
    title: {
      fontSize: 24,
      fontWeight: 700
    },
    content: {
      paddingTop: 10
    }
  };
});

export type CollapseBlockProps = {
  className?: string;
  title?: string;
  children?: React.ReactElement[] | React.ReactElement;
};

const CollapseBlockView = ({
  className,
  title,
  children
}: CollapseBlockProps) => {
  const { classes } = useStyles();
  let rootClassName = classes.root;
  if (className) {
    rootClassName += ' ' + className;
  }
  return (
    <div className={rootClassName}>
      <div className={classes.title}>{title}</div>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

export const CollapseBlock = styled(CollapseBlockView)({});

export default CollapseBlock;
