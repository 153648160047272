import React, { useContext, useState, useCallback, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { PsFormControl, ShortEvent } from 'components/common/PsFormControl';
import { PsInput } from 'components/common/PsInput';
import { ModalDataContext } from 'contexts/ModalDataContext';
import { DataContext } from 'contexts/DataContext';
import { AuthContext } from 'contexts/AuthContext';
import styles from 'modals/ModalWindow.module.scss';
import { PsTheme } from '../../../theme';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import { makeStyles } from 'tss-react/mui';

const useCheckboxStyles = makeStyles()(() => {
  const psTheme = useTheme() as PsTheme;
  return {
    root: {
      '& a': {
        color: psTheme.palette.primary.main
      }
    }
  };
});

const PsCheckboxView = (props: CheckboxProps) => {
  const { classes } = useCheckboxStyles();
  const newProps = { ...props, classes };
  return <Checkbox color="default" {...newProps} />;
};
const PsCheckbox = styled(PsCheckboxView)({});

const ChooseCompanyStep: React.FC = () => {
  const { user } = useContext(AuthContext);
  const { values, handleInputChange, updateField } =
    useContext(ModalDataContext);
  const { dataProvider } = useContext(DataContext);
  const [title, setTitle] = useState(values.title);
  const [url, setUrl] = useState(values.description);
  const [termsAgree, setTermsAgree] = useState(false);

  const onTermsChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setTermsAgree(event.target.checked);
      updateField('termsAgree', event.target.checked);
    },
    [setTermsAgree, updateField]
  );

  const onURLChange = (e: ShortEvent) => {
    handleInputChange(e);
    setUrl(e.target.value);
  };

  const onTitleChange = (e: ShortEvent) => {
    handleInputChange(e);
    setTitle(e.target.value);
  };

  let image: string | undefined = undefined;
  let username = '';
  if (user && user.files && user.files[0]) {
    image = user.files[0].url;
  }
  if (user) {
    username = user.username || user.email;
  }

  return (
    <div className={styles.stepWrapper}>
      <Typography className={styles.text}>
        Please confirm the following information:
      </Typography>
      <div className={styles.imageHolder}>
        <div>{image ? <img src={image} alt={username} /> : null}</div>
        <div>
          <Typography className={styles.textSmall}>{username}</Typography>
        </div>
      </div>
      <PsFormControl
        //placeholder="Type your problem here"
        label="LinkedIn Profile URL"
      >
        <PsInput name="description" value={url} onChange={onURLChange} />
      </PsFormControl>
      <PsFormControl
        //placeholder="Type your problem here"
        label="Your title:"
      >
        <PsInput name="title" value={title} onChange={onTitleChange} />
      </PsFormControl>

      <FormControlLabel
        className={styles.terms}
        control={
          <PsCheckbox
            checked={termsAgree}
            onChange={onTermsChange}
            name="checkedG"
          />
        }
        label={
          <>
            I attest that I am an authorized representative of{' '}
            <b>{values.parentProblemLabel}</b> authorized to create and manage a
            MindMiner page on behalf of <b>{values.parentProblemLabel}</b>.
          </>
        }
      />
    </div>
  );
};

export default ChooseCompanyStep;
