import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import { Avatar, Typography, Box } from '@mui/material';
import { colorPalette } from 'theme';
import { Constants } from 'utilities/constants';

const colorMapper = {
  [Constants.PROBLEMS]: colorPalette.red,
  [Constants.SOLUTIONS]: colorPalette.forestGreen,
  [Constants.CONCEPTS]: colorPalette.forestGreen,
  [Constants.INVENTIONS]: colorPalette.meteor,
  [Constants.CONTESTS]: colorPalette.purple,
  [Constants.AGREEMENTS]: colorPalette.purple
};

const typeStyles = {
  [Constants.PROBLEMS]: {
    background: colorPalette.azalea
  },
  [Constants.SOLUTIONS]: {
    background: colorPalette.seaGreen
  },
  [Constants.CONCEPTS]: {
    background: colorPalette.seaGreen
  },
  [Constants.INVENTIONS]: {
    background: colorPalette.marzipan
  },
  [Constants.CONTESTS]: {
    background: colorPalette.lavenderMist
  },
  [Constants.AGREEMENTS]: {
    background: colorPalette.lavenderMist
  }
};

export const StyledTab = styled(Tab)(({ theme }) => ({
  flexGrow: 1,
  minWidth: 0,
  alignItems: 'center',
  backgroundColor: colorPalette.white,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  minHeight: '5.625rem !important',
  position: 'relative',
  textTransform: 'none',
  '& .tab-label': {
    fontSize: '1rem',
    marginLeft: '0.5rem',
    color: theme.palette.text.secondary
  },
  '&.Mui-selected': {
    backgroundColor: colorPalette.lightestPurple,
    '& .tab-label': {
      fontWeight: 'bold',
      color: colorPalette.darkPurple
    }
  }
}));

export const StyledTabs = styled(Tabs)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    display: 'flex',
    width: '100%',
    overflow: 'hidden',
    '& .MuiTabs-scroller': {
      overflowX: 'auto',
      flexGrow: 1
    },
    height: isMediumScreen ? '4rem' : '5.625rem',
    borderTopLeftRadius: '0.5rem',
    borderTopRightRadius: '0.5rem'
  })
);

export const StyledBox = styled(Box)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    backgroundColor: colorPalette.brightGray,
    border: `0.0625rem solid ${colorPalette.brightGray}`,
    borderRadius: '0.9375rem',
    boxShadow: '0rem 0.4375rem 5rem rgba(3, 31, 70, 0.05)',
    display: 'flex',
    flexDirection: 'column',
    marginTop: isMediumScreen ? '1%' : '1rem',
    width: '100%'
  })
);

export const StyledTabContent = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%'
});

export const StyledTabLabel = styled(Typography)<{
  isMediumScreen: boolean;
  active: boolean;
}>(({ isMediumScreen, active }) => ({
  fontSize: isMediumScreen ? '0.7963rem' : '1.125rem',
  fontWeight: active ? 600 : 400,
  whiteSpace: 'nowrap',
  color: active ? colorPalette.darkPurple : colorPalette.gray
}));

export const StyledTabIcon = styled(Avatar)<{
  isMediumScreen: boolean;
  tabKey?: string;
}>(({ isMediumScreen, tabKey }) => ({
  color: colorMapper[tabKey],
  backgroundColor: typeStyles[tabKey]?.background,
  height: isMediumScreen ? '1.5rem' : '1.75rem',
  width: isMediumScreen ? '1.5rem' : '1.75rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: isMediumScreen ? '0.75rem' : '0.875rem',
  fontWeight: 'bold',
  marginRight: '0.625rem'
}));
