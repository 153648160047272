import React, { useContext, useState, useCallback } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import {
  ModalDataContext,
  StepComponentProps,
  StepComponentType
} from 'contexts/ModalDataContext';
import { PsTheme } from '../../../theme';
import styles from 'modals/ModalWindow.module.scss';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => {
  const psTheme = useTheme() as PsTheme;
  return {
    root: {
      '& a': {
        color: psTheme.palette.primary.main
      }
    }
  };
});

const PsCheckboxView = (props: CheckboxProps) => {
  const { classes: checkboxStyles } = useStyles();
  const newProps = { ...props, checkboxStyles };
  return <Checkbox color="default" {...newProps} />;
};

const PsCheckbox = styled(PsCheckboxView)({});

type ClassKey = 'root' | 'text';

const useStyles2 = makeStyles()(() => {
  const psTheme = useTheme() as PsTheme;
  return {
    root: {},
    text: {
      fontWeight: 600
    }
  };
});

const AcceptConditionsStepView = () => {
  const { values, errors, setErrors, updateField } =
    useContext(ModalDataContext);
  const { classes } = useStyles2();
  const [termsAgree, setTermsAgree] = useState(values.termsAgree);

  const onTermsChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setTermsAgree(event.target.checked);
      updateField('termsAgree', event.target.checked);
    },
    [setTermsAgree, updateField]
  );

  return (
    <div className={styles.stepWrapper}>
      <Typography
        className={`${styles.textSmall} ${classes.text}`}
        align="center"
      >
        The patent application that you are about to download is for
        informational purposes only. It does not constitute and is not a
        substitute for legal advice. You should consult with a patent attorney
        before making any patent filings or related steps.
      </Typography>
      <Typography
        className={`${styles.textSmall} ${classes.text}`}
        align="center"
      >
        I agree to indemnify and hold harmless MindMiner from any of uses of any
        patent application template that I download from MindMiner.
      </Typography>

      <FormControlLabel
        className={styles.termsRegister}
        control={
          <PsCheckbox
            checked={termsAgree}
            onChange={onTermsChange}
            name="checkedG"
          />
        }
        label={<>I agree to the above terms and conditions</>}
      />
    </div>
  );
};

export const AcceptConditionsStep = styled(AcceptConditionsStepView)(
  {}
) as StepComponentType<StepComponentProps>;

export default AcceptConditionsStep;
