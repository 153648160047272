import React, { useState, useContext, useCallback, useMemo } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import ModalWindow from 'modals/ModalWindow';
import { PsButton } from 'components/common/PsButton';
import { ShortEvent, PsFormControl } from 'components/common/PsFormControl';
import { PsOptionType, PsSelect } from 'components/common/PsSelect';
import { PsSelectedList } from 'components/common/PsSelectedList';
import { ModalContext } from 'contexts/ModalContext';
import { ModalDataContext } from 'contexts/ModalDataContext';
import { DataContext } from 'contexts/DataContext';
import { AuthContext } from 'contexts/AuthContext';
import styles from 'modals/ModalWindow.module.scss';
import { makeStyles } from 'tss-react/mui';

const useButtonClasses = makeStyles()(() => {
  return {
    root: {
      margin: '8px 0',
      fontWeight: 400
    }
  };
});

export const ManageTags: React.FC = () => {
  const { user } = useContext(AuthContext);
  const { closeModal } = useContext(ModalContext);
  const { values, updateField, resetFields } = useContext(ModalDataContext);
  const {
    dataProvider,
    refreshProblemDetail,
    refreshSolutionDetail,
    refreshApplicationDetail
  } = useContext(DataContext);
  const [loading, setLoading] = useState(false);
  const [tagsValue, setTagsValue] = useState<string>(values.tags.join(','));

  const tags: Array<PsOptionType> = useMemo(() => {
    return (user?.userData?.moderator || []).map((item) => ({
      label: item.name,
      value: `${item.id}`
    }));
  }, [user?.userData?.moderator]);

  const buttonClasses = useButtonClasses();

  const onTagsChange = useCallback(
    (event: ShortEvent) => {
      const { value } = event.target;
      setTagsValue(value);
      if (value) {
        updateField('tags', value.split(','));
      } else {
        updateField('tags', []);
      }
    },
    [setTagsValue]
  );

  const handleClickBack = () => {
    closeModal();
    resetFields();
  };

  const handleNextStep = async () => {
    if (!values.productId) {
      return;
    }
    setLoading(true);
    dataProvider
      .updateTags(values.title, values.productId, tagsValue.split(','))
      .then((data) => {
        if (data.error) {
          throw data.error;
        }
        refreshProblemDetail();
        refreshSolutionDetail();
        refreshApplicationDetail();
        closeModal();
        resetFields();
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <ModalWindow title="Manage Tags">
      <div className={styles.stepWrapper}>
        <PsFormControl
          placeholder="Choose Tags to keep"
          label="Choose Tags to keep"
        >
          <PsSelect
            isMulti
            maxMenuHeight={270}
            name="tags"
            onChange={onTagsChange}
            options={tags}
            value={tagsValue}
          />
        </PsFormControl>
        <PsSelectedList
          options={tags}
          value={tagsValue}
          onChange={onTagsChange}
        />
      </div>
      <div className={styles.actions}>
        <PsButton
          disabled={loading}
          onClick={handleNextStep}
          fullWidth
          classes={buttonClasses}
        >
          Save
        </PsButton>
        <PsButton
          disabled={loading}
          onClick={handleClickBack}
          fullWidth
          cancel
          classes={buttonClasses}
        >
          Cancel
        </PsButton>
      </div>
    </ModalWindow>
  );
};

export default ManageTags;
