import React from 'react';
export const DislikeIcon = ({
  size = 22,
  color = '#677890'
}: {
  size?: number;
  color?: string;
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 1.00013H19C19.5304 1.00013 20.0391 1.21085 20.4142 1.58592C20.7893 1.96099 21 2.4697 21 3.00013V10.0001C21 10.5305 20.7893 11.0392 20.4142 11.4143C20.0391 11.7894 19.5304 12.0001 19 12.0001H16V1.00013ZM9.00003 14.0001V18.0001C9.00003 18.7957 9.3161 19.5588 9.87871 20.1214C10.4413 20.684 11.2044 21.0001 12 21.0001L16 12.0001V1.00013H4.72003C4.2377 0.994678 3.76965 1.16373 3.40213 1.47613C3.0346 1.78853 2.79235 2.22323 2.72003 2.70013L1.34003 11.7001C1.29652 11.9867 1.31586 12.2794 1.39669 12.5578C1.47753 12.8363 1.61793 13.0938 1.80817 13.3126C1.99842 13.5314 2.23395 13.7062 2.49846 13.8249C2.76297 13.9436 3.05012 14.0034 3.34003 14.0001H9.00003Z"
        stroke={color}
        strokeWidth="1.77"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DislikeIcon;
