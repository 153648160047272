import { Avatar, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colorPalette } from '../../theme';

export const MainBox = styled(Box)({
  alignItems: 'end',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end'
});

export const SubTitle = styled(Box)({
  fontSize: '11px !important',
  color: colorPalette.black
});

export const StyledAvatar = styled(Avatar)({
  height: '3rem',
  width: '3rem'
});

export const StyledTitle = styled(Box)({
  fontSize: '.8125rem',
  fontWeight: 'bold'
});
