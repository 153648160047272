import React, { ReactNode } from 'react';
import { FunctionComponent, MouseEvent } from 'react';
import { styled, useTheme, darken } from '@mui/material/styles';

import { PsTheme, colorPalette } from '../../theme';
import classnames from 'classnames';
import { CoinsCounter } from 'components/common/CoinsCounter';
import Button from '@mui/material/Button';
import { makeStyles } from 'tss-react/mui';
import { BigCounter } from './BigCounter';

const useStyles = makeStyles()(() => {
  return {
    root: {
      borderRadius: '25px',
      padding: 0,
      paddingRight: '8px',
      background: colorPalette.purple,
      flexShrink: 0,
      display: 'inline-flex',
      justifyContent: 'flex-start',
      '&:hover': {
        background: darken(colorPalette.purple, 0.3)
      },
      '& svg': {
        height: 50,
        width: 50
      }
    },
    buttonText: {
      color: '#FFFFFF',
      fontWeight: 600,
      fontSize: '18px',
      textTransform: 'none',
      flex: 1,
      textAlign: 'center',
      padding: '0 8px'
    },
    fullWidth: {
      width: '100%'
    },
    bigCounter: {
      minWidth: '159px',
      height: '44px'
    },
    small: {
      borderRadius: '16px',
      height: '32px',
      paddingRight: '8px',
      backgroundColor: colorPalette.purple,
      '&:hover': {
        background: colorPalette.purple
      },

      '& svg': {
        height: 42,
        width: 42
      },

      '& $buttonText': {
        fontSize: '15px',
        color: '#632DDD',
        padding: '0 8px'
      }
    },
    rightCounter: {
      background: colorPalette.green,
      margin: '5px 0',
      '& svg': {
        order: 2,
        marginRight: '-10px'
      },
      '&:hover': {
        background: colorPalette.darkGreen
      }
    }
  };
});

type ButtonWithCounterProps = {
  children?: ReactNode;
  coins: number | string;
  className?: string;
  onClick?: (arg: MouseEvent) => void;
  small?: boolean;
  fullWidth?: boolean;
  bigCounter?: boolean;
  rightCounter?: boolean;
  isReferred?: boolean;
  disabled?: boolean;
};

const ButtonWithCounterView: FunctionComponent<ButtonWithCounterProps> = ({
  children,
  coins,
  onClick,
  className = '',
  small = false,
  fullWidth = false,
  bigCounter = false,
  rightCounter = false,
  isReferred = false,
  disabled = false
}) => {
  const { classes } = useStyles();
  return (
    <StyledButton
      variant="contained"
      onClick={onClick}
      color="secondary"
      disabled={disabled}
      isReferred={isReferred}
      className={classnames(classes.root, {
        [className]: className,
        [classes.small]: small,
        [classes.fullWidth]: fullWidth,
        [classes.bigCounter]: bigCounter,
        [classes.rightCounter]: rightCounter
      })}
      disableElevation
    >
      {bigCounter ? (
        <BigCounter coins={coins} />
      ) : (
        <CoinsCounter coins={coins} />
      )}
      <span className={classes.buttonText}>{children}</span>
    </StyledButton>
  );
};

export const ButtonWithCounter = styled(ButtonWithCounterView)({});
export const StyledButton = styled(Button)<{ isReferred?: boolean }>(
  ({ isReferred }) => ({
    ...(isReferred && {
      animation: 'pulse 2s infinite',
      '@keyframes pulse': {
        '0%': {
          boxShadow: '0 0 0 0 rgba(88, 120, 243, 1)'
        },
        '70%': {
          boxShadow: '0 0 0 10px rgba(88, 120, 243, 0)'
        },
        '100%': {
          boxShadow: '0 0 0 50px rgba(88, 120, 243, 0)'
        }
      }
    })
  })
);
