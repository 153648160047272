import React from 'react';
export const ItemIcon = ({
  size = 22,
  color = '#001453',
  className
}: {
  size?: number;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 6C1.89543 6 1 6.89543 1 8V19C1 20.1046 1.89543 21 3 21H15C16.1046 21 17 20.1046 17 19V19H4C3.44772 19 3 18.5523 3 18V6Z"
        fill={color}
      />
      <rect
        x="5.5"
        y="2.5"
        width="15"
        height="14"
        rx="1.5"
        stroke={color}
        strokeLinecap="square"
        strokeLinejoin="bevel"
      />
    </svg>
  );
};

export default ItemIcon;
