/* eslint-disable import/named */
import * as React from 'react';
import { Button, SxProps, SvgIconProps } from '@mui/material';
import { Theme } from '@mui/material/styles';

interface ButtonProps {
  color: string;
  onClick?: () => void;
  height: string;
  Icon: React.FC<SvgIconProps>;
  iconSX: SxProps<Theme>;
  width: string;
}

export const ContainedIconButton: React.FC<ButtonProps> = ({
  color,
  height,
  Icon,
  iconSX,
  width,
  onClick
}) => (
  <Button
    onClick={onClick}
    sx={{
      alignItems: 'center',
      backgroundColor: color,
      borderRadius: '10px',
      display: 'flex',
      height: height,
      justifyContent: 'center',
      minWidth: '40px !important',
      textTransform: 'none',
      width: width,
      ':hover': {
        backgroundColor: color,
        boxShadow: '0 0 6px rgba(0, 0, 0, 0.4)'
      },
      '&.MuiButton-root': {
        padding: '0'
      }
    }}
  >
    <Icon sx={iconSX} />
  </Button>
);
