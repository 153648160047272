import React from 'react';
import { styled, Theme, useTheme } from '@mui/material/styles';

import { PsTheme } from '../../theme';
import { Profile } from '../../authProvider';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'flex-end',
      textAlign: 'right',
      marginRight: 5,
      '& > *': {
        marginRight: 10,
        marginBottom: 10
      }
    }
  };
});

type CardActionsProps = {
  children?: React.ReactNode;
  className?: string;
  user?: Profile;
};

const CardActionsView = ({ className, children, user }: CardActionsProps) => {
  const { classes } = useStyles();
  let rootClassName = classes.root;
  if (className) {
    rootClassName += ' ' + className;
  }

  if (!children) {
    return null;
  }

  return <div className={rootClassName}>{children}</div>;
};

export const CardActions = styled(CardActionsView)({});

export default CardActions;
