import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useDispatch } from 'react-redux';
import { Inventory } from '@mui/icons-material';
import { Box, LinearProgress } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ProblemIcon from 'assets/icons/problems.svg';
import userIcon from 'assets/images/user_67x65.jpeg';
import ArchiveToggleButton from 'components/ArchiveToggleButton';
import AuthContext from 'contexts/AuthContext';
import { ModalComponentsKeys } from 'contexts/ModalContext';
import SolutionModal from 'modals/AddSolution';
import Actions from 'redux-state/actions';
import {
  GetProfilePinnedProblems,
  GetProfileProblems,
  GetProfileProblemsCount,
  GetProfileProblemsLoader
} from 'redux-state/selectors';
import { theme, useIsMediumScreen } from 'theme';
import { Constants } from 'utilities/constants';
import { Problem } from '../CardProblem';
import { Profile } from '../CardProfile';
import { Solution } from '../CardSolution';
import { CustomMenu } from '../CustomMenu';
import { GetActions } from '../CustomMenu/actions';
import DataNotFound from '../DataNotFound';
import { ImageView } from '../ImageView';
import { ListItemView } from '../ListItemView';
import TableView from '../TableView';
import { getHeaders } from '../TableView/getHeaders';
import { ToolTip } from '../ToolTip';
import { ArrowIconButton } from '../common/ArrowIcon';
import { ButtonWithCounter } from '../common/ButtonWithCounter';
import { ButtonContainer, StyledBox, StyledMainBox } from './styles';

interface ItemListProps {
  profile: Profile;
  archiveStatus: boolean;
  handleArchiveToggle: (archive: boolean) => void;
}

interface ListItemViewProps {
  expandedId: string;
  id: string;
  setExpandedId: React.Dispatch<React.SetStateAction<boolean>>;
  solutions: Array<Solution>;
}

const ProblemsView: React.FC<ItemListProps> = ({
  profile,
  archiveStatus,
  handleArchiveToggle
}) => {
  const { user } = useContext(AuthContext);
  const [expandedId, setExpandedId] = useState();
  const [expandedIcon, setExpandedIcon] = useState<boolean>(true);
  const [solutionModalOpen, setSolutionModalOpen] = useState<boolean>(false);
  const isMediumScreen = useIsMediumScreen();

  const toggleExpandText = (id, expandedIcon) => {
    setExpandedId((prevId) => (prevId !== id ? id : null));
    setExpandedIcon(!expandedIcon);
  };

  const handleClickButton = () => {
    setSolutionModalOpen(!solutionModalOpen);
  };

  const dispatch = useDispatch();

  const problems = GetProfileProblems();
  const pinnedProblems: Array<Problem> = GetProfilePinnedProblems() || [];
  const problemLoader = GetProfileProblemsLoader();
  const problemsCount = GetProfileProblemsCount();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const pagination = useMemo(
    () => ({
      page: page,
      perPage: rowsPerPage
    }),
    [page, rowsPerPage]
  );

  const isCurrentUser = !!(user && (user.id === profile.id || user.isAdmin));
  const hasEmptyData = !!(problemsCount === 0 && pinnedProblems.length === 0);

  useEffect(() => {
    if (profile) {
      dispatch(
        Actions.getProfileProblems(profile.id, pagination, null, archiveStatus)
      );
    }
  }, [dispatch, pagination, profile, archiveStatus]);

  useEffect(() => {
    if (problems.length < 1 || archiveStatus) {
      setPage(0);
    }
  }, [problems, archiveStatus]);

  const Item = useCallback(
    ({ solutions, id, expandedId, setExpandedId }: ListItemViewProps) => {
      return (
        <ListItemView
          boxWidth={300}
          expandedId={expandedId}
          id={id}
          setExpandedId={setExpandedId}
          solutions={solutions}
          type={Constants.SOLUTIONS}
          useSameTab={true}
          textWidth={500}
        />
      );
    },
    []
  );

  const headers: Array<string> = useMemo(() => {
    return getHeaders('Problem', user, isCurrentUser);
  }, [isCurrentUser, user]);

  const getActions = useMemo(() => {
    return (item: Problem) => {
      return GetActions({
        item,
        profile,
        type: 'problems',
        pagination
      });
    };
  }, [pagination, profile]);

  const handleUnarchiveToggle = useCallback(
    (item) => {
      if (user) {
        dispatch(
          Actions.setArchiveItem(
            item._id,
            'problems',
            false,
            profile.id,
            false,
            pagination
          )
        );
      } else {
        dispatch(Actions.openAuthModal(true));
      }
    },
    [user, dispatch, profile, pagination]
  );

  const getCells = useMemo(() => {
    return (icon, item) => {
      const baseCells = {
        icon: (
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => toggleExpandText(item._id, expandedIcon)}
          >
            <ArrowIconButton
              expandedId={expandedId}
              id={item._id}
            ></ArrowIconButton>
          </IconButton>
        ),
        item: (
          <ImageView
            iconImage={icon}
            id={item.key}
            placeholderImage={item.files[0]?.url ?? userIcon}
            textWidth={isMediumScreen ? 700 : 1000}
            title={item.teaser}
            type={Constants.PROBLEMS}
            useSameTab={true}
          />
        ),
        button: user && (
          <ButtonWithCounter
            bigCounter
            coins={`+${item.ideaPoints}`}
            onClick={handleClickButton}
            small={isMediumScreen ? true : false}
            sx={{
              [theme.breakpoints.up('md')]: {
                display: 'flex'
              }
            }}
          >
            {Constants.SOLVE}
          </ButtonWithCounter>
        )
      };
      if (isCurrentUser) {
        baseCells['actions'] = item.isArchived ? (
          <Box onClick={() => handleUnarchiveToggle(item)}>
            <ToolTip
              color="primary"
              Icon={<Inventory color="primary" />}
              title="Unarchive"
            />
          </Box>
        ) : (
          <CustomMenu
            actions={getActions(item)}
            item={item}
            itemType={'problems'}
            pagination={pagination}
            title="Problem"
          />
        );
      }

      return baseCells;
    };
  }, [
    expandedId,
    Item,
    isCurrentUser,
    expandedIcon,
    getActions,
    pagination,
    isMediumScreen,
    handleUnarchiveToggle
  ]);

  const rows = {
    component: (icon, item) => getCells(icon, item),
    items: problems,
    pinnedItems: pinnedProblems
  };

  return (
    <StyledMainBox>
      <ButtonContainer>
        <ArchiveToggleButton
          archiveStatus={archiveStatus}
          handleArchiveToggle={handleArchiveToggle}
        />
      </ButtonContainer>
      <StyledBox>
        {problemLoader && <LinearProgress value={10} />}
        {!problemLoader && hasEmptyData && (
          <DataNotFound
            iconUrl={ProblemIcon}
            modal={ModalComponentsKeys.addNewProblem}
            text={Constants.PROBLEM}
          />
        )}
        {!hasEmptyData && (
          <TableView
            expandedId={expandedId}
            headers={headers}
            page={page}
            rows={rows}
            rowsPerPage={rowsPerPage}
            setExpandedId={setExpandedId}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            totalRows={problemsCount - pinnedProblems.length}
            type={'problems'}
          />
        )}
        {solutionModalOpen && (
          <SolutionModal
            onClose={() => setSolutionModalOpen(false)}
            open={solutionModalOpen}
          />
        )}
      </StyledBox>
    </StyledMainBox>
  );
};

export { ProblemsView };
