import React, { useCallback, useContext, useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { ModalDataContext } from 'contexts/ModalDataContext';
import { DataContext } from 'contexts/DataContext';
import { Tag } from 'components/CardTag';
import { Product } from 'components/CardProduct';
import { PsSelect, PsOptionType } from 'components/common/PsSelect';
import { PsSelectedList } from 'components/common/PsSelectedList';
import { PsFormControl, ShortEvent } from 'components/common/PsFormControl';
import styles from 'modals/ModalWindow.module.scss';
import { getValueObj } from 'helpers/common';
import { GetUser } from 'redux-state/selectors';

export const defaultProblemTitle = 'The problem of ';

const ProblemTagsStep: React.FC = () => {
  const user = GetUser();
  const { values, updateField } = useContext(ModalDataContext);
  const { dataProvider } = useContext(DataContext);
  const [tags, setTags] = useState<Array<PsOptionType>>([]);
  const [aiTags, setAiTags] = useState<Array<PsOptionType>>([]);
  const [tagsValue, setTagsValue] = useState<string>(
    values.tagsProblem.join(',')
  );
  const [companies, setCompanies] = useState<Array<PsOptionType>>([]);
  const [companyValue, setCompanyValue] = useState<string>(
    values.company || ''
  );
  const [company, setCompany] = useState<Tag | undefined>(undefined);
  const [products, setProducts] = useState<Array<PsOptionType>>([]);
  const [productValue, setProductValue] = useState<string>(
    values.product || ''
  );
  const [selectedTags, setSelectedTags] = useState<Array<string>>([]);

  useEffect(() => {
    let tagsList: PsOptionType[] | undefined;
    let aiTagsList: PsOptionType[] | undefined;

    dataProvider
      .getList<Tag>('tags', {
        pagination: { page: 1, perPage: 50000 },
        sort: { field: 'name', order: 'ASC' },
        filter: { $custom: { type: 'baseTags' } }
      })
      .then(({ data = [] }) => {
        const list: Array<PsOptionType> = data.map((item: Tag) => ({
          value: `${item.id}`,
          label: item.name || ''
        }));
        if (aiTagsList) {
          setTags([...aiTagsList, ...list]);
        } else {
          setTags(list);
        }
        tagsList = list;
      })
      .catch(() => {
        setTags([]);
      });

    dataProvider
      .tagsSearch(values.titleProblem)
      .then((data) => {
        const nameTags = data.tags.map((tag) => {
          return {
            value: `name_${tag}`,
            label: `${tag}`
          };
        });
        aiTagsList = nameTags;
        if (tagsList) {
          setAiTags(nameTags);
          setTags([...nameTags, ...tagsList]);
        }
      })
      .catch(() => {
        //
      });

    dataProvider
      .getList<Tag>('tags', {
        pagination: { page: 1, perPage: 50000 },
        sort: { field: 'name', order: 'ASC' },
        filter: { type: 'workplace' }
      })
      .then(({ data = [] }) => {
        const list: Array<PsOptionType> = data.map((item: Tag) => ({
          value: `${item.id}`,
          label: item.name || ''
        }));
        setCompanies(list);
      })
      .catch(() => {
        setCompanies([]);
      });
  }, []);

  useEffect(() => {
    if (values.company && values.company !== '--create--') {
      dataProvider
        .getList<Product>('company-products', {
          pagination: { page: 1, perPage: 50000 },
          sort: { field: 'name', order: 'ASC' },
          filter: { company: values.company }
        })
        .then(({ data = [] }) => {
          const list: Array<PsOptionType> = data.map((item: Product) => ({
            value: `${item.id}`,
            label: item.title || ''
          }));
          setProducts(list);
        })
        .catch(() => {
          setProducts([]);
        });

      dataProvider
        .getOne<Tag>('tags', { id: values.company || '' })
        .then(({ data }) => {
          setCompany(data);
        })
        .catch(() => {
          setCompany(undefined);
        });
    } else {
      setProducts([]);
      setCompany(undefined);
    }
  }, [values.company, companyValue, dataProvider]);

  useEffect(() => {
    let newTagsValue = (tagsValue || '').split(',');
    aiTags.forEach(({ value }) => {
      if (newTagsValue.indexOf(value) === -1) {
        newTagsValue.push(value);
      }
    });
    newTagsValue = newTagsValue.filter((item) => item);
    setTagsValue(newTagsValue.join(','));
    setSelectedTags(newTagsValue);
    updateField('tagsProblem', newTagsValue);
  }, [aiTags]);

  const onTagsChange = useCallback(
    (event) => {
      const { value } = event.target;
      const newSelectedTags = value ? value.split(',') : [];
      const initialTags =
        tags.length > 0 ? tags.map((tag) => tag.toString()) : [];
      if (initialTags.length > 0) {
        const remainingInitialTags = initialTags.filter((tag) =>
          newSelectedTags.includes(tag)
        );
        const updatedSelectedTags = remainingInitialTags.concat(
          newSelectedTags.filter((tag) => !initialTags.includes(tag))
        );
        setSelectedTags(updatedSelectedTags);
        setTagsValue(updatedSelectedTags.join(','));
      }

      if (value) {
        updateField('tagsProblem', value.split(','));
      } else {
        updateField('tagsProblem', []);
      }
    },
    [tags]
  );

  const onCompanyChange = useCallback(
    (event: ShortEvent) => {
      const { value, label, rawValue } = event.target;
      setProductValue('');
      updateField('product', undefined);
      updateField('productLabel', undefined);
      updateField('productRaw', undefined);
      setCompanyValue(value);
      updateField('company', value);
      updateField('companyLabel', label);
      updateField('companyRaw', rawValue);
    },
    [updateField, setCompanyValue]
  );

  const onCompanyInputChange = useCallback(
    (text: string) => {
      const last = companies[companies.length - 1];
      const isLastCreate = last && last.value === '--create--';

      if (text) {
        const last = {
          value: '--create--',
          label: `Add New Company: "${text}"`,
          raw: text
        };
        // show last
        if (isLastCreate) {
          const newOptions = companies.slice(0);
          newOptions.pop();
          setCompanies([...newOptions, last]);
        } else {
          setCompanies([...companies, last]);
        }
      } else {
        // hide last
        if (isLastCreate) {
          const newOptions = companies.slice(0);
          newOptions.pop();
          setCompanies(newOptions);
        }
      }
    },
    [setCompanies, companies]
  );

  const onProductChange = useCallback(
    (event: ShortEvent) => {
      const { value, label, rawValue } = event.target;
      setProductValue(value);
      updateField('product', value);
      updateField('productLabel', label);
      updateField('productRaw', rawValue);
    },
    [updateField, setProductValue]
  );

  const onProductInputChange = useCallback(
    (text: string) => {
      const last = products[products.length - 1];
      const isLastCreate = last && last.value === '--create--';

      if (
        company &&
        company.owner &&
        user &&
        company.owner !== (user?.id ?? user?._id)
      ) {
        // hide last
        if (isLastCreate) {
          const newOptions = products.slice(0);
          newOptions.pop();
          setProducts(newOptions);
        }
        return;
      }

      if (text) {
        const last = {
          value: '--create--',
          label: `Add New ${
            values.companyRaw || values.companyLabel
          } Product: "${text}"`,
          raw: text
        };
        // show last
        if (isLastCreate) {
          const newOptions = products.slice(0);
          newOptions.pop();
          setProducts([...newOptions, last]);
        } else {
          setProducts([...products, last]);
        }
      } else {
        // hide last
        if (isLastCreate) {
          const newOptions = products.slice(0);
          newOptions.pop();
          setProducts(newOptions);
        }
      }
    },
    [products, company, user, values.companyRaw, values.companyLabel]
  );

  const problemTitle = (values.titleProblem || '').replace(
    defaultProblemTitle,
    ''
  );

  const showCompany = productValue || !values.introProblemTags;

  return (
    <div className={styles.stepWrapper}>
      {values.introProblemTags ? (
        <Typography className={styles.text}>
          {values.introProblemTags}
        </Typography>
      ) : null}

      <PsFormControl
        placeholder="Choose one or more tags"
        label={
          <>
            Choose Tags for the <b>{problemTitle}</b> problem
          </>
        }
      >
        <PsSelect
          isMulti
          maxMenuHeight={270}
          name="tagsProblem"
          onChange={onTagsChange}
          options={tags}
          value={tagsValue}
          valueObj={getValueObj(tags, selectedTags)}
        />
      </PsFormControl>
      <PsSelectedList
        options={tags}
        value={tagsValue}
        onChange={onTagsChange}
      />

      {showCompany ? (
        <>
          <PsFormControl
            placeholder="Which company?"
            label="Is this problem associated with a company's product?"
          >
            <PsSelect
              maxMenuHeight={270}
              name="company"
              onInputChange={onCompanyInputChange}
              options={companies}
              onChange={onCompanyChange}
              value={companyValue}
              valueObj={getValueObj(companies, companyValue)}
            />
          </PsFormControl>
          <PsSelectedList
            options={companies}
            value={companyValue}
            onChange={onCompanyChange}
          />

          <PsFormControl
            placeholder="Which product?"
            label={
              <>
                Which <b>{values.companyRaw || values.companyLabel}</b> product
                is this problem associated with?
              </>
            }
          >
            <PsSelect
              maxMenuHeight={270}
              name="product"
              onInputChange={onProductInputChange}
              options={products}
              onChange={onProductChange}
              value={productValue}
              valueObj={getValueObj(products, productValue)}
            />
          </PsFormControl>
          <PsSelectedList
            options={products}
            value={productValue}
            onChange={onProductChange}
          />
        </>
      ) : null}
    </div>
  );
};

export default ProblemTagsStep;
