import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import ideaCoinYellowIcon from 'assets/icons/ideaCoinYellow.svg';
import ideaCoinPurpleIcon from 'assets/icons/ideaCoinPurple.svg';
import ideaNftPng from 'assets/icons-new/ideaNFT.svg';
import { EarningView } from 'components/common/EarningView';
import { ItemsType } from '../CardProfile';

type StatsItems = {
  inventions?: number;
  problems?: number;
  solutions?: number;
  earned?: number;
  nft?: number;
  claimed?: number;
};

type CardCommunityProps = {
  ideaPoints?: number;
  stats: StatsItems;
};

const EarningsView = ({
  ideaPoints,
  stats = {
    problems: 10,
    solutions: 5,
    inventions: 20,
    earned: 55,
    nft: 11,
    claimed: 58
  }
}: CardCommunityProps) => {
  const points = ideaPoints ?? 0;
  return (
    <>
      <StyledRowCursor>
        <EarningView
          count={points}
          iconUrl={ideaCoinPurpleIcon}
          type={ItemsType.IDEA_POINTS}
        />
      </StyledRowCursor>
      <StyledRowCursor>
        <EarningView
          count={stats && typeof stats.nft !== 'undefined' ? stats.nft : 100}
          iconUrl={ideaNftPng}
          type={ItemsType.IDEA_NFT}
        />
      </StyledRowCursor>
      <StyledRowCursor>
        <EarningView
          count={
            stats && typeof stats.claimed !== 'undefined' ? stats.claimed : 5
          }
          iconUrl={ideaCoinYellowIcon}
          type={ItemsType.IDEA_COINS}
        />
      </StyledRowCursor>
    </>
  );
};

const StyledRowCursor = styled(Box)({
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap'
});

export default EarningsView;
