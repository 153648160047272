import { Box, LinearProgress, linearProgressClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colorPalette } from 'theme';

export const MainBox = styled(Box)({
  alignItems: 'center',
  display: 'flex'
});

export const ProgressBarBox = styled(Box)({
  marginRight: '1rem',
  width: '100%'
});

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor:
      theme.palette.mode === 'light'
        ? colorPalette.darkPastelPurple
        : colorPalette.darkPastelPurple
  }
}));
