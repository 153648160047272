import { Box, Button, styled, TextField, Typography } from '@mui/material';
import { colorPalette } from 'theme';

export const MainContainerBox = styled(Box)({
  alignItems: 'center',
  backgroundColor: colorPalette.white,
  borderRadius: '0.5rem',
  boxShadow: `0 0.0625rem 0.4375rem ${colorPalette.ebb}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: '1.5rem 0rem',
  padding: '0.5rem ',
  width: 'calc(21vw - 2.35rem)'
});

export const ConceptName = styled(Typography)({
  color: colorPalette.lightBlack,
  fontSize: '.9375rem',
  fontWeight: '600',
  maxWidth: '70%',
  display: '-webkit-box',
  lineHeight: 1.25,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: '4',
  ':hover': { textDecoration: 'underline', cursor: 'pointer' }
});

export const TagContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
  height: '100%',
  justifyContent: 'center'
});

export const TagWrapper = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  gap: '1.25rem',
  justifyContent: 'flex-end'
});

export const AllConceptsWrapper = styled(Box)({
  height: '100vh',
  overflow: 'auto',
  padding: '1.5625rem'
});

export const ConceptButton = styled(Button)({
  backgroundColor: 'rgb(53 168 80 / 20%)',
  border: '.0625rem solid black',
  borderColor: colorPalette.lightGreen,
  borderRadius: '1.5625rem',
  color: colorPalette.lightGreen,
  fontWeight: '600',
  marginTop: '1.25rem',
  width: '100%'
});

interface TagProps {
  bgColor: string;
  borderColor: string;
  color: string;
}

export const Tag = styled(Box)<TagProps>(({ color, borderColor, bgColor }) => ({
  background: bgColor,
  border: `.0625rem solid ${borderColor}`,
  borderRadius: '3.125rem',
  color: color,
  display: 'inline-block',
  fontSize: '.625rem',
  fontWeight: 'bold',
  minWidth: '5.625rem',
  padding: '0.2rem 0.5rem',
  textAlign: 'center'
}));

export const NodeWrapper = styled(Box)({
  alignItems: 'center',
  backgroundColor: colorPalette.white,
  borderRadius: '0.5rem',
  boxShadow: `0 0.0625rem 0.4375rem ${colorPalette.ebb}`,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  margin: '1.25rem 0rem',
  padding: '0.8rem',
  width: '100%'
});

export const StyledTop = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  width: '100%'
});

export const TitleTypography = styled(Typography)({
  fontSize: '1.75rem',
  fontWeight: '700',
  lineHeight: '1'
});

export const SearchField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: colorPalette.purple,
      borderRadius: '.625rem'
    }
  },
  width: '100%'
});

export const HeaderBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '.625rem',
  '@media (max-width:900px)': {
    flexDirection: 'column',
    alignItems: 'flex-start'
  }
});

export const ProgressWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center'
});

export const NoConceptsBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: '.3125rem',
  justifyContent: 'flex-start',
  marginTop: '25%'
});
