import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import Autosuggest from 'react-autosuggest';
import { useDispatch } from 'react-redux';
import { AddCircleOutline } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { LogoWithValue } from 'components/common/LogoWithValue';
import PsChip from 'components/common/PsChipNew';
import { ChallengeIcon } from 'components/icons/ChallengeIcon';
import ProductIcon from 'components/icons/ProductIcon';
import { debounce } from 'lodash';
import { AddNewCompany } from 'modals/AddNewCompany';
import ProductModal from 'modals/AddProduct';
import ImproveModal from 'modals/ImproveModal';
import ModalSmall from 'modals/ModalSmall';
import Actions from 'redux-state/actions';
import { GetCompanies, GetProducts, GetUser } from 'redux-state/selectors';
import { colorPalette, useIsMediumScreen } from 'theme';
import { CATEGORY_URL_MAP, Constants, VARIANT } from 'utilities/constants';
import LabeledIconButton from 'components/common/buttons/LabeledIconButton';
import { GreenCounter } from 'components/common/GreenCounter';
import {
  AutoSuggestWrapper,
  ButtonsWrapper,
  StyledSearchIcon,
  StyledSearchIconBox,
  SuggestionContent,
  SuggestionTitle,
  SuggestionWrapper,
  TitleBox
} from './styledComponents';

interface IIconsMapper {
  [key: string]: React.ReactNode;
}

const iconsMapper: IIconsMapper = {
  company: <ChallengeIcon size={20} />,
  product: <ProductIcon size={20} />,
  [Constants.ADD_ITEM]: (
    <AddCircleOutline
      sx={{
        color: colorPalette.greenHaze,
        width: '1.25rem',
        height: '1.25rem'
      }}
    />
  )
};

export const AutoSearch = () => {
  const dispatch = useDispatch();

  const companies = GetCompanies();
  const products = GetProducts();

  const user = GetUser();

  const [showPrdCreationModal, setShowPrdCreationModal] =
    useState<boolean>(false);
  const [showCompanyCreationModal, setShowCompanyCreationModal] =
    useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<Array<any>>([]);
  const [value, setValue] = useState<string>('');

  const [productId, setProductId] = useState<string>('');
  const [productTitle, setProductTitle] = useState<string>('');

  const isMediumScreen = useIsMediumScreen();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    debounce((newValue) => {
      dispatch(Actions.getProducts(newValue));
      dispatch(Actions.getCompanies(newValue));
    }, 1000),
    []
  );

  useEffect(() => {
    if (value.length > 2) {
      const filteredProducts =
        products.length > 0
          ? [{ label: `${Constants.C_PRODUCTS}:` }, ...products]
          : [{ title: Constants.ADD_PRODUCT, category: Constants.ADD_ITEM }];
      const filteredCompanies =
        companies.length > 0
          ? [{ label: `${Constants.C_COMPANIES}:` }, ...companies]
          : [{ title: Constants.ADD_COMPANY, category: Constants.ADD_ITEM }];

      setSuggestions([...filteredCompanies, ...filteredProducts]);
    }
  }, [products, companies, value.length]);

  const getSuggestionValue = () => value;

  const onSuggestionsFetchRequested = async (data: any) => {
    const { value } = data;
    if (value.length > 2) {
      debouncedSave(value);
    }
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const titleModalMap = useMemo(
    () => ({
      [Constants.ADD_PRODUCT]: setShowPrdCreationModal,
      [Constants.ADD_COMPANY]: setShowCompanyCreationModal
    }),
    []
  );

  const [isImproveModalOpen, setIsImproveModalOpen] = useState<boolean>(false);

  const onImproveClick = (id, title) => {
    setProductId(id);
    setProductTitle(title);
    setIsImproveModalOpen(true);
  };

  const renderSuggestion = useMemo(() => {
    const SuggestionComponent: React.FC<{
      label?: string;
      id: string;
      title?: string;
      type?: string;
      category?: string;
      topTags?: Array<any>;
      ideaPoints?: number;
    }> = ({ category, id, label, type, topTags = [], title, ideaPoints }) => {
      return (
        <SuggestionWrapper>
          {!label && category && iconsMapper[category]}
          <SuggestionContent hasLabel={!!label}>
            <TitleBox
              onClick={() => {
                const urlPrefix = CATEGORY_URL_MAP[category];
                if (urlPrefix && !label) {
                  window.location.replace(`${urlPrefix}${id}`);
                  return;
                }
                if (user) {
                  const openModal = titleModalMap[title];
                  if (openModal) {
                    openModal(true);
                  }
                } else {
                  dispatch(Actions.openAuthModal(true));
                }
              }}
            >
              {label ? (
                <Typography id={id} variant={VARIANT.SUBTITLE1}>
                  {label}
                </Typography>
              ) : null}
              {type ? (
                <Typography style={{ marginRight: 10 }} variant={VARIANT.BODY2}>
                  {type}
                </Typography>
              ) : null}
              {title ? (
                <SuggestionTitle id={id} variant={VARIANT.SUBTITLE1}>
                  {title}
                </SuggestionTitle>
              ) : null}
              {category == Constants.COMPANY && ideaPoints > 0 && (
                <LogoWithValue value={ideaPoints} />
              )}
            </TitleBox>
            {!label &&
              category != Constants.ADD_ITEM &&
              category != Constants.COMPANY && (
                <ButtonsWrapper hasTags={!!topTags.length}>
                  <Box>
                    {topTags.length
                      ? topTags.map((item) => {
                          return (
                            <PsChip
                              onClick={(e: MouseEvent<HTMLDivElement>) => {
                                e.stopPropagation();
                                window.location.replace(
                                  CATEGORY_URL_MAP.company + item._id
                                );
                              }}
                              key={item.key}
                              label={item.name}
                              sx={{ height: '1.4375rem', maxWidth: '5rem' }}
                            />
                          );
                        })
                      : null}
                  </Box>
                  {ideaPoints > 0 ? (
                    <LogoWithValue value={ideaPoints} />
                  ) : (
                    <LabeledIconButton
                      icon={
                        <GreenCounter
                          coins={Constants.PLUS_TWO}
                          coinColor={colorPalette.white}
                          bgColor={colorPalette.greenHaze}
                          textColor={colorPalette.white}
                          width={isMediumScreen ? '.875rem' : '1.125rem'}
                          height={isMediumScreen ? '.875rem' : '1.125rem'}
                        />
                      }
                      variant={VARIANT.CONTAINED}
                      text={Constants.IMPROVE}
                      btnColor={colorPalette.greenHaze}
                      buttonSx={{ padding: '.3125rem' }}
                      typographySx={{
                        fontSize: isMediumScreen ? '.625rem' : '.75rem'
                      }}
                      width={isMediumScreen ? '5rem' : '6.5625rem'}
                      onClick={() => onImproveClick(id, title)}
                    />
                  )}
                </ButtonsWrapper>
              )}
          </SuggestionContent>
        </SuggestionWrapper>
      );
    };
    return SuggestionComponent;
  }, [dispatch, isMediumScreen, titleModalMap, user]);

  const onChange = (event: any, data: any) => {
    const { newValue } = data;
    setValue(newValue);
  };

  const inputProps = {
    onChange: onChange,
    value
  };

  const renderInputComponent = (inputProps) => (
    <div style={{ position: 'relative' }}>
      <input {...inputProps} />
      <StyledSearchIconBox
        isMediumScreen={isMediumScreen}
        onClick={inputProps.onSearchIconClick}
      >
        <StyledSearchIcon />
      </StyledSearchIconBox>
    </div>
  );

  return (
    <AutoSuggestWrapper isMediumScreen={isMediumScreen}>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={{
          ...inputProps,
          placeholder: Constants.SEARCH_BAR_PLACEHOLDER,
          className: 'autosuggest-input'
        }}
        highlightFirstSuggestion
        renderInputComponent={renderInputComponent}
      />
      {showPrdCreationModal && (
        <ProductModal
          open={showPrdCreationModal}
          initialValue={value}
          onClose={() => setShowPrdCreationModal(false)}
        />
      )}
      {showCompanyCreationModal && (
        <ModalSmall
          isOpen={showCompanyCreationModal}
          onClose={() => setShowCompanyCreationModal(false)}
        >
          <AddNewCompany
            setOpen={setShowCompanyCreationModal}
            initialValue={value}
          />
        </ModalSmall>
      )}
      {isImproveModalOpen && (
        <ImproveModal
          isImproveModalOpen={isImproveModalOpen}
          modalType={Constants.PRODUCT}
          productId={productId}
          setIsImproveModalOpen={setIsImproveModalOpen}
          title={productTitle}
        />
      )}
    </AutoSuggestWrapper>
  );
};
