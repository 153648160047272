import { GetGraphData } from '../redux-state/selectors';
import { isObjectId } from '../helpers';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'redux-state/actions';

interface FetchGrpahType {
  type: string;
  id: string;
  mapType: string;
}

export const useFetchGraph = ({ type, id, mapType }: FetchGrpahType) => {
  const dispatch = useDispatch();
  const graphData = useSelector(() => GetGraphData());

  const fetchChildrenGraph = useCallback(
    (id, type) => {
      dispatch(Actions.getChildrenGraph(id, type, mapType));
    },
    [dispatch, mapType]
  );

  const isGraphExists = useMemo(
    () => graphData && Object.keys(graphData).length,
    [graphData]
  );
  useEffect(() => {
    if (
      !isGraphExists ||
      id !== (isObjectId(id) ? graphData.id : graphData.key)
    ) {
      dispatch(Actions.getGraph(id, type, mapType));
    }
  }, [dispatch, id, type, graphData, isGraphExists, mapType]);

  return { graphData, fetchChildrenGraph };
};
