import { styled, Button, Box } from '@mui/material';
import { colorPalette } from 'theme';

export const StyledButton = styled(Button)<{ goldenButton: boolean }>(
  ({ theme, goldenButton }) => ({
    background: `linear-gradient(180deg, transparent 0%, transparent 100%), ${goldenButton ? colorPalette.amberOrange : colorPalette.backgroundPurple}`,
    border: `1px solid ${goldenButton ? colorPalette.amberOrange : colorPalette.purple}`,
    borderRadius: '0.4888rem',
    color: goldenButton ? colorPalette.white : colorPalette.purple,
    height: 60,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
    boxShadow: 'none',
    textTransform: 'none',
    [theme.breakpoints.down('md')]: {
      fontSize: '2px'
    },
    '&:hover': {
      background: goldenButton
        ? colorPalette.amberOrange
        : colorPalette.lightestPurple,
      opacity: 0.9
    }
  })
);

export const StyledInlineBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center'
}));

export const StartText = styled('span')`
  margin-left: 8px;
  font-weight: 600;
  font-size: 1.25rem;
`;

export const CenterText = styled('span')`
  margin-right: 8px;
  font-weight: normal;
  font-size: 0.875rem;
`;

export const EndText = styled('span')`
  font-weight: 700;
  font-size: 1.125rem;
`;
