import Actions from 'redux-state/actions';
import { Constants } from 'utilities/constants';

export const addNewConcept = (
  dispatch,
  user,
  selected = [],
  problems = [],
  title = ''
) => {
  if (user) {
    const appInfo = {
      title: title && title !== '' ? `${Constants.IMPROVED} ${title}` : '',
      selected: selected,
      problems: problems
    };
    dispatch(Actions.createConcept(appInfo));
  } else {
    dispatch(Actions.openAuthModal(true));
  }
};
