import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import Actions from 'redux-state/actions';
import FollowButton from 'components/common/FollowButton';
import AuthContext from 'contexts/AuthContext';
import { useIsMediumScreen } from 'theme';
import { Constants, TAG_TYPES } from 'utilities/constants';
import { Tag } from '../CardTag';
import {
  ContentBox,
  DescriptionTextSmall,
  FollowContainer,
  InfoContainer,
  RoundedAvatar,
  TitleText
} from './styles';

interface infoViewProps {
  image: string;
  isOwnCommunity?: boolean;
  title: string;
  tagId: string | number;
  tag?: Tag;
}
const InfoView = ({
  title,
  isOwnCommunity,
  image,
  tagId,
  tag
}: infoViewProps) => {
  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);
  const [followersCount, setFollowersCount] = useState<number>(
    tag.followers.length
  );
  const [isFollowing, setIsFollowing] = useState<boolean>(
    tag.followers.includes(user?.id)
  );
  const [actionType, setActionType] = useState(
    tag.followers.includes(user?.id) ? Constants.UNFOLLOW : Constants.FOLLOW
  );

  const isMediumScreen = useIsMediumScreen();

  const onFollowBtnClick = (tagId: string) => {
    if (user) {
      const newIsFollowing = !isFollowing;
      dispatch(Actions.getTagFollowers(tagId, user.id, actionType));
      setActionType(
        actionType === Constants.FOLLOW ? Constants.UNFOLLOW : Constants.FOLLOW
      );
      setFollowersCount((prevCount) =>
        newIsFollowing ? prevCount + 1 : Math.max(0, prevCount - 1)
      );
      setIsFollowing(newIsFollowing);
    }
  };

  return (
    <InfoContainer>
      <RoundedAvatar
        alt={title}
        isMediumScreen={isMediumScreen}
        src={image}
        variant="rounded"
      />
      <ContentBox ml={1.5}>
        <TitleText isMediumScreen={isMediumScreen}>{title}</TitleText>
        <FollowContainer isMediumScreen={isMediumScreen}>
          {!isMediumScreen &&
            tag.type !== TAG_TYPES.LOCATION &&
            tag.type !== TAG_TYPES.SCHOOL && (
              <FollowButton
                actionType={actionType}
                id={tagId}
                isOwnCommunity={isOwnCommunity}
                type="tag"
                onClick={() => onFollowBtnClick(tagId as string)}
              />
            )}
          <DescriptionTextSmall isMediumScreen={isMediumScreen}>
            {followersCount ? `${followersCount} Followers` : `0 Followers`}
          </DescriptionTextSmall>
        </FollowContainer>
      </ContentBox>
    </InfoContainer>
  );
};

export default InfoView;
