import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export interface IBreadCrumbProps {
  icon?: BreadCrumbIcon;
  label?: string;
  url?: string;
}

export interface IBreadCrumb {
  breadCrumbOptions: IBreadCrumbProps[];
}
export interface ILocation {
  state: IBreadCrumb;
}

export const enum BreadCrumbIcon {
  MIND_MINER_ICON = 'MindMinerIcon'
}

export const useBreadCrumb = ({ breadCrumbOptions = [] }: IBreadCrumb) => {
  const { state = { breadCrumbOptions } }: ILocation = useLocation();
  return useMemo(
    () => (state?.breadCrumbOptions ?? []).concat(breadCrumbOptions),
    [breadCrumbOptions, state?.breadCrumbOptions]
  );
};
