import React from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

interface PipeDividerProps {
  size?: string;
}
const PipeDivider: React.FC<PipeDividerProps> = ({ size }) => {
  return size === 'long' ? (
    <StyledPipeDivider />
  ) : (
    <div className="pipe_divider">|</div>
  );
};

const StyledPipeDivider = styled(Box)({
  width: '1.05px',
  height: '50px',
  backgroundColor: 'rgba(0, 0, 0, 0.15)'
});

export default PipeDivider;
