import React from 'react';
import { Box } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import SimpleIconButton from 'components/SimpleIconButton';
import {
  StyledHeaderText,
  StyledHeaderWrapper,
  StyledSubHeaderText
} from './StyledComponents';

interface HeaderProps {
  isIdeaMapPopUp?: boolean;
  label?: string | JSX.Element;
  modalType?: string;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  parentNodeTitle?: string;
  showCrossIcon?: boolean;
  subHeading?: string | JSX.Element;
}

export const Header: React.FC<HeaderProps> = ({
  isIdeaMapPopUp,
  label,
  modalType,
  onClose,
  parentNodeTitle,
  showCrossIcon
}) => {
  return (
    <Box>
      <StyledHeaderWrapper>
        {label && <StyledHeaderText variant="h5">{label}</StyledHeaderText>}
        {showCrossIcon && onClose && (
          <SimpleIconButton
            Icon={CancelOutlinedIcon}
            color="primary"
            onClick={onClose}
          />
        )}
      </StyledHeaderWrapper>
      {isIdeaMapPopUp && (
        <StyledSubHeaderText variant="h5" type={modalType}>
          {parentNodeTitle}
        </StyledSubHeaderText>
      )}
    </Box>
  );
};
