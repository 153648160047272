import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { ItemIcon } from 'components/icons/ItemIcon';
import { PsTheme } from '../../theme';
import { Link } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'item' | 'itemTop' | 'removeBtn' | 'itemBottom' | 'link';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    item: {
      marginTop: 10,
      userSelect: 'none',
      '&:first-child': {
        marginTop: 0
      }
    },
    itemTop: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    removeBtn: {
      appearance: 'none',
      margin: '0 5px 0 0',
      padding: '3px 3px 1px 3px',
      background: 'none',
      border: 'none',
      borderRadius: 6,
      '&:not(:disabled):hover': {
        color: psTheme.palette.primary.main,
        cursor: 'pointer'
      }
    },
    itemBottom: {
      '& .drawer-list': {
        padding: '10px 0 0 35px'
      },
      '& > button': {
        margin: '10px 0 10px',
        lineHeight: '1.3'
      }
    },
    link: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexGrow: 1,
      padding: 6,
      border: '1px solid #001453',
      borderRadius: 4,
      color: psTheme.palette.primary.main,
      textDecoration: 'none',
      cursor: 'pointer',
      '& > svg': {
        flexShrink: 0,
        marginRight: 6
      },
      '& > .collapse-icon': {
        height: 24,
        cursor: 'pointer',
        transition: 'background 0.3s',
        borderRadius: '50%'
      },
      '& > .collapse-icon:hover': {
        background: '#eee'
      },
      '& > .collapse-icon.expanded > svg': {
        transform: 'rotate(180deg)'
      }
    }
  };
});

type DrawerModalItemProps = {
  children?: React.ReactNode;
  title: string | undefined;
  url?: string;
  collapsed?: boolean;
  editable?: boolean;
  onRemove?: () => Promise<void>;
};

const DrawerModalItemView = ({
  children,
  title = '',
  url = '',
  collapsed,
  editable,
  onRemove
}: DrawerModalItemProps) => {
  const { classes } = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);

  const onCollapseClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setExpanded(!expanded);
  };

  const onRemoveClick = async (event: React.MouseEvent) => {
    event.preventDefault();
    setRemoveLoading(true);
    if (onRemove) {
      try {
        await onRemove();
      } catch (error) {
        console.error(error);
      }
    }
    setRemoveLoading(false);
  };

  return (
    <div className={classes.item}>
      <div className={classes.itemTop}>
        <span>
          {editable ? (
            <button
              title="Remove Solution"
              className={classes.removeBtn}
              onClick={onRemoveClick}
            >
              <RemoveCircleOutlineIcon />
            </button>
          ) : null}
        </span>
        <Link to={url}>
          <a target="_blank" className={classes.link}>
            <ItemIcon />
            <span>{title}</span>
            {collapsed ? (
              <span
                className={`collapse-icon ${expanded ? 'expanded' : ''}`}
                onClick={onCollapseClick}
              >
                <ExpandMoreIcon />
              </span>
            ) : (
              <span />
            )}
          </a>
        </Link>
      </div>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div className={classes.itemBottom}>{children}</div>
      </Collapse>
    </div>
  );
};

export const DrawerModalItem = styled(DrawerModalItemView)({});

export default DrawerModalItem;
