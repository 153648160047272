import React, { useContext, useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { styled, useTheme } from '@mui/material/styles';

import { PsButton } from 'components/common/PsButton';
import { PsInputLabel } from 'components/common/PsInputLabel';
import { PsFormControl, ShortEvent } from 'components/common/PsFormControl';
import { PsInput } from 'components/common/PsInput';
import {
  ImageWithZoom,
  ZoomModal,
  GenerateAIModal
} from 'components/ImageField';
import { ModalDataContext } from 'contexts/ModalDataContext';
import { DataContext } from 'contexts/DataContext';
import {
  Picture,
  convertFileToBase64,
  convertUrlToBase64,
  getFileTitle,
  updateFileName
} from '../../helpers';

import { PsTheme } from '../../theme';
import uploadImage from 'assets/images/cloud.svg';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {},
    pictureHolder: {
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'space-between',
      minHeight: 70
    },
    pictureButtons: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '50%',
      paddingRight: 5,
      '& button': {
        width: '100%',
        marginBottom: 7
      },
      '& button:last-child': {
        marginBottom: 0
      }
    },
    pictureInfo: {
      paddingLeft: 10,
      color: psTheme.palette.primary.main
    },
    pictureTitle: {
      paddingTop: 10,
      '& label': {
        fontSize: 16
      }
    },
    dropzoneContainer: {
      position: 'relative',
      outline: 0,
      background: '#faf8fd',
      padding: 15,
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 8,
      border: '1px dashed #818fa3',
      fontSize: 16,
      lineHeight: '20px',
      color: psTheme.palette.primary.main,
      cursor: 'pointer',
      '&.has-picture': {
        cursor: 'zoom-in'
      }
    },
    dropzoneLabel: {
      marginBottom: 10
    },
    dropzoneText: {
      marginTop: 8
    },
    dropzonePreview: {
      display: 'block',
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      objectFit: 'cover'
    },
    loading: {
      marginBottom: 5
    }
  };
});

type ChangeEvent = {
  target: {
    name: string;
    value: Array<Picture>;
  };
};

type ImageFieldProps = {
  name?: string;
  value?: Array<Picture>;
  onChange?: (event: ChangeEvent) => void;
  label?: string;
  supportDraw?: boolean;
  aiText?: string;
};

const ImageFieldView = ({
  name,
  onChange,
  label,
  aiText = '',
  supportDraw = false
}: ImageFieldProps) => {
  const { classes } = useStyles();
  const { dataProvider, showDraw } = useContext(DataContext);
  const { values } = useContext(ModalDataContext);

  const [picture, setPicture] = useState<Picture | undefined>(undefined);
  const [pictureTitle, setPictureTitle] = useState('');
  const [pictureType, setPictureType] = useState('');

  const [zoomOpen, setZoomOpen] = useState(false);

  const [aiLoading, setAiLoading] = useState(false);
  const [aiPicture, setAiPicture] = useState<Picture | undefined>(undefined);
  const [generateAiOpen, setGenerateAiOpen] = useState(false);

  const { acceptedFiles, getInputProps, open } = useDropzone({
    multiple: false,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'image/jpg': ['.jpg'],
      'image/gif': ['.gif'],
      'image/svg+xml': ['.svg']
    }
  });

  useEffect(() => {
    if (!acceptedFiles.length) {
      return;
    }
    Promise.all(acceptedFiles.map(convertFileToBase64)).then(([item]) => {
      const { file, base64 } = item;
      const picture = {
        contentType: file.type,
        title: file.name,
        url: base64
      };
      setPicture(picture);
      setPictureTitle(getFileTitle(file.name));
      if (onChange) {
        onChange({
          target: {
            name: name || `image-field-${Math.random()}`,
            value: [picture]
          }
        });
      }
    });
  }, [acceptedFiles, name, onChange]);

  useEffect(() => {
    if (!values.imageBase64) {
      return;
    }
    const picture = {
      contentType: 'image/png',
      title: 'draw.png',
      url: values.imageBase64
    };
    setPicture(picture);
    setPictureTitle('draw');
    if (onChange) {
      onChange({
        target: {
          name: name || `image-field-${Math.random()}`,
          value: [picture]
        }
      });
    }
  }, [values.imageBase64, name, onChange]);

  const onPictureTitleChange = useCallback(
    (e: ShortEvent) => {
      const newTitle = e.target.value;
      setPictureTitle(newTitle);
      if (picture) {
        const newFileName = updateFileName(picture.title, newTitle);
        if (onChange) {
          onChange({
            target: {
              name: name || `image-field-${Math.random()}`,
              value: [
                {
                  ...picture,
                  title: newFileName
                }
              ]
            }
          });
        }
      }
    },
    [setPictureTitle, onChange, name, picture]
  );

  const onContainerClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      if (picture) {
        // console.log('zoom');
        // setModalOpen(true);
      } else {
        open();
      }
    },
    [open, picture]
  );

  const onDrawClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      showDraw();
    },
    [showDraw]
  );

  const onZoomClick = ({
    naturalHeight,
    naturalWidth
  }: {
    naturalHeight: number;
    naturalWidth: number;
  }) => {
    const winSize = window.innerHeight / window.innerWidth;
    const pictSize = naturalHeight / naturalWidth;

    setPictureType(winSize > pictSize ? 'h' : 'v');
    setZoomOpen(true);
  };

  const onZoomClose = () => {
    setZoomOpen(false);

    setTimeout(() => {
      setPictureType('');
    }, 1000);
  };

  const onGenerateClick = () => {
    if (!aiText) {
      return;
    }
    setAiLoading(true);

    dataProvider
      .imageSearch(aiText)
      .then((data) => {
        const aiPicture = {
          contentType: 'image/jpeg',
          title: `${aiText}.jpeg`,
          url: data.url
        };
        setAiPicture(aiPicture);
        setGenerateAiOpen(true);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setAiLoading(false);
      });
  };

  const onGeneratePicture = (picture?: Picture) => {
    setPicture(picture);
    setPictureTitle(aiText);
    setAiPicture(undefined);
    if (onChange && picture) {
      onChange({
        target: {
          name: name || `image-field-${Math.random()}`,
          value: [picture]
        }
      });
    }
  };

  const onGenerateClose = () => {
    setGenerateAiOpen(false);
  };

  return (
    <div className={classes.root}>
      {label ? <PsInputLabel label={label} /> : null}
      {/*{aiLoading ? (*/}
      {/*  <Typography className={classes.loading}>*/}
      {/*    Generating AI image &nbsp;*/}
      {/*    <CircularProgress size={15} className="loader" />*/}
      {/*  </Typography>*/}
      {/*) : null}*/}
      <div className={classes.pictureHolder}>
        <div className={classes.pictureButtons}>
          {aiText ? (
            <PsButton
              smallest
              disabled={aiLoading}
              loading={aiLoading}
              onClick={onGenerateClick}
            >
              Generate AI Image
            </PsButton>
          ) : null}
          <PsButton smallest onClick={open}>
            Upload
          </PsButton>

          {supportDraw ? (
            <PsButton smallest onClick={onDrawClick}>
              Draw
            </PsButton>
          ) : null}
        </div>

        <div
          className={`${classes.dropzoneContainer} ${
            picture ? 'has-picture' : ''
          }`}
          onClick={onContainerClick}
        >
          <input name="filesSolution" {...getInputProps()} />
          <img src={uploadImage} alt="cloud icon" />
          <ImageWithZoom
            className={classes.dropzonePreview}
            src={picture?.url}
            alt={pictureTitle}
            onZoomClick={onZoomClick}
          />
          <span className={classes.dropzoneText}>Add Photo</span>
        </div>
        {/*{supportDraw ? (*/}
        {/*  <div className={classes.pictureInfo}>*/}
        {/*    <InfoIcon />*/}
        {/*  </div>*/}
        {/*) : null}*/}
      </div>

      <PsFormControl
        className={classes.pictureTitle}
        label="Image title"
        labelLight
      >
        <PsInput
          name="pictureTitle"
          onChange={onPictureTitleChange}
          value={pictureTitle}
        />
      </PsFormControl>

      <ZoomModal
        open={zoomOpen}
        onClose={onZoomClose}
        picture={picture}
        pictureType={pictureType}
      />

      <GenerateAIModal
        open={generateAiOpen}
        onClose={onGenerateClose}
        picture={aiPicture}
        onPicture={onGeneratePicture}
        onGenerate={onGenerateClick}
        loading={aiLoading}
      />
    </div>
  );
};

export const ImageField = styled(ImageFieldView)({});

export default ImageField;
