import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Actions from 'redux-state/actions';
import { Constants } from 'utilities/constants';
import PsFormControl from 'components/common/PsFormControl';
import PsSearchSelect from 'components/common/PsSearchSelect';
import { OptionType } from 'modals/Common/interface';
import { GetRelatedCompanies } from 'redux-state/selectors';
import { debounce } from 'lodash';

interface CompanyFieldProps {
  companyId?: string | number;
  companyName?: string;
  isIdeaMapPopUp?: boolean;
  selectedCompany?: OptionType | null;
  setSelectedCompany?: React.Dispatch<React.SetStateAction<OptionType | null>>;
}
export const CompanyField: React.FC<CompanyFieldProps> = ({
  companyId,
  companyName,
  isIdeaMapPopUp,
  selectedCompany,
  setSelectedCompany
}) => {
  const dispatch = useDispatch();
  const [companyPage, setCompanyPage] = useState<number>(1);
  const companies = GetRelatedCompanies() || { data: [] };
  const [resetOptions, setResetOptions] = useState<boolean>(false);
  const [searchFilter, setSearchFilter] = useState<string>('');

  useEffect(() => {
    dispatch(
      Actions.getRelatedCompanies({
        pagination: { page: companyPage, perPage: 10 },
        sort: { field: 'name', order: 'ASC' },
        name: searchFilter
      })
    );
  }, [dispatch, companyPage, searchFilter]);

  useEffect(() => {
    if (companyId) {
      setSelectedCompany({ value: companyId, label: companyName });
    }
  }, [companyId, companyName, setSelectedCompany]);

  const handleCompanyChange = (selected: OptionType | null) => {
    setSelectedCompany(selected);
  };

  const placeholder = isIdeaMapPopUp
    ? Constants.COMPANY_FIELD_LABEL
    : Constants.SELECT_COMPANY_LABEL;
  const formatCreateLabel = (inputValue: string) =>
    `Add New Company: ${inputValue}`;

  const handleSearchChange = useCallback(
    debounce((value: string) => {
      setResetOptions(false);
      setCompanyPage(1);
      setSearchFilter(value);
    }, 50),
    []
  );

  const handleInputChange = useCallback(
    (value: string) => {
      setResetOptions(true);
      handleSearchChange(value);
    },
    [handleSearchChange]
  );

  return (
    <PsFormControl
      label={placeholder}
      placeholder={Constants.COMPANY_FIELD_PLACEHOLDER}
      required
    >
      <PsSearchSelect
        createableValue={selectedCompany}
        formatCreateLabel={formatCreateLabel}
        onCreateableChange={handleCompanyChange}
        // onCreateOption={handleCreateCompany}
        optionsList={companies.data}
        page={companyPage}
        setPage={setCompanyPage}
        handleInputChange={handleInputChange}
      />
    </PsFormControl>
  );
};
