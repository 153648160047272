import React, { useEffect, useMemo, useRef, useState } from 'react';
import type { FunctionComponent, MouseEvent } from 'react';
import { useDispatch } from 'react-redux';
import { Avatar, Drawer, LinearProgress, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { styled, useTheme } from '@mui/material/styles';
import { Application } from 'components/CardApplication';
import { Challenge } from 'components/CardChallenge';
import { Contest } from 'components/CardContest';
import { Problem } from 'components/CardProblem';
import { Product } from 'components/CardProduct';
import { Solution } from 'components/CardSolution';
import { Link } from 'react-router-dom';
import { PsTheme } from 'theme';
import { makeStyles } from 'tss-react/mui';
import { Constants, VARIANT } from 'utilities/constants';
import ContestIcon from 'assets/icons/ContestIcon';
import InventionIcon from 'assets/icons/InventionIcon';
import ProductIcon from 'assets/icons/ProductIcon';
import ProblemIcon from 'assets/icons/ProblemIcon';
import SolutionIcon from 'assets/icons/SolutionIcon';
import Actions from 'redux-state/actions';
import useRouter from '../../hooks/useRouter';
import { ContestsIcon } from './icons/ContestsIcon';
import { HomeIcon } from './icons/HomeIcon';
import { InventionsIcon } from './icons/InventionsIcon';
import { ProblemsIcon } from './icons/ProblemsIcon';
import { ProductsIcon } from './icons/ProductsIcon';
import { SolutionsIcon } from './icons/SolutionsIcon';
import {
  GetProfileContests,
  GetProfileContestsLoader,
  GetProfileInventions,
  GetProfileInventionsLoader,
  GetProfileProblems,
  GetProfileProblemsLoader,
  GetProfileProducts,
  GetProfileProductsLoader,
  GetProfileSolutions,
  GetProfileSolutionsLoader,
  GetUser
} from 'redux-state/selectors';

interface Props {
  active: string;
  classes?: any;
  isHomePage?: boolean;
  setFilterType?: React.Dispatch<React.SetStateAction<string[]>>;
}

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      width: '380px',
      zIndex: 10
    },
    img: {
      width: '60px',
      height: '60px',
      objectFit: 'cover'
    },
    link: {
      cursor: 'pointer'
    }
  };
});

const StyledBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    gap: '16px',
    width: '5rem',
    height: '100%',
    top: '0',
    paddingTop: '120px',
    flexDirection: 'column'
  },
  position: 'fixed',
  width: '100%',
  height: '4.0625rem',
  backgroundColor: 'white',
  left: '0',
  top: '5rem',
  justifyContent: 'space-between',
  borderBottom: '1px solid #E6E6E6',
  zIndex: '11',
  display: 'flex',
  flexDirection: 'row',
  paddingTop: '0',
  alignItems: 'center',
  gap: '0',
  padding: '0.8rem',
  borderRight: '1px solid #E6E6E6'
}));

const StyledLinearContainer = styled(Box)({
  position: 'sticky',
  top: 0,
  width: '100%'
});

const StyledItemContainer = styled(Box)({
  alignItems: 'center',
  borderRadius: '7px',
  display: 'flex',
  height: '60px',
  justifyContent: 'center',
  minWidth: '60px',
  mr: 1,
  overflow: 'hidden'
});

const StyledButton = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: '3.125rem',
    height: '3.125rem'
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '2.5rem',
  height: '2.5rem',
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  border: '2px solid #5B38D5',
  borderRadius: '50%',
  cursor: 'pointer',
  marginTop: 'auto',
  marginBottom: '0.75rem',
  overflow: 'hidden'
}));

const SideMenuView: FunctionComponent<Props> = ({
  active = '',
  isHomePage,
  setFilterType
}) => {
  const user = GetUser();
  const [items, setItems] = useState<
    Array<Product | Problem | Solution | Challenge | Contest | Application>
  >([]);
  const [activeMenu, setActiveMenu] = useState<string>(Constants.ALL);
  const [drawerOpen, toggleDrawer] = useState<boolean>(false);
  const { classes } = useStyles();
  const Router = useRouter();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const lastItemRef = useRef(null);
  const [lastItemIndex, setLastItemIndex] = useState<number>(0);
  const problems = GetProfileProblems();
  const problemsLoader = GetProfileProblemsLoader();
  const solutions = GetProfileSolutions();
  const solutionsLoader = GetProfileSolutionsLoader();
  const inventions = GetProfileInventions();
  const inventionsLoader = GetProfileInventionsLoader();
  const products = GetProfileProducts();
  const productsLoader = GetProfileProductsLoader();
  const contests = GetProfileContests();
  const contestsLoader = GetProfileContestsLoader();
  const router = useRouter();
  const { pathname } = router;
  const { resetToken } = router.query;

  const image =
    user?.files && user?.files.length
      ? user?.files[0]
      : {
          url: Constants.PLACEHOLDER_IMAGE,
          title: Constants.PLACEHOLDER
        };

  const itemTypeModelMap = {
    [Constants.PROBLEM]: Constants.PROBLEMS,
    [Constants.SOLUTION]: Constants.SOLUTIONS,
    [Constants.APPLICATION]: Constants.INVENTIONS,
    [Constants.PRODUCT]: Constants.PRODUCTS,
    [Constants.CONTEST]: Constants.CONTESTS
  };

  const handleItemClick = (event: MouseEvent, item: string) => {
    let sameItem = false;
    let homeItem = false;

    setActiveMenu(item);

    if (activeMenu === item) {
      sameItem = true;
    }
    if (item === Constants.ALL) {
      homeItem = true;
    }

    if (homeItem && !isHomePage) {
      handleCloseDrawer();
      setRowsPerPage(10);
      Router.push('/feeds');
    } else if (!drawerOpen && !isHomePage) {
      setRowsPerPage(10);
      toggleDrawer(true);
    } else if (drawerOpen && sameItem && !isHomePage) {
      setRowsPerPage(10);
      toggleDrawer(false);
    }
    if (isHomePage) {
      setFilterType([item]);
    }
  };

  const handleClickItem = () => {
    toggleDrawer(false);
  };

  const handleCloseDrawer = () => toggleDrawer(false);

  useEffect(() => {
    setActiveMenu(active);
  }, []);

  const itemTypeImageMap = {
    [Constants.PROBLEM]: <ProblemIcon />,
    [Constants.SOLUTION]: <SolutionIcon />,
    [Constants.APPLICATION]: <InventionIcon />,
    [Constants.PRODUCT]: <ProductIcon />,
    [Constants.CONTEST]: <ContestIcon />
  };

  const pagination = useMemo(
    () => ({
      page: page,
      perPage: rowsPerPage
    }),
    [page, rowsPerPage]
  );

  const getProblems = () => {
    dispatch(Actions.getProfileProblems(user.id, pagination, null, false));
    setItems(problems);
  };

  const getSolutions = () => {
    dispatch(Actions.getProfileSolutions(user.id, pagination, null, false));
    setItems(solutions);
  };

  const getInventions = () => {
    dispatch(
      Actions.getProfileInventions(user.id, pagination, null, false, true)
    );
    setItems(inventions);
  };

  const getProducts = () => {
    dispatch(Actions.getProfileProducts(user.id, pagination));
    setItems(products);
  };

  const getContests = () => {
    dispatch(Actions.getProfileContests(user.id, pagination));
    setItems(contests);
  };

  useEffect(() => {
    if (!isHomePage) {
      if (!user) {
        dispatch(Actions.openAuthModal(true));
        return;
      }
      if (activeMenu === Constants.PROBLEM) {
        setLastItemIndex(problems?.length - 1);
        getProblems();
      } else if (activeMenu === Constants.SOLUTION) {
        setLastItemIndex(solutions?.length - 1);
        getSolutions();
      } else if (activeMenu === Constants.APPLICATION) {
        setLastItemIndex(inventions?.length - 1);
        getInventions();
      } else if (activeMenu === Constants.PRODUCT) {
        setLastItemIndex(products?.length - 1);
        getProducts();
      } else if (activeMenu === Constants.CONTEST) {
        setLastItemIndex(contests?.length - 1);
        getContests();
      }
    }
  }, [activeMenu, isHomePage, pagination, rowsPerPage]);

  useEffect(() => {
    if (!isHomePage && lastItemRef.current) {
      const lastRewardObserver = new IntersectionObserver(
        (entries) => {
          const lastReward = entries[0];
          if (lastReward.isIntersecting) {
            setRowsPerPage((prevRowsPerPage) => prevRowsPerPage + 10);
          }
        },
        { threshold: 0.7 }
      );

      lastRewardObserver.observe(lastItemRef.current);

      return () => {
        if (lastItemRef.current) {
          lastRewardObserver.unobserve(lastItemRef.current);
        }
      };
    }
  }, [isHomePage, lastItemRef.current]);

  useEffect(() => {
    if (setFilterType && !resetToken) {
      setActiveMenu(active);
      setFilterType([active]);
      window.history.replaceState(null, '', pathname);
    }
  }, [active, setFilterType, resetToken]);
  return (
    <>
      <StyledBox>
        <IconButton
          title={Constants.HOME}
          onClick={(event) => {
            handleItemClick(event, Constants.ALL);
          }}
        >
          <HomeIcon
            color={
              activeMenu === Constants.ALL ? VARIANT.PRIMARY : Constants.ACTION
            }
          />
        </IconButton>
        <IconButton
          title={Constants.C_PROBLEMS}
          onClick={(event) => {
            handleItemClick(event, Constants.PROBLEM);
          }}
        >
          <ProblemsIcon
            color={
              activeMenu === Constants.PROBLEM
                ? VARIANT.PRIMARY
                : Constants.ACTION
            }
          />
        </IconButton>
        <IconButton
          title={Constants.C_SOLUTIONS}
          onClick={(event) => {
            handleItemClick(event, Constants.SOLUTION);
          }}
        >
          <SolutionsIcon
            color={
              activeMenu === Constants.SOLUTION
                ? VARIANT.PRIMARY
                : Constants.ACTION
            }
          />
        </IconButton>
        <IconButton
          title={Constants.C_INVENTIONS}
          onClick={(event) => {
            handleItemClick(event, Constants.APPLICATION);
          }}
        >
          <InventionsIcon
            color={
              activeMenu === Constants.APPLICATION
                ? VARIANT.PRIMARY
                : Constants.ACTION
            }
          />
        </IconButton>
        <IconButton
          title={Constants.C_PRODUCTS}
          onClick={(event) => {
            handleItemClick(event, Constants.PRODUCT);
          }}
        >
          <ProductsIcon
            color={
              activeMenu === Constants.PRODUCT
                ? VARIANT.PRIMARY
                : Constants.ACTION
            }
          />
        </IconButton>
        <IconButton
          title={Constants.C_CONTESTS}
          onClick={(event) => {
            handleItemClick(event, Constants.CONTEST);
          }}
        >
          <ContestsIcon
            color={
              activeMenu === Constants.CONTEST
                ? VARIANT.PRIMARY
                : Constants.ACTION
            }
          />
        </IconButton>
        {/* TODO: No functionality associated with it. */}
        {/* <Link to="/challenges">
          <IconButton
            title="Challenges"
            onClick={(event) => {
              handleItemClick(event, 'challenges');
            }}
          >
            <ChallengesIcon
              color={activeMenu === 'challenges' ? 'primary' : 'action'}
            />
          </IconButton>
        </Link> */}
        <StyledButton>
          {user && (
            <Box
              overflow="hidden"
              onClick={() => Router.push(`/profiles/${user.key}`)}
            >
              {image && (
                <img
                  src={image.url}
                  alt={image.title}
                  style={{
                    display: 'block',
                    width: '100%',
                    height: 'auto',
                    borderRadius: '50%'
                  }}
                />
              )}
            </Box>
          )}
        </StyledButton>
      </StyledBox>
      <Drawer
        anchor="left"
        variant="persistent"
        open={drawerOpen}
        onClose={handleCloseDrawer}
        classes={{ paper: classes.root }}
      >
        <Box
          pl={11}
          mt="120px"
          display="flex"
          flexDirection="column"
          overflow="auto"
        >
          <StyledLinearContainer>
            {(problemsLoader ||
              solutionsLoader ||
              inventionsLoader ||
              productsLoader ||
              contestsLoader) && <LinearProgress value={10} />}
          </StyledLinearContainer>
          {items.length ? (
            items?.map((item, index) => {
              const isLast = index === lastItemIndex;
              return (
                <Box ref={isLast ? lastItemRef : null} key={item?.id}>
                  <Link
                    key={item.id}
                    to={`/${itemTypeModelMap[activeMenu]}/${item.key}`}
                  >
                    <Box
                      onClick={handleClickItem}
                      className={classes.link}
                      display="flex"
                      mb={3}
                      pr={2}
                      alignItems="center"
                    >
                      <StyledItemContainer
                        minWidth="60px"
                        height="60px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        borderRadius="7px"
                        overflow="hidden"
                        mr={1}
                      >
                        {item?.files?.[0]?.url ? (
                          <img
                            className={classes.img}
                            src={item?.files?.[0]?.url}
                            alt={item.key}
                          />
                        ) : (
                          itemTypeImageMap[activeMenu]
                        )}
                      </StyledItemContainer>
                      <Typography>{item.teaser || item.title}</Typography>
                      <Box ml="auto">
                        <Avatar
                          src={item?.ownerInfo?.picture?.url || item.image}
                        />
                      </Box>
                    </Box>
                  </Link>
                </Box>
              );
            })
          ) : (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                marginTop: '3.125rem'
              }}
            >
              No {activeMenu === 'application' ? 'invention' : activeMenu}s
              available
            </Box>
          )}
        </Box>
      </Drawer>
    </>
  );
};

export const SideMenu = styled(SideMenuView)({});
