import React from 'react';

function InventionIcon() {
  return (
    <svg
      width="82"
      height="82"
      viewBox="0 0 82 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41 82C63.6437 82 82 63.6437 82 41C82 18.3563 63.6437 0 41 0C18.3563 0 0 18.3563 0 41C0 63.6437 18.3563 82 41 82Z"
        fill="#F9D991"
      />
      <mask
        id="mask0_130_40031"
        maskUnits="userSpaceOnUse"
        x="21"
        y="11"
        width="40"
        height="58"
      >
        <path
          d="M60.6106 11.6509H21.3896V68.3329H60.6106V11.6509Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_130_40031)">
        <path
          d="M41.0077 11.6519H40.9997C36.8258 11.6533 32.7612 12.9865 29.3974 15.4576C26.0336 17.9287 23.546 21.4086 22.2966 25.3911C21.0472 29.3736 21.1011 33.6509 22.4506 37.6006C23.8 41.5503 26.3746 44.9664 29.7997 47.3519L30.1147 47.5729V54.5279C30.1181 55.1036 30.3483 55.6549 30.7555 56.062C31.1626 56.4692 31.7139 56.6994 32.2897 56.7029H49.7237C50.2991 56.6989 50.8498 56.4684 51.2566 56.0613C51.6633 55.6542 51.8932 55.1033 51.8967 54.5279V47.5739L52.2117 47.3529C55.6374 44.9674 58.2125 41.5509 59.5622 37.6006C60.9118 33.6502 60.9656 29.3723 59.7156 25.3893C58.4656 21.4064 55.9772 17.9262 52.6124 15.4554C49.2476 12.9847 45.1822 11.6521 41.0077 11.6519ZM53.5517 39.9519C52.513 41.4517 51.2125 42.7522 49.7127 43.7909L47.5547 45.3139V52.3559H34.4547V45.3149L32.2897 43.7919C29.8089 42.069 27.8947 39.6493 26.7891 36.8386C25.6834 34.028 25.4359 30.9526 26.0778 28.0013C26.7197 25.05 28.2223 22.3553 30.3956 20.2578C32.5688 18.1604 35.3152 16.7544 38.2874 16.2176C41.2596 15.6808 44.3243 16.0373 47.0939 17.242C49.8635 18.4468 52.2138 20.4457 53.8476 22.986C55.4813 25.5263 56.3252 28.4939 56.2725 31.5138C56.2198 34.5336 55.273 37.4701 53.5517 39.9519Z"
          fill="#C77312"
        />
        <path
          d="M35.1935 68.3327H46.8215C47.3971 68.3293 47.9481 68.099 48.3549 67.6918C48.7618 67.2846 48.9916 66.7334 48.9945 66.1577V63.9897H33.0225V66.1607C33.0256 66.7357 33.2553 67.2861 33.6618 67.6928C34.0682 68.0994 34.6186 68.3293 35.1935 68.3327Z"
          fill="#C77312"
        />
      </g>
    </svg>
  );
}
export default InventionIcon;
