import * as React from 'react';
import PsLogo from 'components/common/PsLogo';
import { colorPalette } from 'theme';
import { MainWrapper, ValueText } from './styledComponents';

export const LogoWithValue: React.FC<{ value: number }> = ({ value }) => {
  return (
    <MainWrapper>
      <PsLogo color={colorPalette.purple} size={18} small />
      <ValueText>{value}</ValueText>
    </MainWrapper>
  );
};
