export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const FORGET_PASSWORD_RESPONSE = 'FORGET_PASSWORD_RESPONSE';
export const GET_PROFILE = 'GET_PROFILE';
export const GET_PUBLIC_PROFILE = 'GET_PUBLIC_PROFILE';
export const GET_PUBLIC_PROFILE_SUCCESS = 'GET_PUBLIC_PROFILE_SUCCESS';
export const GET_PROFILE_SOCIAL_AUTH_KEYS = 'GET_PROFILE_SOCIAL_AUTH_KEYS';
export const GET_PROFILE_SOCIAL_AUTH_KEYS_SUCCESS =
  'GET_PROFILE_SOCIAL_AUTH_KEYS_SUCCESS';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const LOG_IN = 'LOG_IN';
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_RESPONSE = 'RESET_PASSWORD_RESPONSE';
export const SET_LOGIN_USER = 'SET_LOGIN_USER';
export const SIGN_UP = 'SIGN_UP';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SOCIAL_LOG_IN = 'SOCIAL_LOG_IN';
export const SOCIAL_LOG_IN_SUCCESS = 'SOCIAL_LOG_IN_SUCCESS';
export const SOCIAL_DISCONNECT = 'SOCIAL_DISCONNECT';
export const SOCIAL_DISCORD_LOG_IN = 'SOCIAL_DISCORD_LOG_IN';
export const SOCIAL_DISCORD_LOG_IN_SUCCESS = 'SOCIAL_DISCORD_LOG_IN_SUCCESS';
export const SOCIAL_LINKEDIN_LOG_IN = 'SOCIAL_LINKEDIN_LOG_IN';
export const SOCIAL_LINKEDIN_LOG_IN_SUCCESS = 'SOCIAL_LINKEDIN_LOG_IN_SUCCESS';
export const SOCIAL_TIKTOK_LOG_IN = 'SOCIAL_TIKTOK_LOG_IN';
export const SOCIAL_TIKTOK_LOG_IN_SUCCESS = 'SOCIAL_TIKTOK_LOG_IN_SUCCESS';
export const SOCIAL_TWITTER_LOG_IN = 'SOCIAL_TWITTER_LOG_IN';
export const SOCIAL_TWITTER_LOG_IN_SUCCESS = 'SOCIAL_TWITTER_LOG_IN_SUCCESS';
export const SOCIAL_INSTAGRAM_LOG_IN = 'SOCIAL_INSTAGRAM_LOG_IN';
export const SOCIAL_INSTAGRAM_LOG_IN_SUCCESS =
  'SOCIAL_INSTAGRAM_LOG_IN_SUCCESS';
