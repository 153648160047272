import React, { useMemo } from 'react';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import { GetPublicUser, GetUser } from 'redux-state/selectors';
import { VARIANT, Constants } from 'utilities/constants';
import {
  IconContainer,
  IconText,
  IconTextContainer,
  StyledProfileContent
} from './StyledComponents';

const ProfileIconsView = () => {
  const user = GetUser();
  const profile = GetPublicUser();
  const isCurrentUser = !!(user && (user?.id === profile?.id || user?.isAdmin));

  const iconsData = useMemo(
    () => [
      {
        id: 1,
        icon: <LocationOnOutlinedIcon />,
        text:
          (isCurrentUser ? user?.location : profile?.location) ||
          Constants.C_NONE,
        url: isCurrentUser
          ? `/${Constants.TAGS}/${user?.locationTagsInfo?.[0]?.key}`
          : `/${Constants.TAGS}/${profile?.locationTagsInfo?.[0]?.key}`
      },
      {
        id: 2,
        icon: <SchoolOutlinedIcon />,
        text:
          (isCurrentUser ? user?.school : profile?.school) || Constants.C_NONE,
        url: isCurrentUser
          ? `/${Constants.COMMUNITIES}/${user?.schoolTagsInfo?.[0]?.key}`
          : `/${Constants.COMMUNITIES}/${profile?.schoolTagsInfo?.[0]?.key}`
      },
      {
        id: 3,
        icon: <BusinessOutlinedIcon />,
        text:
          (isCurrentUser ? user?.workplace : profile?.workplace) ||
          Constants.C_NONE,
        url: isCurrentUser
          ? `/${Constants.COMMUNITIES}/${user?.workplaceTagsInfo?.[0]?.key}`
          : `/${Constants.COMMUNITIES}/${profile?.workplaceTagsInfo?.[0]?.key}`
      }
    ],
    [user, isCurrentUser, profile]
  );

  return (
    <StyledProfileContent>
      <IconTextContainer>
        {iconsData.map((item) => (
          <IconContainer
            key={item.id}
            onClick={() => {
              window.open(`${item.url}`, '_blank');
            }}
          >
            {item.icon}
            <IconText variant={VARIANT.BODY2}>{item.text}</IconText>
          </IconContainer>
        ))}
      </IconTextContainer>
    </StyledProfileContent>
  );
};

export default ProfileIconsView;
