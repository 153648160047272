import { Box, Button, Divider, Typography, styled } from '@mui/material';
import { colorPalette } from 'theme';

export const TitleTypography = styled(Typography)({
  fontSize: '1.75rem',
  fontWeight: '700',
  lineHeight: '1'
});

export const StyledDivider = styled(Divider)({
  marginTop: '1.5rem'
});

export const ContestsBox = styled(Box)({
  marginBottom: '0.8rem'
});

export const StyledBox = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start'
});

export const HeaderBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '.625rem',
  '@media (max-width:900px)': {
    flexDirection: 'column',
    alignItems: 'flex-start'
  }
});

export const StyledButton = styled(Button)({
  backgroundColor: `${colorPalette.lightGreen}25`,
  boxShadow: 'none',
  color: `${colorPalette.lightGreen}`,
  fontSize: '.8rem',
  fontWeight: '700',
  '&:hover': {
    backgroundColor: `${colorPalette.lightGreen}25`
  },
  '@media (max-width:900px)': {
    marginTop: '.6rem'
  }
});

export const StyledTypography = styled(Typography)({
  color: `${colorPalette.lightGreen}`,
  fontSize: '.9375rem',
  fontWeight: '700',
  marginLeft: '.5rem'
});
