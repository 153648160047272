import React from 'react';
import { Card, CardContent, Container, Grid } from '@mui/material';
import { DottedLine } from 'components/icons/DottedLine';
import { NumberOne } from 'components/icons/NumberOne';
import { NumberThree } from 'components/icons/NumberThree';
import { NumberTwo } from 'components/icons/NumberTwo';
import { useIsMediumScreen } from 'theme';
import {
  StyledDottedLineBox,
  StyledNumberBox,
  StyledRoyaltiesMainBox,
  StyledSubHeaderMainBox,
  StyledWinRoyaltiesContentTypography,
  StyledWinRoyaltiesHeaderTypography,
  StyledWinRoyaltiesSubContentTypography,
  StyledWinRoyaltiesSubHeaderTypography
} from './StyledComponents';

export const InnovationSection = () => {
  const isMediumScreen = useIsMediumScreen();

  return (
    <StyledRoyaltiesMainBox>
      <Container>
        <StyledWinRoyaltiesHeaderTypography
          variant="h4"
          gutterBottom
          textAlign="center"
        >
          Simplify Innovation
        </StyledWinRoyaltiesHeaderTypography>

        <StyledWinRoyaltiesSubHeaderTypography
          variant="body1"
          gutterBottom
          textAlign="center"
        >
          Earn from and build upon your ideas, with the help of MindMiner’s AI.
        </StyledWinRoyaltiesSubHeaderTypography>

        <StyledNumberBox>
          {!isMediumScreen && (
            <StyledDottedLineBox>
              <DottedLine />
            </StyledDottedLineBox>
          )}

          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} md={4}>
              <StyledSubHeaderMainBox textAlign="center" mb={2}>
                <NumberOne />
              </StyledSubHeaderMainBox>
              <Card elevation={0}>
                <CardContent>
                  <StyledWinRoyaltiesContentTypography
                    variant="h6"
                    textAlign="center"
                  >
                    Identify Key Problems
                  </StyledWinRoyaltiesContentTypography>
                  <StyledWinRoyaltiesSubContentTypography
                    variant="body2"
                    textAlign="center"
                  >
                    MindMiner helps you identify the key opportunities to
                    improve existing products and concepts.
                  </StyledWinRoyaltiesSubContentTypography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <StyledSubHeaderMainBox textAlign="center" mb={2}>
                <NumberTwo />
              </StyledSubHeaderMainBox>
              <Card elevation={0}>
                <CardContent>
                  <StyledWinRoyaltiesContentTypography
                    variant="h6"
                    textAlign="center"
                  >
                    Combine Solutions Into Concepts
                  </StyledWinRoyaltiesContentTypography>
                  <StyledWinRoyaltiesSubContentTypography
                    variant="body2"
                    textAlign="center"
                  >
                    Use your own and others’ solutions as building blocks for
                    new, valuable and innovative concepts.
                  </StyledWinRoyaltiesSubContentTypography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <StyledSubHeaderMainBox textAlign="center" mb={2}>
                <NumberThree />
              </StyledSubHeaderMainBox>
              <Card elevation={0}>
                <CardContent>
                  <StyledWinRoyaltiesContentTypography
                    variant="h6"
                    textAlign="center"
                  >
                    Build and Sell Patent Pending Assets
                  </StyledWinRoyaltiesContentTypography>
                  <StyledWinRoyaltiesSubContentTypography
                    variant="body2"
                    textAlign="center"
                  >
                    Lock your concepts as patent pending inventions and then
                    sell them as patent pending token digital assets.
                  </StyledWinRoyaltiesSubContentTypography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </StyledNumberBox>
      </Container>
    </StyledRoyaltiesMainBox>
  );
};
