import React from 'react';
import { HexOutlinedIcon } from 'components/icons/HexOutlinedIcon';
import { Constants } from 'utilities/constants';
import {
  Container,
  ImproveLink,
  StyledDivider,
  TitleText,
  TitleWrapper
} from './styledComponents';

interface ActiveConceptHeaderProps {
  onImproveClick: () => void;
}

export const ActiveConceptHeader: React.FC<ActiveConceptHeaderProps> = ({
  onImproveClick
}) => {
  return (
    <>
      <Container>
        <TitleWrapper>
          <HexOutlinedIcon />
          <TitleText>{Constants.MY_ACTIVE_CONCEPT}</TitleText>
        </TitleWrapper>
        <ImproveLink onClick={onImproveClick}> </ImproveLink>
      </Container>
      <StyledDivider />
    </>
  );
};
