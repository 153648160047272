import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
  Avatar,
  RadioGroup,
  Grid,
  StepLabel,
  Slider,
  styled
} from '@mui/material';
import { colorPalette } from 'theme';
import { CiCircleInfo } from 'react-icons/ci';
import { IoCalendarOutline } from 'react-icons/io5';
import { IoIosInformationCircle } from 'react-icons/io';
import { display } from '@mui/system';

export const MainDialog = styled(Dialog)(() => ({
  '& .MuiDialogContent-root': {
    padding: '1.25rem'
  },
  '& .MuiDialogActions-root': {
    padding: '0.625rem'
  },
  '& .MuiPaper-root': {
    maxWidth: '93.75rem',
    minWidth: '62rem'
  }
}));

export const MainDialogTitle = styled(DialogTitle)({
  padding: '0.5rem 0 1.5rem'
});

export const ModalTitle = styled(Typography)({
  fontSize: '1.625rem',
  fontWeight: 'bold',
  lineHeight: '1.7rem',
  marginBottom: '0.8rem'
});

export const StyledIconButton = styled(IconButton)({
  boxShadow: `0rem 0rem 0.3875rem 0rem ${colorPalette.black}`,
  color: colorPalette.darkPurple,
  position: 'absolute',
  right: 30,
  top: 28
});

export const LoaderBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  minWidth: '1.875rem'
});

export const ButtonTitle = styled(Typography)({
  textTransform: 'capitalize',
  margin: '0.3125rem',
  textDecoration: 'underline',
  fontSize: '1rem'
});

export const SubmitButton = styled(Typography)({
  textTransform: 'uppercase',
  margin: '0.3125rem',
  fontWeight: 'bold',
  fontSize: '1.0625rem'
});

export const StyledAvatar = styled(Avatar)({
  width: '22.1875rem',
  height: '10.3125rem',
  borderRadius: '0.3125rem',
  objectFit: 'cover'
});

export const Heading = styled(Typography)({
  color: colorPalette.veryDarkGrey,
  fontSize: '1.125rem',
  fontWeight: 600,
  margin: '0.125rem'
});

export const DivisionBox = styled(Box)({
  width: '48%',
  // height: '45rem',
  padding: '0.3125rem'
});

export const ContainerBox = styled(Box)({
  marginBottom: '0.9375rem',
  display: 'flex',
  flexDirection: 'column'
});

export const StyledBoxCreate = styled(Box)({
  padding: '1.5rem',
  maxWidth: '81.25rem',
  height: '5.9375rem'
});

export const StyledContestTitle = styled(Box)({
  padding: '1.5rem',
  maxWidth: '81.25rem',
  maxHeight: '72vh',
  overflowY: 'scroll'
});

export const StyledIdeaCoins = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between'
});

export const StyledIdeacoinsMark = styled(Box)({
  display: 'flex',
  alignItems: 'center'
});

export const StyledImageBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row'
});

export const StyledImageBox2 = styled(Box)({
  display: 'flex',
  flexDirection: 'column'
});

export const StyledUserBox = styled(Box)({
  padding: '0.5rem 1.5rem',
  maxWidth: '81.25rem',
  height: '5.9375rem',
  display: 'flex',
  justifyContent: 'space-between'
});

export const StyledFlexBox = styled(Box)({
  display: 'flex',
  alignItems: 'center'
});

export const StyledEarnIdeapoints = styled(Box)({
  marginLeft: '0.625rem'
});

export const StyledSliderText = styled(Typography)({
  marginLeft: '0.3125rem',
  color: colorPalette.purple
});

export const StyledAddProblemButton = styled(Button)({
  margin: '0rem 0.3125rem'
});

export const StyledRadioGroup = styled(RadioGroup)({
  display: 'flex',
  flexDirection: 'row'
});

export const StyledIoCalendarOutline = styled(IoCalendarOutline)({
  color: colorPalette.purple,
  size: 29
});

export const StyledTypo = styled(Typography)({
  color: colorPalette.purple,
  fontWeight: 600,
  fontSize: '0.875rem',
  textTransform: 'capitalize'
});

export const StyledTypo2 = styled(Typography)({
  color: colorPalette.grey,
  fontWeight: 600,
  fontSize: '0.75rem',
  textTransform: 'capitalize',
  fontStyle: 'italic',
  display: 'flex',
  marginTop: '-0.3125rem'
});

export const StyledTypo3 = styled(Typography)({
  color: colorPalette.purple,
  fontWeight: 600,
  fontSize: '0.75rem',
  textTransform: 'capitalize',
  margin: '0rem 0.25rem'
});

export const StyledGridContainer = styled(Box)<{
  isConceptDrawerOpen: boolean;
}>(({ isConceptDrawerOpen, theme }) => ({
  padding: '0rem 2rem 0rem 6.5625rem',
  marginLeft: isConceptDrawerOpen ? '34.375rem' : '0rem',
  width: isConceptDrawerOpen ? 'auto' : '100%',
  [theme.breakpoints.down('md')]: {
    padding: '5rem 1rem 0rem'
  }
}));

export const StyledTitleContest = styled(Box)({
  width: '100%',
  height: '100%',
  borderRadius: '0.3125rem',
  boxShadow: `0rem 0.0625rem 0.7rem 0rem ${colorPalette.boxShadowDarkGrey}`,
  display: 'flex',
  overflow: 'hidden'
});

export const StyledGridTitle = styled(Box)({
  marginLeft: '0.625rem',
  width: '100%',
  position: 'relative'
});

export const StyledGridJackpot = styled(Grid)({
  width: '100%',
  height: '100%',
  borderRadius: '0.3125rem',
  backgroundColor: colorPalette.purple,
  boxShadow: `0rem 0.0625rem 0.7rem 0rem ${colorPalette.boxShadowDarkGrey}`,
  position: 'relative',
  '&:hover': {
    '& > div': {
      display: 'flex'
    },
    '.info-icon': {
      color: `${colorPalette.purple} !important`
    }
  }
});

export const StyledFrameBox = styled(Box)({
  mixBlendMode: 'color-burn',
  opacity: 0.7,
  position: 'absolute'
});

export const StyledLockedBox = styled(Box)(({ theme }) => ({
  background: colorPalette.white,
  borderRadius: '0.9375rem',
  padding: '0rem 0.3125rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0rem 0rem 0.3125rem 1.75rem',
  [theme.breakpoints.down('md')]: {
    margin: '0rem 0.5rem 0.3125rem',
    position: 'absolute',
    left: '0'
  }
}));

export const StyledRemainaingTime = styled(Box)({
  width: '100%',
  height: '100%',
  borderRadius: '0.3125rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
});

export const StyledIdeaMap = styled(Box)({
  width: '100%',
  height: '100%',
  borderRadius: '0.3125rem'
});

export const StyledLeaderboard = styled(Box)({
  width: '100%',
  boxShadow: `0rem 0.0625rem 0.7rem 0rem ${colorPalette.boxShadowDarkGrey}`,
  borderRadius: '0.3125rem'
});

export const StyledIconButtonDupe = styled(IconButton)({
  backgroundColor: colorPalette.lightPurple,
  height: '2rem',
  position: 'absolute',
  right: 85,
  top: '0.375rem',
  width: '2rem'
});

export const StyledIconButtonEdit = styled(IconButton)({
  backgroundColor: colorPalette.lightPurple,
  height: '2rem',
  margin: '0.2rem 0.5rem 0 0',
  width: '2rem'
});

export const StyledIconButtonShare = styled(IconButton)({
  backgroundColor: colorPalette.lightPurple,
  margin: '0.2rem 0',
  width: '2rem',
  height: '2rem'
});

export const StyledContest = styled(Typography)({
  fontWeight: 700,
  fontSize: '1.25rem',
  color: colorPalette.purple,
  marginRight: '0.3125rem'
});

export const StyledTypoTitle = styled(Typography)({
  fontWeight: 700,
  fontSize: '1.25rem'
});

export const StyledAddedTypography = styled(Typography)({
  marginRight: '0.1875rem',
  color: colorPalette.grey,
  fontWeight: 400,
  fontSize: '0.75rem'
});

export const StyledMomentTypo = styled(Typography)({
  color: colorPalette.grey,
  fontWeight: 400,
  fontSize: '0.75rem'
});

export const StyledOwnerName = styled(Typography)({
  color: colorPalette.purple,
  fontWeight: 400,
  fontSize: '0.75rem',
  textDecoration: 'underline',
  textTransform: 'capitalize'
});

export const StyledContestDetail = styled(Typography)({
  color: colorPalette.darkGrey,
  fontSize: '0.9375rem',
  fontWeight: 400,
  height: '4.0625rem',
  lineHeight: '1.25rem',
  width: '98.5%'
});

export const StyledLockedTypo = styled(Typography)({
  fontSize: '0.625rem',
  color: colorPalette.purple
});

export const StyledJackpotTypo = styled(Typography)({
  fontSize: '1.25rem',
  fontWeight: 800,
  textTransform: 'uppercase'
});

export const StyledCoinsTypo = styled(Typography)({
  fontSize: '1.875rem',
  fontWeight: 700
});

export const StyledIdeacoinsTypo = styled(Typography)({
  fontSize: '0.875rem',
  color: colorPalette.gold
});

export const StyledNftTypo = styled(Typography)({
  color: colorPalette.purple,
  fontWeight: 700
});

export const StyledUnlockTypo = styled(Typography)({
  fontWeight: 500
});

export const StyledLeaderboardTypo = styled(Typography)({
  fontSize: '1.25rem',
  fontWeight: 700,
  margin: '0.625rem 0rem 0.625rem 1.25rem',
  paddingTop: '0.625rem'
});

export const StyledLeaderboardTypo2 = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  fontWeight: 700,
  [theme.breakpoints.up('md')]: {
    fontSize: '1rem'
  }
}));

export const StyledTableRow = styled(Typography)({});

export const StyledItemName = styled(Typography)({
  marginLeft: '1.0625rem',
  fontSize: '0.875rem',
  fontWeight: 600,
  textDecoration: 'underline'
});

export const StyledStepLabel1 = styled(StepLabel)({
  marginTop: '-1.125rem'
});

export const StyledHoverBox = styled(Box)({
  width: '100%',
  height: '100%',
  borderRadius: '0.3125rem',
  boxShadow: `0rem 0.0625rem 0.7rem 0rem ${colorPalette.boxShadowDarkGrey}`,
  position: 'absolute',
  backgroundColor: colorPalette.white,
  color: colorPalette.black,
  fontWeight: 500,
  padding: '0.625rem',
  opacity: 0.9,
  display: 'none',
  alignItems: 'center',
  justifyContent: 'center'
});

export const StyledNftBox = styled(Typography)(({ theme }) => ({
  boxShadow: `0rem 0.0625rem 0.7rem 0rem ${colorPalette.boxShadowDarkGrey}`,
  width: '100%',
  height: '100%',
  borderRadius: '0.3125rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('lg')]: {
    padding: '1.5rem'
  }
}));

export const StyledGridInnerContainer = styled(Grid)({
  padding: '0.5rem',
  position: 'relative',
  flex: '1'
});

export const StyledTimeBox1 = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  position: 'absolute',
  top: '-3.4375rem',
  width: '97%'
});

export const StyledTimeBox2 = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%'
});

export const StyledTyp1 = styled(Typography)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  fontSize: '1rem',
  fontWeight: 600,
  lineHeight: '1.25rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '0.8rem'
  }
}));

export const StyledProblem = styled(Box)({
  display: 'flex',
  width: '85%',
  flexDirection: 'column'
});

export const StyledRemoveButton = styled(Button)({
  display: 'flex',
  height: 'fit-content',
  alignItems: 'center',
  marginRight: '0.3125rem'
});

export const StyledNodeBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '10px'
});

export const StyledAddNewProb = styled(Box)({
  height: '5.9375rem',
  maxWidth: '62.5rem',
  padding: '1.5rem'
});

export const StyledModalBox = styled(Box)({
  maxWidth: '62.5rem',
  padding: '1.5rem'
});

export const StyledAddProb = styled(Box)({
  padding: '0.5rem 1.5rem',
  maxWidth: '62.5rem',
  height: '5.9375rem',
  display: 'flex',
  justifyContent: 'flex-end'
});

export const DraftButton = styled(Button)({
  fontWeight: 600,
  display: 'flex',
  alignItems: 'center',
  padding: '0.375rem 1rem',
  maxHeight: '45px',
  marginRight: '1.25rem',
  textDecoration: 'underline',
  '&:hover': { textDecoration: 'underline' }
});

export const CreateButton = styled(Button)({
  color: colorPalette.white,
  backgroundColor: colorPalette.purple,
  fontWeight: 600,
  padding: '1rem 1.75rem',
  maxHeight: '45px',
  display: 'flex',
  alignItems: 'center',
  '&:hover': { backgroundColor: colorPalette.darkPurple },
  ':disabled': {
    backgroundColor: colorPalette.lightPurple
  }
});

export const StyledHexBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  margin: '0.125rem',
  width: '6.875rem'
});

export const StyledSlider = styled(Slider)({
  height: '0.5rem',
  '& .MuiSlider-valueLabel': {
    backgroundColor: colorPalette.white,
    borderRadius: '0.3125rem',
    boxShadow: `0rem 0.0625rem 0.7rem 0rem ${colorPalette.boxShadowDarkGrey}`,
    color: colorPalette.purple,
    width: '1.875rem'
  },
  '& .MuiSlider-thumb': {
    border: `0.1875rem solid ${colorPalette.white}`,
    boxShadow: `0rem 0.0625rem 0.7rem 0rem ${colorPalette.boxShadowDarkGrey}`
  }
});

export const HeadingContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  height: '3.125rem',
  justifyContent: 'space-between'
});

export const StyledCiCircleInfo = styled(CiCircleInfo)({
  zIndex: 9,
  position: 'absolute',
  cursor: 'pointer'
});

export const StyledIoIosInformationCircle = styled(IoIosInformationCircle)({
  border: `1rem solid ${colorPalette.lightPurple}`,
  borderRadius: '50%'
});

export const BannerBox = styled(Box)({
  backgroundColor: colorPalette.red,
  borderRadius: '3.125rem',
  color: colorPalette.white,
  margin: '0.3125rem',
  padding: '0.4375rem 0.9375rem',
  width: '100%'
});

export const BannerTypography = styled(Typography)({
  fontSize: '0.875rem',
  fontWeight: 600
});

export const StyledBannerTypography = styled(Typography)({
  cursor: 'pointer',
  display: 'inline',
  fontSize: '0.875rem',
  fontWeight: 600,
  textDecoration: 'underline'
});

export const StyledContestAvatar = styled(Avatar)(({ theme }) => ({
  borderRadius: 0,
  height: '9.375rem',
  width: '9.375rem',
  [theme.breakpoints.down('xs')]: {
    height: '7rem',
    width: '7rem'
  }
}));

export const MainGridContainer = styled(Grid)({
  alignItems: 'center',
  display: 'flex',
  marginBottom: '0.625rem'
});

export const ContestOwnerAvatar = styled(Avatar)({
  height: 25,
  marginRight: '0.3125rem',
  width: 25
});

export const JackpotGrid = styled(Grid)(({ theme }) => ({
  padding: '0.5rem',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    flex: '1'
  }
}));

export const LockedBoxGrid = styled(Grid)({
  alignItems: 'flex-end',
  display: 'flex',
  justifyContent: 'center'
});

export const JackpotGridItem = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  color: colorPalette.white,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    flex: '1'
  }
}));

export const SecondaryGridContainer = styled(Grid)(({ theme }) => ({
  margin: '0.625rem 0rem',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    marginTop: '0'
  }
}));

export const IdeaMapGridItem = styled(Grid)(({ theme }) => ({
  padding: '0.5rem',
  [theme.breakpoints.down('lg')]: {
    order: '2'
  }
}));

export const ProgressBarBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  position: 'relative',
  width: '80%'
});

export const InfoGridItem = styled(Grid)({
  alignItems: 'flex-start',
  display: 'flex',
  justifyContent: 'flex-end'
});
