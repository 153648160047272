import React, { useMemo } from 'react';
import { MenuItem, Select as SelectItem } from '@mui/material';

export interface TitleStyle {
  color?: string;
  fontSize?: string;
}

export interface DropdownProps {
  classes?: string;
  onChange: (e: any) => void;
  options: { value: string | number; label: string }[];
  value: string | number;
  variant?: string;
}

export const Select = (props: DropdownProps) => {
  const { classes, onChange, options, value } = props;

  const dropdownOptions = useMemo(
    () =>
      options.map(({ label, value }, index) => (
        <MenuItem key={index} value={value}>
          {label}
        </MenuItem>
      )),
    [options]
  );

  return (
    <SelectItem className={classes} onChange={onChange} value={value}>
      {dropdownOptions}
    </SelectItem>
  );
};
