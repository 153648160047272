import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AddCircle, RemoveCircle } from '@mui/icons-material';
import {
  Box,
  BoxProps,
  Button,
  Typography,
  TypographyProps
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Actions from 'redux-state/actions';
import { GetUser } from 'redux-state/selectors';
import { colorPalette } from 'theme';
import { Constants } from 'utilities/constants';

type FollowButtonProps = {
  actionType?: string;
  id: string | number;
  isOwnCommunity?: boolean;
  onClick: () => void;
  type: 'profile' | 'tag';
};

const FollowButton = ({
  type,
  id,
  isOwnCommunity = false,
  onClick,
  actionType
}: FollowButtonProps) => {
  const dispatch = useDispatch();
  const [subscribed, setSubscribed] = useState<boolean>(
    actionType === Constants.UNFOLLOW
  );
  const user = GetUser();

  const onBtnClick = (e: React.MouseEvent) => {
    if (user) {
      e.preventDefault();
      const newSubscribedStatus = !subscribed;
      setSubscribed(newSubscribedStatus);
      onClick();
    } else {
      dispatch(Actions.openAuthModal(true));
    }
  };

  const commonSx = {
    color: colorPalette.purple,
    width: '32px',
    height: '32px',
    marginLeft: '-5px'
  };

  return (
    <StyledButton disabled={isOwnCommunity}>
      <StyledContainer onClick={onBtnClick} border={1.5}>
        {subscribed ? (
          <RemoveCircle sx={commonSx} />
        ) : (
          <AddCircle sx={commonSx} />
        )}
        <StyledText variant="button">
          {isOwnCommunity
            ? Constants.MY_COMMUNITY
            : subscribed
              ? 'Unfollow'
              : 'Follow'}
        </StyledText>
      </StyledContainer>
    </StyledButton>
  );
};

interface StyledTextProps extends TypographyProps {
  loading?: boolean;
}

interface StyledBoxProps extends BoxProps {
  loading?: boolean;
}
const StyledContainer = styled(Box)<StyledBoxProps>(({ loading }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: colorPalette.lavenderGray,
  borderRadius: '20px',
  height: '27px',
  borderColor: loading ? colorPalette.lightShadowGrey : colorPalette.purple,
  cursor: 'pointer'
}));

const StyledText = styled(Typography)<StyledTextProps>(({ loading }) => ({
  fontSize: '13px',
  color: loading ? colorPalette.lightShadowGrey : colorPalette.purple,
  fontWeight: '550',
  marginLeft: '5px',
  marginRight: '10px',
  textTransform: 'none'
}));

const StyledButton = styled(Button)({
  backgroundColor: 'transparent',
  padding: '0px',
  marginLeft: '2px',
  '&:hover': {
    backgroundColor: 'transparent !important'
  }
});

export default FollowButton;
