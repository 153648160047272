import React, { useMemo, useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { VARIANT } from 'utilities/constants';
import { colorPalette } from 'theme';
import { TabsMainBox } from './styledComponents';

interface Tab {
  content: React.ReactNode;
  iconUrl: string;
  title: string;
}

interface WalletTabsViewProps {
  initialTab?: string;
  tabs: Tab[];
}

export const WalletTabsView: React.FC<WalletTabsViewProps> = ({
  initialTab,
  tabs
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(
    tabs.findIndex((tab) => tab.title === initialTab)
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTabIndex(newValue);
  };

  return (
    <TabsMainBox>
      <Tabs
        value={activeTabIndex}
        onChange={handleTabChange}
        variant={VARIANT.FULLWIDTH}
        sx={{ borderBottom: `.0625rem solid ${colorPalette.lightGrey}` }}
      >
        {tabs?.map((tab, index) => (
          <Tab key={index} label={tab.title} sx={{ textTransform: 'none' }} />
        ))}
      </Tabs>
      <Box sx={{ overflow: 'auto' }}>{tabs[activeTabIndex].content}</Box>
    </TabsMainBox>
  );
};
