import React, { useState, useEffect, useContext } from 'react';
import { styled, useTheme, lighten } from '@mui/material/styles';

import { Tag } from 'components/CardTag';
import { DataContext } from 'contexts/DataContext';
import { PsTheme } from '../../theme';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

const defaultColor = '#07A854';

function shuffle(array: Array<any>): Array<any> {
  let currentIndex = array.length;
  let randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex]
    ];
  }

  return array;
}

type ClassKey = 'root' | 'tag';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      fontSize: 14,
      textAlign: 'center',
      width: '100%',
      maxWidth: '600px',
      margin: '0 auto 20px'
    },
    tag: {
      display: 'inline-block',
      margin: '0.7em',
      background: 'blue',
      color: 'white',
      padding: '0.5em 0.7em',
      borderRadius: '0.9em',
      cursor: 'pointer',
      textDecoration: 'none',

      '&.place-1': {
        fontSize: '0.9em'
      },
      '&.place-2': {
        fontSize: '1em'
      },
      '&.place-3': {
        fontSize: '1.1em'
      },
      '&.place-4': {
        fontSize: '1.2em'
      },
      '&.place-5': {
        fontSize: '1.3em'
      },
      '&.place-6': {
        fontSize: '1.4em'
      },
      '&.place-7': {
        fontSize: '1.5em'
      },
      '&.place-8': {
        fontSize: '1.6em'
      },
      '&.place-9': {
        fontSize: '1.7em'
      },
      '&.place-10': {
        fontSize: '1.8em'
      }
    }
  };
});

type CloudTag = Tag & {
  amount: number;
  place: number;
};

type TagСloudProps = {
  className?: string;
  children: string;
  keyStr: string;
  place: number;
  color?: string;
  backgroundColor?: string;
};

const TagCloud = ({ children, keyStr, place, color }: TagСloudProps) => {
  const { classes } = useStyles();

  let finalColor = color;
  if (!finalColor || !finalColor.startsWith('#')) {
    finalColor = defaultColor;
  }
  const backgroundColor = lighten(finalColor, 0.85);

  const style = { color: finalColor, backgroundColor };
  const href = `/tags/${keyStr}`;

  return (
    <Link to={href}>
      <a
        target="_blank"
        className={`${classes.tag} place-${place}`}
        style={style}
      >
        {children}
      </a>
    </Link>
  );
};

const TagCloudMemo = React.memo(TagCloud);

type TagsСloudProps = {
  classes?: any;
  className?: string;
};

const TagsСloudView = ({ classes, className }: TagsСloudProps) => {
  const { dataProvider } = useContext(DataContext);
  const [tagsList, setTagsList] = useState<Array<CloudTag>>([]);

  useEffect(() => {
    dataProvider
      .getGroupsTop<CloudTag>()
      .then(({ data }) => {
        const tags = data
          .sort((tagA, tagB) => {
            if (tagA.amount === tagB.amount) {
              return 0;
            }
            return tagA.amount > tagB.amount ? 1 : -1;
          })
          .map((tag, index) => {
            tag.place = index + 1;
            return tag;
          });
        setTagsList(shuffle(tags));
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  if (!tagsList.length) {
    return null;
  }

  let rootClassName = classes.root;
  if (className) {
    rootClassName += ' ' + className;
  }

  return (
    <div className={rootClassName}>
      {tagsList.map((tag) => (
        <TagCloudMemo
          key={tag.id}
          keyStr={tag.key}
          place={tag.place}
          color={tag.color}
        >
          {tag.name || '(blank)'}
        </TagCloudMemo>
      ))}
    </div>
  );
};

export const TagsСloud = styled(TagsСloudView)({});

export default TagsСloud;
