import React from 'react';
import { styled, Theme, useTheme } from '@mui/material/styles';

import { ProfileName } from 'components/CardProfile';
import { getDateStr } from '../../helpers';
import { PsTheme } from '../../theme';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root' | 'date' | 'author';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      padding: '10px 0',
      display: 'flex',
      alignItems: 'center',
      fontSize: 12,
      lineHeight: '12px',
      letterSpacing: '0.01em',
      color: '#b3bcc7',
      fontFamily: 'Sinkin Sans',
      [psTheme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start'
      }
    },
    date: {
      paddingRight: 18
    },
    author: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 18,
      borderLeft: '1px solid #b3bcc7',
      [psTheme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        paddingTop: 5,
        borderLeft: 'none'
      }
    }
  };
});

type CardOwnerProps = {
  children?: React.ReactNode;
  className?: string;
  owner?: string | number;
  ownerKey?: string;
  ownerName?: string;
  createdAt?: string;
};

const CardOwnerView = ({
  className,
  owner,
  ownerKey,
  ownerName,
  createdAt,
  children
}: CardOwnerProps) => {
  const { classes } = useStyles();
  let rootClassName = classes.root;
  if (className) {
    rootClassName += ' ' + className;
  }

  return (
    <div className={rootClassName}>
      {createdAt ? (
        <span className={classes.date}>Added {getDateStr(createdAt)}</span>
      ) : null}
      {ownerName ? (
        <span className={classes.author}>
          Created by
          <ProfileName id={owner} ownerKey={ownerKey}>
            {ownerName}
          </ProfileName>
        </span>
      ) : null}
    </div>
  );
};

export const CardOwner = styled(CardOwnerView)({});

export default CardOwner;
