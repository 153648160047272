import React, { useContext, useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
  ModalDataContext,
  StepComponentProps,
  StepComponentType
} from 'contexts/ModalDataContext';
import styles from 'modals/ModalWindow.module.scss';
import { PsTheme } from '../../../theme';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'text' | 'textSmall';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    text: {
      fontSize: 34,
      lineHeight: '44px',
      fontWeight: 'bold',
      textAlign: 'center'
    },
    textSmall: {
      lineHeight: '24px',
      textAlign: 'center',
      paddingTop: '25px',
      '& > a': {
        display: 'inline-block',
        marginTop: 10
      }
    }
  };
});

export type CreditsStepProps = {
  onNotify?: (args: any) => void;
};

const CreditsStepView = ({ onNotify }: CreditsStepProps) => {
  const { values } = useContext(ModalDataContext);
  const { classes } = useStyles();

  const onCreditsClick = () => {
    if (onNotify) {
      onNotify('credits-buy');
    }
  };

  return (
    <div className={styles.stepWrapper}>
      <Typography className={classes.text}>
        Mint an Idea NFT for 1 MindMiner Credit
      </Typography>
      <Typography className={`${styles.text} ${classes.textSmall}`}>
        Your MindMiner Credit Balance: {values.tempNumber}
        <br />
        <Link to="/credits-buy">
          <a onClick={onCreditsClick}>Get MindMiner Credits</a>
        </Link>
      </Typography>
    </div>
  );
};

export const CreditsStep = styled(CreditsStepView)(
  {}
) as StepComponentType<StepComponentProps>;

export default CreditsStep;
