import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const NumberTwo = (props: SvgIconProps) => {
  return (
    <SvgIcon
      sx={{ width: '100%', maxWidth: '73px', height: 'auto' }}
      viewBox="0 0 73 73"
      {...props}
    >
      <g clipPath="url(#clip0_5573_3598)">
        <circle cx="36.5" cy="36.5" r="36.5" fill="#68D585" />
        <path
          d="M27.5708 50V46.22L37.2908 37.04C38.0588 36.344 38.6228 35.72 38.9828 35.168C39.3428 34.616 39.5828 34.112 39.7028 33.656C39.8468 33.2 39.9188 32.78 39.9188 32.396C39.9188 31.388 39.5708 30.62 38.8748 30.092C38.2028 29.54 37.2068 29.264 35.8868 29.264C34.8308 29.264 33.8468 29.468 32.9348 29.876C32.0468 30.284 31.2908 30.92 30.6668 31.784L26.4188 29.048C27.3788 27.608 28.7228 26.468 30.4508 25.628C32.1788 24.788 34.1708 24.368 36.4268 24.368C38.2988 24.368 39.9308 24.68 41.3228 25.304C42.7388 25.904 43.8308 26.756 44.5988 27.86C45.3908 28.964 45.7868 30.284 45.7868 31.82C45.7868 32.636 45.6788 33.452 45.4628 34.268C45.2708 35.06 44.8628 35.9 44.2388 36.788C43.6388 37.676 42.7508 38.672 41.5748 39.776L33.5108 47.372L32.3948 45.248H46.6148V50H27.5708Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_5573_3598">
          <rect width="73" height="73" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
