import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import {
  Box,
  IconButton,
  Link,
  Snackbar,
  SnackbarContent
} from '@mui/material';
import {
  Close as CloseIcon,
  Draw as DrawIcon,
  InsertPhoto
} from '@mui/icons-material';
import { convertFileToBase64 } from 'helpers';
import { colorPalette } from 'theme';
import DataContext from 'contexts/DataContext';
import Actions from 'redux-state/actions';
import {
  GetCoverImageLoader,
  GetCoverImage,
  GetGeneratedImage,
  GetGenerateImageLoader
} from 'redux-state/selectors';
import { Constants } from 'utilities/constants';
import GenerateIcon from 'assets/icons/Generate.svg';
import { ModalDataContext } from 'contexts/ModalDataContext';
import { debounce } from 'lodash';

import {
  ImageIconsBox,
  StyledGenerateButtonContainer,
  StyledGenerateImageText,
  StyledIconButten,
  StyledIconButton,
  StyledImageBox,
  StyledImageContainer,
  StyledImageMainContainer,
  StyledImageSupportText,
  StyledLoopIcon,
  StyledSnackbarContent
} from './StyledComponents';

interface Picture {
  contentType?: string;
  url: string;
  title?: string;
}

interface OptionType {
  label: string;
  raw?: string;
  value: number | string;
}

interface ImageContainerProps {
  description?: string;
  isCoverImage?: boolean;
  isEditTag?: boolean;
  parentNode?: OptionType | null;
  picture?: Picture;
  setPicture?: React.Dispatch<React.SetStateAction<Picture>>;
  supportText?: boolean;
  title?: string;
  type?: string;
}

export const ImageContainer: React.FC<ImageContainerProps> = ({
  description,
  isCoverImage = false,
  isEditTag = false,
  parentNode,
  picture,
  setPicture,
  supportText,
  title,
  type
}) => {
  const { values } = useContext(ModalDataContext);
  const { showDraw } = useContext(DataContext);
  const aiImageLoader = isCoverImage
    ? GetCoverImageLoader()
    : GetGenerateImageLoader();
  const generatedImage = isCoverImage ? GetCoverImage() : GetGeneratedImage();
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [hasLocalUpload, setHasLocalUpload] = useState<boolean>(false);

  const dispatch = useDispatch();
  const { acceptedFiles, open } = useDropzone({
    multiple: false,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/gif': ['.gif'],
      'image/svg+xml': ['.svg']
    }
  });

  useEffect(() => {
    if (!values.imageBase64) {
      return;
    }
    const drawing_picture = {
      contentType: 'image/png',
      title: 'draw.png',
      url: values.imageBase64
    };
    setPicture(drawing_picture);
    setHasLocalUpload(true);
    dispatch(Actions.generateImageFromAISuccess({}));
  }, [values.imageBase64]);

  useEffect(() => {
    if (!generatedImage || hasLocalUpload) {
      return;
    }

    const aiPicture = {
      url: generatedImage
    };
    setPicture(aiPicture);
  }, [generatedImage]);

  useEffect(() => {
    if (!acceptedFiles.length) {
      return;
    }

    Promise.all(acceptedFiles.map(convertFileToBase64)).then(([item]) => {
      const { file, base64 } = item;
      const picture = {
        contentType: file.type,
        title: file.name,
        url: base64
      };
      setPicture(picture);
      setHasLocalUpload(true);
      dispatch(Actions.generateImageFromAISuccess({}));
    });
  }, [acceptedFiles, setPicture]);

  const onContainerClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      if (!picture) {
        open();
      }
    },
    [open, picture]
  );

  const renderSnackbar = (message) => (
    <Snackbar
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'top'
      }}
      open={snackbarOpen}
      autoHideDuration={2000}
      onClose={() => setSnackbarOpen(false)}
    >
      <StyledSnackbarContent message={message} />
    </Snackbar>
  );

  const onGenerateClick = () => {
    const infoMap = {
      [Constants.PROBLEMS]: 'parentProduct',
      [Constants.SOLUTIONS]: 'parentProblem',
      [Constants.PRODUCTS]: 'companyName'
    };

    const infoKey = infoMap[type];
    if (!title || !description) {
      setSnackbarMessage(`Please fill in ${type} title, description.`);
      setSnackbarOpen(true);
      return;
    }

    const additionalInfo = infoKey ? { [infoKey]: parentNode?.label } : {};
    setHasLocalUpload(false);

    if (isCoverImage) {
      dispatch(
        Actions.generateCoverImageFromAI({
          title: title,
          description: description,
          type: type,
          additionalInfo
        })
      );
    } else {
      dispatch(
        Actions.generateImageFromAI({
          title: title,
          description: description,
          type: type,
          additionalInfo
        })
      );
    }
  };

  const onDrawClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      showDraw();
    },
    [showDraw]
  );

  return (
    <>
      <StyledImageMainContainer>
        <Box sx={{ flex: 1 }}>
          <StyledImageBox onClick={onContainerClick}>
            {picture && <StyledImageContainer src={picture?.url} />}
            {!picture && (
              <StyledGenerateButtonContainer>
                <StyledIconButten>
                  <img src={GenerateIcon} />
                </StyledIconButten>
                <StyledGenerateImageText>
                  {aiImageLoader
                    ? Constants.AI_IMAGE_PLACEHOLDER_TEXT
                    : Constants.GENERATE_AI_IMAGE}
                </StyledGenerateImageText>
              </StyledGenerateButtonContainer>
            )}
          </StyledImageBox>
          {supportText && (
            <StyledImageSupportText>
              {Constants.ILLUSTRATION_TEXT}
              <Link
                href="https://api.leadconnectorhq.com/widget/booking/AOjgADNIQ9p1bwmWshGH"
                rel="noopener noreferrer"
                target="_blank"
              >
                Contact{' '}
              </Link>
              {Constants.MINDMINER_TEAM}
            </StyledImageSupportText>
          )}
        </Box>
        <ImageIconsBox>
          <StyledIconButton
            onClick={onGenerateClick}
            disabled={aiImageLoader && !hasLocalUpload}
          >
            <StyledLoopIcon loading={aiImageLoader && !hasLocalUpload} />
          </StyledIconButton>
          <StyledIconButton onClick={open}>
            <InsertPhoto sx={{ color: colorPalette.purple }} />
          </StyledIconButton>
          <StyledIconButton onClick={onDrawClick}>
            <DrawIcon sx={{ color: colorPalette.purple }} />
          </StyledIconButton>
        </ImageIconsBox>
        {renderSnackbar(snackbarMessage)}
      </StyledImageMainContainer>
    </>
  );
};
