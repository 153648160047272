import React, { useState, useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { DataContext } from 'contexts/DataContext';
import { AuthContext } from 'contexts/AuthContext';
import { PsTheme } from '../../theme';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root' | 'button' | 'paper';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      position: 'absolute',
      top: 15,
      left: 15
    },
    button: {
      padding: 5,
      '&:hover': {
        background: psTheme.palette.primary.main
      }
    },
    paper: {
      '& .MuiDialog-paper': {
        maxWidth: 500
      }
    }
  };
});

type TagRemoveProps = {
  className?: string;
  onRemove: () => void;
  type: string;
  itemId?: string | number;
  tagKey?: string;
};

const TagRemoveView = ({
  className,
  onRemove,
  type,
  itemId,
  tagKey: tagName
}: TagRemoveProps) => {
  const { classes } = useStyles();

  const { user } = useContext(AuthContext);
  const { dataProvider } = useContext(DataContext);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleAgree = () => {
    if (loading || !itemId || !tagName) {
      return;
    }
    setLoading(true);
    dataProvider
      .removeTag(type, itemId, tagName)
      .then(() => {
        setOpen(false);
        onRemove();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  let rootClassName = classes.root;
  if (className) {
    rootClassName += ' ' + className;
  }

  if (!user || !tagName) {
    return null;
  }

  let userTags: Array<{ name: string }> = [];
  if (user && user.userData && user.userData.moderator) {
    userTags = user.userData.moderator;
  }
  let isModerator = userTags.some((tag) => {
    return tag.name === tagName;
  });
  if (user && user.isAdmin) {
    isModerator = true;
  }

  if (!isModerator) {
    return null;
  }

  return (
    <div className={rootClassName}>
      <IconButton className={classes.button} onClick={handleClickOpen}>
        <CloseIcon color="error" fontSize="large" />
      </IconButton>
      <Dialog
        className={classes.paper}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText>
            This will permanently remove this item from the group. You should
            only do this if the item clearly is not related to the group&apos;s
            purpose.
          </DialogContentText>
          <DialogContentText>
            Are you sure you want to do this?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            disabled={loading}
            color="primary"
            variant="contained"
          >
            No
          </Button>
          <Button
            onClick={handleAgree}
            disabled={loading}
            color="secondary"
            variant="contained"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const TagRemove = styled(TagRemoveView)({});

export default TagRemove;
