import { Product } from 'components/CardProduct';
import { Tag } from 'components/CardTag';
import dataProvider from 'dataPrvider';
import { Constants } from 'utilities/constants';

interface IGetTags {
  filter?: {
    $custom: unknown;
  };
  pagination?: {
    page: number;
    perPage: number;
  };
  sort?: {
    field: string;
    order: 'ASC' | 'DESC';
  };
  userId: string | number;
}

export const Api = {
  getProducts: async (queryString: string) => {
    try {
      const data = await dataProvider.getLimitedObjectsBySubstring<Product>(
        'company-products',
        queryString
      );
      return data.data.map((item) => ({
        title: item.title,
        category: 'product',
        topTags: item.topTags,
        id: item._id,
        ideaPoints: item?.ideaPoints
      }));
    } catch (e) {
      return [];
    }
  },
  getCompanies: async (queryString: string) => {
    try {
      const data = await dataProvider.getLimitedObjectsBySubstring<Tag>(
        'tags',
        queryString
      );
      return data.data.map((item) => ({
        title: item.name,
        category: 'company',
        id: item._id,
        ideaPoints: item?.ideaPoints
      }));
    } catch (e) {
      return [];
    }
  },
  getTags: async (
    payload: IGetTags,
    searchFilter: string | null,
    tagFilter: string | null,
    sort: { field: string; order: string } = {
      field: Constants.NAME,
      order: Constants.ASC
    }
  ) => {
    try {
      const { userId } = payload;
      const {
        filter = {
          $custom: {
            category: 'tag',
            type: tagFilter,
            name: searchFilter,
            userId: userId
          }
        },
        pagination
      } = payload;
      const { data = [], total } = await dataProvider.getList<Tag>('tags', {
        filter,
        pagination,
        sort
      });
      return { data, total };
    } catch (e) {
      return [];
    }
  },

  addProduct: async (payload: any) => {
    try {
      const data = await dataProvider.create<Product>('company-products', {
        data: payload
      });
      return data;
    } catch (e) {
      return [];
    }
  },
  addTag: async (payload: any) => {
    const data = await dataProvider.create<Tag>('tags', {
      data: payload
    });
    return data;
  }
};
