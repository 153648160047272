import React from 'react';

function ProblemIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="82"
      height="82"
      fill="none"
      viewBox="0 0 82 82"
    >
      <path
        fill="#FBDADA"
        d="M41 82c22.644 0 41-18.356 41-41S63.644 0 41 0 0 18.356 0 41s18.356 41 41 41z"
      ></path>
      <path
        fill="#C31A1A"
        d="M37.765 45.564c0-6.3 7.02-7.695 7.02-11.61 0-2.205-1.935-3.6-4.86-3.6-2.88 0-5.085 1.26-6.525 3.33l-5.445-3.195c2.43-3.69 6.66-6.03 12.735-6.03 6.75 0 11.43 3.015 11.43 8.325 0 7.2-7.56 8.01-7.56 12.78h-6.795zm3.42 11.295c-2.52 0-4.32-1.845-4.32-4.095 0-2.295 1.8-4.005 4.32-4.005 2.52 0 4.32 1.71 4.32 4.005 0 2.25-1.8 4.095-4.32 4.095z"
      ></path>
    </svg>
  );
}

export default ProblemIcon;
