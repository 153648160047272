import React, { useCallback, useContext, useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { Application } from 'components/CardApplication';
import { Solution } from 'components/CardSolution';
import { PsButton } from 'components/common/PsButton';
import { ModalEditSolution } from 'components/CardSolution';
import { SolutionIcon } from 'components/icons/SolutionIcon';
import { AuthContext } from 'contexts/AuthContext';
import { ModalContext } from 'contexts/ModalContext';
import { getDateStr, makeSolutionValidation } from '../../helpers';
import { PsTheme } from '../../theme';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';

type SolutionWithValidation = Solution & {
  errors: Array<string>;
};

type ClassKey =
  | 'container'
  | 'list'
  | 'item'
  | 'solutionContent'
  | 'firstLineContainer'
  | 'userName'
  | 'addSolution';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    container: {
      maxWidth: '80%',
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      gap: '15px'
    },
    list: {},
    item: {
      display: 'flex',
      flexDirection: 'column'
    },
    firstLineContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      '& .logoAndTitle': {
        display: 'flex',
        gap: '14px',
        alignItems: 'center',
        '& a': {
          fontWeight: 400,
          fontSize: '18px',
          lineHeight: '22px',
          color: psTheme.palette.primary.main,
          margin: '0'
        }
      },
      '& button': {
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '15px',
        color: 'white',
        maxWidth: '157px',
        width: '100%',
        minHeight: '30px',
        borderRadius: '22px'
      }
    },
    solutionContent: {
      margin: '0 0 0 69px',
      fontWeight: 400,
      '& p:nth-child(1)': {
        fontSize: '10px',
        lineHeight: '12px',
        color: '#66788E'
      },
      '& p:nth-child(2)': {
        fontSize: '12px',
        lineHeight: '15px',
        color: 'black'
      }
    },
    userName: {
      color: psTheme.palette.primary.main
    },
    addSolution: {
      '& span': {
        margin: '30px 0 0 0',
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '22px',
        color: psTheme.palette.primary.main,
        cursor: 'pointer',
        textDecoration: 'none'
      },
      '& span:hover': {
        textDecoration: 'underline'
      }
    }
  };
});

export type CardApplicationSolutionsProps = {
  application: Application;
  solutions: Solution[];
  onSolutionsRefresh?: () => void;
};

const CardApplicationSolutionsView = ({
  application,
  solutions = [],
  onSolutionsRefresh
}: CardApplicationSolutionsProps) => {
  const { classes } = useStyles();
  const { user } = useContext(AuthContext);
  const { openSolutionAddToInvention } = useContext(ModalContext);

  const [items, setItems] = useState<Array<SolutionWithValidation>>([]);
  const [editOpened, setEditOpened] = useState(false);
  const [editSolution, setEditSolution] = useState<Solution>();

  const { title, teaser, owner, isFiled } = application;
  const canEdit = user?.isAdmin || user?.id === owner;
  const canEditSolutions = !isFiled && (user?.isAdmin || user?.id === owner);

  useEffect(() => {
    const items: Array<SolutionWithValidation> = solutions.map(
      (item: Solution) => {
        item.errors = makeSolutionValidation(item);
        return item as unknown as SolutionWithValidation;
      }
    );
    setItems(items);
  }, [solutions]);

  const onEditUpdate = useCallback(() => {
    if (onSolutionsRefresh) {
      onSolutionsRefresh();
    }
    setEditOpened(false);
  }, [onSolutionsRefresh, setEditOpened]);

  const showEditPopup = (solution: Solution) => {
    setEditSolution(solution);
    setEditOpened(true);
  };

  const onEditClose = useCallback(() => {
    setEditOpened(false);
  }, [setEditOpened]);

  const onAddSolutionClick = () => {
    openSolutionAddToInvention({ application });
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.list}>
          {items.map((item) => {
            const onClick = () => {
              showEditPopup(item);
            };

            return (
              <div key={item.id} className={classes.item}>
                <div className={classes.firstLineContainer}>
                  <div className="logoAndTitle">
                    <SolutionIcon size={21} />
                    <Link to={`/solutions/${item.key}`}>
                      {item.teaser || item.title}
                    </Link>
                  </div>
                  {canEditSolutions ? (
                    <PsButton color="secondary" onClick={onClick}>
                      Add Item
                    </PsButton>
                  ) : null}
                </div>
                <div className={classes.solutionContent}>
                  <Typography>
                    {getDateStr(item.createdAt)}{' '}
                    {item.ownerInfo && item.ownerInfo.username ? (
                      <>
                        | Created by{' '}
                        <span className={classes.userName}>
                          {
                            <Link to={`/profiles/${item.ownerInfo?.key}`}>
                              {item.ownerInfo?.username ?? 'No user name'}
                            </Link>
                          }
                        </span>
                      </>
                    ) : null}
                  </Typography>
                  <Typography>
                    <strong>Missing items:</strong> {item.errors.join(', ')}{' '}
                  </Typography>
                </div>
              </div>
            );
          })}
        </div>
        {canEdit ? (
          <Typography className={classes.addSolution}>
            <span onClick={onAddSolutionClick}>
              + add a solution as a component of {teaser || title} invention
            </span>
          </Typography>
        ) : null}
      </div>
      {editSolution ? (
        <ModalEditSolution
          solution={editSolution}
          open={editOpened}
          onUpdate={onEditUpdate}
          onClose={onEditClose}
          solutionTitles={[]}
        />
      ) : null}
    </>
  );
};

export const CardApplicationSolutions = styled(CardApplicationSolutionsView)(
  {}
);

export default CardApplicationSolutions;
