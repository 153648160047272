import React, { FC } from 'react';
import { Typography } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { ActionSection } from '../Common/ActionSection';
import { BaseModal } from '../Common/BaseModal';
import { ContentSection } from '../Common/ContentSection';
import { Constants, VARIANT } from 'utilities/constants';
import { HeaderSection } from '../Common/HeaderSection';
import SimpleIconButton from 'components/SimpleIconButton';
import {
  FooterContainer,
  StyledFooterButton,
  StyledHeaderText,
  StyledHeaderWrapper,
  StyledRoyaltyText
} from './StyledComponents';

interface EarnRoyaltiesModalProps {
  onClose?: () => void;
  open: boolean;
}

export const EarnRoyaltiesModal: FC<EarnRoyaltiesModalProps> = ({
  open,
  onClose
}) => {
  return (
    <BaseModal open={open} isEarnRoyaltyBgImage={true}>
      <HeaderSection>
        <StyledHeaderWrapper>
          <StyledHeaderText>{Constants.EARN_ROYALTIES}</StyledHeaderText>
          {onClose && (
            <SimpleIconButton
              color="primary"
              Icon={CancelOutlinedIcon}
              onClick={onClose}
            />
          )}
        </StyledHeaderWrapper>
      </HeaderSection>
      <ContentSection>
        <StyledRoyaltyText>{Constants.EARN_ROYALTIES_TEXT}</StyledRoyaltyText>
      </ContentSection>
      <ActionSection>
        <FooterContainer>
          <StyledFooterButton variant={VARIANT.CONTAINED} onClick={onClose}>
            {Constants.GOT_IT}
          </StyledFooterButton>
        </FooterContainer>
      </ActionSection>
    </BaseModal>
  );
};
