import React, { useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import {
  ModalDataContext,
  StepComponentProps,
  StepComponentType
} from 'contexts/ModalDataContext';
import { PsTheme } from '../../../theme';
import styles from 'modals/ModalWindow.module.scss';
import docImage from 'assets/icons-new/doc-file.png';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root' | 'row' | 'cellImage' | 'cellText' | 'image' | 'text';

const useStyles = makeStyles()(() => {
  const psTheme = useTheme() as PsTheme;
  return {
    root: {},
    row: {
      display: 'flex',
      alignItems: 'center'
    },
    cellImage: {
      width: '35%',
      flexShrink: 0
    },
    cellText: {},
    image: {
      padding: '10px',
      '& img': {
        display: 'block',
        width: '100%',
        height: 'auto'
      }
    },
    text: {
      fontSize: 24,
      lineHeight: '32px',
      fontWeight: 700,
      '& span': {
        color: psTheme.palette.primary.main
      }
    }
  };
});

const DownloadStepView = () => {
  const { values, errors, setErrors, updateField } =
    useContext(ModalDataContext);
  const { classes } = useStyles();

  return (
    <div className={styles.stepWrapper}>
      <div className={classes.row}>
        <div className={classes.cellImage}>
          <div className={classes.image}>
            <img src={docImage} alt="doc-file" />
          </div>
        </div>
        <div className={classes.cellText}>
          <Typography className={classes.text}>
            Download the{' '}
            <span>{values.application ? values.application.title : ''}</span>{' '}
            Patent Application Template
          </Typography>
        </div>
      </div>
    </div>
  );
};

export const DownloadStep = styled(DownloadStepView)(
  {}
) as StepComponentType<StepComponentProps>;

export default DownloadStep;
