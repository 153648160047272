import React, { useContext, useState, useCallback, useEffect } from 'react';
import { styled } from '@mui/material/styles';

import { PsFormControl, ShortEvent } from 'components/common/PsFormControl';
import { PsSelect, PsOptionType } from 'components/common/PsSelect';
import { PsSelectedList } from 'components/common/PsSelectedList';
import { PsInput } from 'components/common/PsInput';
import { Contest } from 'components/CardContest';
import { Tag } from 'components/CardTag';
import {
  ModalDataContext,
  StepComponentProps,
  StepComponentType
} from 'contexts/ModalDataContext';
import { DataContext } from 'contexts/DataContext';
import { AuthContext } from 'contexts/AuthContext';
import styles from 'modals/ModalWindow.module.scss';

type ClassKey = 'root';

export type ContestNameProps = StepComponentProps;

const ContestNameView: StepComponentType<ContestNameProps> = ({
  onNotify
}: ContestNameProps) => {
  const { user } = useContext(AuthContext);
  const { dataProvider } = useContext(DataContext);
  const { values, updateField } = useContext(ModalDataContext);
  const [title, setTitle] = useState(values.titleChallenge);
  const [header, setHeader] = useState(values.challengeHeader);

  const [tags, setTags] = useState<Array<PsOptionType>>([]);
  const [tagsValue, setTagsValue] = useState<string>(
    values.challengeTags ? values.challengeTags.join(',') : ''
  );

  const [contests, setContests] = useState<Array<PsOptionType>>([]);
  const [contestsValue, setContestsValue] = useState<string>(
    values.challengeContests ? values.challengeContests.join(',') : ''
  );

  useEffect(() => {
    dataProvider
      .getList<Tag>('tags', {
        pagination: { page: 1, perPage: 50000 },
        sort: { field: 'name', order: 'ASC' },
        filter: { $custom: { type: 'forChallenge' } }
      })
      .then(({ data = [] }) => {
        const list: Array<PsOptionType> = data.map((item: Tag) => ({
          value: `${item.id}`,
          label: item.name || '(empty)'
        }));
        setTags(list);
      })
      .catch(() => {
        setTags([]);
      });

    const first = {
      value: '--create--',
      label: `Create New Contest`,
      raw: ''
    };
    // hide 'Create New Problem' in Contest modal
    updateField('selectApplication', 'false');

    dataProvider
      .getList<Contest>('contests', {
        pagination: { page: 1, perPage: 50000 },
        sort: { field: 'name', order: 'ASC' },
        filter: {}
      })
      .then(({ data = [] }) => {
        const list: Array<PsOptionType> = data.map((item: Contest) => ({
          value: `${item.id}`,
          label: item.title || '(empty)'
        }));
        setContests([first, ...list]);
      })
      .catch(() => {
        setContests([first]);
      });
  }, []);

  const onNameChange = useCallback(
    (e: ShortEvent) => {
      setTitle(e.target.value);
      updateField('titleChallenge', e.target.value);
    },
    [setTitle, updateField]
  );

  const onHeaderChange = useCallback(
    (e: ShortEvent) => {
      setHeader(e.target.value);
      updateField('challengeHeader', e.target.value);
    },
    [setHeader, updateField]
  );

  const onTagsChange = useCallback(
    (event: ShortEvent) => {
      const { value } = event.target;
      setTagsValue(value);
      if (value) {
        updateField('challengeTags', value.split(','));
      } else {
        updateField('challengeTags', []);
      }
    },
    [setTagsValue, updateField]
  );

  const onContestsChange = useCallback(
    (event: ShortEvent) => {
      const { value } = event.target;
      const valueList = value.split(',');
      if (
        value &&
        valueList.some((val: string) => val.startsWith('--create--'))
      ) {
        if (onNotify) {
          onNotify('create contest');
        }
        return;
      }
      setContestsValue(value);
      if (value) {
        updateField('challengeContests', value.split(','));
      } else {
        updateField('challengeContests', []);
      }
    },
    [setContestsValue, updateField]
  );

  const onContestsInputChange = (text: string) => {
    const first = contests[0];
    const isFirstCreate = first && first.value.startsWith('--create--');

    if (text) {
      const first = {
        value: `--create--, ${text}`,
        label: `Create New Contest`,
        raw: text
      };
      if (isFirstCreate) {
        const [, ...rest] = contests;
        setContests([first, ...rest]);
      } else {
        setContests([first, ...contests]);
      }
    }
  };

  return (
    <div className={styles.stepWrapper}>
      <PsFormControl
        placeholder="Type your challenge header here"
        label="Enter Challenge Header"
      >
        <PsInput
          name="titleChallenge"
          value={header}
          onChange={onHeaderChange}
        />
      </PsFormControl>

      <PsFormControl
        placeholder="Type your challenge name here"
        label="Enter Challenge Name"
      >
        <PsInput name="challengeHeader" value={title} onChange={onNameChange} />
      </PsFormControl>

      <PsFormControl
        placeholder="Select appropriate groups for the contest"
        label="Select groups"
      >
        <PsSelect
          isMulti
          maxMenuHeight={270}
          name="challengeTags"
          onChange={onTagsChange}
          options={tags}
          value={tagsValue}
        />
      </PsFormControl>
      <PsSelectedList
        options={tags}
        value={tagsValue}
        onChange={onTagsChange}
      />

      <PsFormControl
        placeholder="Select appropriate contests"
        label="Select contests"
      >
        <PsSelect
          isMulti
          maxMenuHeight={270}
          name="challengeContests"
          onInputChange={onContestsInputChange}
          options={contests}
          onChange={onContestsChange}
          value={contestsValue}
        />
      </PsFormControl>
      <PsSelectedList
        options={contests}
        value={contestsValue}
        onChange={onContestsChange}
      />
    </div>
  );
};

export const ChallengeName = styled(ContestNameView)({});

export default ChallengeName;
