import React, { useContext, useState, useEffect, useRef } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { NavBar, ContentBar } from '../../layout';
import { dataProvider } from '../../dataPrvider';
import { FetchError } from '../../fetchUtils';
import { PsTheme } from '../../theme';
import useRouter from '../../hooks/useRouter';

import { AuthContext } from 'contexts/AuthContext';
import { DataContext } from 'contexts/DataContext';

import { CardChallengeDetail, Challenge } from 'components/CardChallenge';
import { Tab, TabContent, Tabs } from 'components/Tabs/Tabs';
import { RelatedApplications } from 'components/RelatedApplications';
import { RelatedSolutions } from 'components/RelatedSolutions/RelatedSolutions';
import { RelatedContests } from 'components/RelatedContests/RelatedContests';
import { RelatedProblems } from 'components/RelatedProblems/RelatedProblems';
import { PsHttpError } from 'components/common/PsHttpError';
import Helmet from 'components/Helmet';
import { makeStyles } from 'tss-react/mui';

type ClassKey =
  | 'container'
  | 'wrapper'
  | 'content'
  | 'loading'
  | 'block'
  | 'header'
  | 'related'
  | 'box'
  | 'bandHeaderItem';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxWidth: 1255,
      margin: '0 auto',
      padding: '48px 10px'
    },
    wrapper: {
      display: 'flex'
    },
    content: {},
    loading: {
      marginBottom: 10,
      textAlign: 'center'
    },
    block: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginTop: 34
    },
    header: {
      fontSize: 32,
      fontWeight: 'bold',
      lineHeight: '39px',
      color: psTheme.palette.third.main
    },
    related: {
      padding: '30px 0 0 0'
    },
    box: {
      gridRow: '1',
      width: '100%',
      height: '41.37px',
      background: '#FFFFFF',
      marginTop: '-40px',
      marginBottom: 10
    },
    bandHeaderItem: {
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '24px',
      lineHeight: '29px',
      color: '#632DDD',
      textAlign: 'center',
      marginTop: '6px'
    }
  };
});

const HeadInfo = ({ challenge }: { challenge?: Challenge }) => {
  if (!challenge) {
    return null;
  }

  const image = (challenge.files || []).find(({ url }: any) => {
    if (url && !url.endsWith('.svg')) {
      return true;
    }
  });
  // const imageUrl = image
  //   ? image.url
  //   : process.env.HOST + '/placeholder/tag.jpg';
  // const url =
  //   process.env.HOST + '/challenges/' + (challenge.key || challenge.id);
  const title = challenge.title || 'Challenge';
  const description = `Join the ${title} contest with ${challenge.ownerInfo?.username} on MindMiner`;
  const keywords = challenge.tagName || '';

  return (
    <header>
      <title>MindMiner - {title}</title>
      <meta name="keywords" content={keywords} />
      {/* <meta property="og:url" content={url} /> */}
      <meta property="og:type" content="article" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {/* <meta property="og:image" content={imageUrl} /> */}
      {/*<meta name="description" content="Generated by create next app" />*/}
    </header>
  );
};

const ChallengeDetailsView = () => {
  const { classes } = useStyles();
  const state = useRef({ initRender: true });

  const { dataProvider, challengeDetailCache } = useContext(DataContext);
  const { user } = useContext(AuthContext);

  const [relatedTab, setRelatedTab] = useState('Contests');
  const [challenge, setChallenge] = useState<Challenge | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<FetchError | undefined>(undefined);

  const router = useRouter();
  const { key } = router.query;

  useEffect(() => {
    const fetchData = async () => {
      const data = await dataProvider.getOneByKey<Challenge>('challenges', {
        key: key as string
      });

      setChallenge(data.data);
      return data;
    };
    fetchData()
      .catch((error: any) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (state.current.initRender) {
      state.current.initRender = false;
      return;
    }

    setLoading(true);
    setError(undefined);
    dataProvider
      .getOneByKey<Challenge>('challenges', { key: key as string })
      .then(({ data }: any) => {
        setChallenge(data);
      })
      .catch((err: any) => {
        console.error(err);
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [key, challengeDetailCache, user]);

  return (
    <div className={classes.container}>
      <HeadInfo challenge={challenge} />
      <Helmet title="Challenges" />
      <div className={classes.box}>
        <div className={classes.bandHeaderItem}>Challenge</div>
      </div>
      <div className={classes.wrapper}>
        <NavBar />

        <ContentBar className={classes.content}>
          {loading ? (
            <Typography className={classes.loading} variant="body2">
              Loading...
            </Typography>
          ) : null}
          <PsHttpError error={error} />
          {challenge ? (
            <>
              <CardChallengeDetail challenge={challenge} />
              <div className={classes.related}>
                <Tabs>
                  <Tab
                    label="Contests"
                    value={relatedTab}
                    setValue={setRelatedTab}
                  />
                  <Tab
                    label="Problems"
                    value={relatedTab}
                    setValue={setRelatedTab}
                  />
                  <Tab
                    label="Solutions"
                    value={relatedTab}
                    setValue={setRelatedTab}
                  />
                  <Tab
                    label="Idea NFTs"
                    value={relatedTab}
                    setValue={setRelatedTab}
                  />
                </Tabs>
                <TabContent label="Contests" value={relatedTab}>
                  <RelatedContests challenge={challenge?.id} />
                </TabContent>
                <TabContent label="Problems" value={relatedTab}>
                  <RelatedProblems challenge={challenge?.id} />
                </TabContent>
                <TabContent label="Solutions" value={relatedTab}>
                  <RelatedSolutions challenge={challenge?.id} />
                </TabContent>
                <TabContent label="Idea NFTs" value={relatedTab}>
                  <RelatedApplications challenge={challenge?.id} />
                </TabContent>
              </div>
            </>
          ) : null}
        </ContentBar>
      </div>
    </div>
  );
};

const ChallengeDetails = styled(ChallengeDetailsView)({});

export default ChallengeDetails;
