import React, { memo } from 'react';
import { Application } from 'components/CardApplication';
import { Constants, PATH_NAMES } from 'utilities/constants';
import { EntityIcon } from '../TreeGraph/EntityIcon';
import { TeaserText } from '../ActiveConceptCard/styledComponents';
import {
  IconWrapper,
  ProblemContainer,
  ProblemIcon,
  ScrollBox,
  WhiteSpace
} from './styledComponents';
import { SolutionBox } from './SolutionBox';

interface ConceptNodesProps {
  concept: Application;
  maxHeight?: string;
}

const ConceptItemNodesComponent = ({
  concept,
  maxHeight = '300px'
}: ConceptNodesProps) => {
  const ProblemBox = ({ problem }) => (
    <ProblemContainer
      onClick={() =>
        window.open(`${PATH_NAMES.PROBLEMS}/${problem.key}`, Constants._BLANK)
      }
    >
      <IconWrapper>
        <ProblemIcon>
          <EntityIcon type={Constants.PROBLEM} />
        </ProblemIcon>
        <TeaserText>{problem.teaser || problem.title}</TeaserText>
        <WhiteSpace />
      </IconWrapper>
    </ProblemContainer>
  );

  return (
    <ScrollBox maxHeight={maxHeight}>
      {concept?.problems?.map((problem) => {
        const filteredSolutions = concept?.solutions?.filter((solution) =>
          problem?.children?.some((child) => child.id === solution.id)
        );
        return filteredSolutions?.length ? (
          <React.Fragment key={problem.id}>
            <ProblemBox problem={problem} />
            {filteredSolutions.map((solution) => (
              <SolutionBox
                key={solution.id}
                solution={solution}
                concept={concept}
              />
            ))}
          </React.Fragment>
        ) : null;
      })}
    </ScrollBox>
  );
};

export const ConceptItemNodes = memo(
  ConceptItemNodesComponent,
  (prevProps, nextProps) => {
    return prevProps.concept === nextProps.concept;
  }
);
