import React from 'react';
import { FunctionComponent } from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const TreeIcon: FunctionComponent<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props} viewBox="0 0 21.952 21.952" fontSize="small">
    <path
      d="M5.488 8.689V.457A.464.464 0 0 0 5.031 0H.457A.464.464 0 0 0 0 .457v8.232a.464.464 0 0 0 .457.457h4.574a.464.464 0 0 0 .457-.457Zm16.464-3.658V.457A.464.464 0 0 0 21.495 0h-4.574a.464.464 0 0 0-.457.457v4.574a.464.464 0 0 0 .457.457h4.573a.464.464 0 0 0 .458-.457Zm0 8.232V8.689a.464.464 0 0 0-.457-.457h-4.574a.464.464 0 0 0-.457.457v4.573a.464.464 0 0 0 .457.457h4.573a.464.464 0 0 0 .458-.456Zm0 8.232v-4.574a.464.464 0 0 0-.457-.457h-4.574a.464.464 0 0 0-.457.457v4.573a.464.464 0 0 0 .457.457h4.573a.464.464 0 0 0 .458-.456Zm-6.4-19.437a.22.22 0 0 0-.229-.229h-.686a8.717 8.717 0 0 1-3.318-.857 10.479 10.479 0 0 0-4-.972h-.688A.22.22 0 0 0 6.4.229V1.6a.22.22 0 0 0 .229.229h.686a8.717 8.717 0 0 1 3.316.858 10.479 10.479 0 0 0 4 .972h.686a.22.22 0 0 0 .229-.229Zm0 8.232a.22.22 0 0 0-.229-.229h-.686c-.9 0-1.963-1.505-2.916-2.82-1.258-1.753-2.553-3.582-4.4-3.582h-.69a.22.22 0 0 0-.229.229v1.371a.22.22 0 0 0 .229.229h.686c.9 0 1.963 1.505 2.916 2.82 1.258 1.753 2.553 3.582 4.4 3.582h.686a.22.22 0 0 0 .229-.229Zm0 8.232a.22.22 0 0 0-.229-.229h-.686c-.8 0-2.42-3.239-3.3-4.974-1.542-3.067-2.876-5.754-4.687-5.983a.225.225 0 0 0-.248.229v1.391a.248.248 0 0 0 .152.229c.84.324 2.326 3.315 3.146 4.954 1.6 3.22 2.992 5.984 4.935 5.984h.686a.22.22 0 0 0 .229-.229v-1.372Z"
      data-name="Path 422"
    />
  </SvgIcon>
);
