import React from 'react';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import {
  StyledArchiveBox,
  StyledToggleButtonArchive,
  StyledToggleButtonUnarchive
} from './styles';

interface ArchiveToggleProps {
  archiveStatus: boolean;
  handleArchiveToggle: (archive: boolean) => void;
}

const ArchiveToggleButton: React.FC<ArchiveToggleProps> = ({
  handleArchiveToggle,
  archiveStatus
}) => {
  return (
    <StyledArchiveBox>
      <StyledToggleButtonArchive
        title="Archived"
        value="left"
        active={archiveStatus}
        onClick={() => handleArchiveToggle(true)}
      >
        <ArchiveIcon />
      </StyledToggleButtonArchive>
      <StyledToggleButtonUnarchive
        title="Unarchived"
        value="right"
        active={!archiveStatus}
        onClick={() => handleArchiveToggle(false)}
      >
        <UnarchiveIcon />
      </StyledToggleButtonUnarchive>
    </StyledArchiveBox>
  );
};

export default ArchiveToggleButton;
