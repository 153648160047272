import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Application } from 'components/CardApplication';
import { PsButton } from 'components/common/PsButton';
import { PsLogo } from 'components/common/PsLogo';
import Step from 'components/Step';
import Stepper from 'components/Stepper';
import { AuthContext } from 'contexts/AuthContext';
import { DataContext, PayProduct } from 'contexts/DataContext';
import { ModalComponentsKeys, ModalContext } from 'contexts/ModalContext';
import {
  BuildPatentApplicationSteps,
  ModalDataContext,
  StepComponentProps
} from 'contexts/ModalDataContext';
import { finalizeType } from 'helpers';
import ModalWindow from 'modals/ModalWindow';
import styles from 'modals/ModalWindow.module.scss';
import Actions from 'redux-state/actions';
import { makeStyles } from 'tss-react/mui';
import {
  BUILD_PATENT_APPLICATION_STEPS_CONFIG,
  productSignStatus,
  stepName
} from './config';
import { InitialPatentFilingModal } from '../InitialPatentFiling';

const steps: string[] = ['Apply Credit', 'Sign & Pay'];

const stepsCoinbase: string[] = ['Apply Credit', 'Sign Documents', 'Payment'];

const useButtonClasses = makeStyles()(() => {
  return {
    root: {
      margin: '8px 0',
      fontWeight: 400
    }
  };
});

export const BuildPatentApplication: React.FC = () => {
  const { userCredits, user } = useContext(AuthContext);
  const dispatch = useDispatch();
  const { openModal, closeModal } = useContext(ModalContext);
  const { values, updateField, setErrors, resetFields } =
    useContext(ModalDataContext);
  const { dataProvider, refreshApplicationDetail, refreshProfileStats } =
    useContext(DataContext);
  const theme = useTheme();
  const [prevSteps, setPrevSteps] = useState<string[]>([]);
  const [activeStepNumber, setActiveStepNumber] = useState<number>(0);
  const [currentStep, setCurrentStep] = useState<string | undefined>(
    BuildPatentApplicationSteps.CreditsStep
  );
  const [loading, setLoading] = useState(false);
  const [showInitialChargeModal, setShowInitialChargeModal] = useState(false);
  const stripeKey = (user && user.userData?.STRIPE_PUB_KEY) || '';
  const [stripe, setStripe] = useState<unknown>(null);
  const [stepsName, setStepsName] = useState<Array<string>>(
    values.brainMethod === 'docusign' ? steps : stepsCoinbase
  );

  const buttonClasses = useButtonClasses();

  const {
    Component,
    nextStep,
    name,
    validate,
    isNextStepBlocked,
    nextButtonTitle
  } = BUILD_PATENT_APPLICATION_STEPS_CONFIG[currentStep || ''];

  const StepComponent = Component as React.FC<StepComponentProps>;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initializeStripe = () => {
    loadStripe(stripeKey)
      .then((res) => {
        setStripe(res);
      })
      .catch((err) => {
        // Need to handle error
        console.log('error', err);
      });
  };

  useEffect(() => {
    if (stripe || !stripeKey) return;
    initializeStripe();
  }, [initializeStripe, stripe, stripeKey]);

  useEffect(() => {
    // payProducts
    const app = values.application as Application;
    if (app) {
      if (
        (values.payProducts && values.payProducts.length && !app.isPaid) ||
        (values.payProducts &&
          values.payProducts.length &&
          app.isShared &&
          !app.isExclusivityPaid)
      ) {
        // show Sign or Pay
        if (app.paymentType === 'coinbase') {
          // 3 steps coinbase
          let isAllSigned = true;
          (values.payProducts || []).forEach((product) => {
            if (product.status !== productSignStatus.SIGNED) {
              isAllSigned = false;
            }
          });
          if (isAllSigned) {
            // pay step
            setPrevSteps([
              BuildPatentApplicationSteps.CreditsStep,
              BuildPatentApplicationSteps.ChooseTypeStep,
              BuildPatentApplicationSteps.ChoosePaymentStep,
              BuildPatentApplicationSteps.SignDocumentsStep
            ]);
            setErrors({});
            setActiveStepNumber(4);
            setCurrentStep(BuildPatentApplicationSteps.PayDocumentsStep);
          } else {
            // sign step
            setPrevSteps([
              BuildPatentApplicationSteps.CreditsStep,
              BuildPatentApplicationSteps.ChooseTypeStep,
              BuildPatentApplicationSteps.ChoosePaymentStep
            ]);
            setErrors({});
            setActiveStepNumber(3);
            setCurrentStep(BuildPatentApplicationSteps.SignDocumentsStep);
          }
        } else {
          // 2 steps docusign
          setPrevSteps([
            BuildPatentApplicationSteps.CreditsStep,
            BuildPatentApplicationSteps.ChooseTypeStep,
            BuildPatentApplicationSteps.ChoosePaymentStep
          ]);
          setErrors({});
          setActiveStepNumber(3);
          setCurrentStep(BuildPatentApplicationSteps.SignDocumentsStep);
        }
      }
    }
  }, []);

  useEffect(() => {
    updateField('tempNumber', userCredits);
  }, [userCredits]);

  const handleClickBack = () => {
    if (activeStepNumber === 0) {
      closeModal();
      resetFields();
      return;
    }

    // payProducts
    const app = values.application as Application;
    if (app.paymentType === 'docusign') {
      setStepsName(steps);
    } else {
      setStepsName(stepsCoinbase);
    }

    if (name === stepName.SIGN && values.application) {
      if (user) {
        dataProvider.payOptionClear(values.application.id);
      } else {
        dispatch(Actions.openAuthModal(true));
      }
    }
    setActiveStepNumber((prev) => Math.max(0, prev - 1));
    setCurrentStep(prevSteps[prevSteps.length - 1]);
    setPrevSteps(prevSteps.slice(0, prevSteps.length - 1));
  };

  const handleNextStep = async () => {
    if (typeof validate === 'function') {
      const { success, errors } = validate(values);

      if (!success) {
        setErrors({ ...errors });

        return;
      }
    }

    if (
      name === stepName.SELECT_TYPE &&
      values.finalizeType === finalizeType.OPEN
    ) {
      if (loading) {
        return;
      }
      if (user) {
        setLoading(true);
        const appId = values.application ? values.application.id : '';
        dataProvider
          .payOptionStart(values.application.id, {
            id: values.productId || '',
            finalizeType: values.finalizeType || finalizeType.OWN,
            paymentType: values.brainMethod
          })
          .then(() => {
            return dataProvider.payOptionSign(appId, '', '');
          })
          .then((data) => {
            if (data.redirect && typeof window !== 'undefined') {
              window.open(data.redirect, '_self');
            }
            if (data.link && typeof window !== 'undefined') {
              window.open(data.link, '_self');
            }
          })
          .catch((err) => {
            console.error(err);
            setLoading(false);
          });
      } else {
        dispatch(Actions.openAuthModal(true));
      }
      return;
    }

    if (name === stepName.SELECT_PAYMENT && values.application) {
      setStepsName(values.brainMethod === 'docusign' ? steps : stepsCoinbase);
      // submit selected pay option
      if (values.title === finalizeType.OPEN) {
        //
      } else {
        let products: Array<PayProduct> | null = null; //values.application.products;
        if (!products) {
          if (user) {
            products = await dataProvider.payOptionStart(
              values.application.id,
              {
                id: values.productId || '',
                finalizeType: values.finalizeType || finalizeType.OWN,
                paymentType: values.brainMethod
              }
            );
            updateField('payProducts', products);
          } else {
            dispatch(Actions.openAuthModal(true));
          }
        }
      }
    }

    if (values.brainMethod === 'skip') {
      dataProvider
        .payOptionFinish(values.application.id)
        .then(() => {
          refreshApplicationDetail();
          refreshProfileStats();
          openModal(ModalComponentsKeys.buildPatentApplicationSuccess);
        })
        .catch((err) => {
          console.error(err);
        });
      return;
    } else if (values.brainMethod === 'coinbase') {
      // 3 steps
      if (name === stepName.PAY && values.application) {
        dataProvider
          .payOptionFinish(values.application.id)
          .then(() => {
            refreshApplicationDetail();
            refreshProfileStats();
            openModal(ModalComponentsKeys.buildPatentApplicationSuccess);
          })
          .catch((err) => {
            console.error(err);
          });
        return;
      }
    } else {
      // 2 steps
      if (name === stepName.SIGN && values.application) {
        setShowInitialChargeModal(true);
        return;
      }
    }

    setPrevSteps([...prevSteps, currentStep || '']);
    setErrors({});
    setActiveStepNumber((prev) => prev + 1);
    if (typeof nextStep === 'function') {
      setCurrentStep(nextStep(values));
      return;
    }

    setCurrentStep(nextStep);
  };

  const onStepNotify = (
    args: 'terms-click' | 'products-refresh' | 'credits-buy'
  ) => {
    const app = values.application as Application;
    if (args === 'terms-click') {
      if (loading) {
        return;
      }
      setLoading(true);
      dataProvider
        .applicationSign(app.id)
        .then((data) => {
          if (data.redirect || data.link) {
            if (data.redirect) {
              window.open(data.redirect, '_self');
            } else {
              window.open(data.link, '_self');
            }
          } else {
            updateField('termsAgree', true);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    }
    if (args === 'products-refresh') {
      dataProvider
        .getOne<Application>('applications', { id: app.id })
        .then(({ data = {} }: { data?: Partial<Application> }) => {
          updateField('payProducts', data.products || []);
        })
        .catch((err) => {
          console.error(err);
        });
    }
    if (args === 'credits-buy') {
      if (app && typeof window !== undefined) {
        window.localStorage['beforeCreditsAppKey'] = app.key;
      }
      setLoading(true);
    }
  };

  const isNextStepDisabled: boolean =
    typeof isNextStepBlocked === 'function' && isNextStepBlocked(values);

  let stepNumber = activeStepNumber;
  let finalNextButtonTitle = nextButtonTitle;
  if (stepNumber < 4) {
    stepNumber = 0;
  } else if (stepNumber >= 4 && stepNumber < 5) {
    stepNumber = 1;
    if (values.brainMethod !== 'coinbase') {
      finalNextButtonTitle = 'Finish';
    }
  } else if (stepNumber >= 5) {
    stepNumber = 2;
    finalNextButtonTitle = 'Finish';
  }

  const handleAgree = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    updateField('application', {});
    refreshApplicationDetail();
    refreshProfileStats();
    setShowInitialChargeModal(false);
    if (!stripe) {
      console.error('Stripe.js has not loaded.');
      return;
    }

    try {
      //TODO: Need to remove this after QA
      // const app = values.application as Application;
      // dataProvider
      //   .initialPaymentSession<{ sessionId: string; type: string }>(
      //     app.id,
      //     '',
      //     ''
      //   )
      //   .then(
      //     (data: {
      //       sessionId: string;
      //       type: string;
      //       stripeSessionId: string;
      //     }) => {
      //       if (data.stripeSessionId) {
      //         return loadStripe(stripeKey).then(async (stripe) => {
      //           if (stripe) {
      //             await stripe.redirectToCheckout({
      //               sessionId: data.stripeSessionId
      //             });
      //           }
      //         });
      //       } else {
      //         location.reload();
      //       }
      //     }
      //   )
      //   .catch((err) => {
      //     console.error(err);
      //   });
    } catch (error) {
      // TODO: Need to handle error
      console.log('Error processing payment:', error);
    }
  };

  return (
    <ModalWindow
      title="Mint Invention NFT for"
      titleAfter={
        <span className={styles.modalTitleIcon}>
          Earn IdeaPoints:{' '}
          <PsLogo size={23} color={theme.palette.primary.main} small />
        </span>
      }
      subTitle={
        <>
          <b>{values.application ? values.application.title : null}</b>
        </>
      }
    >
      <Stepper className={styles.stepper} activeStep={stepNumber}>
        {stepsName.map((step) => (
          <Step key={step}>{step}</Step>
        ))}
      </Stepper>
      <StepComponent onNotify={onStepNotify} />
      {showInitialChargeModal && (
        <InitialPatentFilingModal
          isOpen
          handleAgree={handleAgree}
          onClose={() => setShowInitialChargeModal(false)}
        />
      )}
      <div className={styles.actions}>
        <PsButton
          disabled={isNextStepDisabled || loading}
          onClick={handleNextStep}
          fullWidth
          classes={buttonClasses}
          loading={loading}
        >
          {finalNextButtonTitle || 'Next'}
        </PsButton>
        <PsButton
          onClick={handleClickBack}
          fullWidth
          cancel
          classes={buttonClasses}
        >
          Back
        </PsButton>
      </div>
    </ModalWindow>
  );
};

export default BuildPatentApplication;
