import React, { useContext } from 'react';
import { PsFormControl } from 'components/common/PsFormControl';
import { PsInput } from 'components/common/PsInput';
import { ModalDataContext } from 'contexts/ModalDataContext';
import { ImageField } from 'components/ImageField';
import { defaultProblemTitle } from '../config';
import styles from 'modals/ModalWindow.module.scss';

const FinilizeProblemStep: React.FC = () => {
  const { values, handleInputChange } = useContext(ModalDataContext);
  const { titleProblem, descriptionProblem } = values;

  let aiText = '';
  if (titleProblem) {
    aiText = titleProblem.slice(defaultProblemTitle.length);
  }

  return (
    <div className={styles.stepWrapper}>
      <PsFormControl
        placeholder="Type Your Description here"
        label="Describe Your Problem"
        labelInfo={true}
        helperText="Example: The problem of the buildup of fluid energy to prevent time travel"
      >
        <PsInput
          multiline
          name="descriptionProblem"
          onChange={handleInputChange}
          rows={4}
          value={descriptionProblem}
        />
      </PsFormControl>

      <ImageField
        name="filesProblem"
        value={values.filesProblem}
        onChange={handleInputChange}
        aiText={aiText}
        supportDraw
      />
    </div>
  );
};

export default FinilizeProblemStep;
