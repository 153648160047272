import { colorPalette } from '../../../theme';
import { Avatar, Box, Button, styled, Typography } from '@mui/material';
import { Constants } from 'utilities/constants';

const grandParent = 'product';
const parent = 'problem';
const child = 'solution';

export const StyledAddToConceptText = styled(Typography)({
  fontSize: '.875rem',
  lineHeight: '1.25rem',
  whiteSpace: 'nowrap'
});

export const AddToConceptButton = styled(Button)(({ theme }) => ({
  backgroundColor: colorPalette.lightGreen,
  borderRadius: '1.5625rem',
  color: colorPalette.white,
  display: 'none',
  padding: '.625rem .9375rem',
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

export const ReactionButtons = styled(Box)<{ type: string }>(
  ({ type, theme }) => ({
    [theme.breakpoints.up('md')]: {
      borderRadius: '1.875rem',
      minHeight: '2.8125rem',
      minWidth: '7.0625rem',
      padding: '.625rem 1.125rem'
    },
    alignItems: 'center',
    borderRadius: '1.875rem',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '1rem',
    minWidth: '5.625rem',
    padding: '.3rem .5rem',
    backgroundColor:
      type == grandParent
        ? colorPalette.magnolia
        : type == parent
          ? colorPalette.amour
          : type == child
            ? colorPalette.snowyMint
            : colorPalette.white
  })
);

export const StyledLikes = styled(Typography)({
  color: colorPalette.black,
  fontFamily: 'montserrat',
  fontSize: '1rem',
  fontWeight: '500',
  margin: '0rem .625rem 0rem .625rem'
});

export const AddToButton = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end'
});

export const ButtonContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'end'
});

export const ImageContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'none',
  justifyContent: 'center',
  padding: '0 .938rem',
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

export const StyledAvatar = styled(Avatar)({
  borderRadius: '.625rem'
});

export const StyledBox = styled(Box)<{
  isProblemSelected?: boolean;
  isSolutionSelected?: boolean;
  type: string;
}>(({ isProblemSelected, isSolutionSelected, type }) => ({
  alignItems: 'center',
  borderRadius: '3.75rem',
  display: 'flex',
  justifyContent: 'flex-start',
  backgroundColor:
    type === 'problem'
      ? isProblemSelected
        ? colorPalette.amour
        : colorPalette.roseWhite
      : type === 'solution'
        ? isSolutionSelected
          ? colorPalette.mintGreen
          : colorPalette.sugarCane
        : '',
  minHeight: '3rem',
  boxShadow: `.125rem .25rem .7rem 0rem ${colorPalette.boxShadowGrey}`,
  width: '100%',
  marginBottom: '0.8rem',
  '&[data-type="product"]:hover': {
    backgroundColor: `${colorPalette.cosmicLatte} !important`
  },
  '&[data-type="problem"]:hover': {
    backgroundColor: `${colorPalette.pastelPink} !important`
  },
  border:
    type == 'product'
      ? `1px solid ${colorPalette.purpleBlue}`
      : type == 'problem'
        ? `1px solid ${colorPalette.torchRed}`
        : type == 'solution'
          ? `1px solid ${colorPalette.malachite}`
          : type == Constants.APPLICATION || Constants.Invention
            ? `1px solid ${colorPalette.mustard}`
            : `1px solid ${colorPalette.black}`,
  '&.MuiTreeItem-iconContainer': {
    width: '6.25rem !important'
  }
}));

export const StyledInnerBox = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0',
  width: '100%',
  [theme.breakpoints.up('xl')]: {
    padding: '.313rem 0'
  }
}));

export const TextContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  maxWidth: '50.25rem'
});

export const TitleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingRight: '.9375rem',
  [theme.breakpoints.down('sm')]: {
    paddingRight: '0.3em'
  }
}));

export const VotingBox = styled(Box)({ padding: '0rem .3125rem' });

export const StackBox = styled(Box)({ padding: '0rem .3125rem' });

export const StackButton = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  },
  display: 'none'
}));

export const AddToBox = styled(Box)({
  display: 'flex',
  padding: '0rem .3125rem'
});

export const StyledTitle = styled(Typography)<{ type: string }>(({ type }) => ({
  '-webkit-box-orient': 'vertical',
  '-webkit-line-clamp': '2',
  display: '-webkit-box',
  fontSize: '.9375rem',
  fontWeight: type == 'product' ? '700' : '500',
  lineHeight: type == 'product' ? '1.25rem' : '1.25rem',
  overflow: 'hidden',
  overflowX: 'hidden',
  textOverflow: 'ellipsis',
  width: '100%'
}));

export const StyledCircularProgressMainContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '1.25rem',
  position: 'relative'
});

export const StyledCircularProgressSubContainer = styled(Box)({
  marginBottom: '.625rem'
});

export const StyledCircularProgressText = styled(Typography)({
  textAlign: 'center'
});

export const StyledCircularProgressBar = styled(Box)({
  alignItems: 'center',
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  left: 0,
  position: 'absolute',
  right: 0,
  top: 0
});

export const StyledCircularProgressBarText = styled(Typography)({
  component: 'div',
  fontSize: '1.5625rem',
  marginTop: '2.5rem'
});
