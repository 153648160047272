import React, { useState, useCallback, useContext, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { ModalComponentsKeys, ModalContext } from 'contexts/ModalContext';
import { ModalDataContext } from 'contexts/ModalDataContext';
import { AuthContext } from 'contexts/AuthContext';
import { PriorArt, ModalEditPriorArt } from 'components/CardPriorArt';
import {
  Card,
  CardImage,
  CardBody,
  CardTitle,
  CardActions
} from 'components/card';
import { PsButton, EditButton } from 'components/common/PsButton';
import { Tags } from 'components/Tag';
import { Coins } from 'components/Coins';
import { ShareBtn } from 'components/ShareBtn';
import { VotesSmall } from 'components/Votes';
import { placeholderType } from '../../helpers';
import { PsTheme } from '../../theme';
import { makeStyles } from 'tss-react/mui';

const useCardImageStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      width: '40%',
      [psTheme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    inner: {
      paddingTop: '60%',
      [psTheme.breakpoints.down('sm')]: {
        paddingTop: '40%'
      },
      [psTheme.breakpoints.down('xs')]: {
        paddingTop: '55%'
      }
    }
  };
});

type ClassKey =
  | 'root'
  | 'top'
  | 'bottom'
  | 'tags'
  | 'buttons'
  | 'row'
  | 'cell'
  | 'contributors'
  | 'contributorsTitle'
  | 'users'
  | 'user'
  | 'userPhoto'
  | 'actionButtonsMobile'
  | 'tagsButton'
  | 'descriptionRow'
  | 'votesBlock'
  | 'infoRow'
  | 'iconNft'
  | 'iconNftCoins'
  | 'nftCoinsLogo';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      flexDirection: 'column'
    },
    top: {
      display: 'flex',
      width: '100%',
      [psTheme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    },
    bottom: {
      padding: '10px 10px'
    },
    tags: {
      '& > div': {
        marginTop: 4,
        marginBottom: 4
      }
    },
    buttons: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row-reverse',
      justifyContent: 'flex-start',
      alignItems: 'center'
    },
    row: {
      display: 'flex',
      flexDirection: 'row'
    },
    cell: {
      display: 'flex',
      flexDirection: 'column',
      '&:first-child': {
        flexGrow: 1
      },
      '&.likes': {
        [psTheme.breakpoints.down('sm')]: {
          display: 'none'
        }
      }
    },
    contributors: {},
    contributorsTitle: {
      marginBottom: 14,
      fontSize: 18,
      fontWeight: 600
    },
    users: {
      fontSize: 0,
      lineHeight: 0
    },
    user: {
      display: 'inline-block',
      marginRight: 15,
      marginBottom: 15
    },
    userPhoto: {
      display: 'block',
      width: 45,
      height: 45,
      borderRadius: 10,
      objectFit: 'cover'
    },
    actionButtonsMobile: {
      display: 'none',
      '&.mmmm': {
        display: 'none'
      },
      [psTheme.breakpoints.down('sm')]: {
        display: 'flex',
        '&.mmmm': {
          display: 'flex'
        }
      }
    },
    tagsButton: {
      margin: '10px 10px 0 0',
      whiteSpace: 'nowrap'
    },
    descriptionRow: {
      margin: '10px 0 22px',
      fontSize: 18,
      lineHeight: 1.6,
      '& b': {
        fontWeight: 600,
        color: psTheme.palette.primary.main
      }
    },
    votesBlock: {
      marginTop: -7,
      padding: 0,
      background: 'none !important',
      border: 'none !important'
    },
    infoRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      marginBottom: -5,
      paddingTop: 9,
      '& .left': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& button': {
          marginBottom: 10
        }
      },
      '& .right': {
        paddingLeft: 10
      },
      '& .coins': {
        marginRight: 30,
        marginTop: 10,
        marginBottom: 10
      },
      [psTheme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'stretch',
        '& .left': {
          flexWrap: 'wrap'
        },
        '& .right': {
          display: 'none'
        }
      }
    },
    iconNft: {
      marginLeft: -10,
      marginRight: 10
    },
    iconNftCoins: {
      display: 'inline-flex',
      marginLeft: -10,
      marginRight: 10,
      '& svg:first-child': {
        marginRight: -5
      }
    },
    nftCoinsLogo: {
      marginRight: 10,
      '& > svg:first-child': {
        marginRight: -5
      }
    }
  };
});

export type CardPriorArtDetailProps = {
  priorArt: PriorArt;
};

export const CardPriorArtDetailView = ({
  priorArt: initPriorArt
}: CardPriorArtDetailProps) => {
  const { openModal, openInventionImprove } = useContext(ModalContext);
  const { updateField } = useContext(ModalDataContext);
  const cardImageClasses = useCardImageStyles();
  const { user } = useContext(AuthContext);
  const [priorArt, setPriorArt] = useState<PriorArt>(initPriorArt);
  const [editOpened, setEditOpened] = useState(false);
  const { classes } = useStyles();
  const {
    id,
    key,
    title,
    body,
    files,
    tags = [],
    tagsInfo = [],
    createdAt,
    likes,
    dislikes,
    reward = 0
  } = priorArt;

  const canEdit = user && user.isAdmin;

  const showEditTags = (user?.userData?.moderator || []).length;
  const image = files && files.length ? files[0] : undefined;

  useEffect(() => {
    setPriorArt(initPriorArt);
  }, [initPriorArt]);

  const onEditUpdate = useCallback(
    (newPriorArt: PriorArt) => {
      setPriorArt(newPriorArt);
      setEditOpened(false);
    },
    [setPriorArt, setEditOpened]
  );

  const onEditOpen = useCallback(() => {
    setEditOpened(true);
  }, [setEditOpened]);

  const onEditClose = useCallback(() => {
    setEditOpened(false);
  }, [setEditOpened]);

  const onImproveClick = useCallback(() => {
    openInventionImprove({
      inventionId: id,
      inventionTitle: title,
      type: 'prior-art'
    });
  }, [openInventionImprove, id, title]);

  const onManageTagsClick = useCallback(() => {
    updateField('title', 'prior-arts');
    updateField('productId', id);
    updateField('tags', tags);
    openModal(ModalComponentsKeys.manageTags);
  }, [updateField, openModal, id, tags]);

  return (
    <div>
      <Card className={classes.root}>
        <div className={classes.top}>
          <CardImage image={image} type={placeholderType.A} />
          <CardBody>
            <div className={classes.row}>
              <div className={classes.cell}>
                <CardTitle title={title}>
                  <Tags
                    className={classes.tags}
                    tags={tags}
                    tagsList={tagsInfo}
                    edit={showEditTags ? 'before' : ''}
                    onEditClick={onManageTagsClick}
                  />
                  <div className={classes.buttons}>
                    {canEdit ? (
                      <EditButton
                        className={classes.tagsButton}
                        onClick={onEditOpen}
                      >
                        Edit Prior Art
                      </EditButton>
                    ) : null}
                  </div>
                </CardTitle>
              </div>
              <div className={`${classes.cell} likes`}>
                <VotesSmall
                  className={classes.votesBlock}
                  resource="prior-arts"
                  itemId={id}
                  likes={likes}
                  dislikes={dislikes}
                  user={user}
                />
              </div>
            </div>
            <div>
              <div className={classes.infoRow}>
                <div className="left">
                  <Coins className="coins" count={reward}>
                    Earned
                  </Coins>
                  <PsButton
                    onClick={onImproveClick}
                    color="secondary"
                    coins="up to 30"
                    smallest
                  >
                    Improve
                  </PsButton>
                </div>
                <div className="right">
                  <ShareBtn priorArt={priorArt} tagsList={tagsInfo} />
                </div>
              </div>
            </div>
          </CardBody>
        </div>
        <div className={classes.bottom}>
          {body ? (
            <p className={classes.descriptionRow}>
              The prior art of <b>{title}</b> is generally described as {body}
            </p>
          ) : null}
          <CardActions
            className={`${classes.actionButtonsMobile} mmmm`}
            resource="prior-arts"
            resourceId={id}
            likes={likes}
            dislikes={dislikes}
            user={user}
            row
          >
            <ShareBtn priorArt={priorArt} tagsList={tagsInfo} />
          </CardActions>
        </div>
      </Card>
      <ModalEditPriorArt
        priorArt={priorArt}
        open={editOpened}
        onUpdate={onEditUpdate}
        onClose={onEditClose}
      />
    </div>
  );
};

export const CardPriorArtDetail = styled(CardPriorArtDetailView)({});

export default CardPriorArtDetail;
