import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useEffect,
  useState
} from 'react';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { CustomTextField } from 'components/common/inputs/CustomTextField';
import getQueryParams from 'helpers/getQueryParams';
import { useDispatch } from 'react-redux';
import Actions from 'redux-state/actions';
import {
  GetErrorMessage,
  GetProfileStatusCode,
  GetResetPasswordStatus
} from 'redux-state/selectors';
import { colorPalette } from 'theme';
import {
  Constants,
  ERRORS,
  FORM_VIEW,
  INPUT_TYPES,
  QUERY_PARAMS,
  VARIANT
} from 'utilities/constants';
import {
  ActionButton,
  ArrowForwardIcon,
  MainBox,
  ResetStatusText,
  StyledResetCheckTypography,
  StyledResetTypography,
  TitleTypography
} from './styledComponents';

type FieldValidityType = {
  password: boolean;
  confirmPassword: boolean;
};

interface SetNewPasswordViewProps {
  setFormView: Dispatch<SetStateAction<string>>;
}

export const SetNewPasswordView: React.FC<SetNewPasswordViewProps> = ({
  setFormView
}) => {
  const dispatch = useDispatch();

  const resetToken = getQueryParams(QUERY_PARAMS.RESET_TOKEN);
  const statusCode = GetProfileStatusCode();
  const errorMessage = GetErrorMessage();
  const resetPasswordStatus = GetResetPasswordStatus();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [fieldsValidity, setFieldsValidity] = useState<FieldValidityType>({
    password: true,
    confirmPassword: true
  });

  const onPasswordChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setPassword(value);
    },
    [setPassword]
  );

  const onConfirmPasswordChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setConfirmPassword(value);
    },
    [setConfirmPassword]
  );

  const validateInputs = useCallback(() => {
    dispatch(Actions.updateProfileErrorStatus(null, ''));
    const passwordIsEmpty = password === '';
    const confirmPasswordIsEmpty = confirmPassword === '';
    const passwordMatch = password === confirmPassword;

    setFieldsValidity({
      password: !passwordIsEmpty,
      confirmPassword: !confirmPasswordIsEmpty && passwordMatch
    });
    return !passwordIsEmpty && !confirmPasswordIsEmpty && passwordMatch;
  }, [dispatch, confirmPassword, password]);

  const onResetPasswordClick = useCallback(() => {
    if (resetPasswordStatus == Constants.RESET_PASSWORD_SUCCESS) {
      setFormView(FORM_VIEW.LOGIN);
    } else if (validateInputs()) {
      dispatch(Actions.resetPassword({ newPassword: password, resetToken }));
    }
  }, [
    dispatch,
    password,
    resetPasswordStatus,
    resetToken,
    setFormView,
    validateInputs
  ]);

  const getErrorText = useCallback(() => {
    if (statusCode == 401 || statusCode == 410) {
      return errorMessage;
    } else if (statusCode != 200 && errorMessage) {
      return ERRORS.UNEXPECTED_ERROR;
    }
    return '';
  }, [errorMessage, statusCode]);

  const { errorText } = useMemo(() => {
    const errorText = getErrorText();
    return { errorText };
  }, [getErrorText]);

  const buttonText = resetPasswordStatus
    ? Constants.LOGIN
    : Constants.RESET_PASSWORD;
  const icon = resetPasswordStatus ? <ArrowForwardIcon /> : null;

  return (
    <MainBox>
      <TitleTypography gutterBottom>
        {Constants.SET_NEW_PASSWORD}
      </TitleTypography>
      <StyledResetCheckTypography variant={VARIANT.BODY2}>
        {Constants.MUST_BE_EIGHT_CHARACTERS}
      </StyledResetCheckTypography>
      <CustomTextField
        Icon={LockOutlinedIcon}
        placeholder={Constants.SET_NEW_PASSWORD}
        type={INPUT_TYPES.PASSWORD}
        fullWidth
        fillColor={colorPalette.lightLavender}
        borderColorOnHover={colorPalette.purple}
        value={password}
        onChange={onPasswordChange}
        error={!fieldsValidity.password}
        helperText={!fieldsValidity.password ? ERRORS.EMPTY_PASSWORD : ''}
      />
      <CustomTextField
        Icon={LockOutlinedIcon}
        placeholder={Constants.CONFIRM_PASSWORD}
        type={INPUT_TYPES.PASSWORD}
        fullWidth
        fillColor={colorPalette.lightLavender}
        borderColorOnHover={colorPalette.purple}
        value={confirmPassword}
        onChange={onConfirmPasswordChange}
        error={!fieldsValidity.confirmPassword}
        helperText={
          !fieldsValidity.confirmPassword ? ERRORS.MISMATCH_PASSWORD : ''
        }
      />
      <ResetStatusText error={!!errorText}>
        {resetPasswordStatus ? resetPasswordStatus : errorText || ''}
      </ResetStatusText>
      <ActionButton onClick={onResetPasswordClick} variant={VARIANT.CONTAINED}>
        <StyledResetTypography>{buttonText}</StyledResetTypography>
        {icon}
      </ActionButton>
    </MainBox>
  );
};
