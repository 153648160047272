/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Divider, Grid } from '@mui/material';
import { ModalEditProfile } from 'components/CardProfile';
import { MyWallet } from 'components/MyWallet';
import { OwnedCommunity } from 'components/OwnedCommunity';
import { SocialLinks } from 'components/SocialLinks';
import { useDispatch } from 'react-redux';
import Actions from 'redux-state/actions';
import { GetPublicUser, GetUser } from 'redux-state/selectors';
import { useIsMediumScreen } from 'theme';
import { VARIANT } from 'utilities/constants';
import { ProfileAvatar } from './ProfileAvatar';
import { DetailsWrapper, MainBox, StyledParentGrid } from './StyledComponents';

export type ProfileStatsItems = {
  inventions?: number;
  problems?: number;
  solutions?: number;
};

export type ProfileStatsRest = {
  campaigns?: number;
  claimed?: number;
  credits?: number;
  creditsProgress?: number;
  earned?: number;
  inWallet?: number;
  nft?: number;
  unrealized?: number;
};

export type ProfileStats = ProfileStatsItems & ProfileStatsRest;

const UserProfileDetail = () => {
  const user = GetUser();
  const profile = GetPublicUser();
  const [editOpened, setEditOpened] = useState<boolean>(false);
  const isMediumScreen = useIsMediumScreen();
  const [showWallet, setShowWallet] = useState<boolean>(false);
  const isCurrentUser = !!(user && (user?.id === profile?.id || user?.isAdmin));

  return (
    <MainBox isMediumScreen={isMediumScreen}>
      <StyledParentGrid container>
        <Grid>
          <ProfileAvatar
            isCurrentUser={isCurrentUser}
            isMediumScreen={isMediumScreen}
          />
        </Grid>
      </StyledParentGrid>
      <ModalEditProfile open={editOpened} setOpen={setEditOpened} />
      {showWallet && <MyWallet open={showWallet} setOpen={setShowWallet} />}
      <DetailsWrapper>
        <OwnedCommunity
          user={isCurrentUser ? user : profile}
          isCurrentUser={isCurrentUser}
        />
        {isCurrentUser && (
          <>
            <Divider orientation={VARIANT.VERTICAL} flexItem />
            <SocialLinks />
          </>
        )}
      </DetailsWrapper>
    </MainBox>
  );
};

export default UserProfileDetail;
