/* eslint-disable react/no-array-index-key */
/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */

import React, { useState } from 'react';
import { KeyboardArrowDownOutlined } from '@mui/icons-material';
import {
  Box,
  FormControlLabel,
  Popover,
  RadioGroup,
  Typography
} from '@mui/material';
import { colorPalette } from 'theme';
import { Constants } from 'utilities/constants';
import { StyledRadio } from '../AdvanceSearch/styledComponents';
import { StyledAvatar } from '../Coins/styledComponents';
import {
  ContainerGrid,
  DropDownBox,
  MainBox,
  StyledButton,
  StyledButtonBox,
  StyledDivider,
  StyledInnerBox,
  StyledTypography
} from './styledComponents';

export const FeedSortFilter = ({
  feedFilters,
  filterItems,
  handleChange,
  setFeedFilters,
  sortItems
}: any) => {
  const [anchorElFilter, setAnchorElFilter] = useState<null | HTMLElement>(
    null
  );
  const [anchorElSort, setAnchorElSort] = useState<null | HTMLElement>(null);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [openSort, setOpenSort] = useState<boolean>(false);
  const [sort, setSort] = useState<string>(Constants.CREATED_AT);

  const handleClickSort = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElSort(event.currentTarget);
    setOpenSort(true);
  };

  const handleClickFilter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElFilter(event.currentTarget);
    setOpenFilter(true);
  };

  const handleCloseSort = () => {
    setOpenSort(false);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setFeedFilters([value]);
    handleChange([value], sort);
    handleCloseFilter();
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSort = event.target.value;
    setSort(newSort);
    handleChange(feedFilters, newSort);
    handleCloseSort();
  };

  return (
    <ContainerGrid>
      <MainBox>
        <StyledInnerBox>
          <StyledButton onClick={handleClickSort} sx={{ paddingLeft: 0 }}>
            <StyledButtonBox>
              <StyledTypography>
                {Constants.C_SORT_BY}
                {sort ? (
                  <>
                    :{' '}
                    <strong>
                      {sort === 'createdAt'
                        ? Constants.RECENTLY_ADDED
                        : sort === 'ideaPoints'
                          ? 'Top Ranked'
                          : Constants.C_LIKES}
                    </strong>
                  </>
                ) : (
                  ''
                )}
              </StyledTypography>
              <KeyboardArrowDownOutlined sx={{ color: colorPalette.grey }} />
            </StyledButtonBox>
          </StyledButton>
          <StyledButton onClick={handleClickFilter} sx={{ paddingRight: 0 }}>
            <StyledButtonBox>
              <StyledTypography>
                {Constants.FILTER_BY}:{' '}
                {feedFilters.length === 0 ||
                (feedFilters.length === 1 &&
                  feedFilters[0] === Constants.ALL) ? (
                  <strong>All</strong>
                ) : feedFilters.length === 1 ? (
                  <strong>
                    {filterItems.find((item) => item.value === feedFilters[0])
                      ?.label || feedFilters[0]}
                  </strong>
                ) : feedFilters.length > 1 ? (
                  <strong>{feedFilters.length}</strong>
                ) : (
                  ''
                )}
              </StyledTypography>
              <KeyboardArrowDownOutlined sx={{ color: colorPalette.grey }} />
            </StyledButtonBox>
          </StyledButton>
        </StyledInnerBox>
      </MainBox>
      <StyledDivider />
      <>
        <Popover
          open={openSort}
          anchorEl={anchorElSort}
          onClose={handleCloseSort}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          sx={{
            '& .MuiPopover-paper': {
              width: anchorElSort
                ? `${anchorElSort.clientWidth + 50}px`
                : undefined,
              left: '50%',
              transform: 'translateX(-50%)'
            }
          }}
        >
          <DropDownBox>
            {sortItems && (
              <RadioGroup value={sort} onChange={handleRadioChange}>
                {sortItems.map((item, index) => (
                  <FormControlLabel
                    key={index}
                    value={item.value}
                    control={<StyledRadio />}
                    label={item.label}
                  />
                ))}
              </RadioGroup>
            )}
          </DropDownBox>
        </Popover>
        <Popover
          open={openFilter}
          anchorEl={anchorElFilter}
          onClose={handleCloseFilter}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          sx={{
            '& .MuiPopover-paper': {
              width: anchorElFilter
                ? `${anchorElFilter.clientWidth + 50}px`
                : undefined,
              left: '50%',
              transform: 'translateX(-50%)'
            }
          }}
        >
          <DropDownBox>
            <RadioGroup
              onChange={handleCheckboxChange}
              value={feedFilters[0] || Constants.ALL}
            >
              {filterItems?.map((item, index) => (
                <FormControlLabel
                  key={index}
                  value={item.value}
                  control={<StyledRadio />}
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {item.icon && <StyledAvatar src={item.icon} />}
                      <Typography>{item.label}</Typography>
                    </Box>
                  }
                />
              ))}
            </RadioGroup>
          </DropDownBox>
        </Popover>
      </>
    </ContainerGrid>
  );
};
