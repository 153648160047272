import { Box, Typography, styled } from '@mui/material';

export const StyledHeaderWrapper = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between'
});

export const StyledHeaderText = styled(Typography)({
  fontWeight: 'bold'
});
