import { Api } from './api';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  CREATE_CONCEPT,
  GENERATE_AI,
  GET_CHILDREN_GRAPH,
  GET_CONCEPT,
  GET_CONTESTS_BY_PROBLEM_AND_SOLUTION,
  GET_GRAPH,
  UPDATE_CONCEPT
} from './types';
import * as Actions from './actions';
import { Contest } from 'components/CardContest';
import { setLoginUser } from '../onboarding/actions';

function* createConcept(action) {
  try {
    yield put(Actions.togLoadNewConcept(true));
    const response = yield call(Api.createConcept, {
      data: action.payload.data
    });
    if (response) {
      const existingConcepts = yield select((state) => state.ideamap.concept);
      const updatedData = Object.keys(existingConcepts).length
        ? [response.data, ...existingConcepts.data]
        : [response.data];
      const user = yield select((state) => state.onboarding.loginUser);
      yield put(setLoginUser({ ...user, activeConcept: response.data }));
      yield put(Actions.setConcept({ data: updatedData }));
      yield put(Actions.togLoadNewConcept(false));
    }
  } catch (error) {
    yield put(Actions.togLoadNewConcept(false));
    console.log('error', error);
  }
}

function* generateAI(action) {
  try {
    yield put(Actions.togLoadAiGeneration(true));
    const response = yield call(Api.generateAI, {
      conceptId: action.payload.conceptId
    });
    if (response) {
      yield put(Actions.togLoadAiGeneration(false));
      const user = yield select((state) => state.onboarding.loginUser);
      yield put(setLoginUser({ ...user, activeConcept: response.data }));
      yield put(Actions.getConcept(response.data.owner));
    } else {
      yield put(Actions.togLoadAiGeneration(false));
    }
  } catch (error) {
    console.log('error', error);
  }
}

function* getChildrenGraph(action) {
  try {
    const graph = yield select((state) => state.ideamap.graph);
    const response = yield call(Api.getGraph, {
      type: `${action.payload.type}s`,
      id: action.payload.id,
      mapType: action.payload.mapType
    });
    const updatedGraph = { ...graph };
    response.nodesTree.children = response.nodesTree.children.map((item) => {
      return {
        ...item
      };
    });

    updatedGraph.children = updatedGraph.children.map((item) => {
      if (item.id === action.payload.id) {
        return {
          ...item,
          children: response.nodesTree.children
        };
      }
      return item;
    });
    yield put(Actions.setGraph(updatedGraph));
  } catch (error) {
    console.log('error', error);
  }
}

function* getConcept(action) {
  try {
    const response = yield call(Api.getConcept, {
      userId: action.payload.userId
    });
    yield put(Actions.setConcept(response));
    yield put(Actions.togLoadImageGeneration(false));
  } catch (error) {
    yield put(Actions.togLoadSolution(false));
    console.log('error', error);
  } finally {
    yield put(Actions.getConceptSuccess({}));
  }
}

function* getGraph(action) {
  try {
    const response = yield call(Api.getGraph, {
      type: action.payload.type,
      id: action.payload.id,
      mapType: action.payload.mapType
    });
    yield put(Actions.setGraph(response.nodesTree));
  } catch (error) {
    console.log('error', error);
  }
}

function* updateConcept(action) {
  try {
    const { id, data } = action.payload;
    const response = yield call(Api.updateConcept, {
      conceptId: id,
      data
    });
    if (response?.data) {
      const user = yield select((state) => state.onboarding.loginUser);
      yield put(setLoginUser({ ...user, activeConcept: response.data }));
      yield put(Actions.updateConceptSuccess({}));
      yield put(Actions.getConcept(response.data.owner));
      data.onSubmit?.();
    }
    yield put(Actions.togLoadImageUpload(false));
  } catch (error) {
    console.log('error', error);
    yield put(Actions.togLoadSolution(false));
    yield put(Actions.togLoadImageUpload(false));
  }
}

function updateNodeWithContests(node, id, contests) {
  if (node.id === id) {
    node.contests = contests ? contests : [];
  } else if (node?.children?.length) {
    node.children.forEach((child) =>
      updateNodeWithContests(child, id, contests)
    );
  }
}

function* getConceptsByProblemOrSolution(action) {
  try {
    yield put(Actions.togLoadContests(true));
    const response: Array<Contest> = yield call(
      Api.getConceptsByProblemOrSolution,
      {
        id: action.payload.id,
        type: action.payload.type
      }
    );
    const graph = yield select((state) => state.ideamap.graph);
    if (response.length > 0) {
      updateNodeWithContests(graph, action.payload.id, response);
      yield put(Actions.togLoadContests(false));
    } else {
      updateNodeWithContests(graph, action.payload.id, null);
      yield put(Actions.togLoadContests(false));
    }
    yield put(Actions.setGraph(graph));
  } catch (error) {
    yield put(Actions.togLoadContests(false));
  }
}

function* mySaga() {
  yield takeLatest(CREATE_CONCEPT, createConcept);
  yield takeLatest(GENERATE_AI, generateAI);
  yield takeLatest(GET_CHILDREN_GRAPH, getChildrenGraph);
  yield takeLatest(GET_CONCEPT, getConcept);
  yield takeLatest(
    GET_CONTESTS_BY_PROBLEM_AND_SOLUTION,
    getConceptsByProblemOrSolution
  );
  yield takeLatest(GET_GRAPH, getGraph);
  yield takeLatest(UPDATE_CONCEPT, updateConcept);
}

export default mySaga;
