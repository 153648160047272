import { Box, styled, Typography } from '@mui/material';
import { colorPalette } from 'theme';

export const StyledParentBox = styled(Box)({
  display: 'grid',
  gap: '0.625rem',
  gridAutoFlow: 'column',
  gridTemplateColumns: 'auto',
  padding: '1rem',
  width: '100%'
});

export const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
});

export const StyledTypography = styled(Typography)({
  color: colorPalette.purple,
  fontSize: '0.75rem',
  fontWeight: 500,
  textDecoration: 'underline',
  textDecorationColor: colorPalette.purple
});

export const StyledIdeapointsTypography = styled(Typography)({
  color: colorPalette.purple,
  fontSize: '0.75rem',
  fontWeight: 700
});

export const StyledStatusTypography = styled(Typography)({
  fontSize: '0.9rem',
  textTransform: 'uppercase'
});

export const StyledIdeapointsBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: '0.25rem'
});
