import {
  Accordion,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  LinearProgress,
  Modal,
  Radio,
  styled,
  TextField,
  Typography
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { colorPalette } from 'theme';
import { TreeIconWithPlus } from 'components/ConceptsButton/TreeIconWithPlus';
import { ChipList } from 'components/Tag';
import { Constants } from 'utilities/constants';

interface StyledMainBoxProps {
  type: string;
  isMediumScreen: boolean;
  isCommunityFeed?: boolean;
}

interface StyledMainContainerProps {
  isCommunityFeed?: boolean;
  isEmptyFeed?: boolean;
  isMediumScreen?: boolean;
}

const grandParent = 'product';
const parent = 'problem';
const child = 'solution';

const colorMapper = {
  problem: colorPalette.cinnabar,
  solution: colorPalette.jungleGreen,
  application: colorPalette.meteor,
  invention: colorPalette.meteor,
  default: colorPalette.grey
};

const typeStyles = {
  [Constants.PROBLEM]: {
    background: colorPalette.roseWhite,
    border: `1px solid ${colorPalette.cinnabar}`,
    hoverBackground: colorPalette.azalea
  },
  [Constants.SOLUTION]: {
    background: colorPalette.ceramic,
    border: `1px solid ${colorPalette.forestGreen}`,
    hoverBackground: colorPalette.blueRomance
  },
  [Constants.PRODUCT]: {
    background: colorPalette.roseWhite,
    border: `1px solid ${colorPalette.purple}`,
    hoverBackground: colorPalette.selago
  },
  [Constants.APPLICATION]: {
    background: colorPalette.roseWhite,
    border: `1px solid ${colorPalette.meteor}`,
    hoverBackground: colorPalette.colonialWhite
  },
  [Constants.Invention]: {
    background: colorPalette.roseWhite,
    border: `1px solid ${colorPalette.meteor}`,
    hoverBackground: colorPalette.colonialWhite
  },
  default: {
    background: '',
    border: `1px solid ${colorPalette.grey}`,
    hoverBackground: colorPalette.whisper
  }
};

const getColor = (param) => colorMapper[param];

export const StyledTagsContainer = styled(Container)({
  padding: '12px',
  maxWidth: '385px',
  boxSizing: 'border-box',
  marginTop: '10px',
  borderRadius: '4px',
  background: colorPalette.white,
  boxShadow: `0 1px 7px ${colorPalette.ebb}`
});

export const StyledButtonContainer = styled(Button)({
  background: '#35A8501A',
  borderRadius: '50px',
  color: colorPalette.lightGreen,
  display: 'inline-block',
  fontSize: '17px',
  fontWeight: '600',
  height: '34px',
  '&:hover': {
    background: '#35A8501A',
    color: colorPalette.lightGreen
  },
  marginBottom: '0.5rem',
  marginTop: '0.5rem',
  minWidth: '100px',
  padding: '0.2rem 0.5rem',
  textAlign: 'center',
  textTransform: 'capitalize'
});

export const StyledTagsHeader = styled(Typography)({
  color: colorPalette.purple,
  fontSize: '18px',
  fontWeight: '700',
  marginBottom: '8px'
});

export const StyledTagsSubContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between'
});

export const StyledText = styled(Typography)({
  cursor: 'pointer',
  fontSize: '14px',
  fontWeight: '600',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  ':hover': { textDecoration: 'underline' }
});

export const StyledIcon = styled(Avatar)({
  height: '10px',
  width: '18px'
});

export const StyledTagsButtonContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column'
});

export const StyledTagButton = styled(Button)({
  fontWeight: 'bold',
  textTransform: 'capitalize'
});

export const ConceptDropdown = styled(Accordion)({
  borderRadius: '8px',
  boxShadow: '0px 0px 16px 0px #00000014',
  marginBottom: '1rem',
  overflow: 'hidden'
});

export const LoadingContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
});

export const InnerContainer = styled(Grid)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%'
});

export const AccordionTitle = styled(AccordionSummary)({
  alignItems: 'center',
  display: 'flex',
  width: '100%'
});

export const NameContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  height: '100%'
});

export const ConceptName = styled(Typography)({
  alignItems: 'Center',
  display: 'flex',
  fontSize: '15px',
  fontWeight: '600',
  justifyContent: 'center',
  lineHeight: '22px',
  padding: '0px 10px',
  textAlign: 'left'
});

export const Gridy = styled(Grid)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-end'
});

export const TagContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  paddingRight: '8px'
});

export const ProblemTag = styled(Box)({
  background: colorPalette.pink,
  border: `1px solid ${colorPalette.explosiveGrey}`,
  borderRadius: '50px',
  color: colorPalette.red,
  display: 'inline-block',
  fontSize: '10px',
  fontWeight: 'bold',
  marginTop: '0.5rem',
  minWidth: '90px',
  padding: '0.2rem 0.5rem',
  textAlign: 'center'
});

export const SolutionTag = styled(Box)({
  background: colorPalette.mintTulip,
  border: `1px solid ${colorPalette.explosiveGrey}`,
  borderRadius: '50px',
  color: colorPalette.green,
  display: 'inline-block',
  fontSize: '10px',
  fontWeight: 'bold',
  marginTop: '0.5rem',
  minWidth: '90px',
  padding: '0.2rem 0.5rem',
  textAlign: 'center'
});

export const RadioContainer = styled(Box)({ display: 'flex', height: '100%' });

export const RadioOne = styled(Radio)({
  color: colorPalette.explosiveGrey,
  transform: 'scale(1.2)'
});

export const RadioTwo = styled(Radio)({
  margin: 0,
  padding: 0
});

export const ActiveBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%'
});

export const AccordionDropDown = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column'
});

export const ImageGrid = styled(Grid)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  margin: '0px 15px'
});

export const ConceptAvatar = styled(Avatar)({
  borderRadius: '5px',
  height: '50px',
  width: '50px'
});

export const AiBox = styled(Box)({ display: 'flex' });

export const LoaderBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  minWidth: '46px',
  padding: '8px'
});

export const LinearBox = styled(Box)({ width: '100%', marginBottom: '12px' });

export const ProblemContainer = styled(Box)({
  alignItems: 'center',
  border: `1px solid ${colorPalette.red}`,
  borderRadius: '50px',
  display: 'flex',
  height: '50px',
  justifyContent: 'space-between',
  margin: '5px 0',
  padding: '0px 4px',
  width: '100%'
});

export const ProbSolContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center'
});

export const ProblemIcon = styled(Box)({
  alignItems: 'center',
  backgroundColor: colorPalette.pink,
  borderRadius: '100%',
  color: colorPalette.bloodRed,
  display: 'flex',
  height: '40px',
  justifyContent: 'center',
  marginRight: '10px',
  width: '40px'
});

export const TeaserTypo = styled(Typography)({
  fontSize: 'small'
});

export const SolutionBar = styled(Box)({
  marginLeft: '3px',
  maxHeight: '170px',
  overflowX: 'clip',
  overflowY: 'auto',
  position: 'relative',
  width: '100%'
});

export const SolutionContainer = styled(Box)({
  alignItems: 'center',
  border: `1px solid ${colorPalette.lightGreen}`,
  borderRadius: '50px',
  display: 'flex',
  height: '50px',
  justifyContent: 'space-between',
  margin: '5px 0',
  padding: '0px 4px',
  width: '100%'
});

export const SolutionIcon = styled(Box)({
  alignItems: 'center',
  backgroundColor: colorPalette.mintTulip,
  borderRadius: '100%',
  color: colorPalette.green,
  display: 'flex',
  height: '40px',
  justifyContent: 'center',
  marginRight: '10px',
  minWidth: '40px',
  width: '40px'
});

export const FilePatentButtonContainer = styled(Box)({
  padding: '30px 16px 16px'
});

export const StyledBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '16px 0',
  width: 'auto'
});

export const StyledTypography = styled(Typography)({
  color: colorPalette.dimBlack,
  fontSize: '12px',
  fontWeight: '600'
});

export const AttachedIcon = styled(Box)<{ type: string }>(({ type }) => ({
  alignItems: 'center',
  backgroundColor:
    type === 'product'
      ? colorPalette.lightPurple
      : type === 'problem'
        ? colorPalette.pink
        : type === 'solution'
          ? colorPalette.mintTulip
          : type === Constants.APPLICATION || type === Constants.Invention
            ? colorPalette.marzipan
            : colorPalette.white,
  border:
    type === 'problem'
      ? `1px solid ${colorPalette.red}`
      : type === 'solution'
        ? `1px solid ${colorPalette.green}`
        : type === Constants.APPLICATION || type === Constants.Invention
          ? `1px solid ${colorPalette.meteor}`
          : `1px solid ${colorPalette.purple}`,
  borderRadius: '50%',
  display: 'flex',
  height: '2.8125rem',
  justifyContent: 'center',
  width: '2.8125rem',
  zIndex: 3
}));

export const StyledButton = styled(Button)<{ param: string }>(({ param }) => ({
  alignItems: 'center',
  background: colorPalette.white,
  border: `1px solid ${getColor(param)}`,
  borderRadius: '0.4375rem',
  boxSizing: 'border-box',
  display: 'flex',
  gap: '24px',
  width: '100%',
  minHeight: '4.1875rem',
  '&:hover': {
    backgroundColor: colorPalette.white
  },
  justifyContent: 'flex-start',
  paddingLeft: '1.75rem',
  position: 'relative',
  textTransform: 'none'
}));

export const StyledLikes = styled(Typography)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    color: colorPalette.black,
    fontFamily: 'montserrat',
    fontSize: isMediumScreen ? '0.875rem' : '1rem',
    fontWeight: '500',
    margin: '0px 0.625rem 0px 0.625rem'
  })
);

export const ReactionButtons = styled(Box)<{
  type: string;
  isMediumScreen: boolean;
  scale: string;
}>(({ type, isMediumScreen, scale }) => ({
  alignItems: 'center',
  backgroundColor:
    type == grandParent
      ? colorPalette.magnolia
      : type == parent
        ? colorPalette.amour
        : type === Constants.APPLICATION || type === Constants.Invention
          ? colorPalette.lightPeach
          : type == child
            ? colorPalette.snowyMint
            : colorPalette.lightGrey,
  borderRadius: '30px',
  display: 'flex',
  height: isMediumScreen && '38px',
  justifyContent: 'center',
  minHeight: !isMediumScreen && '45px',
  minWidth: !isMediumScreen && '113px',
  padding: '10px 18px',
  transform: scale ? `scale(${scale})` : 'none',
  width: isMediumScreen && '90px'
}));

export const VotingBox = styled(Box)({ padding: '0px 0px' });

export const StyledMainContainer = styled(Box)<StyledMainContainerProps>(
  ({ isCommunityFeed, isEmptyFeed, isMediumScreen }) => ({
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    padding: isCommunityFeed ? (isMediumScreen ? '1%' : '5%') : '0%',
    paddingTop: '0%',
    width: isEmptyFeed ? '100%' : 'auto'
  })
);

export const StyledSubContainer = styled(Box)<StyledMainContainerProps>(
  ({ isEmptyFeed, isMediumScreen, isCommunityFeed }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: isEmptyFeed ? '100%' : 'none',
    paddingTop: '0px',
    width: '100%'
  })
);

export const StyledIcons = styled(Box)({
  alignContent: 'center',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  position: 'relative'
});

export const StyledMainImage = styled(Avatar)({
  width: '100%',
  height: '100%',
  borderRadius: '8px',
  display: 'block',
  marginTop: '10px',
  maxHeight: '300px'
});

export const StyledTagsContent = styled(Box)({ paddingBottom: '-11px' });

export const StyledChipList = styled(ChipList)({
  marginBottom: '0px'
});

export const StyledLinearProgress = styled(LinearProgress)({
  width: '100%'
});

export const StyledProfileDescription = styled(Box)<{
  isMediumScreen: boolean;
}>(({ isMediumScreen }) => ({
  color: colorPalette.tundora,
  fontSize: isMediumScreen ? '12px' : '14px',
  fontWeight: 500,
  marginTop: '20px',
  '& p': {
    fontSize: 'small'
  }
}));

export const AutoSuggestWrapper = styled(Box)(() => ({
  alignItems: 'center',
  color: colorPalette.black,
  display: 'flex',
  flexDirection: 'row',
  marginRight: '.375rem',
  position: 'relative',
  width: '100%',
  zIndex: 999
}));

export const SearchWrapper = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '0.5rem'
}));

export const StyledTextContainer = styled(Box)<{
  isCommunityFeed: boolean;
  isMediumScreen: boolean;
}>(({ isCommunityFeed, isMediumScreen }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginLeft: isMediumScreen && isCommunityFeed ? '0px' : '8px'
}));

export const StyledTitle = styled(Typography)({
  fontWeight: 600,
  fontSize: 'small',
  lineHeight: '24px',
  '& p': {
    fontSize: 'small',
    fontWeight: 700
  },
  color: colorPalette.purple
});

export const StyledMainBox = styled(Box)<StyledMainBoxProps>(({
  type,
  isMediumScreen
}) => {
  const styles = typeStyles[type] || typeStyles.default;

  return {
    background: styles.background,
    border: styles.border,
    borderRadius: '8px',
    height: '100%',
    marginBottom: '25px',
    padding: isMediumScreen ? '12px' : '20px',
    width: '100%',
    '&:hover': {
      background: styles.hoverBackground
    }
  };
});

export const StyledCreateButton = styled(Button)<{
  isMediumScreen: boolean;
  isCommunityFeed: boolean;
}>(({ isMediumScreen, isCommunityFeed }) => ({
  alignItems: 'center',
  backgroundColor: colorPalette.greenHaze,
  borderRadius: '6px',
  display: 'flex',
  fontSize: !isMediumScreen && '0.5rem',
  height: isMediumScreen ? '48px' : '55px',
  justifyContent: 'center',
  marginLeft: 'auto',
  maxWidth: '18.75rem',
  minWidth: !isMediumScreen && '150px',
  width: isMediumScreen && isCommunityFeed ? '100%' : isMediumScreen && '100%',
  '&:hover': {
    backgroundColor: colorPalette.greenHaze
  }
}));

export const StyledCreateButtonTypography = styled(Typography)<{
  isMediumScreen: boolean;
}>(({ isMediumScreen }) => ({
  fontSize: isMediumScreen ? '14px' : '17px',
  fontWeight: '600',
  marginLeft: isMediumScreen ? '5px' : '12px',
  marginTop: isMediumScreen ? '0px' : '3px',
  textTransform: 'none'
}));

export const StyledTitleText = styled(Typography)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    cursor: 'pointer',
    fontSize: isMediumScreen ? '12px' : '22px',
    fontWeight: 700,
    ':hover': { textDecoration: 'underline' }
  })
);

export const FooterContainer = styled(Box)<{ isCommunityFeed?: boolean }>(
  ({ isCommunityFeed }) => ({
    justifyContent: 'space-between',
    marginTop: '10px',
    width: '100%'
  })
);

export const StyledAddedText = styled(Typography)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    color: colorPalette.grey,
    fontSize: isMediumScreen ? '8px' : '12px',
    fontWeight: 500
  })
);

export const StyledCalendarIcon = styled(Avatar)({
  width: '17px',
  height: '15px',
  marginTop: '1px',
  marginRight: '4px'
});

export const StyledInfoContainer = styled(Box)<{ isCommunityFeed: boolean }>(
  ({ isCommunityFeed }) => ({
    alignItems: 'center',
    display: 'flex',
    paddingLeft: isCommunityFeed ? '6px' : '14px'
  })
);

export const StyledCreatedText = styled(Typography)<{
  isMediumScreen: boolean;
}>(({ isMediumScreen }) => ({
  color: colorPalette.grey,
  fontSize: isMediumScreen ? '10px' : '12px',
  fontWeight: '500',
  lineheight: '14px',
  textAlign: 'left'
}));
export const StyledOwnerInfoText = styled(Typography)({
  color: colorPalette.purple,
  fontSize: '16px',
  fontweight: '600',
  lineHeight: '19px',
  textAlign: 'left'
});

export const StyledContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between'
});

export const ButtonWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%'
});

export const StyledParentContainer = styled(Box)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    flexDirection: isMediumScreen ? 'column' : 'row',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between'
  })
);

export const StyledVoteInfoContainer = styled(Box)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    alignItems: 'center',
    display: 'flex',
    justifyContent: isMediumScreen ? 'space-between' : 'start',
    width: '100%'
  })
);

export const StyledSubFooterContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  width: '100%'
});

export const StyledDateContainer = styled(Box)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: isMediumScreen ? 0 : '-20px',
    marginRight: isMediumScreen ? '24px' : 0
  })
);

export const StyledTreeIconWithPlus = styled(TreeIconWithPlus)<{
  isMediumScreen: boolean;
}>(({ isMediumScreen }) => ({
  height: isMediumScreen ? '22px' : '1.7rem',
  paddingTop: '3px',
  width: isMediumScreen ? '22px' : '1.7rem'
}));

export const StyledButtonsContainer = styled(Box)<{
  isMediumScreen: boolean;
  isCommunityFeed: boolean;
}>(({ isMediumScreen, isCommunityFeed }) => ({
  width: isMediumScreen ? '100%' : 'auto',
  paddingTop: isMediumScreen ? '8px' : 0
}));

export const StyledMainWrapper = styled(Box)({
  width: '100%',
  height: 'auto',
  backgroundColor: colorPalette.white
});

export const StyledIdeaCoin = styled(Avatar)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    height: isMediumScreen ? '14px' : '25px',
    marginLeft: '-10px',
    marginTop: '10px',
    width: isMediumScreen ? '14px' : '25px'
  })
);

export const StyledButtonWrapper = styled(Button)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    background: colorPalette.linkWater,
    borderRadius: '22px',
    height: isMediumScreen ? '35px' : '42px',
    minWidth: isMediumScreen && '0px',
    radius: '22px',
    width: isMediumScreen ? '35px' : '107px'
  })
);

export const StyledShareImage = styled(Avatar)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    height: '19px',
    left: isMediumScreen ? '6px' : '16px',
    position: 'absolute',
    top: isMediumScreen ? '9px' : '11px',
    width: '22px'
  })
);

export const ShareButtonText = styled(Typography)({
  color: colorPalette.black,
  fontSize: '16px',
  fontweight: '500',
  lineHeight: '39px',
  marginLeft: '37px',
  textTransform: 'capitalize'
});

export const StyledCounterWrapper = styled('div')({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center'
});

export const StyledCounterSubWrapper = styled('div')({
  borderRadius: '100%',
  height: '30px',
  width: '30px'
});

export const StyledVoteButton = styled(Button)({
  minWidth: '0px',
  padding: '0px',
  '&:hover': {
    cursor: 'pointer'
  }
});

export const StyledEmptyFeedText = styled(Typography)({
  textAlign: 'center'
});

export const TagsViewModalWrapper = styled(Modal)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center'
});

export const TagsViewContainer = styled(Box)({
  background: colorPalette.white,
  borderRadius: 1,
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  maxWidth: 1200,
  padding: '25px',
  width: '80%'
});

export const StyledFollowText = styled(Typography)({
  background: colorPalette.white,
  color: colorPalette.black,
  fontSize: '20px',
  fontWeight: 700,
  marginBottom: '15px'
});

export const StyledDivider = styled(Divider)({
  marginBottom: '5px'
});

export const StyledHeaderContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between'
});

export const StyledTextField = styled(TextField)({
  borderRadius: '8px',
  boxShadow: `0 1px 7px ${colorPalette.ebb}`,
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
      borderRadius: '10px',
      border: `1px solid ${colorPalette.titaniumWhite}`
    },
    '&:hover fieldset': {
      borderColor: 'transparent'
    }
  }
});

export const StyledSearchIcon = styled(SearchIcon)({
  width: '30px',
  height: '30px',
  color: colorPalette.titaniumWhite
});

export const StyledTableWrapper = styled(Box)({
  overflowY: 'scroll',
  maxHeight: '65vh'
});

export const TagsViewHeaderContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
});

export const StyledTagsViewDivider = styled(Divider)({
  marginBottom: '8px'
});

export const StyledStickyBox = styled(Box)<{ isUserLoggedIn: boolean }>(
  ({ isUserLoggedIn }) => ({
    filter: isUserLoggedIn ? 'none' : 'blur(3px)',
    marginTop: '-18px',
    pointerEvents: isUserLoggedIn ? 'auto' : 'none',
    userSelect: isUserLoggedIn ? 'auto' : 'none'
  })
);

export const StyledFieldMainWrapper = styled(Box)({
  alignItems: 'center',
  border: `2px solid ${colorPalette.white}`,
  borderRadius: '14px',
  boxShadow: `0rem 0rem 1rem 0rem ${colorPalette.lightGrey}`,
  display: 'flex',
  height: '30',
  marginTop: '0.4375rem',
  padding: '.625rem',
  width: '100%'
});

export const StyledAvatar = styled(Avatar)({
  width: 50,
  height: 50
});

export const StyledCreateTextField = styled(TextField)({
  ml: 1,
  background: colorPalette.white,
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#FFFFFF' // Adds a black border color
    },
    '&:hover fieldset': {
      borderColor: '#FFFFFF' // Keeps border black on hover
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FFFFFF' // Keeps border black when focused
    }
  }
});
