/* eslint-disable */
import { Profile } from 'components/CardProfile';
import Config from 'config/config';
import dataProvider from 'dataPrvider';
import { openAuthWindow, simpleEncode } from 'helpers/common';
import {
  Constants,
  END_POINTS,
  ERRORS,
  PATH_NAMES,
  RESOURCE
} from 'utilities/constants';
import { SocialAuthKeys } from './interface';

export const Api = {
  forgetPassword: async (payload: { email: string }) => {
    const response = await dataProvider.forgetPassword(payload);
    return response;
  },
  resetPassword: async (payload: {
    newPassword: string;
    resetToken: string;
  }) => {
    const response = await dataProvider.resetPassword(payload);
    return response;
  },
  getProfile: async (payload: { id: string | number; useKey?: boolean }) => {
    try {
      const { id, useKey = false } = payload;
      const data = useKey
        ? await dataProvider.getOneByKey<Profile>(Constants.PROFILES, {
            key: id as string
          })
        : await dataProvider.getOne<Profile>(Constants.PROFILES, { id });
      return data;
    } catch (e) {
      return [];
    }
  },
  getPublicProfile: async (payload: { userKey?: string | number }) => {
    try {
      const { userKey } = payload;
      const data = await dataProvider.getOneByKey<Profile>(Constants.PROFILES, {
        key: userKey as string
      });
      return data;
    } catch (e) {
      return [];
    }
  },
  getSocialAuthKeys: async (payload) => {
    try {
      const response = await dataProvider.getList<SocialAuthKeys>(
        PATH_NAMES.SOCIAL_AUTH_KEYS,
        {
          filter: {
            $custom: {
              category: Constants.PROFILE,
              userId: payload
            }
          },
          sort: null
        }
      );
      return response;
    } catch (error) {
      console.error(ERRORS.SOCIAL_AUTH_KEYS, error);
      throw error;
    }
  },
  socialDisconnect: async (payload) => {
    try {
      const response = await dataProvider.deleteSocialAuthKey(
        PATH_NAMES.SOCIAL_AUTH_KEYS,
        { id: payload }
      );
      return response.data;
    } catch (error) {
      console.error(ERRORS.SOCIAL_AUTH_KEYS, error);
      throw error;
    }
  },
  signUp: async (data) => {
    try {
      const response = await dataProvider.create(
        `${RESOURCE.AUTH}${END_POINTS.SIGN_UP}`,
        { data }
      );
      return response;
    } catch (error) {
      console.log('SignUp failed');
      throw error;
    }
  },
  signIn: async (data) => {
    try {
      const response = await dataProvider.create(
        `${RESOURCE.AUTH}${END_POINTS.SIGN_IN}`,
        { data }
      );
      return response;
    } catch (error) {
      console.log('Login failed');
      throw error;
    }
  },
  socialLogIn: async (platform, share = null, userId = null) => {
    let encodedUserId = null;
    if (userId) {
      encodedUserId = simpleEncode(userId, Config.USER_SECRET_KEY);
    }
    const url = `${Config.NEXT_PUBLIC_API_URL}/auth/social?platform=${platform}&share=${share}&userId=${encodedUserId}`;
    return openAuthWindow(url);
  },
  socialDiscordLogIn: async (platform, share = null, userId = null) => {
    let encodedUserId = null;
    if (userId) {
      encodedUserId = simpleEncode(userId, Config.USER_SECRET_KEY);
    }
    const url = `${Config.NEXT_PUBLIC_API_URL}/auth/discord?platform=${platform}&share=${share}&userId=${encodedUserId}`;
    return openAuthWindow(url);
  },
  socialLinkedinLogIn: async (platform, share = null, userId = null) => {
    let encodedUserId = null;
    if (userId) {
      encodedUserId = simpleEncode(userId, Config.USER_SECRET_KEY);
    }
    const url = `${Config.NEXT_PUBLIC_API_URL}/auth/linkedin?platform=${platform}&share=${share}&userId=${encodedUserId}`;
    return openAuthWindow(url);
  },
  socialInstagramLogIn: async (platform, share = null, userId = null) => {
    let encodedUserId = null;
    if (userId) {
      encodedUserId = simpleEncode(userId, Config.USER_SECRET_KEY);
    }
    const url = `${Config.NEXT_PUBLIC_API_URL}/auth/instagram?platform=${platform}&share=${share}&userId=${encodedUserId}`;
    return openAuthWindow(url);
  },
  socialTiktokLogIn: async (platform, share = null, userId = null) => {
    let encodedUserId = null;
    if (userId) {
      encodedUserId = simpleEncode(userId, Config.USER_SECRET_KEY);
    }
    const url = `${Config.NEXT_PUBLIC_API_URL}/auth/tiktok?platform=${platform}&share=${share}&userId=${encodedUserId}`;
    return openAuthWindow(url);
  },
  socialTwitterLogIn: async (platform, share = null, userId = null) => {
    let encodedUserId = null;
    if (userId) {
      encodedUserId = simpleEncode(userId, Config.USER_SECRET_KEY);
    }
    const url = `${Config.NEXT_PUBLIC_API_URL}/auth/twitter?platform=${platform}&share=${share}&userId=${encodedUserId}`;
    return openAuthWindow(url);
  }
};
