import React, { useContext, useState, useCallback, useEffect } from 'react';
import PsFormControl, { ShortEvent } from 'components/common/PsFormControl';
import { PsSelect, PsOptionType } from 'components/common/PsSelect';
import { PsSelectedList } from 'components/common/PsSelectedList';
import {
  ModalDataContext,
  StepComponentType,
  StepComponentProps
} from 'contexts/ModalDataContext';
import { DataContext } from 'contexts/DataContext';
import { AuthContext } from 'contexts/AuthContext';
import styles from 'modals/ModalWindow.module.scss';

const SolutionSelect: StepComponentType<StepComponentProps> = ({
  onNotify
}: StepComponentProps) => {
  const { user } = useContext(AuthContext);
  const { values, updateField } = useContext(ModalDataContext);
  const { dataProvider } = useContext(DataContext);

  const initSelected = (values.application || {}).selected || [];

  const [list, setList] = useState<Array<PsOptionType>>([]);
  const [selectedValue, setSelectedValue] = useState<string>(
    initSelected.join(',') || ''
  );

  const onlyAdd = values.rootProblemHide || false;

  useEffect(() => {
    if (!values.application) {
      return;
    }
    const app = values.application;

    const selected = app.selected || [];
    const initSelected = onlyAdd ? [] : selected;
    setSelectedValue(initSelected.join(',') || '');
    updateField('selected', initSelected);

    dataProvider.getProblemTree({ id: app.problem }).then((list) => {
      const first = {
        value: '--create--',
        label: `Create New Solution`,
        raw: ''
      };
      const optionsList: Array<PsOptionType> = list
        .map((item) => {
          return {
            value: `${item.id}`,
            label: item.title || '(empty)',
            type: item.type
          };
        })
        .filter((item) => {
          const isSolution = item.type === 'solution';
          let isExcluded = false;
          if (onlyAdd) {
            isExcluded = selected.includes(item.value);
          }
          return isSolution && !isExcluded;
        });
      setList([first, ...optionsList]);
    });
  }, [values.application]);

  const onListChange = useCallback(
    (event: ShortEvent) => {
      const { value } = event.target;
      const valueList = value.split(',');
      if (
        value &&
        valueList.some((val: string) => val.startsWith('--create--'))
      ) {
        if (onNotify) {
          onNotify('create solution');
        }
        return;
      }

      setSelectedValue(value);
      const newSelected = value.split(',').filter((el: string) => el);
      const fullSelected = initSelected.concat(newSelected);
      updateField('selected', fullSelected);
    },
    [setSelectedValue]
  );

  return (
    <div className={styles.stepWrapper}>
      <PsFormControl
        placeholder="Choose solution"
        label="Selected Solutions"
        labelLight
      >
        <PsSelect
          isMulti
          maxMenuHeight={270}
          onChange={onListChange}
          options={list}
          value={selectedValue}
          name="selected"
        />
      </PsFormControl>
      <PsSelectedList
        options={list}
        value={selectedValue}
        onChange={onListChange}
      />
    </div>
  );
};

export default SolutionSelect;
