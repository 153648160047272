import React, { useState, useEffect, useRef } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { PsTheme } from '../../theme';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root' | 'loader' | 'image';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      // position: 'relative',
    },
    loader: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      margin: '-20px -20px'
    },
    image: {
      display: 'block',
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      objectFit: 'cover',
      opacity: 0,
      transition: 'opacity 0.3s ease',
      '&.loaded': {
        opacity: 1
      }
    }
  };
});

type ImageWithZoomProps = {
  className?: string;
  src?: string;
  alt?: string;
  onZoomClick?: (opts: { naturalHeight: number; naturalWidth: number }) => void;
};

const ImageWithZoomView = ({
  className,
  src,
  alt,
  onZoomClick
}: ImageWithZoomProps) => {
  const { classes } = useStyles();
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    setLoaded(false);
  }, [src]);

  useEffect(() => {
    if (imgRef.current) {
      if (imgRef.current.complete) {
        if (imgRef.current.naturalHeight) {
          setLoaded(true);
          setLoading(false);
        }
      } else {
        setLoading(true);
        imgRef.current.addEventListener('load', () => {
          setLoaded(true);
          setLoading(false);
        });
        imgRef.current.addEventListener('erorr', () => {
          setLoading(false);
        });
      }
    }
  }, [imgRef.current, src]);

  const onClick = () => {
    if (imgRef.current && loaded && onZoomClick) {
      onZoomClick(imgRef.current);
    }
  };

  let rootClassName = classes.root;
  if (className) {
    rootClassName += ' ' + className;
  }

  return (
    <div className={rootClassName} onClick={onClick}>
      {loading ? (
        <CircularProgress size={40} className={classes.loader} />
      ) : null}
      {src ? (
        <img
          ref={imgRef}
          className={`${classes.image} ${loaded ? 'loaded' : ''}`}
          src={src}
          alt={alt}
        />
      ) : null}
    </div>
  );
};

export const ImageWithZoom = styled(ImageWithZoomView)({});

export default ImageWithZoom;
