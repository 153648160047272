import React from 'react';
import { useDispatch } from 'react-redux';
import { Grid } from '@mui/material';
import { SocialIconButton } from 'components/common/buttons/SocialIconButton';
import { DiscordIcon } from 'components/icons/DiscordRoundIcon';
import { FacebookColoredIcon } from 'components/icons/FacebookColoredIcon';
import { GoogleColoredIcon } from 'components/icons/GoogleColoredIcon';
import { InstagramIcon } from 'components/icons/InstagramIcon';
import { LinkedinIcon } from 'components/icons/LinkedinIcon';
import { SnapchatIcon } from 'components/icons/SnapchatIcon';
import { TiktokIcon } from 'components/icons/TiktokIcon';
import { XIcon } from 'components/icons/XIcon';
import { YoutubeIcon } from 'components/icons/YoutubeIcon';
import Actions from 'redux-state/actions';
import { Constants } from 'utilities/constants';
import { SocialIconsContainer } from './styledComponents';

export const SocialIconsView: React.FC = () => {
  const dispatch = useDispatch();

  const icons = [
    {
      component: FacebookColoredIcon,
      onclick: () => dispatch(Actions.socialLogIn(Constants.FACEBOOK))
    },
    {
      component: GoogleColoredIcon,
      onclick: () => dispatch(Actions.socialLogIn(Constants.GOOGLE))
    },
    {
      component: InstagramIcon,
      onclick: () => dispatch(Actions.socialInstagramLogIn(Constants.INSTAGRAM))
    },
    {
      component: LinkedinIcon,
      onclick: () => dispatch(Actions.socialLinkedinLogIn(Constants.LINKEDIN))
    },
    {
      component: YoutubeIcon,
      onclick: () => dispatch(Actions.socialLogIn(Constants.YOUTUBE))
    },
    {
      component: XIcon,
      onclick: () => dispatch(Actions.socialTwitterLogIn(Constants.TWITTER))
    },
    {
      component: SnapchatIcon,
      onclick: () => dispatch(Actions.socialLogIn(Constants.SNAPCHAT))
    },
    {
      component: TiktokIcon,
      onclick: () => dispatch(Actions.socialTiktokLogIn(Constants.TIKTOK))
    },
    {
      component: DiscordIcon,
      onclick: () => dispatch(Actions.socialDiscordLogIn(Constants.DISCORD))
    }
  ];

  return (
    <SocialIconsContainer>
      {icons.map((icon, index) => (
        <Grid item md={2.4} sm={2.4} key={index}>
          <SocialIconButton
            key={index}
            Icon={icon.component}
            onClick={icon.onclick}
          />
        </Grid>
      ))}
    </SocialIconsContainer>
  );
};
