import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { CardContestView } from 'components/CardContestView/CardContestView';
import { ContestModal } from 'pages/newContests/ContestModal';
import MembersView from 'components/MembersView';
import AuthContext from 'contexts/AuthContext';
import DataContext from 'contexts/DataContext';
import { Tag } from '../CardTag';
import { SeeAllContestsModal } from 'modals/SeeAllContestsModal';
import Actions from 'redux-state/actions';
import { GetContest, GetContests, GetUser } from 'redux-state/selectors';
import { Constants } from 'utilities/constants';
import { Contest } from '../CardContest';
import { StyledCardButtonOutside } from '../CardContestView/CardContestView/styles';

interface CardCommunityProps {
  tag: Tag;
}

export const createContestToastify = (title, user) => {
  return (
    <Typography>
      {title ? (
        <>
          {title}
          {Constants.CREATE_CONTEST_TOAST_START}
        </>
      ) : (
        <>{Constants.CREATE_CONTEST_DRAFT}</>
      )}
      <a
        style={{ cursor: 'pointer' }}
        href={`/profiles/${user}?currentTab=Contests`}
      >
        {Constants.CLICK_HERE}
      </a>
      {Constants.CREATE_CONTEST_TOAST_END}
    </Typography>
  );
};

export const CardCommunity = ({ tag }: CardCommunityProps) => {
  const user = GetUser();
  const { showToast } = useContext(DataContext);
  const [topContest, setTopContest] = useState<Contest>();
  const [openSeeAllContestsModal, setOpenSeeAllContestsModal] =
    useState<boolean>(false);
  const [contestModal, setContestModal] = useState<boolean>(false);

  const contests = GetContests();
  const newContest = GetContest();

  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      dispatch(Actions.getProfile(user?._id ?? user?.id));
    }
  }, []);

  useEffect(() => {
    dispatch(Actions.getContests({ tagId: tag.id }));
  }, [dispatch, tag]);

  const toggleContestModal = () => {
    if (!user) {
      dispatch(Actions.openAuthModal(true));
      return;
    }
    setContestModal((pre) => !pre);
  };

  useEffect(() => {
    if (contests.length) {
      setTopContest(
        contests.reduce(
          (max, obj) => (obj.likes > max.likes ? obj : max),
          contests[0]
        )
      );
    }
  }, [contests]);

  useEffect(() => {
    if (
      newContest &&
      Object.keys(newContest).length &&
      newContest.status === Constants.DRAFT
    ) {
      showToast(
        createContestToastify(newContest.title, user?.key) as unknown as string,
        {
          style: {
            position: 'absolute',
            bottom: 0,
            left: 77,
            backgroundColor: 'white',
            color: 'black'
          },
          autoHideDuration: 5000
        }
      );
      dispatch(Actions.setContest({}));
    }
  }, [dispatch, newContest, showToast, user?.key]);

  const isOwner = user?.id === tag?.owner;

  return (
    <>
      <Grid
        container
        columnSpacing={2}
        marginBottom={2}
        sx={{ alignItems: 'center' }}
      >
        <Grid item xs={12} md={12} lg={7} marginTop={2} width="100%">
          {contests.length && topContest ? (
            <CardContestView
              contest={topContest}
              tag={tag}
              setOpenSeeAllContestsModal={setOpenSeeAllContestsModal}
              toggleContestModal={toggleContestModal}
            />
          ) : (
            isOwner && (
              <StyledCardButtonOutside onClick={toggleContestModal}>
                {Constants.CREATE_A_CONTEST}
              </StyledCardButtonOutside>
            )
          )}
        </Grid>
        <Grid item xs={12} md={12} lg={5} width="100%" marginTop={2}>
          <MembersView tag={tag} />
        </Grid>
      </Grid>
      {openSeeAllContestsModal && (
        <SeeAllContestsModal
          open={openSeeAllContestsModal}
          setOpen={setOpenSeeAllContestsModal}
          toggleContestModal={toggleContestModal}
          tag={tag}
          showCreateButton={isOwner}
        />
      )}
      {contestModal && (
        <ContestModal
          onClose={toggleContestModal}
          open={contestModal}
          tag={tag}
        />
      )}
    </>
  );
};
