/* eslint-disable max-lines-per-function */
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useDispatch } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import { CircularProgress, InputAdornment, Typography } from '@mui/material';
import { HexOutlinedIcon } from 'components/icons/HexOutlinedIcon';
import { conceptCartSearch } from 'helpers';
import { FiPlusCircle } from 'react-icons/fi';
import Actions from 'redux-state/actions';
import {
  GetConceptData,
  GetConceptsLoader,
  GetLoadNewConcept,
  GetUser
} from 'redux-state/selectors';
import { colorPalette } from 'theme';
import { Constants, VARIANT } from 'utilities/constants';
import { ConceptNode } from './ConceptNode';
import { addNewConcept } from './utils';
import {
  ConceptButton,
  NoConceptsBox,
  ProgressWrapper,
  SearchField,
  StyledTop
} from './styledComponents';

export const AllConceptsView: FunctionComponent = () => {
  const dispatch = useDispatch();

  const conceptsData = GetConceptData();
  const getConceptsLoader = GetConceptsLoader();
  const newConceptLoader = GetLoadNewConcept();
  const user = GetUser();

  const [concepts, setConcepts] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (conceptsData?.data) {
      setConcepts(conceptsData.data);
    }
  }, [conceptsData]);

  useEffect(() => {
    if (!user) {
      dispatch(Actions.openAuthModal(true));
      return;
    }
    dispatch(Actions.getConcept(user.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  useEffect(() => {
    setConcepts(() => {
      return conceptCartSearch(conceptsData.data, searchValue);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const onSearchChange = useCallback((event) => {
    setSearchValue(event.target.value);
  }, []);

  const TopSection = useMemo(
    () => (
      <StyledTop>
        <SearchField
          value={searchValue}
          onChange={onSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position={VARIANT.START}>
                <SearchIcon
                  color={VARIANT.PRIMARY}
                  sx={{ width: '30px', height: '30px' }}
                />
              </InputAdornment>
            )
          }}
        />
        <ConceptButton
          onClick={() => {
            addNewConcept(dispatch, user);
          }}
        >
          {newConceptLoader ? (
            <CircularProgress size="1rem" sx={{ margin: '0rem .3125rem' }} />
          ) : (
            <FiPlusCircle
              style={{ margin: '0rem .3125rem' }}
              color={colorPalette.lightGreen}
            />
          )}
          Add New Concept
        </ConceptButton>
      </StyledTop>
    ),
    [dispatch, newConceptLoader, onSearchChange, searchValue, user]
  );

  const hasConcepts = conceptsData?.data?.length > 0;
  const noConceptsFound =
    !getConceptsLoader && !hasConcepts && conceptsData?.data !== undefined;

  return (
    <>
      {TopSection}
      {getConceptsLoader ? (
        <ProgressWrapper>
          <CircularProgress sx={{ marginTop: '30%' }} />
        </ProgressWrapper>
      ) : (
        hasConcepts &&
        concepts.map((concept) => (
          <ConceptNode concept={concept} key={concept.id} />
        ))
      )}{' '}
      {noConceptsFound && (
        <NoConceptsBox>
          <HexOutlinedIcon />
          <Typography>{Constants.NO_CONCEPTS_FOUND}</Typography>
        </NoConceptsBox>
      )}
    </>
  );
};
