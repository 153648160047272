import React from 'react';
import { FunctionComponent } from 'react';
import { EntityType } from './types';
import { CheckIconWithContainer } from '../icons/CheckIconWithContainer';
import { InventionIconWithContainer } from '../icons/InventionIconWithContainer';
import { ProductIconWithContainer } from '../icons/ProductIconWithContainer';

const CheckIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17.311" height="12.489">
    <path
      fill="none"
      stroke="#008954"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
      d="m15.544 1.768-9.476 9.471-4.3-4.305"
      data-name="Icon feather-check"
    />
  </svg>
);

const InventionIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    data-name="Group 89"
  >
    <defs>
      <clipPath id="a">
        <path
          fill="#c77312"
          d="M0 0h14.349v20.737H0z"
          data-name="Rectangle 710"
        />
      </clipPath>
    </defs>
    <g fill="#c77312" clipPath="url(#a)" data-name="Group 67">
      <path
        d="M7.177 0a7.174 7.174 0 0 0-4.1 13.062l.115.081v2.545a.8.8 0 0 0 .8.795h6.38a.8.8 0 0 0 .795-.8v-2.541l.115-.081A7.174 7.174 0 0 0 7.177 0m4.59 10.354a5.556 5.556 0 0 1-1.4 1.4l-.79.557v2.576H4.779v-2.571l-.79-.557a5.588 5.588 0 1 1 7.778-1.4"
        data-name="Path 271"
      />
      <path
        d="M5.05 20.741h4.254a.8.8 0 0 0 .795-.8v-.793H4.256v.794a.8.8 0 0 0 .8.795"
        data-name="Path 272"
      />
    </g>
  </svg>
);

const ProductCard = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15.911" height="17.633">
    <g
      fill="none"
      stroke="#632ddd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
      data-name="Icon feather-box"
    >
      <path
        d="M15.311 12.063V5.525a1.635 1.635 0 0 0-.817-1.414L8.773.842a1.635 1.635 0 0 0-1.635 0L1.417 4.111A1.635 1.635 0 0 0 .6 5.525v6.538a1.635 1.635 0 0 0 .817 1.414l5.721 3.269a1.634 1.634 0 0 0 1.635 0l5.721-3.269a1.635 1.635 0 0 0 .817-1.414Z"
        data-name="Path 273"
      />
      <path d="m.816 4.675 7.135 4.127 7.135-4.127" data-name="Path 274" />
      <path d="M7.955 17.032V8.794" data-name="Path 275" />
    </g>
  </svg>
);

const CompetitorIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14.38" height="14.322">
    <g data-name="Group 349">
      <g
        fill="#1f62dd"
        stroke="#1f62dd"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".3"
        data-name="Group 340"
      >
        <path
          d="M.15.15v9.667h2.616l.992-.992H1.141V1.141h7.685v2.616l.992-.991V.15Z"
          data-name="Path 290"
        />
        <path
          d="m12.287 4.178-.991.991h1.558v7.685H5.169v-1.558l-.992.991v1.559h9.668V4.178Z"
          data-name="Path 291"
        />
        <path
          d="m9.817 4.005-.172.173-.819.819-.173.172-3.484 3.484-.173.173-.819.819-.172.172L.352 13.47l.082.082.539.539.081.081 3.125-3.125.99-.986.239-.239.992-.991L8.826 6.4l.991-.992.239-.239.992-.991L14.226.996l-.078-.082-.538-.537-.084-.083Z"
          data-name="Path 292"
        />
      </g>
    </g>
  </svg>
);

const ProblemIcon = () => (
  <svg
    width="27"
    height="35"
    viewBox="0 0 27 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.9609 22.7986C10.9609 21.6644 11.1453 20.6597 11.514 19.7847C11.9162 18.9097 12.4022 18.1319 12.9721 17.4514C13.5754 16.7708 14.2123 16.1389 14.8827 15.5556C15.5531 14.9722 16.1732 14.4213 16.743 13.9028C17.3464 13.3519 17.8324 12.7847 18.2011 12.2014C18.6034 11.6181 18.8045 10.9699 18.8045 10.2569C18.8045 9.09028 18.3017 8.15046 17.2961 7.4375C16.324 6.72454 15.0168 6.36805 13.3743 6.36805C11.7989 6.36805 10.3911 6.69213 9.15084 7.34028C7.91061 7.95602 6.88827 8.83102 6.0838 9.96528L0 6.51389C1.34078 4.53704 3.20112 2.96528 5.58101 1.79861C7.96089 0.599537 10.8436 0 14.229 0C16.743 0 18.9553 0.356481 20.8659 1.06944C22.7765 1.75 24.2682 2.75463 25.3408 4.08333C26.4469 5.41204 27 7.04861 27 8.99306C27 10.2569 26.7989 11.375 26.3967 12.3472C25.9944 13.3194 25.4749 14.162 24.838 14.875C24.2011 15.588 23.514 16.2523 22.7765 16.8681C22.0726 17.4838 21.4022 18.0833 20.7654 18.6667C20.1285 19.25 19.5922 19.8657 19.1564 20.5139C18.7542 21.162 18.5531 21.9236 18.5531 22.7986H10.9609ZM14.7821 35C13.3743 35 12.2179 34.5625 11.3128 33.6875C10.4078 32.8125 9.95531 31.7755 9.95531 30.5764C9.95531 29.3449 10.4078 28.3241 11.3128 27.5139C12.2179 26.6713 13.3743 26.25 14.7821 26.25C16.2235 26.25 17.3799 26.6713 18.2514 27.5139C19.1564 28.3241 19.6089 29.3449 19.6089 30.5764C19.6089 31.7755 19.1564 32.8125 18.2514 33.6875C17.3799 34.5625 16.2235 35 14.7821 35Z"
      fill="#EA3636"
    />
  </svg>
);

interface Props {
  type: EntityType | string;
  isDetailDrawer?: boolean;
}

export const EntityIcon: FunctionComponent<Props> = ({
  type,
  isDetailDrawer
}) => {
  if (type === 'product') {
    return isDetailDrawer ? <ProductIconWithContainer /> : <ProductCard />;
  }

  if (type === 'problem') {
    return isDetailDrawer ? <ProblemIcon /> : <>?</>;
  }

  if (type === 'solution') {
    return isDetailDrawer ? <CheckIconWithContainer /> : <CheckIcon />;
  }

  if (type === 'invention' || 'application') {
    return isDetailDrawer ? <InventionIconWithContainer /> : <InventionIcon />;
  }

  if (type === 'competitor') {
    return <CompetitorIcon />;
  }

  return null;
};
