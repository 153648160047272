import React from 'react';
export const SchoolIcon = ({
  size = 48,
  color = '#000',
  className
}: {
  size?: number;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color}
        d="M23.95 41.55q-.4 0-.75-.075t-.7-.275L11 34.9q-.7-.4-1.125-1.1-.425-.7-.425-1.55v-10.2L4.4 19.3q-.4-.2-.575-.55-.175-.35-.175-.75t.175-.75Q4 16.9 4.4 16.7l18.1-9.9q.35-.2.7-.3.35-.1.75-.1t.75.1q.35.1.7.3l19.8 10.75q.4.25.6.6.2.35.2.75v13.45q0 .65-.425 1.075-.425.425-1.075.425-.65 0-1.075-.425Q43 33 43 32.35v-12.6l-4.55 2.3v10.2q0 .85-.425 1.55-.425.7-1.125 1.1l-11.5 6.3q-.35.2-.7.275-.35.075-.75.075Zm0-14.95L39.7 18 23.95 9.55 8.3 18Zm0 12 11.5-6.35v-8.4l-10.05 5.4q-.35.2-.7.275-.35.075-.75.075t-.725-.075q-.325-.075-.675-.275l-10.1-5.5v8.5Zm.05-12Zm-.05 4.5Zm0 0Z"
      />
    </svg>
  );
};

export default SchoolIcon;
