import React, { useState, useEffect, useCallback } from 'react';
import { Typography } from '@mui/material';
import dataProvider from 'dataPrvider';
import { Product } from 'components/CardProduct';
import { Constants } from 'utilities/constants';
import PsFormControl from 'components/common/PsFormControl';
import PsSearchSelect from 'components/common/PsSearchSelect';
import { OptionType } from 'modals/Common/interface';
import { debounce } from 'lodash';

interface ProductFieldProps {
  boldLabel?: boolean;
  companyId: string | number;
  selectedProduct?: OptionType | null;
  setSelectedProduct?: React.Dispatch<React.SetStateAction<OptionType | null>>;
}

interface ProductType {
  id?: string;
  name?: string;
}
export const ProductField: React.FC<ProductFieldProps> = ({
  boldLabel,
  companyId,
  selectedProduct,
  setSelectedProduct
}) => {
  const [page, setPage] = useState<number>(1);
  const [products, setProducts] = useState<Array<ProductType>>([]);
  const handleProductChange = (selected: OptionType | null) => {
    setSelectedProduct(selected);
  };
  const [resetOptions, setResetOptions] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchFilter, setSearchFilter] = useState<string>('');

  const fetchData = () => {
    setIsLoading(true);
    dataProvider
      .getList<Product>('company-products', {
        pagination: { page: page, perPage: 10 },
        filter: {
          company: companyId,
          $custom: { title: searchFilter, simpleList: true }
        }
      })
      .then(({ data = [] }) => {
        const list = data.map((item) => ({
          id: `${item.id}`,
          name: item.title || ''
        }));
        setProducts(list);
      })
      .catch(() => {
        setProducts([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setResetOptions(true);
  }, [companyId]);

  useEffect(() => {
    if (resetOptions) {
      setProducts([]);
      setPage(1);
      setResetOptions(false);
    }

    fetchData();
  }, [page, resetOptions]);

  const handleSearchChange = useCallback(
    debounce((value: string) => {
      setResetOptions(true);
      setSearchFilter(value);
    }, 500),
    []
  );

  const handleInputChange = useCallback(
    (value: string) => {
      handleSearchChange(value);
    },
    [handleSearchChange]
  );

  return (
    <PsFormControl
      label={
        <Typography sx={{ fontWeight: boldLabel ? 'bold' : 'normal' }}>
          {Constants.PRODUCT_FIELD_LABEL}
        </Typography>
      }
    >
      <PsSearchSelect
        createableValue={selectedProduct}
        onCreateableChange={handleProductChange}
        optionsList={products}
        page={page}
        setPage={setPage}
        resetOptions={resetOptions}
        isLoading={isLoading}
        handleInputChange={handleInputChange}
      />
    </PsFormControl>
  );
};
