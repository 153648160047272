import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PersonIcon from '@mui/icons-material/Person';
import { Constants, VARIANT } from 'utilities/constants';
import Actions from 'redux-state/actions';
import { GetUser } from 'redux-state/selectors';
import { ProblemSolutionModal } from './ProblemSolutionModal';
import {
  StyledFieldMainWrapper,
  StyledAvatar,
  StyledCreateTextField
} from 'pages/home/StyledComponents';

export const CreateProblemSolution = () => {
  const user = GetUser();
  const dispatch = useDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => {
    if (user) {
      setOpen(true);
    } else {
      dispatch(Actions.openAuthModal(true));
    }
  };
  const handleClose = () => setOpen(false);

  return (
    <StyledFieldMainWrapper>
      <StyledAvatar>
        {user?.files?.[0]?.url ? (
          <img
            src={user?.files?.[0]?.url}
            alt={user?.files?.[0]?.title}
            style={{
              borderRadius: '50%',
              height: '100%',
              objectFit: 'cover',
              width: '100%'
            }}
          />
        ) : (
          <PersonIcon />
        )}
      </StyledAvatar>
      <StyledCreateTextField
        fullWidth
        onClick={handleOpen}
        placeholder={Constants.CREATE_PROBLEM_SOLUTION}
        variant={VARIANT.OUTLINED}
        InputProps={{
          readOnly: true
        }}
      />
      <ProblemSolutionModal open={open} onClose={handleClose} />
    </StyledFieldMainWrapper>
  );
};
