import {
  Accordion,
  AccordionSummary,
  Box,
  LinearProgress,
  Link,
  styled
} from '@mui/material';
import { colorPalette } from 'theme';

export const StyledAccordion = styled(Accordion)({
  borderRadius: '0.625rem',
  boxShadow: '0rem 0.25rem 0.5rem rgba(0, 0, 0, 0.2)',
  '&:before': {
    display: 'none'
  }
});

export const StyledProgressAccordion = styled(StyledAccordion)({
  boxShadow: '0rem 0.25rem 0.5rem rgba(0, 0, 0, 0.2)',
  marginTop: '16px'
});

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  '& .MuiLinearProgress-bar': {
    backgroundColor: theme.palette.success.main
  }
}));

export const StyledAccordionContentBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%'
});

export const ProgressWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  width: '50%'
});

export const ChatLinkWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end'
});

export const StyledLinearProgress = styled(LinearProgress)(() => ({
  backgroundColor: '#e0e0e0',
  borderRadius: 5,
  height: 10,
  marginRight: 2,
  width: '100%',
  '& .MuiLinearProgress-bar': {
    backgroundColor: colorPalette.lightGreen
  }
}));

export const AIChatLink = styled(Link)({
  color: colorPalette.purple,
  cursor: 'pointer',
  display: 'flex',
  fontSize: '0.875rem',
  justifyContent: 'flex-end',
  marginBottom: 8,
  '&:hover': {
    textDecoration: 'underline'
  }
});
