import React, { useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { CoinIcon } from 'components/icons/CoinIcon';
import { StepperNav } from 'components/VerticalStepper';
import { Solution } from 'components/CardSolution';
import { ModalContext } from 'contexts/ModalContext';
import { DataContext } from 'contexts/DataContext';

import { PsTheme } from '../../theme';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      marginLeft: 60
    }
  };
});

export type NextStepsSolutionProps = {
  solution: Solution;
};

const NextStepsSolutionView = ({ solution }: NextStepsSolutionProps) => {
  const { setSolutionDetailEditOpened } = useContext(DataContext);
  const { openInventionAttachToSolution, openSolutionImprove } =
    useContext(ModalContext);
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      {' '}
      <StepperNav
        steps={[
          {
            stepContent: () => (
              <div style={{ fontSize: 18 }}>Solution Created</div>
            ),
            stepStatusCircleSize: 20,
            stepStateColor: '#FBCA67'
          },
          {
            stepContent: () => (
              <div
                style={{
                  fontSize: 18,
                  color: '#632DDD',
                  fontWeight: 600
                }}
              >
                <a
                  onClick={() => {
                    setSolutionDetailEditOpened(true);
                  }}
                >
                  Add missing solution details
                </a>
              </div>
            ),
            stepStatusCircleSize: 20,
            stepStateColor: 'white'
          },
          {
            stepContent: () => (
              <div
                style={{
                  fontSize: 18,
                  color: '#632DDD',
                  fontWeight: 600
                }}
              >
                <CoinIcon color="#632DDD" />{' '}
                <a
                  onClick={() => {
                    openInventionAttachToSolution({
                      solutionId: solution.id,
                      solutionProblemId: solution.problem,
                      solutionTitle: solution.title
                    });
                  }}
                  style={{ marginLeft: '.5rem' }}
                >
                  Add solution to existed invention or create an new one to earn
                  1 IdeaPoint{' '}
                </a>
              </div>
            ),
            stepStatusCircleSize: 20,
            stepStateColor: 'white'
          },
          {
            stepContent: () => (
              <div
                style={{
                  fontSize: 18,
                  color: '#632DDD',
                  fontWeight: 600
                }}
              >
                <CoinIcon color="#632DDD" />{' '}
                <a
                  onClick={() => {
                    openSolutionImprove({
                      solutionId: solution.id,
                      solutionTitle: solution?.title
                    });
                  }}
                  style={{ marginLeft: '.5rem' }}
                >
                  Improve this solution to earn up to 2 IdeaPoints
                </a>
              </div>
            ),
            stepStatusCircleSize: 20,
            stepStateColor: 'white'
          },

          {
            stepContent: () => (
              <div
                style={{
                  fontSize: 18,
                  color: '#632DDD',
                  fontWeight: 600
                }}
              >
                <CoinIcon color="#632DDD" />{' '}
                <a
                  onClick={() => {
                    openInventionAttachToSolution({
                      solutionId: solution.id,
                      solutionProblemId: solution.problem,
                      solutionTitle: solution.title
                    });
                  }}
                  style={{ marginLeft: '.5rem' }}
                >
                  Add this solution to the invention
                </a>
              </div>
            ),
            stepStatusCircleSize: 20,
            stepStateColor: 'white'
          },
          {
            stepContent: () => (
              <div style={{ fontSize: 18 }}>
                <CoinIcon color="#632DDD" />{' '}
                <a style={{ marginLeft: '.5rem' }}></a> Mint idea NFT to make
                patent pending
              </div>
            ),
            stepStatusCircleSize: 20,
            stepStateColor: 'white'
          },
          {
            stepContent: () => (
              <div style={{ fontSize: 18 }}>
                Sell your idea NFT to earn crypto
              </div>
            ),
            stepStatusCircleSize: 20,
            stepStateColor: 'white'
          }
        ]}
      />
    </div>
  );
};

export const NextStepsSolution = styled(NextStepsSolutionView)({});

export default NextStepsSolution;
