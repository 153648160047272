import React, {
  useState,
  useMemo,
  useCallback,
  ReactNode,
  useContext
} from 'react';
import {
  styled,
  Box,
  Menu as Menu,
  MenuItem as MenuItem,
  Avatar
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { PushPin } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import ErrorView from 'components/ErrorView/index';
import AuthContext from 'contexts/AuthContext';
import ConfirmationModal from 'modals/ConfirmationModal/ConfirmationModal';
import { ContestModal } from 'pages/newContests/ContestModal';
import Actions from 'redux-state/actions';
import EditModal from 'modals/EditModal/EditModal';
import ModalSmall from 'modals/ModalSmall';
import { toggleError } from 'redux-state/profile/actions';
import {
  GetErrorStatus,
  GetErrorMessage,
  GetErrorItemId
} from 'redux-state/selectors';
import { Constants } from 'utilities/constants';
import { Application } from '../CardApplication';
import { Solution } from '../CardSolution';
import ModalEditSolution from '../CardSolution/ModalEditSolution';
import { Problem } from '../CardProblem';
import { Contest } from '../CardContest';
import PsButton from '../common/PsButton';
import styles from '../../layout/Header/Header.module.scss';

interface Action {
  [key: string]: any;
  component: ReactNode | (() => ReactNode);
  label?: string;
}

const StyledModalContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: 'white',
  borderRadius: '7px',
  padding: '35px',
  width: '90%'
});

const StyledImage = styled(Avatar)({
  width: '3rem',
  height: '3rem',
  borderRadius: '.625rem'
});

const StyledBox = styled(Box)({
  display: 'flex',
  alignItems: 'center'
});

interface CustomMenuProps {
  actions: Action[];
  menuIcon?: any;
  isArchived?: boolean;
  item?: Problem | Solution | Application | Contest;
  itemType?: string;
  isFiled?: boolean;
  pagination?: {
    page: number;
    perPage: number;
  };
  showPin?: boolean;
  tagKey?: string;
  title?: string;
  type?: string;
}

const CustomMenu: React.FC<CustomMenuProps> = ({
  actions,
  menuIcon,
  isArchived = false,
  isFiled,
  item,
  itemType,
  pagination,
  showPin = true,
  tagKey,
  title,
  type
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showConfModal, setShowConfModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const { user } = useContext(AuthContext);
  const open = Boolean(anchorEl);

  const dispatch = useDispatch();
  const errorStatus = GetErrorStatus();
  const errorMessage = GetErrorMessage();
  const errorItemId = GetErrorItemId();

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleCloseModal = () => {
    setOpenEditModal(false);
  };

  const handleButtonClick = useCallback(
    (button) => {
      handleClose();
      switch (button.class) {
        case 'remove':
          setShowConfModal(true);
          break;
        case 'unlink':
          setShowConfModal(true);
          break;
        case 'edit':
          setOpenEditModal(true);
          break;
        default:
          button.onClick();
      }
    },
    [handleClose]
  );

  const mappedActions = useMemo(
    () =>
      actions.map((button, index) => (
        <MenuItem
          key={index}
          onClick={() => {
            handleButtonClick(button);
          }}
        >
          {typeof button.component === 'function'
            ? button.component()
            : button.component}
          <span>{button.label}</span>
        </MenuItem>
      )),
    [actions, handleButtonClick]
  );

  const handleCloseConfModal = () => {
    setShowConfModal(false);
  };

  const handleCloseErrorModal = () => {
    dispatch(toggleError('', itemType));
  };

  const handleDelProfileItem = () => {
    if (user) {
      dispatch(
        Actions.deleteProfileItem(
          item._id as string,
          itemType,
          item.owner,
          isFiled,
          pagination
        )
      );
    } else {
      dispatch(Actions.openAuthModal(true));
    }
  };

  const handleUnlinkTag = () => {
    if (user) {
      dispatch(Actions.unlinkTag(itemType, item.id, tagKey, pagination));
    } else {
      dispatch(Actions.openAuthModal(true));
    }
  };

  const rotatedStyle = {
    transform: 'rotate(40deg)' // Rotate the icon by 180 degrees
  };

  return (
    <StyledBox>
      {type === 'LoginMenu' ? (
        <PsButton
          disableElevation
          className={`${styles.headerButton} ${styles.userDropdownButton}`}
          onClick={handleClick}
        >
          <StyledImage src={user?.files[0]?.url} />
        </PsButton>
      ) : (
        <IconButton
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          aria-label="more"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
      )}
      <Menu
        anchorEl={anchorEl}
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        open={open}
        onClose={handleClose}
      >
        {mappedActions}
      </Menu>
      {showPin && item?.pinnedAt && (
        <PushPin color={'primary'} style={rotatedStyle} />
      )}
      {type != 'LoginMenu' && (
        <>
          <ModalSmall isOpen={showConfModal} onClose={handleCloseConfModal}>
            <ConfirmationModal
              action={type == Constants.C_COMMUNITY ? 'unlink' : 'remove'}
              item={item}
              onClose={handleCloseConfModal}
              onDelete={
                type == Constants.C_COMMUNITY
                  ? handleUnlinkTag
                  : handleDelProfileItem
              }
              title={title}
            />
          </ModalSmall>
          <ModalSmall
            isOpen={errorItemId == item?._id && errorStatus}
            onClose={handleCloseErrorModal}
          >
            <StyledModalContainer>
              <ErrorView errorMessage={errorMessage} />
            </StyledModalContainer>
          </ModalSmall>
          {title === Constants.C_SOLUTION && openEditModal ? (
            <ModalEditSolution
              solution={item as Solution}
              open={openEditModal}
              onClose={handleCloseModal}
              solutionTitles={[]}
            />
          ) : title === Constants.C_CONTEST ? (
            openEditModal && (
              <ContestModal
                contest={item as Contest}
                isArchived={isArchived}
                onClose={handleCloseModal}
                open={openEditModal}
                pagination={pagination}
              />
            )
          ) : (
            <EditModal
              data={item}
              title={title}
              type={itemType}
              openEditModal={openEditModal}
              onClose={handleCloseModal}
              pagination={pagination}
            />
          )}
        </>
      )}
    </StyledBox>
  );
};

export { CustomMenu };
