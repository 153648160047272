import React from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { Application } from 'components/CardApplication';
import { finalizeType as finalizeTypes } from '../../helpers';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { PsTheme } from '../../theme';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      marginTop: -10,
      marginBottom: 5,
      '& svg': {
        marginRight: 5
      }
    }
  };
});

export type VisibilityStatusProps = {
  className?: string;
  application: Application;
};

const VisibilityStatusView = ({
  className,
  application
}: VisibilityStatusProps) => {
  const { classes } = useStyles();
  let iconText = '';
  const isPublic = application.isPublic || false;

  if (isPublic) {
    if (application.finalizeType === finalizeTypes.OPEN) {
      iconText = 'Public Open Source';
    } else if (application.isFiled) {
      iconText = 'Public Patent Pending';
    } else {
      iconText = 'Awaiting Patent Filing';
    }
  } else {
    if (application.isFiled) {
      iconText = 'Private Awaiting Patent Filling';
    } else {
      iconText = 'Private Needs Patent';
    }
  }

  return (
    <div className={`${classes.root} ${className}`}>
      {isPublic ? (
        <VisibilityIcon width="1" height="1" />
      ) : (
        <VisibilityOffIcon width="1" height="1" />
      )}{' '}
      {iconText}
    </div>
  );
};

export const VisibilityStatus = styled(VisibilityStatusView)({});

export default VisibilityStatus;
