import React from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { PsTheme } from '../../theme';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'footer';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    footer: {
      flexShrink: 0,
      '& > button': {
        margin: '15px 0 0',
        lineHeight: '1.3'
      }
    }
  };
});

type DrawerModalFooterProps = {
  classes?: any;
  children?: React.ReactNode;
};

const DrawerModalFooterView = ({ children }: DrawerModalFooterProps) => {
  const { classes } = useStyles();
  return <div className={classes.footer}>{children}</div>;
};

export const DrawerModalFooter = styled(DrawerModalFooterView)({});

export default DrawerModalFooter;
