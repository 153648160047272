/* eslint-disable */
import { Profile } from 'components/CardProfile';
import { setToken } from 'helpers/common';
import {
  call,
  CallEffect,
  put,
  PutEffect,
  takeLatest
} from 'redux-saga/effects';
import { Constants, ERRORS } from 'utilities/constants';
import * as Actions from './actions';
import { Api } from './api';
import { SocialAuthKeys } from './interface';
import {
  FORGET_PASSWORD,
  GET_PROFILE_SOCIAL_AUTH_KEYS,
  GET_PROFILE,
  GET_PUBLIC_PROFILE,
  LOG_IN,
  RESET_PASSWORD,
  SIGN_UP,
  SOCIAL_DISCONNECT,
  SOCIAL_DISCORD_LOG_IN,
  SOCIAL_INSTAGRAM_LOG_IN,
  SOCIAL_LINKEDIN_LOG_IN,
  SOCIAL_LOG_IN,
  SOCIAL_TIKTOK_LOG_IN,
  SOCIAL_TWITTER_LOG_IN
} from './types';
import { CreateProfilePayload } from '../commons/interface';
import * as ProfileActions from '../profile/actions';

function* forgetPassword(action: {
  type: string;
  payload: { email: string };
}): Generator<CallEffect | PutEffect, void, Profile> {
  try {
    const response = yield call(Api.forgetPassword, action.payload);
    if (!response) {
      yield put(
        ProfileActions.updateProfileErrorStatus(500, ERRORS.UNEXPECTED_ERROR)
      );
    }
    yield put(
      Actions.forgetPasswordResponse({
        resetPasswordStatus: response.data.message
      })
    );
  } catch (error) {
    yield put(Actions.forgetPasswordResponse({}));
    yield put(
      ProfileActions.updateProfileErrorStatus(error.status, error.message)
    );
  }
}

function* resetPassword(action: {
  type: string;
  payload: { newPassword: string; resetToken: string };
}): Generator<CallEffect | PutEffect, void, Profile> {
  try {
    const response = yield call(Api.resetPassword, action.payload);
    if (!response) {
      yield put(
        ProfileActions.updateProfileErrorStatus(500, ERRORS.UNEXPECTED_ERROR)
      );
    }
    yield put(
      Actions.resetPasswordResponse({
        resetPasswordStatus: response.data.message
      })
    );
  } catch (error) {
    yield put(Actions.resetPasswordResponse({}));
    yield put(
      ProfileActions.updateProfileErrorStatus(error.status, error.message)
    );
  }
}

function* getProfile(action: {
  type: string;
  payload: {
    id: string | number;
    useKey?: boolean;
  };
}): Generator<CallEffect | PutEffect, void, Profile> {
  try {
    const response: Profile = yield call(Api.getProfile, action.payload);
    yield put(Actions.getProfileSuccess(response));
  } catch (error) {
    console.log('error', error);
  }
}

function* getPublicProfile(action: {
  type: string;
  payload: {
    userKey?: string | number;
  };
}): Generator<CallEffect | PutEffect, void, Profile> {
  try {
    const response: Profile = yield call(Api.getPublicProfile, action.payload);
    yield put(Actions.getPublicProfileSuccess(response));
  } catch (error) {
    console.log('error', error);
  }
}

function* signUp(action: {
  type: string;
  payload: CreateProfilePayload;
}): Generator<CallEffect | PutEffect, void, Profile> {
  try {
    const response: Profile = yield call(Api.signUp, { ...action.payload });
    if (response?.data) {
      setToken(response.data?.info?.token);
      yield put(Actions.setLoginUser(response.data?.user));
      yield put(Actions.updateSignUpStatus());
    }
  } catch (error) {
    yield put(
      ProfileActions.updateProfileErrorStatus(error.status, error.message)
    );
  }
}

function* getSocialAuthKeys(
  action
): Generator<CallEffect | PutEffect, void, SocialAuthKeys> {
  try {
    const data = yield call(Api.getSocialAuthKeys, action.payload);
    if (data) {
      yield put(Actions.getSocialAuthKeysSuccess(data));
    }
  } catch (error) {
    console.log(ERRORS.SOCIAL_AUTH_KEYS, error);
    yield put(Actions.getSocialAuthKeysSuccess([]));
  }
}

function* socialDisconnect(
  action
): Generator<CallEffect | PutEffect, void, SocialAuthKeys> {
  try {
    const data: SocialAuthKeys = yield call(
      Api.socialDisconnect,
      action.payload
    );
    if (data) {
      yield put(Actions.getSocialAuthKeys(data?.userId));
    }
  } catch (error) {
    console.log(ERRORS.SOCIAL_AUTH_KEYS, error);
  }
}

function* socialLogIn(
  action
): Generator<CallEffect | PutEffect, void, Profile> {
  try {
    const data = yield call(
      Api.socialLogIn,
      action.payload,
      action.share,
      action.userId
    );
    if (data && action.share === undefined) {
      setToken(data.token);
      yield put(Actions.setLoginUser(data.user));
      yield put(Actions.logInSuccess({ status: Constants.LOGIN_SUCCESS }));
    }
    if (data && action.share !== undefined) {
      yield put(Actions.getSocialAuthKeys(data?.user?.id));
    }
  } catch (error) {
    yield put(
      ProfileActions.updateProfileErrorStatus(error.status, error.message)
    );
  }
}

function* socialDiscordLogIn(
  action
): Generator<CallEffect | PutEffect, void, Profile> {
  try {
    const data = yield call(
      Api.socialDiscordLogIn,
      action.payload,
      action.share,
      action.userId
    );
    if (data && action.share === undefined) {
      setToken(data.token);
      yield put(Actions.setLoginUser(data.user));
      yield put(Actions.logInSuccess({ status: Constants.LOGIN_SUCCESS }));
    }
    if (data && action.share !== undefined) {
      yield put(Actions.getSocialAuthKeys(data?.user?.id));
    }
  } catch (error) {
    yield put(
      ProfileActions.updateProfileErrorStatus(error.status, error.message)
    );
  }
}

function* socialLinkedinLogIn(
  action
): Generator<CallEffect | PutEffect, void, Profile> {
  try {
    const data = yield call(
      Api.socialLinkedinLogIn,
      action.payload,
      action.share,
      action.userId
    );
    if (data && action.share === undefined) {
      setToken(data.token);
      yield put(Actions.setLoginUser(data.user));
      yield put(Actions.logInSuccess({ status: Constants.LOGIN_SUCCESS }));
    }
    if (data && action.share !== undefined) {
      yield put(Actions.getSocialAuthKeys(data?.user?.id));
    }
  } catch (error) {
    yield put(
      ProfileActions.updateProfileErrorStatus(error.status, error.message)
    );
  }
}

function* socialInstagramLogIn(
  action
): Generator<CallEffect | PutEffect, void, Profile> {
  try {
    const data = yield call(
      Api.socialInstagramLogIn,
      action.payload,
      action.share,
      action.userId
    );
    if (data && action.share === undefined) {
      setToken(data.token);
      yield put(Actions.setLoginUser(data.user));
      yield put(Actions.logInSuccess({ status: Constants.LOGIN_SUCCESS }));
    }
    if (data && action.share !== undefined) {
      yield put(Actions.getSocialAuthKeys(data?.user?.id));
    }
  } catch (error) {
    yield put(
      ProfileActions.updateProfileErrorStatus(error.status, error.message)
    );
  }
}

function* socialTiktokLogIn(
  action
): Generator<CallEffect | PutEffect, void, Profile> {
  try {
    const data = yield call(
      Api.socialTiktokLogIn,
      action.payload,
      action.share,
      action.userId
    );
    if (data && action.share === undefined) {
      setToken(data.token);
      yield put(Actions.setLoginUser(data.user));
      yield put(Actions.logInSuccess({ status: Constants.LOGIN_SUCCESS }));
    }
    if (data && action.share !== undefined) {
      yield put(Actions.getSocialAuthKeys(data?.user?.id));
    }
  } catch (error) {
    yield put(
      ProfileActions.updateProfileErrorStatus(error.status, error.message)
    );
  }
}

function* socialTwitterLogIn(
  action
): Generator<CallEffect | PutEffect, void, Profile> {
  try {
    const data = yield call(
      Api.socialTwitterLogIn,
      action.payload,
      action.share,
      action.userId
    );
    if (data && action.share === undefined) {
      setToken(data.token);
      yield put(Actions.setLoginUser(data.user));
      yield put(Actions.logInSuccess({ status: Constants.LOGIN_SUCCESS }));
    }
    if (data && action.share !== undefined) {
      yield put(Actions.getSocialAuthKeys(data?.user?.id));
    }
  } catch (error) {
    yield put(
      ProfileActions.updateProfileErrorStatus(error.status, error.message)
    );
  }
}

function* logIn(action: {
  type: string;
  payload: CreateProfilePayload;
}): Generator<CallEffect | PutEffect, void, Profile> {
  try {
    const response: Profile = yield call(Api.signIn, { ...action.payload });
    if (response.data) {
      setToken(response.data.info.token);
      yield put(Actions.setLoginUser(response.data.user));
      yield put(Actions.logInSuccess({ status: Constants.LOGIN_SUCCESS }));
    } else {
      yield put(
        ProfileActions.updateProfileErrorStatus(500, ERRORS.UNEXPECTED_ERROR)
      );
    }
  } catch (error) {
    yield put(
      ProfileActions.updateProfileErrorStatus(error.status, error.message)
    );
  }
}

function* mySaga() {
  yield takeLatest(FORGET_PASSWORD, forgetPassword);
  yield takeLatest(GET_PROFILE_SOCIAL_AUTH_KEYS, getSocialAuthKeys);
  yield takeLatest(GET_PROFILE, getProfile);
  yield takeLatest(GET_PUBLIC_PROFILE, getPublicProfile);
  yield takeLatest(LOG_IN, logIn);
  yield takeLatest(RESET_PASSWORD, resetPassword);
  yield takeLatest(SIGN_UP, signUp);
  yield takeLatest(SOCIAL_DISCONNECT, socialDisconnect);
  yield takeLatest(SOCIAL_DISCORD_LOG_IN, socialDiscordLogIn);
  yield takeLatest(SOCIAL_INSTAGRAM_LOG_IN, socialInstagramLogIn);
  yield takeLatest(SOCIAL_LINKEDIN_LOG_IN, socialLinkedinLogIn);
  yield takeLatest(SOCIAL_LOG_IN, socialLogIn);
  yield takeLatest(SOCIAL_TIKTOK_LOG_IN, socialTiktokLogIn);
  yield takeLatest(SOCIAL_TWITTER_LOG_IN, socialTwitterLogIn);
}

export default mySaga;
