import React, { useMemo } from 'react';
import { Button, Modal, Typography, Box } from '@mui/material';
import ModalWindow from '../ModalWindow';
import { INITIAL_PAYMENT_SUCCESS_CONTENT } from '../../utilities/constants';

interface InitialPatentFilingModalProps {
  isOpen: boolean;
  handleAgree: (event: React.FormEvent) => void;
  onClose: () => void;
}

const ModalWindowProps = { maxWidth: '650px', margin: '0 auto' };
const PatentButtonProps = { width: '100%', marginBottom: '16px' };
const CloseButtonProps = { width: '100%' };
const ContentProps = { paddingLeft: '16px' };

export const InitialPatentFilingModal: React.FC<
  InitialPatentFilingModalProps
> = ({ isOpen, handleAgree, onClose }) => {
  const contentList = useMemo(() => {
    return INITIAL_PAYMENT_SUCCESS_CONTENT.split('\n').map((line, index) => (
      <li key={index}>{line}</li>
    ));
  }, []);

  return (
    <Box>
      <Modal open={isOpen} onClose={onClose}>
        <ModalWindow
          title="Patent Pending Token ($300)"
          style={ModalWindowProps}
        >
          <Box>
            <Typography variant="body1">
              <ul style={ContentProps}>{contentList}</ul>
            </Typography>
            <Box>
              <Button
                variant="contained"
                style={PatentButtonProps}
                onClick={handleAgree}
              >
                Create Patent Pending Token
              </Button>
              <Button
                variant="outlined"
                style={CloseButtonProps}
                onClick={onClose}
              >
                Close
              </Button>
            </Box>
          </Box>
        </ModalWindow>
      </Modal>
    </Box>
  );
};
