import React from 'react';
import { Avatar } from '@mui/material';
import { colorPalette } from 'theme';
import { Constants } from 'utilities/constants';
import ideaNftPng from 'assets/icons/ideaNft.png';
import { LogoMindminer } from 'components/icons/LogoMindminer';
import { LinearProgressWithPercentage } from '../../LinearProgressWithPercentage';
import {
  StyledContainer,
  StyledMainContainer,
  StyledPatentText,
  StyledPatentTextValue,
  StyledSubContainer,
  StyledTextMainContainer,
  StyledValueContainer
} from './StyledComponents';

export const InventionPatentContent: React.FC = () => {
  return (
    <StyledContainer>
      <StyledMainContainer>
        <StyledSubContainer>
          <StyledTextMainContainer>
            <StyledPatentTextValue>
              <Avatar src={ideaNftPng} />
              <StyledPatentText>
                {Constants.PURCHASE_PATENT_TOKEN}
              </StyledPatentText>
            </StyledPatentTextValue>
            <StyledValueContainer>
              {Constants.PATENT_VALUE}
            </StyledValueContainer>
          </StyledTextMainContainer>
        </StyledSubContainer>

        <StyledSubContainer>
          <StyledTextMainContainer>
            <StyledPatentTextValue>
              <LogoMindminer
                fill={colorPalette.amberOrange}
                width={36}
                height={36}
              />
              <StyledPatentText>{Constants.STAKE_INVENTION}</StyledPatentText>
            </StyledPatentTextValue>
            <StyledValueContainer>{Constants.STAKE_VALUE}</StyledValueContainer>
          </StyledTextMainContainer>
        </StyledSubContainer>
        <LinearProgressWithPercentage currentPercentage={8} />
      </StyledMainContainer>
    </StyledContainer>
  );
};
