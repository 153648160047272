import React, { useCallback, useState } from 'react';
import { CardMedia } from '@mui/material';
import 'react-multi-carousel/lib/styles.css';
import useRouter from 'hooks/useRouter';
import { Constants, VARIANT } from 'utilities/constants';
import {
  StyledGalleryContainer,
  StyledImageOverlay,
  StyledImageTitle,
  StyledMainImageContainer,
  StyledThumbnailContainer
} from './styledComponents';
import CarouselComponent from '../Carousal';

export interface ImageCarouselProps {
  url: string;
  title: string;
  key: string;
}

interface GalleryViewProps {
  images: ImageCarouselProps[];
}

const GalleryView: React.FC<GalleryViewProps> = ({ images }) => {
  const router = useRouter();

  const [selectedImage, setSelectedImage] = useState<ImageCarouselProps>(
    images[0]
  );

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2
    }
  };

  const handleImageClick = (key) => {
    router.push(`/${Constants.SOLUTIONS}/${key}`);
  };

  const renderCarouselItems = useCallback(() => {
    return images.map((image, index) => (
      <StyledThumbnailContainer
        key={image.key}
        onClick={() => setSelectedImage(image)}
        selected={selectedImage.url === image.url}
      >
        <CardMedia
          component={Constants.IMG}
          image={image.url}
          alt={`${Constants.THUMBNAIL} ${index + 1}`}
          sx={{
            objectFit: Constants.COVER,
            borderRadius: 1
          }}
        />
      </StyledThumbnailContainer>
    ));
  }, [images, selectedImage]);

  return (
    <StyledGalleryContainer>
      <StyledMainImageContainer
        onClick={() => handleImageClick(selectedImage.key)}
      >
        <CardMedia
          component={Constants.IMG}
          image={selectedImage.url}
          alt={Constants.SELECTED_SOLUTION}
          sx={{
            width: Constants.HUNDRED_PERCENT,
            height: Constants.HUNDRED_PERCENT,
            objectFit: Constants.COVER
          }}
        />
        <StyledImageOverlay>
          <StyledImageTitle>{selectedImage.title}</StyledImageTitle>
        </StyledImageOverlay>
      </StyledMainImageContainer>
      <CarouselComponent
        items={renderCarouselItems()}
        arrows={images.length > 5}
        responsive={responsive}
        containerClass={VARIANT.CAROUSEL_CONTAINER}
        itemClass={VARIANT.CAROUSEL_ITEM_PADDING_40_PX}
      />
    </StyledGalleryContainer>
  );
};

export default GalleryView;
