import * as React from 'react';
import {
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  tableContainerClasses,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material';
import ProblemIcon from 'assets/icons/problems.svg';
import SolutionIcon from 'assets/icons/solutions.svg';
import { colorPalette, useIsMediumScreen } from 'theme';
import { Application } from '../CardApplication';
import { Contest } from '../CardContest';
import { Problem } from '../CardProblem';
import { Concept, Product } from '../CardProduct';
import { Profile } from '../CardProfile';
import { Solution } from '../CardSolution';
import { UserAgreement } from '../common/Types';
import { Transaction } from '../MyWallet/tabs/TransactionsView';
import { ExpandedRowContent } from './expandedRowContent';

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  [`&.${tableContainerClasses.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    marginTop: 10
  },
  [theme.breakpoints.down('xs')]: {
    fontSize: 12,
    overflow: 'scroll'
  }
}));

const StyledTableCell = styled(TableCell)({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 12,
    verticalAlign: 'bottom',
    padding: '0.625rem 1.1875rem 0.625rem 0rem',
    background: colorPalette.lightShadow
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    verticalAlign: 'top',
    padding: '.9375rem .25rem .9375rem'
  }
});

interface Rows {
  items: Array<
    | Problem
    | Solution
    | Concept
    | Application
    | Contest
    | Product
    | Profile
    | Transaction
    | UserAgreement
  >;
  pinnedItems: Array<
    | Problem
    | Solution
    | Concept
    | Application
    | Contest
    | Product
    | Transaction
    | UserAgreement
  >;
  component: (
    icon: string | null,
    item:
      | Application
      | Concept
      | Contest
      | Problem
      | Product
      | Profile
      | Solution
      | UserAgreement
      | Transaction,
    type?: string,
    index?: number
  ) => { [key: string]: React.ReactNode };
}

export type TableViewProps = {
  expandedId?: string;
  headers: Array<string>;
  highlightedRowIndex?: number;
  page?: number;
  rows: Rows;
  rowsPerPage?: number;
  setExpandedId?: React.Dispatch<React.SetStateAction<boolean>>;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  setRowsPerPage?: React.Dispatch<React.SetStateAction<number>>;
  showPaginations?: boolean;
  totalRows?: number;
  type?: string;
  hasWalletFixedCellWidth?: boolean;
};

const TableView = ({
  expandedId,
  headers,
  highlightedRowIndex,
  page,
  rows,
  rowsPerPage,
  setExpandedId,
  setPage,
  setRowsPerPage,
  showPaginations = true,
  totalRows,
  type,
  hasWalletFixedCellWidth = false
}: TableViewProps) => {
  const highlightedRowRef = React.useRef<HTMLTableRowElement>(null);
  const isMediumScreen = useIsMediumScreen();

  React.useEffect(() => {
    if (highlightedRowIndex != null && highlightedRowRef.current) {
      highlightedRowRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      });
    }
  }, [highlightedRowIndex]);

  const icon = React.useMemo(() => {
    switch (type) {
      case 'problems':
        return ProblemIcon;
      case 'solutions':
        return SolutionIcon;
      default:
        return null;
    }
  }, [type]);

  const getRowsPerPage = (totalRows: number): Array<number> => {
    if (totalRows <= 5) {
      return [];
    } else if (totalRows <= 10) {
      return [5, 10];
    } else {
      return [5, 10, 15];
    }
  };

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const defaultCellWidths = ['5%', '5%', '5%', '5%', '5%'];
  const cellWidthsToUse = hasWalletFixedCellWidth
    ? defaultCellWidths
    : undefined;

  return (
    <StyledTableContainer>
      <Table
        sx={{
          display: 'table',
          flexDirection: isMediumScreen ? 'column' : 'row'
        }}
        aria-label="customized table"
      >
        <TableHead>
          <TableRow>
            {headers?.map((header, index) => (
              <StyledTableCell
                align="left"
                key={index}
                sx={{
                  width: cellWidthsToUse
                    ? cellWidthsToUse[index]
                    : index === 0
                      ? '3%'
                      : '70%'
                }}
              >
                {header}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.pinnedItems?.map((item, rowIndex) => (
            <React.Fragment key={`pinned-${rowIndex}`}>
              <TableRow selected={true} key={rowIndex}>
                {Object.keys(rows.component(icon, item, type))?.map(
                  (key, cellIndex) => (
                    <StyledTableCell
                      padding="none"
                      align="left"
                      key={cellIndex}
                    >
                      {rows.component(icon, item)[key]}
                    </StyledTableCell>
                  )
                )}
              </TableRow>

              {expandedId !== undefined && expandedId == item?.id && (
                <ExpandedRowContent
                  item={item}
                  expandedId={expandedId}
                  setExpandedId={setExpandedId}
                />
              )}
            </React.Fragment>
          ))}
        </TableBody>
        <TableBody
          sx={{ background: colorPalette.white, color: colorPalette.black }}
        >
          {rows?.items?.map((item, rowIndex) => (
            <React.Fragment key={rowIndex}>
              <TableRow
                ref={
                  rowIndex === highlightedRowIndex ? highlightedRowRef : null
                }
                selected={rowIndex === highlightedRowIndex}
                key={rowIndex}
              >
                {Object.keys(rows.component(icon, item, type))?.map(
                  (key, cellIndex) => (
                    <StyledTableCell
                      padding="none"
                      align="left"
                      key={cellIndex}
                    >
                      {rows.component(icon, item, type, rowIndex)[key]}
                    </StyledTableCell>
                  )
                )}
              </TableRow>

              {expandedId !== undefined && expandedId == item?.id && (
                <ExpandedRowContent
                  item={item}
                  expandedId={expandedId}
                  setExpandedId={setExpandedId}
                />
              )}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
      {showPaginations && rows?.items?.length > 0 && (
        <TablePagination
          component="div"
          count={totalRows}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={getRowsPerPage(totalRows)}
        />
      )}
    </StyledTableContainer>
  );
};

export default TableView;
