import React from 'react';
export const IdeaMapDetailsIcon = ({
  size = 49,
  color = '#632DDD',
  strokeWidth = 0.4
}: {
  size?: number;
  color?: string;
  strokeWidth?: number;
  className?: string;
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24.6377" cy="24.6992" r="23.8125" fill="#F0EEF4" />
      <g opacity="0.75">
        <path
          d="M13.8174 13.5761C13.2841 14.1094 12.9846 14.8326 12.9846 15.5867V33.8114C12.9846 34.5655 13.2841 35.2887 13.8174 35.822C14.3506 36.3552 15.0738 36.6547 15.8279 36.6547H22.8308C23.1175 36.6547 23.3925 36.5408 23.5952 36.3381C23.798 36.1354 23.9119 35.8604 23.9119 35.5736C23.9119 35.2869 23.798 35.0119 23.5952 34.8092C23.3925 34.6064 23.1175 34.4925 22.8308 34.4925H15.8279C15.452 34.4925 15.1468 34.1873 15.1468 33.8114V15.5867C15.1468 15.406 15.2185 15.2328 15.3463 15.1051C15.474 14.9773 15.6472 14.9056 15.8279 14.9056H33.4499C33.6306 14.9056 33.8038 14.9773 33.9316 15.1051C34.0593 15.2328 34.131 15.406 34.131 15.5867V23.1923C34.131 23.479 34.2449 23.754 34.4477 23.9567C34.6504 24.1595 34.9254 24.2734 35.2121 24.2734C35.4989 24.2734 35.7739 24.1595 35.9766 23.9567C36.1793 23.754 36.2932 23.479 36.2932 23.1923V15.5867C36.2932 14.8326 35.9937 14.1094 35.4605 13.5761C34.9272 13.0429 34.204 12.7434 33.4499 12.7434H15.8279C15.0738 12.7434 14.3506 13.0429 13.8174 13.5761Z"
          fill={color}
          stroke={color}
          strokeWidth={strokeWidth}
        />
        <path
          d="M26.8339 27.1952C27.4226 26.6065 28.2211 26.2758 29.0537 26.2758C29.8862 26.2758 30.6847 26.6065 31.2734 27.1952C31.8622 27.7839 32.1929 28.5824 32.1929 29.415C32.1929 30.2476 31.8622 31.046 31.2734 31.6348C30.6847 32.2235 29.8862 32.5542 29.0537 32.5542C28.2211 32.5542 27.4226 32.2235 26.8339 31.6348C26.2452 31.046 25.9144 30.2476 25.9144 29.415C25.9144 28.5824 26.2452 27.7839 26.8339 27.1952ZM24.9769 25.3383C23.8957 26.4195 23.2883 27.8859 23.2883 29.415C23.2883 30.9441 23.8957 32.4105 24.9769 33.4917C26.0581 34.573 27.5246 35.1804 29.0537 35.1804C30.5827 35.1804 32.0492 34.573 33.1304 33.4917C34.2116 32.4105 34.819 30.9441 34.819 29.415C34.819 27.8859 34.2116 26.4195 33.1304 25.3383C32.0492 24.257 30.5827 23.6496 29.0537 23.6496C27.5246 23.6496 26.0581 24.257 24.9769 25.3383Z"
          fill={color}
          stroke={color}
          strokeWidth={strokeWidth}
        />
        <path
          d="M35.8868 33.5541L35.8869 33.554L35.8818 33.5501L33.5499 31.805C33.3262 31.6276 33.0414 31.5457 32.7576 31.5775C32.4727 31.6094 32.2121 31.7532 32.0331 31.9772C31.8541 32.201 31.7714 32.4868 31.803 32.7716C31.8346 33.0566 31.9781 33.3172 32.2019 33.4964L32.2018 33.4965L32.207 33.5004L34.5389 35.2454C34.6492 35.3331 34.7756 35.3983 34.9109 35.4375C35.0473 35.477 35.1902 35.4892 35.3313 35.4735C35.4725 35.4577 35.6091 35.4144 35.7335 35.3458C35.8579 35.2773 35.9675 35.1849 36.0562 35.074C36.1449 34.9631 36.2108 34.8358 36.2503 34.6994C36.2898 34.5629 36.302 34.4201 36.2863 34.2789C36.2706 34.1378 36.2272 34.0011 36.1587 33.8768C36.0901 33.7524 35.9978 33.6427 35.8868 33.5541ZM18.0004 17.7285C17.7977 17.9312 17.6838 18.2062 17.6838 18.4929C17.6838 18.7796 17.7977 19.0546 18.0004 19.2574C18.2032 19.4601 18.4782 19.574 18.7649 19.574H30.5129C30.7997 19.574 31.0746 19.4601 31.2774 19.2574C31.4801 19.0546 31.594 18.7796 31.594 18.4929C31.594 18.2062 31.4801 17.9312 31.2774 17.7285C31.0746 17.5257 30.7997 17.4118 30.5129 17.4118H18.7649C18.4782 17.4118 18.2032 17.5257 18.0004 17.7285ZM18.0004 22.4277C17.7977 22.6304 17.6838 22.9054 17.6838 23.1921C17.6838 23.4789 17.7977 23.7538 18.0004 23.9566C18.2032 24.1593 18.4782 24.2732 18.7649 24.2732H23.4641C23.7508 24.2732 24.0258 24.1593 24.2286 23.9566C24.4313 23.7538 24.5452 23.4789 24.5452 23.1921C24.5452 22.9054 24.4313 22.6304 24.2286 22.4277C24.0258 22.2249 23.7508 22.111 23.4641 22.111H18.7649C18.4782 22.111 18.2032 22.2249 18.0004 22.4277Z"
          fill={color}
          stroke={color}
          strokeWidth={strokeWidth}
        />
      </g>
    </svg>
  );
};

export default IdeaMapDetailsIcon;
