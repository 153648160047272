import { EventEmitter } from 'events';
import Cookies from 'universal-cookie';
import FetchUtils from 'fetchUtils';
import { TagInfo } from 'components/CardTag';
import Config from 'config/config';
import { Picture } from 'helpers';
import { setToken } from 'helpers/common';

let API_URL = Config.NEXT_PUBLIC_API_URL;
if (typeof window === 'undefined') {
  // SSR
  API_URL = Config.NEXT_PUBLIC_API_URL_IN;
}
const TOKEN_NAME = 'token';

type SubscriptionOption = {
  id: string | number;
  type: string;
  price: string;
};

export type BillingAddressType = {
  _id: string | number;
  city: string;
  country: string;
  line1: string;
  line2: string;
  postalCode: string;
  state: string;
};

export type Profile = {
  attrs: Record<string, string>;
  balance: number;
  billingAddresses: BillingAddressType[];
  coins?: number;
  credits: number;
  creditsProgress: number;
  downloadTemplates?: string[];
  earned: number;
  email: string;
  files?: Array<Picture>;
  firstName?: string;
  id: string | number;
  ideaPoints?: number;
  inventorAddress?: string;
  inventorAddressCity?: string;
  inventorAddressState?: string;
  inventorAddressZip?: string;
  inviteUrl?: string;
  isAdmin: boolean;
  isApprover: boolean;
  isChallengeOwner: boolean;
  isFirstLogin?: boolean;
  isLawyer: boolean;
  isTagModerator: boolean;
  key: string;
  lastName?: string;
  location?: string;
  locationTags?: Array<string>;
  locationTagsInfo?: Array<TagInfo>;
  roles: string[];
  school?: string;
  schoolTags?: Array<string>;
  schoolTagsInfo?: Array<TagInfo>;
  subscription?: string;
  subscriptionTag?: string;
  tags?: Array<string>;
  tagsInfo?: Array<TagInfo>;
  termsAgree?: boolean;
  userData?: {
    STRIPE_PUB_KEY?: string;
    isChallengeOwner?: boolean;
    subscr?: { type?: string };
    prices?: Array<SubscriptionOption>;
    tags?: Array<{ id: string | number; name: string }>;
    moderator?: Array<{ id: string | number; name: string }>;
    following?: Array<{ id: string | number; type: string }>;
  };
  username?: string;
  walletAddress: string;
  workplace?: string;
  workplaceTags?: Array<string>;
  workplaceTagsInfo?: Array<TagInfo>;
  isOpenCtaModal?: boolean;
};

export class AuthProvider {
  private cookies!: Cookies;
  private events: EventEmitter;

  profile: Profile | undefined;
  private user: undefined;
  private error: Error | undefined;

  private jwtToken = '';
  private skipLocalInitCookies = false;

  private oauthInfo = {};

  private isRefreshing: Promise<any> | null = null;

  constructor() {
    this.events = new EventEmitter();

    if (typeof window !== 'undefined') {
      this.cookies = new Cookies();

      // @ts-ignore
      window.__auth = this;
    }
  }

  private async getUser() {
    if (this.isRefreshing) {
      return this.isRefreshing;
    }
    this.isRefreshing = Promise.resolve()
      .then(() => {
        return;
      })
      .then((token) => {})
      .then(() => this.loadUserInfo())
      .then((res) => {
        this.isRefreshing = null;
        return res;
      })
      .catch((err) => {
        this.isRefreshing = null;
        throw err;
      });
    return this.isRefreshing;
  }

  private async loadUserInfo(): Promise<undefined> {
    if (this.user) {
      return this.user;
    }
    try {
      let metadata: any;

      const token = this.getCookiesToken();
      if (token) {
        metadata = {};
      } else {
        try {
          metadata = {};
        } catch (err) {
          /* empty */
        }
      }

      if (metadata) {
        this.user = metadata;
        const profile = await this.loadProfileInfo(metadata);
        if (profile) {
          profile.isAdmin = AuthProvider.isAdmin(profile);
          profile.isApprover = AuthProvider.isApprover(profile);
          profile.isLawyer = AuthProvider.isLawyer(profile);
          if (profile.isAdmin) {
            profile.isApprover = false;
            profile.isLawyer = false;
          }
          profile.isTagModerator = AuthProvider.isTagModerator(profile);
          profile.attrs = this.oauthInfo || {};
        }
        this.profile = profile;
      } else {
        this.user = undefined;
        this.profile = undefined;
      }
    } catch (err) {
      // console.error(err);
      this.user = undefined;
      this.profile = undefined;
    }
    return this.user;
  }

  async loadProfileInfo(data?: any): Promise<Profile | undefined> {
    const { fetchJson } = FetchUtils;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');

    const jwtToken = await this.getJwtToken();
    setToken(jwtToken);

    if (jwtToken) {
      headers.set('Authorization', `Bearer ${jwtToken}`);
    }
    let profile;
    try {
      profile = await fetchJson(`${API_URL}/auth/validate`, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({ ...data, ...this.oauthInfo }),
        headers
      });
    } catch (e) {
      this.user = undefined;
    }
    return profile;
  }

  private triggerCallbacks() {
    this.events.emit('profile', this.profile);
  }

  private isLocal() {
    const isLocal =
      typeof window !== undefined && window.location.hostname === 'localhost';
    return isLocal;
  }

  async loadBalance(): Promise<{
    balance: number;
    earned: number;
    credits: number;
  }> {
    const { fetchJson } = FetchUtils;
    if (!this.user) {
      return { balance: 0, earned: 0, credits: 0 };
    }
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');

    const jwtToken = await this.getJwtToken();
    if (jwtToken) {
      headers.set('Authorization', `Bearer ${jwtToken}`);
    }

    let data = { balance: 0, earned: 0, credits: 0 };
    try {
      data = await fetchJson(`${API_URL}/profiles/my/balance`, {
        method: 'GET',
        headers
      });
    } catch (e) {
      console.error(e);
    }

    return data;
  }

  private getCookiesToken() {
    const token = this.cookies.get(TOKEN_NAME);
    if (token && token !== 'undefined') {
      return token;
    } else {
      return '';
    }
  }

  private setToken(token: string) {
    if (this.isLocal()) {
      setToken(token);
      this.skipLocalInitCookies = true;
    } else {
      this.jwtToken = token;
    }
  }

  private updateToken() {
    if (this.isLocal()) {
      this.setToken(this.jwtToken);
    } else {
      this.jwtToken = '';
    }
  }

  async getJwtToken(): Promise<string> {
    const token = this.getCookiesToken();

    if (token) {
      return token;
    } else {
      try {
        console.log('Try refresh token');
        const token = '';
        this.jwtToken = token;
        return token;
      } catch (err) {
        return '';
      }
    }
  }

  on(eventName: string, callback: (...args: Array<any>) => void): void {
    this.events.on(eventName, callback);
  }

  off(eventName: string, callback: (...args: Array<any>) => void): void {
    this.events.off(eventName, callback);
  }

  emitAfterLoginPopup(popup: string) {
    this.events.emit('afterLoginPopup', popup);
  }

  async login(params: {
    provider?: string;
    email?: string;
    password?: string;
  }): Promise<any> {
    const { provider, email, password } = params;
    if (typeof window !== 'undefined') {
      window.localStorage['beforeLoginPath'] = window.location.pathname;
    }
    try {
      if (provider) {
        await new Promise((resolve) => setTimeout(resolve, 5000));
      } else {
        await this.getUser();
        this.triggerCallbacks();
        window.location.replace('/');
      }
    } catch (err) {
      // @ts-ignore
      this.error = err;
      this.triggerCallbacks();
    }
    return this.profile;
  }

  async logout(): Promise<void> {
    try {
      this.user = undefined;
      this.profile = undefined;
      this.oauthInfo = {};
      this.cookies.remove(TOKEN_NAME, {
        path: '/'
      });
      this.triggerCallbacks();
    } catch (err) {
      // @ts-ignore
      this.error = err;
    }
  }

  async checkAuth(): Promise<boolean> {
    await this.getUser();
    return this.user ? Promise.resolve(true) : Promise.reject();
  }

  async checkError(params: Response): Promise<void> {
    const { status } = params;
    if (status === 401 || status === 403) {
      return Promise.reject();
    }
    return Promise.resolve();
  }

  async getPermissions(): Promise<Profile | undefined> {
    await this.getUser();
    return this.profile;
  }

  static isAdmin = (profile: Profile) => {
    return (
      ((profile || {}).roles || []).indexOf(AuthProvider.roles.ADMIN) !== -1
    );
  };

  static isApprover = (profile: Profile) => {
    return (
      ((profile || {}).roles || []).indexOf(AuthProvider.roles.APPROVER) !== -1
    );
  };

  static isLawyer = (profile: Profile) => {
    return (
      ((profile || {}).roles || []).indexOf(AuthProvider.roles.LAWYER) !== -1
    );
  };

  static isTagModerator = (profile: Profile) => {
    return !!(
      profile.userData &&
      profile.userData.moderator &&
      profile.userData.moderator.length
    );
  };

  static isChallengeOwner = (profile: Profile) => {
    return profile.userData && profile.userData.isChallengeOwner;
  };

  static roles = {
    ADMIN: 'admins',
    APPROVER: 'approvers',
    LAWYER: 'lawyers'
  };
}

export const auth = new AuthProvider();

export default auth;
