import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import productImage from 'assets/placeholder/invention.svg';
import { Contest } from 'components/CardContest';
import { Problem } from 'components/CardProblem';
import { Solution } from 'components/CardSolution';
import PsButton from 'components/common/PsButton';
import { PsFormControl } from 'components/common/PsFormControl';
import { PsOptionType, PsSelect } from 'components/common/PsSelect';
import PsSelectedList from 'components/common/PsSelectedList';
import { UploadIcon } from 'components/icons/UploadIcon';
import AuthContext from 'contexts/AuthContext';
import ModalDataContext from 'contexts/ModalDataContext';
import Modal from 'modals/Modal';
import Actions from 'redux-state/actions';
import { getTags } from 'redux-state/home/actions';
import { GetTags } from 'redux-state/home/selectors';
import { editProfileItem } from 'redux-state/profile/actions';
import { PsTheme } from 'theme';
import { makeStyles } from 'tss-react/mui';

const StyledMainBox = styled(Box)({
  backgroundColor: '#fff',
  borderRadius: '8px',
  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
  display: 'flex',
  flexDirection: 'column',
  height: 'auto',
  padding: '20px',
  paddingLeft: '62.5px',
  paddingTop: '40px',
  width: '610px'
});

const StyledHeaderTypography = styled(Typography)({
  color: '#333!important',
  fontSize: '23px!important',
  fontWeight: '600!important',
  paddingBottom: '0px',
  textAlign: 'center',
  width: '489px'
});

const StyledUploadBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  gap: '4px',
  marginTop: '6px',
  paddingBottom: '0px',
  width: '100 %'
});

const StyledImageTextTypography = styled(Typography)({
  color: '#333!important',
  fontSize: '14px!important',
  fontWeight: '700!important',
  width: '55%'
});

const StyledUploadTextButton = styled(Button)({
  color: '#632DDD',
  fontWeight: '600!important',
  textTransform: 'none'
});

const StyledUploadIcon = styled(UploadIcon)({
  marginRight: '6px'
});

const StyledBodyBox = styled(Box)({
  color: '#666',
  fontSize: '14px',
  marginBottom: '4px',
  overflow: 'hidden'
});

const StyledImage = styled('img')({
  borderRadius: '8px',
  maxHeight: '100%',
  maxWidth: '100%'
});

const StyledBold = styled('b')({
  fontSize: '16px!important',
  fontWeight: '700!important'
});

const StyledDescriptionTypography = styled(Typography)({
  color: '#333!important',
  fontSize: '14px!important',
  fontWeight: '700!important',
  paddingBottom: '3px',
  width: '50 %'
});

const StyledDescriptionFieldText = styled(TextField)({
  borderRadius: '8px',
  width: '489px'
});

const StyledPsFormControl = styled(PsFormControl)({
  marginTop: '10px!important'
});

const StyledTagsTypography = styled(Typography)({
  color: '#333',
  fontSize: '14px!important',
  fontWeight: '700!important',
  width: '489px'
});

const StyledPsSelect = styled(PsSelect)({
  marginTop: '-12px!important',
  width: '489px'
});

const StyledFooterBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  gap: '4px',
  justifyContent: 'space-between',
  marginBottom: '10px',
  marginLeft: '20px',
  marginTop: '5px',
  width: '460px'
});

const StyledCancelButton = styled(PsButton)({
  backgroundColor: '#E4CCFF!important',
  borderRadius: '30px!important',
  color: '#632DDD!important',
  fontSize: '14px!important',
  fontWeight: 'bold',
  height: '45px',
  marginRight: '8px',
  outline: 'none',
  padding: '8px 0px!important',
  width: '140px'
});

const StyledSaveButton = styled(PsButton)({
  borderRadius: '30px!important',
  color: '#FFFFFF',
  fontSize: '14px !important',
  fontWeight: 'bold',
  height: '45px',
  marginRight: '8px',
  outline: 'none',
  padding: '8px 0px!important',
  width: '140px'
});

const useStyles = makeStyles()((theme) => {
  const psTheme = theme as PsTheme;
  return {
    modalRoot: {
      maxHeight: '100vh',
      overflow: 'auto',
      paddingBottom: 50,
      paddingTop: 20
    },
    modalPaper: {
      backgroundColor: psTheme.palette.background.paper,
      borderRadius: 8,
      margin: '0 auto',
      outline: 'none',
      padding: '0px 0px',
      position: 'relative',
      width: '610px'
    }
  };
});

interface EditModalProps {
  data: Problem | Solution | Contest;
  type: string;
  onClose: () => void;
  openEditModal: boolean;
  title: string;
  pagination?: {
    page: number;
    perPage: number;
  };
}

const EditModal: React.FC<EditModalProps> = ({
  onClose,
  data,
  title,
  openEditModal,
  type,
  pagination
}) => {
  const { user } = useContext(AuthContext);
  const { values } = useContext(ModalDataContext);
  const getTagsValue = GetTags();

  const dispatch = useDispatch();
  const { classes } = useStyles();
  const { getRootProps } = useDropzone({
    multiple: false,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/gif': ['.gif'],
      'image/svg+xml': ['.svg']
    }
  });

  const [description, setDescription] = useState<string>('');
  const [tags, setTags] = useState<Array<PsOptionType>>([]);
  const [tagsValue, setTagsValue] = useState<string>('');
  const [selectedTags, setSelectedTags] = useState<Array<string>>([]);

  useEffect(() => {
    if (openEditModal) {
      dispatch(getTags({}));
    }
  }, [dispatch, openEditModal]);

  useEffect(() => {
    if (data.body) {
      setDescription(data.body);
    }
  }, [data.body, openEditModal]);

  useEffect(() => {
    const transformedTags = getTagsValue.map((tag) => ({
      value: tag.id.toString(),
      label: tag.name || ''
    }));
    setTags(transformedTags);

    if (Array.isArray(data.tags) && data.tags.length) {
      const tagsString = data.tags.join(',');
      setTagsValue(tagsString);
      setSelectedTags(data.tags.map((tag) => tag.toString()));
    }
  }, [getTagsValue, data.tags]);

  const onTagsChange = useCallback(
    (event) => {
      const { value } = event.target;
      const newSelectedTags = value ? value.split(',') : [];
      const initialTags =
        Array.isArray(data.tags) && data.tags.length
          ? data.tags.map((tag) => tag.toString())
          : [];
      const remainingInitialTags = initialTags.filter((tag) =>
        newSelectedTags.includes(tag)
      );
      const updatedSelectedTags = remainingInitialTags.concat(
        newSelectedTags.filter((tag) => !initialTags.includes(tag))
      );

      setSelectedTags(updatedSelectedTags);
      setTagsValue(updatedSelectedTags.join(','));
    },
    [data.tags]
  );

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleSaveChanges = () => {
    if (user) {
      const updatedtags = { tags: selectedTags };
      dispatch(
        editProfileItem({
          type,
          id: data._id,
          profileId: data.owner,
          pagination,
          data: {
            body: description,
            ...updatedtags
          }
        })
      );
    } else {
      dispatch(Actions.openAuthModal(true));
    }
    onClose();
  };

  return (
    <Modal
      open={openEditModal}
      onClose={onClose}
      className={classes.modalPaper}
      modalRootClassName={classes.modalRoot}
    >
      <StyledMainBox>
        <StyledHeaderTypography>
          Edit {data.title} {title}
        </StyledHeaderTypography>
        <StyledUploadBox>
          <StyledImageTextTypography>Image</StyledImageTextTypography>
          <StyledUploadTextButton {...getRootProps()}>
            <StyledUploadIcon />
            Upload from your files
          </StyledUploadTextButton>
        </StyledUploadBox>
        <StyledBodyBox>
          <StyledImage src={productImage} />
        </StyledBodyBox>
        <StyledDescriptionTypography>Description</StyledDescriptionTypography>
        <StyledDescriptionFieldText
          rows={4}
          multiline
          value={description}
          onChange={handleDescriptionChange}
        ></StyledDescriptionFieldText>
        <Box>
          {values.introProblemTags ? (
            <Typography>{values.introProblemTags}</Typography>
          ) : null}

          <StyledPsFormControl
            name="tagsProblem"
            placeholder="Choose one or more tags"
            label={
              <StyledTagsTypography>
                Choose Tags for the <StyledBold>{data.title} </StyledBold>
                {title?.toLowerCase()}
              </StyledTagsTypography>
            }
          >
            <StyledPsSelect
              isMulti
              maxMenuHeight={270}
              onChange={onTagsChange}
              options={tags}
              value={tagsValue}
              valueObj={tags.filter((tag) => selectedTags.includes(tag.value))}
            />
          </StyledPsFormControl>
          <PsSelectedList
            options={tags}
            value={tagsValue}
            onChange={onTagsChange}
          />
        </Box>
        <StyledFooterBox>
          <StyledCancelButton onClick={onClose}>Cancel</StyledCancelButton>
          <StyledSaveButton onClick={handleSaveChanges}>
            Save Changes
          </StyledSaveButton>
        </StyledFooterBox>
      </StyledMainBox>
    </Modal>
  );
};

export default EditModal;
