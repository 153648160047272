/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Box, Skeleton, Tooltip } from '@mui/material';
import { Profile } from 'components/CardProfile';
import { ContainedStarIcon } from 'components/icons/ContainedStarIcon';
import { handleTagClick } from 'helpers/common';
import { AddNewCompany } from 'modals/AddNewCompany';
import ConfirmationModal from 'modals/ConfirmationModal/ConfirmationModal';
import { ModalSmall } from 'modals/ModalSmall';
import Actions from 'redux-state/actions';
import {
  GetOwnedCommunity,
  GetOwnedCommunityLoader
} from 'redux-state/selectors';
import { Constants, VARIANT } from 'utilities/constants';
import { MainContainer, TitleText } from './styledComponents';

export const OwnedCommunity: React.FC<{
  user: Profile;
  isCurrentUser: boolean;
}> = ({ user, isCurrentUser }) => {
  const dispatch = useDispatch();
  const ownedCommunity = GetOwnedCommunity();
  const loading = GetOwnedCommunityLoader();
  const [showCompanyCreationModal, setShowCompanyCreationModal] =
    useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);

  const showAddNewCompanyModal = () => {
    setShowCompanyCreationModal(true);
  };

  const handleRemoveCommunityOwner = () => {
    if (ownedCommunity?._id && (user?.id ?? user?._id)) {
      dispatch(
        Actions.editTag({
          id: ownedCommunity?._id,
          data: { owner: null, name: ownedCommunity?.name },
          type: Constants.TAGS,
          userId: user?.id ?? user?._id
        })
      );
    }
  };

  const openConfirmationModal = () => {
    setShowConfirmationModal(true);
  };

  const closeConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  useEffect(() => {
    if (user?.id) {
      dispatch(Actions.getOwnedCommunity({ userId: user.id }));
    }
  }, [dispatch, user?.id]);

  const toolTipText = isCurrentUser
    ? Constants.YOUR_COMMUNITY
    : `${user?.username}'s ${Constants.C_COMMUNITY}`;

  const CommunityOwnership = () => {
    return (
      <MainContainer>
        {ownedCommunity ? (
          <Box>
            <Tooltip title={toolTipText} placement={VARIANT.BOTTOM}>
              <TitleText onClick={() => handleTagClick(ownedCommunity)}>
                {ownedCommunity.name}
              </TitleText>
            </Tooltip>
          </Box>
        ) : loading ? (
          <Skeleton variant={VARIANT.TEXT} width={100} />
        ) : isCurrentUser ? (
          <TitleText onClick={showAddNewCompanyModal}>
            {Constants.BECOME_COMMUNITY_LEADER}
          </TitleText>
        ) : (
          ''
        )}
        {(ownedCommunity || loading || isCurrentUser) && (
          <ContainedStarIcon width="1.5625rem" height="1.5625rem" />
        )}
        {isCurrentUser && user?.id === ownedCommunity?.owner && (
          <Tooltip title={Constants.REMOVE_OWNER}>
            <RemoveCircleOutlineIcon
              onClick={openConfirmationModal}
              color={VARIANT.PRIMARY}
              width="1.5625rem"
              height="1.5625rem"
            />
          </Tooltip>
        )}
      </MainContainer>
    );
  };

  return (
    <>
      <CommunityOwnership />
      {showCompanyCreationModal && (
        <ModalSmall
          isOpen={showCompanyCreationModal}
          onClose={() => setShowCompanyCreationModal(false)}
        >
          <AddNewCompany
            setOpen={setShowCompanyCreationModal}
            initialValue=""
          />
        </ModalSmall>
      )}
      <ModalSmall
        isOpen={showConfirmationModal}
        onClose={closeConfirmationModal}
      >
        <ConfirmationModal
          action={Constants.REMOVE}
          title={`${Constants.YOURSELF_AS_OWNER} ${ownedCommunity?.name} ${Constants.COMMUNITY}`}
          item={ownedCommunity}
          onDelete={handleRemoveCommunityOwner}
          onClose={closeConfirmationModal}
        />
      </ModalSmall>
    </>
  );
};
