import React, { Dispatch, SetStateAction } from 'react';
import { MindMinerAiIcon } from 'components/icons/MindMinerAiIcon';
import { GetUser } from 'redux-state/selectors';
import { MESSAGE_ROLES, MESSAGE_TYPES, VARIANT } from 'utilities/constants';
import { Message } from './interfaces';
import { AiGeneratedItems } from './MessageViews/AiGeneratedItem';
import { EditSolution } from './MessageViews/EditSolution';
import { SolutionRelationship } from './MessageViews/SolutionsRelationship';
import { ItemDetails } from './MessageViews/ItemDetails';
import {
  BotAvatarWrapper,
  ChipWrapper,
  MainMsgBox,
  MessageText,
  MessageWrapper,
  StyledAvatar,
  StyledChip,
  UserAvatarWrapper,
  WhiteSpace
} from './styledComponents';

interface ChatMessageProps {
  expandedNode?: {
    key: string;
    msgId: string;
  };
  message: Message;
  setMessages?: Dispatch<SetStateAction<Message[]>>;
}

export const ChatMessage: React.FC<ChatMessageProps> = ({
  expandedNode,
  message,
  setMessages
}) => {
  const user = GetUser();

  const isUser = message.role === MESSAGE_ROLES.USER;

  const MessageMarkup = () => {
    switch (message.type) {
      case MESSAGE_TYPES.ACTION:
        return (
          <ChipWrapper>
            {message.data.map((chip) => (
              <StyledChip
                key={chip.id}
                label={chip.text}
                variant={VARIANT.OUTLINED}
                onClick={() => message.onClick(chip)}
              />
            ))}
          </ChipWrapper>
        );
      case MESSAGE_TYPES.TEXT:
        return <MessageText>{message.text}</MessageText>;
      case MESSAGE_TYPES.AI_PROBLEMS:
      case MESSAGE_TYPES.PRODUCT:
        return (
          <AiGeneratedItems message={message} expandedNode={expandedNode} />
        );
      case MESSAGE_TYPES.EDIT_SOLUTION:
        return <EditSolution message={message} setMessages={setMessages} />;
      case MESSAGE_TYPES.SOLUTION_RELATIONSHIP:
        return (
          <SolutionRelationship message={message} setMessages={setMessages} />
        );
      case MESSAGE_TYPES.ITEM_DETAILS:
        return <ItemDetails item={message.data} setMessages={setMessages} />;
      default:
        return null;
    }
  };

  return (
    <MainMsgBox isUser={isUser} type={message.type}>
      {!isUser ? (
        <BotAvatarWrapper>
          <StyledAvatar size="2.3125rem">
            <MindMinerAiIcon width="2.3125rem" height="2.3125rem" />
          </StyledAvatar>
        </BotAvatarWrapper>
      ) : (
        <WhiteSpace />
      )}

      <MessageWrapper
        isUser={isUser}
        showBackground={message.type !== MESSAGE_TYPES.ACTION}
      >
        <MessageMarkup />
      </MessageWrapper>
      {isUser ? (
        <UserAvatarWrapper>
          <StyledAvatar size="2.5rem" src={user?.files[0]?.url} />
        </UserAvatarWrapper>
      ) : (
        <WhiteSpace />
      )}
    </MainMsgBox>
  );
};
