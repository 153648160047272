import Actions from 'redux-state/actions';

export const updateConceptSolutionsRelationship = (
  currentConcept,
  relation: { solution1: string; solution2: string; relationship: string }
) => {
  const { solutionsRelationship } = currentConcept;
  const { solution1, solution2, relationship } = relation;

  const existingIndex = solutionsRelationship.findIndex(
    (rel) =>
      (rel.solution1 === solution1 && rel.solution2 === solution2) ||
      (rel.solution1 === solution2 && rel.solution2 === solution1)
  );

  const newRelationship = {
    solution1,
    solution2,
    relationship
  };

  const updatedSolutionsRelationship =
    existingIndex !== -1
      ? solutionsRelationship.map((rel, index) =>
          index === existingIndex ? newRelationship : rel
        )
      : [...solutionsRelationship, newRelationship];

  const conceptUpdateObj = {
    solutionsRelationship: updatedSolutionsRelationship
  };

  return conceptUpdateObj;
};

export const handleSolutionDelete = (
  solutionKey,
  selectedConcept,
  dispatch,
  user
) => {
  const selected = selectedConcept.solutions
    .filter((solution) => solution.key !== solutionKey)
    .map(({ id }) => id);

  const problems = selectedConcept.problems
    .filter((problem) =>
      problem?.children?.some((child: { id?: string; ref?: string }) =>
        selected?.includes(child.id)
      )
    )
    .map(({ id }) => id);
  if (user) {
    dispatch(Actions.togLoadSolution(true));
    dispatch(
      Actions.updateConcept(selectedConcept.id, {
        selected,
        problems
      })
    );
  }
};

export const markSolutionAsAdded = (
  problemKey,
  solutionKey,
  msgId,
  setMessages
) => {
  setMessages((prevMessages) => {
    return prevMessages.map((message) => {
      if (message.id !== msgId) return message;

      const updatedData = message.data.map((item) => {
        if (item.key !== problemKey) return item;

        const updatedChildren = item.children.map((child) =>
          child.key === solutionKey ? { ...child, isClicked: true } : child
        );

        return { ...item, children: updatedChildren };
      });

      return { ...message, data: updatedData };
    });
  });
};

export const findProblemByChild = (childKey, msgId, messages) => {
  for (const message of messages) {
    if (message.id === msgId) {
      for (const item of message.data) {
        if (item.children) {
          const solution = item.children.find(
            (child) => child.key === childKey
          );
          if (solution) {
            return item;
          }
        }
      }
    }
  }
  return null;
};
