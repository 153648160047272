import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, LinearProgress, SelectChangeEvent } from '@mui/material';
import { useDispatch } from 'react-redux';
import Actions from 'redux-state/actions';
import AuthContext from 'contexts/AuthContext';
import {
  GetTagUsers,
  GetTagUsersCount,
  GetTagUsersLoader
} from 'redux-state/selectors';
import { Constants } from 'utilities/constants';
import {
  LeaderBoardContainer,
  LeaderBoardHeaderContainer,
  LeaderBoardModalWrapper,
  StyledHeadingText,
  StyledIdeacoinsBox,
  StyledIndexText,
  StyledTableWrapper,
  StyledTotalBox,
  StyledTypographyCell
} from './StyledComponents';
import FilterBy from '../common/FilterBy';
import IdeaCoins from '../common/IdeaCoins';
import { getHeaders } from '../TableView/getHeaders';
import { ImageView } from '../ImageView';
import { LinkView } from '../LinkView';
import { Tag } from '../CardTag';
import TableView from '../TableView';

interface LeaderBoardViewProps {
  handleClose: () => void;
  open: boolean;
  tag?: Tag;
}

const LeaderBoardView: React.FC<LeaderBoardViewProps> = ({
  handleClose,
  open,
  tag
}) => {
  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);
  const tagUsers = GetTagUsers();
  const tagUsersCount = GetTagUsersCount();
  const getTagUsersLoader = GetTagUsersLoader();

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [filterType, setFilterType] = useState<string>(Constants.ALL);

  const pagination = useMemo(
    () => ({
      page: page,
      perPage: rowsPerPage
    }),
    [page, rowsPerPage]
  );

  useEffect(() => {
    if (open) {
      dispatch(
        Actions.getTagUsers({
          filterType: filterType,
          pagination,
          tagId: tag?.id
        })
      );
    }
  }, [dispatch, filterType, open, pagination, tag?.id, tagUsersCount]);

  const filterItems = useMemo(
    () => [
      { label: Constants.ALL, value: Constants.ALL, icon: null },
      {
        label: Constants.COMMUNITY_MEMBERS,
        value: Constants.COMMUNITY,
        icon: null
      },
      {
        label: Constants.LOCAL_MEMBERS,
        value: Constants.LOCAL,
        icon: null
      }
    ],
    []
  );

  const handleChange = (event: SelectChangeEvent<any>) => {
    setFilterType(event.target.value);
    setPage(0);
  };

  const headers: Array<string> = useMemo(() => {
    return getHeaders(Constants.LEADER_BOARD, user, true);
  }, [user]);

  const getCells = useMemo(() => {
    return (icon, item, index) => {
      const baseCells = {
        count: <StyledIndexText variant="body2">{index + 1}</StyledIndexText>,
        tag: (
          <ImageView
            iconImage={icon}
            id={item?.userId?.key}
            isTagView={true}
            placeholderImage={icon}
            tagImage={item?.pictures?.[0]}
            title={item.userId?.email}
            type={Constants.PROFILES}
          />
        ),
        ideaPoints: (
          <StyledTypographyCell>{item.userId?.ideaPoints}</StyledTypographyCell>
        ),
        ideaCoins: (
          <StyledIdeacoinsBox>
            <IdeaCoins walletAddress={item?.userId?.walletAddress} />
          </StyledIdeacoinsBox>
        ),
        type: <StyledTypographyCell>{item.userType}</StyledTypographyCell>,
        total: (
          <StyledTotalBox>
            <LinkView
              height="2.1875rem"
              id={item?.userId?.key}
              title={item.solutionCount}
              type={Constants.PROFILES}
            />
          </StyledTotalBox>
        )
      };

      return baseCells;
    };
  }, []);

  const rows = {
    component: (icon, item, type, index) => {
      return getCells(icon, item, index);
    },
    items: tagUsers,
    pinnedItems: []
  };

  return (
    <LeaderBoardModalWrapper onClose={handleClose} open={open}>
      <LeaderBoardContainer>
        <LeaderBoardHeaderContainer>
          <StyledHeadingText>{`${tag?.name} ${Constants.LEADER_BOARD}`}</StyledHeadingText>
          <Box sx={{ width: '40%' }}>
            <FilterBy
              filterType={filterType}
              handleChange={handleChange}
              items={filterItems}
            />
          </Box>
        </LeaderBoardHeaderContainer>
        {getTagUsersLoader && <LinearProgress />}
        <StyledTableWrapper>
          <TableView
            headers={headers}
            page={page}
            rows={rows}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            totalRows={tagUsersCount}
          />
        </StyledTableWrapper>
      </LeaderBoardContainer>
    </LeaderBoardModalWrapper>
  );
};

export default LeaderBoardView;
