import React from 'react';
export const WorkplaceIcon = ({
  size = 48,
  color = '#000',
  className
}: {
  size?: number;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color}
        d="M9.5 11q-.65 0-1.075-.425Q8 10.15 8 9.5q0-.65.425-1.075Q8.85 8 9.5 8h29.1q.65 0 1.075.425.425.425.425 1.075 0 .65-.425 1.075Q39.25 11 38.6 11Zm.25 29q-.65 0-1.075-.425-.425-.425-.425-1.075V27.1H7q-.7 0-1.15-.55-.45-.55-.3-1.25l2.2-10.1q.1-.55.525-.875Q8.7 14 9.2 14h29.65q.55 0 .95.325.4.325.5.875l2.2 10.1q.15.7-.3 1.25-.45.55-1.15.55H39.8v11.4q0 .65-.425 1.075Q38.95 40 38.3 40q-.65 0-1.075-.425-.425-.425-.425-1.075V27.1h-9.45v11.4q0 .65-.425 1.075Q26.5 40 25.85 40Zm1.5-3h13.1v-9.9h-13.1Zm-2.5-12.9H39.3Zm0 0H39.3L37.75 17H10.3Z"
      />
    </svg>
  );
};

export default WorkplaceIcon;
