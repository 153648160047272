import ProblemName, {
  defaultProblemTitle
} from 'modals/AddNewProblem/steps/ProblemName';
import ProblemTagsStep from 'modals/AddNewProblem/steps/ProblemTagsStep';
import FinilizeProblemStep from 'modals/AddNewProblem/steps/FinilizeProblemStep';

import SolutionName, {
  defaultSolutionTitle
} from 'modals/AddNewSolution/steps/SolutionName';
import SolutionTagsStep from 'modals/AddNewSolution/steps/SolutionTagsStep';
import FinilizeSolutionStep from 'modals/AddNewSolution/steps/FinilizeSolutionStep';

import ApplicationName, {
  defaultInventionTitle
} from 'modals/AddNewApplication/steps/ApplicationName';
import ApplicationTagsStep from 'modals/AddNewApplication/steps/ApplicationTagsStep';
import FinilizeApplicationStep from 'modals/AddNewApplication/steps/FinilizeApplicationStep';

import SelectType from 'modals/AddNewFullFlow/steps/SelectType';

import { isTitleValid } from '../../helpers';
import {
  ValidateFormType,
  FormStepConfig,
  AddFullFlowSteps
} from 'contexts/ModalDataContext';

export { defaultProblemTitle, defaultSolutionTitle, defaultInventionTitle };

export const stepName = {
  PROBLEM_START: 'problemStart',
  PROBLEM_END: 'problemEnd',
  SOLUTION_START: 'solutionStart',
  SOLUTION_END: 'solutionEnd',
  APPLICATION_START: 'applicationStart',
  APPLICATION_END: 'applicationEnd',
  SELECT_TYPE: 'selectType'
};

export const ADD_FULL_FLOW_CONFIG: {
  [name: string]: FormStepConfig;
} = {
  [AddFullFlowSteps.ProblemName]: {
    Component: ProblemName,
    nextStep: AddFullFlowSteps.ProblemTags,
    name: stepName.PROBLEM_START,
    isNextStepBlocked: (data) => {
      return !data.titleProblem;
    },
    validate: (data): ValidateFormType => {
      if (!data.titleProblem) {
        return { success: false, errors: { titleProblem: 'Title required' } };
      }
      if (
        data.titleProblem &&
        !isTitleValid(data.titleProblem, defaultProblemTitle)
      ) {
        return {
          success: false,
          errors: {
            titleProblem: `Title should starts with "${defaultProblemTitle}"`
          }
        };
      }
      return { success: true };
    },
    backButtonTitle: 'Close'
  },
  [AddFullFlowSteps.ProblemTags]: {
    nextStep: AddFullFlowSteps.FinilizeProblem,
    Component: ProblemTagsStep,
    isNextStepBlocked: (data) => {
      if (data.tagsProblem.length === 0) return true;

      return false;
    },
    validate: (data): ValidateFormType => {
      if (data.tagsProblem) {
        return { success: true };
      }
      return { success: false, errors: { tags: 'required' } };
    }
  },
  [AddFullFlowSteps.FinilizeProblem]: {
    nextStep: AddFullFlowSteps.SolutionName,
    Component: FinilizeProblemStep,
    name: stepName.PROBLEM_END,
    isNextStepBlocked: (data) => {
      if (!data.descriptionProblem) return true;

      return false;
    },
    validate: (data): ValidateFormType => {
      if (data.descriptionProblem) {
        return { success: true };
      }
      return { success: false, errors: { description: 'required' } };
    },
    nextButtonTitle: 'Create'
  },
  [AddFullFlowSteps.SolutionName]: {
    Component: SolutionName,
    nextStep: AddFullFlowSteps.SolutionTags,
    name: stepName.SOLUTION_START,
    isNextStepBlocked: (data) => {
      return !data.titleSolution;
    },
    validate: (data): ValidateFormType => {
      if (!data.titleSolution) {
        return { success: false, errors: { titleSolution: 'Title required' } };
      }
      if (
        data.titleSolution &&
        !isTitleValid(data.titleSolution, defaultSolutionTitle)
      ) {
        return {
          success: false,
          errors: {
            titleSolution: `Title should starts with "${defaultSolutionTitle}"`
          }
        };
      }
      return { success: true };
    },
    backButtonTitle: 'Close'
  },
  [AddFullFlowSteps.SolutionTags]: {
    nextStep: AddFullFlowSteps.FinilizeSolution,
    Component: SolutionTagsStep,
    isNextStepBlocked: (data) => {
      if (data.tagsSolution.length === 0) return true;

      return false;
    },
    validate: (data): ValidateFormType => {
      if (data.tagsSolution) {
        return { success: true };
      }
      return { success: false, errors: { tags: 'required' } };
    }
  },
  [AddFullFlowSteps.FinilizeSolution]: {
    nextStep: AddFullFlowSteps.SelectType,
    Component: FinilizeSolutionStep,
    name: stepName.SOLUTION_END,
    isNextStepBlocked: (data) => {
      if (!data.descriptionSolution) return true;

      return false;
    },
    validate: (data): ValidateFormType => {
      if (data.descriptionSolution) {
        return { success: true };
      }
      return { success: false, errors: { description: 'required' } };
    },
    nextButtonTitle: 'Create'
  },
  [AddFullFlowSteps.SelectType]: {
    //nextStep: AddFullFlowSteps.SelectType,
    //isFinalStep: true,
    Component: SelectType,
    name: stepName.SELECT_TYPE,
    isNextStepBlocked: (data) => {
      if (!data.brainObject) return true;

      return false;
    },
    validate: (data): ValidateFormType => {
      if (data.brainObject) {
        return { success: true };
      }
      return { success: false, errors: { description: 'required' } };
    },
    backButtonTitle: 'Close'
  },
  [AddFullFlowSteps.ApplicationName]: {
    Component: ApplicationName,
    nextStep: AddFullFlowSteps.ApplicationTags,
    name: stepName.APPLICATION_START,
    isNextStepBlocked: (data) => {
      return !data.titleApplication || !data.mainProblem;
    },
    validate: (data): ValidateFormType => {
      if (!data.titleApplication) {
        return {
          success: false,
          errors: { titleApplication: 'Title required' }
        };
      }
      if (
        data.titleApplication &&
        !isTitleValid(data.titleApplication, defaultInventionTitle)
      ) {
        return {
          success: false,
          errors: {
            titleApplication: `Title should starts with "${defaultInventionTitle}"`
          }
        };
      }
      if (!data.mainProblem) {
        return {
          success: false,
          errors: {
            parentProblem: 'required'
          }
        };
      }
      return { success: true };
    }
  },
  [AddFullFlowSteps.ApplicationTags]: {
    Component: ApplicationTagsStep,
    nextStep: AddFullFlowSteps.FinilizeApplication,
    isNextStepBlocked: (data) => {
      return false;
    },
    validate: (data): ValidateFormType => {
      return { success: true };
    }
  },
  [AddFullFlowSteps.FinilizeApplication]: {
    isFinalStep: true,
    Component: FinilizeApplicationStep,
    name: stepName.APPLICATION_END,
    isNextStepBlocked: (data) => {
      return false;
    },
    validate: (data): ValidateFormType => {
      return { success: true };
    },
    nextButtonTitle: 'Finish'
  }
};
