import React from 'react';
import { UnlockYourRoyaltiesImage } from 'components/icons/UnlockYourRoyaltiesImage';
import {
  StyledContentBox,
  StyledContentTypography,
  StyledGetStartedButton,
  StyledHeaderTypography,
  StyledImageBox,
  StyledMainBox
} from './StyledComponents';

export const UnlockYourRoyalties = () => {
  return (
    <StyledMainBox>
      <StyledImageBox>
        <UnlockYourRoyaltiesImage />
      </StyledImageBox>
      <StyledContentBox>
        <StyledHeaderTypography>Unlock your</StyledHeaderTypography>
        <StyledHeaderTypography>royalties</StyledHeaderTypography>
        <StyledContentTypography>
          Lorem ipsum is a dummy text.
        </StyledContentTypography>
        <StyledGetStartedButton variant="outlined" color="primary">
          Get Started
        </StyledGetStartedButton>
      </StyledContentBox>
    </StyledMainBox>
  );
};
