import React from 'react';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  styled,
  Typography
} from '@mui/material';
import { colorPalette } from 'theme';

export const StyledSliderCard = styled(Card)({
  backgroundColor: colorPalette.white,
  borderRadius: '0.5rem',
  boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.2)',
  color: colorPalette.dimBlack,
  margin: '0 auto',
  maxWidth: '50rem',
  padding: '1.25rem',
  textAlign: 'left'
});

export const StyledSliderCardContent = styled(CardContent)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row'
});

export const StyledSliderInfoBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '25%'
});

export const StyledSliderInfoAvatar = styled(Avatar)({
  height: '5rem',
  marginBottom: '0.625rem',
  width: '5rem'
});

export const StyledSliderInfoName = styled(Typography)({
  fontSize: '1.0625rem',
  fontWeight: 400,
  marginTop: '0.625rem',
  opacity: '70%'
});

export const StyledSliderContentBox = styled(Box)({
  paddingLeft: '1.875rem',
  width: '75%'
});

export const StyledSliderContentTypography = styled(Typography)({
  fontSize: '1.125rem',
  fontWeight: 400
});
