/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';
import { Box, Grid, styled } from '@mui/material';
import ideaNftPng from 'assets/icons-new/ideaNFT.svg';
import EditButtonIcon from 'assets/icons/editIcon.svg';
import ideaCoinYellowIcon from 'assets/icons/ideaCoinYellow.svg';
import { ModalEditProfile } from 'components/CardProfile';
import { TagInfo } from 'components/CardTag';
import { ShareBtnSimple } from 'components/ShareBtn';
import { PsRecord } from 'contexts/DataContext';
import { Picture } from 'helpers';
import { getOwnedNfts } from 'helpers/blockchain';
import { GetPublicUser, GetUser } from 'redux-state/selectors';
import { useIsMediumScreen } from 'theme';
import { Constants, ERRORS } from 'utilities/constants';
import {
  CustomEarningsWrapper,
  EarningItem,
  EarningLabel,
  EarningNumber,
  EarningsContainer,
  EarningText,
  IconsBox,
  ImageBox,
  ImageContainer,
  MainBox,
  StyledBlock,
  StyledEarningContent,
  StyledEditCursor,
  StyledShareCursor
} from './StyledComponents';

export type Profile = PsRecord & {
  email?: string;
  employer?: string | number;
  files?: Array<Picture>;
  firstName?: string;
  instagramUrl?: string;
  inventorAddress?: string;
  inventorAddressCity?: string;
  inventorAddressState?: string;
  inventorAddressZip?: string;
  invited?: boolean;
  isOpenCtaModal?: boolean;
  key: string;
  lastName?: string;
  linkedinUrl?: string;
  location?: string;
  locationTags?: Array<string | number>;
  locationTagsInfo?: Array<TagInfo>;
  school?: string;
  schoolTags?: Array<string | number>;
  schoolTagsInfo?: Array<TagInfo>;
  share?: number;
  tags?: string[];
  tagsInfo?: Array<TagInfo>;
  termsAgree?: boolean;
  username?: string;
  workplace?: string;
  workplaceTags?: Array<string | number>;
  workplaceTagsInfo?: Array<TagInfo>;
  youtubeUrl?: string;
};

export type ProfileStatsItems = {
  inventions?: number;
  problems?: number;
  solutions?: number;
};

export type ProfileStatsRest = {
  campaigns?: number;
  claimed?: number;
  credits?: number;
  creditsProgress?: number;
  earned?: number;
  inWallet?: number;
  nft?: number;
  unrealized?: number;
};

export type ProfileStats = ProfileStatsItems & ProfileStatsRest;

export type CustomizedStatsViewProps = {
  count: number;
  iconUrl: string;
  onWithdraw?: () => void;
  type: string;
  withdraw?: boolean;
  handleActiveTab?: () => void;
};

const UserProfileCoverView = () => {
  const user = GetUser();
  const profile = GetPublicUser();
  const isMediumScreen = useIsMediumScreen();

  const [editOpened, setEditOpened] = useState<boolean>(false);
  const isCurrentUser = !!(user && (user?.id === profile?.id || user?.isAdmin));
  const [coverImageUrl, setCoverImageUrl] = useState(
    isCurrentUser && user?.files?.length > 1
      ? user?.files[1]?.url
      : profile?.files && profile?.files?.length > 1
        ? profile?.files[1]?.url
        : Constants.DEFAULT_COVER_IMAGE
  );

  useEffect(() => {
    const newCoverImageUrl =
      isCurrentUser && user?.files?.[1]?.url
        ? user?.files?.[1]?.url
        : profile?.files?.[1]?.url || Constants.DEFAULT_COVER_IMAGE;

    setCoverImageUrl(newCoverImageUrl);
  }, [profile?.files, user?.files, isCurrentUser]);

  const onEditOpen = useCallback(() => {
    setEditOpened(true);
  }, [setEditOpened]);

  const onEditClose = useCallback(() => {
    setEditOpened(false);
  }, [setEditOpened]);

  return (
    <MainBox>
      <Box>
        <Box>
          <Grid container spacing={3} style={{ display: 'flex' }}>
            <Grid item xs={12} style={{ flex: 1 }}>
              <ImageContainer>
                <ImageBox src={coverImageUrl} />
                <IconsBox>
                  {isCurrentUser && (
                    <StyledEditCursor
                      isMediumScreen={isMediumScreen}
                      marginRight={1}
                      marginLeft={2}
                      onClick={onEditOpen}
                    >
                      <img
                        width={20}
                        height={20}
                        src={EditButtonIcon}
                        alt="EditIcon"
                      />
                    </StyledEditCursor>
                  )}
                  {user ? (
                    <StyledShareCursor
                      isMediumScreen={isMediumScreen}
                      marginLeft={1.5}
                    >
                      <ShareBtnSimple profile={user} />
                    </StyledShareCursor>
                  ) : null}
                </IconsBox>

                <EarningsContainer>
                  <StyledBlock>
                    <StyledEarningContent>
                      <CustomEarningsView isCurrentUser={isCurrentUser} />
                    </StyledEarningContent>
                  </StyledBlock>
                </EarningsContainer>
                <ModalEditProfile open={editOpened} setOpen={setEditOpened} />
              </ImageContainer>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </MainBox>
  );
};

type CustomEarningsViewProps = {
  isCurrentUser: boolean;
};

const CustomEarningsView = ({ isCurrentUser }: CustomEarningsViewProps) => {
  const user = GetUser();
  const profile = GetPublicUser();
  const [nftCount, setNftCount] = useState<number | null>(null);
  const walletAddress = isCurrentUser
    ? user?.walletAddress
    : profile?.walletAddress;

  useEffect(() => {
    const fetchNftCount = async () => {
      if (walletAddress) {
        try {
          const nfts = await getOwnedNfts(walletAddress);
          setNftCount(nfts);
        } catch (error) {
          console.error(ERRORS.ERROR_NFT_COUNT, error);
          setNftCount(0);
        }
      }
    };
    fetchNftCount();
  }, [walletAddress]);

  return (
    <CustomEarningsWrapper>
      <EarningItem>
        <img src={ideaNftPng} alt={Constants.IDEA_NFTS} />
        <EarningText>
          <EarningNumber>{nftCount}</EarningNumber>
          <EarningLabel>{Constants.IDEA_NFTS}</EarningLabel>
        </EarningText>
      </EarningItem>
      <EarningItem>
        <img src={ideaCoinYellowIcon} alt={Constants.COINS} />
        <EarningText>
          <EarningNumber>
            {isCurrentUser ? user?.coins : profile?.coins}
          </EarningNumber>
          <EarningLabel>{Constants.COINS}</EarningLabel>
        </EarningText>
      </EarningItem>
    </CustomEarningsWrapper>
  );
};

export const UserProfileCover = styled(UserProfileCoverView)({});

export default UserProfileCover;
