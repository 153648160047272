import React, { useContext, useState, useEffect, useRef } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { fabric } from 'fabric';
import { PsButton } from 'components/common/PsButton';
import { Modal } from 'modals/Modal';
import { DataContext } from 'contexts/DataContext';
import { ModalDataContext } from 'contexts/ModalDataContext';
import { DrawTools } from './DrawTools';
import { colors, sizes, tools } from './consts';
import { PsTheme } from '../../theme';
import { makeStyles } from 'tss-react/mui';

//TODO: Fix this when we can
declare global {
  interface Window {
    _canvasFabric: any;
    _state: any;
  }
}

type ClassKey = 'container' | 'canvas';

const useStyles = makeStyles()((theme) => {
  const psTheme = theme as PsTheme;
  return {
    container: {
      position: 'relative',
      flexGrow: 1,
      background: '#fff',
      border: 'solid 1px #ccc'
    },
    canvas: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 5,
      '& > canvas': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
      }
    }
  };
});

export type DrawModalProps = {
  classes?: any;
};

const DrawModalView = () => {
  const { isDraw, hideDraw } = useContext(DataContext);
  const { updateField } = useContext(ModalDataContext);
  const { classes } = useStyles();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const state = useRef<{
    initiated: boolean;
    canvasFabric: fabric.Canvas | undefined;
    saveInHistory: boolean;
    isFirstBack: boolean;
    history: Array<any>;
  }>({
    initiated: false,
    canvasFabric: undefined,
    saveInHistory: true,
    isFirstBack: true,
    history: []
  });

  const [activeTool, setActiveTool] = useState(tools.PEN);
  const [size, setSize] = useState(sizes[0]);
  const [color, setColor] = useState(colors[0]);

  useEffect(() => {
    if (isDraw) {
      // init
      if (state.current.initiated) {
        return;
      }
      state.current.initiated = true;
      setTimeout(() => {
        const canvasFabric = new fabric.Canvas('drawCanvas');
        canvasFabric.setWidth(containerRef.current?.clientWidth || 1000);
        canvasFabric.setHeight(containerRef.current?.clientHeight || 1000);

        canvasFabric.on('object:modified', updateHistory);
        canvasFabric.on('object:added', updateHistory);

        canvasFabric.isDrawingMode = true;
        canvasFabric.backgroundColor = '#fff';
        canvasFabric.freeDrawingBrush = new fabric.PencilBrush(canvasFabric);
        canvasFabric.freeDrawingBrush.color = color;
        canvasFabric.freeDrawingBrush.width = size;
        canvasFabric.renderAll();
        updateHistory();
        window._canvasFabric = canvasFabric;
        window._state = state;

        state.current.canvasFabric = canvasFabric;
      }, 10);
    } else {
      // destroy
      if (!state.current.initiated) {
        return;
      }
      state.current.initiated = false;
      state.current.history.length = 0;
      state.current.isFirstBack = true;
      state.current.saveInHistory = true;
    }
  }, [isDraw, containerRef, containerRef.current]);

  const updateHistory = () => {
    if (!state.current.saveInHistory) {
      return;
    }
    state.current.isFirstBack = true;
    const step = JSON.stringify(state.current.canvasFabric);
    state.current.history.push(step);
  };

  const onContainerRef = (ref: HTMLDivElement | null) => {
    containerRef.current = ref;
  };

  const onToolSelect = (tool: string) => {
    setActiveTool(tool);
    if (state.current && state.current.canvasFabric) {
      if (tool === tools.RAZER) {
        state.current.canvasFabric.freeDrawingBrush.color = '#fff';
      } else {
        state.current.canvasFabric.freeDrawingBrush.color = color;
      }
    }
  };

  const onSizeSelect = (size: number) => {
    setSize(size);
    if (state.current && state.current.canvasFabric) {
      state.current.canvasFabric.freeDrawingBrush.width = size;
    }
  };

  const onColorSelect = (color: string) => {
    setColor(color);
    if (state.current && state.current.canvasFabric) {
      state.current.canvasFabric.freeDrawingBrush.color = color;
    }
  };

  const onBackClick = () => {
    if (state.current.history.length === 0 || !state.current.canvasFabric) {
      return;
    }
    state.current.canvasFabric.clear().renderAll();

    let step = state.current.history[0];

    if (state.current.history.length > 1) {
      step = state.current.history.pop();

      if (state.current.isFirstBack) {
        step = state.current.history.pop();
        state.current.isFirstBack = false;
      }
    }

    state.current.saveInHistory = false;
    state.current.canvasFabric.loadFromJSON(
      step,
      state.current.canvasFabric.renderAll.bind(state.current.canvasFabric)
    );
    state.current.saveInHistory = true;
  };

  const onClearClick = () => {
    state.current.history.length = 1;
    if (state.current.canvasFabric) {
      state.current.canvasFabric.clear().renderAll();
    }
  };

  const onModalClose = () => {
    hideDraw();
  };

  const onSaveClick = (e: React.MouseEvent) => {
    e.preventDefault();
    const canvas = document.getElementById('drawCanvas') as HTMLCanvasElement;
    if (canvas) {
      const img = canvas.toDataURL('image/png');
      updateField('imageBase64', img);
      hideDraw();
    }
  };

  return (
    <Modal
      open={isDraw}
      onClose={onModalClose}
      title={
        <PsButton smallest onClick={onSaveClick}>
          Save
        </PsButton>
      }
    >
      <div className={classes.container}>
        <DrawTools
          tool={activeTool}
          size={size}
          color={color}
          onToolSelect={onToolSelect}
          onSizeSelect={onSizeSelect}
          onColorSelect={onColorSelect}
          onBackClick={onBackClick}
          onClearClick={onClearClick}
          classes={classes}
        />
        <div ref={onContainerRef} className={classes.canvas}>
          <canvas id="drawCanvas" />
        </div>
      </div>
    </Modal>
  );
};

export const DrawModal = styled(DrawModalView)({});

export default DrawModal;
