import React, { useState, useCallback, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import dataProvider from 'dataPrvider';
import Actions from 'redux-state/actions';
import { Solution } from 'components/CardSolution';
import AuthContext from 'contexts/AuthContext';
import { Constants } from 'utilities/constants';
import useRouter from 'hooks/useRouter';
import CounterButton from 'components/common/CounterButton';
import { ShortEvent } from 'components/common/PsFormControl';
import PsInput from 'components/common/PsInput';
import { StyledCircularProgress } from 'modals/AddProblem/StyledComponents';
import CircularProgressWithLabel from '../../CircularProgressWithLabel';
import NodeContent from '../../NodeContent';
import {
  StyledCounterButtonWrapper,
  StyledProductTitle,
  StyledWrapperSubLabel,
  TextWrapper
} from '../../StyledComponents';
import pusher from '../../../../config/pusherConfig';
import { Box } from '@mui/material';
import { getSolutionQuestion } from 'modals/ImproveApplication/texts';

interface Step3Props {
  closeModal?: () => void;
  problem?: any;
  productId: string;
  productKey: string;
  subType: string;
  title: string;
  problemTitle?: string;
  selectedProblemTitle?: string;
}

interface Node {
  id?: string | number;
  isPublic?: boolean;
  key?: string;
  problem?: string;
  shortTitle?: string;
  tags?: string[] | string;
  teaser?: string;
  title?: string;
}

interface SolutionResponse {
  data: {
    key: string;
  };
}
const defaultSolutionTitle = Constants.DEFAULT_SOLUTION_TITLE;

const Step3: React.FC<Step3Props> = ({
  closeModal,
  problem,
  productId,
  subType,
  title,
  problemTitle,
  selectedProblemTitle
}) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const [nodes, setNodes] = useState([]);
  const { user } = useContext(AuthContext);
  const [solutionTitle, setSolutionTitle] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [selectedSolution, setSelectedSolution] = useState<Node | null>(null);

  const trimmedKey = problem?.id
    ?.substring(0, 140)
    .replace(/[^a-zA-Z0-9_=@,.;-]/g, '');
  const currentProblem = problemTitle || selectedProblemTitle;
  const solutionFieldLabel = (
    <StyledProductTitle>
      {getSolutionQuestion(subType, title, currentProblem)}
    </StyledProductTitle>
  );

  const [finalTitle, setFinalTitle] = useState<string>(defaultSolutionTitle);

  const onProblemNameChange = useCallback(
    (e: ShortEvent) => {
      const { value = '' } = e.target;
      const rawVal = value.slice(defaultSolutionTitle.length);
      const finalTitle = `${defaultSolutionTitle}${rawVal}`;
      setFinalTitle(finalTitle);
      setSolutionTitle(rawVal);
    },
    [defaultSolutionTitle]
  );

  const fetchSolutions = async () => {
    try {
      const params = {
        problemId: problem.id,
        problemTitle: problem.title,
        productId: productId,
        type: subType
      };
      if (user) {
        const result =
          await dataProvider.generateSolutionsFromProductTypes(params);
      } else {
        dispatch(Actions.openAuthModal(true));
      }
    } catch (error) {
      console.error('Error fetching solutions:', error);
    }
  };

  useEffect(() => {
    fetchSolutions();
  }, [productId, subType]);

  const handleSolutionUpdate = (data) => {
    setNodes(data.allSolutions);
  };

  const handleProgressUpdate = useCallback((data) => {
    setProgress(data.progress);
  }, []);

  useEffect(() => {
    const problemChannel = pusher.subscribe(
      `${Constants.IMPROVE_SOLUTION_CHANNEL}-${trimmedKey}`
    );
    const progressChannel = pusher.subscribe(
      `${Constants.PROGRESS_CHANNEL}-${trimmedKey}`
    );

    problemChannel.bind(Constants.NEW_SOLUTION, handleSolutionUpdate);
    progressChannel.bind(Constants.PROGRESS_VAL, handleProgressUpdate);

    return () => {
      dispatch(Actions.setCircularProgressValue(0));
      progressChannel.unbind(Constants.PROGRESS_VAL);
      problemChannel.unbind(Constants.NEW_SOLUTION);
      pusher.unsubscribe(`${Constants.IMPROVE_SOLUTION_CHANNEL}-${trimmedKey}`);
      pusher.unsubscribe(`progress-channel-${trimmedKey}`);
    };
  }, [dispatch, trimmedKey, problem, handleProgressUpdate]);

  const createSolutionAndRedirect = async (solutionInfo) => {
    const response = await dataProvider.create<Solution>(Constants.SOLUTIONS, {
      data: solutionInfo
    });
    router.push(`/solutions/${response.data.key}`);
  };

  const manageNextStep = async () => {
    setLoading(true);
    try {
      if (selectedSolution) {
        if (selectedSolution.id) {
          if (selectedSolution.problem !== problem.id) {
            await dataProvider.assignSolution({
              problemId: problem.id,
              solutionId: selectedSolution.id
            });
          }
        } else {
          const { tags, ...rest } = selectedSolution;
          const filteredTags = Array.isArray(tags)
            ? tags.filter((tag) => tag !== null)
            : tags;
          await createSolutionAndRedirect({ ...rest, tags: filteredTags });
        }
      } else {
        await createSolutionAndRedirect({
          title: solutionTitle,
          problem: problem.id
        });
      }
    } catch (error) {
      console.error('Error in manageNextStep:', error);
    } finally {
      setLoading(false);
      closeModal();
    }
  };

  return (
    <>
      <TextWrapper
        label={solutionFieldLabel}
        placeholder={defaultSolutionTitle}
      >
        <PsInput
          name={Constants.TITLE_SOLUTION}
          onChange={onProblemNameChange}
          placeHolder={defaultSolutionTitle}
          value={finalTitle}
        />
      </TextWrapper>
      <StyledCounterButtonWrapper>
        <Box sx={{ position: 'relative' }}>
          <CounterButton
            handleClick={manageNextStep}
            isCreateButton={true}
            text={Constants.COMPLETE}
            disabled={
              loading ||
              (!(nodes && nodes.length > 0) &&
                finalTitle.slice('The solution of:'.length).trim() === '')
            }
          />
          {loading && <StyledCircularProgress size={30} thickness={2} />}
        </Box>
      </StyledCounterButtonWrapper>
      {nodes && nodes.length === 0 ? (
        <CircularProgressWithLabel
          type={Constants.SOLUTIONS}
          progress={progress}
        />
      ) : (
        <StyledWrapperSubLabel>
          {Constants.SELECT_AI_GENERATED} <b>{subType}</b>{' '}
          {Constants.SOLUTION_ASSOCIATED} <span>{title}</span>:
        </StyledWrapperSubLabel>
      )}
      {nodes &&
        nodes.map((node) => (
          <NodeContent
            key={node.key}
            node={node}
            problem={problem}
            selectedNode={selectedSolution}
            setSelectedNode={setSelectedSolution}
            type={Constants.SOLUTION}
          />
        ))}
    </>
  );
};

export default Step3;
