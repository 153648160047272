import * as d3 from 'd3';
import { Simulation } from 'd3-force';

type InitOptions = {
  nodes?: Array<any>;
  links?: Array<any>;
  onNodeClick?: (item: any) => void;
  classes?: any;
};

const BIG_RADIUS = 50;
const SMALL_RADIUS = 15;
const STROKE = 1;
const SCALE_MIN = 0.3;
const SCALE_MAX = 2;

const drag = (simulation: Simulation<any, any>) => {
  const dragstarted = (event: any, d: any) => {
    // @ts-ignore
    if (!event.active) simulation.alphaTarget(0.01).restart();
    d.fx = d.x;
    d.fy = d.y;
  };

  const dragged = (event: any, d: any) => {
    // @ts-ignore
    d.fx = event.x;
    // @ts-ignore
    d.fy = event.y;
  };

  const dragended = (event: any, d: any) => {
    // @ts-ignore
    if (!event.active) simulation.alphaTarget(0);
    d.fx = null;
    d.fy = null;
  };

  return d3
    .drag()
    .on('start', dragstarted)
    .on('drag', dragged)
    .on('end', dragended);
};

export class GraphAPI {
  protected container: HTMLDivElement;
  protected $container: d3.Selection<HTMLDivElement, any, any, any>;

  protected nodes: Array<any> = [];
  protected links: Array<any> = [];
  protected onNodeClick?: (item: any) => void;
  protected classes: any;

  protected resizeTimer: any;

  protected $svg: d3.Selection<d3.BaseType, any, any, any>;
  protected $svgG: d3.Selection<d3.BaseType, any, any, any>;

  protected $link: d3.Selection<d3.BaseType, any, any, any>;

  protected $node: d3.Selection<d3.BaseType, any, any, any>;

  protected $tooltip: d3.Selection<d3.BaseType, any, any, any>;

  protected transform: { k: number; x: number; y: number };

  protected simulation: Simulation<any, any>;

  protected zoom: any;

  protected alignToCenter = true;

  protected index = GraphAPI.index++;

  constructor(container: HTMLDivElement, options?: InitOptions) {
    const { nodes = [], links = [], onNodeClick, classes } = options || {};
    this.container = container;
    this.$container = d3.select(this.container);
    this.nodes = nodes;
    this.links = links;
    this.onNodeClick = onNodeClick;
    this.classes = classes;
    this.transform = { k: 1, x: 0, y: 0 };

    const containerRect = this.container.getBoundingClientRect();
    const height = containerRect.height;
    const width = containerRect.width;

    window.addEventListener('resize', this.onResize);

    // Add the tooltip element to the graph
    const tooltip = document.querySelector('#graph-tooltip-' + this.index);
    if (!tooltip) {
      const tooltipDiv = document.createElement('div');
      tooltipDiv.classList.add(this.classes.tooltip);
      tooltipDiv.style.opacity = '0';
      tooltipDiv.id = 'graph-tooltip-' + this.index;
      this.container.appendChild(tooltipDiv);
    }
    this.$tooltip = d3.select('#graph-tooltip-' + this.index);
    this.$tooltip.style('transform', `translate(-50%, -100%)`);

    this.zoom = d3
      .zoom()
      .scaleExtent([SCALE_MIN, SCALE_MAX])
      .on('zoom', this.onZoom);

    // @ts-ignore
    this.$svg = this.$container.append('svg').call(this.zoom);

    // @ts-ignore
    this.$svgG = this.$svg.append('g');

    this.$svg.attr('viewBox', [-width / 2, -height / 2, width, height]);

    this.simulation = d3
      .forceSimulation(nodes)
      .alpha(0.01)
      .velocityDecay(0.7)
      .force(
        'link',
        d3.forceLink<any, any>(links).id((d) => d.id)
      )
      .force('charge', d3.forceManyBody().strength(-900))
      .force(
        'collision',
        d3.forceCollide().radius((d) => BIG_RADIUS)
      )
      .force('center', d3.forceCenter(0, 0))
      .force('x', d3.forceX())
      .force('y', d3.forceY());

    this.$link = this.$svgG
      .append('g')
      .attr('class', 'linkH')
      .selectAll('line')
      .data(links)
      .join('line');

    this.$node = this.$svgG
      .append('g')
      .attr('class', 'nodeH')
      .selectAll('g')
      .data(nodes)
      .join('g')
      .attr('class', 'node');

    this.$node
      .append('circle')
      .attr('class', (d) => `bg ${d.type}`)
      .attr('r', (d) => (d.big ? BIG_RADIUS : SMALL_RADIUS))
      // @ts-ignore
      .call(drag(this.simulation));

    this.$node
      .append('image')
      .attr('class', 'icon')
      .attr('href', (d) => d.icon)
      .attr('width', SMALL_RADIUS)
      .attr('height', SMALL_RADIUS)
      // @ts-ignore
      .call(drag(this.simulation));

    this.$node
      .append('image')
      .attr('class', 'main')
      .attr('href', (d) => d.image)
      .attr('width', (d) => (d.big ? BIG_RADIUS * 2 : SMALL_RADIUS * 2))
      .attr('height', (d) => (d.big ? BIG_RADIUS * 2 : SMALL_RADIUS * 2))
      .attr('clip-path', (d) => `url(#clip-${d.id})`)
      // @ts-ignore
      .call(drag(this.simulation));

    this.$node
      .append('defs')
      .append('clipPath')
      .attr('id', (d) => `clip-${d.id}`)
      .append('circle')
      .attr('cx', (d) => d.x)
      .attr('cy', (d) => d.y)
      .attr('r', (d) => (d.big ? BIG_RADIUS - STROKE : SMALL_RADIUS - STROKE));

    // events

    this.$node
      .on('click', (event, d) => {
        event.preventDefault();
        event.stopPropagation();
        if (this.onNodeClick) {
          this.onNodeClick(d);
        }
      })
      .on('mouseover', (event, d) => {
        // @ts-ignore
        this._addTooltip(d);
      })
      .on('mouseout', () => {
        this._removeTooltip();
      });

    this.$node.selectAll('image.main').on('load', (event, d) => {
      event.currentTarget.classList.add('loaded');
    });

    this.simulation
      .on('tick', () => {
        //update link positions
        this.$link
          .attr('x1', (d) => d.source.x)
          .attr('y1', (d) => d.source.y)
          .attr('x2', (d) => d.target.x)
          .attr('y2', (d) => d.target.y);

        // update node positions
        this.$node
          .selectAll('circle')
          .attr('x', (d: any) =>
            d.big ? d.x - BIG_RADIUS : d.x - SMALL_RADIUS
          )
          .attr('y', (d: any) =>
            d.big ? d.y - BIG_RADIUS : d.y - SMALL_RADIUS
          )
          .attr('cx', (d: any) => d.x)
          .attr('cy', (d: any) => d.y);

        this.$node
          .selectAll('image.main')
          .attr('x', (d: any) =>
            d.big ? d.x - BIG_RADIUS : d.x - SMALL_RADIUS
          )
          .attr('y', (d: any) =>
            d.big ? d.y - BIG_RADIUS : d.y - SMALL_RADIUS
          );

        this.$node
          .selectAll('image.icon')
          .attr('x', (d: any) => d.x + SMALL_RADIUS)
          .attr('y', (d: any) => d.y - SMALL_RADIUS);
      })
      .on('end', () => {
        if (!this.alignToCenter) {
          return;
        }
        this.alignToCenter = false;
        this._zoomFit();
      });
  }

  update(options?: InitOptions) {
    // eslint-disable-next-line prefer-const
    let { nodes = [], links = [], onNodeClick } = options || {};
    this.nodes = nodes;
    this.links = links;
    this.onNodeClick = onNodeClick;
    this.alignToCenter = true;

    const containerRect = this.container.getBoundingClientRect();
    const height = containerRect.height;
    const width = containerRect.width;

    let old = new Map();
    if (this.$node) {
      old = new Map(this.$node.data().map((d) => [d.id, d]));
    }
    nodes = nodes.map((d) => Object.assign(old.get(d.id) || {}, d));
    links = links.map((d) => Object.assign({}, d));

    this.$svg.attr('viewBox', [-width / 2, -height / 2, width, height]);

    this.simulation.nodes(nodes);
    this.simulation
      .alpha(0.01)
      .velocityDecay(0.7)
      .force(
        'link',
        d3.forceLink<any, any>(links).id((d) => d.id)
      )
      .force('charge', d3.forceManyBody().strength(-900))
      .force(
        'collision',
        d3.forceCollide().radius((d) => BIG_RADIUS)
      )
      .force('center', d3.forceCenter(0, 0))
      .force('x', d3.forceX())
      .force('y', d3.forceY());

    this.simulation.restart();

    this.$link = this.$link
      .data(links, (d) => `${d.source.id}\t${d.target.id}`)
      .join('line');

    this.$node = this.$node
      .data(nodes, (d) => d.id)
      .join((enter) => {
        const temp = enter.append('g').attr('class', 'node');

        temp
          .append('circle')
          .attr('class', (d) => `bg ${d.type}`)
          .attr('r', (d) => (d.big ? BIG_RADIUS : SMALL_RADIUS))
          // @ts-ignore
          .call(drag(this.simulation));

        temp
          .append('image')
          .attr('class', 'icon')
          .attr('href', (d) => d.icon)
          .attr('width', SMALL_RADIUS)
          .attr('height', SMALL_RADIUS)
          // @ts-ignore
          .call(drag(this.simulation));

        temp
          .append('image')
          .attr('class', 'main')
          .attr('href', (d) => d.image)
          .attr('width', (d) => (d.big ? BIG_RADIUS * 2 : SMALL_RADIUS * 2))
          .attr('height', (d) => (d.big ? BIG_RADIUS * 2 : SMALL_RADIUS * 2))
          .attr('clip-path', (d) => `url(#clip-${d.id})`)
          // @ts-ignore
          .call(drag(this.simulation));

        temp
          .append('defs')
          .append('clipPath')
          .attr('id', (d) => `clip-${d.id}`)
          .append('circle')
          .attr('cx', (d) => d.x)
          .attr('cy', (d) => d.y)
          .attr('r', (d) =>
            d.big ? BIG_RADIUS - STROKE : SMALL_RADIUS - STROKE
          );
        return temp;
      });

    // events
    this.$node
      .on('click', (event, d) => {
        event.preventDefault();
        event.stopPropagation();
        if (this.onNodeClick) {
          this.onNodeClick(d);
        }
      })
      .on('mouseover', (event, d) => {
        // @ts-ignore
        this._addTooltip(d);
      })
      .on('mouseout', () => {
        this._removeTooltip();
      });

    this.$node.selectAll('image.main').on('load', (event, d) => {
      event.currentTarget.classList.add('loaded');
    });
  }

  destroy() {
    window.removeEventListener('resize', this.onResize);
    if (this.resizeTimer) {
      clearTimeout(this.resizeTimer);
    }
    if (this.simulation) {
      this.simulation.stop();
    }
    if (this.$svg) {
      this.$svg.remove();
    }
    if (this.$link) {
      this.$link.remove();
    }
    if (this.$node) {
      this.$node.remove();
    }
    if (this.$tooltip) {
      this.$tooltip.remove();
    }
  }

  onZoom = (event: any) => {
    const { transform } = event;
    this.transform = transform;
    this.$svgG.attr('transform', transform);
    this.$svgG.attr('stroke-width', 1 / transform.k);
  };

  onResize = () => {
    if (this.resizeTimer) {
      clearTimeout(this.resizeTimer);
    }
    this.resizeTimer = setTimeout(() => {
      this.update({ nodes: this.nodes, links: this.links });
    }, 500);
  };

  _zoomFit() {
    // @ts-ignore
    const bounds = this.$svgG.node().getBBox();
    // @ts-ignore
    const parent = this.$svgG.node().parentElement;
    const fullWidth = parent.clientWidth;
    const fullHeight = parent.clientHeight;
    const width = bounds.width;
    const height = bounds.height;

    if (width == 0 || height == 0) return; // nothing to fit

    let scale = 0.95 / Math.max(width / fullWidth, height / fullHeight);
    if (scale < SCALE_MIN) {
      scale = SCALE_MIN;
    }
    if (scale > SCALE_MAX) {
      scale = SCALE_MAX;
    }

    this.$svg
      .transition()
      .duration(1000 || 0)
      .call(this.zoom.transform, d3.zoomIdentity.translate(0, 0).scale(scale));
  }

  _addTooltip(d: any) {
    if (!this.$tooltip) {
      return;
    }
    const { k, x: oX, y: oY } = this.transform;

    const containerRect = this.container.getBoundingClientRect();
    const height = containerRect.height;
    const width = containerRect.width;

    const r = d.big ? BIG_RADIUS : SMALL_RADIUS;

    const left = width / 2 + oX + d.x * k;
    const top = height / 2 + oY + d.y * k - r * 1.1 * k;

    this.$tooltip.transition().duration(200).style('opacity', 0.9);
    this.$tooltip
      .html(() => `<div><b>${d.title}</b></div>`)
      .style('left', `${left}px`)
      .style('top', `${top}px`);
  }

  _removeTooltip() {
    if (!this.$tooltip) {
      return;
    }
    this.$tooltip.transition().duration(200).style('opacity', 0);
  }

  static index = 1;
}

export default GraphAPI;
