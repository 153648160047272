/* eslint-disable no-magic-numbers */
/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid } from '@mui/material';
import { Product } from 'components/CardProduct';
import { TagInfo } from 'components/CardTag';
import { ChipList } from 'components/Tag';
import dataProvider from 'dataPrvider';
import { getBasePath } from 'helpers/common';
import { Node } from 'interface/common';
import Actions from 'redux-state/actions';
import { GetGeneratedImage } from 'redux-state/selectors';
import { Constants, ERRORS } from 'utilities/constants';
import { Content } from './Content';
import { GenerationButton } from './GenerationButton';
import Footer from '../Footer';
import { RelatedProduct } from '../RelatedProduct';
import {
  ContentContainer,
  HeadingVotePanelContainer,
  StyledHeading,
  StyledTextWrapper,
  StyledTypography
} from '../StyledComponents';

interface ProblemContentProps {
  displayedTags?: Array<TagInfo>;
  item: Node;
  onShowAllTagsClick?: () => void;
  setImage: React.Dispatch<React.SetStateAction<string>>;
  showAllTags?: boolean;
  votingPanel?: React.ReactNode;
}

export const ProblemContent: React.FC<ProblemContentProps> = ({
  displayedTags,
  item,
  onShowAllTagsClick,
  setImage,
  showAllTags,
  votingPanel
}) => {
  const { title, body, ideaPoints, parentProduct, parent, type } = item;
  const [product, setProduct] = useState<Product>();
  const dispatch = useDispatch();
  const generatedImage = GetGeneratedImage();

  useEffect(() => {
    if (generatedImage) {
      dispatch(
        Actions.editProfileItem({
          data: {
            files: [
              {
                url: generatedImage
              }
            ]
          },
          id: item?.id,
          type: Constants.PROBLEMS
        })
      );
      setImage(generatedImage);
    }
  }, [generatedImage, dispatch, item?.id, setImage]);

  useEffect(() => {
    const fetchParentProduct = async () => {
      if (parentProduct || parent) {
        try {
          const result = await dataProvider.getOne<Product>(
            Constants.COMPANY_PRODUCTS,
            { id: parentProduct ?? parent }
          );
          setProduct(result?.data);
        } catch (error) {
          console.error(ERRORS.FETCH_PRODUCT_ERROR, error);
        }
      }
    };

    fetchParentProduct();
  }, [parentProduct, parent]);

  return (
    <ContentContainer>
      <Grid md={12} sm={12}>
        <HeadingVotePanelContainer>
          <StyledTypography type={type}>
            {Constants.C_PROBLEM}:
          </StyledTypography>
          <GenerationButton
            body={body}
            item={item}
            productTitle={product?.title}
            title={title}
            votingPanel={votingPanel}
          />
        </HeadingVotePanelContainer>
        <StyledTextWrapper>{title}</StyledTextWrapper>
        <ChipList
          list={displayedTags}
          max={5}
          showAll={showAllTags}
          onShowAllTagsClick={onShowAllTagsClick}
          getName={(probTag) => {
            const name = (probTag as TagInfo).name || '';
            return name;
          }}
          getUrl={(probTag) => {
            const basePath = getBasePath(probTag);
            return `/${basePath}/${
              (probTag as TagInfo).key || (probTag as TagInfo).id
            }`;
          }}
        />
        <StyledHeading>{Constants.RELATED_PRODUCT}:</StyledHeading>
        <RelatedProduct
          companyKey={product?.companyKey}
          companyTitle={product?.companyName}
          productKey={product?.key}
          productTitle={product?.title}
        />
        <Content ideaPoints={ideaPoints} body={body} />
        <Footer item={item} />
      </Grid>
    </ContentContainer>
  );
};
