/* eslint-disable max-lines-per-function */
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CancelOutlined } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { loadStripe } from '@stripe/stripe-js';
import LabeledIconButton from 'components/common/buttons/LabeledIconButton';
import SimpleIconButton from 'components/SimpleIconButton';
import Config from 'config/config';
import dataProvider from 'dataPrvider';
import { BaseModal } from 'modals/Common/BaseModal';
import { SubscriptionCard } from 'modals/SubscriptionModal/SubscriptionCard';
import { toastify } from 'pages/newContests/toastify';
import Actions from 'redux-state/actions';
import { GetUser } from 'redux-state/selectors';
import { colorPalette } from 'theme';
import {
  Breakpoints,
  Constants,
  ERRORS,
  SUBSCRIPTION_PLANS,
  TOASTIFY_DURATION,
  VARIANT
} from 'utilities/constants';
import {
  CalcAmountBox,
  CalcAmountText,
  CalcAmountTitle,
  ContentBox,
  PurchaseBox,
  StyledBodyFieldText,
  StyledHeaderBox,
  StyledHeaderTypography,
  StyledSubHeaderTypography,
  SubCardWrapper,
  TextFieldsBox,
  WhiteSpace
} from './styledComponents';

const COST_PER_CREDIT = Config.COST_PER_CREDIT;
const MAX_PRICE = 999999;
const MAX_CREDITS = MAX_PRICE / COST_PER_CREDIT;

const MIN_PRICE = 0.6;
const MIN_CREDITS = 1000;
const MILLION = 1000000;
const THOUSAND = 1000;

const ONE_TIME_CREDITS_500 = {
  bestValue: false,
  features: [
    'One-time credit boost',
    'Test ideas & create visuals',
    'Use credits anytime',
    'Flexible, no commitment'
  ],
  id: 'one-time-credits-500',
  price: 75,
  shortDescription: null,
  title: SUBSCRIPTION_PLANS.ONE_TIME_CREDITS_500,
  actionButtonText: Constants.PURCHASE
};

interface CreditsTopUpModalProps {
  open: boolean;
}
export const CreditsTopUpModal: FC<CreditsTopUpModalProps> = ({ open }) => {
  const user = GetUser();
  const dispatch = useDispatch();

  const stripeKey = (user && user.userData?.STRIPE_PUB_KEY) || '';
  const [price, setPrice] = useState<number>(null);
  const [credits, setCredits] = useState<number>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const onPriceChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = parseFloat(e.target.value);
      if (newValue > MAX_PRICE) return;

      const newCredits = newValue / COST_PER_CREDIT;
      setPrice(Number(newValue.toFixed(2)));
      setCredits(Number(newCredits.toFixed(4)));
    },
    []
  );

  const onCredtisChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = parseFloat(e.target.value);
      if (newValue > MAX_CREDITS) return;

      const newPrice = newValue * COST_PER_CREDIT;
      setPrice(Number(newPrice.toFixed(2)));
      setCredits(Number(newValue.toFixed(4)));
    },
    []
  );

  const handleClose = () => {
    dispatch(Actions.toggleCreditsTopUpModal(false));
  };

  const disablePurchase = !price || price < MIN_PRICE || credits < MIN_CREDITS;

  const handlePurchase = async () => {
    if (disablePurchase) {
      toastify(
        ERRORS.ENTER_VALID_CREDITS,
        VARIANT.WARNING,
        VARIANT.BOTTOM_LEFT,
        TOASTIFY_DURATION
      );
      return;
    }
    setLoading(true);
    const data: {
      stripeSessionId: string;
      pubKey: string;
    } = await dataProvider.createPurchaseCreditsSession(
      `${window.location.href}`,
      [{ name: Constants.ONE_TIME_CREDITS_PURCHASE, price: price, quantity: 1 }]
    );

    if (data.stripeSessionId) {
      const stripe = await loadStripe(stripeKey);
      if (stripe) {
        await stripe.redirectToCheckout({
          sessionId: data.stripeSessionId
        });
      }
    }
  };

  function formatNumber(num: number) {
    if (!num) return '0';

    if (num >= MILLION) {
      return (num / MILLION).toFixed(2) + 'M';
    } else if (num >= THOUSAND) {
      return (num / THOUSAND).toFixed(2) + 'k';
    }
    return num.toFixed(2);
  }

  const buttonStyles = useMemo(
    () => ({
      backgroundColor: 'none',
      border: `1px solid ${colorPalette.purple}`,
      boxShadow: 'none',
      color: 'none',
      marginTop: '.625rem',
      padding: '.1563rem 0rem',
      '&:hover': {
        backgroundColor: colorPalette.purple,
        '& .MuiTypography-root': {
          color: colorPalette.white
        },
        '& .MuiCircularProgress-root': {
          color: colorPalette.white
        }
      },
      '&.Mui-disabled': {
        backgroundColor: colorPalette.white,
        border: 'none',
        boxShadow: 'none',
        color: colorPalette.purple
      }
    }),
    []
  );

  const textFieldStyle = {
    backgroundColor: colorPalette.white,
    borderRadius: '6px',
    color: colorPalette.black,
    fontSize: 12,
    fontWeight: 400,
    height: '3.25rem',
    width: '100%'
  };

  return (
    <BaseModal open={open} onClose={handleClose} maxWidth={Breakpoints.SMALL}>
      <SimpleIconButton
        iconStyle={{
          color: colorPalette.white,
          position: 'absolute',
          right: '.4375rem',
          top: '.4375rem'
        }}
        Icon={CancelOutlined}
        onClick={handleClose}
      />
      <StyledHeaderBox>
        <StyledHeaderTypography>
          {Constants.TOP_UP_CREDITS}
        </StyledHeaderTypography>
        <StyledSubHeaderTypography>
          {Constants.ONE_TIME_PURCHASE}
        </StyledSubHeaderTypography>
        <WhiteSpace />
      </StyledHeaderBox>
      <ContentBox>
        <PurchaseBox>
          <TextFieldsBox>
            <StyledBodyFieldText
              type={VARIANT.NUMBER}
              value={credits}
              onChange={onCredtisChange}
              placeholder={Constants.ENTER_CREDITS_COUNT}
              autoComplete="off"
              InputProps={{
                style: textFieldStyle,
                inputProps: {
                  max: 99999999,
                  min: 850
                }
              }}
              helperText="must be greater than 1000"
            />

            <StyledBodyFieldText
              type={VARIANT.NUMBER}
              value={price}
              onChange={onPriceChange}
              placeholder={Constants.ENTER_PRICE_$}
              autoComplete="off"
              InputProps={{
                style: textFieldStyle,
                inputProps: {
                  max: 999999,
                  min: 1
                }
              }}
            />
          </TextFieldsBox>

          <CalcAmountBox>
            <CalcAmountTitle>{Constants.CALC_AMOUNT}</CalcAmountTitle>
            <CalcAmountText>{`$${formatNumber(price)}`}</CalcAmountText>
            <LabeledIconButton
              buttonSx={buttonStyles}
              fontSize=".5938rem"
              icon={loading ? <CircularProgress size={10} /> : null}
              onClick={handlePurchase}
              text={Constants.PURCHASE}
              typographySx={{ color: colorPalette.purple, fontWeight: 700 }}
              variant={VARIANT.OUTLINED}
              width="100%"
            />
          </CalcAmountBox>
        </PurchaseBox>

        <SubCardWrapper>
          <SubscriptionCard subscription={ONE_TIME_CREDITS_500} />
        </SubCardWrapper>
      </ContentBox>
    </BaseModal>
  );
};
