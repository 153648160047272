import LabeledIconButton from 'components/common/buttons/LabeledIconButton';
import { ShortEvent } from 'components/common/PsFormControl';
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import Actions from 'redux-state/actions';
import { GetEditProfileItemLoader, GetUser } from 'redux-state/selectors';
import { colorPalette } from 'theme';
import { Constants, VARIANT } from 'utilities/constants';
import { Message } from '../../interfaces';
import {
  ActionButtonWrapper,
  InputProps,
  MessageHeading
} from '../../styledComponents';
import { TextFieldWithHeading } from './TextFieldWithHeading';

export const IconSize = 25;

interface EditSolutionProps {
  message: Message;
  setMessages?: Dispatch<SetStateAction<Message[]>>;
}

const EditSolutionComponent: React.FC<EditSolutionProps> = ({
  message,
  setMessages
}) => {
  const dispatch = useDispatch();

  const user = GetUser();
  const editSolutionLoader = GetEditProfileItemLoader();

  const [title, setTitle] = useState<string>(message.data?.title || '');
  const [descr, setDescr] = useState<string>(message.data?.body || '');
  const [teaser, setTeaser] = useState<string>(message.data?.teaser || '');

  const onTitleChange = useCallback(
    (e: ShortEvent) => {
      setTitle(e.target.value);
    },
    [setTitle]
  );

  const onDescrChange = useCallback(
    (e: ShortEvent) => {
      setDescr(e.target.value);
    },
    [setDescr]
  );

  const onTeaserChange = useCallback(
    (e: ShortEvent) => {
      setTeaser(e.target.value);
    },
    [setTeaser]
  );

  const onUpdateClick = useCallback(() => {
    if (user) {
      const solutionInfo = {
        title,
        teaser,
        body: descr
      };
      setMessages((pre) =>
        pre.map((msg) => {
          if (msg.id === message.id) {
            return { ...msg, data: { ...msg.data, ...solutionInfo } };
          }
          return msg;
        })
      );
      dispatch(
        Actions.editProfileItem({
          type: Constants.SOLUTIONS,
          id: message.data.id,
          profileId: user.id,
          parentType: Constants.APPLICATIONS,
          data: solutionInfo,
          getConcepts: true
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, title, teaser, descr, dispatch, message]);

  return (
    <>
      <MessageHeading mb=".75rem" fontWeight={600}>
        {message.title}
      </MessageHeading>
      <TextFieldWithHeading
        heading={Constants.TITLE}
        value={title}
        onChange={onTitleChange}
      />
      <TextFieldWithHeading
        heading={Constants.TEASER}
        value={teaser}
        onChange={onTeaserChange}
      />
      <TextFieldWithHeading
        heading={Constants.DESCRIPTION}
        onChange={onDescrChange}
        value={descr}
        multiline={true}
        rows={8}
        InputProps={InputProps}
      />
      <ActionButtonWrapper>
        <LabeledIconButton
          icon={null}
          variant={VARIANT.CONTAINED}
          text={Constants.UPDATE}
          btnColor={colorPalette.purple}
          buttonSx={{ padding: '.3125rem', marginTop: '.625rem' }}
          disabled={editSolutionLoader}
          typographySx={{
            fontSize: '.75rem'
          }}
          width="10.625rem"
          onClick={onUpdateClick}
        />
      </ActionButtonWrapper>
    </>
  );
};

export const EditSolution = React.memo(
  EditSolutionComponent,
  (prevProps, nextProps) => {
    return prevProps.message === nextProps.message;
  }
);
