import React, { useCallback, useState } from 'react';
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  RadioGroup,
  Typography
} from '@mui/material';
import { Constants } from 'utilities/constants';
import { Radio } from 'components/Radio';
import { ShortEvent } from 'components/common/PsFormControl';
import styles from 'modals/ModalWindow.module.scss';
import dataProvider from 'dataPrvider';
import TextModal from './TextModal';
import Config from 'config/config';
import { getIPAddress } from 'helpers/getIPAddress';
const PsCheckbox = (props) => {
  return <Checkbox color="default" {...props} />;
};

const IP_ADDRESS_PROVIDER_URL: string =
  Config.NEXT_IP_ADDRESS_PROVIDER_URL || '';

interface VisibilityFieldProps {
  setTermsAgree: React.Dispatch<React.SetStateAction<boolean>>;
  setVisibility: React.Dispatch<React.SetStateAction<string>>;
  termsAgree: boolean;
  title: string;
  visibility: string;
  startLoading?: () => void;
  stopLoading?: () => void;
}

type VisibilityFieldOption = {
  label: JSX.Element;
  value: string;
};

const createLabel = (title: string, description: string): JSX.Element => (
  <Box>
    <b>{title}</b> {description}
  </Box>
);

const VisibilityFieldOptions: VisibilityFieldOption[] = [
  {
    label: createLabel(Constants.MAKE_PUBLIC, Constants.EARN_COINS),
    value: Constants.PUBLIC
  },
  {
    label: createLabel(Constants.KEEP_PRIVATE, Constants.CREATE_PATENT),
    value: Constants.PRIVATE
  }
];

const VisibilityField: React.FC<VisibilityFieldProps> = ({
  setVisibility,
  visibility,
  title,
  setTermsAgree,
  termsAgree,
  startLoading,
  stopLoading
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>('');

  const handleTypeChange = useCallback(
    (e: ShortEvent) => {
      setVisibility(e.target.value);
    },
    [setVisibility]
  );

  const onTermsChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setTermsAgree(event.target.checked);
    },
    [setTermsAgree]
  );

  const onTermsClick = useCallback(
    async (e: React.MouseEvent) => {
      try {
        e.preventDefault();
        startLoading();
        const currentDate = new Date().toISOString().split('T')[0];
        const ipAddress = await getIPAddress();
        const data = { title, ipAddress, currentDate };
        const response = await dataProvider.getTermsAndConditionsDoc(data);
        setModalText(response?.data || '');
        setShowModal(true);
      } catch (error) {
        console.error('Error generating terms and conditions:', error);
      } finally {
        stopLoading();
      }
    },
    [startLoading, stopLoading]
  );

  return (
    <Box>
      <RadioGroup
        onChange={handleTypeChange}
        value={visibility}
        aria-label={Constants.ENTITY}
        name={Constants.ENTITY}
        className="radio-group-class"
      >
        {VisibilityFieldOptions.map(({ label, value }) => {
          return (
            <FormControlLabel
              key={value}
              value={value}
              control={<Radio />}
              label={label}
            />
          );
        })}
      </RadioGroup>

      {visibility === Constants.PUBLIC && (
        <FormControlLabel
          sx={{ alignItems: 'flex-start' }}
          className={`${styles.terms} ${!title ? styles.termsDisabled : ''}`}
          control={
            <PsCheckbox
              checked={termsAgree}
              name={Constants.CHECK_FIELD}
              onChange={onTermsChange}
            />
          }
          label={
            <>
              {Constants.ASSIGN_RIGHTS}{' '}
              <span style={{ color: 'blue' }}>
                <a href="#" onClick={onTermsClick}>
                  {Constants.TERMS_CONDITIONS}
                </a>
              </span>
            </>
          }
        />
      )}

      {showModal && (
        <TextModal
          open={showModal}
          onClose={() => setShowModal(false)}
          text={modalText}
        />
      )}
    </Box>
  );
};

export default VisibilityField;
