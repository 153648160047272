import React, { useContext } from 'react';
import DummyImage from 'assets/images/user_67x65.jpeg';
import { Contest } from 'components/CardContest/CardContest';
import { Tag } from 'components/CardTag';
import Vote from 'components/common/Vote';
import AuthContext from 'contexts/AuthContext';
import { Constants, VARIANT } from 'utilities/constants';
import {
  DescriptionText,
  DetailsContainer,
  ImageContainer,
  InfoContainerMobile,
  MainContainer,
  MainContent,
  StyledAvatar,
  StyledBox,
  StyledButton,
  StyledButtonContainer,
  StyledCardButton,
  StyledContestCardDateTypography,
  StyledContestCardLinkTypography,
  StyledContestCardTitle,
  StyledEarnTypography,
  StyledImage,
  StyledLogo
} from './styles';

interface CardContestViewMobileProps {
  contest: Contest;
  inCardCommunity?: boolean;
  onSeeAllContestsClick?: () => void;
  tag: Tag;
  toggleContestModal?: () => void;
}

export const CardContestViewMobile = ({
  contest,
  inCardCommunity,
  onSeeAllContestsClick,
  tag,
  toggleContestModal
}: CardContestViewMobileProps) => {
  const { user } = useContext(AuthContext);

  const { createdAt, title } = contest;

  return (
    <>
      <MainContainer>
        <MainContent>
          <ImageContainer>
            <StyledImage
              alt={title}
              src={contest.files[0]?.url}
              variant={VARIANT.ROUNDED}
            />
          </ImageContainer>
          <InfoContainerMobile>
            <StyledContestCardTitle>{title}</StyledContestCardTitle>
            <DetailsContainer>
              <StyledBox>
                <Vote
                  item={contest}
                  itemType={Constants.CONTEST}
                  scale="0.65"
                />
                <StyledAvatar src={DummyImage} />
              </StyledBox>
              {inCardCommunity && (
                <StyledContestCardDateTypography
                  variant={VARIANT.SUBTITLE1}
                  color={VARIANT.TEXT_SECONDARY}
                >
                  {`Added ${createdAt.split('T')[0]}`}
                </StyledContestCardDateTypography>
              )}
            </DetailsContainer>
          </InfoContainerMobile>
        </MainContent>
        <DescriptionText variant={VARIANT.SUBTITLE1} gutterBottom>
          {contest?.body}
        </DescriptionText>
        <StyledButtonContainer>
          <StyledButton
            variant={VARIANT.CONTAINED}
            color={VARIANT.PRIMARY}
            size={VARIANT.SMALL}
          >
            <StyledEarnTypography>
              Earn 30 Idea Coins Jackpot
            </StyledEarnTypography>
            <StyledLogo size={18} small />
          </StyledButton>
          {inCardCommunity && (
            <StyledContestCardLinkTypography>
              <StyledCardButton onClick={onSeeAllContestsClick}>
                {Constants.SEE_ALL_CONTESTS}
              </StyledCardButton>
              {tag?.owner && user?.id === tag?.owner && (
                <StyledCardButton onClick={toggleContestModal}>
                  {Constants.CREATE_A_CONTEST}
                </StyledCardButton>
              )}
            </StyledContestCardLinkTypography>
          )}
        </StyledButtonContainer>
      </MainContainer>
    </>
  );
};
