import React from 'react';

function Frame1() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="107"
      height="110"
      fill="none"
      viewBox="0 0 107 110"
    >
      <g clipPath="url(#clip0_4313_1604)">
        <path
          fill="url(#paint0_linear_4313_1604)"
          d="M-53.82 62.82s12.025-13.361 41.145-9.698c51.5 6.479 59.206-8.95 63.257-14.988-8.819 14.218-20.328 32.034-64.784 26.071-23.601-3.167-41.91 15.564-41.91 15.564l8.623-15.043-6.333-1.908.002.002z"
        ></path>
        <path
          fill="url(#paint1_linear_4313_1604)"
          d="M76.456 14.599c28.434-48.224-32.432-81.214-52.05-64.479.176.275 6.94 1.528 6.053 2.598-6.806 3.311-12.587 10.809-18.24 18.837C40.65-64.883 94.89-19.09 76.455 14.599z"
        ></path>
        <path
          fill="url(#paint2_linear_4313_1604)"
          d="M76.456 14.599C59.95 36.518-3.447 25.267 11.475-.975L8.077 5.002c-17.58 30.916 51.214 44.303 68.379 9.597z"
        ></path>
        <path
          style={{ mixBlendMode: 'multiply' }}
          fill="url(#paint3_linear_4313_1604)"
          d="M76.456 14.599C59.95 36.518-3.447 25.267 11.475-.975L8.077 5.002c-17.58 30.916 51.214 44.303 68.379 9.597z"
        ></path>
        <path
          fill="url(#paint4_linear_4313_1604)"
          d="M50.581 38.133C78.328-6.142 30.033-24.574 11.472-.974L8.074 5.003C18.49-13.313 74.384-6.956 50.581 38.136v-.003z"
        ></path>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_4313_1604"
          x1="-41.043"
          x2="27.546"
          y1="41.375"
          y2="80.41"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D99E38"></stop>
          <stop offset="0.15" stopColor="#FFF585"></stop>
          <stop offset="0.27" stopColor="#EBBC53"></stop>
          <stop offset="0.74" stopColor="#FFF28F"></stop>
          <stop offset="0.76" stopColor="#FEEA83"></stop>
          <stop offset="0.81" stopColor="#FCDD6E"></stop>
          <stop offset="0.86" stopColor="#FBD562"></stop>
          <stop offset="0.91" stopColor="#FBD35E"></stop>
          <stop offset="0.94" stopColor="#F9D05C"></stop>
          <stop offset="0.96" stopColor="#F3C958"></stop>
          <stop offset="0.97" stopColor="#E9BD50"></stop>
          <stop offset="0.98" stopColor="#C99436"></stop>
          <stop offset="0.99" stopColor="#B47926"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_4313_1604"
          x1="19.348"
          x2="86.686"
          y1="-39.759"
          y2="-1.435"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D99E38"></stop>
          <stop offset="0.15" stopColor="#FFF585"></stop>
          <stop offset="0.27" stopColor="#EBBC53"></stop>
          <stop offset="0.74" stopColor="#FFF28F"></stop>
          <stop offset="0.76" stopColor="#FEEA83"></stop>
          <stop offset="0.81" stopColor="#FCDD6E"></stop>
          <stop offset="0.86" stopColor="#FBD562"></stop>
          <stop offset="0.91" stopColor="#FBD35E"></stop>
          <stop offset="0.94" stopColor="#F9D05C"></stop>
          <stop offset="0.96" stopColor="#F3C958"></stop>
          <stop offset="0.97" stopColor="#E9BD50"></stop>
          <stop offset="0.98" stopColor="#C99436"></stop>
          <stop offset="0.99" stopColor="#B47926"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_4313_1604"
          x1="8.876"
          x2="64.965"
          y1="4.906"
          y2="36.827"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D99E38"></stop>
          <stop offset="0.15" stopColor="#FFF585"></stop>
          <stop offset="0.27" stopColor="#EBBC53"></stop>
          <stop offset="0.74" stopColor="#FFF28F"></stop>
          <stop offset="0.76" stopColor="#FEEA83"></stop>
          <stop offset="0.81" stopColor="#FCDD6E"></stop>
          <stop offset="0.86" stopColor="#FBD562"></stop>
          <stop offset="0.91" stopColor="#FBD35E"></stop>
          <stop offset="0.94" stopColor="#F9D05C"></stop>
          <stop offset="0.96" stopColor="#F3C958"></stop>
          <stop offset="0.97" stopColor="#E9BD50"></stop>
          <stop offset="0.98" stopColor="#C99436"></stop>
          <stop offset="0.99" stopColor="#B47926"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_4313_1604"
          x1="89.914"
          x2="-20.295"
          y1="23.055"
          y2="10.367"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B16611"></stop>
          <stop offset="0.29" stopColor="#F8F2EB"></stop>
          <stop offset="0.67" stopColor="#fff"></stop>
          <stop offset="0.68" stopColor="#FCF9F6"></stop>
          <stop offset="0.77" stopColor="#E2CCAD"></stop>
          <stop offset="0.85" stopColor="#CEA873"></stop>
          <stop offset="0.92" stopColor="#C08E49"></stop>
          <stop offset="0.97" stopColor="#B77E2F"></stop>
          <stop offset="1" stopColor="#B47926"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear_4313_1604"
          x1="12.682"
          x2="59.303"
          y1="-1.784"
          y2="24.75"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D99E38"></stop>
          <stop offset="0.15" stopColor="#FFF585"></stop>
          <stop offset="0.27" stopColor="#EBBC53"></stop>
          <stop offset="0.74" stopColor="#FFF28F"></stop>
          <stop offset="0.76" stopColor="#FEEA83"></stop>
          <stop offset="0.81" stopColor="#FCDD6E"></stop>
          <stop offset="0.86" stopColor="#FBD562"></stop>
          <stop offset="0.91" stopColor="#FBD35E"></stop>
          <stop offset="0.94" stopColor="#F9D05C"></stop>
          <stop offset="0.96" stopColor="#F3C958"></stop>
          <stop offset="0.97" stopColor="#E9BD50"></stop>
          <stop offset="0.98" stopColor="#C99436"></stop>
          <stop offset="0.99" stopColor="#B47926"></stop>
        </linearGradient>
        <clipPath id="clip0_4313_1604">
          <path
            fill="#fff"
            d="M0 0H86.105V174.659H0z"
            transform="matrix(-.7582 -.65203 .73342 -.67977 -21.621 109.432)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Frame1;
