import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import ProfileStatsImage from 'components/icons/ProfileStatsImage';
import { LogoMindminer } from 'components/icons/LogoMindminer';
import { NotificationsPopup } from 'components/NotificationsPopup';
import useRouter from 'hooks/useRouter';
import Actions from 'redux-state/actions';
import {
  GetPublicUser,
  GetUser,
  GetUserIdeaPoints,
  GetUserRanking
} from 'redux-state/selectors';
import { colorPalette, useIsMediumScreen } from 'theme';
import { Constants } from 'utilities/constants';
import {
  InfoBox,
  StatsChildNumber,
  StatsChildText,
  StatsParentNumber,
  StatsParentText,
  StyledMainBox
} from './StyledComponents';

export default function UserStatsView() {
  const isMediumScreen = useIsMediumScreen();
  const user = GetUser();
  const profile = GetPublicUser();
  const router = useRouter();
  const dispatch = useDispatch();
  const userRank = GetUserRanking();
  const ideaPoints = GetUserIdeaPoints();
  const { id } = router.query;
  const isCurrentUser = !!(user && (user?.id === profile?.id || user?.isAdmin));
  const onIdeaPointsClick = () => setOpenNotificationsPopup(true);
  const [openNotificationsPopup, setOpenNotificationsPopup] = useState(false);

  useEffect(() => {
    if (id) {
      dispatch(Actions.getUserRanking(id as string));
    }
  }, [dispatch, id]);

  useEffect(() => {
    const userId = isCurrentUser ? user?.id : profile?.id;

    if (userId) {
      dispatch(Actions.getUserIdeaPoints(userId));
    }
  }, [dispatch, isCurrentUser, user, profile]);

  return (
    <StyledMainBox>
      {!isMediumScreen && <ProfileStatsImage />}
      <InfoBox>
        <StatsParentText>
          {Constants.MINDMINER_OVERALL_RANKING}
          <StatsParentNumber>{userRank}</StatsParentNumber>
        </StatsParentText>
        <StatsChildText onClick={isCurrentUser ? onIdeaPointsClick : undefined}>
          {Constants.IDEA_POINTS_TEXT}
          <Box sx={{ ml: 0.5, mr: '0.1875rem' }}>
            <LogoMindminer fill={colorPalette.purple} width={20} height={20} />
          </Box>
          <StatsChildNumber>{ideaPoints}</StatsChildNumber>
        </StatsChildText>
      </InfoBox>
      {openNotificationsPopup && (
        <NotificationsPopup
          open={openNotificationsPopup}
          setOpen={setOpenNotificationsPopup}
        />
      )}
    </StyledMainBox>
  );
}
