import React from 'react';
import { styled, Theme, useTheme } from '@mui/material/styles';

import { PsTheme } from '../../theme';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root' | 'left' | 'title' | 'subTitle' | 'rest';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      display: 'block',
      '&:after': {
        content: '""',
        clear: 'both'
      }
    },
    left: {
      float: 'left',
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    title: {
      fontWeight: 'bold',
      fontSize: 24,
      margin: '0 0 10px 0',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '& a': {
        color: 'inherit',
        textDecoration: 'none'
      },
      '& a:hover': {
        color: 'inherit',
        textDecoration: 'underline'
      },
      '& b': {
        fontWeight: 'bold',
        color: psTheme.palette.primary.main
      },
      '& span': {
        color: psTheme.palette.primary.main
      }
    },
    subTitle: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      margin: '-3px 0 10px 0',
      '& a': {
        color: 'inherit',
        textDecoration: 'none'
      },
      '& a:hover': {
        color: 'inherit',
        textDecoration: 'underline'
      },
      '& b': {
        fontWeight: 'bold',
        color: psTheme.palette.primary.main
      }
    },
    rest: {
      float: 'right',
      textAlign: 'right',
      maxWidth: '100%'
    }
  };
});

type CardTitleProps = {
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  beforeTitle?: React.ReactNode;
  href?: string;
  hrefTarget?: string;
  children?: React.ReactNode;
  className?: string;
};

const CardTitleView = ({
  className,
  beforeTitle,
  title,
  subTitle,
  href,
  hrefTarget,
  children
}: CardTitleProps) => {
  const { classes } = useStyles();
  let rootClassName = classes.root;
  if (className) {
    rootClassName += ' ' + className;
  }

  return (
    <div className={rootClassName}>
      {beforeTitle ? beforeTitle : null}
      <div className={classes.left}>
        {title ? (
          <h2 className={classes.title}>
            {href ? (
              <Link to={href || ''}>
                <a target={hrefTarget}>{title}</a>
              </Link>
            ) : (
              title
            )}
          </h2>
        ) : null}
        {title && subTitle ? (
          <p className={classes.subTitle}>{subTitle}</p>
        ) : null}
      </div>
      <div className={classes.rest}>{children}</div>
    </div>
  );
};

export const CardTitle = styled(CardTitleView)({});

export default CardTitle;
