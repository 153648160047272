import * as React from 'react';
interface InventionIconProps {
  width?: number | string;
  height?: number | string;
}

export const CheckIconWithContainer: React.FC<InventionIconProps> = ({
  width = '86',
  height = '86',
  ...props
}) => (
  <div style={{ transform: 'scale(1.0)' }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      {...props}
    >
      <path
        fill="#B1F4D4"
        d="M43.4 86.168c23.524 0 42.595-19.07 42.595-42.595C85.995 20.05 66.925.98 43.4.98S.805 20.049.805 43.574c0 23.524 19.07 42.594 42.595 42.594Z"
      />
      <path
        stroke="#008954"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={6.5}
        d="M60.637 32.47 38.985 54.11l-9.825-9.835"
      />
    </svg>
  </div>
);
