import React, { FC, useCallback, useState } from 'react';
import { Constants } from 'utilities/constants';
import { ActionSection } from '../Common/ActionSection';
import { BaseModal } from '../Common/BaseModal';
import { ContentSection } from '../Common/ContentSection';
import { HeaderSection } from '../Common/HeaderSection';
import { Content } from './Content';
import { Header } from './Header';
import {
  FooterContainer,
  StyledFooterButton,
  StyledFooterText
} from './StyledComponents';
import { EarnRoyaltiesModal } from '../EarnRoyaltiesModal';

interface ShareInfoModalProps {
  onClose: () => void;
  open: boolean;
}

export const ShareInfoModal: FC<ShareInfoModalProps> = ({ open, onClose }) => {
  const [isRoyaltiesModalOpen, setRoyaltiesModalOpen] =
    useState<boolean>(false);

  const handleOpenRoyalties = useCallback(() => {
    setRoyaltiesModalOpen(true);
  }, []);
  return (
    <BaseModal open={open} isCtaInfoBgImage={true}>
      <HeaderSection>
        <Header onClose={onClose} />
      </HeaderSection>
      <ContentSection>
        <Content />
      </ContentSection>
      <ActionSection>
        <FooterContainer>
          <StyledFooterText onClick={handleOpenRoyalties}>
            {Constants.EARN_ROYALTIES}
          </StyledFooterText>
          <StyledFooterButton variant="contained" onClick={onClose}>
            {Constants.GOT_IT}
          </StyledFooterButton>
        </FooterContainer>
      </ActionSection>
      <EarnRoyaltiesModal
        open={isRoyaltiesModalOpen}
        onClose={() => setRoyaltiesModalOpen(false)}
      />
    </BaseModal>
  );
};
