import React, { ReactNode } from 'react';
import {
  MenuItem,
  Select,
  Typography,
  ListItemText,
  SelectChangeEvent
} from '@mui/material';
import { useIsMediumScreen } from 'theme';
import { Constants } from 'utilities/constants';
import {
  StyledCustomFormControl,
  StyledFormControl,
  StyledImage,
  StyledListItemIcon,
  StyledMainContainer,
  StyledSelectedItemContainer,
  StyledTypography
} from './StyledComponents';

interface FilterItem {
  icon?: string;
  label: string;
  value: string;
}

interface FilterByProps {
  filterType?: string;
  handleChange?: (event: SelectChangeEvent<any>) => void;
  items: FilterItem[];
  useCustomFormControl?: boolean;
}

const FilterBy: React.FC<FilterByProps> = ({
  filterType,
  handleChange,
  items,
  useCustomFormControl = false
}) => {
  const isMediumScreen = useIsMediumScreen();
  const StyledFormControlComponent = useCustomFormControl
    ? StyledCustomFormControl
    : StyledFormControl;

  const renderSelectedValue = (value) => {
    const selectedItem = items.find((item) => item.value === value);
    return (
      <StyledSelectedItemContainer>
        {selectedItem?.icon && <StyledImage src={selectedItem.icon} />}
        <Typography variant="body1">{selectedItem?.label}</Typography>
      </StyledSelectedItemContainer>
    );
  };

  const capitalTextStyle = {
    textTransform: 'capitalize',
    fontSize: isMediumScreen && Constants.SMALL_12
  };
  return (
    <StyledMainContainer isMediumScreen={isMediumScreen}>
      <StyledTypography isMediumScreen={isMediumScreen} variant="body1">
        Filter By:
      </StyledTypography>
      <StyledFormControlComponent>
        <Select
          value={filterType}
          sx={capitalTextStyle}
          onChange={handleChange}
          renderValue={renderSelectedValue}
        >
          {items.map((item, index) => (
            <MenuItem value={item.value} key={index}>
              {item.icon && (
                <StyledListItemIcon>
                  <img src={item.icon} />
                </StyledListItemIcon>
              )}
              <ListItemText sx={capitalTextStyle} primary={item.label} />
            </MenuItem>
          ))}
        </Select>
      </StyledFormControlComponent>
    </StyledMainContainer>
  );
};

export default FilterBy;
