import React, { useEffect, useMemo, useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Box, LinearProgress, styled, Typography } from '@mui/material';
import ProductIcon from 'assets/icons-new/product.svg';
import AuthContext from 'contexts/AuthContext';
import { ModalComponentsKeys } from 'contexts/ModalContext';
import useRouter from 'hooks/useRouter';
import Actions from 'redux-state/actions';
import {
  GetLoader,
  GetRelatedProducts,
  GetRelatedProductsCount
} from 'redux-state/commons/selectors';
import { Constants } from 'utilities/constants';
import { useIsMediumScreen } from '../../theme';
import { Product } from '../CardProduct';
import { Profile } from '../CardProfile';
import { CustomMenu } from '../CustomMenu';
import { GetActions } from '../CustomMenu/actions';
import DataNotFound from '../DataNotFound';
import { ExpandText } from '../ExpandText';
import { ImageView } from '../ImageView';
import TableView from '../TableView';
import { getHeaders } from '../TableView/getHeaders';

interface ItemListProps {
  profile?: Profile;
  paginationOptions?: {
    page: number;
    rowsPerPage: number;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  };
}

const StyledBox = styled(Box)({
  backgroundColor: 'white',
  height: 'auto',
  padding: '15px',
  width: '100%'
});

const ProductView: React.FC<ItemListProps> = ({
  profile,
  paginationOptions
}) => {
  const dispatch = useDispatch();
  const router = useRouter();

  const { key } = router.query;

  const isMediumScreen = useIsMediumScreen();

  const products: Array<Product> = GetRelatedProducts();
  const productsCount: number = GetRelatedProductsCount() || 0;
  const productLoader = GetLoader();

  const { user } = useContext(AuthContext);
  const [expandedId, setExpandedId] = useState();

  const { page, rowsPerPage, setPage, setRowsPerPage } = paginationOptions;
  const pagination = useMemo(
    () => ({
      page: page,
      perPage: rowsPerPage
    }),
    [page, rowsPerPage]
  );

  useEffect(() => {
    dispatch(
      Actions.getRelatedItems({
        resource: 'tags',
        key: key as string,
        item: Constants.COMPANY_PRODUCTS,
        pagination
      })
    );
  }, [dispatch, key, pagination]);

  useEffect(() => {
    if (products.length < 1) {
      setPage(0);
    }
  }, [products, setPage]);

  const getActions = useMemo(() => {
    return (item: Product) => {
      return GetActions({
        item,
        profile,
        type: 'Community',
        pagination
      });
    };
  }, [pagination, profile]);

  const isCurrentUser = !!(user && (user.id === profile?.id || user?.isAdmin));
  const hasEmptyData = products.length == 0;

  const getCells = useMemo(() => {
    return (icon, item) => {
      const baseCells = {
        item: (
          <ImageView
            iconImage={icon}
            placeholderImage={item?.files[0]?.url || icon}
            title={item.title}
            type="products"
            id={item.id}
            useSameTab
          />
        ),
        company: (
          <ExpandText
            body={item.company?.name}
            expandedId={expandedId}
            id={item.id}
            length={60}
            setExpandedId={setExpandedId}
            textWidth={isMediumScreen ? 200 : 400}
          />
        ),
        ideaPoints: <Typography>{item.ideaPoints ?? 0}</Typography>
      };
      if (isCurrentUser) {
        baseCells['actions'] = (
          <CustomMenu
            showPin={false}
            actions={getActions(item)}
            item={item}
            itemType={'company-products'}
            type={'Community'}
            pagination={pagination}
            title="product"
            tagKey={key as string}
          />
        );
      }

      return baseCells;
    };
  }, [expandedId, isMediumScreen, isCurrentUser, getActions, pagination, key]);

  const headers: Array<string> = useMemo(() => {
    return getHeaders(Constants.COMMUNITY_PRODUCT, user, isCurrentUser);
  }, [isCurrentUser, user]);

  const rows = {
    component: (icon, item) => getCells(icon, item),
    items: products,
    pinnedItems: []
  };

  return (
    <StyledBox>
      {productLoader && <LinearProgress value={productsCount} />}
      {!productLoader && productsCount === 0 && (
        <DataNotFound
          iconUrl={ProductIcon}
          modal={ModalComponentsKeys.addNewProduct}
          text={Constants.PRODUCT}
        />
      )}
      {!hasEmptyData && (
        <TableView
          headers={headers}
          page={page}
          rows={rows}
          rowsPerPage={rowsPerPage}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          totalRows={productsCount}
          type={'products'}
        />
      )}
    </StyledBox>
  );
};

export default ProductView;
