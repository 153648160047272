import React, { FunctionComponent } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { ShortEvent } from 'components/common/PsFormControl';
import { PsOptionType } from 'components/common/PsSelect';
import { PsChip } from 'components/common/PsChip';
import { PsTheme } from '../../theme';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root';

const useStyles = makeStyles()((theme) => {
  const psTheme = theme as PsTheme;
  return {
    root: {
      fontFamily: psTheme.typography.fontFamily,
      fontSize: 15,
      '&.has': {
        marginBottom: 20
      },
      [psTheme.breakpoints.down('xs')]: {
        '&.has': {
          marginTop: -10
        }
      }
    }
  };
});

type PsSelectedListProps = {
  className?: string;
  options: Array<PsOptionType>;
  value?: string;
  name?: string;
  onChange?: (event: ShortEvent) => void;
  canDelete?: boolean;
  onCLick?: (value: string | number, label: string) => void;
  valueObj?: Array<PsOptionType>;
};

const PsSelectedListView: FunctionComponent<PsSelectedListProps> = (
  props: PsSelectedListProps
) => {
  const stringToValue = (
    valueStr: string | undefined,
    options: Array<PsOptionType> = []
  ) => {
    const res: Array<PsOptionType> = [];
    if (!valueStr) {
      return res;
    }
    const findStrs = valueStr.split(',');
    findStrs.forEach((str) => {
      const obj = options.find((i) => i.value === str);
      if (obj) {
        res.push(obj);
      }
    });
    return res;
  };

  const valueToString = (
    value: Array<PsOptionType>,
    key: keyof PsOptionType = 'value'
  ) => {
    let valueStr = '';
    if (value) {
      if (Array.isArray(value)) {
        valueStr = value.map((item) => item[key]).join(',');
      } else {
        valueStr = value[key] || '';
      }
    }
    return valueStr;
  };

  const onDelete = (deleteItem: PsOptionType) => {
    if (props.onChange) {
      const newValueObj: Array<PsOptionType> = [];
      valueObj.forEach((item) => {
        if (item.value !== deleteItem.value) {
          newValueObj.push(item);
        }
      });
      const valueStr = valueToString(newValueObj);
      props.onChange({
        target: {
          value: valueStr,
          name: props.name,
          label: valueToString(newValueObj, 'label'),
          rawValue: valueToString(newValueObj, 'raw')
        }
      });
    }
  };

  const { className, onCLick, canDelete = true } = props;
  const { classes } = useStyles();
  const valueObj = stringToValue(props.value, props.options);
  let rootClassName = classes.root;
  if (className) {
    rootClassName += ' ' + className;
  }
  return (
    <div className={`${rootClassName} ${valueObj.length ? 'has' : ''}`}>
      {valueObj.map((item) => {
        const { value, label } = item;
        const onDeleteFn = canDelete
          ? () => {
              onDelete(item);
            }
          : undefined;
        const onClickFn = onCLick
          ? (e: React.MouseEvent) => {
              e.preventDefault();
              onCLick(value, label);
            }
          : undefined;
        return (
          <PsChip
            key={value}
            label={label}
            onDelete={onDeleteFn}
            onClick={onClickFn}
          />
        );
      })}
    </div>
  );
};

export const PsSelectedList = styled(PsSelectedListView)({});

export default PsSelectedList;
