import React, { useState, useCallback, useContext, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import ModalWindow from 'modals/ModalWindow';
import { PsButton } from 'components/common/PsButton';
import Step from 'components/Step';
import Stepper from 'components/Stepper';
import { PsLogo } from 'components/common/PsLogo';
import { Problem } from 'components/CardProblem';
import { Solution } from 'components/CardSolution';
import { ModalContext, ModalComponentsKeys } from 'contexts/ModalContext';
import {
  ModalDataContext,
  BrainstormSolutionSteps
} from 'contexts/ModalDataContext';
import { DataContext } from 'contexts/DataContext';
import { BRAINSTORM_STEPS_CONFIG } from './config';
import styles from 'modals/ModalWindow.module.scss';
import { makeStyles } from 'tss-react/mui';
import useRouter from 'hooks/useRouter';

const steps: string[] = ['Choose Brainstorm', 'Finalize Solution'];

const useButtonClasses = makeStyles()(() => {
  return {
    root: {
      margin: '8px 0',
      fontWeight: 400
    }
  };
});

export const BrainstormSolution: React.FC = () => {
  const { openModal } = useContext(ModalContext);
  const { values, setErrors, updateField } = useContext(ModalDataContext);
  const { dataProvider } = useContext(DataContext);
  const router = useRouter();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [prevSteps, setPrevSteps] = useState<string[]>([]);
  const [activeStepNumber, setActiveStepNumber] = useState<number>(0);
  const [currentStep, setCurrentStep] = useState<string | undefined>(
    BrainstormSolutionSteps.ChooseTypeStep
  );
  const [solution, setSolution] = useState<Solution>();

  const buttonClasses = useButtonClasses();

  const {
    Component,
    nextStep,
    validate,
    isNextStepBlocked,
    isFinalStep,
    nextButtonTitle
  } = BRAINSTORM_STEPS_CONFIG[currentStep || ''];

  const StepComponent = Component as React.FC;

  useEffect(() => {
    if (!values.parentSolution) {
      return;
    }
    dataProvider
      .getOne<Solution>('solutions', { id: values.parentSolution })
      .then(({ data }) => {
        setSolution(data);
        if (data.problem) {
          dataProvider
            .getOne<Problem>('problems', { id: data.problem })
            .then(({ data }) => {
              updateField('parentProblemLabel', data.title);
            })
            .catch((err) => {
              console.error(err);
            });
        }
        if (data.parentSolution) {
          dataProvider
            .getSolutionProblem<Problem>({ id: data.parentSolution })
            .then(({ data }) => {
              updateField('parentProblemLabel', data.title);
            })
            .catch((err) => {
              console.error(err);
            });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [values.parentSolution]);

  const handleClickBack = useCallback(() => {
    if (activeStepNumber === 0) {
      openModal(ModalComponentsKeys.addNewEntity);
      return;
    }
    setActiveStepNumber((prev) => Math.max(0, prev - 1));
    setCurrentStep(prevSteps[prevSteps.length - 1]);
    setPrevSteps(prevSteps.slice(0, prevSteps.length - 1));
  }, [prevSteps]);

  const handleNextStep = async () => {
    if (typeof validate === 'function') {
      const { success, errors } = validate(values);

      if (!success) {
        setErrors({ ...errors });

        return;
      }
    }

    if (isFinalStep) {
      const boost = {
        method: values.brainMethod,
        object: values.brainObject,
        sight: values.title,
        text: values.description,
        title: values.titleSolution
      };

      if (solution) {
        setLoading(true);

        dataProvider
          .improveSolution<Solution>(solution.id, boost)
          .then((data) => {
            router.push(`/solutions/${data.key || data.id}`);
            openModal(ModalComponentsKeys.brainstormSolutionSuccess);
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            setLoading(false);
          });

        return;
      }
    }

    setPrevSteps([...prevSteps, currentStep || '']);
    setErrors({});
    setActiveStepNumber((prev) => prev + 1);
    if (typeof nextStep === 'function') {
      setCurrentStep(nextStep(values));
      return;
    }

    setCurrentStep(nextStep);
  };

  const isNextStepDisabled: boolean =
    typeof isNextStepBlocked === 'function' && isNextStepBlocked(values);

  const title = values.modalTitle || 'Improve Solution';

  return (
    <ModalWindow
      title={title}
      titleAfter={
        <span className={styles.modalTitleIcon}>
          Earn Up to 2{' '}
          <PsLogo size={23} color={theme.palette.primary.main} small />
        </span>
      }
    >
      <Stepper className={styles.stepper} activeStep={activeStepNumber}>
        {steps.map((step) => (
          <Step key={step}>{step}</Step>
        ))}
      </Stepper>
      <StepComponent />
      <div className={styles.actions}>
        <PsButton
          disabled={loading || isNextStepDisabled}
          loading={loading}
          onClick={handleNextStep}
          fullWidth
          classes={buttonClasses}
        >
          {nextButtonTitle || 'Next'}
        </PsButton>
        <PsButton
          onClick={handleClickBack}
          fullWidth
          cancel
          classes={buttonClasses}
        >
          Back
        </PsButton>
      </div>
    </ModalWindow>
  );
};

export default BrainstormSolution;
