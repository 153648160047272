import React, { useContext } from 'react';
import AuthContext, { Profile, formTypes } from 'contexts/AuthContext';
import type { FunctionComponent, MouseEvent } from 'react';
import useRouter from 'hooks/useRouter';
import { Router } from 'react-router-dom';

function checkAuth(
  router: any,
  user: Profile | undefined,
  authRestricted: boolean,
  href: string,
  setAuthModalOpened: (state: string) => void
) {
  if (authRestricted && !user) {
    setAuthModalOpened(formTypes.LOGIN);
  } else {
    router.push(href);
  }
}

interface Props {
  children?: any;
  href: any;
  authRestricted: boolean;
}
export const AuthGuardLink: FunctionComponent<Props> = (props: Props) => {
  const router = useRouter();
  const { user, loading, setAuthModalOpened } = useContext(AuthContext);

  const divStyle = {
    cursor: 'pointer'
  };

  return (
    <div
      style={divStyle}
      onClick={() =>
        checkAuth(
          router,
          user,
          props.authRestricted,
          props.href,
          setAuthModalOpened
        )
      }
    >
      {props.children}
    </div>
  );
};
