import * as React from 'react';
export const FollowIconRectangle = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={243}
    height={162}
    fill="none"
    {...props}
  >
    <g filter="url(#a)">
      <rect width={209} height={127} x={17} y={17.398} fill="#fff" rx={8} />
    </g>
    <path
      fill="#F0EEF4"
      stroke="#632DDD"
      strokeWidth={1.156}
      d="M61.568 69.398a9.422 9.422 0 0 1 9.422-9.421h104.998c11.285 0 20.433 9.148 20.433 20.432 0 11.285-9.148 20.433-20.433 20.433H70.99a9.422 9.422 0 0 1-9.422-9.422V69.398Z"
    />
    <path
      fill="#632DDD"
      d="M102.336 79.82h7.678v2.42h-7.678v-2.42Zm.242 8.317h-2.86v-15.4h11.242v2.398h-8.382v13.002Zm15.842.154c-1.203 0-2.273-.257-3.212-.77a5.918 5.918 0 0 1-2.222-2.156c-.543-.91-.814-1.944-.814-3.102 0-1.174.271-2.208.814-3.102a5.756 5.756 0 0 1 2.222-2.134c.939-.514 2.009-.77 3.212-.77 1.217 0 2.295.256 3.234.77.953.513 1.694 1.217 2.222 2.112.543.894.814 1.936.814 3.124 0 1.158-.271 2.192-.814 3.102a5.715 5.715 0 0 1-2.222 2.156c-.939.513-2.017.77-3.234.77Zm0-2.354c.675 0 1.276-.147 1.804-.44a3.04 3.04 0 0 0 1.232-1.276c.308-.558.462-1.21.462-1.958 0-.763-.154-1.416-.462-1.958a3.042 3.042 0 0 0-1.232-1.276 3.607 3.607 0 0 0-1.782-.44c-.675 0-1.276.146-1.804.44a3.231 3.231 0 0 0-1.232 1.276c-.308.542-.462 1.195-.462 1.958 0 .748.154 1.4.462 1.958a3.23 3.23 0 0 0 1.232 1.276c.528.293 1.122.44 1.782.44Zm8.9 2.2V71.813h2.75v16.324h-2.75Zm6.359 0V71.813h2.75v16.324h-2.75Zm11.639.154c-1.202 0-2.273-.257-3.212-.77a5.925 5.925 0 0 1-2.222-2.156c-.542-.91-.814-1.944-.814-3.102 0-1.174.272-2.208.814-3.102a5.763 5.763 0 0 1 2.222-2.134c.939-.514 2.01-.77 3.212-.77 1.218 0 2.296.256 3.234.77a5.604 5.604 0 0 1 2.222 2.112c.543.894.814 1.936.814 3.124 0 1.158-.271 2.192-.814 3.102a5.708 5.708 0 0 1-2.222 2.156c-.938.513-2.016.77-3.234.77Zm0-2.354c.675 0 1.276-.147 1.804-.44a3.04 3.04 0 0 0 1.232-1.276c.308-.558.462-1.21.462-1.958 0-.763-.154-1.416-.462-1.958a3.042 3.042 0 0 0-1.232-1.276 3.607 3.607 0 0 0-1.782-.44c-.674 0-1.276.146-1.804.44a3.231 3.231 0 0 0-1.232 1.276c-.308.542-.462 1.195-.462 1.958 0 .748.154 1.4.462 1.958a3.23 3.23 0 0 0 1.232 1.276c.528.293 1.122.44 1.782.44Zm11.174 2.2-4.312-11.748h2.596l3.696 10.362h-1.232l3.872-10.362h2.31l3.762 10.362h-1.21l3.806-10.362h2.442l-4.334 11.748h-2.64l-3.432-9.174h.814l-3.498 9.174h-2.64Z"
    />
    <circle cx={66.019} cy={80.409} r={14.322} fill="#fff" />
    <path
      fill="#632DDD"
      d="M48.441 73.11c-.967 2.321-1.44 4.773-1.44 7.31 0 10.472 8.536 19.008 19.008 19.008 4.924 0 9.59-1.87 13.138-5.268 3.742-3.57 5.892-8.58 5.892-13.74a18.97 18.97 0 0 0-8.558-15.89 19.054 19.054 0 0 0-10.472-3.14c-1.44 0-2.86.15-4.236.473-.602.15-1.204.323-1.806.516a19.016 19.016 0 0 0-9.483 7.01l-.774 1.161a21.782 21.782 0 0 0-1.269 2.56Zm5.677 7.31a2.404 2.404 0 0 1 2.408-2.408h7.075v-7.075a2.404 2.404 0 0 1 2.408-2.408 2.404 2.404 0 0 1 2.409 2.408v7.075h7.074A2.418 2.418 0 0 1 77.9 80.42a2.404 2.404 0 0 1-2.408 2.408h-7.075v7.075a2.404 2.404 0 0 1-2.408 2.408 2.404 2.404 0 0 1-2.408-2.408v-7.075h-7.075a2.404 2.404 0 0 1-2.408-2.408Z"
    />
    <path
      stroke="#fff"
      strokeWidth={2.313}
      d="m77.117 63.564-.002-.002a20.21 20.21 0 0 0-11.106-3.328c-1.52 0-3.03.159-4.5.503l-.008.002-.008.002c-.628.157-1.254.336-1.88.537l-.012.004a20.173 20.173 0 0 0-10.058 7.435l-.01.015-.01.016-.775 1.16-.02.031-.019.032a22.93 22.93 0 0 0-1.335 2.693l29.743-9.1Zm0 0a20.127 20.127 0 0 1 9.078 16.856c0 5.478-2.28 10.79-6.249 14.576a20.064 20.064 0 0 1-13.937 5.589c-11.11 0-20.165-9.054-20.165-20.165 0-2.683.501-5.286 1.53-7.754l29.743-9.102Z"
    />
    <defs>
      <filter
        id="a"
        width={243}
        height={161}
        x={0}
        y={0.398}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={8.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5175_3314"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_5175_3314"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
