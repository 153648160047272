import React, { useState, useCallback, useContext, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';

import { ModalComponentsKeys, ModalContext } from 'contexts/ModalContext';
import { ModalDataContext } from 'contexts/ModalDataContext';
import { AuthContext } from 'contexts/AuthContext';
import { DataContext } from 'contexts/DataContext';

import {
  Card,
  CardImage,
  CardBody,
  CardTitle,
  CardOwner,
  CardActions,
  CardHeader
} from 'components/new-card';
import { Tags } from 'components/Tag';
import VotesNew from 'components/Votes/VotesNew';
import { Coins } from 'components/Coins';
import { VotesSmall } from 'components/Votes';
import { ButtonWithCounter } from 'components/common/ButtonWithCounter';
import { PsButton, EditButton } from 'components/common/PsButton';
import { ShareBtn, ShareBtnSimple } from 'components/ShareBtn';
import { Product, ModalEditProduct } from 'components/CardProduct';
import { Application, ActionButtons } from 'components/CardApplication';
import { getArticle, placeholderType } from '../../helpers';
import { PsTheme } from '../../theme';

import { ProductsIcon } from '../../layout/SideMenu/icons/ProductsIcon';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';

type SolutionShort = {
  children?: {
    id: string;
    ref: string;
  }[];
  id: string;
  key: string;
  title: string;
  teaser: string;
};

export type Concept = Application & {
  solutions: SolutionShort[];
  problems: SolutionShort[];
};

const hasNoSolutions = (app: Concept) => {
  const list = app.selected || [];
  const withoutProblem = list.filter((item) => {
    return item != app.problem;
  });
  return withoutProblem.length <= 0;
};

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      flexDirection: 'column'
    },
    top: {
      display: 'flex',
      width: '100%',
      [psTheme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    },
    bottom: {
      padding: '10px 10px'
    },
    tags: {
      '& > div': {
        marginTop: 4,
        marginBottom: 4
      }
    },
    buttons: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row-reverse',
      justifyContent: 'flex-start',
      alignItems: 'center'
    },
    row: {
      display: 'flex',
      flexDirection: 'row'
    },
    cell: {
      display: 'flex',
      flexDirection: 'column',
      '&:first-child': {
        flexGrow: 1
      },
      '&.likes': {
        [psTheme.breakpoints.down('sm')]: {
          display: 'none'
        }
      }
    },
    contributors: {},
    contributorsTitle: {
      marginBottom: 14,
      fontSize: 18,
      fontWeight: 600
    },
    users: {
      fontSize: 0,
      lineHeight: 0
    },
    user: {
      display: 'inline-block',
      marginRight: 15,
      marginBottom: 15
    },
    userPhoto: {
      display: 'block',
      width: 45,
      height: 45,
      borderRadius: 10,
      objectFit: 'cover'
    },
    actionButtonsMobile: {
      display: 'none',
      '&.mmmm': {
        display: 'none'
      },
      [psTheme.breakpoints.down('sm')]: {
        display: 'flex',
        '&.mmmm': {
          display: 'flex'
        }
      }
    },
    tagsButton: {
      margin: '10px 10px 0 0',
      whiteSpace: 'nowrap'
    },
    descriptionRow: {
      margin: '10px 0 22px',
      fontSize: 18,
      lineHeight: 1.6,
      '& b': {
        fontWeight: 600,
        color: psTheme.palette.primary.main
      }
    },
    votesBlock: {
      marginLeft: 'auto'
    },
    infoRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      marginBottom: -5,
      paddingTop: 9,
      '& .left': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& button': {
          marginBottom: 10
        }
      },
      '& .right': {
        paddingLeft: 10
      },
      '& .coins': {
        marginRight: 30,
        marginTop: 10,
        marginBottom: 10
      },
      [psTheme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'stretch',
        '& .left': {
          flexWrap: 'wrap'
        },
        '& .right': {
          display: 'none'
        }
      }
    },
    iconNft: {
      marginLeft: -10,
      marginRight: 10
    },
    iconNftCoins: {
      display: 'inline-flex',
      marginLeft: -10,
      marginRight: 10,
      '& svg:first-child': {
        marginRight: -5
      }
    },
    nftCoinsLogo: {
      marginRight: 10,
      '& > svg:first-child': {
        marginRight: -5
      }
    },
    boxProductIcon: {
      color: '#632DDD',
      fontSize: '2.5rem'
    },
    iconSpace: {
      marginRight: '16px'
    }
  };
});

export type CardProductDetailProps = {
  product: Product;
};

export const CardProductDetailView = ({
  product: initApplication
}: CardProductDetailProps) => {
  const { openModal, openInventionImprove, openInventionBuildPatent } =
    useContext(ModalContext);
  const { updateField } = useContext(ModalDataContext);
  const { setAlertContent } = useContext(DataContext);
  const { user } = useContext(AuthContext);

  const [application, setApplication] = useState<Product>(initApplication);
  const [editOpened, setEditOpened] = useState(false);
  const { classes } = useStyles();

  const {
    id,
    key,
    title,
    body,
    owner,
    ownerInfo,
    companyKey,
    companyName,
    files,
    tags = [],
    tagsInfo = [],
    createdAt,
    likes,
    dislikes,
    reward = 0
  } = application;

  const canEdit = user && (user.id === owner || user.isAdmin);

  const showEditTags = (user?.userData?.moderator || []).length;
  const image = files && files.length ? files[0] : undefined;

  useEffect(() => {
    setApplication(initApplication);
  }, [initApplication]);

  const onEditUpdate = useCallback(
    (newApp: Product) => {
      setApplication(newApp);
      setEditOpened(false);
    },
    [setApplication, setEditOpened]
  );

  const onEditOpen = useCallback(() => {
    setEditOpened(true);
  }, [setEditOpened]);

  const onEditClose = useCallback(() => {
    setEditOpened(false);
  }, [setEditOpened]);

  const onImproveClick = useCallback(() => {
    openInventionImprove({
      inventionId: id,
      inventionTitle: title,
      type: 'product'
    });
  }, [openInventionImprove, id, title]);

  const onManageTagsClick = useCallback(() => {
    updateField('title', 'company-products');
    updateField('productId', id);
    updateField('tags', tags);
    openModal(ModalComponentsKeys.manageTags);
  }, [updateField, openModal, id, tags]);

  const titleText = (
    <>
      {title}, {getArticle(companyName)}{' '}
      <span>
        <Link to={`/tags/${companyKey}`}>{companyName}</Link>
      </span>{' '}
      product
    </>
  );

  return (
    <div>
      <Card className={classes.root}>
        <CardHeader>
          <CardTitle
            beforeTitle={
              <div className={classes.iconSpace}>
                <ProductsIcon className={classes.boxProductIcon} />
              </div>
            }
            title={titleText}
          >
            <div className={classes.buttons}>
              {canEdit ? (
                <EditButton
                  className={classes.tagsButton}
                  onClick={onEditOpen}
                ></EditButton>
              ) : null}
            </div>
            <ShareBtnSimple product={application} />
          </CardTitle>
          <CardImage image={image} type={placeholderType.A} />
        </CardHeader>
        <CardBody>
          <Box display="flex" alignItems="center" mb={2}>
            <CardOwner
              ownerName={ownerInfo && ownerInfo.username}
              ownerKey={ownerInfo && ownerInfo.key}
              owner={owner}
              createdAt={createdAt}
            />
            <VotesNew
              className={classes.votesBlock}
              resource="products"
              itemId={id}
              likes={likes}
              dislikes={dislikes}
              user={user}
            />
          </Box>
          <Tags
            className={classes.tags}
            tags={tags}
            tagsList={tagsInfo}
            edit={showEditTags ? 'before' : ''}
            onEditClick={onManageTagsClick}
          />
          <div>
            <div className={classes.infoRow}>
              <Coins className="coins" count={reward}>
                Earned
              </Coins>
              <ButtonWithCounter onClick={onImproveClick} coins={30}>
                Improve
              </ButtonWithCounter>
            </div>
          </div>
        </CardBody>
        <div className={classes.bottom}>
          {body ? (
            <p className={classes.descriptionRow}>
              The product of <b>{title}</b> is generally described as {body}
            </p>
          ) : null}
          <CardActions
            className={`${classes.actionButtonsMobile} mmmm`}
            resource="products"
            resourceId={id}
            likes={likes}
            dislikes={dislikes}
            user={user}
            row
          >
            <ShareBtn product={application} tagsList={tagsInfo} />
          </CardActions>
        </div>
      </Card>
      <ModalEditProduct
        product={application}
        open={editOpened}
        onUpdate={onEditUpdate}
        onClose={onEditClose}
      />
    </div>
  );
};

export const CardProductDetail = styled(CardProductDetailView)({});

export default CardProductDetail;
