import ProblemName, { defaultProblemTitle } from './steps/ProblemName';
import ProblemTagsStep from './steps/ProblemTagsStep';
import FinilizeProblemStep from './steps/FinilizeProblemStep';
import {
  ValidateFormType,
  FormStepConfig,
  AddProblemSteps
} from 'contexts/ModalDataContext';
import { isTitleValid } from '../../helpers';

export { defaultProblemTitle };

export const ADD_PROBLEM_STEPS_CONFIG: {
  [name: string]: FormStepConfig;
} = {
  [AddProblemSteps.ProblemName]: {
    Component: ProblemName,
    nextStep: AddProblemSteps.ProblemTags,
    isNextStepBlocked: (data) => {
      return !data.titleProblem;
    },
    validate: (data): ValidateFormType => {
      if (
        data.titleProblem &&
        isTitleValid(data.titleProblem, defaultProblemTitle)
      ) {
        return { success: true };
      }
      if (!data.titleProblem) {
        return { success: false, errors: { titleProblem: 'Title required' } };
      }
      return {
        success: false,
        errors: {
          titleProblem: `Title should starts with "${defaultProblemTitle}"`
        }
      };
    }
  },
  [AddProblemSteps.ProblemTags]: {
    nextStep: AddProblemSteps.FinilizeProblem,
    Component: ProblemTagsStep,
    isNextStepBlocked: (data) => {
      if (data.tagsProblem.length === 0) return true;

      return false;
    },
    validate: (data): ValidateFormType => {
      if (data.tagsProblem) {
        return { success: true };
      }
      return { success: false, errors: { tags: 'required' } };
    }
  },
  [AddProblemSteps.FinilizeProblem]: {
    isFinalStep: true,
    Component: FinilizeProblemStep,
    // nextStep: (data) => {
    //   return data.createdSuccess ? AddOrderSteps.Created : AddOrderSteps.Failed;
    // },
    nextButtonTitle: 'Finish'
  }
};
