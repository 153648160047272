/* eslint-disable */
import { ProfilePayload } from './interface';
import {
  FORGET_PASSWORD_RESPONSE,
  FORGET_PASSWORD,
  GET_PROFILE_SOCIAL_AUTH_KEYS_SUCCESS,
  GET_PROFILE_SOCIAL_AUTH_KEYS,
  GET_PROFILE_SUCCESS,
  GET_PUBLIC_PROFILE_SUCCESS,
  LOG_IN_SUCCESS,
  LOG_IN,
  RESET_PASSWORD_RESPONSE,
  RESET_PASSWORD,
  SET_LOGIN_USER,
  SIGN_UP_SUCCESS,
  SIGN_UP,
  GET_PROFILE,
  GET_PUBLIC_PROFILE
} from './types';

const INITIAL_STATE = {
  getSocialAuthKeys: [],
  getSocialAuthKeysLoading: false,
  loginUser: null,
  publicUser: null,
  getUserLoading: false,
  resetPasswordLoading: false,
  resetPasswordStatus: '',
  signInLoading: false,
  signUpLoading: false
};

const reducer = (
  state = INITIAL_STATE,
  action: { type: string; payload: ProfilePayload }
) => {
  switch (action.type) {
    case SET_LOGIN_USER:
      return { ...state, loginUser: action.payload };
    case GET_PROFILE: {
      return {
        ...state,
        getUserLoading: true
      };
    }
    case GET_PROFILE_SUCCESS: {
      return {
        ...state,
        loginUser: action.payload.data,
        getUserLoading: false
      };
    }
    case GET_PUBLIC_PROFILE: {
      return {
        ...state,
        getUserLoading: true
      };
    }
    case GET_PUBLIC_PROFILE_SUCCESS: {
      return {
        ...state,
        publicUser: action.payload.data,
        getUserLoading: false
      };
    }
    case GET_PROFILE_SOCIAL_AUTH_KEYS:
      return { ...state, getSocialAuthKeysLoading: true };
    case GET_PROFILE_SOCIAL_AUTH_KEYS_SUCCESS:
      return {
        ...state,
        getSocialAuthKeysLoading: false,
        getSocialAuthKeys: action.payload.data
      };
    case SIGN_UP:
      return { ...state, signUpLoading: true };
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        signUpLoading: false
      };
    case FORGET_PASSWORD:
      return { ...state, resetPasswordLoading: true };
    case FORGET_PASSWORD_RESPONSE:
      return {
        ...state,
        resetPasswordLoading: false,
        resetPasswordStatus: action.payload?.resetPasswordStatus ?? ''
      };
    case RESET_PASSWORD:
      return { ...state, resetPasswordLoading: true };
    case RESET_PASSWORD_RESPONSE:
      return {
        ...state,
        resetPasswordLoading: false,
        resetPasswordStatus: action.payload?.resetPasswordStatus ?? ''
      };
    case LOG_IN:
      return { ...state, signInLoading: true };
    case LOG_IN_SUCCESS:
      return { ...state, signInLoading: false };
    default:
      return state;
  }
};

export default reducer;
