import { Box, Modal, Typography, styled } from '@mui/material';
import { colorPalette } from 'theme';

export const LeaderBoardModalWrapper = styled(Modal)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center'
});

export const LeaderBoardContainer = styled(Box)({
  background: colorPalette.white,
  borderRadius: 8,
  boxShadow: '0rem 0.25rem 0.5rem rgba(0, 0, 0, 0.1)',
  maxWidth: 1200,
  padding: '1.5625rem',
  width: '80%'
});

export const LeaderBoardHeaderContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between'
});

export const StyledHeadingText = styled(Typography)({
  background: colorPalette.white,
  color: colorPalette.black,
  fontSize: '1.25rem',
  fontWeight: 700
});

export const StyledTableWrapper = styled(Box)({
  maxHeight: '65vh',
  overflowY: 'scroll'
});

export const StyledIndexText = styled(Typography)({
  marginTop: '.875rem'
});

export const StyledTypographyCell = styled(Typography)({
  marginTop: '0.75rem'
});

export const StyledIdeacoinsBox = styled(Box)({
  marginTop: '0.75rem'
});

export const StyledTotalBox = styled(Box)({
  marginTop: '0.625rem'
});
