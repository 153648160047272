import React, { useState, useCallback, useContext, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import ModalWindow from 'modals/ModalWindow';
import { PsButton } from 'components/common/PsButton';
import Step from 'components/Step';
import Stepper from 'components/Stepper';
import { Challenge } from 'components/CardChallenge';
import { ModalContext, ModalComponentsKeys } from 'contexts/ModalContext';
import {
  ModalDataContext,
  AddChallengeSteps,
  StepComponentProps,
  AddContestSteps
} from 'contexts/ModalDataContext';
import { DataContext } from 'contexts/DataContext';
import { AuthContext } from 'contexts/AuthContext';
import { ADD_CHALLENGE_STEPS_CONFIG, stepName } from './config';
import styles from 'modals/ModalWindow.module.scss';
import { Contest } from '../../components/CardContest';
import { makeStyles } from 'tss-react/mui';
import useRouter from 'hooks/useRouter';

const isToday = (start: Date, end: Date): boolean => {
  const today = new Date();
  const todayStr = `${today.getUTCFullYear()}-${today.getUTCMonth()}-${today.getUTCDate()}`;
  const startStr = `${start.getUTCFullYear()}-${start.getUTCMonth()}-${start.getUTCDate()}`;
  const endStr = `${end.getUTCFullYear()}-${end.getUTCMonth()}-${end.getUTCDate()}`;
  return startStr === endStr && startStr === todayStr;
};

const steps: string[] = [
  'Describe Challenge',
  'Challenge Date',
  'Challenge Rewards'
];

const stepsContest: string[] = [
  'Describe Contest',
  'Contest Type',
  'Rewards & Problems'
];

const useButtonClasses = makeStyles()(() => {
  return {
    root: {
      margin: '8px 0',
      fontWeight: 400
    }
  };
});

const AddNewChallenge: React.FC = () => {
  const { user } = useContext(AuthContext);
  const { openModal } = useContext(ModalContext);
  const { values, setErrors, updateField } = useContext(ModalDataContext);
  const { dataProvider } = useContext(DataContext);
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [prevSteps, setPrevSteps] = useState<string[]>([]);
  const [activeStepNumber, setActiveStepNumber] = useState<number>(0);
  const [currentStep, setCurrentStep] = useState<string | undefined>(
    AddChallengeSteps.ChallengeName
  );
  const [stepsName, setStepsName] = useState<Array<string>>(steps);

  const buttonClasses = useButtonClasses();

  const {
    Component,
    nextStep,
    validate,
    isNextStepBlocked,
    isFinalStep,
    nextButtonTitle,
    modalTitle,
    name
  } = ADD_CHALLENGE_STEPS_CONFIG[currentStep || ''];

  const StepComponent = Component as React.FC<StepComponentProps>;

  const handleClickBack = () => {
    if (name === stepName.CONTEST_START) {
      setPrevSteps([]);
      setErrors({});
      setActiveStepNumber(0);
      setCurrentStep(AddChallengeSteps.ChallengeName);
      setStepsName(steps);
    }
    if (activeStepNumber === 0) {
      openModal(ModalComponentsKeys.addNewEntity);
      return;
    }
    setActiveStepNumber((prev) => Math.max(0, prev - 1));
    setCurrentStep(prevSteps[prevSteps.length - 1]);
    setPrevSteps(prevSteps.slice(0, prevSteps.length - 1));
  };

  const handleNextStep = async () => {
    if (typeof validate === 'function') {
      const { success, errors } = validate(values);

      if (!success) {
        setErrors({ ...errors });

        return;
      }
    }

    if (name === stepName.CONTEST_END) {
      let start = new Date(values.contestStart as string);
      let end = new Date(values.contestEnd as string);
      start.setUTCHours(0);
      start.setUTCMinutes(0);
      start.setUTCSeconds(0);
      start.setUTCMilliseconds(0);
      end.setUTCHours(0);
      end.setUTCMinutes(0);
      end.setUTCSeconds(0);
      end.setUTCMilliseconds(0);

      if (isToday(start, end)) {
        const offset = 1000 * 60 * 15; // 15 min
        start = new Date();
        end = new Date(start.getTime() + offset);
      } else {
        end.setUTCHours(24);
      }
      const contestInfo = {
        title: values.titleContest,
        body: values.descriptionContest,
        tag: values.contestTag,
        problems: values.contestProblems || [],
        files: values.filesContest,
        type: 'hybrid',
        wins: values.contestWins || '1',
        bountyCoins: values.contestCoins || 30,
        startDate: start.toISOString(),
        endDate: end.toISOString()
      };

      setLoading(true);

      dataProvider
        .create<Contest>('contests', { data: contestInfo })
        .then(({ data }) => {
          router.push(`/contests/${data.key || data.id}`);
          const newContests = [...values.challengeContests, data.id];
          updateField('challengeContests', newContests);
          // clear old
          updateField('titleContest', '');
          updateField('descriptionContest', '');
          updateField('contestTag', undefined);
          updateField('contestProblems', []);
          updateField('contestStart', undefined);
          updateField('contestEnd', undefined);
          // move to challenge
          setPrevSteps([]);
          setErrors({});
          setActiveStepNumber(0);
          setCurrentStep(AddChallengeSteps.ChallengeName);
          setStepsName(steps);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });

      return;
    }

    if (isFinalStep) {
      let start = new Date(values.challengeStart as string);
      let end = new Date(values.challengeEnd as string);
      start.setUTCHours(0);
      start.setUTCMinutes(0);
      start.setUTCSeconds(0);
      start.setUTCMilliseconds(0);
      end.setUTCHours(0);
      end.setUTCMinutes(0);
      end.setUTCSeconds(0);
      end.setUTCMilliseconds(0);

      if (isToday(start, end)) {
        const offset = 1000 * 60 * 15; // 15 min
        start = new Date();
        end = new Date(start.getTime() + offset);
      } else {
        end.setUTCHours(24);
      }
      const challengeInfo = {
        title: values.titleChallenge,
        header: values.challengeHeader,
        tags: values.challengeTags,
        contests: values.challengeContests,
        startDate: start.toISOString(),
        endDate: end.toISOString(),
        internalReward: values.challengeInternalReward || 30,
        externalReward: values.challengeExternalReward,
        owner: values.challengeOwner || (user ? user.id : ''),
        files: values.filesChallenge,
        files2: values.files2Challenge
      };

      setLoading(true);

      dataProvider
        .create<Challenge>('challenges', { data: challengeInfo })
        .then(({ data }) => {
          router.push(`/challenges/${data.key || data.id}`);
          openModal(ModalComponentsKeys.success);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });

      return;
    }

    setPrevSteps([...prevSteps, currentStep || '']);
    setErrors({});
    setActiveStepNumber((prev) => prev + 1);
    if (typeof nextStep === 'function') {
      setCurrentStep(nextStep(values));
      return;
    }

    setCurrentStep(nextStep);
  };

  const onStepNotify = (args: any) => {
    if (args === 'create contest') {
      setPrevSteps([...prevSteps, currentStep || '']);
      setErrors({});
      setActiveStepNumber(4);
      setCurrentStep(AddContestSteps.ContestName);
      setStepsName(stepsContest);
    }
  };

  const isNextStepDisabled: boolean =
    typeof isNextStepBlocked === 'function' && isNextStepBlocked(values);

  const title = values.modalTitle || modalTitle || 'Add New Challenge';

  let stepperStep;
  if (stepsName === stepsContest) {
    stepperStep = 0;
    if (activeStepNumber >= 6 && activeStepNumber < 7) {
      stepperStep = 1;
    }
    if (activeStepNumber >= 7) {
      stepperStep = 2;
    }
  } else {
    stepperStep = activeStepNumber;
    if (activeStepNumber >= 2) {
      stepperStep = 2;
    }
  }

  return (
    <ModalWindow title={title}>
      <Stepper className={styles.stepper} activeStep={stepperStep}>
        {stepsName.map((step) => (
          <Step key={step}>{step}</Step>
        ))}
      </Stepper>
      <StepComponent onNotify={onStepNotify} />
      <div className={styles.actions}>
        <PsButton
          disabled={isNextStepDisabled || loading}
          loading={loading}
          onClick={handleNextStep}
          fullWidth
          classes={buttonClasses}
        >
          {nextButtonTitle || 'Next'}
        </PsButton>
        <PsButton
          onClick={handleClickBack}
          fullWidth
          cancel
          classes={buttonClasses}
        >
          Back
        </PsButton>
      </div>
    </ModalWindow>
  );
};

export default AddNewChallenge;
