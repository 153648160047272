import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const NumberThree = (props: SvgIconProps) => {
  return (
    <SvgIcon
      sx={{ width: '100%', maxWidth: '73px', height: 'auto' }}
      viewBox="0 0 73 73"
      {...props}
    >
      <g clipPath="url(#clip0_5573_3604)">
        <circle cx="36.5" cy="36.5" r="36.5" fill="#473BF0" />
        <path
          d="M35.4237 50.432C33.6717 50.432 31.9317 50.204 30.2037 49.748C28.4757 49.268 27.0117 48.596 25.8117 47.732L28.0797 43.268C29.0397 43.964 30.1557 44.516 31.4277 44.924C32.6997 45.332 33.9837 45.536 35.2797 45.536C36.7437 45.536 37.8957 45.248 38.7357 44.672C39.5757 44.096 39.9957 43.304 39.9957 42.296C39.9957 41.336 39.6237 40.58 38.8797 40.028C38.1357 39.476 36.9357 39.2 35.2797 39.2H32.6157V35.348L39.6357 27.392L40.2837 29.48H27.0717V24.8H44.7117V28.58L37.7277 36.536L34.7757 34.844H36.4677C39.5637 34.844 41.9037 35.54 43.4877 36.932C45.0717 38.324 45.8637 40.112 45.8637 42.296C45.8637 43.712 45.4917 45.044 44.7477 46.292C44.0037 47.516 42.8637 48.512 41.3277 49.28C39.7917 50.048 37.8237 50.432 35.4237 50.432Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_5573_3604">
          <rect width="73" height="73" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
