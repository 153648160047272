import Config from 'config/config';

export const getIPAddress = () => {
  const IP_ADDRESS_PROVIDER_URL: string =
    Config.NEXT_IP_ADDRESS_PROVIDER_URL || '';
  return fetch(IP_ADDRESS_PROVIDER_URL)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      return data.ip;
    })
    .catch((error) => {
      console.error('There was a problem with the fetch operation:', error);
      return '';
    });
};
