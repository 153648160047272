import React, { useContext, useEffect, useState } from 'react';
import { useTheme, alpha } from '@mui/material/styles';

import { ModalDataContext } from 'contexts/ModalDataContext';
import { DataContext } from 'contexts/DataContext';
import styles from 'modals/ModalWindow.module.scss';
import { Radio } from 'components/Radio';
import { PsInput } from 'components/common/PsInput';
import { PsFormControl } from 'components/common/PsFormControl';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const methodsVerbs: Record<string, string> = {
  Eliminate: 'Eliminating',
  Reduce: 'Reducing',
  Substitute: 'Substituting',
  Separate: 'Separating',
  Integrate: 'Integrating',
  'Re-Use': 'Re-Using',
  Standardize: 'Standardising',
  Add: 'Adding',

  // old, backward compatibility
  Eliminating: 'Eliminating',
  Reducing: 'Reducing',
  Substituting: 'Substituting',
  Separating: 'Separating',
  Integrating: 'Integrating',
  'Re-Using': 'Re-Using',
  Standardising: 'Standardising',
  Adding: 'Adding'
};

export const methodsHindsightVerbs: Record<string, string> = {
  Eliminate: 'Eliminated',
  Reduce: 'Reduced',
  Substitute: 'Substituted',
  Separate: 'Separated',
  Integrate: 'Integrated',
  'Re-Use': 'Re-Used',
  Standardize: 'Standardised',
  Add: 'Added'
};

export const getLabel = (
  method = '',
  object = '',
  sight = '',
  solutionTitle = '',
  problemTitle = ''
) => {
  const methodInf: string = (method || '').toLowerCase();
  const methodIng: string = (methodsVerbs[method] || '').toLowerCase();
  const methodEd: string = (methodsHindsightVerbs[method] || '').toLowerCase();
  const objectInf: string = (object || '').toLowerCase();
  switch (sight) {
    case 'hindsight': {
      return `Considering how someone else in the past has ${methodEd} a ${objectInf} in another context, solve the problem of ${problemTitle} by ${methodIng} an associated ${objectInf} with the solution of: the ${solutionTitle}`;
    }
    case 'outsight': {
      return `Thinking of how someone else is ${methodIng} a ${objectInf} right now to solve another problem, solve the problem of ${problemTitle} by ${methodIng} an associated ${objectInf} with the solution of: the ${solutionTitle}`;
    }
    case 'foresight': {
      return `By predicting how you might ${methodInf} a ${objectInf} in the future considering how the world might change, solve the problem of ${problemTitle} by ${methodIng} an associated ${objectInf} with the solution of: the ${solutionTitle}`;
    }
    default: {
      return `${method} a ${object} for the following solution`;
    }
  }
};

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  return {
    root: {
      borderRadius: 8,
      margin: '8px 0',
      border: `solid 1px ${alpha(theme.palette.primary.main, 0.15)}`,
      background: 'rgb(248, 245, 255, 0.5)',
      color: theme.palette.primary.main,
      '&:hover': {
        border: `solid 1px ${alpha(theme.palette.primary.main, 0.5)}`
      }
    }
  };
});

const TypeDescriptionStep: React.FC = () => {
  const { values, updateField, handleInputChange } =
    useContext(ModalDataContext);
  const { dataProvider } = useContext(DataContext);
  const [selectedEntity, setSelectedEntity] = useState<string>(values.title);
  const [helperText, setHelperText] = useState<React.ReactNode>('');
  const [helperTextAi, setHelperTextAi] = useState('');
  const { classes } = useStyles();

  const {
    description,
    brainMethod,
    brainObject,
    title,
    parentProblemLabel,
    parentSolutionLabel
  } = values;

  useEffect(() => {
    if (!parentSolutionLabel) {
      setHelperTextAi('');
      return;
    }
    setHelperTextAi('');
    const question = `What ${brainMethod} can I ${brainObject} to Improve a ${parentSolutionLabel}? `;
    dataProvider
      .answerSearch(question)
      .then(({ text }) => {
        setHelperTextAi(text);
      })
      .catch((err) => {
        console.error(err);
        setHelperTextAi('');
      });
  }, []);

  useEffect(() => {
    let helperText: React.ReactNode = '';
    if (helperTextAi) {
      helperText = (
        <span
          className="ai-generated"
          dangerouslySetInnerHTML={{
            __html:
              '<b>MindMiner AI-generated example:</b> ' +
              helperTextAi.replace(/\n/g, '<br />')
          }}
        ></span>
      );
    }
    setHelperText(helperText);
  }, [helperTextAi]);

  const handleEntityChange = (
    e: React.ChangeEvent | React.MouseEvent,
    value: string
  ) => {
    setSelectedEntity(value);
    updateField('title', value);
  };

  const solTitle = parentSolutionLabel || '';
  const stepText = (
    <>
      What <b>{(brainObject || '').toLowerCase()}</b> will you{' '}
      <b>{(brainMethod || '').toLowerCase()}</b> to improve the{' '}
      <b>{solTitle}</b>
    </>
  );

  const label = getLabel(
    brainMethod,
    brainObject,
    title,
    parentSolutionLabel,
    parentProblemLabel
  );

  return (
    <div className={styles.stepWrapper}>
      <Typography className={styles.text}>{stepText}</Typography>

      {!selectedEntity && (
        <div className={selectedEntity ? '' : styles.hideFields}>
          <RadioGroup
            onChange={handleEntityChange}
            value={selectedEntity}
            aria-label="entity"
            name="entity"
            className={styles.radioSmall}
          >
            {[
              { label: 'Hindsight', value: 'hindsight' },
              { label: 'Outsight', value: 'outsight' },
              { label: 'Foresight', value: 'foresight' }
            ].map(({ label, value }) => {
              return (
                <FormControlLabel
                  key={value}
                  classes={classes}
                  value={value}
                  control={<Radio />}
                  label={label}
                />
              );
            })}
          </RadioGroup>
        </div>
      )}

      <PsFormControl label={label} helperText={helperText}>
        <PsInput
          multiline
          name="description"
          onChange={handleInputChange}
          rows={4}
          value={description}
        />
      </PsFormControl>

      <div className={selectedEntity ? styles.hideFields : ''}>
        <Typography className={styles.text}>
          More help: Look to{' '}
          <a
            onClick={(e: React.MouseEvent) => {
              handleEntityChange(e, 'hindsight');
            }}
          >
            hindsight
          </a>
          ,{' '}
          <a
            onClick={(e: React.MouseEvent) => {
              handleEntityChange(e, 'outsight');
            }}
          >
            outsight
          </a>{' '}
          or{' '}
          <a
            onClick={(e: React.MouseEvent) => {
              handleEntityChange(e, 'foresight');
            }}
          >
            foresight
          </a>
        </Typography>
      </div>
    </div>
  );
};

export default TypeDescriptionStep;
