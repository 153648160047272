import React, { useMemo, useState } from 'react';
import Tab from '@mui/material/Tab';
import getQueryParams from 'helpers/getQueryParams';
import { colorPalette, useIsMediumScreen, useIsSmallScreen } from '../../theme';
import {
  StyledBox,
  StyledTab,
  StyledTabContent,
  StyledTabIcon,
  StyledTabLabel,
  StyledTabs
} from './styles';

interface Tab {
  title: string;
  iconUrl: string;
  content: React.ReactNode;
}

interface TabViewProps {
  archiveStatus?: boolean;
  handleArchiveToggle?: (toggle: boolean) => void;
  initialTab?: string;
  setArchiveStatus?: React.Dispatch<React.SetStateAction<boolean>>;
  tabs: Tab[];
}

const CommunityTabsView: React.FC<TabViewProps> = ({ tabs, initialTab }) => {
  const currentTab = getQueryParams('currentTab');
  const isMediumScreen = useIsMediumScreen();

  const findTabIndexByTitle = useMemo(() => {
    return (title: string) => {
      return tabs.findIndex((tab) => tab.title === title);
    };
  }, [tabs]);

  const [activeTabIndex, setActiveTabIndex] = useState(
    findTabIndexByTitle(currentTab ? currentTab : initialTab)
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTabIndex(newValue);
  };
  const isSmallScreen = useIsSmallScreen();

  return (
    <StyledBox isMediumScreen={isMediumScreen}>
      <StyledTabs
        orientation={isMediumScreen ? 'horizontal' : 'vertical'}
        aria-label="secondary tabs example"
        indicatorColor="primary"
        onChange={handleTabChange}
        scrollButtons={true}
        TabIndicatorProps={{
          style: {
            backgroundColor: colorPalette.darkPurple,
            [isMediumScreen ? 'top' : 'left']: 0
          }
        }}
        textColor="secondary"
        value={activeTabIndex}
        variant={isSmallScreen ? 'scrollable' : 'fullWidth'}
        isMediumScreen={isMediumScreen}
      >
        {tabs?.map((tab, index) => (
          <StyledTab
            key={index}
            label={
              <StyledTabContent>
                <StyledTabIcon
                  src={tab.iconUrl}
                  alt={tab.title}
                  isMediumScreen={isMediumScreen}
                />
                <StyledTabLabel isMediumScreen={isMediumScreen}>
                  {tab.title}
                </StyledTabLabel>
              </StyledTabContent>
            }
            isMediumScreen={isMediumScreen}
          />
        ))}
      </StyledTabs>
      {tabs[activeTabIndex].content}
    </StyledBox>
  );
};

export default CommunityTabsView;
