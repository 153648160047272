import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { BackgroundLandingPage } from 'components/icons/BackgroundLandingPage';
import { BulbImage } from 'components/icons/BulbImage';
import { LogoMindminer } from 'components/icons/LogoMindminer';
import { LogoMindminerText } from 'components/icons/LogoMindminerText';
import {
  StyledAppbar,
  StyledEarnedContentTypography,
  StyledEarnedTypography,
  StyledHeaderContentContainer,
  StyledHeaderImageBox,
  StyledHeaderLearnButton,
  StyledHeaderLearnTypography,
  StyledHeaderLoginButton,
  StyledHeaderLoginTypography,
  StyledHeaderLogoBar,
  StyledHeaderMainBox,
  StyledHeaderMenu,
  StyledHeaderMenuButton,
  StyledSubHeaderMainBox,
  StyledToolbar
} from './StyledComponents';
import { useIsMediumScreen } from 'theme';
import { useDispatch } from 'react-redux';
import Actions from 'redux-state/actions';
import { GetOpenAuthModal } from 'redux-state/selectors';
import { Constants, FORM_VIEW } from 'utilities/constants';
import { AuthModal } from 'modals/AuthModal';
import { Logo } from '../error/StyledComponents';
import logo from 'assets/images/whiteLogo.svg';

export const HeaderSection = () => {
  const isMediumScreen = useIsMediumScreen();
  const dispatch = useDispatch();
  const openAuthModal = GetOpenAuthModal();

  const [formView, setFormView] = useState<string>(FORM_VIEW.LOGIN);

  const handleLoginClick = () => {
    dispatch(Actions.openAuthModal(true));
  };

  const handleAuthModalClose = () => {
    dispatch(Actions.openAuthModal(false));
  };

  return (
    <StyledHeaderMainBox>
      <StyledHeaderImageBox>
        <BackgroundLandingPage />
      </StyledHeaderImageBox>

      <StyledSubHeaderMainBox>
        <StyledAppbar>
          <StyledToolbar isMediumScreen={isMediumScreen}>
            <StyledHeaderLogoBar isMediumScreen={isMediumScreen}>
              <Logo src={logo} alt={Constants.MINDMINER} />
            </StyledHeaderLogoBar>

            <StyledHeaderMenu isMediumScreen={isMediumScreen}>
              <StyledHeaderMenuButton isMediumScreen={isMediumScreen}>
                Earn Royalties
              </StyledHeaderMenuButton>
              <StyledHeaderMenuButton isMediumScreen={isMediumScreen}>
                Leaderboards
              </StyledHeaderMenuButton>
              <StyledHeaderMenuButton isMediumScreen={isMediumScreen}>
                Support
              </StyledHeaderMenuButton>
              <StyledHeaderLoginButton
                onClick={handleLoginClick}
                isMediumScreen={isMediumScreen}
                variant="contained"
              >
                <StyledHeaderLoginTypography isMediumScreen={isMediumScreen}>
                  Log In / Sign Up
                </StyledHeaderLoginTypography>
              </StyledHeaderLoginButton>
            </StyledHeaderMenu>
          </StyledToolbar>
        </StyledAppbar>

        {openAuthModal && (
          <AuthModal
            formView={formView}
            onClose={handleAuthModalClose}
            open={openAuthModal}
            setFormView={setFormView}
          />
        )}

        <StyledHeaderContentContainer>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <StyledEarnedTypography variant="h2" gutterBottom>
                Earn From Great Ideas
              </StyledEarnedTypography>
              <StyledEarnedContentTypography variant="h6">
                MindMiner rewards great ideas sourced from you and the crowd by
                leveraging AI, the patent system, and blockchain.
              </StyledEarnedContentTypography>
              <StyledHeaderLearnButton size="large">
                <StyledHeaderLearnTypography>
                  Learn How
                </StyledHeaderLearnTypography>
              </StyledHeaderLearnButton>
            </Grid>

            {!isMediumScreen && (
              <Grid
                item
                xs={12}
                md={6}
                style={{
                  overflow: 'hidden',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <BulbImage style={{ maxWidth: '100%', height: 'auto' }} />
              </Grid>
            )}
          </Grid>
        </StyledHeaderContentContainer>
      </StyledSubHeaderMainBox>
    </StyledHeaderMainBox>
  );
};
