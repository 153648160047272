import React, { useCallback, useContext } from 'react';
import ModalWindow from 'modals/ModalWindow';
import { PsButton } from 'components/common/PsButton';
import { PsLogo } from 'components/common/PsLogo';
import { ModalContext, ModalComponentsKeys } from 'contexts/ModalContext';
import { ModalDataContext } from 'contexts/ModalDataContext';
import { SimpleModalContext } from 'contexts/SimpleModalContext';
import successImage from 'assets/images/success.png';
import styles from './SuccessModal.module.scss';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const defaultInventionTitle = 'The invention of ';

const SuccessModal: React.FC = () => {
  const { closeModal } = useContext(ModalContext);
  const { values, type, resetFields } = useContext(ModalDataContext);
  const { checkModalQueue } = useContext(SimpleModalContext);
  const theme = useTheme();
  const handleClick = useCallback(() => {
    closeModal();
    resetFields();
    setTimeout(() => {
      checkModalQueue();
    }, 1000);
  }, [closeModal, resetFields, checkModalQueue]);

  let typeName = 'Problem';
  if (type === ModalComponentsKeys.addNewSolution) {
    typeName = 'Solution';
  } else if (type === ModalComponentsKeys.addNewInvention) {
    typeName = 'Invention';
  } else if (type === ModalComponentsKeys.addNewContest) {
    typeName = 'Contest';
  } else if (type === ModalComponentsKeys.addNewChallenge) {
    typeName = 'Challenge';
  }
  let text = `Your ${typeName} Successfully Created!`;
  if (type === ModalComponentsKeys.addSolutionToInvention) {
    text = `Your successfully updated solutions for ${values.application.title} invention!`;
  } else if (type === ModalComponentsKeys.attachInventionToSolution) {
    text = `Your successfully added ${values.productLabel} solution into ${values.selectApplicationLabel} invention!`;
  } else if (type === ModalComponentsKeys.companySubscription) {
    text = `Subscription is finished. Wait for approve from Admin.`;
  } else if (type === ModalComponentsKeys.registerPopup) {
    text = `Please check the e-mail from MindMiner to access your account.`;
  }

  if (type === ModalComponentsKeys.addNewInvention) {
    const rawVal = values.titleApplication.slice(defaultInventionTitle.length);
    return (
      <ModalWindow title="Congratulations!" titleCenter>
        <div className={styles.wrapper}>
          <div className={styles.iconHolder}>
            <PsLogo size={90} color={theme.palette.primary.main} small />
            <Typography className={styles.iconHolderPrimary}>
              <span>
                Earn up to <b> +1</b> IdeaCoins{' '}
              </span>
              <br />
              You can earn and claim these IdeaCoins once you build patent
              application with this invention
            </Typography>
          </div>
          <br />
          <br />
          <Typography className={styles.textWide}>
            You created the invention of <b> {rawVal}</b> to solve the problem
            of<b> {values.mainProblemLabel}</b>
          </Typography>
          <PsButton onClick={handleClick} fullWidth>
            Close
          </PsButton>
        </div>
      </ModalWindow>
    );
  }

  if (type === ModalComponentsKeys.addNewSolution) {
    return (
      <ModalWindow title="Congratulations!" titleCenter>
        <div className={styles.wrapper}>
          <div className={styles.iconHolder}>
            <PsLogo size={90} color={theme.palette.primary.main} small />
            <Typography className={styles.iconHolderPrimary}>
              <span>
                Earn up to <b> +1</b> IdeaCoins{' '}
              </span>
              <br />
              You can claim these IdeaCoins once someone incorporates this
              solution into an invention&apos;s patent application
            </Typography>
          </div>
          <br />
          <br />
          {values.parentProblemLabel ? (
            <Typography className={styles.textWide}>
              You created the <b>{values.title}</b> solution for the problem of{' '}
              <b>{values.parentProblemLabel}</b>
            </Typography>
          ) : null}
          {values.parentSolutionLabel ? (
            <Typography className={styles.textWide}>
              You created the <b>{values.title}</b> solution for the parent
              solution of <b>{values.parentSolutionLabel}</b>
            </Typography>
          ) : null}
          <PsButton onClick={handleClick} fullWidth>
            Close
          </PsButton>
        </div>
      </ModalWindow>
    );
  }

  return (
    <ModalWindow title="Congratulations!">
      <div className={styles.wrapper}>
        <img className={styles.image} src={successImage} alt="success image" />
        <Typography className={styles.text}>{text}</Typography>
        <PsButton onClick={handleClick} fullWidth>
          Close
        </PsButton>
      </div>
    </ModalWindow>
  );
};

export default SuccessModal;
