import { Search as SearchIcon } from '@mui/icons-material';
import { Badge, Box, Button, Typography, styled } from '@mui/material';
import { colorPalette } from 'theme';

export const IdeaButton = styled(Button)<{ marginRight?: string }>(
  ({ marginRight }) => ({
    alignItems: 'center',
    background: colorPalette.lavenderMist,
    border: 'none',
    borderRadius: 10,
    cursor: 'pointer',
    display: 'flex',
    height: '3rem',
    margin: 0,
    marginRight: marginRight ?? '20px',
    maxWidth: '3rem',
    minWidth: '3rem',
    '&:active': {
      boxShadow: `0 0 .0625rem ${colorPalette.black}40`,
      marginTop: 1
    }
  })
);

export const StyledTypography = styled(Typography)({
  color: colorPalette.dimBlack,
  fontWeight: '600'
});

export const SuggestionTitle = styled(Typography)({
  display: '-webkit-box',
  lineHeight: 1.25,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: '2',
  width: '92%'
});

export const StyledSearchIconBox = styled(Box)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    alignItems: 'center',
    background: colorPalette.greenHaze,
    borderRadius: '.375rem',
    color: 'white',
    display: 'flex',
    height: '1.875rem',
    justifyContent: 'center',
    margin: 0,
    marginRight: isMediumScreen && '0.6%',
    position: isMediumScreen ? 'relative' : 'absolute',
    right: isMediumScreen ? '3.875rem' : '.625rem',
    top: '.3125rem',
    width: '1.875rem'
  })
);

export const StyledSubContainer = styled(Box)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    alignItems: isMediumScreen && 'center',
    color: colorPalette.black,
    display: 'flex',
    flexDirection: isMediumScreen ? 'row' : 'column',
    position: 'relative',
    width: '40%'
  })
);

export const StyledSearchIcon = styled(SearchIcon)({
  height: '1.375rem',
  width: '1.375rem'
});

export const EarningItem = styled(Box)({
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  marginRight: '4.9375rem',
  transition: 'transform 0.1s ease',
  '&:active': {
    transform: 'scale(0.97)'
  },
  '& img': {
    height: '2.5rem',
    marginLeft: 0,
    width: '2.5rem'
  },
  minWidth: 0,
  position: 'relative'
});

export const IdeaCoinsText = styled(Box)({
  border: `0.125rem solid ${colorPalette.goldenYellow}`,
  borderLeft: 'none',
  borderRadius: '0 1rem 1rem 0',
  display: 'flex',
  flexDirection: 'column',
  left: '0.875rem',
  marginLeft: '0.3125rem',
  minWidth: 0,
  padding: '0.25rem',
  paddingRight: '0.9375rem',
  position: 'absolute'
});

export const IdeaPointsText = styled(Box)({
  border: `0.125rem solid ${colorPalette.purple}`,
  borderLeft: 'none',
  borderRadius: '0 1rem 1rem 0',
  display: 'flex',
  flexDirection: 'column',
  left: '0.875rem',
  marginLeft: '0.3125rem',
  minWidth: 0,
  padding: '0.25rem',
  paddingRight: '0.9375rem',
  position: 'absolute'
});

export const IdeaCoinsNumber = styled(Typography)({
  color: colorPalette.black,
  fontFamily: 'Montserrat',
  fontSize: '0.9rem',
  fontWeight: 700,
  lineHeight: '0.9944rem',
  marginLeft: '1.25rem'
});

export const IdeaPointsNumber = styled(Typography)({
  color: colorPalette.black,
  fontFamily: 'Montserrat',
  fontSize: '0.9rem',
  fontWeight: 700,
  lineHeight: '0.9944rem',
  marginLeft: '1.25rem'
});

export const IdeaCoinsLabel = styled(Typography)({
  color: colorPalette.goldenYellow,
  fontSize: '9px',
  lineHeight: '0.75rem',
  marginLeft: '1.25rem',
  whiteSpace: 'nowrap'
});

export const IdeaPointsLabel = styled(Typography)({
  color: colorPalette.purple,
  fontSize: '9px',
  lineHeight: '12px',
  marginLeft: '1.25rem',
  whiteSpace: 'nowrap'
});

export const IconButtonWrapper = styled(Box)({
  marginRight: '0.5rem',
  transition: 'transform 0.1s ease',
  whiteSpace: 'nowrap',
  '&:active': {
    transform: 'scale(0.97)'
  }
});

export const StyledMobileImage = styled(Box)({
  marginRight: '0.9375rem',
  '& img': {
    height: '2.8125rem',
    width: '2.8125rem'
  }
});

export const StyledBadge = styled(Badge)({
  '& .MuiBadge-badge': {
    backgroundColor: colorPalette.red,
    color: colorPalette.white,
    fontWeight: 600
  }
});
