import { Box, Button, styled, Typography } from '@mui/material';
import { colorPalette } from 'theme';

export const Title = styled(Typography)({
  fontSize: '1.75rem',
  fontWeight: '700',
  lineHeight: '1'
});

export const TransferAmount = styled(Typography)({
  color: colorPalette.black,
  fontSize: '1.75rem',
  fontWeight: '500',
  lineHeight: '1',
  margin: '2.1875rem 0rem',
  textAlign: 'center',
  '@media (max-width:900px)': {
    margin: '1.125rem 0rem',
    fontSize: '1.5rem'
  }
});

export const BorderBox = styled(Box)<{ shadow?: boolean }>(
  ({ shadow = false }) => ({
    border: `1px solid ${colorPalette.titaniumWhite}`,
    borderRadius: '6px',
    boxShadow: shadow ? `0 0 12px ${colorPalette.black}10` : 'none',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '.625rem',
    padding: '.625rem 0'
  })
);

export const MessageText = styled(Typography)({
  color: 'red',
  fontSize: '.8438rem',
  marginBottom: '1.25rem',
  textAlign: 'center',
  '@media (max-width:900px)': {
    fontSize: '.6563rem'
  }
});

export const DescrText = styled(Typography)<{
  fontWeight?: string;
  color?: string;
}>(({ fontWeight = '500', color = colorPalette.lightBlack }) => ({
  color: color,
  fontSize: '.9375rem',
  fontWeight: fontWeight,
  lineHeight: '1',
  padding: '.5rem'
}));

export const HeadingText = styled(Typography)({
  color: colorPalette.lightBlack,
  fontSize: '.9375rem',
  fontWeight: '600',
  lineHeight: '1',
  margin: '.875rem 0rem .3125rem 0rem'
});

export const ValueText = styled(Typography)({
  color: colorPalette.lightBlack,
  fontSize: '.9375rem',
  fontWeight: '600',
  lineHeight: '1',
  padding: '.5rem'
});

export const ButtonBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%'
});

export const StyledButton = styled(Button)({
  textTransform: 'none',
  width: '48%'
});
