/* eslint-disable no-magic-numbers */
/* eslint-disable max-lines */
import {
  Avatar,
  Box,
  Button,
  Chip,
  LinearProgress,
  styled,
  TextField,
  Typography
} from '@mui/material';
import { SimpleTreeView } from '@mui/x-tree-view';
import { colorPalette } from 'theme';
import PsButton from '../common/PsButton';

export const InputProps = {
  style: {
    borderRadius: '8px',
    fontSize: '.8125rem',
    fontWeight: 400,
    marginBottom: '.625rem',
    width: '100%'
  }
};

export const StyledAvatar = styled(Avatar)<{
  size: string;
  mb?: string;
  alignSelf?: string;
}>(({ size, mb, alignSelf }) => ({
  alignSelf: alignSelf ?? 'none',
  height: size,
  marginBottom: mb ?? 'none',
  width: size
}));

export const TextFieldWrapper = styled(Box)({
  margin: '0rem .625rem',
  width: '100%'
});

export const StyledChip = styled(Chip)({
  borderColor: colorPalette.purple,
  boxShadow: `0rem 0rem .3125rem 0rem ${colorPalette.purple}45`,
  color: colorPalette.deepNavy,
  fontSize: '.8125rem',
  fontWeight: 500,
  minWidth: '170px'
});

export const ButtonWrapper = styled(Box)({
  marginBottom: '.875rem'
});

export const NodeButton = styled(Button)<{ bgColor: string }>(
  ({ bgColor }) => ({
    backgroundColor: bgColor,
    borderRadius: '3.125rem',
    color: colorPalette.white,
    textTransform: 'none',
    ':hover': {
      backgroundColor: `${bgColor}95`
    },
    '&.Mui-disabled': {
      backgroundColor: colorPalette.grey,
      color: 'white' // Optional: change icon color when disabled
    }
  })
);

export const MessageWrapper = styled(Box)<{
  isUser: boolean;
  showBackground: boolean;
}>(({ isUser, showBackground }) => ({
  backgroundColor: isUser
    ? colorPalette.cloudSilver
    : showBackground
      ? colorPalette.fairLavender
      : 'transparent',
  borderRadius: '.625rem',
  padding: '.75rem',
  width: '100%'
}));

export const MessageText = styled(Typography)({
  color: colorPalette.deepNavy,
  fontSize: '.8125rem',
  fontWeight: 500,
  whiteSpace: 'pre-line'
});

export const MessageHeading = styled(Typography)({
  color: colorPalette.deepNavy,
  fontSize: '1rem',
  fontWeight: 600,
  whiteSpace: 'pre-line',
  textAlign: 'center'
});

export const WhiteSpace = styled(Box)({
  minWidth: '2.8125rem',
  width: '2.8125rem'
});

export const ActionButtonWrapper = styled(Box)<{ marginTop?: string }>(
  ({ marginTop }) => ({
    display: 'flex',
    justifyContent: 'center',
    marginTop: marginTop ?? '0'
  })
);

export const BotAvatarWrapper = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-end',
  minWidth: '2.8125rem',
  width: '2.8125rem'
});

export const UserAvatarWrapper = styled(Box)({
  minWidth: '2.8125rem',
  width: '2.8125rem'
});

export const ChipWrapper = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '14px',
  justifyContent: 'flex-start'
});

export const StyledSaveButton = styled(PsButton)({
  borderRadius: '6px !important',
  color: colorPalette.white,
  fontSize: '1rem !important',
  fontWeight: 'bold',
  height: '2.8125rem',
  outline: 'none',
  padding: '.5rem 0rem!important',
  width: '11.875rem'
});

export const LoaingWrapper = styled(Box)({
  position: 'relative',
  zIndex: 999
});

export const LoaingProgress = styled(LinearProgress)({
  size: 5,
  thickness: 2
});

export const LoaingText = styled(Typography)({
  color: 'gray',
  fontSize: '.7813rem',
  height: '1.25rem',
  textAlign: 'center'
});

export const HeaderWrapper = styled(Box)({
  height: '3.375rem',
  backgroundColor: 'purple'
});

export const ChatContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  overflowY: 'auto',
  width: '100%'
});

export const CoversionWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'flex-end',
  padding: '.0313rem 1rem .625rem 1rem'
}));

export const CoversionPaper = styled(Box)({
  overflowY: 'auto',
  paddingTop: '.5rem',
  width: '100%',
  '&::-webkit-scrollbar': {
    display: 'none'
  }
});

export const HeaderImg = styled(Avatar)({
  height: '1.875rem',
  width: '1.875rem'
});

export const HeaderTextWrapper = styled(Box)({
  width: '90%'
});

export const HeaderText = styled(Typography)({
  alignItems: 'center',
  color: colorPalette.gray,
  display: '-webkit-box',
  fontWeight: 600,
  gap: '0.3rem',
  lineClamp: 3,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 3,
  whiteSpace: 'normal'
});

export const LinkWrapper = styled(Typography)({
  color: colorPalette.gray,
  display: 'inline',
  fontWeight: 700
});

export const InputWrapper = styled(Box)<{ disabled?: boolean }>(
  ({ disabled }) => ({
    alignItems: 'self-end',
    display: 'flex',
    height: '3.75rem',
    justifyContent: 'space-between',
    padding: '.625rem 1rem 0rem 1rem',
    opacity: disabled ? 0.7 : 1,
    pointerEvents: disabled ? 'none' : 'auto'
  })
);

export const ChatContainer = styled(Box)({
  borderLeft: `.1875rem solid ${colorPalette.lightGreen}`,
  height: '100%',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
});

export const HeaderMainBox = styled(Box)({
  display: 'flex',
  flexShrink: 0,
  gap: '.75rem',
  justifyContent: 'left',
  padding: '1rem'
});

export const MainMsgBox = styled(Box)<{ isUser: boolean; type: string }>(
  ({ isUser, type }) => ({
    alignItems: type === 'action' ? 'center' : 'flex-start',
    display: 'flex',
    gap: '.625rem',
    justifyContent: isUser ? 'flex-end' : 'flex-start',
    marginBottom: '.875rem',
    width: '100%'
  })
);

export const CustomTreeView = styled(SimpleTreeView)<{
  leftPad: boolean;
}>(({ leftPad, theme }) => ({
  [theme.breakpoints.up('xl')]: {
    paddingLeft: leftPad ? '33.6px ' : 0
  },
  [theme.breakpoints.up('md')]: {
    paddingLeft: leftPad ? '16px ' : 0
  },
  flexGrow: 1,
  paddingLeft: '0',
  position: 'relative',
  width: '100%',
  '& > li': {
    ':first-child': {
      '&:before': {
        borderLeft: '0px'
      }
    }
  }
}));

export const StyledTextField = styled(TextField)({
  borderRadius: '26px',
  width: '100%',
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: colorPalette.purple,
      boxShadow: `0px 0px 10px 0px ${colorPalette.purple}50`
    }
  }
});

export const StyledBodyFieldText = styled(TextField)({
  borderRadius: '8px',
  width: '100%'
});

export const StyledBodyTypography = styled(Typography)({
  color: `${colorPalette.lightBlack} !important`,
  fontSize: '.9375rem !important',
  fontWeight: '600 !important',
  paddingBottom: '.1875rem'
});
