import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef
} from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';

import { SearchRow, NavBar, ContentBar } from '../../layout';
import { getQuery } from '../../helpers';
import { PsTheme } from '../../theme';
import useRouter from '../../hooks/useRouter';

import { CardPriorArt, PriorArt } from '../../components/CardPriorArt';
import { PsButton } from '../../components/common/PsButton';
import { AuthContext } from '../../contexts/AuthContext';
import { DataContext } from '../../contexts/DataContext';
import { makeStyles } from 'tss-react/mui';
import { SearchFilter, SearchFilterTypes } from '../components/SearchFilter';

const PAGE_DEFAULT = 1;
const PER_PAGE_DEFAULT = 10;
const SORT_BY_DEFAULT = 'votes';

type ClassKey =
  | 'container'
  | 'wrapper'
  | 'loading'
  | 'listWrapper'
  | 'header'
  | 'itemsSelectWrapper'
  | 'itemsSelect'
  | 'loadMoreWrapper';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxWidth: 1255,
      margin: '0 auto',
      padding: '0 10px'
    },
    wrapper: {
      display: 'flex'
    },
    loading: {
      marginBottom: 10,
      textAlign: 'center'
    },
    listWrapper: {
      padding: '48px 0',
      paddingTop: 6
    },
    header: {
      display: 'flex',
      justifyContent: 'flex-end',
      flexWrap: 'wrap'
    },
    itemsSelectWrapper: {
      justifyContent: 'flex-end',
      display: 'flex',
      alignItems: 'center',
      marginBottom: 22,
      marginLeft: 15,
      fontSize: 14,
      lineHeight: '20px',
      textAlign: 'center',
      fontFeatureSettings: '"pnum" on, "onum" on',
      color: '#000000',
      '& > span': {
        flexShrink: 0
      }
    },
    itemsSelect: {
      marginLeft: 8,
      height: 32,
      padding: 0,
      overflow: 'hidden'
    },
    loadMoreWrapper: {
      textAlign: 'center'
    }
  };
});

type ProductsListProps = {
  classes?: any;
  server?: { data: Array<PriorArt>; total: number };
};

const ProductsListView = ({ server, ...rest }: ProductsListProps) => {
  const state = useRef({ resetPages: true, initRender: true });
  const { user } = useContext(AuthContext);
  const { dataProvider, applicationsListCache, filterTags } =
    useContext(DataContext);
  const { classes } = useStyles();

  const router = useRouter();

  const query = getQuery();
  const initPage = parseInt(query.p, 10) || PAGE_DEFAULT;
  const initPerPage = parseInt(query.pp, 10) || PER_PAGE_DEFAULT;
  const initSortBy = query.s || SORT_BY_DEFAULT;
  const filterTagsStr = query.tags;

  const [dataChunks, setDataChunks] = useState<Array<Array<PriorArt>>>(
    server ? [server.data] : []
  );
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(initPage);
  const [perPage, setPerPage] = useState<number>(initPerPage);
  const [sortBy, setSortBy] = useState<string>(initSortBy);
  const [totalItems, setTotalItems] = useState(server ? server.total : 0);

  let showLoadMore = false;
  if (totalItems && page * perPage < totalItems) {
    showLoadMore = true;
  }

  useEffect(() => {
    const fetchData = async () => {
      const filter: { $custom: any; tags?: any } = {
        $custom: { type: 'forUi' }
      };
      if (query.tags) {
        filter.tags = query.tags.split(',');
      }
      const data = await dataProvider.getList<PriorArt>('prior-arts', {
        pagination: {
          page: query.p || PAGE_DEFAULT,
          perPage: query.pp || PER_PAGE_DEFAULT
        },
        sort: { field: query.s || SORT_BY_DEFAULT, order: 'DESC' },
        filter
      });
      setDataChunks(data as unknown as PriorArt[][]);
      setTotalItems(data.data.length);

      return data;
    };

    fetchData()
      .catch((error: Error) => {
        console.error(error);
      })
      .finally(() => {
        state.current.resetPages = true;
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (state.current.initRender) {
      state.current.initRender = false;
      return;
    }

    setLoading(true);
    const filter: { $custom: any; tags?: any } = {
      $custom: { type: 'forUi' }
    };
    if (filterTags && filterTags.length) {
      filter.tags = filterTags;
    }
    dataProvider
      .getList<PriorArt>('prior-arts', {
        pagination: { page, perPage },
        sort: { field: sortBy, order: 'DESC' },
        filter
      })
      .then(({ data, total }: any) => {
        if (state.current.resetPages) {
          setDataChunks([data]);
        } else {
          setDataChunks([...dataChunks, data]);
        }
        setTotalItems(total);
        state.current.resetPages = true;
      })
      .catch((err: any) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [page, perPage, sortBy, filterTagsStr, applicationsListCache, user]);

  const onPerPageChange = useCallback(
    (e: SelectChangeEvent<{ value: unknown }>) => {
      state.current.resetPages = true;
      state.current.initRender = false;
      if (e.target.value) {
        const numValue = parseInt('' + e.target.value);
        setPerPage(numValue);
        setPage(1);
        const { pp, ...newQuery } = getQuery();
        if (numValue && numValue !== PER_PAGE_DEFAULT) {
          newQuery.pp = `${numValue}`;
        }
        router.replace(
          `${router.pathname}?${new URLSearchParams(newQuery).toString()}`
        );
        setLoading(true);
      }
    },
    [setPerPage, setPage]
  );

  const onSortByChange = useCallback(
    (e: SelectChangeEvent<{ value: unknown }>) => {
      state.current.resetPages = true;
      state.current.initRender = false;
      if (e.target.value) {
        const strVal = `${e.target.value}`;
        setSortBy(strVal);
        setPage(1);
        const { s, ...newQuery } = getQuery();
        if (strVal && strVal !== SORT_BY_DEFAULT) {
          newQuery.s = strVal;
        }
        router.replace(
          `${router.pathname}?${new URLSearchParams(newQuery).toString()}`
        );
        setLoading(true);
      }
    },
    [setSortBy, setPage]
  );

  const onLoadMoreClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      state.current.resetPages = false;
      state.current.initRender = false;
      setPage(page + 1);
    },
    [page, setPage]
  );

  return (
    <div className={classes.container}>
      <header>
        <title>MindMiner - Prior Arts</title>
      </header>

      <SearchRow />

      <div className={classes.wrapper}>
        <NavBar />

        <ContentBar className={classes.listWrapper}>
          <div className={classes.header}>
            <SearchFilter
              onChange={onSortByChange}
              type={SearchFilterTypes.RATING}
              value={sortBy}
              title="Sort by:"
            />
            <SearchFilter
              onChange={onPerPageChange}
              type={SearchFilterTypes.PER_PAGE_ITEMS}
              value={perPage}
              title="Cards on page:"
            />
          </div>
          {Array.isArray(dataChunks) &&
            dataChunks.map((chunk) => {
              return chunk.map((item) => (
                <CardPriorArt key={item.id} priorArt={item} />
              ));
            })}
          {loading ? (
            <Typography className={classes.loading} variant="body2">
              Loading...
            </Typography>
          ) : null}
          {showLoadMore ? (
            <div className={classes.loadMoreWrapper}>
              <Link to={`${router.pathname}?p=${page + 1}`}>
                <PsButton onClick={onLoadMoreClick} component="a">
                  Load More
                </PsButton>
              </Link>
            </div>
          ) : null}
        </ContentBar>
      </div>
    </div>
  );
};

export const ProductsList = styled(ProductsListView)({});

export default ProductsList;
