import { Box, ToggleButton, ToggleButtonProps, styled } from '@mui/material';
import { colorPalette } from 'theme';

interface StyledToggleButtonProps extends ToggleButtonProps {
  active: boolean;
}

export const ButtonContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  padding: '15px'
});

export const StyledArchiveBox = styled(Box)({
  borderRadius: '10px',
  display: 'flex',
  height: '40px',
  marginRight: '5px',
  marginTop: '5px',
  outline: `0.1px solid ${colorPalette.black}33`,
  position: 'relative',
  width: '150px'
});

export const StyledToggleButtonArchive = styled(
  ToggleButton
)<StyledToggleButtonProps>(({ active }) => ({
  backgroundColor: active ? colorPalette.purple : 'transparent',
  border: '0px',
  borderRadius: '10px 0 0 10px',
  color: active ? colorPalette.white : `${colorPalette.black}99`,
  flex: '1',
  outline: 'none',
  '&::after': {
    backgroundColor: `${colorPalette.black}99`,
    bottom: '0%',
    content: '""',
    position: 'absolute',
    right: '0',
    top: '0%',
    width: '0.1px'
  },
  '&:hover': {
    backgroundColor: active ? colorPalette.purple : 'transparent'
  }
}));

export const StyledToggleButtonUnarchive = styled(
  ToggleButton
)<StyledToggleButtonProps>(({ active }) => ({
  backgroundColor: active ? colorPalette.purple : 'transparent',
  border: '0px',
  borderRadius: '0 10px 10px 0',
  color: active ? colorPalette.white : `${colorPalette.black}99`,
  flex: '1',
  outline: 'none',
  '&:hover': {
    backgroundColor: active ? colorPalette.purple : 'transparent'
  }
}));
