import React, {
  useCallback,
  useEffect,
  useMemo,
  useContext,
  useState
} from 'react';
import { Box, LinearProgress, Typography, styled } from '@mui/material';
import { useDispatch } from 'react-redux';
import InventionIcon from 'assets/icons/invention.svg';
import AuthContext from 'contexts/AuthContext';
import { Application } from 'components/CardApplication';
import useRouter from 'hooks/useRouter';
import {
  GetLoader,
  GetRelatedInventions,
  GetRelatedInventionsCount
} from 'redux-state/commons/selectors';
import Actions from 'redux-state/actions';
import { Constants } from 'utilities/constants';
import { Profile } from '../CardProfile';
import { Problem } from '../CardProblem';
import { Solution } from '../CardSolution';
import { GetActions } from '../CustomMenu/actions';
import { CustomMenu } from '../CustomMenu';
import DataNotFound from '../DataNotFound';
import { ListItemView } from '../ListItemView';
import TableView from '../TableView';
import { getHeaders } from '../TableView/getHeaders';
import { colorPalette } from '../../theme';

interface InventionViewProps {
  profile?: Profile;
  paginationOptions?: {
    page: number;
    rowsPerPage: number;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  };
}

const StyledBox = styled(Box)({
  backgroundColor: colorPalette.white,
  height: 'auto',
  padding: '15px',
  width: '100%'
});

interface ListItemViewProps {
  expandedId: string;
  id: string;
  problems?: Array<Problem>;
  setExpandedId: React.Dispatch<React.SetStateAction<boolean>>;
  solutions?: Array<Solution>;
  type: 'problems' | 'solutions';
}

const InventionView: React.FC<InventionViewProps> = ({
  profile,
  paginationOptions
}) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { key } = router.query;

  const inventions = GetRelatedInventions();
  const inventionsCount: number = GetRelatedInventionsCount() || 0;
  const loader = GetLoader();

  const { user } = useContext(AuthContext);
  const [expandedId, setExpandedId] = useState();

  const { page, rowsPerPage, setPage, setRowsPerPage } = paginationOptions;
  const pagination = useMemo(
    () => ({
      page: page,
      perPage: rowsPerPage
    }),
    [page, rowsPerPage]
  );

  const isCurrentUser = !!(user && (user.id === profile?.id || user?.isAdmin));

  useEffect(() => {
    dispatch(
      Actions.getRelatedItems({
        resource: 'tags',
        key: key as string,
        item: Constants.APPLICATIONS,
        pagination
      })
    );
  }, [dispatch, key, pagination]);

  useEffect(() => {
    if (inventions?.length < 1) {
      setPage(0);
    }
  }, [inventions, setPage]);

  const Item = useCallback(
    ({
      type,
      problems,
      solutions,
      id,
      expandedId,
      setExpandedId
    }: ListItemViewProps) => {
      return (
        <ListItemView
          problems={problems}
          solutions={solutions}
          type={type}
          id={id}
          expandedId={expandedId}
          setExpandedId={setExpandedId}
        />
      );
    },
    []
  );

  const headers: Array<string> = useMemo(() => {
    return getHeaders(Constants.COMMUNITY_INVENTION, user, isCurrentUser);
  }, [isCurrentUser, user]);

  const getActions = useMemo(() => {
    return (item: Application) => {
      return GetActions({
        item,
        profile,
        type: 'Community',
        isFiled: true,
        pagination
      });
    };
  }, [pagination, profile]);

  const hasEmptyData = inventions.length == 0;

  const getCustomItem = (item) => {
    return item.problems ? item.problems : item.problem ? [item.problem] : [];
  };

  const getCells = useMemo(() => {
    return (icon, item) => {
      const baseCells = {
        name: (
          <Typography width={150} variant="body2">
            {item.teaser}
          </Typography>
        ),
        items: (
          <Box>
            <Item
              type="problems"
              problems={getCustomItem(item)}
              expandedId={expandedId}
              id={item.id}
              setExpandedId={setExpandedId}
            />
            <Item
              type="solutions"
              solutions={item.solutions ? item.solutions : item.selected}
              expandedId={expandedId}
              id={item.id}
              setExpandedId={setExpandedId}
            />
          </Box>
        ),
        ideaPoints: <Typography>{item.ideaPoints ?? 0}</Typography>
      };
      if (user) {
        baseCells['actions'] = (
          <CustomMenu
            showPin={false}
            actions={getActions(item)}
            isFiled={true}
            item={item}
            itemType={'applications'}
            pagination={pagination}
            type={'Community'}
            tagKey={key as string}
            title={'invention'}
          />
        );
      }
      return baseCells;
    };
  }, [Item, expandedId, user, getActions, pagination, key]);

  const rows = {
    component: (icon, item) => getCells(icon, item),
    items: inventions,
    pinnedItems: []
  };

  return (
    <StyledBox>
      {loader && <LinearProgress value={inventionsCount} />}
      {!loader && inventionsCount === 0 && (
        <DataNotFound iconUrl={InventionIcon} text="invention" />
      )}
      {!hasEmptyData && (
        <TableView
          headers={headers}
          page={page}
          rows={rows}
          rowsPerPage={rowsPerPage}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          totalRows={inventionsCount}
          type={'inventions'}
        />
      )}
    </StyledBox>
  );
};

export default InventionView;
