import React, { useCallback, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { styled, useTheme } from '@mui/material/styles';

import { DislikeIcon } from 'components/icons/DislikeIcon';
import { LikeIcon } from 'components/icons/LikeIcon';
import { AuthContext, Profile } from 'contexts/AuthContext';
import { DataContext } from 'contexts/DataContext';
import Actions from 'redux-state/actions';
import { PsTheme } from 'theme';
import { makeStyles } from 'tss-react/mui';

type ClassKey = 'root' | 'viewOnly' | 'voteButton';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {},
    viewOnly: {
      pointerEvents: 'none'
    },
    voteButton: {
      display: 'inline-flex',
      alignItems: 'center',
      color: '#677890',
      marginLeft: 50,
      '& > button': {
        background: 'none',
        border: 'none',
        outline: 'none',
        margin: '0',
        padding: '0',
        cursor: 'pointer',
        appearance: 'none'
      },
      '& > span': {
        paddingLeft: 10,
        fontSize: 18,
        lineHeight: '20px'
      }
    }
  };
});

export type LikeDislikeProps = {
  className?: string;
  resource: string;
  itemId: string | number;
  likes: number;
  dislikes: number;
  user?: Profile;
};

const LikeDislikeView = ({
  className,
  resource,
  itemId,
  likes: initLikes = 0,
  dislikes: initDislikes = 0,
  user
}: LikeDislikeProps) => {
  const { refreshUserBalance } = useContext(AuthContext);
  const { dataProvider } = useContext(DataContext);
  const dispatch = useDispatch();
  const [likes, setLikes] = useState(initLikes);
  const [dislikes, setDislikes] = useState(initDislikes);
  const { classes } = useStyles();
  const voteItem = useCallback(
    (type: number) => {
      if (user) {
        dataProvider
          .voteItem(resource, itemId, type)
          .then((res) => {
            setLikes(res.likes);
            setDislikes(res.dislikes);
            refreshUserBalance();
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        dispatch(Actions.openAuthModal(true));
      }
    },
    [user, resource, itemId, refreshUserBalance]
  );

  const onLikeClick = (e: React.MouseEvent) => {
    e.preventDefault();
    voteItem(1);
  };

  const onDislikeClick = (e: React.MouseEvent) => {
    e.preventDefault();
    voteItem(-1);
  };

  let rootClassName = classes.root;
  if (className) {
    rootClassName += ' ' + className;
  }
  if (!user) {
    rootClassName += ' ' + classes.viewOnly;
  }

  return (
    <span className={rootClassName}>
      <span className={classes.voteButton}>
        <button onClick={onLikeClick}>
          <LikeIcon />
        </button>
        <span>{likes}</span>
      </span>
      <span className={classes.voteButton}>
        <button onClick={onDislikeClick}>
          <DislikeIcon />
        </button>
        <span>{dislikes}</span>
      </span>
    </span>
  );
};

export const LikeDislike = styled(LikeDislikeView)({});

export default LikeDislike;
