import * as React from 'react';
interface InventionIconProps {
  width?: number | string;
  height?: number | string;
}

export const ProductIconWithContainer: React.FC<InventionIconProps> = ({
  width = '86',
  height = '86',
  ...props
}) => (
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      {...props}
    >
      <circle cx={42.595} cy={42.595} r={42.595} fill="#E5DAFF" />
      <path
        fill="#6330E1"
        d="M60.964 41.117v7.816c0 1.636-.658 3.01-2.025 3.823-4.668 2.774-9.383 5.47-14.109 8.148-1.504.85-3.12.73-4.586-.096-4.492-2.536-8.946-5.14-13.414-7.715-1.74-1.004-2.544-2.483-2.525-4.514.044-4.937.036-9.874.003-14.809-.014-2.138.916-3.677 2.725-4.712 4.281-2.45 8.57-4.885 12.863-7.314 1.913-1.084 3.782-1.023 5.705.096 4.142 2.413 8.321 4.765 12.496 7.122 1.915 1.081 2.939 2.637 2.898 4.89-.044 2.421-.011 4.844-.011 7.268h-.02v-.003ZM42.74 23.877c-.576-.028-1.067.222-1.545.49-3.735 2.106-7.46 4.23-11.203 6.324-.968.543-.922.98-.008 1.51 3.908 2.264 7.813 4.53 11.705 6.82.664.389 1.216.408 1.89.007 3.851-2.272 7.726-4.503 11.585-6.76 1.177-.688 1.183-.965.033-1.621-3.763-2.149-7.534-4.284-11.299-6.43-.365-.209-.73-.376-1.16-.34h.002Zm-1.63 26.027c0-2.286-.025-4.572.014-6.858.013-.733-.24-1.183-.868-1.542a1750.698 1750.698 0 0 1-11.858-6.834c-.82-.477-1.224-.444-1.219.656.025 4.388.009 8.777.014 13.168 0 .988.409 1.75 1.293 2.253 3.847 2.184 7.695 4.374 11.534 6.575.862.494 1.112.214 1.098-.7-.03-2.239-.01-4.478-.01-6.72l.002.002Zm17.004-7.9c0-2.194-.027-4.386.011-6.579.02-1.103-.307-1.284-1.257-.724-3.927 2.316-7.868 4.61-11.825 6.874-.645.37-.868.804-.865 1.529a954.85 954.85 0 0 1 0 13.434c-.01 1.19.395 1.202 1.27.697 3.678-2.11 7.364-4.21 11.066-6.28 1.175-.655 1.641-1.602 1.611-2.92-.047-2.008-.011-4.02-.014-6.032h.003Z"
      />
    </svg>
  </div>
);
