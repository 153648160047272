import { Box, Typography, styled } from '@mui/material';
import { colorPalette } from 'theme';

export const StyledGalleryContainer = styled(Box)({
  textAlign: 'center',
  width: '100%',
  maxWidth: '600px',
  margin: 'auto'
});

export const StyledImageTitle = styled(Typography)({
  variants: 'subtitle1',
  fontWeight: 'bold',
  color: colorPalette.white
});

export const StyledMainImageContainer = styled(Box)({
  position: 'relative',
  width: '100%',
  height: '400px',
  borderRadius: '8px',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  overflow: 'hidden',
  marginBottom: '16px',
  cursor: 'pointer'
});

export const StyledImageOverlay = styled(Box)({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  background: 'linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0))',
  padding: '10px',
  boxSizing: 'border-box'
});

export const StyledThumbnailContainer = styled(Box)(
  ({ selected }: { selected: boolean }) => ({
    padding: '5px',
    cursor: 'pointer',
    borderRadius: '8px',
    transition: 'all 0.3s ease',
    border: selected
      ? `2px solid ${colorPalette.purple}`
      : '2px solid transparent'
  })
);
