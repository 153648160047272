export const CREATE_CONTEST = 'CREATE_CONTEST';
export const GET_CONTESTS = 'GET_CONTESTS';
export const GET_CONTESTS_SUCCESS = 'GET_CONTESTS_SUCCESS';
export const GET_PROBLEMS_FROM_ID = 'GET_PROBLEMS_FROM_ID';
export const GET_SOLUTIONS_WITH_OWNER_INFO = 'GET_SOLUTIONS_WITH_OWNER_INFO';
export const SET_CONTEST_DATA = 'SET_CONTEST_DATA';
export const SET_PROBLEMS = 'SET_PROBLEMS';
export const SET_SOLUTIONS_WITH_OWNER_INFO = 'SET_SOLUTIONS_WITH_OWNER_INFO';
export const TOGGLE_LOADER_LEADERBOARD = 'TOGGLE_LOADER_LEADERBOARD';
export const UPDATE_CONTEST = 'UPDATE_CONTEST';
export const GENERATE_AI_COVER_IMAGE = 'GENERATE_AI_COVER_IMAGE';
export const GENERATE_AI_COVER_IMAGE_SUCCESS =
  'GENERATE_AI_COVER_IMAGE_SUCCESS ';
export const GENERATE_AI_COVER_IMAGE_FAIL = 'GENERATE_AI_COVER_IMAGE_FAIL';
export const GENERATE_AI_DESCRIPTION = 'GENERATE_AI_DESCRIPTION';
export const GENERATE_AI_DESCRIPTION_SUCCESS =
  'GENERATE_AI_DESCRIPTION_SUCCESS';
export const GENERATE_AI_DESCRIPTION_FAIL = 'GENERATE_AI_DESCRIPTION_FAIL';
export const GENERATE_AI_IMAGE = 'GENERATE_AI_IMAGE';
export const GENERATE_AI_IMAGE_SUCCESS = 'GENERATE_AI_IMAGE_SUCCESS';
export const GENERATE_AI_IMAGE_FAIL = 'GENERATE_AI_IMAGE_FAIL';
