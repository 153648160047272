import queryString from 'query-string';
import Config from 'config/config';
import { CustomError } from 'helpers/CustomError';
import { getToken, removeToken } from 'helpers/common';
import { toastify } from 'pages/newContests/toastify';
import auth, { AuthProvider } from './authProvider';
import { FetchUtils } from './fetchUtils';
import { Item as TreeItem, flatList } from './helpers';
import {
  Constants,
  END_POINTS,
  ERRORS,
  PATH_NAMES,
  RESOURCE,
  TOASTIFY_DURATION,
  VARIANT
} from './utilities/constants';

export type PsOptionType = {
  label: string;
  value: string;
};

export type PayOption = {
  id: string | number;
  type: string;
  sortIndex: number;
  title: string;
  subTitle?: string;
  price: string;
  priceInfo?: string;
  documentTitle: string;
  documentTemplate: string;
  subProducts: Array<PayOption>;
};

export type PayProduct = {
  productId: string | number;
  status: string;
  payStatus: string;
  templateId: string;
  templateId2: string;
  title: string;
};

export type SubscriptionOption = {
  id: string | number;
  type: string;
  price: string;
};

export type ActivitySeen = {
  activityId: string | number;
  seen: boolean;
};

export type CreditsPrice = {
  id: string;
  count: number;
  price: number;
  priceStr: string;
};

let API_URL = Config.NEXT_PUBLIC_API_URL;
if (typeof window === 'undefined') {
  // SSR
  API_URL = Config.NEXT_PUBLIC_API_URL_IN;
}

export type Identifier = string | number;

export interface PsRecord {
  id: Identifier;
  [key: string]: any;
}

export interface SortPayload {
  field: string;
  order: string | number;
}
export interface FilterPayload {
  [k: string]: any;
}
export interface PaginationPayload {
  page: number | string;
  perPage: number | string;
}

export interface GetListParams {
  filter?: any;
  itemType?: string;
  pagination?: PaginationPayload;
  sort?: SortPayload;
}

export interface GetListResult<RecordType = PsRecord> {
  data: RecordType[];
  pinnedItems?: RecordType[];
  total: number;
  updatePinItems?: boolean;
  totalActivities?: number;
}

export interface GetUserRankResult {
  rank: number;
}

export interface GetUserIdeaPointsResult {
  ideaPoints: number;
}

export interface GetProfileItemsCount {
  count?: number;
}

export interface GetManyParams {
  ids: Identifier[];
}
export interface GetManyResult<RecordType = PsRecord> {
  data: RecordType[];
  total: number;
}

export interface GetOneParams {
  id: Identifier;
}
export interface GetOneByKeyParams {
  key: string;
  pagination?: {
    page: number;
    perPage: number;
  };
  [key: string]: any;
}
export interface GetOneResult<RecordType = PsRecord> {
  data: RecordType;
}

export interface getRelatedItemsParams {
  key: string;
  item: string;
  pagination: {
    page: number;
    perPage: number;
  };
  [key: string]: any;
}

export interface CreateParams<T = any> {
  data: T;
}
export interface CreateResult<RecordType = PsRecord> {
  data: RecordType;
}

export interface UpdateParams<T = any> {
  id?: Identifier;
  data: T;
}

export interface FollowTag {
  tagId: string | number;
  followers: string | number;
  actionType: string;
}

export interface UpdateResult<RecordType = PsRecord> {
  data: RecordType;
}

export interface DeleteSocialAuthKeyParams {
  id: string | number;
}
export interface DeleteParams<T = any> {
  id: Identifier;
}
export interface DeleteResult<RecordType = PsRecord> {
  data: RecordType;
}

const prepareQueryString = (
  params: Partial<GetListParams>
): Record<string, string> => {
  const { page = 1, perPage = 10 } = params.pagination || {};
  const { field = 'createdAt', order = 'ASC' } = params.sort || {};
  let rangeStart, rangeEnd;
  if (
    params?.filter?.$custom?.category == 'profile' ||
    params?.filter?.$custom?.category == 'tag' ||
    params?.filter?.$custom?.category == 'public' ||
    params?.filter?.$custom?.category == 'tagUser' ||
    params?.filter?.$custom?.category == Constants.REWARD ||
    params?.filter?.$custom?.category == Constants.NOTIFICATION
  ) {
    rangeStart = (page as number) * (perPage as number);
    rangeEnd = ((page as number) + 1) * (perPage as number) - 1;
  } else {
    rangeStart = ((page as number) - 1) * (perPage as number);
    rangeEnd = (page as number) * (perPage as number) - 1;
  }
  const query = {
    sort: JSON.stringify([field, order]),
    range: JSON.stringify([rangeStart, rangeEnd]),
    filter: JSON.stringify(params.filter)
  };
  return query;
};

interface DataProviderI {
  getList: <RecordType extends PsRecord = PsRecord>(
    resource: string,
    params: GetListParams
  ) => Promise<GetListResult<RecordType>>;
  getMany: <RecordType extends PsRecord = PsRecord>(
    resource: string,
    params: GetManyParams
  ) => Promise<GetManyResult<RecordType>>;
  getOne: <RecordType extends PsRecord = PsRecord>(
    resource: string,
    params: GetOneParams
  ) => Promise<GetOneResult<RecordType>>;
  create: <RecordType extends PsRecord = PsRecord>(
    resource: string,
    params: CreateParams
  ) => Promise<CreateResult<RecordType>>;
  update: <RecordType extends PsRecord = PsRecord>(
    resource: string,
    params: UpdateParams
  ) => Promise<UpdateResult<RecordType>>;
  getTagContributors: <RecordType extends PsRecord = PsRecord>(
    params: GetOneParams
  ) => Promise<GetListResult<RecordType>>;
  getProfileStats: <RecordType extends PsRecord = PsRecord>(
    params: GetOneParams
  ) => Promise<GetOneResult<RecordType>>;
  getSolutions: <RecordType extends PsRecord = PsRecord>(
    key: string
  ) => Promise<GetListResult<RecordType>>;
}

export class DataProvider implements DataProviderI {
  auth: AuthProvider;

  private userId = '';

  private serverJwtToken: string | undefined;

  constructor({ auth }: { auth: AuthProvider }) {
    this.auth = auth;
    // Disabled socket for testing purposes
    // this.socket = io(Config.NEXT_PUBLIC_WS_URL as string, {
    //   withCredentials: true
    // });

    // this.socket.on('connection', () => {
    //   this.socket.emit('listen', this.userId);
    // });
  }

  private getResourceName(part: string): string {
    return part;
  }

  private async makeRequest(request: {
    url: string;
    options?: RequestInit;
    throwError?: boolean;
  }): Promise<any> {
    const jwtToken = getToken();
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    if (jwtToken) {
      headers.set('Authorization', `Bearer ${jwtToken}`);
    }
    try {
      const res = await FetchUtils.fetchJson(request.url, {
        ...request.options,
        mode: 'cors',
        headers
      });
      return res;
    } catch (error) {
      const newError = new CustomError(
        error.error || error.message,
        error.status
      );

      const errorMsg =
        error.status == 500 ? ERRORS.UNEXPECTED_ERROR : error.message;

      const isUnauthorized = error.status == 401;
      if (isUnauthorized) {
        removeToken();
      }
      if (isUnauthorized || request.throwError) {
        toastify(
          errorMsg,
          VARIANT.ERROR,
          VARIANT.TOP_CENTER,
          TOASTIFY_DURATION
        );
        throw newError;
      }
    }
  }

  onSocket(eventName: string, cb: (data?: any) => void) {
    // Disabled socket for testing purposes
    return;
  }

  offSocket(eventName: string, cb: (data?: any) => void) {
    // Disabled socket for testing purposes
    return;
  }

  listenUser(userId: string): void {
    // Disabled socket for testing purposes
    return;
    this.userId = userId;
  }

  async configureSSR() {
    try {
      const token = getToken();
      this.serverJwtToken = token || '';
    } catch (err) {
      console.error(err);
    }
  }

  async getList<T>(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult<T>> {
    const resourceName = this.getResourceName(resource);
    const query = prepareQueryString(params);
    const request = {
      url: `${API_URL}/${resourceName}?${queryString.stringify(query)}`
    };
    const data = await this.makeRequest(request);
    return {
      data: data.data,
      total: data.total,
      totalActivities: data.totalActivities,
      pinnedItems: data.pinnedItems ?? [],
      updatePinItems: data.updatePinItems ?? false
    };
  }

  async checkImprovedProgress(conceptId): Promise<any> {
    const request = {
      url: `${API_URL}/${RESOURCE.APPLICATIONS}/${Constants.CHECKED_IMOROVED_PROGRESS}/${conceptId}`
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async updateCtaModal(payload): Promise<any> {
    const request = {
      url: `${API_URL}/profiles/updateCtaModalDisplay`,
      options: { method: 'POST', body: JSON.stringify(payload) }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async getSolutions<T>(key: string): Promise<GetListResult<T>> {
    const request = {
      url: `${API_URL}/${Constants.SOLUTIONS_BY_IDS}?key=${key}`
    };

    const data = await this.makeRequest(request);
    return {
      data: data || [],
      total: data?.length
    };
  }

  async getOne<T>(
    resource: string,
    params: GetOneParams
  ): Promise<GetOneResult<T>> {
    const resourceName = this.getResourceName(resource);
    const request = { url: `${API_URL}/${resourceName}/${params.id}` };
    const data = await this.makeRequest(request);
    return { data };
  }

  async getOneByKey<T>(
    resource: string,
    params: GetOneByKeyParams
  ): Promise<GetOneResult<T>> {
    const resourceName = this.getResourceName(resource);
    const query = prepareQueryString(params);
    const request = {
      url: `${API_URL}/${resourceName}/b_k/${
        params.key
      }?${queryString.stringify(query)}`
    };

    const data = await this.makeRequest(request);
    return { data };
  }

  async getMany<T>(
    resource: string,
    params: GetManyParams
  ): Promise<GetManyResult<T>> {
    const resourceName = this.getResourceName(resource);
    const query = {
      filter: JSON.stringify({ id: params.ids })
    };
    const request = {
      url: `${API_URL}/${resourceName}?${queryString.stringify(query)}`
    };
    const data = await this.makeRequest(request);
    return {
      data: data.data,
      total: data.total
    };
  }

  async getRelatedItems(resource: string, params: getRelatedItemsParams) {
    const resourceName = this.getResourceName(resource);
    const query = prepareQueryString(params);
    const request = {
      url: `${API_URL}/${resourceName}/${params.key}/${
        params.item
      }?${queryString.stringify(query)}`
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async deleteSocialAuthKey<T>(
    resource: string,
    params: DeleteSocialAuthKeyParams,
    throwError?: boolean
  ): Promise<DeleteResult<T>> {
    const resourceName = this.getResourceName(resource);
    const request = {
      url: `${API_URL}/${resourceName}/${params.id}`,
      options: { method: 'DELETE' },
      throwError
    };
    const data = await this.makeRequest(request);
    return { data };
  }
  async processVideoExample(resource: string, id) {
    try {
      const resourceName = this.getResourceName(resource);
      const query = {
        filter: JSON.stringify({ id: id })
      };
      const request = {
        url: `${API_URL}/${resourceName}/getVideo?${queryString.stringify(
          query
        )}`,
        throwError: true
      };
      const res = await this.makeRequest(request);
      return res;
    } catch (error) {
      console.error('Error processing video:', error);
      throw error;
    }
  }

  async getOwnedCommunity(userId) {
    const request = {
      url: `${API_URL}/${RESOURCE.TAGS}${END_POINTS.GET_USER_OWNED_COMMUNITY}/${userId}`,
      options: { method: 'GET' }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async getFollowingTagsCount(userKey, onlyCount) {
    let url = `${API_URL}/${Constants.TAGS}/${Constants.GET_FOLLOWING_TAGS}?onlyCount=${onlyCount}&userKey=${userKey}`;
    const request = {
      url: url,
      options: { method: 'GET' }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async getMutualTags(userRank, pagination = null, key = null, filter) {
    let url = `${API_URL}/${Constants.TAGS}/${Constants.GET_MUTUAL_TAGS}?userRank=${userRank}`;

    if (pagination) {
      url += `&page=${pagination.page}&perPage=${pagination.rowsPerPage}`;
    }
    if (key) {
      url += `&key=${key}`;
    }
    if (filter) {
      url += `&filter=${filter}`;
    }
    const request = {
      url: url,
      options: { method: 'GET' }
    };

    const res = await this.makeRequest(request);
    return res;
  }

  async distributeReward<T>() {
    const request = {
      url: `${API_URL}${PATH_NAMES.REWARDS}${END_POINTS.DISTRIBUTE_MATIC_REWARD}`,
      options: { method: 'PUT' }
    };
    const data = await this.makeRequest(request);
    return { data };
  }

  async getRewardPoolThreshold<T>() {
    const request = {
      url: `${API_URL}${PATH_NAMES.REWARDS}${END_POINTS.GET_REWARD_POOL_THRESHOLD}`,
      options: { method: 'GET' }
    };
    const data = await this.makeRequest(request);
    return { data };
  }

  async create<T>(
    resource: string,
    params: CreateParams
  ): Promise<CreateResult<T>> {
    const resourceName = this.getResourceName(resource);
    this._prepareCreateParams(params);
    const request = {
      url: `${API_URL}/${resourceName}`,
      options: {
        body: JSON.stringify(params.data),
        method: 'POST'
      },
      throwError: true
    };

    const data = await this.makeRequest(request);
    return { data };
  }

  async update<T>(
    resource: string,
    params: UpdateParams
  ): Promise<UpdateResult<T>> {
    const resourceName = this.getResourceName(resource);
    this._prepareUpdateParams(params);
    const request = {
      url: `${API_URL}/${resourceName}/${params.id}`,
      options: { method: 'PUT', body: JSON.stringify(params.data) }
    };
    const data = await this.makeRequest(request);
    return { data };
  }

  async pin<T>(
    resource: string,
    id: string,
    isPinned: boolean,
    isFiled?: boolean
  ): Promise<UpdateResult<T>> {
    const resourceName = this.getResourceName(resource);
    const request = {
      url: `${API_URL}/${resourceName}/pin/${id}`,
      options: {
        method: 'PUT',
        body: JSON.stringify({ isPinned: isPinned, isFiled: isFiled })
      }
    };

    const data = await this.makeRequest(request);
    return { data };
  }

  async delete<T>(
    resource: string,
    params: DeleteParams,
    throwError?: boolean
  ): Promise<DeleteResult<T>> {
    const resourceName = this.getResourceName(resource);
    const request = {
      url: `${API_URL}/${resourceName}/${params.id}`,
      options: { method: 'DELETE' },
      throwError
    };
    const data = await this.makeRequest(request);
    return { data };
  }

  async fileApplication(
    id: string | number,
    data: {
      number: string;
      date: Date;
      receipt?: { src: string; title: string };
      ipAddress?: string;
    }
  ): Promise<any> {
    const request = {
      url: `${API_URL}/${Constants.APPLICATIONS}/${id}/file`,
      options: { method: 'POST', body: JSON.stringify(data) }
    };
    const res = await this.makeRequest(request);
    return { data: res };
  }

  async forgetPassword(data: {
    email: string;
  }): Promise<{ data: { message: string } }> {
    const request = {
      url: `${API_URL}/${RESOURCE.AUTH}${END_POINTS.FORGET_PASSWORD}`,
      options: { method: 'POST', body: JSON.stringify(data) },
      throwError: true
    };
    const res = await this.makeRequest(request);
    return { data: res };
  }

  async resetPassword(data: {
    newPassword: string;
    resetToken: string;
  }): Promise<{ data: { message: string } }> {
    const request = {
      url: `${API_URL}/${RESOURCE.PROFILES}${END_POINTS.RESET_PASSWORD}`,
      options: { method: 'POST', body: JSON.stringify(data) },
      throwError: true
    };
    const res = await this.makeRequest(request);
    return { data: res };
  }

  async getNFTInfo<T>(info: {
    tokens?: Array<string | number>;
    page?: number;
    perPage?: number;
  }): Promise<{ data: Array<T> }> {
    const query = {
      page: info.page,
      perPage: info.perPage,
      tokens: info.tokens ? JSON.stringify(info.tokens) : undefined
    };
    const request = {
      url: `${API_URL}/applications/nftInfo?${queryString.stringify(query)}`,
      options: { method: 'GET' }
    };
    const data = await this.makeRequest(request);
    return data;
  }

  async getSolutionProblem<T>(param: GetOneParams): Promise<GetOneResult<T>> {
    const request = { url: `${API_URL}/solutions/${param.id}/problem` };
    const data = await this.makeRequest(request);
    return { data };
  }

  async getObjectsBySubstring<T>(
    resource: string,
    substring: string
  ): Promise<GetListResult<T>> {
    const resourceName = this.getResourceName(resource);
    const request = {
      url: `${API_URL}/${resourceName}/search?substring=${substring}`
    };
    const data = await this.makeRequest(request);
    return {
      data: data.data,
      total: data.total
    };
  }

  async getLimitedObjectsBySubstring<T>(
    resource: string,
    substring: string
  ): Promise<GetListResult<T>> {
    const resourceName = this.getResourceName(resource);
    const request = {
      url: `${API_URL}/${resourceName}/global-search?substring=${substring}`
    };
    const { data, total } = await this.makeRequest(request);
    return {
      data,
      total
    };
  }

  async getProblemTree(param: GetOneParams): Promise<Array<TreeItem>> {
    const request = { url: `${API_URL}/problems/${param.id}/tree` };
    const data = await this.makeRequest(request);
    const [problemId, ...list] = flatList(data);
    return list;
  }

  async getTagContributors<T>(param: GetOneParams): Promise<GetListResult<T>> {
    const request = { url: `${API_URL}/tags/${param.id}/contributors` };
    const data = await this.makeRequest(request);

    return {
      data: data,
      total: 0
    };
  }

  async getTagFollowers<T>(param: GetOneParams): Promise<GetListResult<T>> {
    const request = {
      url: `${API_URL}/activities/followers/${param.id}`
    };
    const data = await this.makeRequest(request);
    return {
      data: data,
      total: 0
    };
  }

  async followTag<T>(
    resource: string,
    params: FollowTag
  ): Promise<GetListResult<T>> {
    const request = {
      url: `${API_URL}/${resource}/follow/${
        params.tagId
      }?${queryString.stringify(params)}`,
      options: { method: 'PUT' }
    };
    const data = await this.makeRequest(request);
    return {
      data: data,
      total: 0
    };
  }

  async getTagTopUsers<T>(
    groupId: string | number
  ): Promise<{ data: Array<T> }> {
    const request = {
      url: `${API_URL}/rewards/top/users?groupId=${groupId}`,
      options: { method: 'GET' }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async getProfileStats<T>(
    param: GetOneParams,
    type?: string
  ): Promise<GetOneResult<T>> {
    const request = {
      url: `${API_URL}/profiles/${param.id}/stats?type=${type}`
    };
    const data = await this.makeRequest(request);

    return { data };
  }

  async getProfileFollowers<T>(param: GetOneParams): Promise<GetOneResult<T>> {
    const request = { url: `${API_URL}/profiles/${param.id}/followers` };
    const data = await this.makeRequest(request);

    return { data };
  }

  async getProfileContests<T>(param: GetOneParams): Promise<GetOneResult<T>> {
    const request = { url: `${API_URL}/profiles/${param.id}/contests` };
    const data = await this.makeRequest(request);

    return { data };
  }

  async getContestsByProblemOrSolution<T>(
    id: string,
    type: string
  ): Promise<GetListResult<T>> {
    const request = {
      url: `${API_URL}/contests/byProblemOrSolution/${id}/${type}`
    };
    const data = await this.makeRequest(request);

    return data;
  }

  async getGraph<T>(
    resource: string,
    id: string | number,
    mapType: string
  ): Promise<T> {
    const resourceName = this.getResourceName(resource);
    const request = {
      url: `${API_URL}/${resourceName}/${id}/graph?mapType=${mapType}`
    };
    const data = await this.makeRequest(request);
    return data;
  }

  async generateProblems(resource: string, id: string | number) {
    const resourceName = this.getResourceName(resource);
    const request = {
      url: `${API_URL}/${resourceName}/${id}/generateProblems`,
      options: {
        method: 'POST'
      }
    };
    const data = await this.makeRequest(request);
    return data;
  }

  async generateMore(resource: string, params) {
    const request = {
      url: `${API_URL}/${resource}/generateMore`,
      options: {
        method: 'POST',
        body: JSON.stringify(params)
      },
      throwError: true
    };
    const data = await this.makeRequest(request);
    return data;
  }

  async generateProblemsFromProductTypes(params) {
    const request = {
      url: `${API_URL}/company-products/${params['productId']}/generateProblemsFromProductTypes`,
      options: { method: 'POST', body: JSON.stringify(params) }
    };
    const data = await this.makeRequest(request);
    return data;
  }

  async generateSolutionsFromProductTypes(params) {
    const request = {
      url: `${API_URL}/company-products/${params['productId']}/generateSolutionsFromProductTypes`,
      options: { method: 'POST', body: JSON.stringify(params) }
    };
    const data = await this.makeRequest(request);
    return data;
  }

  async assignProblem(params: any) {
    const request = {
      url: `${API_URL}/company-products/${params['id']}/assignProblem`,
      options: { method: 'POST', body: JSON.stringify(params) }
    };
    const data = await this.makeRequest(request);
    return data;
  }

  async assignSolution(params: any) {
    const request = {
      url: `${API_URL}/problems/${params['id']}/assignSolution`,
      options: { method: 'POST', body: JSON.stringify(params) }
    };
    const data = await this.makeRequest(request);
    return data;
  }

  async generateSolutions(resource: string, id: string | number) {
    const resourceName = this.getResourceName(resource);
    const request = {
      url: `${API_URL}/${resourceName}/${id}/generateSolutions`,
      options: {
        method: 'POST'
      }
    };
    const data = await this.makeRequest(request);
    return data;
  }

  async getJackpots<T>(resource: string, id: string | number): Promise<T> {
    const resourceName = this.getResourceName(resource);
    const request = { url: `${API_URL}/${resourceName}/${id}/jackpots` };
    const data = await this.makeRequest(request);
    return data;
  }

  async getLocationList(query = ''): Promise<Array<PsOptionType>> {
    const request = {
      url: `${API_URL}/profiles/location?type=location&query=${encodeURIComponent(
        query
      )}`
    };
    const list = await this.makeRequest(request);
    return list;
  }

  async getWorkplaceList(query = ''): Promise<Array<PsOptionType>> {
    const request = {
      url: `${API_URL}/profiles/location?type=workplace&query=${encodeURIComponent(
        query
      )}`
    };
    const list = await this.makeRequest(request);
    return list;
  }

  async getUniversityList(query = ''): Promise<Array<PsOptionType>> {
    const request = {
      url: `${API_URL}/profiles/location?type=university&query=${encodeURIComponent(
        query
      )}`
    };
    const list = await this.makeRequest(request);
    return list;
  }

  async getPayOptions(): Promise<Array<PayOption>> {
    const request = { url: `${API_URL}/products/pay` };
    const { products } = await this.makeRequest(request);

    return products;
  }

  async payOptionStart(
    appId: string | number,
    data: {
      finalizeType: string;
      id?: string | number;
      paymentType?: string; // 'docusign' | 'coinbase' | 'skip';
    }
  ): Promise<Array<PayProduct>> {
    const request = {
      url: `${API_URL}/applications/${appId}/pay`,
      options: { method: 'POST', body: JSON.stringify(data) }
    };
    const { products } = await this.makeRequest(request);

    return products;
  }

  async payOptionClear(appId: string | number): Promise<void> {
    const request = {
      url: `${API_URL}/applications/${appId}/pay`,
      options: { method: 'DELETE' }
    };
    await this.makeRequest(request);
  }

  async payOptionFinish<T>(appId: string | number): Promise<T> {
    const request = {
      url: `${API_URL}/applications/${appId}/pay`,
      options: { method: 'GET' }
    };
    const { data } = await this.makeRequest(request);

    return data;
  }

  async payOptionSign(
    appId: string | number,
    sign: string,
    ipAddress: string
  ): Promise<{ link?: string; redirect?: string }> {
    let data = { baseUrl: '', redirect: '', sign: '', ipAddress: '' };
    if (typeof window !== 'undefined') {
      data = {
        baseUrl: `${window.location.protocol}//${window.location.host}`,
        redirect: window.location.href,
        sign: sign,
        ipAddress: ipAddress
      };
    }
    const request = {
      url: `${API_URL}/${RESOURCE.APPLICATIONS}/${appId}/${END_POINTS.REQUEST_PAY}`,
      options: { method: 'POST', body: JSON.stringify(data) }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async payOptionPay(
    appId: string | number,
    docId: string | number,
    options?: { test?: boolean }
  ): Promise<{ redirect?: string }> {
    const { test = false } = options || {};
    const data = {
      test,
      redirect: typeof window !== 'undefined' ? window.location.href : ''
    };
    const request = {
      url: `${API_URL}/applications/${appId}/pay2/${docId}`,
      options: { method: 'POST', body: JSON.stringify(data) }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async payOpenOptionSign(
    appId: string | number
  ): Promise<{ link?: string; redirect?: string }> {
    let data = { baseUrl: '', redirect: '' };
    if (typeof window !== 'undefined') {
      data = {
        baseUrl: `${window.location.protocol}//${window.location.host}`,
        redirect: window.location.href
      };
    }
    const request = {
      url: `${API_URL}/${RESOURCE.APPLICATIONS}/${appId}/${END_POINTS.REQUEST_PAY}`,
      options: { method: 'POST', body: JSON.stringify(data) }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async downloadPatentApp(
    appId: string | number,
    title: string
  ): Promise<{ applicationId: string; url: string }> {
    try {
      const jwtToken = await this.auth.getJwtToken();
      const request = {
        url: `${API_URL}/applications/${appId}/pdfTemplate?demo=true&title=${title}`,
        options: {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtToken}`
          }
        },
        throwError: true
      };
      return await this.makeRequest(request);
    } catch (error) {
      console.error('Error downloading the patent application:', error);
    }
  }

  async downloadSignDocument(documentId: string | number): Promise<void> {
    const jwtToken = await this.auth.getJwtToken();
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    if (jwtToken) {
      headers.set('Authorization', `Bearer ${jwtToken}`);
    }
    return fetch(`${API_URL}/sign-documents/download/${documentId}`, {
      headers
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url =
          typeof window !== 'undefined' ? window.URL.createObjectURL(blob) : '';
        const a = document.createElement('a');
        a.href = url;
        a.download = 'SignDocument.pdf';
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  }

  async voteItem(
    resource: string,
    itemId: string | number,
    type: number,
    params?: GetListParams
  ): Promise<{ likes: number; dislikes: number }> {
    const path =
      resource === 'applications' || resource === 'products'
        ? 'likeDislike'
        : 'vote';
    let resourcePath = resource;
    if (resource === 'products') {
      resourcePath = 'company-products';
    }
    const query = params
      ? `?${queryString.stringify(prepareQueryString(params))}`
      : '';
    const data = { type, itemType: resource };
    const request = {
      url: `${API_URL}/${resourcePath}/${itemId}/${path}${query}`,
      options: { method: 'POST', body: JSON.stringify(data) }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async calculateMultiplierIdeaPoints(data: {
    defaultIdeaPoints: string;
    likes: number;
    dislikes: number;
  }): Promise<any> {
    const request = {
      url: `${API_URL}${PATH_NAMES.REWARDS}${END_POINTS.CALCULATE_MULTIPLIER_IDEA_POINTS}`,
      options: {
        method: 'POST',
        body: JSON.stringify(data)
      }
    };
    const response = await this.makeRequest(request);
    return response;
  }

  async getImproveApp(
    id: string | number,
    entity?: string
  ): Promise<{ coins: { [key: string]: number } }> {
    let entityPath = 'applications';
    if (entity === 'product') {
      entityPath = 'company-products';
    } else if (entity === 'prior-art') {
      entityPath = 'prior-arts';
    }
    const request = {
      url: `${API_URL}/${entityPath}/${id}/vote`,
      options: { method: 'GET' }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async improveApp(
    id: string | number,
    type: string,
    entity?: string
  ): Promise<{ success: boolean; message?: string }> {
    let entityPath = 'applications';
    if (entity === 'product') {
      entityPath = 'company-products';
    } else if (entity === 'prior-art') {
      entityPath = 'prior-arts';
    }
    const request = {
      url: `${API_URL}/${entityPath}/${id}/vote`,
      options: { method: 'POST', body: JSON.stringify({ type }) }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async improveSolution<T>(id: string | number, boost?: any): Promise<T> {
    const request = {
      url: `${API_URL}/solutions/${id}/improve`,
      options: { method: 'POST', body: JSON.stringify(boost) }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async globalSearch(query: string, tagsName?: string): Promise<any> {
    const request = {
      url: `${API_URL}/search?q=${query}&t=${tagsName || ''}`,
      options: { method: 'GET' }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async imageSearch(query: string): Promise<{ url: string }> {
    const request = {
      url: `${API_URL}/search/image?q=${encodeURIComponent(query)}&new=true`,
      options: { method: 'GET' }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async answerSearch(query: string): Promise<{ text: string }> {
    const request = {
      url: `${API_URL}/search/answer?q=${query}`,
      options: { method: 'GET' },
      throwError: true
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async regenerateImage(itemId: number): Promise<{ text: string }> {
    const request = {
      url: `${API_URL}/applications/${itemId}/regenerateImage`,
      options: { method: 'GET' },
      throwError: true
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async getImageAndTitle(
    itemId: number,
    genrateImage?: boolean
  ): Promise<{ text: string }> {
    const request = {
      url: `${API_URL}/applications/${itemId}/concept?genrateImage=${genrateImage}`,
      options: { method: 'GET' },
      throwError: true
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async validateCompanyName(name: string): Promise<{ res: boolean }> {
    const request = {
      url: `${API_URL}/ai/validateCompanyName?name=${name}`,
      options: { method: 'POST' }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async getRelationPrompt(payload): Promise<{ text: string }> {
    const request = {
      url: `${API_URL}/ai/getRelationPrompt`,
      options: { method: 'POST', body: JSON.stringify(payload) },
      throwError: true
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async generateDescriptionFromAI(payload): Promise<any> {
    const request = {
      url: `${API_URL}/ai/generateDescription`,
      options: { method: 'POST', body: JSON.stringify(payload) },
      throwError: true
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async generateImageFromAI(payload): Promise<any> {
    const request = {
      url: `${API_URL}/ai/generateImage`,
      options: { method: 'POST', body: JSON.stringify(payload) },
      throwError: true
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async tagsSearch(
    title: string,
    companyName?: string,
    type?: string
  ): Promise<{ tags: string[] }> {
    const request = {
      url: `${API_URL}/search/tags?title=${title}&companyName=${companyName}&type=${type}`,
      options: { method: 'GET' },
      throwError: true
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async nftDeployStart(appId: string | number): Promise<{
    contractAddress: string;
    contractMarketAddress: string;
    tokenURI: string;
  }> {
    const request = {
      url: `${API_URL}/applications/${appId}/nft`,
      options: { method: 'GET' },
      throwError: true
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async nftDeployFinish(
    appId: string | number,
    data: { transactionHash: string; tokenId: string }
  ): Promise<{ nftTransactionUrl: string }> {
    const request = {
      url: `${API_URL}/applications/${appId}/nft`,
      options: { method: 'POST', body: JSON.stringify(data) },
      throwError: true
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async removeTag(type: string, itemId: string | number, tagKey: string) {
    const request = {
      url: `${API_URL}/${type}/${itemId}/tags/${tagKey}`,
      options: { method: 'DELETE' }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async updateTags(type: string, itemId: string | number, tags: Array<string>) {
    const request = {
      url: `${API_URL}/${type}/${itemId}/tags`,
      options: { method: 'POST', body: JSON.stringify({ tags }) }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async trackSharing(
    id: string | number,
    resource: string,
    type: string,
    itemType?: string,
    itemId?: string
  ) {
    const request = {
      url: `${API_URL}/profiles/track/sharing`,
      options: {
        method: 'POST',
        body: JSON.stringify({ id, resource, type, itemType, itemId })
      }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async getMarketInfo(): Promise<{
    data: {
      networkRpc: string;
      contractAddress: string;
      contractMarketAddress: string;
    };
  }> {
    const request = {
      url: `${API_URL}/profiles/marketInfo`,
      options: { method: 'GET' }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async getContestNFTCount<T>(id: string | number): Promise<T> {
    const request = {
      url: `${API_URL}/contests/${id}/nft`,
      options: { method: 'GET' }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async getUnreadNotificationCount<T>(params?: {
    filter?: { $custom?: any };
  }): Promise<T> {
    const query = params ? prepareQueryString(params) : '';
    const request = {
      url: `${API_URL}/notification/unreadCount${query ? `?${queryString.stringify(query)}` : ''}`,
      options: { method: 'GET' }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async toggleNotificationReadStatus<T>(
    id: string | number | null,
    status: boolean
  ): Promise<T> {
    const request = {
      url: `${API_URL}/notification/toggleReadStatus`,
      options: {
        method: 'POST',
        body: JSON.stringify({ id, status })
      }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async markAllNotificationsAsRead<T>(userId): Promise<T> {
    const request = {
      url: `${API_URL}/notification/markAllAsRead`,
      options: {
        method: 'POST',
        body: JSON.stringify({ userId })
      }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async markMultipleNotificationsAsRead<T>(notificationIds): Promise<T> {
    const request = {
      url: `${API_URL}/notification/markMultipleAsRead`,
      options: {
        method: 'POST',
        body: JSON.stringify({ notificationIds })
      }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async getContestTops<T>(id: string | number): Promise<T> {
    const request = {
      url: `${API_URL}/contests/${id}/tops`,
      options: { method: 'GET' }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async getContestTopUsers<T>(id: string | number): Promise<T> {
    const request = {
      url: `${API_URL}/contests/${id}/topUsers`,
      options: { method: 'GET' }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async getChallengeLeaderboard<T>(
    pagination: PaginationPayload,
    filter: any
  ): Promise<{ data: Array<T>; total: number }> {
    const { page, perPage } = pagination;
    const rangeStart = ((page as number) - 1) * (perPage as number);
    const rangeEnd = (page as number) * (perPage as number) - 1;
    const query = {
      range: JSON.stringify([rangeStart, rangeEnd]),
      filter: JSON.stringify(filter)
    };
    const request = {
      url: `${API_URL}/challenges/leaderboard?${queryString.stringify(query)}`,
      options: { method: 'GET' }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async getChallengeTopUsers<T>(id: string | number): Promise<T> {
    const request = {
      url: `${API_URL}/challenges/${id}/topUsers`,
      options: { method: 'GET' }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async getChallengeTopTags<T>(id: string | number): Promise<T> {
    const request = {
      url: `${API_URL}/challenges/${id}/topTags`,
      options: { method: 'GET' }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async getUserRanking<T>(
    resource: string,
    params: GetListParams
  ): Promise<GetUserRankResult> {
    const resourceName = this.getResourceName(resource);
    const query = prepareQueryString(params);
    const request = {
      url: `${API_URL}/${resourceName}?${queryString.stringify(query)}`
    };
    const data = await this.makeRequest(request);
    return data?.rank;
  }

  async getUserIdeaPoints<T>(
    resource: string,
    params: GetListParams
  ): Promise<GetUserIdeaPointsResult> {
    const resourceName = this.getResourceName(resource);
    const query = prepareQueryString(params);
    const request = {
      url: `${API_URL}/${resourceName}?${queryString.stringify(query)}`
    };
    const data = await this.makeRequest(request);
    return data.ideaPoints;
  }

  async getProfileItemsCount<T>(
    resource: string,
    params: GetListParams
  ): Promise<GetProfileItemsCount> {
    const resourceName = this.getResourceName(resource);
    const query = prepareQueryString(params);
    const request = {
      url: `${API_URL}/${resourceName}/getCount?${queryString.stringify(query)}`
    };
    const data = await this.makeRequest(request);
    return data;
  }

  async getChallengeTopContests<T>(id: string | number): Promise<T> {
    const request = {
      url: `${API_URL}/challenges/${id}/topContests`,
      options: { method: 'GET' }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async addSolutionToProblem<T>(
    id: string | number,
    application: string | number,
    solution: string | number
  ): Promise<T> {
    const request = {
      url: `${API_URL}/problems/${id}/add`,
      options: {
        method: 'POST',
        body: JSON.stringify({ solution, application })
      }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async inviteUser<T>(
    email: string,
    itemType: string,
    itemId: string | number
  ): Promise<T> {
    const request = {
      url: `${API_URL}/auth/invite`,
      options: {
        method: 'POST',
        body: JSON.stringify({ email, itemType, itemId })
      }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async inviteUserSimple<T>(email: string): Promise<T> {
    const request = {
      url: `${API_URL}/auth/invite-simple`,
      options: {
        method: 'POST',
        body: JSON.stringify({ email })
      }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async registerUser<T>(data: any): Promise<T> {
    const request = {
      url: `${API_URL}/auth/register`,
      options: {
        method: 'POST',
        body: JSON.stringify(data)
      }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async updateSubscription<T>(
    type: string,
    tagId?: string | number
  ): Promise<T> {
    const request = {
      url: `${API_URL}/profiles/subscription`,
      options: {
        method: 'POST',
        body: JSON.stringify({ type, tagId })
      }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async initialPaymentSession<T>(
    id,
    redirectURL,
    items,
    pagination
  ): Promise<T> {
    const request = {
      url: `${API_URL}/applications/initialPaymentSession/${id}`,
      options: {
        method: 'POST',
        body: JSON.stringify({
          id,
          redirectURL,
          items,
          pagination
        })
      }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async stakerPayment<T>(
    id,
    amount,
    numberOfStake,
    redirectURL,
    items
  ): Promise<T> {
    try {
      const request = {
        url: `${API_URL}/${Constants.INVENTIONSTAKER}/${Constants.CREATE_CHECKOUT_SEESION}`,
        options: {
          method: Constants.POST,
          body: JSON.stringify({
            applicationId: id,
            amount,
            numberOfStake,
            redirectURL,
            items
          })
        }
      };

      const res = await this.makeRequest(request);
      return res;
    } catch (error) {
      console.error(ERRORS.ERROR_DURING_STAKER_PAYMENT_REQUEST, error);
      throw new Error(ERRORS.FAILED_TO_INITIATE_PAYMENT_SESSION);
    }
  }

  async createPurchaseCreditsSession<T>(redirectURL, items): Promise<T> {
    const request = {
      url: `${API_URL}/${RESOURCE.SUBSCRIPTIONS}${END_POINTS.CREATE_PURCHASE_CREDITS_SESSION}`,
      options: {
        method: 'POST',
        body: JSON.stringify({
          redirectURL,
          items
        })
      }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async updateTagSubscription<T>(
    id: string | number,
    data: {
      termsAgree: boolean;
      title?: string;
      url?: string;
      invites: Array<string>;
    }
  ): Promise<T> {
    const request = {
      url: `${API_URL}/tags/${id}/subscribe`,
      options: {
        method: 'POST',
        body: JSON.stringify(data)
      }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async applicationSign(
    solutionId: string | number
  ): Promise<{ link?: string; redirect?: string }> {
    const data = {};
    const request = {
      url: `${API_URL}/applications/${solutionId}/sign`,
      options: { method: 'POST', body: JSON.stringify(data) }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async solutionSign(
    solutionId: string | number
  ): Promise<{ link?: string; redirect?: string }> {
    const data = {};
    const request = {
      url: `${API_URL}/solutions/${solutionId}/sign`,
      options: { method: 'POST', body: JSON.stringify(data) }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async getRelatedNfts<T>(
    resource: string,
    itemId: string | number,
    params?: Partial<GetListParams>
  ): Promise<GetManyResult<T>> {
    const query = prepareQueryString(params || {});
    const request = {
      url: `${API_URL}/${resource}/${itemId}/nfts?${queryString.stringify(
        query
      )}`,
      options: { method: 'GET' }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  // async getRelatedConcepts<T>(
  //   resource: string,
  //   itemId: string | number,
  // ): Promise<T[]> {
  //   const request = {
  //     url: `${API_URL}/${resource}/${itemId}/concepts`,
  //     options: { method: 'GET' },
  //   };
  //   const res = await this.makeRequest(request);
  //   return res;
  // }

  async getConceptsByUserId<T>(
    userId: string | number,
    params: GetListParams
  ): Promise<{ data: Array<T> }> {
    const { field = 'createdAt', order = 'ASC' } = params.sort || {};
    const query = {
      filter: JSON.stringify(params.filter),
      sort: JSON.stringify([field, order])
    };

    const request = {
      url: `${API_URL}/applications?${queryString.stringify(
        query
      )}&owner=${userId}&isPaid=false`,
      options: { method: 'GET' }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async getRewardsTop<T>(type: string): Promise<{ data: Array<T> }> {
    const request = {
      url: `${API_URL}/rewards/top/${type}`,
      options: { method: 'GET' }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async getGroupsTop<T>(): Promise<{ data: Array<T> }> {
    const request = {
      url: `${API_URL}/tags/top`,
      options: { method: 'GET' }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async getGroupRewards(
    id: string | number
  ): Promise<{ data: { total: number } }> {
    const request = {
      url: `${API_URL}/tags/${id}/rewards`,
      options: { method: 'GET' }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async getChartTops<T>(): Promise<{ data: Array<T> }> {
    const request = {
      url: `${API_URL}/tags/chart-top`,
      options: { method: 'GET' }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async activityFollow(data: {
    subscribe: boolean;
    followerType: 'profile' | 'tag';
    followerId: string | number;
  }): Promise<{ link?: string; redirect?: string }> {
    const request = {
      url: `${API_URL}/activities/follow`,
      options: { method: 'POST', body: JSON.stringify(data) }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async activityGetUnseen(): Promise<{
    items: Array<ActivitySeen>;
  }> {
    const request = {
      url: `${API_URL}/activities/unseen`,
      options: { method: 'GET' }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async activityUpdateUnseen(
    id: string | number,
    seen: boolean
  ): Promise<{
    items: Array<ActivitySeen>;
  }> {
    const data = { id, seen };
    const request = {
      url: `${API_URL}/activities/unseen`,
      options: { method: 'POST', body: JSON.stringify(data) }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async shareToSocialMedia(payload: any): Promise<any> {
    const request = {
      url: `${API_URL}/share/shareToSocialMedia`,
      options: { method: 'POST', body: JSON.stringify(payload) }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async getCreditsPrices(): Promise<Array<CreditsPrice>> {
    const request = {
      url: `${API_URL}/profiles/credits/prices`,
      options: { method: 'GET' }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async buyCredits(
    user: string | number,
    priceId: string,
    priceType: string
  ): Promise<{
    pubKey?: string;
    stripeSessionId?: string;
    coinbaseHostedUrl?: string;
  }> {
    const data = { user, id: priceId, type: priceType, redirect: '' };
    if (typeof window !== 'undefined') {
      data.redirect = window.location.href;
    }

    const request = {
      url: `${API_URL}/profiles/credits/buy`,
      options: { method: 'POST', body: JSON.stringify(data) }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async getPrimaryBillingAddress<T>(id: string | number): Promise<T> {
    const request = {
      url: `${API_URL}/billingAddresses/${id}`,
      options: { method: 'GET' }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async updateBillingAddress<T>(id, data): Promise<T> {
    const request = {
      url: `${API_URL}/billingAddresses/${id}`,
      options: { method: 'POST', body: JSON.stringify(data) }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async updateCredits<T>(id, data): Promise<any> {
    let res = {};
    try {
      const request = {
        url: `${API_URL}/profiles/credits/${id}/updateCredits`,
        options: { method: 'POST', body: JSON.stringify(data) }
      };
      res = await this.makeRequest(request);
      return { res, error: null };
    } catch (error) {
      throw error?.message ?? 'Internal Server Error';
    }
  }

  async getTermsAndConditionsDoc<T>(data): Promise<any> {
    const request = {
      url: `${API_URL}/generateAgreementTemplate`,
      options: { method: 'POST', body: JSON.stringify(data) }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  async getInventionStakerByApplicationId<T>(data): Promise<any> {
    const request = {
      url: `${API_URL}/inventionStaker/${data.applicationId}`,
      options: { method: 'GET' }
    };
    const res = await this.makeRequest(request);
    return res;
  }

  _prepareCreateParams(params: any) {
    this._prepareTags(params.data);
  }

  _prepareUpdateParams(params: any) {
    this._prepareTags(params.data);
  }

  _prepareTags(params: { tags: string[]; tagsName: string[] }) {
    const tags: string[] = [];
    const tagsName: string[] = [];

    (params.tags || []).forEach((tag) => {
      if (tag.startsWith('name_')) {
        tagsName.push(tag.slice(5));
      } else {
        tags.push(tag);
      }
    });
    params.tags = tags;
    params.tagsName = tagsName;
  }
}

export const dataProvider = new DataProvider({ auth });

export default dataProvider;
