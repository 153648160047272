import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useDispatch } from 'react-redux';
import auth, { AuthProvider, Profile } from 'authProvider';
import { DataContext } from 'contexts/DataContext';
import { GetUser } from 'redux-state/onboarding/selectors';

export { AuthProvider };

export type { Profile };

type FollowingItem = { id: string | number; type: string };

export const formTypes = {
  SIGN_UP: 'signUp',
  LOGIN: 'login',
  NEW_PASSWORD: 'newPassword',
  FORGOT_PASSWORD: 'forgotPassword'
};

export type AuthContextType = {
  auth: AuthProvider;
  user: Profile | undefined;
  loading: boolean;
  authModalOpened: string;
  setAuthModalOpened: (state: string) => void;
  setUser: (state: string) => void;
  termsModalOpened: string;
  setTermsModalOpened: (name: string) => void;
  userBalance: number;
  userEearned: number;
  userCredits: number;
  refreshUserBalance: () => void;
  following: Array<FollowingItem>;
  setFollowing: (data: Array<FollowingItem>) => void;
};

const defaultContext = {
  auth,
  user: undefined,
  loading: false,
  authModalOpened: '',
  setAuthModalOpened: () => {
    console.log('open');
  },
  setUser: () => {
    console.log('open');
  },
  termsModalOpened: '',
  setTermsModalOpened: (name: string) => {
    console.log(name);
  },
  userBalance: 0,
  userEearned: 0,
  userCredits: 0,
  refreshUserBalance: () => {
    console.log('');
  },
  following: [],
  setFollowing: () => {
    console.log('');
  }
};

export const AuthContext = createContext<AuthContextType>(defaultContext);

type Props = {
  children: React.ReactElement;
  auth: AuthProvider;
};

export const AuthContextProvider = ({ children, auth }: Props) => {
  const { dataProvider } = useContext(DataContext);

  const dispatch = useDispatch();
  const loginUser = GetUser();
  const [termsModalOpened, setTermsModalOpened] = useState('');
  const [userBalanceCache, setUserBalanceCache] = useState(0);
  const [authModalOpened, setAuthModalOpened] = useState('');
  const [userBalance, setUserBalance] = useState(0);
  const [userEearned, setUserEearned] = useState(0);
  const [userCredits, setUserCredits] = useState(0);
  const [following, setFollowing] = useState<Array<FollowingItem>>([]);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(undefined);

  const setValues = (profile: Profile) => {
    setUser(profile);
    setLoading(false);
    setUserBalance(profile ? profile.balance || 0 : 0);
    setUserEearned(profile ? profile.earned || 0 : 0);
    setUserCredits(profile ? profile.credits || 0 : 0);
  };

  useEffect(() => {
    if (loginUser) {
      setValues(loginUser);
    }
  }, [loginUser]);

  useEffect(() => {
    if (!userBalanceCache) {
      return;
    }
    auth
      .loadBalance()
      .then(({ balance, earned, credits }) => {
        setUserBalance(balance);
        setUserEearned(earned);
        setUserCredits(credits);
      })
      .catch((err) => {
        //
      });
  }, [auth, userBalanceCache]);

  const refreshUserBalance = useCallback(() => {
    setUserBalanceCache(userBalanceCache + 1);
  }, [userBalanceCache, setUserBalanceCache]);

  return (
    <AuthContext.Provider
      value={{
        user,
        auth,
        loading,
        authModalOpened,
        setAuthModalOpened,
        setUser,
        termsModalOpened,
        setTermsModalOpened,
        userBalance,
        userEearned,
        userCredits,
        refreshUserBalance,
        following,
        setFollowing
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const AuthContextConsumer = AuthContext.Consumer;

export default AuthContext;
