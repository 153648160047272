import React, { FC } from 'react';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import SimpleIconButton from 'components/SimpleIconButton';
import { Constants } from 'utilities/constants';
import { StyledHeaderText, StyledHeaderWrapper } from './StyledComponents';

interface HeaderProps {
  onClose: () => void;
}

export const Header: FC<HeaderProps> = ({ onClose }) => {
  return (
    <StyledHeaderWrapper>
      <StyledHeaderText>{Constants.HOW_TO_EARN}</StyledHeaderText>
      <SimpleIconButton
        color="primary"
        Icon={CancelOutlinedIcon}
        onClick={onClose}
      />
    </StyledHeaderWrapper>
  );
};
