/* eslint-disable max-lines-per-function */
import React, { FC } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import dataProvider from 'dataPrvider';
import { GetUser } from 'redux-state/selectors';
import { Constants } from 'utilities/constants';
import { ISubscription } from '../interfaces';
import {
  BestValueBox,
  BestValueText,
  CreditsText,
  DetailsBox,
  MainBox,
  MainDurationText,
  MainPrice,
  MainPriceWrapper,
  PlanTitle,
  PriceBox
} from './styledComponents';
import { SubActionButton } from './SubActionButton';
import { SubscriptionFeatures } from './SubscriptionFeatures';

interface SubscriptionCardProps {
  subscription: ISubscription;
}

export const SubscriptionCard: FC<SubscriptionCardProps> = ({
  subscription
}) => {
  const user = GetUser();
  const stripeKey = (user && user.userData?.STRIPE_PUB_KEY) || '';

  const handleSubscription = async () => {
    const data: {
      stripeSessionId: string;
      pubKey: string;
    } = await dataProvider.createPurchaseCreditsSession(
      `${window.location.href}`,
      [{ name: subscription.title, price: subscription.price, quantity: 1 }]
    );

    if (data.stripeSessionId) {
      const stripe = await loadStripe(stripeKey);
      if (stripe) {
        await stripe.redirectToCheckout({
          sessionId: data.stripeSessionId
        });
      }
    }
  };

  return (
    <MainBox>
      <PriceBox>
        <MainPrice>
          <MainPriceWrapper>{`$${subscription.price}`}</MainPriceWrapper>
          {subscription.price === 0 ? '' : `/${Constants.MONTH}`}
        </MainPrice>
        {subscription?.shortDescription ? (
          <CreditsText>{subscription.shortDescription}</CreditsText>
        ) : null}
        <SubActionButton
          subscription={subscription}
          onClick={handleSubscription}
        />
      </PriceBox>
      <DetailsBox>
        <PlanTitle>
          <MainDurationText>{subscription.title}</MainDurationText>
          {subscription.bestValue ? (
            <BestValueBox>
              <BestValueText>{Constants.BEST_VALUE}</BestValueText>
            </BestValueBox>
          ) : null}
        </PlanTitle>
        <SubscriptionFeatures features={subscription.features} />
      </DetailsBox>
    </MainBox>
  );
};
