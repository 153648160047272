import { Avatar, Box, Button, styled, Typography } from '@mui/material';
import { colorPalette } from 'theme';
import { Constants } from 'utilities/constants';

const ITEM_TYPES = {
  GRANDPARENT: 'product',
  PARENT: 'problem',
  CHILD: 'solution'
};

export const getBackgroundColorByType = (
  type: string,
  isCurrentConceptSolution = false
) => {
  switch (type) {
    case ITEM_TYPES.PARENT:
      return colorPalette.roseWhite;
    case ITEM_TYPES.CHILD:
      return colorPalette.ceramic;
    case Constants.APPLICATION:
    case Constants.Invention:
      return colorPalette.marzipan;
    case Constants.SOLUTION:
      return isCurrentConceptSolution
        ? colorPalette.magicMint
        : colorPalette.sugarCane;
    default:
      return colorPalette.white;
  }
};

const getBorderColorByType = (type: string) => {
  switch (type) {
    case Constants.PRODUCT:
      return `1px solid ${colorPalette.purpleBlue}`;
    case Constants.PROBLEM:
      return `1px solid ${colorPalette.torchRed}`;
    case Constants.SOLUTION:
      return `1px solid ${colorPalette.malachite}`;
    case Constants.APPLICATION:
    case Constants.Invention:
      return `1px solid ${colorPalette.mustard}`;
    default:
      return `1px solid ${colorPalette.black}`;
  }
};

export const AddToBut = styled(Button)<{ isCurrentConceptSolution: boolean }>(
  ({ isCurrentConceptSolution, theme }) => ({
    backgroundColor: isCurrentConceptSolution
      ? colorPalette.explosiveGrey
      : colorPalette.lightGreen,
    borderRadius: '1.5625rem',
    color: isCurrentConceptSolution ? colorPalette.grey : colorPalette.white,
    display: 'none',
    padding: '.625rem .9375rem',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  })
);

export const AddToButton = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  '> button': {
    borderRadius: '2.5rem',
    height: 'auto'
  }
});

export const AddToBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '0px .3125rem',
  [theme.breakpoints.down('sm')]: {
    padding: '0'
  }
}));

export const ButtonContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end'
});

export const DetailBox = styled(Box)(({ theme }) => ({
  padding: '0px 5px',
  [theme.breakpoints.down('sm')]: {
    '& .MuiButtonBase-root': {
      padding: '.2rem 0',
      minWidth: 'auto',
      '& svg': {
        width: '30px',
        height: '30px'
      }
    }
  }
}));

export const ImageContainer = styled(Box)(({ theme }) => ({
  display: 'none',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0.938rem',
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

export const StackBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  padding: '0px 5px'
});

export const StyledAvatar = styled(Avatar)({
  borderRadius: '.625rem'
});

export const StyledBox = styled(Box)<{
  type: string;
  isCurrentConceptSolution: boolean;
}>(({ type, isCurrentConceptSolution }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  borderRadius: '3.75rem',
  backgroundColor: getBackgroundColorByType(type, isCurrentConceptSolution),
  minHeight: '3rem',
  boxShadow: `.125rem .25rem 11.2px 0rem ${colorPalette.boxShadowGrey}`,
  width: '100%',
  border: getBorderColorByType(type),
  '&:hover': {
    backgroundColor: 'transparent !important'
  },
  '&[data-type="product"]:hover': {
    backgroundColor: `${colorPalette.cosmicLatte} !important`
  },
  '&[data-type="problem"]:hover': {
    backgroundColor: `${colorPalette.pastelPink} !important`
  },
  '&.MuiTreeItem-iconContainer': {
    width: '6.25rem !important'
  }
}));

export const StyledInnerBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  padding: '0',
  [theme.breakpoints.up('xl')]: {
    padding: '.313rem 0'
  }
}));

export const StyledTitle = styled(Typography)<{ type: string }>(
  ({ type, theme }) => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '15px',
    fontWeight: type === Constants.PRODUCT ? '700' : '500',
    width: '100%',
    lineHeight: '20px',
    display: '-webkit-box',
    overflowX: 'hidden',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '2',
    [theme.breakpoints.down('sm')]: {
      '-webkit-line-clamp': '3'
    }
  })
);

export const TagContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '5px', // Optional spacing between title and tags
  marginTop: '5px',
  alignItems: 'flex-start' // Ensures text and tags align at the start
});

export const TextContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  maxWidth: '50.25rem'
});

export const TitleContainer = styled(Box)<{ marginLeft?: string }>(
  ({ theme, marginLeft }) => ({
    alignItems: 'center',
    display: 'flex',
    marginLeft: marginLeft,
    paddingRight: '.9375rem',
    [theme.breakpoints.down('sm')]: {
      paddingRight: '0'
    }
  })
);

export const VotingBox = styled(Box)({
  padding: '0px 5px'
});
