import React from 'react';
import { styled, Box, Typography, Switch, Button } from '@mui/material';
import { colorPalette } from 'theme';

export const StyledMainBox = styled(Box)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    maxWidth: '81.25rem',
    padding: '1.25rem',
    paddingTop: isMediumScreen ? '5rem' : '1.25rem',
    width: 'fit-content'
  })
);

export const StyledHeaderBox = styled(Box)({
  marginBottom: '1.25rem',
  textAlign: 'center'
});

export const StyledHeaderTypography = styled(Typography)({
  fontSize: '1.75rem',
  fontWeight: 700
});

export const StyledPlatformTypography = styled(Typography)({
  fontSize: '1.25rem',
  fontWeight: 700
});

export const StyledButtonTypography = styled(Typography)({
  fontSize: '0.9375rem',
  fontWeight: 600
});

export const StyledSubHeaderBox = styled(Box)({
  marginTop: '0.625rem',
  textAlign: 'center'
});

export const StyledSubHeaderTypography = styled(Typography)({
  color: colorPalette.lightBlack,
  fontSize: '1.125rem',
  fontWeight: 400
});

export const SocialLinksContainer = styled(Box)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    display: 'grid',
    gap: '2.0625rem',
    gridTemplateColumns: isMediumScreen ? '1fr' : '1fr 1fr',
    marginTop: '1.125rem',
    width: '100%'
  })
);

export const SocialLinkItem = styled(Box)({
  alignItems: 'center',
  backgroundColor: colorPalette.white,
  borderRadius: '0.5rem',
  boxShadow: '0 0.125rem 0.25rem rgba(0,0,0,0.1)',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0.625rem 0.9375rem'
});

export const StyledNameEmailTypography = styled(Typography)({
  maxWidth: '10rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
});

export const SocialLinkInfo = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  gap: '1rem'
});

export const SocialLinkText = styled(Box)({
  display: 'flex',
  flexDirection: 'column'
});

export const StyledButtonContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  gap: '1.25rem',
  marginLeft: '1.25rem'
});

export const ConnectButton = styled(Button)<{ isConnected: boolean }>(
  ({ isConnected }) => ({
    backgroundColor: isConnected ? colorPalette.blueChalk : colorPalette.purple,
    color: isConnected ? colorPalette.purple : colorPalette.white,
    padding: '0.75rem',
    '&:hover': {
      backgroundColor: isConnected
        ? colorPalette.blueChalk
        : colorPalette.purple
    }
  })
);

export const SocialSwitch = styled(Switch)({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#632DDD',
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: 'black',
        opacity: 0.4
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1
  }
});
