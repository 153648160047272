import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const PlaybackGreenImage = (props: SvgIconProps) => {
  return (
    <SvgIcon sx={{ width: 40, height: 37 }} viewBox="0 0 40 37">
      <g clipPath="url(#clip0_5638_7764)">
        <path
          d="M39.1912 23.9479C37.9673 22.4405 35.7582 22.1384 34.1635 23.2605C34.1577 23.2645 34.1528 23.2677 34.1479 23.2718L28.8019 27.1606C28.2818 27.525 27.7117 27.8029 27.1154 27.9911C27.4336 27.0839 27.3795 26.1121 26.9579 25.2421C26.5223 24.3429 25.7536 23.6741 24.7914 23.3582L14.1019 19.8757C12.595 19.3813 10.7411 19.2294 9.01434 19.4596C6.94633 19.7351 5.10965 20.547 3.66754 21.812H-0.769531C-1.44875 21.812 -2 22.3549 -2 23.0238V35.1411C-2 35.81 -1.44875 36.3529 -0.769531 36.3529H4.15234C4.83156 36.3529 5.38281 35.81 5.38281 35.1411V34.2356L15.0338 36.3957C16.7942 36.7923 18.5972 37.0008 20.2666 37.0008C20.3429 37.0008 20.4191 37.0008 20.4946 36.9991C25.1646 36.945 29.6755 35.4804 33.5384 32.7637C33.5441 32.7597 33.5499 32.7556 33.5556 32.7516L38.4636 29.1713C39.2798 28.5937 39.8261 27.7003 39.9647 26.7196C40.1034 25.7348 39.8212 24.725 39.1912 23.9487V23.9479ZM2.92188 33.9286H0.460938V24.2347H2.92188V33.9286ZM37.5276 26.3851C37.4808 26.7179 37.302 27.0088 37.0239 27.2043C37.0182 27.2083 37.0124 27.2123 37.0067 27.2164L32.1004 30.7959C28.6502 33.2201 24.6282 34.5264 20.4659 34.5749C18.9254 34.5926 17.2363 34.4052 15.5809 34.032L5.38281 31.7499V23.5521C8.03406 21.2861 11.6943 21.6392 13.3251 22.1756L24.0154 25.6589C24.3353 25.7639 24.5912 25.9869 24.7364 26.2866C24.8816 26.5863 24.8964 26.9231 24.7783 27.2333C24.5445 27.8465 23.8677 28.1648 23.2369 27.9572C23.2369 27.9572 23.2353 27.9572 23.2345 27.9572L15.6941 25.5086C15.0486 25.2994 14.3529 25.6444 14.1405 26.2801C13.928 26.9159 14.2783 27.6009 14.9239 27.8101L22.5093 30.2732C25.1146 31.1287 28.0046 30.7005 30.2391 29.1285C30.244 29.1245 30.2498 29.1212 30.2547 29.1172L35.5998 25.2291C36.1314 24.8608 36.8623 24.9625 37.2692 25.4626C37.4825 25.7251 37.5743 26.0531 37.5268 26.3859L37.5276 26.3851Z"
          fill="#68D585"
        />
        <path
          d="M31.2985 10.3319C30.6414 9.98698 29.8834 9.84804 29.1509 9.71475C28.6021 9.61458 28.0345 9.51037 27.7129 9.34234C27.5488 9.2559 27.5488 9.23409 27.5488 9.08868C27.5488 9.00305 27.6907 8.84633 27.9623 8.71223C28.2518 8.56925 28.5923 8.49412 28.9261 8.48281C28.9319 8.48281 28.9376 8.48281 28.9434 8.48201C29.3773 8.46989 29.7981 8.56844 30.0746 8.77282C30.6184 9.17431 31.3895 9.06606 31.7972 8.53047C32.2049 7.99489 32.095 7.23553 31.5511 6.83404C31.1467 6.53515 30.6685 6.32188 30.1566 6.19424V5.89777C30.1566 5.22889 29.6054 4.68604 28.9261 4.68604C28.2469 4.68604 27.6957 5.22889 27.6957 5.89777V6.2306C27.4094 6.30896 27.1288 6.41316 26.8614 6.54484C25.7343 7.09982 25.0879 8.0272 25.0879 9.08868C25.0879 10.1502 25.5948 10.9758 26.5538 11.4798C27.2109 11.8248 27.9688 11.9637 28.7014 12.097C29.2502 12.1972 29.8178 12.3014 30.1394 12.4694C30.3034 12.5559 30.3034 12.5777 30.3034 12.7231C30.3034 12.8063 30.1574 12.9662 29.8875 13.0987C29.2165 13.4291 28.2699 13.4025 27.7777 13.0389C27.2338 12.6375 26.4627 12.7457 26.055 13.2813C25.6473 13.8169 25.7573 14.5762 26.3011 14.9777C26.7055 15.2766 27.1838 15.4899 27.6957 15.6167V15.914C27.6957 16.5829 28.2469 17.1257 28.9261 17.1257C29.6054 17.1257 30.1566 16.5829 30.1566 15.914V15.5796C30.4421 15.5012 30.7218 15.3978 30.9884 15.2669C32.1172 14.7111 32.7644 13.7838 32.7644 12.7231C32.7644 11.6624 32.2574 10.836 31.2985 10.3319Z"
          fill="#49695C"
        />
        <path
          d="M28.9258 21.8113C22.8194 21.8113 17.8516 16.9191 17.8516 10.9056C17.8516 4.89219 22.8194 0 28.9258 0C35.0322 0 40 4.89219 40 10.9056C40 16.9191 35.0322 21.8113 28.9258 21.8113ZM28.9258 2.42348C24.1762 2.42348 20.3125 6.22833 20.3125 10.9056C20.3125 15.5829 24.1762 19.3878 28.9258 19.3878C33.6754 19.3878 37.5391 15.5829 37.5391 10.9056C37.5391 6.22833 33.6754 2.42348 28.9258 2.42348Z"
          fill="#49695C"
        />
      </g>
      <defs>
        <clipPath id="clip0_5638_7764">
          <rect width="42" height="37" fill="white" transform="translate(-2)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
