import React from 'react';
import { colorPalette } from 'theme';
import { Constants } from 'utilities/constants';
import { TextContainer } from './StyledComponents';

type RelatedProductProps = {
  companyKey?: string;
  companyTitle?: string;
  productKey: string;
  productTitle: string;
};

export const RelatedProduct: React.FC<RelatedProductProps> = ({
  companyKey,
  companyTitle,
  productKey,
  productTitle
}) => {
  return (
    <TextContainer>
      <a
        href={`/${Constants.PRODUCTS}/${productKey}`}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          color: colorPalette.purple,
          fontWeight: 700,
          textDecoration: 'none'
        }}
      >
        {productTitle}
      </a>{' '}
      {Constants.ASSOCIATED_WITH}{' '}
      <a
        href={`/${Constants.COMMUNITIES}/${companyKey}`}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          color: colorPalette.purple,
          fontWeight: 700,
          textDecoration: 'none'
        }}
      >
        {companyTitle}
      </a>
    </TextContainer>
  );
};
