import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ProblemModal from 'modals/AddProblem';
import SolutionModal from 'modals/AddSolution';
import Actions from 'redux-state/actions';
import { Constants } from 'utilities/constants';
import { GetProduct } from 'redux-state/selectors';
import { ActionSection } from 'modals/Common/ActionSection';
import { BaseModal } from 'modals/Common/BaseModal';
import { ContentSection } from 'modals/Common/ContentSection';
import { HeaderSection } from 'modals/Common/HeaderSection';
import { OptionType } from 'modals/Common/interface';
import { Content } from './Content';
import { Footer } from './Footer';
import { Header } from './Header';

interface ProblemSolutionModalProps {
  open?: boolean;
  onClose?: () => void;
}

export const ProblemSolutionModal: React.FC<ProblemSolutionModalProps> = ({
  open,
  onClose
}) => {
  const dispatch = useDispatch();
  const [isProblemModal, setIsProblemModal] = useState<boolean>(false);
  const [isSolutionModal, setIsSolutionModal] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<OptionType | null>();
  const [selectedParentProblem, setSelectedParentProblem] =
    useState<OptionType | null>();
  const [problemData, setProblemData] = useState<string | null>('');
  const [solutionData, setSolutionData] = useState<string | null>('');
  const [sort, setSort] = useState<string>(Constants.PROBLEM);

  const product = GetProduct();
  useEffect(() => {
    if (selectedProduct) {
      dispatch(Actions.getProduct(selectedProduct?.value as string));
    }
  }, [dispatch, selectedProduct]);

  const preSelectedCompany = {
    label: product?.data?.companyName,
    value: product?.data?.company
  };

  const handleFooterAction = () => {
    if (sort === Constants.PROBLEM) {
      setIsProblemModal(true);
    } else if (sort === Constants.SOLUTION) {
      setIsSolutionModal(true);
    }
  };

  return (
    <>
      <BaseModal open={open} maxWidth="md">
        <HeaderSection>
          <Header onClose={onClose} />
        </HeaderSection>
        <ContentSection>
          <Content
            companyId={preSelectedCompany.value}
            selectedParentProblem={selectedParentProblem}
            setProblemData={setProblemData}
            setSelectedParentProblem={setSelectedParentProblem}
            setSelectedProduct={setSelectedProduct}
            setSolutionData={setSolutionData}
            setSort={setSort}
            solutionData={solutionData}
            sort={sort}
          />
        </ContentSection>
        <ActionSection>
          <Footer onAction={handleFooterAction} />
        </ActionSection>
      </BaseModal>
      {isProblemModal && (
        <ProblemModal
          onClose={() => setIsProblemModal(false)}
          open={isProblemModal}
          preSelectedCompany={preSelectedCompany}
          preSelectedProblem={problemData}
          preSelectedProduct={selectedProduct}
        />
      )}
      {isSolutionModal && (
        <SolutionModal
          onClose={() => setIsSolutionModal(false)}
          open={isSolutionModal}
          preSelectedSolution={solutionData}
          selectedParentProblem={selectedParentProblem}
          setSelectedParentProblem={setSelectedParentProblem}
        />
      )}
    </>
  );
};
