import React, { useContext, useCallback, useRef, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import userIcon from 'assets/icons/header-user.svg';
import { Tags } from 'components/Tag';
import { DataContext } from 'contexts/DataContext';
import { Profile } from 'components/CardProfile';
import { AvaImage } from 'components/card';
import { Picture, getPlaceholderImage, placeholderType } from '../../helpers';
import { PsTheme } from '../../theme';
import { Link } from 'react-router-dom';
import Popover from '@mui/material/Popover';
import { makeStyles } from 'tss-react/mui';

const DEFAULT_IMAGE: Picture = {
  title: 'profile',
  contentType: 'image/svg+xml',
  url: getPlaceholderImage(placeholderType.U)
};

type ClassKey =
  | 'holder'
  | 'text'
  | 'icon'
  | 'name'
  | 'popover'
  | 'root'
  | 'paper'
  | 'preview'
  | 'previewLeft'
  | 'previewImage'
  | 'previewRight'
  | 'previewName'
  | 'previewTags'
  | 'previewLink';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    holder: {},
    text: {
      position: 'relative',
      display: 'inline-block',
      paddingLeft: 28,
      cursor: 'pointer',
      textDecoration: 'none',
      '&:hover span': {
        textDecoration: 'underline'
      }
    },
    icon: {
      position: 'absolute',
      left: 10,
      top: '50%',
      height: 15,
      transform: 'translateY(-50%)',
      '&.big': {
        height: 18,
        left: 7
      }
    },
    name: {
      color: psTheme.palette.primary.main
    },
    popover: {
      pointerEvents: 'none'
    },
    root: {},
    paper: {
      pointerEvents: 'auto',
      borderRadius: 2,
      border: '1px solid rgba(121, 114, 140, 0.22)',
      boxShadow:
        '0px 4px 8px rgba(10, 0, 32, 0.02), 0px 4px 84px rgba(7, 0, 21, 0.04)'
    },
    preview: {
      display: 'flex',
      padding: 11
    },
    previewLeft: {
      flexShrink: 0
    },
    previewImage: {
      display: 'block',
      width: 150,
      height: 150,
      borderRadius: 10,
      objectFit: 'cover'
    },
    previewRight: {
      width: 245,
      paddingLeft: 22,
      flexGrow: 0,
      overflow: 'hidden'
    },
    previewName: {
      marginBottom: 12,
      fontSize: 20,
      fontWeight: 600,
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    previewTags: {
      marginBottom: 6,
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    previewLink: {
      fontSize: 16,
      fontWeight: 600,
      color: psTheme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  };
});

type ProfileNameProps = {
  className?: string;
  id?: string | number;
  ownerKey?: string;
  children?: string;
  big?: boolean;
};

const ProfileNameView = ({
  className,
  id,
  ownerKey,
  big,
  children
}: ProfileNameProps) => {
  const { dataProvider } = useContext(DataContext);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const anchorElCache = useRef<HTMLElement | null>();
  const [profile, setProfile] = useState<Profile | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const { classes } = useStyles();
  const open = Boolean(anchorEl);
  const url = `/profiles/${ownerKey || id}`;

  const handlePopoverOpen = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (id && !profile && !loading) {
        setLoading(true);
        dataProvider
          .getOne<Profile>('profiles', { id })
          .then(({ data }) => {
            setProfile(data);
          })
          .catch((err) => {
            console.error(err);
          });
      }
      anchorElCache.current = event.currentTarget;
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleKeepPopoverOpen = useCallback(() => {
    if (anchorElCache.current) {
      setAnchorEl(anchorElCache.current);
    }
  }, [setAnchorEl]);

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  let holderClassName = classes.holder;
  if (className) {
    holderClassName += ' ' + className;
  }

  let profileImage = DEFAULT_IMAGE;
  if (profile && profile.files && profile.files.length) {
    profileImage = profile.files[0];
  }

  return (
    <div className={holderClassName}>
      <Link to={url}>
        <a
          className={classes.text}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <img
            className={`${classes.icon} ${big ? 'big' : ''}`}
            src={userIcon}
            alt="user icon"
          />
          <span className={classes.name}>{children}</span>
        </a>
      </Link>
      {profile ? (
        <Popover
          id="profile-info"
          className={classes.popover}
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          classes={{ paper: classes.paper }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          PaperProps={{
            onMouseEnter: handleKeepPopoverOpen,
            onMouseLeave: handlePopoverClose
          }}
        >
          <div className={classes.preview}>
            <div className={classes.previewLeft}>
              <AvaImage
                className={classes.previewImage}
                image={profileImage}
                imageAlt={
                  typeof children === 'string' ? children : profileImage.title
                }
              />
            </div>
            <div className={classes.previewRight}>
              <div className={classes.previewName}>{children}</div>
              <Tags className={classes.previewTags} tags={profile.tags || []} />
              <Link to={url}>
                <a className={classes.previewLink}>Learn More</a>
              </Link>
            </div>
          </div>
        </Popover>
      ) : null}
    </div>
  );
};

export const ProfileName = styled(ProfileNameView)({});

export default ProfileName;
