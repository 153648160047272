import React, { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import Actions from 'redux-state/actions';

import { AuthContext } from 'contexts/AuthContext';
import useRouter from 'hooks/useRouter';
import { makeStyles } from 'tss-react/mui';
import { links } from '../helpers';
import { PsTheme } from '../theme';

const useStyles = makeStyles()((theme) => {
  const psTheme = theme as PsTheme;
  return {
    root: {
      width: 250
    },
    cancelHolder: {
      textAlign: 'right',
      paddingRight: 10,
      paddingTop: 10,
      '& button': {
        padding: 7
      }
    },
    listItem: {
      padding: 0,
      '&.active': {
        color: psTheme.palette.primary.main,
        background: 'rgba(0, 0, 0, 0.02)'
      },
      '&.active:hover': {
        background: 'rgba(0, 0, 0, 0.04)'
      }
    },
    listItemLink: {
      display: 'block',
      width: '100%',
      padding: '8px 20px',
      color: 'inherit',
      textDecoration: 'none'
    }
  };
});

const PsListMenuView = ({ onMenuToggle }: any) => {
  const { user } = useContext(AuthContext);
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const router = useRouter();
  let search = '';
  if (typeof window !== 'undefined') {
    search = window.location.search;
  }

  const onLoginClick = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(Actions.openAuthModal(true));
  }, []);

  const onMenuIconCloseClick = () => {
    onMenuToggle(false);
  };

  return (
    <div className={classes.root} role="presentation">
      <div className={classes.cancelHolder}>
        <IconButton onClick={onMenuIconCloseClick}>
          <CloseIcon fontSize="large" />
        </IconButton>
      </div>
      <List>
        {links.map(({ title, link }) => {
          let match = router.pathname === link;
          if (
            link !== '/' &&
            link !== '/activity' &&
            router.pathname.startsWith(link)
          ) {
            match = true;
          }

          if (link === '/activity' && !user) {
            return null;
          }

          return (
            <ListItem button key={title}>
              <Link
                className={classes.listItemLink}
                to={`${link}${link !== '/' ? search : ''}`}
              >
                <ListItemText primary={title} />
              </Link>
            </ListItem>
          );
        })}
        {!user ? (
          <>
            <ListItem key="login" button className={classes.listItem}>
              <Link
                to="/#"
                className={classes.listItemLink}
                onClick={onLoginClick}
              >
                <ListItemText primary="Login" />
              </Link>
            </ListItem>
          </>
        ) : null}
      </List>
      {/*<Divider />*/}
    </div>
  );
};

export const PsListMenu = styled(PsListMenuView)({});

export default PsListMenu;
