import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { Box, Grid, styled, useTheme } from '@mui/material';
import { EditIcon, PsButton } from 'components/common/PsButton';
import { PsFormControl, ShortEvent } from 'components/common/PsFormControl';
import { PsInput } from 'components/common/PsInput';
import { PsOptionType } from 'components/common/PsSelect';
import { OptionType } from 'modals/Common/interface';
import { DataContext } from 'contexts/DataContext';
import {
  Picture,
  convertFileToBase64,
  getPlaceholderImage,
  placeholderType
} from 'helpers';
import { Modal } from 'modals/Modal';
import Actions from 'redux-state/actions';
import {
  GetEditProfileLoader,
  GetLocationTags,
  GetUniversityTags,
  GetUser,
  GetWorkplaceTags
} from 'redux-state/selectors';
import { PsTheme } from 'theme';
import { makeStyles } from 'tss-react/mui';
import { Constants, TAG_TYPES } from 'utilities/constants';
import { ProfileTagsField } from '../common/ProfileTagsField';

const DEFAULT_IMAGE: Picture = {
  title: 'profile',
  contentType: 'image/svg+xml',
  url: getPlaceholderImage(placeholderType.U)
};

const useModalStyles = makeStyles()(() => {
  const psTheme = useTheme();
  return {
    modalRoot: {
      paddingLeft: 15,
      paddingRight: 15,
      [psTheme.breakpoints.down('xs')]: {
        paddingTop: 50,
        paddingLeft: 10,
        paddingRight: 10
      }
    },
    modalPaper: {
      width: '100%',
      maxWidth: 1150,
      [psTheme.breakpoints.down('xs')]: {
        padding: '30px 15px 20px 15px'
      }
    },
    modalHeader: {
      fontSize: 36,
      [psTheme.breakpoints.down('sm')]: {
        fontSize: 30
      },
      [psTheme.breakpoints.down('xs')]: {
        fontSize: 25
      }
    }
  };
});

type ClassKey =
  | 'root'
  | 'columns'
  | 'left'
  | 'imageHolder'
  | 'dropzoneContainer'
  | 'right'
  | 'buttons';

const useStyles = makeStyles()((theme) => {
  const psTheme = theme as PsTheme;
  return {
    root: {},
    columns: {
      display: 'flex',
      [psTheme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    },
    left: {
      width: '44%',
      flexShrink: 0,
      [psTheme.breakpoints.down('sm')]: {
        width: '100%',
        maxWidth: 490,
        margin: '0 auto'
      }
    },
    imageHolder: {
      '& .inner': {
        position: 'relative',
        paddingTop: '112%'
      },
      '& img': {
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'block',
        width: '100%',
        height: '100%',
        borderRadius: 10,
        objectFit: 'cover'
      }
    },
    dropzoneContainer: {
      padding: '10px 0',
      '& .link': {
        color: psTheme.palette.primary.main,
        cursor: 'pointer'
      },
      '& .link:hover': {
        textDecoration: 'underline'
      },
      '& .link svg': {
        marginRight: 5,
        marginBottom: -3
      }
    },
    right: {
      paddingLeft: 25,
      flexGrow: 1,
      '& .MuiGrid-container > div': {
        marginTop: -20
      },
      [psTheme.breakpoints.down('sm')]: {
        paddingLeft: 0
      },
      [psTheme.breakpoints.down('xs')]: {
        paddingTop: 20,
        '& .MuiGrid-spacing-xs-2': {
          margin: '0 -8px'
        }
      },
      overflow: 'auto'
    },
    buttons: {
      display: 'flex',
      '& > button': {
        width: '50%'
      },
      '& > button:first-of-type': {
        marginRight: 70
      },
      [psTheme.breakpoints.down('xs')]: {
        flexDirection: 'column-reverse',
        '& > button': {
          width: '100%'
        },
        '& > button:first-of-type': {
          marginRight: 0,
          marginTop: 10
        }
      }
    }
  };
});

type ModalEditProfileProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const ModalEditProfileView = ({ open, setOpen }: ModalEditProfileProps) => {
  const user = GetUser();
  const { dataProvider } = useContext(DataContext);
  const [tags, setTags] = useState<Array<PsOptionType>>([]);
  const [location, setLocation] = useState<OptionType>();
  const [locationValue, setLocationValue] = useState<string>(user?.location);
  const profileEditLoader = GetEditProfileLoader();
  const [picture, setPicture] = useState<Picture>(user?.files[0]);
  const [coverImage, setCoverImage] = useState<Picture>(user?.files[1]);
  const [username, setUsername] = useState<string>(user?.username);
  const [description, setDescription] = useState<string>(user?.description);
  const [tagsValue, setTagsValue] = useState(user?.tags?.join(','));
  const [locationRaw, setLocationRaw] = useState<string>('');
  const [school, setSchool] = useState<OptionType>();
  const [schoolValue, setSchoolValue] = useState<string>(user?.school);
  const [schoolRaw, setSchoolRaw] = useState<string>('');
  const [workplace, setWorkplace] = useState<OptionType>();
  const [workplaceValue, setWorkplaceValue] = useState<string>(user?.workplace);
  const [workplaceRaw, setWorkplaceRaw] = useState<string>('');
  const [inventorAddress, setInventorAddress] = useState<string>(
    user?.inventorAddress
  );
  const [inventorAddressCity, setInventorAddressCity] = useState<string>(
    user?.inventorAddressCity
  );
  const [inventorAddressState, setInventorAddressState] = useState<string>(
    user?.inventorAddressState
  );
  const [inventorAddressZip, setInventorAddressZip] = useState<string>(
    user?.inventorAddressZip
  );

  const [youtubeUrl, setYoutubeUrl] = useState<string>(user?.youtubeUrl);
  const [instagramUrl, setInstagramUrl] = useState<string>(user?.instagramUrl);
  const [linkedinUrl, setLinkedinUrl] = useState<string>(user?.linkedinUrl);
  const { classes } = useStyles();
  const locationSelector = GetLocationTags();
  const universitySelector = GetUniversityTags();
  const workplaceSelector = GetWorkplaceTags();

  const dispatch = useDispatch();

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/gif': ['.gif'],
      'image/svg+xml': ['.svg']
    }
  });
  const {
    getRootProps: getCoverRootProps,
    getInputProps: getCoverInputProps,
    acceptedFiles: acceptedCoverFiles
  } = useDropzone({
    multiple: false,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/gif': ['.gif'],
      'image/svg+xml': ['.svg']
    }
  });

  useEffect(() => {
    if (!acceptedFiles.length) {
      return;
    }
    Promise.all(acceptedFiles.map(convertFileToBase64)).then(([item]) => {
      const { file, base64 } = item;
      const image = {
        contentType: file.type,
        title: file.name,
        url: base64
      };
      setPicture(image);
    });
  }, [acceptedFiles]);

  useEffect(() => {
    if (!acceptedCoverFiles.length) {
      return;
    }
    Promise.all(acceptedCoverFiles.map(convertFileToBase64)).then(([item]) => {
      const { file, base64 } = item;
      const image = {
        contentType: file.type,
        title: file.name,
        url: base64
      };
      setCoverImage(image);
    });
  }, [acceptedCoverFiles]);

  const onClose = () => {
    setOpen(!open);
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (profileEditLoader) {
      return;
    }

    const files: Array<Picture> = [];
    if (picture !== DEFAULT_IMAGE) {
      files.push(picture);
      files.push(coverImage);
    }
    const profileInfo = {
      username,
      description,
      location: location?.label ?? user?.location,
      locationRaw,
      school: school?.label ?? user?.school,
      schoolRaw,
      workplace: workplace?.label ?? user?.workplace,
      workplaceRaw,
      files,
      inventorAddress,
      inventorAddressCity,
      inventorAddressState,
      inventorAddressZip,
      youtubeUrl,
      instagramUrl,
      linkedinUrl,
      tags: tagsValue ? tagsValue : [],
      isFirstLogin: false
    };

    dispatch(Actions.editProfile(user?.id, profileInfo));
    onClose();
  };

  const onUsernameChange = useCallback(
    (e: ShortEvent) => {
      setUsername(e.target.value);
    },
    [setUsername]
  );

  const onTagsChange = useCallback(
    (event: ShortEvent) => {
      const { value } = event.target;
      setTagsValue(value);
    },
    [setTagsValue]
  );

  const onLocationChange = useCallback(
    (e: ShortEvent) => {
      setLocationValue(e.target.label);
      setLocationRaw(e.target.rawValue || '');
    },
    [setLocationValue, setLocationRaw]
  );

  const onSchoolChange = useCallback(
    (e: ShortEvent) => {
      setSchoolValue(e.target.label);
      setSchoolRaw(e.target.rawValue || '');
    },
    [setSchoolValue, setSchoolRaw]
  );

  const onwWorkplaceChange = useCallback(
    (e: ShortEvent) => {
      setWorkplaceValue(e.target.label);
      setWorkplaceRaw(e.target.rawValue || '');
    },
    [setWorkplaceValue, setWorkplaceRaw]
  );

  const onInventorAddressChange = useCallback(
    (e: ShortEvent) => {
      setInventorAddress(e.target.value);
    },
    [setInventorAddress]
  );

  const onInventorAddressCityChange = useCallback(
    (e: ShortEvent) => {
      setInventorAddressCity(e.target.value);
    },
    [setInventorAddressCity]
  );

  const onInventorAddressStateChange = useCallback(
    (e: ShortEvent) => {
      setInventorAddressState(e.target.value);
    },
    [setInventorAddressState]
  );

  const onInventorAddressZipChange = useCallback(
    (e: ShortEvent) => {
      setInventorAddressZip(e.target.value);
    },
    [setInventorAddressZip]
  );

  const onYoutubeUrlChange = useCallback(
    (e: ShortEvent) => {
      setYoutubeUrl(e.target.value);
    },
    [setYoutubeUrl]
  );

  const onInstagramUrlChange = useCallback(
    (e: ShortEvent) => {
      setInstagramUrl(e.target.value);
    },
    [setInstagramUrl]
  );

  const onLinkedinUrlChange = useCallback(
    (e: ShortEvent) => {
      setLinkedinUrl(e.target.value);
    },
    [setLinkedinUrl]
  );

  const onDescriptionChange = useCallback(
    (e: ShortEvent) => {
      setDescription(e.target.value);
    },
    [setDescription]
  );
  const formatTag = (tag) => ({ label: tag, value: tag });

  return (
    <Modal open={open} onClose={onClose} title="Edit profile information">
      <div className={classes.columns}>
        <div className={classes.left}>
          <div className={classes.imageHolder}>
            <div className="inner">
              {picture?.url && <img src={picture?.url} />}
            </div>
          </div>
          <div
            {...getRootProps({
              className: classes.dropzoneContainer
            })}
          >
            <input name="files" {...getInputProps()} />
            <span className="link">
              <EditIcon />
              {Constants.EDIT_PROFILE_PHOTO}
            </span>
          </div>

          <Box style={{ marginTop: '1.5625rem' }}>
            <Box className={classes.imageHolder}>
              <Box className="inner">
                {coverImage?.url && <img src={coverImage?.url} />}
              </Box>
            </Box>
          </Box>

          <div
            {...getCoverRootProps({
              className: classes.dropzoneContainer
            })}
          >
            <input name="files" {...getCoverInputProps()} />
            <span className="link">
              <EditIcon />
              {Constants.EDIT_COVER_PHOTO}
            </span>
          </div>
        </div>
        <div className={classes.right}>
          <form onSubmit={onSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <PsFormControl label="Name" labelLight>
                  <PsInput
                    name="username"
                    onChange={onUsernameChange}
                    value={username}
                  />
                </PsFormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <PsFormControl label="Description" labelLight>
                  <PsInput
                    name="description"
                    onChange={onDescriptionChange}
                    value={description}
                  />
                </PsFormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <PsFormControl
                  label="My Location"
                  placeholder="type to search..."
                  labelLight
                >
                  <ProfileTagsField
                    action={Actions.getLocationTags}
                    isProfile={true}
                    selectedTags={
                      location
                        ? location
                        : user?.location
                          ? formatTag(user?.location)
                          : ''
                    }
                    setSelectedTags={setLocation}
                    tagsSelector={locationSelector}
                    tagType={TAG_TYPES.LOCATION}
                  />
                </PsFormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <PsFormControl
                  label="My School"
                  placeholder="type to search..."
                  labelLight
                >
                  <ProfileTagsField
                    action={Actions.getUniversityTags}
                    isProfile={true}
                    selectedTags={
                      school
                        ? school
                        : user?.school
                          ? formatTag(user?.school)
                          : ''
                    }
                    setSelectedTags={setSchool}
                    tagsSelector={universitySelector}
                    tagType={TAG_TYPES.UNIVERSITY}
                  />
                </PsFormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <PsFormControl
                  label="My Workplace"
                  placeholder="type to search..."
                  labelLight
                >
                  <ProfileTagsField
                    action={Actions.getWorkplaceTags}
                    isProfile={true}
                    selectedTags={
                      workplace
                        ? workplace
                        : user?.workplace
                          ? formatTag(workplace)
                          : ''
                    }
                    setSelectedTags={setWorkplace}
                    tagsSelector={workplaceSelector}
                    tagType={TAG_TYPES.WORKPLACE}
                  />
                </PsFormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={7}>
                <PsFormControl
                  placeholder="Type inventor street address"
                  label="My Inventor Street Address"
                  labelLight
                >
                  <PsInput
                    name="inventorAddress"
                    value={inventorAddress}
                    onChange={onInventorAddressChange}
                  />
                </PsFormControl>
              </Grid>
              <Grid item xs={12} sm={5}>
                <PsFormControl
                  placeholder="Type inventor city"
                  label="My Inventor City"
                  labelLight
                >
                  <PsInput
                    name="inventorAddressCity"
                    value={inventorAddressCity}
                    onChange={onInventorAddressCityChange}
                  />
                </PsFormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={7}>
                <PsFormControl
                  placeholder="Type inventor state"
                  label="My Inventor State"
                  labelLight
                >
                  <PsInput
                    name="inventorAddressState"
                    value={inventorAddressState}
                    onChange={onInventorAddressStateChange}
                  />
                </PsFormControl>
              </Grid>
              <Grid item xs={12} sm={5}>
                <PsFormControl
                  placeholder="Type inventor zip"
                  label="My Inventor Zip"
                  labelLight
                >
                  <PsInput
                    name="inventorAddressZip"
                    value={inventorAddressZip}
                    onChange={onInventorAddressZipChange}
                  />
                </PsFormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <PsFormControl label="YouTube Url" labelLight>
                  <PsInput
                    name="youtubeUrl"
                    value={youtubeUrl}
                    onChange={onYoutubeUrlChange}
                  />
                </PsFormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <PsFormControl label="Instagram Url" labelLight>
                  <PsInput
                    name="instagramUrl"
                    value={instagramUrl}
                    onChange={onInstagramUrlChange}
                  />
                </PsFormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <PsFormControl label="LinkedIn Url" labelLight>
                  <PsInput
                    name="linkedinUrl"
                    value={linkedinUrl}
                    onChange={onLinkedinUrlChange}
                  />
                </PsFormControl>
              </Grid>
            </Grid>

            <div className={classes.buttons}>
              <PsButton
                disabled={profileEditLoader}
                color="secondary"
                onClick={onClose}
                cancel
                small
              >
                Cancel
              </PsButton>
              <PsButton
                disabled={profileEditLoader}
                color="secondary"
                type="submit"
                small
              >
                Save changes
              </PsButton>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export const ModalEditProfile = styled(ModalEditProfileView)({});

export default ModalEditProfile;
