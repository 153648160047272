import React, { useState, useContext, useCallback } from 'react';

import ModalWindow from 'modals/ModalWindow';
import { PsButton } from 'components/common/PsButton';
import Stepper from 'components/Stepper';
import Step from 'components/Step';
import { Application } from 'components/CardApplication';
import { ModalComponentsKeys, ModalContext } from 'contexts/ModalContext';
import {
  AddSolutionToInventionSteps,
  AddSolutionSteps,
  ModalDataContext,
  StepComponentProps
} from 'contexts/ModalDataContext';
import { DataContext } from 'contexts/DataContext';
import {
  ADD_SOL_TO_INV_STEPS_CONFIG,
  defaultSolutionTitle,
  stepName
} from './config';
import styles from 'modals/ModalWindow.module.scss';
import { removeDuplicates } from '../../helpers';
import { Solution } from '../../components/CardSolution';
import { makeStyles } from 'tss-react/mui';

const stepsSolution: string[] = [
  'Solution Name',
  'Tag Name',
  'Finalize Solution'
];

const useButtonClasses = makeStyles()(() => {
  return {
    root: {
      margin: '8px 0',
      fontWeight: 400
    }
  };
});

export const AddSolutionToInvention: React.FC = () => {
  const { openModal, closeModal } = useContext(ModalContext);
  const { values, updateField, resetFields, setErrors } =
    useContext(ModalDataContext);
  const {
    dataProvider,
    refreshApplicationDetail,
    refreshSolutionDetail,
    refreshRelatedSolutionsList
  } = useContext(DataContext);
  const [loading, setLoading] = useState(false);
  const [prevSteps, setPrevSteps] = useState<string[]>([]);
  const [activeStepNumber, setActiveStepNumber] = useState<number>(0);
  const [currentStep, setCurrentStep] = useState<string | undefined>(
    AddSolutionToInventionSteps.SolutionSelect
  );
  const [stepsName, setStepsName] = useState<Array<string>>([]);

  const buttonClasses = useButtonClasses();

  const {
    Component,
    nextStep,
    validate,
    nextButtonTitle,
    backButtonTitle,
    isNextStepBlocked,
    isFinalStep,
    name
  } = ADD_SOL_TO_INV_STEPS_CONFIG[currentStep || ''];

  const StepComponent = Component as React.FC<StepComponentProps>;

  const handleClickBack = useCallback(() => {
    if (name === stepName.SOLUTION_START) {
      setPrevSteps([]);
      setErrors({});
      setActiveStepNumber(0);
      setCurrentStep(AddSolutionToInventionSteps.SolutionSelect);
      setStepsName([]);
      return;
    }

    if (activeStepNumber === 0) {
      closeModal();
      resetFields;
      return;
    }

    setActiveStepNumber((prev) => Math.max(0, prev - 1));
    setCurrentStep(prevSteps[prevSteps.length - 1]);
    setPrevSteps(prevSteps.slice(0, prevSteps.length - 1));
  }, [activeStepNumber, closeModal, name, prevSteps, resetFields, setErrors]);

  const handleNextStep = async () => {
    if (typeof validate === 'function') {
      const { success, errors } = validate(values);

      if (!success) {
        setErrors({ ...errors });

        return;
      }
    }

    if (name === stepName.SOLUTION_END) {
      const solutionInfo = {
        title: (values.titleSolution || '').replace(defaultSolutionTitle, ''),
        tags: removeDuplicates(values.tagsSolution),
        body: values.descriptionSolution,
        files: values.filesSolution,
        problem: values.parentProblem,
        isPublic: values.finalizeType === 'public',
        termsAgree: values.termsAgree,
        isInProgress: false
      };

      setLoading(true);

      dataProvider
        .create<Solution>('solutions', { data: solutionInfo })
        .then(({ data }) => {
          refreshSolutionDetail();
          refreshRelatedSolutionsList();
          if (values.selectApplication) {
            return dataProvider
              .addSolutionToProblem(
                values.parentProblem || '',
                values.selectApplication || '',
                data.id // solution id
              )
              .then(() => {
                return data.key;
              });
          }
          return data.key;
        })
        .then((solutionKey) => {
          refreshApplicationDetail();
          // router.push(`/solutions/${solutionKey}`);
          openModal(ModalComponentsKeys.success);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });

      return;
    }

    if (isFinalStep) {
      setLoading(true);

      dataProvider
        .update<Application>('applications', {
          id: values.application.id,
          data: {
            selected: values.selected || []
          }
        })
        .then((data) => {
          refreshApplicationDetail();
          openModal(ModalComponentsKeys.success);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });

      return;
    }

    setPrevSteps([...prevSteps, currentStep || '']);
    setErrors({});
    setActiveStepNumber((prev) => prev + 1);
    if (typeof nextStep === 'function') {
      setCurrentStep(nextStep(values));
      return;
    }

    setCurrentStep(nextStep);
  };

  const onStepNotify = (args: any) => {
    if (args === 'create solution') {
      updateField('parentProblem', values.application.problem);
      updateField('parentProblemHide', true);
      updateField('parentSolutionHide', true);
      updateField('selectApplication', values.application.id);
      updateField('selectApplicationLabel', values.application.title);
      setPrevSteps([...prevSteps, currentStep || '']);
      setErrors({});
      setActiveStepNumber(1);
      setCurrentStep(AddSolutionSteps.SolutionName);
      setStepsName(stepsSolution);
    }
  };

  const isNextStepDisabled: boolean =
    typeof isNextStepBlocked === 'function' && isNextStepBlocked(values);

  const title = 'Add Solutions to Invention';

  const stepperStep = activeStepNumber - 1;

  return (
    <ModalWindow title={title}>
      {stepsName.length > 0 ? (
        <Stepper className={styles.stepper} activeStep={stepperStep}>
          {stepsName.map((step) => (
            <Step key={step}>{step}</Step>
          ))}
        </Stepper>
      ) : null}
      <StepComponent onNotify={onStepNotify} />
      <div className={styles.actions}>
        <PsButton
          disabled={isNextStepDisabled || loading}
          loading={loading}
          onClick={handleNextStep}
          fullWidth
          classes={buttonClasses}
        >
          {nextButtonTitle || 'Next'}
        </PsButton>
        <PsButton
          disabled={loading}
          onClick={handleClickBack}
          fullWidth
          cancel
          classes={buttonClasses}
        >
          {backButtonTitle || 'Back'}
        </PsButton>
      </div>
    </ModalWindow>
  );
};

export default AddSolutionToInvention;
