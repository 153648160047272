import React from 'react';
import { Box, styled } from '@mui/material';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import { colorPalette } from 'theme';
import { useIsMediumScreen } from '../../theme';

interface ExpandableRowProps {
  expandedId: string;
  id: string;
}

export const ArrowIconButton: React.FC<ExpandableRowProps> = ({
  id,
  expandedId
}) => {
  const isMediumScreen = useIsMediumScreen();

  const commonWidthHeight = isMediumScreen ? '0.8rem' : '1rem';

  return (
    <ArrowButton isMediumScreen={isMediumScreen} aria-label="expand row">
      {id === expandedId ? (
        <KeyboardArrowUp
          sx={{
            width: commonWidthHeight,
            height: commonWidthHeight
          }}
        />
      ) : (
        <KeyboardArrowDown
          sx={{
            width: commonWidthHeight,
            height: commonWidthHeight
          }}
        />
      )}
    </ArrowButton>
  );
};

const ArrowButton = styled(Box)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    alignSelf: 'center',
    background: colorPalette.lightPurple,
    borderRadius: '30%',
    color: colorPalette.darkPurple,
    display: 'flex',
    marginTop: '3px',
    padding: isMediumScreen ? '0px' : '5px',
    width: isMediumScreen ? '0.8rem' : '1.5rem',
    height: isMediumScreen ? '0.8rem' : '1.5rem'
  })
);
