import React from 'react';
import { Typography } from '@mui/material';

export type IconButtonProps = {
  hasBorder?: boolean;
  title: string;
  onChange?: () => void;
  color: string;
  icon: string;
};

const IconButton = ({
  title,
  onChange,
  color,
  icon,
  hasBorder
}: IconButtonProps) => {
  return (
    <div
      className={
        hasBorder
          ? 'icon_button_container'
          : 'icon_button_container_without_border'
      }
      onClick={onChange}
    >
      <img width={20} height={20} src={icon} />
      <Typography
        marginLeft={1}
        color={color}
        variant="body2"
        fontWeight="bold"
      >
        {title}
      </Typography>
    </div>
  );
};

export default IconButton;
