import React from 'react';

export const PersonOutlinedIcon = ({ size = 20 }: { size?: number }) => {
  return (
    <svg
      width="8"
      height="9"
      viewBox="0 0 8 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.32715 2.41541C6.32715 2.00741 6.20617 1.60858 5.9795 1.26934C5.75283 0.930103 5.43065 0.6657 5.05371 0.509566C4.67677 0.353433 4.262 0.312581 3.86184 0.392177C3.46168 0.471774 3.09411 0.668243 2.80562 0.95674C2.51712 1.24524 2.32065 1.61281 2.24105 2.01296C2.16146 2.41312 2.20231 2.82789 2.35844 3.20483C2.51458 3.58177 2.77898 3.90395 3.11822 4.13062C3.45745 4.35729 3.85629 4.47828 4.26429 4.47828C4.81122 4.47773 5.33561 4.26022 5.72235 3.87347C6.10909 3.48673 6.32661 2.96235 6.32715 2.41541ZM2.88904 2.41541C2.88904 2.14341 2.9697 1.87752 3.12081 1.65136C3.27192 1.4252 3.48671 1.24894 3.738 1.14485C3.98929 1.04076 4.26581 1.01352 4.53258 1.06659C4.79935 1.11965 5.0444 1.25063 5.23673 1.44296C5.42906 1.63529 5.56004 1.88034 5.61311 2.14711C5.66617 2.41388 5.63894 2.6904 5.53485 2.94169C5.43076 3.19298 5.25449 3.40777 5.02833 3.55888C4.80217 3.71 4.53628 3.79065 4.26429 3.79065C3.89955 3.79065 3.54975 3.64576 3.29184 3.38785C3.03393 3.12994 2.88904 2.78015 2.88904 2.41541ZM5.29572 5.1659H3.23285C2.59478 5.16663 1.98306 5.42042 1.53188 5.8716C1.08069 6.32278 0.8269 6.93451 0.826172 7.57258V8.2602C0.826172 8.35138 0.862395 8.43883 0.926872 8.50331C0.991349 8.56779 1.0788 8.60401 1.16998 8.60401C1.26117 8.60401 1.34862 8.56779 1.41309 8.50331C1.47757 8.43883 1.51379 8.35138 1.51379 8.2602V7.57258C1.51434 7.11682 1.69563 6.67989 2.0179 6.35762C2.34017 6.03536 2.7771 5.85407 3.23285 5.85352H5.29572C5.75147 5.85407 6.18841 6.03536 6.51067 6.35762C6.83294 6.67989 7.01423 7.11682 7.01478 7.57258V8.2602C7.01478 8.35138 7.051 8.43883 7.11548 8.50331C7.17995 8.56779 7.2674 8.60401 7.35859 8.60401C7.44977 8.60401 7.53722 8.56779 7.6017 8.50331C7.66618 8.43883 7.7024 8.35138 7.7024 8.2602V7.57258C7.70167 6.93451 7.44788 6.32278 6.99669 5.8716C6.54551 5.42042 5.93379 5.16663 5.29572 5.1659Z"
        fill="#632DDD"
      />
    </svg>
  );
};
