/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import Config from 'config/config';
import { Avatar, Box, Typography } from '@mui/material';
import { Card, CardBody, CardRow } from 'components/card-small';
import { formatDistanceToNow } from 'date-fns';
import { GiTwoCoins } from 'react-icons/gi';
import { GoDotFill } from 'react-icons/go';
import { MdStars } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import Actions from 'redux-state/actions';
import { GetUser } from 'redux-state/onboarding/selectors';
import { Constants } from 'utilities/constants';
import { IdeaCoinsButton, NotificationsButton } from './styledComponents';
import { ShareBtnSimple } from '../ShareBtn';
import { colorPalette } from '../../theme';

export const NotificationCard = ({ notification }: any) => {
  const user = GetUser();

  return (
    <>
      <Card
        sx={{
          border: 'none',
          borderRadius: 0,
          boxShadow: 'none',
          margin: 0,
          marginTop: '20px'
        }}
      >
        {!notification.read && (
          <GoDotFill
            color={colorPalette.purple}
            size={20}
            style={{ position: 'absolute', left: '-10px' }}
          />
        )}
        <Avatar
          sx={{ width: '35px', height: '35px', marginLeft: '8px' }}
          src={notification?.ownerId?.files[0]?.url}
        />
        <CardBody sx={{ padding: 0, marginLeft: '20px' }}>
          <CardRow
            sx={{ fontWeight: 600, fontSize: '14px', marginBottom: '5px' }}
          >
            {notification?.ownerId?.username}
            {notification?.ownerId?.id === user?.id ? ' (you)' : ''}
          </CardRow>
          <CardRow sx={{ fontWeight: 500, fontSize: '14px' }}>
            {getDescription(notification)}
          </CardRow>
          <CardRow sx={{ justifyContent: 'normal' }}>
            <NotificationsButtonMapper
              actions={notification.actions}
              notification={notification}
            />
            <IdeaCoinsButtonMapper
              ideapoints={notification.ideaPoints}
              ideacoins={notification.ideaCoints}
            />
          </CardRow>
          <CardRow
            sx={{
              justifyContent: 'end',
              marginRight: '15px'
            }}
          >
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '10px'
              }}
            >
              {formatDistanceToNow(new Date(notification.createdAt))} ago
            </Typography>
          </CardRow>
        </CardBody>
      </Card>
      <hr style={{ width: '120%', marginLeft: '-10%' }} />
    </>
  );
};

export const NotificationsButtonMapper = ({
  actions,
  notification
}: {
  actions: string[];
  notification?: any;
}) => {
  const dispatch = useDispatch();
  const user = GetUser();
  const [passedAnchor, setPassedAnchor] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (!user) {
      dispatch(Actions.openAuthModal(true));
      return;
    }
    setPassedAnchor(event.currentTarget);
  };
  return (
    <>
      {actions.map((action: string, index: number) => {
        const buttons = [];
        switch (action) {
          case Constants.CREATE_CONTEST_S:
            buttons.push(
              <NotificationsButton
                sx={{
                  backgroundColor: colorPalette.lightGreen,
                  lineHeight: '1.25rem',
                  ':hover': {
                    backgroundColor: colorPalette.darkGreen
                  }
                }}
                onClick={() => {
                  window.location.href = `${Config.NEXT_PUBLIC_CLIENT_URL}contests/${notification.itemId.id}`;
                }}
              >
                {Constants.JOIN_CONTEST}
              </NotificationsButton>
            );
            break;
          case Constants.VIEW_NFT:
            buttons.push(
              <NotificationsButton
                sx={{
                  backgroundColor: colorPalette.purple,
                  ':hover': {
                    backgroundColor: colorPalette.darkPurple
                  }
                }}
                onClick={() => {
                  window.location.href = `${Config.NEXT_PUBLIC_CLIENT_URL}${notification.itemId.type}/${notification.itemId.id}`;
                }}
              >
                {Constants.VIEW_SELL_NFT}
              </NotificationsButton>
            );
            break;
          case Constants.IMPROVED_SOLUTION:
            buttons.push(
              <NotificationsButton
                sx={{
                  backgroundColor: colorPalette.purple,
                  ':hover': {
                    backgroundColor: colorPalette.darkPurple
                  }
                }}
                onClick={() => {
                  window.location.href = `${Config.NEXT_PUBLIC_CLIENT_URL}${notification.itemId.type}/${notification.itemId.id}`;
                }}
              >
                {Constants.PATENT_PENDING_TOKEN}
              </NotificationsButton>
            );
            break;
          case Constants.SHARE:
            buttons.push(
              <NotificationsButton
                sx={{
                  backgroundColor: colorPalette.purple,
                  ':hover': {
                    backgroundColor: colorPalette.darkPurple
                  }
                }}
                onClick={handlePopoverOpen}
              >
                {Constants.SHARE_MORE_EARN_MORE}
              </NotificationsButton>
            );
            <ShareBtnSimple
              problem={notification}
              passedAnchor={passedAnchor}
            />;
            break;
          default:
            break;
        }
        return <React.Fragment key={index}>{buttons}</React.Fragment>;
      })}
    </>
  );
};

export const IdeaCoinsButtonMapper = ({
  ideapoints,
  ideacoins
}: {
  ideapoints: number;
  ideacoins: number;
}) => {
  return (
    <>
      {ideapoints ? (
        <IdeaCoinsButton>
          <MdStars
            color={colorPalette.goldenYellow}
            size={20}
            style={{ marginRight: '5px' }}
          />
          Receives
          <span style={{ fontWeight: 600, margin: '0px 4px' }}>
            {ideapoints}
          </span>
          IdeaPoint{ideapoints && ideapoints !== 1 ? 's' : ''}
        </IdeaCoinsButton>
      ) : null}
      {ideacoins ? (
        <IdeaCoinsButton>
          <GiTwoCoins
            color={colorPalette.goldenYellow}
            size={20}
            style={{ marginRight: '5px' }}
          />
          Receives
          <span style={{ fontWeight: 600, margin: '0px 4px' }}>
            {ideacoins}
          </span>
          additional IdeaPoint{ideacoins && ideacoins !== 1 ? 's' : ''} Jackpot
        </IdeaCoinsButton>
      ) : null}
    </>
  );
};

export const getDescription = (notification: any) => {
  if (!notification) return;
  const { actions, itemType } = notification;

  const desc = ({
    text,
    type,
    id,
    beforeTitle,
    title,
    afterTitle
  }: {
    text: string;
    type: string;
    id: string;
    beforeTitle?: string;
    title: string;
    afterTitle?: string;
  }) => {
    return (
      <Box style={{ display: 'inline' }}>
        {text} {beforeTitle && beforeTitle}
        <span
          onClick={() => {
            if (type && id) {
              window.location.href = `${Config.NEXT_PUBLIC_CLIENT_URL}${type}/${id}`;
            }
          }}
          style={{
            color: colorPalette.purple,
            cursor: 'pointer',
            fontWeight: 'bold',
            textDecoration: 'underline',
            margin: '0px 4px'
          }}
        >
          {title}
        </span>
        {afterTitle && afterTitle}
      </Box>
    );
  };

  switch (actions[0]) {
    case 'create contest':
      return desc({
        text: 'created a contest',
        type: `${itemType}s`,
        id: `${notification.itemId.id}`,
        title: `${notification.itemId.title}`
      });
    case 'tagcreate':
      return desc({
        text: 'created a community',
        type: `communities`,
        id: `${notification.itemId.key}`,
        title: `${notification.itemId.name}`
      });
    case 'follow':
      return desc({
        text: 'followed a community',
        type: `communities`,
        id: `${notification.itemId.key}`,
        title: `${notification.itemId.name}`
      });
    case 'unfollow':
      return desc({
        text: 'unfollowed a community',
        type: `communities`,
        id: `${notification.itemId.key}`,
        title: `${notification.itemId.name}`
      });
    case Constants.CREATED_PROBLEM:
      return desc({
        text: 'created a problem',
        type: `${itemType}`,
        id: `${notification.itemId.id}`,
        title: `${notification.itemId.title}`
      });
    case Constants.CREATED_SOLUTION:
      return desc({
        text: 'created a solution',
        type: `${itemType}`,
        id: `${notification.itemId.id}`,
        title: `${notification.itemId.title}`
      });
    case Constants.IMPROVED_PROBLEM:
      return desc({
        text: 'problem',
        type: `${itemType}`,
        id: `${notification.itemId.id}`,
        title: `${notification.itemId.title}`,
        afterTitle: `${Constants.IMPROVED_STATEMENT}`
      });
    case Constants.IMPROVED_SOLUTION:
      return desc({
        text: 'solution',
        type: `${itemType}`,
        id: `${notification.itemId.id}`,
        title: `${notification.itemId.title}`,
        afterTitle: `${Constants.IMPROVED_STATEMENT}`
      });
    case Constants.CONCEPT_SOLUTION:
      return desc({
        text: 'solution',
        type: `${itemType}`,
        id: `${notification.itemId.id}`,
        title: `${notification.itemId.title}`,
        afterTitle: `${Constants.CONCEPT_STATEMENT}`
      });
    case Constants.CONCEPT_PROBLEM:
      return desc({
        text: 'problem',
        type: `${itemType}s`,
        id: `${notification.itemId.id}`,
        title: `${notification.itemId.title}`,
        afterTitle: `${Constants.CONCEPT_STATEMENT}`
      });
    case Constants.INVITE:
      return desc({
        text: 'invited',
        type: `${itemType}s`,
        id: `${notification.itemId.id}`,
        title: `${notification.itemId.email}`,
        afterTitle: `${Constants.INVITE_STATEMENT}`
      });
    case Constants.SHARE:
      return desc({
        text: 'shared a',
        type: `${itemType}s`,
        id: `${notification.itemId.id}`,
        beforeTitle: `${itemType}`,
        title: `${notification.itemId.title}`
      });
    case Constants.CONTEST_WINNER:
      return desc({
        text: 'won a contest',
        type: `${itemType}s`,
        id: `${notification.itemId.id}`,
        title: `${notification.itemId.title}`
      });
    case Constants.SOLUTION_PATENT_PENDING:
      return desc({
        text: 'solution',
        type: `${itemType}s`,
        id: `${notification.itemId.id}`,
        title: `${notification.itemId.title}`,
        afterTitle: `${Constants.SOLUTION_PATENT_PENDING_STATEMENT}`
      });
    case Constants.SOLUTION_PURCHASED_PATENT:
      return desc({
        text: 'solution',
        type: `${itemType}s`,
        id: `${notification.itemId.id}`,
        title: `${notification.itemId.title}`,
        afterTitle: `${Constants.SOLUTION_PURCHASED_PATENT_STATEMENT}`
      });
    case Constants.PATENT_PENDING:
      return desc({
        text: 'filed a patent pending token',
        type: `${itemType}s`,
        id: `${notification.itemId.id}`,
        title: `${notification.itemId.title}`
      });
    case Constants.PURCHASED_PATENT:
      return desc({
        text: 'purchased a patent application',
        type: `${itemType}s`,
        id: `${notification.itemId.id}`,
        title: `${notification.itemId.title}`
      });
    case Constants.REFERRED_PROBLEM:
      return desc({
        text: 'referee',
        type: `${itemType}s`,
        id: `${notification.itemId.id}`,
        beforeTitle: `${notification?.itemId?.owner?.username} ${Constants.REFEREE_CREATED_PROBLEM_STATEMENT}`,
        title: `${notification.itemId.title}`
      });
    case Constants.REFERRED_SOLUTION:
      return desc({
        text: 'referee',
        type: `${itemType}s`,
        id: `${notification.itemId.id}`,
        beforeTitle: `${notification?.itemId?.owner?.username} ${Constants.REFEREE_CREATED_SOLUTION_STATEMENT}`,
        title: `${notification.itemId.title}`
      });
    case Constants.REFERRED_CONCEPT_ADDITION:
      return desc({
        text: 'referee',
        type: `${itemType}s`,
        id: `${notification.itemId.id}`,
        beforeTitle: `${notification?.itemId?.owner?.username} ${Constants.REFEREE_ADDED_TO_CONCEPT_STATEMENT}`,
        title: `${notification.itemId.title}`
      });
    default:
      return '';
  }
};
