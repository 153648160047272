import React, { useContext, useState, useCallback, useEffect } from 'react';
import { PsFormControl, ShortEvent } from 'components/common/PsFormControl';
import { PsInput } from 'components/common/PsInput';
import { PsSelect, PsOptionType } from 'components/common/PsSelect';
import { ModalDataContext } from 'contexts/ModalDataContext';
import { DataContext } from 'contexts/DataContext';
import styles from 'modals/ModalWindow.module.scss';
import Typography from '@mui/material/Typography';

const methods: Array<PsOptionType> = [
  { value: 'Eliminate', label: 'Eliminate' },
  { value: 'Reduce', label: 'Reduce' },
  { value: 'Substitute', label: 'Substitute' },
  { value: 'Separate', label: 'Separate' },
  { value: 'Integrate', label: 'Integrate' },
  { value: 'Re-Use', label: 'Re-Use' },
  { value: 'Standardize', label: 'Standardize' },
  { value: 'Add', label: 'Add' }
];

const objects: Array<PsOptionType> = [
  { value: 'Function', label: 'Function' },
  { value: 'Part', label: 'Part' },
  { value: 'Process', label: 'Process' },
  { value: 'Material', label: 'Material' },
  { value: 'People', label: 'People' }
];

const ChooseTypeStep: React.FC = () => {
  const { values, errors, updateField, handleInputChange } =
    useContext(ModalDataContext);
  const { dataProvider } = useContext(DataContext);
  const [title, setTitle] = useState<React.ReactNode>();
  const [newTitle, setNewTitle] = useState(values.titleSolution);
  const [method, setMethod] = useState(values.brainMethod);
  const [object, setObject] = useState(values.brainObject);

  useEffect(() => {
    if (values.parentSolutionLabel && !newTitle) {
      setNewTitle(`Improved ${values.parentSolutionLabel || ''}`);
    }
  }, [values.parentSolutionLabel]);

  useEffect(() => {
    const solTitle = values.parentSolutionLabel || '';
    let base = (
      <>
        Improve the solution of <b>{solTitle}</b>
      </>
    );
    if (method && object) {
      base = (
        <>
          <b>{method}</b> <b>{object.toLowerCase()}</b> to improve the solution
          of <b>{solTitle}</b>
        </>
      );
    } else if (method) {
      base = (
        <>
          <b>{method}</b> something to improve the solution of <b>{solTitle}</b>
        </>
      );
    }
    setTitle(base);
  }, [method, object]);

  const onNewTitleChange = useCallback(
    (e: ShortEvent) => {
      const { value = '' } = e.target;
      setNewTitle(value);
      updateField('titleSolution', value);
    },
    [setNewTitle, updateField]
  );

  const onMethodChange = useCallback(
    (e: ShortEvent) => {
      handleInputChange(e);
      setMethod(e.target.value);
      const defaultDescription = `${e.target.value} a ${(
        object || ''
      ).toLowerCase()} for the solution of a`;
      updateField('description', defaultDescription);
    },
    [setMethod, object, updateField, handleInputChange]
  );

  const onObjectChange = useCallback(
    (e: ShortEvent) => {
      handleInputChange(e);
      setObject(e.target.value);
      const defaultDescription = `${method} a ${(
        e.target.value || ''
      ).toLowerCase()} for the solution of a`;
      updateField('description', defaultDescription);
    },
    [setObject, method, updateField, handleInputChange]
  );

  return (
    <div className={styles.stepWrapper}>
      <Typography className={styles.text}>{title}</Typography>

      <PsFormControl
        placeholder="Choose improved solution title"
        label="Improved Solution title"
        error={!!errors.titleSolution}
      >
        <PsInput
          name="titleProblem"
          value={newTitle}
          onChange={onNewTitleChange}
        />
      </PsFormControl>

      <PsFormControl
        placeholder="Choose a Brainstorming Action"
        label="Choose a brainstorming action:"
      >
        <PsSelect
          maxMenuHeight={290}
          name="brainMethod"
          onChange={onMethodChange}
          options={methods}
          value={method}
        />
      </PsFormControl>

      <PsFormControl
        placeholder="Choose a Brainstorming Item"
        label="Choose a brainstorming item:"
      >
        <PsSelect
          maxMenuHeight={270}
          name="brainObject"
          onChange={onObjectChange}
          options={objects}
          value={object}
        />
      </PsFormControl>
    </div>
  );
};

export default ChooseTypeStep;
