import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { Telegram } from '@mui/icons-material';
import { ContainedIconButton } from 'components/common/buttons/ContainedIconButton';
import { CustomTextField } from 'components/common/inputs/CustomTextField';
import { GetUser } from 'redux-state/selectors';
import { colorPalette } from 'theme';
import { Constants, INPUT_TYPES } from 'utilities/constants';
import {
  ButtonWrapper,
  StyledAvatar,
  TextFieldWrapper
} from './styledComponents';

interface ChatInputProps {
  disabled?: boolean;
  onSubmit: () => void;
  placeholderText?: string;
  setValue: Dispatch<SetStateAction<string>>;
  value: string;
}

export const ChatInput: React.FC<ChatInputProps> = ({
  disabled,
  onSubmit,
  placeholderText,
  setValue,
  value
}) => {
  const user = GetUser();

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const input = e.target.value;
      setValue(input);
    },
    [setValue]
  );

  const handleKeyDown = (e) => {
    if (e.key === Constants.ENTER) {
      onSubmit();
    }
  };

  return (
    <>
      <StyledAvatar src={user?.files[0]?.url} size="3.125rem" mb=".875rem" />
      <TextFieldWrapper>
        <CustomTextField
          borderColorOnHover={colorPalette.purple}
          error={false}
          fillColor={colorPalette.lightLavender}
          fullWidth
          helperText=""
          onChange={onChange}
          onKeyDown={handleKeyDown}
          placeholder={disabled ? '' : placeholderText}
          type={INPUT_TYPES.TEXT}
          value={value}
        />
      </TextFieldWrapper>
      <ButtonWrapper>
        <ContainedIconButton
          color={colorPalette.purple}
          height="2.875rem"
          Icon={Telegram}
          iconSX={{
            color: colorPalette.white,
            height: '1.625rem',
            width: '1.625rem'
          }}
          onClick={onSubmit}
          width="3.125rem"
        />
      </ButtonWrapper>
    </>
  );
};
