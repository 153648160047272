import {
  Box,
  IconButton,
  Popover,
  Radio,
  RadioGroup,
  Stepper,
  styled,
  Typography
} from '@mui/material';
import StepLabel, { stepLabelClasses } from '@mui/material/StepLabel';
import { colorPalette } from 'theme';

export const MainBox = styled(Box)<{ isMediumScreen?: boolean }>(
  ({ isMediumScreen }) => ({
    alignItems: 'center',
    backgroundColor: colorPalette.white,
    boxShadow: '0rem 0rem .6875rem rgba(0,0,0,0.2)',
    display: 'flex',
    flexDirection: 'row',
    height: isMediumScreen ? '4.0625rem' : '4.875rem',
    justifyContent: 'space-between',
    overflow: 'hidden',
    padding: '1.5625rem 4.375rem 1.5625rem 4.375rem',
    position: 'relative',
    width: 'auto'
  })
);

export const StyledStepLabel = styled(StepLabel)(() => ({
  [`& .${stepLabelClasses.label}`]: {
    [`&.${stepLabelClasses.completed}`]: {
      color: colorPalette.purple,
      fontWeight: '600'
    },
    [`&.${stepLabelClasses.active}`]: {
      color: colorPalette.purple,
      fontWeight: '600'
    },
    color: colorPalette.grey,
    fontWeight: '600',
    marginTop: '.375rem !important'
  }
}));

export const StyledPopover = styled(Popover)({
  ' & .MuiPopover-paper': {
    borderRadius: '.5rem',
    marginTop: '.4375rem',
    paddingTop: '2rem'
  }
});

export const ConceptTitleText = styled(Typography)({
  color: colorPalette.purple,
  display: '-webkit-box',
  fontSize: '.875rem',
  fontWeight: 600,
  lineHeight: '1.2',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: '2',
  '@media (max-width:900)': {
    WebkitLineClamp: '3'
  }
});

export const StyledButton = styled(IconButton)(() => ({
  backgroundColor: `${colorPalette.purple}10`,
  borderRadius: '.3125rem',
  color: colorPalette.purple,
  height: '2.1875rem',
  width: '2.1875rem',
  '&:hover': {
    color: colorPalette.white,
    backgroundColor: colorPalette.purple
  }
}));

export const StyledStepper = styled(Stepper)({
  width: '20%'
});

export const ConceptBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  gap: '.625rem',
  justifyContent: 'left',
  width: '48%'
});

export const ConceptWrapper = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  gap: '.75rem',
  justifyContent: 'left'
});

export const IconBox = styled(Box)({
  borderRadius: '.3125rem',
  height: '2.8125rem',
  width: '2.8125rem'
});

export const PopupContent = styled(Box)({
  height: 'calc(100vh - 12rem)',
  padding: '0rem 1.25rem .3125rem 1.25rem',
  width: '35vw'
});

export const StyledRadioGroup = styled(RadioGroup)({
  display: 'flex',
  flexDirection: 'row'
});

export const StyledRadio = styled(Radio)({
  '& .MuiSvgIcon-root': {
    fontSize: '1rem'
  }
});
