import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Actions from 'redux-state/actions';
import { GetUser } from 'redux-state/selectors';
import { NotificationBody } from './NotificationBody';
import { NotificationHeader } from './NotificationHeader';
import { PopoverContent, StyledPopover } from './styledComponents';

interface NotificationsPopupProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setUnreadCount?: React.Dispatch<React.SetStateAction<number>>;
}

export const NotificationsPopup = ({
  open,
  setOpen,
  setUnreadCount
}: NotificationsPopupProps) => {
  const dispatch = useDispatch();
  const user = GetUser();

  const handleClose = useCallback(() => {
    setOpen(false);
    if (user?._id ?? user?.id) {
      dispatch(Actions.markAllAsRead(user?._id ?? user?.id));
    }
  }, [dispatch, setOpen, user]);

  return (
    <StyledPopover
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      sx={{
        position: 'fixed',
        top: '85px',
        right: '16px'
      }}
    >
      <PopoverContent>
        <NotificationHeader
          setUnreadCount={setUnreadCount && setUnreadCount}
          handleClose={handleClose}
        />
        <NotificationBody setUnreadCount={setUnreadCount && setUnreadCount} />
      </PopoverContent>
    </StyledPopover>
  );
};
