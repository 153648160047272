import React from 'react';
import { SvgIconProps, SvgIcon } from '@mui/material';
import { FunctionComponent } from 'react';

export const TreeIconWithPlus: FunctionComponent<SvgIconProps> = ({
  ...props
}) => (
  <SvgIcon {...props} viewBox="0 0 20.418 17.931" fontSize="small">
    <g data-name="Group 18986">
      <path
        d="M8.098 6.5V.342A.347.347 0 0 0 7.756 0H4.333a.347.347 0 0 0-.342.342V6.5a.347.347 0 0 0 .342.342h3.423a.347.347 0 0 0 .342-.342Zm12.32-2.738V.342A.347.347 0 0 0 20.076 0h-3.422a.347.347 0 0 0-.342.342v3.423a.347.347 0 0 0 .342.342h3.422a.347.347 0 0 0 .342-.342Zm0 6.16V6.5a.347.347 0 0 0-.342-.342h-3.422a.347.347 0 0 0-.342.342v3.425a.347.347 0 0 0 .342.342h3.422a.347.347 0 0 0 .342-.342Zm0 6.16v-3.419a.347.347 0 0 0-.342-.342h-3.422a.347.347 0 0 0-.342.342v3.422a.347.347 0 0 0 .342.342h3.422a.347.347 0 0 0 .342-.342ZM15.627 1.54a.165.165 0 0 0-.171-.171h-.513a6.523 6.523 0 0 1-2.482-.642A7.841 7.841 0 0 0 9.467 0h-.514a.165.165 0 0 0-.171.171V1.2a.165.165 0 0 0 .171.171h.513a6.523 6.523 0 0 1 2.481.642 7.841 7.841 0 0 0 2.995.727h.513a.165.165 0 0 0 .171-.171Zm0 6.16a.165.165 0 0 0-.171-.171h-.513c-.67 0-1.469-1.127-2.182-2.11-.941-1.312-1.911-2.681-3.294-2.681h-.514a.165.165 0 0 0-.171.171v1.027a.165.165 0 0 0 .171.171h.513c.67 0 1.469 1.127 2.182 2.11.943 1.312 1.911 2.683 3.294 2.683h.513a.165.165 0 0 0 .171-.171Zm0 6.16a.165.165 0 0 0-.171-.171h-.513c-.6 0-1.811-2.424-2.467-3.722-1.155-2.295-2.154-4.306-3.508-4.477a.168.168 0 0 0-.185.171V6.7a.185.185 0 0 0 .114.171c.627.242 1.74 2.481 2.353 3.708 1.2 2.41 2.239 4.478 3.693 4.478h.513a.165.165 0 0 0 .171-.171V13.86Z"
        data-name="Path 423"
      />
      <g data-name="Group 269">
        <g
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.8"
          data-name="Icon feather-plus"
        >
          <path d="M3.991 10.849v6.182" data-name="Path 303" />
          <path d="M.9 13.94h6.182" data-name="Path 304" />
        </g>
      </g>
    </g>
  </SvgIcon>
);
