import React, { useCallback, useContext, useState, useEffect } from 'react';
import { ModalDataContext } from 'contexts/ModalDataContext';
import { DataContext } from 'contexts/DataContext';
import { Problem } from 'components/CardProblem';
import { Solution } from 'components/CardSolution';
import { Tag } from 'components/CardTag';
import { PsSelect, PsOptionType } from 'components/common/PsSelect';
import { PsSelectedList } from 'components/common/PsSelectedList';
import { PsFormControl, ShortEvent } from 'components/common/PsFormControl';
import { removeDuplicates } from '../../../helpers';
import styles from 'modals/ModalWindow.module.scss';
import { getValueObj } from 'helpers/common';

const SolutionTagsStep: React.FC = () => {
  const { values, updateField } = useContext(ModalDataContext);
  const { dataProvider } = useContext(DataContext);
  const [tags, setTags] = useState<Array<PsOptionType>>([]);
  const [aiTags, setAiTags] = useState<Array<PsOptionType>>([]);
  const [tagsValue, setTagsValue] = useState<string>(
    values.tagsSolution.join(',')
  );
  const [selectedTags, setSelectedTags] = useState<Array<string>>([]);
  const { titleSolution } = values;
  const finalTitleSolution = titleSolution.slice(4);

  useEffect(() => {
    let tagsList: PsOptionType[] | undefined;
    let aiTagsList: PsOptionType[] | undefined;

    if (!values.tagsSolution.length && values.parentProblem) {
      dataProvider
        .getOne<Problem>('problems', { id: values.parentProblem })
        .then(({ data }) => {
          let tags = data.tags || [];
          tags = removeDuplicates(tags);
          setTagsValue(tags.join(','));
          updateField('tagsSolution', tags);
        })
        .catch((err) => {
          console.error(err);
        });
    }

    if (!values.tagsSolution.length && values.parentSolution) {
      dataProvider
        .getOne<Solution>('solutions', { id: values.parentSolution })
        .then(({ data }) => {
          let tags = data.tags || [];
          tags = removeDuplicates(tags);
          setTagsValue(tags.join(','));
          updateField('tagsSolution', tags);
        })
        .catch((err) => {
          console.error(err);
        });
    }

    dataProvider
      .getList<Tag>('tags', {
        pagination: { page: 1, perPage: 50000 },
        sort: { field: 'name', order: 'ASC' },
        filter: { $custom: { type: 'baseTags' } }
      })
      .then(({ data = [] }) => {
        const list: Array<PsOptionType> = data.map((item: Tag) => ({
          value: `${item.id}`,
          label: item.name || '(empty)'
        }));
        if (aiTagsList) {
          setTags([...aiTagsList, ...list]);
        } else {
          setTags(list);
        }
        tagsList = list;
      })
      .catch(() => {
        setTags([]);
      });

    dataProvider
      .tagsSearch(values.titleSolution)
      .then((data) => {
        const nameTags = data.tags.map((tag) => {
          return {
            value: `name_${tag}`,
            label: `${tag}`
          };
        });
        aiTagsList = nameTags;
        if (tagsList) {
          setAiTags(nameTags);
          setTags([...nameTags, ...tagsList]);
        }
      })
      .catch(() => {
        //
      });
  }, []);

  useEffect(() => {
    let newTagsValue = (tagsValue || '').split(',');
    aiTags.forEach(({ value }) => {
      if (newTagsValue.indexOf(value) === -1) {
        newTagsValue.push(value);
      }
    });
    newTagsValue = newTagsValue.filter((item) => item);
    setTagsValue(newTagsValue.join(','));
    setSelectedTags(newTagsValue);
    updateField('tagsSolution', newTagsValue);
  }, [aiTags]);

  const onTagsChange = useCallback(
    (event: ShortEvent) => {
      const { value } = event.target;
      setTagsValue(value);
      const newSelectedTags: Array<string> = value ? value.split(',') : [];
      setSelectedTags(newSelectedTags);
      if (value) {
        updateField('tagsSolution', value.split(','));
      } else {
        updateField('tagsSolution', []);
      }
    },
    [setTagsValue]
  );

  return (
    <div className={styles.stepWrapper}>
      <PsFormControl
        name="tagsSolution"
        placeholder="Choose one or more tags"
        label={
          <>
            Choose Tags for a <span> </span>
            <b>{finalTitleSolution}</b>
          </>
        }
      >
        <PsSelect
          isMulti
          maxMenuHeight={270}
          onChange={onTagsChange}
          options={tags}
          value={tagsValue}
          valueObj={getValueObj(tags, selectedTags)}
        />
      </PsFormControl>
      <PsSelectedList
        options={tags}
        value={tagsValue}
        onChange={onTagsChange}
      />
    </div>
  );
};

export default SolutionTagsStep;
