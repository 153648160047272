/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { PsTheme, theme } from '../../theme';
import { PsLogo } from 'components/common/PsLogo';
import Popover from '@mui/material/Popover';
import { makeStyles } from 'tss-react/mui';

const InfoIcon = ({
  className,
  color = theme.palette.primary.main
}: {
  className?: string;
  color?: string;
}) => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M2 9C2 12.8667 5.13359 16 9 16C12.8667 16 16 12.8664 16 9C16 5.13333 12.8664 2 9 2C5.13333 2 2 5.13359 2 9ZM0 9C0 4.02944 4.02834 0 9 0C13.9706 0 18 4.02834 18 9C18 13.9706 13.9717 18 9 18C4.02944 18 0 13.9717 0 9ZM8 8.00293V12.9971C8 13.5621 8.44771 14 9 14C9.55614 14 10 13.551 10 12.9971V8.00293C10 7.43788 9.55229 7 9 7C8.44386 7 8 7.44903 8 8.00293ZM8 5C8 5.55614 8.44771 6 9 6C9.55614 6 10 5.55228 10 5C10 4.44386 9.55229 4 9 4C8.44386 4 8 4.44772 8 5Z"
        fill={color}
      />
    </svg>
  );
};

type ClassKey =
  | 'holder'
  | 'row'
  | 'main'
  | 'logo'
  | 'infoIcon'
  | 'popover'
  | 'root'
  | 'paper';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    holder: {},
    row: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      fontSize: 18,
      fontWeight: 600,
      marginBottom: 8
    },
    main: {
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'nowrap'
    },
    logo: {
      flexShrink: 0,
      marginRight: 10
    },
    infoIcon: {
      paddingTop: 5,
      cursor: 'pointer'
    },
    popover: {
      pointerEvents: 'none'
    },
    root: {},
    paper: {
      padding: '18px 18px 1px 18px',
      borderRadius: 2,
      border: '1px solid rgba(121, 114, 140, 0.22)',
      boxShadow:
        '0px 4px 8px rgba(10, 0, 32, 0.02), 0px 4px 84px rgba(7, 0, 21, 0.04)',
      '& > div': {
        marginBottom: 16
      }
    }
  };
});

const CoinRow = ({
  color = theme.palette.primary.main,
  count,
  children,
  name = 'IdeaCoins'
}: any): React.JSX.Element => {
  const { classes } = useStyles();
  return (
    <div className={classes.row}>
      <span className={classes.main}>
        <PsLogo className={classes.logo} size={30} color={color} small />
        <span className="text" style={{ color }}>
          <b>{typeof count === 'number' && count ? count.toFixed(2) : count}</b>{' '}
          {name}
        </span>
        {children ? <>&nbsp;</> : null}
      </span>
      {children ? children : null}
    </div>
  );
};

type CoinsProps = React.ComponentPropsWithoutRef<'div'> & {
  children?: React.ReactNode;
  color?: string;
  name?: string;
  count: number | string;
  className?: string;
  info?: Record<string, string | number>;
};

const CoinsView = ({
  className,
  color = theme.palette.primary.main,
  name = 'IdeaPoints',
  count,
  children,
  info,
  ...rest
}: CoinsProps) => {
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  let rootClassName = classes.holder;
  if (className) {
    rootClassName += ' ' + className;
  }

  return (
    <div className={rootClassName} {...rest}>
      <CoinRow color={color} count={count} name={name}>
        <>
          {children}
          {info ? (
            <span
              className={classes.infoIcon}
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
              <InfoIcon color={color} />
            </span>
          ) : null}
        </>
      </CoinRow>
      {info ? (
        <Popover
          id="coin-info"
          className={classes.popover}
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          classes={{ paper: classes.paper }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
        >
          {Object.keys(info).map((label) => (
            <CoinRow key={label} color={color} count={info[label]} name={name}>
              {label}
            </CoinRow>
          ))}
        </Popover>
      ) : null}
    </div>
  );
};

export const Coins = styled(CoinsView)({});

export default Coins;
