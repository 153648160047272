import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  convertIdeaCoinsToMatic,
  convertMaticToUsd,
  getBalanceByType
} from 'helpers/blockchain';
import { useDispatch } from 'react-redux';
import Actions from 'redux-state/actions';
import { GetRewardPoolThreshold } from 'redux-state/selectors';
import { ASSET_TYPES, Constants, ERRORS } from 'utilities/constants';
import {
  EquivalentBalance,
  MainBalanceText,
  StyledSkeleton
} from './styledComponents';

interface BalanceProps {
  address: string;
  refresh?: number;
  setWalletBalance?: Dispatch<SetStateAction<number>>;
  type: string;
}

const EquivalentCurrencies = {
  [ASSET_TYPES.MATIC]: Constants.USD,
  [ASSET_TYPES.IDEACOINS]: Constants.MATIC
};

const conversionFunctions = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [ASSET_TYPES.MATIC]: (balance, rewardPoolThreshold) =>
    convertMaticToUsd(balance),
  [ASSET_TYPES.IDEACOINS]: (balance, rewardPoolThreshold) =>
    convertIdeaCoinsToMatic(balance, rewardPoolThreshold)
};

export const Balance: React.FC<BalanceProps> = ({
  type,
  address,
  refresh,
  setWalletBalance
}) => {
  const dispatch = useDispatch();

  const [balance, setBalance] = useState<number>();
  const [equivalentBalance, setEquivalentBalance] = useState<number>();
  const [loading, setLoading] = useState<boolean>(true);
  const rewardPoolThreshold = GetRewardPoolThreshold();

  useEffect(() => {
    dispatch(Actions.getRewardPoolThreshold());
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);
    getBalanceByType(type, address)
      .then(async (balance) => {
        if (balance !== undefined) {
          setBalance(Number(balance));
          setWalletBalance(Number(balance));
          let equivalentAmount = await conversionFunctions[type](
            Number(balance),
            rewardPoolThreshold
          );
          equivalentAmount = parseFloat(equivalentAmount.toFixed(2));
          setEquivalentBalance(equivalentAmount);
        }
      })
      .catch((error) => {
        console.error(`${ERRORS.FETCH_BALANCE} ${type}:`, error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [type, address, rewardPoolThreshold, refresh, setWalletBalance]);

  return (
    <>
      {loading ? (
        <>
          <StyledSkeleton width="13.125rem" height="2.1875rem" />
          <StyledSkeleton width="9.0625rem" height="1.5rem" />
        </>
      ) : (
        <>
          <MainBalanceText>{`${balance.toFixed(4)} ${type}`}</MainBalanceText>
          <EquivalentBalance>{`${equivalentBalance} ${EquivalentCurrencies[type]}`}</EquivalentBalance>
        </>
      )}
    </>
  );
};
