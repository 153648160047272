const environments = {
  production: {
    NEXT_PUBLIC_API_URL: 'https://demoapi.mindminer.ai/api',
    NEXT_PUBLIC_CLIENT_URL: 'https://demo.mindminer.ai/',
    NEXT_PUBLIC_API_URL_IN: 'https://demoapi.mindminer.ai/api',
    NEXT_PUBLIC_ADMIN_URL: 'https://demoadmin.mindminer.ai/',
    PUBLIC_API_URL: 'https://demoapi.mindminer.ai'
  },
  development: {
    NEXT_PUBLIC_API_URL: 'http://localhost:8080/api',
    NEXT_PUBLIC_CLIENT_URL: 'http://localhost:3000/',
    NEXT_PUBLIC_API_URL_IN: 'http://localhost:8080/api',
    NEXT_PUBLIC_ADMIN_URL: 'http://localhost:3003/',
    PUBLIC_API_URL: 'http://localhost:8080'
  }
};
const currentEnv = process.env.NODE_ENV || 'development';
const envConfig = environments[currentEnv];

const Config = {
  ...envConfig,
  NEXT_PUBLIC_WS_URL: 'wss://',
  NEXT_PUBLIC_FB_APP_ID: '4569793806428291',
  NEXT_PUBLIC_HOTJAR_ID: '3183005',
  NEXT_PUBLIC_NFT_NETWORK: 'sepolia',
  NEXT_PUBLIC_WALLET_NETWORK: 'sepolia',
  USER_SECRET_KEY: '30eb7cd3dd9743a910e3abb019321cb3',
  NEXT_PUBLIC_WALLET_ADDRESS: '0x47b286980f5C0cD5E91bAc991864F82E3D16B890',
  NEXT_PUBLIC_DISCORD_GUILD_INVITE_CODE: 'qW8sFrTbp6',
  NEXT_CALENDLY_LINK:
    'https://api.leadconnectorhq.com/widget/booking/AOjgADNIQ9p1bwmWshGH',
  NEXT_PUBLIC_IS_CI: 'false',
  RPC_TEST_URL: 'https://ethereum-sepolia.blockpi.network/v1/rpc/public',
  RPC_TEST_CHAIN_ID: 11155111,
  RPC_URL: 'https://polygon-rpc.com',
  RPC_CHAIN_ID: 137,
  NFT_CONTRACT_ADDRESS: '0xf5351aEad6707239E1fb25133AFc78a5812E35B4',
  NFT_BASE_URL: 'https://testnets.opensea.io/assets',
  CLIENT_DOC_URL:
    'https://gateway.pinata.cloud/ipfs/QmSqDnEDS5gcBGoiPQcAHbJf1qVVVr95SLoZ7dwkcxahcF',
  NEXT_IP_ADDRESS_PROVIDER_URL: 'https://api.ipify.org/?format=json',
  PRIVATE_KEY:
    '0x7f879665dccba99768119d6479fa15ef113221ada942d001d6778619745f8075',
  INFURA_URL: 'https://sepolia.infura.io/v3/5ae311e7fe4d44d58488fd9c2bcef768',
  IDEACOIN_CONTRACT_ADDRESS: '0x87e52C4A4F3D89d0c50A73534a1b44B4E830584f',
  MAINNET_INFURA_URL:
    'https://polygon-mainnet.infura.io/v3/5ae311e7fe4d44d58488fd9c2bcef768',
  MATIC_CONTRACT_ADDRESS: '0x8B266D3605f79eaee96265E8b2Fb73f9d0C0d5a7',
  MORALIS_API_KEY:
    'EuwYtjWwWHGbnwsCnGauMtMMaEQZugtjaws2ybm2ZpSR15a8vzl6QUPkEUWHTOCU',
  MORALIS_API_URL: 'https://deep-index.moralis.io/api/v2',
  POLYGON_EXPLORER_URL: 'https://polygonscan.com/',
  SEPOLIA_BASE_URL: 'https://sepolia.etherscan.io',
  SUPPORT_EMAIL: 'jeff@mndmnr.com',
  SEPOLIA_MAINNET_NAME: 'Polygon Mainnet',
  SEPOLIA_TESTNET_NAME: 'Sepolia',
  COINBASE_API_URL: 'https://api.coinbase.com/v2/exchange-rates',
  CURRENCY: 'MATIC',
  PUSHER_DATA: {
    development: {
      PUSHER_CLUSTER: 'mt1',
      PUSHER_KEY: '4ef3165fff93d2731910'
    },
    production: {
      PUSHER_CLUSTER: 'mt1',
      PUSHER_KEY: '463cf7b5bc41df170537'
    }
  },
  COST_PER_CREDIT: 0.0006,
  API_COSTS: {
    COMPANY_GEN: 500,
    CREATE_APPLICATION: 20,
    CREATE_PROBLEM: 150,
    CREATE_PRODUCT: 20,
    CREATE_SOLUTION: 20,
    CREATE_TAGS: 2,
    EDIT_CONCEPT: 20,
    GEN_CONCEPT_IMAGE_AND_TITLE: 200,
    GEN_DESCR_FROM_AI: 2,
    GET_PDF_TEMPLATE: 0.55,
    GET_REL_PROMPT: 2,
    IMG_GEN_COST: 20,
    PROBLEM_GEN_MORE: 3.062,
    PRODUCT_GEN_MORE: 0.1,
    SEARCH_ANSWER: 2,
    SEARCH_TAGS: 2,
    SOLUTION_GEN_MORE: 3.062,
    TEXT_TO_VIDEO: 50
  }
};

export default Config;
