import React, { useEffect, useState } from 'react';
import type { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { Constants } from 'utilities/constants';
import NewIdeaMap from '../newIdeaMap';
import useRouter from '../../hooks/useRouter';
import { useFetchGraph } from '../../hooks/useFetchGraph';
import Actions from 'redux-state/actions';

const ProductDetails: FunctionComponent = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { key } = router.query;
  const [isDataCleared, setIsDataCleared] = useState<boolean>(false);

  // TODO: shift to component unmount
  useEffect(() => {
    if (key) {
      dispatch(Actions.setGraph({})); // Clear the graph data
      setIsDataCleared(true); // Mark data as cleared
    }
  }, [dispatch, key]);

  const { graphData, fetchChildrenGraph } = useFetchGraph({
    type: Constants.COMPANY_PRODUCTS,
    id: key as string,
    mapType: Constants.COMPANY_PRODUCT
  });

  return (
    isDataCleared && (
      <NewIdeaMap
        fetchChildrenGraph={fetchChildrenGraph}
        graphData={graphData}
        id={key as string}
        type={Constants.COMPANY_PRODUCTS}
      />
    )
  );
};

export default ProductDetails;
