import React, { useState, useContext, useEffect } from 'react';
import ModalWindow from 'modals/ModalWindow';
import { PsButton } from 'components/common/PsButton';
import { WalletField } from 'components/WalletField';
import { Profile } from 'components/CardProfile';
import { ModalContext } from 'contexts/ModalContext';
import { ModalDataContext } from 'contexts/ModalDataContext';
import { DataContext } from 'contexts/DataContext';
import { AuthContext } from 'contexts/AuthContext';
import styles from 'modals/ModalWindow.module.scss';
import { makeStyles } from 'tss-react/mui';

const addressRegExp = /^0x[a-fA-F0-9]{40}$/;

const useButtonClasses = makeStyles()(() => {
  return {
    root: {
      margin: '8px 0',
      fontWeight: 400
    }
  };
});

export const WalletSettings: React.FC = () => {
  const { user } = useContext(AuthContext);
  const { closeModal } = useContext(ModalContext);
  const { values, updateField, resetFields } = useContext(ModalDataContext);
  const { dataProvider } = useContext(DataContext);
  const [address, setAddress] = useState(`${values.productId}` || '');
  const [loading, setLoading] = useState(false);

  const buttonClasses = useButtonClasses();

  useEffect(() => {
    if (user) {
      setAddress(user.walletAddress);
    }
  }, [user, user && user.walletAddress]);

  const handleClickBack = () => {
    closeModal();
    resetFields();
  };

  const handleNextStep = async () => {
    if (!address || !addressRegExp.test(address)) {
      return;
    }

    if (!user) {
      return;
    }

    setLoading(true);

    dataProvider
      .update<Profile>('profiles', {
        id: user.id,
        data: { walletAddress: address }
      })
      .then(({ data }) => {
        if (data.error) {
          throw data.error;
        }
        user.walletAddress = address;
        updateField('productId', address);
        handleClickBack();
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onAddressChange = (newAddress: string) => {
    setAddress(newAddress);
  };

  return (
    <ModalWindow title="Your Crypto Wallet Settings">
      <div className={styles.stepWrapper}>
        <WalletField
          address={address}
          onAddress={onAddressChange}
        ></WalletField>
      </div>
      <div className={styles.actions}>
        <PsButton
          disabled={loading}
          onClick={handleNextStep}
          fullWidth
          classes={buttonClasses}
        >
          Save
        </PsButton>
        <PsButton
          onClick={handleClickBack}
          fullWidth
          cancel
          classes={buttonClasses}
          disabled={loading}
        >
          Cancel
        </PsButton>
      </div>
    </ModalWindow>
  );
};

export default WalletSettings;
