import React, { useState, useCallback, useContext, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js/pure';
import { styled, useTheme } from '@mui/material/styles';

import ModalWindow from 'modals/ModalWindow';
import { PsButton } from 'components/common/PsButton';
import Step from 'components/Step';
import Stepper from 'components/Stepper';
import { Tag } from 'components/CardTag';
import { ModalContext } from 'contexts/ModalContext';
import {
  ModalDataContext,
  CompanySubscriptionSteps
} from 'contexts/ModalDataContext';
import { DataContext } from 'contexts/DataContext';
import { AuthContext } from 'contexts/AuthContext';
import { SUBSCRIPTION_STEPS_CONFIG } from './config';
import styles from 'modals/ModalWindow.module.scss';
import { makeStyles } from 'tss-react/mui';
import useRouter from 'hooks/useRouter';

const steps: string[] = ['Select Company', 'Choose Type', 'Invite Colleagues'];

const useButtonClasses = makeStyles()(() => {
  return {
    root: {
      margin: '8px 0',
      fontWeight: 400
    }
  };
});

export const CompanySubscription: React.FC = () => {
  const { user } = useContext(AuthContext);
  const { openModal, closeModal } = useContext(ModalContext);
  const { values, setErrors, updateField } = useContext(ModalDataContext);
  const { dataProvider } = useContext(DataContext);
  const router = useRouter();
  const [prevSteps, setPrevSteps] = useState<string[]>([]);
  const [activeStepNumber, setActiveStepNumber] = useState<number>(0);
  let initStep = values.parentProblemOnly
    ? CompanySubscriptionSteps.CreateCompanyStep
    : CompanySubscriptionSteps.ChooseCompanyStep;
  if (values.parentSolutionOnly) {
    initStep = CompanySubscriptionSteps.SelectPlanStep;
  }
  const [currentStep, setCurrentStep] = useState<string | undefined>(initStep);

  const buttonClasses = useButtonClasses();

  const {
    Component,
    nextStep,
    validate,
    isNextStepBlocked,
    isFinalStep,
    nextButtonTitle,
    modalTitle,
    name
  } = SUBSCRIPTION_STEPS_CONFIG[currentStep || ''];

  const StepComponent = Component as React.FC;

  useEffect(() => {
    if (values.parentProblemOnly) {
      handleNextStep();
    }
  }, [values.parentProblemOnly]);

  const handleClickBack = useCallback(() => {
    if (activeStepNumber === 0) {
      closeModal();
      return;
    }
    setActiveStepNumber((prev) => Math.max(0, prev - 1));
    setCurrentStep(prevSteps[prevSteps.length - 1]);
    setPrevSteps(prevSteps.slice(0, prevSteps.length - 1));
  }, [prevSteps]);

  const handleNextStep = async () => {
    if (typeof validate === 'function') {
      const { success, errors } = validate(values);

      if (!success) {
        setErrors({ ...errors });

        return;
      }
    }

    if (
      values.parentProblem &&
      name === CompanySubscriptionSteps.ChooseCompanyStep
    ) {
      // skip create Tgg
      setPrevSteps([...prevSteps, currentStep || '']);
      setErrors({});
      setActiveStepNumber(2);
      if (typeof nextStep === 'function') {
        setCurrentStep(nextStep(values));
        return;
      }
      setCurrentStep(nextStep);
      return;
    }

    if (name === CompanySubscriptionSteps.CreateCompanyStep) {
      const tagData = {
        name: values.title,
        body: values.description,
        files: values.files,
        type: 'workplace'
      };

      dataProvider
        .create<Tag>('tags', { data: tagData })
        .then(({ data }) => {
          router.push(`/tags/${data.key || data.id}`);

          updateField('parentProblem', data.id);
          updateField('parentProblemLabel', data.name);

          setPrevSteps([...prevSteps, currentStep || '']);
          setErrors({});
          setActiveStepNumber((prev) => prev + 1);
          if (typeof nextStep === 'function') {
            setCurrentStep(nextStep(values));
            return;
          }

          setCurrentStep(nextStep);
        })
        .catch((err) => {
          console.error(err);
        });

      return;
    }

    if (isFinalStep) {
      const stripeKey = (user && user.userData?.STRIPE_PUB_KEY) || '';
      dataProvider
        .updateSubscription<{ sessionId: string; type: string }>(
          values.finalizeType || '',
          values.parentProblem
        )
        .then((data) => {
          if (data.sessionId) {
            return loadStripe(stripeKey).then((stripe) => {
              if (stripe) {
                return stripe.redirectToCheckout({
                  sessionId: data.sessionId
                });
              }
            });
          } else {
            location.reload();
          }
        })
        .catch((err) => {
          console.error(err);
        });
      return;
    }

    setPrevSteps([...prevSteps, currentStep || '']);
    setErrors({});
    setActiveStepNumber((prev) => prev + 1);
    if (typeof nextStep === 'function') {
      setCurrentStep(nextStep(values));
      return;
    }

    setCurrentStep(nextStep);
  };

  const isNextStepDisabled: boolean =
    typeof isNextStepBlocked === 'function' && isNextStepBlocked(values);

  const title = values.modalTitle || modalTitle || 'Improve Solution';

  let showStepper = false;
  let stepperStep = 0;
  if (activeStepNumber >= 2 && activeStepNumber < 3) {
    stepperStep = 1;
    showStepper = true;
  }
  if (activeStepNumber >= 3) {
    stepperStep = 2;
    showStepper = true;
  }

  if (name === CompanySubscriptionSteps.SelectPlanStep) {
    showStepper = true;
    stepperStep = 1;
  }

  return (
    <ModalWindow title={title}>
      {showStepper ? (
        <Stepper className={styles.stepper} activeStep={stepperStep}>
          {steps.map((step) => (
            <Step key={step}>{step}</Step>
          ))}
        </Stepper>
      ) : null}
      <StepComponent />
      <div className={styles.actions}>
        <PsButton
          disabled={isNextStepDisabled}
          onClick={handleNextStep}
          fullWidth
          classes={buttonClasses}
        >
          {nextButtonTitle || 'Next'}
        </PsButton>
        <PsButton
          onClick={handleClickBack}
          fullWidth
          cancel
          classes={buttonClasses}
        >
          Back
        </PsButton>
      </div>
    </ModalWindow>
  );
};

export default CompanySubscription;
