import { Box, styled } from '@mui/material';
import { colorPalette } from '../../theme';

export const StyledMainBox = styled(Box)({
  backgroundColor: colorPalette.white,
  height: 'auto',
  width: '100%'
});

export const StyledBox = styled(Box)({
  backgroundColor: colorPalette.white,
  height: 'auto',
  padding: '19px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column'
});

export const ButtonContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  padding: '15px 15px 0px 0px'
});
