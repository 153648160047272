import React, { useState, useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import ModalWindow from 'modals/ModalWindow';
import { PsButton } from 'components/common/PsButton';
import { PsInput } from 'components/common/PsInput';
import { ShortEvent, PsFormControl } from 'components/common/PsFormControl';
import { ModalContext } from 'contexts/ModalContext';
import { ModalDataContext } from 'contexts/ModalDataContext';
import { DataContext } from 'contexts/DataContext';
import { WalletContext, MarketItemDetailed } from 'contexts/WalletContext';
import styles from 'modals/ModalWindow.module.scss';
import { makeStyles } from 'tss-react/mui';

const useButtonClasses = makeStyles()(() => {
  return {
    root: {
      margin: '8px 0',
      fontWeight: 400
    }
  };
});

export const SellNft: React.FC = () => {
  const { closeModal } = useContext(ModalContext);
  const { values, resetFields } = useContext(ModalDataContext);
  const { refreshMyNftList } = useContext(DataContext);
  const { walletProvider, marketInfo, checkMagicConnect } =
    useContext(WalletContext);
  const [price, setPrice] = useState(`${values.title}` || '');
  const [priceError, setPriceError] = useState('');
  const [loading, setLoading] = useState(false);
  const buttonClasses = useButtonClasses();

  const handleClickBack = () => {
    closeModal();
    resetFields();
  };

  const handleNextStep = async () => {
    setPriceError('');
    if (!price) {
      setPriceError('Price is incorrect');
      return;
    }
    const priceNum = parseFloat(price);
    if (Number.isNaN(priceNum) || priceNum < 0) {
      setPriceError('Price is incorrect');
      return;
    }
    const item = values.application as MarketItemDetailed;
    if (!item) {
      setPriceError('No item found');
      return;
    }
    if (!marketInfo) {
      setPriceError('No market info found');
      return;
    }

    setLoading(true);

    checkMagicConnect()
      .then(() => {
        return walletProvider.sellNft(item, marketInfo, price);
      })
      .then(() => {
        refreshMyNftList();
        closeModal();
        resetFields();
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onPriceChange = (e: ShortEvent) => {
    setPrice(e.target.value);
    setPriceError('');
  };

  return (
    <ModalWindow title="Sell NFT">
      <div className={styles.stepWrapper}>
        <PsFormControl
          placeholder="Price"
          label="Price"
          helperText={priceError ? priceError : 'Price in ETH'}
          error={!!priceError}
        >
          <PsInput
            name="price"
            onChange={onPriceChange}
            type="number"
            value={price}
          />
        </PsFormControl>
      </div>
      <div className={styles.actions}>
        <PsButton
          disabled={loading}
          onClick={handleNextStep}
          fullWidth
          classes={buttonClasses}
          loading={loading}
        >
          Sell
        </PsButton>
        <PsButton
          onClick={handleClickBack}
          fullWidth
          cancel
          classes={buttonClasses}
          disabled={loading}
        >
          Cancel
        </PsButton>
      </div>
    </ModalWindow>
  );
};

export default SellNft;
