import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import Actions from 'redux-state/actions';
import { setLoginUser } from 'redux-state/onboarding/actions';
import { setConcept } from 'redux-state/ideamap/actions';
import { Close, Edit, Inventory, LinkOff, PushPin } from '@mui/icons-material';
import AuthContext from 'contexts/AuthContext';
import Config from 'config/config';
import ModalContext from 'contexts/ModalContext';
import { Constants } from 'utilities/constants';
import { Profile } from '../CardProfile';
import { ToolTip } from '../ToolTip';
const rotatedStyle = {
  transform: 'rotate(40deg)' // Rotate the icon by 180 degrees
};

interface GetActionsProps {
  item?: any;
  profile?: Profile;
  type: string;
  isFiled?: boolean;
  pagination?: {
    page: number;
    perPage: number;
  };
  setUserMenuOpened?: any;
  user?: Profile;
}

export const GetActions = ({
  item,
  profile,
  type,
  isFiled,
  pagination,
  setUserMenuOpened,
  user
}: GetActionsProps) => {
  const dispatch = useDispatch();
  const { openModal } = useContext(ModalContext);

  const { auth, setUser } = useContext(AuthContext);

  const onLoginMenuClick = (key, isUserMenuOpen) => {
    openModal(key);
    setUserMenuOpened(isUserMenuOpen);
  };

  const onRouteClick = (route, isUserMenuOpen) => {
    window.location.replace(route);
    setUserMenuOpened(isUserMenuOpen);
  };

  const onLogoutClick = () => {
    dispatch(setLoginUser(null));
    dispatch(setConcept({}));
    setUserMenuOpened(false);
    setUser(undefined);
    auth.logout();
  };

  const onSubscriptionClick = () => {
    dispatch(Actions.toggleSubscriptionModal(true));
  };

  const onTopUpClick = () => {
    dispatch(Actions.toggleCreditsTopUpModal(true));
  };

  if (type === Constants.USER_AGREEMENTS) {
    return [
      item?.status !== Constants.ACTIVE &&
        !item?.isLocked && {
          class: 'remove',
          component: (
            <ToolTip
              color="primary"
              Icon={<Close color="warning" />}
              title="Remove"
            />
          )
        }
    ];
  }

  let actions = [];
  if (type === 'Community') {
    actions = [
      {
        class: 'unlink',
        component: (
          <ToolTip
            color="primary"
            Icon={<LinkOff color="warning" />}
            title="unlink"
          />
        )
      }
    ];
  } else if (type === 'LoginMenu') {
    actions = [
      {
        label: Constants.MY_PROFILE,
        onClick: () => onRouteClick(`/profiles/${user?.key}`, false)
      },
      {
        label: Constants.MY_ACTIVITY,
        onClick: () => onRouteClick(`/activity/my`, false)
      },
      {
        label: Constants.SUBSCRIPTION,
        onClick: onSubscriptionClick
      },
      {
        label: Constants.CREDITS_TOP_UP,
        onClick: onTopUpClick
      },
      {
        label: Constants.ADMIN_PANEL,
        onClick: () =>
          onRouteClick(`${Config.NEXT_PUBLIC_ADMIN_URL}admin`, false)
      },
      {
        label: Constants.LOGOUT,
        onClick: onLogoutClick
      }
    ];
  } else {
    actions = [
      {
        class: 'pin',
        component: (
          <ToolTip
            color="disabled"
            Icon={
              <PushPin
                color={item.pinnedAt ? 'primary' : 'disabled'}
                style={rotatedStyle}
              />
            }
            title="Pin"
          />
        ),
        onClick: () =>
          dispatch(
            Actions.setPinItem(
              item._id,
              type,
              item.pinnedAt ? true : false,
              profile.id,
              isFiled,
              pagination
            )
          )
      },
      {
        class: 'Archive',
        component: (
          <ToolTip
            color="primary"
            Icon={<Inventory color="primary" />}
            title="Archive"
          />
        ),
        onClick: () =>
          dispatch(
            Actions.setArchiveItem(
              item._id,
              type,
              true,
              profile.id,
              isFiled,
              pagination
            )
          )
      },
      !isFiled &&
        !item.isFiled &&
        item.status !== Constants.ACTIVE && {
          class: 'edit',
          component: (
            <ToolTip
              color="primary"
              Icon={<Edit color="primary" />}
              title="Edit"
            />
          )
        },
      item.status !== Constants.ACTIVE &&
        !item?.isLocked && {
          class: 'remove',
          component: (
            <ToolTip
              color="primary"
              Icon={<Close color="warning" />}
              title="Remove"
            />
          )
        }
    ];
  }
  return actions;
};
