import React, { useEffect, useCallback, useRef } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { PsTheme } from '../../theme';
import { makeStyles } from 'tss-react/mui';

type ClassKey =
  | 'root'
  | 'inner'
  | 'space'
  | 'item'
  | 'itemActive'
  | 'content'
  | 'contentActive';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {
      position: 'relative',
      display: 'flex',
      marginBottom: 20,
      marginLeft: -10,
      marginRight: -10,
      paddingLeft: 10,
      paddingRight: 10,
      fontSize: 24,
      overflow: 'hidden',
      overflowX: 'auto'
    },
    inner: {
      display: 'flex',
      minWidth: '100%',
      borderBottom: '1px solid #B3BCC7'
    },
    space: {
      width: 10,
      opacity: 0
    },
    item: {
      margin: '7px 60px 0 0',
      padding: '0 0 20px 0',
      whiteSpace: 'nowrap',
      cursor: 'pointer',
      '&:hover': {
        color: psTheme.palette.primary.main
      },
      '&.small': {
        fontSize: 20
      }
    },
    itemActive: {
      cursor: 'default',
      color: psTheme.palette.primary.main,
      fontWeight: 600,
      borderBottom: `solid 3px ${psTheme.palette.primary.main}`
    },
    content: {
      // display: 'none',
    },
    contentActive: {
      // display: 'block',
    }
  };
});

type TabsProps = {
  children: Array<React.ReactNode>;
};

const TabsView = ({ children }: TabsProps) => {
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.inner}>
        {children}
        <div className={classes.space}>aaa</div>
      </div>
    </div>
  );
};

export const Tabs = styled(TabsView)({});

export default Tabs;

type TabProps = {
  label: string;
  value: string;
  title?: string;
  setValue: (value: string) => void;
  small?: boolean;
  scrollTo?: number;
};

const TabView = ({
  label,
  title,
  value,
  setValue,
  small,
  scrollTo
}: TabProps) => {
  const { classes } = useStyles();
  const divRef = useRef<HTMLDivElement>(null);
  let rootClassName = classes.item;
  if (small) {
    rootClassName += ' small';
  }
  if (value === label) {
    rootClassName += ' ' + classes.itemActive;
  }
  const onClick = useCallback(() => {
    setValue(label);
  }, [label, setValue]);

  useEffect(() => {
    if (!scrollTo || !divRef.current) {
      return;
    }
    const rect = divRef.current.getBoundingClientRect();
    const parent = divRef.current.parentNode!.parentNode as HTMLDivElement;
    const left = parent.scrollLeft + rect.left;
    parent.scrollTo({
      behavior: 'smooth',
      left: left
    });
    const top = window.scrollY + rect.top - 80;
    window.scrollTo({
      behavior: 'smooth',
      top: top
    });
  }, [scrollTo]);

  return (
    <div ref={divRef} className={rootClassName} onClick={onClick}>
      {title || label}
    </div>
  );
};

export const Tab = styled(TabView)({});

type TabContentProps = {
  children: React.ReactNode;
  label: string;
  value: string;
};

const TabContentView = ({ children, label, value }: TabContentProps) => {
  const { classes } = useStyles();
  const rootClassName = classes.content;
  if (value === label) {
    //rootClassName += ' ' + classes.contentActive;
    return <div className={rootClassName}>{children}</div>;
  }
  return null;
  // return <div className={rootClassName}>{children}</div>;
};

export const TabContent = styled(TabContentView)({});
