import { Box, Typography, styled } from '@mui/material';
import { colorPalette } from '../../theme';

export const StyledMainBox = styled(Box)({
  backgroundColor: colorPalette.white,
  height: 'auto',
  width: '100%'
});

export const StyledBox = styled(Box)({
  backgroundColor: colorPalette.white,
  height: 'auto',
  padding: '.9375rem',
  width: '100%'
});

export const DateText = styled(Typography)({
  color: colorPalette.purple,
  paddingTop: '0.250rem'
});
