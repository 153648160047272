import * as React from 'react';
const ProductIcon = (props) => (
  <svg
    width="31"
    height="31"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5 30.5C23.7843 30.5 30.5 23.7843 30.5 15.5C30.5 7.21573 23.7843 0.5 15.5 0.5C7.21573 0.5 0.5 7.21573 0.5 15.5C0.5 23.7843 7.21573 30.5 15.5 30.5Z"
      fill="#D6C7F8"
    />
    <path
      d="M19.2319 21.8705V20.3795C19.2321 19.9873 19.1549 19.599 19.0048 19.2366C18.8547 18.8743 18.6346 18.5451 18.3572 18.2678C18.0798 17.9906 17.7504 17.7708 17.388 17.6209C17.0256 17.4711 16.6371 17.3941 16.2449 17.3945H10.2789C9.88697 17.3945 9.49886 17.4717 9.13676 17.6218C8.77466 17.7718 8.44566 17.9917 8.16856 18.2689C7.89146 18.5461 7.67168 18.8751 7.52178 19.2373C7.37188 19.5994 7.29479 19.9876 7.29492 20.3795V21.8715"
      stroke="#5B38D5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.2633 15.0979C14.9113 15.0979 16.2473 13.7619 16.2473 12.1139C16.2473 10.4659 14.9113 9.12988 13.2633 9.12988C11.6153 9.12988 10.2793 10.4659 10.2793 12.1139C10.2793 13.7619 11.6153 15.0979 13.2633 15.0979Z"
      stroke="#5B38D5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.7087 21.871V20.38C23.7073 19.7197 23.4868 19.0785 23.0818 18.5569C22.6767 18.0354 22.1101 17.663 21.4707 17.498"
      stroke="#5B38D5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.4863 9.23145C19.1284 9.39562 19.6976 9.76887 20.1041 10.2923C20.5105 10.8158 20.7312 11.4597 20.7312 12.1224C20.7312 12.7852 20.5105 13.4291 20.1041 13.9525C19.6976 14.476 19.1284 14.8493 18.4863 15.0134"
      stroke="#5B38D5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ProductIcon;
