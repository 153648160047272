import React, { useCallback, useContext, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ControlPointRoundedIcon from '@mui/icons-material/ControlPointRounded';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Typography,
  styled
} from '@mui/material';
import Config from 'config/config';
import AuthContext from 'contexts/AuthContext';
import { Node } from 'interface/common';
import { useDispatch } from 'react-redux';
import Actions from 'redux-state/actions';
import { GetConceptData } from 'redux-state/ideamap/selectors';
import { GetUser } from 'redux-state/selectors';
import { colorPalette } from 'theme';
import { Constants } from 'utilities/constants';

const clientUrl = Config.NEXT_PUBLIC_CLIENT_URL;

interface AddToConceptModalProps {
  handleDrawer: () => void;
  isFeed?: boolean;
  node: Node;
  onClose: () => void;
  open: boolean;
  referralCode?: string | string[];
}

const AddToConceptModal: React.FC<AddToConceptModalProps> = ({
  handleDrawer,
  isFeed,
  node,
  onClose,
  open,
  referralCode
}) => {
  const user = GetUser();
  const [isAddButtonEnabled, setIsAddButtonEnabled] = useState<boolean>(false);
  const [payloadConceptData, setPayloadConceptData] = useState<any>(null);
  const [payloadId, setPayloadId] = useState<string>('');
  const [selectedOption, setSelectedOption] = useState('');
  const [alertEnabled, setAlertEnabled] = useState<boolean>(false);
  const [problemTitle, setProblemTitle] = useState<string>('');
  const activeConcept = user?.activeConcept || {};
  const conceptData = GetConceptData();
  const dispatch = useDispatch();
  const nodeId = node.id ? node.id : node._id;

  const findConcept = useCallback(
    (id: string) => {
      return conceptData?.data?.find((concept) => concept.id === id);
    },
    [conceptData?.data]
  );

  const handleConceptChange = useCallback(
    (value: string) => {
      const concept = findConcept(value);
      const isSolutionInConcept = concept.selected.includes(nodeId);
      setPayloadId(value);
      const existingProblems = concept?.problems?.map((item) => item.id) || [];
      if (!isSolutionInConcept) {
        concept.selected.push(nodeId);
      }
      setPayloadConceptData({
        selected: concept.selected,
        problems: isSolutionInConcept
          ? [...existingProblems]
          : [...existingProblems, node.problem]
      });
    },
    [findConcept, nodeId, node]
  );

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      setIsAddButtonEnabled(true);
      handleConceptChange(event.target.value);
      setSelectedOption(Constants.SELECT_CONCEPT);
    },
    [handleConceptChange]
  );

  const handleAddToActiveConceptChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsAddButtonEnabled(true);
      const value = event.target.value;
      setSelectedOption(value);
      if (value === Constants.ADD_TO_ACTIVE_CONCEPT) {
        handleConceptChange(activeConcept?.id as string);
      } else {
        setPayloadId('');
        setPayloadConceptData(null);
      }
    },
    [activeConcept?.id, handleConceptChange]
  );

  const handleClose = useCallback(() => {
    setSelectedOption('');
    setIsAddButtonEnabled(false);
    onClose();
  }, [onClose]);

  const handleCloseFromCross = useCallback(() => {
    setSelectedOption('');
    setIsAddButtonEnabled(false);
    onClose();
  }, [onClose]);

  const handleAddButtonClick = useCallback(() => {
    if (alertEnabled) {
      alert(`Add only solutions of "${problemTitle}" Problem`);
      setAlertEnabled(false);
      setProblemTitle('');
      handleClose();
    } else {
      const concept = findConcept(payloadId);
      if (activeConcept && concept.id != activeConcept.id) {
        dispatch(Actions.setLoginUser({ ...user, activeConcept: concept }));
        dispatch(Actions.editProfile(user?.id, { activeConcept: concept.id }));
      }
      if (user) {
        dispatch(Actions.togLoadSolution(true));
        dispatch(
          Actions.updateConcept(payloadId, {
            ...payloadConceptData,
            ...(referralCode && { referralCode })
          })
        );
        dispatch(Actions.getAiTitle({ conceptId: activeConcept?.id }));
        dispatch(Actions.toggleConceptCart(true));
        setPayloadId('');
        setPayloadConceptData(null);
        if (isFeed) {
          window.open(
            `${clientUrl}${Constants.SOLUTIONS}/${node?.key}`,
            '_blank'
          );
        }
      } else {
        dispatch(Actions.openAuthModal(true));
      }
    }
    handleClose();
  }, [
    alertEnabled,
    dispatch,
    findConcept,
    handleClose,
    isFeed,
    node?.key,
    payloadConceptData,
    payloadId,
    problemTitle,
    referralCode,
    user
  ]);

  const handleRadioChange = (event) => {
    setIsAddButtonEnabled(
      event.target.value === Constants.SELECT_CONCEPT ? false : true
    );
    setSelectedOption(event.target.value);
  };

  const addNewConcept = useCallback(() => {
    if (user) {
      const appInfo = {
        title: '',
        selected: [nodeId],
        problems: [node.problem]
      };
      dispatch(Actions.createConcept(appInfo));
    } else {
      dispatch(Actions.openAuthModal(true));
    }
    handleClose();
  }, [user, handleClose, conceptData?.data, nodeId, node.problem, dispatch]);

  const handleEvent = (event) => {
    event.stopPropagation();
  };

  return (
    <>
      <MainDialog
        aria-labelledby="customized-dialog-title"
        onClick={handleEvent}
        onClose={handleCloseFromCross}
        open={open}
      >
        <MainDialogTitle id="customized-dialog-title">
          <ModalTitle>{Constants.ADD_TO_CONCEPT}</ModalTitle>
          <Description>{node.title}</Description>
        </MainDialogTitle>
        <StyledIconButton aria-label="close" onClick={handleCloseFromCross}>
          <CloseIcon />
        </StyledIconButton>
        <DialogContent dividers>
          <ModalContent>
            <Grid container spacing={2} p={1}>
              <Grid item md={6}>
                <RadioGroup
                  aria-label={Constants.OPTIONS}
                  name={Constants.OPTIONS}
                  onChange={handleRadioChange}
                  value={selectedOption}
                >
                  <FormControlLabel
                    value={Constants.ADD_TO_ACTIVE_CONCEPT}
                    control={<Radio />}
                    label={Constants.ADD_TO_ACTIVE_CONCEPT_TITLE}
                    disabled={
                      !(activeConcept && Object.keys(activeConcept).length)
                    }
                    onChange={handleAddToActiveConceptChange}
                  />
                  {!(activeConcept && Object.keys(activeConcept).length) ? (
                    <Typography component="div" variant="body1">
                      <Box sx={{ color: 'text.disabled' }}>
                        {Constants.NO_ACTIVE_CONCEPT_STATEMENT}
                      </Box>
                    </Typography>
                  ) : (
                    <Typography component="div" variant="body1">
                      <Box sx={{ color: 'text.primary', fontWeight: 'bold' }}>
                        {activeConcept && Object.keys(activeConcept).length
                          ? findConcept(activeConcept?.id as string)?.title
                          : ''}
                      </Box>
                    </Typography>
                  )}
                </RadioGroup>
                {}
              </Grid>
              <Grid item md={6}>
                <DropDown>
                  <RadioGroup
                    aria-label={Constants.OPTIONS}
                    name={Constants.OPTIONS}
                    onChange={handleRadioChange}
                    value={selectedOption}
                  >
                    <FormControlLabel
                      value={Constants.SELECT_CONCEPT}
                      control={<Radio />}
                      label={Constants.SELECT_CONCEPT_TITLE}
                    />
                    <FormControl
                      sx={{ m: 1, minWidth: '100%' }}
                      disabled={
                        selectedOption === Constants.SELECT_CONCEPT
                          ? false
                          : true
                      }
                    >
                      <InputLabel id="demo-simple-select-autowidth-label">
                        Select Concept
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        onChange={handleChange}
                        label={Constants.SELECT_CONCEPT_TITLE}
                      >
                        {conceptData?.data?.map((concept) =>
                          activeConcept &&
                          concept.id === activeConcept.id ? null : (
                            <MenuItem key={concept.id} value={concept.id}>
                              {concept.title}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </RadioGroup>
                </DropDown>
              </Grid>
            </Grid>
          </ModalContent>
        </DialogContent>
        <DialogActions>
          <Grid container p={2}>
            <Grid item md={6}>
              <AddNewConceptButton autoFocus onClick={addNewConcept}>
                <ControlPointRoundedIcon sx={{ marginRight: '5px' }} />{' '}
                {Constants.ADD_NEW_CONCEPT}
              </AddNewConceptButton>
            </Grid>
            <Grid item md={6}>
              <AddButtonContainer>
                <AddButton
                  isAddButtonEnabled={isAddButtonEnabled}
                  disabled={isAddButtonEnabled ? false : true}
                  onClick={handleAddButtonClick}
                >
                  {Constants.ADD}
                </AddButton>
              </AddButtonContainer>
            </Grid>
          </Grid>
        </DialogActions>
      </MainDialog>
    </>
  );
};

const MainDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  },
  '& .MuiPaper-root': {
    maxWidth: '46.875rem',
    width: '100%'
  }
}));

const MainDialogTitle = styled(DialogTitle)({
  padding: '25px'
});

const Description = styled(Box)({
  color: colorPalette.lightGreen,
  fontWeight: '600',
  lineHeight: '24px'
});

const ModalTitle = styled(Typography)({
  fontSize: '26px',
  fontWeight: 'bold',
  lineHeight: '1rem',
  marginBottom: '0.8rem'
});

const ModalContent = styled(Box)({
  display: 'flex',
  flexDirection: 'row'
});

const DropDown = styled(Box)({
  display: 'flex',
  flexDirection: 'column'
});

const AddButtonContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  marginRight: '10px'
});

const AddButton = styled(Button)<{ isAddButtonEnabled: boolean }>(
  ({ isAddButtonEnabled }) => ({
    padding: '15px 40px 15px 40px',
    background: isAddButtonEnabled
      ? colorPalette.darkPurple
      : colorPalette.explosiveGrey,
    color: colorPalette.white,
    fontWeight: 'bold',
    ':hover': {
      background: colorPalette.midnightPurple
    }
  })
);

const AddNewConceptButton = styled(Button)({
  color: colorPalette.darkGreen,
  fontWeight: 'bold',
  padding: '15px 15px 15px 15px',
  textTransform: 'none'
});

const StyledIconButton = styled(IconButton)({
  boxShadow: '0px 0px 6.2px 0px #00000040',
  color: colorPalette.darkPurple,
  position: 'absolute',
  right: 15,
  top: 10
});

export default AddToConceptModal;
