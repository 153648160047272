/* eslint-disable */
import React from 'react';
import Button from '@mui/material/Button';
import { VARIANT } from 'utilities/constants';
import {
  CenterText,
  EndText,
  StartText,
  StyledButton,
  StyledInlineBox
} from './styles';

interface CustomButtonProps extends React.ComponentProps<typeof Button> {
  goldenButton?: boolean;
  startIcon?: React.ReactNode;
  startText?: string;
  centerText?: string;
  endText?: string;
}

const CustomButton = ({
  goldenButton,
  startIcon,
  startText,
  centerText,
  endText,
  ...props
}: CustomButtonProps) => {
  return (
    <StyledButton
      sx={{ alignSelf: 'center' }}
      variant={VARIANT.CONTAINED}
      goldenButton={goldenButton}
      fullWidth
      {...props}
    >
      <StyledInlineBox>
        {startIcon}
        {startText && <StartText>{startText}</StartText>}
      </StyledInlineBox>
      <StyledInlineBox>
        {centerText && <CenterText>{centerText}</CenterText>}
        {endText && <EndText>{endText}</EndText>}
      </StyledInlineBox>
    </StyledButton>
  );
};

export { CustomButton };
