import { Avatar, Box, Button, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PsLogo from 'components/common/PsLogo';
import { colorPalette } from 'theme';

export const StyledMainContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  width: '75%'
});

export const StyledImage = styled(Avatar)({
  height: '4.375rem',
  width: '4.9375rem'
});

export const StyledDescriptionContainer = styled(Box)({
  alignItems: 'end',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%'
});

export const ContestCardStyled = styled(Box)(({ theme }) => ({
  background: colorPalette.white,
  border: `1px solid ${colorPalette.parrotGreen}`,
  borderRadius: 7,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  overflow: 'hidden',
  padding: '.625rem .625rem',
  height: '100%',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  }
}));

export const ContentBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  width: '100%'
});

export const TextContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '1.375rem',
  width: '100%'
});

export const InfoContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%'
});

export const StyledLink = styled(Link)({
  color: colorPalette.greenHaze,
  textDecorationColor: colorPalette.greenHaze,
  fontSize: '.75rem'
});

export const StyledCardButton = styled(Button)({
  color: colorPalette.white,
  backgroundColor: colorPalette.greenHaze,
  padding: '0.31935rem 0.810rem',
  lineHeight: '1.8',
  fontSize: '11px',
  fontWeight: 700,
  whiteSpace: 'nowrap',
  ':hover': {
    color: colorPalette.white,
    backgroundColor: colorPalette.green
  }
});

export const StyledCardButtonOutside = styled(Button)({
  backgroundColor: colorPalette.greenHaze,
  color: colorPalette.white,
  fontSize: '0.9rem',
  lineHeight: '1',
  padding: '1rem',
  ':hover': {
    backgroundColor: colorPalette.green,
    color: colorPalette.white
  }
});

export const StyledButton = styled(Button)({
  backgroundColor: '#07a854',
  height: '1.875rem'
});

export const StyledContestCardTitle = styled(Typography)({
  lineHeight: '1.2',
  fontSize: '.875rem',
  fontWeight: 700,
  width: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical'
});

export const StyledContestCardLinkTypography = styled(Typography)({
  marginTop: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  '@media (max-width:1400px)': {
    fontSize: 10
  }
});

export const StyledContestCardDateTypography = styled(Typography)({
  margin: '0rem',
  fontSize: 10.5,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
  whiteSpace: 'pre-wrap'
});

export const StyledContestCardDescriptionTypography = styled(Typography)({
  alignSelf: 'center',
  fontSize: 10.5,
  lineHeight: '1.2',
  marginBottom: '0',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical'
});

export const StyledEarnTypography = styled(Typography)({
  fontSize: 11,
  fontWeight: 700,
  whiteSpace: 'nowrap'
});

export const StyledLogo = styled(PsLogo)({
  marginLeft: 12
});

export const StyledButtonContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '35%',
  justifyContent: 'space-between'
});

export const ReactionButtons = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '.625rem 1.125rem',
  borderRadius: '1.875rem',
  minHeight: '2.5rem',
  minWidth: '6.25rem',
  transform: 'scale(0.65)',
  backgroundColor: '#f0eef4'
});

export const VotingBox = styled(Box)({
  marginBottom: '-0.9688rem'
});

export const StyledBox = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  marginRight: '-1.375rem',
  alignItems: 'center'
});

export const StyledLikes = styled(Typography)({
  margin: '0rem .625rem 0rem .625rem',
  fontSize: '1rem',
  fontWeight: '500',
  fontFamily: 'montserrat',
  color: 'black'
});
