import React, { createContext, useCallback, useEffect, useState } from 'react';
import { OptionsObject, useSnackbar } from 'notistack';
import { TagInfo } from 'components/CardTag';
import { OneOfEntity } from 'components/ShareBtn/OneOfEntity';
import dataProvider, {
  ActivitySeen,
  CreditsPrice,
  DataProvider,
  Identifier,
  PayOption,
  PayProduct,
  PsOptionType,
  PsRecord,
  SubscriptionOption
} from '../dataPrvider';
import { rewardResource, rewardType } from '../helpers';
import { Concept } from 'components/CardProduct';
import { EntityType } from '../components/TreeGraph/types';

export { DataProvider };

export type {
  PsRecord,
  PsOptionType,
  PayOption,
  PayProduct,
  SubscriptionOption,
  ActivitySeen,
  CreditsPrice
};

type SharePopupInfo = OneOfEntity & { tagInfo?: Array<TagInfo> };

export const getRewardText = (
  resource: string,
  type: string,
  numStr: string
): string => {
  const prefix = `You earned ${numStr} IdeaPoints for`;

  if (type.includes(rewardType.APP_PAY)) {
    if (resource === rewardResource.SOLUTION) {
      return `${prefix} submitting a new solution`;
    }
    return `${prefix} purchasing an Idea NFT`;
  } else if (type.includes(rewardType.APP_IMPROVE)) {
    return `${prefix} improving an application`;
  } else if (type.includes(rewardType.SOLUTION_IMPROVE)) {
    return `${prefix} improving a solution`;
  } else if (type.includes(rewardType.SOLUTION_CREATE)) {
    return `${prefix} creating a solution`;
  } else if (type.includes(rewardType.NFT_DEPLOY)) {
    return `${prefix} deploying  a NFT`;
  } else if (type.includes(rewardType.NFT_DEPLOY_MM)) {
    return `${prefix} deploying a NFT`;
  } else if (type.includes(rewardType.CONTEST_WIN)) {
    return `${prefix} winning a contest`;
  } else if (type.includes(rewardType.CHALLENGE_WIN)) {
    return `${prefix} winning a challenge`;
  } else if (type.includes(rewardType.CONTEST_OWNER_WIN)) {
    return `${prefix} winning contest`;
  } else if (type.includes(rewardType.SOLUTION_VOTE)) {
    return `${prefix} voting for a solution`;
  }
  // share
  let ent = '';
  switch (resource) {
    case rewardResource.PROBLEM: {
      ent = 'a problem';
      break;
    }
    case rewardResource.SOLUTION: {
      ent = 'a solution';
      break;
    }
    case rewardResource.APPLICATION: {
      ent = 'an invention';
      break;
    }
    case rewardResource.CONTEST: {
      ent = 'a contest';
      break;
    }
    case rewardResource.TAG: {
      ent = 'a tag';
      break;
    }
    case rewardResource.PROFILE: {
      ent = 'a profile';
      break;
    }
    default: {
      ent = '';
      break;
    }
  }
  return `${prefix} sharing ${ent}`;
};

export type DataContextType = {
  dataProvider: DataProvider;

  problemsListCache: number;
  refreshProblemsList: () => void;

  solutionsListCache: number;
  refreshSolutionsList: () => void;

  applicationsListCache: number;
  refreshApplicationsList: () => void;

  problemDetailCache: number;
  refreshProblemDetail: () => void;

  solutionDetailCache: number;
  refreshSolutionDetail: () => void;
  solutionDetailEditOpened: boolean;
  setSolutionDetailEditOpened: (val: boolean) => void;

  applicationDetailCache: number;
  refreshApplicationDetail: () => void;

  relatedProblemsListCache: number;
  refreshRelatedProblemsList: () => void;

  relatedSolutionsListCache: number;
  refreshRelatedSolutionsList: () => void;

  relatedApplicationsListCache: number;
  refreshRelatedApplicationsList: () => void;

  relatedProductsListCache: number;
  refreshRelatedProductsList: () => void;

  relatedContestsListCache: number;
  refreshRelatedContestsList: () => void;

  contestDetailCache: number;
  refreshContestDetail: () => void;

  challengeDetailCache: number;
  refreshChallengeDetail: () => void;

  contestsListCache: number;
  refreshContestsList: () => void;

  challengeListCache: number;
  refreshChallengeList: () => void;

  myNftListCache: number;
  refreshMyNftList: () => void;

  profileStatsCache: number;
  refreshProfileStats: () => void;

  filterTags: Array<string>;
  filterTagsValue: Array<string>;
  updateFilterTags: (tags: Array<string>, tagsValue: Array<string>) => void;

  alertContent: React.ReactNode;
  setAlertContent: (content: React.ReactNode) => void;

  isDraw: boolean;
  showDraw: () => void;
  hideDraw: () => void;

  feedCache: number;
  unseen: Array<ActivitySeen>;
  updateUnseen: (id: string | number, seen: boolean) => void;

  firstRewardCache: any;
  firstProblemCache: any;
  firstSolutionCache: any;
  firstInventionCache: any;

  sharePopupInfo: SharePopupInfo | undefined;
  showSharePopup: (options: SharePopupInfo | undefined) => void;

  showToast: (message: string, opts?: OptionsObject) => void;
  hideToast: () => void;

  view: string;
  handleChangeView: (view: string) => void;

  selectedConceptId: Concept['id'] | undefined;

  setSelectedConceptId: (arg: Concept['id'] | undefined) => void;
  concepts: Concept[];
  getUserConcepts: (
    arg?: Identifier
  ) => Promise<void | { data: Concept[] }> | undefined;
  openedGraphCard: string;
  setOpenedGraphCard: (id: string) => void;

  entityFilters: EntityType[];
  setEntityFilters: (arg: EntityType[]) => void;
};

const defaultContext: DataContextType = {
  dataProvider,
  problemsListCache: 0,
  refreshProblemsList: () => ({}),
  solutionsListCache: 0,
  refreshSolutionsList: () => ({}),
  applicationsListCache: 0,
  refreshApplicationsList: () => ({}),

  problemDetailCache: 0,
  refreshProblemDetail: () => ({}),

  solutionDetailCache: 0,
  refreshSolutionDetail: () => ({}),
  solutionDetailEditOpened: false,
  setSolutionDetailEditOpened: (val: boolean) => ({}),

  applicationDetailCache: 0,
  refreshApplicationDetail: () => ({}),

  relatedProblemsListCache: 0,
  refreshRelatedProblemsList: () => ({}),

  relatedSolutionsListCache: 0,
  refreshRelatedSolutionsList: () => ({}),

  relatedApplicationsListCache: 0,
  refreshRelatedApplicationsList: () => ({}),

  relatedProductsListCache: 0,
  refreshRelatedProductsList: () => ({}),

  relatedContestsListCache: 0,
  refreshRelatedContestsList: () => ({}),

  contestDetailCache: 0,
  refreshContestDetail: () => ({}),

  challengeDetailCache: 0,
  refreshChallengeDetail: () => ({}),

  contestsListCache: 0,
  refreshContestsList: () => ({}),

  challengeListCache: 0,
  refreshChallengeList: () => ({}),

  myNftListCache: 0,
  refreshMyNftList: () => ({}),

  profileStatsCache: 0,
  refreshProfileStats: () => ({}),

  filterTags: [],
  filterTagsValue: [],
  updateFilterTags: (tags: Array<string>, tagsValue: Array<string>) => ({}),

  alertContent: null,
  setAlertContent: () => ({}),

  isDraw: false,
  showDraw: () => false,
  hideDraw: () => false,

  feedCache: 0,
  unseen: [],
  updateUnseen: (id: string | number, seen: boolean) => ({}),

  firstRewardCache: undefined,
  firstProblemCache: undefined,
  firstSolutionCache: undefined,
  firstInventionCache: undefined,

  sharePopupInfo: undefined,
  showSharePopup: (options: SharePopupInfo | undefined) => ({}),

  showToast: () => ({}),
  hideToast: () => ({}),

  view: 'observer',
  handleChangeView: () => ({}),

  selectedConceptId: undefined,
  setSelectedConceptId: () => ({}),

  concepts: [],
  getUserConcepts: () => undefined,

  openedGraphCard: '',
  setOpenedGraphCard: () => undefined,

  entityFilters: [],
  setEntityFilters: () => undefined
};

export const DataContext = createContext<DataContextType>(defaultContext);

type Props = {
  children: React.ReactElement;
  dataProvider: DataProvider;
};

export const DataContextProvider = ({ children, dataProvider }: Props) => {
  const { enqueueSnackbar: showToast, closeSnackbar: hideToast } =
    useSnackbar();

  if (typeof window !== 'undefined') {
    // showToast('aaaaa sd sdvs dvs vsd vs dv sdvss dvsdv sdvsdvsv svs dv sd vsdvs vsvsv sdvvvsd', { variant: 'success' })
    // @ts-ignore
    window._showToast = showToast;
    // @ts-ignore
    window._hideToast = hideToast;
  }

  const [problemsListCache, setProblemsListCache] = useState(0);
  const [solutionsListCache, setSolutionsListCache] = useState(0);
  const [applicationsListCache, setApplicationsListCache] = useState(0);
  const [problemDetailCache, setProblemDetailCache] = useState(0);
  const [solutionDetailCache, setSolutionDetailCache] = useState(0);
  const [solutionDetailEditOpened, setSolutionDetailEditOpened] =
    useState(false);
  const [applicationDetailCache, setApplicationDetailCache] = useState(0);
  const [relatedProblemsListCache, setRelatedProblemsListCache] = useState(0);
  const [relatedSolutionsListCache, setRelatedSolutionsListCache] = useState(0);
  const [relatedApplicationsListCache, setRelatedApplicationsListCache] =
    useState(0);
  const [relatedProductsListCache, setRelatedProductsListCache] = useState(0);
  const [relatedContestsListCache, setRelatedContestsListCache] = useState(0);
  const [contestDetailCache, setContestDetailCache] = useState(0);
  const [challengeDetailCache, setChallengeDetailCache] = useState(0);
  const [contestsListCache, setContestsListCache] = useState(0);
  const [challengeListCache, setChallengeListCache] = useState(0);
  const [myNftListCache, setMyNftListCache] = useState(0);
  const [profileStatsCache, setProfileStatsCache] = useState(0);
  const [filterTags, setFilterTags] = useState<Array<string>>([]);
  const [filterTagsValue, setFilterTagsValue] = useState<Array<string>>([]);
  const [alertContent, setAlertContent] = useState<React.ReactNode>(null);
  const [isDraw, setIsDraw] = useState(false);
  const [unseen, setUnseen] = useState<Array<ActivitySeen>>([]);
  const [feedCache, setFeedCache] = useState(0);
  const [firstRewardCache, setFirstRewardCache] = useState(undefined);
  const [firstProblemCache, setFirstProblemCache] = useState(undefined);
  const [firstSolutionCache, setFirstSolutionCache] = useState(undefined);
  const [firstInventionCache, setFirstInventionCache] = useState(undefined);
  const [view, setView] = useState('observer');
  const [selectedConceptId, setSelectedConceptId] = useState<Concept['id']>();
  const [concepts, setConcepts] = useState<Concept[]>([]);
  const [sharePopupInfo, setSharePopupInfo] = useState<
    SharePopupInfo | undefined
  >(undefined);
  const [openedGraphCard, setOpenedGraphCard] = useState('');
  const [entityFilters, setEntityFilters] = useState<EntityType[]>([]);

  const onFeedUpdate = useCallback(() => {
    setFeedCache(feedCache + 1);
  }, [feedCache, setFeedCache]);

  const onFirstReward = useCallback(
    (data = {} as React.SetStateAction<undefined>) => {
      setFirstRewardCache(data);
    },
    [firstRewardCache, setFirstRewardCache]
  );

  const onFirstProblem = useCallback(
    (data = {} as React.SetStateAction<undefined>) => {
      setFirstProblemCache(data);
    },
    [firstProblemCache, setFirstProblemCache]
  );

  const onFirstSolution = useCallback(
    (data = {} as React.SetStateAction<undefined>) => {
      setFirstSolutionCache(data);
    },
    [firstSolutionCache, setFirstSolutionCache]
  );

  const onFirstInvention = useCallback(
    (data = {} as React.SetStateAction<undefined>) => {
      setFirstInventionCache(data);
    },
    [firstInventionCache, setFirstInventionCache]
  );

  const updateUnseen = useCallback(
    (id: string | number, seen: boolean) => {
      dataProvider
        .activityUpdateUnseen(id, seen)
        .then(({ items }) => {
          setUnseen(items);
        })
        .catch((err) => {
          setUnseen([]);
          console.error(err);
        });
    },
    [dataProvider, setUnseen]
  );

  const refreshProblemsList = useCallback(() => {
    setProblemsListCache(problemsListCache + 1);
  }, [problemsListCache, setProblemsListCache]);

  const refreshSolutionsList = useCallback(() => {
    setSolutionsListCache(solutionsListCache + 1);
  }, [solutionsListCache, setSolutionsListCache]);

  const refreshApplicationsList = useCallback(() => {
    setApplicationsListCache(applicationsListCache + 1);
  }, [applicationsListCache, setApplicationsListCache]);

  const refreshApplicationDetail = useCallback(() => {
    setApplicationDetailCache(applicationDetailCache + 1);
  }, [applicationDetailCache, setApplicationDetailCache]);

  const refreshProblemDetail = useCallback(() => {
    setProblemDetailCache(problemDetailCache + 1);
  }, [problemDetailCache, setProblemDetailCache]);

  const refreshSolutionDetail = useCallback(() => {
    setSolutionDetailCache(solutionDetailCache + 1);
  }, [solutionDetailCache, setSolutionDetailCache]);

  const refreshRelatedProblemsList = useCallback(() => {
    setRelatedProblemsListCache(relatedProblemsListCache + 1);
  }, [relatedProblemsListCache, setRelatedProblemsListCache]);

  const refreshRelatedSolutionsList = useCallback(() => {
    setRelatedSolutionsListCache(relatedSolutionsListCache + 1);
  }, [relatedSolutionsListCache, setRelatedSolutionsListCache]);

  const refreshRelatedApplicationsList = useCallback(() => {
    setRelatedApplicationsListCache(relatedApplicationsListCache + 1);
  }, [relatedApplicationsListCache, setRelatedApplicationsListCache]);

  const refreshRelatedProductsList = useCallback(() => {
    setRelatedProductsListCache(relatedProductsListCache + 1);
  }, [relatedProductsListCache, setRelatedProductsListCache]);

  const refreshRelatedContestsList = useCallback(() => {
    setRelatedContestsListCache(relatedContestsListCache + 1);
  }, [relatedContestsListCache, setRelatedContestsListCache]);

  const refreshContestDetail = useCallback(() => {
    setContestDetailCache(contestDetailCache + 1);
  }, [contestDetailCache, setContestDetailCache]);

  const refreshChallengeDetail = useCallback(() => {
    setChallengeDetailCache(challengeDetailCache + 1);
  }, [challengeDetailCache, setChallengeDetailCache]);

  const refreshContestsList = useCallback(() => {
    setContestsListCache(contestsListCache + 1);
  }, [contestsListCache, setContestsListCache]);

  const refreshChallengeList = useCallback(() => {
    setChallengeListCache(challengeListCache + 1);
  }, [challengeListCache, setChallengeListCache]);

  const refreshMyNftList = useCallback(() => {
    setMyNftListCache(myNftListCache + 1);
  }, [myNftListCache, setMyNftListCache]);

  const refreshProfileStats = useCallback(() => {
    setProfileStatsCache(profileStatsCache + 1);
  }, [profileStatsCache, setProfileStatsCache]);

  const updateFilterTags = useCallback(
    (newTags: Array<string>, newTagsValue: Array<string>) => {
      setFilterTags(newTags);
      setFilterTagsValue(newTagsValue);
    },
    [setFilterTags, setFilterTagsValue]
  );

  const showDraw = useCallback(() => {
    setIsDraw(true);
  }, [setIsDraw]);

  const hideDraw = useCallback(() => {
    setIsDraw(false);
  }, [setIsDraw]);

  const showSharePopup = useCallback(
    (options: SharePopupInfo | undefined) => {
      setSharePopupInfo(options);
    },
    [setSharePopupInfo]
  );

  const handleChangeView = useCallback(
    (view: React.SetStateAction<string>) => setView(view),
    []
  );

  const getUserConcepts = useCallback((userId: Identifier | undefined) => {
    if (!userId) {
      setConcepts([]);
      return;
    }

    return dataProvider
      .getConceptsByUserId<Concept>(userId, null)
      .then((data) => {
        setConcepts(data.data);
        return data;
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <DataContext.Provider
      value={{
        dataProvider,
        problemsListCache,
        refreshProblemsList,
        solutionsListCache,
        refreshSolutionsList,
        applicationsListCache,
        refreshApplicationsList,

        problemDetailCache,
        refreshProblemDetail,

        solutionDetailCache,
        refreshSolutionDetail,
        solutionDetailEditOpened,
        setSolutionDetailEditOpened,

        applicationDetailCache,
        refreshApplicationDetail,

        relatedProblemsListCache,
        refreshRelatedProblemsList,

        relatedSolutionsListCache,
        refreshRelatedSolutionsList,

        relatedApplicationsListCache,
        refreshRelatedApplicationsList,

        relatedProductsListCache,
        refreshRelatedProductsList,

        relatedContestsListCache,
        refreshRelatedContestsList,

        contestDetailCache,
        refreshContestDetail,

        contestsListCache,
        refreshContestsList,

        challengeDetailCache,
        refreshChallengeDetail,

        challengeListCache,
        refreshChallengeList,

        myNftListCache,
        refreshMyNftList,

        profileStatsCache,
        refreshProfileStats,

        filterTags,
        filterTagsValue,
        updateFilterTags,

        alertContent,
        setAlertContent,

        isDraw,
        showDraw,
        hideDraw,

        feedCache,
        unseen,
        updateUnseen,

        firstRewardCache,
        firstProblemCache,
        firstSolutionCache,
        firstInventionCache,

        sharePopupInfo,
        showSharePopup,

        showToast,
        hideToast,

        view,
        handleChangeView,
        selectedConceptId,
        setSelectedConceptId,
        concepts,
        getUserConcepts,
        openedGraphCard,
        setOpenedGraphCard,

        entityFilters,
        setEntityFilters
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const AuthContextConsumer = DataContext.Consumer;

export default DataContext;
