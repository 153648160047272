import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import dataProvider from 'dataPrvider';
import useRouter from 'hooks/useRouter';
import Actions from 'redux-state/actions';
import { GetTags, GetTagsLoading } from 'redux-state/selectors';
import { Constants } from 'utilities/constants';
import PsFormControl from 'components/common/PsFormControl';
import { OptionType } from 'modals/Common/interface';
import { StyledSearchSelect } from './StyledComponents';
import { Tag } from '../CardTag';

interface TagFieldProps {
  label?: string;
  placeholder?: string;
  productTags?: Array<Tag>;
  selectedTags?: Array<OptionType | null>;
  setSelectedTags?: React.Dispatch<
    React.SetStateAction<Array<OptionType | number | null>>
  >;
  startLoading?: () => void;
  stopLoading?: () => void;
  title?: string;
  companyName?: any;
  type?: string;
}

export const TagsField: React.FC<TagFieldProps> = ({
  label,
  placeholder,
  productTags,
  selectedTags,
  setSelectedTags,
  startLoading,
  stopLoading,
  title,
  companyName,
  type
}) => {
  const dispatch = useDispatch();
  const [mergedTags, setMergedTags] = useState<Array<OptionType>>([]);
  const [aiTags, setAiTags] = useState<Array<OptionType>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const tags = GetTags();
  const tagsLoading = GetTagsLoading();
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [tagSearch, setTagSearch] = useState<string>('');
  const [resetOptions, setResetOptions] = useState<boolean>(false);
  const router = useRouter();
  const { key } = router.query;

  useEffect(() => {
    dispatch(
      Actions.getTags(
        {
          key: key as string,
          item: Constants.TAGS,
          pagination: { page: page, perPage: 10 }
        },
        Constants.ALL,
        searchFilter
      )
    );
  }, [dispatch, key, page, searchFilter]);

  const debouncedHandleInput = useCallback(
    debounce((value, companyName) => {
      triggerSearch(value, companyName);
    }, 2000),
    []
  );

  useEffect(() => {
    if (title) {
      debouncedHandleInput(title, companyName);
    }
    return () => {
      debouncedHandleInput.cancel();
    };
  }, [title, debouncedHandleInput, companyName]);

  const productNameTags = useMemo(
    () =>
      productTags?.map((tag) => ({
        value: tag._id,
        label: tag.name
      })) || [],
    [productTags]
  );

  useEffect(() => {
    setMergedTags([...productNameTags, ...aiTags, ...tags]);
  }, [aiTags, tags, productNameTags]);

  useEffect(() => {
    setSelectedTags?.([...productNameTags, ...aiTags]);
  }, [aiTags, productNameTags, setSelectedTags]);

  const triggerSearch = (title: string, companyName: string) => {
    if (!title?.trim()) return;
    if (type == Constants.PRODUCTS && !companyName) return;

    setIsLoading(true);
    startLoading?.();
    dataProvider
      .tagsSearch(title, companyName, type)
      .then((data) => {
        const nameTags = data?.tags?.map((tag) => {
          return {
            value: `name_${tag}`,
            label: `${tag}`
          };
        });
        setAiTags(nameTags);
        setSelectedTags?.([...productNameTags, ...nameTags]);
      })
      .catch(() => {
        console.error('error occurred');
      })
      .finally(() => {
        setIsLoading(false);
        stopLoading?.();
      });
  };

  const handleTagChange = useCallback(
    (selectedOptions: Array<OptionType | null>) => {
      setSelectedTags?.(selectedOptions);
    },
    [setSelectedTags]
  );

  const handleSearchChange = useCallback(
    debounce((value: string) => {
      setMergedTags([]);
      setResetOptions(false);
      setPage(0);
      setSearchFilter(value);
    }, 500),
    []
  );

  const handleInputChange = useCallback(
    (value: string) => {
      setTagSearch(value);
      setResetOptions(true);
      handleSearchChange(value);
    },
    [handleSearchChange]
  );

  useEffect(() => {
    return () => {
      handleSearchChange.cancel();
    };
  }, [handleSearchChange]);

  return (
    <PsFormControl placeholder={placeholder} label={label} required>
      <StyledSearchSelect
        isLoading={isLoading || tagsLoading}
        isMulti
        onChange={handleTagChange}
        optionsList={mergedTags}
        page={page}
        setPage={setPage}
        value={selectedTags}
        setSelectedValue={setSelectedTags}
        resetOptions={resetOptions}
        handleInputChange={handleInputChange}
        inputValue={tagSearch}
        setInputValue={setTagSearch}
      />
    </PsFormControl>
  );
};
