import ChooseTypeStep from './steps/ChooseTypeStep';
import TypeDescriptionStep from './steps/TypeDescriptionStep';
import {
  ValidateFormType,
  FormStepConfig,
  BrainstormSolutionSteps
} from 'contexts/ModalDataContext';

export const BRAINSTORM_STEPS_CONFIG: {
  [name: string]: FormStepConfig;
} = {
  [BrainstormSolutionSteps.ChooseTypeStep]: {
    Component: ChooseTypeStep,
    nextStep: BrainstormSolutionSteps.TypeDescriptionStep,
    isNextStepBlocked: (data) => {
      return !(data.brainMethod && data.brainObject && data.titleSolution);
    },
    validate: (data): ValidateFormType => {
      if (!data.titleSolution) {
        return {
          success: false,
          errors: { titleSolution: 'Title is required' }
        };
      }
      if (data.brainMethod && data.brainObject) {
        return { success: true };
      }
      return { success: false, errors: { brainMethod: 'required' } };
    }
  },
  [BrainstormSolutionSteps.TypeDescriptionStep]: {
    isFinalStep: true,
    Component: TypeDescriptionStep,
    isNextStepBlocked: (data) => {
      if (!data.description) return true;

      return false;
    },
    validate: (data): ValidateFormType => {
      if (data.description) {
        return { success: true };
      }
      return { success: false, errors: { description: 'required' } };
    },
    nextButtonTitle: 'Add'
  }
};
