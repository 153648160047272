import React from 'react';
import { Modal as MuiModal } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { PsTheme } from '../theme';
import { makeStyles } from 'tss-react/mui';

export type ModalProps = {
  classes?: any;
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  className?: string;
};

const useStyles = makeStyles()((theme) => {
  const psTheme = theme as PsTheme;
  return {
    root: {
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`,
      position: 'fixed',
      outline: 0,
      width: '100%',
      maxWidth: 650,
      '&.wide': {
        maxWidth: 1100,
        '& .actions': {
          flexDirection: 'row-reverse'
        },
        '& .actions > button:first-of-type': {
          width: '50%',
          marginLeft: 12
        },
        '& .actions > button:last-child': {
          width: '50%',
          marginRight: 12
        },
        [psTheme.breakpoints.down('xs')]: {
          '& .actions': {
            flexDirection: 'column'
          },
          '& .actions > button:first-of-type': {
            width: '100%',
            marginLeft: 0
          },
          '& .actions > button:last-child': {
            width: '100%',
            marginRight: 0
          }
        }
      }
    }
  };
});

const ModalSmallView = function ModalSmallView({
  isOpen,
  onClose,
  children,
  className
}: ModalProps) {
  const { classes } = useStyles();
  let rootClassName = classes.root;
  if (className) {
    rootClassName += ' ' + className;
  }

  return (
    <MuiModal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      disableEnforceFocus
      disableAutoFocus
      BackdropProps={{
        style: {
          background: 'rgba(3,31,70, 0.7)'
        }
      }}
    >
      <div className={rootClassName}>{children}</div>
    </MuiModal>
  );
};

export const ModalSmall = styled(ModalSmallView)({});

export default ModalSmall;
