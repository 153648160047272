import React, { useCallback, useEffect, useState } from 'react';
import dataProvider from 'dataPrvider';
import { debounce } from 'lodash';
import { Problem } from 'components/CardProblem';
import { Constants } from 'utilities/constants';
import PsFormControl from 'components/common/PsFormControl';
import PsSearchSelect from 'components/common/PsSearchSelect';
import { OptionType } from 'modals/Common/interface';
import { StyledFieldLabel } from './StyledComponents';

interface ProblemFieldProps {
  selectedProblem?: OptionType | null;
  setSelectedProblem?: React.Dispatch<React.SetStateAction<OptionType | null>>;
}

const ParentFieldLabel = (
  <StyledFieldLabel>{Constants.SELECT_PARENT_PROBLEM}</StyledFieldLabel>
);

const ProblemField: React.FC<ProblemFieldProps> = ({
  selectedProblem,
  setSelectedProblem
}) => {
  const [page, setPage] = useState<number>(1);
  const [problems, setProblems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [tagSearch, setTagSearch] = useState<string>('');
  const [resetOptions, setResetOptions] = useState<boolean>(false);

  const handleProblemChange = (selected: OptionType | null) => {
    setSelectedProblem(selected);
  };

  const customFilters = {
    title: searchFilter,
    withTitle: true
  };

  useEffect(() => {
    setLoading(true);
    dataProvider
      .getList<Problem>(Constants.PROBLEMS, {
        pagination: { page, perPage: 10 },
        sort: { field: 'title', order: 'ASC' },
        filter: { $custom: customFilters }
      })
      .then(({ data = [] }) => {
        const list = data.map((item) => ({
          id: `${item.id}`,
          name: item.title || ''
        }));
        setProblems(list);
      })
      .catch(() => {
        setProblems([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [page, searchFilter]);

  const handleSearchChange = useCallback(
    debounce((value: string) => {
      setResetOptions(true);
      setProblems([]);
      setPage(1);
      setSearchFilter(value);
      setResetOptions(false);
    }, 500),
    []
  );

  const handleInputChange = useCallback(
    (value: string) => {
      setTagSearch(value);
      handleSearchChange(value);
    },
    [handleSearchChange]
  );

  useEffect(() => {
    return () => {
      handleSearchChange.cancel();
    };
  }, [handleSearchChange]);

  return (
    <PsFormControl label={ParentFieldLabel} required>
      <PsSearchSelect
        createableValue={selectedProblem}
        handleInputChange={handleInputChange}
        inputValue={tagSearch}
        isLoading={loading}
        onCreateableChange={handleProblemChange}
        optionsList={problems}
        page={page}
        resetOptions={resetOptions}
        setInputValue={setTagSearch}
        setPage={setPage}
      />
    </PsFormControl>
  );
};
export default ProblemField;
