import React from 'react';
export const WalletIconNew = ({
  size = 30,
  color = '',
  className
}: {
  size?: number;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      className={className}
      //   width={size}
      //   height={size}
      xmlns="http://www.w3.org/2000/svg"
      width="19.666"
      height="17.208"
    >
      <path
        fill="#632ddd"
        d="M17.715 3.687H3.073a.615.615 0 1 1 0-1.229h14.75a.614.614 0 0 0 .615-.615A1.844 1.844 0 0 0 16.593 0H2.458A2.458 2.458 0 0 0 0 2.458V14.75a2.458 2.458 0 0 0 2.458 2.458h15.257a1.9 1.9 0 0 0 1.951-1.844V5.531a1.9 1.9 0 0 0-1.951-1.844Zm-1.736 7.989a1.229 1.229 0 1 1 1.229-1.226 1.229 1.229 0 0 1-1.229 1.227Z"
        data-name="Icon awesome-wallet"
      />
    </svg>
  );
};
