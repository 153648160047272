import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useDispatch } from 'react-redux';
import { CallMade } from '@mui/icons-material';
import { Step } from '@mui/material';
import { Application } from 'components/CardApplication';
import { default as Config, default as config } from 'config/config';
import AuthContext from 'contexts/AuthContext';
import Modal from 'modals/Modal';
import { toastify } from 'pages/newContests/toastify';
import Actions from 'redux-state/actions';
import { Constants, ERRORS, VARIANT } from 'utilities/constants';
import { LOCK_CONCEPT_STEPS, STEP_NAME } from './LockConceptSteps';
import {
  HeaderBox,
  HeaderText,
  MainContainer,
  StyledDivider,
  StyledFooterBox,
  StyledIconButton,
  StyledSaveButton,
  StyledStepLabel,
  StyledStepper,
  SubTitleText,
  TitleBox,
  TitleMainBox,
  TitleText
} from './styles';
import { toggleCreditsModal } from 'helpers/common';

const API_COSTS = config.API_COSTS;
const TOASTIFY_DURATION = 2000;

const steps: string[] = [
  STEP_NAME.SOLUTIONS,
  STEP_NAME.RELATIONSHIP,
  STEP_NAME.OVERVIEW
];

interface LockConceptModalProps {
  archiveStatus: boolean;
  beforeClose?: () => void;
  open: boolean;
  concept: Application;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setOpenNextModal?: () => void;
  profileId: string | number;
  pagination: { page: number; perPage: number };
  onTaskComplete: () => void;
}

export const LockConceptModal: React.FC<LockConceptModalProps> = ({
  archiveStatus,
  beforeClose,
  concept,
  open,
  profileId,
  pagination,
  setOpen,
  setOpenNextModal,
  onTaskComplete
}) => {
  const { user } = useContext(AuthContext);
  const [currentStep, setCurrentStep] = useState<string | undefined>(
    STEP_NAME.SOLUTIONS || ''
  );
  const [prevSteps, setPrevSteps] = useState<string[]>([]);
  const [activeStepNumber, setActiveStepNumber] = useState<number>(0);
  const [conceptUpdateObj, setConceptUpdateObj] = useState({});
  const [isNextStepBlocked, setIsNextStepBlocked] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [childSaveCallback, setChildSaveCallback] = useState<
    (() => void) | undefined
  >();

  const dispatch = useDispatch();

  const {
    Component,
    nextStep,
    title,
    subtitle,
    isFinalStep = false
  } = LOCK_CONCEPT_STEPS[currentStep || ''];

  const StepComponent = Component;

  const handleClose = useCallback(() => {
    beforeClose();
    setIsNextStepBlocked(false);
    dispatch(Actions.generateDescriptionFromAISuccess({}));
    dispatch(Actions.generateImageFromAISuccess({}));
    setOpen(false);
  }, [setOpen]);

  useEffect(() => {
    if (open) {
      setPrevSteps([]);
      setActiveStepNumber(0);
      dispatch(Actions.getAiImageSuccess({}));
      setCurrentStep(STEP_NAME.SOLUTIONS);
    }
  }, [open]);

  const handleNextStep = useCallback(() => {
    if (nextStep === STEP_NAME.RELATIONSHIP) {
      if (user.credits < API_COSTS.GET_REL_PROMPT + API_COSTS.SEARCH_ANSWER) {
        toastify(
          ERRORS.NOT_ENOUGH_CREDITS,
          VARIANT.ERROR,
          VARIANT.BOTTOM_LEFT,
          TOASTIFY_DURATION
        );
        toggleCreditsModal(user, dispatch);
        return;
      }
    }
    if (!isFinalStep) {
      setPrevSteps((prev) => [...prev, currentStep || '']);
      setCurrentStep(nextStep);
      setActiveStepNumber((prev) => prev + 1);
    } else {
      if (user) {
        dispatch(
          Actions.editProfileItem({
            type: Constants.APPLICATIONS,
            id: concept.id,
            profileId: profileId,
            pagination,
            data: { ...conceptUpdateObj, isLocked: true }
          })
        );
      } else {
        dispatch(Actions.openAuthModal(true));
      }
      handleClose();
      setOpenNextModal();
    }
  }, [
    isFinalStep,
    currentStep,
    user,
    nextStep,
    dispatch,
    concept.id,
    profileId,
    pagination,
    conceptUpdateObj,
    handleClose
  ]);

  const handleClickBack = useCallback(() => {
    if (prevSteps.length > 0) {
      setCurrentStep(prevSteps[prevSteps.length - 1]);
      setPrevSteps((prev) => prev.slice(0, prev.length - 1));
      setActiveStepNumber((prev) => Math.max(0, prev - 1));
    } else {
      handleClose();
    }
    setIsNextStepBlocked(false);
  }, [prevSteps, handleClose]);

  const handleClickEdit = useCallback(() => {
    if (concept.solutions.length) {
      const lastSolution = concept.solutions[concept.solutions.length - 1];
      window.open(
        `${Config.NEXT_PUBLIC_CLIENT_URL}${Constants.SOLUTIONS}/${lastSolution.id}`,
        '_blank'
      );
    }
  }, [prevSteps, handleClose]);

  const stepsView = useMemo(
    () =>
      steps.map((label, index) => (
        <Step key={`steps${index}`}>
          <StyledStepLabel>{label}</StyledStepLabel>
        </Step>
      )),
    []
  );

  const handleSaveAndContinue = useCallback(() => {
    if (isFormValid && childSaveCallback) {
      childSaveCallback();
      handleNextStep();
    }
  }, [childSaveCallback, handleNextStep, isFormValid]);

  return (
    <Modal open={open} onClose={handleClose}>
      <MainContainer>
        <HeaderBox>
          <HeaderText>{Constants.CONFIRM_CONCEPT}</HeaderText>
          <StyledStepper activeStep={activeStepNumber} alternativeLabel>
            {stepsView}
          </StyledStepper>
        </HeaderBox>
        <StyledDivider />
        {currentStep === STEP_NAME.SOLUTIONS && (
          <TitleMainBox>
            <TitleBox>
              <TitleText>{title}</TitleText>
              {subtitle && <SubTitleText>{subtitle}</SubTitleText>}
            </TitleBox>
            <StyledIconButton onClick={handleClickEdit}>
              <CallMade />
            </StyledIconButton>
          </TitleMainBox>
        )}
        <StepComponent
          archiveStatus={archiveStatus}
          concept={concept}
          handleClose={handleClose}
          isFormValid={isFormValid}
          pagination={pagination}
          profileId={profileId}
          setConceptUpdateObj={setConceptUpdateObj}
          setIsFormValid={setIsFormValid}
          setIsNextStepBlocked={setIsNextStepBlocked}
          setSaveCallback={setChildSaveCallback}
          onTaskComplete={onTaskComplete}
        />
        <StyledDivider />
        <StyledFooterBox>
          <StyledSaveButton onClick={handleClickBack}>
            {Constants.BACK}
          </StyledSaveButton>

          {activeStepNumber === 1 && isFormValid ? (
            <StyledSaveButton onClick={handleSaveAndContinue}>
              {Constants.SAVE_AND_CONTINUE}
            </StyledSaveButton>
          ) : (
            <StyledSaveButton
              onClick={handleNextStep}
              disabled={isNextStepBlocked || concept.selected.length <= 1}
            >
              {isFinalStep ? Constants.CONFIRM_AND_PATENT : Constants.NEXT}
            </StyledSaveButton>
          )}
        </StyledFooterBox>
      </MainContainer>
    </Modal>
  );
};
