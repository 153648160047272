import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash'; // eslint-disable-next-line react-hooks/exhaustive-deps
import { useDispatch } from 'react-redux';
import Actions from 'redux-state/actions';
import { GetTagsLoading } from 'redux-state/selectors';
import useRouter from 'hooks/useRouter';
import PsFormControl from 'components/common/PsFormControl';
import { Constants } from 'utilities/constants';
import { OptionType } from 'modals/Common/interface';
import { StyledSearchSelect } from './StyledComponents';

interface ProfileTagsFieldProps {
  action: typeof Actions.getLocationTags;
  isProfile?: boolean;
  isRelatedTags?: boolean;
  label?: string;
  placeholder?: string;
  selectedTags?: any;
  setSelectedTags?: any;
  tagsSelector: () => Array<OptionType>;
  tagType: string;
}

const DEBOUNCE_DELAY = 500;

export const ProfileTagsField: React.FC<ProfileTagsFieldProps> = ({
  action,
  isProfile,
  isRelatedTags,
  label,
  placeholder,
  selectedTags,
  setSelectedTags,
  tagsSelector,
  tagType
}) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState<number>(0);
  const tagsLoading = GetTagsLoading();
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [tagSearch, setTagSearch] = useState<string>('');
  const [resetOptions, setResetOptions] = useState<boolean>(false);
  const router = useRouter();
  const { key } = router.query;

  useEffect(() => {
    setSearchFilter('');
    setTagSearch('');
    dispatch(
      action(
        {
          key: key as string,
          item: Constants.TAGS,
          pagination: { page, perPage: 10 }
        },
        tagType,
        searchFilter
      )
    );
  }, [dispatch, action, key, page, searchFilter, tagType]);

  const handleTagChange = useCallback(
    (selectedOptions: Array<OptionType | null>) => {
      setSelectedTags?.(selectedOptions);
    },
    [setSelectedTags]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchChange = useCallback(
    debounce((value: string) => {
      setResetOptions(false);
      setPage(0);
      setSearchFilter(value);
    }, DEBOUNCE_DELAY),
    []
  );

  const handleInputChange = useCallback(
    (value: string) => {
      setTagSearch(value);
      setResetOptions(true);
      handleSearchChange(value);
    },
    [handleSearchChange]
  );

  useEffect(() => {
    return () => {
      handleSearchChange.cancel();
    };
  }, [handleSearchChange]);

  return (
    <PsFormControl placeholder={placeholder} label={label} required>
      <StyledSearchSelect
        isLoading={tagsLoading}
        onChange={handleTagChange}
        optionsList={tagsSelector}
        page={page}
        setPage={setPage}
        value={selectedTags}
        setSelectedValue={setSelectedTags}
        resetOptions={resetOptions}
        handleInputChange={handleInputChange}
        inputValue={tagSearch}
        setInputValue={setTagSearch}
        isProfile={isProfile}
        {...(isRelatedTags ? { isMulti: true } : {})}
      />
    </PsFormControl>
  );
};
