import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { EditButton } from 'components/common/PsButton';
import { TreeIcon } from 'components/icons/TreeIcon';
import { PsTheme } from '../../theme';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';

type ClassKey =
  | 'root'
  | 'headerBtns'
  | 'openButton'
  | 'closeButton'
  | 'editButton'
  | 'drawer'
  | 'box'
  | 'header'
  | 'headerIcon';

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {},
    headerBtns: {
      whiteSpace: 'nowrap'
    },
    openButton: {
      position: 'fixed',
      top: 110,
      right: 20,
      zIndex: 1000,
      appearance: 'none',
      margin: 0,
      padding: '15px 13px 8px 13px',
      background: '#fff',
      border: 'none',
      borderRadius: '50%',
      cursor: 'pointer',
      boxShadow: '0px 0px 4px 7px rgba(0, 0, 0, 0.05)',
      '&:hover': {
        boxShadow: '0px 0px 4px 4px rgba(0, 0, 0, 0.05)'
      },
      '&:active': {
        boxShadow: '0px 0px 4px 2px rgba(0, 0, 0, 0.05)'
      },
      '& > .count': {
        position: 'absolute',
        top: 4,
        right: 7,
        padding: '1px 3px',
        background: '#fff',
        borderRadius: '50%',
        fontSize: 12,
        fontWeight: 600,
        lineHeight: 1,
        color: psTheme.palette.primary.main
      }
    },
    closeButton: {
      appearance: 'none',
      margin: 0,
      padding: '5px 8px 2px 11px',
      background: 'none',
      border: 'none',
      borderRadius: '50%',
      color: psTheme.palette.primary.main,
      cursor: 'pointer',
      transition: 'background 0.3s',
      '&:hover': {
        background: '#eee'
      }
    },
    editButton: {
      minHeight: 15,
      padding: 6,
      background: 'none',
      borderRadius: '50%',
      color: psTheme.palette.primary.main,
      transition: 'background 0.3s',
      '& svg': {
        width: 15,
        height: 15,
        margin: 0
      },
      '&:hover, &.active': {
        background: '#eee'
      }
    },
    drawer: {
      position: 'fixed',
      top: 110,
      right: 20,
      zIndex: 1000,
      width: 'calc(100% - 20px - 20px)',
      maxWidth: 375
    },
    box: {
      display: 'inline-flex',
      flexDirection: 'column',
      width: '100%',
      maxHeight: 'calc(100vh - 110px - 100px)',
      padding: 16,
      background: '#fff',
      boxShadow: '0px 0px 4px 7px rgba(0, 0, 0, 0.05)',
      borderRadius: 8,
      boxSizing: 'border-box'
    },
    header: {
      flexShrink: 0,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 15,
      '& > button': {
        flexShrink: 0,
        marginLeft: 5
      },
      '& > p': {
        fontSize: 16,
        lineHeight: 1,
        textAlign: 'center'
      }
    },
    headerIcon: {
      position: 'relative',
      flexShrink: 0,
      marginRight: 20,
      '& > .count': {
        position: 'absolute',
        top: -2,
        left: 24,
        padding: '1px 3px',
        background: '#fff',
        borderRadius: '50%',
        fontSize: 14,
        fontWeight: 600,
        lineHeight: 1,
        color: psTheme.palette.primary.main
      }
    }
  };
});

type DrawerModalProps = {
  className?: string;
  children?: React.ReactNode;
  title: string | undefined;
  count: number;
  canEdit?: boolean;
  editable?: boolean;
  onEditable?: (isEditable: boolean) => void;
};

const DrawerModalView = ({
  className,
  children,
  title,
  count,
  canEdit,
  editable,
  onEditable
}: DrawerModalProps) => {
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);

  const onToggleClick = () => {
    setOpen(!open);
  };

  const onEditClick = () => {
    if (onEditable) {
      onEditable(!editable);
    }
  };

  let rootClassName = classes.root;
  if (className) {
    rootClassName += ' ' + className;
  }

  return (
    <div className={rootClassName}>
      {/*<button className={classes.openButton} onClick={onToggleClick}>*/}
      {/*  <TreeIcon />*/}
      {/*  <Typography className="count">{count}</Typography>*/}
      {/*</button>*/}
      <Slide direction="left" in={open} mountOnEnter unmountOnExit>
        <div className={classes.drawer}>
          <div className={classes.box}>
            <div className={classes.header}>
              <span className={classes.headerIcon}>
                <TreeIcon />
                <Typography className="count">{count}</Typography>
              </span>
              <Typography>{title}</Typography>
              <span className={classes.headerBtns}>
                {canEdit ? (
                  <EditButton
                    title="Edit Solutions"
                    className={`${classes.editButton} ${
                      editable ? 'active' : ''
                    }`}
                    onClick={onEditClick}
                  ></EditButton>
                ) : null}
                <button className={classes.closeButton} onClick={onToggleClick}>
                  <svg
                    width="9"
                    height="16"
                    viewBox="0 0 9 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.66498 0.5L0 2.2625L5.40823 8L0 13.7375L1.66498 15.5L8.75 8L1.66498 0.5Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              </span>
            </div>
            {children}
          </div>
        </div>
      </Slide>
    </div>
  );
};

export const DrawerModal = styled(DrawerModalView)({});

export default DrawerModal;
