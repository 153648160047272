import * as commonsActions from './commons/actions';
import * as contestActions from './contestView/actions';
import * as homeActions from './home/actions';
import * as ideamapActions from './ideamap/actions';
import * as onboardingActions from './onboarding/actions';
import * as profileActions from './profile/actions';
import * as rewardsActions from './rewards/actions';
import * as shareActions from './share/actions';

const combinedActions = {
  ...commonsActions,
  ...contestActions,
  ...homeActions,
  ...ideamapActions,
  ...onboardingActions,
  ...profileActions,
  ...rewardsActions,
  ...shareActions
};

export default combinedActions;
