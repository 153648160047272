import React from 'react';
import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Link,
  styled,
  Toolbar,
  Typography
} from '@mui/material';
import { colorPalette } from 'theme';

export const StyledHeaderMainBox = styled(Box)({
  color: colorPalette.white,
  minHeight: '100vh',
  position: 'relative',
  overflowX: 'hidden'
});

export const StyledFooterMainBox = styled(Box)({
  color: colorPalette.white,
  padding: '3.125rem 0',
  position: 'relative'
});

export const StyledFooterVideoContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  width: '100%'
});

export const StyledFooterCardContainer = styled(Box)({
  display: 'flex',
  gap: '1.25rem'
});

export const StyledFooterDivider = styled(Divider)({
  backgroundColor: 'black',
  mt: '0.5rem',
  mx: 'auto',
  width: '10rem'
});

export const StyledFooterSeeContainer = styled(Box)({
  mb: '0.3125rem',
  textAlign: 'center'
});

export const StyledFooterSeeTypography = styled(Typography)({
  fontSize: '2rem',
  fontWeight: 700
});

export const StyledFooterCardHeader = styled(Typography)({
  fontSize: '1.3125rem',
  fontWeight: 700
});

export const StyledFooterCardContent = styled(Typography)({
  fontSize: '1.0625rem',
  fontWeight: 400,
  opacity: '70%'
});

export const StyledFooterInnovationTextContainer = styled(Box)({
  mb: '0.3125rem',
  textAlign: 'center'
});

export const StyledFooterInnovationTypography = styled(Typography)({
  fontSize: '3rem',
  fontWeight: 700
});

export const StyledFooterVideoBox = styled(Box)({
  alignItems: 'center',
  backgroundColor: colorPalette.alto,
  borderRadius: '0.625rem',
  display: 'flex',
  height: '32.125rem',
  justifyContent: 'center',
  marginBottom: '3.125rem',
  width: '69.375rem'
});

export const StyledReachMainBox = styled(Box)({
  backgroundColor: colorPalette.snowWhite,
  padding: '3.125rem 0  1.5625rem 0'
});

export const StyledReachLogoBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexGrow: 1,
  gap: '0.625rem'
});

export const StyledReachCopyrightBox = styled(Box)({
  borderTop: `0.0625rem solid ${colorPalette.alto}`,
  marginTop: '3.125rem',
  paddingTop: '1.25rem'
});

export const StyledReachIconBox = styled(Box)({
  display: 'flex',
  gap: '2rem'
});

export const StyledReachIconButton = styled(IconButton)({
  color: colorPalette.doveGray,
  height: '1.5rem',
  width: '1.5rem'
});

export const StyledReachTypography = styled(Typography)({
  color: colorPalette.doveGray,
  fontSize: '1.0625rem',
  fontWeight: 400,
  marginTop: '0.625rem'
});

export const StyledReachCopyrightTypography = styled(Typography)({
  color: colorPalette.mirage,
  fontSize: '0.9375rem',
  fontWeight: 400
});

export const StyledReachSubTypography = styled(Typography)({
  color: colorPalette.doveGray,
  fontSize: '0.9375rem',
  fontWeight: 400
});

export const StyledReachLink = styled(Link)({
  display: 'block',
  fontSize: '1.0625rem',
  fontWeight: 400,
  textDecoration: 'none'
});

export const StyledRoyaltiesMainBox = styled(Box)({
  backgroundColor: colorPalette.white,
  padding: '4rem 1rem',
  overflowX: 'hidden'
});

export const StyledAppbar = styled(AppBar)({
  backgroundColor: 'transparent',
  boxShadow: 'none',
  padding: '0.5rem',
  position: 'static'
});

export const StyledHeaderLogoBar = styled(Box)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: isMediumScreen ? 'column' : 'row',
    flexGrow: 1,
    gap: isMediumScreen ? '0.3125rem' : '0.625rem',
    color: colorPalette.white,
    marginLeft: '1.25rem',
    marginTop: '0.625rem'
  })
);

export const StyledHeaderMenu = styled(Box)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    alignItems: 'center',
    display: 'flex',
    gap: isMediumScreen ? '0rem' : '1.25rem'
  })
);

export const StyledEarnedTypography = styled(Typography)({
  fontSize: '3.75rem',
  fontWeight: 700
});

export const StyledWinRoyaltiesHeaderTypography = styled(Typography)({
  fontSize: '2.5rem',
  fontWeight: 700,
  marginBottom: '1rem'
});

export const StyledWinRoyaltiesSubHeaderTypography = styled(Typography)({
  fontSize: '1.25rem',
  fontWeight: 400,
  opacity: 0.7,
  marginBottom: '1.5rem'
});

export const StyledWinRoyaltiesContentTypography = styled(Typography)({
  fontSize: '1.125rem',
  fontWeight: 700,
  marginTop: '1.5rem',
  marginBottom: '0.5rem'
});

export const StyledWinRoyaltiesSubContentTypography = styled(Typography)({
  fontSize: '1rem',
  fontWeight: 400,
  opacity: 0.7,
  lineHeight: 1.6
});

export const StyledEarnedContentTypography = styled(Typography)({
  fontSize: '1.1875rem',
  fontWeight: 400,
  marginBottom: '1.25rem',
  maxWidth: '25rem',
  opacity: '60%'
});

export const StyledHeaderMenuButton = styled(Button)<{
  isMediumScreen: boolean;
}>(({ isMediumScreen }) => ({
  color: colorPalette.white,
  fontSize: isMediumScreen ? '0.5625rem' : '0.9375rem',
  fontWeight: 700
}));

export const StyledHeaderLearnButton = styled(Button)({
  backgroundColor: colorPalette.purpleHeart,
  border: `0.0625rem solid ${colorPalette.white}`,
  borderRadius: '0.125rem',
  height: '3.7rem',
  width: '12.1875rem',
  '&:hover': {
    backgroundColor: colorPalette.purpleHeart
  }
});

export const StyledHeaderLearnTypography = styled(Typography)({
  color: colorPalette.white,
  fontSize: '1.0625rem',
  fontWeight: 700,
  textTransform: 'none'
});

export const StyledHeaderLoginButton = styled(Button)<{
  isMediumScreen: boolean;
}>(({ isMediumScreen }) => ({
  backgroundColor: colorPalette.lightPurpleBackground,
  borderRadius: '0.625rem',
  color: colorPalette.white,
  fontWeight: 'bold',
  minWidth: isMediumScreen && '3.6875rem',
  height: isMediumScreen ? '2rem' : '3rem',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: colorPalette.electricViolet
  }
}));

export const StyledHeaderLoginTypography = styled(Typography)<{
  isMediumScreen: boolean;
}>(({ isMediumScreen }) => ({
  fontSize: isMediumScreen ? '0.5rem' : '1.0625rem',
  fontWeight: 700
}));

export const StyledHeaderContentContainer = styled(Container)({
  paddingTop: '6.25rem'
});

export const StyledToolbar = styled(Toolbar)<{ isMediumScreen: boolean }>(
  ({ isMediumScreen }) => ({
    flexDirection: isMediumScreen ? 'column' : 'row',
    justifyContent: 'space-between',
    '&.MuiToolbar-root': {
      paddingLeft: '0.125rem'
    }
  })
);

export const StyledSubHeaderMainBox = styled(Box)({
  position: 'relative',
  zIndex: 1
});

export const StyledNumberBox = styled(Box)({
  marginTop: '3.125rem',
  position: 'relative'
});

export const StyledDottedLineBox = styled(Box)({
  left: '17%',
  position: 'absolute',
  top: '17%',
  width: '100%',
  zIndex: 0
});

export const StyledHeaderImageBox = styled(Box)({
  height: '100%',
  left: 0,
  overflow: 'hidden',
  position: 'absolute',
  top: 0,
  width: '100%',
  zIndex: 0
});
