import { call, put, takeLatest } from 'redux-saga/effects';

import * as Actions from './actions';
import { Api } from './api';

import { SHARE_TO_SOCIAL_MEDIA } from './types';

function* shareToSocialMedia(action: { type: string; payload: any }): any {
  try {
    const response = yield call(Api.shareToSocialMedia, action.payload);
    if (response) {
      yield put(Actions.shareToSocialMediaSuccess(response));
    }
  } catch (error) {
    console.log(error);
  }
}

function* mySaga() {
  yield takeLatest(SHARE_TO_SOCIAL_MEDIA, shareToSocialMedia);
}

export default mySaga;
