import { Typography, Box, styled, Divider } from '@mui/material';
import { colorPalette } from 'theme';

export const Container = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0.9rem 0.7rem 0.6rem 0.7rem ',
  width: '100%'
});

export const TitleText = styled(Typography)({
  alignItems: 'center',
  display: 'flex',
  fontSize: '.9375rem',
  fontWeight: 600,
  lineHeight: 1.2
});

export const TitleWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '.5rem'
});

export const StyledDivider = styled(Divider)({
  width: '100%',
  margin: '.125rem 0px'
});

export const ImproveLink = styled(Typography)({
  color: colorPalette.purple,
  cursor: 'pointer',
  fontSize: '.9375rem',
  fontWeight: 700,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline'
  }
});
