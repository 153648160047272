import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  useMemo
} from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { Modal } from 'modals/Modal';
import { Application } from 'components/CardApplication';
import { PsFormControl, ShortEvent } from 'components/common/PsFormControl';
import { PsInput } from 'components/common/PsInput';
import { PsSelect, PsOptionType } from 'components/common/PsSelect';
import { PsSelectedList } from 'components/common/PsSelectedList';
import { PsButton, EditIcon } from 'components/common/PsButton';
import { useDropzone } from 'react-dropzone';
import { DataContext } from 'contexts/DataContext';
import {
  Picture,
  convertFileToBase64,
  getPlaceholderImage,
  getFileTitle,
  placeholderType
} from '../../helpers';
import { PsTheme } from '../../theme';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';

const DEFAULT_IMAGE = {
  title: 'picture',
  contentType: 'image/svg+xml',
  url: getPlaceholderImage(placeholderType.A)
};

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    root: {},
    columns: {
      display: 'flex',
      [psTheme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    },
    left: {
      width: '44%',
      flexShrink: 0,
      [psTheme.breakpoints.down('sm')]: {
        width: '100%',
        maxWidth: 490,
        margin: '0 auto'
      }
    },
    imageHolder: {
      '& .inner': {
        position: 'relative',
        paddingTop: '60%'
      },
      '& img': {
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'block',
        width: '100%',
        height: '100%',
        borderRadius: 10,
        objectFit: 'cover'
      }
    },
    dropzoneContainer: {
      padding: '10px 0',
      '& .link': {
        color: psTheme.palette.primary.main,
        cursor: 'pointer'
      },
      '& .link:hover': {
        textDecoration: 'underline'
      },
      '& .link svg': {
        marginRight: 5,
        marginBottom: -3
      }
    },
    right: {
      paddingLeft: 25,
      flexGrow: 1,
      '& .MuiGrid-container > div': {
        marginTop: -20
      },
      [psTheme.breakpoints.down('sm')]: {
        paddingLeft: 0
      },
      [psTheme.breakpoints.down('xs')]: {
        paddingTop: 20,
        '& .MuiGrid-spacing-xs-2': {
          margin: '0 -8px'
        }
      }
    },
    buttons: {
      display: 'flex',
      '& > button': {
        width: '50%'
      },
      '& > button:first-child': {
        marginRight: 70
      },
      [psTheme.breakpoints.down('xs')]: {
        flexDirection: 'column-reverse',
        '& > button': {
          width: '100%'
        },
        '& > button:first-child': {
          marginRight: 0,
          marginTop: 10
        }
      }
    }
  };
});

type ModalEditProfileProps = {
  app: Application;
  open: boolean;
  onClose?: () => void;
  onUpdate?: (profile: Application) => void;
};

const ModalEditApplicationView = ({
  app,
  open,
  onClose,
  onUpdate
}: ModalEditProfileProps) => {
  const initTitle = app.title || '';
  const initBody = app.body || '';
  const initSelected = useMemo(() => app.selected || [], [app.selected]);
  const initPicture =
    app.files && app.files.length ? app.files[0] : DEFAULT_IMAGE;

  const { dataProvider } = useContext(DataContext);
  const [loading, setLoading] = useState(false);
  const { classes } = useStyles();
  const [list, setList] = useState<Array<PsOptionType>>([]);
  const [picture, setPicture] = useState<Picture>(initPicture);
  const [title, setTitle] = useState<string>(initTitle);
  const [description, setDescription] = useState<string>(initBody);
  const [selectedValue, setSelectedValue] = useState<string>(
    initSelected.join(',') || ''
  );

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/gif': ['.gif'],
      'image/svg+xml': ['.svg']
    }
  });

  useEffect(() => {
    const initTitle = app.title || '';
    const initBody = app.body || '';
    const initSelected = app.selected || [];
    const initPicture =
      app.files && app.files.length ? app.files[0] : DEFAULT_IMAGE;

    setPicture(initPicture);
    setTitle(initTitle);
    setDescription(initBody);
    setSelectedValue(initSelected.join(',') || '');

    dataProvider
      .getProblemTree({ id: app.problem })
      .then((list) => {
        const optionsList: Array<PsOptionType> = list
          .map((item) => {
            return {
              value: `${item.id}`,
              label: item.title || '(empty)',
              type: item.type
            };
          })
          .filter((item) => item.type === 'solution');
        setList([...optionsList]);
      })
      .catch((error) => console.error(error));
  }, [app, dataProvider]);

  useEffect(() => {
    if (!acceptedFiles.length) {
      return;
    }
    Promise.all(acceptedFiles.map(convertFileToBase64)).then(([item]) => {
      const { file, base64 } = item;
      const image = {
        contentType: file.type,
        title: file.name,
        url: base64
      };
      setPicture(image);
    });
  }, [acceptedFiles]);

  const onCancel = useCallback(() => {
    if (onClose) {
      onClose();
    }
    setPicture(initPicture);
    setTitle(initTitle);
    setDescription(initBody);
    setSelectedValue(initSelected.join(',') || '');
  }, [
    setPicture,
    initPicture,
    setTitle,
    initTitle,
    setDescription,
    initBody,
    initSelected,
    onClose
  ]);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (loading) {
      return;
    }

    const files: Array<Picture> = [];
    if (picture !== DEFAULT_IMAGE) {
      files.push(picture);
    }
    const appInfo = {
      title,
      files,
      body: description,
      selected: selectedValue.split(',')
    };

    setLoading(true);

    dataProvider
      .update<Application>('applications', { id: app.id, data: appInfo })
      .then(({ data }) => {
        if (onUpdate) {
          onUpdate(data);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onTitleChange = useCallback(
    (e: ShortEvent) => {
      setTitle(e.target.value);
    },
    [setTitle]
  );

  const onDescriptionChange = useCallback(
    (e: ShortEvent) => {
      setDescription(e.target.value);
    },
    [setDescription]
  );

  const onListChange = useCallback(
    (event: ShortEvent) => {
      const { value } = event.target;
      setSelectedValue(value);
    },
    [setSelectedValue]
  );

  return (
    <Modal open={open} onClose={onCancel} title="Edit invention information">
      <div className={classes.columns}>
        <div className={classes.left}>
          <div className={classes.imageHolder}>
            <div className="inner">
              <img src={picture.url} alt={getFileTitle(picture.title)} />
            </div>
          </div>
          <div
            {...getRootProps({
              className: classes.dropzoneContainer
            })}
          >
            <input name="files" {...getInputProps()} />
            <span className="link">
              <EditIcon />
              Edit Photo
            </span>
          </div>
        </div>
        <div className={classes.right}>
          <form onSubmit={onSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <PsFormControl label="Title" labelLight>
                  <PsInput
                    name="title"
                    onChange={onTitleChange}
                    value={title}
                  />
                </PsFormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <PsFormControl label="Description" labelLight>
                  <PsInput
                    name="description"
                    multiline={true}
                    onChange={onDescriptionChange}
                    rows={4}
                    value={description}
                  />
                </PsFormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <PsFormControl
                  placeholder="Choose solution"
                  label="Selected Solutions"
                  labelLight
                >
                  <PsSelect
                    isMulti
                    maxMenuHeight={270}
                    name="selected"
                    onChange={onListChange}
                    options={list}
                    value={selectedValue}
                  />
                </PsFormControl>
                <PsSelectedList
                  options={list}
                  value={selectedValue}
                  onChange={onListChange}
                />
              </Grid>
            </Grid>

            <div className={classes.buttons}>
              <PsButton
                disabled={loading}
                color="secondary"
                onClick={onCancel}
                cancel
                small
              >
                Cancel
              </PsButton>
              <PsButton
                disabled={loading}
                loading={loading}
                color="secondary"
                type="submit"
                small
              >
                Save changes
              </PsButton>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export const ModalEditApplication = styled(ModalEditApplicationView)({});

export default ModalEditApplication;
