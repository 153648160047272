import React, { useCallback, useContext, useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';

import { ModalComponentsKeys, ModalContext } from 'contexts/ModalContext';
import { ModalDataContext } from 'contexts/ModalDataContext';
import { DataContext } from 'contexts/DataContext';
import { AuthContext } from 'contexts/AuthContext';
import {
  CardSolutionDetails,
  ModalEditSolution,
  Solution
} from 'components/CardSolution';
import { Tags } from 'components/Tag';
import { Problem } from 'components/CardProblem';
import { PsButton } from 'components/common/PsButton';
import { Application } from 'components/CardApplication';
import {
  Card,
  CardImage,
  CardBody,
  CardTitle,
  CardOwner,
  CardHeader,
  CardTimestamp
} from 'components/new-card';
import { GraphData } from 'components/GraphDeps';
import { CheckIcon } from 'components/icons/CheckIcon';
import { MoreHoriz } from 'components/icons/MoreHoriz';
import { ExpandCard } from 'components/ExpandCard';
import { RelatedNfts } from 'components/RelatedNfts';
import { CoinsCounter } from 'components/common/CoinsCounter';
import { ShareBtnSimple } from 'components/ShareBtn';
import { RelatedJackpots } from 'components/RelatedJackpots';
import { RelatedPriorArts } from 'components/RelatedPriorArts';
import { ButtonWithCounter } from 'components/common/ButtonWithCounter';
import { AddToConceptButton } from 'components/common/AddToConceptButton';
import { defaultSolutionTitle } from 'modals/AddNewSolution/config';
import { methodsHindsightVerbs } from 'modals/BrainstormSolution/steps/TypeDescriptionStep';
import { useAddSolutionInConcept } from 'hooks/useAddSolutionInConcept';
import { placeholderType } from '../../helpers';
import { PsTheme } from '../../theme';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';
import { Constants } from 'utilities/constants';

interface ISolutionTitles {
  id: string | number;
  title: string;
  teaser: string;
}

const useStyles = makeStyles()(() => {
  const theme = useTheme();
  const psTheme = theme as PsTheme;
  return {
    card: {
      marginBottom: '32px'
    },
    top: {
      display: 'flex',
      width: '100%',
      [psTheme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    },
    bottom: {
      padding: '10px 10px'
    },
    tags: {
      '& > div': {
        marginTop: 4,
        marginBottom: 4
      }
    },
    related: {
      padding: '30px 0 0 0'
    },
    buttons: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row-reverse',
      justifyContent: 'flex-start',
      alignItems: 'center'
    },
    addButton: {
      flexShrink: 0,
      '& svg': {
        marginRight: 10,
        marginLeft: -5
      }
    },
    tagsButton: {
      width: 160,
      margin: '10px 0 10px 10px'
    },
    descriptionRow: {
      margin: '10px 0 22px',
      fontSize: 18,
      lineHeight: 1.6,
      '& b': {
        fontWeight: 600,
        color: psTheme.palette.primary.main
      },
      '& a': {
        color: psTheme.palette.primary.main,
        textDecoration: 'underline'
      },
      '& a:hover': {
        cursor: 'pointer',
        textDecoration: 'none'
      }
    },
    infoRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      marginBottom: -5,
      paddingTop: 9,
      [psTheme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'stretch'
      }
    },
    infoCellRight: {
      display: 'flex',
      [psTheme.breakpoints.down('xs')]: {
        justifyContent: 'flex-end'
        // flexDirection: 'column',
        // alignItems: 'flex-start',
      }
    },
    nfts: {
      '& p': {
        margin: '10px 0 0'
      },
      '& b': {
        fontWeight: 600
      },
      '& a': {
        color: psTheme.palette.primary.main
      },
      '& ul': {
        margin: '0 0 5px'
      }
    },
    ownerInfo: {
      marginBottom: '16px'
    },
    checkMarkIcon: {
      color: '#B1F4D4',
      fontSize: '2.5rem',
      height: '30px',
      width: '30px',
      borderRadius: '50%',
      backgroundColor: '#B1F4D4'
    },
    iconSpace: {
      marginRight: '16px'
    },
    solutionDetailsWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '8px',
      borderBottom: '1px solid #C4C4C4'
    },
    solutionDetails: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      color: '#632DDD',
      fontSize: '13px',
      fontWeight: 600,
      cursor: 'pointer'
    },
    solutionDetailsTitle: {
      color: '#454F63',
      fontSize: '13px',
      fontWeight: 400,
      margin: 0
    },
    solutionDetailsProblemTitle: {
      fontSize: '13px',
      fontWeight: 600,
      color: '#5B38D5',
      margin: 0
    },
    solutionDetailsTitleWrapper: {}
  };
});

export type CardSolutionDetailProps = {
  solution: Solution;
  onUpdate?: (solution: Solution) => void;
  productId?: string;
  solutionTitles: ISolutionTitles[];
};

const CardSolutionDetailView = ({
  solution,
  onUpdate,
  productId,
  solutionTitles
}: CardSolutionDetailProps) => {
  const {
    id,
    // key,
    title,
    teaser,
    body,
    owner,
    ownerInfo,
    improvedSolutionInfo,
    files,
    tags = [],
    tagsInfo = [],
    createdAt,
    problem,
    descFurther,
    envelopeId,
    reward = 0
    // isPublic = false,
  } = solution;
  const { user, loading } = useContext(AuthContext);
  const { openModal, openSolutionImprove, openInventionAttachToSolution } =
    useContext(ModalContext);
  const { setType, updateField } = useContext(ModalDataContext);
  const { addInConcept, solutionInConcept } = useAddSolutionInConcept({
    solutionId: solution.id.toString(),
    productId: productId,
    problemId: String(solution.problem)
  });
  const {
    dataProvider,
    solutionDetailEditOpened,
    setSolutionDetailEditOpened
    // concepts,
    // selectedConceptId,
  } = useContext(DataContext);
  const [parentProblem, setParentProblem] = useState<Problem>();
  const [nfts, setNfts] = useState<Array<string>>([]);
  const [graph, setGraph] = useState<GraphData | undefined>();
  const [relatedTab, setRelatedTab] = useState('Problems');

  const [newSolution, setNewSolution] = useState<Solution>();
  const [newDescFurther, setNewDescFurther] = useState(descFurther);
  const [newTitle, setNewTitle] = useState(title);
  const { classes } = useStyles();

  const showEditTags = (user?.userData?.moderator || []).length;
  const showAddToInvention = !!user;
  const image = files && files.length ? files[0] : undefined;

  const showEdit = user ? user.id === owner || user.isAdmin : false;

  useEffect(() => {
    if (solution.descFurther) {
      setNewDescFurther(solution.descFurther);
    }
  }, [solution.descFurther]);

  useEffect(() => {
    if (!title) {
      const titleQuery = `Create title for solution of ${solution.problemInfo?.title}?`;
      const generateTitle = async () => {
        try {
          const res = await dataProvider.answerSearch(titleQuery);
          if (res.text) {
            if (!solution.title) {
              setNewTitle(res.text);
            }
          }
        } catch (error) {
          console.log(error);
        }
      };
      generateTitle();
      return;
    }

    const problemName = solution.problemInfo ? solution.problemInfo.title : '';

    const descrQuery = `Describe the solution of ${title} to solve the problem of ${problemName}?`;
    const generateDescr = async () => {
      try {
        const res = await dataProvider.answerSearch(descrQuery);
        if (res.text) {
          if (!solution.descFurther) {
            setNewDescFurther(res.text);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    generateDescr();
  }, [
    dataProvider,
    solution.descFurther,
    solution.problemInfo,
    solution.title,
    title
  ]);

  const getSolution = useCallback(async () => {
    try {
      const { data } = await dataProvider.getOne<Solution>('solutions', {
        id: solution.id
      });
      setNewSolution(data);
      return data;
    } catch (error) {
      console.log(error);
    }
  }, [dataProvider, solution.id]);

  useEffect(() => {
    getSolution();
  }, [getSolution]);

  const onEditOpen = useCallback(async () => {
    setSolutionDetailEditOpened(true);
  }, [setSolutionDetailEditOpened]);

  let improvementTitle;
  if (solution && solution.improvement) {
    improvementTitle = `${methodsHindsightVerbs[solution.improvement.method]} ${
      solution.title
    } ${solution.improvement.object}`;
  }

  useEffect(() => {
    if (loading) {
      return;
    }
    if (solution && solution.isInProgress) {
      setType(ModalComponentsKeys.addNewSolution);
      updateField('activeSolution', solution);
      updateField('titleSolution', `${defaultSolutionTitle}${solution.title}`);
      updateField('parentSolution', solution.parentSolution);
      updateField('parentProblem', solution.problem);
      updateField('finalizeType', solution.isPublic ? 'public' : 'private');
      updateField('termsAgree', solution.termsAgree);
      openModal(ModalComponentsKeys.addNewSolution);
    }
    if (solution) {
      dataProvider
        .getRelatedNfts<Application>('solutions', solution.id)
        .then(({ data }) => {
          const nfts = data
            .map((el) => el.nftTransactionUrl || '')
            .filter((el) => el);
          setNfts(nfts);
        })
        .catch((err) => {
          setNfts([]);
          console.error(err);
        });
    }
  }, [solution, loading, setType, updateField, openModal, dataProvider]);

  useEffect(() => {
    if (problem) {
      dataProvider
        .getOne<Problem>('problems', { id: problem })
        .then(({ data }) => {
          setParentProblem(data);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      setParentProblem(undefined);
    }
  }, [dataProvider, problem]);

  useEffect(() => {
    if (!solution) {
      setGraph(undefined);
      return;
    }
    dataProvider
      .getGraph<GraphData>(Constants.SOLUTIONS, solution.id, Constants.SOLUTION)
      .then((data) => {
        setGraph(data);
      })
      .catch((err) => {
        console.error(err);
        setGraph(undefined);
      });
  }, [dataProvider, solution]);

  const onSolutionUpdate = useCallback(
    async (newSolution: Solution) => {
      if (onUpdate) {
        onUpdate(newSolution);
      }
      setSolutionDetailEditOpened(false);
    },
    [onUpdate, setSolutionDetailEditOpened]
  );

  const onSolutionUpdateClose = useCallback(async () => {
    setSolutionDetailEditOpened(false);
  }, [setSolutionDetailEditOpened]);

  const onSignLinkClick = async (event: React.MouseEvent) => {
    event.preventDefault();
    if (envelopeId) {
      await dataProvider.downloadSignDocument(envelopeId);
    }
  };

  const onBrainstormClick = useCallback(() => {
    openSolutionImprove({ solutionId: id, solutionTitle: teaser || title });
  }, [openSolutionImprove, id, teaser, title]);

  const onManageTagsClick = useCallback(() => {
    updateField('title', 'solutions');
    updateField('productId', id);
    updateField('tags', tags);
    openModal(ModalComponentsKeys.manageTags);
  }, [updateField, openModal, id, tags]);

  const onAddToInventionClick = (e: React.MouseEvent) => {
    e.preventDefault();
    openInventionAttachToSolution({
      solutionId: id,
      solutionProblemId: problem,
      solutionTitle: teaser || title
    });
  };

  return (
    <>
      <Card className={classes.card}>
        <CardHeader>
          <CardTitle
            beforeTitle={
              <div className={classes.iconSpace}>
                <CheckIcon className={classes.checkMarkIcon} />
              </div>
            }
            // title={<>{newTitle || teaser}</>}
            title={<>{newTitle}</>}
          >
            <ShareBtnSimple solution={solution} tagsList={tagsInfo} />
          </CardTitle>
          <CardImage image={image} type={placeholderType.S} />
        </CardHeader>

        <div className={classes.solutionDetailsWrapper}>
          <div className={classes.solutionDetailsTitleWrapper}>
            <p className={classes.solutionDetailsTitle}>
              A solution of the problem of
            </p>
            <p className={classes.solutionDetailsProblemTitle}>
              {parentProblem ? parentProblem.teaser : ''}
            </p>
          </div>
          {showEdit ? (
            <span className={classes.solutionDetails} onClick={onEditOpen}>
              <MoreHoriz /> Solution Details
            </span>
          ) : null}
        </div>

        <CardBody>
          <CardOwner
            ownerName={ownerInfo && ownerInfo.username}
            ownerKey={ownerInfo && ownerInfo.key}
            owner={owner}
            createdAt={createdAt}
            ownerPicture={ownerInfo?.picture?.url}
            className={classes.ownerInfo}
          />
          <Tags
            className={classes.tags}
            tagsList={tagsInfo}
            edit={showEditTags ? 'before' : ''}
            onEditClick={onManageTagsClick}
          />
          {newDescFurther ? (
            <p className={classes.descriptionRow}>
              The solution of <b>{teaser || title}</b> is further described as{' '}
              {newDescFurther}.
            </p>
          ) : null}
          {parentProblem ? (
            <p className={classes.descriptionRow}>
              A solution for the problem of{' '}
              <Link to={`/problems/${parentProblem.key}`}>
                {parentProblem.teaser || parentProblem.title}
              </Link>
              .
            </p>
          ) : null}
          {body || improvedSolutionInfo ? (
            <p className={classes.descriptionRow}>
              {body}
              {improvedSolutionInfo ? (
                <>
                  {body ? <br /> : null}
                  This solution improved the solution of the{' '}
                  <Link to={`/solutions/${improvedSolutionInfo.key}`}>
                    {improvedSolutionInfo.teaser || improvedSolutionInfo.title}
                  </Link>
                  .
                </>
              ) : null}
            </p>
          ) : null}
          <div className={classes.infoRow}>
            <ButtonWithCounter onClick={onBrainstormClick} coins={30}>
              Improve
            </ButtonWithCounter>
            {productId ? (
              <AddToConceptButton
                added={solutionInConcept}
                onClick={addInConcept}
              />
            ) : null}
          </div>

          <Box
            my={1}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            Earned <CoinsCounter coins={reward} />
          </Box>
          <div className={classes.bottom}>
            {user && envelopeId ? (
              <p className={classes.descriptionRow}>
                Solution Assignment Document:{' '}
                <a onClick={onSignLinkClick}>Download</a>
              </p>
            ) : null}
            {nfts.length ? (
              <div className={classes.nfts}>
                <Typography component="div">
                  <p>
                    Links of the NFT of the invention where this solution was
                    included:
                  </p>
                  <ul>
                    {nfts.map((nft) => (
                      <li key={nft}>
                        <a href={nft} target="_blank" rel="noreferrer">
                          {nft}
                        </a>
                      </li>
                    ))}
                  </ul>
                </Typography>
              </div>
            ) : null}
          </div>
          <div className={classes.buttons}>
            {showAddToInvention ? (
              <PsButton
                className={classes.addButton}
                color="secondary"
                onClick={onAddToInventionClick}
                smallest
                fullWidth
              >
                <AddIcon />
                Add to Invention/Mint NFT
              </PsButton>
            ) : null}
          </div>
          <CardTimestamp createdAt={createdAt} />
        </CardBody>
      </Card>
      {/*<ExpandCard title={'next steps'}>*/}
      {/*  <NextStepsSolution solution={solution} />*/}
      {/*</ExpandCard>*/}
      <ExpandCard title="contests and challenges">
        <RelatedJackpots solution={solution.id} />
      </ExpandCard>
      {solution && solution.improvement ? (
        <ExpandCard title="details">
          <Typography>
            <b>{improvementTitle}</b>
            <br />
            {solution.improvement.text}
          </Typography>
        </ExpandCard>
      ) : null}
      {/*<ExpandCard title="related items">*/}
      {/*  <div className={classes.related}>*/}
      {/*    <Tabs>*/}
      {/*      <Tab label="Problems" value={relatedTab} setValue={setRelatedTab} />*/}
      {/*      <Tab*/}
      {/*        label="Inventions/NFTs"*/}
      {/*        value={relatedTab}*/}
      {/*        setValue={setRelatedTab}*/}
      {/*      />*/}
      {/*      <Tab label="Products" value={relatedTab} setValue={setRelatedTab} />*/}
      {/*      <Tab*/}
      {/*        label="Improved solutions"*/}
      {/*        value={relatedTab}*/}
      {/*        setValue={setRelatedTab}*/}
      {/*      />*/}
      {/*    </Tabs>*/}
      {/*    <TabContent label="Problems" value={relatedTab}>*/}
      {/*      <RelatedProblems solution={solution?.id} />*/}
      {/*    </TabContent>*/}
      {/*    <TabContent label="Products" value={relatedTab}>*/}
      {/*      <RelatedProducts solution={solution?.id} />*/}
      {/*    </TabContent>*/}
      {/*    <TabContent label="Improved solutions" value={relatedTab}>*/}
      {/*      <RelatedSolutions improvedSolution={solution?.id} />*/}
      {/*    </TabContent>*/}
      {/*  </div>*/}
      {/*</ExpandCard>*/}
      <ExpandCard title="prior art search">
        <RelatedPriorArts solution={solution?.id} />
      </ExpandCard>
      <ExpandCard title="idea NFTs featuring solution">
        <RelatedNfts solutionId={solution.id} />
      </ExpandCard>
      <ExpandCard title="more details">
        <CardSolutionDetails solution={solution} />
      </ExpandCard>
      {newSolution ? (
        <ModalEditSolution
          solution={newSolution}
          open={solutionDetailEditOpened}
          onUpdate={onSolutionUpdate}
          onClose={onSolutionUpdateClose}
          setNewDescFurther={setNewDescFurther}
          setNewTitle={setNewTitle}
          solutionTitles={solutionTitles}
        />
      ) : null}
    </>
  );
};

export const CardSolutionDetail = styled(CardSolutionDetailView)({});

export default CardSolutionDetail;
