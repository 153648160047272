import React, { Dispatch, FC, SetStateAction, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Typography
} from '@mui/material';
import InteractiveNodeContainer from 'components/InteractiveNodeContainer';
import Actions from 'redux-state/actions';
import { GetCalculateMultiplierIdeaPoints } from 'redux-state/selectors';
import { IdeaPointIcon } from 'components/icons/IdeaPointIcon';
import { Node } from 'interface/common';
import { Constants } from 'utilities/constants';
import { colorPalette } from 'theme';
import {
  AddChildToGrandParentBox,
  AddChildToGrandParentButton,
  AddChildToGrandParentText,
  ParentBox,
  StyledButtonContainer,
  StyledButtonEarnPointsTextContainer,
  StyledButtonTextContainer,
  StyledIdeadPointsIconContainer,
  StyledIdeaIconTextContainer,
  StyledParent,
  StyledPlusIcon,
  StyledTypography
} from './StyledComponents';

interface ParentNodeProps {
  addNewConcept: () => void;
  graphData: any;
  handleDrawer: () => void;
  openCreateProblemModal: () => void;
  problemAndSolutionCreationCallBack: () => void;
  progress: number;
  setExpansionIds?: Dispatch<SetStateAction<string[]>>;
  setIsSolutionModalOpen: (value: boolean) => void;
  setProblem: (problem: Node) => void;
  terminated: boolean;
  type: string;
}

const typeToButtonTextMapper: Record<string, string> = {
  [Constants.PROBLEMS]: Constants.ADD_NEW_SOLUTION,
  [Constants.SOLUTIONS]: Constants.ADD_NEW_RELATED_PROBLEM,
  default: Constants.ADD_NEW_PROBLEM
};

const typeToButtonEarnIdeaPoints: Record<string, string> = {
  [Constants.PROBLEMS]: Constants.EARN_IDEAPOINTS,
  [Constants.COMPANY_PRODUCTS]: Constants.EARN_IDEAPOINTS
};

const CircularProgressWithLabel: FC<
  {
    value: number;
    title: string;
    progress: number;
  } & CircularProgressProps
> = React.memo(({ value, title, progress, ...props }) => (
  <Box
    sx={{
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '20px'
    }}
  >
    <Box sx={{ marginBottom: '10px' }}>
      <Typography>
        {progress >= 88
          ? 'AI at work: Almost Done ...'
          : `AI at work: Generating problems and solutions for the ${title}`}
      </Typography>
    </Box>
    <CircularProgress
      variant="determinate"
      sx={{ alignSelf: 'center' }}
      value={value}
      size={100}
      {...props}
    />
    <ParentBox>
      <StyledTypography variant="caption" component="div">
        {`${Math.round(value)}%`}
      </StyledTypography>
    </ParentBox>
  </Box>
));

const ParentNode: FC<ParentNodeProps> = ({
  addNewConcept,
  graphData,
  handleDrawer,
  openCreateProblemModal,
  problemAndSolutionCreationCallBack,
  progress,
  setExpansionIds,
  setIsSolutionModalOpen,
  setProblem,
  terminated,
  type
}) => {
  const multiplierIdeaPoints = GetCalculateMultiplierIdeaPoints();
  const dispatch = useDispatch();

  useEffect(() => {
    if (graphData) {
      dispatch(
        Actions.calculateMultiplierIdeaPoints({
          defaultIdeaPoints: Constants.DEFAULT_CREATE_ITEM_POINTS,
          likes: graphData?.likes || 0,
          dislikes: graphData?.dislikes || 0
        })
      );
    }
  }, [dispatch, graphData]);
  const ChildToGrandParentText = useMemo(() => {
    switch (type) {
      case Constants.PROBLEMS:
        return Constants.C_SOLUTIONS;
      case Constants.SOLUTIONS:
        return Constants.RELATED_PROBLEMS;
      case Constants.APPLICATIONS:
        return Constants.C_SOLUTIONS;
      default:
        return Constants.C_PROBLEMS;
    }
  }, [type]);

  const progressTitle = useMemo(
    () => graphData?.title || '',
    [graphData?.title]
  );

  const addChildButton = useMemo(() => {
    if (type === Constants.SOLUTIONS || type === Constants.APPLICATIONS) {
      return null;
    }

    return (
      <AddChildToGrandParentButton
        variant="contained"
        onClick={openCreateProblemModal}
        aria-label={`Add new ${typeToButtonTextMapper[type] || typeToButtonTextMapper.default}`}
        sx={{
          backgroundColor:
            type === Constants.PROBLEMS ? colorPalette.lightGreen : ''
        }}
      >
        <StyledButtonContainer>
          <StyledButtonTextContainer>
            <StyledPlusIcon />
            <Box sx={{ ml: 0.5 }}>
              {typeToButtonTextMapper[type] || typeToButtonTextMapper.default}
            </Box>
          </StyledButtonTextContainer>

          <StyledIdeaIconTextContainer>
            <StyledIdeadPointsIconContainer>
              <IdeaPointIcon />
            </StyledIdeadPointsIconContainer>
            <StyledButtonEarnPointsTextContainer>
              {`${Constants.EARN} ${multiplierIdeaPoints} ${Constants.C_IDEA_POINTS}`}
            </StyledButtonEarnPointsTextContainer>
          </StyledIdeaIconTextContainer>
        </StyledButtonContainer>
      </AddChildToGrandParentButton>
    );
  }, [type, openCreateProblemModal]);

  return (
    <>
      <StyledParent>
        <InteractiveNodeContainer
          addNewConcept={addNewConcept}
          node={graphData}
          handleDrawer={handleDrawer}
          setProblem={setProblem}
          setIsSolutionModalOpen={setIsSolutionModalOpen}
          problemAndSolutionCreationCallBack={
            problemAndSolutionCreationCallBack
          }
          isParentNode={true}
          setExpansionIds={setExpansionIds}
        />
      </StyledParent>

      <>
        {progress >= 96 || graphData?.children?.length ? (
          <AddChildToGrandParentBox>
            <AddChildToGrandParentText>
              {ChildToGrandParentText}
            </AddChildToGrandParentText>
            {addChildButton}
          </AddChildToGrandParentBox>
        ) : null}

        {!terminated && graphData?.isBeingGenerated ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '50vh'
            }}
          >
            <CircularProgressWithLabel
              value={progress}
              title={progressTitle}
              progress={progress}
            />
            {type !== Constants.SOLUTIONS &&
              type !== Constants.APPLICATIONS &&
              addChildButton}
          </Box>
        ) : null}
      </>
    </>
  );
};

export default React.memo(ParentNode);
