import { useSelector } from 'react-redux';

export const GetHomeLoader = () =>
  useSelector((state: any) => state.home.loading);
export const GetProducts = () =>
  useSelector((state: any) => state.home.products);
export const GetCompanies = () =>
  useSelector((state: any) => state.home.companies);
export const GetTags = () => useSelector((state: any) => state.home.tags);
export const GetTagsLoading = () =>
  useSelector((state: any) => state.home.loading);
export const GetTagsCount = () =>
  useSelector((state: any) => state.home.tagsCount);
export const GetTagsLoader = () =>
  useSelector((state: any) => state.home.tagsLoader);
export const GetLocationTags = () =>
  useSelector((state: any) => state.home.locationTags);
export const GetWorkplaceTags = () =>
  useSelector((state: any) => state.home.workplaceTags);
export const GetUniversityTags = () =>
  useSelector((state: any) => state.home.universityTags);
export const GetRelatedTags = () =>
  useSelector((state: any) => state.home.relatedTags);
