import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState
} from 'react';
import LabeledIconButton from 'components/common/buttons/LabeledIconButton';
import { useDispatch } from 'react-redux';
import Actions from 'redux-state/actions';
import { GetConceptData, GetUser } from 'redux-state/selectors';
import { colorPalette } from 'theme';
import { Constants, VARIANT } from 'utilities/constants';
import { updateConceptSolutionsRelationship } from '../helpers';
import { Message } from '../interfaces';
import {
  ActionButtonWrapper,
  InputProps,
  MessageHeading,
  StyledTextField
} from '../styledComponents';

export const IconSize = 25;

interface SolutionRelationshipProps {
  message: Message;
  setMessages?: Dispatch<SetStateAction<Message[]>>;
}

const SolutionRelationshipComponent: React.FC<SolutionRelationshipProps> = ({
  message,
  setMessages
}) => {
  const dispatch = useDispatch();
  const user = GetUser();
  const conceptData = GetConceptData();
  const activeConcept = useMemo(() => user?.activeConcept || {}, [user]);

  const [solutionsRelation, setSolutionsRelation] = useState<string>(
    message.data.relationship || ''
  );

  const selectedConcept = useMemo(() => {
    return (
      activeConcept &&
      Object?.keys(activeConcept)?.length &&
      conceptData?.data?.find((concept) => concept?.id === activeConcept?.id)
    );
  }, [activeConcept, conceptData?.data]);

  const onRelationshipChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSolutionsRelation(e.target.value);
      message.data.relationship = e.target.value;
    },
    [message]
  );

  const handleSave = useCallback(() => {
    if (user) {
      const conceptUpdateObj = updateConceptSolutionsRelationship(
        selectedConcept,
        message.data
      );
      dispatch(
        Actions.editProfileItem({
          type: Constants.APPLICATIONS,
          id: activeConcept.id,
          profileId: user.id,
          data: conceptUpdateObj,
          getConcepts: true
        })
      );
      setMessages((pre) =>
        pre.map((msg) => {
          if (msg.id === message.id) {
            return { ...msg, isClicked: true };
          }
          return msg;
        })
      );
    }
  }, [
    user,
    selectedConcept,
    message.data,
    message.id,
    dispatch,
    activeConcept.id,
    setMessages
  ]);

  return (
    <>
      <MessageHeading mb=".75rem" fontWeight={600}>
        {message.title}
      </MessageHeading>
      <StyledTextField
        value={solutionsRelation}
        onChange={onRelationshipChange}
        InputProps={InputProps}
        multiline={true}
        rows={17}
      />
      <ActionButtonWrapper>
        <LabeledIconButton
          icon={null}
          variant={VARIANT.CONTAINED}
          text={message?.isClicked ? Constants.SAVED : Constants.SAVE}
          btnColor={colorPalette.purple}
          buttonSx={{ padding: '.3125rem', marginTop: '10px' }}
          typographySx={{
            fontSize: '.75rem'
          }}
          width="170px"
          onClick={handleSave}
          disabled={message?.isClicked}
        />
      </ActionButtonWrapper>
    </>
  );
};

export const SolutionRelationship = React.memo(SolutionRelationshipComponent);
