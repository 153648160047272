/* eslint-disable max-lines-per-function */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Grid } from '@mui/material';
import { TagInfo } from 'components/CardTag';
import { ChipList } from 'components/Tag';
import { getBasePath } from 'helpers/common';
import { Node } from 'interface/common';
import Actions from 'redux-state/actions';
import {
  GetGeneratedImage,
  GetProblem,
  GetProduct
} from 'redux-state/selectors';
import { Constants } from 'utilities/constants';
import { Content } from './Content';
import { GenerationButton } from './GenerationButton';
import Footer from '../Footer';
import { RelatedParent } from '../RelatedParent';
import {
  ContentContainer,
  HeadingVotePanelContainer,
  StyledHeading,
  StyledTextWrapper,
  StyledTypography
} from '../StyledComponents';

interface SolutionContentProps {
  displayedTags?: Array<TagInfo>;
  item: Node;
  onShowAllTagsClick?: () => void;
  setImage: React.Dispatch<React.SetStateAction<string>>;
  showAllTags?: boolean;
  votingPanel?: React.ReactNode;
}

export const SolutionContent: React.FC<SolutionContentProps> = ({
  displayedTags,
  item,
  onShowAllTagsClick,
  setImage,
  showAllTags,
  votingPanel
}) => {
  const { title, body, ideaPoints, parent, problem, type } = item;
  const dispatch = useDispatch();
  const parentProblem = GetProblem();
  const grandParentProduct = GetProduct();
  const generatedImage = GetGeneratedImage();

  useEffect(() => {
    if (generatedImage) {
      dispatch(
        Actions.editProfileItem({
          data: {
            files: [
              {
                url: generatedImage
              }
            ]
          },
          id: item?.id,
          type: Constants.SOLUTIONS
        })
      );
      setImage(generatedImage);
    }
  }, [generatedImage, dispatch, item?.id, setImage]);

  useEffect(() => {
    dispatch(Actions.getProblem((problem as string) || (parent as string)));
  }, [dispatch, parent, problem]);

  useEffect(() => {
    if (parentProblem?.parentProduct) {
      dispatch(Actions.getProduct(parentProblem?.parentProduct as string));
    }
  }, [dispatch, parentProblem?.parentProduct]);

  return (
    <ContentContainer>
      <Grid md={12} sm={12}>
        <HeadingVotePanelContainer>
          <StyledTypography type={type}>
            {Constants.C_SOLUTION}:
          </StyledTypography>
          <GenerationButton
            body={body}
            item={item}
            parentProblemTitle={parentProblem?.title}
            title={title}
            votingPanel={votingPanel}
          />
        </HeadingVotePanelContainer>
        <StyledTextWrapper>{title}</StyledTextWrapper>
        <ChipList
          list={displayedTags}
          max={5}
          showAll={showAllTags}
          onShowAllTagsClick={onShowAllTagsClick}
          getName={(solTag) => {
            const name = (solTag as TagInfo).name || '';
            return name;
          }}
          getUrl={(solTag) => {
            const basePath = getBasePath(solTag);
            return `/${basePath}/${
              (solTag as TagInfo).key || (solTag as TagInfo).id
            }`;
          }}
        />
        <StyledHeading>{Constants.RELATED_PROBLEM_PRODUCT}:</StyledHeading>
        <RelatedParent
          problemKey={parentProblem?.key}
          problemTitle={parentProblem?.title}
          productKey={grandParentProduct?.key}
          productTitle={grandParentProduct?.title}
        />
        <Content body={body} ideaPoints={ideaPoints} />
        <Footer item={item} />
      </Grid>
    </ContentContainer>
  );
};
